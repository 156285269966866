import { useEffect, useRef, useState } from 'react';

import type { ReactNode } from 'react';

import styles from './modal.module.css';

type Props = {
  title?: string;
  description?: string;
  type?: 'small' | 'medium' | 'large';
  hasSpace?: boolean;
  children: ReactNode;
  className?: string;
  overflow?: 'auto' | 'none';
  footer?: ReactNode;
};

/**
 * @deprecated
 * このコンポーネントの代わりに、@src/views/Common/Ui/Modalを使用してください。
 */
const Modal: React.FC<Props> = ({
  title,
  description,
  type = 'small',
  hasSpace = true,
  children,
  className = '',
  overflow = 'auto',
  footer,
}) => {
  const [isFooterFixed, setIsFooterFixed] = useState(false);
  const [footerHeight, setFooterHeight] = useState(0);
  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // footerが指定されていなければ早期リターン
    if (!footer) return;

    const wrapperElement = scrollWrapperRef.current;
    const footerElement = footerRef.current;
    if (wrapperElement !== null && footerElement !== null) {
      const resizeEventListener = () => {
        const wrapperClientHeight = wrapperElement.clientHeight;
        const wrapperScrollHeight = wrapperElement.scrollHeight;
        const isScrollable = wrapperClientHeight < wrapperScrollHeight;
        if (isScrollable) {
          setFooterHeight(footerElement.clientHeight);
          setIsFooterFixed(true);
        } else {
          setIsFooterFixed(false);
        }
      };
      resizeEventListener();
      window.addEventListener('resize', resizeEventListener);

      return () => window.removeEventListener('resize', resizeEventListener);
    }
  }, [footer]);

  return (
    <div
      className={`${styles.wrapper} ${styles[type]} ${
        footer && styles.hasFooter
      } ${className}`}
    >
      <div
        ref={scrollWrapperRef}
        className={`${styles.scrollWrapper} ${
          hasSpace ? styles.hasSpace : ''
        } ${overflow === 'auto' ? styles.overflowAuto : ''} ${
          isFooterFixed ? styles.footerFixed : ''
        } ${footer && styles.hasFooter}`}
        // footerHeightの高さ分、領域を確保して要素が見えなくなるのを防ぐ
        style={isFooterFixed ? { paddingBottom: footerHeight + 40 } : {}}
      >
        {title && <h3 className={styles.title}>{title}</h3>}
        {description && <p className={styles.description}>{description}</p>}
        {children}
      </div>
      {footer && (
        <div
          ref={footerRef}
          className={`${styles.footer} ${isFooterFixed ? styles.fixed : ''}`}
        >
          {footer}
        </div>
      )}
    </div>
  );
};

export default Modal;
