import { useId } from 'react';

type UseTextfieldArgs = {
  error?: string;
  hasError?: boolean;
  onEnter?: () => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
};

type UseTextfieldReturn = {
  inputId: string;
  onKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
};

export const useTextfield = ({
  error,
  hasError,
  onEnter,
  onKeyDown: originalOnKeyDown,
}: UseTextfieldArgs): UseTextfieldReturn => {
  const inputId = useId();

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (!e.nativeEvent.isComposing && e.key === 'Enter') {
      !error && !hasError && onEnter?.();
    }

    originalOnKeyDown?.(e);
  };

  return {
    inputId,
    onKeyDown,
  };
};
