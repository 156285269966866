/**
 * @file nanoidを利用した機能を提供する
 *
 * テスト時にモック化したい場合は次のような形でできます
 * ```
 * vi.mock('@/lib/nanoid', () => {
 *   return {
 *     genUID: () => 'abcde12345',
 *   };
 * });
 * ```
 */

import { nanoid } from 'nanoid';

/**
 * ランダムなIDを生成して返却する
 *
 * デフォルトの長さは10文字としている. プロジェクトでnanoidで生成するIDの長さは主に10文字であるため.
 *
 * @see {@link https://github.com/ai/nanoid}
 */
function genUID(len = 10) {
  return nanoid(len);
}

export { genUID };
