import { useTranslation } from 'react-i18next';

import type { KeyPrefix } from 'i18next';

/**
 * FormRole関連のi18nを扱うカスタムフック
 *
 * @param keyPrefix - "formRole"にあるキーのプレフィックス (ネストしたキーを指定する場合に使用してください)
 *
 * @see {@link https://react.i18next.com/latest/usetranslation-hook}
 */
function useI18nFormRole<K extends KeyPrefix<'formRole'>>(keyPrefix?: K) {
  return useTranslation<'formRole', K>('formRole', {
    keyPrefix,
  });
}

/**
 * FormRole関連のヘルパー関数類のi18nを扱うカスタムフック
 *
 * @see {@link https://react.i18next.com/latest/usetranslation-hook}
 */
function useI18nFormRoleHelpers() {
  return useTranslation('formRoleHelpers');
}

export { useI18nFormRole, useI18nFormRoleHelpers };
