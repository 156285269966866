import cx from 'classnames';
import type React from 'react';
import { useState, useCallback } from 'react';

import styles from './tooltip.module.css';

type Props = {
  children?: React.ReactNode;
  trigger?: React.ReactChild;
  className?: string;
  alignment?: 'right' | 'left';
  onClose?: () => React.MouseEventHandler;
  isOnClick?: boolean;
};

const Tooltip: React.FC<Props> = ({
  children,
  trigger,
  className,
  alignment = 'right',
  onClose = () => {},
  isOnClick = true,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const open = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (isOnClick) {
        e.stopPropagation();
        setIsOpen(true);
      }
    },
    [setIsOpen, isOnClick],
  );
  const close = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setIsOpen(false);
      onClose();
    },
    [onClose],
  );

  return (
    <div className={className}>
      <div
        className={cx(styles.trigger, {
          [styles.isOnClick]: isOnClick,
        })}
        onClick={open}
      >
        {trigger}
        {isOpen && (
          <div>
            <div className={styles.mask} onClick={close} />
            <div
              className={cx(styles.container, styles[alignment])}
              onClick={close}
            >
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
