import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import {
  createContent as createContentUsecase,
  bulkDeleteContents as bulkDeleteContentsUsecase,
  deleteAllContents as deleteAllContentsUsecase,
} from '@/usecase/contentUsecase';

import type { Contents } from '../../types/contents';

import { useRegisterTopicEvent } from '@/views/Common/providers/momento';

type CreateContentParam = {
  apiId: string;
  contentId: string;
  value: Record<string, unknown>;
  contentStatus: 'PUBLISH' | 'DRAFT';
};

export const useCreateContent = () => {
  const { mutateAsync: createContentAsync } = useMutation<
    Contents,
    Error,
    CreateContentParam
  >({
    mutationFn: async ({ apiId, contentId, value, contentStatus }) => {
      return createContentUsecase({
        apiId,
        contentId,
        value: JSON.stringify(value),
        contentStatus,
      });
    },
  });

  return {
    createContentAsync,
  };
};

type Data = {
  result?: boolean;
  successIds?: string[];
  failureIds?: string[];
};

type Variables = {
  selectedContents: string[];
};

export const useBulkDeleteContents = (apiId: string) => {
  const { t } = useTranslation('hooksContent');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  const {
    mutateAsync: bulkDeleteContentsAsync,
    isLoading: bulkDeleteContentsLoading,
  } = useMutation<Data, Error, Variables>({
    mutationFn: async ({ selectedContents }) => {
      return bulkDeleteContentsUsecase({ apiId, contentIds: selectedContents });
    },
    onSuccess(data) {
      if (data.successIds?.length !== 0) {
        addToast(
          t('{{count}} contents were deleted.', {
            count: data.successIds?.length,
          }),
          {
            appearance: 'success',
          },
        );
      }

      if (data.failureIds?.length !== 0) {
        addToast(
          t(
            'Failed to delete {{count}} content items \n Content ID:{{ids}} \n\n A content that is being referenced by another content or in pending review cannot be deleted.',
            {
              count: data.failureIds?.length,
              ids: data.failureIds?.join(t('period')),
            },
          ),
          {
            appearance: 'error',
            autoDismiss: false,
          },
        );
      }

      cache.invalidateQueries(['contentList'], { type: 'all' });
    },
    onError({ message }) {
      addToast(message ? message : t('Content could not be deleted.'), {
        appearance: 'error',
      });
    },
  });

  return { bulkDeleteContentsAsync, bulkDeleteContentsLoading };
};

export const useDeleteAllContents = (apiId: string, apiName: string) => {
  const { t } = useTranslation('hooksContent');
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const { register } = useRegisterTopicEvent();

  const { mutate: deleteAllContents, isLoading: deleteAllContentsLoading } =
    useMutation({
      mutationFn: () => {
        return deleteAllContentsUsecase({ apiId });
      },
      onSuccess() {
        addToast(t('All contents have been started for deletion.'), {
          appearance: 'success',
        });
        register((topicValue, done) => {
          if (
            topicValue.eventName === 'deleteAllContents' &&
            (topicValue.data as { apiId: string; userId: string })?.apiId ===
              apiId
          ) {
            if (topicValue.status === 'SUCCEEDED') {
              addToast(
                t(
                  'Deletion of all contents of the {{apiName}} API has been completed.',
                  { apiName },
                ),
                {
                  appearance: 'success',
                },
              );
              cache.invalidateQueries(['contentList'], { type: 'all' });
              done();
            } else if (topicValue.status === 'FAILED') {
              addToast(
                topicValue.message ||
                  t('Deletion of all contents of the {{apiName}} API failed.', {
                    apiName,
                  }),
                {
                  appearance: 'error',
                },
              );
              done();
            }
          }
        });
      },
      onError({ message }) {
        addToast(
          message ? message : t('Could not start deleting all content.'),
          {
            appearance: 'error',
          },
        );
      },
    });

  return { deleteAllContents, deleteAllContentsLoading };
};
