import cx from 'classnames';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import { useTextfield } from './useTextfield';
import { Input } from '../Input';
import { TextBottomAlert } from '../TextBottomAlert';
import { useFieldIds } from '../useFieldIds';

import type { NumberRange } from '@/types/field';

import styles from './textfield.module.css';

type TextType = 'text' | 'number' | 'tel' | 'password';
type SizeType = 'xsmall' | 'small' | 'medium' | 'large' | 'full';

export type Props = {
  label: string;
  error?: string;
  textCount?: number;
  inputRef?: React.Ref<HTMLInputElement>;
  size?: SizeType;
  onEnter?: () => void;
  type?: TextType;
  textSize?: NumberRange;
  displayTextCount?: boolean;
} & (
  | {
      direction?: 'vertical' | undefined;
      description?: React.ReactNode;
    }
  | {
      direction: 'horizontal';
      description?: undefined;
    }
) &
  Omit<React.ComponentPropsWithoutRef<'input'>, 'type' | 'size'>;

export type ViewProps = Omit<Props, 'onEnter'> &
  ReturnType<typeof useFieldIds> &
  ReturnType<typeof useTextfield>;

export const TextfieldView: React.FC<ViewProps> = ({
  inputId,
  label,
  labelId,
  description,
  descriptionId,
  error,
  errorId,
  textCount = 0,
  inputRef,
  size = 'full',
  direction = 'vertical',
  type = 'text',
  textSize,
  displayTextCount,
  ...props
}) => {
  const { t } = useTranslation('commonUi');

  return (
    <div>
      <div className={cx(styles.wrapper, styles[direction])}>
        <div>
          <label id={labelId} htmlFor={inputId} className={styles.label}>
            <span>{label}</span>
            {props.required && (
              <span className={styles.required}>
                <span aria-hidden>*</span>
                <span className="sr-only">{t('Required')}</span>
              </span>
            )}
          </label>
          {description && (
            <div id={descriptionId} className={styles.description}>
              {description}
            </div>
          )}
        </div>
        <Input
          id={inputId}
          ref={inputRef}
          type={type}
          className={cx(styles.input, styles[size])}
          aria-labelledby={labelId}
          aria-describedby={
            [descriptionId, errorId].filter(Boolean).join(' ') || undefined
          }
          aria-invalid={props['aria-invalid'] ?? !!error}
          aria-readonly={props['aria-readonly'] ?? props.readOnly}
          {...props}
        />
      </div>
      <TextBottomAlert
        errorText={error}
        errorId={errorId}
        textSize={textSize}
        textCount={textCount}
        displayTextCount={displayTextCount}
      />
    </div>
  );
};

export const Textfield: React.FC<Props> = ({ onEnter, ...props }) => {
  const fieldIds = useFieldIds(props);
  const hooks = useTextfield({ onEnter, ...props });

  return <TextfieldView {...props} {...fieldIds} {...hooks} />;
};
