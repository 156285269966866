import { Slot } from '@radix-ui/react-slot';
import cx from 'classnames';

import { useSortableItem } from './useSortableItem';

import type { UniqueIdentifier } from '@dnd-kit/core';

import styles from './sortableitem.module.css';

export type Props = {
  uniqueIdentifier: UniqueIdentifier;
  handle?: React.ReactNode;
} & React.ComponentPropsWithoutRef<'div'>;

export type ViewProps = {
  rootProps: React.ComponentProps<'div'> & {
    [key: `data-${string}`]: string;
  };
} & (
  | {
      handle?: React.ReactNode;
      handleProps: React.ComponentPropsWithoutRef<'div'> & {
        ref: React.RefCallback<HTMLDivElement>;
        [key: `data-${string}`]: string;
      };
    }
  | {
      handle: null;
      handleProps: null;
    }
);

export const SortableItemView: React.FC<ViewProps> = ({
  rootProps,
  handle,
  handleProps,
}) => {
  return (
    <div {...rootProps} className={cx(styles.main, rootProps.className)}>
      {rootProps.children}
      {!!handle && <Slot {...handleProps}>{handle}</Slot>}
    </div>
  );
};

export const SortableItem: React.FC<Props> = (props) => {
  const hooks = useSortableItem(props);

  return <SortableItemView {...hooks} />;
};
