import { useI18nFormRole } from '../../i18n';

import styles from './NoDisplayPermissions.module.css';

import { Fieldset } from '@/views/Common/Ui/Fieldset';
import { Icon } from '@/views/Common/Ui/Icon';

/**
 * 閲覧権限がないAPIスキーマの表示内容
 */
const NoDisplayPermissions = () => {
  const { t } = useI18nFormRole();

  return (
    <div data-testid="noDisplayPermissions">
      <Fieldset label={t('Target API')}>
        <span className={styles.noPermission}>
          <Icon name="visibility_off" size="large" className={styles.icon} />
          <span className={styles.text}>
            {t(
              'Cannot be displayed due to lack of read permissions for the target API.',
            )}
          </span>
        </span>
      </Fieldset>
    </div>
  );
};

export default NoDisplayPermissions;
