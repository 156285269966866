import { getBandwidthLog } from '@/usecase/bandwidthLogUsecase';

import { useApi } from '@/lib/useApi';
import { isReactQueryError } from '@/util/type-guard';

export const useAmountSettings = () => {
  const useGetBandwidthLog = (serviceId: string | undefined) =>
    useApi(
      ['bandwidthLog', { serviceId }],
      async ({ serviceId }) => {
        return await getBandwidthLog(serviceId || '');
      },
      {
        // キャッシュinvalidateするコンポーネントがないためstaleTimeはデフォルトの0としておく
        enabled: !!serviceId,
        onSuccess: () => {
          // noop
        },
        onError: (error) => {
          if (isReactQueryError(error)) {
            return {
              message: `${error.status}:${error.message}`,
            };
          }
          if (error instanceof Error) {
            return {
              message: error.message,
            };
          }
          return {
            message: 'Something went wrong...',
          };
        },
      },
    );

  return { useGetBandwidthLog };
};
