import cx from 'classnames';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { myRolesContext } from '@/hooks/Role/useMyRoles';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';

import { validateEmail } from '../Validations';
import IconWithTextButton from '../ui/IconWithTextButton';
import Notification from '../ui/Notification';
import MemberInput from './MemberInput';

import type { Dispatch, SetStateAction } from 'react';

import styles from './inviteMemberModal.module.css';

import { useAllRoles } from '@/views/Common/Roles/useRolesReader';
import { Fieldset } from '@/views/Common/Ui/Fieldset';
import { ModalContent } from '@/views/Common/Ui/Modal';
import { RolesSettingField } from '@/views/members/InviteMember/RolesSettingField';

type Props = {
  members?: any[];
  newMembers: Member[];
  setNewMembers: Dispatch<SetStateAction<Member[]>>;
  inviteMembers: any;
  isLoading: boolean;
  closeModal: () => void;
};

// 一度に招待できるユーザ数
const MAX_INVITATIONS_AT_ONE_TIME = 10;

export type Member = {
  email: string | undefined;
  errorMessage: string | null;
};

const InviteMemberModal: React.FC<Props> = ({
  members = [],
  inviteMembers,
  newMembers,
  setNewMembers,
  closeModal,
  isLoading,
}) => {
  const { t } = useTranslation('inviteMemberModal');
  const { service } = useGetMyService();
  const { currentPlan } = useStripeActions(service);

  const { roles: myRoles } = useContext(myRolesContext);
  const areNewMemberEmailsValid = useMemo(() => {
    return (
      newMembers.length > 0 &&
      newMembers.every(
        (email) => email.email !== undefined && email.errorMessage === null,
      )
    );
  }, [newMembers]);
  const duplicateEmailAddressesExist = useMemo(() => {
    return new Set(newMembers.map((v) => v.email)).size !== newMembers.length;
  }, [newMembers]);
  const setNewMemberAt = (email: string, index: number) => {
    setNewMembers((prev) => {
      const next = [...prev];
      next[index] = {
        email: email,
        errorMessage: validateEmail(email),
      };
      return next;
    });
  };
  const deleteNewMemberAt = (index: number) => {
    setNewMembers((prev) => {
      const next = [...prev];
      next.splice(index, 1);
      return next;
    });
  };

  const isDeleteMemberInputButtonVisible = newMembers.length > 1;

  const addMemberInput = useCallback(() => {
    setNewMembers(
      newMembers.concat([{ email: undefined, errorMessage: null }]),
    );
  }, [newMembers, setNewMembers]);
  const isAddMemberInputVisible =
    newMembers.length < MAX_INVITATIONS_AT_ONE_TIME;

  const { data: roles } = useAllRoles(service?.partitionKey);
  const [roleIds, setRoleIds] = useState<string[]>([]);
  const addRoleId = (roleId: string) => {
    setRoleIds((roleIds) => [...roleIds, roleId]);
  };
  const removeRoleId = (roleId: string) => {
    setRoleIds((roleIds) => roleIds.filter((id) => id !== roleId));
  };

  // 追加課金によるメンバー追加ができない場合
  const willBeOverLimitWhenAddMember = useMemo(() => {
    return (
      currentPlan &&
      currentPlan.limit.memberCount < members.length + newMembers.length &&
      currentPlan.limit.memberCount !== null &&
      currentPlan.limit.memberCountOveragePrice === null &&
      currentPlan.limit.memberCountOverageUnit === null
    );
  }, [currentPlan, members.length, newMembers.length]);

  // 追加課金によりメンバー追加ができる場合
  const willBeChargedWhenAddMember = useMemo(() => {
    return (
      currentPlan &&
      currentPlan.limit.memberCount < members.length + newMembers.length &&
      currentPlan.limit.memberCount !== null &&
      currentPlan.limit.memberCountOveragePrice !== null &&
      currentPlan.limit.memberCountOverageUnit !== null
    );
  }, [currentPlan, members.length, newMembers.length]);

  const isSubmitabble =
    !willBeOverLimitWhenAddMember &&
    areNewMemberEmailsValid &&
    !duplicateEmailAddressesExist &&
    roleIds.length !== 0 &&
    !isLoading;

  const submit = useCallback(async () => {
    if (
      !willBeChargedWhenAddMember ||
      (willBeChargedWhenAddMember &&
        window.confirm(
          t(
            'Inviting this member will increase your monthly fee upon joining. Continue?',
          ),
        ))
    ) {
      inviteMembers(
        {
          roleIds,
          emails: newMembers.map((newMember) => newMember.email),
        },
        {
          onSettled: () => {
            setNewMembers([{ email: undefined, errorMessage: null }]);
            setRoleIds([]);
            closeModal();
          },
        },
      );
    }
  }, [
    willBeChargedWhenAddMember,
    t,
    inviteMembers,
    roleIds,
    newMembers,
    setNewMembers,
    closeModal,
  ]);

  return (
    <ModalContent
      title={t('Invite Members')}
      footer={{
        submitButtonProps: {
          type: 'primary',
          value: t('Invite'),
          disabled: !isSubmitabble,
          onClick: submit,
          className: cx(`ga-invite-send-button`, styles.modalActionButton),
        },
        errorMessage: (
          <>
            {willBeOverLimitWhenAddMember && (
              <p>
                {t(
                  'The maximum number of members that can be invited under the current plan has been reached.',
                )}
                <br />
                <Link to="/settings/billing" onClick={closeModal}>
                  {t('Check the current plan.')}
                </Link>
              </p>
            )}
            {areNewMemberEmailsValid && duplicateEmailAddressesExist && (
              <p>
                {t(
                  'More than one of the same e-mail address has been entered.',
                )}
              </p>
            )}
          </>
        ),
      }}
    >
      {isLoading ? (
        <div className={styles.loading}>
          <img src="/images/icon_loading.svg" alt="" />
          {t('Inviting members...')}
        </div>
      ) : (
        <div className={styles.fieldGroup}>
          <Fieldset label={t('Email Address')} required>
            <div className={styles.memberInputs}>
              {newMembers.map((_, i) => {
                return (
                  <MemberInput
                    key={i}
                    email={newMembers[i].email || ''}
                    errorMessage={newMembers[i].errorMessage}
                    placeholder={t('Enter your email address')}
                    isDeleteButtonVisible={isDeleteMemberInputButtonVisible}
                    onChange={(email) => {
                      setNewMemberAt(email, i);
                    }}
                    onDelete={() => {
                      deleteNewMemberAt(i);
                    }}
                  />
                );
              })}
            </div>
            {isAddMemberInputVisible && (
              <IconWithTextButton
                text={t('Add')}
                icon="add"
                onClick={addMemberInput}
                outlined={false}
                className={styles.addMemberInputButton}
              />
            )}
            {willBeChargedWhenAddMember && (
              <Notification
                status="warning"
                text={
                  <span className={styles.notification}>
                    {t(
                      'For each additional member in excess of the maximum number of members in the plan, A monthly fee of {{price}} yen (tax excluded) will be charged.',
                      {
                        price:
                          currentPlan &&
                          currentPlan.limit.memberCountOveragePrice,
                      },
                    )}
                    <br />
                    <Link to="/settings/billing" onClick={closeModal}>
                      {t('Click here for plan details')}
                    </Link>
                  </span>
                }
              />
            )}
          </Fieldset>

          <RolesSettingField
            myRoles={myRoles}
            allRoles={roles}
            selectedRoleIds={roleIds}
            addRoleId={addRoleId}
            removeRoleId={removeRoleId}
          />
        </div>
      )}
    </ModalContent>
  );
};

export default InviteMemberModal;
