import { useCallback } from 'react';

import type { Props } from './Clear';

export const useClear = ({ editor }: Props) => {
  const setClearHandler = useCallback(() => {
    editor.chain().focus().unsetAllMarks().clearNodes().run();
  }, [editor]);

  return {
    setClearHandler,
  };
};
