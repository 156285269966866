import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from './_types';
import type { Role } from '@/entity/role';

import { produce } from '@/lib/immer';

type SetReviewRequestPermissionCreateAction = FormRoleInputValueAction<
  'set-reviewrequest-permission-create',
  Role['reviewRequestPermission']['create']
>;
type SetReviewRequestPermissionReadAction = FormRoleInputValueAction<
  'set-reviewrequest-permission-read',
  Role['reviewRequestPermission']['readLevel']
>;
type SetReviewRequestPermissionUpdateAction = FormRoleInputValueAction<
  'set-reviewrequest-permission-update',
  Role['reviewRequestPermission']['updateLevel']
>;
type ReviewRequestPermissionAction =
  | SetReviewRequestPermissionCreateAction
  | SetReviewRequestPermissionReadAction
  | SetReviewRequestPermissionUpdateAction;

/**
 * ReviewRequestPermissionのcreateフィールドを更新する
 */
const setReviewRequestPermissionCreate: FormRoleInputValueDispatchFn<
  SetReviewRequestPermissionCreateAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.reviewRequestPermission.create = payload;
  });
};

/**
 * ReviewRequestPermissionのreadLevelフィールドを更新する
 */
const setReviewRequestPermissionRead: FormRoleInputValueDispatchFn<
  SetReviewRequestPermissionReadAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.reviewRequestPermission.readLevel = payload;
  });
};

/**
 * ReviewRequestPermissionのupdateLevelフィールドを更新する
 */
const setReviewRequestPermissionUpdate: FormRoleInputValueDispatchFn<
  SetReviewRequestPermissionUpdateAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.reviewRequestPermission.updateLevel = payload;
  });
};

export {
  type ReviewRequestPermissionAction,
  setReviewRequestPermissionCreate,
  setReviewRequestPermissionRead,
  setReviewRequestPermissionUpdate,
};
