import { useCallback, useEffect, useState } from 'react';

import { getIframelyResult } from '@/usecase/iframelyUsecase';

import {
  convertInputOptions,
  convertOptionsToQuery,
} from '../../shared/convertEmbedOptions';
import { useEmbedOptionsDebounce } from '../../shared/useEmbedOptionsDebounce';
import { useKeyEventBlock } from '../../shared/useKeyEventBlock';

import type {
  IframelyResponse,
  EmbedResult,
  OptionsField,
} from '@/entity/iframely';
import type { NodeViewProps } from '@tiptap/core';

import { validateUrl } from '@/views/Common/validations';

export type Args = IframelyResponse & {
  editor: NodeViewProps['editor'];
  currentPosition: number;
};

export const useEmbedBlock = ({
  url,
  html,
  options,
  editor,
  currentPosition,
}: Args) => {
  const [embedUrl, setEmbedUrl] = useState(url);
  const [embedUrlError, setEmbedUrlError] = useState<string | undefined>(
    undefined,
  );

  const onChangeEmbedUrl = useCallback((url: string) => {
    setEmbedUrl(url);
    setEmbedUrlError(validateUrl(url));
    setEmbedResult((prev) => {
      return { ...prev, isSuccess: false };
    });
  }, []);

  const [inputOptions, setInputOptions] = useState<
    Record<string, OptionsField['value']> | undefined
  >(convertInputOptions(options));
  const onChangeInputOptions = useCallback(
    (value: OptionsField['value'], key: string) => {
      setInputOptions((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
      setEmbedResult((prev) => {
        return { ...prev, isSuccess: true };
      });
    },
    [],
  );

  const [embedResult, setEmbedResult] = useState<EmbedResult>({
    data: {
      url,
      html,
      options,
    },
    isLoading: false,
    isSuccess: false,
  });

  const { isDebounceDone } = useEmbedOptionsDebounce({ inputOptions });

  useEffect(() => {
    const fetchIframely = async () => {
      const { optionQuery } = convertOptionsToQuery(inputOptions);

      const result = await getIframelyResult({ url: embedUrl, optionQuery });
      if (!result) {
        return;
      }

      setEmbedResult({ data: result, isLoading: false, isSuccess: true });
    };

    if (!embedResult.isSuccess) {
      return;
    }

    if (isDebounceDone) {
      fetchIframely();
    }
  }, [
    embedResult.isSuccess,
    embedUrl,
    embedUrlError,
    inputOptions,
    isDebounceDone,
    options,
  ]);

  const [isEmbedModal, toggleEmbedModal] = useState<boolean>(false);

  const { blockRef, isCurrentActiveBlock, setIsButtonFocus } = useKeyEventBlock(
    {
      editor,
      isActive: editor.isActive('embedBlock'),
      currentPosition,
      isOpen: isEmbedModal,
    },
  );
  const onToggleEmbedModal = useCallback(
    (open: boolean) => {
      toggleEmbedModal(open);
      if (!open) {
        setEmbedUrl(url);
        setEmbedUrlError(undefined);
        setEmbedResult((prev) => {
          return { ...prev, isLoading: false, isSuccess: false };
        });
        setInputOptions(undefined);
        setIsButtonFocus(true);
      }
    },
    [setIsButtonFocus, url],
  );

  const onClickPreviewEmbed = useCallback(async () => {
    setEmbedResult({ data: null, isLoading: true, isSuccess: false });
    if (!embedUrl) {
      setEmbedUrlError(validateUrl(embedUrl));
      return;
    }
    const { optionQuery } = convertOptionsToQuery(inputOptions);

    const result = await getIframelyResult({ url: embedUrl, optionQuery });

    if (!result) {
      setEmbedResult({ data: null, isLoading: false, isSuccess: false });
      return;
    }

    setEmbedResult({ data: result, isLoading: false, isSuccess: true });
  }, [embedUrl, inputOptions]);

  const onUpdate = useCallback(() => {
    if (!embedResult.data) {
      return;
    }
    editor.chain().focus().updateEmbed(embedResult.data).run();
    toggleEmbedModal(false);
  }, [editor, embedResult.data]);

  const onDelete = useCallback(() => {
    editor.chain().focus().deleteSelection().run();
  }, [editor]);

  return {
    isEmbedModal,
    embedUrl,
    embedUrlError,
    onChangeEmbedUrl,
    onToggleEmbedModal,
    inputOptions,
    onChangeInputOptions,
    embedResult,
    onClickPreviewEmbed,
    onUpdate,
    onDelete,
    blockRef,
    isCurrentActiveBlock,
  };
};
