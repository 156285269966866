import { Td } from '@/components/ui/Table';

import { FIELD_NO_VALUE } from '../constants';
import { useI18nFormRole } from '../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../provider';

import { Checkbox } from '@/views/Common/Ui/Checkbox';

const FieldEnvironment = () => {
  const { t } = useI18nFormRole();

  const {
    isAdminRole,
    input: { environmentPermission },
  } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  return (
    <>
      <Td>{t('Environment')}</Td>
      <Td>
        <Checkbox
          checked={environmentPermission.read}
          disabled={disabled}
          name="environmentPermission.read"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-environment-permission-read',
              payload,
            });
          }}
          label={t('Access to environments')}
        />
      </Td>
      <Td>
        <Checkbox
          checked={environmentPermission.create}
          disabled={disabled}
          name="environmentPermission.create"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-environment-permission-create',
              payload,
            });
          }}
          label={t('Create environments')}
        />
      </Td>
      <Td>{FIELD_NO_VALUE}</Td>
      <Td>
        <Checkbox
          checked={environmentPermission.delete}
          disabled={disabled}
          name="environmentPermission.delete"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-environment-permission-delete',
              payload,
            });
          }}
          label={t('Delete environments')}
        />
      </Td>
    </>
  );
};

export default FieldEnvironment;
