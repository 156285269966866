import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useBold } from './useBold';
import { IconButton } from '../../../IconButton';
import styles from '../../../richeditorv2.module.css';

import type { Editor } from '@tiptap/react';

import { BoldIcon } from '@/views/Common/Icons';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  toggleBoldHandler: () => void;
  isActiveBold: boolean;
  editable: boolean;
};

export const BoldView: React.FC<ViewProps> = ({
  toggleBoldHandler,
  isActiveBold,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <IconButton
      icon={
        <BoldIcon
          className={cx(styles.icon, {
            [styles.isActive]: isActiveBold,
          })}
        />
      }
      aria-label={t('Bold')}
      isActive={isActiveBold}
      onClick={toggleBoldHandler}
      disabled={!editable}
    />
  );
};

export const Bold: React.FC<Props> = ({ editor }) => {
  const { toggleBoldHandler, isActiveBold } = useBold({ editor });

  return (
    <BoldView
      toggleBoldHandler={toggleBoldHandler}
      isActiveBold={isActiveBold}
      editable={editor.isEditable}
    />
  );
};
