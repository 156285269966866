import cx from 'classnames';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import IconButton from '../IconButton';

import type { Accept } from 'react-dropzone';

import styles from './dropArea.module.css';

type Props = {
  className?: string;
  targetFiles: File[];
  onDrop: (acceptedFiles: File[]) => void;
  removeFile?: () => void;
  accept?: Accept;
};

const DropArea: React.FC<Props> = ({
  className,
  targetFiles,
  onDrop,
  removeFile,
  accept,
}) => {
  const { t } = useTranslation('dropArea');
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    accept,
    noClick: true,
    maxFiles: 1,
  });

  const files = targetFiles?.map((file: File, index) => (
    <div key={index} className={styles.selectedFileName}>
      <img
        className={styles.fileIcon}
        width={18}
        height={18}
        src="/images/icon_file_droparea.svg"
        alt=""
      />
      <span className={styles.fileName}>{file.name}</span>
      {/* 複数のファイルを扱えるようにする場合、個別のファイル削除に対応する場合追加実装が必要 */}
      {removeFile && (
        <IconButton onClick={removeFile} icon="delete" outlined={true} />
      )}
    </div>
  ));

  return (
    <div className={className}>
      {targetFiles.length === 0 ? (
        <div
          className={cx(styles.droparea, { [styles.drag]: isDragActive })}
          {...getRootProps()}
        >
          <img
            className={cx(styles.img, { [styles.drag]: isDragActive })}
            width={78}
            height={52}
            src={
              isDragActive
                ? '/images/icon_droparea_active.svg'
                : '/images/icon_droparea.svg'
            }
            alt=""
          />
          <p className={styles.description}>{t('Drag and drop a ﬁle here')}</p>
          <span>or</span>
          <div className={styles.selectButton}>
            <input {...getInputProps()} />
            <Button type="tertiary" value={t('Select file')} onClick={open} />
          </div>
        </div>
      ) : (
        <div className={styles.selectedFile}>
          <h3 className={styles.selectedFileTitle}>{t('Selected file')}</h3>
          {files}
        </div>
      )}
    </div>
  );
};

export default DropArea;
