import type { Role } from '@/entity/role';

/**
 * Admin権限のRoleである場合はtrueを返す
 */
function isAdminRole<T extends Pick<Role, 'isAdmin'>>(role: T): boolean {
  return role.isAdmin;
}

/**
 * 引数のRole群のうちに、Admin権限を持つRoleが存在するかどうかを判定する
 */
function hasAdminPermission<T extends Pick<Role, 'isAdmin'>>(
  roles: Array<T> | undefined,
): boolean {
  if (!roles) {
    return false;
  }
  return roles.some(isAdminRole);
}

export { isAdminRole, hasAdminPermission };
