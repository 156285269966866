import type React from 'react';

import styles from './sortButton.module.css';

type Direction = 'asc' | 'desc' | 'none';

type Props = {
  children: React.ReactNode;
  name: string;
  direction: Direction;
  onClick: (name: string, previousDirection: Direction) => void;
  disabled?: boolean;
};

const SortButtonTs: React.FC<Props> = ({
  children,
  name,
  direction = 'none',
  onClick,
  disabled = false,
}) => {
  return (
    <button
      className={
        direction === 'none'
          ? styles.button
          : `${styles.button} ${styles[direction]}`
      }
      onClick={() => onClick(name, direction)}
      disabled={disabled}
    >
      {children}
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={
            direction === 'asc' ? `${styles.asc} ${styles.on}` : styles.asc
          }
          d="M8 5H0L4 0L8 5Z"
        />
        <path
          className={
            direction === 'desc' ? `${styles.desc} ${styles.on}` : styles.desc
          }
          d="M8 9H0L4 14L8 9Z"
        />
      </svg>
    </button>
  );
};

export default SortButtonTs;
export type { Direction };
