import type React from 'react';
import { useState, useMemo, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  useHandleSortButtonClick,
  getSortDirection,
} from '@/components/Form/SortButton/useSortButtonTs';

import { useMembers } from '@/hooks/Member/useMembers';
import { useAdmin } from '@/hooks/Role/useMyRoles';
import { useRoleList } from '@/hooks/Role/useRoles';
import { useQueryParam } from '@/hooks/useQueryParams';
import { useGetMyService } from '@/hooks/useService';

import { useInviteMembers } from '../../hooks/Member/useMembers';
import CheckRoles from '../CheckRoles';
import SortButton from '../Form/SortButton/SortButtonTs';
import Head from '../Head';
import InviteMemberModal from '../InviteMemberModal';
import Button from '../ui/Button';
import Searchfield from '../ui/Searchfield';
import { Table, Thead, Tbody, Tr, Th, Td } from '../ui/Table';
import User from '../User';

import type { Member } from '../InviteMemberModal/InviteMemberModal';

import styles from './memberList.module.css';

import { isInvitingMember } from '@/util/member';
import { Modal, ModalTrigger } from '@/views/Common/Ui/Modal';
import { useToggle } from '@/views/Common/Ui/useToggle';
import { useSAMLQuery } from '@/views/ServiceSettings/SAMLSettings/useSAMLQuery';

const MemberList: React.FC = () => {
  const { service } = useGetMyService();
  const history = useHistory();

  const { t } = useTranslation('memberList');

  const order = useQueryParam('order', 'default');

  const {
    serviceUsers: members,
    filteredUsers,
    searchUsers,
    isLoading,
  } = useMembers(service, order);
  const [isAdmin] = useAdmin();

  const displayUsers = useMemo(() => {
    if (!members) {
      return [];
    }
    return filteredUsers || members;
  }, [filteredUsers, members]);

  const [roles] = useRoleList(service && service.partitionKey);
  const { useFindSAMLConfiguration } = useSAMLQuery(
    service?.partitionKey || '',
  );
  const { data: samlConfiguration } = useFindSAMLConfiguration();

  const handleSortButtonClick = useHandleSortButtonClick('default');

  /**
   * ロールのIDをキーにしたロールの名前のマップ
   */
  const roleSet: Record<string, string> = useMemo(() => {
    if (roles === undefined || roles === null) {
      return {};
    }
    return roles.reduce((a, b) => {
      return {
        ...a,
        [b.roleId]: b.name,
      };
    }, {});
  }, [roles]);

  const [isOpenInviteMemberModal, toggleInviteMemberModal] = useToggle(false);
  const [newMembers, setNewMembers] = useState<Member[]>([
    { email: undefined, errorMessage: null },
  ]);
  const [inviteMembers, inviteMembersLoading] = useInviteMembers(
    service && service.partitionKey,
  );

  if (
    displayUsers === undefined ||
    roles === undefined ||
    roles === null ||
    !samlConfiguration
  ) {
    return null;
  }

  const createSortableHeader = (sortName: string, element: string) => {
    return (
      <Th>
        <SortButton
          name={sortName}
          direction={getSortDirection(order, sortName)}
          onClick={handleSortButtonClick}
        >
          {element}
        </SortButton>
      </Th>
    );
  };

  return (
    <div>
      <Head title={t('Members')} />
      <header className={styles.header}>
        <Searchfield
          onChange={(e) => searchUsers(e.target.value)}
          className={styles.searchfield}
        />
        {samlConfiguration.samlOnly ? (
          <></>
        ) : (
          <CheckRoles permission="memberPermission" operation="create">
            <Modal
              open={isOpenInviteMemberModal}
              onOpenChange={toggleInviteMemberModal}
            >
              <ModalTrigger asChild>
                <Button
                  type="secondary"
                  value={t('Add')}
                  // @ts-expect-error
                  disabled={inviteMembersLoading}
                  icon="add"
                />
              </ModalTrigger>
              <InviteMemberModal
                members={members}
                newMembers={newMembers}
                setNewMembers={setNewMembers}
                inviteMembers={inviteMembers}
                // @ts-expect-error
                isLoading={inviteMembersLoading}
                closeModal={() => toggleInviteMemberModal(false)}
              />
            </Modal>
          </CheckRoles>
        )}
      </header>
      <main className={styles.main}>
        <div className={styles.tableWrap}>
          {isLoading && (
            <div className={styles.loading}>
              <img src="/images/icon_loading.svg" alt="" />
            </div>
          )}
          {!isLoading && (
            <Table>
              <Thead>
                <Tr>
                  {createSortableHeader('default', t('Member'))}
                  {createSortableHeader('email', t('Email Address'))}
                  {createSortableHeader('roles', t('Role'))}
                  {isAdmin &&
                    createSortableHeader(
                      'twofactor',
                      t('Two-Factor authentication'),
                    )}
                </Tr>
              </Thead>
              <Tbody>
                {displayUsers.map((member) => {
                  const isInvitingUser = isInvitingMember(member);
                  return (
                    <Tr
                      key={member.username}
                      onClick={() =>
                        history.push(
                          isInvitingUser
                            ? `/members/invitation?id=${encodeURIComponent(
                                member.username,
                              )}`
                            : `/members/${member.username}`,
                        )
                      }
                    >
                      <Td>
                        <div className={styles.username}>
                          <User
                            user={member}
                            theme="black"
                            showAllEmail={isInvitingUser}
                            showProfileImage={!isInvitingUser}
                          />
                          {isInvitingUser && (
                            <div className={styles.inviLabel}>
                              <span className="material-icons-outlined">
                                assignment
                              </span>
                              <p>{t('Pending invitation')}</p>
                            </div>
                          )}
                        </div>
                      </Td>
                      <Td>{member.email}</Td>
                      <Td>
                        {roles.length > 0
                          ? member.roleIds.map((r) => (
                              <Fragment key={r}>
                                {roleSet[r] && (
                                  <span className={styles.role}>
                                    {roleSet[r]}
                                  </span>
                                )}
                              </Fragment>
                            ))
                          : t('{{count}} roles', {
                              count: member.roleIds.length,
                            })}
                      </Td>
                      {isAdmin && (
                        <Td>
                          {member.mfaSetting ? (
                            <i className="material-icons">check</i>
                          ) : (
                            '-'
                          )}
                        </Td>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
          {!isLoading && displayUsers.length === 0 && (
            <div className={styles.empty}>{t('No matching members')}</div>
          )}
        </div>
      </main>
    </div>
  );
};

export default MemberList;
