import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { updateContentCreatedBy } from '@/usecase/contentCreatedByUseCase';
import { getAllMembers } from '@/usecase/memberUsecase';
import { getAllRoles } from '@/usecase/rolesUseCase';

import { isOpenAPIResponseError } from '@/lib/openapi';
import { useApi } from '@/lib/useApi';
import { isReactQueryError } from '@/util/type-guard';

export const useUpdateContentCreatedByModalQuery = (serviceId: string) => {
  const { addToast } = useToasts();

  const { t } = useTranslation('updateCreatedByModal');

  /**
   * @deprecated
   * 代わりに views/Common/member/useMemberReader.ts の useAllMembers を使ってください
   */
  const useAllMembers = () => {
    return useApi(
      ['serviceUsers', serviceId],
      async (serviceId) => await getAllMembers(serviceId),
      {
        staleTime: Number.POSITIVE_INFINITY,
        onSuccess: () => {
          // noop
        },
        onError: (error) => {
          if (isReactQueryError(error)) {
            return {
              message: `${error.status}:${error.message}`,
            };
          }
          if (error instanceof Error) {
            return {
              message: error.message,
            };
          }
          return {
            message: 'Something went wrong...',
          };
        },
      },
    );
  };

  /**
   *
   * @deprecated
   * 代わりに views/Common/roles/useRolesReader.ts の useAllRoles を使ってください
   */
  const useAllRoles = () => {
    return useApi(
      ['serviceRoles', serviceId],
      async (serviceId) => await getAllRoles(serviceId),
      {
        staleTime: Number.POSITIVE_INFINITY,
        onSuccess: () => {
          // noop
        },
        onError: (error) => {
          if (isReactQueryError(error)) {
            return {
              message: `${error.status}:${error.message}`,
            };
          }
          if (error instanceof Error) {
            return {
              message: error.message,
            };
          }
          return {
            message: 'Something went wrong...',
          };
        },
      },
    );
  };

  const useUpdateContentCreatedBy = (endpoint: string, contentId: string) => {
    return useMutation({
      mutationFn: async (createdBy: string) => {
        await updateContentCreatedBy({
          endpoint,
          contentId,
          createdBy,
        });
      },
      onSuccess: () => {
        addToast(t('The author of the content has been changed'), {
          appearance: 'success',
        });
      },
      onError: (error) => {
        if (isOpenAPIResponseError(error) && error.isForbidden()) {
          addToast(
            t('You do not have permission to change the author of the content'),
            {
              appearance: 'error',
            },
          );
        } else {
          addToast(t('Failed to change the author of the content'), {
            appearance: 'error',
          });
        }
      },
    });
  };

  return {
    useAllMembers,
    useAllRoles,
    useUpdateContentCreatedBy,
  };
};
