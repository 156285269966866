import ExtensionCodeBlock from '@tiptap/extension-code-block';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { CodeBlock, CodeBlockView } from './CodeBlock';

import type { CodeBlockLowlightOptions } from '@tiptap/extension-code-block-lowlight';

export type CodeBlockAttributes = {
  language: string | null;
  fileName: string | null;
};

type CustomCodeBlockOptions = CodeBlockLowlightOptions & {
  inputRules: boolean;
};

export { CodeBlock, CodeBlockView };

// NOTE:code-block https://github.com/ueberdosis/tiptap/blob/main/packages/extension-code-block/src/code-block.ts
export const CustomCodeBlock =
  ExtensionCodeBlock.extend<CustomCodeBlockOptions>({
    addOptions() {
      return {
        ...this.parent?.(),
        inputRules: true,
      };
    },

    parseHTML() {
      return this.options.inputRules ? this.parent?.() : [];
    },

    addAttributes() {
      return {
        ...this.parent?.(),
        fileName: null,
      };
    },

    addCommands() {
      return {
        toggleCodeBlock:
          (attributes) =>
          ({ commands }) => {
            const { $anchor, from, to } = this.editor.state.selection;
            commands.selectParentNode();

            const textArray: string[] = [];
            $anchor.parent.content.forEach((content) => {
              if (content.type.name === 'hardBreak') {
                return textArray.push('\n');
              }
              if (!content.text) {
                return;
              }
              return textArray.push(content.text);
            });

            if (!textArray.includes('\n')) {
              return commands.toggleNode(this.name, 'paragraph', attributes);
            }

            commands.deleteRange({ from, to });

            return commands.insertContent({
              type: 'doc',
              attrs: {
                attributes,
              },
              content: [
                {
                  type: this.type.name,
                  content: [
                    {
                      type: 'text',
                      text: textArray.join(''),
                    },
                  ],
                },
              ],
            });
          },
      };
    },

    addKeyboardShortcuts() {
      return this.options.inputRules ? { ...this.parent?.() } : {};
    },

    addInputRules() {
      return this.options.inputRules ? this.parent?.() || [] : [];
    },

    addNodeView() {
      return ReactNodeViewRenderer(CodeBlock);
    },
  });
