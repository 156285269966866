import type { MigrateApi } from '@/entity/api';
import type React from 'react';
import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { CustomField } from '../../../types/contents';
import Button from '../../ui/Button';
import Selectfield from '../../ui/Selectfield';
import styles from '../apiModel.module.css';

type SelectCustomFieldProps = {
  customFields: CustomField[];
  createdAt?: string;
  setCreatedAt: (value: string) => void;
};

export const SelectCustomField: React.FC<SelectCustomFieldProps> = ({
  customFields = [],
  createdAt,
  setCreatedAt,
}) => {
  const { t } = useTranslation('selectCustomField');
  if (customFields.length === 0) {
    return (
      <p className={styles.errorText}>
        {t('A Custom field has not been created.')}
      </p>
    );
  }
  return (
    <Selectfield
      size="full"
      onChange={(e) => setCreatedAt(e.target.value)}
      value={createdAt}
    >
      {customFields.map((field, i) => {
        return (
          <option key={i} value={field.createdAt}>
            {field.name} ({field.fieldId})
          </option>
        );
      })}
    </Selectfield>
  );
};

type SelectCustomFieldInModalProps = {
  api: MigrateApi;
  customFieldCreatedAt?: string;
  setCustom: (createdAt?: string) => void;
  closeCustomModal: () => void;
  customFields: CustomField[];
};

export const SelectCustomFieldInModal: React.FC<
  SelectCustomFieldInModalProps
> = ({
  api,
  customFieldCreatedAt,
  setCustom,
  closeCustomModal,
  customFields = [],
}) => {
  const { t } = useTranslation('selectCustomField');

  const [createdAt, setCreatedAt] = useState(
    customFieldCreatedAt ||
      (customFields.length > 0 ? customFields[0].createdAt : undefined),
  );

  const set = useCallback(() => {
    setCustom(createdAt);
  }, [createdAt, setCustom]);
  return (
    <div>
      {customFields.length > 0 ? (
        <div>
          <p className={styles.modalDescription}>
            {t('Select Custom fields you wish to use.')}
          </p>
          <SelectCustomField
            customFields={customFields}
            createdAt={createdAt}
            setCreatedAt={setCreatedAt}
          ></SelectCustomField>

          <div className={styles.modalActionButtonWrapper}>
            <Button
              type="secondary"
              value={t('Confirm')}
              onClick={set}
              className={styles.modalActionButton}
            />
          </div>
        </div>
      ) : (
        <div className={styles.modalAction}>
          <p className={styles.modalDescription}>
            {t('A Custom field has not been created.')}
            <br />
            {Trans({
              t,
              i18nKey: 'Create it here.',
              children: (
                <Link to={`/apis/${api.apiEndpoint}/custom-fields/create`}>
                  here
                </Link>
              ),
            })}
          </p>

          <Button
            type="secondary"
            value={t('Close')}
            onClick={closeCustomModal}
            className={styles.modalActionButton}
          />
        </div>
      )}
    </div>
  );
};
