import { connect } from 'react-redux';

import { authOperations, authSelectors } from '../../ducks/auth';
import Signin from './Signin';

const mapStateToProps = (state: any) => {
  return authSelectors.getSigninState(state.authState);
};

const mapDispatchToProps = {
  confirmSignup: authOperations.confirmSignup,
  reset: authOperations.resetAuth,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
