import { API, graphqlOperation } from 'aws-amplify';

import * as queries from '../../graphql/queries';

import type { ContentEditor } from '@/entity/contentEditor';

import { useApi } from '@/lib/useApi';

type ContentCreators = {
  list?: ContentEditor[] | undefined;
};

export const useContentCreators = (apiId: string): ContentCreators => {
  const query = useApi(['contentCreators', { apiId }], async () => {
    const response = (await API.graphql(
      graphqlOperation(queries.findContentCreators, { apiId }),
    )) as { data: { findContentCreators: ContentEditor[] } };

    return response.data.findContentCreators;
  });

  const { data: creators } = query;

  return { list: creators };
};

export const useContentEditors = (apiId: string): ContentCreators => {
  const query = useApi(['contentEditors', { apiId }], async () => {
    const response = (await API.graphql(
      graphqlOperation(queries.findContentEditors, { apiId }),
    )) as { data: { findContentEditors: ContentEditor[] } };

    return response.data.findContentEditors;
  });

  const { data: creators } = query;

  return { list: creators };
};
