import { SketchPicker } from 'react-color';
import type { Color, ColorResult } from 'react-color';

import styles from './colorPicker.module.css';

type Props = {
  color?: Color;
  colorItemId: string;
  handleChangeColor: (id: string, value: ColorResult) => void;
};

export const ColorPicker: React.FC<Props> = ({
  color,
  handleChangeColor,
  colorItemId,
}) => {
  return (
    <div className={styles.main}>
      <SketchPicker
        className={styles.colorPicker}
        color={color}
        width={'100%'}
        onChange={(color) => handleChangeColor(colorItemId, color)}
        styles={{
          default: {
            picker: {
              boxShadow: 'none',
              padding: '0',
            },
            saturation: {
              borderRadius: '4px',
            },
          },
        }}
        presetColors={[]}
      />
    </div>
  );
};
