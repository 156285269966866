import * as Popover from '@radix-ui/react-popover';
import { useTranslation } from 'react-i18next';

import { SearchRoles } from './SearchRoles/SearchRoles';
import { useRolesSettingField } from './useRolesSettingField';

import type { Role } from '@/entity/role';

import styles from './rolessettingfield.module.css';

import { Fieldset } from '@/views/Common/Ui/Fieldset';
import { Icon } from '@/views/Common/Ui/Icon';

export type Props = {
  myRoles?: Role[];
  allRoles?: Role[];
  selectedRoleIds: string[];
  addRoleId: (roleId: string) => void;
  removeRoleId: (roleId: string) => void;
};

export type ViewProps = {
  selectedRoleIds: string[];
  addRoleId: (roleId: string) => void;
  removeRoleId: (roleId: string) => void;
  isOpenSearchRoles: boolean;
  toggleSearchRoles: (value?: boolean) => void;
  selectableRoles: Role[] | null;
  selectedRoleNames: { name: string; id: string }[];
};

export const RolesSettingFieldView: React.FC<ViewProps> = ({
  selectedRoleIds,
  addRoleId,
  removeRoleId,
  isOpenSearchRoles,
  toggleSearchRoles,
  selectableRoles,
  selectedRoleNames,
}) => {
  const { t } = useTranslation('membersInviteMemberRolesSettingField');

  return (
    <Fieldset
      label={t('Role')}
      required
      help="https://document.microcms.io/manual/roles"
    >
      {selectableRoles && selectableRoles.length > 0 ? (
        <div className={styles.main}>
          {/* TODO:Escキーでモーダル全体が閉じてしまう問題がある。このPopoverのみを閉じられるように修正が必要。 */}
          <Popover.Root
            open={isOpenSearchRoles}
            onOpenChange={toggleSearchRoles}
          >
            <Popover.Trigger className={styles.trigger}>
              <Icon name="settings" />
              <span>{t('Select')}</span>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content
                avoidCollisions={false}
                updatePositionStrategy="always"
                style={{ zIndex: 100, pointerEvents: 'auto' }}
              >
                <SearchRoles
                  selectableRoles={selectableRoles}
                  selectedRoleIds={selectedRoleIds}
                  addRoleId={addRoleId}
                  removeRoleId={removeRoleId}
                  closeSearchRoles={() => toggleSearchRoles(false)}
                />
              </Popover.Content>
            </Popover.Portal>
            {/* ロール検索UIが出る場所をアイコンボタンではなくロール表示部分にするためAnchorをおく */}
            <Popover.Anchor />
          </Popover.Root>
          {selectedRoleNames.length > 0 ? (
            <ul className={styles.roleList}>
              {selectedRoleNames.map(({ name, id }) => (
                <li key={id} className={styles.roleItem}>
                  <span>{name}</span>
                  <button
                    onClick={() => removeRoleId(id)}
                    aria-label={t('Remove')}
                    className={styles.roleItemRemoveButton}
                  >
                    <Icon name="close" />
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p className={styles.notSelected}>{t('Roles not selected.')}</p>
          )}
        </div>
      ) : (
        <p className={styles.notPermission}>
          {t(
            'Unable to display due to lack of authorization. Please check with the administrator.',
          )}
        </p>
      )}
    </Fieldset>
  );
};

export const RolesSettingField: React.FC<Props> = (props) => {
  const hooks = useRolesSettingField(props);

  return <RolesSettingFieldView {...hooks} {...props} />;
};
