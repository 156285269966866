import { useTranslation } from 'react-i18next';

import Button from '@/components/ui/Button';
import Modal from '@/components/ui/Modal';
import Textfield from '@/components/ui/Textfield';

import { useUpdateEnvironmentNameModal } from './useUpdateEnvironmentNameModal';

import style from './updateenvironmentnamemodal.module.css';

export type Props = {
  serviceId: string;
  name: string;
  closeEnvNameModal: () => void;
};

export type ViewProps = {
  inputEnvName: string;
  onChangeEnvName: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  inputEnvNameError: string | null;
  onClickUpdateEnvName: () => void;
  updateEnvNameLoading: boolean;
};

export const UpdateEnvironmentNameModalView: React.FC<ViewProps> = ({
  inputEnvName,
  onChangeEnvName,
  inputEnvNameError,
  onClickUpdateEnvName,
  updateEnvNameLoading,
}) => {
  const { t } = useTranslation('updateEnvironmentNameModal');
  return (
    <Modal
      type="small"
      title={t('Edit Environment Name')}
      overflow={'none'}
      footer={
        <Button
          type="primary"
          value={t('Save changes')}
          size="small"
          disabled={inputEnvNameError !== null || updateEnvNameLoading}
          onClick={onClickUpdateEnvName}
        />
      }
    >
      <p className={style.modalDescription}>{t('Edit an environment name.')}</p>
      <Textfield
        value={inputEnvName}
        onChange={onChangeEnvName}
        errorText={inputEnvNameError}
      />
    </Modal>
  );
};

export const UpdateEnvironmentNameModal: React.FC<Props> = ({
  serviceId,
  name,
  closeEnvNameModal,
}) => {
  const {
    inputEnvName,
    onChangeEnvName,
    inputEnvNameError,
    onClickUpdateEnvName,
    updateEnvNameLoading,
  } = useUpdateEnvironmentNameModal({ serviceId, name, closeEnvNameModal });

  return (
    <UpdateEnvironmentNameModalView
      inputEnvName={inputEnvName}
      onChangeEnvName={onChangeEnvName}
      inputEnvNameError={inputEnvNameError}
      onClickUpdateEnvName={onClickUpdateEnvName}
      updateEnvNameLoading={updateEnvNameLoading}
    />
  );
};
