import cx from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Selectfield from '@/components/ui/Selectfield';

import type { CustomField } from '../../../types/contents';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import Switch from '../../ui/Switch';
import Textfield from '../../ui/Textfield';
import CustomFieldDisplayItem from '../CustomFieldDisplayItem';
import ImageSizeValidationField from '../ImageSizeValidationField';
import { InputIframeField } from '../InputIframeField';
import { InputRichEditorField } from '../InputRichEditorField';
import { InputSelectField } from '../InputSelectField';
import NumberSizeLimitValidationField from '../NumberSizeLimitValidationField';
import { OrderRepeaterField } from '../OrderRepeaterField';
import PatternMatchValidationField from '../PatternMatchValidationField';
import ReferenceContent from '../ReferenceContent';
import ReferenceDisplayItem from '../ReferenceDisplayItem';
import RelationListCountValidationField from '../RelationListCountValidationField';
import RepeaterCountValidationField from '../RepeaterCountValidationField';
import { SelectCustomField } from '../SelectCustomField';
import { SelectRepeaterField } from '../SelectRepeaterField';
import TextSizeLimitValidationField from '../TextSizeLimitValidationField';
import UniqueValidationField from '../UniqueValidationField';

import styles from './DetailFieldSettings.module.css';

import type { ApiListByRedux, MigrateApi } from '@/entity/api';
import { isMediaListLayout } from '@/entity/field';
import { InputCustomClassField } from '@/views/ApiModel/InputCustomClassField';
import { InputRichEditorV2ColorListField } from '@/views/ApiModel/InputRichEditorV2ColorListField';
import { InputRichEditorV2OptionsField } from '@/views/ApiModel/InputRichEditorV2OptionsField';
import { Fieldset as NewFieldset } from '@/views/Common/Ui/Fieldset';

type Props = {
  useInCustom?: boolean;
  field: any;
  setFieldAt: (field: (prev: any) => void, index: number) => void;
  index: number;
  deleteField: (index: number) => void;
  apiList: ApiListByRedux['apiList'];
  type: 'LIST' | 'PAGE';
  close: (enable?: boolean) => void;
  placeholder: {
    fieldId: string;
    name: string;
    placeholder: string;
  };
  customFields: CustomField[];
  setSelectField: (
    items: any,
    multiple: boolean,
    initialValue: string[],
  ) => void;
  setIframe: (value: any) => void;
  setCustom: (value: any) => void;
  setRepeater: (value: any) => void;
  onChangeRequired: (e: any) => void;
  onChangeReference: (e: any) => void;
  isEditApi: boolean;
};

const DetailFieldSettings: React.FC<Props> = ({
  useInCustom,
  field,
  setFieldAt,
  index,
  deleteField,
  apiList,
  type,
  close,
  placeholder,
  customFields,
  setSelectField,
  setIframe,
  setCustom,
  setRepeater,
  onChangeRequired,
  onChangeReference,
  isEditApi,
}) => {
  const { t } = useTranslation('detailFieldSettings');

  const {
    idValue,
    fieldId,
    name,
    kind,
    richEditorOptions,
    richEditorV2Options,
    richEditorV2ColorList,
    richEditorMultiParagraph = true,
    richEditorImageSize = false,
    customClassList,
    description,
    required = false,
    dateFormat = false,
    mediaListLayout = 'HORIZONTAL_SCROLL',
    booleanInitialValue,
    selectItems,
    selectInitialValue,
    multipleSelect = false,
    referenceKey,
    referenceDisplayItem,
    iframeUrl,
    customFieldCreatedAt,
    customFieldDisplayItem,
    customFieldCreatedAtList,
    patternMatchValidation,
    textSizeLimitValidation,
    numberSizeLimitValidation,
    imageSizeValidation,
    relationListCountLimitValidation,
    repeaterCountLimitValidation,
    isUnique = false,
    isAdditionalField,
  } = field;

  const [isInvalidPatternMatchValidation, setIsInvalidPatternMatchValidation] =
    useState(false);
  const [
    isInvalidTextSizeLimitValidation,
    setIsInvalidTextSizeLimitValidation,
  ] = useState(false);
  const [
    isInvalidNumberSizeLimitValidation,
    setIsInvalidNumberSizeLimitValidation,
  ] = useState(false);
  const [isInvalidImageSizeValidation, setIsInvalidImageSizeValidation] =
    useState(false);
  const [
    isInvalidRelationListCountLimitValidation,
    setIsInvalidRelationListCountLimitValidation,
  ] = useState(false);
  const [
    isInvalidRepeatCountLimitValidation,
    setIsInvalidRepeatCountLimitValidation,
  ] = useState(false);

  const [isSelectError, setIsSelectError] = useState(false);

  const hasDetailsFieldError = useMemo(
    () =>
      isInvalidPatternMatchValidation ||
      isInvalidTextSizeLimitValidation ||
      isInvalidNumberSizeLimitValidation ||
      isInvalidImageSizeValidation ||
      isInvalidRelationListCountLimitValidation ||
      isInvalidRepeatCountLimitValidation ||
      isSelectError,
    [
      isInvalidPatternMatchValidation,
      isInvalidTextSizeLimitValidation,
      isInvalidNumberSizeLimitValidation,
      isInvalidImageSizeValidation,
      isInvalidRelationListCountLimitValidation,
      isInvalidRepeatCountLimitValidation,
      isSelectError,
    ],
  );

  const setRichEditorOptions = useCallback(
    (value: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          kind: 'richEditor',
          richEditorOptions: value,
        }),
        index,
      );
    },
    [setFieldAt, index],
  );

  const setRichEditorV2Options = useCallback(
    (value: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          kind: 'richEditorV2',
          richEditorV2Options: value,
        }),
        index,
      );
    },
    [setFieldAt, index],
  );

  const setRichEditorV2ColorList = useCallback(
    (value: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          kind: 'richEditorV2',
          richEditorV2ColorList: value,
        }),
        index,
      );
    },
    [setFieldAt, index],
  );

  const setCustomClassList = useCallback(
    (value: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          customClassList: value,
        }),
        index,
      );
    },
    [setFieldAt, index],
  );

  const onChangeDescription = useCallback(
    (e: any) => {
      const description = e.target.value;
      setFieldAt(
        (prev) => ({
          ...prev,
          description,
        }),
        index,
      );
    },
    [setFieldAt, index],
  );

  const onChangeIsUnique = useCallback(
    (isUnique: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          isUnique,
        }),
        index,
      );
    },
    [setFieldAt, index],
  );

  const onChangeRichEditorMultiParagraph = useCallback(
    (richEditorMultiParagraph: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          richEditorMultiParagraph,
        }),
        index,
      );
    },
    [setFieldAt, index],
  );

  const onChangeRichEditorImageSize = useCallback(
    (richEditorImageSize: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          richEditorImageSize,
        }),
        index,
      );
    },
    [setFieldAt, index],
  );

  const onChangeDateFormat = useCallback(
    (dateFormat: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          dateFormat,
        }),
        index,
      );
    },
    [setFieldAt, index],
  );

  const onChangeBooleanInitialValue = useCallback(
    (e: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          booleanInitialValue: e.target.value === 'true' ? true : false,
        }),
        index,
      );
    },
    [setFieldAt, index],
  );

  const onChangeReferenceDisplayItem = useCallback(
    (e: any) => {
      const referenceDisplayItem = e.target.value;
      setFieldAt(
        (prev) => ({
          ...prev,
          referenceDisplayItem,
        }),
        index,
      );
    },
    [setFieldAt, index],
  );

  const onChangePatternMatchValidation = useCallback(
    (patternMatchValidation: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          patternMatchValidation,
        }),
        index,
      );
    },
    [index, setFieldAt],
  );

  const onChangeTextSizelimitValidation = useCallback(
    (textSizeLimitValidation: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          textSizeLimitValidation,
        }),
        index,
      );
    },
    [index, setFieldAt],
  );

  const onChangeNumberSizelimitValidation = useCallback(
    (numberSizeLimitValidation: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          numberSizeLimitValidation,
        }),
        index,
      );
    },
    [index, setFieldAt],
  );

  const onChangeImageSizeValidation = useCallback(
    (imageSizeValidation: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          imageSizeValidation,
        }),
        index,
      );
    },
    [index, setFieldAt],
  );

  const onChangeMediaListLayout = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;
      if (!isMediaListLayout(value)) {
        return;
      }
      setFieldAt(
        (prev) => ({
          ...prev,
          mediaListLayout: value,
        }),
        index,
      );
    },
    [index, setFieldAt],
  );

  const onChangeRelationListCountLimitValidation = useCallback(
    (relationListCountLimitValidation: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          relationListCountLimitValidation,
        }),
        index,
      );
    },
    [index, setFieldAt],
  );

  const onChangeCustomFieldDislayItem = useCallback(
    (customFieldDisplayItem: string) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          customFieldDisplayItem,
        }),
        index,
      );
    },
    [index, setFieldAt],
  );

  const onChangeRepeaterCountLimitValidation = useCallback(
    (repeaterCountLimitValidation: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          repeaterCountLimitValidation,
        }),
        index,
      );
    },
    [index, setFieldAt],
  );

  return (
    <div className={styles.main}>
      <dl className={styles.header}>
        <dt className={styles.headerName}>{name || '-'}</dt>
        <dd className={styles.headerId}>/ {fieldId || '-'}</dd>
      </dl>
      <div className={cx(styles.body, styles.scroll)}>
        {/* TODO: booleanの場合は常に値が入るので不要 */}
        <Fieldset
          legend={t('Required field')}
          description={t(
            'When this is ON, input is required at the time of submission.',
          )}
        >
          <Switch on={required} onChange={onChangeRequired} size="large" />
        </Fieldset>
        <Fieldset
          legend={t('Description')}
          description={t(
            'The description to be displayed on the submission page. Enter a description that is easy for the submitter to understand.',
          )}
        >
          <Textfield
            value={description || ''}
            placeholder={t('E.g., {{placeholder}}', {
              placeholder: placeholder.placeholder,
            })}
            onChange={onChangeDescription}
          />
        </Fieldset>
        {kind === 'text' && type === 'LIST' && useInCustom !== true && (
          <UniqueValidationField
            isUnique={isUnique}
            onChange={onChangeIsUnique}
          ></UniqueValidationField>
        )}
        {(kind === 'text' || kind === 'textArea') && (
          <PatternMatchValidationField
            patternMatchValidation={patternMatchValidation}
            idValue={idValue}
            onChange={onChangePatternMatchValidation}
            onError={(error) =>
              setIsInvalidPatternMatchValidation(error !== null)
            }
          ></PatternMatchValidationField>
        )}
        {(kind === 'text' || kind === 'textArea') && (
          <TextSizeLimitValidationField
            textSizeLimitValidation={textSizeLimitValidation}
            idValue={idValue}
            onChange={onChangeTextSizelimitValidation}
            onError={(error) =>
              setIsInvalidTextSizeLimitValidation(error !== null)
            }
          ></TextSizeLimitValidationField>
        )}
        {kind === 'number' && (
          <NumberSizeLimitValidationField
            numberSizeLimitValidation={numberSizeLimitValidation}
            idValue={idValue}
            onChange={onChangeNumberSizelimitValidation}
            onError={(error) =>
              setIsInvalidNumberSizeLimitValidation(error !== null)
            }
          ></NumberSizeLimitValidationField>
        )}
        {(kind === 'media' || kind === 'mediaList') && (
          <ImageSizeValidationField
            imageSizeValidation={imageSizeValidation}
            idValue={idValue}
            onChange={onChangeImageSizeValidation}
            onError={(error) =>
              setIsInvalidImageSizeValidation(error !== undefined)
            }
          ></ImageSizeValidationField>
        )}
        {kind === 'mediaList' && (
          <Fieldset
            legend={t('Layout')}
            description={t(
              'Customizable the layout on the administration screen.',
            )}
          >
            <Selectfield
              value={mediaListLayout}
              onChange={onChangeMediaListLayout}
            >
              <option value="HORIZONTAL_SCROLL">
                {t('Horizontal (Scroll)')}
              </option>
              <option value="GRID_2">{t('Horizontal (2 Columns)')}</option>
              <option value="GRID_3">{t('Horizontal (3 Columns)')}</option>
              <option value="GRID_4">{t('Horizontal (4 Columns)')}</option>
            </Selectfield>
          </Fieldset>
        )}
        {kind === 'date' && (
          <Fieldset
            legend={t('Specify Date Only')}
            description={t('When this is ON, the time cannot be specified.')}
          >
            <Switch
              on={dateFormat}
              onChange={onChangeDateFormat}
              size="large"
            />
          </Fieldset>
        )}
        {kind === 'boolean' && (
          <Fieldset
            legend={t('Initial Value')}
            description={t(
              'You can set the initial values that will be used for submissions.',
            )}
          >
            <Selectfield
              value={booleanInitialValue ? 'true' : 'false'}
              style={{ width: 'auto' }}
              onChange={onChangeBooleanInitialValue}
            >
              <option key="false" value="false">
                {t('Set initial value to false')}
              </option>
              <option key="true" value="true">
                {t('Set initial value to true')}
              </option>
            </Selectfield>
          </Fieldset>
        )}
        {kind === 'select' && (
          <Fieldset legend={t('Options')}>
            <InputSelectField
              items={selectItems ?? []}
              multiple={multipleSelect ?? false}
              initialValue={selectInitialValue ?? []}
              setError={setIsSelectError}
              setField={setSelectField}
              isAdditionalField={isAdditionalField}
            />
          </Fieldset>
        )}
        {(kind === 'relation' || kind === 'relationList') && (
          <>
            <Fieldset legend={t('Referenced Content')}>
              <ReferenceContent
                apiId={referenceKey}
                apiList={apiList}
                kind={kind}
                onChangeReference={onChangeReference}
                disabled={!isAdditionalField}
              />
            </Fieldset>
            <Fieldset
              legend={t('Items to be displayed on the list page')}
              description={t(
                'You can choose from a content ID or a TextField item. If the specified item does not exist, the content ID will be displayed instead.',
              )}
            >
              <ReferenceDisplayItem
                apiId={referenceKey}
                apiList={apiList}
                value={referenceDisplayItem}
                onChangeReferenceDisplayItem={onChangeReferenceDisplayItem}
              ></ReferenceDisplayItem>
            </Fieldset>
          </>
        )}
        {kind === 'relationList' && (
          <RelationListCountValidationField
            relationListCountLimitValidation={relationListCountLimitValidation}
            idValue={idValue}
            onChange={onChangeRelationListCountLimitValidation}
            onError={(error) =>
              setIsInvalidRelationListCountLimitValidation(error !== null)
            }
          ></RelationListCountValidationField>
        )}
        {kind === 'iframe' && (
          <Fieldset legend={t('Extended Field URL')}>
            <InputIframeField setUrl={setIframe} url={iframeUrl} />
            {/* @ts-ignore */}
            <Trans t={t} i18nKey="Detailed setup instructions is here.">
              Detailed setup instructions is{' '}
              <a
                href="https://document.microcms.io/manual/iframe-field"
                target="docs"
              >
                here
              </a>
            </Trans>
          </Fieldset>
        )}
        {kind === 'custom' && (
          <>
            <Fieldset legend={t('Custom Field')}>
              <SelectCustomField
                setCreatedAt={setCustom}
                createdAt={customFieldCreatedAt}
                customFields={customFields}
              />
            </Fieldset>
            <Fieldset
              legend={t('Items to be displayed on the list page')}
              description={t(
                'You can choose from the default, which is the number of fields with values, or a text field item. If the specified item does not exist, the default will be displayed.',
              )}
            >
              <CustomFieldDisplayItem
                customFieldCreatedAt={customFieldCreatedAt}
                customFields={customFields}
                value={customFieldDisplayItem}
                onChangeCustomFieldDisplayItem={onChangeCustomFieldDislayItem}
              />
            </Fieldset>
          </>
        )}
        {kind === 'repeater' && (
          <>
            <Fieldset legend={t('Repetitive Field')}>
              <SelectRepeaterField
                setList={setRepeater}
                list={customFieldCreatedAtList}
                customFields={customFields}
              />
            </Fieldset>
            <Fieldset legend={t('Sort Order')}>
              <OrderRepeaterField
                setList={setRepeater}
                list={customFieldCreatedAtList}
                customFields={customFields}
              />
            </Fieldset>
            <RepeaterCountValidationField
              repeaterCountLimitValidation={repeaterCountLimitValidation}
              idValue={idValue}
              onChange={onChangeRepeaterCountLimitValidation}
              onError={(error) =>
                setIsInvalidRepeatCountLimitValidation(error !== null)
              }
            ></RepeaterCountValidationField>
          </>
        )}
        {kind === 'richEditor' && (
          <>
            <Fieldset
              legend={t('Decorative Button')}
              description={t(
                'Choose decorative buttons to be removed in the editor.',
              )}
            >
              <InputRichEditorField
                options={richEditorOptions}
                setOption={setRichEditorOptions}
              />
            </Fieldset>
            <Fieldset
              legend={t('Use <br> Tag for Line Break')}
              description={t(
                'When this is ON, <br> tag is used to break lines. When this is OFF, <p> tag is used to separate paragraphs.',
              )}
            >
              <Switch
                on={
                  richEditorMultiParagraph !== null
                    ? richEditorMultiParagraph
                    : true
                }
                onChange={onChangeRichEditorMultiParagraph}
                size="large"
              />
            </Fieldset>
            <Fieldset
              legend={t('Include Width and Height in Response')}
              description={t(
                'When this is ON, the <img> tag contains the width and height.',
              )}
            >
              <Switch
                on={richEditorImageSize !== null ? richEditorImageSize : false}
                onChange={onChangeRichEditorImageSize}
                size="large"
              />
            </Fieldset>
          </>
        )}
        {kind === 'richEditorV2' && (
          <>
            <div className={styles.inputField}>
              <NewFieldset
                label={t('Edit Toolbar')}
                description={t(
                  'Choose toolbar buttons to be removed in the editor.',
                )}
              >
                <InputRichEditorV2OptionsField
                  options={richEditorV2Options}
                  setOptions={setRichEditorV2Options}
                />
              </NewFieldset>
            </div>
            <div className={styles.inputField}>
              <NewFieldset
                label={t('Color Presets')}
                description={t(
                  'Allows registered colors to be easily used as text colors. Changing a registered color later will not affect text already applied in the rich editor. Also, if there are duplicate registered colors, they will automatically be combined into one color.',
                )}
              >
                <InputRichEditorV2ColorListField
                  colorList={richEditorV2ColorList || []}
                  setColorList={setRichEditorV2ColorList}
                />
              </NewFieldset>
            </div>
            <div className={styles.inputField}>
              <NewFieldset
                label={t('Custom class')}
                description={t(
                  'Registered class names can be assigned to specific text elements.',
                )}
              >
                <InputCustomClassField
                  customClassList={customClassList || []}
                  setCustomClassList={setCustomClassList}
                />
              </NewFieldset>
            </div>
          </>
        )}
        <div className={cx(styles.actions, styles.withBorder)}>
          <Button
            type="secondary"
            value={t('Close')}
            disabled={
              (kind === 'repeater' && customFieldCreatedAtList.length === 0) ||
              hasDetailsFieldError
            }
            onClick={() => close(!hasDetailsFieldError)}
          />
          <Button
            type="danger"
            value={t('Delete field')}
            onClick={() => deleteField(index)}
          />
        </div>
        {isEditApi && (
          <p className={styles.caution}>
            {t(
              'Click the Close button to apply the changes. Clicking the Close button will close the pop-up window without applying the changes.',
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default DetailFieldSettings;
