/**
 * ServiceエンティティのIDの形式（SERVICE#を先頭にとる）に統一して返却する
 *
 * @example
 * uniformServiceId('02e46e7f-63a9-4f53-ab26-f4584387ebe9') // => SERVICE#02e46e7f-63a9-4f53-ab26-f4584387ebe9
 * uniformServiceId('SERVICE#02e46e7f-63a9-4f53-ab26-f4584387ebe9') // => SERVICE#02e46e7f-63a9-4f53-ab26-f4584387ebe9
 */
function uniformServiceId(likeServiceId: string): string {
  return likeServiceId.startsWith('SERVICE#')
    ? likeServiceId
    : `SERVICE#${likeServiceId}`;
}

export { uniformServiceId };
