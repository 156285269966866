import * as Sentry from '@sentry/browser';
import { API, Amplify, Auth, I18n, graphqlOperation } from 'aws-amplify';
import enUS from 'date-fns/locale/en-US';
import ja from 'date-fns/locale/ja';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { changeLanguage } from 'i18next';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import TagManager from 'react-gtm-module';

import awsExports from './aws-exports';
import App from './components/App';
import Error from './components/ui/Error';
import { REACT_APP_LD_CLIENT_SIDE_ID } from './constants/env';
import { loadAmplifyAuthConfig } from './constants/localStorage';
import { MetaContext } from './context/MetaContext';
import * as queries from './graphql/queries';
import { AppProvider } from './providers/AppProvider';
import {
  APP_DEFAULT_DOMAIN,
  getBrowserLanguage,
  getPureHost,
  getServiceDomain,
  isLocalhost,
} from './util';
import dict from './vocabularies';

import './i18n';

import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/border.css';
import './styles/colors.css';
import './styles/font.css';
import './styles/fonticon.css';
import './styles/hubspot.css';
import './styles/icon.css';
import './styles/reset.css';
import './styles/spacing.css';
import './styles/utility.css';

import type { GraphQLResult } from '@aws-amplify/api-graphql';
import type {
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
// eslint-disable-next-line import/order
import type {
  CheckUserStateQuery,
  GetAccountLanguageQuery,
  GetMetaQuery,
} from './API';

dayjs.extend(relativeTime);
dayjs.extend(LocalizedFormat);

// 開発時にlocalhost:3333にアクセスした際に、開発環境を開けるようにURLを書き換える
if (!import.meta.env.PROD && isLocalhost) {
  window.location.href = 'http://app.dev.local:3333';
}

const authConfig = loadAmplifyAuthConfig();

Amplify.configure({
  ...awsExports,
  ...(authConfig
    ? { Auth: authConfig }
    : {
        cookieStorage: {
          domain: getPureHost(),
          secure: window.location.protocol === 'https:',
        },
      }),
  Analytics: { disabled: true },
});

I18n.putVocabularies(dict);

import.meta.env.PROD &&
  Sentry.init({
    dsn: 'https://f5926a9a0d96434f82280422b7d2a6b9@sentry.io/1508702',
    environment: getPureHost(),
  });

const generateLdProvider = ({
  username,
  env,
}: {
  username: string;
  env: string;
}): Promise<any> => {
  return new Promise((resolve, reject) => {
    try {
      resolve(
        asyncWithLDProvider({
          clientSideID: REACT_APP_LD_CLIENT_SIDE_ID || '',
          context: {
            kind: 'user',
            key: username,
            env: env || 'local',
            subdomain: getServiceDomain() || APP_DEFAULT_DOMAIN,
          },
        }),
      );
    } catch (err) {
      reject(err);
    }
  });
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

Promise.all([
  //1. IPアドレスをチェック
  (
    API.graphql(
      graphqlOperation(queries.checkUserState, { domain: getServiceDomain() }),
    ) as Promise<GraphQLResult<CheckUserStateQuery>>
  )
    .then((res) => JSON.parse(res.data?.checkUserState || ''))
    .then(async (result) => {
      if (!result.result) {
        // ログインしているが、サービスを選択していない時
        await Auth.currentAuthenticatedUser().then((user) => {
          const _user = user as CognitoUser;
          MetaContext.user.username = _user.getUsername();
        });
        return true;
      }

      //グループ更新のためログインセッションを更新する
      //※上記のIPチェック結果をバックエンドで利用するためこの順番での実行が必須
      await Promise.all([
        Auth.currentAuthenticatedUser(),
        Auth.currentSession(),
      ]).then(([user, session]) => {
        const _user = user as CognitoUser;
        MetaContext.user.username = _user.getUsername();

        const refreshToken = (session as CognitoUserSession)?.getRefreshToken();
        return (
          _user &&
          refreshToken &&
          new Promise((resolve) => _user.refreshSession(refreshToken, resolve))
        );
      });
      return result.value;
    })
    .catch(() => {
      return true;
    }), //権限がない = 未ログインの時はIP制限をかけない

  //2.  環境毎に異なるメタ情報を取得
  (
    API.graphql(graphqlOperation(queries.getMeta)) as Promise<
      GraphQLResult<GetMetaQuery>
    >
  )
    .then((result) => {
      MetaContext.value = JSON.parse(result.data?.getMeta || '');
    })
    .catch(() => {
      //
    }),

  //3. 言語の切り替え
  (
    API.graphql(graphqlOperation(queries.getAccountLanguage)) as Promise<
      GraphQLResult<GetAccountLanguageQuery>
    >
  )
    .then((result) => {
      const userLanguage = result.data?.getAccountLanguage || 'ja';
      changeLanguage(userLanguage);
      if (userLanguage === 'en') {
        dayjs.locale('en');
        registerLocale('en-US', enUS);
        setDefaultLocale('en-US');
      } else {
        dayjs.locale('ja');
        registerLocale('ja', ja);
        setDefaultLocale('ja');
      }
    })
    .catch(() => {
      // 未ログイン時はブラウザ言語に切り替える
      const browserLanguage = getBrowserLanguage();
      changeLanguage(browserLanguage);

      if (browserLanguage === 'en') {
        dayjs.locale('en');
        registerLocale('en-US', enUS);
        setDefaultLocale('en-US');
      } else {
        dayjs.locale('ja');
        registerLocale('ja', ja);
        setDefaultLocale('ja');
      }
    }),
])
  .then(async ([results]) => {
    let LDProvider = ({ children }: { children: React.ReactNode }) => (
      <>{children}</>
    );

    try {
      const _LDProvider = async () => {
        LDProvider = await generateLdProvider({
          username: MetaContext?.user?.username || '',
          env: MetaContext?.value?.environment,
        });
      };
      await Promise.race([_LDProvider(), timeout(2000)]);
    } catch {
      return [results, LDProvider];
    }
    return [results, LDProvider];
  })
  .then(([results, LDProvider]) => {
    // タグマネージャーの導入
    if (
      MetaContext.value.isProduction &&
      window.location.protocol === 'https:'
    ) {
      const tagManagerArgs = {
        gtmId: 'GTM-MTZKV67',
      };

      TagManager.initialize(tagManagerArgs);
    }

    if (!LDProvider) throw Error('LDProvider is not defined');

    // Loadingを削除
    const loadingBlock = document.getElementById('pre-loading');
    loadingBlock && loadingBlock.remove();

    //画面描画を開始
    const container = document.getElementById('root') as HTMLElement;
    const root = createRoot(container);
    root.render(
      <ErrorBoundary FallbackComponent={Error}>
        <LDProvider>
          <AppProvider results={results}>
            <App />
          </AppProvider>
        </LDProvider>
      </ErrorBoundary>,
    );
  })
  .catch(() => {
    // 初期化処理に失敗した場合にエラー画面を表示する
    const container = document.getElementById('root') as HTMLElement;
    const root = createRoot(container);
    root.render(<Error />);
  });
