import dayjs from 'dayjs';
import { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Redirect, useParams } from 'react-router-dom';

import { useApiKeyReader } from '@/hooks/ApiKey/useApiKeyReader';
import {
  useUpdateApiKey,
  useDeleteApiKey,
} from '@/hooks/ApiKey/useApiKeyWriter';
import { useLoadApis } from '@/hooks/ApiList/useApiListReader';
import { useCopy } from '@/hooks/Copy/useCopy';
import { myRolesContext } from '@/hooks/Role/useMyRoles';
import { useGetMyService } from '@/hooks/useService';

import Head from '../Head';
import ManagementPolicySetting from '../ManagementPolicySetting';
import Member from '../Member';
import NotFound from '../NotFound';
import PolicySetting from '../PolicySetting';
import PriorityPolicy from '../PriorityPolicy';
import Button from '../ui/Button';
import ExternalLink from '../ui/ExternalLink';
import IconButton from '../ui/IconButton';
import IconWithTextButton from '../ui/IconWithTextButton';
import Loading from '../ui/Loading';

import type {
  ApiKeyPolicy,
  ApiKeyPriorityPolicy,
  ManagementPolicy,
} from '@/types/apiKey';

import styles from './editapikey.module.css';

import { formatDateToMinutes } from '@/util/date';
import { useInputPlainText } from '@/views/Common/Inputs';
import { AlertDialog } from '@/views/Common/Ui/AlertDialog';
import { Fieldset } from '@/views/Common/Ui/Fieldset';
import { Textfield } from '@/views/Common/Ui/Textfield';

const EditApiKey: React.FC = () => {
  const { service } = useGetMyService();
  const { t } = useTranslation('editApiKey');
  const { apiList } = useLoadApis(service);
  const context = useContext(myRolesContext);
  const isAdmin = useMemo(() => {
    return context.roles
      ? context.roles.some((role) => role.isAdmin === true)
      : undefined;
  }, [context.roles]);

  // URLからapiKeyIdを取得
  const slug = useParams<{ apikeyId: string }>();
  const { apiKey, apiKeyIsLoading } = useApiKeyReader(
    service?.partitionKey || '',
    `API_KEY#${slug.apikeyId}`,
  );
  const { updateApiKey, updateApiKeyLoading } = useUpdateApiKey();
  const { deleteApiKey, deleteApiKeyLoading, deleteApiKeyResult } =
    useDeleteApiKey(service?.partitionKey || '');

  // APIキーの表示/非表示
  const [getKeyVisibility, setGetKeyVisibility] = useState(false);
  const showGetKey = useCallback(() => setGetKeyVisibility(true), []);
  const hideReadKey = useCallback(() => setGetKeyVisibility(false), []);

  // NOTE: apiKey?.labelの部分について、APIキーの取得をサイドバーで行っているため、キャッシュによりundefinedになることはない
  const [apiKeyName, onChangeApiKeyName, apiKeyNameValidationError] =
    useInputPlainText(apiKey?.label, { validateOnInit: true });

  const [tab, setTab] = useState('content');
  const onChangeTab = useCallback(
    (e: any) => {
      setTab(e.target.getAttribute('data-id'));
    },
    [setTab],
  );

  const [defaultPolicy, setDefaultPolicy] = useState<ApiKeyPolicy | undefined>(
    apiKey?.defaultPolicy,
  );

  const [priorityPolicies, setPriorityPolicies] = useState<
    ApiKeyPriorityPolicy[]
  >([]);

  const [managementPolicy, setManagementPolicy] = useState<
    ManagementPolicy | undefined
  >(apiKey?.managementPolicy);

  useEffect(() => {
    const filterdList =
      apiKey?.priorityPolicies.filter((policy) => {
        const apiIdList = apiList?.list.map((api) => api.partitionKey);
        return policy.apiId && apiIdList?.includes(policy.apiId);
      }) ?? [];
    setPriorityPolicies(filterdList);
  }, [apiKey?.priorityPolicies, apiList?.list]);

  const replacePriorityPolicy = useCallback(
    (priorityPolicy: any, targetIndex: any) => {
      // 新規作成した個別権限に差分が出た場合のみ更新する
      if (
        JSON.stringify(priorityPolicy) ===
        JSON.stringify(priorityPolicies[targetIndex])
      ) {
        return;
      }
      setPriorityPolicies((policies) =>
        policies.map((current, index) => {
          return targetIndex === index ? priorityPolicy : current;
        }),
      );
    },
    [priorityPolicies],
  );

  const addPriorityPolicies = useCallback(() => {
    const defaultPriorityPolicy = {
      apiId: apiList?.list[0]?.partitionKey,
      policy: {
        get: true,
        getAllDraft: false,
        getAllClosed: false,
        post: false,
        put: false,
        patch: false,
        delete: false,
      },
    };
    setPriorityPolicies((policy) => [...policy, { ...defaultPriorityPolicy }]);
  }, [apiList?.list]);

  const deletePriorityPolicies = useCallback(
    (paramIndex: any) => {
      setPriorityPolicies((policies) =>
        policies.filter((_, index) => index !== paramIndex),
      );
    },
    [setPriorityPolicies],
  );

  const onUpdateApiKey = useCallback(() => {
    if (!apiKey?.apiKeyId || !defaultPolicy || !managementPolicy) return;
    updateApiKey({
      apiKeyId: apiKey.apiKeyId,
      label: apiKeyName,
      defaultPolicy,
      priorityPolicies,
      managementPolicy,
    });
  }, [
    updateApiKey,
    apiKey?.apiKeyId,
    apiKeyName,
    defaultPolicy,
    priorityPolicies,
    managementPolicy,
  ]);

  const onDeleteApiKey = useCallback(() => {
    if (!apiKey?.apiKeyId) return;
    deleteApiKey({
      apiKeyId: apiKey.apiKeyId,
    });
  }, [deleteApiKey, apiKey?.apiKeyId]);

  const [copied, copyAction, setCopied] = useCopy(apiKey?.value ?? '');

  const copyApiKey = () => {
    copyAction();

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const deleteConfirmText = useMemo(() => {
    return apiKey?.label ? apiKey.label : 'CONFIRM';
  }, [apiKey?.label]);

  if (deleteApiKeyResult === 'success') {
    return <Redirect to="/api-keys" />;
  }

  if (apiKeyIsLoading) {
    return <Loading />;
  }

  if (!apiKey) {
    return <NotFound />;
  }

  return (
    <div className={styles.wrapper}>
      <Head title={t('API Key Details')} />
      <header className={styles.header}>
        <Link to="/api-keys" className={styles.backButton}>
          <i className="material-icons-outlined">arrow_back_ios</i>
        </Link>
        {isAdmin && (
          <div className={styles.headerActions}>
            <AlertDialog
              trigger={
                <IconWithTextButton
                  icon="delete"
                  text={t('Delete')}
                  disabled={deleteApiKeyLoading}
                />
              }
              title={t('Delete API Key')}
              description={t(
                'Are you sure you want to delete your API key? To perform the deletion, enter ({{apiKeyName}}) in the form. in the form. Deleted API keys cannot be restored.',
                { apiKeyName: deleteConfirmText },
              )}
              buttonText={t('#Delete')}
              onSubmit={onDeleteApiKey}
              confirmText={deleteConfirmText}
            />
            <AlertDialog
              trigger={
                <Button
                  type="primary"
                  value={t('Save changes')}
                  disabled={
                    !!apiKeyNameValidationError ||
                    updateApiKeyLoading ||
                    deleteApiKeyLoading
                  }
                />
              }
              title={t('Change API Key')}
              description={t(
                'Are you sure you want to change your API key? If you are already using an API key, changing it may cause problems.',
              )}
              buttonText={t('#Save changes')}
              onSubmit={onUpdateApiKey}
            />
          </div>
        )}
      </header>
      <div className={styles.main}>
        <div className={styles.container}>
          <div className={styles.mata}>
            <dl className={styles.keyValue}>
              <dt className={styles.key}>{t('Date Created')}</dt>
              <dd>{formatDateToMinutes(apiKey?.createdAt || '')}</dd>
              {apiKey?.createdBy && (
                <dd className={styles.editor}>
                  <Member username={apiKey?.createdBy} imageOnly={true} />
                </dd>
              )}
              <dt className={styles.key}>{t('Last Date Updated')}</dt>
              <dd>{dayjs(apiKey?.updatedAt).fromNow()}</dd>
              {apiKey?.updatedBy && (
                <dd className={styles.editor}>
                  <Member username={apiKey?.updatedBy} imageOnly={true} />
                </dd>
              )}
            </dl>
          </div>
          <div className={styles.fieldset}>
            <Textfield
              type="text"
              size="full"
              label={t('API Key Name')}
              placeholder={t('Name of API Key')}
              value={apiKeyName}
              onChange={(e) => onChangeApiKeyName(e)}
              readOnly={!isAdmin}
              error={apiKeyNameValidationError}
            />
          </div>
          <Fieldset
            label={t('API Key')}
            description={t(
              'The following API key must be included in the X-MICROCMS-API-KEY header when making a request.',
            )}
            className={styles.fieldset}
          >
            <div className={styles.apikeyValue}>
              <Textfield
                type="text"
                size="full"
                label=""
                readOnly
                value={
                  getKeyVisibility
                    ? apiKey?.value
                    : `********************************${apiKey?.value.slice(
                        -4,
                      )}`
                }
              />
              <div className={styles.visibilityButton}>
                {getKeyVisibility ? (
                  <IconButton
                    icon="visibility_off"
                    hasFrame={true}
                    onClick={hideReadKey}
                  />
                ) : (
                  <IconButton
                    icon="visibility"
                    hasFrame={true}
                    onClick={showGetKey}
                  />
                )}
              </div>
              {apiKey && (
                <div className={styles.copyButton}>
                  <IconButton
                    icon={copied ? 'check' : 'content_copy'}
                    hasFrame={true}
                    onClick={copyApiKey}
                  />
                </div>
              )}
            </div>
          </Fieldset>
          <dl className={styles.tabs}>
            <dd
              onClick={onChangeTab}
              className={
                tab === 'content'
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }
              data-id="content"
            >
              {t('Content API')}
            </dd>
            <dd
              onClick={onChangeTab}
              className={
                tab === 'management'
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }
              data-id="management"
            >
              {t('Management API (Beta)')}
            </dd>
          </dl>
          {tab === 'content' && (
            <>
              <Fieldset
                label={t('Default Permission')}
                description={t('Default permission is applied for all APIs.')}
                className={styles.fieldset}
              >
                <PolicySetting
                  defaultPolicy={
                    defaultPolicy ? defaultPolicy : apiKey.defaultPolicy
                  }
                  setPolicy={setDefaultPolicy}
                />
              </Fieldset>
              {apiList && apiList?.list?.length > 0 && (
                <>
                  <Fieldset
                    label={t('Custom Permission for Selected API')}
                    description={t(
                      'Custom permissions can be set for each selected API individually. The default permissions for a selected API will be overridden by this custom permission.',
                    )}
                    className={styles.fieldset}
                  >
                    {priorityPolicies?.map((priorityPolicy, index) => (
                      <div className={styles.block} key={index}>
                        <PriorityPolicy
                          apiList={apiList}
                          priorityPolicy={priorityPolicy}
                          setPriorityPolicy={(param: ApiKeyPriorityPolicy) =>
                            replacePriorityPolicy(param, index)
                          }
                        />
                        <button
                          className={styles.removeButton}
                          onClick={() => deletePriorityPolicies(index)}
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                    ))}
                  </Fieldset>
                  {!isAdmin || (
                    <Button
                      type="tertiary"
                      size="full"
                      icon="add"
                      onClick={() => addPriorityPolicies()}
                      value={t('Set custom permissions for selected API')}
                      className={styles.addPriorityButton}
                    />
                  )}
                </>
              )}
            </>
          )}
          {tab === 'management' && (
            <Fieldset label={t('Permissions')} className={styles.fieldset}>
              <dd className={styles.description}>
                {Trans({
                  t,
                  i18nKey:
                    'Add management API permissions. Refer to documentation for more details.',
                  children: (
                    <ExternalLink
                      href={`https://document.microcms.io/management-api`}
                      target="docs"
                      hasUnderline={true}
                      className={styles.helpLink}
                    >
                      the documentation
                    </ExternalLink>
                  ),
                })}
              </dd>
              <ManagementPolicySetting
                managementPolicy={
                  managementPolicy ? managementPolicy : apiKey.managementPolicy
                }
                setPolicy={setManagementPolicy}
              />
            </Fieldset>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditApiKey;
