import cx from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useStripeActions } from '../../../hooks/useStripeActions';
import HelpButton from '../../ui/HelpButton';

import type { Service } from '../../../types/services';
import type { FieldType } from '@/constants/useFieldTypes';
import type { FieldKind } from '@/types/field';
import type { KeyboardEvent, MouseEvent } from 'react';

import styles from './SelectKind.module.css';

type Props = {
  service: Service;
  isOpen: boolean;
  allowCustomField?: boolean;
  types: FieldType[];
  kind: FieldKind;
  onChangeKind: (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => void;
};

const SelectKind: React.FC<Props> = ({
  service,
  isOpen,
  allowCustomField,
  types,
  kind,
  onChangeKind,
}) => {
  const { t } = useTranslation('selectKind');

  const { currentPlan } = useStripeActions(service);
  const isLock = useCallback(
    (restrictionKey?: string) => {
      if (restrictionKey === undefined) {
        return false;
      }
      return currentPlan && currentPlan.limit[restrictionKey] === false;
    },
    [currentPlan],
  );

  // buttonタグではない要素でボタンを表現するためにclickイベントとkeyイベントを設定する
  const handleClickButton = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    onChangeKind(e);
  };
  const handleKeydownButton = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onChangeKind(e);
    }
  };

  return (
    <div className={styles.main}>
      <header
        className={cx(styles.header, {
          [styles.selectFieldType]: !allowCustomField,
        })}
      >
        <h3 className={styles.headerName}>{t('Select type')}</h3>
        {!allowCustomField && (
          <p className={styles.headerDescription}>
            {t(
              'Custom fields and Repeated fields become available once an API is created.',
            )}
          </p>
        )}
      </header>
      <ul className={styles.scroll}>
        {types
          .filter(
            (type) =>
              allowCustomField ||
              (type.id !== 'custom' && type.id !== 'repeater'),
          )
          .map((type, i) => (
            <li key={i}>
              <div
                className={
                  kind === type.id
                    ? `${styles.typeList} ${styles.isSelected}`
                    : styles.typeList
                }
                data-id={type.id}
                onClick={(e) =>
                  !isLock(type.restrictionKey) && handleClickButton(e)
                }
                onKeyDown={(e) =>
                  !isLock(type.restrictionKey) && handleKeydownButton(e)
                }
                role="button"
                tabIndex={isOpen && !isLock(type.restrictionKey) ? 0 : -1}
              >
                <span className={styles.typeIcon}>
                  <i className="material-icons">{type.icon}</i>
                </span>
                <dl className={styles.type}>
                  <dt className={styles.typeTitle}>
                    <p>{type.name}</p>
                    {type.id === 'custom' ? (
                      <HelpButton
                        type="manual"
                        id="custom-field"
                        className={`${styles.helpButton} ga-click-tooltip-select-custom-field`}
                        tabIndex={isOpen ? 0 : -1}
                      />
                    ) : type.id === 'repeater' ? (
                      <HelpButton
                        type="manual"
                        id="repeat-field"
                        className={`${styles.helpButton} ga-click-tooltip-select-repeat-field`}
                        tabIndex={isOpen ? 0 : -1}
                      />
                    ) : null}
                    {type.isDeprecated && (
                      <span className={styles.deprecated}>
                        {t('Deprecated')}
                      </span>
                    )}
                  </dt>
                  <dd className={styles.typeDescription}>{type.description}</dd>
                </dl>
                <div
                  className={
                    isLock(type.restrictionKey)
                      ? `${styles.padlock} ${styles.isForbidden}`
                      : styles.padlock
                  }
                >
                  <i className={`material-icons ${styles.padlockIcon}`}>lock</i>
                  <p className={styles.padlockDescription}>
                    {t('Not available with current plan')}
                  </p>
                </div>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default SelectKind;
