import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetMyService } from '@/hooks/useService';

import Button from '../ui/Button';
import Row from './Row';

import type { ApiList, ApiListByRedux, MigrateApi } from '@/entity/api';

import styles from './contentFilters.module.css';

import { CONDITIONS } from '@/constants/contentFilterKey';
import { apiListSelectors } from '@/ducks/apiList';
import {
  useContentCreators,
  useContentEditors,
} from '@/hooks/ContentCreator/useContentCreators';
import { useAppSelector } from '@/store/hooks';
import { Loading } from '@/views/Common/Ui/Loading';

type Props = {
  api: MigrateApi;
  close: any;
  setFiltersString: any;
  defaultFiltersString: string;
};

const ContentFilters: React.FC<Props> = ({
  api,
  close,
  setFiltersString,
  defaultFiltersString = '',
}) => {
  const { t } = useTranslation('contentFilters');
  const { service } = useGetMyService();

  // TODO: ReduxをReactQueryに置き換える
  const { apiList } = useAppSelector(
    (state) =>
      apiListSelectors.get(state.apiListState as ApiList) as ApiListByRedux,
  );

  const { list: contentCreators } = useContentCreators(api.partitionKey);
  const { list: contentEditors } = useContentEditors(api.partitionKey);

  const defaultFilters: {
    key: string;
    value: string;
    condition: string;
  }[] = useMemo(() => {
    return defaultFiltersString
      .split('[and]')
      .map((item) => {
        const symbols = [...new Set(Object.values(CONDITIONS).flat())];
        const regExp = new RegExp(
          `${symbols.join('|').replace(/\[/g, '\\[').replace(/\]/g, '\\]')}`,
        );
        const res = item.match(regExp);
        if (!res) {
          // 条件が不正な場合は除外する
          return undefined;
        }
        const [key, value] = item.split(res[0]);
        return {
          key,
          value,
          condition: res[0],
        };
      })
      .filter((v): v is Exclude<typeof v, undefined> => v !== undefined);
  }, [defaultFiltersString]);
  const [filtersArray, setFiltersArray] = useState(defaultFilters);

  // APIを切り替えた場合、すべてのフィルターをリセット
  useEffect(() => {
    setFiltersArray(defaultFilters);
  }, [api, defaultFilters]);

  const add = useCallback(() => {
    const defaultFilter = {
      key: 'contentStatus',
      value: 'PUBLISH',
      condition: '[equals]',
    };
    const newFiltersArray = [...filtersArray, defaultFilter];
    setFiltersArray(newFiltersArray);
  }, [filtersArray]);

  const remove = useCallback(
    (index: any) => {
      const newFiltersArray = filtersArray.filter((_, i) => i !== index);
      setFiltersArray(newFiltersArray);
    },
    [filtersArray],
  );

  const onChange = useCallback(
    (filter: any, index: any) => {
      const newFiltersArray = [...filtersArray];
      newFiltersArray[index] = filter;
      setFiltersArray(newFiltersArray);
    },
    [filtersArray],
  );

  const setFilters = useCallback(() => {
    const filtersString = filtersArray
      .map(({ key, value, condition }) => `${key}${condition}${value}`)
      .join('[and]');
    setFiltersString(filtersString);
    close();
  }, [filtersArray, close, setFiltersString]);

  const reset = useCallback(() => {
    close();
    setFiltersArray([]);
    setFiltersString('');
  }, [close, setFiltersString]);

  if (!service || !contentCreators || !contentEditors) {
    return <Loading className={styles.loading} />;
  }

  return (
    <div>
      <h3 className={styles.title}>{t('Filters')}</h3>
      {filtersArray.map((filter, i) => (
        <Row
          key={i}
          service={service}
          apiList={apiList}
          api={api}
          contentCreators={contentCreators}
          contentEditors={contentEditors}
          // @ts-expect-error
          filter={filter}
          onChange={onChange}
          remove={remove}
          index={i}
        />
      ))}
      <Button
        type="tertiary"
        size="full"
        icon="add"
        value={t('Add')}
        onClick={add}
      />
      <div className={styles.actions}>
        <Button type="secondary" value={t('Apply')} onClick={setFilters} />
        <button onClick={reset} className={styles.textButton}>
          {t('Reset')}
        </button>
      </div>
    </div>
  );
};

export default ContentFilters;
