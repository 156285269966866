import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { contentRepository } from '@/repository/contentRepository';

import {
  generateExportJsonFile,
  generateUniqueSchema,
  getCurrentPlan,
  objectSimplify,
} from '@/usecase/serviceUsecase/serviceUsecase';
import { changePlanToTemplate as changePlanToTemplateUsecase } from '@/usecase/stripeUsecase';

import { useLoadApis } from '@/hooks/ApiList/useApiListReader';

import type { Service } from '@/types/services';

import { useApi } from '@/lib/useApi';
import { isReactQueryError } from '@/util/type-guard';
import { useToggle } from '@/views/Common/Ui/useToggle';

export const useExportService = (service: Service | null) => {
  const { t } = useTranslation('exportService');
  const [trigger, setTrigger] = useState(false);
  const { apiList } = useLoadApis(service);
  const [currentPlan, setCurrentPlan] = useState<string>('');
  const [isToggleOn, setToggleOn] = useToggle(false);
  const cache = useQueryClient();
  const { addToast } = useToasts();

  useEffect(() => {
    getCurrentPlan(service?.partitionKey || '').then((plan) => {
      if (plan) {
        setCurrentPlan(plan.name);
        setToggleOn(currentPlan === 'Template');
      }
    });
  }, [currentPlan, service?.partitionKey, setToggleOn]);

  // Templateプランに変更
  const {
    mutate: changePlanToTemplate,
    isLoading: changePlanToTemplateLoading,
  } = useMutation({
    mutationFn: async () => {
      if (!service) {
        return;
      }
      return await changePlanToTemplateUsecase({
        serviceId: service.partitionKey,
      });
    },
    onSuccess() {
      cache.invalidateQueries(['subscriptionData'], { type: 'all' });
      cache.invalidateQueries(['planList'], { type: 'all' });
      addToast(t('The change to the Template plan has been completed.'), {
        appearance: 'success',
      });
    },
    onError({ errors }) {
      addToast(errors ? errors[0].message : t('Failed to change the plan.'), {
        appearance: 'error',
      });
    },
  });

  const switchToggleState = useCallback(async () => {
    if (window.confirm(t('Once set, it cannot be undone. Are you sure?'))) {
      changePlanToTemplate();
      setToggleOn(true);
    }
  }, [changePlanToTemplate, setToggleOn, t]);

  const setApiDetail = apiList?.list.map(async (api) => {
    const customFields = await contentRepository().getCustomFields(
      api.partitionKey,
    );

    const data = {
      apiId: api.partitionKey,
      apiName: api.apiName,
      apiDescription: api.apiDescription,
      apiEndpoint: api.apiEndpoint,
      apiType: api.apiType,
      apiFields: api.apiFields.map(objectSimplify),
      customFields: customFields.map((field) => {
        if (field) {
          field.fields = field?.fields.map((f) => objectSimplify(f));
        }
        return field;
      }),
    };
    return data;
  });

  const planSelector = (apiCount: number) => {
    if (apiCount <= 3) {
      return 'Hobby';
    } else if (apiCount <= 10) {
      return 'Team';
    } else if (apiCount <= 30) {
      return 'Business';
    } else {
      return 'Advanced';
    }
  };

  const createApiSchema = async () => {
    const result = await Promise.all(setApiDetail || []);
    const schema = generateUniqueSchema(result);
    const apiCount = schema.length;

    const exportSchema = {
      name: 'microCMS template',
      plan: planSelector(apiCount),
      schemaVersion: '1.0.0',
      schema: schema,
    };
    return exportSchema;
  };

  const useGetApiSchema = () => {
    return useApi(
      ['exportService', { serviceId: service?.partitionKey }],
      async () => {
        return await createApiSchema();
      },
      {
        enabled: !!apiList && !!service?.partitionKey && trigger,
        onSuccess: (data) => {
          // JSON validation
          generateExportJsonFile(data);
        },
        onError: (error) => {
          if (isReactQueryError(error)) {
            return {
              message: `${error.status}:${error.message}`,
            };
          }
          if (error instanceof Error) {
            return {
              message: error.message,
            };
          }
          return {
            message: 'Something went wrong...',
          };
        },
        cacheTime: 0,
      },
    );
  };

  const exportHandler = async () => {
    setTrigger(true);
  };

  return {
    useGetApiSchema,
    exportHandler,
    currentPlan,
    isToggleOn,
    switchToggleState,
    changePlanToTemplateLoading,
  };
};
