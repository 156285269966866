import { useId } from 'react';

type UseTextareaArgs = {
  error?: string;
  onEnter?: () => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
};

type UseTextareaReturn = {
  textareaId: string;
  onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement>;
};

export const useTextarea = ({
  error,
  onEnter,
  onKeyDown: originalOnKeyDown,
}: UseTextareaArgs): UseTextareaReturn => {
  const textareaId = useId();

  const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (!e.nativeEvent.isComposing && e.key === 'Enter') {
      !error && onEnter?.();
    }

    originalOnKeyDown?.(e);
  };

  return {
    textareaId,
    onKeyDown,
  };
};
