import { useTranslation } from 'react-i18next';

import { useUndo } from './useUndo';
import { IconButton } from '../../../IconButton';
import styles from '../../../richeditorv2.module.css';

import type { Editor } from '@tiptap/react';

import { UndoIcon } from '@/views/Common/Icons';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  onUndoHandler: () => void;
  editable: boolean;
};

export const UndoView: React.FC<ViewProps> = ({ onUndoHandler, editable }) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <IconButton
      icon={<UndoIcon className={styles.icon} />}
      aria-label={t('Undo')}
      onClick={onUndoHandler}
      isActive={false}
      disabled={!editable}
    />
  );
};

export const Undo: React.FC<Props> = ({ editor }) => {
  const { onUndoHandler } = useUndo({ editor });

  return (
    <UndoView onUndoHandler={onUndoHandler} editable={editor.isEditable} />
  );
};
