import { webhookSettingWriterRepository } from '@/repository/webhookSettingWriterRepository';

import type { WebhookSettingTarget } from '@/constants/webhookSettings';
import type { WebhookSetting } from '@/entity/webhookSettings';
import type { WebhookEventIdentifier } from '@/entity/webhookSettings';

export const createWebhookSetting = async ({
  apiId,
  target,
  values,
  events,
}: {
  apiId: string;
  target: (typeof WebhookSettingTarget)[keyof typeof WebhookSettingTarget];
  values: object;
  events: Array<WebhookEventIdentifier>;
}) => {
  const { createWebhookSetting } = webhookSettingWriterRepository(apiId);
  return await createWebhookSetting({ target, values, events });
};

export const updateWebhookSetting = async ({
  current,
  values,
  events,
}: {
  current: WebhookSetting;
  values: object;
  events: Array<WebhookEventIdentifier>;
}) => {
  const { updateWebhookSetting } = webhookSettingWriterRepository(
    current.apiId,
  );
  return await updateWebhookSetting({ current, values, events });
};

export const deleteWebhookSetting = async ({
  current,
}: {
  current: WebhookSetting;
}) => {
  const { deleteWebhookSetting } = webhookSettingWriterRepository(
    current.apiId,
  );
  return await deleteWebhookSetting({ current });
};

export const updateWebhookEnabledSetting = async ({
  current,
  enabled,
}: Parameters<
  ReturnType<
    typeof webhookSettingWriterRepository
  >['updateWebhookEnabledSetting']
>[0]) => {
  const { updateWebhookEnabledSetting } = webhookSettingWriterRepository(
    current.apiId,
  );
  return await updateWebhookEnabledSetting({ current, enabled });
};
