import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  putService as putServiceUsecase,
  putServiceId as putServiceIdUsecase,
} from '@/usecase/serviceWriterUsecase';

import { loadAmplifyAuthConfig } from '../../constants/localStorage';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { getHost, getSSOHost } from '../../util';

export const useServiceWriter = () => {
  const { t } = useTranslation('hooksService');
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const updateRequireMfa = useCallback(
    (serviceId, requireMfa) => {
      setLoading(true);
      API.graphql(
        graphqlOperation(mutations.updateServiceRequireMfa, {
          serviceId,
          requireMfa,
        }),
      )
        .then((result) => {
          setLoading(false);

          if (result.data.updateServiceRequireMfa.result === true) {
            addToast(t('Changes have been saved.'), { appearance: 'success' });
          } else {
            addToast(result.data.updateServiceRequireMfa.message, {
              appearance: 'error',
            });
          }
        })
        .catch(() => {
          setLoading(false);
          addToast(t('An unexpected error has occurred.'), {
            appearance: 'error',
          });
        });
    },
    [addToast, t],
  );

  return [updateRequireMfa, loading];
};

export const usePostService = () => {
  const { t } = useTranslation('hooksService');
  const { addToast } = useToasts();
  const history = useHistory();

  const { mutate: postService, isLoading: postServiceLoading } = useMutation({
    mutationFn: ({ serviceName, serviceDomain }) => {
      return API.graphql(
        graphqlOperation(queries.createService, {
          name: serviceName,
          domain: serviceDomain,
          planName: 'Hobby',
        }),
      ).then((result) => JSON.parse(result.data.createService));
    },
    onSuccess(data) {
      if (data.result === false) {
        addToast(data.message, { appearance: 'error' });
        return;
      }
      const domain = data.result?.gsiSinglePartitionKey1?.replace(
        'DOMAIN#',
        '',
      );
      history.replace(`/letsgo/${domain}`);
    },
    onError({ errors }) {
      addToast(errors ? errors[0].message : t('Could not create service'), {
        appearance: 'error',
      });
    },
  });

  return { postService, postServiceLoading };
};

export const usePutService = () => {
  const { t } = useTranslation('hooksService');
  const { addToast } = useToasts();
  const cache = useQueryClient();

  const { mutate: putService, isLoading: putServiceLoading } = useMutation({
    mutationFn: async ({ partitionKey, serviceName, serviceImage }) => {
      return await putServiceUsecase({
        partitionKey,
        serviceName,
        serviceImage,
      });
    },
    onSuccess() {
      cache.invalidateQueries(['service'], { type: 'all' });

      // サムネイル画像の更新を反映
      cache.invalidateQueries(['services'], { type: 'all' });

      addToast(t('Changes have been saved.'), { appearance: 'success' });
    },
    onError({ errors }) {
      addToast(errors ? errors[0].message : t('Failed to make changes.'), {
        appearance: 'error',
      });
    },
  });

  return { putService, putServiceLoading };
};

async function moveToSAMLLogin(serviceId) {
  const config = await API.graphql(
    graphqlOperation(queries.getSAMLConfiguration, {
      serviceId,
    }),
  );

  const { loginURL } = JSON.parse(config.data.getSAMLConfiguration);
  const loginToken = new URLSearchParams(new URL(loginURL).search).get(
    'loginToken',
  );

  const host = getSSOHost();
  const query = new URLSearchParams({
    loginToken,
  }).toString();

  window.location.href = `${host}/saml/signin?${query}`;
}

export const usePutServiceId = () => {
  const { t } = useTranslation('hooksService');
  const { addToast } = useToasts();

  const { mutate: putServiceId, isLoading: putServiceIdLoading } = useMutation({
    mutationFn: async ({ partitionKey, domain }) => {
      return await putServiceIdUsecase({
        partitionKey,
        domain,
      });
    },
    onSuccess(_, { partitionKey, domain }) {
      addToast(t('Service ID changed'), { appearance: 'success' });

      // SAML ログイン中であれば再ログインを行う
      if (loadAmplifyAuthConfig()) {
        return moveToSAMLLogin(partitionKey);
      }

      if (domain) {
        window.location.href = `${getHost(domain)}/settings/service-id`;
      }
    },
    onError({ errors }) {
      addToast(errors ? errors[0].message : t('Could not change service ID'), {
        appearance: 'error',
      });
    },
  });

  return { putServiceId, putServiceIdLoading };
};
