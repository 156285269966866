import { API, graphqlOperation } from 'aws-amplify';

import * as mutations from '../../graphql/mutations';

import type { GraphQLResult } from '@aws-amplify/api-graphql';

import {
  handleApiResult,
  type ApiResponse,
} from '@/views/Common/handleApiResult';

export const reviewRepository = () => {
  const updateReviewRequest = async ({
    serviceId,
    reqId,
    title,
    description,
    reservationTime,
    reservationStopTime,
  }: {
    serviceId: string;
    reqId: number;
    title: string;
    description?: string;
    reservationTime?: string;
    reservationStopTime?: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.updateReviewRequest, {
          serviceId,
          reqId,
          title,
          description,
          reservationTime,
          reservationStopTime,
        }),
      )) as GraphQLResult<{
        updateReviewRequest: ApiResponse<boolean> | boolean;
      }>;

      if (!result.data) {
        throw new Error('Unexpected error');
      }

      // TODO: フロントエンドエラー改善
      if (
        typeof result.data.updateReviewRequest === 'object' &&
        typeof result.data.updateReviewRequest.result === 'boolean'
      ) {
        return handleApiResult(result.data.updateReviewRequest);
      } else if (typeof result.data.updateReviewRequest === 'boolean') {
        return result.data.updateReviewRequest;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected error');
    }
  };

  const toggleReviewRequestStatus = async ({
    serviceId,
    reqId,
  }: {
    serviceId: string;
    reqId: number;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.toggleReviewRequestStatus, {
          serviceId,
          reqId,
        }),
      )) as GraphQLResult<{
        toggleReviewRequestStatus: ApiResponse<boolean> | boolean;
      }>;

      if (!result.data) {
        throw new Error('Unexpected error');
      }

      // TODO: フロントエンドエラー改善
      if (
        typeof result.data.toggleReviewRequestStatus === 'object' &&
        typeof result.data.toggleReviewRequestStatus.result === 'boolean'
      ) {
        return handleApiResult(result.data.toggleReviewRequestStatus);
      } else if (typeof result.data.toggleReviewRequestStatus === 'boolean') {
        return result.data.toggleReviewRequestStatus;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected error');
    }
  };

  const closeReviewRequestAndPublishContent = async ({
    serviceId,
    reqId,
  }: {
    serviceId: string;
    reqId: number;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.closeReviewRequestAndPublishContent, {
          serviceId,
          reqId,
        }),
      )) as GraphQLResult<{
        closeReviewRequestAndPublishContent: ApiResponse<boolean> | boolean;
      }>;

      if (!result.data) {
        throw new Error('Unexpected error');
      }

      // TODO: フロントエンドエラー改善
      if (
        typeof result.data.closeReviewRequestAndPublishContent === 'object' &&
        typeof result.data.closeReviewRequestAndPublishContent.result ===
          'boolean'
      ) {
        return handleApiResult(result.data.closeReviewRequestAndPublishContent);
      } else if (
        typeof result.data.closeReviewRequestAndPublishContent === 'boolean'
      ) {
        return result.data.closeReviewRequestAndPublishContent;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected error');
    }
  };

  const createReviewRequestComment = async ({
    serviceId,
    reqId,
    comment,
  }: {
    serviceId: string;
    reqId: number;
    comment: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.createReviewRequestComment, {
          serviceId,
          reqId,
          comment,
        }),
      )) as GraphQLResult<{
        createReviewRequestComment: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected error');
      }

      // TODO: フロントエンドエラー改善
      if (
        typeof result.data.createReviewRequestComment === 'object' &&
        typeof result.data.createReviewRequestComment.result === 'boolean'
      ) {
        return handleApiResult(result.data.createReviewRequestComment);
      } else if (typeof result.data.createReviewRequestComment === 'string') {
        return result.data.createReviewRequestComment;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected error');
    }
  };

  const deleteReviewRequestComment = async ({
    serviceId,
    reqId,
    eventId,
  }: {
    serviceId: string;
    reqId: number;
    eventId: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.deleteReviewRequestComment, {
          serviceId,
          reqId,
          eventId,
        }),
      )) as GraphQLResult<{
        deleteReviewRequestComment: ApiResponse<boolean> | boolean;
      }>;

      if (!result.data) {
        throw new Error('Unexpected error');
      }

      // TODO: フロントエンドエラー改善
      if (
        typeof result.data.deleteReviewRequestComment === 'object' &&
        typeof result.data.deleteReviewRequestComment.result === 'boolean'
      ) {
        return handleApiResult(result.data.deleteReviewRequestComment);
      } else if (typeof result.data.deleteReviewRequestComment === 'boolean') {
        return result.data.deleteReviewRequestComment;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected error');
    }
  };

  return {
    updateReviewRequest,
    toggleReviewRequestStatus,
    closeReviewRequestAndPublishContent,
    createReviewRequestComment,
    deleteReviewRequestComment,
  };
};
