import { useCallback, useContext, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { myRolesContext } from '@/hooks/Role/useMyRoles';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';

import { PrimaryButton } from '../Form';
import Modal from '../Modal';
import Button from '../ui/Button';
import UpsellChild from '../ui/Upsell';

import styles from './upsell.module.css';

type Props = {
  isChildEnvironment?: boolean;
  restrictionKey: any;
  children?: React.ReactNode;
  samlSetting?: any;
};

/**
 * @deprecated
 * src/views/Common/plan/Upsellを使用してください。
 * アドオンに関するUIはまだ未実装のため、このコンポーネントを使用して問題ありません。
 */
const Upsell: React.FC<Props> = ({
  isChildEnvironment,
  restrictionKey,
  children,
  samlSetting = null,
}) => {
  const { service } = useGetMyService();
  const { t } = useTranslation('upsell');
  const { currentPlan, purchaseAddons, purchaseAddonsLoading } =
    useStripeActions(service);

  // 権限を取得
  const { roles } = useContext(myRolesContext);
  const hasUpdateBillingPermission = useMemo(() => {
    return (
      roles &&
      roles
        .map(({ billingPermission }) => billingPermission.update)
        .includes(true)
    );
  }, [roles]);

  // モーダル
  const [isOpenChangeAddonModal, setIsOpenChangeAddonModal] = useState(false);
  const openChangeAddonModal = useCallback(() => {
    setIsOpenChangeAddonModal(true);
  }, []);
  const closeChangeAddonModal = useCallback(() => {
    setIsOpenChangeAddonModal(false);
  }, []);

  // アドオンの購入処理
  const doPurchaseAddons = useCallback(
    async (e: any) => {
      e.preventDefault();
      // @ts-expect-error
      purchaseAddons({
        restrictionKey,
        type: restrictionKey,
        closeModal: closeChangeAddonModal,
      });
    },
    [closeChangeAddonModal, purchaseAddons, restrictionKey],
  );

  if (!currentPlan) {
    return null;
  }

  if (
    currentPlan &&
    ((currentPlan.limit[restrictionKey] === false &&
      !samlSetting?.idpMetadataURL) ||
      currentPlan.limit[restrictionKey] === 0)
  ) {
    if (
      restrictionKey !== 'customStatusCount' &&
      restrictionKey !== 'enableSAML' &&
      currentPlan.limit.enableAddons
    ) {
      return (
        <div className={styles.wrapper}>
          {restrictionKey === 'additionalEnvironments' ? (
            <div className={styles.changeAddonModal}>
              <div className={styles.container}>
                <img src="/images/icon_lock_fill.svg" alt="" />
                <p className={styles.description}>
                  {t('Features are available after add-on purchase')} <br />
                  {t(
                    'For more information, contact us from the chat in the lower right corner of the screen.',
                  )}
                </p>
                <Button
                  className={styles.button}
                  type="secondary"
                  value={t('Added')}
                  onClick={openChangeAddonModal}
                />
              </div>
            </div>
          ) : (
            <div className={styles.container}>
              <img src="/images/icon_lock_fill.svg" alt="" />
              <p className={styles.description}>
                {t('Features are available after add-on purchase')} <br />
                {t(
                  'For more information, contact us from the chat in the lower right corner of the screen.',
                )}
              </p>
              <Button
                className={styles.button}
                type="secondary"
                size="large"
                value={t('Added')}
                onClick={openChangeAddonModal}
              />
            </div>
          )}
          <Modal isOpen={isOpenChangeAddonModal} close={closeChangeAddonModal}>
            <div className={styles.changeAddonModal}>
              {restrictionKey === 'auditLog' && (
                <>
                  <p className={styles.description}>
                    {t(
                      'The audit log allows you to check the operations performed for services.',
                    )}
                    <br />
                    {Trans({
                      t,
                      i18nKey:
                        'For more information, please also see our document.',
                      children: (
                        <a
                          href="https://document.microcms.io/manual/audit-log"
                          target="docs"
                        >
                          document
                        </a>
                      ),
                    })}
                  </p>
                  <p className={styles.pricing}>
                    {t('Audit log 49,500 yen/month (tax excluded)')}
                  </p>
                </>
              )}
              {restrictionKey === 'apiIpRestriction' && (
                <>
                  <p className={styles.description}>
                    {t(
                      'API IP Restriction restricts API access based on IP address.',
                    )}
                    <br />
                    {Trans({
                      t,
                      i18nKey:
                        'For more information, please also see our document.',
                      children: (
                        <a
                          href="https://document.microcms.io/manual/api-ip-restriction"
                          target="docs"
                        >
                          document
                        </a>
                      ),
                    })}
                  </p>
                  <p className={styles.pricing}>
                    {t('IP restriction on API 29,700 yen/month (tax excluded)')}
                  </p>
                </>
              )}
              {restrictionKey === 'additionalEnvironments' && (
                <>
                  <p className={styles.description}>
                    {t(
                      'Up to 10 environments can be created with this function.',
                    )}
                    <br />
                    {Trans({
                      t,
                      i18nKey:
                        'For more information, please also see our document.',
                      children: (
                        <a
                          href="https://document.microcms.io/manual/environments"
                          target="docs"
                        >
                          document
                        </a>
                      ),
                    })}
                  </p>
                  <p className={styles.pricing}>
                    {t(
                      'Add multiple environments 49,500 yen/month (tax excluded)',
                    )}
                  </p>
                </>
              )}
              {hasUpdateBillingPermission ? (
                <PrimaryButton
                  value={t('Purchase')}
                  onClick={doPurchaseAddons}
                  disabled={purchaseAddonsLoading}
                />
              ) : (
                <p className={styles.alert}>
                  {t('You are not authorized to make payment')}
                </p>
              )}
            </div>
          </Modal>
        </div>
      );
    }

    const param =
      restrictionKey === 'auditLog'
        ? {
            helpUrl: 'https://document.microcms.io/manual/audit-log',
            children: (
              <>
                <p>
                  {t(
                    'Audit log is used for checking users activities on microCMS.',
                  )}
                </p>
                <p>
                  {t(
                    'Operation details such as date, time, IP address and target API name are recorded.',
                  )}
                </p>
              </>
            ),
          }
        : restrictionKey === 'handleEnvironments'
          ? {
              helpUrl: 'https://document.microcms.io/manual/environments',
              children: (
                <>
                  <p>
                    {t(
                      'Separated environments for development can be created with higher plans.',
                    )}
                  </p>
                  <p>
                    {t(
                      'API schemas or functionalities can be changed safely without affecting the production environment.',
                    )}
                  </p>
                </>
              ),
            }
          : restrictionKey === 'requireMfa'
            ? {
                helpUrl: 'https://document.microcms.io/manual/mfa-need-setting',
                children: (
                  <>
                    <p>
                      {t(
                        'Two-Factor authentication can be made mandatory for each member.',
                      )}
                    </p>
                    <p>
                      {t('This provides better security when using microCMS.')}
                    </p>
                  </>
                ),
              }
            : restrictionKey === 'ipRestriction'
              ? {
                  helpUrl: 'https://document.microcms.io/manual/ip-restriction',
                  children: (
                    <>
                      <p>
                        {t(
                          'Communication with microCMS can be restricted to only some IP addresses.',
                        )}
                      </p>
                      <p>
                        {t(
                          'Once set, the Administration console will become available only from the authorized IP addresses.',
                        )}
                      </p>
                    </>
                  ),
                }
              : restrictionKey === 'apiIpRestriction'
                ? {
                    helpUrl:
                      'https://document.microcms.io/manual/api-ip-restriction',
                  }
                : restrictionKey === 'customStatusCount'
                  ? {
                      helpUrl:
                        'https://document.microcms.io/manual/custom-status',
                      children: (
                        <>
                          <p>
                            {t(
                              'This feature allows creating your own content status.',
                            )}
                          </p>
                          <p>
                            {t(
                              "Custom Status facilitates operations by creating team-specific status such as 'Campaign Completed' or 'Revisions Needed'.",
                            )}
                          </p>
                        </>
                      ),
                    }
                  : restrictionKey === 'enableSAML' &&
                      !samlSetting?.idpMetadataURL
                    ? {
                        helpUrl:
                          'https://document.microcms.io/manual/saml-auth0',
                        children: (
                          <>
                            <p>
                              {t(
                                'This function allows authentication through SSO (single sign-on) in conjunction with an ID provider (IdP).',
                              )}
                            </p>
                            <p>
                              {t(
                                "You can build an authentication flow tailored to your team's environment.",
                              )}
                            </p>
                          </>
                        ),
                      }
                    : restrictionKey === 'mediumTags'
                      ? {
                          helpUrl:
                            'https://document.microcms.io/manual/media-tag-settings',
                          children: (
                            <>
                              <p>
                                {t(
                                  'This function allows you to set and organize tags for media.',
                                )}
                              </p>
                              <p>
                                {t(
                                  'You can also add your favorite keywords to make it easier to search for media.',
                                )}
                              </p>
                            </>
                          ),
                        }
                      : {};

    return <UpsellChild isChildEnvironment={isChildEnvironment} {...param} />;
  }

  return children;
};

export default Upsell;
