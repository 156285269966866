import type {
  UpdateParams,
  UpdateResult,
} from '@/repository/languageSettingsRepository';
import { languageSettingsRepository } from '@/repository/languageSettingsRepository';

import type { ServiceLanguage } from '@/entity/serviceLanguage';

export const getServiceLanguage = async (
  serviceId: string,
): Promise<ServiceLanguage> => {
  const { getServiceLanguage } = languageSettingsRepository(serviceId);
  return await getServiceLanguage();
};

export const updateServiceLanguage = async ({
  serviceId,
  serviceLanguage,
}: UpdateParams): Promise<UpdateResult> => {
  const { updateServiceLanguage } = languageSettingsRepository(serviceId);
  return await updateServiceLanguage(serviceLanguage);
};
