import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Breadcrumbs } from './Breadcrumbs';

import type { MigrateApi } from '@/entity/api';

import styles from './header.module.css';

import { Icon } from '@/views/Common/Ui/Icon';

type Props = {
  api: MigrateApi;
  userDefinedContentId: string;
};

const Header: React.FC<Props> = ({ api, userDefinedContentId }) => {
  const { t } = useTranslation('apisContentCompare');

  const history = useHistory();

  return (
    <header className={styles.main}>
      <h1 className={styles.title}>{t('Content difference management')}</h1>
      <div className={styles.nav}>
        <button
          type="button"
          onClick={() => history.goBack()}
          title={t('Back history')}
          aria-label={t('Back history')}
        >
          <Icon name="arrow_back_ios" outlined size="large" />
        </button>
        <div className={styles.spaceBar} />
        <Breadcrumbs
          apiName={api.apiName}
          apiEndpoint={api.apiEndpoint}
          userDefinedContentId={userDefinedContentId}
        />
      </div>
    </header>
  );
};

export { Header };
