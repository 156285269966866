import cx from 'classnames';
import { forwardRef, useCallback } from 'react';

import styles from './searchfield.module.css';

type Props = {
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEnter?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  className?: string;
  value?: string;
  defaultValue?: string;
  readOnly?: boolean;
  errorText?: string | null;
  size?: string;
  labelText?: string;
};

const Searchfield = (
  {
    placeholder,
    onChange = () => null,
    onEnter = () => null,
    style,
    className,
    value,
    defaultValue,
    readOnly,
    errorText = null,
    size = 'fixed',
    labelText,
  }: Props,
  ref: React.Ref<HTMLInputElement>,
) => {
  const _onEnter: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        errorText === null && onEnter();
      }
    },
    [errorText, onEnter],
  );
  return (
    <input
      type="text"
      className={cx(styles.input, styles[size], className)}
      placeholder={placeholder}
      onChange={onChange}
      onKeyDown={_onEnter}
      style={style}
      value={value}
      defaultValue={defaultValue}
      readOnly={readOnly}
      ref={ref}
      aria-label={labelText}
    />
  );
};

export default forwardRef(Searchfield);
