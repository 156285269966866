import type { ReviewRequestAccessInfo } from '@/API';
import type { MigrateApi } from '@/entity/api';
import { useApi } from '@/lib/useApi';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';

type Args = {
  api: MigrateApi;
  contentId: string;
};

export const useOpenedReviewRequestAccessInfo = ({ api, contentId }: Args) => {
  const openedReviewRequestAccessInfoQuery = useApi(
    ['reviewRequestAccessInfo', contentId],
    async () => {
      try {
        const result = await API.graphql(
          graphqlOperation(queries.getOpenedReviewRequestAccessInfo, {
            serviceId: api.gsiPartitionKey2,
            contentId,
          }),
        );
        const typedResult = result as {
          data: { getOpenedReviewRequestAccessInfo: ReviewRequestAccessInfo };
        };
        if (typedResult.data.getOpenedReviewRequestAccessInfo != null) {
          return typedResult.data.getOpenedReviewRequestAccessInfo;
        }

        return null;
      } catch (error) {
        if (error instanceof Error) {
          throw error;
        }
        throw new Error('Could not fetch review request access info');
      }
    },
    {
      enabled: !!api && !!contentId,
      staleTime: Number.POSITIVE_INFINITY,
    },
  );

  return openedReviewRequestAccessInfoQuery;
};
