import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

import { useSortable } from './useSortable';

import type {
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier,
  SensorDescriptor,
  SensorOptions,
  Modifier,
} from '@dnd-kit/core';
import type { SortingStrategy } from '@dnd-kit/sortable';

export type Props = {
  items: UniqueIdentifier[];
  setItems: (
    dispatch: (value: UniqueIdentifier[]) => UniqueIdentifier[],
  ) => void;
  sensors?: SensorDescriptor<SensorOptions>[];
  axis?: 'x' | 'y' | null;
  movableRange?: 'window' | 'parentElement' | 'scrollArea' | null;
  strategy?: 'reorder' | 'swap';
  children: React.ReactNode;
};

export type ViewProps = {
  items: UniqueIdentifier[];
  handleDragStart: (event: DragStartEvent) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragCancel: (event: DragEndEvent) => void;
  sensors: SensorDescriptor<SensorOptions>[];
  modifiers: Modifier[];
  strategy?: SortingStrategy;
  children: React.ReactNode;
};

export const SortableView: React.FC<ViewProps> = ({
  items,
  handleDragStart,
  handleDragEnd,
  handleDragCancel,
  sensors,
  modifiers,
  strategy,
  children,
}: ViewProps) => {
  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
      modifiers={modifiers}
    >
      <SortableContext items={items} strategy={strategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

export const Sortable: React.FC<Props> = (props) => {
  const hooks = useSortable(props);

  return <SortableView {...hooks} />;
};
