import Button from '@/components//ui/Button';
import type { MigrateApi } from '@/entity/api';
import { useHasCreateContentPermission } from '@/views/Common/Roles/MyRolesPermission';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './contentList.module.css';

type Props = {
  api: MigrateApi;
  options: {
    api: MigrateApi;
    offset: number;
    size: number;
    q: string;
    filters: string;
    orders?: string | undefined;
  };
  openImportModal: () => void;
};

export const ContentListEmpty: React.FC<Props> = ({
  api,
  options,
  openImportModal,
}) => {
  const { t } = useTranslation('contentList');

  const { hasCreateContentPermissionForAny, hasCreateContentPermission } =
    useHasCreateContentPermission();

  const hasCreatePermission = useMemo(() => {
    return hasCreateContentPermissionForAny(api.partitionKey);
  }, [api.partitionKey, hasCreateContentPermissionForAny]);

  const hasCreatePublishPermission = useMemo(() => {
    return hasCreateContentPermission('publish', api.partitionKey);
  }, [api.partitionKey, hasCreateContentPermission]);

  return (
    <div className={styles.empty}>
      <img className={styles.emptyIcon} src="/images/icon_empty.svg" alt="" />
      <p className={styles.emptyTitle}>{t('No content')}</p>
      {!hasCreatePermission ? (
        <p className={styles.emptySubText}>
          {t('Unauthorized to create contents')}
        </p>
      ) : (
        options.q === '' &&
        options.offset === 0 &&
        options.filters === '' && (
          // コンテンツ自体が0件の場合
          <div className={styles.emptyActions}>
            <p className={styles.emptySubText}>
              {Trans({
                t,
                i18nKey: hasCreatePublishPermission
                  ? 'Choose a CSV file to import or add contents.'
                  : 'Click the Add button to create content.',
              })}
            </p>
            <div className={styles.emptyButtons}>
              <Link
                to={`/apis/${api.apiEndpoint}/create`}
                className="ga-content-add-sub"
              >
                <Button
                  type="secondary"
                  size="large"
                  icon="add"
                  value={t('Add')}
                />
              </Link>
              {/* NOTE: 作成の公開の権限があれば、インポート可能なので */}
              {hasCreatePublishPermission && (
                <Button
                  type="tertiary"
                  size="large"
                  value={t('Import')}
                  onClick={openImportModal}
                />
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};
