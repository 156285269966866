import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToasts } from 'react-toast-notifications';

import { deleteRole as deleteRoleUsecase } from '@/usecase/roleUsecase';

import { useI18nFormRoleHelpers } from '../i18n';

import type { Role } from '@/entity/role';
import type { Service } from '@/types/services';

/**
 * Roleを削除するハンドラーとその結果を提供する
 */
function useDeleteRole(
  serviceId: Service['partitionKey'],
  roleId: Role['roleId'],
) {
  const cache = useQueryClient();
  const { t } = useI18nFormRoleHelpers();
  const { addToast } = useToasts();

  const { mutate, data, isLoading } = useMutation({
    mutationFn: async () => {
      return deleteRoleUsecase({
        serviceId,
        roleId,
      });
    },
    async onSuccess(data) {
      if (data) {
        await cache.invalidateQueries(['roleList'], { type: 'all' });
        cache.removeQueries(['formRole', { serviceId, roleId }]); // 削除したRoleのキャッシュを削除
        addToast(t('Deleted role'), {
          appearance: 'success',
        });
      }
    },
    onError(e) {
      console.error(e);
      addToast(t('Failed to delete'), { appearance: 'error' });
    },
  });

  return [mutate, data, isLoading] as const;
}

export { useDeleteRole };
