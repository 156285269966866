import { connect } from 'react-redux';

import { apiOperations } from '../../ducks/api';
import ImportStatus from './ImportStatus';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  resetImportProgress: apiOperations.resetImportProgress,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportStatus);
