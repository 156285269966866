import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';

import { useContent } from '@/hooks/Content/useContentReader';
import { useReview } from '@/hooks/Review/useReview';
import { useGetMyService } from '@/hooks/useService';

import Forbidden from '../Forbidden';
import Head from '../Head';
import { Events } from './Events';
import { Header } from './Header';
import { Information } from './Information';
import { PostComment } from './PostComment';
import { SideMenu } from './SideMenu';
import { Title } from './Title';

import type { ApiList, ApiListByRedux } from '@/entity/api';
import type { CognitoUser } from '@/entity/user';

import styles from './editReview.module.css';

import { apiListSelectors } from '@/ducks/apiList';
import { useAppSelector } from '@/store/hooks';
import { useReviewRequestReadPermission } from '@/views/Common/ReviewRequestPermission/useReviewRequestReadPermission';
import { useReviewRequestUpdatePermission } from '@/views/Common/ReviewRequestPermission/useReviewRequestUpdatePermission';
import { ContentsCompare } from '@/views/reviews/reqId/EditReview/ContentsCompare';
import { MainTabs } from '@/views/reviews/reqId/EditReview/MainTabs';
import { useAdditionalUpdateContentEvents } from './useAdditionalUpdateContentEvents';

type Props = {
  cognitoUser: CognitoUser;
};

const EditReview: React.FC<Props> = ({ cognitoUser }) => {
  const { t } = useTranslation('editReview');

  const { service } = useGetMyService();
  const { reqId } = useParams<{ reqId: string }>();

  // TODO: ReduxをReactQueryに置き換える
  const { apiList } = useAppSelector((state) =>
    apiListSelectors.get(state.apiListState as ApiList),
  ) as ApiListByRedux;

  const {
    reviewRequest,
    reviewRequestEvents,
    updateReviewRequest,
    updateReviewRequestLoading,
    updateReviewRequestReservationTime,
    updateReviewRequestReservationTimeLoading,
    toggleReviewRequestStatus,
    toggleReviewRequestStatusLoading,
    closeReviewRequestAndPublishContent,
    closeReviewRequestAndPublishContentLoading,
    createReviewRequestComment,
    createReviewRequestCommentLoading,
    deleteReviewRequestComment,
    deleteReviewRequestCommentLoading,
  } = useReview(service, reqId);

  // コンテンツデータ
  const [data] = useContent(reviewRequest && reviewRequest.contentId);
  const contentId: string | undefined = useMemo(() => {
    return data?.gsiSortKey2.replace(/.*#CONTENT_ID#/, '');
  }, [data]);
  // dataがnullの場合は権限がないと判断→getContentsByIdの挙動が権限がない場合nullを返すため
  const hasNotContentReadPermission = data === null;

  // レビュー申請以前に行われたコンテンツ更新を表示するために擬似的に作成したイベントデータ
  const { events: additionalEvents, isLoading: isAdditionalEventsLoading } =
    useAdditionalUpdateContentEvents({
      content: data ?? null,
      serviceId: service?.partitionKey ?? null,
      reqId,
      reviewRequestCreatedAt: reviewRequest?.createdAt ?? null,
    });

  // 保存されているレビューイベントと追加のイベントを結合
  const reviewRequestEventsWithAdditionalEvents = useMemo(() => {
    if (
      isAdditionalEventsLoading ||
      reviewRequestEvents === undefined ||
      additionalEvents === null
    ) {
      return undefined;
    }

    return additionalEvents.concat(reviewRequestEvents);
  }, [additionalEvents, isAdditionalEventsLoading, reviewRequestEvents]);

  // APIデータ
  const api = useMemo(() => {
    if (!apiList || !data) {
      return;
    }
    const apiId = data && data.gsiPartitionKey1.replace('#CONTENT', '');
    return apiList.find((item) => item.partitionKey === apiId);
  }, [apiList, data]);

  const [editing, setEditing] = useState(false);

  // 権限
  const hasReviewRequestReadPermission = useReviewRequestReadPermission({
    apiIds: [api?.partitionKey ?? ''],
  });
  const hasReviewRequestPermission = useReviewRequestUpdatePermission({
    apiId: api?.partitionKey ?? '',
  });
  const hasAllReviewUpdatePermission = hasReviewRequestPermission === 'ALL';
  const hasEditReviewUpdatePermission =
    hasReviewRequestPermission === 'ALL' ||
    hasReviewRequestPermission === 'EDIT';

  const deleteComment = useCallback(
    (eventId: string) => {
      window.confirm(t('Do you want to delete your comment?')) &&
        // @ts-expect-error
        deleteReviewRequestComment({ eventId });
    },
    [deleteReviewRequestComment, t],
  );

  const closeAndPublish = useCallback(() => {
    window.confirm(
      reviewRequest?.reservationTime
        ? t('Are you sure you want to schedule publishing?')
        : t('Are you sure you want to publish the content?'),
    ) &&
      // @ts-expect-error
      closeReviewRequestAndPublishContent({
        reservationTime: reviewRequest?.reservationTime,
        contentId,
      });
  }, [
    closeReviewRequestAndPublishContent,
    contentId,
    reviewRequest?.reservationTime,
    t,
  ]);

  if (reviewRequest === undefined || !service) {
    return null;
  }

  if (reviewRequest === null) {
    return <Redirect to={'/reviews'} />;
  }

  if (api && !hasReviewRequestReadPermission) {
    return <Forbidden />;
  }

  return (
    <div className={styles.wrapper}>
      <Head title={t('Review Details')} />
      <Header
        api={api}
        contentId={contentId}
        reviewRequest={reviewRequest}
        data={data}
        toggleReviewRequestStatus={toggleReviewRequestStatus}
        toggleReviewRequestStatusLoading={toggleReviewRequestStatusLoading}
        closeReviewRequestAndPublishContentLoading={
          closeReviewRequestAndPublishContentLoading
        }
        closeAndPublish={closeAndPublish}
        hasEditReviewUpdatePermission={hasEditReviewUpdatePermission}
        hasAllReviewUpdatePermission={hasAllReviewUpdatePermission}
      />
      <main className={styles.main}>
        <div className={styles.container}>
          <Title
            reviewRequest={reviewRequest}
            reqId={reqId}
            editing={editing}
            setEditing={setEditing}
            updateReviewRequest={updateReviewRequest}
            updateReviewRequestLoading={updateReviewRequestLoading}
            updateReviewRequestReservationTimeLoading={
              updateReviewRequestReservationTimeLoading
            }
            hasEditReviewUpdatePermission={hasEditReviewUpdatePermission}
          />
          <Information
            api={api}
            contentId={contentId}
            reviewRequest={reviewRequest}
          />
        </div>

        <MainTabs
          reviewStatus={reviewRequest.status}
          reviewChildren={
            <div className={styles.reviewChildren}>
              <div className={styles.reviewMainArea}>
                <Events
                  api={api}
                  userDefinedContentId={contentId}
                  reviewRequest={reviewRequest}
                  reviewRequestEvents={reviewRequestEventsWithAdditionalEvents}
                  hasAllReviewUpdatePermission={hasAllReviewUpdatePermission}
                  deleteComment={deleteComment}
                  deleteReviewRequestCommentLoading={
                    deleteReviewRequestCommentLoading
                  }
                />
                <div className={styles.postComment}>
                  {hasEditReviewUpdatePermission && (
                    <PostComment
                      username={cognitoUser.username}
                      createReviewRequestComment={createReviewRequestComment}
                      createReviewRequestCommentLoading={
                        createReviewRequestCommentLoading
                      }
                    />
                  )}
                </div>
              </div>
              <div className={styles.reviewSideMenuArea}>
                <SideMenu
                  serviceId={service.partitionKey}
                  reviewRequest={reviewRequest}
                  hasEditReviewUpdatePermission={hasEditReviewUpdatePermission}
                  updateReviewRequestLoading={updateReviewRequestLoading}
                  updateReviewRequestReservationTime={
                    updateReviewRequestReservationTime
                  }
                  updateReviewRequestReservationTimeLoading={
                    updateReviewRequestReservationTimeLoading
                  }
                  setEditing={setEditing}
                />
              </div>
            </div>
          }
          comparisonChildren={
            <ContentsCompare
              api={api}
              contentId={data?.partitionKey}
              currentStatus={data?.contentStatus}
              forbidden={
                hasNotContentReadPermission || (data !== undefined && !api) // dataが読み込み中でないかつapiの読み取り権限がない場合
              }
            />
          }
          className={styles.mainTabs}
        />
      </main>
    </div>
  );
};

export default EditReview;
