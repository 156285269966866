import type { ServiceUser } from '@/entity/member';

export default (members: ServiceUser[], order?: string) => {
  if (!order) {
    return members;
  }

  return members?.sort((a, b) => {
    if (order === 'default') {
      return (a.name || a.email).localeCompare(b.name || b.email);
    } else if (order === '-default') {
      return (b.name || b.email).localeCompare(a.name || a.email);
    } else if (order === 'email') {
      return (a.email || '').localeCompare(b.email || '');
    } else if (order === '-email') {
      return (b.email || '').localeCompare(a.email || '');
    } else if (order === 'roles') {
      return (a.roleIds || []).join().localeCompare((b.roleIds || []).join());
    } else if (order === '-roles') {
      return (b.roleIds || []).join().localeCompare((a.roleIds || []).join());
    } else if (order === 'twofactor') {
      return (a.mfaSetting || false) === (b.mfaSetting || false)
        ? 0
        : a.mfaSetting || false
          ? 1
          : -1;
    } else if (order === '-twofactor') {
      return (a.mfaSetting || false) === (b.mfaSetting || false)
        ? 0
        : a.mfaSetting || false
          ? -1
          : 1;
    }
    return 0;
  });
};
