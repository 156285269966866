import { useCallback } from 'react';

import type { Props } from './HorizontalRule';

export const useHorizontalRule = ({ editor }: Props) => {
  const setHorizontalRuleHandler = useCallback(() => {
    editor.chain().focus().setHorizontalRule().run();
  }, [editor]);

  return {
    setHorizontalRuleHandler,
  };
};
