import { type ReviewTarget, reviewTarget } from '@/constants/reviewRequest';
import type { ContentStatus } from '@/entity/content';
import { type ReviewRequestEvent, createServiceIdReqId } from '@/entity/review';
import type { Contents } from '@/types/contents';
import { parseHistoryVersion } from '@/util/history-version';
import { useContentHistories } from '../ContentHistories/useContentHistories';

type Args = {
  /**
   * useContentで取得できるデータ
   */
  content: Contents | null;
  serviceId: string | null;
  reqId: string;
  reviewRequestCreatedAt: string | null;
};

/**
 * レビューリクエストを出す以前の「コンテンツ更新」について表示するために、コンテンツ履歴のデータから擬似的にイベントを生成する
 */
const useAdditionalUpdateContentEvents = ({
  content,
  serviceId,
  reqId,
  reviewRequestCreatedAt,
}: Args): { events: ReviewRequestEvent[] | null; isLoading: boolean } => {
  const { data, fetchNextPage, hasNextPage, isFetching } = useContentHistories(
    content?.partitionKey,
  );

  // 外から受け取っているデータがまだ取得できていない状態の場合はローディング中として扱う
  if (!content || !serviceId || reviewRequestCreatedAt === null) {
    return { events: null, isLoading: true };
  }

  if (isFetching) {
    return { events: null, isLoading: true };
  }

  if (!data) {
    return { events: [], isLoading: false };
  }

  const histories = buildHistories(data.pages, content);

  const {
    targetHistories,
    historyOnReviewRequest,
    neededNextPage: neededNextPageInTargetHistories,
  } = buildTargetHistories(histories, reviewRequestCreatedAt);
  if (neededNextPageInTargetHistories) {
    if (hasNextPage) {
      fetchNextPage();

      return { events: null, isLoading: true };
    }

    // IC-90 なんらかの原因でコンテンツ履歴が正常に作成されていないケースの考慮
    // レビュー申請を出した時点の履歴が存在しない場合は、レビュー申請前のコンテンツ更新は無視して処理する
    return { events: [], isLoading: false };
  }

  // レビュー申請を出した時点でのコンテンツのステータスから、何をするためのレビューリクエストかを判定する
  const reviewTarget = decideReviewTarget(historyOnReviewRequest.contentStatus);

  const { subjectHistories, neededNextPage: neededNextPageSubjectHistories } =
    buildSubjectHistories(targetHistories, reviewTarget, hasNextPage ?? false);
  if (neededNextPageSubjectHistories) {
    fetchNextPage();

    return { events: null, isLoading: true };
  }

  // 対象のコンテンツ履歴のデータから、「コンテンツ更新」のレビューイベントを生成する
  const events: ReviewRequestEvent[] = subjectHistories.map(
    (history): ReviewRequestEvent =>
      createReviewRequestEventByContentHistory(history, serviceId, reqId),
  );

  return { events: events, isLoading: false };
};

/**
 * 取得が完了した履歴データと最新のコンテンツデータから、その時点での履歴データを返す
 */
const buildHistories = (
  pages: NonNullable<ReturnType<typeof useContentHistories>['data']>['pages'],
  latestContent: Contents,
): Contents[] => {
  const histories: Contents[] = pages.flatMap((page) => page?.items ?? []);
  // コンテンツ更新履歴には現在の状態のデータが含まれていないため、最新の履歴に現在のデータを追加する
  const latestHistory = histories.at(0);
  histories.unshift({
    ...latestContent,
    sortKey: `VERSION#${latestHistory ? (parseHistoryVersion(latestHistory.sortKey) + 1).toString().padStart(6, '0') : '000001'}`,
  });

  return histories;
};

/**
 * レビューイベントに用いる履歴データを生成する
 * 内部的にはレビュー申請を出した時点の履歴を基準に、それ以前の履歴データを取得している
 */
const buildTargetHistories = (
  histories: Contents[],
  reviewRequestCreatedAt: string,
):
  | {
      targetHistories: Contents[];
      historyOnReviewRequest: Contents;
      neededNextPage?: undefined;
    }
  | {
      targetHistories?: undefined;
      historyOnReviewRequest?: undefined;
      neededNextPage: true;
    } => {
  // レビュー申請を出した時点の履歴
  const historyOnReviewRequestIndex = histories.findIndex(
    (history) => reviewRequestCreatedAt >= history.updatedAt,
  );
  const historyOnReviewRequest = histories[historyOnReviewRequestIndex];

  if (historyOnReviewRequest === undefined) {
    // レビュー申請を出した時点の履歴が存在しない場合は、次のページを要求する
    return { neededNextPage: true };
  }

  // レビュー申請を出した時点以前の履歴のみ使用する
  const targetHistories = histories.slice(historyOnReviewRequestIndex);

  return { targetHistories, historyOnReviewRequest };
};

/**
 * コンテンツのステータスから、何をするためのレビューリクエストかを判定する
 */
const decideReviewTarget = (
  contentStatus: ContentStatus,
): ReviewTarget | null => {
  switch (contentStatus) {
    case 'DRAFT': {
      return reviewTarget.DRAFT_TO_PUBLISH;
    }
    case 'PUBLISH_AND_DRAFT': {
      return reviewTarget.UPDATE;
    }
    default: {
      // 現状では「下書き中」か「公開中かつ下書き中」でしかレビュー申請を出すことができないため、それ以外のステータスの場合はnullを返す
      return null;
    }
  }
};

/**
 * レビューイベントの生成に使用する履歴データを生成する
 */
const buildSubjectHistories = (
  targetHistories: Contents[],
  reviewTarget: ReviewTarget | null,
  hasNextPage: boolean,
):
  | { subjectHistories: Contents[]; neededNextPage?: undefined }
  | { subjectHistories?: undefined; neededNextPage: true } => {
  let endSliceIndex = -1;

  if (reviewTarget === 'UPDATE') {
    // 最後に公開された時点の履歴を取得する
    endSliceIndex = targetHistories.findIndex(
      (history) => history.contentStatus === 'PUBLISH',
    );

    // 公開済みの履歴が存在しない場合は、次のページを取得する
    if (endSliceIndex === -1) {
      return { neededNextPage: true };
    }
  } else if (reviewTarget === 'DRAFT_TO_PUBLISH') {
    // 下書きから公開する場合は、最後に下書き以外のステータスだった時の履歴を取得する
    endSliceIndex = targetHistories.findIndex(
      (history) => history.contentStatus !== 'DRAFT',
    );

    // 下書き以外のステータスの履歴がないかつ次のページが存在する場合は、次のページを取得する
    if (endSliceIndex === -1 && hasNextPage) {
      return { neededNextPage: true };
    }

    // 下書き以外のステータスの履歴がない場合は、 最後の履歴から1つ前まで取得するため配列の長さ - 1を指定する
    if (endSliceIndex === -1) {
      endSliceIndex = targetHistories.length - 1;
    }
  }

  // レビューイベント生成のためのデータを用意する。降順に表示するため、reverseする
  const subjectHistories = targetHistories.slice(0, endSliceIndex).reverse();

  return { subjectHistories };
};

const createReviewRequestEventByContentHistory = (
  history: Contents,
  serviceId: string,
  reqId: string,
): ReviewRequestEvent => {
  return {
    serviceIdReqId: createServiceIdReqId(serviceId, reqId),
    eventId: `updatecontent-${history.sortKey}`, // Reactのkeyに使用するため、一意な値を設定

    createdAt: history.updatedAt,
    updatedAt: history.updatedAt,
    authorId: history.editorId,

    type: 'UPDATE_CONTENT',
    comment: null,
    oldStatus: null,
    newStatus: null,
    oldTitle: null,
    newTitle: null,
    reservationTime: null,
    reservationStopTime: null,
    historyVersion: history.sortKey,
    reviewerId: null,
  };
};

export {
  buildHistories,
  buildTargetHistories,
  decideReviewTarget,
  buildSubjectHistories,
  createReviewRequestEventByContentHistory,
  useAdditionalUpdateContentEvents,
};
