import cx from 'classnames';
import dayjs from 'dayjs';
import { filesize } from 'filesize';
import type React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';

import {
  useMedia,
  useMedium,
  useMediumCount,
} from '@/hooks/Medium/useMediumReader';
import useMediumWriter from '@/hooks/Medium/useMediumWriter';
import { useListMediumTags } from '@/hooks/MediumTags/useMediumTags';
import { usePermissionIsHaveLeastOne } from '@/hooks/Role/useMyRoles';
import { useDebounce } from '@/hooks/useDebounce';
import { useMatchMedia } from '@/hooks/useMatchMedia';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';

import CheckRoles from '../CheckRoles';
import Feedback from '../Feedback';
import Head from '../Head';
import MediaApiRequest from '../MediaApiRequest';
import MediaFilter from '../MediaFilter';
import { MediaFilterList } from '../MediaFilterList';
import MediaJson from '../MediaJson';
import Member from '../Member';
import S3Image from '../S3Image';
import { validateFileName, validateMedium } from '../Validations';
import Button from '../ui/Button';
import IconWithTextButton from '../ui/IconWithTextButton';
import Searchfield from '../ui/Searchfield';
import { Table, Tbody, Td, Th, Thead, Tr } from '../ui/Table';
import { useMediaFilter } from './useMediaFilter';

import type { Medium } from '@/entity/medium';

import styles from './media.module.css';

import { isMobile } from '@/util';
import { Modal, ModalContent } from '@/views/Common/Ui/Modal';
import {
  SideModal,
  SideModalContent,
  SideModalTrigger,
} from '@/views/Common/Ui/SideModal';
import { useToggle } from '@/views/Common/Ui/useToggle';
import { useInput } from '@/views/Common/useInput';

// fix:Reduxの型
interface Props {
  onClick: (medium: Medium) => () => void;
  onlyImage: boolean;
  inModal?: boolean;
}

const Media: React.FC<Props> = ({
  onClick,
  onlyImage = false,
  inModal = false,
}) => {
  const { service } = useGetMyService();
  const { t } = useTranslation('media');

  // 権限を取得
  const [hasCreatePermission] = usePermissionIsHaveLeastOne(
    'mediumPermission',
    'create',
  );
  const { currentPlan } = useStripeActions(service);

  const ddArea = useRef<HTMLDivElement>(null);
  const defaultType = useMemo(() => {
    const item = window.localStorage.getItem('mediaType');
    return item === null ? 0 : Number(item);
  }, []);
  const [type, setType] = useState(defaultType); // 0: list, 1: image
  const changeType = useCallback((value: any) => {
    window.localStorage.setItem('mediaType', value);
    setType(value);
  }, []);

  // mediaQuery (max-width: 600px) を判別する
  const isMatchMediaQueryMobile = useMatchMedia('(max-width: 600px)');

  //検索
  const [inputSearchText, handleChangeInputSearchText, errorInputSearchText] =
    useInput('', validateFileName, true);
  const [searchText] = useDebounce(inputSearchText, 300); //実際に検索を行うのは少し待ってからにする

  const updateFileEl = useRef<HTMLInputElement>(null);
  const scrollRef = useRef<HTMLLIElement>(null);

  // フィルター機能
  const [isOpenFilterModal, toggleFilterModal] = useToggle(false);
  const openFilterModal = useCallback(
    () => toggleFilterModal(true),
    [toggleFilterModal],
  );
  const closeFilterModal = useCallback(
    () => toggleFilterModal(false),
    [toggleFilterModal],
  );

  const {
    defaultFiltersQuery,
    defaultFiltersArray,
    onSubmitFiltersArray,
    addFiltersTag,
  } = useMediaFilter();
  const { listMediumTags } = useListMediumTags(service?.partitionKey || '');

  //HooksでReadWrite
  const {
    upload: uploadMedium,
    update: updateMedium,
    deleteMedium,
    progress: mediumWriteProgress,
    inputFileEl,
  }: any = useMediumWriter(service);
  const [mediumCount] = useMediumCount(service?.partitionKey, { onlyImage });

  const mediaQuery = useMedia(
    service?.partitionKey,
    onlyImage,
    searchText,
    defaultFiltersQuery,
  );
  const { data, isLoading, isError, hasNextPage, fetchNextPage } = mediaQuery;
  const media = useMemo(() => {
    return data ? data.pages.flatMap(({ items }) => items) : [];
  }, [data]);

  const [selectedMedium, setMedium] = useState<Medium | undefined>(undefined);
  const [validationResult, setValidationResult] = useState<string | null>(null);
  const [isOpenPlanModal, togglePlanModal] = useToggle(false);
  const isImage = useCallback((medium: any) => {
    return medium?.kind === 'IMAGE';
  }, []);
  const inputFiles = useCallback(
    async (files: any) => {
      for (const f of files) {
        //バリデーション
        const res = validateMedium(f, currentPlan);
        setValidationResult(res);
        if (res !== null) {
          inputFileEl.current.value = ''; //同じファイルを2回連続選択してもonChangeが効くようにする
          setTimeout(() => setValidationResult(null), 3000);
          return;
        }

        //無料プランだと画像のみをアップロードできる
        if (
          currentPlan &&
          currentPlan.limit.fileUpload === false &&
          !f.type.match(/^image\/.*/)
        ) {
          inputFileEl.current.value = '';
          togglePlanModal(true);
          return;
        }
      }
      //アップロード
      await uploadMedium(files);
    },
    [uploadMedium, currentPlan, inputFileEl, togglePlanModal],
  );
  useEffect(() => {
    if (
      service?.domain === undefined ||
      media === undefined ||
      !hasCreatePermission
    ) {
      return;
    }
    const area = ddArea.current;
    function dragoverFunc(e: DragEvent) {
      e.preventDefault();
      area?.classList.add(styles.dragover);
    }
    function dragleaveFunc(e: DragEvent) {
      e.preventDefault();
      area?.classList.remove(styles.dragover);
    }
    function dropFunc(e: DragEvent) {
      e.preventDefault();
      area?.classList.remove(styles.dragover);
      const files = e.dataTransfer?.files;
      inputFiles(files);
    }
    area?.addEventListener('dragover', dragoverFunc);
    area?.addEventListener('dragleave', dragleaveFunc);
    area?.addEventListener('drop', dropFunc);
    return () => {
      area?.removeEventListener('dragover', dragoverFunc);
      area?.removeEventListener('dragleave', dragleaveFunc);
      area?.removeEventListener('drop', dropFunc);
    };
  }, [service?.domain, inputFiles, hasCreatePermission, media]);

  useEffect(() => {
    if (media && selectedMedium === undefined) {
      setMedium(media[0]);
    }
  }, [media, selectedMedium]);

  useEffect(() => {
    if (uploadMedium) {
      setMedium(media[0]);
    }
  }, [media, uploadMedium]);

  useEffect(() => {
    if (media?.length !== 0) {
      setMedium(media[0]);
    }
  }, [media]);

  useEffect(() => {
    if (mediumWriteProgress) {
      scrollRef?.current?.scrollIntoView();
    }
  }, [mediumWriteProgress]);

  const onClickButton = useCallback(
    () => inputFileEl.current.click(),
    [inputFileEl],
  );
  const select = useCallback((medium: any) => () => setMedium(medium), []);

  const deleteMediumWithConfirm = useCallback(
    (medium: any) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      const res = window.confirm(
        t('Delete selected media. Are you sure delete it?'),
      );
      if (res) {
        deleteMedium({
          medium,
          setMedium,
          onlyImage,
          text: searchText,
          filters: defaultFiltersQuery,
        });
      }
    },
    [defaultFiltersQuery, deleteMedium, onlyImage, searchText, t],
  );

  const mediumRelatedContent = useMedium(
    service?.partitionKey,
    selectedMedium && selectedMedium.mediumId,
  );
  const contentIds: string[] | undefined = useMemo(
    () =>
      mediumRelatedContent?.relatedContentIds?.map((id: any) =>
        id?.partitionKey?.replace(/.*MEDIUM_RELATION#/, ''),
      ),
    [mediumRelatedContent],
  );

  if (!service) {
    return null;
  }

  return (
    <div className={cx(styles.wrapper, inModal && styles.inModal)} ref={ddArea}>
      <Head title={t('Media')} />
      {mediumWriteProgress && (
        <div className={styles.uploading}>
          <progress
            value={mediumWriteProgress.loaded}
            max={mediumWriteProgress.total}
            className={styles.progress}
          />
          <p>{t('Uploading')}</p>
        </div>
      )}
      <header className={styles.header}>
        <div className={styles.inputSearchWrapper}>
          <Searchfield
            onChange={handleChangeInputSearchText}
            className={cx(styles.inputSearch, styles.override)}
          />
          {errorInputSearchText && (
            <p role="alert" className={styles.errorText}>
              {errorInputSearchText}
            </p>
          )}
        </div>
        <span className={styles.filter}>
          <SideModal open={isOpenFilterModal} onClose={closeFilterModal}>
            <SideModalTrigger asChild>
              <IconWithTextButton
                icon="filter_list"
                text={t('Filter')}
                onClick={openFilterModal}
                className="track-click-by-gtm"
                data-gtm-track-event-name="click_media_filter"
              />
            </SideModalTrigger>
            <SideModalContent title={t('Filters')} titleAs="h3">
              <MediaFilter
                serviceId={service.partitionKey}
                defaultFiltersArray={defaultFiltersArray}
                onSubmitFiltersArray={onSubmitFiltersArray}
                close={closeFilterModal}
                listMediumTags={listMediumTags}
              />
            </SideModalContent>
          </SideModal>
          {defaultFiltersArray.length !== 0 && (
            <span className={styles.filtersLength}>
              {defaultFiltersArray.length}
            </span>
          )}
        </span>
        <CheckRoles permission="mediumPermission" operation="create">
          <div className={styles.uploadButton}>
            <Button
              icon="add"
              type="secondary"
              value={t('Upload')}
              disabled={mediumWriteProgress}
              onClick={onClickButton}
            />
            <input
              type="file"
              ref={inputFileEl}
              accept={onlyImage || isMobile() ? 'image/*' : '*/*'} //スマホはファイルを上げづらいので画像のみとする
              onChange={(e) => inputFiles(e.target.files)}
              multiple={true}
              style={{ display: 'none' }}
              data-testid="media-upload-file-input"
            />
          </div>
        </CheckRoles>
        <Feedback
          message={validationResult !== null ? validationResult : undefined}
        />
      </header>
      <div className={styles.meta}>
        <p>
          {t('Number of items', {
            total: mediumCount,
            displayCount: media?.length || 0,
          })}
        </p>
        <SideModal>
          <SideModalTrigger asChild>
            <IconWithTextButton
              icon="send"
              text={t('Preview Image API')}
              disabled={media?.length === 0 || !isImage(selectedMedium)}
            />
          </SideModalTrigger>
          <SideModalContent size="large">
            <MediaApiRequest
              medium={selectedMedium}
              customDomainImageHost={service.customDomainImageHost}
            />
          </SideModalContent>
        </SideModal>
      </div>
      {defaultFiltersArray.length > 0 && (
        <MediaFilterList
          serviceId={service.partitionKey}
          defaultFiltersArray={defaultFiltersArray}
          onSubmitFiltersArray={onSubmitFiltersArray}
          listMediumTags={listMediumTags}
        />
      )}
      {media?.length !== 0 && (
        <ul className={cx(styles.types)}>
          <li>
            <button
              className={
                type === 0
                  ? `${styles.typeButton} ${styles.active}`
                  : styles.typeButton
              }
              onClick={() => changeType(0)}
            >
              <i className="material-icons">list</i>
            </button>
          </li>
          <li>
            <button
              className={
                type === 1
                  ? `${styles.typeButton} ${styles.active}`
                  : styles.typeButton
              }
              onClick={() => changeType(1)}
            >
              <i className="material-icons-outlined">insert_photo</i>
            </button>
          </li>
        </ul>
      )}
      {isLoading ? (
        <div className={styles.empty}>
          <img src="/images/icon_loading.svg" alt="" />
        </div>
      ) : isError ? (
        <p className={styles.empty}>{t('Invalid parameter')}</p>
      ) : media?.length === 0 && !searchText && !defaultFiltersQuery ? (
        <div className={styles.empty}>
          <img
            className={styles.emptyIcon}
            src="/images/icon_media_library.svg"
            alt=""
          />
          <p className={styles.emptyTitle}>{t('No media')}</p>
          <p className={styles.emptySubText}>
            {t('You can drag and drop file here')}
          </p>
        </div>
      ) : media?.length === 0 ? (
        <p className={styles.empty}>{t('No files found')}</p>
      ) : (
        media?.length > 0 &&
        selectedMedium && (
          <div className={styles.container}>
            <div className={styles.mediaListWrapper}>
              {type === 0 && !isMatchMediaQueryMobile && (
                <div className={styles.tableWrap}>
                  <Table>
                    <Thead hasBottomSpace={true}>
                      <Tr>
                        <Th></Th>
                        <Th isLong={true}>{t('Filename')}</Th>
                        <Th>{t('Format')}</Th>
                        <Th>{t('File Size')}</Th>
                        <Th>{t('Image Size')}</Th>
                        <Th>{t('Created By')}</Th>
                        <Th>{t('Date Created')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {media.map((medium) => (
                        <Tr
                          key={medium.mediumId}
                          onClick={select(medium)}
                          isSelected={
                            selectedMedium.mediumId === medium.mediumId
                          }
                          isSlim={true}
                        >
                          <Td>
                            {isImage(medium) ? (
                              <div>
                                <LazyLoad offset={100} overflow once>
                                  <S3Image
                                    className={`${styles.thumbnail}`}
                                    directory={medium.directory}
                                    fileName={medium.fileName}
                                    kind={medium.kind}
                                    queryString={`?w=64&h=64&fit=crop&crop=entropy&auto=format,compress`}
                                    customDomainImageHost={
                                      service.customDomainImageHost
                                    }
                                  />
                                </LazyLoad>
                              </div>
                            ) : (
                              <div>
                                <i
                                  className={`material-icons-outlined ${styles.smallIcon}`}
                                >
                                  insert_drive_file
                                </i>
                              </div>
                            )}
                          </Td>
                          <Td>{medium.fileName}</Td>
                          <Td>{medium.contentType}</Td>
                          <Td>{filesize(medium.contentLength)}</Td>
                          <Td>
                            {medium.imageWidth && medium.imageHeight ? (
                              <p>
                                {medium.imageWidth} × {medium.imageHeight}
                              </p>
                            ) : (
                              <p> - </p>
                            )}
                          </Td>
                          <Td>
                            <Member
                              username={medium.uploader}
                              theme={
                                selectedMedium.mediumId === medium.mediumId
                                  ? 'current'
                                  : 'black'
                              }
                            />
                          </Td>
                          <Td>{dayjs(medium.createdAt).fromNow()}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              )}
              {(type === 1 || isMatchMediaQueryMobile) && (
                <ul className={styles.imageLists}>
                  <li ref={scrollRef}></li>
                  {media.map((medium) => (
                    <li
                      key={medium.mediumId}
                      onClick={select(medium)}
                      onDoubleClick={onClick && onClick(selectedMedium)}
                      className={`${styles.imageList} ${
                        selectedMedium.mediumId === medium.mediumId
                          ? styles.isSelected
                          : ''
                      }`}
                    >
                      {isImage(medium) ? (
                        <div className={styles.isLargeThumbnail}>
                          <LazyLoad offset={100} overflow once>
                            <S3Image
                              className={`${styles.thumbnail} ${styles.isLarge}`}
                              directory={medium.directory}
                              fileName={medium.fileName}
                              kind={medium.kind}
                              queryString={`?w=160&h=160&fit=crop&crop=entropy&auto=format,compress`}
                              customDomainImageHost={
                                service.customDomainImageHost
                              }
                            />
                          </LazyLoad>
                        </div>
                      ) : (
                        <div className={styles.isLargeThumbnail}>
                          <i
                            className={`material-icons-outlined ${styles.largeIcon}`}
                          >
                            insert_drive_file
                          </i>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              )}
              {hasNextPage && (
                <div className={styles.moreLink}>
                  <Button
                    value={t('See more')}
                    type="tertiary"
                    size="full"
                    onClick={() => fetchNextPage()}
                  />
                </div>
              )}
            </div>
            <div className={styles.detail}>
              <div
                className={cx(styles.flexDetail, {
                  [styles.isProtected]:
                    selectedMedium.directory.indexOf('protected') === 0,
                })}
              >
                <div className={styles.imageWrap}>
                  {isImage(selectedMedium) ? (
                    <div className={styles.fileImageWrap}>
                      <S3Image
                        className={styles.selectedImage}
                        directory={selectedMedium.directory}
                        fileName={selectedMedium.fileName}
                        queryString={
                          selectedMedium.imageHeight &&
                          selectedMedium.imageHeight > 300
                            ? '?auto=format,compress&h=300'
                            : '?auto=format,compress'
                        }
                        kind={selectedMedium.kind}
                        customDomainImageHost={service.customDomainImageHost}
                      />
                    </div>
                  ) : (
                    <div
                      className={`${styles.fileImageWrap} ${styles.fileImageIcon}`}
                    >
                      <img
                        alt=""
                        src="/images/icon_file.svg"
                        className={styles.fileImage}
                      />
                      <p className={styles.fileExtension}>
                        {selectedMedium.fileName.includes('.')
                          ? selectedMedium.fileName.split('.')[1].toUpperCase()
                          : ''}
                      </p>
                    </div>
                  )}
                  <div className={styles.mediaJson}>
                    <MediaJson
                      key={selectedMedium.mediumId}
                      medium={selectedMedium}
                      contentIds={contentIds}
                      service={service}
                      listMediumTags={listMediumTags}
                      addFiltersTag={addFiltersTag}
                    />
                  </div>
                </div>
                {onClick ? (
                  <div className={styles.actions}>
                    <Button
                      value={
                        isImage(selectedMedium)
                          ? t('Use this image')
                          : t('Use this file')
                      }
                      type="secondary"
                      size="full"
                      onClick={onClick(selectedMedium)}
                    />
                  </div>
                ) : (
                  <div className={styles.actions}>
                    <div className={styles.actionsButtonArea}>
                      <CheckRoles
                        permission="mediumPermission"
                        operation="update"
                      >
                        <div className={styles.buttonWrapper}>
                          <Button
                            onClick={() => {
                              if (updateFileEl.current === null) return null;
                              updateFileEl.current.click();
                            }}
                            type="tertiary"
                            value={t('Re-upload')}
                            size="full"
                            disabled={
                              selectedMedium?.directory.indexOf('protected') ===
                              0
                            }
                          />
                        </div>
                        <input
                          type="file"
                          ref={updateFileEl}
                          accept={isMobile() ? 'image/*' : '*/*'} //スマホはファイルを上げづらいので画像のみとする
                          onChange={(e) => {
                            if (e.target.files === null) return;
                            const file = e.target.files[0];
                            if (!file) {
                              return;
                            }
                            if (
                              window.confirm(
                                t(
                                  'Existing media will be deleted. Are you sure you want to update?',
                                ),
                              )
                            ) {
                              updateMedium(selectedMedium, file, setMedium);
                            }
                          }}
                          multiple={false}
                          style={{ display: 'none' }}
                          data-testid="media-reupload-file-input"
                        />
                      </CheckRoles>
                      <CheckRoles
                        permission="mediumPermission"
                        operation="delete"
                      >
                        <div className={styles.buttonWrapper}>
                          <Button
                            onClick={deleteMediumWithConfirm(selectedMedium)}
                            value={t('Delete')}
                            type="danger"
                            size="full"
                          />
                        </div>
                      </CheckRoles>
                    </div>
                    {selectedMedium.directory.indexOf('protected') === 0 && (
                      <CheckRoles
                        permission="mediumPermission"
                        operation="update"
                      >
                        <p className={styles.note}>
                          {t(
                            'Images uploaded using the old method will not be able to use the re-upload function',
                          )}
                        </p>
                      </CheckRoles>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        )
      )}
      <Modal open={isOpenPlanModal} onOpenChange={togglePlanModal}>
        <ModalContent size="medium">
          <div className={styles.lockModalContainer}>
            <img
              className={styles.lockImg}
              src="/images/icon_lock_fill.svg"
              alt=""
            />
            <p className={styles.lockTitle}>
              {t('Not available on current plans')}
            </p>
            <p className={styles.lockDescription}>
              {t(
                'File uploads other than images are not available with the current plan',
              )}
            </p>
            <Link to="/settings/billing">
              <Button
                type="primary"
                size="large"
                className={styles.modalLargeButton}
                value={t('Upgrade this service')}
              />
            </Link>
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Media;
