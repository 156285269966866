import type React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetMyServices } from '@/hooks/ServiceList/useServiceList';

import useDeleteAccount from './useDeleteAccount';
import Feedback from '../../Feedback';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';

import styles from './delete.module.css';

import { isSAMLUser as getIsSAMLUserFlag } from '@/util';

type Props = {
  signOut: () => void;
};

const Delete: React.FC<Props> = ({ signOut }) => {
  const { t } = useTranslation('profileDelete');
  const { services } = useGetMyServices();
  const [result, error, deleteAccount, loading] = useDeleteAccount();

  const hasService = useMemo(
    () => services === undefined || services?.list?.length !== 0,
    [services],
  );

  const isSAMLUser = useMemo(() => getIsSAMLUserFlag(), []);

  const deleteWithConfirm = useCallback(async () => {
    window.confirm(t('Are you sure you want to delete your account?')) &&
      // @ts-expect-error
      (await deleteAccount());
  }, [deleteAccount, t]);

  useEffect(() => {
    result && loading === false && signOut();
  }, [result, loading, signOut]);

  if (isSAMLUser) {
    return (
      <div>
        <h2 className={styles.title}>{t('Close Account')}</h2>
        <div className={styles.wrapper}>
          <p>{t('This setting cannot be changed with your account. ')}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2 className={styles.title}>{t('Close Account')}</h2>
      <Fieldset
        legend={t('Account Deletion')}
        description={t(
          'Click the button below to delete your account. An account cannot be deleted if the account is associated with a service (or more).',
        )}
      >
        <Button
          type="danger"
          gaon="click"
          gaeventcategory="account"
          gaeventaction="delete"
          // @ts-expect-error
          disabled={hasService || loading}
          value={t('Delete your Account')}
          onClick={deleteWithConfirm}
        />
        {hasService && (
          <p className={styles.notice}>
            {t(
              'Unable to delete this account as it is associated with a service (or more).',
            )}
          </p>
        )}
      </Fieldset>
      <Feedback
        type={'failure'}
        message={error ? t('Could not delete this account.') : undefined}
      />
    </div>
  );
};

export default Delete;
