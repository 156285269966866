import cx from 'classnames';
import { nanoid } from 'nanoid';
import type React from 'react';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/ui/Button';
import Fieldset from '@/components/ui/Fieldset';
import IconButton from '@/components/ui/IconButton';
import Selectfield from '@/components/ui/Selectfield';
import Switch from '@/components/ui/Switch';
import Textfield from '@/components/ui/Textfield';

import { useDragAndDrop } from '../../../hooks/DragAndDrop/useDragAndDrop';

import styles from './inputSelectField.module.css';

import { Popover } from '@/views/ApiModel/Popover';
import { CheckboxGroup } from '@/views/Common/Ui/CheckboxGroup';

type Item = {
  id: string;
  value: string;
};

type InputSelectFieldProps = {
  items: Item[];
  multiple: boolean;
  initialValue: string[];
  setError: React.Dispatch<boolean>;
  setField: (items: Item[], multiple: boolean, initialValue: string[]) => void;
  isAdditionalField?: boolean;
};

export const InputSelectField: React.FC<InputSelectFieldProps> = ({
  items,
  setField,
  multiple,
  initialValue,
  setError,
  isAdditionalField,
}) => {
  const { t } = useTranslation('inputSelectField');

  const onChangeOrder = useCallback(
    (x: any, y: any) => {
      if (x === y) {
        return;
      }
      const copy = [...items];
      copy.splice(y, 0, items[x]);
      if (x < y) {
        copy.splice(x, 1);
      } else {
        copy.splice(x + 1, 1);
      }
      setField(copy, multiple, initialValue);
    },
    [items, multiple, setField, initialValue],
  );
  const { Handle, draggable, onDragStart, onDragOver, onDragEnd, targetIndex } =
    // @ts-ignore
    useDragAndDrop(onChangeOrder);
  const checkError = useCallback(
    (items: Item[]) => {
      const hasError = items.some((item) => item.value === '');
      setError(hasError);
    },
    [setError],
  );
  const onChangeText = useCallback(
    (id: any) => (e?: React.ChangeEvent<HTMLInputElement>) => {
      if (e === undefined) {
        return;
      }
      const { value } = e.target;
      const newItems = items.map((item) => {
        if (item.id === id) {
          return {
            id,
            value,
          };
        }
        return item;
      });
      checkError(newItems);

      setField(newItems, multiple, initialValue);
    },
    [checkError, items, multiple, initialValue, setField],
  );
  const onChangeMultiple = useCallback(
    (newMultiple: boolean) => {
      const newInitialValue = newMultiple
        ? initialValue
        : initialValue.slice(0, 1);
      setField(items, newMultiple, newInitialValue);
    },
    [items, initialValue, setField],
  );
  const onChangeSingleInitialValue = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newInitialValue = [e.target.value];
      setField(items, false, newInitialValue);
    },
    [items, setField],
  );
  const onChangeMultipleInitialValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newInitialValue = e.target.checked
        ? [...initialValue, e.target.value]
        : initialValue.filter((v) => v !== e.target.value);
      setField(items, true, newInitialValue);
    },
    [initialValue, items, setField],
  );

  const add = useCallback(() => {
    const newItems = [...(items || []), { id: nanoid(10), value: '' }];
    checkError(newItems);
    setField(newItems, multiple, initialValue);
  }, [checkError, items, multiple, initialValue, setField]);

  const remove = useCallback(
    (id: any) => () => {
      const newItems = items.filter((item) => item.id !== id);
      const newInitialValue = initialValue.filter((v) => v !== id);
      checkError(newItems);
      setField(newItems, multiple, newInitialValue);
    },
    [checkError, items, multiple, initialValue, setField],
  );
  return (
    <>
      <div className={styles.selectListContainer}>
        <ul className={styles.selectList}>
          {items &&
            items.map((item, i) => (
              <li
                className={
                  Number(targetIndex) === i + 1 && i === items.length - 1
                    ? cx(
                        styles.selectListItem,
                        styles.isDragOver,
                        styles.isNext,
                      )
                    : Number(targetIndex) === i
                      ? cx(styles.selectListItem, styles.isDragOver)
                      : styles.selectListItem
                }
                key={item.id}
                draggable={draggable}
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDragEnd={onDragEnd}
                data-index={i}
              >
                <span className={styles.handle}>
                  <Handle />
                </span>
                <Textfield
                  defaultValue={item.value}
                  onChange={onChangeText(item.id)}
                  className={styles.textfield}
                  errorText={
                    item.value === '' ? t('This field is required.') : undefined
                  }
                />
                <IconButton
                  icon="clear"
                  className={styles.removeButton}
                  onClick={remove(item.id)}
                />
              </li>
            ))}
        </ul>
        <Button type="tertiary" onClick={add} value={t('Add')} icon="add" />
      </div>
      <div className={styles.addtionalSettings}>
        <Fieldset
          legend={t('Multiple choices')}
          description={t(
            'If you turn on the setting, you will be able to select multiple items.',
          )}
        >
          <Popover
            enabled={!isAdditionalField}
            contentPosition={{
              side: 'bottom',
              align: 'start',
            }}
            content={
              <p>
                {t(
                  'Cannot change multiple selection. If you want to change it, delete the field and recreate it.',
                )}
              </p>
            }
          >
            <Switch
              on={multiple}
              onChange={onChangeMultiple}
              size="large"
              disabled={!isAdditionalField}
            />
          </Popover>
        </Fieldset>
        {multiple ? (
          <CheckboxGroup
            label={t('Initial Value')}
            description={t(
              'You can set the initial values that will be used for submissions.',
            )}
            items={items.map((item) => ({
              label: item.value,
              value: item.id,
              checked: initialValue.includes(item.id),
              onChange: onChangeMultipleInitialValue,
            }))}
            className={styles.checkboxGroup}
            scrollable
          />
        ) : (
          <Fieldset
            legend={t('Initial Value')}
            description={t(
              'You can set the initial values that will be used for submissions.',
            )}
          >
            <Selectfield
              value={initialValue?.[0] || ''}
              style={{ width: 'auto' }}
              onChange={onChangeSingleInitialValue}
              placeholder={{
                value: '',
                name: t('Select'),
              }}
            >
              {items.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.value}
                </option>
              ))}
            </Selectfield>
          </Fieldset>
        )}
      </div>
    </>
  );
};

type InputSelectFieldInModalProps = {
  items: Item[];
  multiple: boolean;
  initialValue: string[];
  setField: (items: Item[], multiple: boolean, initialValue: string[]) => void;
  isAdditionalField?: boolean;
};

export const InputSelectFieldInModal: React.FC<
  InputSelectFieldInModalProps
> = ({ items, multiple, initialValue, setField, isAdditionalField }) => {
  const { t } = useTranslation('inputSelectField');

  const [selectItems, setSelectItems] = useState(items);
  const [multipleSelect, setMultipleSelect] = useState(multiple);
  const [selectInitialValue, setSelectInitialValue] = useState(initialValue);
  const [error, setError] = useState(false);
  const setSelectField = useCallback(
    (items: any, multiple: boolean, initialValue: string[]) => {
      setSelectItems(items);
      setMultipleSelect(multiple);
      setSelectInitialValue(initialValue);
    },
    [],
  );

  const set = useCallback(() => {
    setField(selectItems, multipleSelect, selectInitialValue);
  }, [setField, selectItems, multipleSelect, selectInitialValue]);
  return (
    <div>
      <p className={styles.description}>
        {t('Define Options for Selectable Field')}
      </p>
      <InputSelectField
        items={selectItems}
        multiple={multipleSelect}
        initialValue={selectInitialValue}
        setError={setError}
        setField={setSelectField}
        isAdditionalField={isAdditionalField}
      />
      <div className={styles.modalAction}>
        <Button
          type="secondary"
          size="small"
          className={styles.modalActionButton}
          onClick={set}
          value={t('decision')}
          disabled={error}
        />
      </div>
    </div>
  );
};
