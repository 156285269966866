import type { GraphQLResultErrors } from '@/types/graphql';

type ReactQueryError = {
  message: string;
  status: number;
};

export const isReactQueryError = (value: unknown): value is ReactQueryError => {
  if (typeof value !== 'object' || value === null) {
    return false;
  }
  const reactQueryError = value as Record<keyof ReactQueryError, unknown>;
  if (typeof reactQueryError.message !== 'string') {
    return false;
  }
  if (typeof reactQueryError.status !== 'number') {
    return false;
  }
  return true;
};

export const nonNullable = <T>(value: T): value is NonNullable<T> => {
  return value != null && value !== undefined;
};

/**
 * 引数valueがGraphQLResultErrorsObjectのオブジェクトの場合はTrueを返却
 *
 * ※ errorsプロパティを持ち、必ず1つ以上の要素が存在することが保証される
 *
 * @param value
 * @returns {boolean}
 */
export const isGraphQLResultErrorsObject = <T = object>(
  value: unknown,
): value is GraphQLResultErrors<T> => {
  return Boolean(
    value &&
      typeof value === 'object' &&
      'errors' in value &&
      Array.isArray(value.errors) &&
      // NOTE: errorsプロパティを持つ場合に、必ず1つ以上の要素が存在するため、条件に個数チェックを含める
      value.errors.length > 0,
  );
};

/**
 * 引数valueがErrorオブジェクトの場合はTrueをへ返却
 *
 * @param value
 * @returns {boolean}
 */
export const isErrorObject = (value: unknown): value is Error => {
  return value instanceof Error;
};

/**
 * 引数valueがbooleanの場合はTrueを返却
 *
 * @param value
 * @returns {boolean}
 */
export const isBoolean = (value: unknown): value is boolean => {
  return typeof value === 'boolean';
};

/**
 * 引数valueがundefinedである場合はTrueを返却
 *
 * @param value
 * @returns {boolean}
 */
export const isUndefined = (value: unknown): value is undefined => {
  return typeof value === 'undefined';
};

/**
 * 引数valueがstringである場合はTrueを返却
 *
 * @param value
 * @returns {boolean}
 */
export function isString(value: unknown): value is string {
  return typeof value === 'string';
}
