import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import * as mutations from '../../graphql/mutations';

export const useRestartApi = (serviceId) => {
  const { t } = useTranslation('hooksApi');
  const { addToast } = useToasts();
  const cache = useQueryClient();

  const { mutate: restartApi, isLoading: restartApiLoading } = useMutation({
    mutationFn: () => {
      return API.graphql(
        graphqlOperation(mutations.restartApi, {
          serviceId,
        }),
      ).then((result) => result.data.restartApi);
    },
    onSuccess(data, { close }) {
      // サーバーサイドのバリデーション
      if (data.result === false) {
        addToast(data.message, { appearance: 'error' });
        return;
      }
      cache.invalidateQueries(['service'], { type: 'all' });
      cache.invalidateQueries(['services'], { type: 'all' });
      addToast(t('API has resumed.'), {
        appearance: 'success',
      });
      close();
    },
    onError({ errors }) {
      addToast(errors ? errors[0].message : t('API could not be resumed.'), {
        appearance: 'error',
      });
    },
  });

  return [restartApi, restartApiLoading];
};
