import cx from 'classnames';

import styles from './iconButton.module.css';

import { Icon } from '@/views/Common/Ui/Icon';

type Props = {
  icon: string;
  outlined?: boolean;
  hasFrame?: boolean;
} & React.ComponentPropsWithoutRef<'button'>;

const IconButton: React.FC<Props> = ({
  icon,
  outlined = false,
  hasFrame = false,
  ...props
}) => {
  return (
    <button
      {...props}
      className={cx(
        styles.button,
        { [styles.hasFrame]: hasFrame },
        props.className,
      )}
    >
      <Icon name={icon} outlined={outlined} />
    </button>
  );
};

export default IconButton;
