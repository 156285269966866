import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useItalic } from './useItalic';
import { IconButton } from '../../../IconButton';
import styles from '../../../richeditorv2.module.css';

import type { Editor } from '@tiptap/react';

import { ItalicIcon } from '@/views/Common/Icons';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  toggleItalicHandler: () => void;
  isActiveItalic: boolean;
  editable: boolean;
};

export const ItalicView: React.FC<ViewProps> = ({
  toggleItalicHandler,
  isActiveItalic,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <IconButton
      icon={
        <ItalicIcon
          className={cx(styles.icon, {
            [styles.isActive]: isActiveItalic,
          })}
        />
      }
      aria-label={t('Italic')}
      isActive={isActiveItalic}
      onClick={toggleItalicHandler}
      disabled={!editable}
    />
  );
};

export const Italic: React.FC<Props> = ({ editor }) => {
  const { toggleItalicHandler, isActiveItalic } = useItalic({ editor });

  return (
    <ItalicView
      toggleItalicHandler={toggleItalicHandler}
      isActiveItalic={isActiveItalic}
      editable={editor.isEditable}
    />
  );
};
