import { useTranslation } from 'react-i18next';

import { useEmbedBlock } from './useEmbedBlock';
import { EmbedModal } from '../../../EmbedModal';
import { IconButton } from '../../../IconButton';

import type { EmbedResult } from '@/entity/iframely';
import type { OptionsField } from '@/entity/iframely';
import type { Editor } from '@tiptap/react';

import style from './embedblock.module.css';

import { EmbedBlockIcon } from '@/views/Common/Icons';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  isEmbedModal: boolean;
  onToggleEmbedModal: (open: boolean) => void;
  editable: boolean;
  embedUrl: string;
  onChangeEmbedUrl: (url: string) => void;
  embedUrlError?: string;
  inputOptions?: Record<string, OptionsField['value']>;
  onChangeInputOptions: (value: OptionsField['value'], key: string) => void;
  embedResult: EmbedResult;
  onClickPreviewEmbed: () => Promise<void>;
  insertEmbedBlock: () => Promise<void>;
};

export const EmbedBlockView: React.FC<ViewProps> = ({
  isEmbedModal,
  onToggleEmbedModal,
  editable,
  embedUrl,
  onChangeEmbedUrl,
  embedUrlError,
  inputOptions,
  onChangeInputOptions,
  embedResult,
  onClickPreviewEmbed,
  insertEmbedBlock,
}) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <EmbedModal
      title={t('Add Embed')}
      trigger={
        <IconButton
          icon={<EmbedBlockIcon className={style.icon} />}
          aria-label={t('Embed')}
          isActive={false}
          onClick={() => onToggleEmbedModal(true)}
          disabled={!editable}
        />
      }
      footer={{
        submitButtonProps: {
          value: t('Add'),
          type: 'primary',
          disabled:
            !!embedUrlError || !embedResult.data || !!embedResult.data.error,
          onClick: insertEmbedBlock,
        },
      }}
      isEmbedModal={isEmbedModal}
      onToggleEmbedModal={onToggleEmbedModal}
      embedUrl={embedUrl}
      onChangeEmbedUrl={onChangeEmbedUrl}
      embedUrlError={embedUrlError}
      inputOptions={inputOptions}
      onChangeInputOptions={onChangeInputOptions}
      embedResult={embedResult}
      onClickPreviewEmbed={onClickPreviewEmbed}
    />
  );
};

export const EmbedBlock: React.FC<Props> = ({ editor }) => {
  const {
    isEmbedModal,
    onToggleEmbedModal,
    embedUrl,
    onChangeEmbedUrl,
    embedUrlError,
    inputOptions,
    onChangeInputOptions,
    embedResult,
    onClickPreviewEmbed,
    insertEmbedBlock,
  } = useEmbedBlock({ editor });

  return (
    <EmbedBlockView
      isEmbedModal={isEmbedModal}
      onToggleEmbedModal={onToggleEmbedModal}
      editable={editor.isEditable}
      embedUrl={embedUrl}
      onChangeEmbedUrl={onChangeEmbedUrl}
      embedUrlError={embedUrlError}
      inputOptions={inputOptions}
      onChangeInputOptions={onChangeInputOptions}
      embedResult={embedResult}
      onClickPreviewEmbed={onClickPreviewEmbed}
      insertEmbedBlock={insertEmbedBlock}
    />
  );
};
