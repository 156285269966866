import cx from 'classnames';
import type React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Td } from '@/components/ui/Table';

import { useCustomStatus } from '@/hooks/CustomStatus/useCustomStatus';
import { useGetMyService } from '@/hooks/useService';

import IframeContent from '../../IframeContent';
import S3Image from '../../S3Image';
import ContentStatus from '../../ui/ContentStatus';
import Switch from '../../ui/Switch';

import type { Field } from '@/types/field';
import type { Dispatch, SetStateAction } from 'react';

import styles from './relationContent.module.css';

import { formatDateToMinutes } from '@/util/date';
import { Icon } from '@/views/Common/Ui/Icon';
import { MediaList } from '@/views/ContentList/MediaList';
import { Relation } from '@/views/ContentList/Relation';
import { RelationList } from '@/views/ContentList/RelationList';
import { NoPermission } from '@/views/ContentList/shared/NoPermission';

type Props = {
  content: any;
  fields: Field[];
  items: any;
  onClick?: any;
  remove?: any;
  isSelected: boolean;
  draggable?: boolean;
  setDraggable?: Dispatch<SetStateAction<boolean>>;
  onDragStart?: () => void;
  onDragOver?: () => void;
  onDragEnd?: () => void;
  targetIndex?: number;
  index?: number;
};
const RelationContent: React.FC<Props> = ({
  content,
  fields,
  items,
  onClick,
  remove,
  isSelected = false,
  draggable,
  setDraggable = () => {},
  onDragStart = () => {},
  onDragOver = () => {},
  onDragEnd = () => {},
  targetIndex,
  index = 0,
}) => {
  const { t } = useTranslation('formRelationContent');

  const { service } = useGetMyService();
  const { customStatus } = useCustomStatus(
    content?.gsiPartitionKey1?.replace('#CONTENT', ''),
  );
  const _remove = useCallback(
    (e: any) => {
      e.stopPropagation();
      remove();
    },
    [remove],
  );

  if (content === undefined) {
    return null;
  }

  return (
    <tr
      className={
        Number(targetIndex) === index + 1
          ? cx(styles.tr, styles.isDragOver, styles.isNext)
          : Number(targetIndex) === index
            ? cx(styles.tr, styles.isDragOver)
            : isSelected === true
              ? cx(styles.tr, styles.isSelected)
              : styles.tr
      }
      draggable={draggable}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      onClick={() => onClick && onClick(content)}
      data-index={index}
    >
      {draggable !== undefined && (
        <td
          className={cx(styles.td, styles.dragHandle)}
          onClick={(e) => e.stopPropagation()}
        >
          <button
            className={styles.button}
            onMouseDown={() => setDraggable(true)}
          >
            <i className={`material-icons ${styles.handle}`}>drag_handle</i>
          </button>
          <div className={styles.dropArea}></div>
        </td>
      )}
      {remove !== undefined && (
        <td className={styles.td}>
          <i
            className={`material-icons ${styles.removeIcon}`}
            onClick={_remove}
          >
            clear
          </i>
        </td>
      )}
      {content !== null ? (
        <>
          <td className={styles.td}>
            <ContentStatus
              status={content.contentStatus}
              reservationTime={content.reservationTime}
              reservationStopTime={content.reservationStopTime}
              reviewRequestId={content.reviewRequestId}
              customStatus={customStatus}
              contentCustomStatusIds={content.contentCustomStatusIds}
            />
          </td>
          {items?.includes('contentId') && (
            <td className={styles.td}>{content.contentId}</td>
          )}
          {items?.includes('createdAt') && (
            <td className={styles.td}>
              {formatDateToMinutes(content.createdAt)}
            </td>
          )}
          {fields
            .map((field, index) => ({
              field,
              index,
              value:
                content.value &&
                content.value[field.idValue] &&
                content.value[field.idValue][field.kind],
            }))
            .map(({ field, index, value }) => {
              if (!items?.includes(field.idValue)) {
                return null;
              }
              if (field.kind === 'boolean') {
                return (
                  <td key={index} className={styles.td}>
                    <Switch on={value || false} />
                  </td>
                );
              }
              if (value === undefined) {
                return (
                  <td key={index} className={styles.td}>
                    -
                  </td>
                );
              }
              if (field.kind === 'textArea') {
                return (
                  <td key={index} className={styles.td}>
                    {value}
                  </td>
                );
              }
              if (field.kind === 'richEditor') {
                return (
                  <td key={index} className={styles.td}>
                    {value}
                  </td>
                );
              }
              if (field.kind === 'date') {
                return (
                  <td key={index} className={styles.td}>
                    {formatDateToMinutes(value)}
                  </td>
                );
              }
              if (field.kind === 'media') {
                return (
                  <Td key={index}>
                    {value.isAuthorized ? (
                      <S3Image
                        directory={value.data.directory}
                        fileName={value.data.fileName}
                        kind="IMAGE"
                        queryString="?w=180"
                        className={styles.media}
                        customDomainImageHost={service?.customDomainImageHost}
                      />
                    ) : (
                      <NoPermission />
                    )}
                  </Td>
                );
              }
              if (field.kind === 'mediaList') {
                return (
                  <Td key={index}>
                    <MediaList
                      value={value}
                      customDomainImageHost={service?.customDomainImageHost}
                    />
                  </Td>
                );
              }
              if (field.kind === 'file') {
                return (
                  <td key={index} className={styles.td}>
                    {value ? t('Exist') : t('Not Exist')}
                  </td>
                );
              }
              if (field.kind === 'select') {
                return (
                  <td key={index} className={styles.td}>
                    {value.length === 0 ? (
                      '-'
                    ) : field.multipleSelect ? (
                      // @ts-expect-error
                      value.map((v, i) => (
                        <p key={i}>
                          {
                            /* @ts-expect-error */
                            field.selectItems.find((item) => item.id === v)
                              ?.value
                          }
                        </p>
                      ))
                    ) : (
                      <p>
                        {
                          // @ts-expect-error
                          field.selectItems.find((item) => item.id === value[0])
                            ?.value
                        }
                      </p>
                    )}
                  </td>
                );
              }
              if (field.kind === 'relation') {
                return (
                  <Td key={index}>
                    <Relation
                      content={value}
                      displayItem={field.referenceDisplayItem}
                    />
                  </Td>
                );
              }
              if (field.kind === 'relationList') {
                return (
                  <Td key={index}>
                    <RelationList
                      contents={value}
                      displayItem={field.referenceDisplayItem}
                    />
                  </Td>
                );
              }
              if (field.kind === 'iframe') {
                return (
                  <td key={index} className={styles.td}>
                    {!value ? '-' : <IframeContent value={value} />}
                  </td>
                );
              }
              if (field.kind === 'custom') {
                return (
                  <td key={index} className={styles.td}>
                    <span className={styles.nowrap}>
                      {value
                        ? t('{{count}} fields', {
                            count: Object.keys(value).length,
                          })
                        : '-'}
                    </span>
                  </td>
                );
              }
              if (field.kind === 'repeater') {
                return (
                  <td key={index} className={styles.td}>
                    <span className={styles.nowrap}>
                      {value
                        ? t('{{count}} Custom fields', { count: value.length })
                        : '-'}
                    </span>
                  </td>
                );
              }
              return (
                <td key={index} className={styles.td}>
                  {value}
                </td>
              );
            })}
        </>
      ) : (
        // 権限がない場合の表示
        <td
          // 表示できないセルの数を計算
          colSpan={
            [
              draggable,
              remove,
              items?.includes('contentId'),
              items?.includes('createdAt'),
            ].filter(Boolean).length +
            fields.filter((field) => items?.includes(field.idValue)).length
          }
          className={styles.td}
        >
          <div className={styles.noPermission}>
            <Icon
              name="visibility_off"
              size="large"
              className={styles.noPermissionIcon}
            />
            <p className={styles.noPermissionText}>
              権限がないため表示できません
            </p>
          </div>
        </td>
      )}
    </tr>
  );
};

export default RelationContent;
