import { useInfiniteQuery } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useCallback } from 'react';

import * as queries from '../../graphql/queries';

import type { FindHistoriesQuery } from '@/API';
import type { Contents } from '@/types/contents';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

export const useContentHistories = (
  partitionKey?: string | null | undefined,
) => {
  const loadHistories = useCallback(
    async (
      partitionKey?: string | null | undefined,
      paramNextToken?: string,
    ) => {
      if (!partitionKey) {
        return null;
      }

      const result = (await API.graphql(
        graphqlOperation(queries.findHistories, {
          contentId: partitionKey,
          nextToken: paramNextToken ? JSON.stringify(paramNextToken) : null,
        }),
      )) as GraphQLResult<FindHistoriesQuery>;

      if (!result.data?.findHistories) {
        return null;
      }

      return JSON.parse(result.data.findHistories) as {
        items: Contents[] | null;
        nextToken: string | null;
      } | null;
    },
    [],
  );

  const { data, fetchNextPage, hasNextPage, isFetching } = useInfiniteQuery({
    queryKey: ['contentHistories', partitionKey],
    queryFn: ({ pageParam }) => {
      if (!partitionKey) {
        return;
      }

      return loadHistories(partitionKey, pageParam);
    },

    getNextPageParam: (lastPage) => {
      return lastPage?.nextToken;
    },
    enabled: !!partitionKey,
  });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
  };
};
