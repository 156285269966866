import styles from './hoverdescriptioncard.module.css';
type Props = {
  title: string;
  description: string;
  indentionDescription?: string;
  imgUrl: string;
  onClick: () => void;
  className: string;
};

const HoverDescriptionCard: React.FC<Props> = ({
  title,
  description,
  indentionDescription,
  imgUrl,
  onClick,
  className,
}) => {
  return (
    <button className={`${styles.wrapper} ${className}`} onClick={onClick}>
      <div className={styles.mask}>
        <div className={styles.maskDescription}>
          <p>{description}</p>
          {indentionDescription && <p>{indentionDescription}</p>}
        </div>
        <p className={styles.maskTitle}>
          <span>
            {title}
            <i className="material-icons-outlined">navigate_next</i>
          </span>
        </p>
      </div>
      <img
        src={imgUrl}
        width={175}
        height={138}
        alt=""
        className={styles.img}
      />
      <div className={styles.templateTitle}>
        <p className={styles.title}>{title}</p>
      </div>
    </button>
  );
};

export default HoverDescriptionCard;
