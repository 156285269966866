import oneC from 'highlight.js/lib/languages/1c';
import abnf from 'highlight.js/lib/languages/abnf';
import accesslog from 'highlight.js/lib/languages/accesslog';
import actionscript from 'highlight.js/lib/languages/actionscript';
import ada from 'highlight.js/lib/languages/ada';
import angelscript from 'highlight.js/lib/languages/angelscript';
import apache from 'highlight.js/lib/languages/apache';
import applescript from 'highlight.js/lib/languages/applescript';
import arcade from 'highlight.js/lib/languages/arcade';
import arduino from 'highlight.js/lib/languages/arduino';
import armasm from 'highlight.js/lib/languages/armasm';
import asciidoc from 'highlight.js/lib/languages/asciidoc';
import aspectj from 'highlight.js/lib/languages/aspectj';
import autohotkey from 'highlight.js/lib/languages/autohotkey';
import autoit from 'highlight.js/lib/languages/autoit';
import avrasm from 'highlight.js/lib/languages/avrasm';
import awk from 'highlight.js/lib/languages/awk';
import axapta from 'highlight.js/lib/languages/axapta';
import basic from 'highlight.js/lib/languages/basic';
import bnf from 'highlight.js/lib/languages/bnf';
import brainfuck from 'highlight.js/lib/languages/brainfuck';
import c from 'highlight.js/lib/languages/c';
import cal from 'highlight.js/lib/languages/cal';
import capnproto from 'highlight.js/lib/languages/capnproto';
import clojure from 'highlight.js/lib/languages/clojure';
import cmake from 'highlight.js/lib/languages/cmake';
import coffeescript from 'highlight.js/lib/languages/coffeescript';
import coq from 'highlight.js/lib/languages/coq';
import cos from 'highlight.js/lib/languages/cos';
import cpp from 'highlight.js/lib/languages/cpp';
import crmsh from 'highlight.js/lib/languages/crmsh';
import crystal from 'highlight.js/lib/languages/crystal';
import csharp from 'highlight.js/lib/languages/csharp';
import csp from 'highlight.js/lib/languages/csp';
import css from 'highlight.js/lib/languages/css';
import d from 'highlight.js/lib/languages/d';
import dart from 'highlight.js/lib/languages/dart';
import diff from 'highlight.js/lib/languages/diff';
import django from 'highlight.js/lib/languages/django';
import dns from 'highlight.js/lib/languages/dns';
import dockerfile from 'highlight.js/lib/languages/dockerfile';
import dos from 'highlight.js/lib/languages/dos';
import dsconfig from 'highlight.js/lib/languages/dsconfig';
import dts from 'highlight.js/lib/languages/dts';
import ebnf from 'highlight.js/lib/languages/ebnf';
import elixir from 'highlight.js/lib/languages/elixir';
import elm from 'highlight.js/lib/languages/elm';
import erlang from 'highlight.js/lib/languages/erlang';
import excel from 'highlight.js/lib/languages/excel';
import fix from 'highlight.js/lib/languages/fix';
import fortran from 'highlight.js/lib/languages/fortran';
import fsharp from 'highlight.js/lib/languages/fsharp';
import gams from 'highlight.js/lib/languages/gams';
import gauss from 'highlight.js/lib/languages/gauss';
import gcode from 'highlight.js/lib/languages/gcode';
import gherkin from 'highlight.js/lib/languages/gherkin';
import glsl from 'highlight.js/lib/languages/glsl';
import go from 'highlight.js/lib/languages/go';
import golo from 'highlight.js/lib/languages/golo';
import gradle from 'highlight.js/lib/languages/gradle';
import graphql from 'highlight.js/lib/languages/graphql';
import groovy from 'highlight.js/lib/languages/groovy';
import haml from 'highlight.js/lib/languages/haml';
import handlebars from 'highlight.js/lib/languages/handlebars';
import haskell from 'highlight.js/lib/languages/haskell';
import haxe from 'highlight.js/lib/languages/haxe';
import http from 'highlight.js/lib/languages/http';
import hy from 'highlight.js/lib/languages/hy';
import inform7 from 'highlight.js/lib/languages/inform7';
import toml from 'highlight.js/lib/languages/ini';
import irpf90 from 'highlight.js/lib/languages/irpf90';
import java from 'highlight.js/lib/languages/java';
import javascript from 'highlight.js/lib/languages/javascript';
import json from 'highlight.js/lib/languages/json';
import julia from 'highlight.js/lib/languages/julia';
import kotlin from 'highlight.js/lib/languages/kotlin';
import lasso from 'highlight.js/lib/languages/lasso';
import tex from 'highlight.js/lib/languages/latex';
import ldif from 'highlight.js/lib/languages/ldif';
import leaf from 'highlight.js/lib/languages/leaf';
import less from 'highlight.js/lib/languages/less';
import lisp from 'highlight.js/lib/languages/lisp';
import livecodeserver from 'highlight.js/lib/languages/livecodeserver';
import livescript from 'highlight.js/lib/languages/livescript';
import lua from 'highlight.js/lib/languages/lua';
import makefile from 'highlight.js/lib/languages/makefile';
import markdown from 'highlight.js/lib/languages/markdown';
import mathematica from 'highlight.js/lib/languages/mathematica';
import matlab from 'highlight.js/lib/languages/matlab';
import maxima from 'highlight.js/lib/languages/maxima';
import mel from 'highlight.js/lib/languages/mel';
import mercury from 'highlight.js/lib/languages/mercury';
import mizar from 'highlight.js/lib/languages/mizar';
import mojolicious from 'highlight.js/lib/languages/mojolicious';
import monkey from 'highlight.js/lib/languages/monkey';
import moonscript from 'highlight.js/lib/languages/moonscript';
import n1ql from 'highlight.js/lib/languages/n1ql';
import nginx from 'highlight.js/lib/languages/nginx';
import nim from 'highlight.js/lib/languages/nim';
import nix from 'highlight.js/lib/languages/nix';
import nsis from 'highlight.js/lib/languages/nsis';
import objectivec from 'highlight.js/lib/languages/objectivec';
import ocaml from 'highlight.js/lib/languages/ocaml';
import openscad from 'highlight.js/lib/languages/openscad';
import oxygene from 'highlight.js/lib/languages/oxygene';
import parser3 from 'highlight.js/lib/languages/parser3';
import perl from 'highlight.js/lib/languages/perl';
import pf from 'highlight.js/lib/languages/pf';
import postgresql from 'highlight.js/lib/languages/pgsql';
import php from 'highlight.js/lib/languages/php';
import plaintext from 'highlight.js/lib/languages/plaintext';
import pony from 'highlight.js/lib/languages/pony';
import powershell from 'highlight.js/lib/languages/powershell';
import processing from 'highlight.js/lib/languages/processing';
import profile from 'highlight.js/lib/languages/profile';
import prolog from 'highlight.js/lib/languages/prolog';
import properties from 'highlight.js/lib/languages/properties';
import protobuf from 'highlight.js/lib/languages/protobuf';
import puppet from 'highlight.js/lib/languages/puppet';
import python from 'highlight.js/lib/languages/python';
import profileRepl from 'highlight.js/lib/languages/python-repl';
import qml from 'highlight.js/lib/languages/qml';
import r from 'highlight.js/lib/languages/r';
import reasonml from 'highlight.js/lib/languages/reasonml';
import rib from 'highlight.js/lib/languages/rib';
import rsl from 'highlight.js/lib/languages/rsl';
import ruby from 'highlight.js/lib/languages/ruby';
import ruleslanguage from 'highlight.js/lib/languages/ruleslanguage';
import rust from 'highlight.js/lib/languages/rust';
import sas from 'highlight.js/lib/languages/sas';
import scala from 'highlight.js/lib/languages/scala';
import scheme from 'highlight.js/lib/languages/scheme';
import scilab from 'highlight.js/lib/languages/scilab';
import scss from 'highlight.js/lib/languages/scss';
import shell from 'highlight.js/lib/languages/shell';
import smali from 'highlight.js/lib/languages/smali';
import smalltalk from 'highlight.js/lib/languages/smalltalk';
import sml from 'highlight.js/lib/languages/sml';
import sql from 'highlight.js/lib/languages/sql';
import stan from 'highlight.js/lib/languages/stan';
import stata from 'highlight.js/lib/languages/stata';
import stylus from 'highlight.js/lib/languages/stylus';
import subunit from 'highlight.js/lib/languages/subunit';
import swift from 'highlight.js/lib/languages/swift';
import tap from 'highlight.js/lib/languages/tap';
import tcl from 'highlight.js/lib/languages/tcl';
import thrift from 'highlight.js/lib/languages/thrift';
import tp from 'highlight.js/lib/languages/tp';
import twig from 'highlight.js/lib/languages/twig';
import typescript from 'highlight.js/lib/languages/typescript';
import vala from 'highlight.js/lib/languages/vala';
import vbnet from 'highlight.js/lib/languages/vbnet';
import vbscript from 'highlight.js/lib/languages/vbscript';
import verilog from 'highlight.js/lib/languages/verilog';
import vhdl from 'highlight.js/lib/languages/vhdl';
import vim from 'highlight.js/lib/languages/vim';
import x86asm from 'highlight.js/lib/languages/x86asm';
import xl from 'highlight.js/lib/languages/xl';
import html from 'highlight.js/lib/languages/xml';
import xquery from 'highlight.js/lib/languages/xquery';
import yaml from 'highlight.js/lib/languages/yaml';
import zephir from 'highlight.js/lib/languages/zephir';

// https://github.com/highlightjs/highlight.js/blob/main/SUPPORTED_LANGUAGES.md#supported-languages
export const listLanguage = {
  '1c': oneC,
  abnf,
  accesslog,
  ada,
  arduino,
  armasm,
  avrasm,
  actionscript,
  angelscript,
  apache,
  applescript,
  arcade,
  asciidoc,
  aspectj,
  autohotkey,
  autoit,
  awk,
  basic,
  bnf,
  brainfuck,
  csharp,
  c,
  cpp,
  cal,
  cos,
  cmake,
  coq,
  csp,
  css,
  capnproto,
  clojure,
  coffeescript,
  crmsh,
  crystal,
  d,
  dart,
  diff,
  django,
  dns,
  dockerfile,
  dos,
  dsconfig,
  dts,
  ebnf,
  elixir,
  elm,
  erlang,
  excel,
  fsharp,
  fix,
  fortran,
  gcode,
  gams,
  gauss,
  gherkin,
  go,
  golo,
  gradle,
  graphql,
  groovy,
  html,
  http,
  haml,
  handlebars,
  haskell,
  haxe,
  hy,
  toml,
  inform7,
  irpf90,
  json,
  java,
  javascript,
  julia,
  kotlin,
  tex,
  leaf,
  lasso,
  less,
  ldif,
  lisp,
  livecodeserver,
  livescript,
  lua,
  makefile,
  markdown,
  mathematica,
  matlab,
  maxima,
  mel,
  mercury,
  mizar,
  mojolicious,
  monkey,
  moonscript,
  n1ql,
  nsis,
  nginx,
  nim,
  nix,
  ocaml,
  objectivec,
  glsl,
  openscad,
  ruleslanguage,
  oxygene,
  pf,
  php,
  parser3,
  perl,
  plaintext,
  pony,
  postgresql,
  powershell,
  processing,
  prolog,
  properties,
  protobuf,
  puppet,
  python,
  profile: profile,
  'python-rpythonepl': profileRepl,
  qml,
  r,
  reasonml,
  rib,
  rsl,
  ruby,
  rust,
  sas,
  scss,
  sql,
  scala,
  scheme,
  scilab,
  shell,
  smali,
  smalltalk,
  sml,
  stan,
  stata,
  stylus,
  subunit,
  swift,
  tcl,
  tap,
  thrift,
  tp,
  twig,
  typescript,
  vbnet,
  vbscript,
  vhdl,
  vala,
  verilog,
  vim,
  axapta,
  x86asm,
  xl,
  xquery,
  yaml,
  zephir,
};
