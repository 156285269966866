import { useCallback } from 'react';

import type { Props } from './Redo';

export const useRedo = ({ editor }: Props) => {
  const onRedoHandler = useCallback(() => {
    editor.commands.redo();
  }, [editor]);

  return {
    onRedoHandler,
  };
};
