export const getHasSameEndpoint = (state) => {
  if (state.object === undefined) {
    return { hasSameEndpoint: undefined };
  }
  return { hasSameEndpoint: state.object.hasSameEndpoint };
};

export const getPostResult = ({ postResult }) => ({
  postResult,
});

export const getPutResult = ({ putResult, errorMessage }) => ({
  putResult,
  errorMessage,
});

export const getPutDescriptionResult = ({ putDescriptionResult }) => ({
  putDescriptionResult,
});
