import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from './_types';
import type { Role } from '@/entity/role';

import { produce } from '@/lib/immer';

type SetMediumPermissionCreateAction = FormRoleInputValueAction<
  'set-medium-permission-create',
  Role['mediumPermission']['create']
>;
type SetMediumPermissionReadAction = FormRoleInputValueAction<
  'set-medium-permission-read',
  Role['mediumPermission']['readLevel']
>;
type SetMediumPermissionUpdateAction = FormRoleInputValueAction<
  'set-medium-permission-update',
  Role['mediumPermission']['update']
>;
type SetMediumPermissionDeleteAction = FormRoleInputValueAction<
  'set-medium-permission-delete',
  Role['mediumPermission']['delete']
>;
type MediumPermissionAction =
  | SetMediumPermissionCreateAction
  | SetMediumPermissionReadAction
  | SetMediumPermissionUpdateAction
  | SetMediumPermissionDeleteAction;

/**
 * MediumPermissionのcreateフィールドを更新する
 */
const setMediumPermissionCreate: FormRoleInputValueDispatchFn<
  SetMediumPermissionCreateAction
> = (currentState, action) => {
  return produce(currentState, (draft) => {
    draft.mediumPermission.create = action;
  });
};

/**
 * MediumPermissionのreadLevelフィールドを更新する
 */
const setMediumPermissionRead: FormRoleInputValueDispatchFn<
  SetMediumPermissionReadAction
> = (currentState, action) => {
  return produce(currentState, (draft) => {
    draft.mediumPermission.readLevel = action;
  });
};

/**
 * MediumPermissionのupdateフィールドを更新する
 */
const setMediumPermissionUpdate: FormRoleInputValueDispatchFn<
  SetMediumPermissionUpdateAction
> = (currentState, action) => {
  return produce(currentState, (draft) => {
    draft.mediumPermission.update = action;
  });
};

/**
 * MediumPermissionのdeleteフィールドを更新する
 */
const setMediumPermissionDelete: FormRoleInputValueDispatchFn<
  SetMediumPermissionDeleteAction
> = (currentState, action) => {
  return produce(currentState, (draft) => {
    draft.mediumPermission.delete = action;
  });
};

export {
  type MediumPermissionAction,
  setMediumPermissionCreate,
  setMediumPermissionRead,
  setMediumPermissionUpdate,
  setMediumPermissionDelete,
};
