import { I18n } from 'aws-amplify';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoading, useInput } from '../../hooks';
import Feedback from '../Feedback';
import { PrimaryButton } from '../Form';
import Fieldset from '../ui/Fieldset';
import Footer from '../ui/Footer';
import Textfield from '../ui/Textfield';
import { validateCode } from '../Validations';

import styles from './confirmSignup.module.css';

type Props = {
  email: string;
  serverError: any;
  confirmSignup: any;
};

const ConfirmSignup: React.FC<Props> = ({
  email,
  serverError,
  confirmSignup,
}) => {
  const [loading, startLoading] = useLoading(serverError !== undefined);
  const [code, onChangeCode, codeError, setCode] = useInput('', validateCode);
  const { t } = useTranslation('confirmSignup');

  useEffect(() => {
    if (serverError !== undefined) {
      setCode('');
    }
  }, [serverError, setCode]);

  const submit = useCallback(() => {
    startLoading();
    confirmSignup(email, code);
  }, [startLoading, confirmSignup, email, code]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className={styles.title}>{t('Enter the verification code')}</h2>
        <p className={styles.description}>
          {t('Enter the verification code sent to your email')}
        </p>
        <Fieldset legend={t('Verification code')}>
          <Textfield
            type="text"
            value={code}
            placeholder="123456"
            onChange={onChangeCode}
            onEnter={submit}
            errorText={codeError}
          />
        </Fieldset>
        <div className={styles.actions}>
          <Feedback
            message={
              serverError && I18n.get(serverError.code, serverError.message)
            }
          />
          <PrimaryButton
            className="ga-signup-confirm"
            value={t('Verify')}
            disabled={codeError !== null || loading}
            onClick={submit}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ConfirmSignup;
