import { API, graphqlOperation } from 'aws-amplify';

import type { GraphQLResult } from '@aws-amplify/api-graphql';

import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import {
  handleApiResult,
  type ApiResponse,
} from '@/views/Common/handleApiResult';

export const authRepository = () => {
  const verifyMemberInvitation = async ({ token }: { token: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.verifyMemberInvitation, {
          token,
        }),
      )) as GraphQLResult<{
        verifyMemberInvitation: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.verifyMemberInvitation === 'object' &&
        typeof result.data.verifyMemberInvitation.result === 'boolean'
      ) {
        return handleApiResult(result.data.verifyMemberInvitation);
      } else if (typeof result.data.verifyMemberInvitation === 'string') {
        return JSON.parse(result.data.verifyMemberInvitation);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const acceptMemberInvitation = async () => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.acceptMemberInvitation),
      )) as GraphQLResult<{
        acceptMemberInvitation: ApiResponse<undefined> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.acceptMemberInvitation === 'object' &&
        typeof result.data.acceptMemberInvitation.result === 'boolean'
      ) {
        return result.data.acceptMemberInvitation;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  return { verifyMemberInvitation, acceptMemberInvitation };
};
