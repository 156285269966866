import cx from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { validateMediumTagName } from '@/components/Validations';

import Button from '../../../ui/Button';
import { Tag } from '../../../ui/Tag';

import styles from './tagsInputGroup.module.css';

export type Props = {
  onClickAddButton: (tags: string[]) => void;
};

const TagsInputGroup: React.FC<Props> = ({ onClickAddButton }) => {
  const { t } = useTranslation('tagsSettings');

  const [tags, setTags] = useState<string[]>([]);
  const [value, setValue] = useState('');

  const addingTags = [...tags, ...(value.trim() !== '' ? [value.trim()] : [])];

  // エラー内の同じタグ名のものは省く
  const errors = Array.from(new Set(addingTags)).flatMap((tag) => {
    const error = validateMediumTagName(tag);
    return error ? [{ name: tag, error }] : [];
  });

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event,
  ) => {
    if (event.key === 'Backspace' && value === '') {
      setTags((prevTags) => prevTags.slice(0, -1));
    }
    if (
      event.key === 'Enter' &&
      value !== '' &&
      !event.nativeEvent.isComposing
    ) {
      setTags((prevTags) => [...prevTags, value.trim()]);
      setValue('');
    }
  };
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    const splitCharacter = ',';
    if (value.includes(splitCharacter)) {
      const tags = value.split(splitCharacter);
      const validTags = tags
        .filter((tag) => tag !== '' && tags.at(-1) !== tag)
        .map((tag) => tag.trim());
      setTags((prevTags) => [...prevTags, ...validTags]);
      setValue(value.replace(`${validTags.join(splitCharacter)},`, ''));
    } else {
      setValue(event.target.value);
    }
  };

  const handleClickAddButton = () => {
    onClickAddButton(addingTags);
    setTags([]);
    setValue('');
  };

  const handleClickDeleteTagButton = (name: string) => {
    setTags((prevTags) => [...prevTags.filter((tag) => tag !== name)]);
  };

  const [isFocus, setIsFocus] = useState(false);
  const handleFocus = () => setIsFocus(true);
  const handleBlur = () => setIsFocus(false);

  return (
    <div>
      <h4>
        <label
          id="medium-tags-settings-input-label"
          htmlFor="medium-tags-settings-input"
        >
          {t('Enter tags to add')}
        </label>
      </h4>
      <p
        id="medium-tags-settings-input-description"
        className={styles.description}
      >
        {t(
          'Create a list of tags by pressing enter key after each tag or separating each tag by a comma. Multiple tags can be entered at once by copy-pasting a list of tags separated by commas.',
        )}
      </p>
      <div className={styles.tagsInputGroup}>
        <div className={cx(styles.tagsInputArea, isFocus && styles.focus)}>
          {tags.map((tag, i) => (
            // tagの名前はこの時点では重複する可能性があるのでkeyにindexを使用しているが順番を入れ替えないので問題ない
            <Tag
              key={i}
              name={tag}
              icon="sell"
              onDelete={() => handleClickDeleteTagButton(tag)}
            />
          ))}
          <input
            id="medium-tags-settings-input"
            className={styles.tagsInput}
            type="text"
            value={value}
            aria-labelledby="medium-tags-settings-input-label"
            aria-describedby="medium-tags-settings-input-description"
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            autoFocus
          />
        </div>
        <Button
          value={t('Add')}
          type="secondary"
          icon="add"
          onClick={handleClickAddButton}
          disabled={errors.length !== 0}
          className="track-click-by-gtm"
          data-gtm-track-event-name="click_add_tag_button"
        />
      </div>
      <ul className={styles.errorList}>
        {errors.map(({ name, error }) => (
          <li key={name}>
            <p role="alert" className={styles.error}>
              {name} : {error}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TagsInputGroup;
