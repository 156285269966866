import Document from '@tiptap/extension-document';
import Gapcursor from '@tiptap/extension-gapcursor';
import HardBreak from '@tiptap/extension-hard-break';
import History from '@tiptap/extension-history';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';

import { CustomCodeBlock } from './CodeBlock';
import { CustomEmbed } from './EmbedBlock';
import { CustomImageBlock } from './ImageBlock';
import { CustomTable } from './Table';
import { CustomBlockquote } from './blockQuote';
import { CustomBold } from './bold';
import { CustomBulletList } from './bulletList';
import { CustomCode } from './code';
import { CustomColor } from './color';
import { CustomClass } from './customClass';
import { CustomHeading } from './heading';
import { CustomHorizontalRule } from './horizontalRule';
import { CustomItalic } from './italic';
import { CustomLink } from './link';
import { CustomListItem } from './listItem';
import { CustomOrderedList } from './orderedList';
import { CustomStrike } from './strike';
import { CustomTableCell } from './tableCell';
import { CustomTableHeader } from './tableHeader';
import { CustomTableRow } from './tableRow';
import { CustomTextAlign } from './textAlign';
import { CustomTextStyle } from './textStyle';
import { CustomUnderline } from './underline';

import type {
  RichEditorV2HeadingOptions,
  RichEditorV2Options,
} from '@/constants/richEditorOptions';

export const extensions = (
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>,
  serviceId?: string,
) => [
  Document,
  Gapcursor,
  HardBreak,
  History,
  Paragraph,
  Text,
  CustomBlockquote.configure({
    inputRules: richEditorV2Options?.includes('blockquote'),
  }),
  CustomBold.configure({
    inputRules: richEditorV2Options?.includes('bold'),
  }),
  CustomBulletList.configure({
    inputRules: richEditorV2Options?.includes('listBullet'),
  }),
  CustomCode.configure({
    inputRules: richEditorV2Options?.includes('code'),
  }),
  CustomColor.configure({
    inputRules: richEditorV2Options?.includes('color'),
  }),
  CustomCodeBlock.configure({
    inputRules: richEditorV2Options?.includes('codeBlock'),
  }),
  CustomClass,
  CustomHeading.configure({
    inputRules: [
      'headerOne',
      'headerTwo',
      'headerThree',
      'headerFour',
      'headerFive',
    ].filter((level) => {
      return richEditorV2Options?.includes(level as RichEditorV2HeadingOptions);
    }) as RichEditorV2HeadingOptions[],
  }),
  CustomHorizontalRule.configure({
    inputRules: richEditorV2Options?.includes('horizontalRule'),
  }),
  CustomImageBlock.configure({
    inputRules: richEditorV2Options?.includes('image'),
    serviceId,
  }),
  CustomItalic.configure({
    inputRules: richEditorV2Options?.includes('italic'),
  }),
  CustomLink.configure({
    inputRules: richEditorV2Options.includes('link'),
    openOnClick: false,
    autolink: false,
  }),
  CustomListItem.configure({
    inputRules: ['listBullet', 'listOrdered'].filter((level) => {
      return richEditorV2Options.includes(level as RichEditorV2HeadingOptions);
    }) as RichEditorV2HeadingOptions[],
  }),
  CustomOrderedList.configure({
    inputRules: richEditorV2Options?.includes('listOrdered'),
  }),
  CustomStrike.configure({
    inputRules: richEditorV2Options?.includes('strike'),
  }),
  CustomTable.configure({
    inputRules: richEditorV2Options?.includes('table'),
  }),
  CustomTableCell.configure({
    inputRules: richEditorV2Options.includes('table'),
  }),
  CustomTableHeader.configure({
    inputRules: richEditorV2Options.includes('table'),
  }),
  CustomTableRow.configure({
    inputRules: richEditorV2Options.includes('table'),
  }),
  CustomTextAlign.configure({
    alignments: ['left', 'center', 'right'],
    types: ['heading', 'paragraph'],
    inputRules: richEditorV2Options?.includes('textAlign'),
  }),
  CustomTextStyle.configure({
    inputRules: ['customClass', 'color'].filter((option) => {
      return richEditorV2Options.includes(option as RichEditorV2Options);
    }) as RichEditorV2Options[],
  }),
  CustomUnderline.configure({
    inputRules: richEditorV2Options?.includes('underline'),
  }),
  CustomEmbed.configure({
    inputRules: richEditorV2Options?.includes('oembedly'),
  }),
];
