import type { Member, ServiceUser } from '@/entity/member';

/**
 * 引数valueがMember型であると判定された場合はTrueを返却
 *
 * id, serviceId, roleIdsが存在することで、Member型であることを判定する
 *
 * @param value
 * @returns
 */
const isMemberObject = (value: unknown): value is Member => {
  const member = value as Member;
  return (
    member.id !== undefined &&
    member.serviceId !== undefined &&
    member.roleIds !== undefined
  );
};

/**
 * 引数memberが招待中のメンバーの場合はTrueを返却
 *
 * @param value
 */
const isInvitingMember = <T extends Member | ServiceUser>(value: T) => {
  if (isMemberObject(value)) {
    return value.id.startsWith('PENDING#') || !!value.inviting;
  }

  return value.username.startsWith('PENDING#') || !!value.inviting;
};

/**
 * 引数valueから招待中のメンバーを除外した配列を返却
 *
 * @param value
 */
const filterNotInvitingMembers = <T extends Member | ServiceUser>(
  value: Array<T>,
) => {
  return value.filter((member) => !isInvitingMember(member));
};

export { isInvitingMember, filterNotInvitingMembers };
