import { useCookies } from 'react-cookie';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import { useGetMyServices } from '@/hooks/ServiceList/useServiceList';
import { useGetMyService } from '@/hooks/useService';

import ApiKeysTop from '../ApiKeysTop';
import ApiList from '../ApiList';
import ContentTop from '../ContentTop';
import CreateApi from '../CreateApi';
import CreateService from '../CreateService';
import Forbidden from '../Forbidden';
import ForgotPassword from '../ForgotPassword';
import GuestOnlyRoute from '../GuestOnlyRoute';
import LetsGo from '../LetsGo';
import MediaTop from '../MediaTop';
import MembersTop from '../MembersTop';
import NotFound from '../NotFound';
import PrivateRoute from '../PrivateRoute';
import Profile from '../Profile';
import ReviewsTop from '../ReviewsTop';
import RolesTop from '../RolesTop';
import Root from '../Root';
import SAMLCallback from '../SAMLCallback';
import SAMLSignin from '../SAMLSignin';
import ServiceSettings from '../ServiceSettings';
import Signin from '../Signin';
import Signup from '../Signup';
import Survey from '../Survey';
import VerifyInvitation from '../VerifyInvitation';
import Welcome from '../Welcome';

import styles from './routes.module.css';

import { getPureHost, getServiceDomain, isSAMLUser, isValidUrl } from '@/util';

type Props = {
  isAuthorized: boolean;
};

const Routes: React.FC<Props> = ({ isAuthorized }) => {
  const location = useLocation();
  const { isChildEnvironment, hasService } = useGetMyService();
  const { services, hasServices } = useGetMyServices();
  const serviceDomain = getServiceDomain();

  const [cookies, _, removeCookie] = useCookies([
    'ServiceTemplatesCallbackUrl',
  ]);

  // 認証チェック
  if (isAuthorized) {
    if (services === undefined) {
      return null;
    }

    // サービスが取得できるまでは待機
    if (!hasServices || !hasService) {
      return null;
    }

    // サービステンプレートからのCallbackURLが指定されている場合、サービステンプレートのURLにリダイレクト
    if (cookies.ServiceTemplatesCallbackUrl) {
      const callbackUrl = cookies.ServiceTemplatesCallbackUrl;
      removeCookie('ServiceTemplatesCallbackUrl', {
        path: '/',
        domain: getPureHost(),
        secure: window.location.protocol === 'https',
      });
      if (isValidUrl(callbackUrl)) {
        window.open(callbackUrl, '_self');
      }
      return null;
    }

    const isServiceDomain = services?.list?.find((list) => {
      return list.domain === serviceDomain;
    });

    // 存在しないサービスにアクセスした場合とprofileのサブドメインが一致しない場合リダイレクト
    if (
      (isServiceDomain?.domain === undefined && serviceDomain !== '') ||
      (serviceDomain !== '' &&
        location.pathname === '/profile' &&
        !isSAMLUser())
    ) {
      return (
        <Switch>
          <Route path="/not-found" component={NotFound} />
          <Redirect to="/not-found" />
        </Switch>
      );
    }

    //招待のトークン認証画面に遷移
    const params = new URLSearchParams(location.search);
    const token = params.get('token') || '';
    const pathname = window.location.pathname;

    if (token && pathname !== '/survey') {
      return (
        <Switch>
          <Route path="/verify-invitation" component={VerifyInvitation} />
          <Redirect
            to={{
              pathname: '/verify-invitation',
              search: `?token=${token}`,
            }}
          />
        </Switch>
      );
    }
  }

  return (
    <div className={styles.wrapper}>
      <Switch>
        <Route exact path="/" component={Root} />
        <Route exact path="/not-found" component={NotFound} />
        <Route exact path="/forbidden" component={Forbidden} />
        <GuestOnlyRoute exact path="/signup" component={Signup} />
        <GuestOnlyRoute exact path="/signin" component={Signin} />
        <GuestOnlyRoute
          exact
          path="/forgot-password"
          component={ForgotPassword}
        />
        <GuestOnlyRoute exact path="/sso/saml/signin" component={SAMLSignin} />
        <GuestOnlyRoute
          exact
          path="/sso/saml/callback"
          component={SAMLCallback}
        />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute exact path="/survey" component={Survey} />
        <PrivateRoute exact path="/welcome" component={Welcome} />
        <PrivateRoute exact path="/create-service" component={CreateService} />
        <PrivateRoute exact path="/letsgo/:domain" component={LetsGo} />
        <PrivateRoute exact path="/create-api" component={CreateApi} />
        <PrivateRoute path="/settings" component={ServiceSettings} />
        <PrivateRoute path="/reviews" component={ReviewsTop} />
        <PrivateRoute path="/media" component={MediaTop} />
        {!isChildEnvironment && (
          <PrivateRoute path="/members" component={MembersTop} />
        )}
        {!isChildEnvironment && (
          <PrivateRoute path="/roles" component={RolesTop} />
        )}
        <PrivateRoute path="/api-keys" component={ApiKeysTop} />
        <PrivateRoute exact path="/apis" component={ApiList} />
        <PrivateRoute path="/apis/:endpoint" component={ContentTop} />
        <PrivateRoute path="/verify-invitation" component={VerifyInvitation} />

        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default Routes;
