import { useState, useCallback, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Expander from '../../../Expander';
import Button from '../../../ui/Button';
import IconButton from '../../../ui/IconButton';
import Switch from '../../../ui/Switch';
import Textfield from '../../../ui/Textfield';
import {
  validateUrl,
  validateSecret,
  validateCustomRequestHeaderKey,
  validateCustomRequestHeaderValue,
} from '../../../Validations';
import styles from '../webhookSettings.module.css';

import type { WebhookEvents } from '../../../../types/webhook';
import type {
  CreateNewWebhookSetting,
  CreateNewWebhookSettingForCustom,
  WebhookSetting,
  WebhookSettingForCustom,
} from '@/entity/webhookSettings';

import { useInput } from '@/views/Common/useInput';
import { useInputWebhookEvents } from '@/views/WebhookSettings/helpers/useInputWebehookEvents';

type Props = {
  setting: WebhookSettingForCustom | CreateNewWebhookSettingForCustom;
  settings: Array<WebhookSetting | CreateNewWebhookSetting>;
  createSetting: any;
  updateSetting: any;
  deleteSetting: any;
  loading: boolean;
  updatable?: boolean;
  onChangeEnabled: (setting: any) => void;
  updateWebhookEnableLoading: boolean;
  webhookEvents: WebhookEvents[];
};

const CustomWebhook: React.FC<Props> = ({
  setting,
  settings,
  createSetting,
  updateSetting,
  deleteSetting,
  loading,
  updatable,
  onChangeEnabled,
  updateWebhookEnableLoading,
  webhookEvents,
}) => {
  const { t } = useTranslation('webhooks');
  const isNew = useMemo(
    () => setting && setting.apiId === undefined,
    [setting],
  );

  const [name, onChangeName, nameError] = useInput(
    (setting && setting.settingValues && setting.settingValues.name) || '',
    () => null,
    true,
  );

  const [customRequestHeaders, setCustomRequestHeaders] = useState<
    Array<{ key: string; value: string }>
  >([]);
  useEffect(() => {
    if (setting?.settingValues?.customRequestHeaders?.length) {
      setCustomRequestHeaders(setting.settingValues.customRequestHeaders);
    } else {
      setCustomRequestHeaders([]);
    }
  }, [setting]);

  const onChangeCustomRequestHeaderKeys = (e: any) => {
    const { name, value } = e.target;
    const headers = customRequestHeaders;
    const target = headers[name];
    target.key = value.trim();
    // @ts-expect-error
    target.keyError = validateCustomRequestHeaderKey(value);
    headers[name] = target;
    setCustomRequestHeaders([...headers]);
  };

  const onChangeCustomRequestHeaderValues = (e: any) => {
    const { name, value } = e.target;
    const headers = customRequestHeaders;
    const target = headers[name];
    target.value = value.trim();
    // @ts-expect-error
    target.valueError = validateCustomRequestHeaderValue(value);
    headers[name] = target;
    setCustomRequestHeaders([...headers]);
  };

  const onClickCustomRequestHeaderAddButton = () => {
    const headers: any = [...customRequestHeaders];
    headers.push({
      id: Math.random().toString(32).substring(2),
      key: '',
      value: '',
      keyError: validateCustomRequestHeaderKey(''),
      valueError: validateCustomRequestHeaderValue(''),
    });
    setCustomRequestHeaders(headers);
  };

  const onClickCustomRequestHeaderDeleteButton = (index: number) => {
    const headers = [...customRequestHeaders];
    headers.splice(index, 1);
    setCustomRequestHeaders(headers);
  };

  const hasCustomRequestHeaderError = () => {
    if (
      customRequestHeaders.find((element) => {
        // @ts-expect-error
        return element.keyError || element.valueError;
      })
    ) {
      return true;
    }
    return false;
  };

  const [secret, onChangeSecret, secretError] = useInput(
    setting?.settingValues?.secret || '',
    validateSecret,
    true,
  );
  const [customUrl, onChangeCustomUrl, customUrlError] = useInput(
    (setting && setting.settingValues && setting.settingValues.url) || '',
    validateUrl,
    true,
  );

  const [events, onChangeEvents] = useInputWebhookEvents(setting.handleEvents);

  const remove = useCallback(() => {
    deleteSetting(setting);
  }, [deleteSetting, setting]);

  const submit = useCallback(() => {
    const headers = customRequestHeaders
      .map((element) => {
        const { key, value } = element;
        return { key, value };
      })
      .filter((element) => {
        return element.key && element.value;
      });

    if (isNew) {
      createSetting(
        'CUSTOM',
        {
          name,
          url: customUrl,
          secret,
          customRequestHeaders: headers || null,
        },
        events.join(',').split(','),
      );
    } else if (customUrl) {
      updateSetting(
        setting,
        {
          ...setting.settingValues,
          name: name || null,
          url: customUrl || null,
          secret: secret || null,
          customRequestHeaders: headers || null,
        },
        events.join(',').split(','),
      );
    }
  }, [
    isNew,
    customUrl,
    createSetting,
    name,
    secret,
    events,
    updateSetting,
    setting,
    customRequestHeaders,
  ]);

  if (setting === undefined) {
    return null;
  }

  return (
    <Expander
      title={
        <div className={styles.title}>
          <img className={styles.icon} src="/images/icon_custom.png" alt="" />
          <span className={styles.name}>{t('Custom Notifications')}</span>
          {setting && setting.settingValues && setting.settingValues.name && (
            <span className={styles.webhookName}>
              {setting.settingValues.name}
            </span>
          )}
          <Switch
            className={styles.switch}
            on={
              setting.enabled === null
                ? true
                : typeof setting.enabled === 'undefined'
                  ? true
                  : setting.enabled
            }
            onChange={() => onChangeEnabled(setting)}
            onClick={(e) => e.stopPropagation()}
            disabled={
              updateWebhookEnableLoading ||
              settings.length !==
                settings.filter((setting: any) => setting.apiId !== undefined)
                  .length /* 作成中を含むwebhook要素数と作成ずみのwebhookの数を比較　*/
            }
          />
        </div>
      }
      defaultOpen={isNew}
    >
      <div className={styles.sectionWrapper}>
        <p className={styles.timingGroupLabel}>{t('Basic Settings')}</p>
        <p className={styles.description}>
          {t('Set Webhook name which can be identified. (Optional)')}
        </p>
        <Textfield
          type="text"
          placeholder={t('Webhook Name')}
          defaultValue={name}
          onChange={onChangeName}
          errorText={nameError}
        />
        <p className={styles.description}>
          {Trans({
            t,
            i18nKey:
              'Notify any URL with a POST request for API or content updates. For more information, refer to the documentation for more details.',
            children: (
              <a
                href="https://document.microcms.io/manual/webhook-setting#hd95625726f"
                target="docs"
              >
                the documentation
              </a>
            ),
          })}
        </p>
        <Textfield
          type="text"
          placeholder="https://webhook.example.com/your_receiver"
          defaultValue={customUrl}
          onChange={onChangeCustomUrl}
          errorText={customUrlError}
          readOnly={!updatable}
        />
      </div>
      <div className={styles.sectionWrapper}>
        <p className={styles.timingGroupLabel}>{t('Secret')}</p>
        <p className={styles.description}>
          {Trans({
            t,
            i18nKey:
              'Enter secret value to be used for Signature generation. See the documentation for details. (Optional)',
            children: (
              <a
                href="https://document.microcms.io/manual/webhook-setting#hb2d39bd6cc"
                target="docs"
              >
                the documentation
              </a>
            ),
          })}
        </p>
        <Textfield
          type="password"
          placeholder="Secret value"
          defaultValue={secret}
          onChange={onChangeSecret}
          errorText={secretError}
        />
      </div>
      <div className={styles.sectionWrapper}>
        <p className={styles.timingGroupLabel}>{t('Custom Request Headers')}</p>
        <p className={styles.description}>
          {t(
            'You can add a request header when calling this webhook from microCMS. The key name must start with X-.',
          )}
          <br />
          {t(
            'If you wish to set your own API key, etc. in the request header, add them here. (optional)',
          )}
        </p>
        {customRequestHeaders.map((element: any, index: any) => (
          <div
            key={element.id}
            className={styles.customRequestHeadersContainer}
          >
            <Textfield
              type="text"
              id="key"
              labelName="Key"
              placeholder="X-MICROCMS-API-KEY"
              name={index}
              value={element.key}
              onChange={onChangeCustomRequestHeaderKeys}
              errorText={element.keyError}
            />
            <Textfield
              type="text"
              id="value"
              labelName="Value"
              placeholder="API-KEY-VALUE"
              name={index}
              value={element.value}
              onChange={onChangeCustomRequestHeaderValues}
              errorText={element.valueError}
            />
            <IconButton
              icon="clear"
              onClick={() => onClickCustomRequestHeaderDeleteButton(index)}
              className={styles.deleteButton}
            />
          </div>
        ))}
        <div className={styles.sectionButton}>
          <Button
            type="tertiary"
            icon="add"
            value={t('Add')}
            onClick={onClickCustomRequestHeaderAddButton}
          ></Button>
        </div>
      </div>
      <div className={styles.sectionWrapper}>
        <p className={styles.timingGroupLabel}>
          {t('Notification timing settings')}
        </p>
        {webhookEvents.map(({ label, values }, i) => (
          <div className={styles.section} key={i}>
            <p className={styles.description}>{label}</p>
            <div className={styles.checkboxes}>
              {values.map(
                ({ label, value }, i) =>
                  label &&
                  value && (
                    <div className={styles.timingDetailLabel} key={i}>
                      <label className={styles.checkbox}>
                        <input
                          type="checkbox"
                          value={value}
                          checked={events.includes(value)}
                          onChange={onChangeEvents}
                          disabled={!updatable}
                        ></input>
                        {label}
                      </label>
                    </div>
                  ),
              )}
            </div>
          </div>
        ))}
        {updatable && (
          <div className={styles.actions}>
            <Button
              type="primary"
              className="ga-api-settings-webhook-custom"
              disabled={
                loading ||
                !!customUrlError ||
                !customUrl ||
                events.length === 0 ||
                hasCustomRequestHeaderError()
              }
              value={isNew ? t('Setup') : t('Save changes')}
              onClick={submit}
            />
            {!isNew && (
              <Button
                type="danger"
                disabled={loading}
                value={t('Delete')}
                onClick={remove}
              />
            )}
          </div>
        )}
      </div>
    </Expander>
  );
};

export default CustomWebhook;
