import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  useCreateAccount,
  useUpdateAccountSurvey,
} from '../../hooks/Account/useAccount';
import Head from '../Head';
import Button from '../ui/Button';
import Fieldset from '../ui/Fieldset';
import Selectfield from '../ui/Selectfield';

import styles from './survey.module.css';

const Survey: React.FC = () => {
  const { t } = useTranslation('survey');
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const token = params.get('token') || '';

  const [job, setJob] = useState('');
  const [product, setProduct] = useState('');
  const [teamSize, setTeamSize] = useState('');
  const [comeFrom, setComeFrom] = useState('');

  const { updateAccountSurvey, updateAccountSurveyLoading } =
    useUpdateAccountSurvey();

  const submit = useCallback(() => {
    updateAccountSurvey({
      question: {
        job,
        product,
        teamSize,
        comeFrom,
        isInvitedUser: token ? true : false,
      },
      redirectPath: `/${token && `?token=${token}`}`,
    });
  }, [job, product, teamSize, comeFrom, token, updateAccountSurvey]);

  return (
    <div className={styles.wrapper}>
      <Head title={t('Survey')} />
      <div className={styles.container}>
        <h2 className={styles.title}>{t('Thank you for registering!')}</h2>
        <p className={styles.description}>
          {t(
            'Please fill out the survey so we can provide you better support.',
          )}
        </p>

        <div className={styles.box}>
          <Fieldset legend={t('What is your job role?')}>
            <Selectfield
              value={job}
              onChange={(e) => setJob(e.target.value)}
              className={styles.select}
            >
              <option value="">{t('Select')}</option>
              <option value="エンジニア">{t('Engineer')}</option>
              <option value="ディレクター">{t('Director')}</option>
              <option value="ライター">{t('Writer')}</option>
              <option value="デザイナー">{t('Designer')}</option>
              <option value="その他">{t('Other')}</option>
            </Selectfield>
          </Fieldset>

          <Fieldset legend={t('What type of service are you developing?')}>
            <Selectfield
              value={product}
              onChange={(e) => setProduct(e.target.value)}
              className={styles.select}
            >
              <option value="">{t('Select')}</option>
              <option value="Webサイト">{t('Website')}</option>
              <option value="モバイルアプリ">{t('Mobile Apps')}</option>
              <option value="IoT、 デジタルサイネージ">
                {t('IoT and digital signage')}
              </option>
              <option value="その他">{t('Other')}</option>
            </Selectfield>
          </Fieldset>

          <Fieldset legend={t('What is the size of your company?')}>
            <Selectfield
              value={teamSize}
              onChange={(e) => setTeamSize(e.target.value)}
              className={styles.select}
            >
              <option value="">{t('Select')}</option>
              <option value="1人">{t('1 person')}</option>
              <option value="2-10人">{t('2-10 people')}</option>
              <option value="11-100人">{t('11-100 people')}</option>
              <option value="101-1000人">{t('101-1000 people')}</option>
              <option value="1000人以上">{t('Over 1000 people')}</option>
            </Selectfield>
          </Fieldset>

          <Fieldset legend={t('How did you hear about microCMS?')}>
            <Selectfield
              value={comeFrom}
              onChange={(e) => setComeFrom(e.target.value)}
              className={styles.select}
            >
              <option value="">{t('Select')}</option>
              <option value="Twitter">{t('Twitter')}</option>
              <option value="ミートアップ・イベント">
                {t('Meetups and events')}
              </option>
              <option value="友人・知人から">
                {t('From friends and acquaintances')}
              </option>
              <option value="検索">{t('Search engines')}</option>
              <option value="microCMSブログ">{t('microCMS blog')}</option>
              <option value="外部メディア">{t('External Media')}</option>
              <option value="その他">{t('Other')}</option>
            </Selectfield>

            <div className={styles.actions}>
              <Button
                type="secondary"
                size="full"
                value={t('Submit')}
                onClick={submit}
                disabled={updateAccountSurveyLoading}
              />
            </div>
            <div className={styles.link}>
              <Link to={`/${token && `?token=${token}`}`}>{t('Skip')}</Link>
            </div>
          </Fieldset>
        </div>
      </div>
    </div>
  );
};

export default Survey;
