import { apiRepository } from '@/repository/apiRepository';

import type { ApiType, ContentIdSetting, CustomField, Field } from '@/API';
import {
  type MigrateApi,
  type RestrictedApi,
  isRestrictedApi,
} from '@/entity/api';
import type { Service } from '@/types/services';
import { filterUniqueValues } from '@/util/array';

/**
 * 指定されたAPIIDに基づいてAPI情報を取得し、必要な形式に変換します
 * @param {Object} params - パラメータオブジェクト
 * @param {string} params.apiId - 取得対象のAPIのID
 * @returns {Promise<(MigrateApi | RestrictedApi)[]>} 変換されたAPI情報
 * */
export const findApis = async ({
  service,
}: { service: Service }): Promise<{ list: (MigrateApi | RestrictedApi)[] }> => {
  const { findApis } = apiRepository();
  const apis = await findApis({ serviceId: service.partitionKey });
  if (!apis?.length) {
    return { list: [] };
  }

  const list = apis.map((api) => {
    if (isRestrictedApi(api)) {
      return api;
    }

    return {
      ...api,
      //新DB移行にあたって、旧DBで取得できていた値を調整して入れておく
      apiEndpoint: api.gsiSortKey2.replace('ENDPOINT#', ''),
      contentId: api.gsiSortKey2.replace('ENDPOINT#', ''),
      domain: service.gsiSinglePartitionKey1.replace('DOMAIN#', ''),
      id: service.gsiSinglePartitionKey1.replace('DOMAIN#', ''),
      serviceId: service.partitionKey, //各所で必要になるため予め持っておく
    };
  });

  return { list };
};

export const createApi = async ({
  serviceId,
  apiName,
  apiEndpoint,
  apiType,
  apiFields,
  customFields,
}: {
  serviceId: string;
  apiName: string;
  apiEndpoint: string;
  apiType: ApiType;
  apiFields: Field[];
  customFields: CustomField[];
}) => {
  const { createApi } = apiRepository();
  return await createApi({
    serviceId,
    apiName,
    apiEndpoint,
    apiType,
    apiFields: apiFields.map((v) => {
      return {
        ...v,
        isAdditionalField: undefined,
        // richEditorV2ColorListが存在する場合は重複を削除する
        richEditorV2ColorList: v.richEditorV2ColorList
          ? filterUniqueValues(v.richEditorV2ColorList)
          : undefined,
      };
    }),
    customFields,
  });
};

export const updateApi = async ({
  apiId,
  name,
  endpoint,
  fields,
  previewUrl,
  previewLinkIsVisible,
  sortOrderValue,
  contentIdSetting,
}: {
  apiId: string;
  name?: string;
  endpoint?: string;
  fields?: Field[];
  previewUrl?: string;
  previewLinkIsVisible?: boolean;
  sortOrderValue?: string;
  contentIdSetting?: ContentIdSetting;
}) => {
  const { updateApi } = apiRepository();
  return await updateApi({
    apiId,
    name,
    endpoint,
    fields: fields?.map((v) => {
      return {
        ...v,
        isAdditionalField: undefined,
        // richEditorV2ColorListが存在する場合は重複を削除する
        richEditorV2ColorList: v.richEditorV2ColorList
          ? filterUniqueValues(v.richEditorV2ColorList)
          : undefined,
      };
    }),
    previewUrl,
    previewLinkIsVisible,
    sortOrderValue,
    contentIdSetting,
  });
};

export const updateApiDescription = async ({
  apiId,
  description,
}: {
  apiId: string;
  description: string;
}) => {
  const { updateApiDescription } = apiRepository();
  return await updateApiDescription({
    apiId,
    description,
  });
};

export const resetImportProgress = async ({ apiId }: { apiId: string }) => {
  const { resetImportProgress } = apiRepository();
  return await resetImportProgress({
    apiId,
  });
};

export const updateSortOrderValue = async ({
  apiId,
  sortOrderValue,
}: {
  apiId: string;
  sortOrderValue: string;
}) => {
  const { updateSortOrderValue } = apiRepository();
  return await updateSortOrderValue({ apiId, sortOrderValue });
};

export const deleteApi = async ({ apiId }: { apiId: string }) => {
  const { deleteApi } = apiRepository();
  return await deleteApi({ apiId });
};
