import React from 'react';

const TypeList = ({ className }) => (
  <svg
    className={className}
    width="16px"
    height="10px"
    viewBox="0 0 16 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>icon_type_list</title>
    <g id="PC" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-510.000000, -124.000000)">
        <g id="icon_type_list" transform="translate(510.000000, 124.000000)">
          <rect id="Rectangle" x="0" y="0" width="2" height="2" />
          <rect id="Rectangle" x="0" y="4" width="2" height="2" />
          <rect id="Rectangle" x="0" y="8" width="2" height="2" />
          <rect id="Rectangle" x="4" y="0" width="12" height="2" />
          <rect id="Rectangle" x="4" y="4" width="12" height="2" />
          <rect id="Rectangle" x="4" y="8" width="12" height="2" />
        </g>
      </g>
    </g>
  </svg>
);

export default TypeList;
