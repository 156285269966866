import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from './_types';
import type { Role } from '@/entity/role';

import { produce } from '@/lib/immer';

type SetUsagePermissionReadAction = FormRoleInputValueAction<
  'set-usage-permission-read',
  Role['usagePermission']['read']
>;
type UsagePermissionAction = SetUsagePermissionReadAction;

/**
 * UsagePermissionのreadフィールドを更新する
 */
const setUsagePermissionRead: FormRoleInputValueDispatchFn<
  SetUsagePermissionReadAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.usagePermission.read = payload;
  });
};

export { type UsagePermissionAction, setUsagePermissionRead };
