import { apiListSelectors } from '@/ducks/apiList';
import type { ApiList, ApiListByRedux } from '@/entity/api';
import { useAppSelector } from '@/store/hooks';
import { useReviewRequestCreatePermission } from '@/views/Common/ReviewRequestPermission/useReviewRequestCreatePermission';
import type React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useInput } from '../../hooks';
import { useContent } from '../../hooks/Content/useContentReader';
import { useCreateReview } from '../../hooks/Review/useReviews';
import { usePermissionIsHaveLeastOne } from '../../hooks/Role/useMyRoles';
import Head from '../Head';
import { validateReviewRequestTitle } from '../Validations';
import Button from '../ui/Button';
import Fieldset from '../ui/Fieldset';
import Textarea from '../ui/Textarea';
import Textfield from '../ui/Textfield';
import styles from './createReview.module.css';

const CreateReview: React.FC = () => {
  const { t } = useTranslation('createReview');
  const history = useHistory();

  // TODO: ReduxをReactQueryに置き換える
  const { contentId } = useParams<{ contentId: string }>();
  const { apiList } = useAppSelector(
    (state) =>
      apiListSelectors.get(state.apiListState as ApiList) as ApiListByRedux,
  );

  const [title, setTitle, titleError] = useInput(
    '',
    // @ts-expect-error
    validateReviewRequestTitle,
  );
  const [description, setDescription] = useInput(undefined);
  const { createReviewRequest, createReviewRequestLoading }: any =
    useCreateReview(contentId, history);

  //コンテンツデータ
  const [data]: any = useContent(`CONTENT#${contentId}`);
  const slug = useMemo(() => {
    return data && data.gsiSortKey2.replace(/.*#CONTENT_ID#/, '');
  }, [data]);

  //権限
  const hasCreateReviewPermission = useReviewRequestCreatePermission({
    apiId: data && data.gsiPartitionKey1.replace('#CONTENT', ''),
  });
  const [hasCreatePermission] = usePermissionIsHaveLeastOne(
    'reviewRequestPermission',
    'create',
  );

  //APIデータ
  const api = useMemo(() => {
    if (!apiList || !data) {
      return;
    }
    const apiId = data && data.gsiPartitionKey1.replace('#CONTENT', '');
    return apiList.find((item) => item.partitionKey === apiId);
  }, [apiList, data]);

  return (
    <div className={styles.wrapper}>
      <Head title={t('Review Request')} />
      <header className={styles.header}>
        <button className={styles.backButton} onClick={() => history.goBack()}>
          <i className="material-icons-outlined">arrow_back_ios</i>
          {api && slug && (
            <span className={styles.contentLink}>
              {api.apiName} &gt; {slug}
            </span>
          )}
        </button>
        <div className={styles.action}>
          {hasCreateReviewPermission && (
            <Button
              className="ga-submit-review"
              onClick={() => createReviewRequest({ title, description })}
              disabled={
                createReviewRequestLoading || titleError || title.length === 0
              }
              value={t('Request')}
            />
          )}
        </div>
      </header>
      <main className={styles.main}>
        <Fieldset
          legend={t('Title')}
          description={t('Enter a brief description of review request.')}
          option={false}
        >
          <Textfield
            type="text"
            defaultValue={title}
            onChange={setTitle}
            errorText={titleError}
          />
        </Fieldset>
        <Fieldset legend={t('Description')}>
          <Textarea defaultValue={description} onChange={setDescription} />
        </Fieldset>
      </main>
    </div>
  );
};

export default CreateReview;
