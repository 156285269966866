import cx from 'classnames';
import type React from 'react';
import { useTranslation } from 'react-i18next';

import { useTextarea } from './useTextareaField';
import { Textarea } from '../Textarea/Textarea';
import { TextBottomAlert } from '../TextBottomAlert';
import { useFieldIds } from '../useFieldIds';

import type { NumberRange } from '@/types/field';

import styles from './textareafield.module.css';

export type Props = {
  label: string;
  error?: string;
  textCount?: number;
  textareaRef?: React.Ref<HTMLTextAreaElement>;
  onEnter?: () => void;
  textSize?: NumberRange;
  displayTextCount?: boolean;
  description?: React.ReactNode;
} & Omit<React.ComponentProps<'textarea'>, 'size'>;

export type ViewProps = Omit<Props, 'onEnter'> &
  ReturnType<typeof useFieldIds> &
  ReturnType<typeof useTextarea>;

export const TextareaFieldView: React.FC<ViewProps> = ({
  textareaId,
  label,
  labelId,
  description,
  descriptionId,
  error,
  errorId,
  textCount = 0,
  textareaRef,
  textSize,
  displayTextCount,
  ...props
}) => {
  const { t } = useTranslation('commonUi');

  return (
    <div>
      <label id={labelId} htmlFor={textareaId} className={styles.label}>
        <span>{label}</span>
        {props.required && (
          <span className={styles.required}>
            <span aria-hidden>*</span>
            <span className="sr-only">{t('Required')}</span>
          </span>
        )}
      </label>
      {description && (
        <div id={descriptionId} className={styles.description}>
          {description}
        </div>
      )}
      <Textarea
        {...props}
        ref={textareaRef}
        id={textareaId}
        className={cx(styles.textarea, props.className)}
        aria-labelledby={labelId}
        aria-describedby={
          [descriptionId, errorId].filter(Boolean).join(' ') || undefined
        }
        aria-invalid={props['aria-invalid'] ?? !!error}
        aria-readonly={props['aria-readonly'] ?? props.readOnly}
      />
      <TextBottomAlert
        errorText={error}
        errorId={errorId}
        textSize={textSize}
        textCount={textCount}
        displayTextCount={displayTextCount}
      />
    </div>
  );
};

export const TextareaField: React.FC<Props> = ({ onEnter, ...props }) => {
  const fieldIds = useFieldIds(props);
  const hooks = useTextarea({ onEnter, ...props });

  return <TextareaFieldView {...props} {...fieldIds} {...hooks} />;
};
