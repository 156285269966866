import { useCallback, useMemo } from 'react';

import type { Props, RowProps, ViewProps } from './SearchRoles';

export const useSearchRoles = ({
  selectableRoles: propsSelectableRoles,
  selectedRoleIds,
  addRoleId,
  removeRoleId,
  closeSearchRoles,
}: Props): ViewProps => {
  const selectableRoles = useMemo(() => {
    if (propsSelectableRoles === null) {
      return null;
    }

    return propsSelectableRoles.map((role) => ({
      key: role.roleId,
      ...role,
    }));
  }, [propsSelectableRoles]);

  const filterRule = useCallback((item: RowProps, inputValue: string) => {
    if (item.name.toLowerCase().includes(inputValue.toLowerCase())) {
      return true;
    }

    return false;
  }, []);

  const onConfirm = useCallback(
    ({ roleId }: RowProps) => {
      const isSelected = selectedRoleIds.includes(roleId);
      isSelected ? removeRoleId(roleId) : addRoleId(roleId);
    },
    [addRoleId, removeRoleId, selectedRoleIds],
  );

  return {
    closeSearchRoles,
    selectableRoles,
    currentValueKeys: selectedRoleIds,
    filterRule,
    onConfirm,
  };
};
