import BulletList from '@tiptap/extension-bullet-list';

import type { BulletListOptions } from '@tiptap/extension-bullet-list';

type CustomBulletListOptions = BulletListOptions & {
  inputRules: boolean;
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-bullet-list/src/bullet-list.ts
export const CustomBulletList = BulletList.extend<CustomBulletListOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: true,
    };
  },

  parseHTML() {
    return this.options.inputRules ? this.parent?.() : [];
  },

  addKeyboardShortcuts() {
    return this.options.inputRules ? { ...this.parent?.() } : {};
  },

  addInputRules() {
    return this.options.inputRules ? this.parent?.() || [] : [];
  },
});
