/**
 * 何をするためのレビューリクエストかを判定するために必要な情報
 */
export const reviewTarget = {
  /**
   * DRAFT_TO_PUBLISH: 下書きを公開するためのレビューリクエスト
   */
  DRAFT_TO_PUBLISH: 'DRAFT_TO_PUBLISH',
  /**
   * UPDATE: 既に公開済みのコンテンツを更新するためのレビューリクエスト（公開中かつ下書き中のコンテンツの下書きデータを公開するためのもの）
   */
  UPDATE: 'UPDATE',
  /**
   * PUBLISH_TO_CLOSE: 公開中のコンテンツを公開終了にするためのレビューリクエスト
   */
  PUBLISH_TO_CLOSE: 'PUBLISH_TO_CLOSE',
} as const;
export type ReviewTarget = (typeof reviewTarget)[keyof typeof reviewTarget];
