import { useFormRoleDispatch } from '../../../provider';

import type { PropsWithECPId } from '../_types';
import type { FC } from 'react';

import styles from './RemoveButton.module.css';

import { Icon } from '@/views/Common/Ui/Icon';

const RemoveButton: FC<PropsWithECPId> = ({ ecpId }) => {
  const dispatch = useFormRoleDispatch();

  return (
    <button
      type="button"
      onClick={() =>
        dispatch({
          type: 'remove-ec-permission',
          payload: ecpId,
        })
      }
      className={styles.button}
      data-testid="removeECPButton"
    >
      <Icon name="clear" size="large" />
    </button>
  );
};

export default RemoveButton;
