import { mergeAttributes, Node, nodeInputRule } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { ImageBlock } from './ImageBlock';

export { ImageBlockView, type ViewProps } from './ImageBlock';

export type ImageAttributes = {
  medium: {
    serviceId: string;
    mediumId: string;
  };
  alt: string;
  customWidth?: number;
  customHeight?: number;
  link: string;
  figcaption?: string;
  isTargetBlank: boolean;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    image: {
      setImage: (options: ImageAttributes) => ReturnType;
    };
  }
}

type CustomImageBlockOptions = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  HTMLAttributes: Record<string, any>;
  inline: boolean;
  inputRules: boolean;
  serviceId: string;
};

export const inputRegex =
  /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/;

export const CustomImageBlock = Node.create<CustomImageBlockOptions>({
  name: 'imageBlock',

  addOptions() {
    return {
      inputRules: true,
      inline: false,
      HTMLAttributes: {},
      serviceId: '',
    };
  },

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? 'inline' : 'block';
  },

  draggable: true,

  addAttributes() {
    return {
      medium: {
        rendered: false,
      },
      alt: {
        default: '',
      },
      customWidth: {
        default: null,
      },
      customHeight: {
        default: null,
      },
      figcaption: {
        default: null,
      },
      link: {
        default: '',
        rendered: false,
      },
      isTargetBlank: {
        default: false,
        rendered: false,
      },
      'data-path': {
        default: '',
        renderHTML: (attributes) => {
          return {
            'data-path': JSON.stringify({
              mediumId: attributes.medium.mediumId,
              serviceId: attributes.medium.serviceId,
              alt: attributes.alt,
              link: attributes.link,
              isTargetBlank: attributes.isTargetBlank,
            }),
          };
        },
      },
    };
  },

  parseHTML() {
    return this.options.inputRules
      ? [
          // 画像をコピー&ペーストした場合のパース処理
          {
            tag: 'img[data-path]',
            getAttrs: (node) => {
              if (node instanceof HTMLElement) {
                const dataPath = node.getAttribute('data-path');
                const attrs = dataPath ? JSON.parse(dataPath) : null;
                const customWidth =
                  node.getAttribute('data-w') ||
                  node.getAttribute('customWidth');
                const customHeight =
                  node.getAttribute('data-h') ||
                  node.getAttribute('customHeight');

                // data-pathの値が正しいか整合性チェック
                if (!attrs?.serviceId || !attrs?.mediumId) {
                  return false;
                }

                // 画像のサービスIDが一致しない場合はパースしない
                if (attrs.serviceId !== this.options.serviceId) {
                  return false;
                }

                return {
                  medium: {
                    mediumId: attrs.mediumId,
                    serviceId: attrs.serviceId,
                  },
                  alt: attrs.alt ?? '',
                  customWidth: customWidth
                    ? Number.parseInt(customWidth, 10)
                    : null,
                  customHeight: customHeight
                    ? Number.parseInt(customHeight, 10)
                    : null,
                  link: attrs.link ?? '',
                  isTargetBlank: attrs.isTargetBlank ?? false,
                };
              } else {
                return false;
              }
            },
          },
        ]
      : [];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'img',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addCommands() {
    return {
      setImage:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addInputRules() {
    return this.options.inputRules
      ? [
          nodeInputRule({
            find: inputRegex,
            type: this.type,
            getAttributes: (match) => {
              const [, , alt, width, height, link] = match;

              return { alt, width, height, link };
            },
          }),
        ]
      : [];
  },

  addNodeView() {
    return ReactNodeViewRenderer(ImageBlock);
  },
});
