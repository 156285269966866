import { EmbedBlock } from './EmbedBlock';
import { ImageBlock } from './ImageBlock';
import { LinkBlock } from './LinkBlock';

import type { RichEditorV2Options } from '@/constants/richEditorOptions';
import type { Editor } from '@tiptap/react';

import style from './embed.module.css';

export type Props = {
  editor: Editor;
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
};

export type ViewProps = {
  editor: Editor;
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
};

export const EmbedView: React.FC<ViewProps> = ({
  editor,
  richEditorV2Options,
}) => {
  return (
    <div className={style.main}>
      {richEditorV2Options.includes('link') && <LinkBlock editor={editor} />}
      {richEditorV2Options.includes('image') && <ImageBlock editor={editor} />}
      {richEditorV2Options.includes('oembedly') && (
        <EmbedBlock editor={editor} />
      )}
    </div>
  );
};

export const Embed: React.FC<Props> = ({ editor, richEditorV2Options }) => {
  return (
    <EmbedView editor={editor} richEditorV2Options={richEditorV2Options} />
  );
};
