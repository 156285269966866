import type React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Fieldset from '../../ui/Fieldset';
import Switch from '../../ui/Switch';
import Textfield from '../../ui/Textfield';
import { validateNumberRange } from '../../Validations/index';
import styles from '../apiModel.module.css';

type NumberRange = {
  min?: number;
  max?: number;
};

type RelationListCountLimitValidation = {
  relationListCount: NumberRange;
};

type Props = {
  relationListCountLimitValidation: RelationListCountLimitValidation;
  idValue: string;
  onChange: (validation?: RelationListCountLimitValidation) => void;
  onError: (error: string | null) => void;
};

const RelationListCountValidationField: React.FC<Props> = ({
  relationListCountLimitValidation,
  idValue,
  onChange = () => {},
  onError = () => {},
}) => {
  const { t } = useTranslation('relationListCountValidationField');
  const [enabled, setEnabled] = useState(
    () => !!relationListCountLimitValidation,
  );
  const [min, setMin] = useState<number | undefined>(
    () => relationListCountLimitValidation?.relationListCount.min,
  );
  const [max, setMax] = useState<number | undefined>(
    () => relationListCountLimitValidation?.relationListCount.max,
  );
  const [errorText, setErrorText] = useState<string | null>(null);
  const [validation, setValidation] =
    useState<RelationListCountLimitValidation>();

  const onChangeMin = useCallback(({ target }: any) => {
    setMin(target.value ? Number(target.value) : undefined);
  }, []);

  const onChangeMax = useCallback(({ target }: any) => {
    setMax(target.value ? Number(target.value) : undefined);
  }, []);

  useEffect(() => {
    onError(errorText);
  }, [errorText, onError]);

  useEffect(() => {
    onChange(validation);
  }, [onChange, validation]);

  useEffect(() => {
    if (!enabled) {
      setValidation(undefined);
      setErrorText(null);
      return;
    }
    const error = validateNumberRange(min, max);
    setErrorText(error);
    if (!error) {
      setValidation({ relationListCount: { min, max } });
    }
  }, [enabled, min, max]);

  return (
    <Fieldset
      legend={t('Limit the number of multiple content references')}
      description={t(
        'Sets the minimum and maximum number of multiple content references.',
      )}
    >
      <Switch
        on={enabled}
        onChange={(state) => setEnabled(state)}
        size="large"
      />
      {enabled && (
        <>
          <div className={styles.validationContent}>
            <Textfield
              defaultValue={min}
              id={`${idValue}_validation-relationList-count-min`}
              className={styles.validationContentField}
              labelName={t('minimum')}
              type="number"
              placeholder={t('E.g., 2')}
              onChange={onChangeMin}
              style={{ width: '100px' }}
              hasError={!!errorText}
            />
            <Textfield
              defaultValue={max}
              id={`${idValue}_validation-relationList-count-max`}
              className={styles.validationContentField}
              labelName={t('maximum')}
              type="number"
              placeholder={t('E.g., 4')}
              onChange={onChangeMax}
              style={{ width: '100px' }}
              hasError={!!errorText}
            />
          </div>
          {errorText && <p className={styles.errorText}>{errorText}</p>}
        </>
      )}
    </Fieldset>
  );
};

export default RelationListCountValidationField;
