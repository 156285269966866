import { useCallback } from 'react';

import { useMyRolesContext } from '@/hooks/Role/useMyRoles';

import type { MigrateApi } from '@/entity/api';
import type { ContentPermission } from '@/entity/role';

import { isAdminRole } from '@/util/permissions/admin';
import { getExceptionContentPermissionByApiId } from '@/util/role';

function useHasCreateContentPermission() {
  const { roles } = useMyRolesContext();

  /**
   * 閲覧者がコンテンツのCREATE権限（下書き保存、公開のうち、いずれか1つでも）を持っている場合はtrue
   *
   * @param apiId 個別権限まで考慮する場合は、引数apiIdに対象のAPIのIDを指定してください
   */
  const hasCreateContentPermissionForAny = useCallback(
    (apiId?: MigrateApi['partitionKey']) => {
      if (!roles) {
        return false;
      }
      return roles.some((r) => {
        if (isAdminRole(r)) {
          return true;
        }

        const ecp = getExceptionContentPermissionByApiId(r, apiId);
        // NOTE: 個別権限があれば、そちらの権限設定が優先される
        const permission = ecp ? ecp.permission : r.defaultContentPermission;
        return (
          permission.contentCreate.draft || permission.contentCreate.publish
        );
      });
    },
    [roles],
  );

  /**
   * 閲覧者がコンテンツのCREATE権限で指定した項目の権限を持っている場合はtrue
   *
   * @param permissionEntry 権限をチェックする項目
   * @param apiId 個別権限まで考慮する場合は、引数apiIdに対象のAPIのIDを指定してください
   */
  const hasCreateContentPermission = useCallback(
    (
      permissionEntry: keyof ContentPermission['contentCreate'],
      apiId?: MigrateApi['partitionKey'],
    ) => {
      if (!roles) {
        return false;
      }
      return roles.some((r) => {
        if (isAdminRole(r)) {
          return true;
        }

        const ecp = getExceptionContentPermissionByApiId(r, apiId);
        // NOTE: 個別権限があれば、そちらの権限設定が優先される
        const permission = ecp ? ecp.permission : r.defaultContentPermission;
        return permission.contentCreate[permissionEntry];
      });
    },
    [roles],
  );

  return {
    hasCreateContentPermissionForAny,
    hasCreateContentPermission,
  };
}

export { useHasCreateContentPermission };
