import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { updateDraftKey } from '@/usecase/contentUsecase';

import { contentQueryKeys } from '@/views/Common/content/queryKeys';

export const useUpdateDraftKey = (contentId: string) => {
  const { addToast } = useToasts();
  const cache = useQueryClient();
  const { t } = useTranslation('updateDraftKey');

  const { mutate: mutateDraftKey, isLoading: updateDraftKeyIsLoading } =
    useMutation({
      mutationFn: async ({ contentId }: { contentId: string }) =>
        await updateDraftKey(contentId),
      onSuccess(contentId) {
        cache.invalidateQueries(contentQueryKeys.detail(contentId), {
          type: 'all',
        });
        addToast(t('Updated draftKey'), { appearance: 'success' });
      },
      onError(error: Error) {
        addToast(error.message ? error.message : t('Failed to make changes.'), {
          appearance: 'error',
        });
      },
    });

  const doUpdateDraftKey = useCallback(() => {
    if (
      window.confirm(
        t(
          'Do you want to update the draftKey needed to retrieve draft information? The draftKey before updating will no longer be available.',
        ),
      )
    ) {
      mutateDraftKey({ contentId });
    }
  }, [t, mutateDraftKey, contentId]);

  return { doUpdateDraftKey, updateDraftKeyIsLoading };
};
