import type React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import useCurrentIp from '@/hooks/useCurrentIp';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';

import Head from '../../Head';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import Textarea from '../../ui/Textarea';
import useUpdateAllowedIpList from './useUpdateAllowedIpList';

import styles from './ipRestrictionsSettings.module.css';

import { useInput } from '@/hooks';
import { Upsell } from '@/views/Common/plan/Upsell';

const IpRestrictionsSettings: React.FC = () => {
  const { t } = useTranslation('serviceIpRestrictionsSettings');
  const { service } = useGetMyService();
  const [currentIp] = useCurrentIp();
  const [updateIpList, isLoading] = useUpdateAllowedIpList(
    service ? service.partitionKey : undefined,
  );

  //許可設定するIP
  const [ipList, onChangeIpList] = useInput(
    service ? service.serviceAllowedIpList : '',
    () => true,
  );

  const { currentPlan } = useStripeActions(service);
  // 現状currentPlanがundefinedになることはなさそうなのですが、取得タイミングによっては可能性ありそうなのでその分岐もしています。
  const ipRestrictionCount = currentPlan?.limit.ipRestrictionCount ?? 30;

  const submit = useCallback(() => {
    updateIpList(ipList);
  }, [updateIpList, ipList]);

  if (!service) {
    return null;
  }

  return (
    <Upsell restrictionKey="ipRestriction">
      <Head title={t('Administration console IP Restriction')} />
      <h2 className={styles.title}>
        {t('Administration console IP Restriction')}
      </h2>
      <Fieldset
        legend={t('Allowed IP Addresses')}
        description={t(
          'Enter IP addresses allowed to use the Administration console, one per line in IPv4 / IPv6 CIDR notation. Up to {{validateCount}} lines can be set. If this is blank, any IP addresses will be allowed to use the Administration console.',
          { validateCount: ipRestrictionCount },
        )}
      >
        <Textarea
          placeholder={t('E.g., \n198.51.100.60\n198.51.100.150')}
          onChange={onChangeIpList}
          defaultValue={service.serviceAllowedIpList || undefined}
        />
        <p className={styles.caution}>
          {t(
            'Accessing from IP addresses other than the above is restricted. Do not set a dynamically assigned IP address.',
          )}
        </p>
      </Fieldset>
      <p>
        <span className={styles.ipAddressMenu}>
          {t('Current source IP address :')}
        </span>
        {currentIp}
      </p>
      <div className={styles.actions}>
        <Button
          type="primary"
          disabled={isLoading}
          value={t('Save changes')}
          onClick={submit}
        />
      </div>
    </Upsell>
  );
};

export default IpRestrictionsSettings;
