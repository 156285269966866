export default class UploadImage {
  /**
   * @param {Quill} quill
   * @param {Object} options
   */
  constructor(quill, options = {}) {
    this.quill = quill;
    this.options = options;

    this.quill
      .getModule('toolbar')
      .addHandler('image', this.selectImage.bind(this));

    this.dropFunc = this.dropFunc.bind(this);
    this.pasteFunc = this.pasteFunc.bind(this);

    this.quill?.root?.addEventListener('drop', this.dropFunc);
    this.quill?.root?.addEventListener('paste', this.pasteFunc);
  }

  dropFunc(e) {
    // アップロードされるファイルがない場合は早期リターンする
    // 理由: リッチエディタ内の画像移動のDnDもこのイベントで処理されるので、e.preventDefault()を行わないようにするため
    if (e.dataTransfer?.files?.length === 0) return;

    e.preventDefault();
    // caretRangeFromPointは非標準(Firefox/IE非対応)
    if (document.caretRangeFromPoint) {
      const selection = document.getSelection();
      const range = document.caretRangeFromPoint(e.clientX, e.clientY);
      if (selection && range) {
        selection.setBaseAndExtent(
          range.startContainer,
          range.startOffset,
          range.startContainer,
          range.startOffset,
        );
      }
    } else {
      const selection = document.getSelection();
      // 以下のメソッドはChromeでは非対応
      const range = document.caretPositionFromPoint(e.clientX, e.clientY);
      if (selection && range) {
        selection.setBaseAndExtent(
          range.offsetNode,
          range.offset,
          range.offsetNode,
          range.offset,
        );
      }
    }

    const files = e?.dataTransfer?.files;
    this.options?.inputFiles(files);
  }

  pasteFunc(e) {
    const clipboard = e?.clipboardData || window.clipboardData;

    if (clipboard && (clipboard.items || clipboard.files)) {
      const files = clipboard.items || clipboard.files;
      // file形式がペーストされた時のみ実行
      for (const file of files) {
        if (
          file.type.match(/^text\/html$/i) ||
          file.type.match(/^text\/plain$/i)
        ) {
          return;
        }
        e.preventDefault();
        // ファイルアップロードを一つずつ操作するので配列に挿入する
        const inputFile = [file.getAsFile()];
        this.options?.inputFiles(inputFile);
      }
    }
  }

  /**
   * ギャラリーから画像選択する
   */
  selectImage() {
    this.options?.openMedia();
  }

  /**
   * @param {String} medium imgタグにオブジェクト
   */
  insert(medium, selection = {}) {
    const index =
      selection?.index === 0 ? 0 : selection?.index || this.quill?.getLength();
    this.quill?.insertEmbed(
      index,
      'image',
      JSON.stringify({
        serviceId: medium?.serviceId,
        mediumId: medium?.mediumId,
      }) /* TODO: 保存内容を mediumId だけに変える！！*/,
      'user',
    );
  }
}
