import type React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './iframeContent.module.css';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
};

const IframeContent: React.FC<Props> = ({
  value: { imageUrl, id, title, description, updatedAt },
}) => {
  const { t } = useTranslation('iFrameContent');
  if (
    imageUrl === undefined &&
    id === undefined &&
    title === undefined &&
    description === undefined &&
    updatedAt === undefined
  ) {
    return t('Data exists');
  }

  return (
    <div className={styles.wrapper}>
      {imageUrl && <img src={imageUrl} alt="" className={styles.image} />}
      <div>
        {id && <p className={styles.id}>{id}</p>}
        {title && <p className={styles.title}>{title}</p>}
        {description && <p className={styles.description}>{description}</p>}
        {updatedAt && <p className={styles.updatedAt}>{updatedAt}</p>}
      </div>
    </div>
  );
};

export default IframeContent;
