import { useState } from 'react';

import useCurrentIp from '@/hooks/useCurrentIp';

import type { Props } from './IpAddressRestrictionSettings';

import { useUpdateApiIpAddressRestriction } from '@/views/Common/apiIpAddressRestriction/useApiIpAddressRestrictionWriter';
import { useCurrentPlan } from '@/views/Common/service/useServiceReader';

export const useIpAddressRestrictionSettings = ({
  service,
  api,
  globalIpList,
}: Props) => {
  const { data: currentPlan } = useCurrentPlan(service.partitionKey);
  const [currentIp] = useCurrentIp();

  const [ipList, setIpList] = useState(api.apiAllowedIpList ?? '');
  const handleChangeIpList: React.ChangeEventHandler<HTMLTextAreaElement> = (
    e,
  ) => {
    setIpList(e.target.value);
  };

  const [enabledSetIndividually, setEnabledSetIndividually] = useState(
    api.apiAllowedIpList !== null,
  );
  const handleSetIndividuallyChange = (on: boolean) => {
    setEnabledSetIndividually(on);
  };

  const {
    mutate: updateApiIpAddressRestriction,
    isLoading: isUpdateApiIpAddressRestrictionLoading,
  } = useUpdateApiIpAddressRestriction(api.partitionKey);
  const handleSaveButtonClick = () => {
    updateApiIpAddressRestriction({ ipList, enabled: enabledSetIndividually });
  };

  return {
    apiName: api.apiName,
    globalIpList,
    ipRestrictionCount: currentPlan?.limit.apiIpRestrictionCount ?? 0,
    currentIp,
    ipList,
    handleChangeIpList,
    enabledSetIndividually,
    handleSetIndividuallyChange,
    handleSaveButtonClick,
    disabledSaveButton: isUpdateApiIpAddressRestrictionLoading,
  };
};
