import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation, Auth, I18n } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';

import {
  verifyMemberInvitation as verifyMemberInvitationUsecase,
  acceptMemberInvitation as acceptMemberInvitationUsecase,
} from '@/usecase/authUsecase';

import { deleteAmplifyAuthConfig } from '../../constants/localStorage';
import * as queries from '../../graphql/queries';
import { useCreateAccountLanguage } from '../../hooks/Account/useAccount';
import { changeLanguage } from '../../i18n';
import {
  getDefaultHost,
  isSAMLUser,
  getBrowserLanguage,
  getPureHost,
  isValidUrl,
} from '../../util';

import type { CognitoUser } from 'amazon-cognito-identity-js';

interface SigninParam {
  email: string;
  password: string;
  code: string;
  accountRegisterRedirectPath?: string;
}

export const useSignup = () => {
  const { addToast } = useToasts();

  const {
    mutate: signup,
    isLoading: signupLoading,
    isSuccess,
  } = useMutation<any, Error | any, SigninParam>({
    mutationFn: ({ email, password }) =>
      Auth.signUp({ username: email, password }),
    onError(error) {
      error &&
        addToast(I18n.get(error.code, error.message), {
          appearance: 'error',
        });
    },
  });
  return { signup, signupLoading, signupSuccess: isSuccess };
};

export const useSignin = () => {
  const { t } = useTranslation('hooksAuth');
  const history = useHistory();
  const { addToast } = useToasts();
  const { createAccountLanguage } = useCreateAccountLanguage();

  const [user, setUser] = useState(null);
  const [redirectToMfa, setRedirectToMfa] = useState(false);
  const [redirectToConfirmSignup, setRedirectToConfirmSignup] = useState(false);

  const [cookies, _, removeCookie] = useCookies([
    'ServiceTemplatesCallbackUrl',
  ]);

  const {
    mutate: signin,
    isLoading: signinLoading,
    isSuccess: signinSuccess,
  } = useMutation<CognitoUser | any, Error | any, SigninParam>({
    mutationFn: ({ email, password }) => Auth.signIn(email, password),
    onSuccess(user, { accountRegisterRedirectPath }) {
      setUser(user);

      if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        setRedirectToMfa(true);
        return;
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        const requiredAttributes =
          (user.challengeParam && user.challengeParam.requiredAttributes) || [];
        const newPassword = prompt(t('Please reset password.')) ?? '';
        Auth.completeNewPassword(user, newPassword, requiredAttributes);
      }

      // アカウント登録時と通常ログイン時でリダイレクト先を変更
      if (accountRegisterRedirectPath) {
        history.push(accountRegisterRedirectPath);

        // 新規登録時のログインでのみアカウント言語を保存する
        createAccountLanguage({
          userLanguage: getBrowserLanguage(),
        });
      } else if (
        // サービステンプレートからのCallbackURLが指定されている場合、サービステンプレートのURLにリダイレクト
        cookies.ServiceTemplatesCallbackUrl
      ) {
        const callbackUrl = cookies.ServiceTemplatesCallbackUrl;
        removeCookie('ServiceTemplatesCallbackUrl', {
          path: '/',
          domain: getPureHost(),
          secure: window.location.protocol === 'https',
        });
        if (isValidUrl(callbackUrl)) {
          window.open(callbackUrl, '_self');
        } else {
          window.location.href = `${getDefaultHost()}`;
        }
      } else {
        window.location.href = `${getDefaultHost()}`;
      }
    },
    onError(error, { email }) {
      if (error.code === 'UserNotConfirmedException') {
        Auth.resendSignUp(email);
        setRedirectToConfirmSignup(true);
      } else {
        addToast(t('Login failed.'), {
          appearance: 'error',
        });
      }
    },
  });
  return {
    signin,
    signinLoading,
    signinSuccess,
    user,
    redirectToMfa,
    redirectToConfirmSignup,
  };
};

interface confirmMfa {
  tempUser: any;
  code: any;
}
export const useConfirmMfa = () => {
  const { t } = useTranslation('hooksAuth');
  const { addToast } = useToasts();
  const { mutate: confirmMfa, isLoading: confirmMfaLoading } = useMutation<
    string,
    Error,
    confirmMfa
  >({
    mutationFn: ({ tempUser, code }) =>
      Auth.confirmSignIn(tempUser, code, 'SOFTWARE_TOKEN_MFA'),
    onSuccess() {
      addToast(t('Login succeeded.'), {
        appearance: 'success',
      });
      window.location.href = `${getDefaultHost()}`;
    },
    onError(error) {
      addToast(
        error
          ? I18n.get(error.name, error.message)
          : t('Failed to make changes.'),
        {
          appearance: 'error',
        },
      );
    },
  });
  return { confirmMfa, confirmMfaLoading };
};

export const useSignOut = () => {
  const { t } = useTranslation('hooksAuth');
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const { mutate: signOut, isLoading: signOutLoading } = useMutation<
    void,
    Error
  >({
    mutationFn: () => Auth.signOut(),
    onSuccess() {
      cache.clear();
      if (isSAMLUser()) {
        deleteAmplifyAuthConfig();
      }
      addToast(t('Logout'), {
        appearance: 'success',
      });
      changeLanguage(getBrowserLanguage());
    },
    onError(error) {
      addToast(
        error ? I18n.get(error.name, error.message) : t('Logout failed.'),
        {
          appearance: 'error',
        },
      );
    },
  });
  return { signOut, signOutLoading };
};

interface VerifyToken {
  token: string;
}

export const useVerifyMemberInvitation = () => {
  const { t } = useTranslation('hooksAuth');

  const { addToast } = useToasts();
  const {
    data: verifyMemberInvitationData,
    mutate: verifyMemberInvitation,
    isLoading: verifyMemberInvitationLoading,
    isSuccess: verifyMemberInvitationSuccess,
    isError: verifyMemberInvitationError,
  } = useMutation<any, Error, VerifyToken>({
    mutationFn: async ({ token }) => {
      return await verifyMemberInvitationUsecase({ token });
    },
    onError() {
      // emailの改竄ケースなど限られたケースなので、具体的なエラーメッセージは出さない。
      addToast(t('Authentication failed.'), {
        appearance: 'error',
      });
    },
  });
  return {
    verifyMemberInvitationData,
    verifyMemberInvitation,
    verifyMemberInvitationLoading,
    verifyMemberInvitationSuccess,
    verifyMemberInvitationError,
  };
};

export const useIsInvitedFromServices = (isAuthorized: boolean) => {
  const isInvitedQuery = useQuery({
    queryKey: ['isInvited', isAuthorized],
    queryFn: async () => {
      const result: any = await API.graphql(
        graphqlOperation(queries.isInvitedFromServices),
      );
      return result.data.isInvitedFromServices.result;
    },
    cacheTime: 0,
  });
  const { data: isInvited, isLoading: isInvitedLoading } = isInvitedQuery;
  return { isInvited, isInvitedLoading };
};

export const useAcceptMemberInvitation = () => {
  const { t } = useTranslation('hooksAuth');
  const { addToast } = useToasts();

  const {
    data: hasAcceptMember,
    mutate: acceptMemberInvitation,
    isLoading: acceptMemberLoading,
    isSuccess: acceptMemberInvitationIsSuccess,
  } = useMutation<{ result: boolean }, Error>({
    mutationFn: async () => {
      return await acceptMemberInvitationUsecase();
    },
    onSuccess(data) {
      if (data.result === true) {
        addToast(t('Invited to service.'), {
          appearance: 'success',
        });
      }
    },
    onError(error) {
      addToast(
        error
          ? I18n.get(error.name, error.message)
          : t('Service invitation failed.'),
        {
          appearance: 'error',
        },
      );
    },
  });

  return {
    hasAcceptMember,
    acceptMemberInvitation,
    acceptMemberLoading,
    acceptMemberInvitationIsSuccess,
  };
};

export const useMemberEmailVerified = (
  emailVerified: 'true' | 'false' | undefined,
) => {
  const [previous, setPrevious] = useState(emailVerified);
  const [onDoneVerified, setOnDoneVerified] = useState(false);
  useEffect(() => {
    setPrevious(emailVerified);
    setOnDoneVerified(emailVerified === 'true' && previous === 'false');
  }, [setPrevious, setOnDoneVerified, emailVerified, previous]);

  return { onDoneVerified };
};

export const useForgotPassword = () => {
  const { t } = useTranslation('hooksAuth');
  const { addToast } = useToasts();
  // mutaionのisSuccessを用いるとエラーの時にfalseとなるため、stateを用いる
  const [forgotPasswordSuccess, setForgetPasswordSuccess] = useState(false);

  const { mutate: forgotPassword, isLoading: forgotPasswordLoading } =
    useMutation<any, Error | any, SigninParam>({
      mutationFn: ({ email }) => Auth.forgotPassword(email),
      // エラーを表示すると第三者からそのメールアドレスがmicroCMSに登録されているか否かを知られてしまうため、
      // フィードバックとしては常に成功として表示し、第三者には登録の有無がわからないようにする
      //
      // Cognito側はユーザー存在エラーの防止設定を有効にし UserNotFoundException が返ってこないようにする
      onSettled() {
        addToast(t('Authorization code has been sent.'), {
          appearance: 'success',
        });
        setForgetPasswordSuccess(true);
      },
    });

  return {
    forgotPassword,
    forgotPasswordLoading,
    forgotPasswordSuccess,
  };
};

export const useForgotPasswordSubmit = () => {
  const { t } = useTranslation('hooksAuth');
  const { addToast } = useToasts();
  const history = useHistory();

  const {
    mutate: forgotPasswordSubmit,
    isLoading: forgotPasswordSubmitLoading,
    isSuccess: forgotPasswordSubmitSuccess,
  } = useMutation<string, Error & { code: number }, SigninParam, unknown>({
    mutationFn: ({ email, code, password }) =>
      Auth.forgotPasswordSubmit(email, code, password),
    onSuccess() {
      addToast(t('Password has been reset.'), {
        appearance: 'success',
      });
      history.push('/signin');
    },
    onError(error) {
      error &&
        addToast(I18n.get(error.code, error.message), {
          appearance: 'error',
        });
    },
  });

  return {
    forgotPasswordSubmit,
    forgotPasswordSubmitLoading,
    forgotPasswordSubmitSuccess,
  };
};
