import { nanoid } from 'nanoid';
import { useRef, useEffect, useState } from 'react';

import { buildIframePostMessage } from '../../../util';
import { validateIframe } from '../../Validations';

import styles from './iframefield.module.css';

const Iframefield = ({ title, src, value, onChange, email }) => {
  const ref = useRef(null);
  const [style, setStyle] = useState();
  const [error, setError] = useState(null);
  const [id] = useState(nanoid(10));

  useEffect(() => {
    window.addEventListener('message', (e) => {
      const url = new URL(src);
      if (
        e.origin === url.origin &&
        e.isTrusted === true &&
        e.data.id === id &&
        e.data.message
      ) {
        if (e.data.action === 'MICROCMS_POST_DATA') {
          const validateResult = validateIframe(e.data.message);
          if (validateResult === null) {
            onChange(e.data.message);
            // successレスポンス用
            ref.current.contentWindow.postMessage(
              {
                id,
                action: 'MICROCMS_POST_DATA_SUCCESS',
                message: e.data.message,
              },
              src,
            );
          } else {
            setError(validateResult);
            // failureレスポンス用
            ref.current.contentWindow.postMessage(
              {
                id,
                action: 'MICROCMS_POST_DATA_FAILURE',
                error: validateResult,
              },
              src,
            );
          }
        } else if (e.data.action === 'MICROCMS_UPDATE_STYLE') {
          setStyle({
            height: e.data.message.height,
            width: e.data.message.width,
          });
        }
      }
    });
  }, [onChange, src, id]);

  useEffect(() => {
    let timer;
    const target = ref.current;
    if (target !== null) {
      target.onload = async (event) => {
        // iframeの読み込みが完了するまで監視

        timer = setInterval(async () => {
          const iframe = event.currentTarget;
          if (!iframe) {
            target.contentWindow.postMessage(
              buildIframePostMessage({
                id,
                email,
                message: value,
              }),
              src,
            );
            clearInterval(timer);
          }
        }, 100);
      };
    }
    return () => {
      clearInterval(timer);
    };
  }, [email, id, src, value]);

  if (!email) return <></>;

  return (
    <>
      {error !== null && <p className={styles.errorText}>{error}</p>}
      <iframe
        ref={ref}
        className={styles.iframe}
        title={title}
        src={src}
        style={style}
      />
    </>
  );
};

export default Iframefield;
