import { useTranslation } from 'react-i18next';

import type { ContentListRelationProps } from '@/components/ContentListRelation';
import ContentListRelation from '@/components/ContentListRelation';

import { NoPermission } from '../shared/NoPermission';

import { nonNullable } from '@/util/type-guard';

export type Props = {
  contents: (ContentListRelationProps['content'] | null)[];
  displayItem?: ContentListRelationProps['displayItem'];
};

export type ViewProps = {
  displayContents: ContentListRelationProps['content'][];
  countOfNoPermissionContents: number;
  displayItem?: ContentListRelationProps['displayItem'];
};

export const RelationListView: React.FC<ViewProps> = ({
  displayContents,
  countOfNoPermissionContents,
  displayItem,
}) => {
  const { t } = useTranslation('contentListRelationList');

  return (
    <div>
      {displayContents.length > 0
        ? displayContents.map((v, i) => (
            <ContentListRelation
              key={i}
              content={v}
              displayItem={displayItem}
            />
          ))
        : '-'}
      {countOfNoPermissionContents > 0 && (
        <span>
          {t('{{ count }} others', { count: countOfNoPermissionContents })}
        </span>
      )}
    </div>
  );
};

export const RelationList: React.FC<Props> = ({ contents, displayItem }) => {
  const displayContents = contents.filter(nonNullable);
  const countOfNoPermissionContents = contents.length - displayContents.length;

  if (displayContents.length === 0 && countOfNoPermissionContents > 0) {
    return <NoPermission />;
  }

  return (
    <RelationListView
      displayContents={displayContents}
      countOfNoPermissionContents={countOfNoPermissionContents}
      displayItem={displayItem}
    />
  );
};
