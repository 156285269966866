import { useMemo, useState, useTransition } from 'react';

import type { ComboboxItem, ComboboxProps } from './Combobox';

const useCombobox = <T extends ComboboxItem>({
  list,
  currentValueKeys,
  filterRule,
  equalRule,
  onConfirm,
  onEscapeKeyDown,
  ...props
}: ComboboxProps<T>) => {
  const [, startInputTransition] = useTransition();
  const [inputValue, setInputValue] = useState('');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const filteredList = useMemo(() => {
    if (!list) return null;
    if (inputValue === '') return list;

    const result = list.filter((item) => filterRule(item, inputValue));

    // 完全一致のルールがある場合、完全一致のものを優先して表示する
    if (equalRule) {
      const equalItem = result.find((item) => equalRule(item, inputValue));
      if (equalItem) {
        return [equalItem].concat(result.filter((item) => item !== equalItem));
      }
    }

    return result;
  }, [equalRule, filterRule, inputValue, list]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    startInputTransition(() => setInputValue(value));

    if (value) {
      setActiveIndex(0);
    }
  };

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !event.nativeEvent.isComposing &&
      event.key === 'Enter' &&
      activeIndex != null &&
      filteredList?.[activeIndex]
    ) {
      onConfirm(filteredList[activeIndex]);
    }

    if (event.key === 'Escape') {
      onEscapeKeyDown();
    }
  };

  return {
    currentValueKeys,
    onConfirm,
    filteredList,
    activeIndex,
    setActiveIndex,
    onInputChange,
    onInputKeyDown,
    ...props,
  };
};

export { useCombobox };
