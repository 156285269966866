import type { CurrentPlan } from '@/entity/plan';

type canCreateApiTemplateParam = {
  apiListLength: number;
  currentPlan: CurrentPlan;
  needApiCount: 1 | 2;
};

type canCreateApiTemplateReturnValue = {
  canCreateApiTemplate: boolean | 'willBeChargedWhenAddApi';
};

type CanCreateApiTemplate = ({
  apiListLength,
  currentPlan,
  needApiCount,
}: canCreateApiTemplateParam) => canCreateApiTemplateReturnValue;

export const checkApiTemplateCountLimit: CanCreateApiTemplate = ({
  apiListLength,
  currentPlan,
  needApiCount,
}) => {
  const currentApiCount = apiListLength || 0;
  const apiCountLimit = currentPlan.limit.apiCount;

  const willBeChargedWhenAddApi =
    currentPlan &&
    currentPlan.name !== 'Hobby' &&
    // EnterpriseはapiCountなどがnullになるのでそれを考慮する
    currentPlan.limit.apiCount !== null &&
    currentPlan.limit.apiCountOveragePrice !== null &&
    currentPlan.limit.apiCountOverageUnit !== null &&
    apiCountLimit < apiListLength + needApiCount;

  if (willBeChargedWhenAddApi) {
    return { canCreateApiTemplate: 'willBeChargedWhenAddApi' };
  }

  const canCreateApiTemplate =
    currentPlan.name !== 'Hobby' ||
    (currentPlan?.name === 'Hobby' &&
      currentApiCount + needApiCount <= apiCountLimit);

  return { canCreateApiTemplate };
};
