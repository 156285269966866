import cx from 'classnames';
import { forwardRef } from 'react';

import styles from './table.module.css';

const Table = forwardRef<HTMLTableElement, JSX.IntrinsicElements['table']>(
  function Table({ children, className, ...otherProps }, ref) {
    return (
      <table {...otherProps} ref={ref} className={cx(styles.table, className)}>
        {children}
      </table>
    );
  },
);

const Thead = forwardRef<
  HTMLTableSectionElement,
  JSX.IntrinsicElements['thead'] & { hasBottomSpace?: boolean }
>(function Thead(
  { children, className, hasBottomSpace = false, ...otherProps },
  ref,
) {
  return (
    <thead
      {...otherProps}
      ref={ref}
      className={cx(
        styles.thead,
        {
          [styles.hasBottomSpace]: hasBottomSpace,
        },
        className,
      )}
    >
      {children}
    </thead>
  );
});

const Tbody = forwardRef<
  HTMLTableSectionElement,
  JSX.IntrinsicElements['tbody']
>(function Tbody({ children, className, ...otherProps }, ref) {
  return (
    <tbody {...otherProps} ref={ref} className={cx(styles.tbody, className)}>
      {children}
    </tbody>
  );
});

const Tr = forwardRef<
  HTMLTableRowElement,
  JSX.IntrinsicElements['tr'] & {
    insertPrev?: boolean;
    insertNext?: boolean;
    enableHoverStyle?: boolean;
    isSelected?: boolean;
    isSlim?: boolean;
  }
>(function Tr(
  {
    children,
    className,
    insertPrev,
    insertNext,
    enableHoverStyle = true,
    isSelected = false,
    isSlim = false,
    onClick,
    ...otherProps
  },
  ref,
) {
  return (
    <tr
      {...otherProps}
      ref={ref}
      className={cx(
        styles.tr,
        {
          [styles.insertPrev]: insertPrev,
          [styles.insertNext]: insertNext,
          [styles.enableHoverStyle]: enableHoverStyle,
          [styles.isSelected]: isSelected,
          [styles.isSlim]: isSlim,
        },
        className,
      )}
      onClick={onClick}
    >
      {children}
    </tr>
  );
});

const Th = forwardRef<
  HTMLTableCellElement,
  JSX.IntrinsicElements['th'] & { isLong?: boolean }
>(function Th({ children, className, isLong, ...otherProps }, ref) {
  return (
    <th
      {...otherProps}
      ref={ref}
      className={cx(
        styles.th,
        {
          [styles.isLong]: isLong,
        },
        className,
      )}
    >
      {children}
    </th>
  );
});

const Td = forwardRef<
  HTMLTableCellElement,
  JSX.IntrinsicElements['td'] & {
    isRight?: boolean;
    verticalAlign?: 'top' | 'bottom'; // middleはデフォルトスタイルになっているので省略(CSS参照)
  }
>(function Td(
  { children, className, isRight, verticalAlign, ...otherProps },
  ref,
) {
  return (
    <td
      {...otherProps}
      ref={ref}
      className={cx(styles.td, className, {
        [styles.isRight]: isRight,
        [styles.verticalAlignTop]: verticalAlign === 'top',
        [styles.verticalAlignBottom]: verticalAlign === 'bottom',
      })}
    >
      {children}
    </td>
  );
});

export { Table, Thead, Tbody, Tr, Th, Td };
