import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './contentsCountSettings.module.css';

type Props = {
  limitContentsCount: number;
};

export const ContentsCountMessage: React.FC<Props> = ({
  limitContentsCount,
}) => {
  const { t } = useTranslation('serviceContentsCountSettings');
  return (
    <div className={styles.planInfo}>
      <p>
        {Trans({
          t,
          i18nKey:
            'The current plan allows you to store up to {{count}} contents. For more information, check the current plan.',
          values: { count: limitContentsCount.toLocaleString() },
          children: (
            <Link to="/settings/billing" className={styles.link}>
              click here
            </Link>
          ),
        })}
      </p>
      <p>
        {t(
          'Please note that once the limit is exceeded, you will no longer be able to create a new content.',
        )}
      </p>
    </div>
  );
};
