import { Underline } from '@tiptap/extension-underline';

import type { UnderlineOptions } from '@tiptap/extension-underline';

type CustomUnderlineOptions = UnderlineOptions & {
  inputRules: boolean;
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-underline/src/underline.ts
export const CustomUnderline = Underline.extend<CustomUnderlineOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: true,
    };
  },

  parseHTML() {
    return this.options.inputRules ? this.parent?.() : [];
  },

  addKeyboardShortcuts() {
    return this.options.inputRules ? { ...this.parent?.() } : {};
  },
});
