import { useCallback, useId } from 'react';

import { useToggle } from '../useToggle';

import type { Props, ViewProps } from './DatePicker';

export const useDatePicker = ({ reactDatePickerProps }: Props) => {
  const inputId = useId();
  const [isOpen, toggle, , close] = useToggle(false);

  const handleOpenChange: ViewProps['handleOpenChange'] = useCallback(
    (open) => {
      if (reactDatePickerProps.disabled || reactDatePickerProps.readOnly) {
        return;
      }
      toggle(open);
    },
    [reactDatePickerProps.disabled, reactDatePickerProps.readOnly, toggle],
  );

  const handleTriggerClick: ViewProps['handleTriggerClick'] = useCallback(
    (e) => {
      //　インプットエリアをクリックしたときはカレンダーを閉じない
      if (e.target === document.getElementById(inputId) && isOpen) {
        e.preventDefault();
      }
    },
    [inputId, isOpen],
  );

  const handleInteractOutside: ViewProps['handleInteractOutside'] = useCallback(
    (e) => {
      // インプットエリアをクリックしたときはカレンダーを閉じない
      if (e.target === document.getElementById(inputId)) {
        e.preventDefault();
      }
    },
    [inputId],
  );

  return {
    inputId,
    isOpen,
    handleOpenChange,
    handleTriggerClick,
    handleEscapeKeyDown: close,
    handleInteractOutside,
  };
};
