import { useCallback, useMemo } from 'react';

import type { Props, RowProps, ViewProps } from './SearchMember';

import { nonNullable } from '@/util/type-guard';

export const useSearchMember = ({
  selectedMemberId,
  onClickRow,
  ...props
}: Props): ViewProps => {
  const currentValueKeys = useMemo(() => {
    if (props.members === null) {
      return [];
    }

    return selectedMemberId ? [selectedMemberId] : [];
  }, [props.members, selectedMemberId]);

  const filterRule = useCallback((item: RowProps, inputValue: string) => {
    const matchList = [item.name, item.email].filter(nonNullable);

    for (const match of matchList) {
      if (match.toLowerCase().includes(inputValue.toLowerCase())) {
        return true;
      }
    }

    return false;
  }, []);

  const onConfirm = useCallback(
    (item: RowProps) => {
      onClickRow(item.username);
    },
    [onClickRow],
  );

  return {
    currentValueKeys,
    filterRule,
    onConfirm,
    ...props,
  };
};
