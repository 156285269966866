/**
 * クレジットカードのブランド名
 *
 * microCMSで扱うブランドのみを定義している
 *
 * @see {@link https://stripe.com/docs/api/cards/object#card_object-brand}
 */
const StripeCardBrand = {
  Visa: 'Visa',
  MasterCard: 'MasterCard',
  Jcb: 'JCB',
  AmericanExpress: 'American Express',
  DinersClub: 'Diners Club',
  Discover: 'Discover',
  // EftposAustralia: 'Eftpos Australia',
  // UnionPay: 'UnionPay',
  Unknown: 'Unknown',
} as const;

export { StripeCardBrand };
