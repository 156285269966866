import { useTranslation } from 'react-i18next';

import { useRedo } from './useRedo';
import { IconButton } from '../../../IconButton';
import styles from '../../../richeditorv2.module.css';

import type { Editor } from '@tiptap/react';

import { RedoIcon } from '@/views/Common/Icons';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  onRedoHandler: () => void;
  editable: boolean;
};

export const RedoView: React.FC<ViewProps> = ({ onRedoHandler, editable }) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <IconButton
      icon={<RedoIcon className={styles.icon} />}
      aria-label={t('Redo')}
      onClick={onRedoHandler}
      isActive={false}
      disabled={!editable}
    />
  );
};

export const Redo: React.FC<Props> = ({ editor }) => {
  const { onRedoHandler } = useRedo({ editor });

  return (
    <RedoView onRedoHandler={onRedoHandler} editable={editor.isEditable} />
  );
};
