import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './nopermission.module.css';

import { Icon } from '@/views/Common/Ui/Icon';

export type Props = ViewProps;

export type ViewProps = React.ComponentPropsWithoutRef<'div'>;

export const NoPermissionView: React.FC<ViewProps> = (props) => {
  const { t } = useTranslation('contentListNoPermission');

  return (
    <div {...props} className={cx(styles.main, props.className)}>
      <Icon name="visibility_off" size="large" className={styles.icon} />
      <p className={styles.text}>
        {t('Cannot display due to lack of permission.')}
      </p>
    </div>
  );
};

export const NoPermission: React.FC<Props> = (props) => {
  return <NoPermissionView {...props} />;
};
