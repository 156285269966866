import type { InputExceptionContentPermission } from '@/entity/role';

/**
 * 指定したIDの個別権限のインデックスを取得する
 *
 * @param ecps 個別権限のリスト
 * @param id 検索するID
 * @returns 指定したIDの個別権限のインデックス (見つからない場合は-1)
 */
function findIndexECPs<T extends Pick<InputExceptionContentPermission, 'id'>>(
  ecps: Array<T>,
  id: InputExceptionContentPermission['id'],
): number {
  return ecps.findIndex((ecp) => ecp.id === id);
}

export { findIndexECPs };
