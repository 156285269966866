import { useQuery } from '@tanstack/react-query';

import { fetchServiceUsersAssignableReviewRequestUsecase } from '@/usecase/memberUsecase';

const useFetchServiceUsersAssignableReviewRequest = (
  serviceId: string,
  reviewRequestId: number,
) => {
  const {
    data: fetchedUsers,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ['serviceUsers', { serviceId, reviewRequestId }],
    queryFn: async () => {
      return fetchServiceUsersAssignableReviewRequestUsecase({
        serviceId,
        reqId: reviewRequestId,
      });
    },
    staleTime: Number.POSITIVE_INFINITY,
  });

  return {
    fetchedUsers,
    isLoading: isLoading || isFetching,
  };
};

export { useFetchServiceUsersAssignableReviewRequest };
