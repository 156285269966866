import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import {
  createApiKey as createApiKeyUsecase,
  updateApiKey as updateApiKeyUsecase,
} from '@/usecase/apiKeyUsecase';

import * as mutations from '../../graphql/mutations';

import type {
  ApiKeyPolicy,
  ApiKeyPriorityPolicy,
  ManagementPolicy,
} from '../../types/apiKey';

type CreateApi = {
  label?: string;
  defaultPolicy: ApiKeyPolicy;
  priorityPolicies?: ApiKeyPriorityPolicy[];
  managementPolicy?: ManagementPolicy | null;
  close: () => void;
};

type UpdateApi = {
  apiKeyId: string;
  label: string | undefined;
  defaultPolicy: ApiKeyPolicy;
  priorityPolicies?: ApiKeyPriorityPolicy[];
  managementPolicy?: ManagementPolicy | null;
};

type DeleteApi = {
  apiKeyId: string;
};

export const useCreateApiKey = (serviceId: string) => {
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation('hooksApiKey');

  const {
    mutate: createApiKey,
    isLoading: createApiKeyLoading,
    isSuccess: createApiKeyResult,
  } = useMutation<any, Error, CreateApi>({
    mutationFn: async ({
      label,
      defaultPolicy,
      priorityPolicies,
      managementPolicy,
    }) => {
      return await createApiKeyUsecase({
        serviceId,
        label,
        defaultPolicy,
        priorityPolicies,
        managementPolicy,
      });
    },
    onSuccess(_, { close }) {
      cache.invalidateQueries(['apiKey'], { type: 'all' });
      addToast(t('API Key has been added.'), { appearance: 'success' });

      close();
    },
    onError(result: any) {
      const errorMessage = result.message;
      addToast(errorMessage ? errorMessage : t('API key could not be added.'), {
        appearance: 'error',
      });
    },
  });

  return {
    createApiKey,
    createApiKeyLoading,
    createApiKeyResult,
  };
};

export const useUpdateApiKey = () => {
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useTranslation('hooksApiKey');

  const { mutate: updateApiKey, isLoading: updateApiKeyLoading } = useMutation<
    any,
    Error,
    UpdateApi
  >({
    mutationFn: async ({
      apiKeyId,
      label,
      defaultPolicy,
      priorityPolicies,
      managementPolicy,
    }) => {
      return await updateApiKeyUsecase({
        apiKeyId,
        label: label ? label : undefined,
        defaultPolicy,
        priorityPolicies: priorityPolicies ? priorityPolicies : [],
        managementPolicy: managementPolicy ? managementPolicy : null,
      });
    },
    onSuccess() {
      cache.invalidateQueries(['apiKey'], { type: 'all' });
      addToast(t('API key has been changed.'), { appearance: 'success' });
    },
    onError(result: any) {
      const errorMessage = result.message;
      addToast(
        errorMessage ? errorMessage : t('API key could not be changed.'),
        {
          appearance: 'error',
        },
      );
    },
  });
  return {
    updateApiKey,
    updateApiKeyLoading,
  };
};

export const useDeleteApiKey = (serviceId: string) => {
  const { t } = useTranslation('hooksApiKey');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  const {
    mutate: deleteApiKey,
    isLoading: deleteApiKeyLoading,
    status: deleteApiKeyResult,
  } = useMutation<any, Error, DeleteApi>({
    mutationFn: async ({ apiKeyId }) => {
      const response: any = await API.graphql(
        graphqlOperation(mutations.deleteApiKey, {
          serviceId,
          apiKeyId,
        }),
      );
      const result = response.data.deleteApiKey;
      return result;
    },
    onSuccess(result) {
      if (result && result.result === true) {
        cache.invalidateQueries(['apiKey'], { type: 'all' });
        addToast(t('API key has been deleted.'), { appearance: 'success' });
      } else {
        const errorMessage = result.message;
        addToast(
          errorMessage ? errorMessage : t('API key could not be deleted.'),
          {
            appearance: 'error',
          },
        );
      }
    },
    onError(result: any) {
      const errorMessage = result.message;
      addToast(
        errorMessage ? errorMessage : t('API key could not be deleted.'),
        {
          appearance: 'error',
        },
      );
    },
  });
  return {
    deleteApiKey,
    deleteApiKeyLoading,
    deleteApiKeyResult,
  };
};
