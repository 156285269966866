import type { PlanLimit } from '@/API';
import { getTranslation } from '@/i18n';

const getParam = (
  restrictionKey: keyof Omit<PlanLimit, '__typename'>,
  idpMetadataURL?: string | null,
): {
  helpUrl: string;
  helpUrlTitle: string;
  children: NonNullable<React.ReactNode>;
} => {
  const { t } = getTranslation('commonPlanUpsell');

  if (idpMetadataURL && restrictionKey === 'enableSAML') {
    return {
      helpUrl: 'https://document.microcms.io/manual/saml-auth0',
      helpUrlTitle: t('Single Sign On'),
      children: (
        <p>
          {t(
            "This function allows authentication through SSO (single sign-on) in conjunction with an ID provider (IdP).\nYou can build an authentication flow tailored to your team's environment.",
          )}
        </p>
      ),
    };
  }

  switch (restrictionKey) {
    case 'auditLog': {
      return {
        helpUrl: 'https://document.microcms.io/manual/audit-log',
        helpUrlTitle: t('Audit Log'),
        children: (
          <p>
            {t(
              'Audit log is used for checking users activities on microCMS.\nOperation details such as date, time, IP address and target API name are recorded.',
            )}
          </p>
        ),
      };
    }

    case 'handleEnvironments': {
      return {
        helpUrl: 'https://document.microcms.io/manual/environments',
        helpUrlTitle: t('Multiple Environments'),
        children: (
          <p>
            {t(
              'Separated environments for development can be created with higher plans.\nAPI schemas or functionalities can be changed safely without affecting the production environment.',
            )}
          </p>
        ),
      };
    }

    case 'requireMfa': {
      return {
        helpUrl: 'https://document.microcms.io/manual/mfa-need-setting',
        helpUrlTitle: t('Mandatory two-factor authentication'),
        children: (
          <p>
            {t(
              'Two-Factor authentication can be made mandatory for each member.\nThis provides better security when using microCMS.',
            )}
          </p>
        ),
      };
    }

    case 'ipRestriction': {
      return {
        helpUrl: 'https://document.microcms.io/manual/ip-restriction',
        helpUrlTitle: t('Administration console IP Restriction'),
        children: (
          <p>
            {t(
              'Communication with microCMS can be restricted to only some IP addresses.\nOnce set, the Administration console will become available only from the authorized IP addresses.',
            )}
          </p>
        ),
      };
    }

    case 'apiIpRestriction': {
      return {
        helpUrl: 'https://document.microcms.io/manual/api-ip-restriction',
        helpUrlTitle: t('API IP Restriction'),
        children: (
          <p>
            {t(
              'API IP Restriction is a feature that enforces IP restrictions on API requests, such as GET and POST operations.\nThis feature can be used as a security measure.',
            )}
          </p>
        ),
      };
    }

    case 'customStatusCount': {
      return {
        helpUrl: 'https://document.microcms.io/manual/custom-status',
        helpUrlTitle: t('Custom Status'),
        children: (
          <p>
            {t(
              "This feature allows creating your own content status.\nCustom Status facilitates operations by creating team-specific status such as 'Campaign Completed' or 'Revisions Needed'.",
            )}
          </p>
        ),
      };
    }

    case 'mediumTags': {
      return {
        helpUrl: 'https://document.microcms.io/manual/media-tag-settings',
        helpUrlTitle: t('Media Tag Settings'),
        children: (
          <p>
            {t(
              'This function allows you to set and organize tags for media.\nYou can also add your favorite keywords to make it easier to search for media.',
            )}
          </p>
        ),
      };
    }

    case 'enableSAML': {
      return {
        helpUrl: 'https://document.microcms.io/manual/saml-auth0',
        helpUrlTitle: t('Single Sign On'),
        children: (
          <p>
            {t(
              "This function allows authentication through SSO (single sign-on) in conjunction with an ID provider (IdP).\nYou can build an authentication flow tailored to your team's environment.",
            )}
          </p>
        ),
      };
    }

    case 'roleCount': {
      return {
        helpUrl: 'https://document.microcms.io/manual/roles',
        helpUrlTitle: t('Roles'),
        children: (
          <p>
            {t(
              'This feature allows you to set different types of operation permissions for members within the Administration console.\nThis detailed permission setting allows for safer content operation.',
            )}
          </p>
        ),
      };
    }

    case 'history': {
      return {
        helpUrl: 'https://document.microcms.io/manual/content-history',
        helpUrlTitle: t('Content Revision History'),
        children: <p>{t('You can restore to past versions.')}</p>,
      };
    }

    default: {
      throw new Error('Invalid restriction key');
    }
  }
};

export { getParam };
