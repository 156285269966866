import cx from 'classnames';
import type React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import HelpBody from '../HelpBody';

import styles from './helpButton.module.css';

import { Icon } from '@/views/Common/Ui/Icon';
import { Modal, ModalContent, ModalTrigger } from '@/views/Common/Ui/Modal';

type Props = {
  type: string;
  id: string;
  className?: string;
  tabIndex?: number;
  label?: string;
};

const HelpButton: React.FC<Props> = ({
  type,
  id,
  className,
  tabIndex,
  label,
}) => {
  const { t } = useTranslation('helpButton');

  const clickStopPropagation = useCallback(
    (e: { stopPropagation: () => void }) => {
      e.stopPropagation();
    },
    [],
  );
  const keyDownStopPropagation = useCallback(
    (event: React.KeyboardEvent<HTMLButtonElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.stopPropagation();
      }
    },
    [],
  );

  return (
    <Modal>
      <div className={styles.trigger}>
        <ModalTrigger
          className={cx(styles.button, className)}
          onClick={clickStopPropagation}
          onKeyDown={keyDownStopPropagation}
          tabIndex={tabIndex}
          aria-label={label ?? t('Browse help')}
        >
          <Icon name="help_outline" />
        </ModalTrigger>
        <ModalContent size="medium">
          <HelpBody type={type} id={id} />
        </ModalContent>
      </div>
    </Modal>
  );
};

export default HelpButton;
