import { useCallback } from 'react';

import IconButton from '@/components/ui/IconButton';
import Textfield from '@/components/ui/Textfield';

import styles from './memberInput.module.css';

type Props = {
  email: string;
  errorMessage: string | null;
  placeholder: string;
  isDeleteButtonVisible: boolean;
  onChange: (email: string) => void;
  onDelete: () => void;
};

const MemberInput: React.FC<Props> = ({
  email,
  errorMessage,
  placeholder,
  isDeleteButtonVisible,
  onChange,
  onDelete,
}) => {
  const handleChange = useCallback(
    (e: any) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <div className={styles.container}>
      <Textfield
        type="text"
        value={email}
        errorText={errorMessage}
        placeholder={placeholder}
        onChange={handleChange}
      />
      {isDeleteButtonVisible && (
        <IconButton
          icon="cancel"
          className={styles.iconButton}
          onClick={onDelete}
        />
      )}
    </div>
  );
};

export default MemberInput;
