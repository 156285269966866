import { forwardRef } from 'react';

import type { ReactNode } from 'react';

import s from './InputGroup.module.css';

type Props = {
  groupLabel?: ReactNode;
} & JSX.IntrinsicElements['div'];

/**
 * Roleの作成・編集画面の権限項目の入力フィールドをレイアウトを組むためのコンポーネント
 */
const InputGroup = forwardRef<HTMLDivElement, Props>(function InputGroup(
  { groupLabel, children, ...otherProps },
  ref,
) {
  return (
    <div {...otherProps} ref={ref}>
      {groupLabel && <div className={s.label}>{groupLabel}</div>}
      <div className={s.inputs}>{children}</div>
    </div>
  );
});

export { InputGroup };
