import type React from 'react';
import { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import snakecaseKeys from 'snakecase-keys';

import { useCopy } from '../../../hooks/Copy/useCopy';
import IconButton from '../../ui/IconButton';

import type { Param } from '../Params';

import styles from './code.module.css';

interface Props {
  inputPath: string;
  params: Param[];
  method: string;
  body: string;
  apiType: string;
}

const Ruby: React.FC<Props> = ({
  inputPath,
  params,
  method,
  body,
  apiType,
}) => {
  const { t } = useTranslation('code');
  const [endpoint, contentId] = inputPath.split('/');
  const paramsString = useMemo(() => {
    const paramObject = snakecaseKeys(
      params.reduce(
        (prev, current) => ({
          ...prev,
          ...{ [current.key]: current.value },
        }),
        {},
      ),
    );

    const values = Object.entries(paramObject).map(([key, value]) => {
      // 数値
      if (['limit', 'offset', 'depth'].includes(key)) {
        return { key, value };
      }
      // 配列
      if (['fields', 'ids', 'orders'].includes(key)) {
        return {
          key,
          value:
            '[' +
            String(value)
              .split(',')
              .map((v) => `'${v}'`)
              .join(`, `) +
            ']',
        };
      }
      // 文字列
      return { key, value: `'${value}'` };
    });

    return values.map(({ key, value }) => `  ${key}: ${value}`).join(`, \n`);
  }, [params]);

  const enabledParams = params
    .filter((p) => p.check)
    .reduce((prev, obj) => ({ ...prev, [obj.key]: obj.value }), {});

  const code =
    method === 'GET'
      ? `result = MicroCMS.${
          contentId || apiType === 'PAGE' ? 'get' : 'list'
        }('${endpoint}'` +
        (contentId ? `, '${contentId}'` : '') +
        (params.length > 0 ? `, {\n${paramsString}\n}` : '') +
        ')\n' +
        'puts result'
      : method === 'POST'
        ? `require 'json'\n\nMicroCMS.create(\n  '${endpoint}'` +
          (body ? `,\n  JSON.parse '${body}'` : '') +
          (Object.keys(enabledParams).length !== 0
            ? `,\n  JSON.parse '${JSON.stringify(enabledParams)}'`
            : '') +
          '\n)'
        : method === 'PUT'
          ? `require 'json'\n\nMicroCMS.create(\n  "${endpoint}"` +
            (body
              ? `,\n  JSON.parse '${JSON.stringify({
                  id: contentId,
                  ...JSON.parse(body),
                })}'`
              : '') +
            (Object.keys(enabledParams).length !== 0
              ? `,\n  JSON.parse '${JSON.stringify(enabledParams)}'`
              : '') +
            '\n)'
          : method === 'PATCH'
            ? `require 'json'\n\nMicroCMS.update(\n  "${endpoint}"` +
              (body
                ? `,\n  JSON.parse '${JSON.stringify({
                    id: contentId,
                    ...JSON.parse(body),
                  })}'`
                : '') +
              '\n)'
            : method === 'DELETE'
              ? `MicroCMS.delete('${endpoint}', '${contentId}')`
              : t('Not supported');

  const [copied, copyAction, setCopied] = useCopy(code);
  const copyApiKey = () => {
    copyAction();

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <>
      <SyntaxHighlighter
        language="ruby"
        style={docco}
        customStyle={{ fontSize: 14, padding: '16px', borderRadius: '4px' }}
      >
        {code}
      </SyntaxHighlighter>
      <p className={styles.note}>
        {Trans({
          t,
          i18nKey: 'Above is an example using microcms-ruby-sdk.',
          children: (
            <a
              href="https://github.com/microcmsio/microcms-ruby-sdk"
              target="sdk"
            >
              microcms-ruby-sdk
            </a>
          ),
        })}
      </p>
      <div className={styles.copyButton}>
        <IconButton
          icon={copied ? 'check' : 'content_copy'}
          hasFrame={true}
          onClick={copyApiKey}
        />
      </div>
    </>
  );
};

export default Ruby;
