import { connect } from 'react-redux';

import { accountSelectors } from '../../ducks/account';
import ServiceSideBar from './ServiceSideBar';

const mapStateToProps = (state: any) => {
  return {
    ...accountSelectors.getAccountState(state.accountState),
  };
};

export default connect(mapStateToProps)(ServiceSideBar);
