import type { ContentStatus } from '@/entity/content';

import styles from './customstatus.module.css';

type Props = {
  status: ContentStatus;
  name: string;
  color: string;
  children?: React.ReactNode;
};

const CustomStatus: React.FC<Props> = ({ status, name, color, children }) => {
  return (
    <div className={styles.wrapper} data-testid="content-status">
      <span className={styles.status} style={{ backgroundColor: color }} />
      <span className={styles.name}>{name}</span>
      {children}
    </div>
  );
};

export default CustomStatus;
