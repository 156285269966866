import type React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import TypeList from '../../Icons/TypeList';
import TypeObject from '../../Icons/TypeObject';
import Button from '../../ui/Button';
import styles from '../createApi.module.css';

type Props = {
  goNext: () => void;
  goPrevious: () => void;
  typeInput: any[];
};

const CreateApiInputType: React.FC<Props> = ({
  goNext,
  goPrevious,
  typeInput: [type, onChangeType],
}) => {
  const { t } = useTranslation('createApiInputType');

  const firstFocusRef = useCallback((node: HTMLInputElement | null) => {
    node?.focus();
  }, []);

  return (
    <div className={styles.area}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('Select API type')}</h2>
        <div>
          <div className={styles.typeWrapper}>
            <label>
              <input
                ref={type === 'LIST' ? firstFocusRef : undefined}
                className={styles.typeRadio}
                type="radio"
                name="type"
                value="LIST"
                onChange={onChangeType}
                defaultChecked={type === 'LIST'}
              />
              <dl className={styles.type}>
                <dd>
                  <TypeList className={styles.typeImg} />
                </dd>
                <dt className={styles.typeTitle}>{t('List Format')}</dt>
                <dd className={styles.typeDescription}>
                  {t(
                    'Create an API that returns a JSON array. Suitable for blogs, lists of announcements, carousels, etc.',
                  )}
                </dd>
              </dl>
            </label>
            <label>
              <input
                ref={type === 'PAGE' ? firstFocusRef : undefined}
                className={styles.typeRadio}
                type="radio"
                name="type"
                value="PAGE"
                onChange={onChangeType}
                defaultChecked={type === 'PAGE'}
              />
              <dl className={styles.type}>
                <dd>
                  <TypeObject className={styles.typeImg} />
                </dd>
                <dt className={styles.typeTitle}>{t('Object Format')}</dt>
                <dd className={styles.typeDescription}>
                  {t(
                    'Create an API that returns a JSON object. Suitable for retrieving configuration files, stand-alone page information, etc.',
                  )}
                </dd>
              </dl>
            </label>
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <span className={styles.step}>
          <em>2</em>/3 {t('Steps')}
        </span>
        <div className={styles.prev}>
          <Button value={t('Back')} type="tertiary" onClick={goPrevious} />
        </div>
        <div className={styles.next}>
          <Button
            className="ga-create-api-type"
            value={t('Next')}
            type="secondary"
            onClick={goNext}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateApiInputType;
