import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { answerMemberInvitation } from '@/usecase/memberUsecase';

import { getHost } from '@/util';

type MutationFnProps = {
  /**
   * 参加承諾はtrue、拒否はfalse
   */
  accept: boolean;
};

const useAnswerMemberInvitation = (serviceId: string) => {
  const { t } = useTranslation('hooksMember');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  const { mutateAsync, data, isLoading } = useMutation({
    mutationFn: async (props: MutationFnProps) => {
      return answerMemberInvitation(serviceId, props.accept);
    },
    onSuccess(data, variables) {
      if (variables.accept) {
        if (!data.result) {
          addToast(t('Failed to join the service.'), { appearance: 'error' });
          return;
        }
        addToast(t('Succeeded to join the service.'), {
          appearance: 'success',
        });
      } else {
        if (!data.result) {
          addToast(t('Failed to reject the invitation.'), {
            appearance: 'error',
          });
          return;
        }
      }

      cache.invalidateQueries(['myMember'], { type: 'all' });
      cache.invalidateQueries(['serviceUsers'], { type: 'all' });
    },
    onError() {
      addToast(t('An unexpected error has occurred.'), {
        appearance: 'error',
      });
    },
  });

  return {
    answerMemberInvitationMutation: mutateAsync,
    result: data?.result,
    isLoading,
  };
};

export { useAnswerMemberInvitation };
