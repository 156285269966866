import { apiListSelectors } from '@/ducks/apiList';
import type { ApiList } from '@/entity/api';
import { useLoading } from '@/hooks';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';
import { useAppSelector } from '@/store/hooks';
import type { Service } from '@/types/services';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Feedback from '../../Feedback';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import Textfield from '../../ui/Textfield';
import styles from '../createApi.module.css';

type Props = {
  goNext: () => void;
  nameInput: any;
  endpointInput: any;
  checkSameEndpoint: (service: Service, endpoint: string) => void;
  hasSameEndpoint?: boolean;
  reset: () => void;
};

const CreateApiInputBasicInfo: React.FC<Props> = ({
  goNext,
  nameInput: [name, onChangeName, nameError],
  endpointInput: [endpoint, onChangeEndpoint, endpointError],
  checkSameEndpoint,
  hasSameEndpoint,
  reset,
}) => {
  const { t } = useTranslation('createApiInputBasicInfo');
  const { service } = useGetMyService();

  // TODO: ReduxをReactQueryに置き換える
  const apiLength = useAppSelector(
    (state) =>
      apiListSelectors.getAllApiLength(state.apiListState as ApiList) as number,
  );

  const [serverError, setServerError] = useState<string | undefined>(undefined);
  const [loading, startLoading] = useLoading(hasSameEndpoint !== undefined);
  const { currentPlan } = useStripeActions(service);

  const firstFocusRef = useCallback((node: HTMLInputElement | null) => {
    node?.focus();
  }, []);

  useEffect(() => {
    hasSameEndpoint === true
      ? setServerError(t('Same endpoint already exists.'))
      : hasSameEndpoint === false
        ? goNext()
        : (() => {})() /*何もしない*/;
    reset();
  }, [hasSameEndpoint, goNext, reset, t]);

  const onClickNext = useCallback(() => {
    startLoading();
    if (service) {
      checkSameEndpoint(service, endpoint);
    }
  }, [startLoading, checkSameEndpoint, service, endpoint]);

  const hasLocalError = useMemo(
    () => nameError !== null || endpointError !== null,
    [nameError, endpointError],
  );

  // 追加課金によるAPI追加ができない場合
  const hasMaxApi = useMemo(() => {
    return (
      currentPlan &&
      currentPlan.limit.apiCount !== null &&
      currentPlan.limit.apiCountOveragePrice === null &&
      currentPlan.limit.apiCountOverageUnit === null &&
      currentPlan.limit.apiCount <= apiLength
    );
  }, [currentPlan, apiLength]);

  // 追加課金によりAPI追加ができる場合
  const willBeChargedWhenAddApi = useMemo(() => {
    return (
      currentPlan &&
      currentPlan.limit.apiCount !== null &&
      currentPlan.limit.apiCountOveragePrice !== null &&
      currentPlan.limit.apiCountOverageUnit !== null &&
      currentPlan.limit.apiCount <= apiLength
    );
  }, [apiLength, currentPlan]);

  return (
    <div className={styles.area}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('Enter basic API information')}</h2>
        <div className={styles.container}>
          <Fieldset
            legend={t('API Name')}
            description={t(
              'Enter description of API. This can be changed later.',
            )}
            style={{ paddingBottom: 25 }}
          >
            <Textfield
              inputRef={firstFocusRef}
              className={styles.flex}
              type="text"
              placeholder={t(
                'E.g., announcements, blogs, configuration files, etc...',
              )}
              defaultValue={name}
              onChange={onChangeName}
              errorText={nameError}
            />
          </Fieldset>
          <Fieldset
            legend={t('Endpoint')}
            description={t(
              'Enter API endpoint name in single-byte characters. This can be changed later.',
            )}
          >
            <div className={styles.flexField}>
              <span className={styles.host}>
                {window.location.origin}/api/v1/
              </span>
              <Textfield
                className={styles.endpoint}
                type="text"
                placeholder="news"
                defaultValue={endpoint}
                onChange={onChangeEndpoint}
                errorText={endpointError}
              />
            </div>
          </Fieldset>
        </div>
        <div className={styles.error}>
          {hasMaxApi && (
            <p className={styles.apiCountAlert}>
              {t(
                'The maximum number of APIs that can be created under the current plan has been reached.',
              )}
              <br />
              <Link to="/settings/billing">{t('Check the current plan.')}</Link>
            </p>
          )}
          {willBeChargedWhenAddApi && (
            <p className={styles.apiCountAlert}>
              {t(
                'The maximum number of APIs that can be created under the current plan has been reached.',
              )}
              <br />
              {t(
                'A monthly fee of {{price}} yen (tax excluded) will be charged for each additional API. (The fee for the current month is calculated on a pro-rated basis.)',
                {
                  price:
                    currentPlan.limit.apiCountOveragePrice.toLocaleString(),
                },
              )}
              <br />
              <Link to="/settings/billing">{t('Check the current plan.')}</Link>
            </p>
          )}
        </div>
      </div>
      <div className={styles.actions}>
        <span className={styles.step}>
          <em>1</em>/3 {t('Steps')}
        </span>
        <div className={styles.next}>
          <Feedback message={serverError} />
          <Button
            className="ga-create-api-basic-info"
            value={t('Next')}
            type="secondary"
            disabled={hasMaxApi || loading || hasLocalError}
            onClick={hasMaxApi ? undefined : onClickNext} //開発者コンソールですぐdisabledは外せるので念の為
          />
        </div>
      </div>
    </div>
  );
};

export default CreateApiInputBasicInfo;
