import cx from 'classnames';
import type React from 'react';
import { useCallback } from 'react';

import styles from './textfield.module.css';

type TextType = 'text' | 'number' | 'tel' | 'password';
type SizeType = 'xsmall' | 'small' | 'medium' | 'large' | 'full';

type Props = {
  inputRef?: React.Ref<HTMLInputElement>;
  className?: string;
  placeholder?: string;
  name?: string;
  type?: TextType;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  onClick?: () => void;
  onFocus?: () => void;
  style?: React.CSSProperties;
  value?: string | number;
  defaultValue?: string | number;
  readOnly?: boolean;
  autoComplete?: string;
  errorText?: string | null;
  hasError?: boolean;
  id?: string;
  labelName?: string;
  size?: SizeType;
  dataTestid?: string;
};

/**
 * @deprecated
 * このコンポーネントの代わりに、@src/views/Common/Ui/Textfieldを使用してください。
 */
const Textfield: React.FC<Props> = ({
  inputRef,
  className,
  placeholder,
  name,
  type = 'text',
  onChange = () => {},
  onEnter = () => {},
  onClick = () => {},
  onFocus = () => {},
  style,
  value,
  defaultValue,
  readOnly,
  autoComplete,
  errorText = null,
  hasError = false,
  id,
  labelName,
  size = 'full',
  dataTestid,
}) => {
  const _onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        errorText === null && onEnter();
      }
    },
    [errorText, onEnter],
  );
  return (
    <div className={cx(styles.inputContainer, className)}>
      {id && labelName && <label htmlFor={id}>{labelName}</label>}
      <div className={styles.inputContent}>
        <input
          ref={inputRef}
          id={id}
          type={type}
          name={name}
          className={cx(styles.input, styles[size], {
            [styles.hasError]: errorText !== null || hasError,
            [styles.readOnly]: readOnly,
          })}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={_onEnter}
          onClick={onClick}
          onFocus={onFocus}
          style={style}
          value={value}
          defaultValue={defaultValue}
          readOnly={readOnly}
          autoComplete={autoComplete}
          data-testid={dataTestid}
        />
        {errorText && <p className={styles.errorText}>{errorText}</p>}
      </div>
    </div>
  );
};

export default Textfield;
