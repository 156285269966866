import cx from 'classnames';

import styles from './tag.module.css';

type Props = {
  name: string;
  icon: string;
  limitedWidth?: boolean;
  onDelete?: () => void;
};

export const Tag: React.FC<Props> = ({
  name,
  icon,
  limitedWidth = false,
  onDelete,
}) => {
  return (
    <div className={cx(styles.main, limitedWidth && styles.limited)}>
      <span className={cx('material-icons', styles.icon)}>{icon}</span>
      <span className={cx(styles.name, limitedWidth && styles.limited)}>
        {name}
      </span>
      {onDelete && (
        <button type="button" onClick={onDelete} className={styles.iconClose}>
          <span className="material-icons">close</span>
        </button>
      )}
    </div>
  );
};
