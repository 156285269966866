import cx from 'classnames';

import styles from '../../../richeditorv2.module.css';

import type { TextAlignType } from './useTextAlign';

import {
  TextAlignRightIcon,
  TextAlignCenterIcon,
  TextAlignLeftIcon,
} from '@/views/Common/Icons';

type Props = {
  type: TextAlignType;
};

export const TextAlignIcon: React.FC<Props> = ({ type }) => {
  if (type === 'right') {
    return (
      <TextAlignRightIcon
        className={cx(styles.icon, {
          [styles.isActive]: true,
        })}
      />
    );
  } else if (type === 'center') {
    return (
      <TextAlignCenterIcon
        className={cx(styles.icon, {
          [styles.isActive]: true,
        })}
      />
    );
  }

  return <TextAlignLeftIcon className={styles.icon} />;
};
