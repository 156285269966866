import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from './_types';
import type { Role } from '@/entity/role';

import { produce } from '@/lib/immer';

type SetApiKeyPermissionReadAction = FormRoleInputValueAction<
  'set-apikey-permission-read',
  Role['apiKeyPermission']['read']
>;
type ApiKeyPermissionAction = SetApiKeyPermissionReadAction;

/**
 * ApiKeyPermissionのreadフィールドを更新する
 */
const setApiKeyPermissionRead: FormRoleInputValueDispatchFn<
  SetApiKeyPermissionReadAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.apiKeyPermission.read = payload;
  });
};

export { type ApiKeyPermissionAction, setApiKeyPermissionRead };
