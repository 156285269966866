import { Auth } from 'aws-amplify';
import type React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';

import Delete from './Delete';
import Language from './Language';
import Mfa from './Mfa';
import Name from './Name';
import Password from './Password';
import { useSignOut } from '../../hooks/Auth/useAuth';
import { useCognitoUser } from '../../hooks/Profile/useProfile';
import { getSSOHost, isSAMLUser } from '../../util';
import Head from '../Head';
import PrivateRoute from '../PrivateRoute';
import Menu from '../ui/Menu';
import MenuItem from '../ui/MenuItem';

import styles from './profile.module.css';

async function revokeToken() {
  const ssoHost = getSSOHost();

  const session = await Auth.currentSession();
  const { client_id: clientId } = session.getAccessToken().decodePayload();
  const token = session.getRefreshToken().getToken();

  return fetch(`${ssoHost}/saml/revoke`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token, clientId }),
  }).then((res) =>
    res.status < 300
      ? res.json()
      : Promise.reject(new Error('failed to revoke token')),
  );
}

const Profile: React.FC = () => {
  const { t } = useTranslation('profile');
  const { cognitoUser } = useCognitoUser();
  const { signOut, signOutLoading } = useSignOut();

  const logout = useCallback(() => {
    if (window.confirm(t('Are you sure you want to logout?'))) {
      if (isSAMLUser()) {
        revokeToken().then(() => {
          signOut();
        });
      } else {
        signOut();
      }
    }
  }, [signOut, t]);

  if (cognitoUser === undefined) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Head title={t('Profile')} />
      <div className={styles.menu}>
        <Menu title={t('Profile Settings')}>
          <MenuItem to="/profile" exact={true}>
            {t('Profile')}
          </MenuItem>
          <MenuItem to="/profile/password">{t('Change Password')}</MenuItem>
          <MenuItem to="/profile/language">{t('Language')}</MenuItem>
          <MenuItem to="/profile/mfa">
            {t('Two-Factor authentication')}
          </MenuItem>
          <button
            type="button"
            className={styles.logoutButton}
            onClick={logout}
            disabled={signOutLoading}
          >
            {t('Logout')}
          </button>
        </Menu>
        <Menu title={t('Other')}>
          <MenuItem to="/profile/delete">{t('Close Account')}</MenuItem>
        </Menu>
      </div>
      <div className={styles.flex}>
        <Switch>
          <PrivateRoute exact path="/profile" component={Name} />
          <PrivateRoute exact path="/profile/password" component={Password} />
          <PrivateRoute exact path="/profile/language" component={Language} />
          <PrivateRoute exact path="/profile/mfa" component={Mfa} />
          <PrivateRoute exact path="/profile/delete" component={Delete} />
        </Switch>
      </div>
    </div>
  );
};

export default Profile;
