import { isString } from './type-guard';

import type {
  WebhookEventIdentifier,
  WebhookSetting,
} from '@/entity/webhookSettings';

import { WebhookEvent } from '@/constants/webhookSettings';

/**
 * 引数valueがWebhookEventの識別子の値である場合はtrueを返却
 */
const isWebhookEventIdentifier = (
  value: unknown,
): value is WebhookEventIdentifier => {
  if (!isString(value)) {
    return false;
  }
  return value in WebhookEvent;
};

/**
 * Webhookの通知が有効かどうかを判定
 *
 * trueまたはnullの場合は有効と判定 (nullは一度も有効/無効のフラグが変更されたことがない状態であり、その場合は有効であるため)
 */
const isWebhookEnabled = (value: WebhookSetting['enabled']) => {
  return value === true || value === null;
};

export { isWebhookEventIdentifier, isWebhookEnabled };
