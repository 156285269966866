import { API, graphqlOperation } from 'aws-amplify';

import type { ApiType, ContentIdSetting, CustomField, Field } from '@/API';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

import type { Api, RestrictedApi } from '@/entity/api';
import * as queries from '@/graphql/queries';
import { isGraphQLResultErrorsObject } from '@/util/type-guard';
import {
  type ApiResponse,
  handleApiResult,
} from '@/views/Common/handleApiResult';

type FindApisResponse = (Api | RestrictedApi)[];

export const apiRepository = () => {
  const findApis = async ({
    serviceId,
  }: { serviceId: string }): Promise<FindApisResponse> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.findApis, {
          serviceId,
        }),
      )) as GraphQLResult<{
        findApis: FindApisResponse;
      }>;
      if (!result.data) {
        throw new Error('Unexpected Error');
      }
      return result.data.findApis;
    } catch (error) {
      if (isGraphQLResultErrorsObject(error)) {
        throw error;
      }
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Unexpected Error');
    }
  };
  const createApi = async ({
    serviceId,
    apiName,
    apiEndpoint,
    apiType,
    apiFields,
    customFields,
  }: {
    serviceId: string;
    apiName: string;
    apiEndpoint: string;
    apiType: ApiType;
    apiFields: Field[];
    customFields: CustomField[];
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.createApi, {
          serviceId,
          apiName,
          apiEndpoint,
          apiType,
          apiFields: JSON.stringify(apiFields),
          customFields: JSON.stringify(customFields),
        }),
      )) as GraphQLResult<{
        createApi: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.createApi === 'object' &&
        typeof result.data.createApi.result === 'boolean'
      ) {
        return handleApiResult(result.data.createApi);
      } else if (typeof result.data.createApi === 'string') {
        return result.data.createApi;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const updateApi = async ({
    apiId,
    name,
    endpoint,
    fields,
    previewUrl,
    previewLinkIsVisible,
    sortOrderValue,
    contentIdSetting,
  }: {
    apiId: string;
    name?: string;
    endpoint?: string;
    fields?: Field[];
    previewUrl?: string;
    previewLinkIsVisible?: boolean;
    sortOrderValue?: string;
    contentIdSetting?: ContentIdSetting;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.updateApi, {
          apiId,
          name,
          endpoint,
          fields: JSON.stringify(fields),
          previewUrl,
          previewLinkIsVisible,
          sortOrderValue,
          contentIdSetting: JSON.stringify(contentIdSetting),
        }),
      )) as GraphQLResult<{
        updateApi: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.updateApi === 'object' &&
        typeof result.data.updateApi.result === 'boolean'
      ) {
        return handleApiResult(result.data.updateApi);
      } else if (typeof result.data.updateApi === 'string') {
        return result.data.updateApi;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (isGraphQLResultErrorsObject(e)) {
        throw new Error(e.errors[0].message);
      }
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  const updateApiDescription = async ({
    apiId,
    description,
  }: {
    apiId: string;
    description: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.updateApiDescription, {
          apiId,
          description,
        }),
      )) as GraphQLResult<{
        updateApiDescription: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.updateApiDescription === 'object' &&
        typeof result.data.updateApiDescription.result === 'boolean'
      ) {
        return handleApiResult(result.data.updateApiDescription);
      } else if (typeof result.data.updateApiDescription === 'string') {
        return result.data.updateApiDescription;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  const resetImportProgress = async ({ apiId }: { apiId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.resetImportProgress, {
          apiId,
        }),
      )) as GraphQLResult<{
        resetImportProgress: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.resetImportProgress === 'object' &&
        typeof result.data.resetImportProgress.result === 'boolean'
      ) {
        return handleApiResult(result.data.resetImportProgress);
      } else if (typeof result.data.resetImportProgress === 'string') {
        return result.data.resetImportProgress;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  const updateSortOrderValue = async ({
    apiId,
    sortOrderValue,
  }: {
    apiId: string;
    sortOrderValue: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.updateApi, {
          apiId,
          sortOrderValue,
        }),
      )) as GraphQLResult<{
        updateApi: ApiResponse | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.updateApi === 'object' &&
        typeof result.data.updateApi.result === 'boolean'
      ) {
        return handleApiResult(result.data.updateApi);
      } else if (typeof result.data.updateApi === 'string') {
        return result.data.updateApi;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  const deleteApi = async ({ apiId }: { apiId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.deleteApi, {
          apiId,
        }),
      )) as GraphQLResult<{
        deleteApi: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.deleteApi === 'object' &&
        typeof result.data.deleteApi.result === 'boolean'
      ) {
        return handleApiResult(result.data.deleteApi);
      } else if (typeof result.data.deleteApi === 'string') {
        return result.data.deleteApi;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
        // "Lambda:Unhandled" のエラーオブジェクトの場合を考慮
      } else if (e instanceof Object) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  return {
    findApis,
    createApi,
    updateApi,
    updateApiDescription,
    resetImportProgress,
    updateSortOrderValue,
    deleteApi,
  };
};
