import { Blockquote } from '@tiptap/extension-blockquote';

import type { BlockquoteOptions } from '@tiptap/extension-blockquote';

type CustomBoldOptions = BlockquoteOptions & {
  inputRules: boolean;
};

// NOTE: https://github.com/ueberdosis/tiptap/blob/main/packages/extension-blockquote/src/blockquote.ts
export const CustomBlockquote = Blockquote.extend<CustomBoldOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: true,
    };
  },

  defining() {
    return this.options.inputRules;
  },

  parseHTML() {
    return this.options.inputRules ? this.parent?.() : [];
  },

  addKeyboardShortcuts() {
    return this.options.inputRules ? { ...this.parent?.() } : {};
  },

  addInputRules() {
    return this.options.inputRules ? this.parent?.() || [] : [];
  },
});
