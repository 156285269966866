import { API, graphqlOperation } from 'aws-amplify';

import type {
  GetGlobalApiIpAddressRestrictionQuery,
  UpdateApiAllowedIpListMutation,
  UpdateGlobalApiIpAddressRestrictionMutation,
} from '@/API';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';

export const apiIpAddressRestrictionRepository = () => {
  const getGlobalApiIpAddressRestriction = async (serviceId: string) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.getGlobalApiIpAddressRestriction, {
          serviceId,
        }),
      )) as GraphQLResult<GetGlobalApiIpAddressRestrictionQuery>;

      return result.data?.getGlobalApiIpAddressRestriction?.ipList;
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const updateGlobalApiIpAddressRestriction = async ({
    serviceId,
    ipList,
  }: {
    serviceId: string;
    ipList: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.updateGlobalApiIpAddressRestriction, {
          serviceId,
          ipList,
        }),
      )) as GraphQLResult<UpdateGlobalApiIpAddressRestrictionMutation>;

      if (!result.data?.updateGlobalApiIpAddressRestriction?.result) {
        if (result.data?.updateGlobalApiIpAddressRestriction?.message) {
          throw new Error(
            result.data.updateGlobalApiIpAddressRestriction.message,
          );
        } else {
          throw new Error('Failed to update the IP address list');
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const updateApiIpAddressRestriction = async ({
    apiId,
    ipList,
    enabled,
  }: {
    apiId: string;
    ipList: string;
    enabled: boolean;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.updateApiAllowedIpList, {
          apiId,
          ipList,
          enabled,
        }),
      )) as GraphQLResult<UpdateApiAllowedIpListMutation>;

      if (!result.data?.updateApiAllowedIpList?.result) {
        if (result.data?.updateApiAllowedIpList?.message) {
          throw new Error(result.data.updateApiAllowedIpList.message);
        } else {
          throw new Error('Failed to update the IP address list');
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  return {
    getGlobalApiIpAddressRestriction,
    updateGlobalApiIpAddressRestriction,
    updateApiIpAddressRestriction,
  };
};
