import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Expander from '../../../Expander';
import Button from '../../../ui/Button';
import Switch from '../../../ui/Switch';
import Textfield from '../../../ui/Textfield';
import { validateUrl } from '../../../Validations';
import styles from '../webhookSettings.module.css';

import type { WebhookEvents } from '../../../../types/webhook';
import type {
  CreateNewWebhookSetting,
  CreateNewWebhookSettingForChatwork,
  WebhookSetting,
  WebhookSettingForChatwork,
} from '@/entity/webhookSettings';

import { useInput } from '@/views/Common/useInput';
import { useInputWebhookEvents } from '@/views/WebhookSettings/helpers/useInputWebehookEvents';

type Props = {
  setting: WebhookSettingForChatwork | CreateNewWebhookSettingForChatwork;
  settings: Array<WebhookSetting | CreateNewWebhookSetting>;
  createSetting: any;
  updateSetting: any;
  deleteSetting: any;
  loading: boolean;
  updatable?: boolean;
  onChangeEnabled: (setting: any) => void;
  updateWebhookEnableLoading: boolean;
  webhookEvents: WebhookEvents[];
};

const ChatworkWebhook: React.FC<Props> = ({
  setting,
  settings,
  createSetting,
  updateSetting,
  deleteSetting,
  loading,
  updatable,
  onChangeEnabled,
  updateWebhookEnableLoading,
  webhookEvents,
}) => {
  const { t } = useTranslation('webhooks');
  const isNew = useMemo(
    () => setting && setting.apiId === undefined,
    [setting],
  );

  const [name, onChangeName, nameError] = useInput(
    (setting && setting.settingValues && setting.settingValues.name) || '',
    () => null,
    true,
  );
  const [chatworkApiToken, onChangeChatworkApiToken, chatworkApiTokenError] =
    useInput(
      (setting && setting.settingValues && setting.settingValues.apiToken) ||
        '',
      () => null,
      true,
    );
  const [chatworkRoomId, onChangeChatworkRoomId, chatworkRoomIdError] =
    useInput(
      (setting && setting.settingValues && setting.settingValues.roomId) || '',
      () => null,
      true,
    );

  const [chatworkEvents, onChangeChatworkEvents] = useInputWebhookEvents(
    setting.handleEvents,
  );

  const [
    chatworkNotificationUrl,
    onChangeChatworkNotificationUrl,
    chatworkNotificationUrlError,
  ] = useInput(
    (setting &&
      setting.settingValues &&
      setting.settingValues.notificationUrl) ||
      '',
    validateUrl,
    true,
  );

  const remove = useCallback(() => {
    deleteSetting(setting);
  }, [deleteSetting, setting]);

  const submit = useCallback(() => {
    if (isNew) {
      createSetting(
        'CHATWORK',
        {
          name,
          apiToken: chatworkApiToken,
          roomId: chatworkRoomId,
          notificationUrl: chatworkNotificationUrl,
        },
        chatworkEvents.join(',').split(','),
      );
    } else {
      updateSetting(
        setting,
        {
          ...setting.settingValues,
          name: name || null,
          apiToken: chatworkApiToken || null,
          roomId: chatworkRoomId || null,
          notificationUrl: chatworkNotificationUrl || null,
        },
        chatworkEvents.join(',').split(','),
      );
    }
  }, [
    isNew,
    createSetting,
    name,
    chatworkApiToken,
    chatworkRoomId,
    chatworkNotificationUrl,
    chatworkEvents,
    updateSetting,
    setting,
  ]);

  if (setting === undefined) {
    return null;
  }
  return (
    <Expander
      title={
        <div className={styles.title}>
          <img className={styles.icon} src="/images/icon_chatwork.png" alt="" />
          <span className={styles.name}>Chatwork</span>
          {setting && setting.settingValues && setting.settingValues.name && (
            <span className={styles.webhookName}>
              {setting.settingValues.name}
            </span>
          )}
          <Switch
            className={styles.switch}
            on={
              setting.enabled === null
                ? true
                : typeof setting.enabled === 'undefined'
                  ? true
                  : setting.enabled
            }
            onChange={() => onChangeEnabled(setting)}
            onClick={(e) => e.stopPropagation()}
            disabled={
              updateWebhookEnableLoading ||
              settings.length !==
                settings.filter((setting: any) => setting.apiId !== undefined)
                  .length /* 作成中を含むwebhook要素数と作成ずみのwebhookの数を比較　*/
            }
          />
        </div>
      }
      defaultOpen={isNew}
    >
      <div className={styles.sectionWrapper}>
        <p className={styles.timingGroupLabel}>{t('Basic Settings')}</p>
        <p className={styles.description}>
          {t('Set Webhook name which can be identified. (Optional)')}
        </p>
        <Textfield
          type="text"
          placeholder={t('Webhook Name')}
          defaultValue={name}
          onChange={onChangeName}
          errorText={nameError}
        />
        <p className={styles.description}>
          {t(
            'Set API token. A notification is sent when content is published.',
          )}
        </p>
        <Textfield
          type="text"
          placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
          defaultValue={chatworkApiToken}
          onChange={onChangeChatworkApiToken}
          errorText={chatworkApiTokenError}
          readOnly={!updatable}
        />
        <p className={styles.description}>
          {t(
            'Set the room ID. It is the numeric part of rid00000000000 which is usually included in the URL.',
          )}
        </p>
        <Textfield
          type="text"
          placeholder="000000000"
          defaultValue={chatworkRoomId}
          onChange={onChangeChatworkRoomId}
          errorText={chatworkRoomIdError}
          readOnly={!updatable}
        />
      </div>
      <div className={styles.sectionWrapper}>
        <p className={styles.timingGroupLabel}>
          {t('Notification timing settings')}
        </p>
        {webhookEvents.map(({ label, values }, i) => (
          <div className={styles.section} key={i}>
            <p className={styles.description}>{label}</p>
            <div className={styles.checkboxes}>
              {values.map(
                ({ label, value }, i) =>
                  label &&
                  value && (
                    <div className={styles.timingDetailLabel} key={i}>
                      <label className={styles.checkbox}>
                        <input
                          type="checkbox"
                          value={value}
                          checked={chatworkEvents.includes(value)}
                          onChange={onChangeChatworkEvents}
                          disabled={!updatable}
                        ></input>
                        {label}
                      </label>
                    </div>
                  ),
              )}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.sectionWrapper}>
        <p className={styles.timingGroupLabel}>{t('Display Settings')}</p>
        <p className={styles.description}>
          {t('Enter URL to be notified if required.')}
          {t(
            'If you include the string {CONTENT_ID}, it will be replaced by the contentId of the published content during notification. If you do not specify this setting, the URL of the Administration console will be notified.',
          )}
        </p>
        <Textfield
          type="text"
          placeholder="https://yourdomain.com/{CONTENT_ID}"
          defaultValue={chatworkNotificationUrl}
          onChange={onChangeChatworkNotificationUrl}
          errorText={chatworkNotificationUrlError}
          readOnly={!updatable}
        />
        {updatable && (
          <div className={styles.actions}>
            <Button
              type="primary"
              className="ga-api-settings-webhook-chatwork"
              disabled={
                loading ||
                !!chatworkNotificationUrlError ||
                !chatworkApiToken ||
                !chatworkRoomId ||
                chatworkEvents.length === 0
              }
              value={isNew ? t('Setup') : t('Save changes')}
              onClick={submit}
            />
            {!isNew && (
              <Button
                type="danger"
                disabled={loading}
                value={t('Delete')}
                onClick={remove}
              />
            )}
          </div>
        )}
      </div>
    </Expander>
  );
};

export default ChatworkWebhook;
