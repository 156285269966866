import { contentsRepository } from '@/repository/contentsRepository';

type Params = {
  endpoint: string;
  contentId: string;
  createdBy: string;
};

/**
 * コンテンツの作成者を変更する
 */
export const updateContentCreatedBy = async ({
  endpoint,
  contentId,
  createdBy,
}: Params): Promise<void> => {
  const { updateContentCreatedBy } = contentsRepository(endpoint, contentId);
  return await updateContentCreatedBy(createdBy);
};
