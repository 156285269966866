import { useTranslation } from 'react-i18next';

import Button from '@/components/ui/Button';
import Switch from '@/components/ui/Switch';

import { useImageSettings } from './useImageSettings';

import type { ImageAttributes } from '..';

import styles from './imagesettings.module.css';

import { Textfield } from '@/views/Common/Ui/Textfield';

export type Props = {
  src: string;
  width: number;
  height: number;
  imgAttributes: ImageAttributes;
  onUpdateImgAttributes: (inputImgAttributes: ImageAttributes) => void;
};

export type ViewProps = {
  src: string;
  width: number;
  height: number;
  inputImgAttributes: ImageAttributes;
  onChangeAlt: (alt: ImageAttributes['alt']) => void;
  onChangeFigcaption: (figcaption: ImageAttributes['figcaption']) => void;
  onChangeWidth: (width: number) => void;
  onChangeHeight: (height: number) => void;
  onChangeLink: (link: string) => void;
  onChangeTargetBlank: (isTargetBlank: boolean) => void;
  onUpdateImgAttributes: (inputImgAttributes: ImageAttributes) => void;
  sizeError?: string;
  linkError?: string;
};

export const ImageSettingsView: React.FC<ViewProps> = ({
  src,
  width,
  height,
  inputImgAttributes,
  onChangeAlt,
  onChangeFigcaption,
  onChangeWidth,
  onChangeHeight,
  onChangeLink,
  onChangeTargetBlank,
  onUpdateImgAttributes,
  sizeError,
  linkError,
}) => {
  const { t } = useTranslation('richEditorV2');
  const { alt, customWidth, customHeight, link, figcaption, isTargetBlank } =
    inputImgAttributes;

  return (
    <div>
      <img src={src} className={styles.image} alt={alt} />
      <div className={styles.editFields}>
        <div className={styles.flex}>
          <Textfield
            type="number"
            label={t('Width')}
            value={customWidth === null ? width : customWidth}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeWidth(e.target.valueAsNumber)
            }
            error={sizeError}
            style={{ width: 140 }}
          />
          <div className={styles.link}>
            <span className="material-icons-outlined" aria-hidden="true">
              link
            </span>
          </div>
          <Textfield
            type="number"
            label={t('Height')}
            value={customHeight === null ? height : customHeight}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeHeight(e.target.valueAsNumber)
            }
            error={sizeError}
            style={{ width: 140 }}
          />
        </div>
        <Textfield
          type="text"
          label={t('Alt')}
          value={alt}
          onChange={(e) => onChangeAlt(e.target.value)}
        />
        <Textfield
          type="text"
          label={t('Caption (description)')}
          value={figcaption || ''}
          onChange={(e) => onChangeFigcaption(e.target.value)}
        />
        <div>
          <Textfield
            label={t('Link')}
            placeholder={t('Enter URL')}
            type="text"
            value={link}
            onChange={(e) => onChangeLink(e.target.value)}
            error={linkError}
          />
          <div className={styles.switch}>
            <Switch on={isTargetBlank} onChange={onChangeTargetBlank}>
              {t('Open in a new tab')}
            </Switch>
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <Button
          value={t('Save changes')}
          size="small"
          type="secondary"
          onClick={() => onUpdateImgAttributes(inputImgAttributes)}
          disabled={!!sizeError || !!linkError}
        />
      </div>
    </div>
  );
};

export const ImageSettings: React.FC<Props> = ({
  src,
  width,
  height,
  imgAttributes,
  onUpdateImgAttributes,
}) => {
  const {
    inputImgAttributes,
    onChangeAlt,
    onChangeFigcaption,
    sizeError,
    onChangeWidth,
    onChangeHeight,
    linkError,
    onChangeLink,
    onChangeTargetBlank,
  } = useImageSettings({
    initialImgAttributes: imgAttributes,
    width,
    height,
  });
  return (
    <ImageSettingsView
      src={src}
      width={width}
      height={height}
      inputImgAttributes={inputImgAttributes}
      onChangeAlt={onChangeAlt}
      onChangeFigcaption={onChangeFigcaption}
      onChangeWidth={onChangeWidth}
      onChangeHeight={onChangeHeight}
      onChangeLink={onChangeLink}
      onChangeTargetBlank={onChangeTargetBlank}
      onUpdateImgAttributes={onUpdateImgAttributes}
      sizeError={sizeError}
      linkError={linkError}
    />
  );
};
