import {
  addReviewerRepository,
  removeReviewerRepository,
} from '@/repository/reviewerRepository';

async function addReviewerUsecase(
  variables: Parameters<typeof addReviewerRepository>[0],
) {
  const result = await addReviewerRepository(variables);
  return result.data?.addReviewer;
}

async function removeReviewerUsecase(
  variables: Parameters<typeof removeReviewerRepository>[0],
) {
  const result = await removeReviewerRepository(variables);
  return result.data?.removeReviewer;
}

export { addReviewerUsecase, removeReviewerUsecase };
