import { EditTableMenu } from './EditTableMenu';
import { InsertTableMenu } from './InsertTableMenu';
import { useTable } from './useTable';

import type { Editor } from '@tiptap/react';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  insertTableHandler: (rows: number, cols: number) => void;
  deleteTableHandler: () => void;
  addColumnBeforeHandler: () => void;
  addColumnAfterHandler: () => void;
  deleteColumnHandler: () => void;
  addRowAfterHandler: () => void;
  addRowBeforeHandler: () => void;
  deleteRowHandler: () => void;
  mergeCellsHandler: () => void;
  splitCellHandler: () => void;
  onHoverTableItem: (row: number, col: number) => void;
  tableItemPosition: { row: number; column: number };
  isActiveTable: boolean;
  toggleHeaderRowHandler: () => void;
  toggleHeaderColumnHandler: () => void;
  isEnableHeaderRow: boolean;
  isEnableHeaderColumn: boolean;
  isEnableMergeCell: boolean;
  isEnableSplitCell: boolean;
  editable: boolean;
  isActiveTableHeader: boolean;
};

export const TableView: React.FC<ViewProps> = ({
  insertTableHandler,
  deleteTableHandler,
  addColumnBeforeHandler,
  addColumnAfterHandler,
  deleteColumnHandler,
  addRowAfterHandler,
  addRowBeforeHandler,
  deleteRowHandler,
  mergeCellsHandler,
  splitCellHandler,
  onHoverTableItem,
  tableItemPosition,
  isActiveTable,
  toggleHeaderRowHandler,
  toggleHeaderColumnHandler,
  isEnableHeaderRow,
  isEnableHeaderColumn,
  isEnableMergeCell,
  isEnableSplitCell,
  editable,
  isActiveTableHeader,
}) => {
  return isActiveTable ? (
    <EditTableMenu
      deleteTableHandler={deleteTableHandler}
      addColumnBeforeHandler={addColumnBeforeHandler}
      addColumnAfterHandler={addColumnAfterHandler}
      deleteColumnHandler={deleteColumnHandler}
      addRowAfterHandler={addRowAfterHandler}
      addRowBeforeHandler={addRowBeforeHandler}
      deleteRowHandler={deleteRowHandler}
      mergeCellsHandler={mergeCellsHandler}
      splitCellHandler={splitCellHandler}
      isActiveTable={isActiveTable}
      toggleHeaderRowHandler={toggleHeaderRowHandler}
      toggleHeaderColumnHandler={toggleHeaderColumnHandler}
      isEnableHeaderRow={isEnableHeaderRow}
      isEnableHeaderColumn={isEnableHeaderColumn}
      isEnableMergeCell={isEnableMergeCell}
      isEnableSplitCell={isEnableSplitCell}
      isActiveTableHeader={isActiveTableHeader}
    />
  ) : (
    <InsertTableMenu
      tableItemPosition={tableItemPosition}
      insertTableHandler={insertTableHandler}
      onHoverTableItem={onHoverTableItem}
      editable={editable}
    />
  );
};

export const Table: React.FC<Props> = ({ editor }) => {
  const {
    insertTableHandler,
    deleteTableHandler,
    addColumnBeforeHandler,
    addColumnAfterHandler,
    deleteColumnHandler,
    addRowAfterHandler,
    addRowBeforeHandler,
    deleteRowHandler,
    mergeCellsHandler,
    splitCellHandler,
    tableItemPosition,
    onHoverTableItem,
    isActiveTable,
    toggleHeaderRowHandler,
    toggleHeaderColumnHandler,
    isEnableHeaderRow,
    isEnableHeaderColumn,
    isEnableMergeCell,
    isEnableSplitCell,
    isActiveTableHeader,
  } = useTable({
    editor,
  });

  return (
    <TableView
      insertTableHandler={insertTableHandler}
      deleteTableHandler={deleteTableHandler}
      onHoverTableItem={onHoverTableItem}
      tableItemPosition={tableItemPosition}
      isActiveTable={isActiveTable}
      addColumnBeforeHandler={addColumnBeforeHandler}
      addColumnAfterHandler={addColumnAfterHandler}
      deleteColumnHandler={deleteColumnHandler}
      addRowAfterHandler={addRowAfterHandler}
      addRowBeforeHandler={addRowBeforeHandler}
      deleteRowHandler={deleteRowHandler}
      mergeCellsHandler={mergeCellsHandler}
      splitCellHandler={splitCellHandler}
      toggleHeaderRowHandler={toggleHeaderRowHandler}
      toggleHeaderColumnHandler={toggleHeaderColumnHandler}
      isEnableHeaderRow={isEnableHeaderRow}
      isEnableHeaderColumn={isEnableHeaderColumn}
      isEnableMergeCell={isEnableMergeCell}
      isEnableSplitCell={isEnableSplitCell}
      editable={editor.isEditable}
      isActiveTableHeader={isActiveTableHeader}
    />
  );
};
