import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { updatePublishedAt } from '@/usecase/contentUsecase';

import { contentQueryKeys } from '@/views/Common/content/queryKeys';

export const useUpdatePublishedAtModalContentQuery = () => {
  const { t } = useTranslation('updatePublishedAtModal');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  return useMutation({
    mutationFn: async ({
      contentId,
      publishedAt,
    }: {
      contentId: string;
      publishedAt: Date;
      closeEditPublishedAtModal: () => void;
    }) => await updatePublishedAt(contentId, publishedAt),
    onSuccess(contentId, { closeEditPublishedAtModal }) {
      cache.invalidateQueries(contentQueryKeys.detail(contentId), {
        type: 'all',
      });
      addToast(t('Updated published date and time.'), {
        appearance: 'success',
      });
      closeEditPublishedAtModal();
    },
    onError(error: Error) {
      addToast(
        error.message ? error.message : t('PublishedAt could not be changed.'),
        {
          appearance: 'error',
        },
      );
    },
  });
};
