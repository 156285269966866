import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

export const useCustomStatus = (apiId) => {
  const customStatusQuery = useQuery({
    queryKey: ['customStatus', apiId],
    queryFn: () => {
      // TODO:undefinedが入るケースの調査
      // https://github.com/microcmsio/microcms/pull/9435
      if (!apiId) {
        return null;
      }
      return API.graphql(
        graphqlOperation(queries.listCustomStatus, {
          apiId,
        }),
      ).then((response) => {
        return response.data.listCustomStatus;
      });
    },
    staleTime: Number.POSITIVE_INFINITY,
  });

  const { data: customStatus, isLoading } = customStatusQuery;
  return { customStatus, isLoading };
};

export const useAddCustomStatus = () => {
  const { t } = useTranslation('hooksCustomStatus');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  const { mutate: addCustomStatus, isLoading: addCustomStatusLoading } =
    useMutation(
      async ({ apiId, key, description, name, behaviour, color }) => {
        const response = await API.graphql(
          graphqlOperation(mutations.addCustomStatus, {
            apiId,
            key,
            description: description ?? '',
            name,
            behaviour,
            color,
          }),
        );
        const data = await response.data.addCustomStatus;

        return data;
      },
      {
        onSuccess(data, { closedModal }) {
          if (data.result === false) {
            addToast(data.message, { appearance: 'error' });
            return;
          }

          cache.invalidateQueries(['customStatus'], { type: 'all' });
          closedModal();
          addToast(t('Custom status has been created.'), {
            appearance: 'success',
          });
        },
        onError({ errors }) {
          addToast(errors ? errors[0].message : t('Failed to make changes.'), {
            appearance: 'error',
          });
        },
      },
    );
  return [addCustomStatus, addCustomStatusLoading];
};

export const useUpdateCustomStatus = () => {
  const { t } = useTranslation('hooksCustomStatus');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  const { mutate: updateCustomStatus, isLoading: updateCustomStatusLoading } =
    useMutation(
      async ({
        apiId,
        customStatusId,
        key,
        description,
        name,
        behaviour,
        color,
      }) => {
        const response = await API.graphql(
          graphqlOperation(mutations.updateCustomStatus, {
            apiId,
            customStatusId,
            key,
            description: description ?? '',
            name,
            behaviour,
            color,
          }),
        );
        const data = await response.data.updateCustomStatus;

        return data;
      },
      {
        onSuccess(data, { closeModal }) {
          if (data.result === false) {
            addToast(data.message, { appearance: 'error' });
            return;
          }

          cache.invalidateQueries(['customStatus'], { type: 'all' });
          closeModal();
          addToast(t('Custom status has been updated.'), {
            appearance: 'success',
          });
        },
        onError({ errors }) {
          const errorMessage = errors?.[0]?.message;
          addToast(errorMessage ? errorMessage : t('Failed to make changes.'), {
            appearance: 'error',
          });
        },
      },
    );
  return [updateCustomStatus, updateCustomStatusLoading];
};

export const useDeleteCustomStatus = () => {
  const { t } = useTranslation('hooksCustomStatus');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  const { mutate: deleteCustomStatus, isLoading: deleteCustomStatusLoading } =
    useMutation(
      async ({ apiId, customStatusId }) => {
        const response = await API.graphql(
          graphqlOperation(mutations.deleteCustomStatus, {
            apiId,
            customStatusId,
          }),
        );
        const data = await response.data.deleteCustomStatus;

        return data;
      },
      {
        onSuccess(data, { closeModal }) {
          if (data.result === false) {
            addToast(data.message, { appearance: 'error' });
            return;
          }

          cache.invalidateQueries(['customStatus'], { type: 'all' });
          closeModal();
          addToast(t('Custom status has been deleted.'), {
            appearance: 'success',
          });
        },
        onError({ errors }) {
          addToast(errors ? errors[0].message : t('Failed to make changes.'), {
            appearance: 'error',
          });
        },
      },
    );
  return [deleteCustomStatus, deleteCustomStatusLoading];
};

export const useAllCustomStatuses = (apiList) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (apiList) {
        const customStatuses = await Promise.all(
          await apiList.map((v) => {
            return API.graphql(
              graphqlOperation(queries.listCustomStatus, {
                apiId: v.partitionKey,
              }),
            ).then((v) => v.data.listCustomStatus);
          }),
        );

        const newCustomStuses = customStatuses.flat();

        setData(newCustomStuses);
      }

      return null;
    };

    fetchData();
  }, [apiList]);

  return { data };
};
