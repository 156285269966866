import type { MigrateApi } from '@/entity/api';
import type { ReviewRequest, ReviewRequestEvent } from '@/entity/review';
import { Icon } from '@/views/Common/Ui/Icon';
import { useTranslation } from 'react-i18next';
import Event from '../../Event';
import styles from './events.module.css';
import { useDisplayEvents } from './useDisplayEvents';

type Props = {
  api: MigrateApi | null | undefined;
  userDefinedContentId: string | undefined;
  reviewRequest: ReviewRequest;
  reviewRequestEvents: ReviewRequestEvent[] | undefined;
  hasAllReviewUpdatePermission: boolean;
  deleteComment: (eventId: string) => void;
  deleteReviewRequestCommentLoading: boolean;
};

const Events: React.FC<Props> = ({
  api,
  userDefinedContentId,
  reviewRequest,
  reviewRequestEvents,
  hasAllReviewUpdatePermission,
  deleteComment,
  deleteReviewRequestCommentLoading,
}) => {
  const { t } = useTranslation('editReview');

  return (
    <div className={styles.timeline}>
      {reviewRequestEvents === undefined ? (
        <p className={styles.loading}>{t('Loading')}</p>
      ) : reviewRequestEvents.length === 0 ? (
        <p className={styles.empty}>
          <span className={styles.icon}>
            <i className="material-icons-outlined">announcement</i>
          </span>
          {t('No comments')}
        </p>
      ) : (
        <EventList
          api={api}
          userDefinedContentId={userDefinedContentId}
          reviewRequest={reviewRequest}
          reviewRequestEvents={reviewRequestEvents}
          hasAllReviewUpdatePermission={hasAllReviewUpdatePermission}
          deleteComment={deleteComment}
          deleteReviewRequestCommentLoading={deleteReviewRequestCommentLoading}
        />
      )}
    </div>
  );
};

type EventListProps = Omit<Props, 'reviewRequestEvents'> & {
  reviewRequestEvents: ReviewRequestEvent[];
};

const EventList: React.FC<EventListProps> = ({
  api,
  userDefinedContentId,
  reviewRequest,
  reviewRequestEvents,
  hasAllReviewUpdatePermission,
  deleteComment,
  deleteReviewRequestCommentLoading,
}) => {
  const { t } = useTranslation('editReview');

  const displayEvents = useDisplayEvents(reviewRequestEvents);

  return (
    <ul className={styles.events}>
      {displayEvents.map((node) => {
        if (node.type === 'expanded') {
          const event = node.event;
          return (
            <Event
              key={node.key}
              event={event}
              api={api}
              userDefinedContentId={userDefinedContentId}
              hasAllUpdatePermission={hasAllReviewUpdatePermission}
              deleteComment={deleteComment}
              deleteCommentLoading={deleteReviewRequestCommentLoading}
              publishMessage={
                reviewRequest.reservationTime
                  ? t('Schedule Publishing')
                  : t('Content Release')
              }
            />
          );
        }

        return (
          <li key={node.key} className={styles.omitted}>
            <button
              type="button"
              className={styles.omittedButton}
              onClick={node.expandEvents}
            >
              <Icon name="unfold_more" outlined />
              {t('Expand')}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export { Events };
