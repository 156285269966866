import { getAllMembers } from '@/usecase/memberUsecase';

import { useApi } from '@/lib/useApi';

export const useAllMembers = (serviceId?: string | null) => {
  return useApi(
    ['serviceUsers'],
    async () => {
      if (!serviceId) {
        return;
      }

      return getAllMembers(serviceId);
    },
    {
      enabled: !!serviceId,
      staleTime: Number.POSITIVE_INFINITY,
    },
  );
};
