import { Blockquote } from './Blockquote';
import { CodeBlock } from './CodeBlock';
import { HorizontalRule } from './HorizontalRule';
import { Table } from './Table';

import type { RichEditorV2Options } from '@/constants/richEditorOptions';
import type { Editor } from '@tiptap/react';

import style from './block.module.css';

export type Props = {
  editor: Editor;
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
};

export type ViewProps = {
  editor: Editor;
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
};

export const Block: React.FC<ViewProps> = ({ editor, richEditorV2Options }) => {
  return (
    <div className={style.main}>
      {richEditorV2Options.includes('horizontalRule') && (
        <HorizontalRule editor={editor} />
      )}
      {richEditorV2Options.includes('blockquote') && (
        <Blockquote editor={editor} />
      )}
      {richEditorV2Options.includes('codeBlock') && (
        <CodeBlock editor={editor} />
      )}
      {richEditorV2Options.includes('table') && <Table editor={editor} />}
    </div>
  );
};
