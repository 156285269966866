import {
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
  useCallback,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useInput } from '../../hooks';
import {
  useForgotPassword,
  useForgotPasswordSubmit,
} from '../../hooks/Auth/useAuth';
import Feedback from '../Feedback';
import Button from '../ui/Button';
import ExternalLink from '../ui/ExternalLink';
import Fieldset from '../ui/Fieldset';
import Footer from '../ui/Footer';
import Textfield from '../ui/Textfield';
import { validateEmail, validatePassword, validateCode } from '../Validations';

import styles from './forgotPassword.module.css';

type SendEmailProps = {
  email: string;
  onChangeEmail: (e?: React.ChangeEvent<HTMLInputElement> | undefined) => void;
  emailError?: string | null;
  forgotPassword: any;
  forgotPasswordLoading: boolean;
  serverError?: Error;
};

const SendEmail: React.FC<SendEmailProps> = ({
  email,
  onChangeEmail,
  emailError,
  forgotPassword,
  forgotPasswordLoading,
  serverError,
}) => {
  const { t } = useTranslation('forgotPassword');
  const sendEmail = useCallback(() => {
    forgotPassword({ email });
  }, [email, forgotPassword]);

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <Link to="/" className={styles.logo}>
          <img
            className={styles.logoImg}
            src="/images/logo_black.svg"
            alt="microCMS"
          />
        </Link>
      </header>
      <div className={styles.container}>
        <div className={styles.textBox}>
          <h2 className={styles.title}>{t('Request Password Reset')}</h2>
          <p className={styles.description}>
            {t(
              'An authorization code to reset your password will be sent to the email address provided.',
            )}
            <br />
            {t(
              'If you have set blocking e-mail for domains, change the setting now to allow e-mail from `@microcms.io` domain.',
            )}
          </p>
        </div>
        <div className={styles.formBox}>
          <Fieldset legend={t('Email Address')}>
            <Textfield
              type="text"
              name="email"
              placeholder="mailaddress@microcms.io"
              onChange={onChangeEmail}
              onEnter={sendEmail}
              errorText={emailError}
            />
          </Fieldset>
          <div className={styles.actions}>
            <Feedback message={serverError && serverError.message} />
            <Button
              type="secondary"
              value={t('Send Email')}
              size="full"
              disabled={emailError !== null || forgotPasswordLoading}
              onClick={sendEmail}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

type SetNewPasswordProps = {
  email: string;
  serverError?: Error;
};

const SetNewPassword: React.FC<SetNewPasswordProps> = ({
  email,
  serverError,
}) => {
  const { t } = useTranslation('forgotPassword');
  const { forgotPasswordSubmit, forgotPasswordSubmitLoading } =
    useForgotPasswordSubmit();
  const [code, onChangeCode, codeError, setCode] = useInput('', validateCode);
  const [password, onChangePassword, passwordError, setPassword] = useInput(
    '',
    validatePassword,
  );

  const hasLocalError = useMemo(
    () => passwordError !== null || codeError !== null,
    [passwordError, codeError],
  );

  useEffect(() => {
    if (serverError !== undefined) {
      setPassword('');
      setCode('');
    }
  }, [serverError, setPassword, setCode]);

  const submit = useCallback(() => {
    forgotPasswordSubmit({ email, code, password });
  }, [email, code, password, forgotPasswordSubmit]);

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <Link to="/" className={styles.logo}>
          <img
            className={styles.logoImg}
            src="/images/logo_black.svg"
            alt="microCMS"
          />
        </Link>
      </header>
      <div className={styles.container}>
        <div className={styles.textBox}>
          <h2 className={styles.title}>{t('Reset Password')}</h2>
          <p className={styles.description}>
            {t(
              'An authentication code has been sent to the email address you provided for identification purposes. Enter the verification code and new password to reset your password.',
            )}
            <br />
            {Trans({
              t,
              i18nKey:
                "If you don't receive the authentication code, it might be because the account doesn't exist or the email address has not been verified. For more details, please check the <l>help</l>.",
              components: {
                l: (
                  <ExternalLink
                    href={
                      'https://help.microcms.io/ja/knowledge/password-reset-failed'
                    }
                    hasUnderline={true}
                    eraseNoreferrer={true}
                  >
                    help link
                  </ExternalLink>
                ),
              },
            })}
          </p>
        </div>
        <div className={styles.formBox}>
          <Fieldset legend={t('Verification Code')}>
            <Textfield
              type="text"
              placeholder="123456"
              value={code}
              onChange={onChangeCode}
              errorText={codeError}
              autoComplete="new-code"
            />
          </Fieldset>
          <Fieldset
            legend={t('Password')}
            description={t(
              'Must be at least 8 characters. Must include uppercase letters, lowercase letters and numbers.',
            )}
          >
            <Textfield
              type="password"
              placeholder="************"
              value={password}
              onChange={onChangePassword}
              onEnter={submit}
              errorText={passwordError}
              autoComplete="new-password"
            />
          </Fieldset>
          <div className={styles.actions}>
            <Feedback message={serverError && serverError.message} />
            <Button
              type="secondary"
              size="full"
              value={t('Reset Password')}
              disabled={hasLocalError || forgotPasswordSubmitLoading}
              onClick={submit}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

type ForgotPasswordProps = {
  reset: any;
};

const ForgotPassword: React.FC<ForgotPasswordProps> = ({ reset, ...props }) => {
  const { forgotPassword, forgotPasswordLoading, forgotPasswordSuccess } =
    useForgotPassword();

  const [page, setPage] = useState(1);
  const [email, onChangeEmail, emailError] = useInput('', validateEmail);

  useLayoutEffect(() => {
    reset();
  }, [reset]);

  useEffect(() => {
    if (forgotPasswordSuccess === true) {
      setPage(2);
    }
  }, [forgotPasswordSuccess]);

  if (page === 1) {
    return (
      <SendEmail
        {...props}
        email={email}
        onChangeEmail={onChangeEmail}
        emailError={emailError}
        forgotPassword={forgotPassword}
        forgotPasswordLoading={forgotPasswordLoading}
      />
    );
  }
  return <SetNewPassword {...props} email={email} />;
};

export default ForgotPassword;
