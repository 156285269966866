import { useFloating, autoUpdate, offset } from '@floating-ui/react';
import * as Slider from '@radix-ui/react-slider';
import { forwardRef } from 'react';

import styles from './range.module.css';

export type Props = Omit<
  React.ComponentProps<typeof Slider.Root>,
  'mix' | 'max' | 'value' | 'defaultValue' | 'onValueChange'
> & {
  label: string;
  min: number;
  max: number;
  value?: number[];
  defaultValue?: number[];
  onValueChange?(value: number[]): void;
};

export const Range = forwardRef<React.ElementRef<typeof Slider.Root>, Props>(
  (
    { label, max, min, value, defaultValue, onValueChange, ...props },
    forwardedRef,
  ) => {
    const { refs, x, y, strategy } = useFloating({
      placement: 'top',
      middleware: [offset(4)],
      whileElementsMounted: autoUpdate,
    });

    return (
      <Slider.Root
        ref={forwardedRef}
        className={styles.sliderRoot}
        step={1}
        max={max}
        min={min}
        value={value || defaultValue}
        onValueChange={onValueChange}
        {...props}
      >
        <span className={styles.sliderMin}>{min}</span>
        <Slider.Track className={styles.sliderTrack}>
          <Slider.Range className={styles.sliderRange} />
        </Slider.Track>
        <span className={styles.sliderMax}>{max}</span>
        <Slider.Thumb
          className={styles.sliderThumb}
          aria-label={label}
          ref={refs.setReference}
        />
        <span
          ref={refs.setFloating}
          style={{ position: strategy, left: x || 0, top: y || 0 }}
          className={styles.sliderValue}
        >
          {value}
        </span>
      </Slider.Root>
    );
  },
);

Range.displayName = 'Range';
