import type React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Fieldset from '../../ui/Fieldset';
import Switch from '../../ui/Switch';
import Textfield from '../../ui/Textfield';
import { validateNumberRange } from '../../Validations/index';
import styles from '../apiModel.module.css';

type NumberRange = {
  min?: number;
  max?: number;
};

type NumberRangeValidation = {
  numberSize: NumberRange;
};

type Props = {
  numberSizeLimitValidation: NumberRangeValidation;
  idValue: string;
  onChange: (validation?: NumberRangeValidation) => void;
  onError: (error: string | null) => void;
};

const NumberSizeLimitValidationField: React.FC<Props> = ({
  numberSizeLimitValidation,
  idValue,
  onChange = () => {},
  onError = () => {},
}) => {
  const { t } = useTranslation('numberSizeLimitValidationField');
  const [enabled, setEnabled] = useState(() => !!numberSizeLimitValidation);
  const [min, setMin] = useState<number | undefined>(
    () => numberSizeLimitValidation?.numberSize.min,
  );
  const [max, setMax] = useState<number | undefined>(
    () => numberSizeLimitValidation?.numberSize.max,
  );
  const [errorText, setErrorText] = useState<string | null>(null);
  const [validation, setValidation] = useState<NumberRangeValidation>();

  const onChangeMin = useCallback(({ target }: any) => {
    setMin(target.value ? Number(target.value) : undefined);
  }, []);

  const onChangeMax = useCallback(({ target }: any) => {
    setMax(target.value ? Number(target.value) : undefined);
  }, []);

  useEffect(() => {
    onError(errorText);
  }, [errorText, onError]);

  useEffect(() => {
    onChange(validation);
  }, [onChange, validation]);

  useEffect(() => {
    if (!enabled) {
      setValidation(undefined);
      setErrorText(null);
      return;
    }
    const error = validateNumberRange(min, max);
    setErrorText(error);
    if (!error) {
      setValidation({ numberSize: { min, max } });
    }
  }, [enabled, max, min]);

  return (
    <Fieldset
      legend={t('Set Maximum and Minimum Value')}
      description={t(
        'Set the minimum and maximum value to be entered in the field.',
      )}
    >
      <Switch
        on={enabled}
        onChange={(state) => setEnabled(state)}
        size="large"
      />
      {enabled && (
        <>
          <div className={styles.validationContent}>
            <Textfield
              defaultValue={min}
              id={`${idValue}_validation-number-size-min`}
              className={styles.validationContentField}
              labelName={t('minimum')}
              type="number"
              placeholder={t('E.g., 5')}
              onChange={onChangeMin}
              style={{ width: '100px' }}
              hasError={!!errorText}
            />
            <Textfield
              defaultValue={max}
              id={`${idValue}_validation-number-size-max`}
              className={styles.validationContentField}
              labelName={t('maximum')}
              type="number"
              placeholder={t('E.g., 30')}
              // @ts-ignore
              onChange={onChangeMax}
              style={{ width: '100px' }}
              hasError={!!errorText}
            />
          </div>
          {errorText && <p className={styles.errorText}>{errorText}</p>}
        </>
      )}
    </Fieldset>
  );
};

export default NumberSizeLimitValidationField;
