import { getTranslation } from '@/i18n';
import { isUrl } from '@/util/is-url';

export const validateUrl = (url: string) => {
  const { t } = getTranslation('richEditorV2');

  if (url === '') {
    return t('This field is required.');
  }
  if (!url.startsWith('http') || !isUrl(url)) {
    return t('Incorrect URL format');
  }
};
