import { createContext, useContext } from 'react';

import type { useFormRoleInputValueReducer } from './reducer';

type ContextValue = ReturnType<typeof useFormRoleInputValueReducer>[1];

const formRoleDispatchContext = createContext<ContextValue>(() => {
  // 内容はProviderで与えられる
});

function useFormRoleDispatch() {
  return useContext(formRoleDispatchContext);
}

export { formRoleDispatchContext, useFormRoleDispatch };
