import { tableNodeTypes, selectedRect } from '@tiptap/pm/tables';
import { useState, useCallback } from 'react';

import type { Props } from './Table';
import type { EditorState } from 'prosemirror-state';

export const useTable = ({ editor }: Props) => {
  const [tableItemPosition, setTableItemPosition] = useState({
    row: 0,
    column: 0,
  });

  const onHoverTableItem = useCallback((row: number, column: number) => {
    setTableItemPosition({ row, column });
  }, []);

  const isActiveTable = editor.isActive('table');
  const isActiveTableHeader = editor.isActive('tableHeader');

  // editor.can().mergeCells()とeditor.can().splitCell()は有効時にfalseを返すため否定系にしている
  const isEnableMergeCell = !editor.can().mergeCells();
  const isEnableSplitCell = !editor.can().splitCell();

  // TipTapではrowとcolumnがheaderかどうかの判定はできないため独自実装
  const isEnableHeaderRow = editor
    .can()
    .chain()
    .focus()
    .command(({ state }) => {
      if (isActiveTable) {
        return isEnableHeaderByType('row', state);
      }

      return false;
    })
    .run();
  const isEnableHeaderColumn = editor
    .can()
    .chain()
    .focus()
    .command(({ state }) => {
      if (isActiveTable) {
        return isEnableHeaderByType('column', state);
      }

      return false;
    })
    .run();

  // tableの操作
  const insertTableHandler = useCallback(
    (rows: number, cols: number) => {
      editor
        .chain()
        .focus()
        .insertTable({ rows, cols, withHeaderRow: true })
        .run();
      editor.view.focus();

      // インサートしたタイミングで値を初期値に戻す
      setTableItemPosition({ row: 0, column: 0 });
    },
    [editor],
  );
  const deleteTableHandler = useCallback(() => {
    editor.chain().focus().deleteTable().run();
  }, [editor]);

  // columnの操作
  const addColumnBeforeHandler = useCallback(() => {
    editor.chain().focus().addColumnBefore().run();
  }, [editor]);
  const addColumnAfterHandler = useCallback(() => {
    editor.chain().focus().addColumnAfter().run();
  }, [editor]);
  const deleteColumnHandler = useCallback(() => {
    editor.chain().focus().deleteColumn().run();
  }, [editor]);

  // rowの操作
  const addRowAfterHandler = useCallback(() => {
    editor.chain().focus().addRowAfter().run();
  }, [editor]);
  const addRowBeforeHandler = useCallback(() => {
    editor.chain().focus().addRowBefore().run();
  }, [editor]);
  const deleteRowHandler = useCallback(() => {
    editor.chain().focus().deleteRow().run();
  }, [editor]);

  // cellの操作
  const mergeCellsHandler = useCallback(() => {
    editor.chain().focus().mergeCells().run();
  }, [editor]);
  const splitCellHandler = useCallback(() => {
    editor.chain().focus().splitCell().run();
  }, [editor]);

  // rowとcolumnのheaderの操作
  const toggleHeaderRowHandler = useCallback(() => {
    editor.chain().focus().toggleHeaderRow().run();
  }, [editor]);
  const toggleHeaderColumnHandler = useCallback(() => {
    editor.chain().focus().toggleHeaderColumn().run();
  }, [editor]);

  return {
    insertTableHandler,
    deleteTableHandler,
    addColumnBeforeHandler,
    addColumnAfterHandler,
    deleteColumnHandler,
    addRowAfterHandler,
    addRowBeforeHandler,
    deleteRowHandler,
    mergeCellsHandler,
    splitCellHandler,
    toggleHeaderRowHandler,
    toggleHeaderColumnHandler,
    tableItemPosition,
    onHoverTableItem,
    isActiveTable,
    isEnableHeaderRow,
    isEnableHeaderColumn,
    isEnableMergeCell,
    isEnableSplitCell,
    isActiveTableHeader,
  };
};

// テーブルのrowとcolumnがヘッダーかどうかを判定
// 参考:https://github.com/ProseMirror/prosemirror-tables/blob/master/src/commands.ts#L639
const isEnableHeaderByType = (type: 'row' | 'column', state: EditorState) => {
  const types = tableNodeTypes(state.schema);
  const rect = selectedRect(state);

  const cellPositions = rect.map.cellsInRect({
    left: 0,
    top: 0,
    right: type == 'row' ? rect.map.width : 1,
    bottom: type == 'column' ? rect.map.height : 1,
  });

  for (let i = 0; i < cellPositions.length; i++) {
    const cell = rect.table.nodeAt(cellPositions[i]);
    if (cell && cell.type !== types.header_cell) {
      return false;
    }
  }

  return true;
};
