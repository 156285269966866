import { NodeViewWrapper } from '@tiptap/react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { ImageSettings } from './ImageSettings';
import { useImageBlock } from './useImageBlock';
import { useKeyEventBlock } from '../../shared/useKeyEventBlock';

import type { ImageAttributes } from '.';
import type { NodeViewProps } from '@tiptap/react';

import styles from './imageblock.module.css';

import {
  SideModal,
  SideModalContent,
  SideModalTrigger,
} from '@/views/Common/Ui/SideModal';
import { NoPermission } from '@/views/InputField/shared/NoPermission';

export type ViewProps = {
  mediumAttributes: {
    src: string;
    width: number | undefined;
    height: number | undefined;
  } | null;
  isOpen: boolean;
  onToggleImageSettings: (nextState?: boolean) => void;
  imgAttributes: ImageAttributes;
  onUpdateImgAttributes: (imgAttributes: ImageAttributes) => void;
  isActive: boolean;
  editor: NodeViewProps['editor'];
  currentPosition: number;
};

export const ImageBlockView: React.FC<ViewProps> = ({
  mediumAttributes,
  isOpen,
  onToggleImageSettings,
  imgAttributes,
  onUpdateImgAttributes,
  isActive,
  editor,
  currentPosition,
}) => {
  const { t } = useTranslation('richEditorV2');
  const { alt, figcaption } = imgAttributes;
  const { blockRef, isCurrentActiveBlock } = useKeyEventBlock({
    editor,
    isActive,
    currentPosition,
    isOpen,
  });

  // 権限がない場合
  if (mediumAttributes === null) {
    return (
      <NodeViewWrapper
        className={cx(styles.imgBlock, 'richeditorv2-imageBlock', {
          [styles.isActive]: isCurrentActiveBlock,
        })}
      >
        <button
          type="button"
          className={styles.NoPermission}
          ref={blockRef}
          tabIndex={-1}
        >
          <NoPermission />
        </button>
      </NodeViewWrapper>
    );
  }

  const { src, width, height } = mediumAttributes;

  // mediumのwidthとheightが取れるのを待つ
  if (width === undefined || height === undefined) {
    return null;
  }

  return (
    <NodeViewWrapper
      className={cx(styles.imgBlock, 'richeditorv2-imageBlock', {
        [styles.isActive]: isCurrentActiveBlock,
      })}
    >
      <SideModal open={isOpen} onOpenChange={onToggleImageSettings}>
        <SideModalTrigger
          onClick={() => onToggleImageSettings()}
          aria-label={t('Edit image parameters')}
          tabIndex={-1}
          ref={blockRef}
        >
          <figure>
            <img
              src={src}
              alt={alt}
              className={styles.img}
              width={width}
              height={height}
            />
            {figcaption && (
              <figcaption className={styles.figcaption}>
                {figcaption}
              </figcaption>
            )}
          </figure>
        </SideModalTrigger>
        <SideModalContent
          size="small"
          title={t('Image Settings')}
          onEscapeKeyDown={(e) => e.stopPropagation()}
        >
          <ImageSettings
            src={src}
            width={width}
            height={height}
            imgAttributes={imgAttributes}
            onUpdateImgAttributes={onUpdateImgAttributes}
          />
        </SideModalContent>
      </SideModal>
    </NodeViewWrapper>
  );
};

export const ImageBlock: React.FC<NodeViewProps> = (props) => {
  const {
    medium,
    alt,
    customWidth,
    customHeight,
    link,
    figcaption,
    isTargetBlank,
  } = props.node.attrs as ImageAttributes;
  const {
    mediumAttributes,
    isOpen,
    onToggleImageSettings,
    onUpdateImgAttributes,
  } = useImageBlock(props.updateAttributes, medium);

  const isActive = props.editor.isActive('imageBlock', { medium });

  if (mediumAttributes === undefined) {
    return null;
  }

  return (
    <ImageBlockView
      mediumAttributes={mediumAttributes}
      isOpen={isOpen}
      onToggleImageSettings={onToggleImageSettings}
      imgAttributes={{
        medium,
        alt,
        customWidth,
        customHeight,
        link,
        figcaption,
        isTargetBlank,
      }}
      onUpdateImgAttributes={onUpdateImgAttributes}
      isActive={isActive}
      editor={props.editor}
      currentPosition={props.getPos()}
    />
  );
};
