import { useQuery } from '@tanstack/react-query';
import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { MICROCMS_API_KEY, MICROCMS_STG_API_KEY, isDevHost } from '../../util';

const isDev = isDevHost();
const endpoint = 'https://microcms.microcms.io/api/v1/news?limit=20';
const endpointStg =
  'https://microcms-u0euzgcmkwlxpzmoxgk2.microcms.io/api/v1/news?limit=20';

export const useAnnouncement = () => {
  const { t } = useTranslation('hooksAnnouncement');
  const getAnnouncementQuery = useQuery({
    queryKey: ['announcement'],
    queryFn: () => {
      return fetch(isDev ? endpointStg : endpoint, {
        headers: {
          'X-MICROCMS-API-KEY': isDev ? MICROCMS_STG_API_KEY : MICROCMS_API_KEY,
        },
      }).then((res) => {
        if (!res.ok) {
          throw new Error(t('Could not retrieve notification'));
        }
        return res.json();
      });
    },
    staleTime: Number.POSITIVE_INFINITY,
  });

  const { data: announcement, error: announcementError } = getAnnouncementQuery;

  const [isOpenAnnouncement, setIsOpenAnnouncement] = useState(false);
  const openAnnouncement = useCallback(() => {
    window.localStorage.setItem(
      'latestOpenAnnouncement',
      new Date().toISOString(),
    );
    setIsOpenAnnouncement(true);
  }, [setIsOpenAnnouncement]);
  const closeAnnouncement = useCallback(() => {
    setIsOpenAnnouncement(false);
  }, [setIsOpenAnnouncement]);

  // 最新のニュースがあるかどうか判定
  const hasLatestAnnouncement = useMemo(() => {
    const latest = window.localStorage.getItem('latestOpenAnnouncement');
    if (isOpenAnnouncement === true) {
      return false;
    }
    if (latest === null) {
      return true;
    }
    if (announcement?.contents?.[0]?.publishedAt > latest) {
      return true;
    }
    return false;
  }, [announcement, isOpenAnnouncement]);

  return {
    announcement,
    announcementError,
    hasLatestAnnouncement,
    isOpenAnnouncement,
    openAnnouncement,
    closeAnnouncement,
  };
};
