import type React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { usePermissionIsNot } from '@/hooks/Role/useMyRoles';
import { useGetMyService } from '@/hooks/useService';

import Media from '../../Media';
import Button from '../../ui/Button';

import type { Medium } from '@/entity/medium';

import styles from './filefield.module.css';

import { useMedium } from '@/views/Common/medium/useMediumReader';
import { Icon } from '@/views/Common/Ui/Icon';
import { Modal, ModalContent, ModalTrigger } from '@/views/Common/Ui/Modal';
import { useToggle } from '@/views/Common/Ui/useToggle';

interface Props {
  onChange: (medium: Medium) => void;
  onDelete: () => void;
  value?: Medium | null;
  readOnly?: boolean;
}

const Filefield: React.FC<Props> = ({
  onChange,
  onDelete,
  value,
  readOnly,
}) => {
  const { t } = useTranslation('filefield');
  const { service } = useGetMyService();

  // 権限を取得
  const [hasnotPermission] = usePermissionIsNot(
    'mediumPermission',
    'readLevel',
    'NONE',
  );

  // モーダル・ファイル選択
  const [isOpenFileModal, toggleFileModal] = useToggle(false);

  const openFile = () => {
    toggleFileModal(true);
  };

  const selectFile = useCallback(
    (medium: Medium) => () => {
      toggleFileModal(false);
      onChange(medium);
    },
    [toggleFileModal, onChange],
  );

  const { data: medium, isLoading } = useMedium({
    serviceId: service?.partitionKey,
    mediumId: value?.mediumId,
  });

  // valueがあるのに読み込み中の場合はローディングを表示
  if (isLoading && !!value) {
    return <p className={styles.loading}>{t('Loading...')}</p>;
  }

  const displayValue =
    medium !== undefined ? (
      medium !== null ? (
        medium.fileName
      ) : (
        <span className={styles.noPermission}>
          <Icon name="visibility_off" size="large" />
          <span>{t('Cannot display due to lack of permission.')}</span>
        </span>
      )
    ) : (
      t('Select')
    );

  return (
    <div className={styles.wrapper}>
      <Modal open={isOpenFileModal} onOpenChange={toggleFileModal}>
        <ModalTrigger asChild>
          <Button
            type="tertiary"
            value={displayValue}
            onClick={
              readOnly !== true && !hasnotPermission ? openFile : undefined
            }
          />
        </ModalTrigger>
        <ModalContent size="large" hasSpace={false}>
          <Media onClick={selectFile} onlyImage={false} inModal />
        </ModalContent>
      </Modal>
      {value && (
        <button
          className={styles.deleteButton}
          onClick={onDelete}
          aria-label={t('Delete')}
        >
          <Icon name="clear" size="large" />
        </button>
      )}
    </div>
  );
};

export default Filefield;
