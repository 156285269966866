import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  FloatingOverlay,
  FloatingPortal,
  FloatingFocusManager,
  useInteractions,
  useClick,
  useRole,
  useDismiss,
} from '@floating-ui/react';
import { posToDOMRect } from '@tiptap/core';
import { useLayoutEffect } from 'react';

import { LinkBlock } from './LinkBlock';
import { useFloatingMenu } from './useFloatingMenu';

import type { Editor } from '@tiptap/react';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  editor: Editor;
  isActiveLink: boolean;
  openTooltip: boolean;
  onToggleTooltip: () => void;
};

export const FloatingMenuView: React.FC<ViewProps> = ({
  editor,
  isActiveLink,
  openTooltip,
  onToggleTooltip,
}) => {
  // NOTE:以下の処理はfloatingUIでDOMを組み立てる設定なので、例外的にコンポーネントに直接書いています。
  const { x, y, strategy, refs, context } = useFloating({
    open: openTooltip,
    onOpenChange: onToggleTooltip,
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [
      offset({ mainAxis: 8 }),
      flip({
        fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
      }),
    ],
  });

  const { getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context),
  ]);

  useLayoutEffect(() => {
    if (!isActiveLink || !editor.view.state.selection) {
      return;
    }

    const { from, to } = editor.view.state.selection;
    refs.setReference({
      getBoundingClientRect() {
        return posToDOMRect(editor.view, from, to);
      },
    });
  }, [editor, isActiveLink, refs]);

  if (!openTooltip) {
    return null;
  }

  return (
    <FloatingPortal>
      <FloatingOverlay lockScroll />
      <FloatingFocusManager context={context}>
        <div
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            zIndex: 100,
          }}
          {...getFloatingProps}
        >
          {isActiveLink && (
            <LinkBlock editor={editor} onToggleTooltip={onToggleTooltip} />
          )}
        </div>
      </FloatingFocusManager>
    </FloatingPortal>
  );
};

export const FloatingMenu: React.FC<Props> = ({ editor }) => {
  const { openTooltip, onToggleTooltip } = useFloatingMenu({ editor });
  return (
    <FloatingMenuView
      editor={editor}
      isActiveLink={editor.isActive('link')}
      openTooltip={openTooltip}
      onToggleTooltip={onToggleTooltip}
    />
  );
};
