import { API, graphqlOperation } from 'aws-amplify';

import * as queries from '../../graphql/queries';

import type { Service } from '@/types/services';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

const getWebhookSettingsCount = async (
  serviceId: Service['partitionKey'],
  limit: number,
) => {
  try {
    const res = (await API.graphql(
      graphqlOperation(queries.getWebhookSettingsCount, {
        serviceId,
        limit,
      }),
    )) as GraphQLResult<{ getWebhookSettingsCount: number }>;

    if (!res.data) {
      throw new Error('Failed to fetch webhook settings count');
    }

    return res.data.getWebhookSettingsCount;
  } catch (e) {
    if (e instanceof Error) {
      throw e;
    }

    throw new Error('Unexpected error');
  }
};

export { getWebhookSettingsCount };
