import type { GraphQLResult } from '@aws-amplify/api-graphql';
import { API, graphqlOperation } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';

import type { MigrateApi } from '@/entity/api';
import type { CustomField } from '@/types/contents';
import * as queries from '../../graphql/queries';

export const useCustomFields = (
  apiId: MigrateApi['partitionKey'] | undefined,
) => {
  const [customFields, setCustomFields] = useState<
    Array<CustomField> | undefined
  >();

  const reload = useCallback(() => {
    setCustomFields(undefined);
    if (apiId) {
      (
        API.graphql(
          graphqlOperation(queries.findCustomFields, { apiId }),
        ) as Promise<GraphQLResult<{ findCustomFields: Array<CustomField> }>>
      ).then((result) => {
        setCustomFields(result.data?.findCustomFields || []);
      });
    }
  }, [apiId]);

  useEffect(() => {
    reload();
  }, [reload]);

  return [customFields, reload] as const;
};
