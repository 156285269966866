/**
 * プラン名
 */
const PlanName = {
  Hobby: 'Hobby',
  Team: 'Team',
  Business: 'Business',
  Advanced: 'Advanced',
  Enterprise: 'Enterprise',
  Template: 'Template',
} as const;

export { PlanName };
