import { useMemo, useCallback } from 'react';

import type { Props } from './TextAlign';

export type TextAlignType = 'right' | 'left' | 'center';

// textAlignTypeがstringで返ってしまうためReturnValueを定義
type ReturnValue = {
  toggleTextAlignHandler: (type: TextAlignType) => void;
  textAlignType: TextAlignType;
};

export const useTextAlign = ({ editor }: Props): ReturnValue => {
  const toggleTextAlignHandler = useCallback(
    (type: TextAlignType) => {
      editor.view.focus();
      editor.commands.setTextAlign(type);
    },
    [editor],
  );

  const isActiveTextAlignRight = editor.isActive({ textAlign: 'right' });
  const isActiveTextAlignCenter = editor.isActive({ textAlign: 'center' });

  const textAlignType = useMemo(() => {
    if (isActiveTextAlignRight) {
      return 'right';
    } else if (isActiveTextAlignCenter) {
      return 'center';
    }
    return 'left';
  }, [isActiveTextAlignRight, isActiveTextAlignCenter]);

  return {
    toggleTextAlignHandler,
    textAlignType,
  };
};
