export default class ColorPicker {
  /**
   * @param {Quill} quill
   * @param {Object} options
   */
  constructor(quill, options = {}) {
    this.quill = quill;
    this.options = options;

    this.quill.getModule('toolbar').addHandler('color', this.value.bind(this));
  }

  value(value) {
    const sid = this.options.sid;
    if (value === `custom-color-${sid}`) {
      const colorSelect = document.getElementById(`colorSelect-${sid}`);

      let picker = document.getElementById(`custom-color-${sid}`);
      if (!picker) {
        picker = document.createElement('input');
        picker.id = `custom-color-${sid}`;
        picker.type = 'color';
        picker.style.visibility = 'hidden';
        picker.value = '#FF0000';
        colorSelect.appendChild(picker);
        picker.addEventListener('input', () => {
          this.quill.format('color', picker.value);
        });
      }
      setTimeout(() => {
        picker.click();
      }, 100);
    } else {
      this.quill.format('color', value);
    }
  }
}
