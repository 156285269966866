import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useCode } from './useCode';
import { IconButton } from '../../../IconButton';
import styles from '../../../richeditorv2.module.css';

import type { Editor } from '@tiptap/react';

import { CodeIcon } from '@/views/Common/Icons';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  toggleCodeHandler: () => void;
  isActiveCode: boolean;
  editable: boolean;
};

export const CodeView: React.FC<ViewProps> = ({
  toggleCodeHandler,
  isActiveCode,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <IconButton
      icon={
        <CodeIcon
          className={cx(styles.icon, {
            [styles.isActive]: isActiveCode,
          })}
        />
      }
      aria-label={t('Code')}
      isActive={isActiveCode}
      onClick={toggleCodeHandler}
      disabled={!editable}
    />
  );
};

export const Code: React.FC<Props> = ({ editor }) => {
  const { toggleCodeHandler, isActiveCode } = useCode({ editor });

  return (
    <CodeView
      toggleCodeHandler={toggleCodeHandler}
      isActiveCode={isActiveCode}
      editable={editor.isEditable}
    />
  );
};
