import { Icon } from '../../Ui/Icon';
import { ComparisonField } from './ComparisonField';
import { useI18nContentsComparison } from './useI18nContentsComparison';

import type { MigrateApi } from '@/entity/api';
import type { CustomField } from '@/types/contents';
import type { FieldContentComparison } from './buildContentComparisonList';

import styles from './contentsComparisonViewer.module.css';

import { useFieldTypes } from '@/constants/useFieldTypes';

type Props = {
  apiField: MigrateApi['apiFields'][number];
  customFields: CustomField[];
  comparison: FieldContentComparison;
};

const ContentsComparisonViewer: React.FC<Props> = ({
  apiField,
  customFields,
  comparison,
}) => {
  const { t } = useI18nContentsComparison();

  const fieldTypes = useFieldTypes();
  const fieldType = fieldTypes[comparison.kind];

  return (
    <div>
      <div className={styles.header}>
        <span className={styles.headerIcon} title={fieldType.name}>
          <Icon name={fieldType.icon} size="large" />
          <span className="sr-only">{fieldType.name}</span>
        </span>
        <span className={styles.headerText}>{comparison.name}</span>
      </div>
      {!comparison.unauthorized ? (
        <ComparisonField
          apiField={apiField}
          customFields={customFields}
          kind={comparison.kind}
          sinceValue={comparison.sinceRaw}
          untilValue={comparison.untilRaw}
        />
      ) : (
        <div className={styles.unauthorized}>
          <Icon name="visibility_off" size="large" />
          <span>
            {comparison.kind === 'custom' || comparison.kind === 'repeater'
              ? t(
                  'Cannot display due to insufficient permissions on some fields',
                )
              : t('Cannot display due to lack of some permissions.')}
          </span>
        </div>
      )}
    </div>
  );
};

export { ContentsComparisonViewer };
