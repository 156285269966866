type Args = {
  rowCellCount: number;
};

const CELL_WIDTH = 246;

export const useTable = ({ rowCellCount }: Args) => {
  const width = rowCellCount * CELL_WIDTH;
  return { width };
};
