import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Tag } from '../ui/Tag';
import { useAllMembers } from './useAllMembers';
import { useMediaFilterList } from './useMediaFilterList';

import type { ListMediumTags } from '@/types/MediumTag';
import type { Filter } from '../Media/useMediaFilter';

import styles from './mediafilterlist.module.css';

import { formatDate } from '@/util/date';

type Props = {
  serviceId: string;
  defaultFiltersArray: Filter[];
  onSubmitFiltersArray: (defaultFiltersArray: Filter[]) => void;
  listMediumTags?: ListMediumTags;
};

export const MediaFilterList: React.FC<Props> = ({
  serviceId,
  defaultFiltersArray,
  onSubmitFiltersArray,
  listMediumTags,
}) => {
  const { t } = useTranslation('mediaFilterList');
  const { data: members } = useAllMembers(serviceId);

  const { mediaFilters, onDeleteFilter } = useMediaFilterList({
    defaultFiltersArray,
    onSubmitFiltersArray,
    members,
    listMediumTags,
  });

  return (
    <div className={styles.container}>
      {mediaFilters.map((mediaFilter) => (
        <Fragment key={mediaFilter.id}>
          {mediaFilter.key === 'createdBy' && (
            <Tag
              name={mediaFilter.value}
              icon="account_circle"
              onDelete={() => onDeleteFilter(mediaFilter.id)}
            />
          )}
          {mediaFilter.key === 'format' && (
            <Tag
              // @ts-expect-error
              name={t(mediaFilter.value)}
              icon="image"
              onDelete={() => onDeleteFilter(mediaFilter.id)}
            />
          )}
          {mediaFilter.key === 'createdAt' && (
            <Tag
              name={
                mediaFilter.condition === '[less_than]'
                  ? t('Before {{day}}', {
                      day: formatDate(mediaFilter.value),
                    })
                  : t('After {{day}}', {
                      day: formatDate(mediaFilter.value),
                    })
              }
              icon="date_range"
              onDelete={() => onDeleteFilter(mediaFilter.id)}
            />
          )}
          {mediaFilter.key === 'tags' && (
            <Tag
              name={mediaFilter.value}
              icon="sell"
              onDelete={() => onDeleteFilter(mediaFilter.id)}
            />
          )}
        </Fragment>
      ))}
    </div>
  );
};
