import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useListMediumTags } from '@/hooks/MediumTags/useMediumTags';
import { useDebounce } from '@/hooks/useDebounce';
import { useGetMyService } from '@/hooks/useService';

import Head from '../../Head';
import Searchfield from '../../ui/Searchfield';
import MediumTags from './MediumTags';

import { Upsell } from '@/views/Common/plan/Upsell';
import styles from './tagsSettings.module.css';

const TagsSettings: React.FC = () => {
  const { t } = useTranslation('mediaTagsSettings');
  const { service } = useGetMyService();
  const [inputSearchText, setSearchText] = useState('');
  const [searchText] = useDebounce(inputSearchText, 300); //実際に検索を行うのは少し待ってからにする

  const { listMediumTags, listhMediumTagsLoading } = useListMediumTags(
    service?.partitionKey || '',
    searchText,
  );

  const displayListMediumTags = useMemo(() => {
    // 検索にマッチしない場合はnullを返す
    if (listMediumTags?.tags?.length === 0 && searchText !== '') {
      return { tags: null };
    }
    return listMediumTags;
  }, [listMediumTags, searchText]);

  if (!service) return null;

  return (
    <Upsell restrictionKey="mediumTags">
      <div className={styles.wrapper}>
        <Head title={t('Manage Tags')} />
        <div className={styles.flex}>
          <h2 className={styles.pageTitle}>{t('Manage Tags')}</h2>
          <p className={styles.description}>
            {t('Edit or delete tags added with the media page.')}
          </p>
          <Searchfield
            className={styles.search}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <MediumTags
            serviceId={service?.partitionKey}
            listMediumTags={displayListMediumTags}
            isLoading={listhMediumTagsLoading}
          />
        </div>
      </div>
    </Upsell>
  );
};

export default TagsSettings;
