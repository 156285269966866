import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './planCard.module.css';

type Plan =
  | 'Hobby'
  | 'Team'
  | 'Business'
  | 'Advanced'
  | 'Enterprise'
  | 'Standard';

type Props = {
  name: string;
  description: string;
  minimumMonthPrice: number | null;
  minimumMonthTaxIncludedPrice: number | null;
  trialLength?: string;
  onChangePlan: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultPlan: Plan;
  currentPlan?: string;
};

const PlanCard: React.FC<Props> = ({
  name,
  minimumMonthPrice,
  trialLength,
  onChangePlan,
  defaultPlan,
  currentPlan,
}) => {
  const { t } = useTranslation('planCard');
  return (
    <label
      className={styles.clickable}
      data-testid={`plan-changes-to-${name.toLowerCase()}`}
    >
      <input
        className={styles.radio}
        type="radio"
        name="plan"
        value={name}
        onChange={onChangePlan}
        defaultChecked={defaultPlan === name}
      />
      {minimumMonthPrice === null ? (
        <div className={styles.card}>
          <p className={styles.name}>{name}</p>
          <div className={styles.estimation}>{t('Request a quote')}</div>
        </div>
      ) : (
        <div className={styles.card}>
          <div
            className={cx(styles.plan, {
              [styles.isAdvanced]: name === 'Advanced',
            })}
          >
            <p className={styles.name}>{name}</p>
            {currentPlan === name && (
              <p className={styles.currentPlan}>{t('Using')}</p>
            )}
          </div>
          <div className={styles.price}>
            <p>
              <span className={styles.number}>
                {/* NOTE: 表示価格は税抜き表記と統一されている */}
                {minimumMonthPrice.toLocaleString()}
              </span>{' '}
              {t('yen ~ / month')}
            </p>
            {trialLength && (
              <p className={styles.benefit}>
                {t('{{trialLength}} free trial', { trialLength })}
              </p>
            )}
          </div>
        </div>
      )}
    </label>
  );
};

export default PlanCard;
