import { useTranslation } from 'react-i18next';

import { IconButton } from '../../IconButton';

import type { Editor } from '@tiptap/react';

import style from './fullscreen.module.css';

import { CloseFullScreenIcon, FullScreenIcon } from '@/views/Common/Icons';

export type Props = {
  editor: Editor;
  isFullScreen: boolean;
  handleFullScreen: () => void;
  handleCloseFullScreen: () => void;
};

export type ViewProps = {
  isFullScreen: boolean;
  handleFullScreen: () => void;
  handleCloseFullScreen: () => void;
  editable: boolean;
};

export const FullScreenView: React.FC<ViewProps> = ({
  isFullScreen,
  handleFullScreen,
  handleCloseFullScreen,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');

  if (isFullScreen) {
    return (
      <IconButton
        icon={<CloseFullScreenIcon className={style.icon} />}
        aria-label={t('Release')}
        isActive={false}
        onClick={handleCloseFullScreen}
        disabled={!editable}
      />
    );
  } else {
    return (
      <IconButton
        icon={<FullScreenIcon className={style.icon} />}
        aria-label={t('Maximize')}
        isActive={false}
        onClick={handleFullScreen}
        disabled={!editable}
      />
    );
  }
};

export const FullScreen: React.FC<Props> = ({
  editor,
  isFullScreen,
  handleFullScreen,
  handleCloseFullScreen,
}) => {
  return (
    <FullScreenView
      isFullScreen={isFullScreen}
      handleFullScreen={handleFullScreen}
      handleCloseFullScreen={handleCloseFullScreen}
      editable={editor.isEditable}
    />
  );
};
