import { API, graphqlOperation } from 'aws-amplify';

import * as queries from '../../../graphql/queries';

export default class Oembed {
  /**
   * @param {Quill} quill
   * @param {Object} options
   */
  constructor(quill, options = {}) {
    this.quill = quill;
    this.options = options;

    this.quill
      .getModule('toolbar')
      .addHandler('oembedly', this.openModal.bind(this));
  }

  /**
   * モーダルを開く
   */
  openModal() {
    this.options.openEmbed();
  }

  /**
   * @param {String} value embed用url
   */
  insert(value, selection = {}) {
    const index =
      selection.index === 0 ? 0 : selection.index || this.quill.getLength();

    API.graphql(
      graphqlOperation(queries.getOembedlyResult, {
        url: value,
      }),
    )
      .then((res) => JSON.parse(res.data.getOembedlyResult))
      .then((res) => {
        const newValue = {
          url: value,
          html: res.html,
        };
        return this.quill.insertEmbed(index, 'oembedly', newValue, 'user');
      })
      .catch((e) => {
        const newValue = {};
        return this.quill.insertEmbed(index, 'oembedly', newValue, 'user');
      });
  }
}
