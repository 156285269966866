import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import mime from 'mime-types';
import { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { v4 as uuid } from 'uuid';

import { updateMedium, uploadMedium } from '@/usecase/mediumUsecase';

import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

const useMediumWriter = (service) => {
  const { t } = useTranslation('hooksMedium');
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const inputFileEl = useRef(null);

  const [progress, setProgress] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const upload = useCallback(
    async (files) => {
      const { domain, partitionKey } = service;
      const total = files.length;
      setLoading(true);
      setProgress({ loaded: 0, total });

      //全ファイルをアップロード
      try {
        const resultMediumIds = await uploadMedium({
          serviceId: partitionKey,
          domain,
          files,
          onSuccessEach: () => {
            setProgress((prev) => ({ ...prev, loaded: prev.loaded + 1 }));
          },
        });

        //後処理
        setTimeout(() => {
          // 完了した瞬間を表示するためprogressをundefinedにするのは200ms後にする
          setProgress(undefined);
        }, 200);
        setLoading(false);
        cache.invalidateQueries(['mediumCount'], { type: 'all' });
        cache.invalidateQueries(['media'], { type: 'all' });
        if (inputFileEl.current) {
          inputFileEl.current.value = ''; // 同じファイルを2回連続選択してもonChangeが効くようにする
        }
        return resultMediumIds;
      } catch ({ errors }) {
        addToast(errors ? errors[0].message : t('Could not upload media.'), {
          appearance: 'error',
        });
      }
    },
    [addToast, cache, service, t],
  );

  const update = useCallback(
    async (medium, file, setMedium) => {
      await Promise.resolve()
        .then(() => {
          //S3にアップロード
          const contentType = mime.lookup(file.name);
          return Storage.put(`${uuid()}/${file.name}`, file, {
            contentType,
            progressCallback(progress) {
              setProgress(progress);
            },
          });
        })
        .then(({ key }) => {
          return updateMedium({
            serviceId: service.partitionKey,
            mediumId: medium.mediumId,
            filePath: key,
          });
        })
        .then(() => {
          //データ取得
          return API.graphql(
            graphqlOperation(queries.findMedium, {
              serviceId: service.partitionKey,
              mediumId: medium.mediumId,
            }),
          ).then((result) => {
            if (result.data.findMedium) {
              setMedium(result.data.findMedium);
            }
          });
        })
        .catch((error) => {
          addToast(error.message || t('Could not update media.'), {
            appearance: 'error',
          });
        });

      // 後処理
      setProgress(undefined);
      cache.invalidateQueries(['media'], { type: 'all' });
      cache.invalidateQueries(['medium', { mediumId: medium.mediumId }], {
        type: 'all',
      });
      cache.invalidateQueries(
        ['mediumAttributes', { mediumId: medium.mediumId }],
        {
          type: 'all',
        },
      );
      if (inputFileEl.current) {
        inputFileEl.current.value = ''; // 同じファイルを2回連続選択してもonChangeが効くようにする
      }
    },
    [addToast, cache, service.partitionKey, t],
  );

  const { mutate: deleteMedium, isLoading: deleteMediumLoading } = useMutation({
    mutationFn: ({ medium }) => {
      if (service) {
        return API.graphql(
          graphqlOperation(queries.deleteMedium, {
            serviceId: medium.serviceId,
            mediumId: medium.mediumId,
          }),
        ).then((result) => result.data.deleteMedium);
      }
      return;
    },
    onSuccess(data, { setMedium, medium, onlyImage, text, filters }) {
      if (data.result === false) {
        addToast(data.message, { appearance: 'error' });
        return;
      }

      cache.invalidateQueries(['mediumCount'], { type: 'all' });
      // レスポンスからキャッシュを更新
      cache.setQueryData(['media', { onlyImage, text, filters }], (old) => {
        return {
          pageParams: old?.pageParams,
          pages: old?.pages?.map((page) => {
            return {
              ...page,
              items: page.items.filter(
                (item) => item.mediumId !== medium.mediumId,
              ),
            };
          }),
        };
      });
      setMedium(undefined);
      addToast(t('Media has been removed.'), { appearance: 'success' });
    },
    onError({ errors }) {
      addToast(errors ? errors[0].message : t('Could not delete media.'), {
        appearance: 'error',
      });
    },
  });

  const { mutate: updateMediumName, isLoading: updateMediumNameLoading } =
    useMutation({
      mutationFn: async ({ medium, fileName }) => {
        if (medium) {
          return await API.graphql(
            graphqlOperation(mutations.updateMediumName, {
              serviceId: medium.serviceId,
              mediumId: medium.mediumId,
              fileName,
            }),
          ).then((result) => ({
            ...result.data.updateMediumName,
            mediumId: medium.mediumId,
          }));
        }
        return;
      },
      onSuccess(data) {
        const { result, message, mediumId } = data;
        if (result === false) {
          addToast(message, {
            appearance: 'error',
          });
          return;
        }
        cache.invalidateQueries(['media'], { type: 'all' });
        cache.invalidateQueries(['medium', { mediumId }], {
          type: 'all',
        });
        addToast(t('The file name of the media has been changed.'), {
          appearance: 'success',
        });
      },
      onError({ errors }) {
        addToast(errors ? errors[0].message : t('Could not rename the file.'), {
          appearance: 'error',
        });
      },
    });

  return {
    upload,
    update,
    deleteMedium,
    deleteMediumLoading,
    updateMediumName,
    updateMediumNameLoading,
    progress,
    loading,
    inputFileEl,
  };
};

export default useMediumWriter;
