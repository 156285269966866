/* tslint:disable */
/* eslint-disable */
/**
 * マネジメントAPI
 * マネジメントAPI
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ContentReservationTime
 */
export interface ContentReservationTime {
  /**
   *
   * @type {Date}
   * @memberof ContentReservationTime
   */
  publishTime: Date | null;
  /**
   *
   * @type {Date}
   * @memberof ContentReservationTime
   */
  stopTime: Date | null;
}

export function ContentReservationTimeFromJSON(
  json: any,
): ContentReservationTime {
  return ContentReservationTimeFromJSONTyped(json, false);
}

export function ContentReservationTimeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ContentReservationTime {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    publishTime:
      json['publishTime'] === null ? null : new Date(json['publishTime']),
    stopTime: json['stopTime'] === null ? null : new Date(json['stopTime']),
  };
}

export function ContentReservationTimeToJSON(
  value?: ContentReservationTime | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    publishTime:
      value.publishTime === null ? null : value.publishTime.toISOString(),
    stopTime: value.stopTime === null ? null : value.stopTime.toISOString(),
  };
}
