import { createActions } from 'redux-actions';

const actions = createActions({
  ON_SIGNIN: (cognitoUser) => ({ cognitoUser }),
  ON_SIGNOUT: undefined,
  GET_ATTRIBUTES: (attrs) => ({ attrs }),
  GET_COGNITO_USER: (cognitoUser) => ({ cognitoUser }),
});

export default actions;
