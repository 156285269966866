import { useCopy } from '../../../hooks/Copy/useCopy';
import IconButton from '../IconButton';

type Props = {
  value: string;
  className?: string;
};

const CopyButton: React.FC<Props> = ({ value, className }) => {
  const [copied, copyAction, setCopied] = useCopy(value);

  const copyKey = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    copyAction();

    const timerId = setTimeout(() => {
      setCopied(false);
    }, 3000);

    return () => clearTimeout(timerId);
  };

  return (
    <IconButton
      icon={copied ? 'check' : 'content_copy'}
      hasFrame={true}
      onClick={copyKey}
      className={className}
    />
  );
};

export default CopyButton;
