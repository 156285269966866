import { isBoolean } from './type-guard';

import type { MigrateApi } from '@/entity/api';
import type {
  EditableInputExceptionContentPermission,
  InputExceptionContentPermission,
  MemberReadPermissionDetail,
  Role,
  RoleOrderKey,
  RoleOrderQuery,
} from '@/entity/role';

/**
 * Roleの名前フィールドをキーワードで絞り込み、結果を返す
 */
function searchRolesByName<T extends Pick<Role, 'name'>>(
  roles: Array<T>,
  inputName: string,
): Array<T> {
  return roles.filter((r) => r.name && r.name.indexOf(inputName) > -1);
}

/**
 * Role群を指定の順序でソートし、結果を返す
 */
function sortRoles<T extends Pick<Role, RoleOrderKey>>(
  roles?: Array<T>,
  order?: RoleOrderQuery,
): Array<T> | undefined {
  if (!order) {
    return roles;
  }

  return roles?.sort((a, b) => {
    if (order === 'createdAt') {
      return a.createdAt.localeCompare(b.createdAt);
    } else if (order === '-createdAt') {
      return b.createdAt.localeCompare(a.createdAt);
    } else if (order === 'updatedAt') {
      return (a.updatedAt || '').localeCompare(b.updatedAt || '');
    } else if (order === '-updatedAt') {
      return (b.updatedAt || '').localeCompare(a.updatedAt || '');
    } else if (order === 'name') {
      return (a.name || '').localeCompare(b.name || '');
    } else if (order === '-name') {
      return (b.name || '').localeCompare(a.name || '');
    }
    return 0;
  });
}

function hasGrantedViewExceptionContentPermission(
  likeECP: InputExceptionContentPermission | undefined,
): likeECP is EditableInputExceptionContentPermission {
  if (!likeECP) {
    return false;
  }
  return Boolean(likeECP.permission && likeECP.reviewRequestPermission);
}

/**
 * RoleエンティティのIDの形式（ROLE#を先頭にとる）に統一して返却する
 *
 * @example
 * uniformRoleId('02e46e7f-63a9-4f53-ab26-f4584387ebe9') // -> ROLE#02e46e7f-63a9-4f53-ab26-f4584387ebe9
 * uniformRoleId('ROLE#02e46e7f-63a9-4f53-ab26-f4584387ebe9') // -> ROLE#02e46e7f-63a9-4f53-ab26-f4584387ebe9
 */
function uniformRoleId(likeRoleId: Role['roleId']): Role['roleId'] {
  return likeRoleId.startsWith('ROLE#') ? likeRoleId : `ROLE#${likeRoleId}`;
}

/**
 * MemberReadPermissionDetailの型を判定する関数
 */
function isMemberReadPermissionDetailObject(
  value: unknown,
): value is MemberReadPermissionDetail {
  if (
    typeof value !== 'object' ||
    value === undefined ||
    value === null ||
    Array.isArray(value)
  ) {
    return false;
  }
  return 'defaultRead' in value && isBoolean(value.defaultRead);
}

/**
 * 対象のAPIに関する個別権限を取得する
 *
 * 対象のAPIに関する個別権限が存在しない場合、APIのIDが指定されていない場合はundefinedを返す
 */
function getExceptionContentPermissionByApiId<
  T extends Pick<Role, 'exceptionContentPermissions'>,
>(role: T, apiId: MigrateApi['partitionKey'] | undefined) {
  if (!role.exceptionContentPermissions || !apiId) {
    return undefined;
  }
  return role.exceptionContentPermissions?.find((ecp) => ecp.apiId === apiId);
}

export {
  searchRolesByName,
  sortRoles,
  hasGrantedViewExceptionContentPermission,
  uniformRoleId,
  isMemberReadPermissionDetailObject,
  getExceptionContentPermissionByApiId,
};
