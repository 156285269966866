import { useTranslation } from 'react-i18next';

import styles from './reviewStatus.module.css';

type Props = {
  status: 'OPEN' | 'CLOSE';
};

const ReviewStatus: React.FC<Props> = ({ status = 'OPEN' }) => {
  const { t } = useTranslation('reviewStatus');
  if (status === 'CLOSE') {
    return (
      <span className={`${styles.status} ${styles.close}`}>
        {t('Unpublished')}
      </span>
    );
  }
  return (
    <span className={`${styles.status} ${styles.open}`}>{t('In Review')}</span>
  );
};

export default ReviewStatus;
