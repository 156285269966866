import { useId, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReservationDatePicker } from '@/components/Form';
import Button from '@/components/ui/Button';
import Modal from '@/components/ui/Modal';

import { useReservationModal } from './useReservationModal';

import type { MigrateApi } from '@/entity/api';
import type { ContentStatus } from '@/entity/content';

import style from './reservationmodal.module.css';

import { useHasUpdateContentPermission } from '@/views/Common/Roles/MyRolesPermission';
import { Icon } from '@/views/Common/Ui/Icon';

export type Props = {
  contentStatus: ContentStatus;
  apiId: MigrateApi['partitionKey'];
  startDate?: Date;
  stopDate?: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setStopDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setReservation: () => void;
  setReservationLoading: boolean;
};

export type ViewProps = {
  startDate?: Date;
  stopDate?: Date;
  setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setStopDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setReservation: () => void;
  setReservationLoading: boolean;
  date: Date;
  currentHours: number;
  currentMinutes: number;
  canReservationTime: boolean;
  canReservationStopTime: boolean;
  disabledReservationTime: boolean;
  disabledReservationStopTime: boolean;
  permission: {
    hasSchedulePublishing: boolean;
    hasScheduleUnpublishing: boolean;
  };
};

export const ReservationModalView: React.FC<ViewProps> = ({
  startDate,
  stopDate,
  setStartDate,
  setStopDate,
  setReservation,
  setReservationLoading,
  date,
  currentHours,
  currentMinutes,
  canReservationTime,
  canReservationStopTime,
  disabledReservationTime,
  disabledReservationStopTime,
  permission,
}) => {
  const { t } = useTranslation('reservationModal');

  const publishingTitleId = useId();
  const unpublishingTitleId = useId();

  if (
    !permission.hasSchedulePublishing &&
    !permission.hasScheduleUnpublishing
  ) {
    /**
     * 公開開始の予約権限、公開停止の予約権限の両方がない場合はモーダルを表示しない
     *
     * NOTE: ReservationTriggerコンポーネントの予約可能かの判定フラグがあるので、
     * そこでモーダルを開くトリガーが無効化される点に留意
     *
     * @see web/src/views/ContentActions/ReservationTrigger/ReservationTrigger.tsx
     */
    return null;
  }

  return (
    <Modal>
      <p className={style.modalTitle}>
        <Icon name="play_circle" outlined />
        <span id={publishingTitleId}>
          {t('Scheduled Publishing Date / Time')}
        </span>
      </p>
      <p className={style.modalDescription}>
        {t('The content in Draft will become Published at the specified time')}
      </p>
      <ReservationDatePicker
        date={date}
        inputDate={startDate}
        setInputDate={setStartDate}
        currentHours={currentHours}
        currentMinutes={currentMinutes}
        disabled={disabledReservationTime}
        readonlyMode={!permission.hasSchedulePublishing}
        errorText={
          !permission.hasSchedulePublishing
            ? t('You are not authorized to set the reservation time')
            : canReservationTime
              ? t('This must be prior to the unpublishing date / time')
              : t('Unpublishing date / time must be set')
        }
        aria-labelledby={publishingTitleId}
      />

      <p className={style.modalTitle}>
        <Icon name="stop_circle" outlined />
        <span id={unpublishingTitleId}>
          {t('Scheduled Unpublishing Date / Time')}
        </span>
      </p>
      <p className={style.modalDescription}>
        {t(
          'The Published content will become Unpublished at the scheduled time.',
        )}
        <br />
        {t(
          'Note that Draft data is lost if content is `published and draft` status.',
        )}
      </p>
      <ReservationDatePicker
        date={date}
        inputDate={stopDate}
        setInputDate={setStopDate}
        currentHours={currentHours}
        currentMinutes={currentMinutes}
        disabled={disabledReservationStopTime}
        readonlyMode={!permission.hasScheduleUnpublishing}
        errorText={
          !permission.hasScheduleUnpublishing
            ? t('You are not authorized to set the reservation time')
            : canReservationStopTime
              ? t('This must be after the publishing time')
              : t('Publishing time must be set')
        }
        aria-labelledby={unpublishingTitleId}
      />

      <Button
        onClick={setReservation}
        size="small"
        disabled={setReservationLoading}
        value={t('Submit')}
        className={style.modalButton}
      />
    </Modal>
  );
};

export const ReservationModal: React.FC<Props> = ({
  contentStatus,
  apiId,
  startDate,
  stopDate,
  setStartDate,
  setStopDate,
  setReservation,
  setReservationLoading,
}) => {
  const date = new Date();
  const currentHours = date.getHours();
  const currentMinutes = date.getMinutes();
  const {
    canReservationTime,
    canReservationStopTime,
    disabledReservationTime,
    disabledReservationStopTime,
  } = useReservationModal({ contentStatus, startDate, stopDate });

  const { hasUpdateContentPermission } = useHasUpdateContentPermission();
  const permission = useMemo(() => {
    return {
      // 公開予約ができる権限を持つ場合はtrue
      hasSchedulePublishing: hasUpdateContentPermission('publish', apiId),
      // 公開終了予約ができる権限を持つ場合はtrue
      hasScheduleUnpublishing: hasUpdateContentPermission('unpublish', apiId),
    };
  }, [apiId, hasUpdateContentPermission]);

  return (
    <ReservationModalView
      startDate={startDate}
      stopDate={stopDate}
      setStartDate={setStartDate}
      setStopDate={setStopDate}
      setReservation={setReservation}
      setReservationLoading={setReservationLoading}
      date={date}
      currentHours={currentHours}
      currentMinutes={currentMinutes}
      canReservationTime={canReservationTime}
      canReservationStopTime={canReservationStopTime}
      disabledReservationTime={disabledReservationTime}
      disabledReservationStopTime={disabledReservationStopTime}
      permission={permission}
    />
  );
};
