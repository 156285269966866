import tinycolor from 'tinycolor2';
import type { Color } from 'react-color';

/**
 * カラーコードをHEX形式に変換する
 * @returns string ex: #00000000
 */
const colorToHexString = (color: Color) => {
  return tinycolor(color).toHex8String();
};

/**
 * カラーコードをRGB形式に変換する
 * @returns RGBColor ex: { r: 0, g: 0, b: 0, a: 1 }
 */
const colorToRgb = (color: Color) => {
  return tinycolor(color).toRgb();
};

/**
 * カラーコードをRGB形式の文字列に変換する
 * @returns RGBColor ex: 'rgba(0, 0, 0, 1)'
 */
const colorToRgbString = (color: Color) => {
  return tinycolor(color).toRgbString();
};

export { colorToHexString, colorToRgb, colorToRgbString };
