import { createContext, useContext, useState } from 'react';

import {
  calcContentValueSize,
  getCapacityPercentage,
} from './useSetContentCapacity';

import type { ContentValue } from '@/entity/content';

const contentCapacityReadContext = createContext<number | null>(null);
const contentCapacityWriteContext = createContext<
  React.Dispatch<React.SetStateAction<number | null>>
>(() => null);

type Props = {
  children: React.ReactNode;
  defaultValue: ContentValue;
};

const ContentCapacityProvider: React.FC<Props> = ({
  children,
  defaultValue,
}) => {
  const [contentCapacity, setContentCapacity] = useState<number | null>(() =>
    getCapacityPercentage(calcContentValueSize(defaultValue)),
  );

  return (
    <contentCapacityReadContext.Provider value={contentCapacity}>
      <contentCapacityWriteContext.Provider value={setContentCapacity}>
        {children}
      </contentCapacityWriteContext.Provider>
    </contentCapacityReadContext.Provider>
  );
};

const useContentCapacityRead = () => {
  const context = contentCapacityReadContext;
  if (context === undefined) {
    throw new Error(
      'useContentCapacityRead must be used within a ContentCapacityProvider',
    );
  }
  return useContext(context);
};

const useContentCapacityWrite = () => {
  const context = contentCapacityWriteContext;
  if (context === undefined) {
    throw new Error(
      'useContentCapacityWrite must be used within a ContentCapacityProvider',
    );
  }
  return useContext(context);
};

export {
  ContentCapacityProvider,
  useContentCapacityRead,
  useContentCapacityWrite,
};
