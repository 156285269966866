import dayjs from 'dayjs';
import type React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';

import Head from '@/components/Head';
import MemberListTable from '@/components/MemberListTable';
import S3Image from '@/components/S3Image';
import Signup from '@/components/Signup';
import Button from '@/components/ui/Button';
import Searchfield from '@/components/ui/Searchfield';
import { Table, Tbody, Td, Th, Thead, Tr } from '@/components/ui/Table';

import { useIsInvitedFromServices } from '@/hooks/Auth/useAuth';
import { useGetMyServices } from '@/hooks/ServiceList/useServiceList';

import styles from './root.module.css';

import { localStorage } from '@/constants/localStorage';
import { MetaContext } from '@/context/MetaContext';
import { apiListSelectors } from '@/ducks/apiList';
import type { ApiList, ApiListByRedux } from '@/entity/api';
import { useAppSelector } from '@/store/hooks';
import { getHost, getServiceDomain } from '@/util';
import { isSAMLUser } from '@/util';
import { ServiceAvatar } from '@/views/Common/Ui/ServiceAvatar';

type Props = {
  isAuthorized: boolean;
};

const Root: React.FC<Props> = ({ isAuthorized }) => {
  const { t } = useTranslation('root');
  const serviceDomain = getServiceDomain();

  const { services, servicesLoading, filteredServices, searchServices } =
    useGetMyServices();
  const { isInvited, isInvitedLoading } =
    useIsInvitedFromServices(isAuthorized);

  // TODO: ReduxをReactQueryに置き換える
  const apis = useAppSelector(
    (state) =>
      apiListSelectors.get(state.apiListState as ApiList) as ApiListByRedux,
  );

  const displayServices = useMemo(() => {
    if (!services) {
      return [];
    }
    return filteredServices || services.list;
  }, [filteredServices, services]);

  const allowMultiService = !isSAMLUser() && !MetaContext.value.isEnterprise;

  if (isAuthorized === false && serviceDomain !== '') {
    return <Redirect to="/signin" />;
  }

  if (isAuthorized === false) {
    return <Signup />;
  }

  if (services === undefined) {
    return null;
  }

  // サービスが取得できるまでは待機
  if (services.list === undefined) {
    return null;
  }

  // 招待状況の確認中は待機
  if (isInvitedLoading === true) {
    return null;
  }

  // サービスが1つもなかったらwelcomeかprofileに飛ばす
  // （ユーザー招待を受けていた場合プロフィール画面に遷移する）
  if (services.list.length === 0 && isInvitedLoading === false) {
    const nextPath = isInvited ? '/profile' : '/welcome';
    return <Redirect to={nextPath} />;
  }

  // 存在するserviceDomainをチェックする;
  if (isAuthorized && servicesLoading) {
    return null;
  }

  // サービスが存在しているかつサブドメインの指定がなかったらサービスを一覧表示
  if (serviceDomain === '') {
    return (
      <div>
        <Head title={t('Services')} />
        <header className={styles.header}>
          <h1 className={styles.title} data-cy="service-title">
            {t('Manage Services')}
          </h1>
          <div className={styles.headerActions}>
            <Searchfield
              onChange={(e) => searchServices(e.target.value)}
              className={styles.searchfield}
              labelText={t('Search for services')}
            />
            {allowMultiService && (
              <Link to="/create-service">
                <Button type="secondary" value={t('Add')} icon="add" />
              </Link>
            )}
          </div>
        </header>

        <div className={styles.container}>
          <div className={styles.tableWrapper}>
            <Table>
              <Thead>
                <Tr>
                  <Th>{t('Service Name')}</Th>
                  <Th>{t('Service ID')}</Th>
                  <Th>{t('Members')}</Th>
                  <Th>{t('Date Created')}</Th>
                  <Th>{t('Status')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {displayServices
                  .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
                  .filter((service) => service.environment === null)
                  .map((service) => (
                    <Tr
                      key={service.domain}
                      onClick={() =>
                        (window.location.href = getHost(service.domain))
                      }
                    >
                      <Td>
                        <a
                          href={getHost(service.domain)}
                          className={styles.serviceIconWrapper}
                        >
                          {service.serviceImage !== null ? (
                            <S3Image
                              directory={`${
                                service.serviceImage.level || 'protected'
                              }/${service.serviceImage.identityId}/${
                                service.serviceImage.key
                              }`.replace(
                                `/${service.serviceImage.key.replace(
                                  /.*\//,
                                  '',
                                )}`,
                                '',
                              )}
                              fileName={service.serviceImage.key.replace(
                                /.*\//,
                                '',
                              )}
                              kind={'IMAGE'}
                              queryString="?fit=crop&w=32&h=32"
                              className={styles.serviceImage}
                            />
                          ) : (
                            <div className={styles.identiconWrapper}>
                              <ServiceAvatar
                                string={service.domain}
                                size={32}
                              />
                            </div>
                          )}
                          <p className={styles.serviceName}>
                            {service.serviceName}
                          </p>
                        </a>
                      </Td>
                      <Td>{service.domain}</Td>
                      <Td>
                        <MemberListTable service={service} />
                      </Td>
                      <Td>{dayjs(service.createdAt).fromNow()}</Td>
                      <Td>
                        {service.suspended === true ? (
                          <p className={styles.suspended}>{t('Suspended')}</p>
                        ) : (
                          <p>{t('Active')}</p>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            {displayServices.length === 0 && (
              <div className={styles.empty}>{t('No matching services')}</div>
            )}
          </div>
        </div>
      </div>
    );
  }

  // APIが取得できるまでは待機
  if (apis.apiList === undefined) {
    return null;
  }

  // APIが1つもなかったらAPI作成画面に飛ばす
  if (apis.apiList.length === 0) {
    return <Redirect to="/create-api" />;
  }

  const apiPath = window.localStorage.getItem(localStorage.apiPath);

  // ローカルストレージにAPIアクセス履歴がある場合リダイレクトする
  if (apiPath !== null) {
    return <Redirect to={apiPath} />;
  }
  // APIがある場合は一番上のAPIを選択状態にする
  return <Redirect to={`/apis/${apis.apiList[0].apiEndpoint}`} />;
};

export default Root;
