import { useState, useCallback } from 'react';

type UseInput = (
  initialValue: string,
  validateFunc: (value: string) => string | null,
  allowEmpty?: boolean,
) => [
  string,
  (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  string | null,
  (v: string) => void,
];

function isEmpty(value?: unknown) {
  if (value === undefined || value === null) {
    return true;
  }

  // https://github.com/ianstormtaylor/is-empty/blob/master/lib/index.js#L36
  if (typeof value === 'string') {
    return value.length === 0;
  }

  if (typeof value === 'function') {
    return value.length === 0;
  }

  // Anything else...(型をつけているため来ないはず)
  return false;
}

type SetValidationFunc = (v: string) => string;
export const useInput: UseInput = (
  initialValue,
  validateFunc,
  allowEmpty = false,
) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState<string | null>(null);

  const setValueWithValidation = useCallback(
    (v: string | SetValidationFunc) => {
      if (typeof v === 'string') {
        setValue(v);
        setError(allowEmpty && isEmpty(v) ? null : validateFunc(v));
        return;
      }
      if (typeof v === 'function') {
        setValue((prev) => {
          const nextValue = v(prev);
          setError(
            allowEmpty && isEmpty(nextValue) ? null : validateFunc(nextValue),
          );
          return nextValue;
        });
        return;
      }
      return;
    },
    [setValue, setError, validateFunc, allowEmpty],
  );

  const onChange = useCallback(
    (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!e) return;
      const value = e.target.value;
      setValueWithValidation(value);
    },
    [setValueWithValidation],
  );

  return [value, onChange, error, setValueWithValidation];
};
