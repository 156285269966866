export const auditLogTypes: string[] = [
  'createdAt',
  'sourceIp',
  'user',
  'event',
  'apiId',
  'apiName',
  'contentId',
  'contentIds',
  'userDefinedContentId',
  'userDefinedContentIds',
  'mediumId',
  'mediumName',
  'mediumTagId',
  'mediumTagName',
  'memberId',
  'roleId',
  'roleName',
  'customFieldId',
  'reviewerId',
  'reviewRequestId',
  'reviewRequestTitle',
  'environmentServiceId',
  'environmentName',
  'environmentSubdomain',
  'apiKeyId',
  'apiKeyName',
  'customStatusId',
  'customStatusName',
  'createdBy',
  'email',
  'reservationStartTime',
  'reservationStopTime',
];
