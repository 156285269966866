import cx from 'classnames';

import { useTextBottomAlert } from './useTextBottomAlert';

import type { NumberRange } from '@/types/field';

import styles from './textbottomalert.module.css';

export type Props = {
  errorText?: string | null;
  errorId?: string;
  textSize?: NumberRange;
  textCount: number;
  displayTextCount?: boolean;
};

export type ViewProps = Omit<Props, 'textSize' | 'textCount'> &
  ReturnType<typeof useTextBottomAlert>;

export const TextBottomAlertView: React.FC<ViewProps> = ({
  errorText,
  errorId,
  alertStyle,
  countDisplay,
  displayTextCount,
}) => {
  return (
    <div className={styles.alertWrapper}>
      {errorText ? (
        <p role="alert" id={errorId} className={styles.error}>
          {errorText}
        </p>
      ) : (
        <p></p>
      )}
      {displayTextCount && (
        <p className={cx(styles.textCount, alertStyle)}>{countDisplay}</p>
      )}
    </div>
  );
};

export const TextBottomAlert: React.FC<Props> = (props) => {
  const hooks = useTextBottomAlert(props);

  return <TextBottomAlertView {...props} {...hooks} />;
};
