import { useQuery } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';

import type { Service } from '@/types/services';

import * as queries from '@/graphql/queries';
import { getServiceDomain } from '@/util';

export const useGetMyService = () => {
  // TODO useGetMyServicesとrepositoryを共通化
  const getMyServices = async () => {
    const services = (await API.graphql(
      graphqlOperation(queries.getMyServices),
    )) as { data: { getMyServices: Service[] } };

    const serviceList: Service[] = services.data.getMyServices;

    return {
      list: serviceList.map((serviceList) => {
        return {
          ...serviceList,
          domain: serviceList.gsiSinglePartitionKey1.replace('DOMAIN#', ''),
        };
      }),
    };
  };

  const getMyService = (state: Awaited<ReturnType<typeof getMyServices>>) => {
    const domain = getServiceDomain();
    const service =
      state.list.find((service) => service.domain === domain) || null;
    const parentServiceId = service?.environment?.parent;
    const parentService =
      state.list.find((service) => service.partitionKey === parentServiceId) ||
      null;
    return {
      service,
      parentService,
      isChildEnvironment: parentService !== null,
    };
  };

  const serviceQuery = useQuery({
    queryKey: ['service'],
    queryFn: async () => {
      const state = await getMyServices();
      return getMyService(state);
    },
    staleTime: Number.POSITIVE_INFINITY,
  });

  const { data, isSuccess, isLoading } = serviceQuery;

  return {
    service: isSuccess ? data.service : null,
    parentService: isSuccess ? data.parentService : null,
    isChildEnvironment: isSuccess ? data.isChildEnvironment : null,
    hasService: isSuccess,
    isLoading,
  };
};
