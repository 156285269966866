import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Button from '../Button';
import Modal from '../Modal';

import styles from './upsellmodal.module.css';

type Props = {
  title: string;
  description: React.ReactNode;
  isHobby?: boolean;
  onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  submitText?: string;
  close?: React.MouseEventHandler<HTMLButtonElement>;
};

const UpsellModal: React.FC<Props> = ({
  title,
  description,
  isHobby,
  onSubmit,
  submitText,
  close,
}) => {
  const { t } = useTranslation('upsellModal');
  const history = useHistory();
  const onClick = useCallback(() => {
    history.push('/settings/billing');
  }, [history]);

  return (
    <Modal title={title} type="medium">
      <div className={styles.main}>
        <div className={styles.description}>{description}</div>
        {isHobby ? (
          <Button
            value={t('Compare plans')}
            size="large"
            type="secondary"
            onClick={onClick}
            className={styles.button}
          />
        ) : onSubmit && submitText && close ? (
          <div className={styles.addCharges}>
            <Button value={submitText} size="large" onClick={onSubmit} />
            <button className={styles.cancelButton} onClick={close}>
              {t('Cancel')}
            </button>
          </div>
        ) : (
          <div className={styles.closeButton}>
            <Button
              type="secondary"
              value={t('Close')}
              size="small"
              onClick={close}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UpsellModal;
