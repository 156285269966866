import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { listLanguage } from './listLanguage';
import { useCodeBlock } from './useCodeBlock';
import { useCodeBlockKeyEvent } from './useCodeBlockKeyEvent';
import { AutoComplete } from '../../AutoComplete';

import type { CodeBlockAttributes } from '.';
import type { FocusManagement } from './useCodeBlock';
import type { NodeViewProps, NodeViewContentProps } from '@tiptap/react';

import style from './codeblock.module.css';

export type ViewProps = {
  editor: NodeViewContentProps['editor'];
  filteredListLanguage: string[];
  codeBlockAttributes: CodeBlockAttributes;
  onChangeFileName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeLanguage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickLanguage: (item: string) => void;
  onKeyDownLanguage: (event: React.KeyboardEvent<Element>) => void;
  open: boolean;
  onOpenChange: () => void;
  activeIndex: number | null;
  setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
  focusManagement: FocusManagement;
  toggleFocusManagement: (state: FocusManagement) => void;
  currentPosition: number;
};

export const CodeBlockView: React.FC<ViewProps> = ({
  editor,
  filteredListLanguage,
  codeBlockAttributes,
  onChangeFileName,
  onChangeLanguage,
  onClickLanguage,
  onKeyDownLanguage,
  open,
  onOpenChange,
  activeIndex,
  setActiveIndex,
  focusManagement,
  toggleFocusManagement,
  currentPosition,
}) => {
  const { t } = useTranslation('richEditorV2');

  const { inputRef, id } = useCodeBlockKeyEvent({
    editor,
    open,
    focusManagement,
    toggleFocusManagement,
    currentPosition,
  });

  return (
    <NodeViewWrapper className={cx(style.wrapper, 'richeditorv2-codeblock')}>
      <pre>
        <NodeViewContent as="code" />
      </pre>
      <div className={style.action} contentEditable={false}>
        <input
          ref={inputRef}
          className={style.input}
          placeholder={t('File Name')}
          value={codeBlockAttributes.fileName || ''}
          onChange={onChangeFileName}
          tabIndex={-1}
          onFocus={() =>
            toggleFocusManagement({
              isInputFocus: true,
              isAutoCompleteFocus: false,
            })
          }
        />
        <div className={style.autocomplete}>
          <AutoComplete
            id={id}
            selectList={filteredListLanguage}
            inputValue={codeBlockAttributes.language || ''}
            onChange={onChangeLanguage}
            onClick={onClickLanguage}
            onKeyDown={onKeyDownLanguage}
            open={open}
            onOpenChange={onOpenChange}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            onFocus={onOpenChange}
            placeholder={t('Select Language')}
          />
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export const CodeBlock: React.FC<NodeViewProps> = (props) => {
  const codeBlockAttributes = props.node.attrs as CodeBlockAttributes;
  const {
    filteredListLanguage,
    onChangeFileName,
    onChangeLanguage,
    onClickLanguage,
    onKeyDownLanguage,
    open,
    onOpenChange,
    activeIndex,
    setActiveIndex,
    focusManagement,
    toggleFocusManagement,
  } = useCodeBlock({
    codeBlockAttributes,
    listLanguage: Object.keys(listLanguage),
    updateCodeBlockAttributes: props.updateAttributes,
  });

  return (
    <CodeBlockView
      editor={props.editor}
      filteredListLanguage={filteredListLanguage}
      codeBlockAttributes={codeBlockAttributes}
      onChangeFileName={onChangeFileName}
      onChangeLanguage={onChangeLanguage}
      onClickLanguage={onClickLanguage}
      onKeyDownLanguage={onKeyDownLanguage}
      open={open}
      onOpenChange={onOpenChange}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      focusManagement={focusManagement}
      toggleFocusManagement={toggleFocusManagement}
      currentPosition={props.getPos()}
    />
  );
};
