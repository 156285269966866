import type { MigrateApi } from '@/entity/api';
import type { ContentValue } from '@/entity/content';
import { nonNullable } from '@/util/type-guard';
import cx from 'classnames';
import { type SetStateAction, type WritableAtom, useAtomValue } from 'jotai';
import { selectAtom } from 'jotai/utils';
import type React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { CustomField, Field } from '../../../types/contents';
import InputField from '../../InputField';
import styles from './customfield.module.css';

type Props = {
  api: MigrateApi;
  contentAtom: WritableAtom<ContentValue, [SetStateAction<ContentValue>], void>;
  getContent: () => ContentValue;
  customFields: CustomField[];
  repeatCount?: number;
} & (
  | {
      inRepeatField?: false;
      customFieldCreatedAt: string;
      fieldIndex?: undefined;
      validationError?: undefined;
    }
  | {
      inRepeatField: true;
      customFieldCreatedAt?: undefined;
      fieldIndex: number;
      validationError: boolean;
    }
);

type FieldWithPosition = Field & { position: 'left' | 'right' };

const selector = (v: unknown) =>
  (v as { customFieldCreatedAt: string } | undefined | null)
    ?.customFieldCreatedAt;

const Customfield: React.FC<Props> = ({
  api,
  contentAtom,
  getContent,
  customFields: allCustomFields,
  customFieldCreatedAt: _customFieldCreatedAt,
  fieldIndex,
  validationError,
  repeatCount,
  inRepeatField,
}) => {
  const { t } = useTranslation('customField');

  // 繰り返しフィールド内のカスタムフィールドではcustomFieldCreateAtを渡せないためcontentAtomから取得する
  const customFieldCreatedAtInRepeaterField = useAtomValue(
    selectAtom(contentAtom, selector),
  );
  const customFieldCreatedAt =
    _customFieldCreatedAt ?? customFieldCreatedAtInRepeaterField;

  const endpoint = api.apiEndpoint;
  const customField = useMemo(
    () => allCustomFields.find((f) => f.createdAt === customFieldCreatedAt),
    [allCustomFields, customFieldCreatedAt],
  );

  // カラム数
  const column = useMemo(
    () => customField && customField.position.length,
    [customField],
  );

  // 整形したフィールド
  const fields: FieldWithPosition[] = useMemo(() => {
    if (customField == null) {
      return [];
    }
    return customField.position.length === 1
      ? customField.position[0]
          .map((idValue): FieldWithPosition | null => {
            const field = customField.fields.find((f) => f.idValue === idValue);
            if (field == null) {
              return null;
            }
            return {
              ...field,
              position: 'left',
            };
          })
          .filter(nonNullable)
      : [...customField.position[0], ...customField.position[1]]
          .map((idValue): FieldWithPosition | null => {
            const field = customField.fields.find((f) => f.idValue === idValue);
            if (field == null) {
              return null;
            }
            return {
              ...field,
              position: customField.position[0].includes(idValue)
                ? 'left'
                : 'right',
            };
          })
          .filter(nonNullable);
  }, [customField]);

  if (customField === null || customField === undefined) {
    return (
      <div className={styles.wrapper}>
        <p className={styles.errorText}>{t('Custom fields are not found.')}</p>
      </div>
    );
  }

  // repeatCountの値を計算する
  // 繰り返しフィールド内のカスタムフィールドの場合、repeatCountを渡す
  const calculatedRepeatCount = inRepeatField ? repeatCount : 1;

  return (
    <div
      className={`customFieldHook ${cx(styles.wrapper, {
        [styles.isError]: validationError === true,
      })}`}
    >
      <p className={styles.fieldInfo}>
        {fieldIndex !== undefined ? `${fieldIndex + 1}: ` : ''}
        {customField.name}
      </p>
      {column === 1 && (
        <ul className={styles.column}>
          {fields.map((field) => {
            return (
              <InputField
                key={field.idValue}
                api={api}
                endpoint={endpoint}
                field={field}
                customFields={allCustomFields}
                contentAtom={contentAtom}
                getContent={getContent}
                repeatCount={calculatedRepeatCount}
              />
            );
          })}
        </ul>
      )}
      {column === 2 && (
        <>
          <ul className={styles.column}>
            {fields
              .filter((field) => field.position === 'left')
              .map((field) => {
                return (
                  <InputField
                    key={field.idValue}
                    api={api}
                    endpoint={endpoint}
                    field={field}
                    customFields={allCustomFields}
                    contentAtom={contentAtom}
                    getContent={getContent}
                    repeatCount={calculatedRepeatCount}
                  />
                );
              })}
          </ul>
          <ul className={styles.column}>
            {fields
              .filter((field) => field.position === 'right')
              .map((field) => {
                return (
                  <InputField
                    key={field.idValue}
                    api={api}
                    endpoint={endpoint}
                    field={field}
                    customFields={allCustomFields}
                    contentAtom={contentAtom}
                    getContent={getContent}
                    repeatCount={calculatedRepeatCount}
                  />
                );
              })}
          </ul>
        </>
      )}
    </div>
  );
};

export default Customfield;
