import { useState, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import {
  useUpdateCustomStatus,
  useDeleteCustomStatus,
} from '../../../hooks/CustomStatus/useCustomStatus';
import { LinkButton } from '../../Form';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import Modal from '../../ui/Modal';
import Selectfield from '../../ui/Selectfield';
import Textfield from '../../ui/Textfield';
import {
  validateCustomStatusKey,
  validateCustomStatusName,
} from '../../Validations';

import type { CustomState, Behaviour } from '../../../types/customState';

import styles from './customStatusSettings.module.css';

import { useInput } from '@/views/Common/useInput';

type Props = {
  apiId: string;
  customStatus: CustomState;
  closeModal: () => void;
};

const EditModal: React.FC<Props> = ({ apiId, customStatus, closeModal }) => {
  const { t } = useTranslation('customStatusSettings');
  const [updateCustomStatus, updateCustomStatusLoadaing] =
    useUpdateCustomStatus();
  const [behaviour, setBehaviour] = useState<Behaviour>(customStatus.behaviour);
  const [key, onChangeKey, keyError] = useInput(
    customStatus.key,
    validateCustomStatusKey,
  );
  const [name, onChangeName, nameError] = useInput(
    customStatus.name,
    validateCustomStatusName,
  );
  const [color, setColor] = useState(customStatus.color);
  const onChangeColor = useCallback((value: string) => {
    setColor(value);
  }, []);
  const [description, setDescription] = useState(customStatus.description);
  const onChangeDescription = useCallback((value: string) => {
    setDescription(value);
  }, []);

  const submitUpdates = useCallback(() => {
    // @ts-ignore
    updateCustomStatus({
      apiId,
      customStatusId: customStatus.id,
      key,
      name,
      description,
      behaviour,
      color,
      closeModal,
    });
  }, [
    apiId,
    behaviour,
    color,
    customStatus.id,
    description,
    key,
    name,
    updateCustomStatus,
    closeModal,
  ]);

  const [deleteCustomStatus, deleteCustomStatusLoading] =
    useDeleteCustomStatus();
  const deleteWithConfirm = useCallback(() => {
    window.confirm(
      t(
        'This cannot be recovered once deleted. Are you sure you want to delete it?',
      ),
    ) &&
      // @ts-ignore
      deleteCustomStatus({
        apiId,
        customStatusId: customStatus.id,
        closeModal,
      });
  }, [apiId, customStatus.id, deleteCustomStatus, closeModal, t]);

  return (
    <Modal type="medium">
      <Fieldset
        legend={t('API Status Name')}
        option={false}
        customDescription={
          <p>
            {t('Enter a unique value to be retrieved by the API.')}
            {Trans({
              t,
              i18nKey: 'API documentation is here.',
              children: (
                <a
                  href="https://document.microcms.io/manual/custom-status#h72a886c1f0"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  here
                </a>
              ),
            })}
          </p>
        }
      >
        <Textfield
          type="text"
          size="medium"
          placeholder={t('E.g., PRIVATE')}
          value={key}
          onChange={onChangeKey}
          errorText={keyError}
        />
      </Fieldset>
      <Fieldset
        legend={t('Display Status Name')}
        option={false}
        description={t(
          'Enter unique text to be displayed next to the status icon.',
        )}
      >
        <Textfield
          type="text"
          size="medium"
          placeholder={t('E.g., private')}
          value={name}
          onChange={onChangeName}
          errorText={nameError}
        />
      </Fieldset>
      <Fieldset
        legend={t('Status Description')}
        description={t(
          'Displays status description when status name is selected.',
        )}
      >
        <Textfield
          type="text"
          size="full"
          placeholder={t(
            'E.g., Change the status of the campaign to End of Campaign with the article published.',
          )}
          value={description}
          onChange={(e) => onChangeDescription(e?.target.value || '')}
        />
      </Fieldset>
      <Fieldset
        legend={t('Next Status')}
        option={false}
        description={t('Select the next status after status change.')}
      >
        <Selectfield
          value={behaviour}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setBehaviour(e.target.value as Behaviour)
          }
        >
          <option value="">{t('Select')}</option>
          <option value="PUBLISH">{t('Public')}</option>
          <option value="CLOSED">{t('Unpublished')}</option>
          <option value="DRAFT">{t('Draft')}</option>
        </Selectfield>
      </Fieldset>
      <Fieldset
        legend={t('Status Color')}
        description={t('Specify color of the status icon.')}
      >
        <>
          <span className={styles.colorPickerLabel}>{t('Selected color')}</span>
          <input
            type="color"
            value={color}
            onChange={(e) => onChangeColor(e?.target.value || '')}
            className={styles.colorPicker}
          />
        </>
      </Fieldset>
      <div className={styles.modalButtonWrapper}>
        <LinkButton
          value={t('Delete')}
          style={{ fontSize: 14, color: '#DC2647', backgroundColor: '#ffffff' }}
          onClick={deleteWithConfirm}
          // @ts-expect-error
          disabled={updateCustomStatusLoadaing || deleteCustomStatusLoading}
        ></LinkButton>

        <Button
          type="secondary"
          size="large"
          value={t('Change Custom status')}
          onClick={submitUpdates}
          // @ts-expect-error NOTE: updateCustomStatusLoadingの返り値に型がついていないため
          disabled={
            updateCustomStatusLoadaing ||
            deleteCustomStatusLoading ||
            keyError !== null ||
            nameError !== null ||
            !key ||
            !name ||
            !behaviour
          }
          className={styles.modalButton}
        />
      </div>
    </Modal>
  );
};

export default EditModal;
