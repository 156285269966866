import { useTranslation } from 'react-i18next';

import CheckRoles from '../../CheckRoles';
import Button from '../Button';

import styles from './empty.module.css';

type Props = {
  title: string;
  helpUrl: string;
  helpText: string;
  description: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  checkPermission: string;
  checkOperation: string;
};

const Empty: React.FC<Props> = ({
  title,
  helpUrl,
  helpText,
  description,
  onClick,
  checkPermission,
  checkOperation,
}) => {
  const { t } = useTranslation('empty');
  return (
    <div className={styles.main}>
      <div className={styles.description}>{description}</div>
      <img src="/images/icon_empty_fill.svg" alt="" className={styles.img} />
      <h3 className={styles.title}>{title}</h3>
      {/* 外部リンクの分析ができなくなってしまうのでnoreferrerを設定しないようにしている */}
      {/* eslint-disable-next-line */}
      <a
        className={styles.link}
        href={helpUrl}
        target="_blank"
        rel="noreferrer"
      >
        <i className="material-icons">help_outline</i>
        <p className={styles.helpText}>{helpText}</p>
      </a>
      <CheckRoles permission={checkPermission} operation={checkOperation}>
        <Button
          value={t('Add')}
          type="secondary"
          size="large"
          icon="add"
          onClick={onClick}
        />
      </CheckRoles>
    </div>
  );
};

export default Empty;
