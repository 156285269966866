import { findIndexECPs } from '../../../../helpers/findIndexExceptionContentPermissions';

import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from '../../_types';
import type { EditableInputExceptionContentPermission } from '@/entity/role';

import { produce } from '@/lib/immer';

type SetECPermissionShowDeveloperMenuAction = FormRoleInputValueAction<
  'set-ec-permission-showdevelopermenu',
  {
    id: EditableInputExceptionContentPermission['id'];
    nextValue: EditableInputExceptionContentPermission['permission']['showDeveloperMenu'];
  }
>;

/**
 * 個別権限の指定したIDの要素にあるpermission.showDeveloperMenuを更新する
 */
const setECPermissionShowDeveloperMenu: FormRoleInputValueDispatchFn<
  SetECPermissionShowDeveloperMenuAction
> = (currentState, { id, nextValue }) => {
  return produce(currentState, (draft) => {
    const index = findIndexECPs(draft.exceptionContentPermissions, id);
    if (index >= 0) {
      const perm = draft.exceptionContentPermissions[index].permission;
      if (perm) perm.showDeveloperMenu = nextValue;
    }
  });
};

export {
  type SetECPermissionShowDeveloperMenuAction,
  setECPermissionShowDeveloperMenu,
};
