import { useCallback, useEffect, useState } from 'react';

import type { WebhookEventIdentifier } from '@/entity/webhookSettings';

import { WebhookEvent } from '@/constants/webhookSettings';
import { produce } from '@/lib/immer';
import { isWebhookEventIdentifier } from '@/util/webhookSetting';

const initialEvents: Array<WebhookEventIdentifier> = [
  WebhookEvent.PUBLISH_CONTENT_BY_SCREEN,
  WebhookEvent.PUBLISH_CONTENT_BY_WRITE_API,
  WebhookEvent.PUBLISH_CONTENT_BY_REVIEW,
  WebhookEvent.PUBLISH_CONTENT_BY_RESERVATION,
  WebhookEvent.PUBLISH_CONTENT_BY_CHANGE_ORDER,
  WebhookEvent.PUBLISH_CONTENT_BY_CHANGE_CONTENT_ID,
  WebhookEvent.PUBLISH_CONTENT_BY_CHANGE_PUBLISHED_AT,
];

/**
 * Webhook設定の通知項目の入力内容を管理する
 */
function useInputWebhookEvents(
  initialValue: Array<WebhookEventIdentifier> = initialEvents,
) {
  const [events, setEvents] =
    useState<Array<WebhookEventIdentifier>>(initialValue);

  /**
   * 表示内容は初期値に変更があり次第更新する
   */
  useEffect(() => {
    if (initialValue) {
      setEvents(initialValue);
    }
  }, [initialValue]);

  /**
   * 変更のイベントハンドラ
   *
   * onChangeイベントが発生した際に、チェックボックスの状態を更新する
   */
  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const isChecked = e.target.checked;

      if (isWebhookEventIdentifier(value)) {
        setEvents(
          produce((draft) => {
            const index = draft.indexOf(value);
            // 追加
            if (isChecked) {
              // 安全のため、存在しない場合のみ追加
              if (index < 0) {
                draft.push(value);
              }
            }
            // 削除
            else {
              // 安全のため、存在する場合のみ削除
              if (index >= 0) {
                draft.splice(index, 1);
              }
            }
          }),
        );
      }
    },
    [],
  );

  return [events, onChangeHandler] as const;
}

export { useInputWebhookEvents };
