import React, { useMemo } from 'react';

import ProfileImage from '../ProfileImage';

import styles from './user.module.css';

const User = ({
  user,
  theme = 'white',
  imageOnly = false,
  nameOnly = false,
  showAllEmail = false,
  hasName = true,

  /**
   * true: 画像を表示する, ただし、pictureがない場合はプレースホルダーが表示される
   * false: プレースホルダーが表示される
   */
  showProfileImage = true,
}) => {
  const isApi = useMemo(() => {
    return user.username && user.username.startsWith('API#');
  }, [user]);

  const picture = useMemo(
    () => (isApi ? undefined : user && user.picture),
    [user, isApi],
  );

  const name = useMemo(() => {
    if (isApi) {
      return 'API';
    }
    if (showAllEmail) {
      return user.email;
    }
    if (user && user.name) {
      return user.name;
    }
    if (user && user.email) {
      return user.email.split('@')[0];
    }
    return '';
  }, [isApi, user, showAllEmail]);

  if (!isApi && user === undefined) {
    return null;
  }
  if (imageOnly === true) {
    return (
      <ProfileImage
        picture={showProfileImage ? picture : undefined}
        title={name}
        isApi={isApi}
      />
    );
  }
  if (nameOnly === true) {
    return name || '-';
  }
  return (
    <div className={styles.wrapper}>
      <ProfileImage
        picture={showProfileImage ? picture : undefined}
        isApi={isApi}
      />
      {hasName && <p className={`${styles.name} ${styles[theme]}`}>{name}</p>}
    </div>
  );
};

export default User;
