import cx from 'classnames';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { useEnvironments } from '@/hooks/Environment/useEnvironments';
import { useMediumCount } from '@/hooks/Medium/useMediumReader';
import { useReviewRequestsCount } from '@/hooks/Review/useReviews';
import { useApiPermission, usePermissionIsNot } from '@/hooks/Role/useMyRoles';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';

import Breadcrumb from '../Breadcrumb';
import CheckRoles from '../CheckRoles';
import CreateEnvironment from '../CreateEnvironment';
import TypeList from '../Icons/TypeList';
import TypeObject from '../Icons/TypeObject';
import PermissionList from './PermissionList';
import ReleaseAnnounceModal from './ReleaseAnnounceModal';

import type { Service } from '@/types/services';

import styles from './serviceSideBar.module.css';

import { apiListOperations, apiListSelectors } from '@/ducks/apiList';
import type { ApiList, ApiListByRedux, MigrateApi } from '@/entity/api';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { buildHost } from '@/util';
import { Icon } from '@/views/Common/Ui/Icon';
import { useApiListInvalidateCache } from '@/views/Common/api/invalidateCache';
import { useSAMLQuery } from '@/views/ServiceSettings/SAMLSettings/useSAMLQuery';
import { ReviewsCount } from '@/views/ServiceSideBar/ReviewsCount';

const ServiceSideBar: React.FC = () => {
  const { service, parentService, isChildEnvironment } = useGetMyService();
  const apiListInvalidateCache = useApiListInvalidateCache();

  // TODO: ReduxをReactQueryに置き換える
  const { apiList } = useAppSelector(
    (state) =>
      apiListSelectors.get(state.apiListState as ApiList) as ApiListByRedux,
  );
  const dispatch = useAppDispatch();
  const loadApis = useCallback(
    (service: Service) => {
      dispatch(apiListOperations.loadApis(service));
    },
    [dispatch],
  );
  const updateSortOrderValue = useCallback(
    (service: Service, api: MigrateApi, sortOrderValue: string) => {
      if (!service) return;
      dispatch(
        apiListOperations.updateSortOrderValue(service, api, sortOrderValue),
      ).then(() => {
        apiListInvalidateCache();
      });
    },
    [apiListInvalidateCache, dispatch],
  );

  const [draggable, setDraggable] = useState(false);
  const [isDragOver, setIsDragOver] = useState(-1);
  const [isSpMenuOpen, setIsSpMenuOpen] = useState(false);

  const { t } = useTranslation('serviceSideBar');

  const apiIds = apiList && apiList.map((api) => api.partitionKey);

  // 権限を取得
  const [hasnotPermission] = usePermissionIsNot(
    'mediumPermission',
    'readLevel',
    'NONE',
  );
  const [hasApiReadPermission] = useApiPermission();

  const closeSpMenu = useCallback(() => {
    setIsSpMenuOpen(false);
  }, []);

  // 環境
  const { environments } = useEnvironments(
    service?.partitionKey,
    parentService !== null ? parentService.partitionKey : undefined,
  );
  const [isOpenEnvSelect, setIsOpenEnvSelect] = useState(false);
  const openEnvSelect = useCallback((e: any) => {
    e.stopPropagation();
    setIsOpenEnvSelect(true);
  }, []);
  const closeEnvSelect = useCallback((e: any) => {
    e.stopPropagation();
    setIsOpenEnvSelect(false);
  }, []);
  const [EnvModal, openEnvModal, closeEnvModal] = useModal('root');

  const { actionRequired, currentPlan } = useStripeActions(service);

  const [mediumCount] = useMediumCount(
    hasnotPermission
      ? undefined
      : service?.partitionKey && service.partitionKey,
  );

  const { useFindSAMLConfiguration } = useSAMLQuery(
    service?.partitionKey || '',
  );
  const { data: samlConfiguration } = useFindSAMLConfiguration();

  // 権限リストの取得
  const [reviewRequestsCount] = useReviewRequestsCount(service);

  const insert = useCallback(
    (index: any, targetIndex: any) => {
      // 位置を移動しない場合は何もしない
      if (index === targetIndex || index === targetIndex - 1) {
        return;
      }
      /**
       * 先頭に移動する場合は現在時刻
       * 間に移動する場合は両隣のsortOrderValueの平均を使う
       * ミリ秒まで計算
       */
      const newSortOrderValue =
        targetIndex === 0
          ? dayjs().toISOString()
          : dayjs(
              Math.floor(
                (Number(
                  dayjs(
                    apiList[targetIndex - 1].gsiSortKey1.replace('SORT#', ''),
                  ).valueOf(),
                ) +
                  Number(
                    dayjs(
                      apiList[targetIndex].gsiSortKey1.replace('SORT#', ''),
                    ).valueOf(),
                  )) /
                  2,
              ),
            ).toISOString();
      if (service) {
        updateSortOrderValue(service, apiList[index], newSortOrderValue);
      }
    },
    [service, apiList, updateSortOrderValue],
  );

  const onDragStart = useCallback((e: any) => {
    e.dataTransfer.setData('index', e.currentTarget.getAttribute('data-index'));
  }, []);

  const onDragOver = useCallback((e: any) => {
    e.preventDefault();
    setIsDragOver(e.currentTarget.getAttribute('data-index'));
  }, []);

  const onDragLeave = useCallback((e: any) => {
    setIsDragOver(-1);
    setDraggable(false);
  }, []);

  const onDrop = useCallback(
    (e: any) => {
      const index = e.dataTransfer.getData('index');
      const targetIndex = e.currentTarget.getAttribute('data-index');
      setIsDragOver(-1);
      insert(Number(index), Number(targetIndex));
    },
    [insert],
  );

  // サイドバーの横幅調整
  const defaultWidth = useMemo(() => {
    const w = window.localStorage.getItem('sidebarWidth');
    return w === null ? 200 : Number(w);
  }, []);
  const [sidebarWidth, setSidebarWidth] = useState(defaultWidth);
  const [isExpanding, setIsExpanding] = useState(false);

  const onExpand = useCallback(
    (e: any) => {
      if (!isExpanding) {
        return;
      }
      e.preventDefault();
      const w = e.clientX - 61; // サービスナビゲーションの横幅を引いた値
      const MIN_WIDTH = 50;
      const MAX_WIDTH = 400;
      const newWidth =
        w < MIN_WIDTH ? MIN_WIDTH : MAX_WIDTH < w ? MAX_WIDTH : w;
      setSidebarWidth(newWidth);
      window.localStorage.setItem('sidebarWidth', String(newWidth));
    },
    [isExpanding],
  );

  useEffect(() => {
    if (service) {
      loadApis(service);
    }
  }, [loadApis, service]);

  const location = useLocation();

  if (location.pathname.startsWith('/profile')) {
    return <></>;
  }

  if (service === undefined || service === null) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.first}></div>
      </div>
    );
  }

  return (
    <div
      className={styles.wrapper}
      style={{ width: sidebarWidth }}
      data-testid="service-sidebar"
    >
      <header className={styles.header}>
        <button
          className={styles.menuBtn}
          onClick={() => setIsSpMenuOpen(true)}
        >
          <img src="/images/icon_menu.svg" alt={t('Menu')} />
        </button>
        <Breadcrumb apiList={apiList} />
      </header>
      {isSpMenuOpen && (
        <div className={styles.mask} onClick={() => setIsSpMenuOpen(false)} />
      )}
      <div
        className={cx(styles.container, {
          [styles.overflowVisible]: isOpenEnvSelect,
          [styles.isOpen]: isSpMenuOpen,
        })}
      >
        <div className={styles.first}>
          <div className={styles.serviceSet}>
            <p className={styles.serviceName}>{service.serviceName}</p>
            {actionRequired ? (
              <Link to="/settings/billing" className={styles.alert}>
                {t('You have an incomplete payment')}
              </Link>
            ) : (
              <p className={styles.serviceId}>{service.domain}</p>
            )}
          </div>
          <Link
            to="/settings"
            className={styles.settings}
            data-testid="link-settings"
          >
            <Icon name="settings" size="large" />
          </Link>
        </div>
        <div>
          {environments &&
            (currentPlan?.limit?.handleEnvironments ||
              currentPlan?.name === 'Environment') && (
              <div className={styles.environment}>
                <div className={styles.tooltipHook}>
                  <button
                    type="button"
                    className={cx(styles.env, {
                      [styles.isOpen]: isOpenEnvSelect,
                      [styles.isProduction]: service.environment === null,
                    })}
                    onClick={openEnvSelect}
                    aria-label={t('Open environment settings')}
                  >
                    <img src="/images/icon_branch.svg" alt="" />
                    <p className={styles.envName}>
                      {service.environment === null
                        ? t('Production')
                        : service.environment.name}
                    </p>
                    <Icon name="unfold_more" />
                  </button>
                  {isOpenEnvSelect && (
                    <>
                      <div
                        className={styles.envMask}
                        onClick={closeEnvSelect}
                      />
                      <div className={styles.tooltip}>
                        <ul aria-label={t('Environment list')}>
                          <li className={styles.tooltipList}>
                            <a
                              href={buildHost({
                                domain: (parentService || service).domain,
                                childService: {},
                                saml: samlConfiguration,
                              })}
                              className={styles.envLink}
                            >
                              <dl className={styles.envSet}>
                                <dt className={styles.envName}>
                                  {t('Production')}
                                </dt>
                                <dd className={styles.envDomain}>
                                  {(parentService || service).domain}
                                </dd>
                              </dl>
                              <Icon name="chevron_right" />
                            </a>
                          </li>
                          {environments.map(
                            ({
                              name,
                              domain,
                              partitionKey,
                            }: {
                              name: string;
                              domain: string;
                              partitionKey: string;
                            }) => (
                              <li key={domain} className={styles.tooltipList}>
                                <a
                                  href={buildHost({
                                    domain,
                                    childService: { partitionKey },
                                    saml: samlConfiguration,
                                  })}
                                  className={styles.envLink}
                                >
                                  <dl className={styles.envSet}>
                                    <dt className={styles.envName}>{name}</dt>
                                    <dd className={styles.envDomain}>
                                      {domain}
                                    </dd>
                                  </dl>
                                  <Icon name="chevron_right" />
                                </a>
                              </li>
                            ),
                          )}
                        </ul>
                        {!isChildEnvironment && (
                          <CheckRoles
                            permission="environmentPermission"
                            operation="create"
                          >
                            <div className={styles.envActions}>
                              <button
                                type="button"
                                className={styles.envActionButton}
                                onClick={() => openEnvModal()}
                              >
                                <Icon name="add" />
                                {t('Add')}
                              </button>
                              <Link
                                className={styles.envActionButton}
                                to="/settings/environments"
                                onClick={closeEnvSelect}
                              >
                                <Icon name="settings" />
                                {t('Settings')}
                              </Link>
                            </div>
                          </CheckRoles>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          <div className={styles.contents}>
            <dl>
              <dt className={styles.title}>
                <Link to="/apis" onClick={closeSpMenu}>
                  {t('APIs')}
                </Link>
                <CheckRoles permission="apiPermission" operation="create">
                  <Link
                    className={styles.add}
                    to="/create-api"
                    onClick={closeSpMenu}
                  >
                    <i className="material-icons">add</i>
                  </Link>
                </CheckRoles>
              </dt>
              <dd>
                <ul className={styles.apiLists}>
                  {apiList &&
                    apiList.map((api, index) => {
                      return (
                        hasApiReadPermission(api.partitionKey) && (
                          <li
                            key={index}
                            className={
                              Number(isDragOver) === index
                                ? `${styles.apiList} ${styles.isDragOver}`
                                : styles.apiList
                            }
                            draggable={draggable}
                            onDragStart={onDragStart}
                            onDragOver={onDragOver}
                            onDragLeave={onDragLeave}
                            onDrop={onDrop}
                            data-index={index}
                            title={api.apiName}
                          >
                            <NavLink
                              to={`/apis/${api.apiEndpoint}`}
                              activeClassName={styles.isActive}
                              onClick={closeSpMenu}
                            >
                              {api.apiType === 'PAGE' ? (
                                <TypeObject className={styles.icon} />
                              ) : (
                                <TypeList className={styles.icon} />
                              )}
                              <p className={styles.apiName}>{api.apiName}</p>
                            </NavLink>
                          </li>
                        )
                      );
                    })}
                </ul>
              </dd>
              {apiList && apiList.length === 0 && (
                <dd className={styles.empty}>{t('No contents')}</dd>
              )}
              {apiList === undefined && (
                <dd className={styles.empty}>{t('Loading')}</dd>
              )}
            </dl>
          </div>
          <CheckRoles permission="mediumPermission" operation="readLevel">
            <div className={styles.media}>
              <dl>
                <dt className={styles.title}>
                  <Link to="/media" onClick={closeSpMenu}>
                    {t('Media')}
                  </Link>
                </dt>
                <dd className={styles.length}>
                  <NavLink
                    to="/media"
                    activeClassName={styles.isActive}
                    onClick={closeSpMenu}
                  >
                    <Icon name="image" outlined className={styles.linkIcon} />
                    {t('Number of items', { count: mediumCount || '0' })}
                  </NavLink>
                </dd>
              </dl>
            </div>
          </CheckRoles>
          {apiIds && (
            <ReviewsCount
              apiIds={apiIds}
              reviewRequestsCount={reviewRequestsCount}
              closeSpMenu={closeSpMenu}
            />
          )}
          <PermissionList
            service={service}
            close={closeSpMenu}
            isChildEnvironment={!!isChildEnvironment}
          />
        </div>
      </div>
      <div
        className={cx(styles.expander, {
          [styles.isExpanding]: isExpanding,
        })}
        onMouseDown={() => setIsExpanding(true)}
        onMouseUp={() => setIsExpanding(false)}
        onMouseMove={onExpand}
      />
      <EnvModal>
        <CreateEnvironment
          service={service}
          parentService={parentService}
          closeEnvModal={closeEnvModal}
        />
      </EnvModal>
      {!window._DATADOG_SYNTHETICS_BROWSER && (
        <ReleaseAnnounceModal service={service} />
      )}
    </div>
  );
};

export default ServiceSideBar;
