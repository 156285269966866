import type {
  InputExceptionContentPermission,
  ExceptionContentPermission,
} from '@/entity/role';

import { genUID } from '@/lib/nanoid';

/**
 * 入力用の個別権限の内容を返却する
 */
function getInputExceptionContentPermissions(
  ecp: Array<ExceptionContentPermission> | undefined,
) {
  if (!ecp) return [];
  return ecp.map((item) => {
    return {
      apiId: item.apiId,
      permission: item.permission,
      reviewRequestPermission: item.reviewRequestPermission,
      id: genUID(),
    };
  });
}

/**
 * 出力用の個別権限の内容を返却する
 *
 * ※ 出力用とはDBに保存するような場合を指す
 */
function getOutputExceptionContentPermissions(
  inputECP: Array<InputExceptionContentPermission> | undefined,
): Array<Omit<InputExceptionContentPermission, 'id'>> {
  if (!inputECP) return [];
  return inputECP.map((item) => ({
    apiId: item.apiId,
    permission: item.permission,
    reviewRequestPermission: item.reviewRequestPermission,
  }));
}

export {
  getInputExceptionContentPermissions,
  getOutputExceptionContentPermissions,
};
