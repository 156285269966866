import type React from 'react';
import { useTranslation } from 'react-i18next';

import Selectfield from '../../ui/Selectfield';

import type { ApiListByRedux } from '@/entity/api';

type Props = {
  apiId: string;
  apiList: ApiListByRedux['apiList'];
  value: string;
  onChangeReferenceDisplayItem: (
    e?: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
};

const ReferenceDisplayItem: React.FC<Props> = ({
  apiId,
  apiList,
  value = '',
  onChangeReferenceDisplayItem,
}) => {
  const { t } = useTranslation('referenceDisplayItem');
  const api = apiList.find((api) => api.partitionKey === apiId);
  return (
    <Selectfield
      size="full"
      onChange={onChangeReferenceDisplayItem}
      value={value === null ? '' : value}
    >
      <option value="">{t('Content ID')}</option>
      {api &&
        api.apiFields
          .filter((field) => field.kind === 'text')
          .map((field, i) => {
            return (
              <option key={i} value={field.idValue}>
                {field.name} ({field.fieldId})
              </option>
            );
          })}
    </Selectfield>
  );
};

export default ReferenceDisplayItem;
