import type {
  ContentPermission,
  ReviewRequestPermission,
  SettingsDefaultPermission,
} from '@/entity/role';

/**
 * 全ての権限付与
 *
 * 新規作成時の初期値や「すべて付与」のアクションに使用する
 */
export const highestLevelPermission: SettingsDefaultPermission = {
  memberPermission: {
    read: {
      defaultRead: true,
    },
    create: true,
    update: true,
    delete: true,
  },
  rolePermission: {
    read: true,
    readLevel: 'ALL',
    create: true,
    update: true,
    delete: true,
  },
  billingPermission: {
    read: true,
    update: true,
  },
  apiKeyPermission: {
    read: true,
  },
  usagePermission: {
    read: true,
  },
  environmentPermission: {
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  reviewRequestPermission: {
    readLevel: 'ALL',
    create: true,
    updateLevel: 'ALL',
  },
  mediumPermission: {
    readLevel: 'ALL',
    create: true,
    update: true,
    delete: true,
  },
  apiPermission: {
    create: true,
  },
  defaultContentPermission: {
    apiRead: true,
    apiUpdate: true,
    apiDelete: true,
    contentCreate: {
      draft: true,
      publish: true,
    },
    contentReadLevel: 'ALL',
    contentUpdate: {
      saveDraft: true,
      removeDraft: true,
      changeContentIdUnpublished: true,
      changeContentIdPublished: true,
      reorderUnpublished: true,
      reorderPublished: true,
      changePublicationDateUnpublished: true,
      changePublicationDatePublished: true,
      changeAuthor: true,
      changeDraftKey: true,
      publish: true,
      unpublish: true,
      revertToDraft: true,
      revertToDraftUnpublished: true,
    },
    contentDelete: true,
    showDeveloperMenu: true,
  },
};

/**
 * 全ての権限解除
 *
 * 「すべて解除」のアクションに使用する
 */
export const lowestLevelPermission: SettingsDefaultPermission = {
  memberPermission: {
    read: {
      defaultRead: false,
    },
    create: false,
    update: false,
    delete: false,
  },
  rolePermission: {
    read: false,
    readLevel: 'NONE',
    create: false,
    update: false,
    delete: false,
  },
  billingPermission: {
    read: false,
    update: false,
  },
  apiKeyPermission: {
    read: false,
  },
  usagePermission: {
    read: false,
  },
  environmentPermission: {
    read: false,
    create: false,
    update: false,
    delete: false,
  },
  reviewRequestPermission: {
    readLevel: 'NONE',
    create: false,
    updateLevel: 'NONE',
  },
  mediumPermission: {
    readLevel: 'NONE',
    create: false,
    update: false,
    delete: false,
  },
  apiPermission: {
    create: false,
  },
  defaultContentPermission: {
    apiRead: false,
    apiUpdate: false,
    apiDelete: false,
    contentCreate: {
      draft: false,
      publish: false,
    },
    contentReadLevel: 'NONE',
    contentUpdate: {
      saveDraft: false,
      removeDraft: false,
      changeContentIdUnpublished: false,
      changeContentIdPublished: false,
      reorderUnpublished: false,
      reorderPublished: false,
      changePublicationDateUnpublished: false,
      changePublicationDatePublished: false,
      changeAuthor: false,
      changeDraftKey: false,
      publish: false,
      unpublish: false,
      revertToDraft: false,
      revertToDraftUnpublished: false,
    },
    contentDelete: false,
    showDeveloperMenu: false,
  },
};

/**
 * 個別権限のContentPermission: 全ての権限付与
 */
export const highestLevelExceptionContentPermission: ContentPermission = {
  apiRead: true,
  apiUpdate: true,
  apiDelete: true,
  contentCreate: {
    draft: true,
    publish: true,
  },
  contentReadLevel: 'ALL',
  contentUpdate: {
    saveDraft: true,
    removeDraft: true,
    changeContentIdUnpublished: true,
    changeContentIdPublished: true,
    reorderUnpublished: true,
    reorderPublished: true,
    changePublicationDateUnpublished: true,
    changePublicationDatePublished: true,
    changeAuthor: true,
    changeDraftKey: true,
    publish: true,
    unpublish: true,
    revertToDraft: true,
    revertToDraftUnpublished: true,
  },
  contentDelete: true,
  showDeveloperMenu: true,
};

/**
 * 個別権限のContentPermission: 全ての権限解除
 */
export const lowestLevelExceptionContentPermission: ContentPermission = {
  apiRead: false,
  apiUpdate: false,
  apiDelete: false,
  contentCreate: {
    draft: false,
    publish: false,
  },
  contentReadLevel: 'NONE',
  contentUpdate: {
    saveDraft: false,
    removeDraft: false,
    changeContentIdUnpublished: false,
    changeContentIdPublished: false,
    reorderUnpublished: false,
    reorderPublished: false,
    changePublicationDateUnpublished: false,
    changePublicationDatePublished: false,
    changeAuthor: false,
    changeDraftKey: false,
    publish: false,
    unpublish: false,
    revertToDraft: false,
    revertToDraftUnpublished: false,
  },
  contentDelete: false,
  showDeveloperMenu: false,
};

/**
 * 個別権限のReviewRequestPermission: 全ての権限付与
 */
export const highestLevelExceptionReviewRequestPermission: ReviewRequestPermission =
  {
    readLevel: 'ALL',
    create: true,
    updateLevel: 'ALL',
  };

/**
 * 個別権限のReviewRequestPermission: 全ての権限解除
 */
export const lowestLevelExceptionReviewRequestPermission: ReviewRequestPermission =
  {
    readLevel: 'NONE',
    create: false,
    updateLevel: 'NONE',
  };
