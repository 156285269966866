import cx from 'classnames';

import styles from './socialButton.module.css';

import DiscordLogo from '@/images/icon_discord.svg?react';
import XLogo from '@/images/icon_x.svg?react';

type Props = {
  type: 'x' | 'discord';
  outlined?: boolean;
  link: string;
  text?: string;
  className?: string;
};

export const SocialButton: React.FC<Props> = ({
  type,
  outlined,
  link,
  text,
  className,
}) => {
  return (
    <a
      className={cx(
        styles.button,
        outlined ? styles.outline : '',
        styles[type],
        className,
      )}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {type === 'x' && <XLogo />}
      {type === 'discord' && <DiscordLogo />}
      {text}
    </a>
  );
};
