import IconButton from '../IconButton';

import styles from './toast.module.css';

type Props = {
  children: React.ReactNode;
  appearance: 'success' | 'failure';
  transitionState: 'entering' | 'entered' | 'exiting' | 'exited' | 'unmounted';
  fixed?: boolean;
  onDismiss?: () => void;
};

const Toast: React.FC<Props> = ({
  children,
  appearance,
  transitionState,
  fixed = false,
  onDismiss,
}) => {
  return (
    <div
      className={`${styles.wrapper} ${styles[transitionState]} ${
        styles[appearance]
      } ${fixed ? styles.fixed : ''}`}
    >
      <div className={styles.icon}>
        {appearance === 'success' ? (
          <i className="material-icons">check_circle</i>
        ) : (
          <i className="material-icons">error</i>
        )}
      </div>
      <p className={styles.message}>{children}</p>
      {onDismiss !== undefined && (
        <div className={styles.removeButton}>
          <IconButton icon="clear" onClick={onDismiss} />
        </div>
      )}
    </div>
  );
};

export default Toast;
