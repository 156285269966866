import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';

import {
  findWebhookSettingsUsecase,
  getWebhookSettingsCountUsecase,
} from '@/usecase/webhookSettingUsecase';

import type { MigrateApi } from '@/entity/api';
import type {
  CreateNewWebhookSetting,
  WebhookSetting,
  WebhookSettingTargetIdentifier,
} from '@/entity/webhookSettings';
import type { Service } from '@/types/services';

import { produce } from '@/lib/immer';

const useReadWebhookSettings = (
  serviceId: Service['partitionKey'],
  apiId: MigrateApi['partitionKey'],
) => {
  // 未登録の作成中のWebhook設定群
  const [newWebhookSettings, setNewWebhookSettings] = useState<
    Array<CreateNewWebhookSetting>
  >([]);

  const { data: fetchedWebhookSettings } = useQuery({
    queryKey: ['webhookSettings', { apiId }],
    queryFn: async () => {
      const res = await findWebhookSettingsUsecase(apiId);
      return res;
    },
    onSuccess: () => {
      // NOTE: 取得（またはキャッシュがアップデートされた）時に新規作成中のWebhook設定をリセット
      setNewWebhookSettings([]);
    },
    onError: (e) => {
      console.error(e);
    },
  });

  const { data: allSettingsCount } = useQuery({
    queryKey: ['webhookSettingsCount', { serviceId }],
    queryFn: async () => {
      const res = await getWebhookSettingsCountUsecase(serviceId);
      return res;
    },
    onError: (e) => {
      console.error(e);
    },
  });

  const addNewWebhookSetting = useCallback(
    (target: WebhookSettingTargetIdentifier) => {
      setNewWebhookSettings(
        produce((draft) => {
          draft.push({ target });
        }),
      );
    },
    [],
  );

  const webhookSettings: Array<WebhookSetting | CreateNewWebhookSetting> =
    useMemo(() => {
      if (!fetchedWebhookSettings) {
        return newWebhookSettings;
      }

      return [...fetchedWebhookSettings, ...newWebhookSettings];
    }, [fetchedWebhookSettings, newWebhookSettings]);

  return [
    webhookSettings,
    allSettingsCount || 0,
    addNewWebhookSetting,
  ] as const;
};

export { useReadWebhookSettings };
