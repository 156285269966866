import type { MigrateApi } from '@/entity/api';
import type { Service } from '@/types/services';
import { updateWebhookEnabledSetting } from '@/usecase/webhookSettingWriterUsecase';
import {
  createWebhookSetting,
  deleteWebhookSetting,
  updateWebhookSetting,
} from '@/usecase/webhookSettingWriterUsecase';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToasts } from 'react-toast-notifications';
import { useI18nWebhookSettings } from '../i18n';

const useCreateWebhookSetting = (
  serviceId: Service['partitionKey'],
  apiId: MigrateApi['partitionKey'],
) => {
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useI18nWebhookSettings('$feedback');

  const { mutate, isLoading } = useMutation({
    mutationFn: async (
      input: Pick<
        Parameters<typeof createWebhookSetting>[0],
        'events' | 'target' | 'values'
      >,
    ) => {
      return createWebhookSetting({
        apiId,
        ...input,
      });
    },
    onSuccess: async () => {
      await cache.invalidateQueries(['webhookSettings', { apiId }]);
      await cache.invalidateQueries(['webhookSettingsCount', { serviceId }]);
      addToast(t('Created the webhook setting'), {
        appearance: 'success',
      });
    },
    onError: () => {
      addToast(t('Failed to create the webhook setting'), {
        appearance: 'error',
      });
    },
  });

  return [mutate, isLoading] as const;
};

const useUpdateWebhookSetting = (
  serviceId: Service['partitionKey'],
  apiId: MigrateApi['partitionKey'],
) => {
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useI18nWebhookSettings('$feedback');

  const { mutate, isLoading } = useMutation({
    mutationFn: async (input: Parameters<typeof updateWebhookSetting>[0]) => {
      return updateWebhookSetting(input);
    },
    onSuccess: async () => {
      await cache.invalidateQueries(['webhookSettings', { apiId }]);
      await cache.invalidateQueries(['webhookSettingsCount', { serviceId }]);
      addToast(t('Updated the webhook setting'), {
        appearance: 'success',
      });
    },
    onError: () => {
      addToast(t('Failed to update the webhook setting'), {
        appearance: 'error',
      });
    },
  });

  return [mutate, isLoading] as const;
};

const useDeleteWebhookSetting = (
  serviceId: Service['partitionKey'],
  apiId: MigrateApi['partitionKey'],
) => {
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useI18nWebhookSettings('$feedback');

  const { mutate, isLoading } = useMutation({
    mutationFn: async (input: Parameters<typeof deleteWebhookSetting>[0]) => {
      return deleteWebhookSetting(input);
    },
    onSuccess: async () => {
      await cache.invalidateQueries(['webhookSettings', { apiId }]);
      await cache.invalidateQueries(['webhookSettingsCount', { serviceId }]);
      addToast(t('Deleted the webhook setting'), {
        appearance: 'success',
      });
    },
    onError: () => {
      addToast(t('Failed to delete the webhook setting'), {
        appearance: 'error',
      });
    },
  });

  return [mutate, isLoading] as const;
};

const useUpdateWebhookEnabledSetting = (
  serviceId: Service['partitionKey'],
  apiId: MigrateApi['partitionKey'],
) => {
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const { t } = useI18nWebhookSettings('$feedback');

  const { mutate, isLoading } = useMutation({
    mutationFn: async (
      input: Parameters<typeof updateWebhookEnabledSetting>[0],
    ) => {
      return updateWebhookEnabledSetting(input);
    },
    onSuccess: async () => {
      await cache.invalidateQueries(['webhookSettings', { apiId }]);
      await cache.invalidateQueries(['webhookSettingsCount', { serviceId }]);
      addToast(t('Changes have been made'), {
        appearance: 'success',
      });
    },
    onError: () => {
      addToast(t('Failed to make changes'), {
        appearance: 'error',
      });
    },
  });

  return [mutate, isLoading] as const;
};

export {
  useCreateWebhookSetting,
  useUpdateWebhookSetting,
  useDeleteWebhookSetting,
  useUpdateWebhookEnabledSetting,
};
