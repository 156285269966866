import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import interval from 'interval-promise';
import { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { deleteEnvironment as deleteEnvironmentUsecase } from '@/usecase/environmentSettingsUsecase';

import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { myRolesContext } from '../Role/useMyRoles';

export const useEnvironments = (serviceId, parentServiceId) => {
  const { t } = useTranslation('hooksEnvironment');
  // 権限を取得
  const { roles } = useContext(myRolesContext);
  const hasReadEnvironmentPermission = useMemo(() => {
    return (
      roles &&
      roles
        .map(({ environmentPermission }) => environmentPermission.read)
        .includes(true)
    );
  }, [roles]);

  const cache = useQueryClient();
  const { addToast } = useToasts();

  // 環境を取得
  const environmentsQuery = useQuery({
    queryKey: ['environments'],
    queryFn: () => {
      if (hasReadEnvironmentPermission) {
        return API.graphql(
          graphqlOperation(queries.getEnvironments, {
            serviceId: parentServiceId || serviceId,
          }),
        ).then((result) =>
          result.data.getEnvironments.map((service) => {
            return {
              ...service.environment,
              domain: service.gsiSinglePartitionKey1.replace('DOMAIN#', ''),
              createdAt: service.createdAt,
              partitionKey: service.partitionKey,
            };
          }),
        );
      }
      return;
    },
    enabled: hasReadEnvironmentPermission,
    staleTime: Number.POSITIVE_INFINITY,
  });
  const { data: environments } = environmentsQuery;

  // 環境を作成
  const { mutate: createEnvironment, isLoading: createEnvironmentLoading } =
    useMutation({
      mutationFn: async ({ environmentName, contentCopyLevel }) => {
        return API.graphql(
          graphqlOperation(mutations.executeCreateEnvironment, {
            serviceId,
            environmentName,
            contentCopyLevel,
          }),
        )
          .then((result) => result.data.executeCreateEnvironment)
          .then((output) => {
            if (output.result === false) {
              return output;
            }
            return new Promise((resolve) => {
              //ポーリングで進捗状況を確認
              interval(async (_, stop) => {
                const result = await API.graphql(
                  graphqlOperation(queries.describeCreateEnvironment, {
                    executionId: output.data,
                  }),
                ).then((result) =>
                  JSON.parse(result.data.describeCreateEnvironment),
                );

                if (result.status !== 'RUNNING') {
                  resolve(JSON.parse(result.output));
                  stop();
                }
              }, 3000);
            });
          });
      },
      onSuccess(output, { closeEnvModal }) {
        if (output?.result === false) {
          addToast(output?.message || t('Could not create a new environment'), {
            appearance: 'error',
          });
        } else {
          cache.invalidateQueries(['environments'], { type: 'all' });
          closeEnvModal();
          addToast(t('Created a new environment'), {
            appearance: 'success',
          });
        }
      },
      onError(error) {
        addToast(error?.message || t('Could not create a new environment'), {
          appearance: 'error',
        });
      },
    });

  /**
   * 環境を削除
   *
   * @type {import('@tanstack/react-query').UseMutationResult<unknown,unknown,{serviceId:string}>}
   */
  const { mutate: deleteEnvironment, isLoading: deleteEnvironmentLoading } =
    useMutation({
      mutationFn: async ({ serviceId }) => {
        return deleteEnvironmentUsecase({ serviceId });
      },
      onSuccess() {
        cache.invalidateQueries(['environments'], { type: 'all' });
        addToast(t('Deleted environment'), {
          appearance: 'success',
        });
      },
      onError(error) {
        addToast(error?.message || t('Could not delete environment'), {
          appearance: 'error',
        });
      },
    });

  return {
    environments: environments?.sort((e1, e2) =>
      e1.createdAt.localeCompare(e2.createdAt),
    ),
    createEnvironment,
    createEnvironmentLoading,
    deleteEnvironment,
    deleteEnvironmentLoading,
  };
};
