import { useState, useCallback } from 'react';

import styles from './dragAndDrop.module.css';

export const useDragAndDrop = (onChange = () => {}) => {
  const [beforeIndex, setBeforeIndex] = useState(-1);
  const [afterIndex, setAfterIndex] = useState(-1);
  const [draggable, setDraggable] = useState(false);
  const onDragStart = useCallback((e) => {
    e.stopPropagation();
    setBeforeIndex(e.target.getAttribute('data-index'));
  }, []);

  const onDragOver = useCallback(
    (e) => {
      if (draggable === false) {
        return;
      }
      e.stopPropagation();
      e.preventDefault();
      const { top, height } = e.currentTarget.getBoundingClientRect();
      const y = e.pageY - top - window.pageYOffset;

      // 要素の半分より上か下か判定
      setAfterIndex(
        Number(e.currentTarget.getAttribute('data-index')) +
          (y < height / 2 ? 0 : 1),
      );
    },
    [draggable],
  );
  const onDragEnd = useCallback(
    (e) => {
      e.stopPropagation();
      onChange(Number(beforeIndex), Number(afterIndex));
      setBeforeIndex(-1);
      setAfterIndex(-1);
      setDraggable(false);
    },
    [onChange, beforeIndex, afterIndex],
  );
  const Handle = () => (
    <button
      className={styles.button}
      onMouseDown={() => setDraggable(true)}
      // TODO: DnDのキーボード操作を実装する際に消す。それまではフォーカスを当てない。(当たっても何もできないため)
      tabIndex={-1}
    >
      <i className={`material-icons ${styles.handle}`}>drag_handle</i>
    </button>
  );

  return {
    onDragStart,
    onDragOver,
    onDragEnd,
    targetIndex: afterIndex,
    draggable,
    setDraggable,
    Handle,
  };
};
