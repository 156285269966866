import { useCallback } from 'react';

import type { Props } from './Code';

export const useCode = ({ editor }: Props) => {
  const toggleCodeHandler = useCallback(() => {
    editor.chain().focus().toggleCode().run();
  }, [editor]);
  const isActiveCode = editor.isActive('code');

  return {
    toggleCodeHandler,
    isActiveCode,
  };
};
