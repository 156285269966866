import { API, graphqlOperation } from 'aws-amplify';

import type { ServiceLanguage } from '@/entity/serviceLanguage';

import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';

export type UpdateParams = {
  serviceId: string;
  serviceLanguage: ServiceLanguage;
};

export type UpdateResult =
  | {
      result: {
        message: string;
        result: boolean;
      };
    }
  | {
      result: null;
    };

export const languageSettingsRepository = (serviceId: string) => {
  const getServiceLanguage = async (): Promise<ServiceLanguage> => {
    try {
      const result = await API.graphql(
        graphqlOperation(queries.getServiceLanguage, {
          serviceId,
        }),
      );
      const typedResult = result as {
        data: { getServiceLanguage: ServiceLanguage };
      };
      if (typedResult.data.getServiceLanguage != null) {
        return typedResult.data.getServiceLanguage;
      }
      return 'ja';
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not get service language');
    }
  };

  const updateServiceLanguage = async (
    serviceLanguage: ServiceLanguage,
  ): Promise<UpdateResult> => {
    try {
      const result = await API.graphql(
        graphqlOperation(mutations.updateServiceLanguage, {
          serviceId,
          serviceLanguage,
        }),
      );
      const typedResult = result as {
        data: { updateServiceLanguage: { message: string; result: boolean } };
      };
      if (typedResult.data.updateServiceLanguage != null) {
        return { result: typedResult.data.updateServiceLanguage };
      }

      return { result: null };
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not update service language');
    }
  };
  return { getServiceLanguage, updateServiceLanguage };
};
