type AuthConfig = {
  userPoolId: string;
  userPoolWebClientId: string;
  identityPoolId: string;
};

export const localStorage = {
  apiPath: 'apiPath',
  service: 'microCMS.service',
  amplifyAuthConfig: 'microCMS.Amplify.AuthConfig',
};

export function storeAmplifyAuthConfig(authConfig: AuthConfig) {
  window.localStorage.setItem(
    localStorage.amplifyAuthConfig,
    JSON.stringify(authConfig),
  );
}

export function loadAmplifyAuthConfig(): AuthConfig | null {
  const authConfigText = window.localStorage.getItem(
    localStorage.amplifyAuthConfig,
  );
  return authConfigText ? JSON.parse(authConfigText) : null;
}

export function deleteAmplifyAuthConfig(): void {
  window.localStorage.removeItem(localStorage.amplifyAuthConfig);
}
