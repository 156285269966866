import { useTranslation } from 'react-i18next';

import { useClear } from './useClear';
import { IconButton } from '../../../IconButton';

import type { Editor } from '@tiptap/react';

import styles from './clear.module.css';

import { ClearIcon } from '@/views/Common/Icons';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  setClearHandler: () => void;
  editable: boolean;
};

export const ClearView: React.FC<ViewProps> = ({
  setClearHandler,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <IconButton
      icon={<ClearIcon className={styles.icon} />}
      aria-label={t('Clear format')}
      isActive={false}
      onClick={setClearHandler}
      disabled={!editable}
    />
  );
};

export const Clear: React.FC<Props> = ({ editor }) => {
  const { setClearHandler } = useClear({ editor });

  return (
    <ClearView setClearHandler={setClearHandler} editable={editor.isEditable} />
  );
};
