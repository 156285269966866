import { connect } from 'react-redux';

import { authOperations } from '../../../ducks/auth';
import Delete from './Delete';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  signOut: authOperations.signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(Delete);
