import { useEditor } from '@tiptap/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { validateMedium } from '@/components/Validations';

import { usePermissionIsHaveLeastOne } from '@/hooks/Role/useMyRoles';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';

import { extensions } from './extensions/extensions';

import type { RichEditorV2Options } from '@/constants/richEditorOptions';
import type { CustomClass as CustomClassType } from '@/types/field';
import type { JSONContent } from '@tiptap/react';
import type { EditorView } from 'prosemirror-view';

import styles from './richeditorv2.module.css';

import { useUploadMedium } from '@/views/Common/medium/useMediumWriter';

type Props = {
  onChange: (value?: JSONContent) => void;
  defaultValue: JSONContent;
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
  customClassList: CustomClassType[];
  editable?: boolean;
};

export const useRichEditorV2 = ({
  onChange,
  defaultValue,
  richEditorV2Options,
  editable,
}: Props) => {
  const { t } = useTranslation('richEditorV2');
  const { service } = useGetMyService();
  const { currentPlan } = useStripeActions(service);
  const { addToast } = useToasts();
  const [hasCreatePermission] = usePermissionIsHaveLeastOne(
    'mediumPermission',
    'create',
  );

  const { uploadMediumAsync, uploadMediumProgress } = useUploadMedium({
    serviceId: service?.partitionKey,
    domain: service?.domain,
  });

  const editor = useEditor({
    extensions: extensions(richEditorV2Options, service?.partitionKey),
    content: defaultValue,
    editorProps: {
      attributes: {
        class: styles.content,
      },
      handlePaste(view, event) {
        const files = Array.from(event.clipboardData?.files ?? []);
        if (files.length !== 0) {
          const result = handleUploadMedium(view, files);
          return result;
        }

        return false;
      },
      handleDrop(view, event) {
        const files = event.dataTransfer?.files ?? [];
        if (files.length !== 0) {
          const result = handleUploadMedium(view, files);
          return result;
        }

        return false;
      },
      transformPastedHTML(html) {
        // 旧リッチエディタはinline (imgタグがpタグで囲まれている) のためpタグを削除する
        return html.replace(/<p[^>]*>\s*(<img[^>]+>)\s*<\/p>/g, '$1');
      },
    },
    editable: editable,
    onUpdate({ editor }) {
      onChange(editor.isEmpty ? undefined : editor.getJSON());
    },
  });

  // ペースト時とドラッグ&ドロップ時に画像をアップロードして画像コンテンツを追加する処理。各処理でtrueを返すことで画像のリンクに飛んでしまう本来の挙動を制御している
  const handleUploadMedium = (view: EditorView, files: File[] | FileList) => {
    if (!hasCreatePermission) {
      addToast(t('You do not have permission to upload images.'), {
        appearance: 'error',
      });
      return true;
    }

    if (files.length > 10) {
      addToast(t('Please add up to 10 images'), {
        appearance: 'error',
      });
      return true;
    }

    // アップロードできるファイルかチェック
    for (const file of files) {
      if (!file.type.match(/^image\/.*/)) {
        addToast(t('Not supported file format'), {
          appearance: 'error',
        });
        return true;
      }

      const res = validateMedium(file, currentPlan);
      if (res !== null) {
        return true;
      }
    }

    uploadMediumAsync(files).then((mediumId) => {
      if (mediumId && service?.partitionKey) {
        for (const id of mediumId) {
          const { schema, selection } = view.state;

          const node = schema.nodes.imageBlock.create({
            medium: {
              mediumId: id,
              serviceId: service.partitionKey,
            },
          });

          const transaction = view.state.tr.insert(selection.anchor, node);
          view.dispatch(transaction);
        }
      }
    });

    return true;
  };

  // NOTE:リッチエディタオプションの判定が複雑なものはここに書く
  const hasHeadingOption = isExistHeadingOption(richEditorV2Options);
  const hasDecorationOption = isExistDecorationOption(richEditorV2Options);
  const hasListOption = isExistListOption(richEditorV2Options);
  const hasBlockOption = isExistBlockOption(richEditorV2Options);
  const hasEmbedOption = isExistEmbedOption(richEditorV2Options);

  // リッチエディタの全画面表示の処理
  const [isFullScreen, setIsFullScreen] = useState(false);
  const scrollTop = useRef(0);
  const $mainEl = document.getElementById('main');
  const $customFieldHooks = document.querySelectorAll<HTMLElement>(
    '.customFieldHook, .customFieldDragAreaHook',
  );

  const handleFullScreen = useCallback(() => {
    setIsFullScreen(true);
    if ($mainEl === null) {
      return;
    }
    $customFieldHooks.forEach(($el) => {
      $el.style.position = 'static';
    });
    scrollTop.current = $mainEl.scrollTop;
    $mainEl.scrollTop = 0;
    $mainEl.style.overflow = 'hidden';
  }, [$customFieldHooks, $mainEl]);

  const handleCloseFullScreen = useCallback(() => {
    setIsFullScreen(false);
    if ($mainEl === null) {
      return;
    }
    $customFieldHooks.forEach(($el) => {
      $el.style.position = 'relative';
    });
    $mainEl.style.overflow = 'auto';
    setTimeout(() => ($mainEl.scrollTop = scrollTop.current), 0);
  }, [$customFieldHooks, $mainEl]);

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.code === 'Escape' && isFullScreen) {
        handleCloseFullScreen();
      }
      if (e.shiftKey && e.code === 'Tab' && isFullScreen) {
        return;
      }

      if (e.code === 'Tab' && isFullScreen && editor?.isFocused) {
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyUp);
    return () => {
      window.addEventListener('keydown', handleKeyUp);
    };
  }, [editor?.isFocused, handleCloseFullScreen, isFullScreen]);

  return {
    progress: uploadMediumProgress,
    editor,
    hasHeadingOption,
    hasDecorationOption,
    hasListOption,
    hasBlockOption,
    hasEmbedOption,
    isFullScreen,
    handleFullScreen,
    handleCloseFullScreen,
  };
};

export const isExistHeadingOption = (
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>,
) => {
  return richEditorV2Options.some((option) => {
    return /^(headerOne|headerTwo|headerThree|headerFour|headerFive|paragraph)$/.test(
      option,
    );
  });
};

export const isExistDecorationOption = (
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>,
) => {
  return richEditorV2Options.some((option) => {
    return /^(bold|italic|underline|strike|color|code|textAlign)$/.test(option);
  });
};

export const isExistListOption = (
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>,
) => {
  return richEditorV2Options.some((option) => {
    return /^(listBullet|listOrdered|)$/.test(option);
  });
};

export const isExistBlockOption = (
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>,
) => {
  return richEditorV2Options.some((option) => {
    return /^(horizontalRule|blockquote|codeBlock|table)$/.test(option);
  });
};

export const isExistEmbedOption = (
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>,
) => {
  return richEditorV2Options.some((option) => {
    return /^(link|image|oembedly)$/.test(option);
  });
};
