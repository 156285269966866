import { useCallback } from 'react';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useInput, useLoading } from '../../../hooks';
import { useExceptionPermissionIsHaveLeastOne } from '../../../hooks/Role/useMyRoles';
import Feedback from '../../Feedback';
import Head from '../../Head';
import { validateApiEndpoint, validateApiName } from '../../Validations';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import Textfield from '../../ui/Textfield';

import type { GetPutResultArgs } from '@/ducks/api/selector';
import type { ApiList, MigrateApi } from '@/entity/api';

import styles from './nameSettings.module.css';

import { apiSelectors } from '@/ducks/api';
import { putApi } from '@/ducks/api/operations';
import { apiListSelectors } from '@/ducks/apiList';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AlertDialog } from '@/views/Common/Ui/AlertDialog';
import { useApiListInvalidateCache } from '@/views/Common/api/invalidateCache';

const NameSettings: React.FC = () => {
  const { t } = useTranslation('nameSettings');
  const apiListInvalidateCache = useApiListInvalidateCache();

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint: _endpoint } = useParams<{
    endpoint: string;
  }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        _endpoint,
      ) as MigrateApi,
  );
  const dispatch = useAppDispatch();
  const putResult = useAppSelector(
    (state) =>
      apiSelectors.getPutResult(state.apiState as GetPutResultArgs).putResult,
  );

  const [updatable] = useExceptionPermissionIsHaveLeastOne(
    api.partitionKey,
    'apiUpdate',
  );

  // @ts-expect-error
  const [name, onChangeName, nameError] = useInput(undefined, validateApiName);
  const [endpoint, onChangeEndpoint, endpointError] = useInput(
    undefined,
    // @ts-expect-error
    validateApiEndpoint,
  );
  const [loading, startLoading] = useLoading(putResult !== undefined);
  const submit = useCallback(() => {
    startLoading();
    // @ts-expect-error
    dispatch(putApi({ apiId: api.partitionKey, name, endpoint })).then(() => {
      apiListInvalidateCache();
    });
  }, [
    api.partitionKey,
    apiListInvalidateCache,
    dispatch,
    endpoint,
    name,
    startLoading,
  ]);
  return (
    <div>
      <div className={styles.wrapper}>
        <Head title={t('API/Settings')} />
        <h2 className={styles.title}>{t('Basic Information')}</h2>
        <Fieldset
          legend={t('API name')}
          description={t('Enter description of the API.')}
        >
          <Textfield
            type="text"
            placeholder={t(
              'E.g., announcements, blogs, configuration files, etc...',
            )}
            defaultValue={api.apiName}
            onChange={onChangeName}
            errorText={nameError}
            readOnly={!updatable}
          />
        </Fieldset>
        <Fieldset
          legend={t('Endpoint')}
          description={t('Specify API endpoint name.')}
        >
          <div className={styles.flexField}>
            <span className={styles.host}>
              {window.location.origin}/api/v1/
            </span>
            <Textfield
              className={styles.endpoint}
              type="text"
              placeholder="news"
              defaultValue={api.apiEndpoint}
              onChange={onChangeEndpoint}
              errorText={endpointError}
              readOnly={!updatable}
            />
          </div>
        </Fieldset>
        {updatable && (
          <div className={styles.actions}>
            <Feedback
              type={putResult === false ? 'failure' : 'success'}
              message={
                putResult === false
                  ? t('Failed to make changes.')
                  : putResult === true
                    ? t('Changes have been made.')
                    : undefined
              }
            />
            {/* endpoint変更時のみ確認ダイアログを表示する */}
            {endpoint === undefined ? (
              <Button
                type="primary"
                disabled={loading || nameError || endpointError}
                value={t('Save changes')}
                onClick={submit}
              />
            ) : (
              <AlertDialog
                trigger={
                  <Button
                    type="primary"
                    disabled={loading || nameError || endpointError}
                    value={t('Save changes')}
                  />
                }
                title={t('Change API endpoints')}
                description={t(
                  'Are you sure you want to change the endpoint? If you are already using the API, the change may cause problems.',
                )}
                buttonText={t('Save changes')}
                onSubmit={submit}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NameSettings;
