import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

import { IconButton } from './IconButton';
import { TextButton } from './TextButton';
import { useInputRichEditorV2OptionsField } from './useInputRichEditorV2OptionsField';

import type { RichEditorV2Options } from '@/constants/richEditorOptions';

import styles from './inputRichEditorV2OptionsField.module.css';

import { richEditorV2Options } from '@/constants/richEditorOptions';
import {
  BlockquoteIcon,
  BoldIcon,
  BulletListIcon,
  CodeBlockIcon,
  CodeIcon,
  ColorIcon,
  EmbedBlockIcon,
  HorizontalRuleIcon,
  ImageBlockIcon,
  ItalicIcon,
  LinkIcon,
  OrderedListIcon,
  StrikeIcon,
  TableIcon,
  TextAlignLeftIcon,
  UnderlineIcon,
} from '@/views/Common/Icons/RichEditorV2Icons';

export type Props = {
  options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
  setOptions: React.Dispatch<string[]>;
};

export type ViewProps = {
  options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
  onChangeOption: (value: string) => () => void;
};

export const InputRichEditorV2OptionsFieldView: React.FC<ViewProps> = ({
  onChangeOption,
  options,
}) => {
  const { t } = useTranslation('inputRichEditorV2OptionsField');
  const flags = useFlags();

  return (
    <div>
      <div className={styles.section}>
        <TextButton
          text={t('Heading1')}
          onChangeOption={onChangeOption('headerOne')}
          isSelected={options.includes('headerOne')}
        />
        <TextButton
          text={t('Heading2')}
          onChangeOption={onChangeOption('headerTwo')}
          isSelected={options.includes('headerTwo')}
        />
        <TextButton
          text={t('Heading3')}
          onChangeOption={onChangeOption('headerThree')}
          isSelected={options.includes('headerThree')}
        />
        <TextButton
          text={t('Heading4')}
          onChangeOption={onChangeOption('headerFour')}
          isSelected={options.includes('headerFour')}
        />
        <TextButton
          text={t('Heading5')}
          onChangeOption={onChangeOption('headerFive')}
          isSelected={options.includes('headerFive')}
        />
        <TextButton
          text={t('Paragraph')}
          onChangeOption={onChangeOption('paragraph')}
          isSelected={options.includes('paragraph')}
        />
      </div>
      <div className={styles.section}>
        <IconButton
          icon={<BoldIcon />}
          label={t('Bold')}
          onChangeOption={onChangeOption('bold')}
          isSelected={options.includes('bold')}
        />
        <IconButton
          icon={<ItalicIcon />}
          label={t('Italic')}
          onChangeOption={onChangeOption('italic')}
          isSelected={options.includes('italic')}
        />
        <IconButton
          icon={<UnderlineIcon />}
          label={t('Underline')}
          onChangeOption={onChangeOption('underline')}
          isSelected={options.includes('underline')}
        />
        <IconButton
          icon={<StrikeIcon />}
          label={t('Strike')}
          onChangeOption={onChangeOption('strike')}
          isSelected={options.includes('strike')}
        />
        <IconButton
          icon={<CodeIcon />}
          label={t('Code')}
          onChangeOption={onChangeOption('code')}
          isSelected={options.includes('code')}
        />
        <IconButton
          icon={<ColorIcon />}
          label={t('Color')}
          onChangeOption={onChangeOption('color')}
          isSelected={options.includes('color')}
        />
        <IconButton
          icon={<TextAlignLeftIcon />}
          label={t('TextAlign')}
          onChangeOption={onChangeOption('textAlign')}
          isSelected={options.includes('textAlign')}
        />
      </div>
      <div className={styles.section}>
        <IconButton
          icon={<HorizontalRuleIcon />}
          label={t('HorizontalRule')}
          onChangeOption={onChangeOption('horizontalRule')}
          isSelected={options.includes('horizontalRule')}
        />
        <IconButton
          icon={<BlockquoteIcon />}
          label={t('Blockquote')}
          onChangeOption={onChangeOption('blockquote')}
          isSelected={options.includes('blockquote')}
        />
        <IconButton
          icon={<CodeBlockIcon />}
          label={t('CodeBlock')}
          onChangeOption={onChangeOption('codeBlock')}
          isSelected={options.includes('codeBlock')}
        />
        <IconButton
          icon={<TableIcon />}
          label={t('Table')}
          onChangeOption={onChangeOption('table')}
          isSelected={options.includes('table')}
        />
      </div>
      <div className={styles.section}>
        <IconButton
          icon={<BulletListIcon />}
          label={t('ListBullet')}
          onChangeOption={onChangeOption('listBullet')}
          isSelected={options.includes('listBullet')}
        />
        <IconButton
          icon={<OrderedListIcon />}
          label={t('ListOrdered')}
          onChangeOption={onChangeOption('listOrdered')}
          isSelected={options.includes('listOrdered')}
        />
      </div>
      <div className={styles.section}>
        <IconButton
          icon={<LinkIcon />}
          label={t('Link')}
          onChangeOption={onChangeOption('link')}
          isSelected={options.includes('link')}
        />
        <IconButton
          icon={<ImageBlockIcon />}
          label={t('Image')}
          onChangeOption={onChangeOption('image')}
          isSelected={options.includes('image')}
        />
        <IconButton
          icon={<EmbedBlockIcon />}
          label={t('Oembedly')}
          onChangeOption={onChangeOption('oembedly')}
          isSelected={options.includes('oembedly')}
        />
      </div>
      <div className={styles.section}>
        <TextButton
          text={t('Custom')}
          onChangeOption={onChangeOption('customClass')}
          isSelected={options.includes('customClass')}
        />
      </div>
    </div>
  );
};

export const InputRichEditorV2OptionsField: React.FC<Props> = ({
  options = richEditorV2Options,
  setOptions,
}) => {
  // optionsがnullの場合はすべてのオプションを付ける
  const targetOptions = options === null ? richEditorV2Options : options;

  const { onChangeOption } = useInputRichEditorV2OptionsField({
    options: targetOptions,
    setOptions,
  });

  return (
    <InputRichEditorV2OptionsFieldView
      onChangeOption={onChangeOption}
      options={targetOptions}
    />
  );
};
