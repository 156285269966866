import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getSSOHost } from '../../util';
import Feedback from '../Feedback';
import Button from '../ui/Button';
import Footer from '../ui/Footer';

import styles from './samlSignIn.module.css';

const SAMLSignin: React.FC = () => {
  const { t } = useTranslation('samlSignin');
  const [error, setError] = useState(
    new URLSearchParams(window.location.search).get('error'),
  );
  setTimeout(() => {
    setError(null);
  }, 1000 * 3);

  const onClickSAMLLoginButton = () => {
    const params = new URLSearchParams(window.location.search);

    const loginToken = params.get('loginToken');
    if (!loginToken) {
      // @ts-expect-error
      window.location = `/`;
      return;
    }

    const host = getSSOHost();

    // @ts-expect-error
    window.location = `${host}/saml/signin?loginToken=${loginToken}`;
  };

  // TODO: samlOnly が設定されているか確認
  // TODO: samlOnly !== true であれば ID, パスワードの入力フォームを表示？

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img
            className={styles.logoImg}
            src="/images/logo_black.svg"
            alt="microCMS"
          />
        </div>
        <p className={styles.description}>
          {t(
            'You can use microCMS with your companys authentication provider.',
          )}
        </p>
        <div className={styles.formBox}>
          <div className={styles.actions}>
            <Feedback message={error || undefined} />
            <Button
              type="secondary"
              value={t('Log in with SAML')}
              size="full"
              onClick={onClickSAMLLoginButton}
            />
          </div>
          <div className={styles.links}>
            <Link to="/signin" className={styles.link}>
              {t('Click here for login')}
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SAMLSignin;
