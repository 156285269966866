import { useCallback, useState } from 'react';

import { useImageBlockQuery } from './useImageBlockQuery';

import type { ImageAttributes } from '.';

export const useImageBlock = (
  updateAttributes: (attributes: ImageAttributes) => void,
  medium: ImageAttributes['medium'],
) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onToggleImageSettings = useCallback((nextState?: boolean) => {
    setIsOpen((prevState) => nextState ?? !prevState);
  }, []);

  const { useGetMediumAttributes } = useImageBlockQuery();
  const { data: mediumAttributes } = useGetMediumAttributes({
    serviceId: medium?.serviceId,
    mediumId: medium?.mediumId,
  });

  const onUpdateImgAttributes = useCallback(
    (imgAttributes: ImageAttributes) => {
      updateAttributes(imgAttributes);
      onToggleImageSettings();
    },
    [onToggleImageSettings, updateAttributes],
  );

  return {
    mediumAttributes,
    isOpen,
    onToggleImageSettings,
    onUpdateImgAttributes,
  };
};
