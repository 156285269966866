import Selectfield from '@/components/ui/Selectfield';

import { useFormRoleDispatch, useFormRoleState } from '../../../provider';

import type { PropsWithECP } from '../_types';
import type { FC } from 'react';

const SelectApiSchema: FC<PropsWithECP> = ({ targetECP }) => {
  const { isAdminRole, apiSchemas } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  return (
    <Selectfield
      name="apiId"
      value={targetECP.apiId}
      disabled={disabled}
      onChange={(e) => {
        const nextApiId = e.target.value;
        dispatch({
          type: 'set-ec-permission-apiid',
          payload: { id: targetECP.id, apiId: nextApiId },
        });
      }}
      data-testid="selectApiSchema"
    >
      {apiSchemas.map((apiSchema) => {
        return (
          <option key={apiSchema.partitionKey} value={apiSchema.partitionKey}>
            {apiSchema.apiName}
          </option>
        );
      })}
    </Selectfield>
  );
};

export default SelectApiSchema;
