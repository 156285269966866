import { useMemo, useContext } from 'react';

import { myRolesContext } from '@/hooks/Role/useMyRoles';

import type { ReviewRequestUpdateLevel, Role } from '@/entity/role';

type ReviewRequestPermissionArgs = {
  roles: Role[] | undefined;
  apiId: string;
};

export const checkReviewRequestUpdatePermission = ({
  roles,
  apiId,
}: ReviewRequestPermissionArgs): ReviewRequestUpdateLevel => {
  if (!roles) {
    return 'NONE';
  }

  const readLevels = roles.map((role) => {
    const canReadReviewRequestPermission =
      role.exceptionContentPermissions?.find((ecp) => {
        return ecp.apiId === apiId && ecp.reviewRequestPermission?.updateLevel;
      });

    return (
      canReadReviewRequestPermission?.reviewRequestPermission?.updateLevel ||
      role.reviewRequestPermission.updateLevel
    );
  });

  const hasReviewRequestPermission = readLevels.includes('ALL')
    ? 'ALL'
    : readLevels.includes('EDIT')
      ? 'EDIT'
      : 'NONE';
  return hasReviewRequestPermission;
};

export const useReviewRequestUpdatePermission = ({
  apiId,
}: Pick<ReviewRequestPermissionArgs, 'apiId'>): ReviewRequestUpdateLevel => {
  const { roles } = useContext(myRolesContext);

  const hasPermission = useMemo(() => {
    return checkReviewRequestUpdatePermission({ roles, apiId });
  }, [apiId, roles]);

  return hasPermission;
};
