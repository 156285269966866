import deepEqual from 'deep-equal';
import isEmpty from 'is-empty';
import { nanoid } from 'nanoid';
import { useMemo, useState } from 'react';

import { ContentsComparisonComponent } from '../../../ContentsComparison';
import { ExpandedButton } from '../../parts/ExpandedButton';

import type { CustomField } from '@/types/contents';

import styles from './repeater.module.css';

type Props = {
  customFields: CustomField[];
  sinceValue: ({ fieldId: string } & Record<string, unknown>)[];
  untilValue: ({ fieldId: string } & Record<string, unknown>)[];
};

const Repeater: React.FC<Props> = ({
  customFields,
  sinceValue,
  untilValue,
}) => {
  const customFieldMap = useMemo(() => {
    const map: Record<string, CustomField> = {};
    for (const field of customFields) {
      map[field.fieldId] = field;
    }
    return map;
  }, [customFields]);

  const comparisonList = useMemo(
    () => buildComparisonList({ sinceValue, untilValue }),
    [sinceValue, untilValue],
  );

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const viewNodes = useMemo(
    () => buildViewNodes({ comparisonList, expandedKeys }),
    [comparisonList, expandedKeys],
  );
  const handleExpand = (keys: string[]) => {
    setExpandedKeys((prev) => prev.concat(keys));
  };

  return (
    <div className={styles.main}>
      {viewNodes.map((node) => {
        switch (node.type) {
          case 'expanded': {
            const comparison = comparisonList.find(
              (comparison) => comparison.key === node.keys[0],
            );

            if (!comparison) {
              // 実装上ここには来ないはずなのでエラーを返す
              throw new Error('comparison not found');
            }

            const customField = customFieldMap[comparison.fieldId];

            if (!customField) {
              // すでにカスタムフィールドが削除されている場合は何も表示しない
              return null;
            }

            return (
              <div key={comparison.key} className={styles.field}>
                <p className={styles.name}>{customField.name}</p>
                <ContentsComparisonComponent
                  apiFields={customField.fields}
                  customFields={customFields}
                  sinceContent={comparison.since ?? {}}
                  untilContent={comparison.until ?? {}}
                />
              </div>
            );
          }
          case 'omitted': {
            return (
              <ExpandedButton
                key={node.keys.join('-')}
                onClick={() => handleExpand(node.keys)}
                className={styles.expandedButton}
              />
            );
          }
        }
      })}
    </div>
  );
};

const buildComparisonList = ({
  sinceValue,
  untilValue,
}: {
  sinceValue: ({ fieldId: string } & Record<string, unknown>)[];
  untilValue: ({ fieldId: string } & Record<string, unknown>)[];
}) => {
  const list: {
    key: string;
    fieldId: string;
    since: Record<string, unknown> | null;
    until: Record<string, unknown> | null;
  }[] = [];
  const sinceList = [...sinceValue];
  const untilList = [...untilValue];

  const unAddedUntil: ({ fieldId: string } & Record<string, unknown>)[] = [];

  for (let i = 0; i < untilList.length; i++) {
    const until = untilList[i];

    const sameFieldSinceIndex = sinceList.findIndex(
      (since) => since.fieldId === until.fieldId,
    );

    if (sameFieldSinceIndex !== -1) {
      if (unAddedUntil.length > 0) {
        list.push(
          ...unAddedUntil.splice(0).map((value) => ({
            key: nanoid(),
            fieldId: value.fieldId,
            since: null,
            until: value,
          })),
        );
      }

      const since = sinceList[sameFieldSinceIndex];
      const unAddedSince = sinceList.splice(0, sameFieldSinceIndex);
      list.push(
        ...unAddedSince.map((since) => ({
          key: nanoid(),
          fieldId: since.fieldId,
          since,
          until: null,
        })),
        {
          key: nanoid(),
          fieldId: until.fieldId,
          since,
          until,
        },
      );
      sinceList.shift();
    } else {
      unAddedUntil.push(until);
    }
  }

  if (sinceList.length > 0) {
    list.push(
      ...sinceList.map((value) => ({
        key: nanoid(),
        fieldId: value.fieldId,
        since: value,
        until: null,
      })),
    );
  }

  if (unAddedUntil.length > 0) {
    list.push(
      ...unAddedUntil.map((value) => ({
        key: nanoid(),
        fieldId: value.fieldId,
        since: null,
        until: value,
      })),
    );
  }

  return list;
};

const buildViewNodes = ({
  comparisonList,
  expandedKeys,
}: {
  comparisonList: ReturnType<typeof buildComparisonList>;
  expandedKeys: string[];
}) => {
  const result: {
    keys: string[];
    type: 'expanded' | 'omitted';
  }[] = [];

  for (let i = 0; i < comparisonList.length; i++) {
    const item = comparisonList[i];

    // フィールド内に変更がない場合は省略
    if (
      (isEmpty(item.since) && isEmpty(item.until)) ||
      deepEqual(item.since, item.until)
    ) {
      if (expandedKeys.includes(item.key)) {
        result.push({ keys: [item.key], type: 'expanded' });
      } else {
        const latestItem = result.at(-1);
        if (latestItem && latestItem.type === 'omitted') {
          latestItem.keys.push(item.key);
        } else {
          result.push({ keys: [item.key], type: 'omitted' });
        }
      }
    } else {
      result.push({ keys: [item.key], type: 'expanded' });
    }
  }

  return result;
};

export { Repeater, buildComparisonList, buildViewNodes };
