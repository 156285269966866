import cx from 'classnames';
import type React from 'react';
import { useCallback, useMemo } from 'react';

import { useDragAndDrop } from '../../../hooks/DragAndDrop/useDragAndDrop';

import type { CustomField } from '../../../types/contents';

import styles from './orderSelectField.module.css';

type Props = {
  customFields: CustomField[];
  list: string[];
  setList: React.Dispatch<string[]>;
};

export const OrderRepeaterField: React.FC<Props> = ({
  customFields = [],
  list = [],
  setList,
}) => {
  const onChangeOrder = useCallback(
    (x: any, y: any) => {
      if (x === y) {
        return;
      }
      const copy = [...list];
      copy.splice(y, 0, list[x]);
      if (x < y) {
        copy.splice(x, 1);
      } else {
        copy.splice(x + 1, 1);
      }
      setList(copy);
    },
    [list, setList],
  );
  const { Handle, draggable, onDragStart, onDragOver, onDragEnd, targetIndex } =
    // @ts-ignore
    useDragAndDrop(onChangeOrder);
  const fields = useMemo((): { [index: string]: string } => {
    return customFields.reduce((a, b) => {
      return {
        ...a,
        [b.createdAt]: `${b.name} (${b.fieldId})`,
      };
    }, {});
  }, [customFields]);
  return (
    <ul>
      {list &&
        list.map((item, i) => (
          <li
            className={
              Number(targetIndex) === i + 1 && i === list.length - 1
                ? cx(styles.selectList, styles.isDragOver, styles.isNext)
                : Number(targetIndex) === i
                  ? cx(styles.selectList, styles.isDragOver)
                  : styles.selectList
            }
            key={item}
            draggable={draggable}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            data-index={i}
          >
            <span className={styles.handle}>
              <Handle />
            </span>
            <p>{fields[item]}</p>
          </li>
        ))}
    </ul>
  );
};
