import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Member from '../Member';
import Button from '../ui/Button';
import Textarea from '../ui/Textarea';
import { validateReviewRequestComment } from '../Validations';

import styles from './postComment.module.css';

import { useInput } from '@/views/Common/useInput';

type createReviewRequestCommentArgs = {
  comment: string;
  setComment: (value: string) => void;
};

type Props = {
  username: string;
  createReviewRequestComment: (args: createReviewRequestCommentArgs) => void;
  createReviewRequestCommentLoading: boolean;
};

const PostComment: React.FC<Props> = ({
  username,
  createReviewRequestComment,
  createReviewRequestCommentLoading,
}) => {
  const { t } = useTranslation('editReview');

  const [comment, onChangeComment, commentError, setComment] = useInput(
    '',
    validateReviewRequestComment,
  );

  const createComment = useCallback(() => {
    comment && createReviewRequestComment({ comment, setComment });
  }, [comment, createReviewRequestComment, setComment]);

  return (
    <div className={styles.main}>
      <div className={styles.post}>
        <Member username={username} imageOnly={true} />
        <div className={styles.postArea}>
          <Textarea
            className={styles.postText}
            placeholder={t('To Comment')}
            onChange={onChangeComment}
            value={comment}
          />
          <Button
            type="primary"
            value={t('Post')}
            onClick={createComment}
            disabled={
              !!commentError ||
              comment.length === 0 ||
              createReviewRequestCommentLoading
            }
          />
        </div>
      </div>
    </div>
  );
};

export { PostComment };
