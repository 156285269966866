import { useTranslation } from 'react-i18next';

import type { Field } from '@/types/field';

import styles from './customField.module.css';

type Props = {
  field: Field;
  customFieldContent?: any;
};

const CustomField: React.FC<Props> = ({ field, customFieldContent }) => {
  const { t } = useTranslation('contentListCustomField');

  const customFieldDisplayItem = field.customFieldDisplayItem;
  if (customFieldContent != null && customFieldDisplayItem != null) {
    const displayItem = customFieldContent[customFieldDisplayItem];
    if (displayItem == null) {
      return (
        <span className={styles.nowrap}>
          {t('{{count}} fields', {
            count: Object.keys(customFieldContent).length,
          })}
        </span>
      );
    } else if (displayItem['text'] != null) {
      return <span className={styles.nowrap}>{displayItem['text']}</span>;
    }
  }

  return (
    <span className={styles.nowrap}>
      {customFieldContent
        ? `${t('{{count}} fields', {
            count: Object.keys(customFieldContent).length,
          })}`
        : '-'}
    </span>
  );
};

export default CustomField;
