import type {
  DeleteParams,
  UpdateParams,
  ResultResponse,
} from '@/repository/samlRepository';
import { samlRepository } from '@/repository/samlRepository';

import type { SAMLConfiguration } from '@/entity/saml';

export const findSAMLConfiguration = async (
  serviceId: string,
): Promise<SAMLConfiguration> => {
  const { findSAMLConfiguration } = samlRepository();
  return await findSAMLConfiguration(serviceId);
};

export const updateSAMLConfiguration = async ({
  serviceId,
  idpMetadataURL,
  idpMetadataXML,
  defaultRoleId,
  samlOnly,
}: UpdateParams): Promise<ResultResponse> => {
  const { updateSAMLConfiguration } = samlRepository();
  return await updateSAMLConfiguration(
    serviceId,
    idpMetadataURL,
    idpMetadataXML,
    defaultRoleId,
    samlOnly,
  );
};

export const deleteSAMLConfiguration = async ({
  serviceId,
}: DeleteParams): Promise<ResultResponse> => {
  const { deleteSAMLConfiguration } = samlRepository();
  return await deleteSAMLConfiguration(serviceId);
};
