import { useTranslation } from 'react-i18next';

import { useRemoveReviewer } from '@/hooks/Review/useRemoveReviewer';

import ContentReservationModal from './ContentReservationModal';
import SelectReviewers from './SelectReviewers';
import Member from '../Member';
import IconButton from '../ui/IconButton';

import type { ContentReservationModalProps } from './ContentReservationModal';
import type { ReviewRequest } from '@/entity/review';

import styles from './sideMenu.module.css';

import { formatDateToMinutes } from '@/util/date';
import { Icon } from '@/views/Common/Ui/Icon';

type Props = {
  serviceId: string;
  reviewRequest: ReviewRequest;
  hasEditReviewUpdatePermission: boolean;
  updateReviewRequestLoading: boolean;
  updateReviewRequestReservationTime: ContentReservationModalProps['updateReviewRequestReservationTime'];
  updateReviewRequestReservationTimeLoading: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

const SideMenu: React.FC<Props> = ({
  serviceId,
  reviewRequest,
  hasEditReviewUpdatePermission,
  updateReviewRequestLoading,
  updateReviewRequestReservationTime,
  updateReviewRequestReservationTimeLoading,
  setEditing,
}) => {
  const { t } = useTranslation('editReview');

  const { removeReviewer, isLoading: isRemoveReviewerLoading } =
    useRemoveReviewer();

  return (
    <aside className={styles.aside}>
      <dl>
        <dt className={styles.label}>
          {hasEditReviewUpdatePermission ? (
            <SelectReviewers
              reviewerUserIds={
                reviewRequest.reviewers
                  ? reviewRequest.reviewers.map((reviewer) => reviewer.id)
                  : []
              }
              serviceId={serviceId}
              reviewRequestId={reviewRequest.reqId}
              triggerElement={
                <button
                  type="button"
                  className={styles.labelButton}
                  disabled={isRemoveReviewerLoading}
                >
                  {t('Reviewer')}
                  <Icon name="settings" />
                </button>
              }
            />
          ) : (
            <span className={styles.labelText}>{t('Reviewer')}</span>
          )}
        </dt>
        {reviewRequest.reviewers && reviewRequest.reviewers.length > 0 ? (
          <dd>
            <ul>
              {reviewRequest.reviewers.map((reviewer) => (
                <li key={reviewer.id} className={styles.reviewerList}>
                  <Member username={reviewer.id} theme="black" />
                  {hasEditReviewUpdatePermission && (
                    <IconButton
                      icon="clear"
                      onClick={() =>
                        removeReviewer({
                          serviceId,
                          reqId: reviewRequest.reqId,
                          reviewerId: reviewer.id,
                        })
                      }
                      disabled={isRemoveReviewerLoading}
                      aria-label={t('Deleted reviewer')}
                    />
                  )}
                </li>
              ))}
            </ul>
          </dd>
        ) : (
          <dd className={styles.notset}>
            {t('Reviewer has not been chosen.')}
          </dd>
        )}
      </dl>
      <dl>
        <dt className={styles.label}>
          <ContentReservationModal
            reviewRequest={reviewRequest}
            isProcessingUpdate={
              updateReviewRequestLoading ||
              updateReviewRequestReservationTimeLoading
            }
            setEditing={setEditing}
            updateReviewRequestReservationTime={
              updateReviewRequestReservationTime
            }
            hasEditReviewUpdatePermission={hasEditReviewUpdatePermission}
          />
        </dt>
        {reviewRequest.reservationTime && (
          <dd>
            <p className={styles.reservationTime}>
              {t('Starts at')}
              {formatDateToMinutes(reviewRequest.reservationTime)}
            </p>
          </dd>
        )}
        {reviewRequest.reservationStopTime && (
          <dd>
            <p className={styles.reservationTime}>
              {t('Stops')}
              {formatDateToMinutes(reviewRequest.reservationStopTime)}
            </p>
          </dd>
        )}
        {!reviewRequest.reservationTime &&
          !reviewRequest.reservationStopTime && (
            <dd className={styles.notset}>
              {t('Date and time have not been set.')}
            </dd>
          )}
      </dl>
    </aside>
  );
};

export { SideMenu };
