import type React from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUpdateMediumTags } from '@/hooks/Medium/useUpdateMediumTags';
import { useCreateMediumTags } from '@/hooks/MediumTags/useMediumTags';
import { useHasDiff } from '@/hooks/useHasDiff';

import { TagsInProgressGroup } from './TagsInProgressGroup';
import { TagsInputGroup } from './TagsInputGroup';
import { TagsSelectGroup } from './TagsSelectGroup';

import type { TagsInProgressGroupProps } from './TagsInProgressGroup';
import type { TagsInputGroupProps } from './TagsInputGroup';
import type { TagsSelectGroupProps } from './TagsSelectGroup';
import type { Medium } from '@/entity/medium';
import type {
  ListMediumTags,
  MediumTag,
  NewMediumTag,
} from '@/types/MediumTag';
import type { Service } from '@/types/services';

import styles from './tagsSettings.module.css';

import { ModalContent } from '@/views/Common/Ui/Modal';

export type SettedTag =
  | (MediumTag & { new?: undefined })
  | (NewMediumTag & { new: true });

type Props = {
  serviceId: Service['partitionKey'];
  medium: Medium;
  listMediumTags?: ListMediumTags;
  tags?: MediumTag[];
  closeModal: () => void;
};

const TagsSettings: React.FC<Props> = ({
  serviceId,
  medium,
  listMediumTags,
  tags,
  closeModal,
}) => {
  const { t } = useTranslation('tagsSettings');

  const [settedTags, setSettedTags] = useState<SettedTag[]>(tags ?? []);
  const newSettedTags = settedTags.filter((tag) => tag.new);

  const { hasDiff } = useHasDiff(tags, settedTags);

  const { createMediumTagsAsync, createMediumTagsLoading } =
    useCreateMediumTags();

  const { updateMediumTags, updateMediumTagsLoading } = useUpdateMediumTags();

  const errors: string[] = useMemo(() => {
    // メディアに登録できるタグの個数は10個まで
    const isMediumTagsLimitExceeded = settedTags.length > 10;
    const mediumTagsLimitExceededText = t(
      'Have exceeded the maximum number of tags that can be set for each media; please set no more than 10.',
    );

    // サービスに登録できるタグの個数は100個まで
    const listMediumTagsLimitExceededCount =
      listMediumTags !== undefined &&
      listMediumTags?.tags?.length + newSettedTags.length - 100;
    const isListMediumTagsLimitExceeded =
      typeof listMediumTagsLimitExceededCount === 'boolean'
        ? listMediumTagsLimitExceededCount
        : listMediumTagsLimitExceededCount > 0;
    const listMediumTagsLimitExceededText = t(
      'Have exceeded the maximum number of tags that can be set for each Service; please set no more than 100. ({{ count }} exceeded)',
      { count: Number(listMediumTagsLimitExceededCount) },
    );

    return [
      ...(isMediumTagsLimitExceeded ? [mediumTagsLimitExceededText] : []),
      ...(isListMediumTagsLimitExceeded
        ? [listMediumTagsLimitExceededText]
        : []),
    ];
  }, [listMediumTags, newSettedTags.length, settedTags.length, t]);

  const handleClickSaveButton = async () => {
    // 新規メディアタグを作成
    const newMediumTags =
      newSettedTags.length !== 0
        ? await createMediumTagsAsync({
            serviceId,
            newMediumTags: newSettedTags.map((tag) => ({
              name: tag.name,
            })),
          })
        : null;

    const targetMediumTagIds = settedTags
      .map((tag) => {
        if (tag.new) {
          const mediumTagId = newMediumTags?.find(
            (newMediumTag) => newMediumTag.name === tag.name,
          )?.mediumTagId;

          if (!mediumTagId) {
            return '';
          }

          return mediumTagId;
        } else {
          return tag.mediumTagId;
        }
      })
      .filter((v) => v);

    // メディアタグを更新
    updateMediumTags({
      medium,
      tags: targetMediumTagIds,
      closeModal,
    });
    // 新規に作成したタグの情報を「設定されたタグ」に反映する
    setSettedTags((settedTags) =>
      settedTags.map((tag) => {
        if (tag.new) {
          // newフラグがついているタグは新規に作成したタグなので、MediumTagに変換する
          const result = newMediumTags?.find(
            (newMediumTag) => newMediumTag.name === tag.name,
          );
          if (!result) {
            // 正しくタグが作成できていれば、ここには到達しない
            throw new Error('newMediumTags is undefined');
          }
          return result;
        } else {
          // newフラグがついていないタグは既存のタグなので、そのまま返す
          return tag;
        }
      }),
    );
  };

  const handleClickAddButton: TagsInputGroupProps['onClickAddButton'] = (
    tags,
  ) => {
    const newSettedTags: SettedTag[] = Array.from(new Set(tags))
      .flatMap((tagName) => {
        const existDuplicateTag = settedTags.some(
          ({ name }) => name === tagName,
        );

        return existDuplicateTag
          ? // 追加しようとしたタグと同名のタグが設定中のタグに存在する場合は追加しない
            []
          : [
              {
                name: tagName,
                new: true as const,
              },
            ];
      })
      .map((tag) => {
        const duplicateTag = listMediumTags?.tags?.find(
          (listMediumTag) => listMediumTag.name === tag.name,
        );

        return duplicateTag !== undefined
          ? // 追加しようとしたタグと同名のタグが既存タグに存在する場合は既存タグから追加する
            duplicateTag
          : tag;
      });
    setSettedTags((prevSettedTags) => [...prevSettedTags, ...newSettedTags]);
  };

  const handleClickSelect: TagsSelectGroupProps['onClickSelect'] = (id) => {
    const tag = listMediumTags?.tags?.find((tag) => tag.mediumTagId === id);

    if (settedTags.some((tag) => !tag.new && tag.mediumTagId === id)) {
      return;
    }

    setSettedTags((prevSettedTags) => [
      ...prevSettedTags,
      ...(tag !== undefined ? [tag] : []),
    ]);
  };

  const handleDelete: TagsInProgressGroupProps['onClickDeleteButton'] = (
    index,
  ) => {
    const newSettedTags = settedTags.filter((_, i) => i !== index);
    setSettedTags(newSettedTags);
  };
  const handleClickResetButton = () => {
    setSettedTags([]);
  };

  return (
    <ModalContent
      title={t('Set Tags')}
      size="medium"
      footer={{
        submitButtonProps: {
          value: t('Save changes'),
          onClick: handleClickSaveButton,
          disabled:
            errors.length > 0 ||
            createMediumTagsLoading ||
            updateMediumTagsLoading ||
            !hasDiff,
          type: 'primary',
          className: 'track-click-by-gtm',
          // data属性を付与するためには、eslintのルールを無効化する必要がある
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          'data-gtm-track-event-name': 'click_complete_tag_button',
        },
      }}
    >
      <div className={styles.main}>
        <TagsInputGroup onClickAddButton={handleClickAddButton} />
        <TagsSelectGroup
          tags={listMediumTags?.tags}
          onClickSelect={handleClickSelect}
        />
        <TagsInProgressGroup
          tags={settedTags}
          onClickDeleteButton={handleDelete}
          onClickResetButton={handleClickResetButton}
          errors={errors}
        />
      </div>
    </ModalContent>
  );
};

export default TagsSettings;
