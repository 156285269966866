import cx from 'classnames';
import type { Color as ColorType, RGBColor } from 'react-color';
import { useTranslation } from 'react-i18next';
import { ColorPicker } from './ColorPicker';

import { IconButton } from '../../../IconButton';
import styles from '../../../richeditorv2.module.css';
import { useColor } from './useColor';

import type { Editor } from '@tiptap/react';

import type { RichEditorV2Color } from '@/types/field';
import { ColorIcon } from '@/views/Common/Icons';
import { Popover } from '@/views/InputField/RichEditorV2/Popover';

export type Props = {
  editor: Editor;
  colorList: RichEditorV2Color[];
};

export type ViewProps = {
  setColorHandler: (color: RGBColor) => void;
  unsetColorHandler: () => void;
  isActiveColor: boolean;
  setCurrentColorHandler: () => void;
  color?: ColorType;
  isSetColor: boolean;
  editable: boolean;
  colorList: RichEditorV2Color[];
};

export const ColorView: React.FC<ViewProps> = ({
  setColorHandler,
  unsetColorHandler,
  isActiveColor,
  setCurrentColorHandler,
  color,
  isSetColor,
  editable,
  colorList,
}) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <Popover
      modal={true}
      trigger={
        <IconButton
          icon={
            <ColorIcon
              className={cx(styles.icon, {
                [styles.isActive]: isActiveColor,
              })}
            />
          }
          aria-label={t('Color')}
          isActive={isActiveColor}
          onClick={setCurrentColorHandler}
          disabled={!editable}
        />
      }
      body={
        <ColorPicker
          color={color}
          isSetColor={isSetColor}
          presets={colorList}
          setColorHandler={setColorHandler}
          unsetColorHandler={unsetColorHandler}
        />
      }
      disabled={!editable}
    />
  );
};

export const Color: React.FC<Props> = ({ editor, colorList }) => {
  const {
    setColorHandler,
    isActiveColor,
    setCurrentColorHandler,
    color,
    isSetColor,
    unsetColorHandler,
  } = useColor({
    editor,
    colorList,
  });

  return (
    <ColorView
      setColorHandler={setColorHandler}
      unsetColorHandler={unsetColorHandler}
      isActiveColor={isActiveColor}
      setCurrentColorHandler={setCurrentColorHandler}
      color={color}
      isSetColor={isSetColor}
      editable={editor.isEditable}
      colorList={colorList}
    />
  );
};
