import { buildDiffNodes } from '../parts/DiffNodesDisplay';
import { DiffNodesDisplay } from '../parts/DiffNodesDisplay';

import type { Omittable } from '../parts/DiffNodesDisplay';

type Props = {
  sinceValue: string;
  untilValue: string;
  json?: boolean;
  url?: boolean;
  omittable?: Omittable;
  diffType?: 'char' | 'line';
};

const PlainText: React.FC<Props> = ({
  sinceValue,
  untilValue,
  json,
  url,
  omittable,
  diffType = 'line',
}) => {
  const diffNodes = buildDiffNodes({
    sinceValue,
    untilValue,
    diffType,
  });

  return (
    <DiffNodesDisplay
      nodes={diffNodes}
      json={json}
      url={url}
      omittable={omittable}
    />
  );
};

export { PlainText };
