import { useI18nFormRole } from '../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../provider';

import styles from './FieldName.module.css';

import { useInputPlainText } from '@/views/Common/Inputs';
import { Textfield } from '@/views/Common/Ui/Textfield';

const FieldName = () => {
  const { t } = useI18nFormRole();
  const { originalRole, isAdminRole } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const [inputName, onChangeInputName, validationError] = useInputPlainText(
    originalRole?.name,
    { validateOnInit: true },
  );

  return (
    <div className={styles.field}>
      <Textfield
        type="text"
        label=""
        placeholder={t('Admin')}
        value={inputName}
        onChange={(e) => {
          onChangeInputName(e);

          const nextValue = e.target.value;
          dispatch({ type: 'set-name', payload: nextValue });
        }}
        error={validationError}
        readOnly={isAdminRole}
        disabled={isAdminRole}
        data-testid="roleName"
      />
    </div>
  );
};

export default FieldName;
