import type {
  InputExceptionContentPermission,
  SettingsDefaultPermission,
} from '@/entity/role';

import { isMemberReadPermissionDetailObject } from '@/util/role';

const ObjectKeys = <T extends {} = {}>(object: T) => {
  return Object.keys(object) as (keyof T)[];
};

function isAllCheckedForDefaultPermission(
  inputValue: SettingsDefaultPermission,
): boolean {
  for (const fieldKey of ObjectKeys(inputValue)) {
    if (
      fieldKey !== 'memberPermission' &&
      fieldKey !== 'rolePermission' &&
      fieldKey !== 'billingPermission' &&
      fieldKey !== 'apiKeyPermission' &&
      fieldKey !== 'usagePermission' &&
      fieldKey !== 'environmentPermission' &&
      fieldKey !== 'reviewRequestPermission' &&
      fieldKey !== 'mediumPermission' &&
      fieldKey !== 'apiPermission' &&
      fieldKey !== 'defaultContentPermission'
    ) {
      continue;
    }

    const permission = inputValue[fieldKey];

    if (!isAllChecked(permission)) {
      return false;
    }
  }

  return true;
}

function isAllCheckedForExceptionContentPermission<
  T extends Pick<
    InputExceptionContentPermission,
    'permission' | 'reviewRequestPermission'
  >,
>(ecp: T): boolean {
  if (!ecp || !ecp.permission || !ecp.reviewRequestPermission) {
    return false;
  }
  return (
    isAllChecked(ecp.permission) && isAllChecked(ecp.reviewRequestPermission)
  );
}

/**
 * 権限が全てチェックされているかを判定する関数
 *
 * 下記の関数の内部で利用する
 * - isAllCheckedForDefaultPermission
 * - isAllCheckedForExceptionContentPermission
 */
function isAllChecked<T extends {}>(r: T) {
  for (const k in r) {
    const v = r[k];

    if (v === undefined || v === null) {
      continue;
    }

    if (typeof v === 'boolean') {
      if (!v) return false;
      continue;
    } else if (typeof v === 'string') {
      if (v !== 'ALL') return false;
      continue;
    } else if (isMemberReadPermissionDetailObject(v)) {
      // メンバーのREAD権限の構造が、他と異なるので、個別のチェック方法としている
      if (!v.defaultRead) return false;
      continue;
    } else if (typeof v === 'object' && !Array.isArray(v)) {
      if (!isAllChecked(v)) return false;
    }
  }
  return true;
}

export {
  isAllCheckedForDefaultPermission,
  isAllCheckedForExceptionContentPermission,
  isAllChecked,
};
