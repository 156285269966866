import type React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import FieldLayout from '../../FieldLayout';
import Button from '../../ui/Button';

import styles from './inputLayout.module.css';

type Props = {
  createField: () => void;
  goPrevious: () => void;
  setLayout: React.Dispatch<React.SetStateAction<any[] | undefined>>;
  fields: any[];
  loading: boolean;
};

const InputLayout: React.FC<Props> = ({
  createField,
  goPrevious,
  setLayout,
  fields,
  loading,
}) => {
  const { t } = useTranslation('inputLayout');
  const [column, setColumn] = useState(1);
  const [singleColumnLayout, setSingleColumnLayout] = useState<any[]>([]);
  const [multiColumnLayout, setMultiColumnLayout] = useState<{
    left: any[];
    right: any[];
  }>({
    left: [],
    right: [],
  });
  const [isDragOver, setIsDragOver] = useState();

  // singleColumnLayout, multiColumnLayoutの初期値を決める
  useEffect(() => {
    if (!fields) {
      return;
    }

    const initialSingleColumnLayout = fields.map((element) => {
      return {
        ...element,
      };
    });
    setSingleColumnLayout(initialSingleColumnLayout);

    const left = fields.map((element) => {
      return {
        ...element,
      };
    });
    const initialMultiColumnLayout = { left: left, right: [] };
    setMultiColumnLayout(initialMultiColumnLayout);
  }, [fields]);

  // サーバに送付するpositionsの初期値を決める
  useEffect(() => {
    const positions = fields.map((element) => element.idValue);
    if (column === 1) {
      setLayout([positions]);
    } else if (column === 2) {
      setLayout([positions, []]);
    }
  }, [fields, column, setLayout]);

  const onDragStart = useCallback((e: any) => {
    e.dataTransfer.setData('idValue', e.target.getAttribute('data-id-value'));
  }, []);

  const onDragOver = useCallback(
    (e: any) => {
      e.preventDefault();
      const position = e.currentTarget.getAttribute('data-column-name');
      if (isDragOver !== position) {
        setIsDragOver(position);
      }
    },
    [isDragOver],
  );

  /**
   * カラム移動とカラム内並び替えを実現するために、カラム自体ulとカラム内要素liにonDropが実装されている。そのためonDropが2回呼ばれる設計になっている
   */
  const onDrop = useCallback(
    (event: any) => {
      const dataTransfer = event.dataTransfer;
      const dropArea = event.currentTarget;

      // ドラッグ中のfield.idValue
      const dropItemIdValue = dataTransfer.getData('idValue');

      if (!dropItemIdValue) {
        // 対象外の要素がドロップされたとき（通常のリンク要素など
        return;
      }

      // ドロップ先のfield.idValue（カラム全体に対してのイベント時はnull）
      const destinationIdValue = dropArea.getAttribute('data-id-value');

      // カラム全体に対するイベントかどうか
      const isColumnEvent = !destinationIdValue;

      // ドロップ先のカラム名
      const destinationColumn = dropArea.getAttribute('data-column-name');

      if (column === 1) {
        // 1カラムの並び替えの時はカラム全体に対するonDropイベントは無視する

        if (isColumnEvent) {
          return;
        }

        const destinationDataIdIndex = singleColumnLayout.findIndex(
          (e) => e.idValue === destinationIdValue,
        );
        const dropItem = singleColumnLayout.find(
          (e) => e.idValue === dropItemIdValue,
        );
        const newLayout = singleColumnLayout.filter(
          (e) => e.idValue !== dropItemIdValue,
        );
        newLayout.splice(destinationDataIdIndex, 0, dropItem);
        setSingleColumnLayout(newLayout);

        // 更新されるたびに保存する
        const positions = [newLayout.map((element) => element.idValue)];
        setLayout(positions);
      } else if (column === 2) {
        const fromLeft = multiColumnLayout.left.some(
          (element) => element.idValue === dropItemIdValue,
        );
        if (
          (fromLeft && destinationColumn === 'left') ||
          (!fromLeft && destinationColumn === 'right')
        ) {
          // 同じカラム内の並び替え

          if (isColumnEvent) {
            // 同じカラムの並び替えの時はカラム全体に対するonDropイベントは無視する
            return;
          }

          const targetColumnLayout =
            destinationColumn === 'left'
              ? multiColumnLayout.left
              : multiColumnLayout.right;

          const destinationDataIdIndex = targetColumnLayout.findIndex(
            (e) => e.idValue === destinationIdValue,
          );
          const dropItem = targetColumnLayout.find(
            (e) => e.idValue === dropItemIdValue,
          );
          const updatedTargetColumnLayout = targetColumnLayout.filter(
            (e) => e.idValue !== dropItemIdValue,
          );
          updatedTargetColumnLayout.splice(destinationDataIdIndex, 0, dropItem);

          if (destinationColumn === 'left') {
            const newLayout = {
              left: updatedTargetColumnLayout,
              right: multiColumnLayout.right,
            };
            setMultiColumnLayout(newLayout);

            // 更新されるたびに保存する
            const positions = [
              newLayout.left.map((element) => element.idValue),
              newLayout.right.map((element) => element.idValue),
            ];
            setLayout(positions);
          } else {
            const newLayout = {
              left: multiColumnLayout.left,
              right: updatedTargetColumnLayout,
            };
            setMultiColumnLayout(newLayout);

            // 更新されるたびに保存する
            const positions = [
              newLayout.left.map((element) => element.idValue),
              newLayout.right.map((element) => element.idValue),
            ];
            setLayout(positions);
          }
        } else {
          // カラム間の移動
          const destinationColumnLayout =
            destinationColumn === 'left'
              ? multiColumnLayout.left
              : multiColumnLayout.right;

          const sourceColumnLayout =
            destinationColumn === 'left'
              ? multiColumnLayout.right
              : multiColumnLayout.left;

          if (!isColumnEvent) {
            // カラム間の移動の時はカラム内要素に対するonDropイベントは無視する
            return;
          }

          const dropItem = sourceColumnLayout.find(
            (e) => e.idValue === dropItemIdValue,
          );

          destinationColumnLayout.push(dropItem);

          const updatedSourceColumnLayout = sourceColumnLayout.filter(
            (element) => element.idValue !== dropItemIdValue,
          );

          if (destinationColumn === 'left') {
            const newLayout = {
              left: destinationColumnLayout,
              right: updatedSourceColumnLayout,
            };
            setMultiColumnLayout(newLayout);

            // 更新されるたびに保存する
            const positions = [
              newLayout.left.map((element) => element.idValue),
              newLayout.right.map((element) => element.idValue),
            ];
            setLayout(positions);
          } else {
            const newLayout = {
              left: updatedSourceColumnLayout,
              right: destinationColumnLayout,
            };
            setMultiColumnLayout(newLayout);

            // 更新されるたびに保存する
            const positions = [
              newLayout.left.map((element) => element.idValue),
              newLayout.right.map((element) => element.idValue),
            ];
            setLayout(positions);
          }
        }
      }

      // @ts-expect-error
      setIsDragOver(false);
    },
    [singleColumnLayout, multiColumnLayout, column, setLayout],
  );

  return (
    <div className={styles.area}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('Layout')}</h2>
        <p className={styles.description}>
          {t('Choose your own layout for the fields.')}
        </p>
        <ul className={styles.select}>
          <li className={styles.selectItem}>
            <button
              className={
                column === 1
                  ? `${styles.selectButton} ${styles.isSelected}`
                  : styles.selectButton
              }
              onClick={() => setColumn(1)}
            >
              <i className="material-icons-outlined">horizontal_split</i>
              {t('1 Column')}
            </button>
          </li>
          <li className={styles.selectItem}>
            <button
              className={
                column === 2
                  ? `${styles.selectButton} ${styles.isSelected}`
                  : styles.selectButton
              }
              onClick={() => setColumn(2)}
            >
              <i className="material-icons-outlined">vertical_split</i>
              {t('2 Columns')}
            </button>
          </li>
        </ul>
        <div className={styles.container}>
          {column === 1 && (
            <ul
              className={
                isDragOver === 'left'
                  ? `${styles.column} ${styles.isDragOver}`
                  : styles.column
              }
              onDragOver={onDragOver}
              onDrop={onDrop}
              data-column-name="left"
            >
              {singleColumnLayout.map((field) => (
                <FieldLayout
                  field={field}
                  key={field.idValue}
                  onDragStart={onDragStart}
                  onDrop={onDrop}
                  columnName="left"
                />
              ))}
            </ul>
          )}
          {column === 2 && (
            <>
              <ul
                className={
                  isDragOver === 'left'
                    ? `${styles.column} ${styles.isDragOver}`
                    : styles.column
                }
                onDragOver={onDragOver}
                onDrop={onDrop}
                data-column-name="left"
              >
                {multiColumnLayout.left.map((field, i) => (
                  <FieldLayout
                    field={field}
                    key={i}
                    onDragStart={onDragStart}
                    onDrop={onDrop}
                    columnName="left"
                  />
                ))}
              </ul>
              <ul
                className={
                  isDragOver === 'right'
                    ? `${styles.column} ${styles.isDragOver}`
                    : styles.column
                }
                onDragOver={onDragOver}
                onDrop={onDrop}
                data-column-name="right"
              >
                {multiColumnLayout.right.map((field, i) => (
                  <FieldLayout
                    field={field}
                    key={i}
                    onDragStart={onDragStart}
                    onDrop={onDrop}
                    columnName="right"
                  />
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
      <div className={styles.actions}>
        <span className={styles.step}>
          <em>3</em>/3 {t('steps')}
        </span>
        <div className={styles.prev}>
          <Button value={t('Back')} type="tertiary" onClick={goPrevious} />
        </div>
        <div className={styles.next}>
          <Button
            className="ga-create-customfield-layout"
            type="secondary"
            value={t('Complete')}
            onClick={() => {
              createField();
            }}
            disabled={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default InputLayout;
