import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Expander from '../../../Expander';
import Button from '../../../ui/Button';
import Switch from '../../../ui/Switch';
import Textfield from '../../../ui/Textfield';
import { validateUrl } from '../../../Validations';
import styles from '../webhookSettings.module.css';

import type { WebhookEvents } from '../../../../types/webhook';
import type {
  CreateNewWebhookSetting,
  CreateNewWebhookSettingForCloudflarePages,
  WebhookSetting,
  WebhookSettingForCloudflarePages,
} from '@/entity/webhookSettings';

import { useInput } from '@/views/Common/useInput';
import { useInputWebhookEvents } from '@/views/WebhookSettings/helpers/useInputWebehookEvents';

type Props = {
  setting:
    | WebhookSettingForCloudflarePages
    | CreateNewWebhookSettingForCloudflarePages;
  settings: Array<WebhookSetting | CreateNewWebhookSetting>;
  createSetting: any;
  updateSetting: any;
  deleteSetting: any;
  loading: boolean;
  updatable?: boolean;
  onChangeEnabled: (setting: any) => void;
  updateWebhookEnableLoading: boolean;
  webhookEvents: WebhookEvents[];
};

const CloudflarePagesWebhook: React.FC<Props> = ({
  setting,
  settings,
  createSetting,
  updateSetting,
  deleteSetting,
  loading,
  updatable,
  onChangeEnabled,
  updateWebhookEnableLoading,
  webhookEvents,
}) => {
  const { t } = useTranslation('webhooks');
  const isNew = useMemo(
    () => setting && setting.apiId === undefined,
    [setting],
  );

  const [name, onChangeName, nameError] = useInput(
    (setting && setting.settingValues && setting.settingValues.name) || '',
    () => null,
    true,
  );

  const [
    cloudFlarePagesUrl,
    onChangeCloudflarePagesUrl,
    cloudflarePagesUrlError,
  ] = useInput(
    (setting && setting.settingValues && setting.settingValues.url) || '',
    validateUrl,
    true,
  );

  const [events, onChangeEvents] = useInputWebhookEvents(setting.handleEvents);

  const remove = useCallback(() => {
    deleteSetting(setting);
  }, [deleteSetting, setting]);

  const submit = useCallback(() => {
    if (isNew) {
      createSetting(
        'CLOUDFLARE_PAGES',
        {
          name,
          url: cloudFlarePagesUrl || null,
        },
        events.join(',').split(','),
      );
    } else {
      updateSetting(
        setting,
        {
          ...setting.settingValues,
          name: name || null,
          url: cloudFlarePagesUrl || null,
        },
        events.join(',').split(','),
      );
    }
  }, [
    isNew,
    createSetting,
    name,
    cloudFlarePagesUrl,
    events,
    updateSetting,
    setting,
  ]);

  if (setting === undefined) {
    return null;
  }
  return (
    <Expander
      title={
        <div className={styles.title}>
          <img
            className={styles.icon}
            src="/images/icon_cloudflare.png"
            alt="cloudflare"
          />
          <span className={styles.name}>Cloudflare Pages</span>
          {setting && setting.settingValues && setting.settingValues.name && (
            <span className={styles.webhookName}>
              {setting.settingValues.name}
            </span>
          )}
          <Switch
            className={styles.switch}
            on={
              setting.enabled === null
                ? true
                : typeof setting.enabled === 'undefined'
                  ? true
                  : setting.enabled
            }
            onChange={() => onChangeEnabled(setting)}
            onClick={(e) => e.stopPropagation()}
            disabled={
              updateWebhookEnableLoading ||
              settings.length !==
                settings.filter((setting: any) => setting.apiId !== undefined)
                  .length /* 作成中を含むwebhook要素数と作成ずみのwebhookの数を比較　*/
            }
          />
        </div>
      }
      defaultOpen={isNew}
    >
      <div className={styles.sectionWrapper}>
        <p className={styles.timingGroupLabel}>{t('Basic Settings')}</p>
        <p className={styles.description}>
          {t('Set Webhook name which can be identified. (Optional)')}
        </p>
        <Textfield
          type="text"
          placeholder={t('Webhook Name')}
          defaultValue={name}
          onChange={onChangeName}
          errorText={nameError}
        />
        <p className={styles.description}>
          {t(
            'Enter the URL that can be found in the Deploy hooks setting of Cloudflare Pages. A POST request is triggered when a content is updated.',
          )}
        </p>
        <Textfield
          type="text"
          placeholder="https://api.cloudflare.com/client/v4/pages/webhooks/deploy_hooks/xxxxxxxxxxxxxxxxxxxxxxxxxx"
          defaultValue={cloudFlarePagesUrl}
          onChange={onChangeCloudflarePagesUrl}
          errorText={cloudflarePagesUrlError}
          readOnly={!updatable}
        />
      </div>
      <div className={styles.sectionWrapper}>
        <p className={styles.timingGroupLabel}>
          {t('Notification timing settings')}
        </p>
        {webhookEvents.map(({ label, values }, i) => (
          <div className={styles.section} key={i}>
            <p className={styles.description}>{label}</p>
            <div className={styles.checkboxes}>
              {values.map(
                ({ label, value }, i) =>
                  label &&
                  value && (
                    <div className={styles.timingDetailLabel} key={i}>
                      <label className={styles.checkbox}>
                        <input
                          type="checkbox"
                          value={value}
                          checked={events.includes(value)}
                          onChange={onChangeEvents}
                          disabled={!updatable}
                        ></input>
                        {label}
                      </label>
                    </div>
                  ),
              )}
            </div>
          </div>
        ))}
        {updatable && (
          <div className={styles.actions}>
            <Button
              type="primary"
              className="ga-api-settings-webhook-cloudflare-pages"
              disabled={
                loading ||
                !!cloudflarePagesUrlError ||
                !cloudFlarePagesUrl ||
                events.length === 0
              }
              value={isNew ? t('Setup') : t('Save changes')}
              onClick={submit}
            />
            {!isNew && (
              <Button
                type="danger"
                disabled={loading}
                value={t('Delete')}
                onClick={remove}
              />
            )}
          </div>
        )}
      </div>
    </Expander>
  );
};

export default CloudflarePagesWebhook;
