import * as RadixUIScrollArea from '@radix-ui/react-scroll-area';
import cx from 'classnames';

import styles from './scrollarea.module.css';

export type Props = ViewProps;

export type ViewProps = {
  rootRef?: React.RefObject<HTMLDivElement>;
  viewportRef?: React.RefObject<HTMLDivElement>;
  rootClassName?: string;
  viewportClassName?: string;
  children?: React.ReactNode;
};

export const ScrollAreaView: React.FC<ViewProps> = ({
  rootRef,
  viewportRef,
  rootClassName,
  viewportClassName,
  children,
}) => {
  return (
    <RadixUIScrollArea.Root
      ref={rootRef}
      className={cx(styles.root, rootClassName)}
    >
      <RadixUIScrollArea.Viewport
        ref={viewportRef}
        className={cx(styles.viewport, viewportClassName)}
      >
        {children}
      </RadixUIScrollArea.Viewport>
      <RadixUIScrollArea.Scrollbar
        className={styles.scrollbar}
        orientation="vertical"
      >
        <RadixUIScrollArea.Thumb className={styles.thumb} />
      </RadixUIScrollArea.Scrollbar>
      <RadixUIScrollArea.Scrollbar
        className={styles.scrollbar}
        orientation="horizontal"
      >
        <RadixUIScrollArea.Thumb className={styles.thumb} />
      </RadixUIScrollArea.Scrollbar>
      <RadixUIScrollArea.Corner className={styles.corner} />
    </RadixUIScrollArea.Root>
  );
};

export const ScrollArea: React.FC<Props> = (props) => {
  return <ScrollAreaView {...props} />;
};
