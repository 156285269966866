import type { MigrateApi } from '@/entity/api';
import { isRestrictedApi } from '@/entity/api';
import { useLocalStorageItems } from '@/hooks/Content/useLocalStorageItems';
import { useToggleItem } from '@/hooks/Content/useToggleItem';
import type { Field } from '@/types/field';
import { Icon } from '@/views/Common/Ui/Icon';
import { Modal, ModalContent } from '@/views/Common/Ui/Modal';
import { useToggle } from '@/views/Common/Ui/useToggle';
import { useLoadApis } from '@/views/Common/api/useApiListReader';
import { useTotalCount } from '@/views/Common/content/useContentReader';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Errorfield } from '..';
import {
  useContent,
  useContentObject,
} from '../../../hooks/Content/useContentReader';
import Button from '../../ui/Button';
import Content from '../RelationContent';
import RelationModal from '../RelationModal';
import styles from './relationSelect.module.css';

type Props = {
  endpoint?: string;
  onChange?: (value: unknown) => void;
  value?: unknown;
  readOnly?: boolean;
  referenceKey: string;
  parentField: Field;
};

type ViewProps = Omit<Props, 'referenceKey'> & {
  api: MigrateApi;
};

const RelationSelectView: React.FC<ViewProps> = ({
  api,
  endpoint,
  onChange = () => {},
  value,
  readOnly,
  parentField,
}) => {
  const { t } = useTranslation('relationSelect');

  const [isOpenRelationModal, toggleRelationModal] = useToggle(false);

  // 参照先オブジェクトコンテンツを取得
  // オブジェクト形式の場合はモーダルで選択しないため
  // 事前にコンテンツを取得しておく必要がある
  const { contentObject } = useContentObject(api);

  const { data: totalCount, isLoading } = useTotalCount(api.partitionKey);

  // 選択済みコンテンツを取得
  const [selectedContent] = useContent(value);
  useEffect(() => {
    if (
      api.apiType === 'PAGE' &&
      contentObject !== undefined &&
      contentObject !== null &&
      selectedContent === undefined
    ) {
      onChange(contentObject.partitionKey);
    }
    //  react-hooks/exhaustive-depsを無視
    // eslint-disable-next-line
  }, [api, contentObject, selectedContent]);

  const select = useCallback(
    (content: any) => {
      toggleRelationModal(false);
      onChange(content.partitionKey);
    },
    [onChange, toggleRelationModal],
  );

  const remove = useCallback(() => {
    onChange(undefined);
  }, [onChange]);

  // 表示項目の制限
  const localStoragePath = `${api.id}#${endpoint}#${parentField.idValue}`;
  const localStorageItems = useLocalStorageItems(localStoragePath);
  const [items, setItems] = useState(
    localStorageItems || api.apiFields.map((field) => field.idValue),
  );
  // TODO: apiはuseToggleItem内で未使用なので削除してOK
  const toggleItem = useToggleItem(api, localStoragePath, items, setItems);

  const selectedList = useMemo(() => {
    const data = api.apiType === 'PAGE' ? contentObject : selectedContent;
    const onClick =
      api.apiType === 'LIST' && readOnly !== true
        ? () => toggleRelationModal(true)
        : undefined;

    if (value === undefined || selectedContent === undefined) {
      return (
        <Button type="tertiary" value={t('Select')} onClick={onClick}></Button>
      );
    }

    return (
      <div>
        <table className={styles.table}>
          <thead className={styles.thead}>
            <tr>
              {api.apiType === 'LIST' && (
                <th className={styles.th}>{t('Delete')}</th>
              )}
              <th className={styles.th}>{t('Status')}</th>
              {items.includes('contentId') && (
                <th className={styles.th}>{t('Content ID')}</th>
              )}
              {items.includes('createdAt') && (
                <th className={styles.th}>{t('Date Created')}</th>
              )}
              {api.apiFields.map(
                (field, i) =>
                  items.includes(field.idValue) && (
                    <th
                      key={i}
                      className={`${styles.th} ${styles[field.kind]}`}
                    >
                      {field.name}
                    </th>
                  ),
              )}
            </tr>
          </thead>
          <tbody className={styles.tbody}>
            <Content
              content={data}
              fields={api.apiFields}
              items={items}
              onClick={onClick}
              remove={
                api.apiType === 'LIST' && readOnly !== true ? remove : undefined
              }
              isSelected={true}
            />
          </tbody>
        </table>
        {data && data.contentStatus !== 'PUBLISH' && (
          <p className={styles.alert}>
            {t('Only published contents can be obtained by API.')}
          </p>
        )}
      </div>
    );
  }, [
    api,
    contentObject,
    items,
    readOnly,
    remove,
    selectedContent,
    t,
    toggleRelationModal,
    value,
  ]);

  if (isLoading) {
    return null;
  }

  // ユーザーの権限で選択できるコンテンツが0件かつ選択済みコンテンツが0件の場合
  // 権限上、選択できるコンテンツがない場合でも、すでに選択ずみのコンテンツがある場合はそれ自体は編集できるため。
  if (totalCount === 0 && !value) {
    return (
      <Errorfield>
        {Trans({
          t,
          i18nKey: 'There are no contents to refer',
          children: (
            <Link to={`/apis/${api.apiEndpoint}/create`}>
              Create a new content
            </Link>
          ),
        })}
      </Errorfield>
    );
  }

  return (
    <div>
      <div className={styles.selectedContent}>{selectedList}</div>
      <Modal open={isOpenRelationModal} onOpenChange={toggleRelationModal}>
        <ModalContent size="large" hasSpace={false}>
          <RelationModal
            select={select}
            api={api}
            close={() => toggleRelationModal(false)}
            items={items}
            toggleItem={toggleItem}
          />
        </ModalContent>
      </Modal>
    </div>
  );
};

const RelationSelect: React.FC<Props> = ({
  endpoint,
  onChange = () => {},
  value,
  readOnly,
  parentField,
  referenceKey,
}) => {
  const { t } = useTranslation('relationSelect');

  const apiResponse = useLoadApis();

  if (apiResponse.isLoading) {
    return null;
  }

  const api = apiResponse.data?.list?.find(
    (api) => api.partitionKey === referenceKey,
  );

  if (api === undefined || api === null) {
    return (
      <Errorfield>
        {Trans({
          t: t,
          i18nKey: 'The referenced API does not exist',
          children: (
            <Link to={`/apis/${endpoint}/settings/model`}>
              API Schema Settings
            </Link>
          ),
        })}
      </Errorfield>
    );
  }

  if (isRestrictedApi(api)) {
    return (
      <span className={styles.noPermission}>
        <Icon name="visibility_off" size="large" className={styles.icon} />
        <span className={styles.text}>
          {t(
            'Cannot be displayed due to lack of read permissions for the target API.',
          )}
        </span>
      </span>
    );
  }

  return (
    <RelationSelectView
      api={api}
      endpoint={endpoint}
      onChange={onChange}
      value={value}
      readOnly={readOnly}
      parentField={parentField}
    />
  );
};

export default RelationSelect;
