import { useQuery } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from '../../graphql/queries';

import { getTranslation } from '@/i18n';

export const useAuditLog = (serviceId, limit, offset) => {
  const findAuditLogListQuery = useQuery({
    queryKey: ['auditLogList', serviceId, { limit, offset }],
    queryFn: () => {
      if (serviceId) {
        return API.graphql(
          graphqlOperation(queries.findAuditLogs, {
            serviceId,
            limit,
            offset,
          }),
        ).then((result) => {
          return JSON.parse(result.data.findAuditLogs);
        });
      }
      return;
    },
    cacheTime: 0,
  });

  const { data: auditLogList, isLoading } = findAuditLogListQuery;
  return { auditLogList, isLoading };
};

export const getAuditLog = (serviceId, limit, offset, startDate, endDate) => {
  return API.graphql(
    graphqlOperation(queries.findAuditLogs, {
      serviceId,
      limit,
      offset,
      startDate,
      endDate,
    }),
  ).then((result) => {
    return JSON.parse(result.data.findAuditLogs);
  });
};

export const formatData = async (data) => {
  const { t } = getTranslation('hooksAuditLog');

  const ids = data
    .flatMap((item) => {
      return [item.userId, item.memberId, item.reviewerId];
    })
    .filter((v) => v);

  const uniqeIds = [...new Set(ids)];

  const result = (
    await Promise.all(
      uniqeIds.map((userId) => {
        return fetchUser(userId);
      }),
    )
  )
    .filter((v) => v)
    .reduce((obj, user) => {
      return {
        ...obj,
        [user.username]: user.name ? user.name : user.email.split('@')[0],
      };
    }, {});

  return data.map((item) => {
    return {
      ...item,
      userId: item.userId
        ? result[item.userId] ?? t('Non-existent user')
        : undefined,
      memberId: item.memberId
        ? result[item.memberId] ?? t('Non-existent user')
        : undefined,
      reviewerId: item.reviewerId
        ? result[item.reviewerId] ?? t('Non-existent user')
        : undefined,
    };
  });
};

const fetchUser = async (userId) => {
  const result = await API.graphql(
    graphqlOperation(queries.findUser, { email: userId }),
  ).then((result) => {
    if (result.data.findUser !== null) {
      return JSON.parse(result.data.findUser);
    }
    return null;
  });

  return result;
};
