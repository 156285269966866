import { useTranslation } from 'react-i18next';

import { useMembers } from '../../hooks/Member/useMembers';
import User from '../User';

import type { Service } from '../../types/services';

import styles from './memberListTable.module.css';

import { isInvitingMember } from '@/util/member';

type Props = {
  service: Service;
};

const MemberListTable: React.FC<Props> = ({ service }) => {
  const { t } = useTranslation('memberListTable');
  const { serviceUsers: members } = useMembers(service);

  return (
    <div className={styles.userIcons}>
      <div className={styles.userIconList}>
        {members?.map((member: any, count: number) => (
          <div key={member.sub} className={styles.user}>
            {count < 3 && (
              <User
                user={member}
                showAllEmail={member.isInvitingUser}
                showProfileImage={!isInvitingMember(member)}
                hasName={false}
              />
            )}
          </div>
        ))}
      </div>

      {members && members.length >= 4 && (
        <p className={styles.userCount}>
          {t('Other {{count}} members', { count: members.length - 3 })}
        </p>
      )}
    </div>
  );
};

export default MemberListTable;
