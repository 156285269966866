/**
 * デザインライブラリ
 * https://www.figma.com/design/mrX9D5Rwfh1LuRO0rN3uUN/Design-Library?node-id=546-4669&node-type=frame&t=nzRlcYYkLvCsLo6e-0
 */

import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { PlanLimit } from '@/API';
import Button from '@/components/ui/Button';
import ExternalLink from '@/components/ui/ExternalLink';
import type { SAMLConfiguration } from '@/entity/saml';

import {
  useCurrentPlan,
  useCurrentService,
} from '../../service/useServiceReader';

import { getParam } from './getParam';
import styles from './upsell.module.css';

type Props = (
  | {
      restrictionKey: keyof Omit<PlanLimit, '__typename'>;
      samlSetting?: undefined;
    }
  | {
      restrictionKey: keyof Pick<PlanLimit, 'enableSAML'>;
      samlSetting: SAMLConfiguration;
    }
) & {
  children?: React.ReactNode;
};

type ViewProps = ReturnType<typeof getParam> & {};

const UpsellView: React.FC<ViewProps> = ({
  helpUrl,
  helpUrlTitle,
  children,
}) => {
  const { t } = useTranslation('commonPlanUpsell');

  return (
    <div className={styles.main}>
      <img className={styles.img} src="/images/icon_lock_fill.svg" alt="" />
      <p className={styles.title}>{t('Not available on current plan')}</p>
      <div className={styles.description}>
        {children ? (
          children
        ) : (
          <p>{t('Please check payment settings and documentation.')}</p>
        )}
      </div>
      <p className={styles.link}>
        {Trans({
          t,
          i18nKey:
            'For more information, please see {{title}} (Documentation).',
          children: (
            <ExternalLink
              href={helpUrl}
              hasUnderline={true}
              eraseNoreferrer={true}
            >
              here
            </ExternalLink>
          ),
          tOptions: { title: helpUrlTitle },
        })}
      </p>
      {/* TODO: a > button となっておりセマンティクス上よくないため、LinkButtonといったコンポーネントを用意しなければいけない  */}
      <Link to="/settings/billing">
        <Button
          value={t('Upgrade this service')}
          type="secondary"
          size="large"
        />
      </Link>
    </div>
  );
};

export const Upsell: React.FC<Props> = ({
  restrictionKey,
  samlSetting,
  children,
}) => {
  const { data: service } = useCurrentService();
  const { data: currentPlan } = useCurrentPlan(service?.partitionKey);

  if (!currentPlan) {
    return null;
  }

  const param = getParam(restrictionKey, samlSetting?.idpMetadataURL);

  // 現在のプラン上限に達している場合は、UpsellViewを表示
  if (
    (currentPlan.limit[restrictionKey] === false &&
      !samlSetting?.idpMetadataURL) ||
    currentPlan.limit[restrictionKey] === 0 ||
    (restrictionKey === 'roleCount' && currentPlan.limit[restrictionKey] === 1)
  ) {
    return <UpsellView {...param} />;
  }

  // 現在のプラン上限に達していない場合は、childrenをそのまま表示
  return children;
};
