import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Switch from '@/components/ui/Switch';

import { IconButton } from '../../../../IconButton';

import styles from './editTableMenu.module.css';

import { TableIcon } from '@/views/Common/Icons';
import {
  AddColumnAfterIcon,
  AddColumnBeforeIcon,
  DeleteColumnIcon,
  AddRowAfterIcon,
  AddRowBeforeIcon,
  DeleteRowIcon,
  DeleteTableIcon,
  MergeCellsIcon,
  SplitCellsIcon,
} from '@/views/Common/Icons';
import { Popover } from '@/views/InputField/RichEditorV2/Popover';

export type Props = {
  deleteTableHandler: () => void;
  addColumnBeforeHandler: () => void;
  addColumnAfterHandler: () => void;
  deleteColumnHandler: () => void;
  addRowAfterHandler: () => void;
  addRowBeforeHandler: () => void;
  deleteRowHandler: () => void;
  mergeCellsHandler: () => void;
  splitCellHandler: () => void;
  isActiveTable: boolean;
  toggleHeaderRowHandler: () => void;
  toggleHeaderColumnHandler: () => void;
  isEnableHeaderRow: boolean;
  isEnableHeaderColumn: boolean;
  isEnableMergeCell: boolean;
  isEnableSplitCell: boolean;
  isActiveTableHeader: boolean;
};

export type ViewProps = {
  deleteTableHandler: () => void;
  addColumnBeforeHandler: () => void;
  addColumnAfterHandler: () => void;
  deleteColumnHandler: () => void;
  addRowAfterHandler: () => void;
  addRowBeforeHandler: () => void;
  deleteRowHandler: () => void;
  mergeCellsHandler: () => void;
  splitCellHandler: () => void;
  isActiveTable: boolean;
  toggleHeaderRowHandler: () => void;
  toggleHeaderColumnHandler: () => void;
  isEnableHeaderRow: boolean;
  isEnableHeaderColumn: boolean;
  isEnableMergeCell: boolean;
  isEnableSplitCell: boolean;
  isActiveTableHeader: boolean;
};

export const EditTableMenuView: React.FC<ViewProps> = ({
  deleteTableHandler,
  addColumnBeforeHandler,
  addColumnAfterHandler,
  deleteColumnHandler,
  addRowAfterHandler,
  addRowBeforeHandler,
  deleteRowHandler,
  mergeCellsHandler,
  splitCellHandler,
  isActiveTable,
  toggleHeaderRowHandler,
  toggleHeaderColumnHandler,
  isEnableHeaderRow,
  isEnableHeaderColumn,
  isEnableMergeCell,
  isEnableSplitCell,
  isActiveTableHeader,
}) => {
  const { t } = useTranslation('richEditorV2');

  return (
    <Popover
      trigger={
        <IconButton
          icon={
            <TableIcon
              className={cx(styles.icon, {
                [styles.isActive]: isActiveTable,
              })}
            />
          }
          aria-label={t('Table')}
          isActive={true}
        />
      }
      body={
        <div className={styles.content}>
          <div className={styles.group}>
            <button onClick={addColumnAfterHandler} className={styles.item}>
              <AddColumnAfterIcon className={styles.menuIcon} />
              {t('Add columns to the right')}
            </button>
            <button
              onClick={addRowBeforeHandler}
              className={styles.item}
              disabled={isEnableHeaderRow && isActiveTableHeader}
            >
              <AddRowBeforeIcon
                className={cx(styles.menuIcon, {
                  [styles.disabled]: isEnableHeaderRow && isActiveTableHeader,
                })}
              />
              {t('Add a row top')}
            </button>
            <button
              onClick={addColumnBeforeHandler}
              className={styles.item}
              disabled={isEnableHeaderColumn && isActiveTableHeader}
            >
              <AddColumnBeforeIcon
                className={cx(styles.menuIcon, {
                  [styles.disabled]:
                    isEnableHeaderColumn && isActiveTableHeader,
                })}
              />
              {t('Add columns to the left')}
            </button>
            <button onClick={addRowAfterHandler} className={styles.item}>
              <AddRowAfterIcon className={styles.menuIcon} />
              {t('Add a row down')}
            </button>
            <button onClick={deleteColumnHandler} className={styles.item}>
              <DeleteColumnIcon className={styles.menuIcon} />
              {t('Delete column')}
            </button>
            <button onClick={deleteRowHandler} className={styles.item}>
              <DeleteRowIcon className={styles.menuIcon} />
              {t('Delete row')}
            </button>
            <button onClick={deleteTableHandler} className={styles.item}>
              <DeleteTableIcon className={styles.menuIcon} />
              {t('Delete table')}
            </button>
          </div>
          <div className={styles.group}>
            <button
              onClick={mergeCellsHandler}
              className={styles.item}
              disabled={isEnableMergeCell}
            >
              <MergeCellsIcon
                className={cx(styles.menuIcon, {
                  [styles.disabled]: isEnableMergeCell,
                })}
              />
              {t('Merge cells')}
            </button>
            <button
              onClick={splitCellHandler}
              className={styles.item}
              disabled={isEnableSplitCell}
            >
              <SplitCellsIcon
                className={cx(styles.menuIcon, {
                  [styles.disabled]: isEnableSplitCell,
                })}
              />
              {t('Split cells')}
            </button>
          </div>
          <div>
            <div className={styles.toggleItem}>
              <Switch onClick={toggleHeaderRowHandler} on={isEnableHeaderRow} />
              {t('Set first row as header')}
            </div>
            <div className={styles.toggleItem}>
              <Switch
                onClick={toggleHeaderColumnHandler}
                on={isEnableHeaderColumn}
              />
              {t('Set first column as header')}
            </div>
          </div>
        </div>
      }
      modal={true}
    />
  );
};

export const EditTableMenu: React.FC<Props> = ({
  deleteTableHandler,
  addColumnBeforeHandler,
  addColumnAfterHandler,
  deleteColumnHandler,
  addRowAfterHandler,
  addRowBeforeHandler,
  deleteRowHandler,
  mergeCellsHandler,
  splitCellHandler,
  isActiveTable,
  toggleHeaderRowHandler,
  toggleHeaderColumnHandler,
  isEnableHeaderRow,
  isEnableHeaderColumn,
  isEnableMergeCell,
  isEnableSplitCell,
  isActiveTableHeader,
}) => {
  return (
    <EditTableMenuView
      deleteTableHandler={deleteTableHandler}
      addColumnBeforeHandler={addColumnBeforeHandler}
      addColumnAfterHandler={addColumnAfterHandler}
      deleteColumnHandler={deleteColumnHandler}
      addRowAfterHandler={addRowAfterHandler}
      addRowBeforeHandler={addRowBeforeHandler}
      deleteRowHandler={deleteRowHandler}
      mergeCellsHandler={mergeCellsHandler}
      splitCellHandler={splitCellHandler}
      isActiveTable={isActiveTable}
      toggleHeaderRowHandler={toggleHeaderRowHandler}
      toggleHeaderColumnHandler={toggleHeaderColumnHandler}
      isEnableHeaderRow={isEnableHeaderRow}
      isEnableHeaderColumn={isEnableHeaderColumn}
      isEnableMergeCell={isEnableMergeCell}
      isEnableSplitCell={isEnableSplitCell}
      isActiveTableHeader={isActiveTableHeader}
    />
  );
};
