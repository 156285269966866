import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useBulletList } from './useBulletList';
import { IconButton } from '../../../IconButton';
import styles from '../../../richeditorv2.module.css';

import type { Editor } from '@tiptap/react';

import { BulletListIcon } from '@/views/Common/Icons';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  toggleBulletListHandler: () => void;
  isActiveBulletList: boolean;
  editable: boolean;
};

export const BulletListView: React.FC<ViewProps> = ({
  toggleBulletListHandler,
  isActiveBulletList,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <IconButton
      icon={
        <BulletListIcon
          className={cx(styles.icon, {
            [styles.isActive]: isActiveBulletList,
          })}
        />
      }
      aria-label={t('ListBullet')}
      isActive={isActiveBulletList}
      onClick={toggleBulletListHandler}
      disabled={!editable}
    />
  );
};

export const BulletList: React.FC<Props> = ({ editor }) => {
  const { toggleBulletListHandler, isActiveBulletList } = useBulletList({
    editor,
  });

  return (
    <BulletListView
      toggleBulletListHandler={toggleBulletListHandler}
      isActiveBulletList={isActiveBulletList}
      editable={editor.isEditable}
    />
  );
};
