import { reviewRepository } from '@/repository/reviewRepository';

export const updateReviewRequest = async ({
  serviceId,
  reqId,
  title,
  description,
  reservationTime,
  reservationStopTime,
}: {
  serviceId: string;
  reqId: number;
  title: string;
  description?: string;
  reservationTime?: string;
  reservationStopTime?: string;
}) => {
  return await reviewRepository().updateReviewRequest({
    serviceId,
    reqId,
    title,
    description,
    reservationTime,
    reservationStopTime,
  });
};

export const toggleReviewRequestStatus = async ({
  serviceId,
  reqId,
}: {
  serviceId: string;
  reqId: number;
}) => {
  return await reviewRepository().toggleReviewRequestStatus({
    serviceId,
    reqId,
  });
};

export const closeReviewRequestAndPublishContent = async ({
  serviceId,
  reqId,
}: {
  serviceId: string;
  reqId: number;
}) => {
  return await reviewRepository().closeReviewRequestAndPublishContent({
    serviceId,
    reqId,
  });
};

export const createReviewRequestComment = async ({
  serviceId,
  reqId,
  comment,
}: {
  serviceId: string;
  reqId: number;
  comment: string;
}) => {
  return await reviewRepository().createReviewRequestComment({
    serviceId,
    reqId,
    comment,
  });
};

export const deleteReviewRequestComment = async ({
  serviceId,
  reqId,
  eventId,
}: {
  serviceId: string;
  reqId: number;
  eventId: string;
}) => {
  return await reviewRepository().deleteReviewRequestComment({
    serviceId,
    reqId,
    eventId,
  });
};
