import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { createClient } from 'microcms-js-sdk';
import { useCallback, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { MICROCMS_API_KEY } from '../util';
import { getPureHost } from '../util';

const client = createClient({
  serviceDomain: 'microcms',
  apiKey: MICROCMS_API_KEY,
});

export const useInfo = () => {
  const getInfoQuery = useQuery({
    queryKey: ['microcmsInfo'],
    queryFn: async () => {
      return await client.get({
        endpoint: 'info',
        queries: { fields: 'id,title,link,publishedAt' },
      });
    },
    staleTime: Number.POSITIVE_INFINITY,
  });

  const { data: info, isLoading: isInfoLoading } = getInfoQuery;
  const latestContentPublishedAt = info?.contents?.[0]?.publishedAt;

  const [cookies, setCookie, removeCookie] = useCookies([
    `usage#latestOpenInfo`,
  ]);
  const [isInfoOpen, setInfoOpen] = useState<boolean>(
    !cookies[`usage#latestOpenInfo`],
  );

  useEffect(() => {
    if (latestContentPublishedAt > cookies[`usage#latestOpenInfo`]) {
      removeCookie(`usage#latestOpenInfo`, {
        path: '/',
        domain: getPureHost(),
        secure: window.location.protocol === 'https',
      });
      setInfoOpen(true);
    }
  }, [cookies, latestContentPublishedAt, removeCookie]);

  const closeInfo = useCallback(
    (e: any) => {
      setCookie(`usage#latestOpenInfo`, new Date().toISOString(), {
        path: '/',
        domain: getPureHost(),
        secure: window.location.protocol === 'https',
        expires: dayjs().add(20, 'year').toDate(),
      });
      setInfoOpen(false);
      e.preventDefault();
    },
    [setCookie],
  );

  return {
    info: info?.contents?.[0],
    isInfoOpen,
    closeInfo,
    isInfoLoading,
  };
};
