import { useCallback, useEffect, useState } from 'react';

import { getIframelyResult } from '@/usecase/iframelyUsecase';

import {
  convertOptionsToQuery,
  convertInputOptions,
} from '../../../shared/convertEmbedOptions';
import { useEmbedOptionsDebounce } from '../../../shared/useEmbedOptionsDebounce';

import type { Props } from './EmbedBlock';
import type { EmbedResult, OptionsField } from '@/entity/iframely';

import { validateUrl } from '@/views/Common/validations';

export const useEmbedBlock = ({ editor }: Props) => {
  const [embedResult, setEmbedResult] = useState<EmbedResult>({
    data: null,
    isLoading: false,
    isSuccess: false,
  });
  const [embedUrl, setEmbedUrl] = useState('');
  const [embedUrlError, setEmbedUrlError] = useState<string | undefined>(
    undefined,
  );
  const onChangeEmbedUrl = useCallback((url: string) => {
    setEmbedUrl(url);
    setEmbedUrlError(validateUrl(url));
    setEmbedResult((prev) => {
      return { ...prev, isSuccess: false };
    });
  }, []);

  const [inputOptions, setInputOptions] = useState<
    Record<string, OptionsField['value']> | undefined
  >(undefined);

  const onChangeInputOptions = useCallback(
    (value: OptionsField['value'], key: string) => {
      setInputOptions((prev) => {
        return {
          ...prev,
          [key]: value,
        };
      });
      setEmbedResult((prev) => {
        return { ...prev, isSuccess: true };
      });
    },
    [],
  );
  const { isDebounceDone } = useEmbedOptionsDebounce({ inputOptions });

  useEffect(() => {
    const fetchIframely = async () => {
      const { optionQuery } = convertOptionsToQuery(inputOptions);
      if (!optionQuery) {
        return;
      }

      const result = await getIframelyResult({ url: embedUrl, optionQuery });
      if (!result) {
        return;
      }

      setEmbedResult({ data: result, isLoading: false, isSuccess: true });
    };

    if (!embedResult.isSuccess) {
      return;
    }

    if (isDebounceDone) {
      fetchIframely();
    }
  }, [
    embedResult.isSuccess,
    embedUrl,
    embedUrlError,
    inputOptions,
    isDebounceDone,
  ]);

  const [isEmbedModal, toggleEmbedModal] = useState<boolean>(false);

  const onToggleEmbedModal = useCallback((open: boolean) => {
    toggleEmbedModal(open);
    if (!open) {
      setEmbedUrl('');
      setEmbedUrlError(undefined);
      setEmbedResult({ data: null, isLoading: false, isSuccess: false });
      setInputOptions(undefined);
    }
  }, []);

  const onClickPreviewEmbed = useCallback(async () => {
    setEmbedResult({ data: null, isLoading: true, isSuccess: false });
    if (!embedUrl) {
      setEmbedUrlError(validateUrl(embedUrl));
      return;
    }
    const { optionQuery } = convertOptionsToQuery(inputOptions);

    const result = await getIframelyResult({ url: embedUrl, optionQuery });

    if (!result) {
      setEmbedResult({ data: null, isLoading: false, isSuccess: false });
      return;
    }

    setEmbedResult({ data: result, isLoading: false, isSuccess: true });
    if (!inputOptions) {
      setInputOptions(convertInputOptions(result.options));
    }
  }, [embedUrl, inputOptions]);

  const insertEmbedBlock = useCallback(async () => {
    if (!embedUrl) {
      return;
    }
    const { optionQuery } = convertOptionsToQuery(inputOptions);

    const result = await getIframelyResult({ url: embedUrl, optionQuery });

    if (!result) {
      return;
    }

    editor
      .chain()
      .focus()
      .setEmbed({ url: result.url, html: result.html, options: result.options })
      .run();

    onToggleEmbedModal(false);
  }, [editor, embedUrl, inputOptions, onToggleEmbedModal]);

  return {
    isEmbedModal,
    onToggleEmbedModal,
    embedUrl,
    embedUrlError,
    onChangeEmbedUrl,
    inputOptions,
    onChangeInputOptions,
    embedResult,
    onClickPreviewEmbed,
    insertEmbedBlock,
  };
};
