import type React from 'react';
import { useCallback, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAdmin } from '@/hooks/Role/useMyRoles';
import { usePutService } from '@/hooks/Service/useServiceWriter';
import { useGetMyService } from '@/hooks/useService';

import { useNameSettings } from './useNameSettings';
import Feedback from '../../Feedback';
import Head from '../../Head';
import S3Image from '../../S3Image';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import Textfield from '../../ui/Textfield';
import { validateServiceName, validateMedium } from '../../Validations';

import styles from './nameSettings.module.css';

import { useInput, useLoading } from '@/hooks';
import { getServiceDomain } from '@/util';

const NameSettings: React.FC = () => {
  const { t } = useTranslation('serviceNameSettings');
  const { service } = useGetMyService();
  const domain = getServiceDomain();
  const { putService, putServiceLoading } = usePutService();
  const [isAdmin] = useAdmin();
  const [name, onChangeName, nameError] = useInput(
    undefined,
    // @ts-expect-error
    validateServiceName,
  );
  const { imageUploadResult, uploadImage } = useNameSettings();

  const [file, setFile] = useState<File | undefined>(undefined);
  const [loading, startLoading] = useLoading(imageUploadResult !== undefined);
  const [validationResult, setValidationResult] = useState<string | null>(null);
  const ddArea = useRef<HTMLDivElement | null>(null);

  const submit = useCallback(() => {
    // @ts-expect-error
    putService({
      partitionKey: service?.partitionKey,
      serviceName: name,
      serviceImage: imageUploadResult,
    });
  }, [putService, service?.partitionKey, name, imageUploadResult]);

  const selectFile = useCallback(
    (selectedFile: File) => {
      const res = validateMedium(selectedFile);
      setValidationResult(res);
      if (res !== null) {
        setTimeout(() => setValidationResult(null), 3000);
        return;
      }
      setFile(selectedFile);
      startLoading();
      uploadImage(domain, selectedFile);
    },
    [startLoading, uploadImage, domain],
  );

  useEffect(() => {
    if (!isAdmin) {
      return;
    }
    const area = ddArea.current;
    function dragoverFunc(e: React.DragEvent<HTMLElement>) {
      e.preventDefault();
    }
    function dragleaveFunc(e: React.DragEvent<HTMLElement>) {
      e.preventDefault();
    }
    function dropFunc(e: React.DragEvent<DataTransfer>) {
      e.preventDefault();
      const files = e.dataTransfer.files;
      const selectedFile = files[0];
      selectFile(selectedFile);
    }
    // @ts-expect-error
    area.addEventListener('dragover', dragoverFunc);
    // @ts-expect-error
    area.addEventListener('dragleave', dragleaveFunc);
    // @ts-expect-error
    area.addEventListener('drop', dropFunc);
    return () => {
      // @ts-expect-error
      area.removeEventListener('dragover', dragoverFunc);
      // @ts-expect-error
      area.removeEventListener('dragleave', dragleaveFunc);
      // @ts-expect-error
      area.removeEventListener('drop', dropFunc);
    };
  }, [isAdmin, selectFile]);

  if (service && Object.keys(service).length === 0) {
    return null;
  }

  const serviceName = service?.serviceName;
  const serviceImage = service?.serviceImage;

  return (
    <div>
      <Head title={t('Service Settings')} />
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('Basic Information')}</h2>
        <Fieldset
          legend={t('Service Name')}
          description={t(
            'The service name is the label used within the Administration console. Give it a name that is easy for content creators to understand.',
          )}
        >
          <Textfield
            type="text"
            defaultValue={serviceName}
            onChange={onChangeName}
            errorText={nameError}
            readOnly={!isAdmin}
            dataTestid="name-sttings-textfield-service-name"
          />
        </Fieldset>
        <Fieldset legend={t('Service Image')}>
          <div className={styles.selectImageArea} ref={ddArea}>
            <label className={styles.selectImage}>
              {file ? (
                <img
                  className={styles.selectedImage}
                  src={window.URL.createObjectURL(file)}
                  alt=""
                />
              ) : serviceImage ? (
                <S3Image
                  directory={`protected/${serviceImage.identityId}/${serviceImage.key}`.replace(
                    `/${serviceImage.key.replace(/.*\//, '')}`,
                    '',
                  )}
                  fileName={serviceImage.key.replace(/.*\//, '')}
                  kind={'IMAGE'}
                  queryString="?fit=crop&w=180&h=180"
                  className={styles.selectedImage}
                />
              ) : (
                <img
                  className={styles.selectedImage}
                  src="/images/icon_default_image_square.svg"
                  alt=""
                />
              )}
              {isAdmin && (
                <>
                  <span className={styles.editBtn}>
                    <img src="/images/icon_edit_white.svg" alt={t('Edit')} />
                  </span>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      // @ts-expect-error TODO:nullチェック
                      selectFile(e.target.files[0])
                    }
                  />
                </>
              )}
            </label>
            <Feedback
              message={validationResult !== null ? validationResult : undefined}
            />
            <p className={styles.description}>
              {t('Select an image larger than 180 x 180.')}
              <br />
              {t('Drag and drop can be used.')}
              <br />
              {t('Supported file types: png / jpg / svg / bmp')}
            </p>
          </div>
        </Fieldset>
        {isAdmin && (
          <div className={styles.actions}>
            <Button
              type="primary"
              disabled={loading || putServiceLoading || nameError}
              value={t('Save changes')}
              onClick={submit}
              gaon="click"
              gaeventcategory="service"
              gaeventaction="update_basic_info"
              gaeventlabel={domain}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NameSettings;
