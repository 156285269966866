export type ServiceLanguage = 'ja' | 'en';

export type LanguageSetting = 'ja' | 'en' | null;

export const isServiceLanguage = (value: unknown): value is ServiceLanguage => {
  if (typeof value !== 'string') {
    return false;
  }
  const serviceLanguage = value as ServiceLanguage;
  if (serviceLanguage !== 'ja' && serviceLanguage !== 'en') {
    return false;
  }
  return true;
};
