import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import {
  getServiceLanguage,
  updateServiceLanguage,
} from '@/usecase/languageSettingsUseCase';

import { useApi } from '@/lib/useApi';
import { isReactQueryError } from '@/util/type-guard';

export const useLanguageSettingsQuery = () => {
  const useGetServiceLanguage = (serviceId: string) => {
    return useApi(
      ['serviceLanguage', { serviceId }],
      async ({ serviceId }) => await getServiceLanguage(serviceId),
      {
        staleTime: Number.POSITIVE_INFINITY,
        onSuccess: () => {
          // noop
        },
        onError: (error) => {
          if (isReactQueryError(error)) {
            return {
              message: `${error.status}:${error.message}`,
            };
          }
          if (error instanceof Error) {
            return {
              message: error.message,
            };
          }
          return {
            message: 'Something went wrong...',
          };
        },
      },
    );
  };

  const useUpdateServiceLanguage = () => {
    const { t } = useTranslation('hooksService');
    const cache = useQueryClient();
    const { addToast } = useToasts();

    return useMutation(updateServiceLanguage, {
      onSuccess({ result }) {
        if (result?.result === true) {
          cache.invalidateQueries(['serviceLanguage'], { type: 'all' });
          addToast(t('Changed language.'), { appearance: 'success' });
        } else {
          addToast(result?.message, {
            appearance: 'error',
          });
        }
      },
      onError(error: Error) {
        addToast(
          error.message ? error.message : t('Language could not be changed.'),
          {
            appearance: 'error',
          },
        );
      },
    });
  };

  return {
    useGetServiceLanguage,
    useUpdateServiceLanguage,
  };
};
