import type { SearchOptions } from '@/API';

const contentQueryKeys = {
  list: (apiId?: string, options?: SearchOptions) => {
    if (!apiId) {
      return ['contentList'] as const;
    }
    if (!options) {
      return ['contentList', apiId] as const;
    }
    return ['contentList', apiId, options] as const;
  },
  detail: (userDefinedId?: string, apiId?: string) => {
    if (!userDefinedId) {
      return ['content'] as const;
    }
    if (!apiId) {
      return ['content', userDefinedId] as const;
    }
    return ['content', userDefinedId, apiId] as const;
  },
  object: (apiId?: string) => {
    if (!apiId) {
      return ['contentObject'] as const;
    }
    return ['contentObject', apiId] as const;
  },
  histories: (contentId?: string) => {
    if (!contentId) {
      return ['contentHistories'] as const;
    }
    return ['contentHistories', contentId] as const;
  },
  version: (contentId?: string, version?: number, denyDraft?: boolean) => {
    if (!contentId) {
      return ['contentVersion'] as const;
    }

    if (!version) {
      return ['contentVersion', contentId] as const;
    }

    if (denyDraft === undefined) {
      return ['contentVersion', contentId, version] as const;
    }

    return ['contentVersion', contentId, version, denyDraft] as const;
  },
};

export { contentQueryKeys };
