import { connect } from 'react-redux';

import App from './App';
import { accountOperations, accountSelectors } from '../../ducks/account';

import type { ComponentType } from 'react';

const mapStateToProps = (state: any) => {
  return {
    isAuthorized: accountSelectors.getAccountState(state.accountState)
      .isAuthorized,
  };
};

const mapDispatchToProps = {
  subscribeAuth: accountOperations.subscribeAuth,
  unsubscribeAuth: accountOperations.unsubscribeAuth,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App as ComponentType<{}>);
