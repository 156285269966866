import { useState, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { useAddCustomStatus } from '../../../hooks/CustomStatus/useCustomStatus';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import Modal from '../../ui/Modal';
import Selectfield from '../../ui/Selectfield';
import Textfield from '../../ui/Textfield';
import {
  validateCustomStatusKey,
  validateCustomStatusName,
} from '../../Validations';

import styles from './customStatusSettings.module.css';

import { useInput } from '@/views/Common/useInput';

type Props = {
  apiId: string;
  closeModal: () => void;
};

const CreateModal: React.FC<Props> = ({ apiId, closeModal }) => {
  const { t } = useTranslation('customStatusSettings');
  const [addCustomStatus, addCustomStatusLoading] = useAddCustomStatus();
  const [behaviour, setBehaviour] = useState('');
  const [key, onChangeKey, keyError] = useInput('', validateCustomStatusKey);
  const [name, onChangeName, nameError] = useInput(
    '',
    validateCustomStatusName,
  );
  const [color, setColor] = useState('#563BFF');
  const onChangeColor = useCallback((value: string) => {
    setColor(value);
  }, []);
  const [description, setDescription] = useState('');
  const onChangeDescription = useCallback((value: string) => {
    setDescription(value);
  }, []);

  const submit = useCallback(() => {
    // @ts-ignore
    addCustomStatus({
      apiId,
      key,
      name,
      description,
      behaviour,
      color,
      closedModal: closeModal,
    });
  }, [
    addCustomStatus,
    apiId,
    key,
    name,
    description,
    behaviour,
    closeModal,
    color,
  ]);

  return (
    <Modal type="medium">
      <Fieldset
        legend={t('API Status Name')}
        option={false}
        customDescription={
          <p>
            {t('Enter a unique value to be retrieved by the API.')}
            {Trans({
              t,
              i18nKey: 'API documentation is here.',
              children: (
                <a
                  href="https://document.microcms.io/manual/custom-status#h72a886c1f0"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                >
                  here
                </a>
              ),
            })}
          </p>
        }
      >
        <Textfield
          type="text"
          size="medium"
          placeholder={t('E.g., PRIVATE')}
          onChange={onChangeKey}
          errorText={keyError}
        />
      </Fieldset>
      <Fieldset
        legend={t('Display Status Name')}
        option={false}
        description={t(
          'Enter unique text to be displayed next to the status icon.',
        )}
      >
        <Textfield
          type="text"
          size="medium"
          placeholder={t('E.g., private')}
          onChange={onChangeName}
          errorText={nameError}
        />
      </Fieldset>
      <Fieldset
        legend={t('Status Description')}
        description={t(
          'Displays status description when status name is selected.',
        )}
      >
        <Textfield
          type="text"
          size="full"
          placeholder={t(
            'E.g., Change the status of the campaign to End of Campaign with the article published.',
          )}
          onChange={(e) => onChangeDescription(e?.target.value || '')}
        />
      </Fieldset>
      <Fieldset
        legend={t('Next Status')}
        option={false}
        description={t('Select the next status after status change.')}
      >
        <Selectfield onChange={(e) => setBehaviour(e.target.value)}>
          <option value="">{t('Select')}</option>
          <option value="PUBLISH">{t('Public')}</option>
          <option value="CLOSED">{t('Unpublished')}</option>
          <option value="DRAFT">{t('Draft')}</option>
        </Selectfield>
      </Fieldset>
      <Fieldset
        legend={t('Status Color')}
        description={t('Specify color of the status icon.')}
      >
        <>
          <span className={styles.colorPickerLabel}>{t('Selected color')}</span>
          <input
            type="color"
            value={color}
            onChange={(e) => onChangeColor(e?.target.value || '')}
            className={styles.colorPicker}
          />
        </>
      </Fieldset>
      <div className={styles.modalButtonWrapper}>
        <Button
          type="secondary"
          size="large"
          value={t('Create Status')}
          onClick={submit}
          // @ts-expect-error NOTE: addCustomStatusLoadingの返り値に型がついていないため
          disabled={
            addCustomStatusLoading ||
            keyError !== null ||
            nameError !== null ||
            !key ||
            !name ||
            !behaviour
          }
          className={styles.modalButton}
        />
      </div>
    </Modal>
  );
};

export default CreateModal;
