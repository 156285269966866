import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';

import ApiKeyList from '../ApiKeyList';
import CheckRoles from '../CheckRoles';
import EditApiKey from '../EditApiKey';
import Main from '../Main';
import PrivateRoute from '../PrivateRoute';
import HelpButton from '../ui/HelpButton';

import styles from './apikeystop.module.css';

const ApiKeysTop: React.FC = () => {
  const { t } = useTranslation('apiKeysTop');
  return (
    <Main
      header={
        <header className={styles.header}>
          <h2 className={styles.title}>
            {t('Manage API Keys')}
            <HelpButton
              type="content-api"
              id="x-microcms-api-key"
              className="ga-click-tooltip-api-key"
            />
          </h2>
        </header>
      }
    >
      <CheckRoles permission="apiKeyPermission" display={true}>
        <Switch>
          <PrivateRoute exact path="/api-keys" component={ApiKeyList} />
          <PrivateRoute
            exact
            path="/api-keys/:apikeyId"
            component={EditApiKey}
          />
        </Switch>
      </CheckRoles>
    </Main>
  );
};

export default ApiKeysTop;
