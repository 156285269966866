import { I18n, Auth } from 'aws-amplify';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useInput } from '../../hooks';
import { useSignin, useSignup } from '../../hooks/Auth/useAuth';
import Feedback from '../Feedback';
import Head from '../Head';
import Button from '../ui/Button';
import Fieldset from '../ui/Fieldset';
import Footer from '../ui/Footer';
import Switch from '../ui/Switch';
import Textfield from '../ui/Textfield';
import { validateEmail, validatePassword } from '../Validations';

import styles from './signup.module.css';

type SignupProps = {
  email: string;
  onChangeEmail: () => void;
  emailError: string;
  password: string;
  passwordError: string;
  onChangePassword: () => void;
  serverError: any;
  newSignup: any;
  signupLoading: boolean;
  token: string;
};

const Signup: React.FC<SignupProps> = ({
  email,
  onChangeEmail,
  emailError,
  password,
  onChangePassword,
  passwordError,
  serverError,
  newSignup,
  signupLoading,
  token,
}) => {
  const { t } = useTranslation('signup');
  const [consent, setConsent] = useState(false);
  const hasLocalError = useMemo(
    () => emailError !== null || passwordError !== null || consent === false,
    [emailError, passwordError, consent],
  );

  const submit = useCallback(() => {
    newSignup({ email, password }); //new
  }, [newSignup, email, password]);

  return (
    <div className={styles.wrapper}>
      <Head title={t('Register Account ')} />
      <header className={styles.header}>
        <Link to="/">
          <img
            className={styles.logoImg}
            src="/images/logo_black.svg"
            alt="microCMS"
          />
        </Link>
      </header>
      <div className={styles.container}>
        <div className={styles.leftContent}>
          <div className={styles.textBox}>
            <h2 className={styles.title}>{t('Register for a free account')}</h2>
            <p className={styles.description}>
              {t('First-time users must register for a new account.')}
              <br />
              {Trans({
                t,
                i18nKey: 'If you already have an account, you can log in here.',
                children: <Link to="/signin">log in here</Link>,
              })}
            </p>
          </div>
          <div className={styles.formBox}>
            <Fieldset legend={t('Email address')}>
              {token ? (
                <p className={styles.inviteEmail}>{email}</p>
              ) : (
                <Textfield
                  type="text"
                  name="email"
                  placeholder="mailaddress@microcms.io"
                  defaultValue={email}
                  // @ts-expect-error
                  readOnly={token}
                  errorText={emailError}
                  onChange={onChangeEmail}
                />
              )}
            </Fieldset>
            <Fieldset
              legend={t('Password')}
              description={t(
                'Must be at least 8 characters. Must contain uppercase letters, lowercase letters and numbers.',
              )}
            >
              <Textfield
                type="password"
                name="password"
                placeholder="************"
                value={password}
                errorText={passwordError}
                onChange={onChangePassword}
              />
            </Fieldset>
            <Switch
              className={styles.agreement}
              on={consent}
              size="large"
              onChange={setConsent}
            >
              <span className={styles.checkboxText}>
                {Trans({
                  t,
                  i18nKey: 'I agree to the Terms of Use and Privacy Policy',
                  children: [
                    <a href="https://microcms.io/terms" target="terms">
                      the Terms
                    </a>,
                    <a href="https://microcms.io/policy" target="policy">
                      Privacy Policy
                    </a>,
                  ],
                })}
              </span>
            </Switch>
            <div className={styles.actions}>
              <Feedback
                message={
                  serverError && I18n.get(serverError.code, serverError.message)
                }
              />
              <Button
                className={
                  token ? 'ga-invite-signup-button' : 'ga-signup-button'
                }
                type="secondary"
                value={t('Register account')}
                size="full"
                disabled={hasLocalError || signupLoading}
                onClick={submit}
              />
            </div>
          </div>
        </div>
        <div className={styles.rightContent}>
          <img className={styles.mainImg} src="/images/signup.svg" alt="" />
          <ul className={styles.list}>
            <li className={styles.listItem}>
              <img
                className={styles.checkboxImg}
                src="/images/checkbox.svg"
                alt=""
              />
              <p>
                {Trans({
                  t,
                  i18nKey: 'Headless CMS made in Japan with intuitive UI',
                  children: (
                    <span className={styles.underline}>
                      Headless CMS made in Japan
                    </span>
                  ),
                })}
              </p>
            </li>
            <li className={styles.listItem}>
              <img
                className={styles.checkboxImg}
                src="/images/checkbox.svg"
                alt=""
              />
              <p>
                {Trans({
                  t,
                  i18nKey:
                    'Reduce content update lead time from 5 days to just 1 hour',
                  children: (
                    <span className={styles.underline}>just 1 hour</span>
                  ),
                })}
              </p>
            </li>
            <li className={styles.listItem}>
              <img
                className={styles.checkboxImg}
                src="/images/checkbox.svg"
                alt=""
              />
              <p>
                <span className={styles.underline}>
                  {t('Extensive support and robust security')}
                </span>
              </p>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

type SignupFlowProps = {
  confirmSignupSuccess: any;
  reset: () => void;
};

const SignupFlow: React.FC<SignupFlowProps> = ({
  confirmSignupSuccess,
  reset,
  ...props
}) => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const token = params.get('token') || '';
  const invitedEmail = params.get('email') || '';

  const [page, setPage] = useState(1);
  const [email, onChangeEmail, emailError] = useInput(
    invitedEmail,
    validateEmail,
  );

  //readonlyのケースもあるので初回に一度バリデーションを実行しておく
  useEffect(() => {
    invitedEmail && onChangeEmail({ target: { value: invitedEmail } });
  }, [invitedEmail, onChangeEmail]);

  const [password, onChangePassword, passwordError] = useInput(
    '',
    validatePassword,
  );
  const { signup, signupLoading, signupSuccess } = useSignup();
  const { signin } = useSignin();

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  // 登録が完了し、画面遷移が完了したタイミングで認証メールを送る
  useEffect(() => {
    return () => {
      // NOTE: 変数invitedEmailに内容がある場合は、送られてきた招待メールからSignUpのフローを通っているため、認証メールは送らない。
      // したがって、変数invitedEmailがfalsyな値の場合であることを条件に含めている。
      if (signupSuccess === true && !invitedEmail) {
        Auth.verifyCurrentUserAttribute('email');
      }
    };
  }, [reset, signupSuccess, invitedEmail]);

  useEffect(() => {
    if (signupSuccess === true) {
      setPage(2);
      // @ts-expect-error
      signin({
        email,
        password,
        accountRegisterRedirectPath: `/survey${token && `?token=${token}`}`,
      });
    }
  }, [email, password, signin, signupSuccess, token]);

  if (page === 1) {
    return (
      // @ts-expect-error
      <Signup
        {...props}
        email={email}
        onChangeEmail={onChangeEmail}
        emailError={emailError}
        password={password}
        onChangePassword={onChangePassword}
        passwordError={passwordError}
        newSignup={signup}
        signupLoading={signupLoading}
        token={token}
      />
    );
  }

  return <></>;
};

export default SignupFlow;
