import cx from 'classnames';
import { useCallback } from 'react';

import type { NumberRange } from '@/types/field';

import styles from './textarea.module.css';

import { TextBottomAlert } from '@/views/Common/Ui/TextBottomAlert';

type Props = {
  className?: string;
  placeholder?: string;
  onChange?: (e?: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onEnter?: () => void;
  onClick?: () => void;
  onFocus?: () => void;
  style?: React.CSSProperties;
  value?: string;
  defaultValue?: string;
  readOnly?: any;
  autoComplete?: any;
  errorText?: string | null;
  textCount?: number;
  textSize?: NumberRange;
  displayTextCount?: boolean;
};

/**
 * @deprecated
 * このコンポーネントの代わりに、@src/views/Common/Ui/Textareaを使用してください。
 */
const Textarea: React.FC<Props> = ({
  className,
  placeholder,
  onChange = () => {},
  onEnter = () => {},
  onClick = () => {},
  onFocus = () => {},
  style,
  value,
  defaultValue,
  readOnly,
  autoComplete,
  errorText = null,
  textCount = 0,
  textSize,
  displayTextCount,
}) => {
  const _onEnter = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        errorText === null && onEnter();
      }
    },
    [errorText, onEnter],
  );
  return (
    <div className={className}>
      <textarea
        className={cx(styles.input, {
          [styles.hasError]: errorText !== null,
          [styles.readOnly]: readOnly,
        })}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={_onEnter}
        onClick={onClick}
        onFocus={onFocus}
        style={style}
        value={value}
        defaultValue={defaultValue}
        readOnly={readOnly}
        autoComplete={autoComplete}
      />
      <TextBottomAlert
        errorText={errorText}
        textSize={textSize}
        textCount={textCount}
        displayTextCount={displayTextCount}
      />
    </div>
  );
};

export default Textarea;
