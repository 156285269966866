import i18n from 'i18next';
import type { KeyPrefix } from 'i18next';
import { initReactI18next } from 'react-i18next';

import announcement from './components/Announcement/translations.json';
import apiKeyModal from './components/ApiKeyList/ApiKeyModal/translations.json';
import apiKeyList from './components/ApiKeyList/translations.json';
import apiKeysTop from './components/ApiKeysTop/translations.json';
import apiList from './components/ApiList/translations.json';
import customFieldDisplayItem from './components/ApiModel/CustomFieldDisplayItem/translations.json';
import detailFieldSettings from './components/ApiModel/DetailFieldSettings/translations.json';
import imageSizeValidationField from './components/ApiModel/ImageSizeValidationField/translations.json';
import inputIframeField from './components/ApiModel/InputIframeField/translations.json';
import inputRichEditorField from './components/ApiModel/InputRichEditorField/translations.json';
import inputSelectField from './components/ApiModel/InputSelectField/translations.json';
import numberSizeLimitValidationField from './components/ApiModel/NumberSizeLimitValidationField/translations.json';
import patternMatchValidationField from './components/ApiModel/PatternMatchValidationField/translations.json';
import referenceContent from './components/ApiModel/ReferenceContent/translations.json';
import referenceDisplayItem from './components/ApiModel/ReferenceDisplayItem/translations.json';
import relationListCountValidationField from './components/ApiModel/RelationListCountValidationField/translations.json';
import repeaterCountValidationField from './components/ApiModel/RepeaterCountValidationField/translations.json';
import selectCustomField from './components/ApiModel/SelectCustomField/translations.json';
import selectKind from './components/ApiModel/SelectKind/translations.json';
import selectRelationInModal from './components/ApiModel/SelectRelationInModal/translations.json';
import selectRepeaterField from './components/ApiModel/SelectRepeaterField/translations.json';
import textSizeLimitValidationField from './components/ApiModel/TextSizeLimitValidationField/translations.json';
import uniqueValidationField from './components/ApiModel/UniqueValidationField/translations.json';
import apiModel from './components/ApiModel/translations.json';
import code from './components/ApiRequest/Code/translations.json';
import method from './components/ApiRequest/Method/translations.json';
import apiRequest from './components/ApiRequest/translations.json';
import contentIdSettings from './components/ApiSettings/ContentIdSettings/translations.json';
import customStatusSettings from './components/ApiSettings/CustomStatusSettings/translations.json';
import deleteSettings from './components/ApiSettings/DeleteSettings/translations.json';
import modelSettings from './components/ApiSettings/ModelSettings/translations.json';
import nameSettings from './components/ApiSettings/NameSettings/translations.json';
import previewSettings from './components/ApiSettings/PreviewSettings/translations.json';
import webhooks from './components/ApiSettings/WebhookSettings/Webhooks/translations.json';
import webhookSettings from './components/ApiSettings/WebhookSettings/translations.json';
import apiSettings from './components/ApiSettings/translations.json';
import app from './components/App/translations.json';
import breadcrumb from './components/Breadcrumb/translations.json';
import confirmSignup from './components/ConfirmSignup/translations.json';
import contentActions from './components/ContentActions/translations.json';
import row from './components/ContentFilters/Row/translations.json';
import contentFilters from './components/ContentFilters/translations.json';
import contentHeader from './components/ContentHeader/translations.json';
import contentHistories from './components/ContentHistories/translations.json';
import contentListCustomField from './components/ContentList/CustomField/translations.json';
import contentList from './components/ContentList/translations.json';
import createApiInputBasicInfo from './components/CreateApi/InputBasicInfo/translations.json';
import createApiInputModel from './components/CreateApi/InputModel/translations.json';
import createApiInputType from './components/CreateApi/InputType/translations.json';
import selectApiTemplate from './components/CreateApi/SelectApiTemplate/translations.json';
import createApi from './components/CreateApi/translations.json';
import createContent from './components/CreateContent/translations.json';
import createEnvironment from './components/CreateEnvironment/translations.json';
import inputBasicInfo from './components/CreateField/InputBasicInfo/translations.json';
import inputLayout from './components/CreateField/InputLayout/translations.json';
import inputModel from './components/CreateField/InputModel/translations.json';
import createField from './components/CreateField/translations.json';
import createReview from './components/CreateReview/translations.json';
import createRole from './components/CreateRole/translations.json';
import selectTemplate from './components/CreateService/SelectTemplate/translations.json';
import createService from './components/CreateService/translations.json';
import customFields from './components/CustomFields/translations.json';
import customFieldsDeleteSettings from './components/CustomFieldsSettings/DeleteSettings/translations.json';
import customFieldsLayoutSettings from './components/CustomFieldsSettings/LayoutSettings/translations.json';
import customFieldsModelSettings from './components/CustomFieldsSettings/ModelSettings/translations.json';
import customFieldsNameSettings from './components/CustomFieldsSettings/NameSettings/translations.json';
import customFieldsSettings from './components/CustomFieldsSettings/translations.json';
import editApiKey from './components/EditApiKey/translations.json';
import editContent from './components/EditContent/translations.json';
import editMember from './components/EditMember/translations.json';
import editReview from './components/EditReview/translations.json';
import editRole from './components/EditRole/translations.json';
import event from './components/Event/translations.json';
import fieldLayout from './components/FieldLayout/translations.json';
import forbidden from './components/Forbidden/translations.json';
import forgotPassword from './components/ForgotPassword/translations.json';
import customField from './components/Form/Customfield/translations.json';
import filefield from './components/Form/Filefield/translations.json';
import relatedContentsModal from './components/Form/RelatedContentsModal/translations.json';
import formRelationContent from './components/Form/RelationContent/translations.json';
import relationListSelect from './components/Form/RelationListSelect/translations.json';
import relationModal from './components/Form/RelationModal/translations.json';
import relationSelect from './components/Form/RelationSelect/translations.json';
import repeaterfield from './components/Form/Repeaterfield/translations.json';
import reservationDatePicker from './components/Form/ReservationDatePicker/translations.json';
import richEditor from './components/Form/RichEditor/translations.json';
import iFrameContent from './components/IframeContent/translations.json';
import importStatus from './components/ImportStatus/translations.json';
import inputField from './components/InputField/translations.json';
import inviteMemberModal from './components/InviteMemberModal/translations.json';
import ipDenied from './components/IpDenied/translations.json';
import joinServiceNotifier from './components/JoinServiceNotifier/translations.json';
import letsGo from './components/LetsGo/translations.json';
import main from './components/Main/translations.json';
import maintenance from './components/Maintenance/translations.json';
import managementPolicySetting from './components/ManagementPolicySetting/translations.json';
import media from './components/Media/translations.json';
import mediaApiRequest from './components/MediaApiRequest/translations.json';
import mediaFilterRow from './components/MediaFilter/Row/translations.json';
import mediaFilter from './components/MediaFilter/translations.json';
import mediaFilterList from './components/MediaFilterList/translations.json';
import tagsSettings from './components/MediaJson/TagsSettings/translations.json';
import mediaJson from './components/MediaJson/translations.json';
import editMediumTag from './components/MediaSettings/TagsSettings/EditMediumTag/translations.json';
import mediumTags from './components/MediaSettings/TagsSettings/MediumTags/translations.json';
import mediaTagsSettings from './components/MediaSettings/TagsSettings/translations.json';
import mediaWebhookSettings from './components/MediaSettings/WebhookSettings/translations.json';
import mediaSettings from './components/MediaSettings/translations.json';
import mediaTop from './components/MediaTop/translations.json';
import memberList from './components/MemberList/translations.json';
import memberListTable from './components/MemberListTable/translations.json';
import membersTop from './components/MembersTop/translations.json';
import mfaDenied from './components/MfaDenied/translations.json';
import nav from './components/Nav/translations.json';
import notFound from './components/NotFound/translations.json';
import planCard from './components/PlanCard/translations.json';
import planListModal from './components/PlanListModal/translations.json';
import policySetting from './components/PolicySetting/translations.json';
import priorityPolicy from './components/PriorityPolicy/translations.json';
import profileDelete from './components/Profile/Delete/translations.json';
import profileLanguage from './components/Profile/Language/translations.json';
import profileMfa from './components/Profile/Mfa/translations.json';
import profileName from './components/Profile/Name/translations.json';
import profilePassword from './components/Profile/Password/translations.json';
import profile from './components/Profile/translations.json';
import reviewList from './components/ReviewList/translations.json';
import reviewsTop from './components/ReviewsTop/translations.json';
import roleList from './components/RoleList/translations.json';
import rolesTop from './components/RolesTop/translations.json';
import root from './components/Root/translations.json';
import samlCallback from './components/SAMLCallback/translations.json';
import samlDenied from './components/SAMLDenied/translations.json';
import samlSignin from './components/SAMLSignin/translations.json';
import selectServiceUsers from './components/SelectServiceUsers/translations.json';
import serviceAmountSettings from './components/ServiceSettings/AmountSettings/translations.json';
import serviceAuditLogSettings from './components/ServiceSettings/AuditLogSettings/translations.json';
import serviceAuditLogTables from './components/ServiceSettings/AuditLogTables/translations.json';
import serviceBillingSettings from './components/ServiceSettings/BillingSettings/translations.json';
import serviceContentsCountSettings from './components/ServiceSettings/ContentsCountSettings/translations.json';
import serviceCustomDomainSettings from './components/ServiceSettings/CustomDomainSettings/translations.json';
import serviceDeleteSettings from './components/ServiceSettings/DeleteSettings/translations.json';
import serviceEnvironmentsSettings from './components/ServiceSettings/EnvironmentsSettings/translations.json';
import serviceIdSettings from './components/ServiceSettings/IdSettings/translations.json';
import serviceIpRestrictionsSettings from './components/ServiceSettings/IpRestrictionsSettings/translations.json';
import serviceNameSettings from './components/ServiceSettings/NameSettings/translations.json';
import serviceRequireMfaSettings from './components/ServiceSettings/RequireMfaSettings/translations.json';
import serviceSettings from './components/ServiceSettings/translations.json';
import permissionList from './components/ServiceSideBar/PermissionList/translations.json';
import releaseAnnounceModal from './components/ServiceSideBar/ReleaseAnnounceModal/translations.json';
import serviceSideBar from './components/ServiceSideBar/translations.json';
import multiFactorAuth from './components/Signin/MultiFactorAuth/translations.json';
import signin from './components/Signin/translations.json';
import signup from './components/Signup/translations.json';
import survey from './components/Survey/translations.json';
import suspended from './components/Suspended/translations.json';
import upsell from './components/Upsell/translations.json';
import validations from './components/Validations/translations.json';
import verifyInvitation from './components/VerifyInvitation/translations.json';
import welcome from './components/Welcome/translations.json';
import contentStatus from './components/ui/ContentStatus/translations.json';
import contentStatusState from './components/ui/ContentStatusState/translations.json';
import dropArea from './components/ui/DropArea/translations.json';
import empty from './components/ui/Empty/translations.json';
import error from './components/ui/Error/translations.json';
import footer from './components/ui/Footer/translations.json';
import helpBody from './components/ui/HelpBody/translations.json';
import helpButton from './components/ui/HelpButton/translations.json';
import reviewStatus from './components/ui/ReviewStatus/translations.json';
import selectfield from './components/ui/Selectfield/translations.json';
import uiUpsell from './components/ui/Upsell/translations.json';
import upsellModal from './components/ui/UpsellModal/translations.json';
import constants from './constants/translations.json';
import hooksAccount from './hooks/Account/translations.json';
import hooksAnnouncement from './hooks/Announcement/translations.json';
import hooksApi from './hooks/Api/translations.json';
import hooksApiKey from './hooks/ApiKey/translations.json';
import hooksAuditLog from './hooks/AuditLog/translations.json';
import hooksAuth from './hooks/Auth/translations.json';
import hooksContent from './hooks/Content/translations.json';
import hooksCustomStatus from './hooks/CustomStatus/translations.json';
import hooksDocument from './hooks/Document/translations.json';
import hooksEnvironment from './hooks/Environment/translations.json';
import hooksMedium from './hooks/Medium/translations.json';
import hooksMediumTags from './hooks/MediumTags/translations.json';
import hooksMember from './hooks/Member/translations.json';
import hooksProfile from './hooks/Profile/translations.json';
import hooksReview from './hooks/Review/translations.json';
import hooksRole from './hooks/Role/translations.json';
import hooksService from './hooks/Service/translations.json';
import hooksWebhook from './hooks/Webhook/translations.json';
import hooks from './hooks/translations.json';
import util from './util/translations.json';
import memberSelect from './views/Common/member/MemberSelect/translations.json';
import contentListMediaList from './views/ContentList/MediaList/translations.json';
import contentListRelationList from './views/ContentList/RelationList/translations.json';
import contentListNoPermission from './views/ContentList/shared/NoPermission/translations.json';
import updateCreatedByModal from './views/EditContent/UpdateContentCreatedByModal/translations.json';
import mediaField from './views/InputField/MediaField/translations.json';
import mediaListField from './views/InputField/MediaListField/translations.json';
import richEditorV2 from './views/InputField/RichEditorV2/translations.json';
import imageOperation from './views/InputField/shared/ImageOperation/translations.json';
import inputFieldNoPermission from './views/InputField/shared/NoPermission/translations.json';
import mediaDeleteSettings from './views/MediumSettings/DeleteSettings/translations.json';
import formRoleHelpers from './views/Role/FormRole/helpers/translations.json';
import formRole from './views/Role/FormRole/translations.json';
import environmentsSettingsTooltip from './views/ServiceSettings/EnvironmentSettings/EnvironmentsSettingsTooltip/translations.json';
import updateEnvironmentNameModal from './views/ServiceSettings/EnvironmentSettings/UpdateEnvironmentNameModal/translations.json';
import exportService from './views/ServiceSettings/ExportService/translations.json';
import serviceLanguageSettings from './views/ServiceSettings/LanguageSettings/translations.json';

import inputCustomClassField from '@/views/ApiModel/InputCustomClassField/translations.json';
import inputRichEditorV2ColorListField from '@/views/ApiModel/InputRichEditorV2ColorListField/translations.json';
import inputRichEditorV2OptionsField from '@/views/ApiModel/InputRichEditorV2OptionsField/translations.json';
import alertDialog from '@/views/Common/Ui/AlertDialog/translations.json';
import commonUi from '@/views/Common/Ui/translations.json';
import commonApiIpAddressRestriction from '@/views/Common/apiIpAddressRestriction/translations.json';
import commonContentContentsComparison from '@/views/Common/content/ContentsComparison/translations.json';
import commonMedium from '@/views/Common/medium/translations.json';
import commonPlanUpsell from '@/views/Common/plan/Upsell/translations.json';
import reservationModal from '@/views/ContentActions/ReservationModal/translations.json';
import reservationTrigger from '@/views/ContentActions/ReservationTrigger/translations.json';
import updateDraftKey from '@/views/EditContent/UpdateDraftKey/translations.json';
import updatePublishedAtModal from '@/views/EditContent/UpdatePublishedAtModal/translations.json';
import serviceApiIpAddressRestrictionSettings from '@/views/ServiceSettings/ApiIpAddressRestrictionSettings/translations.json';
import serviceSAMLSettings from '@/views/ServiceSettings/SAMLSettings/translations.json';
import reviewsCount from '@/views/ServiceSideBar/ReviewsCount/translations.json';
import apisContentContentCapacity from '@/views/apis/content/ContentCapacity/translations.json';
import apisContentCompare from '@/views/apis/content/compare/translations.json';
import apisSettingsIpAddressRestrictionSettings from '@/views/apis/settings/IpAddressRestrictionSettings/translations.json';
import mediaJsonUpdateCreatedBy from '@/views/media/Media/MediaJson/UpdateCreatedBy/translations.json';
import membersInviteMemberRolesSettingField from '@/views/members/InviteMember/RolesSettingField/translations.json';
import reviewsReqIdEditReview from '@/views/reviews/reqId/EditReview/translations.json';

export const resources = {
  en: {
    reviewsCount: reviewsCount.en,
    mediaApiRequest: mediaApiRequest.en,
    repeaterfield: repeaterfield.en,
    iFrameContent: iFrameContent.en,
    relationListSelect: relationListSelect.en,
    mediaJson: mediaJson.en,
    mediaDeleteSettings: mediaDeleteSettings.en,
    tagsSettings: tagsSettings.en,
    mediaTop: mediaTop.en,
    mediaFilter: mediaFilter.en,
    mediaFilterRow: mediaFilterRow.en,
    mediaFilterList: mediaFilterList.en,
    mfaDenied: mfaDenied.en,
    nav: nav.en,
    announcement: announcement.en,
    apiKeyList: apiKeyList.en,
    apiKeyModal: apiKeyModal.en,
    editApiKey: editApiKey.en,
    apiRequest: apiRequest.en,
    apiList: apiList.en,
    app: app.en,
    method: method.en,
    code: code.en,
    managementPolicySetting: managementPolicySetting.en,
    policySetting: policySetting.en,
    priorityPolicy: priorityPolicy.en,
    apiKeysTop: apiKeysTop.en,
    selectfield: selectfield.en,
    inviteMemberModal: inviteMemberModal.en,
    uiUpsell: uiUpsell.en,
    helpBody: helpBody.en,
    helpButton: helpButton.en,
    forbidden: forbidden.en,
    forgotPassword: forgotPassword.en,
    footer: footer.en,
    maintenance: maintenance.en,
    apiModel: apiModel.en,
    customFieldDisplayItem: customFieldDisplayItem.en,
    detailFieldSettings: detailFieldSettings.en,
    inputRichEditorField: inputRichEditorField.en,
    imageSizeValidationField: imageSizeValidationField.en,
    inputIframeField: inputIframeField.en,
    inputSelectField: inputSelectField.en,
    patternMatchValidationField: patternMatchValidationField.en,
    referenceContent: referenceContent.en,
    referenceDisplayItem: referenceDisplayItem.en,
    relationListCountValidationField: relationListCountValidationField.en,
    repeaterCountValidationField: repeaterCountValidationField.en,
    selectCustomField: selectCustomField.en,
    selectKind: selectKind.en,
    selectRelationInModal: selectRelationInModal.en,
    selectRepeaterField: selectRepeaterField.en,
    textSizeLimitValidationField: textSizeLimitValidationField.en,
    uniqueValidationField: uniqueValidationField.en,
    numberSizeLimitValidationField: numberSizeLimitValidationField.en,
    apiSettings: apiSettings.en,
    customStatusSettings: customStatusSettings.en,
    contentIdSettings: contentIdSettings.en,
    deleteSettings: deleteSettings.en,
    modelSettings: modelSettings.en,
    nameSettings: nameSettings.en,
    previewSettings: previewSettings.en,
    webhookSettings: webhookSettings.en,
    webhooks: webhooks.en,
    contentStatus: contentStatus.en,
    contentStatusState: contentStatusState.en,
    dropArea: dropArea.en,
    reviewStatus: reviewStatus.en,
    createReview: createReview.en,
    editRole: editRole.en,
    customFields: customFields.en,
    customFieldsSettings: customFieldsSettings.en,
    customFieldsNameSettings: customFieldsNameSettings.en,
    customFieldsDeleteSettings: customFieldsDeleteSettings.en,
    customFieldsLayoutSettings: customFieldsLayoutSettings.en,
    customFieldsModelSettings: customFieldsModelSettings.en,
    createField: createField.en,
    inputBasicInfo: inputBasicInfo.en,
    inputLayout: inputLayout.en,
    fieldLayout: fieldLayout.en,
    inputModel: inputModel.en,
    createService: createService.en,
    serviceSettings: serviceSettings.en,
    serviceLanguageSettings: serviceLanguageSettings.en,
    serviceAmountSettings: serviceAmountSettings.en,
    serviceAuditLogSettings: serviceAuditLogSettings.en,
    serviceAuditLogTables: serviceAuditLogTables.en,
    serviceBillingSettings: serviceBillingSettings.en,
    serviceContentsCountSettings: serviceContentsCountSettings.en,
    serviceCustomDomainSettings: serviceCustomDomainSettings.en,
    serviceDeleteSettings: serviceDeleteSettings.en,
    serviceEnvironmentsSettings: serviceEnvironmentsSettings.en,
    serviceIdSettings: serviceIdSettings.en,
    serviceIpRestrictionsSettings: serviceIpRestrictionsSettings.en,
    serviceNameSettings: serviceNameSettings.en,
    serviceRequireMfaSettings: serviceRequireMfaSettings.en,
    serviceApiIpAddressRestrictionSettings:
      serviceApiIpAddressRestrictionSettings.en,
    serviceSAMLSettings: serviceSAMLSettings.en,
    reservationModal: reservationModal.en,
    reservationTrigger: reservationTrigger.en,
    exportService: exportService.en,
    breadcrumb: breadcrumb.en,
    serviceSideBar: serviceSideBar.en,
    createEnvironment: createEnvironment.en,
    updateEnvironmentNameModal: updateEnvironmentNameModal.en,
    richEditorV2: richEditorV2.en,
    mediaField: mediaField.en,
    mediaListField: mediaListField.en,
    imageOperation: imageOperation.en,
    inputFieldNoPermission: inputFieldNoPermission.en,
    environmentsSettingsTooltip: environmentsSettingsTooltip.en,
    permissionList: permissionList.en,
    profile: profile.en,
    profileDelete: profileDelete.en,
    profileMfa: profileMfa.en,
    profileName: profileName.en,
    profilePassword: profilePassword.en,
    profileLanguage: profileLanguage.en,
    confirmSignup: confirmSignup.en,
    releaseAnnounceModal: releaseAnnounceModal.en,
    validations: validations.en,
    constants: constants.en,
    suspended: suspended.en,
    contentList: contentList.en,
    contentListCustomField: contentListCustomField.en,
    importStatus: importStatus.en,
    error: error.en,
    empty: empty.en,
    upsellModal: upsellModal.en,
    updateCreatedByModal: updateCreatedByModal.en,
    contentFilters: contentFilters.en,
    row: row.en,
    relationModal: relationModal.en,
    contentHeader: contentHeader.en,
    contentHistories: contentHistories.en,
    upsell: upsell.en,
    createContent: createContent.en,
    createApi: createApi.en,
    createApiInputModel: createApiInputModel.en,
    createApiInputBasicInfo: createApiInputBasicInfo.en,
    contentActions: contentActions.en,
    createApiInputType: createApiInputType.en,
    selectTemplate: selectTemplate.en,
    selectApiTemplate: selectApiTemplate.en,
    selectServiceUsers: selectServiceUsers.en,
    relatedContentsModal: relatedContentsModal.en,
    reservationDatePicker: reservationDatePicker.en,
    filefield: filefield.en,
    inputField: inputField.en,
    editMember: editMember.en,
    membersTop: membersTop.en,
    editContent: editContent.en,
    media: media.en,
    relationSelect: relationSelect.en,
    letsGo: letsGo.en,
    ipDenied: ipDenied.en,
    joinServiceNotifier: joinServiceNotifier.en,
    samlCallback: samlCallback.en,
    samlSignin: samlSignin.en,
    samlDenied: samlDenied.en,
    reviewList: reviewList.en,
    editReview: editReview.en,
    event: event.en,
    memberList: memberList.en,
    memberListTable: memberListTable.en,
    notFound: notFound.en,
    mediaWebhookSettings: mediaWebhookSettings.en,
    mediaTagsSettings: mediaTagsSettings.en,
    editMediumTag: editMediumTag.en,
    mediumTags: mediumTags.en,
    mediaSettings: mediaSettings.en,
    planCard: planCard.en,
    formRoleHelpers: formRoleHelpers.en,
    formRole: formRole.en,
    main: main.en,
    planListModal: planListModal.en,
    roleList: roleList.en,
    root: root.en,
    rolesTop: rolesTop.en,
    reviewsTop: reviewsTop.en,
    signup: signup.en,
    signin: signin.en,
    survey: survey.en,
    customField: customField.en,
    multiFactorAuth: multiFactorAuth.en,
    welcome: welcome.en,
    verifyInvitation: verifyInvitation.en,
    createRole: createRole.en,
    richEditor: richEditor.en,
    formRelationContent: formRelationContent.en,
    inputCustomClassField: inputCustomClassField.en,
    inputRichEditorV2OptionsField: inputRichEditorV2OptionsField.en,
    inputRichEditorV2ColorListField: inputRichEditorV2ColorListField.en,
    apisContentCompare: apisContentCompare.en,
    apisContentContentCapacity: apisContentContentCapacity.en,
    apisSettingsIpAddressRestrictionSettings:
      apisSettingsIpAddressRestrictionSettings.en,
    alertDialog: alertDialog.en,
    commonApiIpAddressRestriction: commonApiIpAddressRestriction.en,
    commonContentContentsComparison: commonContentContentsComparison.en,
    commonMedium: commonMedium.en,
    commonPlanUpsell: commonPlanUpsell.en,
    commonUi: commonUi.en,
    updateDraftKey: updateDraftKey.en,
    updatePublishedAtModal: updatePublishedAtModal.en,
    mediaJsonUpdateCreatedBy: mediaJsonUpdateCreatedBy.en,
    membersInviteMemberRolesSettingField:
      membersInviteMemberRolesSettingField.en,
    reviewsReqIdEditReview: reviewsReqIdEditReview.en,
    contentListMediaList: contentListMediaList.en,
    contentListRelationList: contentListRelationList.en,
    contentListNoPermission: contentListNoPermission.en,
    memberSelect: memberSelect.en,
    util: util.en,
    hooks: hooks.en,
    hooksCustomStatus: hooksCustomStatus.en,
    hooksContent: hooksContent.en,
    hooksApiKey: hooksApiKey.en,
    hooksApi: hooksApi.en,
    hooksAuditLog: hooksAuditLog.en,
    hooksMedium: hooksMedium.en,
    hooksMediumTags: hooksMediumTags.en,
    hooksMember: hooksMember.en,
    hooksRole: hooksRole.en,
    hooksReview: hooksReview.en,
    hooksAccount: hooksAccount.en,
    hooksDocument: hooksDocument.en,
    hooksAnnouncement: hooksAnnouncement.en,
    hooksWebhook: hooksWebhook.en,
    hooksService: hooksService.en,
    hooksEnvironment: hooksEnvironment.en,
    hooksProfile: hooksProfile.en,
    hooksAuth: hooksAuth.en,
  },
  ja: {
    reviewsCount: reviewsCount.ja,
    mediaApiRequest: mediaApiRequest.ja,
    iFrameContent: iFrameContent.ja,
    relationListSelect: relationListSelect.ja,
    mediaJson: mediaJson.ja,
    mediaDeleteSettings: mediaDeleteSettings.ja,
    tagsSettings: tagsSettings.ja,
    mediaTop: mediaTop.ja,
    mediaFilter: mediaFilter.ja,
    mediaFilterRow: mediaFilterRow.ja,
    mediaFilterList: mediaFilterList.ja,
    mfaDenied: mfaDenied.ja,
    nav: nav.ja,
    announcement: announcement.ja,
    apiKeyList: apiKeyList.ja,
    apiKeyModal: apiKeyModal.ja,
    editApiKey: editApiKey.ja,
    apiRequest: apiRequest.ja,
    apiList: apiList.ja,
    app: app.ja,
    method: method.ja,
    code: code.ja,
    managementPolicySetting: managementPolicySetting.ja,
    policySetting: policySetting.ja,
    priorityPolicy: priorityPolicy.ja,
    apiKeysTop: apiKeysTop.ja,
    selectfield: selectfield.ja,
    inviteMemberModal: inviteMemberModal.ja,
    uiUpsell: uiUpsell.ja,
    helpBody: helpBody.ja,
    helpButton: helpButton.ja,
    forbidden: forbidden.ja,
    forgotPassword: forgotPassword.ja,
    footer: footer.ja,
    maintenance: maintenance.ja,
    apiModel: apiModel.ja,
    detailFieldSettings: detailFieldSettings.ja,
    customFieldDisplayItem: customFieldDisplayItem.ja,
    inputRichEditorField: inputRichEditorField.ja,
    imageSizeValidationField: imageSizeValidationField.ja,
    inputIframeField: inputIframeField.ja,
    inputSelectField: inputSelectField.ja,
    patternMatchValidationField: patternMatchValidationField.ja,
    referenceContent: referenceContent.ja,
    referenceDisplayItem: referenceDisplayItem.ja,
    relationListCountValidationField: relationListCountValidationField.ja,
    repeaterCountValidationField: repeaterCountValidationField.ja,
    selectCustomField: selectCustomField.ja,
    selectKind: selectKind.ja,
    selectRelationInModal: selectRelationInModal.ja,
    selectRepeaterField: selectRepeaterField.ja,
    textSizeLimitValidationField: textSizeLimitValidationField.ja,
    uniqueValidationField: uniqueValidationField.ja,
    numberSizeLimitValidationField: numberSizeLimitValidationField.ja,
    apiSettings: apiSettings.ja,
    customStatusSettings: customStatusSettings.ja,
    contentIdSettings: contentIdSettings.ja,
    deleteSettings: deleteSettings.ja,
    modelSettings: modelSettings.ja,
    nameSettings: nameSettings.ja,
    previewSettings: previewSettings.ja,
    webhookSettings: webhookSettings.ja,
    webhooks: webhooks.ja,
    contentStatus: contentStatus.ja,
    contentStatusState: contentStatusState.ja,
    dropArea: dropArea.ja,
    reviewStatus: reviewStatus.ja,
    createReview: createReview.ja,
    editRole: editRole.ja,
    customFields: customFields.ja,
    customFieldsSettings: customFieldsSettings.ja,
    customFieldsNameSettings: customFieldsNameSettings.ja,
    customFieldsDeleteSettings: customFieldsDeleteSettings.ja,
    customFieldsLayoutSettings: customFieldsLayoutSettings.ja,
    customFieldsModelSettings: customFieldsModelSettings.ja,
    createField: createField.ja,
    inputBasicInfo: inputBasicInfo.ja,
    inputLayout: inputLayout.ja,
    fieldLayout: fieldLayout.ja,
    inputModel: inputModel.ja,
    createService: createService.ja,
    serviceSettings: serviceSettings.ja,
    serviceLanguageSettings: serviceLanguageSettings.ja,
    serviceAmountSettings: serviceAmountSettings.ja,
    serviceAuditLogSettings: serviceAuditLogSettings.ja,
    serviceAuditLogTables: serviceAuditLogTables.ja,
    serviceBillingSettings: serviceBillingSettings.ja,
    serviceContentsCountSettings: serviceContentsCountSettings.ja,
    serviceCustomDomainSettings: serviceCustomDomainSettings.ja,
    serviceDeleteSettings: serviceDeleteSettings.ja,
    serviceEnvironmentsSettings: serviceEnvironmentsSettings.ja,
    serviceIdSettings: serviceIdSettings.ja,
    serviceIpRestrictionsSettings: serviceIpRestrictionsSettings.ja,
    serviceNameSettings: serviceNameSettings.ja,
    serviceRequireMfaSettings: serviceRequireMfaSettings.ja,
    serviceApiIpAddressRestrictionSettings:
      serviceApiIpAddressRestrictionSettings.ja,
    serviceSAMLSettings: serviceSAMLSettings.ja,
    reservationModal: reservationModal.ja,
    reservationTrigger: reservationTrigger.ja,
    exportService: exportService.ja,
    breadcrumb: breadcrumb.ja,
    serviceSideBar: serviceSideBar.ja,
    createEnvironment: createEnvironment.ja,
    updateEnvironmentNameModal: updateEnvironmentNameModal.ja,
    richEditorV2: richEditorV2.ja,
    mediaField: mediaField.ja,
    mediaListField: mediaListField.ja,
    imageOperation: imageOperation.ja,
    inputFieldNoPermission: inputFieldNoPermission.ja,
    environmentsSettingsTooltip: environmentsSettingsTooltip.ja,
    permissionList: permissionList.ja,
    profile: profile.ja,
    profileDelete: profileDelete.ja,
    profileMfa: profileMfa.ja,
    profileName: profileName.ja,
    profilePassword: profilePassword.ja,
    profileLanguage: profileLanguage.ja,
    confirmSignup: confirmSignup.ja,
    releaseAnnounceModal: releaseAnnounceModal.ja,
    validations: validations.ja,
    constants: constants.ja,
    suspended: suspended.ja,
    contentList: contentList.ja,
    contentListCustomField: contentListCustomField.ja,
    importStatus: importStatus.ja,
    error: error.ja,
    empty: empty.ja,
    upsellModal: upsellModal.ja,
    updateCreatedByModal: updateCreatedByModal.ja,
    contentFilters: contentFilters.ja,
    row: row.ja,
    relationModal: relationModal.ja,
    contentHeader: contentHeader.ja,
    contentHistories: contentHistories.ja,
    upsell: upsell.ja,
    createContent: createContent.ja,
    createApi: createApi.ja,
    createApiInputModel: createApiInputModel.ja,
    createApiInputBasicInfo: createApiInputBasicInfo.ja,
    contentActions: contentActions.ja,
    createApiInputType: createApiInputType.ja,
    selectTemplate: selectTemplate.ja,
    selectApiTemplate: selectApiTemplate.ja,
    selectServiceUsers: selectServiceUsers.ja,
    relatedContentsModal: relatedContentsModal.ja,
    reservationDatePicker: reservationDatePicker.ja,
    filefield: filefield.ja,
    inputField: inputField.ja,
    editMember: editMember.ja,
    membersTop: membersTop.ja,
    editContent: editContent.ja,
    media: media.ja,
    relationSelect: relationSelect.ja,
    letsGo: letsGo.ja,
    ipDenied: ipDenied.ja,
    joinServiceNotifier: joinServiceNotifier.ja,
    samlCallback: samlCallback.ja,
    samlSignin: samlSignin.ja,
    samlDenied: samlDenied.ja,
    reviewList: reviewList.ja,
    editReview: editReview.ja,
    event: event.ja,
    memberList: memberList.ja,
    memberListTable: memberListTable.ja,
    notFound: notFound.ja,
    mediaWebhookSettings: mediaWebhookSettings.ja,
    mediaTagsSettings: mediaTagsSettings.ja,
    editMediumTag: editMediumTag.ja,
    mediumTags: mediumTags.ja,
    mediaSettings: mediaSettings.ja,
    planCard: planCard.ja,
    formRoleHelpers: formRoleHelpers.ja,
    formRole: formRole.ja,
    main: main.ja,
    planListModal: planListModal.ja,
    roleList: roleList.ja,
    root: root.ja,
    rolesTop: rolesTop.ja,
    reviewsTop: reviewsTop.ja,
    signup: signup.ja,
    signin: signin.ja,
    survey: survey.ja,
    customField: customField.ja,
    repeaterfield: repeaterfield.ja,
    multiFactorAuth: multiFactorAuth.ja,
    welcome: welcome.ja,
    verifyInvitation: verifyInvitation.ja,
    createRole: createRole.ja,
    richEditor: richEditor.ja,
    formRelationContent: formRelationContent.ja,
    inputCustomClassField: inputCustomClassField.ja,
    inputRichEditorV2OptionsField: inputRichEditorV2OptionsField.ja,
    inputRichEditorV2ColorListField: inputRichEditorV2ColorListField.ja,
    apisContentCompare: apisContentCompare.ja,
    apisContentContentCapacity: apisContentContentCapacity.ja,
    apisSettingsIpAddressRestrictionSettings:
      apisSettingsIpAddressRestrictionSettings.ja,
    alertDialog: alertDialog.ja,
    commonApiIpAddressRestriction: commonApiIpAddressRestriction.ja,
    commonContentContentsComparison: commonContentContentsComparison.ja,
    commonMedium: commonMedium.ja,
    commonPlanUpsell: commonPlanUpsell.ja,
    commonUi: commonUi.ja,
    updateDraftKey: updateDraftKey.ja,
    updatePublishedAtModal: updatePublishedAtModal.ja,
    mediaJsonUpdateCreatedBy: mediaJsonUpdateCreatedBy.ja,
    membersInviteMemberRolesSettingField:
      membersInviteMemberRolesSettingField.ja,
    reviewsReqIdEditReview: reviewsReqIdEditReview.ja,
    contentListMediaList: contentListMediaList.ja,
    contentListRelationList: contentListRelationList.ja,
    contentListNoPermission: contentListNoPermission.ja,
    memberSelect: memberSelect.ja,
    hooks: hooks.ja,
    util: util.ja,
    hooksCustomStatus: hooksCustomStatus.ja,
    hooksContent: hooksContent.ja,
    hooksApiKey: hooksApiKey.ja,
    hooksApi: hooksApi.ja,
    hooksAuditLog: hooksAuditLog.ja,
    hooksMedium: hooksMedium.ja,
    hooksMediumTags: hooksMediumTags.ja,
    hooksMember: hooksMember.ja,
    hooksRole: hooksRole.ja,
    hooksReview: hooksReview.ja,
    hooksAccount: hooksAccount.ja,
    hooksDocument: hooksDocument.ja,
    hooksAnnouncement: hooksAnnouncement.ja,
    hooksWebhook: hooksWebhook.ja,
    hooksService: hooksService.ja,
    hooksEnvironment: hooksEnvironment.ja,
    hooksProfile: hooksProfile.ja,
    hooksAuth: hooksAuth.ja,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'ja',
  fallbackLng: 'ja',
  debug: false,
  saveMissing: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});

export default i18n;

export const changeLanguage = (lng: 'ja' | 'en'): void => {
  i18n.changeLanguage(lng);
};

type Namespace = keyof (typeof resources)['ja'];
export const getTranslation = <T extends Namespace>(
  ns: T,
): {
  t: (key: NonNullable<KeyPrefix<T>>) => string;
} => {
  const t = (key: NonNullable<KeyPrefix<T>>) =>
    i18n.t(key, { ns, defaultValue: '' }) as string;
  return { t };
};
