import { getCurrentPlan, getCurrentService } from '@/usecase/serviceUsecase';

import { useApi } from '@/lib/useApi';

export const useCurrentService = () => {
  return useApi(['currentService'], () => getCurrentService(), {
    staleTime: Number.POSITIVE_INFINITY,
  });
};

export const useCurrentPlan = (serviceId?: string) => {
  return useApi(
    ['planList', 'currentPlan'],
    async () => getCurrentPlan(serviceId),
    {
      enabled: !!serviceId,
      staleTime: Number.POSITIVE_INFINITY,
    },
  );
};
