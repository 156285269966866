import type React from 'react';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getDefaultHost, getServiceDomain } from '../../util';

import styles from './mfaDenied.module.css';

type Props = {
  allowAccess: boolean;
  children: React.ReactElement;
};

const IpDenied: React.FC<Props> = ({ allowAccess, children }) => {
  const { t } = useTranslation('mfaDenied');

  const host = useMemo(() => getDefaultHost(), []);
  const domain = getServiceDomain();
  if (allowAccess === false) {
    return (
      <div className={styles.wrapper}>
        <div>
          <h1 className={styles.title}>
            {t('Two-Factor authentication must be enabled')}
          </h1>
          <div className={styles.description}>
            <p>
              {Trans({
                t,
                i18nKey:
                  'Two-Factor authentication is required to configure {{domain}} service.',
                values: { domain },
                children: (
                  <a href={`${host}/profile/mfa`}>Two-Factor authentication</a>
                ),
              })}
              <br />
              {t('Enable two-factor authentication and try again.')}
            </p>
          </div>
        </div>
      </div>
    );
  }
  return children;
};

export default IpDenied;
