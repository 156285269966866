import { useCallback } from 'react';

import type { RichEditorV2Options } from '@/constants/richEditorOptions';

export type Props = {
  options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
  setOptions: React.Dispatch<string[]>;
};

export const useInputRichEditorV2OptionsField = ({
  options,
  setOptions,
}: Props) => {
  const onChangeOption = useCallback(
    (value: string) => () => {
      const isSameValue = options.some((f) => {
        return f === value;
      });

      const newValue = isSameValue
        ? options.filter((f) => {
            return f !== value;
          })
        : [...(options || []), value];

      setOptions(newValue);
    },
    [options, setOptions],
  );

  return {
    onChangeOption,
  };
};
