import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SortButton } from '..';
import { useSearch } from '../../../hooks/Content/useContentReader';
import { useDebounce } from '../../../hooks/useDebounce';
import Button from '../../ui/Button';
import IconWithTextButton from '../../ui/IconWithTextButton';
import Pagination from '../../ui/Pagination';
import Searchfield from '../../ui/Searchfield';
import Switch from '../../ui/Switch';
import Content from '../RelationContent';

import styles from './relationModal.module.css';

import type { MigrateApi } from '@/entity/api';
import { useHasCreateContentPermission } from '@/views/Common/Roles/MyRolesPermission';
import { Modal, ModalContent, ModalTrigger } from '@/views/Common/Ui/Modal';

type Props = {
  select: (content: any) => void;
  api: MigrateApi;
  close: any;
  items: any;
  toggleItem: (idValue: any) => (on: any) => void;
};

const RelationModal: React.FC<Props> = ({
  select,
  api,
  close,
  items,
  toggleItem,
}) => {
  const { t } = useTranslation('relationModal');

  // 権限取得
  const { hasCreateContentPermissionForAny } = useHasCreateContentPermission();

  // ページング
  const PAGE_LENGTH = 25;
  const [options, setOptions] = useState({
    api,
    offset: 0,
    size: PAGE_LENGTH,
  });
  const { offset } = options;

  // 検索
  const [inputSearchText, setSearchText] = useState('');
  const [q] = useDebounce(inputSearchText, 300); //実際に検索を行うのは少し待ってからにする
  const { contents, totalCount, isLoading } = useSearch(options);

  // ソート
  const setOrders = useCallback((name: any) => {
    setOptions((options) => {
      return {
        ...options,
        // @ts-expect-error
        orders: options.orders === name ? `-${name}` : name,
      };
    });
  }, []);

  // 検索文字列に変更があった場合、ページングをリセット
  useEffect(() => {
    setOptions((option) => ({
      ...option,
      q,
      offset: 0,
    }));
  }, [q]);

  if (contents && contents.length === 0 && q === '') {
    return (
      <div className={styles.modal}>
        <div className={styles.empty}>
          <h3 className={styles.emptyTitle}>{t('No contents to refer')}</h3>
          <Link to={`/apis/${api.apiEndpoint}/create`}>
            {t('Create a new content')}
          </Link>
          {t('Please')}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <header className={styles.header}>
        <Searchfield
          style={{ width: 300 }}
          defaultValue={q}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Modal>
          <ModalTrigger asChild>
            <IconWithTextButton
              icon="visibility"
              text={t('Select Display Columns')}
              outlined={true}
            />
          </ModalTrigger>
          <ModalContent
            size="medium"
            title={t('Select the items you wish to display')}
          >
            <ul className={styles.filterLists}>
              <li className={styles.filterList}>
                <Switch
                  on={items.includes('contentId')}
                  onChange={toggleItem('contentId')}
                >
                  {t('Content ID')}
                </Switch>
              </li>
              <li className={styles.filterList}>
                <Switch
                  on={items.includes('createdAt')}
                  onChange={toggleItem('createdAt')}
                >
                  {t('Date Created')}
                </Switch>
              </li>
              {api.apiFields.map((field, i) => (
                <li key={i} className={styles.filterList}>
                  <Switch
                    on={items.includes(field.idValue)}
                    onChange={toggleItem(field.idValue)}
                  >
                    {field.name}
                  </Switch>
                </li>
              ))}
            </ul>
          </ModalContent>
        </Modal>
        {hasCreateContentPermissionForAny(api.partitionKey) && (
          <div className={styles.actionList}>
            <Link to={`/apis/${api.apiEndpoint}/create`} onClick={close}>
              <Button type="secondary" value={t('Add')} />
            </Link>
          </div>
        )}
      </header>
      <div className={styles.modalContent}>
        {isLoading ? (
          <div className={styles.searching}>
            <img src="/images/icon_loading.svg" alt="" />
          </div>
        ) : (
          <>
            <div className={styles.detail}>
              <p className={styles.totalCount}>
                {(totalCount ?? 0) > 0 &&
                  t('{{offset}} ~ {{count}} of total {{totalCount}}', {
                    offset: offset + 1,
                    count:
                      (totalCount ?? 0) > offset + PAGE_LENGTH
                        ? offset + PAGE_LENGTH
                        : totalCount,
                    totalCount,
                  })}
              </p>
              {contents !== undefined && contents.length !== 0 && (
                <div className={styles.pagination}>
                  <Pagination
                    offset={offset}
                    // @ts-expect-error
                    setOptions={setOptions}
                    totalCount={totalCount ?? 0}
                    pageLength={PAGE_LENGTH}
                  />
                </div>
              )}
            </div>
            <div className={styles.container}>
              <table className={styles.table}>
                <thead className={styles.thead}>
                  <tr>
                    <th className={styles.th}>{t('Content Status')}</th>
                    {items.includes('contentId') && (
                      <th className={styles.th}>{t('Content ID')}</th>
                    )}
                    {items.includes('createdAt') && (
                      <th className={styles.th}>
                        <SortButton
                          name="createdAt"
                          // @ts-expect-error
                          orders={options.orders}
                          onClick={() => setOrders('createdAt')}
                        >
                          {t('Date Created')}
                        </SortButton>
                      </th>
                    )}
                    {api.apiFields.map(
                      (field, i) =>
                        items.includes(field.idValue) && (
                          <th
                            key={i}
                            className={`${styles.th} ${styles[field.kind]}`}
                          >
                            {['date', 'boolean', 'number'].includes(
                              field.kind,
                            ) ? (
                              <SortButton
                                name={field.fieldId}
                                // @ts-expect-error
                                orders={options.orders}
                                onClick={() => setOrders(field.fieldId)}
                              >
                                {field.name}
                              </SortButton>
                            ) : (
                              field.name
                            )}
                          </th>
                        ),
                    )}
                  </tr>
                </thead>
                <tbody className={styles.tbody}>
                  {/* @ts-expect-error */}
                  {contents.map((content, i) => {
                    return (
                      // @ts-expect-error
                      <Content
                        key={i}
                        content={content}
                        fields={api.apiFields}
                        items={items}
                        onClick={select}
                      />
                    );
                  })}
                </tbody>
              </table>
              {contents && contents.length === 0 && q !== '' && (
                <div className={styles.notMatch}>{t('No contents')}</div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RelationModal;
