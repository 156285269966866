import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useGetAccountLanguage,
  useUpdateAccountLanguage,
} from '../../../hooks/Account/useAccount';
import Button from '../../ui/Button';
import Selectfield from '../../ui/Selectfield';

import styles from './language.module.css';

const Language: React.FC = () => {
  const { t } = useTranslation('profileLanguage');
  const [language, setLanguage] = useState<'ja' | 'en' | null>(null);

  const { accountLanguage } = useGetAccountLanguage();
  const { updateAccountLanguage, updateAccountLanguageLoading } =
    useUpdateAccountLanguage();

  const onChangeLanguage = useCallback((e: any) => {
    setLanguage(e.target.value);
  }, []);

  const onUpdateAccountLanguage = useCallback(() => {
    updateAccountLanguage({
      userLanguage: language === null ? accountLanguage : language,
    });
  }, [accountLanguage, updateAccountLanguage, language]);

  return (
    <div>
      <h2 className={styles.title}>{t('Language')}</h2>
      <p>{t('Select a language for the Administration console.')}</p>
      <div className={styles.select}>
        <Selectfield
          value={language === null ? accountLanguage : language}
          onChange={onChangeLanguage}
        >
          <option value="ja">日本語</option>
          <option value="en">English</option>
        </Selectfield>
      </div>
      <div className={styles.actions}>
        <Button
          type="primary"
          value={t('Save changes')}
          onClick={onUpdateAccountLanguage}
          disabled={updateAccountLanguageLoading}
        />
      </div>
    </div>
  );
};

export default Language;
