import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '@/components/ui/Button';

import { EditEmbedOptions } from './EditEmbedOptions';

import type { OptionsField, EmbedResult } from '@/entity/iframely';

import styles from './embedmodal.module.css';

import {
  Modal,
  ModalContent,
  ModalTrigger,
  type ContentProps,
} from '@/views/Common/Ui/Modal';
import { Textfield } from '@/views/Common/Ui/Textfield';

export type Props = {
  title: string;
  trigger: React.ReactNode;
  footer: ContentProps['footer'];
  isEmbedModal: boolean;
  onToggleEmbedModal: (open: boolean) => void;
  embedUrl: string;
  onChangeEmbedUrl: (url: string) => void;
  embedUrlError?: string;
  inputOptions?: Record<string, OptionsField['value']>;
  onChangeInputOptions: (value: OptionsField['value'], key: string) => void;
  embedResult: EmbedResult;
  onClickPreviewEmbed: () => Promise<void>;
};

export const EmbedModal: React.FC<Props> = ({
  title,
  trigger,
  footer,
  isEmbedModal,
  onToggleEmbedModal,
  embedUrl,
  onChangeEmbedUrl,
  embedUrlError,
  inputOptions,
  onChangeInputOptions,
  embedResult,
  onClickPreviewEmbed,
}) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <Modal open={isEmbedModal} onOpenChange={onToggleEmbedModal}>
      <ModalTrigger asChild>{trigger}</ModalTrigger>
      <ModalContent
        size="large"
        onCloseAutoFocus={(event) => event.preventDefault()}
        title={title}
        footer={footer}
        onEscapeKeyDown={(e) => {
          e.stopPropagation();
        }}
      >
        <div className={styles.description}>
          <p className={styles.descriptionText}>
            {t(
              'Enter the URL to preview and add the content to the rich editor as is.',
            )}
          </p>
        </div>
        <div className={styles.preview}>
          <div className={styles.input}>
            <Textfield
              label="URL"
              required={true}
              placeholder="https://www.youtube.com/watch?v=xxxxxxx"
              value={embedUrl}
              onChange={(event) => onChangeEmbedUrl(event.target.value)}
              error={embedUrlError}
            />
          </div>
          <div
            className={cx(styles.previewButton, {
              [styles.isError]: !!embedUrlError,
            })}
          >
            <Button
              icon="send"
              value={t('Preview')}
              disabled={embedUrl === '' || !!embedUrlError}
              onClick={onClickPreviewEmbed}
            />
          </div>
        </div>
        {embedResult.isLoading && (
          <div className={styles.loading}>
            <img src="/images/icon_loading.svg" alt="loading..." />
          </div>
        )}
        {embedResult.data && (
          <EditEmbedOptions
            iframelyResponseData={embedResult.data}
            inputOptions={inputOptions}
            onChangeInputOptions={onChangeInputOptions}
          />
        )}
      </ModalContent>
    </Modal>
  );
};
