import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';

import { formatDate } from '../../util/date';

import styles from './announcement.module.css';

import { REACT_APP_VERSION } from '@/constants/env';
import { SocialButton } from '@/views/Common/Ui/SocialButton';

type Contents = {
  contents: [
    {
      title: string;
      content: string;
      publishedAt: string;
      blog: [
        {
          id: string;
          title: string;
          ogimage?: {
            url?: string;
          };
        },
      ];
    },
  ];
};

type Props = {
  isOpen: boolean;
  close: () => void;
  data: Contents;
  error: unknown;
};

const Announcement: React.FC<Props> = ({ isOpen, close, data, error }) => {
  const { t } = useTranslation('announcement');

  return createPortal(
    <Transition in={isOpen === true} timeout={0}>
      {(state) => (
        <div className={`${styles.wrapper} ${styles[state]}`}>
          <div
            className={styles.mask}
            onClick={close}
            data-testid="announcement-overlay"
          />
          <div className={`${styles.container} ${styles[state]}`}>
            <div className={styles.header}>
              <h3 className={styles.title}>
                <i className="material-icons-outlined">notifications</i>
                {t('Announcement')}
                <span className={styles.length}>
                  {t('(Show latest 20 results)')}
                </span>
              </h3>
              <div className={styles.sns}>
                <SocialButton
                  type="x"
                  outlined={true}
                  link="https://twitter.com/micro_cms"
                  text="X (Twitter)"
                />
                <SocialButton
                  type="discord"
                  outlined={true}
                  link="https://discord.com/invite/K3DPqw4EJ2"
                  text={t('Community')}
                />
              </div>
            </div>
            <ul className={styles.lists} data-testid="announcement-list">
              {data &&
                data.contents &&
                data.contents.map((content, i) => (
                  <li className={styles.list} key={i}>
                    <dl>
                      <dd className={styles.timestamp}>
                        {formatDate(content.publishedAt)}
                      </dd>
                      <dt className={styles.listTitle}>{content.title}</dt>
                      <dd
                        className={styles.content}
                        dangerouslySetInnerHTML={{ __html: content.content }}
                      />
                      {content.blog?.map((blog) =>
                        blog.ogimage?.url ? (
                          <dd className={styles.blogList} key={blog.id}>
                            <a
                              href={`https://blog.microcms.io/${blog.id}/`}
                              target="blog"
                            >
                              <img
                                className={styles.blogImage}
                                src={blog.ogimage.url}
                                alt={blog.title}
                              />
                            </a>
                          </dd>
                        ) : null,
                      )}
                    </dl>
                  </li>
                ))}
              {error ? (
                <li className={styles.list}>
                  {t('Information could not be retrieved')}
                </li>
              ) : null}
              <p className={styles.version}>
                {REACT_APP_VERSION && `Version #${REACT_APP_VERSION}`}
              </p>
            </ul>
          </div>
        </div>
      )}
    </Transition>,
    document.getElementById('root') as HTMLElement,
  );
};

export default Announcement;
