import Linkify from '@/components/Linkify';

import { useTextField } from './useTextField';

import type { Field, NumberRange } from '@/types/field';

import { Textfield } from '@/views/Common/Ui/Textfield';

export type Props = {
  field: Field;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export type ViewProps = {
  label: string;
  description?: React.ReactNode;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
  error?: string;
  textSize?: NumberRange;
  textCount?: number;
};

export const TextFieldView: React.FC<ViewProps> = ({
  label,
  description,
  value,
  onChange,
  required,
  error,
  textSize,
  textCount,
}) => {
  return (
    <Textfield
      label={label}
      description={
        description !== undefined ? <Linkify>{description}</Linkify> : undefined
      }
      value={value}
      onChange={onChange}
      required={required}
      error={error}
      displayTextCount
      textSize={textSize}
      textCount={textCount}
    />
  );
};

export const TextField: React.FC<Props> = (props) => {
  const hooks = useTextField(props);

  return <TextFieldView {...hooks} {...props} />;
};
