import type React from 'react';

import styles from './linkButton.module.css';

interface Props {
  value: string;
  onClick?: ((e?: React.MouseEvent<HTMLInputElement>) => void) | undefined;
  style?: React.CSSProperties;
  disabled?: boolean;
  loading?: boolean;
}

const LinkButton: React.FC<Props> = ({
  onClick,
  value,
  style,
  disabled = false,
  loading = false,
}) => {
  return (
    <input
      type="button"
      className={styles.button}
      onClick={onClick}
      value={value}
      style={style}
      disabled={disabled || loading}
    />
  );
};

export default LinkButton;
