import cx from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { LinkButton } from '../Form';
import Linkify from '../Linkify';
import Button from '../ui/Button';
import IconButton from '../ui/IconButton';
import Textarea from '../ui/Textarea';
import Textfield from '../ui/Textfield';
import { validateReviewRequestTitle } from '../Validations';

import type { ReviewRequest } from '@/entity/review';

import styles from './title.module.css';

import { useInput } from '@/views/Common/useInput';

type UpdateReviewRequestArgs = {
  title: string;
  description?: string;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

type Props = {
  reviewRequest: ReviewRequest;
  reqId: string;
  editing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  updateReviewRequest: (args: UpdateReviewRequestArgs) => void;
  updateReviewRequestLoading: boolean;
  updateReviewRequestReservationTimeLoading: boolean;
  hasEditReviewUpdatePermission: boolean;
};

const Title: React.FC<Props> = ({
  reviewRequest,
  reqId,
  editing,
  setEditing,
  updateReviewRequest,
  updateReviewRequestLoading,
  updateReviewRequestReservationTimeLoading,
  hasEditReviewUpdatePermission,
}) => {
  const { t } = useTranslation('editReview');

  const [title, onChangeTitle, titleError, setTitleWithValidation] = useInput(
    '',
    validateReviewRequestTitle,
  );
  const [description, onChangeDescription, , setDescriptionWithValidation] =
    useInput('', () => null);

  const update = useCallback(() => {
    updateReviewRequest({
      title: title || reviewRequest.title,
      description: description || reviewRequest.description || undefined,
      setEditing,
    });
  }, [description, reviewRequest, setEditing, title, updateReviewRequest]);

  const cancel = useCallback(() => {
    setEditing(false);
    setTitleWithValidation(reviewRequest.title);
    setDescriptionWithValidation(reviewRequest.description ?? '');
  }, [
    reviewRequest,
    setDescriptionWithValidation,
    setEditing,
    setTitleWithValidation,
  ]);

  return (
    <div
      className={cx(styles.editArea, {
        [styles.isEditing]: editing,
      })}
    >
      <div>
        {editing ? (
          <Textfield
            defaultValue={title || reviewRequest.title}
            onChange={onChangeTitle}
            className={styles.titleField}
            placeholder={t('Enter a title')}
          />
        ) : (
          <h2 className={styles.title}>
            {title || reviewRequest.title}
            <span className={styles.reqId}>#{reqId}</span>
          </h2>
        )}
        {editing ? (
          <Textarea
            defaultValue={description || reviewRequest.description || undefined}
            onChange={onChangeDescription}
            className={styles.descriptionField}
            placeholder={t('Enter a description')}
          />
        ) : description || reviewRequest.description ? (
          <Linkify>
            <p className={styles.description}>
              {description || reviewRequest.description}
            </p>
          </Linkify>
        ) : null}
      </div>
      {hasEditReviewUpdatePermission && !editing && (
        <div className={styles.editButton}>
          <IconButton icon="edit" onClick={() => setEditing(true)} />
        </div>
      )}
      {hasEditReviewUpdatePermission && editing && (
        <div className={styles.actions}>
          <Button
            type="primary"
            value={t('Update Request')}
            onClick={update}
            disabled={
              !!titleError ||
              updateReviewRequestLoading ||
              updateReviewRequestReservationTimeLoading
            }
            className={styles.actionButton}
          />
          <LinkButton value={t('Cancel')} onClick={cancel} />
        </div>
      )}
    </div>
  );
};

export { Title };
