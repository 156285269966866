import { UpdatePublishedAtModalContent } from './UpdatePublishedAtModalContent';
import { UpdatePublishedAtModalTrigger } from './UpdatePublishedAtModalTrigger';
import { useUpdatePublishedAtModal } from './useUpdatePublishedAtModal';

import type { MigrateApi } from '@/entity/api';
import type { ContentStatus } from '@/entity/content';

import { Modal } from '@/views/Common/Ui/Modal';

export type Props = {
  needSave: () => boolean;
  contentId: string;
  publishedAt: string;
  contentStatus: ContentStatus;
  disabledOfTrigger?: boolean;
  apiId: MigrateApi['partitionKey'];
};

export type ViewProps = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  contentId: string;
  publishedAt: string;
  contentStatus: ContentStatus;
  onClickOfTrigger: () => void;
  disabledOfTrigger?: boolean;
  closeEditPublishedAtModal: () => void;
  apiId: MigrateApi['partitionKey'];
};

export const UpdatePublishedAtModalView: React.FC<ViewProps> = ({
  open,
  onOpenChange,
  contentId,
  publishedAt,
  contentStatus,
  onClickOfTrigger,
  disabledOfTrigger,
  closeEditPublishedAtModal,
  apiId,
}) => {
  return (
    <Modal open={open} onOpenChange={onOpenChange}>
      <UpdatePublishedAtModalTrigger
        contentStatus={contentStatus}
        onClick={onClickOfTrigger}
        disabled={disabledOfTrigger}
        publishedAt={publishedAt}
        apiId={apiId}
      />
      <UpdatePublishedAtModalContent
        contentId={contentId}
        publishedAt={publishedAt}
        closeEditPublishedAtModal={closeEditPublishedAtModal}
      />
    </Modal>
  );
};

export const UpdatePublishedAtModal: React.FC<Props> = ({
  needSave,
  contentId,
  publishedAt,
  contentStatus,
  disabledOfTrigger,
  apiId,
}) => {
  const { open, onOpenChange, onClickOfTrigger, close } =
    useUpdatePublishedAtModal({ needSave });

  return (
    <UpdatePublishedAtModalView
      open={open}
      onOpenChange={onOpenChange}
      contentId={contentId}
      publishedAt={publishedAt}
      contentStatus={contentStatus}
      onClickOfTrigger={onClickOfTrigger}
      disabledOfTrigger={disabledOfTrigger}
      closeEditPublishedAtModal={close}
      apiId={apiId}
    />
  );
};
