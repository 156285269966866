import dayjs from 'dayjs';
import { v4 as uuid } from 'uuid';

import { serviceRepository } from '@/repository/serviceRepository';

import type { MigrateApi } from '@/entity/api';

import { getServiceDomain } from '@/util';

export const findServiceByDomain = async ({ domain }: { domain: string }) => {
  const { findServiceByDomain } = serviceRepository();
  return await findServiceByDomain(domain);
};

export const getMyServices = async () => {
  const { getMyServices } = serviceRepository();
  return getMyServices();
};

export const getCurrentService = async () => {
  const domain = getServiceDomain();

  const myServices = await getMyServices();
  return myServices.find((service) => service.domain === domain) ?? null;
};

export const objectSimplify = (field: any) => {
  const undefObject = Object.keys(field)
    .filter((key) => field[key] === null)
    .map((key) => ({ [key]: undefined }))
    .reduce((total, current) => ({ ...total, ...current }), {});
  return {
    ...field,
    viewGroup: undefined,
    ...undefObject,
  };
};

export const getCurrentPlan = async (serviceId?: string | null) => {
  if (!serviceId) {
    return null;
  }

  const { getPlanList } = serviceRepository();
  const result = await getPlanList(serviceId);

  const currentPlan =
    result?.planTypes &&
    result.planTypes
      .flatMap((types) => types?.plans)
      .find((plan) => plan?.current);

  if (!currentPlan) {
    throw new Error('Current plan not found');
  }

  return currentPlan;
};

export const generateUniqueSchema = (schema: any) => {
  const regex = /API#[a-zA-Z0-9-]+/g;
  const apiMap = new Map();

  schema.forEach((obj: any) => {
    const match = JSON.stringify(obj).match(regex);
    if (match) {
      match.forEach((matchValue) => {
        if (!apiMap.has(matchValue)) {
          const id = `API#${uuid()}`;
          apiMap.set(matchValue, id);
        }
      });
    }
  });

  const uniqueObjects = JSON.parse(
    JSON.stringify(schema).replace(regex, (matchValue) => {
      return apiMap.get(matchValue);
    }),
  );

  return uniqueObjects as MigrateApi[];
};

export const generateExportJsonFile = (data: any) => {
  const content = JSON.stringify(data);
  const blob = new Blob([content], { type: 'text/plain' });
  const fileName = `microcms-template-${dayjs().format('YYYYMMDDHHmmss')}.json`;

  // @ts-expect-error
  if (window.navigator.msSaveBlob) {
    // @ts-expect-error
    window.navigator.msSaveBlob(blob, fileName);
    // @ts-expect-error
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const downLoadLink = document.createElement('a');
    downLoadLink.download = fileName;
    downLoadLink.href = URL.createObjectURL(blob);
    downLoadLink.dataset.downloadurl = [
      'text/plain',
      downLoadLink.download,
      downLoadLink.href,
    ].join(':');
    downLoadLink.click();
  }
};
