import { API, graphqlOperation } from 'aws-amplify';

import type { ApiResponse } from '@/views/Common/handleApiResult';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

import * as queries from '@/graphql/queries';

export const memberRolesRepository = () => {
  const updateMemberRoles = async ({
    serviceId,
    targetUsername,
    roleIds,
  }: {
    serviceId: string;
    targetUsername: string;
    roleIds: string[];
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.updateMemberRoles, {
          serviceId,
          sub: targetUsername,
          roleIds,
        }),
      )) as GraphQLResult<{
        updateMemberRoles: ApiResponse | boolean | null;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO: フロントエンドエラー改善
      if (
        typeof result.data.updateMemberRoles === 'object' &&
        typeof result.data.updateMemberRoles?.result === 'boolean'
      ) {
        const success = result.data.updateMemberRoles?.result;
        if (success) {
          return result.data.updateMemberRoles;
        } else {
          throw new Error(
            result.data.updateMemberRoles?.message ?? 'Unexpected Error',
          );
        }
      } else if (typeof result.data.updateMemberRoles === 'boolean') {
        return result.data.updateMemberRoles;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  return { updateMemberRoles };
};
