import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';

import { storeAmplifyAuthConfig } from '../../constants/localStorage';
import { getTranslation } from '../../i18n';
import { getServiceDomain, getSSOHost } from '../../util';

async function fetchTokens() {
  const subDomain = getServiceDomain();
  const ssoHost = getSSOHost();
  const code = new URLSearchParams(window.location.search).get('code');

  return fetch(`${ssoHost}/saml/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ subDomain, code }),
  }).then((res) =>
    res.status < 300
      ? res.json()
      : Promise.reject(new Error('failed to fetch tokens')),
  );
}

function setAuthConfig(
  userPoolId: string,
  clientId: string,
  identityPoolId: string,
) {
  const authConfig = {
    userPoolId: userPoolId,
    userPoolWebClientId: clientId,
    identityPoolId: identityPoolId,
  };

  Auth.configure(authConfig);

  storeAmplifyAuthConfig(authConfig);
}

function setSession(
  userPoolId: string,
  clientId: string,
  username: string,
  idToken: string,
  accessToken: string,
  refreshToken: string,
) {
  const userPool = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: userPoolId,
    ClientId: clientId,
  });
  const cognitoIdToken = new AmazonCognitoIdentity.CognitoIdToken({
    IdToken: idToken,
  });
  const cognitoAccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
    AccessToken: accessToken,
  });
  const cognitoRefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
    RefreshToken: refreshToken,
  });

  const user = new AmazonCognitoIdentity.CognitoUser({
    Username: username,
    Pool: userPool,
  });
  user.setSignInUserSession(
    new AmazonCognitoIdentity.CognitoUserSession({
      AccessToken: cognitoAccessToken,
      IdToken: cognitoIdToken,
      RefreshToken: cognitoRefreshToken,
    }),
  );
}

type LocationType = (string | Location) & Location;

type Token = {
  userPoolId: string;
  clientId: string;
  identityPoolId: string;
  username: string;
  idToken: string;
  accessToken: string;
  refreshToken: string;
  nextLocation: LocationType;
};

function handleTokens(tokens: Token) {
  const {
    userPoolId,
    clientId,
    identityPoolId,
    username,
    idToken,
    accessToken,
    refreshToken,
    nextLocation,
  } = tokens;

  setAuthConfig(userPoolId, clientId, identityPoolId);

  setSession(
    userPoolId,
    clientId,
    username,
    idToken,
    accessToken,
    refreshToken,
  );

  window.location = nextLocation;
}

function handleError(error: Error) {
  const { t } = getTranslation('samlCallback');
  console.error(error);
  // @ts-expect-error
  const query = new URLSearchParams({
    error: t('Could not create a session.'),
    loginToken: new URLSearchParams(window.location.search).get('loginToken'),
  });
  window.location = `/sso/saml/signin?${query}` as LocationType;
}

const SAMLCallback = () => {
  useEffect(() => {
    fetchTokens().then(handleTokens).catch(handleError);
  }, []);

  return null;
};

export default SAMLCallback;
