import { connect } from 'react-redux';

import { apiSelectors, apiOperations } from '../../ducks/api';
import ContentHeader from './ContentHeader';

const mapStateToProps = ({ apiState }) => {
  return {
    putDescriptionResult:
      apiSelectors.getPutDescriptionResult(apiState).putDescriptionResult,
  };
};

const mapDispatchToProps = {
  putApiDescription: apiOperations.putApiDescription,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentHeader);
