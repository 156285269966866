import { API, graphqlOperation } from 'aws-amplify';
import { useCallback } from 'react';

import type { Service } from '@/types/services';

import * as queries from '@/graphql/queries';
import { getServiceDomain } from '@/util';

export const useDeleteSettings = () => {
  const deleteService = useCallback(async (service: Service) => {
    //サービスを削除
    await API.graphql(
      graphqlOperation(queries.deleteService, {
        serviceId: service.partitionKey,
      }),
    );

    //画面遷移
    const baseAppDomain = window.location.hostname.replace(
      // eslint-disable-next-line no-useless-escape
      new RegExp(`^${getServiceDomain()}\.`),
      'app.',
    );
    const portStr = window.location.port ? `:${window.location.port}` : '';
    window.location.href = `${window.location.protocol}//${baseAppDomain}${portStr}`;
  }, []);

  return {
    deleteService,
  };
};
