import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from '../_types';
import type { Role } from '@/entity/role';

import { produce } from '@/lib/immer';

type SetDefaultContentPermissionShowDeveloperMenuAction =
  FormRoleInputValueAction<
    'set-dc-permission-showdevelopermenu',
    Role['defaultContentPermission']['showDeveloperMenu']
  >;

/**
 * DefaultContentPermissionのshowDeveloperMenuフィールドを更新する
 */
const setDefaultContentPermissionShowDeveloperMenu: FormRoleInputValueDispatchFn<
  SetDefaultContentPermissionShowDeveloperMenuAction
> = (currentState, action) => {
  return produce(currentState, (draft) => {
    draft.defaultContentPermission.showDeveloperMenu = action;
  });
};

export {
  setDefaultContentPermissionShowDeveloperMenu,
  type SetDefaultContentPermissionShowDeveloperMenuAction,
};
