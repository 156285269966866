import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Table, Thead, Tbody, Tr, Th, Td } from '@/components/ui/Table';

import type { History } from 'history';

import styles from './customFields.module.css';

import { useFieldTypes } from '@/constants/useFieldTypes';

type Props = {
  endpoint: string;
  history: History;
  customFields: any;
};

export const FieldsWrapper: React.FC<Props> = ({
  customFields,
  endpoint,
  history,
}) => {
  const { t } = useTranslation('customFields');
  const fieldTypes = useFieldTypes();

  return (
    <div className={styles.main}>
      <div className={styles.tableWrap}>
        <div>
          <p className={styles.note}>
            {Trans({
              t,
              i18nKey:
                'Custom fields that have already been created can be configured from the API schema in the API Settings.',
              children: (
                <Link to={`/apis/${endpoint}/settings/model`}>API schema</Link>
              ),
            })}
          </p>
          <Table>
            <Thead>
              <Tr>
                <Th>{t('ID')}</Th>
                <Th>{t('Name')}</Th>
                <Th>{t('Type')}</Th>
                <Th>{t('Field Name')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {customFields.map((content: any, i: any) => (
                <Tr
                  key={i}
                  onClick={() =>
                    history.push(
                      `/apis/${endpoint}/custom-fields/${content.fieldId}`,
                    )
                  }
                >
                  <Td>{content.fieldId}</Td>
                  <Td>{content.name}</Td>
                  <Td>
                    <ul>
                      {content.fields.map((field: any, i: any) => (
                        <li className={styles.list} key={i}>
                          {field.name}
                        </li>
                      ))}
                    </ul>
                  </Td>
                  <Td>
                    <ul>
                      {content.fields.map((field: any, i: any) => (
                        <li className={styles.list} key={i}>
                          <i className="material-icons">
                            {fieldTypes[field.kind].icon}
                          </i>
                          {fieldTypes[field.kind].name}
                        </li>
                      ))}
                    </ul>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};
