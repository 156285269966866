import * as diff from 'diff';
import { Fragment } from 'react/jsx-runtime';

import { Highlight } from '../Highlight';

import type { DiffNode } from './DiffNodesDisplay';

type Args = {
  sinceValue: string;
  untilValue: string;
} & (
  | {
      diffType: 'char';
      Element?: undefined;
    }
  | {
      diffType?: 'line';
      Element?: React.FC<{ value: string }>;
    }
);

const buildDiffNodes = ({
  sinceValue,
  untilValue,
  diffType = 'line',
  Element,
}: Args): DiffNode[] => {
  const nodes: DiffNode[] = [];

  const diffChanges = diff.diffLines(sinceValue, untilValue, {
    newlineIsToken: true,
  });

  for (let index = 0; index < diffChanges.length; index++) {
    const { value, added, removed } = diffChanges[index];

    if (value === '\n') {
      continue;
    }

    const values = value.split('\n').filter((line) => line !== '');

    if (added) {
      for (const line of values) {
        nodes.push({
          since: null,
          until: {
            value: Element ? <Element value={line} /> : line,
            type: 'added',
          },
        });
      }
    } else if (removed) {
      const nextChange = diffChanges[index + 1];

      if (nextChange?.added) {
        const nextChangeValues = nextChange.value.split('\n');
        const maxLineLength = Math.max(values.length, nextChangeValues.length);

        switch (diffType) {
          case 'char': {
            for (let i = 0; i < maxLineLength; i++) {
              const sinceLine = values[i];
              const untilLine = nextChangeValues[i];

              if (sinceLine === undefined) {
                nodes.push({
                  since: null,
                  until: { value: untilLine, type: 'added' },
                });
                continue;
              }
              if (untilLine === undefined) {
                nodes.push({
                  since: { value: sinceLine, type: 'removed' },
                  until: null,
                });
                continue;
              }

              const charsChanges = diff.diffChars(
                sinceLine ?? '',
                untilLine ?? '',
              );

              const nodeSource: {
                since: {
                  value: string;
                  highlight?: boolean;
                }[];
                until: {
                  value: string;
                  highlight?: boolean;
                }[];
              } = {
                since: [],
                until: [],
              };

              charsChanges.forEach((charChange) => {
                if (charChange.added) {
                  nodeSource.until.push({
                    value: charChange.value,
                    highlight: true,
                  });
                } else if (charChange.removed) {
                  nodeSource.since.push({
                    value: charChange.value,
                    highlight: true,
                  });
                } else {
                  nodeSource.since.push({
                    value: charChange.value,
                  });
                  nodeSource.until.push({
                    value: charChange.value,
                  });
                }
              });

              const node: DiffNode = {
                since: {
                  value: (
                    <>
                      {nodeSource.since.map((char, i) =>
                        char.highlight ? (
                          <Highlight type="removed" key={i}>
                            {char.value}
                          </Highlight>
                        ) : (
                          <Fragment key={i}>{char.value}</Fragment>
                        ),
                      )}
                    </>
                  ),
                  type: 'removed',
                },
                until: {
                  value: (
                    <>
                      {nodeSource.until.map((char, i) =>
                        char.highlight ? (
                          <Highlight type="added" key={i}>
                            {char.value}
                          </Highlight>
                        ) : (
                          <Fragment key={i}>{char.value}</Fragment>
                        ),
                      )}
                    </>
                  ),
                  type: 'added',
                },
              };
              nodes.push(node);
            }

            break;
          }
          case 'line': {
            for (let i = 0; i < maxLineLength; i++) {
              const sinceLine = values[i];
              const untilLine = nextChangeValues[i];

              if (sinceLine === undefined) {
                nodes.push({
                  since: null,
                  until: {
                    value: Element ? <Element value={untilLine} /> : untilLine,
                    type: 'added',
                  },
                });
                continue;
              }
              if (untilLine === undefined) {
                nodes.push({
                  since: {
                    value: Element ? <Element value={sinceLine} /> : sinceLine,
                    type: 'removed',
                  },
                  until: null,
                });
                continue;
              }

              nodes.push({
                since: {
                  value: Element ? <Element value={sinceLine} /> : sinceLine,
                  type: 'removed',
                },
                until: {
                  value: Element ? <Element value={untilLine} /> : untilLine,
                  type: 'added',
                },
              });
            }

            break;
          }
        }

        index++;
      } else {
        for (const line of values) {
          nodes.push({
            since: {
              value: Element ? <Element value={line} /> : line,
              type: 'removed',
            },
            until: null,
          });
        }
      }
    } else {
      for (const line of values) {
        nodes.push({
          since: {
            value: Element ? <Element value={line} /> : line,
            type: 'noChange',
          },
          until: {
            value: Element ? <Element value={line} /> : line,
            type: 'noChange',
          },
        });
      }
    }
  }

  return nodes;
};

export { buildDiffNodes };
