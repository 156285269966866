import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

import type { CustomField } from '@/types/contents';
import type { Service } from '../../types/services';

const useLimitCustomFieldNest = (
  allCustomFields: CustomField[],
  customField: CustomField,
  countRef: { count: number },
  service: Service | null,
  parentService?: Service | null,
) => {
  const flags = useFlags();
  const maxFieldDate = flags.maxFieldDate;
  const maxNestCustomField = flags.maxCustomFieldNestNumber;

  const isLimitNumCustomFieldNest = useMemo(() => {
    return isLimitCustomFieldNest(
      { allCustomFields, customField, countRef, service, parentService },
      { maxFieldDate, maxNestCustomField },
    );
  }, [
    allCustomFields,
    customField,
    maxFieldDate,
    maxNestCustomField,
    service,
    parentService,
    countRef,
  ]);

  return isLimitNumCustomFieldNest;
};

const isLimitCustomFieldNest = (
  input: {
    allCustomFields: CustomField[];
    customField: CustomField;
    countRef: { count: number };
    service: Service | null;
    parentService?: Service | null;
  },
  max: { maxFieldDate: string; maxNestCustomField: number },
) => {
  // createdAtをもとにCustomField情報を取得
  const searchCustomField = (
    customFields: CustomField[],
    customFieldCratedAt: string,
  ) => {
    return customFields.find(
      (customField) => customField.createdAt === customFieldCratedAt,
    );
  };

  // CustomFieldのネストを再帰的に探索
  // ネストされたCustomFieldの数を返す
  // ネスト数を用いて繰り返しフィールドで選択できないようにする
  const recursiveCustomField = (
    allCustomFields: CustomField[],
    customField: CustomField,
    countRef: { count: number },
  ) => {
    customField.fields.forEach((field) => {
      if (field.customFieldCreatedAtList) {
        field.customFieldCreatedAtList.forEach((createdAt) => {
          const customField = searchCustomField(allCustomFields, createdAt);
          if (customField) {
            countRef.count += 1;
            return recursiveCustomField(allCustomFields, customField, countRef);
          }
        });
      }
    });
    return countRef.count;
  };

  if (
    input.service &&
    max.maxFieldDate &&
    // 複数環境の場合、親サービスの作成日で判定する
    ((input.parentService &&
      new Date(input.parentService.createdAt) > new Date(max.maxFieldDate)) ||
      (!input.parentService &&
        new Date(input.service.createdAt) > new Date(max.maxFieldDate)))
  ) {
    const count = recursiveCustomField(
      input.allCustomFields,
      input.customField,
      input.countRef,
    );
    if (max.maxNestCustomField > 0 && count >= max.maxNestCustomField) {
      return true;
    }
    return false;
  }
  return false;
};

// ANKEN-1164 繰り返しフィールドの繰り返し数上限設定
const isOverMaxRepeat = (
  input: {
    repeatCount: number;
    service: Service | null;
    parentService?: Service | null;
  },
  max: { maxFieldDate: string; maxRepeatTimes: number },
) => {
  if (
    input.service &&
    max.maxFieldDate &&
    // 複数環境の場合、親サービスの作成日で判定する
    ((input.parentService &&
      new Date(input.parentService.createdAt) > new Date(max.maxFieldDate)) ||
      (!input.parentService &&
        new Date(input.service.createdAt) > new Date(max.maxFieldDate)))
  ) {
    if (max.maxRepeatTimes > 0 && input.repeatCount > max.maxRepeatTimes - 1) {
      return true;
    }
    return false;
  }
  return false;
};

export { useLimitCustomFieldNest, isLimitCustomFieldNest, isOverMaxRepeat };
