import { type FC, useCallback, useMemo, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';

import { ReservationDatePicker } from '../Form';
import Button from '../ui/Button';
import Modal from '../ui/Modal';

import type { ReviewRequest } from '@/entity/review';
import type { Dispatch, SetStateAction } from 'react';

import styles from './contentReservationModal.module.css';

type UpdateReviewRequestReservationTimeArgs = {
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  reservationTime?: Date | string;
  reservationStopTime?: Date | string;
  closeReservationModal: () => void;
};

type Props = {
  reviewRequest: ReviewRequest;
  isProcessingUpdate: boolean;
  setEditing: Dispatch<SetStateAction<boolean>>;
  updateReviewRequestReservationTime: (
    args: UpdateReviewRequestReservationTimeArgs,
  ) => void;
  hasEditReviewUpdatePermission: boolean;
};

/**
 * レビュー詳細画面でのコンテンツの予約モーダルとモーダルを開くトリガー要素を表示するコンポーネント
 */
const ContentReservationModal: FC<Props> = ({
  reviewRequest,
  isProcessingUpdate,
  setEditing,
  updateReviewRequestReservationTime,
  hasEditReviewUpdatePermission,
}) => {
  const { t } = useTranslation('editReview');

  const [ReservationModal, openReservationModal, closeReservationModal] =
    useModal('root');

  const date = new Date();
  const currentHours = date.getHours();
  const currentMinutes = date.getMinutes();

  const [startDate, setStartDate] = useState(
    reviewRequest.reservationTime
      ? new Date(reviewRequest?.reservationTime)
      : undefined,
  );

  const [stopDate, setStopDate] = useState(
    reviewRequest.reservationStopTime
      ? new Date(reviewRequest?.reservationStopTime)
      : undefined,
  );

  const reservationStopDateErrorMessage = useMemo(() => {
    if (startDate === undefined) {
      return t('Publishing time must be set');
    }

    return undefined;
  }, [startDate, t]);

  const setReservation = useCallback(() => {
    if (hasEditReviewUpdatePermission) {
      updateReviewRequestReservationTime({
        setEditing,
        reservationTime: startDate,
        reservationStopTime: stopDate,
        closeReservationModal,
      });
    }
  }, [
    closeReservationModal,
    startDate,
    stopDate,
    updateReviewRequestReservationTime,
    setEditing,
    hasEditReviewUpdatePermission,
  ]);

  return (
    <>
      {/* モーダルを開くトリガー要素 */}
      <button
        type="button"
        className={styles.labelButton}
        onClick={openReservationModal}
        disabled={
          isProcessingUpdate ||
          reviewRequest.status === 'CLOSE' ||
          !hasEditReviewUpdatePermission
        }
      >
        {t('Scheduled Publishing')}
        {hasEditReviewUpdatePermission && (
          <i className="material-icons">settings</i>
        )}
      </button>

      {/* NOTE: 公開予約、または停止予約のいずれかの権限があればモーダル要素を出力 */}
      {hasEditReviewUpdatePermission && (
        <ReservationModal>
          <Modal>
            <p className={styles.modalTitle}>
              <i className="material-icons-outlined">play_circle</i>
              {t('Scheduled Publishing Date / Time')}
            </p>
            <p className={styles.modalDescription}>
              {t(
                'The content in Draft will become Published at the specified time.',
              )}
            </p>
            <ReservationDatePicker
              date={date}
              inputDate={startDate}
              setInputDate={setStartDate}
              currentHours={currentHours}
              currentMinutes={currentMinutes}
            />

            <p className={styles.modalTitle}>
              <i className="material-icons-outlined">stop_circle</i>
              {t('Scheduled Unpublishing Date / Time')}
            </p>
            <p className={styles.modalDescription}>
              {t(
                'The Published content will become Unpublished at the specified time.',
              )}
            </p>
            <ReservationDatePicker
              date={date}
              inputDate={stopDate}
              setInputDate={setStopDate}
              currentHours={currentHours}
              currentMinutes={currentMinutes}
              disabled={reservationStopDateErrorMessage !== undefined}
              errorText={reservationStopDateErrorMessage}
            />

            <Button
              type="primary"
              size="small"
              onClick={setReservation}
              disabled={isProcessingUpdate}
              className={styles.setupModalButton}
              value={t('Save changes')}
            />
          </Modal>
        </ReservationModal>
      )}
    </>
  );
};

export default ContentReservationModal;
export type { Props as ContentReservationModalProps };
