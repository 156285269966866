import * as Popover from '@radix-ui/react-popover';
import cx from 'classnames';
import { forwardRef } from 'react';

import { Icon } from '../Icon';

import styles from './infoTooltip.module.css';

type Props = React.ComponentProps<'button'> & {
  'aria-label': string;
  iconName: string;
};

const InfoTooltip = forwardRef<HTMLButtonElement, Props>(
  ({ iconName, children, className, ...props }, forwardedRef) => {
    return (
      <Popover.Root>
        <Popover.Trigger
          {...props}
          ref={forwardedRef}
          className={cx(styles.trigger, className)}
        >
          <Icon name={iconName} outlined />
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            side="top"
            align="center"
            sideOffset={-2}
            className={styles.content}
            style={{ zIndex: 20 }}
          >
            {children}
            <Popover.Arrow className={styles.arrow} width={20} height={12} />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    );
  },
);
InfoTooltip.displayName = 'InfoTooltip';

export { InfoTooltip };
