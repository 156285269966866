import { useTranslation } from 'react-i18next';

import { useHorizontalRule } from './useHorizontalRule';
import { IconButton } from '../../../IconButton';
import styles from '../../../richeditorv2.module.css';

import type { Editor } from '@tiptap/react';

import { HorizontalRuleIcon } from '@/views/Common/Icons';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  setHorizontalRuleHandler: () => void;
  editable: boolean;
};

export const HorizontalRuleView: React.FC<ViewProps> = ({
  setHorizontalRuleHandler,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <IconButton
      icon={<HorizontalRuleIcon className={styles.icon} />}
      isActive={false}
      aria-label={t('HorizontalRule')}
      onClick={setHorizontalRuleHandler}
      disabled={!editable}
    />
  );
};

export const HorizontalRule: React.FC<Props> = ({ editor }) => {
  const { setHorizontalRuleHandler } = useHorizontalRule({
    editor,
  });

  return (
    <HorizontalRuleView
      setHorizontalRuleHandler={setHorizontalRuleHandler}
      editable={editor.isEditable}
    />
  );
};
