import { API, graphqlOperation } from 'aws-amplify';

import type {
  FindServiceUsersAssignableReviewRequestQueryVariables,
  FindServiceUsersAssignableReviewRequestQuery,
} from '@/API';
import type { Member, ServiceUser } from '@/entity/member';
import type { GraphQLDataResponse } from '@/types/graphql';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import {
  handleApiResult,
  type ApiResponse,
} from '@/views/Common/handleApiResult';

export const membersRepository = (serviceId: string) => {
  /**
   * ユーザー一覧を取得する
   */
  const fetchAllMembers = async (): Promise<ServiceUser[]> => {
    try {
      const result = await API.graphql(
        graphqlOperation(queries.findServiceUsers, {
          serviceId,
        }),
      );
      const typedResult = result as {
        data: { findServiceUsers: ServiceUser[] };
      };

      return typedResult.data.findServiceUsers ?? [];
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not fetch all members');
    }
  };

  /**
   * 自身のメンバー一覧を取得する
   */
  const getMyMembers = async () => {
    try {
      const result = await API.graphql(graphqlOperation(queries.getMyMembers));
      const typedResult = result as {
        data: { getMyMembers: GraphQLDataResponse<Array<Member>> };
      };
      return typedResult.data.getMyMembers.data ?? [];
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not fetch my members');
    }
  };

  /**
   * 指定したサービスの自身のメンバーを取得する
   */
  const getMyMember = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(queries.getMyMember, {
          serviceId,
        }),
      );
      const typedResult = result as {
        data: { getMyMember: GraphQLDataResponse<Member> };
      };
      return typedResult.data.getMyMember.data ?? null;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error(`Could not fetch my member on service ${serviceId}`);
    }
  };

  /**
   * 指定したサービスに参加する/拒否するの表明をする
   * 自身のMemberのinvitingを更新する
   */
  const answerMemberInvitation = async (accept: boolean) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.answerMemberInvitation, {
          serviceId,
          accept,
        }),
      )) as GraphQLResult<{
        answerMemberInvitation: ApiResponse;
      }>;

      if (!result.data) {
        throw new Error('Unexpected error');
      }
      return handleApiResult(result.data.answerMemberInvitation);
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected error');
    }
  };

  const inviteMembers = async ({
    emails,
    roleIds,
  }: {
    emails: string[];
    roleIds: string[];
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.inviteMembers, {
          serviceId,
          emails,
          roleIds,
        }),
      )) as GraphQLResult<{
        inviteMembers: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected error');
      }

      // TODO: フロントエンドエラー改善
      if (
        typeof result.data.inviteMembers === 'object' &&
        typeof result.data.inviteMembers.result === 'boolean'
      ) {
        return handleApiResult(result.data.inviteMembers);
      } else if (typeof result.data.inviteMembers === 'string') {
        return result.data.inviteMembers;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected error');
    }
  };

  const cancelMemberInvitation = async ({ email }: { email: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.cancelMemberInvitation, {
          serviceId,
          email,
        }),
      )) as GraphQLResult<{
        cancelMemberInvitation: ApiResponse;
      }>;

      if (!result.data) {
        throw new Error('Unexpected error');
      }

      // TODO: フロントエンドエラー改善
      if (
        typeof result.data.cancelMemberInvitation === 'object' &&
        typeof result.data.cancelMemberInvitation.result === 'boolean'
      ) {
        return handleApiResult(result.data.cancelMemberInvitation);
      } else if (typeof result.data.cancelMemberInvitation === 'string') {
        return result.data.cancelMemberInvitation;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected error');
    }
  };

  const fetchServiceUsersAssignableReviewRequestRepository = async (
    props: Pick<FindServiceUsersAssignableReviewRequestQueryVariables, 'reqId'>,
  ) => {
    try {
      const queryVars: FindServiceUsersAssignableReviewRequestQueryVariables = {
        ...props,
        serviceId,
      };

      const result = (await API.graphql<
        Record<
          keyof FindServiceUsersAssignableReviewRequestQuery,
          Array<ServiceUser>
        >
      >(
        graphqlOperation(
          queries.findServiceUsersAssignableReviewRequest,
          queryVars,
        ),
      )) as GraphQLResult<
        Record<
          keyof FindServiceUsersAssignableReviewRequestQuery,
          Array<ServiceUser>
        >
      >;

      return result.data?.findServiceUsersAssignableReviewRequest ?? [];
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not fetch all members');
    }
  };

  return {
    fetchAllMembers,
    getMyMembers,
    getMyMember,
    answerMemberInvitation,
    inviteMembers,
    cancelMemberInvitation,
    fetchServiceUsersAssignableReviewRequestRepository,
  };
};
