import cx from 'classnames';
import type React from 'react';

import styles from './primaryButton.module.css';

interface Props {
  value: string;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  style?: React.CSSProperties;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
}

const PrimaryButton: React.FC<Props> = ({
  onClick,
  value,
  style,
  disabled = false,
  loading = false,
  className = '',
}) => {
  return (
    <input
      type="button"
      className={cx(styles.button, className, {
        [styles.isLoading]: loading,
      })}
      onClick={onClick}
      value={value}
      style={style}
      disabled={disabled || loading}
    />
  );
};

export default PrimaryButton;
