import { Table, Tbody, Th, Thead, Tr } from '@/components/ui/Table';

import { DefaultPermissionAllCheckButton } from './AllCheckButton';
import FieldApi from './fields/FieldApi';
import FieldApiKey from './fields/FieldApiKey';
import FieldBilling from './fields/FieldBilling';
import FieldContent from './fields/FieldContent';
import FieldDevMenu from './fields/FieldDevMenu';
import FieldEnvironment from './fields/FieldEnvironment';
import FieldsetExceptionContent from './fields/FieldExceptionContent/FieldsetExceptionContent';
import FieldMedium from './fields/FieldMedium';
import FieldMember from './fields/FieldMember';
import FieldName from './fields/FieldName';
import FieldReviewRequest from './fields/FieldReviewRequest';
import FieldRole from './fields/FieldRole';
import FieldUsage from './fields/FieldUsage';
import { useI18nFormRole } from './i18n';
import { useFormRoleState } from './provider';

import styles from './Main.module.css';

import { Fieldset } from '@/views/Common/Ui/Fieldset';

const Main = () => {
  const { t } = useI18nFormRole();

  const { isAdminRole, apiSchemas } = useFormRoleState();

  return (
    <div className={styles.container}>
      <Fieldset label={t('Role name')} required className={styles.fieldSet}>
        <FieldName />
      </Fieldset>

      <Fieldset label={t('Default Permission')} className={styles.fieldSet}>
        <div className={styles.tableWrap}>
          {!isAdminRole && <DefaultPermissionAllCheckButton />}

          <Table>
            <Thead>
              <Tr>
                <Th />
                <Th>{t('Read')}</Th>
                <Th>{t('Create')}</Th>
                <Th>{t('Update')}</Th>
                <Th>{t('Delete')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr enableHoverStyle={false} data-testid="memberPermission">
                <FieldMember />
              </Tr>
              <Tr enableHoverStyle={false} data-testid="rolePermission">
                <FieldRole />
              </Tr>
              <Tr enableHoverStyle={false} data-testid="billingPermissions">
                <FieldBilling />
              </Tr>
              <Tr enableHoverStyle={false} data-testid="apiKeyPermissions">
                <FieldApiKey />
              </Tr>
              <Tr enableHoverStyle={false} data-testid="usagePermissions">
                <FieldUsage />
              </Tr>
              <Tr enableHoverStyle={false} data-testid="environmentPermissions">
                <FieldEnvironment />
              </Tr>
              <Tr
                enableHoverStyle={false}
                data-testid="reviewRequestPermissions"
              >
                <FieldReviewRequest />
              </Tr>
              <Tr enableHoverStyle={false} data-testid="mediumPermissions">
                <FieldMedium />
              </Tr>
              <Tr enableHoverStyle={false} data-testid="apiPermissions">
                <FieldApi />
              </Tr>
              <Tr enableHoverStyle={false} data-testid="contentPermissions">
                <FieldContent />
              </Tr>
              <Tr enableHoverStyle={false} data-testid="devMenuPermissions">
                <FieldDevMenu />
              </Tr>
            </Tbody>
          </Table>
        </div>
      </Fieldset>

      {/* APIスキーマが1つでもある場合のみ、個別権限の設定フィールドを表示 */}
      {apiSchemas.length > 0 && <FieldsetExceptionContent />}
    </div>
  );
};

export default Main;
