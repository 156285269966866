import { useState } from 'react';

import useCurrentIp from '@/hooks/useCurrentIp';

import type { Props } from './ApiIpAddressRestrictionSettings';

import { useUpdateGlobalApiIpAddressRestriction } from '@/views/Common/apiIpAddressRestriction/useApiIpAddressRestrictionWriter';
import { useCurrentPlan } from '@/views/Common/service/useServiceReader';

export const useApiIpAddressRestrictionSettings = ({
  service,
  initialIpList,
}: Props) => {
  const { data: currentPlan } = useCurrentPlan(service.partitionKey);
  const [currentIp] = useCurrentIp();

  const [ipList, setIpList] = useState(initialIpList);
  const handleChangeIpList: React.ChangeEventHandler<HTMLTextAreaElement> = (
    e,
  ) => {
    setIpList(e.target.value);
  };

  const {
    mutate: updateGlobalApiIpAddressRestriction,
    isLoading: isUpdateGlobalApiIpAddressRestrictionLoading,
  } = useUpdateGlobalApiIpAddressRestriction(service.partitionKey);
  const handleSaveButtonClick = () => {
    updateGlobalApiIpAddressRestriction(ipList);
  };

  return {
    ipRestrictionCount: currentPlan?.limit.apiIpRestrictionCount ?? 0,
    currentIp,
    ipList,
    handleChangeIpList,
    handleSaveButtonClick,
    disabledSaveButton: isUpdateGlobalApiIpAddressRestrictionLoading,
  };
};
