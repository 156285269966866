import {
  autoUpdate,
  size as sizeMiddleware,
  useId,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  FloatingFocusManager,
  FloatingPortal,
  offset,
} from '@floating-ui/react';
import cx from 'classnames';
import { forwardRef, useRef } from 'react';

import style from './autocomplete.module.css';

interface ItemProps {
  children: React.ReactNode;
  active: boolean;
}

const Item = forwardRef<
  HTMLDivElement,
  ItemProps & React.HTMLProps<HTMLDivElement>
>(({ children, active, ...rest }, ref) => {
  const id = useId();
  return (
    <div
      ref={ref}
      role="option"
      id={id}
      aria-selected={active}
      {...rest}
      className={cx(style.item, {
        [style.isActive]: active,
      })}
    >
      {children}
    </div>
  );
});

Item.displayName = 'Item';

type SizeType = 'xsmall' | 'small' | 'medium' | 'large' | 'full';

export type Props = {
  id: string;
  selectList: string[];
  inputValue: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (item: string) => void;
  onKeyDown: (event: React.KeyboardEvent<Element>) => void;
  open: boolean;
  onOpenChange: () => void;
  activeIndex: number | null;
  setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
  onFocus?: () => void;
  size?: SizeType;
  placeholder?: string;
};

export type ViewProps = {
  id: string;
  selectList: string[];
  inputValue: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (item: string) => void;
  onKeyDown: (event: React.KeyboardEvent<Element>) => void;
  open: boolean;
  onOpenChange: () => void;
  activeIndex: number | null;
  setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
  onFocus?: () => void;
  size?: SizeType;
  placeholder?: string;
};

export const AutoCompleteView: React.FC<ViewProps> = ({
  id,
  selectList,
  inputValue,
  onChange,
  open,
  onOpenChange,
  activeIndex,
  setActiveIndex,
  onClick,
  onKeyDown,
  onFocus,
  size = 'full',
  placeholder,
}) => {
  const listRef = useRef<Array<HTMLElement | null>>([]);

  const { x, y, strategy, refs, context } = useFloating<HTMLInputElement>({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange,
    middleware: [
      sizeMiddleware({
        apply({ rects, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
        padding: 10,
      }),
      offset({ mainAxis: 1 }),
    ],
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useRole(context, { role: 'listbox' }),
      useDismiss(context),
      useListNavigation(context, {
        listRef,
        activeIndex,
        onNavigate: setActiveIndex,
        virtual: true,
        loop: true,
      }),
    ],
  );

  return (
    <>
      <input
        id={`codeblock-autocomplete-${id}`}
        className={cx(style.input, style[size])}
        {...getReferenceProps({
          ref: refs.setPositionReference,
          onChange: onChange,
          value: inputValue || '',
          placeholder: placeholder,
          'aria-autocomplete': 'list',
          onKeyDown: onKeyDown,
          onFocus: onFocus,
        })}
        tabIndex={-1}
      />
      <FloatingPortal>
        {open && (
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  position: strategy,
                  left: x ?? 0,
                  top: y ?? 0,
                },
              })}
              className={style.body}
            >
              {selectList.map((item, index) => (
                <Item
                  {...getItemProps({
                    key: item,
                    ref(node) {
                      listRef.current[index] = node;
                    },
                    onClick: () => onClick(item),
                  })}
                  active={activeIndex === index}
                >
                  {item}
                </Item>
              ))}
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  );
};

export const AutoComplete: React.FC<Props> = ({
  id,
  selectList,
  inputValue,
  onChange,
  onClick,
  onKeyDown,
  open,
  onOpenChange,
  activeIndex,
  setActiveIndex,
  onFocus,
  size,
  placeholder = '',
}) => {
  return (
    <AutoCompleteView
      id={id}
      selectList={selectList}
      inputValue={inputValue}
      onChange={onChange}
      open={open}
      onOpenChange={onOpenChange}
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      size={size}
      placeholder={placeholder}
    />
  );
};
