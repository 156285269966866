import { Auth } from 'aws-amplify';

import actions from './actions';

export const confirmSignup = (email, code) => (dispatch) => {
  Auth.confirmSignUp(email, code)
    .then(() => {
      dispatch(actions.successConfirmSignup());
    })
    .catch((err) => dispatch(actions.failureConfirmSignup(err))); //TODO: Amplify I18n対応
};

export const confirmMfa = (user, code) => async (dispatch) => {
  Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA')
    .then(() => dispatch(actions.successSignin()))
    .catch((err) => {
      dispatch(actions.failureSignin(err));
    });
};

export const forgotPassword = (email) => (dispatch) => {
  Auth.forgotPassword(email)
    .then((data) => {
      dispatch(actions.successForgotPassword());
    })
    .catch((err) => dispatch(actions.failureForgotPassword(err))); //TODO: Amplify I18n対応
};

export const forgotPasswordSubmit = (email, code, password) => (dispatch) => {
  Auth.forgotPasswordSubmit(email, code, password)
    .then((data) => {
      dispatch(actions.successForgotPasswordSubmit());
    })
    .catch((err) => dispatch(actions.failureForgotPasswordSubmit(err))); //TODO: Amplify I18n対応
};

export const changePassword = (oldPassword, newPassword) => (dispatch) => {
  Auth.currentAuthenticatedUser()
    .then((user) => Auth.changePassword(user, oldPassword, newPassword))
    .then((data) => {
      dispatch(actions.successChangePassword());
      setTimeout(() => dispatch(actions.resetAuth()), 3000); //モーダルをしばらくしたら消す
    })
    .catch((err) => dispatch(actions.failureChangePassword(err)));
};

export const signOut = () => (dispatch) => {
  Auth.signOut()
    .then(() => dispatch(actions.successSignout()))
    .catch((err) => dispatch(actions.failureSignout(err))); //TODO: Amplify I18n対応
};

export const resetAuth = actions.resetAuth;
