import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useDeleteApi } from '@/hooks/Api/useApiWriterTs';
import { useDeleteAllContents } from '@/hooks/Content/useContentWriterTs';
import { useExceptionPermissionIsHaveLeastOne } from '@/hooks/Role/useMyRoles';
import { useGetMyService } from '@/hooks/useService';

import Head from '../../Head';
import Button from '../../ui/Button';

import type { ApiList, MigrateApi } from '@/entity/api';

import styles from './deleteSettings.module.css';

import { apiListSelectors } from '@/ducks/apiList';
import { useAppSelector } from '@/store/hooks';
import { AlertDialog } from '@/views/Common/Ui/AlertDialog';
import { Fieldset } from '@/views/Common/Ui/Fieldset';
import { useTopicValues } from '@/views/Common/providers/momento';

type Props = {
  api: MigrateApi;
};

const DeleteSettings: React.FC<Props> = () => {
  const { t } = useTranslation('deleteSettings');
  const { service } = useGetMyService();

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint } = useParams<{ endpoint: string }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );

  // 権限を取得
  const [hasDeleteApiPermission] = useExceptionPermissionIsHaveLeastOne(
    api.partitionKey,
    'apiDelete',
  );
  const [hasDeleteContentPermission] = useExceptionPermissionIsHaveLeastOne(
    api.partitionKey,
    'contentDelete',
  );

  const { apiEndpoint } = api;

  const { deleteApi, deleteApiLoading } = useDeleteApi(api, service);

  const { deleteAllContents, deleteAllContentsLoading } = useDeleteAllContents(
    api.partitionKey,
    api.apiName,
  );

  const onDeleteAllContents = useCallback(() => {
    deleteAllContents();
  }, [deleteAllContents]);

  const onDeleteApi = useCallback(() => {
    deleteApi();
  }, [deleteApi]);

  const topicValues = useTopicValues('deleteAllContents');
  const isRunningDeleteAllContents = useMemo(() => {
    // deleteAllContentsの情報がない場合は削除ボタンを活性にする
    if (topicValues.length === 0) {
      return false;
    }

    const topicValuesOfApi = topicValues.filter(
      (topicValue) =>
        (
          topicValue.data as {
            userId: string;
            apiId: string;
          }
        ).apiId === api.partitionKey,
    );

    // 削除対象のAPIに関するdeleteAllContentsの情報がない場合は削除ボタンを活性にする
    if (topicValuesOfApi.length === 0) {
      return false;
    }

    const latestTopicValue = topicValuesOfApi.at(-1);

    // deleteAllContentsがRUNNINGの場合は削除ボタンを非活性にする
    return latestTopicValue?.status === 'RUNNING';
  }, [api.partitionKey, topicValues]);

  return (
    <div>
      <Head title={t('API/Delete')} />
      <h2 className={styles.title}>{t('Delete')}</h2>
      <div className={styles.group}>
        {hasDeleteContentPermission && (
          <Fieldset
            label={t('Delete all contents of {{apiName}} API', {
              apiName: api.apiName,
            })}
            description={t(
              'Warning!! This will delete all contents belong to this API but this API will remain.',
            )}
          >
            <AlertDialog
              trigger={
                <Button
                  type="danger"
                  gaon="click"
                  gaeventcategory="api"
                  gaeventaction="delete"
                  gaeventlabel={`${api.domain}.${apiEndpoint}`}
                  disabled={
                    deleteAllContentsLoading || isRunningDeleteAllContents
                  }
                  value={t('Delete all contents')}
                  className={styles.button}
                />
              }
              title={t('#Delete all contents')}
              description={t(
                'Are you sure you want to delete all content? To perform the deletion, enter {{endpoint}}. Deleted content cannot be restored.',
                { endpoint: apiEndpoint },
              )}
              buttonText={t('#Delete')}
              onSubmit={onDeleteAllContents}
              confirmText={apiEndpoint}
            />
          </Fieldset>
        )}
        {hasDeleteApiPermission && (
          <Fieldset
            label={t('Delete {{apiName}} API', { apiName: api.apiName })}
            description={t(
              'Warning!! Everything associated with this API will not work once it is deleted.',
            )}
          >
            <AlertDialog
              trigger={
                <Button
                  type="danger"
                  gaon="click"
                  gaeventcategory="api"
                  gaeventaction="delete"
                  gaeventlabel={`${api.domain}.${apiEndpoint}`}
                  disabled={deleteApiLoading || isRunningDeleteAllContents}
                  value={t('Delete API')}
                  className={styles.button}
                />
              }
              title={t('#Delete API')}
              description={t(
                'Are you sure you want to delete api? To perform the deletion, enter {{endpoint}}. Deleted api cannot be restored.',
                { endpoint: apiEndpoint },
              )}
              buttonText={t('#Delete')}
              onSubmit={onDeleteApi}
              confirmText={apiEndpoint}
            />
            {isRunningDeleteAllContents && (
              <p className={styles.buttonNotes}>
                {t('API cannot be deleted while content is being deleted.')}
              </p>
            )}
          </Fieldset>
        )}
      </div>
    </div>
  );
};

export default DeleteSettings;
