import TextStyle from '@tiptap/extension-text-style';

import type { RichEditorV2Options } from '@/constants/richEditorOptions';
import type { TextStyleOptions } from '@tiptap/extension-text-style';

type CustomTextStyleOptions = TextStyleOptions & {
  inputRules: RichEditorV2Options[];
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-text-style/src/text-style.ts
export const CustomTextStyle = TextStyle.extend<CustomTextStyleOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: ['customClass', 'color'],
    };
  },

  parseHTML() {
    const result = [];

    if (this.options.inputRules.includes('customClass')) {
      result.push({ tag: 'span[data-custom-class]' });
    }

    if (this.options.inputRules.includes('color')) {
      result.push({ tag: 'span[data-richeditor-color]' });
    }

    return result;
  },
});
