import md5 from 'md5';
import { useCallback, useEffect, useRef } from 'react';

import type { RefObject } from 'react';

export type Props = {
  string: string;
  size: number;
  bg?: string;
  count?: number;
  padding?: number;
  className?: string;
};

export type ViewProps = {
  canvas: RefObject<HTMLCanvasElement>;
  className: string;
  size: number;
};

export const ServiceAvatarView: React.FC<ViewProps> = ({
  canvas,
  className,
  size,
}) => {
  return (
    <canvas
      ref={canvas}
      className={className}
      style={{ width: `${size}px`, height: `${size}px` }}
    />
  );
};

export const ServiceAvatar: React.FC<Props> = (props) => {
  const {
    bg = 'transparent',
    count = 5,
    string = '',
    size = 400,
    padding = 0,
    className = 'identicon',
  } = props;

  const canvas = useRef<HTMLCanvasElement>(null);

  const updateCanvas = useCallback(() => {
    const hash = md5(string);
    const block = Math.floor(size / count);
    const hashcolor = hash.slice(0, 6);

    if (!canvas.current) return;

    canvas.current.width = block * count + padding;
    canvas.current.height = block * count + padding;
    const arr = hash.split('').map((el) => {
      const _el = Number.parseInt(el, 16);
      if (_el < 8) {
        return 0;
      } else {
        return 1;
      }
    });

    const map = [];

    map[0] = map[4] = arr.slice(0, 5);
    map[1] = map[3] = arr.slice(5, 10);
    map[2] = arr.slice(10, 15);

    const ctx = canvas.current.getContext('2d');

    if (!ctx) return;

    ctx.imageSmoothingEnabled = false;
    ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);

    map.forEach((row, i) => {
      row.forEach((el, j) => {
        if (el) {
          ctx.fillStyle = '#' + hashcolor;
          ctx.fillRect(
            block * i + padding,
            block * j + padding,
            block - padding,
            block - padding,
          );
        } else {
          ctx.fillStyle = bg;
          ctx.fillRect(
            block * i + padding,
            block * j + padding,
            block - padding,
            block - padding,
          );
        }
      });
    });
  }, [bg, count, padding, size, string]);

  useEffect(() => {
    updateCanvas();
  }, [updateCanvas]);

  return (
    <ServiceAvatarView size={size} canvas={canvas} className={className} />
  );
};
