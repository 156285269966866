import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import type React from 'react';
import { useCallback, useState, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAdmin, myRolesContext } from '@/hooks/Role/useMyRoles';
import { useStripeActions } from '@/hooks/useStripeActions';

import { PrimaryButton, LinkButton } from '../Form';
import Fieldset from '../ui/Fieldset';
import Textfield from '../ui/Textfield';
import {
  validateEmail,
  validateCustomerName,
  validateCustomerCompanyName,
  validateCustomerPhoneNumber,
} from '../Validations';
import { useSuspended } from './useSuspended';

import type { Service } from '@/types/services';

import styles from './suspended.module.css';

import {
  CHARGE_AUTOMATICALLY,
  SEND_INVOICE,
} from '@/constants/collectionMethodTypes';
import { useInput } from '@/hooks';

type Props = {
  service: Service;
  parentService: Service;
};

const Suspended: React.FC<Props> = ({ service, parentService }) => {
  const { t } = useTranslation('suspended');
  const { deleteService } = useSuspended();
  const [collectionMethod, setCollectionMethod] =
    useState(CHARGE_AUTOMATICALLY);
  const [email, onChangeEmail, emailError] = useInput(undefined, validateEmail);
  const [company, onChangeCompany, companyError] = useInput(
    undefined,
    // @ts-expect-error
    validateCustomerCompanyName,
  );
  const [name, onChangeName, nameError] = useInput(
    undefined,
    // @ts-expect-error
    validateCustomerName,
  );
  const [phone, onChangePhone, phoneError] = useInput(
    undefined,
    // @ts-expect-error
    validateCustomerPhoneNumber,
  );
  // 権限を取得
  const { roles } = useContext(myRolesContext);

  // 管理者権限
  const [isAdmin] = useAdmin();

  // お支払い権限
  const hasUpdateBillingPermission = useMemo(() => {
    return (
      roles &&
      roles
        .map(({ billingPermission }) => billingPermission.update)
        .includes(true)
    );
  }, [roles]);

  const { restartSubscription, restartSubscriptionLoading, currentPlan } =
    useStripeActions(service);
  const stripe = useStripe();
  const elements = useElements();

  const restart = useCallback(() => {
    if (!elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    // @ts-expect-error
    restartSubscription({
      stripe,
      cardElement,
      email,
      name,
      phone,
      company,
    });
  }, [company, elements, email, name, phone, restartSubscription, stripe]);

  const [loading, setLoading] = useState(false);

  const deleteWithConfirm = useCallback(() => {
    const res = window.confirm(
      `${t(
        'Do you really want to delete {{serviceName}} service? This cannot be recovered.',
        { serviceName: service.serviceName },
      )}`,
    );
    if (res) {
      setLoading(true);
      deleteService(service);
    }
  }, [deleteService, service, t]);

  const doChangeRadio = useCallback((type: any) => {
    setCollectionMethod(type);
  }, []);

  if (parentService?.suspended === true) {
    return (
      <div className={styles.center}>
        <h1 className={styles.title}>{t('Service has been suspended.')}</h1>
        <p className={styles.description}>
          {t('Resume payment in the production environment.')}
          <br />
          {t(
            "The current month's charge will be settled immediately after the service is resumed.",
          )}
        </p>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t('Service has been suspended.')}</h1>
      {hasUpdateBillingPermission ? (
        <p className={styles.description}>
          {t('Register your credit card again below and resume payment.')}
          <br />
          {t(
            "The current month's charge will be settled immediately after the service is resumed.",
          )}
        </p>
      ) : (
        <p className={styles.description}>
          {t('Contact your service manager')}
        </p>
      )}
      {hasUpdateBillingPermission && (
        <div className={styles.container}>
          {currentPlan.limit.enableSendInvoice && (
            <Fieldset legend={t('Payment Method')} option={false}>
              <div className={styles.radioGroup}>
                <div>
                  <label className={styles.radioLabel}>
                    <input
                      type="radio"
                      className={styles.radioBox}
                      checked={collectionMethod === CHARGE_AUTOMATICALLY}
                      onChange={() => doChangeRadio(CHARGE_AUTOMATICALLY)}
                    />
                    <span>{t('Credit Cards')}</span>
                  </label>
                </div>
                <div>
                  <label className={styles.radioLabel}>
                    <input
                      type="radio"
                      className={styles.radioBox}
                      checked={collectionMethod === SEND_INVOICE}
                      onChange={() => doChangeRadio(SEND_INVOICE)}
                    />
                    <span>{t('Invoice Payment')}</span>
                  </label>
                </div>
              </div>
            </Fieldset>
          )}

          {collectionMethod === CHARGE_AUTOMATICALLY && (
            <Fieldset legend={t('Credit Card Details')} option={false}>
              <CardElement />
              <ul className={styles.cardBrands}>
                <li>
                  <img
                    src="/images/logo_visa.gif"
                    className={styles.cardBrandLogo}
                    alt="VISA"
                  />
                </li>
                <li>
                  <img
                    src="/images/logo_mastercard.gif"
                    className={styles.cardBrandLogo}
                    alt="MasterCard"
                  />
                </li>
                <li>
                  <img
                    src="/images/logo_jcb.gif"
                    className={styles.cardBrandLogo}
                    alt="JCB"
                  />
                </li>
                <li>
                  <img
                    src="/images/logo_american_express.png"
                    className={styles.cardBrandLogo}
                    alt="AmericanExpress"
                  />
                </li>
                <li>
                  <img
                    src="/images/logo_diners.gif"
                    className={styles.cardBrandLogo}
                    alt="Diners Club INTERNATIONAL"
                  />
                </li>
                <li>
                  <img
                    src="/images/logo_discover.jpg"
                    className={styles.cardBrandLogo}
                    alt="Discover"
                  />
                </li>
              </ul>
            </Fieldset>
          )}
          <Fieldset legend={t('Billing Email Address')} option={false}>
            <Textfield onChange={onChangeEmail} errorText={emailError} />
          </Fieldset>
          <Fieldset legend={t('Billing Company')} option={false}>
            <Textfield onChange={onChangeCompany} errorText={companyError} />
          </Fieldset>
          <Fieldset legend={t('Billing Contact Name')} option={false}>
            <Textfield onChange={onChangeName} errorText={nameError} />
          </Fieldset>
          <Fieldset legend={t('Billing Phone Number')} option={false}>
            <Textfield
              type="number"
              onChange={onChangePhone}
              errorText={phoneError}
            />
          </Fieldset>
          <PrimaryButton
            value={t('Resume service')}
            style={{ marginTop: 20 }}
            onClick={restart}
            disabled={
              restartSubscriptionLoading ||
              emailError ||
              email === undefined ||
              companyError ||
              company === undefined ||
              nameError ||
              name === undefined ||
              phoneError ||
              phone === undefined
            }
          />
        </div>
      )}
      {isAdmin && (
        <div className={styles.deleteArea}>
          <LinkButton
            value={t('Delete service')}
            onClick={deleteWithConfirm}
            disabled={loading}
          />
        </div>
      )}
    </div>
  );
};

export default Suspended;
