import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { EmbedBlock } from './EmbedBlock';

import type { IframelyResponse } from '@/entity/iframely';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    embedBlock: {
      setEmbed: (attrs: IframelyResponse) => ReturnType;
      updateEmbed: (attrs: IframelyResponse) => ReturnType;
    };
  }
}

export const CustomEmbed = Node.create({
  name: 'embedBlock',

  group: 'block',

  addAttributes() {
    return {
      url: {
        default: null,
      },
      html: {
        default: null,
      },
      options: {
        default: null,
      },
    };
  },

  addCommands() {
    return {
      setEmbed:
        (options) =>
        ({ chain }) => {
          return chain()
            .focus()
            .insertContent({
              type: this.name,
              attrs: options,
            })
            .run();
        },
      updateEmbed:
        (options) =>
        ({ chain }) => {
          return chain().focus().updateAttributes(this.name, options).run();
        },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      {
        'data-value': HTMLAttributes.url,
        'data-html': HTMLAttributes.html,
        'data-options': HTMLAttributes.options,
      },
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(EmbedBlock);
  },
});
