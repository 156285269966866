import { useCallback } from 'react';

import { useUpdateEnvironmentNameModalQuery } from './useUpdateEnvironmentNameModalQuery';

import type { Props } from './UpdateEnvironmentNameModal';

import { getTranslation } from '@/i18n';
import { useInput } from '@/views/Common/useInput';

export const validateEnvironmentName = (name: string) => {
  const { t } = getTranslation('updateEnvironmentNameModal');
  if (!name) {
    return t('Enter an environment name');
  }
  const PRODUCTION_NAME = ['本番環境', 'Production'];
  if (PRODUCTION_NAME.includes(name)) {
    return t('Invalid environment name');
  }
  return null;
};

export const useUpdateEnvironmentNameModal = ({
  serviceId,
  name,
  closeEnvNameModal,
}: Props) => {
  const [inputEnvName, onChangeEnvName, inputEnvNameError] = useInput(
    name,
    validateEnvironmentName,
  );

  const { useUpdateEnvironmentName } = useUpdateEnvironmentNameModalQuery();

  const { mutate: updateEnvName, isLoading: updateEnvNameLoading } =
    useUpdateEnvironmentName();

  const onClickUpdateEnvName = useCallback(() => {
    updateEnvName({
      serviceId,
      environmentName: inputEnvName,
      closeEnvNameModal,
    });
  }, [closeEnvNameModal, inputEnvName, serviceId, updateEnvName]);

  return {
    inputEnvName,
    onChangeEnvName,
    inputEnvNameError,
    onClickUpdateEnvName,
    updateEnvNameLoading,
  };
};
