import { type FC, type PropsWithChildren, useCallback, useMemo } from 'react';

import { validateEmpty } from '@/components/Validations';

import { findIndexECPs } from '../helpers/findIndexExceptionContentPermissions';
import {
  isAllCheckedForDefaultPermission,
  isAllCheckedForExceptionContentPermission,
} from '../helpers/isAllChecked';
import { formRoleDispatchContext } from './dispatchContext';
import { useFormRoleInputValueReducer } from './reducer';
import { formRoleStateContext } from './stateContext';

import type { MigrateApi } from '@/entity/api';
import type { InputExceptionContentPermission, Role } from '@/entity/role';

import { isAdminRole } from '@/util/permissions/admin';

type Props = {
  // 未指定の場合は新規作成モードとなる
  originalRole?: Role;
  apiSchemas: MigrateApi[];
};

const FormRoleProvider: FC<PropsWithChildren<Props>> = ({
  originalRole,
  apiSchemas,
  children,
}) => {
  const [inputValue, inputValueDispatch] =
    useFormRoleInputValueReducer(originalRole);

  const isAdmin = useMemo(() => {
    if (!originalRole) {
      return false;
    }
    return isAdminRole(originalRole);
  }, [originalRole]);

  const isAllCheckedDefaultPermission = useMemo(() => {
    return isAllCheckedForDefaultPermission({
      memberPermission: inputValue.memberPermission,
      rolePermission: inputValue.rolePermission,
      billingPermission: inputValue.billingPermission,
      apiKeyPermission: inputValue.apiKeyPermission,
      usagePermission: inputValue.usagePermission,
      environmentPermission: inputValue.environmentPermission,
      reviewRequestPermission: inputValue.reviewRequestPermission,
      mediumPermission: inputValue.mediumPermission,
      apiPermission: inputValue.apiPermission,
      defaultContentPermission: inputValue.defaultContentPermission,
    });
  }, [
    inputValue.memberPermission,
    inputValue.rolePermission,
    inputValue.billingPermission,
    inputValue.apiKeyPermission,
    inputValue.usagePermission,
    inputValue.environmentPermission,
    inputValue.reviewRequestPermission,
    inputValue.mediumPermission,
    inputValue.apiPermission,
    inputValue.defaultContentPermission,
  ]);

  const isAllCheckedExceptionContentPermission = useCallback(
    (id: InputExceptionContentPermission['id']) => {
      const targetECP = findIndexECPs(
        inputValue.exceptionContentPermissions,
        id,
      );
      if (targetECP === -1) {
        return false;
      }
      return isAllCheckedForExceptionContentPermission(
        inputValue.exceptionContentPermissions[targetECP],
      );
    },
    [inputValue.exceptionContentPermissions],
  );

  const validateFields = useCallback(() => {
    // NOTE: 現在はnameフィールドのみがバリデーション対象
    return !validateEmpty(inputValue.name);
  }, [inputValue.name]);

  return (
    <formRoleStateContext.Provider
      value={{
        originalRole,
        apiSchemas,
        isAdminRole: isAdmin,
        isAllCheckedDefaultPermission,
        isAllCheckedExceptionContentPermission,
        validateFields,
        input: inputValue,
      }}
    >
      <formRoleDispatchContext.Provider value={inputValueDispatch}>
        {children}
      </formRoleDispatchContext.Provider>
    </formRoleStateContext.Provider>
  );
};

export { FormRoleProvider };
