import { Td } from '@/components/ui/Table';

import FieldContentCreate from './shared/FieldContent/FieldContentCreate';
import FieldContentDelete from './shared/FieldContent/FieldContentDelete';
import FieldContentRead from './shared/FieldContent/FieldContentRead';
import FieldContentUpdate from './shared/FieldContent/FieldContentUpdate';
import FieldTitle from './shared/FieldTitle';
import { useI18nFormRole } from '../i18n';

const FieldContent = () => {
  const { t } = useI18nFormRole('$contentPermission');

  return (
    <>
      <Td verticalAlign="top">
        <FieldTitle variant="v-top">{t('Contents')}</FieldTitle>
      </Td>
      <Td verticalAlign="top">
        <FieldContentRead />
      </Td>
      <Td verticalAlign="top">
        <FieldContentCreate />
      </Td>
      <Td verticalAlign="top">
        <FieldContentUpdate />
      </Td>
      <Td verticalAlign="top">
        <FieldContentDelete />
      </Td>
    </>
  );
};

export default FieldContent;
