import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { findContent, getContentInitialValues } from '@/usecase/contentUsecase';

import { createdContentId } from '@/hooks/Content/useCreateContentId';

import type { MigrateApi } from '@/entity/api';
import type { CustomField } from '@/types/contents';

import { useApi } from '@/lib/useApi';
import { contentQueryKeys } from '@/views/Common/content/queryKeys';

const useCreateContent = (
  api: MigrateApi,
  customFields?: CustomField[],
  /**
   * コピー元のコンテンツID
   *
   * ※ コピーせずに新規作成の場合はfalsyな値を持つ
   */
  copyContentId?: string,
) => {
  const { addToast, removeAllToasts } = useToasts();
  const { t } = useTranslation('createContent');

  const [alreadyShowedToast, setAlreadyShowedToast] = useState(false);
  const { initialContentId, isDoneFetching } = useGenerateInitialContentId(api);
  const { copySourceContentData, isCopySourceContentDataLoading } =
    useCopySourceContentData(api, copyContentId, {
      done: isDoneFetching,
      contentId: initialContentId,
    });
  const initialContentValue = useMemo(
    () => (customFields ? getContentInitialValues(api, customFields) : null),
    [api, customFields],
  );

  /**
   * アンマウント時にトーストを全て閉じる
   */
  useEffect(() => {
    return removeAllToasts;
  }, [removeAllToasts]);

  useEffect(() => {
    if (
      !alreadyShowedToast &&
      !isCopySourceContentDataLoading &&
      copySourceContentData &&
      initialContentValue
    ) {
      setAlreadyShowedToast(true);
      // コピーしたコンテンツのデータとcontentIdの初期値が定まった時点で、トーストを表示する
      addToast(t('Copied content. Edit and save the content.'), {
        appearance: 'success',
        autoDismiss: false,
      });
    }
  }, [
    isCopySourceContentDataLoading,
    copySourceContentData,
    initialContentValue,
    addToast,
    t,
    alreadyShowedToast,
  ]);

  return {
    copySourceContentData,
    isCopySourceContentDataLoading,
    initialContentValue,
    initialContentId,
  };
};

/**
 * コピーするコンテンツのデータを取得
 *
 * WebAPI実行はコンテンツIDの初期値が準備されたかどうかに依存する
 */
const useCopySourceContentData = (
  api: MigrateApi | undefined,
  copyContentId: string | undefined,
  initialContentId: {
    done: boolean;
    contentId: string | null | undefined;
  },
) => {
  const {
    data: copySourceContentData,
    isLoading: isCopySourceContentDataLoading,
  } = useApi(
    contentQueryKeys.detail(copyContentId, api?.partitionKey),
    async () => {
      // NOTE: コピーせずに新規作成の場合も考慮し、コピー元のコンテンツIDが存在しない場合はnullを返す
      if (!copyContentId || !api) return null;

      const result = await findContent(
        {
          partitionKey: api.partitionKey,
          domain: api.domain,
          apiEndpoint: api.apiEndpoint,
        },
        copyContentId,
      );

      return result;
    },
    {
      staleTime: 0,
      cacheTime: 0,
      // NOTE: コンテンツIDの生成が完了し、生成できていて、依存する変数が全て揃っている場合にAPIを叩く
      enabled:
        initialContentId.done &&
        !!initialContentId.contentId &&
        (!!copyContentId || !!api),
    },
  );

  return { copySourceContentData, isCopySourceContentDataLoading };
};

/**
 * コンテンツIDの初期値を生成
 */
const useGenerateInitialContentId = (api: MigrateApi | undefined) => {
  const { addToast } = useToasts();
  const [initialContentId, setInitialContentId] = useState<string | null>(null);
  const [isDoneFetching, setIsDoneFetching] = useState<boolean>(false);

  useEffect(() => {
    let ignore = false;

    if (!api?.partitionKey || isDoneFetching) {
      return;
    }

    createdContentId(api.partitionKey)
      .then((res) => {
        if (!ignore) {
          setInitialContentId(res);
        }
      })
      .catch((error) => {
        if (!ignore && error instanceof Error) {
          addToast(error.message, {
            appearance: 'error',
            autoDismiss: false,
          });
        }
      })
      .finally(() => {
        if (!ignore) {
          setIsDoneFetching(true);
        }
      });

    return () => {
      ignore = true;
    };
  }, [api?.partitionKey, addToast, isDoneFetching]);

  return {
    initialContentId,
    /**
     * コンテンツIDの生成が成功、失敗に関わらず終了したかどうか
     */
    isDoneFetching,
  };
};

export { useCreateContent };
