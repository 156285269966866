import { useTranslation } from 'react-i18next';

import Button from '@/components/ui/Button';
import Switch from '@/components/ui/Switch';

import { EditLinkBlock } from './EditLinkBlock';
import { useLinkBlock } from './useLinkBlock';
import { IconButton } from '../../../IconButton';
import { Popover } from '../../../Popover';

import type { Editor } from '@tiptap/react';

import style from './linkblock.module.css';

import { LinkIcon } from '@/views/Common/Icons';
import { Textfield } from '@/views/Common/Ui/Textfield';

export type LinkAttributes = {
  text: string;
  href: string;
  target?: string | null;
};

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  editor: Editor;
  inputLinkAttributes: LinkAttributes;
  onChangeText: (href: LinkAttributes['text']) => void;
  onChangeHref: (href: LinkAttributes['href']) => void;
  onChangeTargetBlank: (isTargetBlank: boolean) => void;
  onSubmitLink: () => void;
  onUpdateLink: () => void;
  onClearLink: () => void;
  textError: string | undefined;
  hrefError: string | undefined;
  isActiveLink: boolean;
  isOpen: boolean;
  onToggleLinkSetting: (nextState?: boolean) => void;
  editable: boolean;
};

export const LinkBlockView: React.FC<ViewProps> = ({
  editor,
  inputLinkAttributes,
  onChangeText,
  onChangeHref,
  onChangeTargetBlank,
  onSubmitLink,
  onUpdateLink,
  onClearLink,
  textError,
  hrefError,
  isActiveLink,
  isOpen,
  onToggleLinkSetting,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');
  if (isActiveLink) {
    return (
      <EditLinkBlock
        editor={editor}
        inputLinkAttributes={inputLinkAttributes}
        onChangeText={onChangeText}
        onChangeHref={onChangeHref}
        onChangeTargetBlank={onChangeTargetBlank}
        onUpdateLink={onUpdateLink}
        onClearLink={onClearLink}
        textError={textError}
        hrefError={hrefError}
        isOpen={isOpen}
        onToggleLinkSetting={onToggleLinkSetting}
      />
    );
  }

  return (
    <Popover
      trigger={
        <IconButton
          icon={<LinkIcon className={style.icon} />}
          aria-label={t('Link')}
          isActive={isActiveLink}
          disabled={!editable}
          onClick={() => onToggleLinkSetting}
        />
      }
      body={
        <div className={style.wrapper}>
          <Textfield
            label={t('Link')}
            placeholder={t('Enter URL')}
            value={inputLinkAttributes.href}
            onChange={(e) => onChangeHref(e.target.value)}
            error={hrefError}
          />
          <Switch
            on={inputLinkAttributes.target ? true : false}
            onChange={onChangeTargetBlank}
          >
            {t('Open in a new tab')}
          </Switch>
          <div className={style.button}>
            <Button
              value={t('Save')}
              type="secondary"
              onClick={onSubmitLink}
              disabled={!!hrefError}
            />
          </div>
        </div>
      }
      open={isOpen}
      onOpenChange={onToggleLinkSetting}
    />
  );
};

export const LinkBlock: React.FC<Props> = ({ editor }) => {
  const {
    inputLinkAttributes,
    onChangeText,
    onChangeHref,
    onChangeTargetBlank,
    onSubmitLink,
    onUpdateLink,
    onClearLink,
    textError,
    hrefError,
    isActiveLink,
    isOpen,
    onToggleLinkSetting,
  } = useLinkBlock({ editor });

  return (
    <LinkBlockView
      editor={editor}
      inputLinkAttributes={inputLinkAttributes}
      onChangeText={onChangeText}
      onChangeHref={onChangeHref}
      onChangeTargetBlank={onChangeTargetBlank}
      onSubmitLink={onSubmitLink}
      onUpdateLink={onUpdateLink}
      onClearLink={onClearLink}
      textError={textError}
      hrefError={hrefError}
      isActiveLink={isActiveLink}
      isOpen={isOpen}
      onToggleLinkSetting={onToggleLinkSetting}
      editable={editor.isEditable}
    />
  );
};
