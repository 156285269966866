import { useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CONTENT_STATUS } from '../../../constants/contentStatus';
import { useCustomStatus } from '../../../hooks/CustomStatus/useCustomStatus';
import { useExceptionPermissionIsHaveLeastOne } from '../../../hooks/Role/useMyRoles';
import Head from '../../Head';
import Button from '../../ui/Button';
import ContentStatus from '../../ui/ContentStatus';
import CustomStatus from '../../ui/CustomStatus';
import IconButton from '../../ui/IconButton';
import CreateModal from './CreateModal';
import EditModal from './EditModal';

import type { ApiList, MigrateApi } from '@/entity/api';
import type { CustomState } from '../../../types/customState';

import styles from './customStatusSettings.module.css';

import { apiListSelectors } from '@/ducks/apiList';
import { useAppSelector } from '@/store/hooks';
import { Upsell } from '@/views/Common/plan/Upsell';

const CustomStatusSettings: React.FC = () => {
  const { t } = useTranslation('customStatusSettings');

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint } = useParams<{ endpoint: string }>();
  const { partitionKey: apiId } = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );

  const [updatable] = useExceptionPermissionIsHaveLeastOne(apiId, 'apiUpdate');
  const { customStatus } = useCustomStatus(apiId);
  const [CustomStatusModal, openModal, closeModal] = useModal('root');
  const [editTarget, setEditTarget] = useState<CustomState | undefined>(
    undefined,
  );

  return (
    <Upsell restrictionKey="customStatusCount">
      <div className={styles.wrapper}>
        <Head title={t('Custom Status/Settings')} />
        <h2 className={styles.title}>{t('Custom Status')}</h2>
        <p className={styles.description}>
          {t('You can create your own status.')}
        </p>
        <div className={styles.container}>
          <h3 className={styles.subTitle}>{t('Configured Status')}</h3>
          <ul>
            {CONTENT_STATUS.map((status: any, i: number) => (
              <li key={i} className={styles.status}>
                <ContentStatus status={status} />
              </li>
            ))}
            {customStatus?.map((status: CustomState) => (
              <li key={status.id} className={styles.status}>
                <CustomStatus
                  status={status.behaviour}
                  name={status.name}
                  color={status.color}
                >
                  <span className={styles.text}>
                    (
                    {status.behaviour === 'PUBLISH'
                      ? t('Public')
                      : status.behaviour === 'CLOSED'
                        ? t('Unpublished')
                        : status.behaviour === 'DRAFT'
                          ? t('Draft')
                          : null}
                    )
                  </span>
                  {updatable && (
                    <IconButton
                      icon="edit"
                      onClick={() => {
                        setEditTarget(status);
                        openModal();
                      }}
                    />
                  )}
                </CustomStatus>
              </li>
            ))}
          </ul>
          {updatable && (
            <div className={styles.actions}>
              <Button
                type="tertiary"
                value={t('Add')}
                onClick={() => {
                  setEditTarget(undefined);
                  openModal();
                }}
              />
            </div>
          )}
        </div>
        {!editTarget && (
          <CustomStatusModal>
            <CreateModal apiId={apiId} closeModal={closeModal} />
          </CustomStatusModal>
        )}
        {editTarget && (
          <CustomStatusModal>
            <EditModal
              apiId={apiId}
              customStatus={editTarget}
              closeModal={closeModal}
            />
          </CustomStatusModal>
        )}
      </div>
    </Upsell>
  );
};

export default CustomStatusSettings;
