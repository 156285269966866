import { useCallback } from 'react';

import type { Props } from './Bold';

export const useBold = ({ editor }: Props) => {
  const toggleBoldHandler = useCallback(() => {
    editor.chain().focus().toggleBold().run();
  }, [editor]);
  const isActiveBold = editor.isActive('bold');

  return {
    toggleBoldHandler,
    isActiveBold,
  };
};
