import deepEqual from 'deep-equal';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { useCallback, useMemo } from 'react';

import type { ContentValue } from '@/entity/content';
import type { Field } from '@/types/field';
import type { PrimitiveAtom } from 'jotai';

import { useSetContentCapacity } from '@/views/apis/content/ContentCapacity/useSetContentCapacity';

const isEmptyField = (field: Field, value: unknown) => {
  if (field.kind === 'custom' && typeof value === 'object' && value !== null) {
    return Object.keys(value).length === 0;
  }
  if (field.kind === 'text') {
    return value === '';
  }
  if (field.kind === 'textArea') {
    return value === '';
  }
  if (field.kind === 'richEditor') {
    return deepEqual(value, { ops: [{ insert: '\n' }] });
  }
  return false;
};

export const useField = ({
  parentContentAtom,
  field,
  getContent,
}: {
  parentContentAtom: PrimitiveAtom<ContentValue>;
  field: Field;
  getContent: () => ContentValue;
}): {
  value: unknown;
  onChange: (value: unknown) => void;
  contentAtomForCustomAndRepeater: PrimitiveAtom<unknown>;
} => {
  const contentAtom: PrimitiveAtom<ContentValue> = useMemo(
    () =>
      atom(
        (get) => get(parentContentAtom),
        (get, set, newContentValue) => {
          set(parentContentAtom, {
            ...get(parentContentAtom),
            ...newContentValue,
          });
        },
      ),
    [parentContentAtom],
  );
  const contentAtomForCustomAndRepeater = useMemo(
    () =>
      atom(
        (get) =>
          get(parentContentAtom)?.[field.idValue]?.[field.kind] ??
          (field.kind === 'custom'
            ? {}
            : field.kind === 'repeater'
              ? []
              : undefined),
        (get, set, newContentValue) => {
          set(parentContentAtom, {
            ...get(parentContentAtom),
            [field.idValue]: {
              [field.kind]: newContentValue,
            },
          });
        },
      ),
    [field.idValue, field.kind, parentContentAtom],
  );

  const fieldSelector = useCallback(
    (v: ContentValue) => {
      return v?.[field.idValue]?.[field.kind];
    },
    [field.idValue, field.kind],
  );
  const equalityFn = useCallback(
    (a: unknown, b: unknown) => {
      // カスタムフィールドと繰り返しフィールドにおいては、
      // その下に存在するInputFieldが値の更新をウォッチするため比較関数としては常にtrueを返す
      if (field.kind === 'custom' || field.kind === 'repeater') {
        return true;
      } else {
        return Object.is(a, b);
      }
    },
    [field.kind],
  );
  const value = useAtomValue(
    selectAtom(contentAtom, fieldSelector, equalityFn),
  );

  // コンテンツ容量表示のための更新
  const setContentCapacity = useSetContentCapacity(getContent);

  const setContent = useSetAtom(contentAtom);
  const onChange = useCallback(
    (value: unknown) => {
      setContent({
        [field.idValue]: isEmptyField(field, value)
          ? {}
          : { [field.kind]: value },
      });
      setContentCapacity();
    },
    [field, setContent, setContentCapacity],
  );

  return {
    value,
    onChange,
    contentAtomForCustomAndRepeater,
  };
};
