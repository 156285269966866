import * as Popover from '@radix-ui/react-popover';
import { useMemo, useState, useDeferredValue } from 'react';
import { useTranslation } from 'react-i18next';

import { useFetchServiceUsersAssignableReviewRequest } from '@/hooks/Member/useFetchServiceUsersAssignableReviewRequest';
import { useAddReviewer } from '@/hooks/Review/useAddReviewer';
import { useRemoveReviewer } from '@/hooks/Review/useRemoveReviewer';

import ProfileImage from '../ProfileImage';
import Searchfield from '../ui/Searchfield';

import type { FC, ReactNode } from 'react';

import styles from './selectReviewers.module.css';

import {
  getUserId,
  getUserNameWithFallback,
  searchUsersByKeyword,
} from '@/util/service-user';
import { Icon } from '@/views/Common/Ui/Icon';

type Props = {
  reviewerUserIds: Array<string>;
  triggerElement: ReactNode;
  serviceId: string;
  reviewRequestId: number;
};

const SelectReviewers: FC<Props> = ({
  reviewerUserIds,
  triggerElement,
  serviceId,
  reviewRequestId,
}) => {
  const { t } = useTranslation('editReview');

  const { addReviewer, isLoading: isLoadingAddReviewer } = useAddReviewer();
  const { removeReviewer, isLoading: isLoadingRemoveReviewer } =
    useRemoveReviewer();

  const [searchKeyword, setSearchKeyword] = useState('');
  const deferredSearchKeyword = useDeferredValue(searchKeyword);

  const { fetchedUsers, isLoading: isLoadingUsers } =
    useFetchServiceUsersAssignableReviewRequest(serviceId, reviewRequestId);

  const displayUsers = useMemo(() => {
    if (!fetchedUsers) {
      return [];
    }
    return searchUsersByKeyword(fetchedUsers, deferredSearchKeyword);
  }, [fetchedUsers, deferredSearchKeyword]);

  // addReviewer、またはremoveReviewerが処理している場合はTrueをとる
  const isOperatingReviewer = useMemo(() => {
    return isLoadingAddReviewer || isLoadingRemoveReviewer;
  }, [isLoadingAddReviewer, isLoadingRemoveReviewer]);

  if (!fetchedUsers) {
    return null;
  }

  const handleClickUser = (targetUserId: string, isSelected: boolean) => {
    if (isSelected) {
      removeReviewer({
        serviceId,
        reqId: reviewRequestId,
        reviewerId: targetUserId,
      });
    } else {
      addReviewer({
        serviceId,
        reqId: reviewRequestId,
        reviewerId: targetUserId,
      });
    }
  };

  return (
    <Popover.Root
      onOpenChange={(isOpen) => {
        // 閉じた時に検索キーワードをクリアする
        if (!isOpen) {
          setSearchKeyword('');
        }
      }}
    >
      <Popover.Trigger
        className={styles.trigger}
        disabled={isLoadingUsers}
        asChild
      >
        {triggerElement}
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          side="bottom"
          align="end"
          className={styles.content}
          style={{ zIndex: 1 }}
        >
          <div className={styles.search} onClick={(e) => e.stopPropagation()}>
            <Searchfield onChange={(e) => setSearchKeyword(e.target.value)} />
          </div>

          {displayUsers.length > 0 ? (
            <ul className={styles.userList}>
              {displayUsers.map((user) => {
                const userId = getUserId(user);
                const isSelected = reviewerUserIds.some((id) => id === userId);
                return (
                  <li key={userId}>
                    <button
                      className={styles.userListButton}
                      disabled={isOperatingReviewer}
                      onClick={() => handleClickUser(userId, isSelected)}
                    >
                      <ProfileImage picture={user.picture} />
                      <p className={styles.userName}>
                        {getUserNameWithFallback(user)}
                      </p>
                      {isSelected && (
                        <Icon
                          name="check"
                          className={styles.userListButtonIcon}
                        />
                      )}
                    </button>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className={styles.noUsers}>
              {t('No members available for display')}
            </p>
          )}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default SelectReviewers;
