import { useQuery } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from '../graphql/queries';

const useContentsCount = (serviceId: string) => {
  const getContentsCountQuery = useQuery({
    queryKey: ['contentsCount', serviceId],
    queryFn: () => {
      if (serviceId) {
        return (
          API.graphql(
            graphqlOperation(queries.getContentsCount, {
              serviceId,
            }),
          ) as Promise<{ data?: { getContentsCount: number } }>
        ).then((result) => result.data?.getContentsCount);
      }
    },
    cacheTime: 60,
    staleTime: 0,
    refetchOnMount: true,
  });

  const { data: contentsCount, isLoading } = getContentsCountQuery;
  return { contentsCount, isLoading };
};

export default useContentsCount;
