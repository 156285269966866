import type React from 'react';
import { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { useCopy } from '../../../hooks/Copy/useCopy';
import IconButton from '../../ui/IconButton';

import type { Param } from '../Params';

import styles from './code.module.css';

interface Props {
  inputPath: string;
  params: Param[];
  method: string;
  body: string;
  apiType: string;
}

const Go: React.FC<Props> = ({ inputPath, params, method, body, apiType }) => {
  const { t } = useTranslation('code');
  const [endpoint, contentId] = inputPath.split('/');
  const paramsString = useMemo(() => {
    const getValue = (key: string, value: string) => {
      // 数値
      if (['limit', 'offset', 'depth'].includes(key)) {
        return value;
      }
      // 配列
      if (['fields', 'ids', 'orders'].includes(key)) {
        return (
          '[]string{' +
          value
            .split(',')
            .map((v) => `"${v}"`)
            .join(`,`) +
          '}'
        );
      }
      // 文字列
      return `"${value}"`;
    };
    const capitalize = (str: string) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    };
    return params
      .map((p) => `${capitalize(p.key)}: ${getValue(p.key, p.value)}`)
      .join(`,\n`);
  }, [params]);

  const enabledParams = params
    .filter((p) => p.check)
    .reduce((prev, obj) => ({ ...prev, [obj.key]: obj.value }), {}) as {
    status: string;
  };
  const isContent = contentId || apiType === 'PAGE';

  const code =
    method === 'GET'
      ? 'import "github.com/microcmsio/microcms-go-sdk"\n\n' +
        (isContent
          ? `var content YourContentList // TODO ${t('Define it yourself')}\n\n`
          : `var list YourContentList // TODO ${t('Define it yourself')}\n\n`) +
        `err := client.${
          isContent
            ? 'Get(\n\tmicrocms.GetParams{'
            : 'List(\n\tmicrocms.ListParams{'
        }\n` +
        `\t\tEndpoint: "${endpoint}",\n` +
        `${contentId ? `\t\tContentID: "${contentId}",\n` : ''}` +
        `${
          paramsString
            ? paramsString
                .split('\n')
                .map((v) => `\t\t${v}`)
                .join('\n') + ',\n'
            : ''
        }` +
        '\t},\n' +
        `\t${isContent ? '&content,' : '&list,'}\n)`
      : method === 'POST' || method === 'PUT'
        ? 'import (\n' +
          '\t"encoding/json"\n' +
          '\t"github.com/microcmsio/microcms-go-sdk"\n' +
          ')\n\n' +
          `var yourContent YourContent // TODO ${t('Define it yourself')}\n` +
          `err := json.Unmarshal([]byte(\`${body}\`), &yourContent)\n\n` +
          'createResult, err := client.Create(microcms.CreateParams{\n' +
          `\tEndpoint: "${endpoint}",\n` +
          `${contentId ? `\tContentID: "${contentId}",\n` : ''}` +
          `${
            enabledParams.status === 'draft'
              ? `\tStatus: microcms.StatusDraft,\n`
              : ''
          }` +
          `\tContent: yourContent,\n` +
          '})'
        : method === 'PATCH'
          ? 'import (\n' +
            '\t"encoding/json"\n' +
            '\t"github.com/microcmsio/microcms-go-sdk"\n' +
            ')\n' +
            `var yourContent YourContent // TODO ${t('Define it yourself')}\n` +
            `err := json.Unmarshal('${body}', &yourContent)\n\n` +
            'updateResult, err := client.Update(microcms.UpdateParams{\n' +
            `\tEndpoint: "${endpoint}",\n` +
            `${contentId ? `\tContentID: "${contentId}",\n` : ''}` +
            `\tContent: yourContent,\n` +
            '})'
          : method === 'DELETE'
            ? 'import "github.com/microcmsio/microcms-go-sdk"\n\n' +
              `err := client.Delete(microcms.DeleteParams{\n` +
              `\tEndpoint: "${endpoint}",\n` +
              `\tContentID: "${contentId}",\n` +
              `})`
            : t('Not supported');

  const [copied, copyAction, setCopied] = useCopy(code);
  const copyApiKey = () => {
    copyAction();

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <>
      <SyntaxHighlighter
        language="go"
        style={docco}
        customStyle={{ fontSize: 14, padding: '16px', borderRadius: '4px' }}
      >
        {code}
      </SyntaxHighlighter>
      <p className={styles.note}>
        {Trans({
          t,
          i18nKey: 'Above is an example using microcms-go-sdk.',
          children: (
            <a
              href="https://github.com/microcmsio/microcms-go-sdk"
              target="sdk"
            >
              microcms-go-sdk
            </a>
          ),
        })}
      </p>
      <div className={styles.copyButton}>
        <IconButton
          icon={copied ? 'check' : 'content_copy'}
          hasFrame={true}
          onClick={copyApiKey}
        />
      </div>
    </>
  );
};

export default Go;
