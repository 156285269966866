import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'react-i18next';

import { TextAlignIcon } from './TextAlignIcon';
import { useTextAlign } from './useTextAlign';
import { IconButton } from '../../../IconButton';

import type { TextAlignType } from './useTextAlign';
import type { Editor } from '@tiptap/react';

import styles from './textAlign.module.css';

import {
  TextAlignRightIcon,
  TextAlignCenterIcon,
  TextAlignLeftIcon,
} from '@/views/Common/Icons';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  toggleTextAlignHandler: (type: TextAlignType) => void;
  textAlignType: TextAlignType;
  editable: boolean;
};

export const TextAlignView: React.FC<ViewProps> = ({
  toggleTextAlignHandler,
  textAlignType,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger
        asChild
        aria-label={t('TextAlign')}
        disabled={!editable}
      >
        <IconButton
          icon={<TextAlignIcon type={textAlignType} />}
          aria-label={t('TextAlign')}
          isActive={false}
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={styles.content}
          align="start"
          onEscapeKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <DropdownMenu.Group>
            <DropdownMenu.Item
              className={styles.item}
              onSelect={() => toggleTextAlignHandler('left')}
            >
              <TextAlignLeftIcon className={styles.icon} />
              {t('Left aligning')}
            </DropdownMenu.Item>
            <DropdownMenu.Item
              className={styles.item}
              onSelect={() => toggleTextAlignHandler('center')}
            >
              <TextAlignCenterIcon className={styles.icon} />
              {t('Center aligning')}
            </DropdownMenu.Item>
            <DropdownMenu.Item
              className={styles.item}
              onSelect={() => toggleTextAlignHandler('right')}
            >
              <TextAlignRightIcon className={styles.icon} />
              {t('Right aligning')}
            </DropdownMenu.Item>
          </DropdownMenu.Group>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export const TextAlign: React.FC<Props> = ({ editor }) => {
  const { toggleTextAlignHandler, textAlignType } = useTextAlign({
    editor,
  });

  return (
    <TextAlignView
      toggleTextAlignHandler={toggleTextAlignHandler}
      textAlignType={textAlignType}
      editable={editor.isEditable}
    />
  );
};
