import type { NumberRange } from '@/types/field';

import styles from './textbottomalert.module.css';

type UseTextBottomAlertArgs = {
  textSize?: NumberRange;
  textCount: number;
};

type UseTextBottomAlertReturn = {
  alertStyle: string;
  countDisplay: string;
};

const createAlertStyle = ({
  textSize,
  textCount,
}: UseTextBottomAlertArgs): string => {
  if (textSize) {
    // 最大・最小文字数設定:
    // 最大文字数超過または最小文字数未満の場合エラー
    if (textSize.max && textSize.min) {
      return textCount > textSize.max || textCount < textSize.min
        ? styles.error
        : '';
      // 最小文字数のみ: 最小文字数未満の場合エラー
    } else if (!textSize.max && textSize.min) {
      return textCount < textSize.min ? styles.error : '';
      // 最大文字数のみ: 最大文字数超過の場合エラー
    } else if (textSize.max && !textSize.min) {
      return textCount > textSize.max ? styles.error : '';
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const useTextBottomAlert = ({
  textSize,
  textCount,
}: UseTextBottomAlertArgs): UseTextBottomAlertReturn => {
  return {
    alertStyle: createAlertStyle({ textSize, textCount }),
    countDisplay:
      // 最大文字数が設定されている場合は、最大文字数も表示
      textSize && textSize.max
        ? `${textCount} / ${textSize.max}`
        : `${textCount}`,
  };
};
