/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createService = /* GraphQL */ `
  query CreateService(
    $name: String
    $domain: String
    $image: AWSJSON
    $planName: String
    $trialLengthInSec: Int
  ) {
    createService(
      name: $name
      domain: $domain
      image: $image
      planName: $planName
      trialLengthInSec: $trialLengthInSec
    )
  }
`;
export const updateService = /* GraphQL */ `
  query UpdateService(
    $serviceId: String
    $ipList: String
    $name: String
    $image: AWSJSON
  ) {
    updateService(
      serviceId: $serviceId
      ipList: $ipList
      name: $name
      image: $image
    ) {
      result
      message
    }
  }
`;
export const updateServiceId = /* GraphQL */ `
  query UpdateServiceId($serviceId: String, $domain: String) {
    updateServiceId(serviceId: $serviceId, domain: $domain) {
      result
      message
    }
  }
`;
export const deleteService = /* GraphQL */ `
  query DeleteService($serviceId: String) {
    deleteService(serviceId: $serviceId)
  }
`;
export const addMedium = /* GraphQL */ `
  query AddMedium($serviceId: String, $fileName: String, $identityId: String) {
    addMedium(
      serviceId: $serviceId
      fileName: $fileName
      identityId: $identityId
    ) {
      result
      message
      data
    }
  }
`;
export const deleteMedium = /* GraphQL */ `
  query DeleteMedium($serviceId: String, $mediumId: String, $path: String) {
    deleteMedium(serviceId: $serviceId, mediumId: $mediumId, path: $path) {
      result
      message
    }
  }
`;
export const createApi = /* GraphQL */ `
  query CreateApi(
    $serviceId: String
    $apiName: String
    $apiEndpoint: String
    $apiType: String
    $apiFields: AWSJSON
    $customFields: AWSJSON
    $apiId: String
  ) {
    createApi(
      serviceId: $serviceId
      apiName: $apiName
      apiEndpoint: $apiEndpoint
      apiType: $apiType
      apiFields: $apiFields
      customFields: $customFields
      apiId: $apiId
    )
  }
`;
export const updateApi = /* GraphQL */ `
  query UpdateApi(
    $apiId: String
    $name: String
    $endpoint: String
    $fields: AWSJSON
    $previewUrl: String
    $previewLinkIsVisible: Boolean
    $sortOrderValue: String
    $contentIdSetting: AWSJSON
  ) {
    updateApi(
      apiId: $apiId
      name: $name
      endpoint: $endpoint
      fields: $fields
      previewUrl: $previewUrl
      previewLinkIsVisible: $previewLinkIsVisible
      sortOrderValue: $sortOrderValue
      contentIdSetting: $contentIdSetting
    )
  }
`;
export const updateApiDescription = /* GraphQL */ `
  query UpdateApiDescription($apiId: String, $description: String) {
    updateApiDescription(apiId: $apiId, description: $description) {
      result
      message
    }
  }
`;
export const resetImportProgress = /* GraphQL */ `
  query ResetImportProgress($apiId: String) {
    resetImportProgress(apiId: $apiId)
  }
`;
export const deleteApi = /* GraphQL */ `
  query DeleteApi($apiId: String) {
    deleteApi(apiId: $apiId) {
      result
      message
    }
  }
`;
export const deleteAllContents = /* GraphQL */ `
  query DeleteAllContents($apiId: String) {
    deleteAllContents(apiId: $apiId)
  }
`;
export const createContent = /* GraphQL */ `
  query CreateContent(
    $apiId: String
    $contentId: String
    $value: AWSJSON
    $contentStatus: ContentStatus
  ) {
    createContent(
      apiId: $apiId
      contentId: $contentId
      value: $value
      contentStatus: $contentStatus
    )
  }
`;
export const updateContent = /* GraphQL */ `
  query UpdateContent(
    $contentId: String
    $value: AWSJSON
    $contentStatus: ContentStatus
  ) {
    updateContent(
      contentId: $contentId
      value: $value
      contentStatus: $contentStatus
    )
  }
`;
export const setReservationTime = /* GraphQL */ `
  query SetReservationTime(
    $contentId: String
    $startDate: String
    $stopDate: String
  ) {
    setReservationTime(
      contentId: $contentId
      startDate: $startDate
      stopDate: $stopDate
    )
  }
`;
export const deleteDraft = /* GraphQL */ `
  query DeleteDraft($contentId: String) {
    deleteDraft(contentId: $contentId)
  }
`;
export const updateDraftKey = /* GraphQL */ `
  query UpdateDraftKey($contentId: String) {
    updateDraftKey(contentId: $contentId)
  }
`;
export const updateContentId = /* GraphQL */ `
  query UpdateContentId($contentId: String, $userDefinedId: String) {
    updateContentId(contentId: $contentId, userDefinedId: $userDefinedId)
  }
`;
export const updatePublishedAt = /* GraphQL */ `
  query UpdatePublishedAt($contentId: String, $publishedAt: String) {
    updatePublishedAt(contentId: $contentId, publishedAt: $publishedAt)
  }
`;
export const updateContentSortOrder = /* GraphQL */ `
  query UpdateContentSortOrder($contentId: String, $sortOrderValue: String) {
    updateContentSortOrder(
      contentId: $contentId
      sortOrderValue: $sortOrderValue
    )
  }
`;
export const deleteContent = /* GraphQL */ `
  query DeleteContent($contentId: String) {
    deleteContent(contentId: $contentId)
  }
`;
export const inviteMember = /* GraphQL */ `
  query InviteMember(
    $serviceId: String!
    $email: String!
    $roleIds: [String!]
  ) {
    inviteMember(serviceId: $serviceId, email: $email, roleIds: $roleIds) {
      result
      message
    }
  }
`;
export const inviteMembers = /* GraphQL */ `
  query InviteMembers(
    $serviceId: String!
    $emails: [String!]!
    $roleIds: [String!]!
  ) {
    inviteMembers(serviceId: $serviceId, emails: $emails, roleIds: $roleIds)
  }
`;
export const reinviteMember = /* GraphQL */ `
  query ReinviteMember($serviceId: String!, $email: String!) {
    reinviteMember(serviceId: $serviceId, email: $email) {
      result
      message
    }
  }
`;
export const cancelMemberInvitation = /* GraphQL */ `
  query CancelMemberInvitation($serviceId: String!, $email: String!) {
    cancelMemberInvitation(serviceId: $serviceId, email: $email) {
      result
      message
    }
  }
`;
export const verifyMemberInvitation = /* GraphQL */ `
  query VerifyMemberInvitation($token: String!) {
    verifyMemberInvitation(token: $token)
  }
`;
export const isInvitedFromServices = /* GraphQL */ `
  query IsInvitedFromServices {
    isInvitedFromServices {
      result
      message
    }
  }
`;
export const updateMemberRoles = /* GraphQL */ `
  query UpdateMemberRoles(
    $serviceId: String
    $sub: String
    $roleIds: [String]
  ) {
    updateMemberRoles(serviceId: $serviceId, sub: $sub, roleIds: $roleIds)
  }
`;
export const deleteMember = /* GraphQL */ `
  query DeleteMember($serviceId: String, $sub: String) {
    deleteMember(serviceId: $serviceId, sub: $sub) {
      result
      message
    }
  }
`;
export const createRole = /* GraphQL */ `
  query CreateRole($serviceId: String, $name: String, $settings: AWSJSON) {
    createRole(serviceId: $serviceId, name: $name, settings: $settings)
  }
`;
export const updateRole = /* GraphQL */ `
  query UpdateRole(
    $serviceId: String
    $roleId: String
    $name: String
    $settings: AWSJSON
  ) {
    updateRole(
      serviceId: $serviceId
      roleId: $roleId
      name: $name
      settings: $settings
    )
  }
`;
export const deleteRole = /* GraphQL */ `
  query DeleteRole($serviceId: String, $roleId: String) {
    deleteRole(serviceId: $serviceId, roleId: $roleId)
  }
`;
export const createWebhookSetting = /* GraphQL */ `
  query CreateWebhookSetting(
    $apiId: String
    $target: WebhookTarget
    $settingValues: AWSJSON
    $handleEvents: [WebhookEvent]
  ) {
    createWebhookSetting(
      apiId: $apiId
      target: $target
      settingValues: $settingValues
      handleEvents: $handleEvents
    )
  }
`;
export const createWebhookMediumSetting = /* GraphQL */ `
  query CreateWebhookMediumSetting(
    $apiId: String
    $settingValues: AWSJSON
    $handleEvents: [WebhookEvent]
  ) {
    createWebhookMediumSetting(
      apiId: $apiId
      settingValues: $settingValues
      handleEvents: $handleEvents
    ) {
      result
      message
    }
  }
`;
export const updateWebhookSetting = /* GraphQL */ `
  query UpdateWebhookSetting(
    $apiId: String
    $createdAt: String
    $settingValues: AWSJSON
    $handleEvents: [WebhookEvent]
  ) {
    updateWebhookSetting(
      apiId: $apiId
      createdAt: $createdAt
      settingValues: $settingValues
      handleEvents: $handleEvents
    )
  }
`;
export const updateWebhookEnabledSetting = /* GraphQL */ `
  query UpdateWebhookEnabledSetting(
    $apiId: String
    $createdAt: String
    $enabled: Boolean
  ) {
    updateWebhookEnabledSetting(
      apiId: $apiId
      createdAt: $createdAt
      enabled: $enabled
    ) {
      result
      message
    }
  }
`;
export const updateWebhookMediumSetting = /* GraphQL */ `
  query UpdateWebhookMediumSetting(
    $apiId: String
    $createdAt: String
    $settingValues: AWSJSON
    $handleEvents: [WebhookEvent]
  ) {
    updateWebhookMediumSetting(
      apiId: $apiId
      createdAt: $createdAt
      settingValues: $settingValues
      handleEvents: $handleEvents
    ) {
      result
      message
    }
  }
`;
export const deleteWebhookSetting = /* GraphQL */ `
  query DeleteWebhookSetting($apiId: String, $createdAt: String) {
    deleteWebhookSetting(apiId: $apiId, createdAt: $createdAt)
  }
`;
export const deleteWebhookMediumSetting = /* GraphQL */ `
  query DeleteWebhookMediumSetting($apiId: String, $createdAt: String) {
    deleteWebhookMediumSetting(apiId: $apiId, createdAt: $createdAt) {
      result
      message
    }
  }
`;
export const createCustomField = /* GraphQL */ `
  query CreateCustomField(
    $apiId: String
    $fieldId: String
    $name: String
    $fields: AWSJSON
    $position: AWSJSON
  ) {
    createCustomField(
      apiId: $apiId
      fieldId: $fieldId
      name: $name
      fields: $fields
      position: $position
    )
  }
`;
export const updateCustomField = /* GraphQL */ `
  query UpdateCustomField(
    $apiId: String
    $fieldId: String
    $createdAt: String
    $name: String
    $fields: AWSJSON
    $position: AWSJSON
  ) {
    updateCustomField(
      apiId: $apiId
      fieldId: $fieldId
      createdAt: $createdAt
      name: $name
      fields: $fields
      position: $position
    )
  }
`;
export const deleteCustomField = /* GraphQL */ `
  query DeleteCustomField($apiId: String, $createdAt: String) {
    deleteCustomField(apiId: $apiId, createdAt: $createdAt)
  }
`;
export const deleteAccount = /* GraphQL */ `
  query DeleteAccount {
    deleteAccount
  }
`;
export const generateUnusedContentId = /* GraphQL */ `
  query GenerateUnusedContentId($apiId: String) {
    generateUnusedContentId(apiId: $apiId)
  }
`;
export const startImport = /* GraphQL */ `
  query StartImport($bucket: String, $identityId: String, $fileName: String) {
    startImport(bucket: $bucket, identityId: $identityId, fileName: $fileName) {
      result
      message
    }
  }
`;
export const getMeta = /* GraphQL */ `
  query GetMeta {
    getMeta
  }
`;
export const findServiceByDomain = /* GraphQL */ `
  query FindServiceByDomain($domain: String) {
    findServiceByDomain(domain: $domain) {
      partitionKey
      sortKey
      gsiPartitionKey1
      gsiSortKey1
      gsiPartitionKey2
      gsiSortKey2
      gsiPartitionKey3
      gsiSortKey3
      gsiSinglePartitionKey1
      serviceName
      serviceImage {
        key
        level
        identityId
      }
      servicePostCount
      serviceAllowedIpList
      requireMfa
      environment {
        name
        parent
      }
      apiStopUntil
      serviceLanguage
      serviceViewerGroup
      memberViewerGroup
      roleViewerGroup
      mediumViewerGroup
      reviewRequestViewerGroup
      apiViewerGroup
      contentViewerGroup
      viewerGroup
      apiName
      apiDescription
      apiType
      apiFields {
        idValue
        fieldId
        name
        kind
        description
        required
        richEditorMultiParagraph
        richEditorImageSize
        referenceKey
        referenceDisplayItem
        dateFormat
        booleanInitialValue
        selectItems {
          id
          value
        }
        selectInitialValue
        richEditorOptions
        richEditorV2Options
        richEditorV2ColorList {
          id
          value
        }
        customClassList {
          id
          name
          value
        }
        multipleSelect
        iframeUrl
        customFieldCreatedAt
        customFieldDisplayItem
        customFieldCreatedAtList
        textSizeLimitValidation {
          textSize {
            min
            max
          }
        }
        numberSizeLimitValidation {
          numberSize {
            min
            max
          }
        }
        relationListCountLimitValidation {
          relationListCount {
            min
            max
          }
        }
        repeaterCountLimitValidation {
          repeatCount {
            min
            max
          }
        }
        imageSizeValidation {
          imageSize {
            width
            height
          }
        }
        mediaListLayout
        patternMatchValidation {
          regexp {
            pattern
            flags
          }
        }
        isUnique
      }
      apiPreviewUrl
      apiPreviewLinkIsVisible
      apiImportProgress {
        status
        date
        message
      }
      apiAllowedIpList
      contentIdSetting {
        allowList
        length
      }
      contentValue
      draftValue
      closedValue
      contentDraftKey
      contentStatus
      contentCustomStatusIds
      reservationTime
      reservationStopTime
      authType
      createdAt
      updatedAt
      publishedAt
      revisedAt
      closedAt
      editorId
      authorId
      inputSource
      groups
      suspended
      relatedContentIds {
        partitionKey
        apiId
        gsiSinglePartitionKey1
        sortKey
      }
      customDomainImageHost
      customDomainFileHost
      _restricted {
        type
        reason
      }
    }
  }
`;
export const checkApiEndpoint = /* GraphQL */ `
  query CheckApiEndpoint($serviceId: String, $endpoint: String) {
    checkApiEndpoint(serviceId: $serviceId, endpoint: $endpoint)
  }
`;
export const findApi = /* GraphQL */ `
  query FindApi($apiId: String) {
    findApi(apiId: $apiId) {
      partitionKey
      sortKey
      gsiPartitionKey1
      gsiSortKey1
      gsiPartitionKey2
      gsiSortKey2
      gsiPartitionKey3
      gsiSortKey3
      gsiSinglePartitionKey1
      serviceName
      serviceImage {
        key
        level
        identityId
      }
      servicePostCount
      serviceAllowedIpList
      requireMfa
      environment {
        name
        parent
      }
      apiStopUntil
      serviceLanguage
      serviceViewerGroup
      memberViewerGroup
      roleViewerGroup
      mediumViewerGroup
      reviewRequestViewerGroup
      apiViewerGroup
      contentViewerGroup
      viewerGroup
      apiName
      apiDescription
      apiType
      apiFields {
        idValue
        fieldId
        name
        kind
        description
        required
        richEditorMultiParagraph
        richEditorImageSize
        referenceKey
        referenceDisplayItem
        dateFormat
        booleanInitialValue
        selectItems {
          id
          value
        }
        selectInitialValue
        richEditorOptions
        richEditorV2Options
        richEditorV2ColorList {
          id
          value
        }
        customClassList {
          id
          name
          value
        }
        multipleSelect
        iframeUrl
        customFieldCreatedAt
        customFieldDisplayItem
        customFieldCreatedAtList
        textSizeLimitValidation {
          textSize {
            min
            max
          }
        }
        numberSizeLimitValidation {
          numberSize {
            min
            max
          }
        }
        relationListCountLimitValidation {
          relationListCount {
            min
            max
          }
        }
        repeaterCountLimitValidation {
          repeatCount {
            min
            max
          }
        }
        imageSizeValidation {
          imageSize {
            width
            height
          }
        }
        mediaListLayout
        patternMatchValidation {
          regexp {
            pattern
            flags
          }
        }
        isUnique
      }
      apiPreviewUrl
      apiPreviewLinkIsVisible
      apiImportProgress {
        status
        date
        message
      }
      apiAllowedIpList
      contentIdSetting {
        allowList
        length
      }
      contentValue
      draftValue
      closedValue
      contentDraftKey
      contentStatus
      contentCustomStatusIds
      reservationTime
      reservationStopTime
      authType
      createdAt
      updatedAt
      publishedAt
      revisedAt
      closedAt
      editorId
      authorId
      inputSource
      groups
      suspended
      relatedContentIds {
        partitionKey
        apiId
        gsiSinglePartitionKey1
        sortKey
      }
      customDomainImageHost
      customDomainFileHost
      _restricted {
        type
        reason
      }
    }
  }
`;
export const findApis = /* GraphQL */ `
  query FindApis($serviceId: String) {
    findApis(serviceId: $serviceId) {
      partitionKey
      sortKey
      gsiPartitionKey1
      gsiSortKey1
      gsiPartitionKey2
      gsiSortKey2
      gsiPartitionKey3
      gsiSortKey3
      gsiSinglePartitionKey1
      serviceName
      serviceImage {
        key
        level
        identityId
      }
      servicePostCount
      serviceAllowedIpList
      requireMfa
      environment {
        name
        parent
      }
      apiStopUntil
      serviceLanguage
      serviceViewerGroup
      memberViewerGroup
      roleViewerGroup
      mediumViewerGroup
      reviewRequestViewerGroup
      apiViewerGroup
      contentViewerGroup
      viewerGroup
      apiName
      apiDescription
      apiType
      apiFields {
        idValue
        fieldId
        name
        kind
        description
        required
        richEditorMultiParagraph
        richEditorImageSize
        referenceKey
        referenceDisplayItem
        dateFormat
        booleanInitialValue
        selectItems {
          id
          value
        }
        selectInitialValue
        richEditorOptions
        richEditorV2Options
        richEditorV2ColorList {
          id
          value
        }
        customClassList {
          id
          name
          value
        }
        multipleSelect
        iframeUrl
        customFieldCreatedAt
        customFieldDisplayItem
        customFieldCreatedAtList
        textSizeLimitValidation {
          textSize {
            min
            max
          }
        }
        numberSizeLimitValidation {
          numberSize {
            min
            max
          }
        }
        relationListCountLimitValidation {
          relationListCount {
            min
            max
          }
        }
        repeaterCountLimitValidation {
          repeatCount {
            min
            max
          }
        }
        imageSizeValidation {
          imageSize {
            width
            height
          }
        }
        mediaListLayout
        patternMatchValidation {
          regexp {
            pattern
            flags
          }
        }
        isUnique
      }
      apiPreviewUrl
      apiPreviewLinkIsVisible
      apiImportProgress {
        status
        date
        message
      }
      apiAllowedIpList
      contentIdSetting {
        allowList
        length
      }
      contentValue
      draftValue
      closedValue
      contentDraftKey
      contentStatus
      contentCustomStatusIds
      reservationTime
      reservationStopTime
      authType
      createdAt
      updatedAt
      publishedAt
      revisedAt
      closedAt
      editorId
      authorId
      inputSource
      groups
      suspended
      relatedContentIds {
        partitionKey
        apiId
        gsiSinglePartitionKey1
        sortKey
      }
      customDomainImageHost
      customDomainFileHost
      _restricted {
        type
        reason
      }
    }
  }
`;
export const findCustomFields = /* GraphQL */ `
  query FindCustomFields($apiId: String) {
    findCustomFields(apiId: $apiId) {
      apiId
      createdAt
      fieldId
      name
      fields {
        idValue
        fieldId
        name
        kind
        description
        required
        richEditorMultiParagraph
        richEditorImageSize
        referenceKey
        referenceDisplayItem
        dateFormat
        booleanInitialValue
        selectItems {
          id
          value
        }
        selectInitialValue
        richEditorOptions
        richEditorV2Options
        richEditorV2ColorList {
          id
          value
        }
        customClassList {
          id
          name
          value
        }
        multipleSelect
        iframeUrl
        customFieldCreatedAt
        customFieldDisplayItem
        customFieldCreatedAtList
        textSizeLimitValidation {
          textSize {
            min
            max
          }
        }
        numberSizeLimitValidation {
          numberSize {
            min
            max
          }
        }
        relationListCountLimitValidation {
          relationListCount {
            min
            max
          }
        }
        repeaterCountLimitValidation {
          repeatCount {
            min
            max
          }
        }
        imageSizeValidation {
          imageSize {
            width
            height
          }
        }
        mediaListLayout
        patternMatchValidation {
          regexp {
            pattern
            flags
          }
        }
        isUnique
      }
      position
      updatedAt
      viewerGroup
    }
  }
`;
export const readApiKey = /* GraphQL */ `
  query ReadApiKey($apiKeyId: String, $serviceId: String, $value: String) {
    readApiKey(apiKeyId: $apiKeyId, serviceId: $serviceId, value: $value) {
      apiKeyId
      serviceId
      value
      label
      createdAt
      updatedAt
      createdBy
      updatedBy
      defaultPolicy {
        get
        getAllDraft
        getAllClosed
        post
        put
        patch
        delete
      }
      priorityPolicies {
        apiId
        policy {
          get
          getAllDraft
          getAllClosed
          post
          put
          patch
          delete
        }
      }
      managementPolicy {
        getContent
        patchContentCreatedBy
        patchContentStatus
        getMedia
        postMedia
        deleteMedia
      }
    }
  }
`;
export const findWebhookSettings = /* GraphQL */ `
  query FindWebhookSettings($apiId: String) {
    findWebhookSettings(apiId: $apiId) {
      apiId
      createdAt
      target
      settingValues
      handleEvents
      serviceId
      updatedAt
      viewerGroup
      enabled
    }
  }
`;
export const findWebhookMediumSettings = /* GraphQL */ `
  query FindWebhookMediumSettings($apiId: String) {
    findWebhookMediumSettings(apiId: $apiId) {
      apiId
      createdAt
      target
      settingValues
      handleEvents
      serviceId
      updatedAt
      viewerGroup
      enabled
    }
  }
`;
export const getWebhookSettingsCount = /* GraphQL */ `
  query GetWebhookSettingsCount($serviceId: String) {
    getWebhookSettingsCount(serviceId: $serviceId)
  }
`;
export const findContent = /* GraphQL */ `
  query FindContent($apiId: String, $userDefinedId: String) {
    findContent(apiId: $apiId, userDefinedId: $userDefinedId) {
      partitionKey
      sortKey
      gsiPartitionKey1
      gsiSortKey1
      gsiPartitionKey2
      gsiSortKey2
      gsiPartitionKey3
      gsiSortKey3
      gsiSinglePartitionKey1
      serviceName
      serviceImage {
        key
        level
        identityId
      }
      servicePostCount
      serviceAllowedIpList
      requireMfa
      environment {
        name
        parent
      }
      apiStopUntil
      serviceLanguage
      serviceViewerGroup
      memberViewerGroup
      roleViewerGroup
      mediumViewerGroup
      reviewRequestViewerGroup
      apiViewerGroup
      contentViewerGroup
      viewerGroup
      apiName
      apiDescription
      apiType
      apiFields {
        idValue
        fieldId
        name
        kind
        description
        required
        richEditorMultiParagraph
        richEditorImageSize
        referenceKey
        referenceDisplayItem
        dateFormat
        booleanInitialValue
        selectItems {
          id
          value
        }
        selectInitialValue
        richEditorOptions
        richEditorV2Options
        richEditorV2ColorList {
          id
          value
        }
        customClassList {
          id
          name
          value
        }
        multipleSelect
        iframeUrl
        customFieldCreatedAt
        customFieldDisplayItem
        customFieldCreatedAtList
        textSizeLimitValidation {
          textSize {
            min
            max
          }
        }
        numberSizeLimitValidation {
          numberSize {
            min
            max
          }
        }
        relationListCountLimitValidation {
          relationListCount {
            min
            max
          }
        }
        repeaterCountLimitValidation {
          repeatCount {
            min
            max
          }
        }
        imageSizeValidation {
          imageSize {
            width
            height
          }
        }
        mediaListLayout
        patternMatchValidation {
          regexp {
            pattern
            flags
          }
        }
        isUnique
      }
      apiPreviewUrl
      apiPreviewLinkIsVisible
      apiImportProgress {
        status
        date
        message
      }
      apiAllowedIpList
      contentIdSetting {
        allowList
        length
      }
      contentValue
      draftValue
      closedValue
      contentDraftKey
      contentStatus
      contentCustomStatusIds
      reservationTime
      reservationStopTime
      authType
      createdAt
      updatedAt
      publishedAt
      revisedAt
      closedAt
      editorId
      authorId
      inputSource
      groups
      suspended
      relatedContentIds {
        partitionKey
        apiId
        gsiSinglePartitionKey1
        sortKey
      }
      customDomainImageHost
      customDomainFileHost
      _restricted {
        type
        reason
      }
    }
  }
`;
export const findHistories = /* GraphQL */ `
  query FindHistories($contentId: String, $nextToken: AWSJSON) {
    findHistories(contentId: $contentId, nextToken: $nextToken)
  }
`;
export const findContentCreators = /* GraphQL */ `
  query FindContentCreators($apiId: String) {
    findContentCreators(apiId: $apiId) {
      username
      email
      name
      isRemoved
      isApi
    }
  }
`;
export const findContentEditors = /* GraphQL */ `
  query FindContentEditors($apiId: String) {
    findContentEditors(apiId: $apiId) {
      username
      email
      name
      isRemoved
      isApi
    }
  }
`;
export const getMyRoles = /* GraphQL */ `
  query GetMyRoles($serviceId: String) {
    getMyRoles(serviceId: $serviceId) {
      serviceId
      roleId
      name
      isAdmin
      createdAt
      updatedAt
      viewerGroup
      rolePermission {
        create
        read
        readLevel
        update
        delete
      }
      memberPermission {
        create
        read {
          defaultRead
          allowMemberList
          sameRole
        }
        update
        delete
      }
      usagePermission {
        read
      }
      billingPermission {
        read
        update
      }
      environmentPermission {
        create
        read
        update
        delete
      }
      apiKeyPermission {
        read
      }
      reviewRequestPermission {
        readLevel
        create
        updateLevel
      }
      mediumPermission {
        create
        readLevel
        update
        delete
      }
      apiPermission {
        create
      }
      defaultContentPermission {
        apiRead
        apiUpdate
        apiDelete
        contentCreate {
          draft
          publish
        }
        contentReadLevel
        contentUpdate {
          saveDraft
          removeDraft
          changeContentIdUnpublished
          changeContentIdPublished
          reorderUnpublished
          reorderPublished
          changePublicationDateUnpublished
          changePublicationDatePublished
          changeAuthor
          changeDraftKey
          publish
          unpublish
          revertToDraft
          revertToDraftUnpublished
        }
        contentDelete
        showDeveloperMenu
      }
      exceptionContentPermissions {
        apiId
        permission {
          apiRead
          apiUpdate
          apiDelete
          contentCreate {
            draft
            publish
          }
          contentReadLevel
          contentUpdate {
            saveDraft
            removeDraft
            changeContentIdUnpublished
            changeContentIdPublished
            reorderUnpublished
            reorderPublished
            changePublicationDateUnpublished
            changePublicationDatePublished
            changeAuthor
            changeDraftKey
            publish
            unpublish
            revertToDraft
            revertToDraftUnpublished
          }
          contentDelete
          showDeveloperMenu
        }
        reviewRequestPermission {
          readLevel
          create
          updateLevel
        }
      }
    }
  }
`;
export const listServiceRoles = /* GraphQL */ `
  query ListServiceRoles($serviceId: String) {
    listServiceRoles(serviceId: $serviceId) {
      serviceId
      roleId
      name
      isAdmin
      createdAt
      updatedAt
      viewerGroup
      rolePermission {
        create
        read
        readLevel
        update
        delete
      }
      memberPermission {
        create
        read {
          defaultRead
          allowMemberList
          sameRole
        }
        update
        delete
      }
      usagePermission {
        read
      }
      billingPermission {
        read
        update
      }
      environmentPermission {
        create
        read
        update
        delete
      }
      apiKeyPermission {
        read
      }
      reviewRequestPermission {
        readLevel
        create
        updateLevel
      }
      mediumPermission {
        create
        readLevel
        update
        delete
      }
      apiPermission {
        create
      }
      defaultContentPermission {
        apiRead
        apiUpdate
        apiDelete
        contentCreate {
          draft
          publish
        }
        contentReadLevel
        contentUpdate {
          saveDraft
          removeDraft
          changeContentIdUnpublished
          changeContentIdPublished
          reorderUnpublished
          reorderPublished
          changePublicationDateUnpublished
          changePublicationDatePublished
          changeAuthor
          changeDraftKey
          publish
          unpublish
          revertToDraft
          revertToDraftUnpublished
        }
        contentDelete
        showDeveloperMenu
      }
      exceptionContentPermissions {
        apiId
        permission {
          apiRead
          apiUpdate
          apiDelete
          contentCreate {
            draft
            publish
          }
          contentReadLevel
          contentUpdate {
            saveDraft
            removeDraft
            changeContentIdUnpublished
            changeContentIdPublished
            reorderUnpublished
            reorderPublished
            changePublicationDateUnpublished
            changePublicationDatePublished
            changeAuthor
            changeDraftKey
            publish
            unpublish
            revertToDraft
            revertToDraftUnpublished
          }
          contentDelete
          showDeveloperMenu
        }
        reviewRequestPermission {
          readLevel
          create
          updateLevel
        }
      }
    }
  }
`;
export const getServiceRole = /* GraphQL */ `
  query GetServiceRole($serviceId: String, $roleId: String) {
    getServiceRole(serviceId: $serviceId, roleId: $roleId) {
      serviceId
      roleId
      name
      isAdmin
      createdAt
      updatedAt
      viewerGroup
      rolePermission {
        create
        read
        readLevel
        update
        delete
      }
      memberPermission {
        create
        read {
          defaultRead
          allowMemberList
          sameRole
        }
        update
        delete
      }
      usagePermission {
        read
      }
      billingPermission {
        read
        update
      }
      environmentPermission {
        create
        read
        update
        delete
      }
      apiKeyPermission {
        read
      }
      reviewRequestPermission {
        readLevel
        create
        updateLevel
      }
      mediumPermission {
        create
        readLevel
        update
        delete
      }
      apiPermission {
        create
      }
      defaultContentPermission {
        apiRead
        apiUpdate
        apiDelete
        contentCreate {
          draft
          publish
        }
        contentReadLevel
        contentUpdate {
          saveDraft
          removeDraft
          changeContentIdUnpublished
          changeContentIdPublished
          reorderUnpublished
          reorderPublished
          changePublicationDateUnpublished
          changePublicationDatePublished
          changeAuthor
          changeDraftKey
          publish
          unpublish
          revertToDraft
          revertToDraftUnpublished
        }
        contentDelete
        showDeveloperMenu
      }
      exceptionContentPermissions {
        apiId
        permission {
          apiRead
          apiUpdate
          apiDelete
          contentCreate {
            draft
            publish
          }
          contentReadLevel
          contentUpdate {
            saveDraft
            removeDraft
            changeContentIdUnpublished
            changeContentIdPublished
            reorderUnpublished
            reorderPublished
            changePublicationDateUnpublished
            changePublicationDatePublished
            changeAuthor
            changeDraftKey
            publish
            unpublish
            revertToDraft
            revertToDraftUnpublished
          }
          contentDelete
          showDeveloperMenu
        }
        reviewRequestPermission {
          readLevel
          create
          updateLevel
        }
      }
    }
  }
`;
export const getMyServices = /* GraphQL */ `
  query GetMyServices {
    getMyServices {
      partitionKey
      sortKey
      gsiPartitionKey1
      gsiSortKey1
      gsiPartitionKey2
      gsiSortKey2
      gsiPartitionKey3
      gsiSortKey3
      gsiSinglePartitionKey1
      serviceName
      serviceImage {
        key
        level
        identityId
      }
      servicePostCount
      serviceAllowedIpList
      requireMfa
      environment {
        name
        parent
      }
      apiStopUntil
      serviceLanguage
      serviceViewerGroup
      memberViewerGroup
      roleViewerGroup
      mediumViewerGroup
      reviewRequestViewerGroup
      apiViewerGroup
      contentViewerGroup
      viewerGroup
      apiName
      apiDescription
      apiType
      apiFields {
        idValue
        fieldId
        name
        kind
        description
        required
        richEditorMultiParagraph
        richEditorImageSize
        referenceKey
        referenceDisplayItem
        dateFormat
        booleanInitialValue
        selectItems {
          id
          value
        }
        selectInitialValue
        richEditorOptions
        richEditorV2Options
        richEditorV2ColorList {
          id
          value
        }
        customClassList {
          id
          name
          value
        }
        multipleSelect
        iframeUrl
        customFieldCreatedAt
        customFieldDisplayItem
        customFieldCreatedAtList
        textSizeLimitValidation {
          textSize {
            min
            max
          }
        }
        numberSizeLimitValidation {
          numberSize {
            min
            max
          }
        }
        relationListCountLimitValidation {
          relationListCount {
            min
            max
          }
        }
        repeaterCountLimitValidation {
          repeatCount {
            min
            max
          }
        }
        imageSizeValidation {
          imageSize {
            width
            height
          }
        }
        mediaListLayout
        patternMatchValidation {
          regexp {
            pattern
            flags
          }
        }
        isUnique
      }
      apiPreviewUrl
      apiPreviewLinkIsVisible
      apiImportProgress {
        status
        date
        message
      }
      apiAllowedIpList
      contentIdSetting {
        allowList
        length
      }
      contentValue
      draftValue
      closedValue
      contentDraftKey
      contentStatus
      contentCustomStatusIds
      reservationTime
      reservationStopTime
      authType
      createdAt
      updatedAt
      publishedAt
      revisedAt
      closedAt
      editorId
      authorId
      inputSource
      groups
      suspended
      relatedContentIds {
        partitionKey
        apiId
        gsiSinglePartitionKey1
        sortKey
      }
      customDomainImageHost
      customDomainFileHost
      _restricted {
        type
        reason
      }
    }
  }
`;
export const findUser = /* GraphQL */ `
  query FindUser($email: String) {
    findUser(email: $email)
  }
`;
export const findServiceUser = /* GraphQL */ `
  query FindServiceUser($serviceId: String, $userId: String) {
    findServiceUser(serviceId: $serviceId, userId: $userId)
  }
`;
export const findServiceUsers = /* GraphQL */ `
  query FindServiceUsers($serviceId: String) {
    findServiceUsers(serviceId: $serviceId) {
      sub
      email_verified
      email
      mfaSetting
      username
      picture
      name
      isSAML
      roleIds
      inviting
    }
  }
`;
export const findServiceUsersAssignableReviewRequest = /* GraphQL */ `
  query FindServiceUsersAssignableReviewRequest(
    $serviceId: String!
    $reqId: Int!
  ) {
    findServiceUsersAssignableReviewRequest(
      serviceId: $serviceId
      reqId: $reqId
    ) {
      sub
      email_verified
      email
      mfaSetting
      username
      picture
      name
      isSAML
      roleIds
      inviting
    }
  }
`;
export const getMyMember = /* GraphQL */ `
  query GetMyMember($serviceId: String) {
    getMyMember(serviceId: $serviceId) {
      result
      message
      data {
        id
        serviceId
        roleIds
        createdAt
        updatedAt
        viewerGroup
        inviting
      }
    }
  }
`;
export const getMyMembers = /* GraphQL */ `
  query GetMyMembers {
    getMyMembers {
      result
      message
      data {
        id
        serviceId
        roleIds
        createdAt
        updatedAt
        viewerGroup
        inviting
      }
    }
  }
`;
export const getBandwidthLog = /* GraphQL */ `
  query GetBandwidthLog($serviceId: String) {
    getBandwidthLog(serviceId: $serviceId)
  }
`;
export const searchContents = /* GraphQL */ `
  query SearchContents($apiId: String, $options: SearchOptions) {
    searchContents(apiId: $apiId, options: $options)
  }
`;
export const getContentsCount = /* GraphQL */ `
  query GetContentsCount($serviceId: String) {
    getContentsCount(serviceId: $serviceId)
  }
`;
export const getContentsById = /* GraphQL */ `
  query GetContentsById($contentIds: [String]) {
    getContentsById(contentIds: $contentIds)
  }
`;
export const getContentsByIdandApiId = /* GraphQL */ `
  query GetContentsByIdandApiId($contentIds: [String]) {
    getContentsByIdandApiId(contentIds: $contentIds)
  }
`;
export const findMedium = /* GraphQL */ `
  query FindMedium($serviceId: String, $mediumId: String) {
    findMedium(serviceId: $serviceId, mediumId: $mediumId) {
      serviceId
      mediumId
      fileName
      directory
      createdAt
      updatedAt
      kind
      uploader
      contentType
      contentLength
      imageWidth
      imageHeight
      viewerGroup
      relatedContentIds {
        partitionKey
        apiId
        gsiSinglePartitionKey1
        sortKey
      }
      tags
    }
  }
`;
export const findMedia = /* GraphQL */ `
  query FindMedia(
    $serviceId: String
    $fileNameQuery: String
    $filters: String
    $onlyImage: Boolean
    $nextToken: AWSJSON
  ) {
    findMedia(
      serviceId: $serviceId
      fileNameQuery: $fileNameQuery
      filters: $filters
      onlyImage: $onlyImage
      nextToken: $nextToken
    )
  }
`;
export const getMediumCount = /* GraphQL */ `
  query GetMediumCount($serviceId: String, $onlyImage: Boolean) {
    getMediumCount(serviceId: $serviceId, onlyImage: $onlyImage)
  }
`;
export const findAuditLogs = /* GraphQL */ `
  query FindAuditLogs(
    $serviceId: String
    $limit: Int
    $offset: Int
    $startDate: String
    $endDate: String
  ) {
    findAuditLogs(
      serviceId: $serviceId
      limit: $limit
      offset: $offset
      startDate: $startDate
      endDate: $endDate
    )
  }
`;
export const getEnvironments = /* GraphQL */ `
  query GetEnvironments($serviceId: String) {
    getEnvironments(serviceId: $serviceId) {
      partitionKey
      sortKey
      gsiPartitionKey1
      gsiSortKey1
      gsiPartitionKey2
      gsiSortKey2
      gsiPartitionKey3
      gsiSortKey3
      gsiSinglePartitionKey1
      serviceName
      serviceImage {
        key
        level
        identityId
      }
      servicePostCount
      serviceAllowedIpList
      requireMfa
      environment {
        name
        parent
      }
      apiStopUntil
      serviceLanguage
      serviceViewerGroup
      memberViewerGroup
      roleViewerGroup
      mediumViewerGroup
      reviewRequestViewerGroup
      apiViewerGroup
      contentViewerGroup
      viewerGroup
      apiName
      apiDescription
      apiType
      apiFields {
        idValue
        fieldId
        name
        kind
        description
        required
        richEditorMultiParagraph
        richEditorImageSize
        referenceKey
        referenceDisplayItem
        dateFormat
        booleanInitialValue
        selectItems {
          id
          value
        }
        selectInitialValue
        richEditorOptions
        richEditorV2Options
        richEditorV2ColorList {
          id
          value
        }
        customClassList {
          id
          name
          value
        }
        multipleSelect
        iframeUrl
        customFieldCreatedAt
        customFieldDisplayItem
        customFieldCreatedAtList
        textSizeLimitValidation {
          textSize {
            min
            max
          }
        }
        numberSizeLimitValidation {
          numberSize {
            min
            max
          }
        }
        relationListCountLimitValidation {
          relationListCount {
            min
            max
          }
        }
        repeaterCountLimitValidation {
          repeatCount {
            min
            max
          }
        }
        imageSizeValidation {
          imageSize {
            width
            height
          }
        }
        mediaListLayout
        patternMatchValidation {
          regexp {
            pattern
            flags
          }
        }
        isUnique
      }
      apiPreviewUrl
      apiPreviewLinkIsVisible
      apiImportProgress {
        status
        date
        message
      }
      apiAllowedIpList
      contentIdSetting {
        allowList
        length
      }
      contentValue
      draftValue
      closedValue
      contentDraftKey
      contentStatus
      contentCustomStatusIds
      reservationTime
      reservationStopTime
      authType
      createdAt
      updatedAt
      publishedAt
      revisedAt
      closedAt
      editorId
      authorId
      inputSource
      groups
      suspended
      relatedContentIds {
        partitionKey
        apiId
        gsiSinglePartitionKey1
        sortKey
      }
      customDomainImageHost
      customDomainFileHost
      _restricted {
        type
        reason
      }
    }
  }
`;
export const getGlobalApiIpAddressRestriction = /* GraphQL */ `
  query GetGlobalApiIpAddressRestriction($serviceId: String!) {
    getGlobalApiIpAddressRestriction(serviceId: $serviceId) {
      ipList
    }
  }
`;
export const findContentByVersion = /* GraphQL */ `
  query FindContentByVersion(
    $contentId: String!
    $version: Int!
    $denyDraft: Boolean
  ) {
    findContentByVersion(
      contentId: $contentId
      version: $version
      denyDraft: $denyDraft
    ) {
      value
      unauthorizedFieldIds
    }
  }
`;
export const ensureContentObjectExists = /* GraphQL */ `
  query EnsureContentObjectExists($apiId: String!) {
    ensureContentObjectExists(apiId: $apiId) {
      userDefinedContentId
      exists
      authorized
    }
  }
`;
export const getFeatureFlagValue = /* GraphQL */ `
  query GetFeatureFlagValue($flagName: String) {
    getFeatureFlagValue(flagName: $flagName)
  }
`;
export const checkUserState = /* GraphQL */ `
  query CheckUserState($domain: String) {
    checkUserState(domain: $domain)
  }
`;
export const getOembedResult = /* GraphQL */ `
  query GetOembedResult($url: String) {
    getOembedResult(url: $url)
  }
`;
export const getOembedlyResult = /* GraphQL */ `
  query GetOembedlyResult($url: String) {
    getOembedlyResult(url: $url)
  }
`;
export const getIframelyResult = /* GraphQL */ `
  query GetIframelyResult($url: String!, $optionQuery: String) {
    getIframelyResult(url: $url, optionQuery: $optionQuery)
  }
`;
export const findReviewRequest = /* GraphQL */ `
  query FindReviewRequest($serviceId: String, $reqId: Int) {
    findReviewRequest(serviceId: $serviceId, reqId: $reqId) {
      serviceId
      reqId
      title
      description
      contentId
      status
      createdAt
      updatedAt
      reservationTime
      reservationStopTime
      reviewers {
        id
      }
      commentCount
      authorId
      viewerGroup
    }
  }
`;
export const getOpenedReviewRequestAccessInfo = /* GraphQL */ `
  query GetOpenedReviewRequestAccessInfo(
    $serviceId: String
    $contentId: String
  ) {
    getOpenedReviewRequestAccessInfo(
      serviceId: $serviceId
      contentId: $contentId
    ) {
      reqId
      readable
    }
  }
`;
export const getOpenedReviewRequestsCount = /* GraphQL */ `
  query GetOpenedReviewRequestsCount($serviceId: String) {
    getOpenedReviewRequestsCount(serviceId: $serviceId)
  }
`;
export const getReviewRequestsByStatus = /* GraphQL */ `
  query GetReviewRequestsByStatus(
    $serviceId: String
    $status: ReviewRequestStatus
    $nextToken: AWSJSON
  ) {
    getReviewRequestsByStatus(
      serviceId: $serviceId
      status: $status
      nextToken: $nextToken
    )
  }
`;
export const getAllReviewRequestEvent = /* GraphQL */ `
  query GetAllReviewRequestEvent($serviceId: String, $reqId: Int) {
    getAllReviewRequestEvent(serviceId: $serviceId, reqId: $reqId)
  }
`;
export const getPlanList = /* GraphQL */ `
  query GetPlanList($serviceId: String) {
    getPlanList(serviceId: $serviceId) {
      suspended
      planTypes {
        type
        plans {
          name
          current
          description
          displayName
          minimumMonthPrice
          minimumMonthTaxIncludedPrice
          trialLength
          limit {
            transfer
            transferOveragePrice
            transferOverageUnit
            contentsCount
            memberCount
            memberCountOveragePrice
            memberCountOverageUnit
            importUpperLimit
            maxMediumSizeInMb
            roleCount
            apiCount
            apiCountOveragePrice
            apiCountOverageUnit
            webhookCount
            customFieldCount
            fileUpload
            history
            ipRestriction
            ipRestrictionCount
            apiIpRestriction
            apiIpRestrictionCount
            additionalEnvironments
            auditLog
            requireMfa
            iframe
            premiumSupport
            indivisualInfra
            sla
            handleEnvironments
            enableAllParams
            enableAddons
            maxApiKeyCount
            enableSendInvoice
            customStatusCount
            enableSAML
            mediumTags
          }
        }
      }
    }
  }
`;
export const hasExperiencedPaidPlans = /* GraphQL */ `
  query HasExperiencedPaidPlans($serviceId: String) {
    hasExperiencedPaidPlans(serviceId: $serviceId)
  }
`;
export const describeCreateEnvironment = /* GraphQL */ `
  query DescribeCreateEnvironment($executionId: String) {
    describeCreateEnvironment(executionId: $executionId)
  }
`;
export const getSAMLConfiguration = /* GraphQL */ `
  query GetSAMLConfiguration($serviceId: String) {
    getSAMLConfiguration(serviceId: $serviceId) {
      serviceProviderInfo {
        applicationCallbackURL
        settings {
          audience
          nameIdentifierFormat
          mappings {
            email
            name
            picture
          }
        }
      }
      idpMetadataURL
      idpMetadataXML
      defaultRoleId
      samlOnly
      loginURL
    }
  }
`;
export const listCustomStatus = /* GraphQL */ `
  query ListCustomStatus($apiId: String!) {
    listCustomStatus(apiId: $apiId) {
      id
      apiId
      key
      description
      name
      behaviour
      color
      createdAt
      updatedAt
    }
  }
`;
export const getAccountLanguage = /* GraphQL */ `
  query GetAccountLanguage {
    getAccountLanguage
  }
`;
export const getServiceLanguage = /* GraphQL */ `
  query GetServiceLanguage($serviceId: String!) {
    getServiceLanguage(serviceId: $serviceId)
  }
`;
export const healthcheckGo = /* GraphQL */ `
  query HealthcheckGo($message: String) {
    healthcheckGo(message: $message) {
      message
    }
  }
`;
export const listMediumTags = /* GraphQL */ `
  query ListMediumTags($serviceId: String!, $text: String!) {
    listMediumTags(serviceId: $serviceId, text: $text) {
      tags {
        mediumTagId
        serviceId
        name
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
    }
  }
`;
