/**
 * @file immerを利用した機能を提供する
 *
 * ライブラリのアップデートで関数のシグネチャが変わる可能性があるため、機能の提供元をまとめておく
 */

import { produce as immer__produce } from 'immer';

/**
 * @see {@link https://immerjs.github.io/immer/produce}
 */
const produce = immer__produce;

export { produce };
