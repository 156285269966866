import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';

import { useReleaseAnnouncement } from '../../../hooks/Announcement/useReleaseAnnouncement';
import useContentsCount from '../../../hooks/useContentsCount';
import IconWithTextButton from '../../ui/IconWithTextButton';
import Modal from '../../ui/Modal';
import RichText from '../../ui/RichText';

import styles from './releaseAnnounceModal.module.css';

type Props = {
  service: any;
};

const ReleaseAnnounceModal: React.FC<Props> = ({ service }) => {
  // 機能リリースお知らせモーダル
  const [AnnounceModal, openAnnounceModal] = useModal('root');
  const { contentsCount } = useContentsCount(service.partitionKey);
  const { announcement } = useReleaseAnnouncement(
    openAnnounceModal,
    contentsCount,
  );
  const releaseContent = announcement?.blog?.[0];
  const { t } = useTranslation('releaseAnnounceModal');

  return (
    <AnnounceModal>
      <Modal type="medium">
        <header className={styles.releaseHeader}>
          <h3 className={styles.releaseTitle}>{t('Update Information')}</h3>
          <IconWithTextButton
            className={styles.releaseListLink}
            text={t('Updates')}
            icon="info"
            outlined={true}
            onClick={() =>
              window.open('https://blog.microcms.io/category/update/page/1/')
            }
          />
        </header>
        <RichText html={announcement?.content} />
        <a
          href={`https://blog.microcms.io/${releaseContent?.id}/`}
          target="blog"
        >
          <img
            className={styles.releaseImage}
            src={releaseContent?.ogimage?.url}
            width={releaseContent?.ogimage?.width}
            height={releaseContent?.ogimage?.height}
            alt=""
          />
        </a>
        <div className={styles.share}>
          <a
            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
              releaseContent?.title,
            )}&url=https://blog.microcms.io/${
              releaseContent?.id
            }/&hashtags=microcms`}
            className={styles.tweetButton}
            target="twitter"
          >
            {t('Share on Twitter')}
          </a>
        </div>
      </Modal>
    </AnnounceModal>
  );
};

export default ReleaseAnnounceModal;
