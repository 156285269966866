import cx from 'classnames';
import { nanoid } from 'nanoid';
import Quill from 'quill';
import { useEffect, useMemo, useCallback, useState, useRef, memo } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation, Trans } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import useMediumWriter from '@/hooks/Medium/useMediumWriter';
import { usePermissionIsHaveLeastOne } from '@/hooks/Role/useMyRoles';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';

import BackgroundPicker from './BackgroundPicker';
import Clipboard from './Clipboard.js';
import ColorPicker from './ColorPicker';
import { ColorStyle } from './ColorStyle';
import ImageFormat from './ImageFormat';
import LinkFormat from './LinkFormat';
import MarkdownShortcuts from './MarkdownShortcuts';
import Oembed from './Oembed.js';
import OembedFormat from './OembedFormat';
import OembedlyFormat from './OembedlyFormat';
import UploadImage from './UploadImage';
import CheckRoles from '../../CheckRoles';
import Media from '../../Media';
import Button from '../../ui/Button';
import OldModal from '../../ui/Modal';
import Textfield from '../../ui/Textfield';
import { validateUrl, validateMedium } from '../../Validations';

import styles from './richEditor.module.css';

import { optionTypes } from '@/constants/optionTypes';
import { useInput } from '@/hooks';
import { useContentMetaHight } from '@/views/apis/content/useContentMetaHight';
import { Modal, ModalContent } from '@/views/Common/Ui/Modal';
import { useToggle } from '@/views/Common/Ui/useToggle';

const icons = Quill.import('ui/icons');
icons['code'] = `
<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="OGP-Copy-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <path class="ql-fill" d="M6,5 L6,7 L4,7 L4,11 L6,11 L6,13 L3,13 C2.44771525,13 2,12.5522847 2,12 L2,6 C2,5.44771525 2.44771525,5 3,5 L6,5 Z M15,5 C15.5522847,5 16,5.44771525 16,6 L16,12 C16,12.5522847 15.5522847,13 15,13 L12,13 L12,11 L14,11 L14,7 L12,7 L12,5 L15,5 Z" id="Combined-Shape"></path>
    <rect class="ql-fill" id="Rectangle" transform="translate(9.000000, 9.000000) rotate(15.000000) translate(-9.000000, -9.000000) " x="8" y="3" width="2" height="12" rx="1"></rect>
  </g>
</svg>
`;
Quill.register(ColorStyle, true);
Quill.register(LinkFormat, true);
Quill.register(ImageFormat, true);
Quill.register(OembedFormat, true);
Quill.register(OembedlyFormat, true);
Quill.register('modules/uploadImage', UploadImage);
Quill.register('modules/oembed', Oembed);
Quill.register('modules/markdownShortcuts', MarkdownShortcuts);
Quill.register('modules/colorPicker', ColorPicker);
Quill.register('modules/backgroundPicker', BackgroundPicker);
Quill.register('modules/clipboard', Clipboard, true);

type Props = {
  onChange: any;
  richEditorOptions: any;
  defaultValue: any;
  readOnly?: boolean;
};

const RichEditor: React.FC<Props> = memo(
  ({
    onChange = (f: any) => f,
    richEditorOptions = optionTypes,
    defaultValue,
    readOnly = false,
  }) => {
    const { service } = useGetMyService();
    const { t } = useTranslation('richEditor');
    //複数あっても大丈夫なようにidを割り当てる
    const sid = useMemo(() => nanoid(10), []);

    //カーソルの位置
    const [selection, setSelection] = useState(undefined);

    //モーダル・ファイル選択
    const [isOpenMediaModal, toggleMediaModal] = useToggle(false);
    const openMedia = useCallback(() => {
      //ファイル選択時にカーソルの位置を覚えておく
      // @ts-expect-error
      setSelection(quill.current.getSelection());
      toggleMediaModal(true);
    }, [toggleMediaModal]);
    const closeModal = useCallback(() => {
      toggleMediaModal(false);
    }, [toggleMediaModal]);
    const selectFile = useCallback(
      (medium: any) => () => {
        closeModal();
        // @ts-expect-error
        quill?.current?.theme?.modules?.uploadImage?.insert(medium, selection);
      },
      [closeModal, selection],
    );

    // 画像のドラッグ&ドロップでのアップロード対応
    const { addToast } = useToasts();
    const { currentPlan } = useStripeActions(service);
    const [hasCreatePermission] = usePermissionIsHaveLeastOne(
      'mediumPermission',
      'create',
    );
    const {
      upload: uploadMedium,
      inputFileEl,
      progress: uploadMediumProgress,
    } = useMediumWriter(service);

    const inputFiles = useCallback(
      async (files: any) => {
        if (!hasCreatePermission) {
          addToast(t('You do not have permission to upload images.'), {
            appearance: 'error',
          });
          return;
        }

        for (const file of files) {
          // ファイルをアップロードできないようにする
          if (!file.type.match(/^image\/.*/)) {
            addToast(t('Not supported file format'), {
              appearance: 'error',
            });
            return null;
          }
          //バリデーション
          const res = validateMedium(file, currentPlan);
          if (res !== null) {
            // @ts-expect-error
            inputFileEl.current.value = ''; //同じファイルを2回連続選択してもonChangeが効くようにする
            return;
          }
        }

        //アップロード
        const mediumIds = await uploadMedium(files);

        if (mediumIds) {
          for (const mediumId of mediumIds) {
            // @ts-expect-error
            quill?.current?.theme?.modules?.uploadImage?.insert(
              {
                mediumId,
                serviceId: service?.partitionKey || '',
              },
              // @ts-expect-error
              quill?.current?.getSelection(),
            );
          }
        }
      },
      [
        hasCreatePermission,
        uploadMedium,
        addToast,
        currentPlan,
        inputFileEl,
        service?.partitionKey,
        t,
      ],
    );

    //モーダル・Embed URL入力
    const [EmbedModal, openEmbedModal, closeEmbedModal] = useModal('root');
    const openEmbed = useCallback(() => {
      //ファイル選択時にカーソルの位置を覚えておく
      // @ts-expect-error
      setSelection(quill.current.getSelection());
      openEmbedModal();
    }, [openEmbedModal]);
    const closeEmbed = useCallback(() => {
      closeEmbedModal();
    }, [closeEmbedModal]);
    const [embedUrl, onChangeEmbedUrl, embedUrlError] = useInput(
      '',
      // @ts-ignore
      validateUrl,
    );
    const previewEmbed = useCallback(() => {
      closeEmbed();
      // @ts-expect-error
      quill.current.theme.modules.oembed.insert(embedUrl, selection);
    }, [closeEmbed, selection, embedUrl]);

    // リッチエディタの全画面表示の処理
    const [isActiveRichEditor, setIsActiveRichEditor] = useState(false);
    const scrollTop = useRef(0);
    const $mainEl = document.getElementById('main');
    const $customFieldHooks = document.querySelectorAll(
      '.customFieldHook, .customFieldDragAreaHook',
    );
    const spreadRichEditor = useCallback(() => {
      setIsActiveRichEditor(true);
      if ($mainEl === null) {
        return;
      }
      $customFieldHooks.forEach(($el) => {
        // @ts-expect-error
        $el.style.position = 'static';
      });
      scrollTop.current = $mainEl.scrollTop;
      $mainEl.scrollTop = 0;
      $mainEl.style.overflow = 'hidden';
    }, [$customFieldHooks, $mainEl]);
    const closeRichEditor = useCallback(() => {
      setIsActiveRichEditor(false);
      if ($mainEl === null) {
        return;
      }
      $customFieldHooks.forEach(($el) => {
        // @ts-expect-error
        $el.style.position = 'relative';
      });
      $mainEl.style.overflow = 'auto';
      setTimeout(() => ($mainEl.scrollTop = scrollTop.current), 0);
    }, [$customFieldHooks, $mainEl]);
    useEffect(() => {
      // @ts-expect-error
      const handleKeyUp = (e) => {
        // escで全画面表示を閉じる
        if (e.keyCode === 27 && isActiveRichEditor) {
          closeRichEditor();
        }
      };

      window.addEventListener('keydown', handleKeyUp);
      return () => {
        window.removeEventListener('keydown', handleKeyUp);
      };
    }, [closeRichEditor, isActiveRichEditor]);

    //quillの初期設定
    const quill = useRef(null);
    useEffect(() => {
      // @ts-expect-error
      quill.current = new Quill(`#editor-${sid}`, {
        theme: 'snow',
        modules: {
          toolbar: `#toolbar-${sid}`,
          markdownShortcuts: {
            richEditorOptions,
          },
          uploadImage: {
            openMedia,
            inputFiles,
          },
          oembed: {
            openEmbed,
          },
          colorPicker: {
            sid,
          },
          backgroundPicker: {
            sid,
          },
          clipboard: {
            matchers: [
              [
                'SPAN',
                // @ts-expect-error
                (_, delta) => {
                  return {
                    ...delta,
                    // @ts-expect-error
                    ops: delta.ops.map((obj) => ({
                      ...obj,
                      attributes: {
                        ...obj.attributes,
                        background: undefined,
                      },
                    })),
                  };
                },
              ],
            ],
          },
        },
        readOnly,
      });
      // @ts-expect-error
      const tooltip = quill.current.theme.tooltip;
      const input = tooltip.root.querySelector('input[data-link]');
      input.dataset.link = 'https://microcms.io';
    }, [sid, openEmbed, readOnly, richEditorOptions, inputFiles, openMedia]);

    // 初期ロード時
    useEffect(() => {
      // @ts-expect-error
      quill?.current?.setContents(defaultValue);
      // eslint-disable-next-line
    }, []);

    // 変更時はonChangeにコールバック
    const callOnChange = useCallback(() => {
      // @ts-expect-error
      onChange(quill.current.getContents());
    }, [onChange]);

    // quillのイベントリスナー設定
    useEffect(() => {
      // @ts-expect-error
      quill.current.on('text-change', callOnChange);
      // @ts-expect-error
      return () => quill.current.off('text-change', callOnChange);
    }, [callOnChange]);

    // toolbarの要素がクリックされた時にスクロールが起きないようにする
    const onMouseDownToolbar = useCallback((e: any) => {
      e.preventDefault();
      e.stopPropagation();
    }, []);

    const stickyTop = useContentMetaHight();

    // dividerのスタイルを消すためにグループ化
    const hasHeaderGroups =
      richEditorOptions.includes('headerOne') ||
      richEditorOptions.includes('headerTwo') ||
      richEditorOptions.includes('headerThree') ||
      richEditorOptions.includes('headerFour') ||
      richEditorOptions.includes('headerFive');

    const hasSizeGroups =
      richEditorOptions.includes('sizeSmall') ||
      richEditorOptions.includes('sizeNormal') ||
      richEditorOptions.includes('sizeLarge') ||
      richEditorOptions.includes('sizeHuge');

    const hasDecorationGroups =
      richEditorOptions.includes('bold') ||
      richEditorOptions.includes('italic') ||
      richEditorOptions.includes('underline') ||
      richEditorOptions.includes('strike') ||
      richEditorOptions.includes('code') ||
      richEditorOptions.includes('background') ||
      richEditorOptions.includes('color') ||
      richEditorOptions.includes('align') ||
      richEditorOptions.includes('clean');

    const hasBlockGroups =
      richEditorOptions.includes('blockquote') ||
      richEditorOptions.includes('codeBlock');

    const hasListGroups =
      richEditorOptions.includes('listOrdered') ||
      richEditorOptions.includes('listBullet') ||
      richEditorOptions.includes('indentRemove') ||
      richEditorOptions.includes('indentAdd');

    const hasScriptGroups =
      richEditorOptions.includes('scriptSub') ||
      richEditorOptions.includes('scriptSuper');

    const hasEmbedGroups =
      richEditorOptions.includes('link') ||
      richEditorOptions.includes('image') ||
      richEditorOptions.includes('oembedly');

    return (
      <div
        className={cx(styles.wrapper, {
          [styles.isActive]: isActiveRichEditor,
        })}
        id="wrapper"
      >
        <div
          className={styles.sticky}
          id="sticky"
          style={{ top: isActiveRichEditor ? 0 : stickyTop }}
        >
          <div
            id={`toolbar-${sid}`}
            className={styles.toolbar}
            onMouseDown={(e) => onMouseDownToolbar(e)}
          >
            {(hasHeaderGroups || richEditorOptions.includes('paragraph')) && (
              <span className={styles.divider}>
                {/* @ts-expect-error */}
                <select className="ql-header" defaultValue={false}>
                  {richEditorOptions.includes('headerOne') && (
                    <option value="1">{t('Header1')}</option>
                  )}
                  {richEditorOptions.includes('headerTwo') && (
                    <option value="2">{t('Header2')}</option>
                  )}
                  {richEditorOptions.includes('headerThree') && (
                    <option value="3">{t('Header3')}</option>
                  )}
                  {richEditorOptions.includes('headerFour') && (
                    <option value="4">{t('Header4')}</option>
                  )}
                  {richEditorOptions.includes('headerFive') && (
                    <option value="5">{t('Header5')}</option>
                  )}
                  {richEditorOptions.includes('paragraph') && (
                    // @ts-expect-error
                    <option value={false}>{t('Paragraph')}</option>
                  )}
                </select>
              </span>
            )}
            {hasSizeGroups && (
              <span className={styles.divider}>
                {/* @ts-expect-error */}
                <select className="ql-size" defaultValue={false}>
                  {richEditorOptions.includes('sizeSmall') && (
                    <option value="small">{t('Small')}</option>
                  )}
                  {richEditorOptions.includes('sizeNormal') && (
                    // @ts-expect-error
                    <option value={false}>{t('Normal')}</option>
                  )}
                  {richEditorOptions.includes('sizeLarge') && (
                    <option value="large">{t('Large')}</option>
                  )}
                  {richEditorOptions.includes('sizeHuge') && (
                    <option value="huge">{t('Huge')}</option>
                  )}
                </select>
              </span>
            )}
            {hasDecorationGroups && (
              <span className={styles.divider}>
                {richEditorOptions.includes('bold') && (
                  <button
                    className={`${styles.balloon} ql-bold`}
                    data-balloon={t('Bold')}
                  />
                )}

                {richEditorOptions.includes('italic') && (
                  <button
                    className={`${styles.balloon} ql-italic`}
                    data-balloon={t('Italic')}
                  />
                )}

                {richEditorOptions.includes('underline') && (
                  <button
                    className={`${styles.balloon} ql-underline`}
                    data-balloon={t('Underline')}
                  />
                )}
                {richEditorOptions.includes('strike') && (
                  <button
                    className={`${styles.balloon} ql-strike`}
                    data-balloon={t('Strike')}
                  />
                )}
                {richEditorOptions.includes('code') && (
                  <button
                    className={`${styles.balloon} ql-code`}
                    data-balloon={t('Code')}
                  />
                )}
                {richEditorOptions.includes('background') && (
                  <select
                    className={`${styles.balloon} ql-background`}
                    data-balloon={t('Background')}
                    id={`backgroundSelect-${sid}`}
                  >
                    {/* @ts-expect-error */}
                    <option value={null} />
                    <option value="#dddddd" />
                    <option value="#bbbbbb" />
                    <option value="#999999" />
                    <option value="#666666" />
                    <option value="#333333" />
                    <option value="#000000" />

                    <option value="#f7dbdf" />
                    <option value="#efb7bf" />
                    <option value="#e795a0" />
                    <option value="#df7785" />
                    <option value="#d75969" />
                    <option value="#cf3e51" />
                    <option value="#c7243a" />

                    <option value="#fcf3db" />
                    <option value="#fae6b5" />
                    <option value="#f7db92" />
                    <option value="#f5cf6f" />
                    <option value="#f2c44e" />
                    <option value="#f0b82b" />
                    <option value="#edad0b" />

                    <option value="#FFFBDB" />
                    <option value="#FFF8B6" />
                    <option value="#FFF491" />
                    <option value="#FFF06C" />
                    <option value="#FFED49" />
                    <option value="#FFE924" />
                    <option value="#FFE600" />

                    <option value="#F0F7D9" />
                    <option value="#E4EEB5" />
                    <option value="#D6E693" />
                    <option value="#C9DE73" />
                    <option value="#BBD655" />
                    <option value="#B0CD38" />
                    <option value="#A4C520" />

                    <option value="#CDEFE0" />
                    <option value="#A0E0C3" />
                    <option value="#76D0A8" />
                    <option value="#53C18E" />
                    <option value="#33B178" />
                    <option value="#16A262" />
                    <option value="#009250" />

                    <option value="#D1ECF3" />
                    <option value="#A5D9E6" />
                    <option value="#7DC7DB" />
                    <option value="#58B5CF" />
                    <option value="#38A5C3" />
                    <option value="#1995B7" />
                    <option value="#0186AB" />

                    <option value="#E3E5F1" />
                    <option value="#C8CAE3" />
                    <option value="#B0B4D5" />
                    <option value="#999DC8" />
                    <option value="#8488BA" />
                    <option value="#6F75AC" />
                    <option value="#5D639E" />

                    <option value="#F0D5E9" />
                    <option value="#E0B0D3" />
                    <option value="#D18EBE" />
                    <option value="#C16FAA" />
                    <option value="#B25496" />
                    <option value="#A23C84" />
                    <option value="#932574" />
                    <option value={`custom-background-${sid}`} />
                  </select>
                )}
                {richEditorOptions.includes('color') && (
                  <select
                    className={`${styles.balloon} ql-color`}
                    data-balloon={t('Color')}
                    id={`colorSelect-${sid}`}
                  >
                    <option value="#ffffff" />
                    <option value="#dddddd" />
                    <option value="#bbbbbb" />
                    <option value="#999999" />
                    <option value="#666666" />
                    <option value="#333333" />
                    {/* @ts-expect-error */}
                    <option value={null} />

                    <option value="#f7dbdf" />
                    <option value="#efb7bf" />
                    <option value="#e795a0" />
                    <option value="#df7785" />
                    <option value="#d75969" />
                    <option value="#cf3e51" />
                    <option value="#c7243a" />

                    <option value="#fcf3db" />
                    <option value="#fae6b5" />
                    <option value="#f7db92" />
                    <option value="#f5cf6f" />
                    <option value="#f2c44e" />
                    <option value="#f0b82b" />
                    <option value="#edad0b" />

                    <option value="#FFFBDB" />
                    <option value="#FFF8B6" />
                    <option value="#FFF491" />
                    <option value="#FFF06C" />
                    <option value="#FFED49" />
                    <option value="#FFE924" />
                    <option value="#FFE600" />

                    <option value="#F0F7D9" />
                    <option value="#E4EEB5" />
                    <option value="#D6E693" />
                    <option value="#C9DE73" />
                    <option value="#BBD655" />
                    <option value="#B0CD38" />
                    <option value="#A4C520" />

                    <option value="#CDEFE0" />
                    <option value="#A0E0C3" />
                    <option value="#76D0A8" />
                    <option value="#53C18E" />
                    <option value="#33B178" />
                    <option value="#16A262" />
                    <option value="#009250" />

                    <option value="#D1ECF3" />
                    <option value="#A5D9E6" />
                    <option value="#7DC7DB" />
                    <option value="#58B5CF" />
                    <option value="#38A5C3" />
                    <option value="#1995B7" />
                    <option value="#0186AB" />

                    <option value="#E3E5F1" />
                    <option value="#C8CAE3" />
                    <option value="#B0B4D5" />
                    <option value="#999DC8" />
                    <option value="#8488BA" />
                    <option value="#6F75AC" />
                    <option value="#5D639E" />

                    <option value="#F0D5E9" />
                    <option value="#E0B0D3" />
                    <option value="#D18EBE" />
                    <option value="#C16FAA" />
                    <option value="#B25496" />
                    <option value="#A23C84" />
                    <option value="#932574" />

                    <option value={`custom-color-${sid}`} />
                  </select>
                )}
                {richEditorOptions.includes('align') && (
                  <select
                    className={`${styles.balloon} ql-align`}
                    data-balloon={t('Align')}
                  />
                )}
              </span>
            )}
            {hasBlockGroups && (
              <span className={styles.divider}>
                {richEditorOptions.includes('blockquote') && (
                  <button
                    className={`${styles.balloon} ql-blockquote`}
                    data-balloon={t('Blockquote')}
                  />
                )}
                {richEditorOptions.includes('codeBlock') && (
                  <button
                    className={`${styles.balloon} ql-code-block`}
                    data-balloon={t('CodeBlock')}
                  />
                )}
              </span>
            )}
            {hasListGroups && (
              <span className={styles.divider}>
                {richEditorOptions.includes('listOrdered') && (
                  <button
                    className={`${styles.balloon} ql-list`}
                    value="ordered"
                    data-balloon={t('ListOrdered')}
                  />
                )}
                {richEditorOptions.includes('listBullet') && (
                  <button
                    className={`${styles.balloon} ql-list`}
                    value="bullet"
                    data-balloon={t('ListBullet')}
                  />
                )}
                {richEditorOptions.includes('indentRemove') && (
                  <button
                    className={`${styles.balloon} ql-indent`}
                    value="-1"
                    data-balloon={t('IndentRemove')}
                  />
                )}
                {richEditorOptions.includes('indentAdd') && (
                  <button
                    className={`${styles.balloon} ql-indent`}
                    value="+1"
                    data-balloon={t('IndentAdd')}
                  />
                )}
              </span>
            )}
            {hasScriptGroups && (
              <span className={styles.divider}>
                {richEditorOptions.includes('scriptSub') && (
                  <button
                    className={`${styles.balloon} ql-script`}
                    value="sub"
                    data-balloon={t('ScriptSub')}
                  />
                )}
                {richEditorOptions.includes('scriptSuper') && (
                  <button
                    className={`${styles.balloon} ql-script`}
                    value="super"
                    data-balloon={t('ScriptSuper')}
                  />
                )}
              </span>
            )}
            {hasEmbedGroups && (
              <span className={styles.divider}>
                {richEditorOptions.includes('link') && (
                  <button
                    className={`${styles.balloon} ql-link`}
                    data-balloon={t('Link')}
                  />
                )}
                {richEditorOptions.includes('image') && (
                  <CheckRoles
                    permission="mediumPermission"
                    operation="readLevel"
                  >
                    <button
                      className={`${styles.balloon} ql-image`}
                      data-balloon={t('Image')}
                    />
                  </CheckRoles>
                )}
                {richEditorOptions.includes('oembedly') && (
                  <button
                    className={`${styles.balloon} ql-oembedly`}
                    data-balloon={t('Oembedly')}
                  />
                )}
              </span>
            )}
            {richEditorOptions.includes('clean') && (
              <span className={styles.divider}>
                {richEditorOptions.includes('clean') && (
                  <button
                    className={`${styles.balloon} ql-clean`}
                    data-balloon={t('Clear format')}
                  />
                )}
              </span>
            )}
            <span className={styles.last}>
              <a
                href="https://document.microcms.io/manual/how-to-use-richeditor"
                target="docs"
              >
                <button
                  className={`${styles.balloon} ${styles.button}`}
                  data-balloon={t('Help')}
                >
                  <i className="material-icons">help_outline</i>
                </button>
              </a>
              <button
                className={`${styles.balloon} ${styles.button}`}
                data-balloon={isActiveRichEditor ? t('Release') : t('Maximize')}
                onClick={
                  isActiveRichEditor ? closeRichEditor : spreadRichEditor
                }
              >
                {isActiveRichEditor ? (
                  <i className="material-icons-outlined">close_fullscreen</i>
                ) : (
                  <i className="material-icons-outlined">open_in_full</i>
                )}
              </button>
            </span>
          </div>
        </div>
        <div
          className={cx(styles.editorWrapper, {
            [styles.isActive]: isActiveRichEditor,
          })}
        >
          <div className={styles.bgEditor}>
            {uploadMediumProgress && (
              <div className={styles.uploading}>
                <progress
                  // @ts-expect-error
                  value={uploadMediumProgress.loaded}
                  // @ts-expect-error
                  max={uploadMediumProgress.total}
                  className={styles.progress}
                />
                <p>{t('Uploading now...')}</p>
              </div>
            )}
          </div>
          <div id={`editor-${sid}`} className={styles.editor} />
        </div>
        <Modal open={isOpenMediaModal} onOpenChange={toggleMediaModal}>
          <ModalContent size="large" hasSpace={false}>
            <Media onClick={selectFile} onlyImage inModal />
          </ModalContent>
        </Modal>
        <EmbedModal>
          <OldModal>
            <p className={styles.modalDescription}>
              {t('Enter embed URL.')}
              <br />
              {Trans({
                t,
                i18nKey: 'Click here for information on supported services.',
                children: (
                  <a
                    href="https://document.microcms.io/manual/how-to-use-richeditor#haf38f3d7f3"
                    target="docs"
                  >
                    Click here
                  </a>
                ),
              })}
            </p>
            <Textfield
              onChange={onChangeEmbedUrl}
              errorText={embedUrlError}
              placeholder={t('E.g., https://www.youtube.com/watch?v=xxxxxxxx')}
            />
            <Button
              type="secondary"
              className={styles.modalActionButton}
              value={t('Oembedly')}
              onClick={previewEmbed}
              disabled={embedUrlError}
            />
          </OldModal>
        </EmbedModal>
      </div>
    );
  },
);

RichEditor.displayName = 'RichEditor';

export default RichEditor;
