import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Member from '@/components/Member';

import { useUpdateCreatedBy } from './useUpdateCreatedBy';

import styles from './updatecreatedby.module.css';

import { MemberSelect } from '@/views/Common/member/MemberSelect';
import type { RowProps } from '@/views/Common/member/MemberSelect/SearchMember/SearchMember';
import { Icon } from '@/views/Common/Ui/Icon';
import { Modal, ModalContent, ModalTrigger } from '@/views/Common/Ui/Modal';

export type Props = {
  mediumId: string;
  mediumCreatedBy: string;
  serviceId: string;
};

export type ViewProps = {
  hasUpdatePermission: boolean;
  currentCreatedBy: string;
  isOpenCreatedByModal: boolean;
  toggleCreatedByModal: (nextValue?: boolean) => void;
  isOpenSearchMemberSelector: boolean;
  toggleSearchMemberSelector: (nextValue?: boolean) => void;
  members: RowProps[] | null;
  createdBy: string;
  onClickRow: (username: string) => void;
  onClickSaveButton: () => void;
  disabledSaveButton: boolean;
};

export const UpdateCreatedByView: React.FC<ViewProps> = ({
  hasUpdatePermission,
  currentCreatedBy,
  isOpenCreatedByModal,
  toggleCreatedByModal,
  isOpenSearchMemberSelector,
  toggleSearchMemberSelector,
  members,
  createdBy,
  onClickRow,
  onClickSaveButton,
  disabledSaveButton,
}) => {
  const { t } = useTranslation('mediaJsonUpdateCreatedBy');

  if (!hasUpdatePermission) {
    return <Member username={currentCreatedBy} nameOnly />;
  }

  return (
    <Modal open={isOpenCreatedByModal} onOpenChange={toggleCreatedByModal}>
      <ModalTrigger
        className={styles.openButton}
        data-testid="media-json-media-update-created-by"
      >
        <span className={styles.openButtonText}>
          <Member username={currentCreatedBy} nameOnly />
        </span>
        <Icon name="edit" />
      </ModalTrigger>
      <ModalContent
        size="small"
        title={t('Change Content Creator')}
        description={Trans({
          i18nKey:
            'This will change the user created this media to user in Service. If the desired user is not listed, check Roles.',
          t,
          children: (
            <Link to="/roles" className={styles.descriptionLink}>
              Roles
            </Link>
          ),
        })}
        footer={{
          submitButtonProps: {
            type: 'primary',
            value: t('Save changes'),
            onClick: onClickSaveButton,
            disabled: disabledSaveButton,
          },
        }}
      >
        <MemberSelect
          showMemberSelector={isOpenSearchMemberSelector}
          reverseShowMemberSelector={toggleSearchMemberSelector}
          selectedMemberId={createdBy}
          members={members}
          onClickRow={onClickRow}
        />
      </ModalContent>
    </Modal>
  );
};

export const UpdateCreatedBy: React.FC<Props> = (props) => {
  const hooks = useUpdateCreatedBy(props);

  return <UpdateCreatedByView {...hooks} />;
};
