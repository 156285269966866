import { Helmet } from 'react-helmet-async';

import i18n from '../../i18n';

type Props = {
  title: string;
};

const Head: React.FC<Props> = ({ title }) => {
  return (
    <Helmet htmlAttributes={{ lang: i18n.language }}>
      <title>{title} - microCMS</title>
    </Helmet>
  );
};

export default Head;
