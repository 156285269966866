import cx from 'classnames';

import { useEmbedPreview } from './useEmbedPreview';

import type { OptionsField } from '@/entity/iframely';

import styles from './embedPreview.module.css';

import { REACT_APP_IFRAMELY_CLIENT_API_KEY } from '@/constants/env';

export type Props = {
  html: string;
  url: string;
  options?: Record<string, OptionsField>;
};

export type ViewProps = {
  containerRef: React.RefObject<HTMLDivElement>;
  html: string;
  url: string;
  optionQuery: string;
  isScript: boolean;
  isTwitter: boolean;
  isSmallCard: boolean;
};

export const EmbedPreviewView: React.FC<ViewProps> = ({
  containerRef,
  html,
  url,
  optionQuery,
  isScript,
  isSmallCard,
  isTwitter,
}) => {
  if (isTwitter) {
    return (
      <div ref={containerRef} className={styles.main}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    );
  }

  if (isScript) {
    return (
      <iframe
        className={cx(styles.iframe, {
          [styles.isSmallCard]: isSmallCard,
        })}
        src={`//cdn.iframe.ly/api/iframe?app=1&url=${url}${optionQuery}&key=${
          REACT_APP_IFRAMELY_CLIENT_API_KEY || ''
        }`}
        allowFullScreen={true}
      ></iframe>
    );
  }

  return <div dangerouslySetInnerHTML={{ __html: html ?? '' }} />;
};

export const EmbedPreview: React.FC<Props> = ({ html, url, options }) => {
  const { containerRef, isScript, isTwitter, isSmallCard, optionQuery } =
    useEmbedPreview({
      html,
      url,
      options,
    });

  return (
    <EmbedPreviewView
      containerRef={containerRef}
      html={html}
      url={url}
      isScript={isScript}
      isTwitter={isTwitter}
      isSmallCard={isSmallCard}
      optionQuery={optionQuery}
    />
  );
};
