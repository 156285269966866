import * as cheerio from 'cheerio';
import { useMemo, useState } from 'react';

import { useI18nContentsComparison } from '../../useI18nContentsComparison';
import { PlainText } from '../comparator/PlainText';
import { RichText } from '../comparator/RichText';
import { ModeChangeButtons } from '../parts/ModeChangeButtons';

type Props = {
  sinceValue: string;
  untilValue: string;
};

const blockElementNames = [
  'blockquote',
  'div',
  'figure',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'hr',
  'iframe',
  'ul',
  'ol',
  'p',
  'pre',
  'table',
];
const parseHtmlAndSplitBlock = (html: string, mode: string) => {
  const $ = cheerio.load(html);
  const blocks: string[] = [];

  const blockElementNamesQuery = blockElementNames.join(',');

  $(blockElementNamesQuery).each((_, element) => {
    // ネストされたブロック要素は無視して最上位のブロック要素のみを追加する
    // たとえば、<ul>要素の中に<ul>要素がある場合、外側の<ul>要素のみを追加する

    // 前処理
    if (!$(element).parents(blockElementNamesQuery).length) {
      // 1. pre要素の中のcode要素内の改行をエスケープする
      // これはdiffLines関数で改行をトークンとして扱うため
      let children: ReturnType<
        ReturnType<cheerio.CheerioAPI>['children']
      > | null = null;
      if (
        // 旧リッチエディタのpre要素 pre > code
        element.type === 'tag' &&
        element.name === 'pre'
      ) {
        children = $(element)
          .children()
          .filter((_, child) => child.type === 'tag' && child.name === 'code');
      } else if (
        // 新リッチエディタのpre要素 div > pre > code
        element.type === 'tag' &&
        element.name === 'div' &&
        element.children[0].type === 'tag' &&
        element.children[0].name === 'pre'
      ) {
        children = $(element)
          .children()
          .filter((_, child) => child.type === 'tag' && child.name === 'pre')
          .children()
          .filter((_, child) => child.type === 'tag' && child.name === 'code');
      }

      if (children !== null) {
        for (const child of children) {
          const childHtml = $(child).html();
          if (childHtml != null) {
            $(child).html(
              childHtml.replace(/\n/g, mode === 'richText' ? '<br>' : '\\n'),
            );
          }
        }
      }
      // 1. ここまで

      // 2. modeがリッチテキストの場合のみ、table要素をdiv要素で囲む(スタイリングのため)
      if (
        mode === 'richText' &&
        element.type === 'tag' &&
        element.name === 'table'
      ) {
        const div = $('<div></div>');
        const appended = div.append($.html(element)).get(0);
        if (appended) {
          element = appended;
        }
      }
      // 2. ここまで

      blocks.push($.html(element));
    }
  });

  return blocks.join('\n');
};

const RichEditor: React.FC<Props> = ({ sinceValue, untilValue }) => {
  const { t } = useI18nContentsComparison();

  const [mode, setMode] = useState<'richText' | 'html'>('richText');

  const splitSinceValue = useMemo(
    () => parseHtmlAndSplitBlock(sinceValue, mode),
    [mode, sinceValue],
  );
  const splitUntilValue = useMemo(
    () => parseHtmlAndSplitBlock(untilValue, mode),
    [mode, untilValue],
  );

  const [expandedStartLineNumbers, setExpandedStartLineNumbers] = useState<
    number[]
  >([]);

  const omittable = useMemo(() => {
    return {
      enabled: true,
      expandedStartLineNumbers,
      setExpandedStartLineNumbers,
    };
  }, [expandedStartLineNumbers]);

  return (
    <div>
      <ModeChangeButtons
        currentMode={mode}
        modeList={[
          { key: 'richText', label: t('Rich Text') },
          { key: 'html', label: t('HTML') },
        ]}
        setMode={setMode}
      />
      {mode === 'richText' ? (
        <RichText
          sinceValue={splitSinceValue}
          untilValue={splitUntilValue}
          omittable={omittable}
        />
      ) : mode === 'html' ? (
        <PlainText
          sinceValue={splitSinceValue}
          untilValue={splitUntilValue}
          omittable={omittable}
          diffType="char"
        />
      ) : null}
    </div>
  );
};

export { RichEditor, parseHtmlAndSplitBlock };
