import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';

import Head from '../Head';
import Button from '../ui/Button';

import styles from './letsGo.module.css';

import { getHost } from '@/util';

const LetsGo: React.FC = () => {
  const { t } = useTranslation('letsGo');
  const { domain } = useParams<{ domain: string }>();
  const createdUrl = getHost(domain);

  if (createdUrl === undefined) {
    return <Redirect to="/" />;
  }

  return (
    <div className={styles.wrapper}>
      <Head title={t('Service created')} />
      <div className={styles.container}>
        <div className={styles.image}>
          <img src="/images/cracker.gif" alt="" />
        </div>
        <h2 className={styles.title}>{t('A new service has been created.')}</h2>
        <Button
          type="primary"
          size="large"
          onClick={() => (window.location.href = createdUrl)}
          value={t('Access the service')}
        />
      </div>
    </div>
  );
};

export default LetsGo;
