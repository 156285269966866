import { useTranslation } from 'react-i18next';

import styles from './ipDenied.module.css';

type Props = {
  allowAccess: boolean;
  children: React.ReactElement;
};

const IpDenied: React.FC<Props> = ({ allowAccess, children }) => {
  const { t } = useTranslation('ipDenied');
  if (allowAccess === false) {
    return (
      <div className={styles.wrapper}>
        <div>
          <h1 className={styles.title}>{t('Invalid IP address')}</h1>
          <div className={styles.description}>
            <p>{t('Contact your system administrator for details.')}</p>
          </div>
        </div>
      </div>
    );
  }
  return children;
};

export default IpDenied;
