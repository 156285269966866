import cx from 'classnames';
import { forwardRef } from 'react';

import styles from './iconWithTextButton.module.css';

import { Icon } from '@/views/Common/Ui/Icon';

type Props = {
  className?: string;
  icon: string;
  text: string;
  outlined?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  selected?: boolean;
};

const IconWithText = forwardRef<HTMLButtonElement, Props>(
  (
    {
      className,
      icon,
      text,
      outlined = false,
      onClick,
      disabled = false,
      selected = false,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        className={cx(styles.button, className, {
          [styles.selected]: selected,
        })}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
        {...props}
      >
        <Icon name={icon} outlined={outlined} />
        {text}
      </button>
    );
  },
);
IconWithText.displayName = 'IconWithText';

export default IconWithText;
