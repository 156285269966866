import { useState, useCallback } from 'react';

import styles from './expander.module.css';

const Expander = ({ title, children, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);
  return (
    <div>
      <div className={styles.expander} onClick={toggle}>
        {title}
        <i className={`material-icons ${isOpen ? styles.isOpen : ''}`}>
          expand_more
        </i>
      </div>
      {isOpen && children}
    </div>
  );
};

export default Expander;
