import { serviceWriterRepository } from '@/repository/serviceWriterRepository';

export const putService = async ({
  partitionKey,
  serviceName,
  serviceImage,
}: {
  partitionKey: string;
  serviceName: string;
  serviceImage: object;
}) => {
  const { putService } = serviceWriterRepository();
  return await putService({
    partitionKey,
    serviceName,
    serviceImage,
  });
};

export const putServiceId = async ({
  partitionKey,
  domain,
}: {
  partitionKey: string;
  domain: string;
}) => {
  const { putServiceId } = serviceWriterRepository();
  return await putServiceId({ partitionKey, domain });
};
