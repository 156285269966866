import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';

import { UpdateEnvironmentNameModal } from '../UpdateEnvironmentNameModal';
import { useEnvironmentsSettingsTooltip } from './useEnvironmentsSettingsTooltip';

import styles from './environmentssettingstooltip.module.css';

import {
  AlertDialog,
  useAlertDialogTrigger,
} from '@/views/Common/Ui/AlertDialog';
import { Icon } from '@/views/Common/Ui/Icon';

export type Props = {
  envServiceId: string;
  name: string;
  onDeleteEnvironment: (serviceId: string) => void;
  deleteEnvironmentLoading: boolean;
};

export type ViewProps = Props & {
  isAdmin: boolean;
  isDeleteEnvironmentRole: boolean;
};

export const EnvironmentsSettingsTooltipView: React.FC<ViewProps> = ({
  envServiceId,
  name,
  isAdmin,
  isDeleteEnvironmentRole,
  onDeleteEnvironment,
  deleteEnvironmentLoading,
}) => {
  const deletionAlertDialog = useAlertDialogTrigger();
  const { t } = useTranslation('environmentsSettingsTooltip');
  const [UpdateEnvNameModal, openUpdateEnvNameModal, closeEnvNameModal] =
    useModal('root');

  // メニュー項目にある操作の権限を持たない場合は非表示
  if (!isAdmin && !isDeleteEnvironmentRole) {
    return null;
  }

  return (
    <>
      <DropdownMenu.Root modal={false}>
        <DropdownMenu.Trigger
          className={styles.trigger}
          aria-label={t('Open Menu')}
        >
          <Icon
            name="more_vert"
            className={styles.triggerIcon}
            title={t('Open Menu')}
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            side="bottom"
            align="end"
            className={styles.container}
          >
            <DropdownMenu.Group className={styles.group}>
              {isAdmin && (
                <DropdownMenu.Item>
                  <button
                    type="button"
                    className={styles.itemButton}
                    onClick={() => openUpdateEnvNameModal()}
                  >
                    <Icon name="edit" />
                    {t('Edit Environment')}
                  </button>
                </DropdownMenu.Item>
              )}
              {(isAdmin || isDeleteEnvironmentRole) && (
                <DropdownMenu.Item>
                  <button
                    type="button"
                    className={styles.itemButton}
                    disabled={deleteEnvironmentLoading}
                    onClick={() => deletionAlertDialog.openAlertDialog()}
                  >
                    <Icon name="clear" />
                    {t('Delete Environment')}
                  </button>
                </DropdownMenu.Item>
              )}
            </DropdownMenu.Group>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      {/*
       * 環境を削除する前に表示するダイアログ
       * NOTE: AlertDialogをtrigger込みでDropdownMenu.Item内に配置するケースで挙動に問題があるため、外部からダイアログの開閉を制御する方法を採る
       */}
      <AlertDialog
        open={deletionAlertDialog.isOpenAlertDialog}
        trigger={null}
        title={t('#Delete Environment')}
        description={t(
          'Are you sure you want to delete an environment? To perform the deletion, enter ({{environmentName}}) in the form. in the form. Deleted environments cannot be restored.',
          {
            environmentName: name,
          },
        )}
        buttonText={t('Delete')}
        onSubmit={() => {
          onDeleteEnvironment(envServiceId);
          deletionAlertDialog.closeAlertDialog();
        }}
        onCancel={() => deletionAlertDialog.closeAlertDialog()}
        confirmText={name}
      />

      {/* 環境を削除する前に表示するモーダル */}
      <UpdateEnvNameModal>
        <UpdateEnvironmentNameModal
          serviceId={envServiceId}
          name={name}
          closeEnvNameModal={closeEnvNameModal}
        />
      </UpdateEnvNameModal>
    </>
  );
};

export const EnvironmentsSettingsTooltip: React.FC<Props> = ({
  envServiceId,
  name,
  onDeleteEnvironment,
  deleteEnvironmentLoading,
}) => {
  const { isAdmin, isDeleteEnvironmentRole } = useEnvironmentsSettingsTooltip();
  return (
    <EnvironmentsSettingsTooltipView
      envServiceId={envServiceId}
      name={name}
      isAdmin={isAdmin}
      isDeleteEnvironmentRole={isDeleteEnvironmentRole}
      onDeleteEnvironment={onDeleteEnvironment}
      deleteEnvironmentLoading={deleteEnvironmentLoading}
    />
  );
};
