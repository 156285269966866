import { useMemo } from 'react';

import { Table, Tbody, Th, Thead, Tr } from '@/components/ui/Table';

import NoDisplayPermissions from './NoDisplayPermissions';
import FieldApi from './partials/FieldApi';
import FieldContent from './partials/FieldContent';
import FieldDevMenu from './partials/FieldDevMenu';
import FieldReviewRequest from './partials/FieldReviewRequest';
import RemoveButton from './partials/RemoveButton';
import SelectApiSchema from './partials/SelectApiSchema';
import { ExceptionContentPermissionAllCheckButton } from '../../AllCheckButton';
import { useI18nFormRole } from '../../i18n';
import { useFormRoleState } from '../../provider';

import type { PropsWithECP } from './_types';
import type { InputExceptionContentPermission } from '@/entity/role';
import type { FC } from 'react';

import styles from './FieldExceptionContent.module.css';

import { hasGrantedViewExceptionContentPermission } from '@/util/role';
import { Fieldset } from '@/views/Common/Ui/Fieldset';

const FieldExceptionContent: FC<{
  target: { id: InputExceptionContentPermission['id']; indexOf: number };
}> = ({ target }) => {
  const {
    input: { exceptionContentPermissions },
  } = useFormRoleState();

  const targetECP = useMemo(() => {
    return exceptionContentPermissions[target.indexOf];
  }, [exceptionContentPermissions, target.indexOf]);

  return (
    <div className={styles.block}>
      {hasGrantedViewExceptionContentPermission(targetECP) ? (
        /**
         * 対象のAPIスキーマに対して、閲覧者が権限を持つ場合のコンテンツ
         *
         * コンポーネントの出し分けの条件より、targetECP.permission と targetECP.reviewRequestPermission は値を持つことが保証される
         */
        <Main targetECP={targetECP} />
      ) : (
        /**
         * 対象のAPIスキーマに対して、閲覧者が権限を持たない場合のコンテンツ
         */
        <NoDisplayPermissions />
      )}
    </div>
  );
};

const Main: FC<PropsWithECP> = ({ targetECP }) => {
  const { t } = useI18nFormRole();

  const { isAdminRole } = useFormRoleState();

  return (
    <div data-testid="exceptionContentPermissionMain">
      {!isAdminRole && (
        <div className={styles.removeButtonContainer}>
          <RemoveButton ecpId={targetECP.id} />
        </div>
      )}

      <Fieldset
        label={t('Target API')}
        description={t(
          'Select an API which you want to customize permissions.',
        )}
        className={styles.fieldSet}
      >
        <SelectApiSchema targetECP={targetECP} />
      </Fieldset>

      <Fieldset label={t('Permission')} className={styles.fieldSet}>
        <div className={styles.tableWrap}>
          <ExceptionContentPermissionAllCheckButton targetECP={targetECP} />

          <Table>
            <Thead>
              <Tr enableHoverStyle={false}>
                <Th />
                <Th>{t('Read')}</Th>
                <Th>{t('Create')}</Th>
                <Th>{t('Update')}</Th>
                <Th>{t('Delete')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr enableHoverStyle={false}>
                <FieldApi targetECP={targetECP} />
              </Tr>
              <Tr enableHoverStyle={false}>
                <FieldContent targetECP={targetECP} />
              </Tr>
              <Tr enableHoverStyle={false}>
                <FieldDevMenu targetECP={targetECP} />
              </Tr>
              <Tr enableHoverStyle={false}>
                <FieldReviewRequest targetECP={targetECP} />
              </Tr>
            </Tbody>
          </Table>
        </div>
      </Fieldset>
    </div>
  );
};

export default FieldExceptionContent;
