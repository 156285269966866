import type { MemberReadPermissionDetail } from '@/entity/role';

/**
 * メンバーの読み取り権限があるかどうかを判定する
 *
 * メンバーの読み取り権限があるかどうかは、以下の条件を満たす場合にtrueを返す
 * - デフォルトで読み取り権限がある場合
 * - 任意のメンバーについて、読み取り権限がある場合
 * - 同じロールのメンバーについて、読み取り権限がある場合
 */
function hasMemberReadPermission(
  readPermission: MemberReadPermissionDetail,
): boolean {
  return (
    readPermission.defaultRead === true ||
    // 任意のメンバーについて
    (readPermission.defaultRead === false &&
      readPermission.allowMemberList &&
      readPermission.allowMemberList.length !== 0) ||
    // 同じロールのメンバーについて
    (readPermission.defaultRead === false && !!readPermission.sameRole)
  );
}

export { hasMemberReadPermission };
