import { Td } from '@/components/ui/Table';

import Read from './Read';
import { useI18nFormRole } from '../../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../../provider';

import { Checkbox } from '@/views/Common/Ui/Checkbox';

const FieldMember = () => {
  const { t } = useI18nFormRole();

  const {
    isAdminRole,
    input: { memberPermission },
  } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  return (
    <>
      <Td>{t('Members')}</Td>
      <Td>
        <Read />
      </Td>
      <Td>
        <Checkbox
          checked={memberPermission.create}
          disabled={disabled}
          name="memberPermission.create"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-member-permission-create',
              payload,
            });
          }}
          label={t('Invite members')}
        />
      </Td>
      <Td>
        <Checkbox
          checked={memberPermission.update}
          disabled={disabled}
          name="memberPermission.update"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-member-permission-update',
              payload,
            });
          }}
          label={t('Linked to members\nupdate roles')}
        />
      </Td>
      <Td>
        <Checkbox
          checked={memberPermission.delete}
          disabled={disabled}
          name="memberPermission.delete"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-member-permission-delete',
              payload,
            });
          }}
          label={t('Delete members')}
        />
      </Td>
    </>
  );
};

export default FieldMember;
