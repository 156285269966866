import { nanoid } from 'nanoid';
import type React from 'react';
import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Row from './Row';
import Button from '../ui/Button';

import type { Filter } from '../Media/useMediaFilter';
import type { ListMediumTags } from '@/types/MediumTag';

import styles from './mediaFilter.module.css';

type Props = {
  serviceId: string;
  defaultFiltersArray: Filter[];
  onSubmitFiltersArray: (filtersArray: Filter[]) => void;
  close: () => void;
  listMediumTags?: ListMediumTags;
};

const MediaFilter: React.FC<Props> = ({
  serviceId,
  defaultFiltersArray,
  onSubmitFiltersArray,
  close,
  listMediumTags,
}) => {
  const { t } = useTranslation('mediaFilter');
  const history = useHistory();
  const [filtersArray, setFiltersArray] =
    useState<Filter[]>(defaultFiltersArray);

  const isEmptyValue = useMemo(() => {
    return filtersArray.find((arr) => arr.value === '') !== undefined;
  }, [filtersArray]);

  const addFilter = useCallback(() => {
    setFiltersArray([
      ...filtersArray,
      {
        id: nanoid(),
        key: 'format',
        condition: '[equals]',
        value: 'image',
      },
    ]);
  }, [filtersArray, setFiltersArray]);

  const removeFilter = useCallback(
    (index: string) => {
      const newFiltersArray = filtersArray.filter(
        (filter) => filter.id !== index,
      );
      setFiltersArray(newFiltersArray);
    },
    [filtersArray, setFiltersArray],
  );

  const resetFilters = useCallback(() => {
    setFiltersArray([]);
    history.replace({
      search: '',
    });
    close();
  }, [close, history, setFiltersArray]);

  return (
    <div>
      <div className={styles.rows}>
        {filtersArray.map((filter) => (
          <Row
            key={filter.id}
            serviceId={serviceId}
            filter={filter}
            filtersArray={filtersArray}
            setFiltersArray={setFiltersArray}
            removeFilter={removeFilter}
            listMediumTags={listMediumTags}
          />
        ))}
      </div>
      <Button
        type="tertiary"
        size="full"
        value={t('Add')}
        onClick={addFilter}
      />
      <div className={styles.actions}>
        <Button
          type="secondary"
          value={t('Apply')}
          onClick={() => {
            onSubmitFiltersArray(filtersArray);
            close();
          }}
          disabled={isEmptyValue}
          className="track-click-by-gtm"
          data-gtm-track-event-name="click_apply_media_filter_button"
        />
        <button onClick={resetFilters} className={styles.textButton}>
          {t('Reset')}
        </button>
      </div>
    </div>
  );
};

export default MediaFilter;
