import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import style from './reservationtrigger.module.css';

type Props = {
  contentReservable: boolean;
  openReservationModal: () => void;
};

export type ViewProps = Props;

export const ReservationTriggerView: React.FC<ViewProps> = ({
  contentReservable,
  openReservationModal,
}) => {
  const { t } = useTranslation('reservationTrigger');
  return (
    <button
      className={cx(style.tooltipButton, !contentReservable && style.disabled)}
      onClick={
        contentReservable ? openReservationModal : (e) => e.stopPropagation()
      }
    >
      <span className="material-icons" aria-hidden>
        schedule
      </span>
      <dl>
        <dt className={style.tooltipListTitle}>{t('Schedule')}</dt>
        <dd className={style.tooltipListDescription}>
          {t('Schedule publishing or unpublishing this content')}
        </dd>
      </dl>
    </button>
  );
};

export const ReservationTrigger: React.FC<Props> = ({
  contentReservable,
  openReservationModal,
}) => {
  return (
    <ReservationTriggerView
      contentReservable={contentReservable}
      openReservationModal={openReservationModal}
    />
  );
};
