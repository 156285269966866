import { findIndexECPs } from '../../../../helpers/findIndexExceptionContentPermissions';

import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from '../../_types';
import type { EditableInputExceptionContentPermission } from '@/entity/role';

import { produce } from '@/lib/immer';

type SetECPermissionApiReadAction = FormRoleInputValueAction<
  'set-ec-permission-apiread',
  {
    id: string;
    nextValue: EditableInputExceptionContentPermission['permission']['apiRead'];
  }
>;
type SetECPermissionApiUpdateAction = FormRoleInputValueAction<
  'set-ec-permission-apiupdate',
  {
    id: EditableInputExceptionContentPermission['id'];
    nextValue: EditableInputExceptionContentPermission['permission']['apiUpdate'];
  }
>;
type SetECPermissionApiDeleteAction = FormRoleInputValueAction<
  'set-ec-permission-apidelete',
  {
    id: EditableInputExceptionContentPermission['id'];
    nextValue: EditableInputExceptionContentPermission['permission']['apiDelete'];
  }
>;
/**
 * 個別権限の指定したIDの要素にあるpermission.apiReadを更新する
 */
const setECPermissionApiRead: FormRoleInputValueDispatchFn<
  SetECPermissionApiReadAction
> = (currentState, { id, nextValue }) => {
  return produce(currentState, (draft) => {
    const index = findIndexECPs(draft.exceptionContentPermissions, id);
    if (index >= 0) {
      const perm = draft.exceptionContentPermissions[index].permission;
      if (perm) perm.apiRead = nextValue;
    }
  });
};

/**
 * 個別権限の指定したIDの要素にあるpermission.apiUpdateを更新する
 */
const setECPermissionApiUpdate: FormRoleInputValueDispatchFn<
  SetECPermissionApiUpdateAction
> = (currentState, { id, nextValue }) => {
  return produce(currentState, (draft) => {
    const index = findIndexECPs(draft.exceptionContentPermissions, id);
    if (index >= 0) {
      const perm = draft.exceptionContentPermissions[index].permission;
      if (perm) perm.apiUpdate = nextValue;
    }
  });
};

/**
 * 個別権限の指定したIDの要素にあるpermission.apiDeleteを更新する
 */
const setECPermissionApiDelete: FormRoleInputValueDispatchFn<
  SetECPermissionApiDeleteAction
> = (currentState, { id, nextValue }) => {
  return produce(currentState, (draft) => {
    const index = findIndexECPs(draft.exceptionContentPermissions, id);
    if (index >= 0) {
      const perm = draft.exceptionContentPermissions[index].permission;
      if (perm) perm.apiDelete = nextValue;
    }
  });
};

export {
  type SetECPermissionApiReadAction,
  type SetECPermissionApiUpdateAction,
  type SetECPermissionApiDeleteAction,
  setECPermissionApiRead,
  setECPermissionApiUpdate,
  setECPermissionApiDelete,
};
