import { useMemo, useState } from 'react';

import { usePermissionIsHaveLeastOne } from '@/hooks/Role/useMyRoles';

import type { Props, ViewProps } from './UpdateCreatedBy';

import { useUpdateMediumCreatedBy } from '@/views/Common/medium/useMediumWriter';
import { buildRowValues } from '@/views/Common/member/MemberSelect/SearchMember/SearchMember';
import { useAllMembers } from '@/views/Common/member/useMemberReader';
import { useAllRoles } from '@/views/Common/Roles/useRolesReader';
import { useToggle } from '@/views/Common/Ui/useToggle';

export const useUpdateCreatedBy = ({
  mediumId,
  mediumCreatedBy,
  serviceId,
}: Props): ViewProps => {
  // 権限
  const [hasUpdatePermission] = usePermissionIsHaveLeastOne(
    'mediumPermission',
    'update',
  );

  // state
  const [createdBy, setCreatedBy] = useState(mediumCreatedBy);
  const [isOpenCreatedByModal, toggleCreatedByModal] = useToggle(false);
  const [isOpenSearchMemberSelector, toggleSearchMemberSelector] =
    useToggle(false);

  // クエリとそこから計算される表示用のメンバー情報
  const { data: allMembers } = useAllMembers(serviceId);
  const { data: allRoles } = useAllRoles(serviceId);
  const rowValues = useMemo(
    () => buildRowValues(allMembers, allRoles),
    [allMembers, allRoles],
  );

  // mutation
  const { updateMediumCreatedBy, isLoadingUpdateMediumCreatedBy } =
    useUpdateMediumCreatedBy({
      serviceId,
      mediumId,
      onSettled: () => toggleCreatedByModal(false),
    });

  // handler
  const onClickRow = (username: string) => {
    setCreatedBy(username);
    toggleSearchMemberSelector(false);
  };
  const onClickSaveButton = () => {
    updateMediumCreatedBy(createdBy);
  };

  return {
    hasUpdatePermission: hasUpdatePermission ?? false,
    currentCreatedBy: mediumCreatedBy,
    isOpenCreatedByModal,
    toggleCreatedByModal,
    isOpenSearchMemberSelector,
    toggleSearchMemberSelector,
    members: rowValues,
    createdBy,
    onClickRow,
    onClickSaveButton,
    disabledSaveButton:
      isLoadingUpdateMediumCreatedBy || createdBy === mediumCreatedBy,
  };
};
