import { connect } from 'react-redux';

import { authSelectors, authOperations } from '../../../ducks/auth';
import Password from './Password';

const mapStateToProps = ({ authState }: any) => {
  return authSelectors.getChangePasswordState(authState);
};

const mapDispatchToProps = {
  changePassword: authOperations.changePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
