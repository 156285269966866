import cx from 'classnames';
import { SketchPicker } from 'react-color';
import type { Color, RGBColor } from 'react-color';

import type { RichEditorV2Color } from '@/types/field';
import { colorToHexString, colorToRgb, colorToRgbString } from '@/util/color';

import { Icon } from '@/views/Common/Ui/Icon';
import { useTranslation } from 'react-i18next';

import styles from './colorPicker.module.css';

type Props = {
  color?: Color;
  isSetColor: boolean;
  presets?: RichEditorV2Color[];
  setColorHandler: (color: RGBColor) => void;
  unsetColorHandler: () => void;
};

export const ColorPicker: React.FC<Props> = ({
  color,
  isSetColor,
  presets,
  setColorHandler,
  unsetColorHandler,
}) => {
  const { t } = useTranslation('richEditorV2');

  return (
    <div className={styles.main}>
      <SketchPicker
        className={styles.colorPicker}
        color={color}
        onChange={(color) => setColorHandler(color.rgb)}
        width={'100%'}
        styles={{
          default: {
            picker: {
              boxShadow: 'none',
              padding: '0',
            },
            saturation: {
              borderRadius: '4px',
            },
          },
        }}
        presetColors={[]}
      />
      {presets && presets.length !== 0 && (
        <div className={styles.colorPreset}>
          {presets.map((preset) => (
            <div key={preset.id} className={styles.colorPresetButton}>
              <button
                type="button"
                aria-label={t('{{colorValue}} color presets', {
                  colorValue: colorToHexString(preset.value),
                })}
                style={{ backgroundColor: preset.value }}
                onClick={() => setColorHandler(colorToRgb(preset.value))}
                className={cx(styles.colorItem, {
                  [styles.isActiveColor]:
                    color && preset.value === colorToRgbString(color),
                })}
              />
            </div>
          ))}
        </div>
      )}
      {isSetColor && (
        <div className={styles.clearButtonContainer}>
          <button
            type="button"
            className={styles.clearbutton}
            onClick={unsetColorHandler}
          >
            <Icon name="clear" outlined={true} />
            {t('Remove color')}
          </button>
        </div>
      )}
    </div>
  );
};
