import { useEffect, useState } from 'react';

export const useMatchMedia = (query: string, initialValue = false) => {
  const [isMatch, setIsMatch] = useState(initialValue);
  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const listener = (event: MediaQueryListEvent) => {
      setIsMatch(event.matches);
    };
    mediaQueryList.addEventListener('change', listener);

    setIsMatch(mediaQueryList.matches);

    return () => {
      mediaQueryList.removeEventListener('change', listener);
    };
  }, [query]);

  return isMatch;
};
