import { useState, useMemo, useCallback } from 'react';

import { getAllRoles } from '@/usecase/rolesUseCase';

import type { Role, RoleOrderQuery } from '@/entity/role';

import { useApi } from '@/lib/useApi';
import { searchRolesByName, sortRoles } from '@/util/role';

export const useAllRoles = (serviceId?: string | null) => {
  return useApi(
    ['roleList'],
    async () => {
      if (!serviceId) {
        return;
      }

      return await getAllRoles(serviceId);
    },
    {
      staleTime: Number.POSITIVE_INFINITY,
      enabled: !!serviceId,
    },
  );
};

// サービスに紐づくロールの情報から、検索やソートなどの加工処理を行う
export const useFilteredRoles = (roles?: Role[], order?: RoleOrderQuery) => {
  const [filteredRoles, setFilteredRoles] = useState<Role[] | undefined>();
  const searchRoles = useCallback(
    (value: string) => {
      if (!roles || !value) {
        setFilteredRoles(undefined);
        return;
      }
      return setFilteredRoles(searchRolesByName(roles, value));
    },
    [roles],
  );

  const sortedRoles = useMemo(() => {
    const rolesToSort = filteredRoles || roles;
    return sortRoles(rolesToSort, order);
  }, [filteredRoles, roles, order]);

  return { filteredRoles, sortedRoles, searchRoles };
};
