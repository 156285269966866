import { useTranslation } from 'react-i18next';

import Media from '@/components/Media';

import { useImageBlock } from './useImageBlock';
import { IconButton } from '../../../IconButton';
import styles from '../../../richeditorv2.module.css';

import type { Medium } from '@/entity/medium';
import type { Editor } from '@tiptap/react';

import { ImageBlockIcon } from '@/views/Common/Icons';
import { Modal, ModalContent, ModalTrigger } from '@/views/Common/Ui/Modal';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  isOpenMediaModal: boolean;
  toggleMediaModal: (nextValue?: boolean) => void;
  setImageBlockHandler: (medium: Medium) => () => Promise<void>;
  editable: boolean;
};

export const ImageBlockView: React.FC<ViewProps> = ({
  isOpenMediaModal,
  toggleMediaModal,
  setImageBlockHandler,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <Modal open={isOpenMediaModal} onOpenChange={toggleMediaModal}>
      <ModalTrigger asChild>
        <IconButton
          icon={<ImageBlockIcon className={styles.icon} />}
          aria-label={t('Image')}
          isActive={false}
          onClick={() => toggleMediaModal(true)}
          disabled={!editable}
        />
      </ModalTrigger>
      <ModalContent
        size="large"
        hasSpace={false}
        // 画像を挿入したあとはカーソルがあった位置にフォーカスさせるためにモーダルが閉じた時のフォーカス管理はしない
        onCloseAutoFocus={(event) => event.preventDefault()}
        onEscapeKeyDown={(e) => {
          e.stopPropagation();
        }}
      >
        <Media onClick={setImageBlockHandler} onlyImage inModal />
      </ModalContent>
    </Modal>
  );
};

export const ImageBlock: React.FC<Props> = ({ editor }) => {
  const { isOpenMediaModal, toggleMediaModal, setImageBlockHandler } =
    useImageBlock({
      editor,
    });

  return (
    <ImageBlockView
      isOpenMediaModal={isOpenMediaModal}
      toggleMediaModal={toggleMediaModal}
      setImageBlockHandler={setImageBlockHandler}
      editable={editor.isEditable}
    />
  );
};
