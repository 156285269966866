import { useHistory } from 'react-router-dom';

import type { ReviewRequest } from '@/entity/review';

const useMainTabs = ({
  reviewStatus,
}: {
  reviewStatus: ReviewRequest['status'];
}) => {
  const history = useHistory();
  const handleTabChange = (value: string) => {
    history.replace(`?tab=${value}`);
  };
  const tab =
    reviewStatus === 'OPEN'
      ? new URLSearchParams(history.location.search).get('tab') ?? 'review'
      : 'review';

  return { tab, handleTabChange };
};

export { useMainTabs };
