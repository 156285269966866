import { useQueries } from '@tanstack/react-query';

import { useContentHistories } from '@/components/ContentHistories/useContentHistories';
import { useApi } from '@/lib/useApi';
import {
  ensureContentObjectExists,
  findContentByVersion,
  searchContents,
} from '@/usecase/contentUsecase';

import { contentQueryKeys } from './queryKeys';

export const useTotalCount = (apiId?: string) => {
  const { data, ...useApiReturn } = useApi(
    ['contentList', apiId],
    async () => {
      if (!apiId) {
        return null;
      }
      return searchContents(apiId, {
        size: 0,
      });
    },
    {
      enabled: !!apiId,
    },
  );

  return {
    ...useApiReturn,
    data: data?.totalCount,
  };
};

export const useContentsByVersions = (
  contentId?: string | null | undefined,
  queryKeys?:
    | ({ version: number; denyDraft?: boolean } | null)[]
    | null
    | undefined,
) => {
  return useQueries({
    queries:
      queryKeys?.map((key) => ({
        queryKey: contentQueryKeys.version(
          contentId ?? undefined,
          key?.version ?? undefined,
          key?.denyDraft,
        ),
        queryFn: async () => {
          if (!contentId || !key?.version) {
            return;
          }

          return findContentByVersion({
            contentId,
            version: key.version,
            denyDraft: key.denyDraft,
          });
        },
        enabled: !!contentId && !!key?.version,
        staleTime: Number.POSITIVE_INFINITY,
      })) ?? [],
  });
};

export const useAllContentHistories = (
  contentId?: string | null | undefined,
) => {
  const {
    data: contentHistories,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useContentHistories(contentId);

  // fetchNextPageをレンダリング中に呼び出しているので、無限に呼び出されないようにするために次のページがある、かつフェッチ中ではないという限られた条件下でのみ実行されるようにする
  // ↓命令的にfetchNextPageを呼び出す際の注意点
  // https://tanstack.com/query/v4/docs/framework/react/reference/useInfiniteQuery#:~:text=Keep%20in%20mind%20that%20imperative%20fetch%20calls%2C%20such%20as%20fetchNextPage%2C%20may%20interfere%20with%20the%20default%20refetch%20behaviour%2C%20resulting%20in%20outdated%20data.%20Make%20sure%20to%20call%20these%20functions%20only%20in%20response%20to%20user%20actions%2C%20or%20add%20conditions%20like%20hasNextPage%20%26%26%20!isFetching.
  if (hasNextPage && !isFetching) {
    fetchNextPage();

    return {
      allContentHistories: null,
      isLoading: true,
    };
  }

  if (isFetching) {
    return {
      allContentHistories: null,
      isLoading: true,
    };
  }

  return {
    allContentHistories:
      contentHistories?.pages.flatMap((page) => page?.items ?? []) ?? null,
    isLoading: isFetching,
  };
};

export const useExistsContentObject = (apiId?: string | null | undefined) => {
  return useApi(
    contentQueryKeys.object(apiId ?? undefined),
    async () => {
      if (!apiId) {
        return;
      }

      return ensureContentObjectExists(apiId);
    },
    {
      enabled: !!apiId,
      staleTime: Number.POSITIVE_INFINITY,
    },
  );
};
