import { NavLink } from 'react-router-dom';

import styles from './iconWithTextNavLink.module.css';

type Props = {
  icon: string;
  text: string;
  outlined?: boolean;
  to: string;
  enableActiveStyle?: boolean;
};

const IconWithTextNavLink: React.FC<Props> = ({
  icon,
  text,
  outlined = false,
  to,
  enableActiveStyle = false,
}) => {
  return (
    <NavLink
      to={to}
      className={styles.link}
      activeClassName={enableActiveStyle === true ? styles.selected : ''}
    >
      <i className={`material-icons${outlined === true ? '-outlined' : ''}`}>
        {icon}
      </i>
      {text}
    </NavLink>
  );
};

export default IconWithTextNavLink;
