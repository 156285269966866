import { useMemo, useContext } from 'react';

import { myRolesContext } from '@/hooks/Role/useMyRoles';

import type { Role } from '@/entity/role';

type ReviewRequestPermissionArgs = {
  roles: Role[] | undefined;
  apiId: string;
};

export const checkReviewRequestCreatePermission = ({
  roles,
  apiId,
}: ReviewRequestPermissionArgs): boolean => {
  if (!roles) {
    return false;
  }

  const readLevels = roles.map((role) => {
    const canReadReviewRequestPermission =
      role.exceptionContentPermissions?.find((ecp) => {
        return ecp.apiId === apiId;
      });

    return (
      canReadReviewRequestPermission?.reviewRequestPermission ||
      role.reviewRequestPermission
    );
  });

  const hasReviewRequestPermission = readLevels.some((level) => level.create);
  return hasReviewRequestPermission;
};

export const useReviewRequestCreatePermission = ({
  apiId,
}: Pick<ReviewRequestPermissionArgs, 'apiId'>): boolean => {
  const { roles } = useContext(myRolesContext);

  const hasPermission = useMemo(() => {
    return checkReviewRequestCreatePermission({ roles, apiId });
  }, [apiId, roles]);

  return hasPermission;
};
