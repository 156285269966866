import { Bold } from './Bold';
import { Code } from './Code';
import { Color } from './Color';
import { Italic } from './Italic';
import { Strike } from './Strike';
import { TextAlign } from './TextAlign';
import { Underline } from './Underline';

import type { RichEditorV2Options } from '@/constants/richEditorOptions';
import type { RichEditorV2Color } from '@/types/field';
import type { Editor } from '@tiptap/react';

import style from './decoration.module.css';

export type Props = {
  editor: Editor;
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
  colorList: RichEditorV2Color[];
};

export type ViewProps = {
  editor: Editor;
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
  colorList: RichEditorV2Color[];
};

export const DecorationView: React.FC<ViewProps> = ({
  editor,
  richEditorV2Options,
  colorList,
}) => {
  return (
    <div className={style.main}>
      {richEditorV2Options.includes('bold') && <Bold editor={editor} />}
      {richEditorV2Options.includes('italic') && <Italic editor={editor} />}
      {richEditorV2Options.includes('underline') && (
        <Underline editor={editor} />
      )}
      {richEditorV2Options.includes('strike') && <Strike editor={editor} />}
      {richEditorV2Options.includes('code') && <Code editor={editor} />}
      {richEditorV2Options.includes('color') && (
        <Color editor={editor} colorList={colorList} />
      )}
      {richEditorV2Options.includes('textAlign') && (
        <TextAlign editor={editor} />
      )}
    </div>
  );
};

export const Decoration: React.FC<Props> = ({
  editor,
  richEditorV2Options,
  colorList,
}) => {
  return (
    <DecorationView
      editor={editor}
      richEditorV2Options={richEditorV2Options}
      colorList={colorList}
    />
  );
};
