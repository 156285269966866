import type { MigrateApi } from '@/entity/api';
import type { ContentValue } from '@/entity/content';
import { isOverMaxRepeat } from '@/hooks/FieldLimit/useLimitCustomField';
import { useGetMyService } from '@/hooks/useService';
import { getCustomFieldInitialValues } from '@/usecase/contentUsecase';
import cx from 'classnames';
import { useAtom } from 'jotai';
import type { PrimitiveAtom, SetStateAction, WritableAtom } from 'jotai';
import { splitAtom } from 'jotai/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { nanoid } from 'nanoid';
import { useCallback, useMemo, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';
import { Customfield } from '..';
import { useFieldTypes } from '../../../constants/useFieldTypes';
import type { CustomField, Field } from '../../../types/contents';
import Button from '../../ui/Button';
import Modal from '../../ui/Modal';
import styles from './repeaterfield.module.css';

type Props = {
  api: MigrateApi;
  parentField: Field;
  contentAtom: WritableAtom<unknown[], [SetStateAction<unknown[]>], void>;
  getContent: () => ContentValue;
  customFields: CustomField[];
  repeatCount: number;
};

const keyExtractor = (content: unknown) => (content as { id: string }).id;

const Repeaterfield: React.FC<Props> = ({
  api,
  parentField,
  contentAtom,
  getContent,
  customFields: allCustomFields,
  repeatCount,
}) => {
  const { t } = useTranslation('repeaterfield');
  const { service, parentService } = useGetMyService();
  const flags = useFlags();

  // ANKEN-1164 繰り返しフィールドの繰り返し数上限設定
  const maxFieldDate = flags.maxFieldDate;
  const maxRepeatTimes = flags.maxRepeatRepeaterContentAtCreateContentTimes;
  const overMaxRepeat = isOverMaxRepeat(
    { repeatCount, service, parentService },
    { maxFieldDate, maxRepeatTimes },
  );

  const [contentAtoms, dispatch] = useAtom(
    splitAtom(contentAtom, keyExtractor),
  );

  const min =
    parentField?.repeaterCountLimitValidation?.repeatCount?.min ?? null;
  const max =
    parentField?.repeaterCountLimitValidation?.repeatCount?.max ?? null;
  const hasMinimumOnly = typeof min === 'number' && max === null;
  const hasMaximumOnly = min === null && typeof max === 'number';
  const hasBothLimits = typeof min === 'number' && typeof max === 'number';
  const isMinEqualToMax =
    typeof min === 'number' && typeof max === 'number' && min === max;

  const isLessThanMinWhenMinOnly = hasMinimumOnly && contentAtoms.length < min;
  const isGreaterThanMaxWhenMaxOnly =
    hasMaximumOnly && contentAtoms.length > max;
  const isOutOfRangeWhenBothLimits =
    hasBothLimits && (contentAtoms.length < min || contentAtoms.length > max);
  const isNotEqualToLimitWhenEqual =
    isMinEqualToMax && contentAtoms.length !== min;

  const hasError =
    isLessThanMinWhenMinOnly ||
    isGreaterThanMaxWhenMaxOnly ||
    isOutOfRangeWhenBothLimits ||
    isNotEqualToLimitWhenEqual;

  const fieldTypes = useFieldTypes();
  const customFields = useMemo(() => {
    const fields = allCustomFields.reduce<Record<string, CustomField>>(
      (a, b) => {
        return {
          ...a,
          [b.createdAt]: b,
        };
      },
      {},
    );
    return (
      parentField.customFieldCreatedAtList
        ?.map((createdAt) => fields[createdAt])
        .filter((field) => field) ?? []
    );
  }, [parentField, allCustomFields]);
  const [RepeaterModal, openRepeaterModal, closeRepeaterModal] =
    useModal('root');
  const [index, setIndex] = useState(0);

  const openModal = useCallback(
    (i: number) => {
      setIndex(i);
      openRepeaterModal();
    },
    [openRepeaterModal],
  );

  const addField = useCallback(
    (createdAt: string, i: number) => {
      const selectedCustomField = allCustomFields.find(
        (v) => v.createdAt === createdAt,
      );
      if (selectedCustomField == null) {
        return;
      }

      const selectedCustomFieldInitialValues =
        getCustomFieldInitialValues(selectedCustomField);

      const beforeContentAtom = contentAtoms[i];

      dispatch({
        type: 'insert',
        before: beforeContentAtom,
        value: {
          ...selectedCustomFieldInitialValues,
          customFieldCreatedAt: createdAt,
          id: nanoid(10), // mapのkeyに用いる。一意のものでないと削除時に一つ前のコンポーネントが参照されたままになってしまう。
        },
      });

      closeRepeaterModal();
    },
    [allCustomFields, closeRepeaterModal, contentAtoms, dispatch],
  );

  const removeField = useCallback(
    (i: number) => {
      dispatch({ type: 'remove', atom: contentAtoms[i] });
    },
    [contentAtoms, dispatch],
  );

  const addNewField = useCallback(
    (i: number) => {
      // カスタムフィールドが1件の場合は選択の必要がないので、モーダルを出さずに追加する
      if (customFields.length === 1) {
        addField(customFields[0].createdAt, i);
      } else {
        openModal(i);
      }
    },
    [customFields, addField, openModal],
  );

  // 並びかえ
  const [beforeIndex, setBeforeIndex] = useState(-1);
  const [afterIndex, setAfterIndex] = useState(-1);
  const [draggable, setDraggable] = useState(false);
  const onDragStart = useCallback((e: any) => {
    setBeforeIndex(e.target.getAttribute('data-index'));
  }, []);

  const onDragOver = useCallback(
    (e: any) => {
      if (draggable === false) {
        return;
      }
      e.preventDefault();
      const { top, height } = e.currentTarget.getBoundingClientRect();
      const y = e.pageY - top - window.pageYOffset;

      // 要素の半分より上か下か判定
      setAfterIndex(
        Number(e.currentTarget.getAttribute('data-index')) +
          (y < height / 2 ? 0 : 1),
      );
    },
    [draggable],
  );

  const moveField = useCallback(
    (index: number, targetIndex: number) => {
      // 位置を移動しない場合は何もしない
      const movedToSamePosition =
        index === targetIndex || index === targetIndex - 1 || targetIndex < 0;
      if (movedToSamePosition) {
        return;
      }

      dispatch({
        type: 'move',
        atom: contentAtoms[index],
        before: contentAtoms[targetIndex],
      });
    },
    [contentAtoms, dispatch],
  );

  const onDragEnd = useCallback(() => {
    moveField(Number(beforeIndex), Number(afterIndex));
    setBeforeIndex(-1);
    setAfterIndex(-1);
    setDraggable(false);
  }, [moveField, beforeIndex, afterIndex]);

  if (customFields.length === 0) {
    return (
      <div className={styles.wrapper}>
        <p className={styles.errorText}>{t('Custom fields are not found.')}</p>
      </div>
    );
  }

  return (
    <>
      {hasError && (
        <p className={styles.errorText}>
          {isLessThanMinWhenMinOnly
            ? t('Make the number of fields at least {{count}}', {
                count: min,
              })
            : isGreaterThanMaxWhenMaxOnly
              ? t('Make the number of fields at most {{count}}', {
                  count: max,
                })
              : isOutOfRangeWhenBothLimits
                ? t('Make the number of fields between {{min}} and {{max}}', {
                    min,
                    max,
                  })
                : isNotEqualToLimitWhenEqual
                  ? t('Make the number of fields {{count}}', {
                      count: max,
                    })
                  : ''}
        </p>
      )}
      {contentAtoms.map((contentAtom, i) => (
        <div
          key={contentAtom.toString()}
          className={cx('customFieldHook', styles.customField, {
            [styles.isError]: hasError,
          })}
        >
          <div
            draggable={draggable}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            data-index={i}
            className={cx('customFieldDragAreaHook', styles.dragArea, {
              [styles.isNext]:
                Number(afterIndex) === i + 1 && i === contentAtoms.length - 1,
              [styles.isDragOver]: Number(afterIndex) === i,
            })}
          >
            <button
              className={styles.upButton}
              onClick={() => moveField(i, i - 1)}
            >
              <i className="material-icons">keyboard_arrow_up</i>
            </button>
            <button
              className={styles.handle}
              onMouseDown={() => setDraggable(true)}
              onMouseUp={() => setDraggable(false)}
            >
              <i className="material-icons">drag_handle</i>
            </button>
            <button
              className={styles.downButton}
              onClick={() => moveField(i, i + 2)}
            >
              <i className="material-icons">keyboard_arrow_down</i>
            </button>
            <Customfield
              api={api}
              contentAtom={contentAtom as PrimitiveAtom<ContentValue>}
              customFields={allCustomFields}
              inRepeatField
              fieldIndex={i}
              validationError={hasError}
              getContent={getContent}
              repeatCount={repeatCount + 1}
            />
          </div>
          {i === 0 && (!max || contentAtoms.length < max) && (
            <button className={styles.addButton} onClick={() => addNewField(i)}>
              <i className="material-icons">add</i>
            </button>
          )}
          {(!max || contentAtoms.length < max) && (
            <button
              className={`${styles.addButton} ${styles.next}`}
              onClick={() => addNewField(i + 1)}
            >
              <i className="material-icons">add</i>
            </button>
          )}
          <button
            className={styles.removeButton}
            onClick={() => removeField(i)}
          >
            <i className="material-icons">clear</i>
          </button>
        </div>
      ))}
      {contentAtoms.length === 0 && (
        <Button
          value={t('Add field')}
          type="tertiary"
          size="full"
          icon="add"
          onClick={() => addNewField(0)}
          disabled={overMaxRepeat}
        />
      )}
      {overMaxRepeat && (
        <p className={styles.errorText}>
          {t(
            'The field cannot be added because the repetition limit has been reached.',
          )}
        </p>
      )}
      <RepeaterModal>
        <Modal type="medium" title={t('Select a Custom field')}>
          <div className={styles.modal}>
            <ul>
              {customFields.map(({ name, fields, createdAt }, i) => (
                <li
                  key={i}
                  className={styles.list}
                  onClick={() => addField(createdAt, index)}
                >
                  <span className={styles.name}>{name}</span>
                  <div className={styles.fields}>
                    {fields.map((field: Field, j: number) => (
                      <span className={styles.icons} key={j}>
                        <i className="material-icons">
                          {fieldTypes[field.kind].icon}
                        </i>
                        {field.name}
                      </span>
                    ))}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Modal>
      </RepeaterModal>
    </>
  );
};

export default Repeaterfield;
