import { nanoid } from 'nanoid';
import queryString from 'query-string';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type Condition = '[less_than]' | '[greater_than]' | '[equals]' | '[contains]';
type Conditions = {
  [index: string]: Condition[];
};
export const CONDITIONS: Conditions = {
  createdAt: ['[less_than]', '[greater_than]'],
  tags: ['[contains]'],
  createdBy: ['[equals]'],
  format: ['[equals]'],
};

export type Filter = {
  id: string;
  key: string;
  condition: string;
  value: string;
};

export const useMediaFilter = () => {
  // フィルターのクエリをURLから取得
  const query = new URLSearchParams(useLocation().search);
  const filterQuery = query.get('filters') || null;
  // ex:createdAt[less_than]2022-09-13T15:00:00.000Z[and]createdAt[greater_than]2022-08-30T15:00:00.000Z
  const defaultFiltersQuery = decodeURI(filterQuery || '');

  // ex:createdAt[less_than]2022-09-13T15:00:00.000Z
  const defaultFilters = defaultFiltersQuery.split('[and]');

  const defaultFiltersArray = useMemo(() => {
    return defaultFilters
      .map((item: string): Filter | undefined => {
        const symbols = [...new Set(Object.values(CONDITIONS).flat())];
        const regExp = new RegExp(
          `${symbols.join('|').replace(/\[/g, '\\[').replace(/\]/g, '\\]')}`,
        );
        const res = item.match(regExp);

        if (!res) {
          return undefined;
        }

        const [key, value] = item.split(res[0]);
        return {
          id: nanoid(),
          key, // ex:createdAt
          value, // ex:2022-09-13T15:00:00.000Z
          condition: res[0], // ex:[less_than]
        };
      })
      .flatMap((item) => (item !== undefined ? [item] : []));
  }, [defaultFilters]);

  const history = useHistory();

  const onSubmitFiltersArray = useCallback(
    (filtersArray: Filter[]) => {
      const filterParams = filtersArray.map((filter) => {
        return `${filter.key}${filter.condition}${filter.value}`;
      });

      if (filterParams.length === 0) {
        history.replace({
          search: '',
        });
        return;
      }

      history.push({
        search: `?${queryString.stringify({
          filters: filterParams.join(`[and]`),
        })}`,
      });
    },
    [history],
  );

  const addFiltersTag = useCallback(
    (mediumTagId: string) => {
      const newFiltersArray = [
        ...defaultFiltersArray,
        {
          id: nanoid(),
          key: 'tags',
          value: mediumTagId,
          condition: '[contains]',
        },
      ];
      onSubmitFiltersArray(newFiltersArray);
    },
    [defaultFiltersArray, onSubmitFiltersArray],
  );

  return {
    defaultFiltersQuery,
    defaultFiltersArray,
    onSubmitFiltersArray,
    addFiltersTag,
  };
};
