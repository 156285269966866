// TODO:roleRepositoryフォルダに移動する
import { API, graphqlOperation } from 'aws-amplify';

import type { Role } from '@/entity/role';

import * as queries from '@/graphql/queries';

export const rolesRepository = (serviceId: string) => {
  /**
   * ロール一覧を取得する
   */
  const fetchAllRoles = async (): Promise<Role[]> => {
    try {
      const result = await API.graphql(
        graphqlOperation(queries.listServiceRoles, { serviceId }),
      );
      const typedResult = result as { data: { listServiceRoles: Role[] } };

      return typedResult.data.listServiceRoles ?? null;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not fetch all roles');
    }
  };

  const fetchMyRoles = async (): Promise<Role[]> => {
    try {
      const result = await API.graphql(
        graphqlOperation(queries.getMyRoles, { serviceId }),
      );
      const typedResult = result as { data: { getMyRoles: Role[] } };

      return typedResult.data.getMyRoles ?? null;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not fetch all roles');
    }
  };

  return { fetchAllRoles, fetchMyRoles };
};
