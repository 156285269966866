import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { IconButton } from '../../../../IconButton';

import styles from './insertTableMenu.module.css';

import { arrayNumbers } from '@/util/array';
import { TableIcon } from '@/views/Common/Icons';
import { Popover } from '@/views/InputField/RichEditorV2/Popover';

const TABLE_ROWS = 6;
const TABLE_COLUMNS = 7;

export type Props = {
  insertTableHandler: (rows: number, cols: number) => void;
  onHoverTableItem: (row: number, col: number) => void;
  tableItemPosition: { row: number; column: number };
  editable: boolean;
};

export type ViewProps = {
  insertTableHandler: (rows: number, cols: number) => void;
  onHoverTableItem: (row: number, col: number) => void;
  tableItemPosition: { row: number; column: number };
  editable: boolean;
};

export const InsertTableMenuView: React.FC<ViewProps> = ({
  tableItemPosition,
  insertTableHandler,
  onHoverTableItem,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');

  return (
    <Popover
      trigger={
        <IconButton
          icon={<TableIcon className={styles.icon} />}
          aria-label={t('Table')}
          isActive={false}
        />
      }
      body={
        <div className={styles.content}>
          <h3 className={styles.label}>{t('Add columns and rows')}</h3>
          {arrayNumbers(TABLE_ROWS).map((row) => (
            <div key={row} className={styles.group}>
              {arrayNumbers(TABLE_COLUMNS).map((column) => (
                <button
                  key={column}
                  className={cx(styles.item, {
                    [styles.isActive]:
                      row <= tableItemPosition.row &&
                      column <= tableItemPosition.column,
                  })}
                  onClick={() => insertTableHandler(row, column)}
                  onMouseEnter={() => onHoverTableItem(row, column)}
                  onMouseLeave={() => onHoverTableItem(0, 0)}
                />
              ))}
            </div>
          ))}
        </div>
      }
      disabled={!editable}
    />
  );
};

export const InsertTableMenu: React.FC<Props> = ({
  tableItemPosition,
  insertTableHandler,
  onHoverTableItem,
  editable,
}) => {
  return (
    <InsertTableMenuView
      tableItemPosition={tableItemPosition}
      insertTableHandler={insertTableHandler}
      onHoverTableItem={onHoverTableItem}
      editable={editable}
    />
  );
};
