import cx from 'classnames';
import { forwardRef } from 'react';

import styles from './button.module.css';

export type Props = {
  value: NonNullable<React.ReactNode>;
  type?: 'primary' | 'secondary' | 'tertiary' | 'danger';
  size?: 'auto' | 'small' | 'large' | 'full';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  icon?: string;
  className?: string;
  autoFocus?: boolean;
} & Omit<React.ComponentPropsWithoutRef<'button'>, 'type' | 'value'> &
  // GAイベントトラッキング用のプロパティ
  Record<`ga${string}`, unknown>;

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      value,
      type = 'primary',
      size = 'auto',
      onClick,
      disabled = false,
      icon,
      className = '',
      autoFocus,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <button
        ref={forwardedRef}
        type="button"
        className={cx(styles.button, styles[type], styles[size], className, {
          [styles.disabled]: disabled,
        })}
        onClick={onClick}
        disabled={disabled}
        autoFocus={autoFocus}
        {...props}
      >
        {icon && (
          <span
            className={cx(
              `material-icons${
                type === 'tertiary' || type === 'danger' ? '-outlined' : ''
              }`,
              styles.icon,
            )}
            aria-hidden
          >
            {icon}
          </span>
        )}
        <span>{value}</span>
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
