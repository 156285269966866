import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  defaultManagementPolicy,
  defaultPriorityPolicy,
} from '../../../constants/apiKeyPolices';
import { useCreateApiKey } from '../../../hooks/ApiKey/useApiKeyWriter';
import { useLoadApis } from '../../../hooks/ApiList/useApiListReader';
import { useStripeActions } from '../../../hooks/useStripeActions';
import ManagementPolicySetting from '../../ManagementPolicySetting';
import PolicySetting from '../../PolicySetting';
import PriorityPolicy from '../../PriorityPolicy';
import Button from '../../ui/Button';
import ExternalLink from '../../ui/ExternalLink';

import type {
  ApiKey,
  ApiKeyPriorityPolicy,
  ManagementPolicy,
} from '../../../types/apiKey';
import type { Service } from '../../../types/services';

import styles from './apiKeyModal.module.css';

import { useInputPlainText } from '@/views/Common/Inputs';
import { Fieldset } from '@/views/Common/Ui/Fieldset';
import { ModalContent } from '@/views/Common/Ui/Modal';
import { Textfield } from '@/views/Common/Ui/Textfield';

type Props = {
  close: () => void;
  service: Service;
  apiKeys: ApiKey[];
};

const ApiKeyModal: React.FC<Props> = ({ close, service, apiKeys }) => {
  const { t } = useTranslation('apiKeyModal');
  const { apiList } = useLoadApis(service);
  const { createApiKey, createApiKeyLoading, createApiKeyResult } =
    useCreateApiKey(service.partitionKey);

  const { currentPlan } = useStripeActions(service);

  const [
    apiKeyName,
    onChangeApiKeyName,
    apiKeyNameValidationError,
    resetApiKeyName,
  ] = useInputPlainText('', { validateOnInit: true, validateOnReset: true });

  const [tab, setTab] = useState<string>('content');
  const onChangeTab: React.MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      setTab(e.currentTarget.getAttribute('data-id') ?? 'content');
    },
    [setTab],
  );

  const [defaultPolicy, setDefaultPolicy] = useState({
    get: true,
    getAllDraft: false,
    getAllClosed: false,
    post: false,
    put: false,
    patch: false,
    delete: false,
  });

  const [managementPolicy, setManagementPolicy] = useState<ManagementPolicy>(
    defaultManagementPolicy,
  );

  const [priorityPolicies, setPriorityPolicies] = useState<
    ApiKeyPriorityPolicy[]
  >([]);

  const replacePriorityPolicy = useCallback(
    (priorityPolicy: ApiKeyPriorityPolicy, targetIndex: number) => {
      // 新規作成した個別権限に差分が出た場合のみ更新する
      if (
        JSON.stringify(priorityPolicy) ===
        JSON.stringify(priorityPolicies[targetIndex])
      ) {
        return;
      }
      setPriorityPolicies((policies) =>
        policies.map((current, index) => {
          return targetIndex === index ? priorityPolicy : current;
        }),
      );
    },
    [priorityPolicies],
  );

  const addPriorityPolicies = useCallback(() => {
    setPriorityPolicies((policy) => [
      ...policy,
      { apiId: apiList?.list[0]?.partitionKey, ...defaultPriorityPolicy },
    ]);
  }, [apiList?.list]);

  const deletePriorityPolicies = useCallback(
    (paramIndex: number) => {
      setPriorityPolicies((policies) =>
        policies.filter((_, index) => index !== paramIndex),
      );
    },
    [setPriorityPolicies],
  );

  const onCreateApiKey = useCallback(() => {
    createApiKey({
      label: apiKeyName,
      defaultPolicy,
      priorityPolicies: priorityPolicies.map((priorityPolicy) => {
        return { apiId: priorityPolicy.apiId, policy: priorityPolicy.policy };
      }),
      managementPolicy,
      close,
    });
  }, [
    createApiKey,
    apiKeyName,
    defaultPolicy,
    priorityPolicies,
    managementPolicy,
    close,
  ]);

  /**
   * APIキー作成成功時した後、モーダルが閉じた時に初期値にリセットする
   */
  useEffect(() => {
    if (createApiKeyResult === true) {
      resetApiKeyName();
      setDefaultPolicy({
        get: true,
        getAllDraft: false,
        getAllClosed: false,
        post: false,
        put: false,
        patch: false,
        delete: false,
      });
      setPriorityPolicies([]);
      setTab('content');
    }
  }, [createApiKeyResult, resetApiKeyName]);

  return (
    <ModalContent
      size="medium"
      title={t('Create an API Key')}
      footer={{
        submitButtonProps: {
          value: t('Create'),
          onClick: onCreateApiKey,
          disabled:
            !!apiKeyNameValidationError ||
            createApiKeyLoading ||
            currentPlan.limit.maxApiKeyCount <= apiKeys.length,
        },
      }}
    >
      {currentPlan.limit.maxApiKeyCount > apiKeys.length ? (
        <>
          <Textfield
            label={t('API Key Name')}
            type="text"
            placeholder={t('Name of API Key')}
            value={apiKeyName}
            onChange={(e) => onChangeApiKeyName(e)}
            error={apiKeyNameValidationError}
          />
          <dl className={styles.tabs}>
            <dd
              onClick={onChangeTab}
              className={
                tab === 'content'
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }
              data-id="content"
            >
              {t('Content API')}
            </dd>
            <dd
              onClick={onChangeTab}
              className={
                tab === 'management'
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }
              data-id="management"
            >
              {t('Management API (Beta)')}
            </dd>
          </dl>
          {tab === 'content' && (
            <>
              <Fieldset label={t('Default Permission')}>
                <PolicySetting
                  defaultPolicy={defaultPolicy}
                  setPolicy={setDefaultPolicy}
                />
              </Fieldset>
              {apiList && apiList?.list?.length > 0 && (
                <>
                  <Fieldset
                    label={t('Custom Permission for Selected API')}
                    description={t(
                      'Custom permissions can be set for each selected API individually. The default permissions for a selected API will be overridden by this custom permission.',
                    )}
                  >
                    {priorityPolicies.map((priorityPolicy, index) => (
                      <div className={styles.block} key={index}>
                        <PriorityPolicy
                          apiList={apiList}
                          priorityPolicy={priorityPolicy}
                          setPriorityPolicy={(param: ApiKeyPriorityPolicy) =>
                            replacePriorityPolicy(param, index)
                          }
                        />
                        <button
                          className={styles.removeButton}
                          onClick={() => deletePriorityPolicies(index)}
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                    ))}
                  </Fieldset>
                  <Button
                    size="full"
                    type="tertiary"
                    onClick={() => addPriorityPolicies()}
                    value={t('+Set custom permissions for selected API')}
                  />
                </>
              )}
            </>
          )}
          {tab === 'management' && (
            <Fieldset label={t('Permission')}>
              <p className={styles.description}>
                {Trans({
                  t,
                  i18nKey:
                    'Add management API permissions. Refer to documentation for more details.',
                  children: (
                    <ExternalLink
                      href={`https://document.microcms.io/management-api`}
                      target="docs"
                      hasUnderline={true}
                      className={styles.helpLink}
                    >
                      the documentation
                    </ExternalLink>
                  ),
                })}
              </p>
              <ManagementPolicySetting
                managementPolicy={managementPolicy}
                setPolicy={setManagementPolicy}
              />
            </Fieldset>
          )}
        </>
      ) : (
        <dl className={styles.alert}>
          <dt className={styles.alertText}>
            {t(
              'The maximum number of API keys that can be created under the current plan has been reached.',
            )}
          </dt>
          {/* 複数環境では、リンク先URLが存在しないので表示しない */}
          {service.environment === null && (
            <dd>
              <Link to="/settings/billing">{t('Check the current plan.')}</Link>
            </dd>
          )}
        </dl>
      )}
    </ModalContent>
  );
};

export default ApiKeyModal;
