import { Td } from '@/components/ui/Table';

import { FIELD_NO_VALUE } from '../constants';
import { useI18nFormRole } from '../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../provider';

import { Checkbox } from '@/views/Common/Ui/Checkbox';

const FieldApiKey = () => {
  const { t } = useI18nFormRole();

  const {
    isAdminRole,
    input: {
      apiKeyPermission: { read },
    },
  } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  return (
    <>
      <Td>{t('API Keys')}</Td>
      <Td>
        <Checkbox
          checked={read}
          disabled={disabled}
          name="apiKeyPermission.read"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-apikey-permission-read',
              payload,
            });
          }}
          label={t('Access to API key setup page')}
        />
      </Td>
      <Td>{FIELD_NO_VALUE}</Td>
      <Td>
        {/* NOTE: ADMINロールのみで表示するが、変更できない項目なのでデフォルトでdisabledとしている */}
        {isAdminRole ? (
          <Checkbox
            checked
            disabled
            name="apiKeyPermission.update"
            label={t('Update API key permissions')}
          />
        ) : (
          FIELD_NO_VALUE
        )}
      </Td>
      <Td>ー</Td>
    </>
  );
};

export default FieldApiKey;
