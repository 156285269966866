import { useCallback } from 'react';

import type { Props } from './OrderedList';

export const useOrderedList = ({ editor }: Props) => {
  const toggleOrderedListHandler = useCallback(() => {
    editor.chain().focus().toggleOrderedList().run();
  }, [editor]);
  const isActiveOrderedList = editor.isActive('orderedList');

  return {
    toggleOrderedListHandler,
    isActiveOrderedList,
  };
};
