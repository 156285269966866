import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useMediaListField } from './useMediaListField';
import { ImageOperation } from '../shared/ImageOperation';

import type { MediaFieldValue } from '../MediaField';
import type { Field } from '@/types/contents';
import type { UniqueIdentifier } from '@dnd-kit/core';

import styles from './medialistfield.module.css';

import { ScrollArea } from '@/views/Common/Ui/ScrollArea';
import { Sortable, SortableItem } from '@/views/Common/Ui/Sortable';

export type Value = (MediaFieldValue & { id: string })[];

export type Props = {
  onChange: (value: Value) => void;
  value?: Value | null;
  field?: Field | null;
  hasMediumCreatePermission: boolean;
};

export type ViewProps = {
  scrollAreaRef: React.RefObject<HTMLDivElement>;
  scrollImagesRef: React.RefObject<HTMLDivElement>;
  value?: Value | null;
  field?: Field | null;
  sortableItems: string[];
  setSortableItems: (
    dispatch: (value: UniqueIdentifier[]) => UniqueIdentifier[],
  ) => void;
  layout: 'HORIZONTAL_SCROLL' | 'GRID_2' | 'GRID_3' | 'GRID_4';
  handleChangeOnSelected: (updatedValue: MediaFieldValue, id: string) => void;
  handleDeleteOnSelected: (id: string) => void;
  handleDropAcceptedOnSelected: (
    resultMediumIds: (string | null)[] | null,
    id: string,
  ) => void;
  handleChangeOnUnselected: (updatedValue: MediaFieldValue) => void;
  handleDropAcceptedOnUnselected: (
    resultMediumIds: (string | null)[] | null,
  ) => void;
  hasMediumCreatePermission: boolean;
};

export const MediaListFieldView: React.FC<ViewProps> = ({
  scrollAreaRef,
  scrollImagesRef,
  value,
  field,
  sortableItems,
  setSortableItems,
  layout,
  handleChangeOnSelected,
  handleDropAcceptedOnSelected,
  handleDeleteOnSelected,
  handleChangeOnUnselected,
  handleDropAcceptedOnUnselected,
  hasMediumCreatePermission,
}) => {
  const { t } = useTranslation('mediaListField');

  return (
    <div>
      {layout === 'HORIZONTAL_SCROLL' && (
        <Sortable
          items={sortableItems}
          setItems={setSortableItems}
          axis="x"
          movableRange="parentElement"
        >
          <div
            className={cx(
              styles.scroll,
              (value ?? []).length !== 0 && styles.hasElements,
            )}
          >
            <ScrollArea
              viewportRef={scrollAreaRef}
              rootClassName={styles.scrollArea}
            >
              <div
                ref={scrollImagesRef}
                className={cx(
                  styles.scrollImages,
                  (value ?? []).length !== 0 && styles.hasElements,
                )}
              >
                {(value ?? []).map((item) => (
                  <SortableItem
                    key={item.id}
                    aria-label={t('Reorder images')}
                    uniqueIdentifier={item.id}
                  >
                    <ImageOperation
                      key={item.id}
                      value={item}
                      field={field}
                      multipleUpload={false}
                      onChange={(updatedValue) =>
                        handleChangeOnSelected(updatedValue, item.id)
                      }
                      onDelete={() => handleDeleteOnSelected(item.id)}
                      onDropAccepted={(resultMediumIds) =>
                        handleDropAcceptedOnSelected(resultMediumIds, item.id)
                      }
                      className={styles.scrollImage}
                      hasMediumCreatePermission={hasMediumCreatePermission}
                    />
                  </SortableItem>
                ))}
              </div>
            </ScrollArea>
            <ImageOperation
              value={null}
              field={null}
              multipleUpload
              onChange={(updatedValue) =>
                handleChangeOnUnselected(updatedValue)
              }
              // onDeleteをクリックすることはできない想定
              onDelete={() => null}
              onDropAccepted={(resultMediumIds) =>
                handleDropAcceptedOnUnselected(resultMediumIds)
              }
              className={styles.scrollNew}
              hasMediumCreatePermission={hasMediumCreatePermission}
            />
          </div>
        </Sortable>
      )}
      {(layout === 'GRID_2' || layout === 'GRID_3' || layout === 'GRID_4') && (
        <Sortable
          items={sortableItems}
          setItems={setSortableItems}
          movableRange="parentElement"
        >
          <div
            className={cx(
              styles.grid,
              layout === 'GRID_2' && styles.grid2,
              layout === 'GRID_3' && styles.grid3,
              layout === 'GRID_4' && styles.grid4,
            )}
          >
            {value &&
              value.length !== 0 &&
              value.map((item) => (
                <SortableItem
                  key={item.id}
                  aria-label={t('Reorder images')}
                  uniqueIdentifier={item.id}
                  className={styles.sortableItem}
                >
                  <ImageOperation
                    value={item}
                    field={field}
                    multipleUpload={false}
                    onChange={(updatedValue) =>
                      handleChangeOnSelected(updatedValue, item.id)
                    }
                    onDelete={() => handleDeleteOnSelected(item.id)}
                    onDropAccepted={(resultMediumIds) =>
                      handleDropAcceptedOnSelected(resultMediumIds, item.id)
                    }
                    className={styles.gridImage}
                    queryString={layout === 'GRID_2' ? '?w=800' : undefined}
                    hasMediumCreatePermission={hasMediumCreatePermission}
                  />
                </SortableItem>
              ))}
            <ImageOperation
              value={null}
              field={null}
              multipleUpload
              onChange={(updatedValue) =>
                handleChangeOnUnselected(updatedValue)
              }
              // onDeleteをクリックすることはできない想定
              onDelete={() => null}
              onDropAccepted={(resultMediumIds) =>
                handleDropAcceptedOnUnselected(resultMediumIds)
              }
              className={styles.gridImage}
              hasMediumCreatePermission={hasMediumCreatePermission}
            />
          </div>
        </Sortable>
      )}
      <p className={styles.totalCount}>
        {t('Total of {{count}}', { count: (value ?? []).length })}
      </p>
    </div>
  );
};

export const MediaListField: React.FC<Props> = (props) => {
  const hooks = useMediaListField(props);

  return <MediaListFieldView {...hooks} />;
};
