import { useI18nFormRole } from '../../../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../../../provider';

import type { FormRoleInputValue } from '../../../provider/stateContext';
import type { PropsWithECP } from '../../FieldExceptionContent/_types';
import type { FC } from 'react';

import { Checkbox } from '@/views/Common/Ui/Checkbox';

type Props = Partial<PropsWithECP>;

/**
 * コンテンツ権限項目 - DELETE権限の入力フィールド
 */
const FieldContentDelete: FC<Props> = ({ targetECP }) => {
  const { t } = useI18nFormRole('$contentPermission.$delete');

  const { isAdminRole, input } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  const value = getValueIsomorphic(input, targetECP);

  return (
    <Checkbox
      name={'permission.contentDelete'}
      label={t('Delete contents')}
      checked={value}
      disabled={disabled}
      onChange={(e) => {
        const nextValue = e.target.checked;
        if (targetECP) {
          dispatch({
            type: 'set-ec-permission-contentdelete',
            payload: {
              id: targetECP.id,
              nextValue,
            },
          });
        } else {
          dispatch({
            type: 'set-dc-permission-contentdelete',
            payload: nextValue,
          });
        }
      }}
    />
  );
};

function getValueIsomorphic(
  input: FormRoleInputValue,
  targetEcp: Props['targetECP'],
) {
  if (targetEcp) {
    return targetEcp.permission.contentDelete;
  }
  return input.defaultContentPermission.contentDelete;
}

export default FieldContentDelete;
