import { useCallback } from 'react';

import {
  validateMatchPattern,
  validateNumber,
  validateNumberFinite,
  validateNumberIsSafeNumber,
  validateNumberSizeLimit,
  validateRelationListCountLimit,
  validateTextSizeLimit,
} from '../../../components/Validations';

import type { ContentValue } from '@/entity/content';
import type { CustomField } from '@/types/contents';
import type { Field } from '@/types/field';

// 循環参照するためにinterfaceで定義
export interface ValidationErrors {
  [key: string]:
    | (string | null | undefined) // 通常のフィールド
    | ValidationErrors // カスタムフィールド
    | ValidationErrors[]; // 繰り返しフィールド
}

export const validateStringField = (
  field: Field,
  value: string | null | undefined,
) => {
  const textSizeError =
    field.textSizeLimitValidation?.textSize &&
    validateTextSizeLimit(value, field.textSizeLimitValidation.textSize);
  const patternMatchError =
    field.patternMatchValidation?.regexp &&
    validateMatchPattern(value, field.patternMatchValidation.regexp);

  return textSizeError || patternMatchError;
};

export const validateNumberField = (
  field: Field,
  value: string | number | null | undefined,
) => {
  const numberExactError = validateNumber(value);
  const numberFiniteError = validateNumberFinite(value);
  const numberIsSafeNumberError = validateNumberIsSafeNumber(value);
  const numberSizeError =
    field.numberSizeLimitValidation?.numberSize &&
    validateNumberSizeLimit(value, field.numberSizeLimitValidation?.numberSize);

  // エラー表示の優先度の理由としては、まず数値であるかどうかをチェックし、その次にJSで扱える数値であるかどうかをチェックする。
  // そして最後にユーザー定義のバリデーションをチェックしている。
  return (
    numberExactError ||
    numberFiniteError ||
    numberIsSafeNumberError ||
    numberSizeError
  );
};

export const validateRelationListField = (field: Field, value: unknown) => {
  const relationListCountError =
    field.relationListCountLimitValidation?.relationListCount &&
    validateRelationListCountLimit(
      value,
      field.relationListCountLimitValidation?.relationListCount,
    );

  return relationListCountError;
};

const validateScalarField = (
  field: Field,
  value: unknown,
): string | null | undefined => {
  if (field.kind === 'text' || field.kind === 'textArea') {
    // valueがstring | null | undefined であることを保証する
    if (typeof value !== 'string' && value !== null && value !== undefined) {
      return;
    }

    return validateStringField(field, value);
  }
  if (field.kind === 'number') {
    // valueがstring | null | undefined であることを保証する
    if (
      typeof value !== 'string' &&
      typeof value !== 'number' &&
      value !== null &&
      value !== undefined
    ) {
      return;
    }

    return validateNumberField(field, value);
  }
  if (field.kind === 'relationList') {
    return validateRelationListField(field, value);
  }

  return;
};

const validateField = (
  field: Field,
  value: unknown,
  customFields: CustomField[],
): ValidationErrors[string] => {
  let result: ValidationErrors[string] = null;
  switch (field.kind) {
    case 'custom': {
      result = validateCustomField(field, value, customFields);
      break;
    }
    case 'repeater': {
      result = validateRepeaterField(value, customFields);
      break;
    }
    default: {
      result = validateScalarField(field, value);
      break;
    }
  }

  return result;
};

const validateCustomField = (
  field: Field,
  value: unknown,
  customFields: CustomField[],
): ValidationErrors[string] => {
  if (customFields.length === 0) {
    return {};
  }

  const customFieldErrors =
    customFields
      .find(
        (customField) => customField.createdAt === field.customFieldCreatedAt,
      )
      ?.fields.reduce<ValidationErrors>((previousValue, f) => {
        const validateResult = validateField(
          f,
          (value as ContentValue)?.[f.idValue]?.[f.kind],
          customFields,
        );

        return {
          ...previousValue,
          [f.idValue]: validateResult,
        };
      }, {}) ?? {};

  return customFieldErrors;
};

const validateRepeaterField = (
  value: unknown,
  customFields: CustomField[],
): ValidationErrors[] => {
  if (customFields.length === 0) {
    return [];
  }

  if (!Array.isArray(value)) {
    return [];
  }

  const repeaterFieldErrors: ValidationErrors[] = value.map(
    (content: ContentValue & { customFieldCreatedAt: string; id: string }) => {
      const field = customFields.find(
        (customField) => customField.createdAt === content.customFieldCreatedAt,
      );
      if (!field) {
        return {};
      }
      const scalarErrors = field.fields.map((f) => {
        const error = validateField(
          f,
          content?.[f.idValue]?.[f.kind],
          customFields,
        );
        return { [f.idValue]: error };
      });
      const fieldErrors: ValidationErrors =
        scalarErrors.reduce<ValidationErrors>((previousErrors, error) => {
          return { ...previousErrors, ...error };
        }, {});
      return fieldErrors;
    },
  );

  return repeaterFieldErrors;
};

const checkArrayError = (arrayError: ValidationErrors[]): boolean => {
  return arrayError.some((error) => checkErrors(error));
};
const checkErrors = (errors: ValidationErrors): boolean => {
  return Object.values(errors).some((error) => {
    if (typeof error === 'object') {
      if (error === null) {
        return false;
      } else if (Array.isArray(error)) {
        return checkArrayError(error);
      } else {
        return checkErrors(error);
      }
    } else {
      return error !== undefined;
    }
  });
};

export const useValidateAllFields = (
  getContent: () => ContentValue,
  fields: Field[],
  customFields: CustomField[],
) => {
  const validateAllFields = useCallback((): boolean => {
    const content = getContent();

    const validationResult = fields.reduce<ValidationErrors>(
      (previousErrors, field) => {
        const result = validateField(
          field,
          content?.[field.idValue]?.[field.kind],
          customFields,
        );

        if (result) {
          return {
            ...previousErrors,
            [field.idValue]: result,
          };
        } else {
          return previousErrors;
        }
      },
      {},
    );

    return checkErrors(validationResult);
  }, [customFields, fields, getContent]);

  return validateAllFields;
};
