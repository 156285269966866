import { Td } from '@/components/ui/Table';

import { useI18nFormRole } from '../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../provider';

import { Checkbox } from '@/views/Common/Ui/Checkbox';

const FieldApi = () => {
  const { t } = useI18nFormRole();

  const {
    isAdminRole,
    input: {
      defaultContentPermission: { apiRead, apiUpdate, apiDelete },
      apiPermission: { create: apiCreate },
    },
  } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  return (
    <>
      <Td>{t('API')}</Td>
      <Td>
        <Checkbox
          checked={apiRead}
          disabled={disabled}
          name="defaultContentPermission.apiRead"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-dc-permission-apiread',
              payload,
            });
          }}
          label={t('Reading the API\n(Sidebar, Content Reference)')}
        />
      </Td>
      <Td>
        <Checkbox
          checked={apiCreate}
          disabled={disabled}
          name="apiPermission.create"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-api-permission-create',
              payload,
            });
          }}
          label={t('Create API')}
        />
      </Td>
      <Td>
        <Checkbox
          checked={apiUpdate}
          disabled={disabled}
          name="defaultContentPermission.apiUpdate"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-dc-permission-apiupdate',
              payload,
            });
          }}
          label={t('API Settings and\ncreate / edit / delete Custom fields')}
        />
      </Td>
      <Td>
        <Checkbox
          checked={apiDelete}
          disabled={disabled}
          name="defaultContentPermission.apiDelete"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-dc-permission-apidelete',
              payload,
            });
          }}
          label={t('Delete API')}
        />
      </Td>
    </>
  );
};

export default FieldApi;
