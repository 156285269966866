import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';

import { formatData, getAuditLog } from '@/hooks/AuditLog/useAuditLog';
import { useGetMyService } from '@/hooks/useService';

import Head from '../../Head';
import Button from '../../ui/Button';
import IconWithTextButton from '../../ui/IconWithTextButton';
import Modal from '../../ui/Modal';
import Switch from '../../ui/Switch';
import AuditLogTables from '../AuditLogTables';

import styles from './auditLogSettings.module.css';

import { auditLogTypes } from '@/constants/auditLogTypes';
import { convertToEvent, escapeCsvStr } from '@/util';
import { formatDatePicker, formatDateToSeconds } from '@/util/date';
import { DatePicker } from '@/views/Common/Ui/DatePicker';
import { Upsell } from '@/views/Common/plan/Upsell';

const AuditLogSettings: React.FC = () => {
  const { t } = useTranslation('serviceAuditLogSettings');
  const { service } = useGetMyService();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isLoading, setLoading] = useState(false);

  // モーダル
  const [DownloadModal, openDownloadModal] = useModal('root');
  const [FilterModal, openFilterModal] = useModal('root');

  // 時間の範囲指定
  const onChangeStartDate = useCallback((d: any) => {
    setStartDate(d);
  }, []);

  const onChangeEndDate = useCallback((d: any) => {
    setEndDate(d);
  }, []);

  // 表示項目の編集
  const localStorageItems = useMemo(() => {
    const _items = window.localStorage.getItem(
      `${service?.partitionKey}-auditLog`,
    );
    return _items === null ? undefined : JSON.parse(_items);
  }, [service?.partitionKey]);

  const [items, setItems] = useState(localStorageItems ?? auditLogTypes);

  useEffect(() => {
    setItems(localStorageItems ?? auditLogTypes);
  }, [localStorageItems]);

  const toggleItem = useCallback(
    (idValue: any) => (on: boolean) => {
      const _items = on
        ? [...items, idValue]
        : items.filter((item: any) => item !== idValue);
      setItems(_items);
      window.localStorage.setItem(
        `${service?.partitionKey}-auditLog`,
        JSON.stringify(_items),
      );
    },
    [items, service?.partitionKey],
  );

  // 100件づつデータを取得して再帰でCSVファイル作成
  const csvFileName = useMemo(
    () => `${service?.serviceName}.csv`,
    [service?.serviceName],
  );
  const downloadCsv = useCallback(
    (result = [], offset = 0) =>
      // @ts-expect-error
      async () => {
        // CSVの作成開始
        setLoading(true);

        const GET_SIZE = 100;

        const response = await getAuditLog(
          service?.partitionKey,
          GET_SIZE,
          offset,
          startDate,
          endDate,
        );

        const tmpResult = [...result, ...response.auditLogList];

        if (response.totalCount > tmpResult.length) {
          // @ts-expect-error
          return await downloadCsv(tmpResult, offset + GET_SIZE)();
        }

        const data = await formatData(tmpResult);

        // CSV data format
        const csv = `${t('Date')},${t('IP Address')},${t('User')},${t(
          'Activity',
        )},${t('Target API Key ID')},${t('Target API ID')},${t(
          'Target API Name',
        )},${t('Target Content ID')},${t('Target Content ID (User defined)')},${t('Target Media ID')},${t(
          'Target Media Name',
        )},${t('Target Media Tag ID')},${t('Target Media Tag Name')},${t(
          'Target Member',
        )},${t('Target Role ID')},${t('Target Role Name')},${t(
          'Target Custom Field Name',
        )},${t('Target Custom Status ID')},${t(
          'Target Custom Status Name',
        )},${t('Target Reviewer')},${t('Target Review Application ID')},${t(
          'Target Review Application Title',
        )},${t('Target Environment Domain')},${t('New Creator')},${t(
          'Email',
        )},${t('Scheduled Starting Time')},${t('Scheduled Ending Time')}\n${data
          .map(
            (res: any) =>
              // ユーザーからの動的な値はescapeCsvStrでエスケープ処理が必要
              `${
                res.createdAt
                  ? // 文字列の中に、コンマが含まれていると、CSVデータで改行されてしまうので削除している
                    formatDateToSeconds(res.createdAt).replace(',', '')
                  : ``
              },${res.sourceIp ? res.sourceIp : ``},${
                res.userId ? escapeCsvStr(res.userId) : ``
              },${res.event ? convertToEvent(res.event) : ``},${
                res.apiKeyId ? res.apiKeyId : ``
              },${res.apiId ? res.apiId : ``},${
                res.apiName ? escapeCsvStr(res.apiName) : ``
              },${
                res.contentIds
                  ? res.contentIds.join(' ')
                  : res.contentId
                    ? res.contentId
                    : ``
              },${
                res.userDefinedContentIds
                  ? res.userDefinedContentIds.join(' ')
                  : res.userDefinedContentId
                    ? res.userDefinedContentId
                    : ''
              },${res.mediumId ? res.mediumId : ``},${
                res.mediumName ? escapeCsvStr(res.mediumName) : ``
              },${
                res.mediumTagIds
                  ? res.mediumTagIds.join(' ')
                  : res.mediumTagId
                    ? res.mediumTagId
                    : ``
              },${res.mediumTagName ? escapeCsvStr(res.mediumTagName) : ``},${
                res.memberId ? escapeCsvStr(res.memberId) : ``
              },${res.roleId ? res.roleId : ``}, ${
                res.roleName ? escapeCsvStr(res.roleName) : ``
              },${res.customFieldId ? escapeCsvStr(res.customFieldId) : ``}, ${
                res.customStatusId ? res.customStatusId : ``
              },${
                res.customStatusName ? escapeCsvStr(res.customStatusName) : ``
              }, ${res.reviewerId ? escapeCsvStr(res.reviewerId) : ``},  ${
                res.reviewRequestId ? res.reviewRequestId : ``
              }, ${
                res.reviewRequestTitle
                  ? escapeCsvStr(res.reviewRequestTitle)
                  : ``
              }, ${
                res.environmentSubdomain
                  ? escapeCsvStr(res.environmentSubdomain)
                  : ``
              },${res.createdBy ? escapeCsvStr(res.createdBy) : ``},${
                res.email ? escapeCsvStr(res.email) : ``
              },${res.reservationStartTime ? res.reservationStartTime : ``},${
                res.reservationStopTime ? res.reservationStopTime : ``
              }`,
          )
          .join(`\n`)}`;

        const blob = new Blob([csv], { type: 'text/plain' });
        const blobURL = window.URL.createObjectURL(blob);

        // aタグを明示的に生成してダウンロードする
        const link = document.createElement('a');
        link.download = csvFileName;
        link.href = blobURL;
        link.dataset.downloadurl = ['text/csv', link.download, link.href].join(
          ':',
        );
        link.click();

        // CSV作成後にローディングの無効化
        setLoading(false);
      },
    [csvFileName, endDate, service?.partitionKey, startDate, t],
  );

  const excludeDate = useCallback((startDate: any, endDate: any) => {
    //現在の時刻を取得
    const date = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    // 開始と終了が同じ日付の場合（年月日が同一）は、開始の時分を設定する
    return start.getFullYear() === end.getFullYear() &&
      start.getMonth() === end.getMonth() &&
      start.getDate() === end.getDate()
      ? date.setHours(
          new Date(startDate).getHours(),
          new Date(startDate).getMinutes(),
          0,
          0,
        )
      : date.setHours(0, 0, 0, 0);
  }, []);

  return (
    <Upsell restrictionKey="auditLog">
      <div>
        <Head title={t('Audit Log')} />
        <h2 className={styles.title}>{t('Audit Log')}</h2>
        <div className={styles.head}>
          <div className={styles.action}>
            <IconWithTextButton
              icon="visibility"
              text={t('Select Display Columns')}
              onClick={openFilterModal}
            />
            <IconWithTextButton
              icon="download"
              text={t('Download')}
              onClick={openDownloadModal}
            />
          </div>
        </div>
        <AuditLogTables items={items} />
      </div>
      <FilterModal>
        <Modal type="medium" title={t('Select Columns to Display')}>
          <ul className={styles.filterLists}>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('createdAt')}
                onChange={toggleItem('createdAt')}
              >
                {t('Date')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('sourceIp')}
                onChange={toggleItem('sourceIp')}
              >
                {t('IP Address')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch on={items.includes('user')} onChange={toggleItem('user')}>
                {t('User')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('event')}
                onChange={toggleItem('event')}
              >
                {t('Activity')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('apiKeyId')}
                onChange={toggleItem('apiKeyId')}
              >
                {t('Target API Key ID')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('apiKeyName')}
                onChange={toggleItem('apiKeyName')}
              >
                {t('Target API Key Name')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('apiId')}
                onChange={toggleItem('apiId')}
              >
                {t('Target API ID')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('apiName')}
                onChange={toggleItem('apiName')}
              >
                {t('Target API Name')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('contentId')}
                onChange={toggleItem('contentId')}
              >
                {t('Target Content ID')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('userDefinedContentId')}
                onChange={toggleItem('userDefinedContentId')}
              >
                {t('Target Content ID (User defined)')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('mediumId')}
                onChange={toggleItem('mediumId')}
              >
                {t('Target Media ID')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('mediumName')}
                onChange={toggleItem('mediumName')}
              >
                {t('Target Media Name')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('mediumTagId')}
                onChange={toggleItem('mediumTagId')}
              >
                {t('Target Media Tag ID')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('mediumTagName')}
                onChange={toggleItem('mediumTagName')}
              >
                {t('Target Media Tag Name')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('memberId')}
                onChange={toggleItem('memberId')}
              >
                {t('Target Member')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('roleId')}
                onChange={toggleItem('roleId')}
              >
                {t('Target Role ID')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('roleName')}
                onChange={toggleItem('roleName')}
              >
                {t('Target Role Name')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('customFieldId')}
                onChange={toggleItem('customFieldId')}
              >
                {t('Target Custom Field Name')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('customStatusId')}
                onChange={toggleItem('customStatusId')}
              >
                {t('Target Custom Status ID')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('customStatusName')}
                onChange={toggleItem('customStatusName')}
              >
                {t('Target Custom Status Name')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('reviewerId')}
                onChange={toggleItem('reviewerId')}
              >
                {t('Target Reviewer')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('reviewRequestId')}
                onChange={toggleItem('reviewRequestId')}
              >
                {t('Target Review Application ID')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('reviewRequestTitle')}
                onChange={toggleItem('reviewRequestTitle')}
              >
                {t('Target Review Application Title')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('environmentServiceId')}
                onChange={toggleItem('environmentServiceId')}
              >
                {t('Target Environment Service ID')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('environmentName')}
                onChange={toggleItem('environmentName')}
              >
                {t('Target Environment Name')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('environmentSubdomain')}
                onChange={toggleItem('environmentSubdomain')}
              >
                {t('Target Environment Domain')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('createdBy')}
                onChange={toggleItem('createdBy')}
              >
                {t('New Creator')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('email')}
                onChange={toggleItem('email')}
              >
                {t('Email')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('reservationStartTime')}
                onChange={toggleItem('reservationStartTime')}
              >
                {t('Scheduled Starting Time')}
              </Switch>
            </li>
            <li className={styles.filterList}>
              <Switch
                on={items.includes('reservationStopTime')}
                onChange={toggleItem('reservationStopTime')}
              >
                {t('Scheduled Ending Time')}
              </Switch>
            </li>
          </ul>
        </Modal>
      </FilterModal>
      <DownloadModal>
        <Modal type="small" title={t('Specify Range of Date and Time')}>
          <div className={styles.form}>
            <DatePicker
              reactDatePickerProps={{
                selected: startDate,
                onChange: onChangeStartDate,
                showTimeSelect: true,
                dateFormat: formatDatePicker({ dateFormat: false }),
                timeCaption: t('Time'),
                maxDate: new Date(),
              }}
            />
            <div className={styles.line}>&nbsp;〜&nbsp;</div>
            <DatePicker
              reactDatePickerProps={{
                selected: endDate,
                onChange: onChangeEndDate,
                showTimeSelect: true,
                dateFormat: 'yyyy/MM/dd HH:mm',
                timeCaption: t('Time'),
                maxDate: new Date(),
                minDate: startDate,
                // @ts-expect-error
                minTime: excludeDate(startDate, endDate),
                // @ts-expect-error
                maxTime: new Date().setHours(23, 59, 0, 0),
              }}
            />
          </div>
          <div className={styles.download}>
            <Button
              type="secondary"
              value={t('Download')}
              onClick={downloadCsv()}
              disabled={isLoading || !startDate || !endDate}
            />
          </div>
        </Modal>
      </DownloadModal>
    </Upsell>
  );
};

export default AuditLogSettings;
