import { forwardRef } from 'react';

const Loading = forwardRef<HTMLDivElement, React.ComponentProps<'div'>>(
  ({ children, ...props }, forwardedRef) => {
    return (
      <div {...props} ref={forwardedRef}>
        <img src="/images/icon_loading.svg" decoding="async" alt="loading..." />
        {children}
      </div>
    );
  },
);
Loading.displayName = 'Loading';

export { Loading };
