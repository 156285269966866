import deepEqual from 'deep-equal';

import { CustomPrompt } from './CustomPrompt';
import ReloadPrompt from '../ReloadPrompt';

import type { ContentValue } from '@/entity/content';

type Props = {
  getContent: () => ContentValue;
  data?: ContentValue;
  tryingToSave: boolean;
};

export const PromptGroup: React.FC<Props> = ({
  getContent,
  data,
  tryingToSave,
}) => {
  return (
    <>
      <CustomPrompt
        getContent={getContent}
        data={data}
        tryingToSave={tryingToSave}
      />
      <ReloadPrompt
        showPrompt={() => {
          const hasUnsavedContent = !deepEqual(getContent(), data);
          return hasUnsavedContent && !tryingToSave;
        }}
      />
    </>
  );
};
