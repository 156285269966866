import { useMemo } from 'react';

import { useExceptionPermissionIsHaveLeastOne } from '@/hooks/Role/useMyRoles';

import type { MigrateApi } from '@/entity/api';
import type { ContentStatus } from '@/entity/content';

import { getContentUpdatePermissionEntryByStatus } from '@/util/permissions/content';
import {
  useHasCreateContentPermission,
  useHasUpdateContentPermission,
} from '@/views/Common/Roles/MyRolesPermission';

/**
 * ContentActionsコンポーネント用のコンテンツに対する操作可能かの情報を提供する
 *
 * ContentActionsは、コンテンツの編集、削除、レビュー依頼の作成などの操作に必要な権限情報が多いため、
 * このカスタムフックにまとめる形を採る。
 */
function useContentOperationOnContentActions(input: {
  apiId: MigrateApi['partitionKey'];
  contentStatus?: ContentStatus;
  underReviewing?: boolean;
  /**
   * 新規作成時はfalse, 編集時はtrueの値をとる
   */
  isEditing: boolean;
}) {
  const { hasCreateContentPermission, hasCreateContentPermissionForAny } =
    useHasCreateContentPermission();
  const { hasUpdateContentPermission } = useHasUpdateContentPermission();
  const [hasDeletePermission] = useExceptionPermissionIsHaveLeastOne(
    input.apiId,
    'contentDelete',
  );

  /**
   * 「コンテンツをコピーして新規作成」ができる場合はtrue
   */
  const contentCopyable = useMemo(
    () => hasCreateContentPermissionForAny(input.apiId),
    [hasCreateContentPermissionForAny, input.apiId],
  );

  /**
   * 「削除」ができる場合はtrue
   */
  const contentDeletable = useMemo(() => {
    return hasDeletePermission && !input.underReviewing;
  }, [hasDeletePermission, input.underReviewing]);

  /**
   * 公開中、または公開終了の状態で「下書きに戻す」ができる場合はtrue
   */
  const canRevertToDraft = useMemo(() => {
    return (
      (input.contentStatus === 'CLOSED' || input.contentStatus === 'PUBLISH') &&
      hasUpdateContentPermission(
        getContentUpdatePermissionEntryByStatus(
          'revertToDraft',
          input.contentStatus,
        ),
        input.apiId,
      )
    );
  }, [input.contentStatus, input.apiId, hasUpdateContentPermission]);

  /**
   * 「公開終了にする」ができる場合はtrue
   */
  const canBeReturnedToClosed = useMemo(() => {
    return (
      input.contentStatus === 'PUBLISH' && // 公開中の状態に限る
      hasUpdateContentPermission('unpublish', input.apiId)
    );
  }, [input.contentStatus, input.apiId, hasUpdateContentPermission]);

  /**
   * 「下書きを破棄」できる場合はtrue
   */
  const draftDeletable = useMemo(() => {
    return (
      input.contentStatus === 'PUBLISH_AND_DRAFT' && // 公開中、かつ下書きの状態に限る
      !input.underReviewing &&
      hasUpdateContentPermission('removeDraft', input.apiId)
    );
  }, [
    input.contentStatus,
    input.underReviewing,
    input.apiId,
    hasUpdateContentPermission,
  ]);

  /**
   * 公開予約、または公開終了予約ができる場合はtrue
   *
   * この場合は、どちらか一方の操作ができる場合なので、それぞれで操作可能かを判定したい場合は、個別で判定する必要がある
   */
  const canScheduleContent = useMemo(() => {
    return (
      !input.underReviewing &&
      (hasUpdateContentPermission('publish', input.apiId) ||
        hasUpdateContentPermission('unpublish', input.apiId))
    );
  }, [input.apiId, input.underReviewing, hasUpdateContentPermission]);

  /**
   * 「公開」ができる場合はtrue
   */
  const canSavePublish = useMemo(() => {
    if (input.isEditing) {
      return (
        !input.underReviewing &&
        // NOTE: 編集時はUPDATE権限の方を参照する
        hasUpdateContentPermission('publish', input.apiId)
      );
    }
    return hasCreateContentPermission('publish', input.apiId);
  }, [
    input.isEditing,
    input.apiId,
    input.underReviewing,
    hasUpdateContentPermission,
    hasCreateContentPermission,
  ]);

  /**
   * 「下書き保存」ができる場合はtrue
   */
  const canSaveDraft = useMemo(() => {
    if (input.isEditing) {
      // NOTE: 編集時はUPDATE権限の方を参照する
      return hasUpdateContentPermission('saveDraft', input.apiId);
    }
    return hasCreateContentPermission('draft', input.apiId);
  }, [
    input.isEditing,
    input.apiId,
    hasUpdateContentPermission,
    hasCreateContentPermission,
  ]);

  return {
    contentCopyable,
    contentDeletable,
    canRevertToDraft,
    canBeReturnedToClosed,
    draftDeletable,
    canScheduleContent,
    canSavePublish,
    canSaveDraft,
  };
}

export { useContentOperationOnContentActions };
