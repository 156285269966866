import type React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './samlDenied.module.css';

type Props = {
  allowAccess: boolean;
  children: React.ReactElement;
};

const SAMLDenied: React.FC<Props> = ({ allowAccess, children }) => {
  const { t } = useTranslation('samlDenied');
  if (allowAccess === false) {
    return (
      <div className={styles.wrapper}>
        <div>
          <h1 className={styles.title}>
            {t('Log in to this service using SAML.')}
          </h1>
          <div className={styles.description}>
            <p>{t('Contact your system administrator for details.')}</p>
          </div>
        </div>
      </div>
    );
  }
  return children;
};

export default SAMLDenied;
