import type React from 'react';
import { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { useCopy } from '../../../hooks/Copy/useCopy';
import IconButton from '../../ui/IconButton';

import type { Param } from '../Params';

import styles from './code.module.css';

interface Props {
  inputPath: string;
  params: Param[];
  method: string;
  body: string;
  apiType: string;
}

const Php: React.FC<Props> = ({ inputPath, params, method, body, apiType }) => {
  const { t } = useTranslation('code');
  const [endpoint, contentId] = inputPath.split('/');
  const paramsString = useMemo(() => {
    const getValue = (key: string, value: string) => {
      // 数値
      if (['limit', 'offset', 'depth'].includes(key)) {
        return value;
      }
      // 配列
      if (['fields', 'ids', 'orders'].includes(key)) {
        return (
          '[' +
          value
            .split(',')
            .map((v) => `"${v}"`)
            .join(`, `) +
          ']'
        );
      }
      // 文字列
      return `"${value}"`;
    };
    return params
      .map((p) => `  "${p.key}" => ${getValue(p.key, p.value)}`)
      .join(`, \n`);
  }, [params]);

  const enabledParams = params
    .filter((p) => p.check)
    .reduce((prev, obj) => ({ ...prev, [obj.key]: obj.value }), {});

  const code =
    method === 'GET'
      ? `$result = $client->${
          contentId || apiType === 'PAGE' ? 'get' : 'list'
        }("${endpoint}"` +
        (contentId ? `, "${contentId}"` : '') +
        (params.length > 0 ? `, [\n${paramsString}\n]` : '') +
        ');\n' +
        'var_dump($result);'
      : method === 'POST'
        ? `$client->create(\n  "${endpoint}"` +
          (body ? `,\n  json_decode('${body}', true)` : '') +
          (Object.keys(enabledParams).length !== 0
            ? `,\n  json_decode('${JSON.stringify(enabledParams)}', true)`
            : '') +
          '\n);'
        : method === 'PUT'
          ? `$client->create(\n  "${endpoint}"` +
            (body
              ? `,\n  json_decode('${JSON.stringify({
                  id: contentId,
                  ...JSON.parse(body),
                })}', true)`
              : '') +
            (Object.keys(enabledParams).length !== 0
              ? `,\n  json_decode('${JSON.stringify(enabledParams)}', true)`
              : '') +
            '\n);'
          : method === 'PATCH'
            ? `$client->update(\n  "${endpoint}"` +
              (body
                ? `,\n  json_decode('${JSON.stringify({
                    id: contentId,
                    ...JSON.parse(body),
                  })}', true)`
                : '') +
              '\n);'
            : method === 'DELETE'
              ? `$client->delete("${endpoint}", "${contentId}");`
              : t('Not supported');

  const [copied, copyAction, setCopied] = useCopy(code);
  const copyApiKey = () => {
    copyAction();

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <>
      <SyntaxHighlighter
        language="php"
        style={docco}
        customStyle={{ fontSize: 14, padding: '16px', borderRadius: '4px' }}
      >
        {code}
      </SyntaxHighlighter>
      <p className={styles.note}>
        {Trans({
          t,
          i18nKey: 'Above is an example using microcms-php-sdk.',
          children: (
            <a
              href="https://github.com/microcmsio/microcms-php-sdk"
              target="sdk"
            >
              microcms-php-sdk
            </a>
          ),
        })}
      </p>
      <div className={styles.copyButton}>
        <IconButton
          icon={copied ? 'check' : 'content_copy'}
          hasFrame={true}
          onClick={copyApiKey}
        />
      </div>
    </>
  );
};

export default Php;
