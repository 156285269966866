import deepEqual from 'deep-equal';
import { useContext, useEffect, useState } from 'react';

import {
  HasDiffContext,
  HasDiffUpdateContext,
} from '../context/HasDiffContext';

export const useHasDiff = <T, U>(first?: T, second?: U) => {
  const [isSameFields, setIsSameFields] = useState<boolean>(false);
  const { hasDiff } = useContext(HasDiffContext);
  const { setHasDiff } = useContext(HasDiffUpdateContext);

  useEffect(() => {
    setIsSameFields(deepEqual(first, second));
  }, [first, second]);

  useEffect(() => {
    setHasDiff(!isSameFields);
  }, [isSameFields, setHasDiff]);

  // 画面遷移時にはStatusをリセットする
  useEffect(() => {
    return () => {
      setHasDiff(false);
    };
  }, [setHasDiff]);

  return { hasDiff };
};
