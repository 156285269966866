import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import {
  uploadMedium,
  updateMediumCreatedBy,
  deleteAllMedia as deleteAllMediaUsecase,
} from '@/usecase/mediumUsecase';

import { useRegisterTopicEvent } from '@/views/Common/providers/momento';

type UseUploadMediumArgs = {
  serviceId?: string;
  domain?: string;
};

export const useUploadMedium = ({ serviceId, domain }: UseUploadMediumArgs) => {
  const { t } = useTranslation('commonMedium');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  const [progress, setProgress] = useState<{
    loaded: number;
    total: number;
  } | null>(null);

  const upload = async (files: FileList | File[]) => {
    return uploadMedium({
      serviceId,
      domain,
      files,
      onSuccessEach: () => {
        setProgress((prev) => {
          if (!prev) {
            // ここには来ないはずなのでエラーを投げる
            throw new Error('Progress is null.');
          }
          return {
            ...prev,
            loaded: prev.loaded + 1,
          };
        });
      },
    });
  };

  const mutation = useMutation({
    mutationFn: upload,
    onMutate: (files) => {
      setProgress({ loaded: 0, total: files.length });
    },
    onSuccess: () => {
      // 完了した瞬間を表示するためprogressをnullにするのは200ms後にする
      setTimeout(() => setProgress(null), 200);

      cache.invalidateQueries(['mediumCount'], { type: 'all' });
      cache.invalidateQueries(['media'], { type: 'all' });
    },
    onError: (error) => {
      setProgress(null);
      if (error instanceof Error) {
        addToast(error.message, { appearance: 'error' });
      } else {
        addToast(t('Could not upload media.'), { appearance: 'error' });
      }
    },
  });

  return {
    uploadMedium: mutation.mutate,
    uploadMediumAsync: mutation.mutateAsync,
    uploadMediumProgress: progress,
    rawUseMutationReturnValue: mutation,
  };
};

type UseUpdateMediumCreatedByArgs = {
  serviceId: string;
  mediumId: string;
  onSettled?: () => void;
};

export const useUpdateMediumCreatedBy = ({
  serviceId,
  mediumId,
  onSettled,
}: UseUpdateMediumCreatedByArgs) => {
  const { t } = useTranslation('commonMedium');

  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const mutation = useMutation({
    mutationFn: async (username: string) => {
      return await updateMediumCreatedBy({
        serviceId,
        mediumId,
        createdBy: username,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['media'], { type: 'all' });
      queryClient.invalidateQueries(['medium', { mediumId }]);
      addToast(t('Changed media creator.'), { appearance: 'success' });
    },
    onError: (error: unknown) => {
      addToast(
        error instanceof Error
          ? error.message
          : t('Could not change media creator.'),
        { appearance: 'error' },
      );
    },
    onSettled,
  });

  return {
    updateMediumCreatedBy: mutation.mutate,
    updateMediumCreatedByAsync: mutation.mutateAsync,
    isLoadingUpdateMediumCreatedBy: mutation.isLoading,
    rawUseMutationReturnValue: mutation,
  };
};

type UseDeleteMediaArgs = {
  serviceId: string;
  serviceName: string;
};

export const useDeleteAllMedia = ({
  serviceId,
  serviceName,
}: UseDeleteMediaArgs) => {
  const { t } = useTranslation('commonMedium');
  const queryClient = useQueryClient();
  const { addToast } = useToasts();
  const { register } = useRegisterTopicEvent();

  const { mutate: deleteAllMedia, isLoading: deleteAllMediaLoading } =
    useMutation({
      mutationFn: () => {
        return deleteAllMediaUsecase({ serviceId });
      },
      onSuccess() {
        addToast(t('All media have been started for deletion.'), {
          appearance: 'success',
        });
        register((topicValue, done) => {
          if (
            topicValue.eventName === 'deleteAllMedia' &&
            topicValue.serviceId === serviceId
          ) {
            if (topicValue.status === 'SUCCEEDED') {
              addToast(
                t(
                  'Deletion of all media of the {{serviceName}} service has been completed.',
                  { serviceName },
                ),
                {
                  appearance: 'success',
                },
              );
              queryClient.invalidateQueries(['media'], { type: 'all' });
              queryClient.invalidateQueries(['mediumCount'], { type: 'all' });
              done();
            } else if (topicValue.status === 'FAILED') {
              addToast(
                topicValue.message ||
                  t(
                    'Deletion of all media of the {{serviceName}} service failed.',
                    {
                      serviceName,
                    },
                  ),
                {
                  appearance: 'error',
                },
              );
              done();
            }
          }
        });
      },
    });

  return { deleteAllMedia, deleteAllMediaLoading };
};
