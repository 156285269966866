import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { Tag } from '../../../ui/Tag';

import type { SettedTag } from '../TagsSettings';

import styles from './tagsInProgressGroup.module.css';

export type Props = {
  tags: SettedTag[];
  onClickDeleteButton: (index: number) => void;
  onClickResetButton: () => void;
  errors: string[];
};

const TagsInProgressGroup: React.FC<Props> = ({
  tags,
  onClickDeleteButton,
  onClickResetButton,
  errors,
}) => {
  const { t } = useTranslation('tagsSettings');

  return (
    <div>
      <h4>{t('Tags that have been set')}</h4>
      <div
        className={cx(styles.listWrapper, errors.length > 0 && styles.error)}
      >
        <ul className={styles.list}>
          {tags.map(({ name, ...rest }, i) => (
            <li key={rest.new ? i : rest.mediumTagId} className={styles.item}>
              <Tag
                name={name}
                icon="sell"
                onDelete={() => onClickDeleteButton(i)}
              />
            </li>
          ))}
        </ul>
        <button
          type="button"
          onClick={onClickResetButton}
          className={cx(styles.resetButton, styles.override)}
          // closeだと意味が通じないのでaria-labelで上書き
          aria-label={t('Remove all tags that are being set')}
        >
          <span className="material-icons">close</span>
        </button>
      </div>
      <ul className={styles.errorList}>
        {errors.map((error) => (
          <li key={error}>
            <p role="alert" className={styles.error}>
              {error}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TagsInProgressGroup;
