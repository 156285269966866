import { accountRepository } from '@/repository/accountRepository';

export const deleteAccount = async () => {
  const { deleteAccount } = accountRepository();
  return await deleteAccount();
};

export const createAccountLanguage = async (userLanguage: string) => {
  const { createAccountLanguage } = accountRepository();
  return await createAccountLanguage(userLanguage);
};
