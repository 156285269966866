import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Props } from './UpdatePublishedAtModal';

import { useToggle } from '@/views/Common/Ui/useToggle';

type UseUpdatePublishedAtModalArgs = Pick<Props, 'needSave'>;

export const useUpdatePublishedAtModal = ({
  needSave,
}: UseUpdatePublishedAtModalArgs) => {
  const { t } = useTranslation('updatePublishedAtModal');
  const [isOpen, onOpenChange, open, close] = useToggle(false);
  const onClickOfTrigger = useCallback(() => {
    if (needSave()) {
      window.alert(
        t(
          'Changes have not been saved. Save and then change the publish date and time.',
        ),
      );
      return;
    }
    open();
  }, [needSave, open, t]);

  return {
    open: isOpen,
    onOpenChange,
    onClickOfTrigger,
    close,
  };
};
