import { connect } from 'react-redux';

import { accountSelectors } from '../../ducks/account';
import GuestOnlyRoute from './GuestOnlyRoute';

const mapStateToProps = (state) => {
  return accountSelectors.getAccountState(state.accountState);
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GuestOnlyRoute);
