import { roleRepository } from '@/repository/roleRepository';

import type {
  CreateRoleVarsStringified,
  Role,
  UpdateRoleVarsStringified,
} from '@/entity/role';
import type { Service } from '@/types/services';

export const createRole = async ({
  serviceId,
  name,
  settings,
}: CreateRoleVarsStringified) => {
  const { createRole } = roleRepository();

  return await createRole({
    serviceId,
    name,
    settings,
  });
};

export const updateRole = async ({
  serviceId,
  roleId,
  name,
  settings,
}: UpdateRoleVarsStringified) => {
  const { updateRole } = roleRepository();

  return await updateRole({
    serviceId,
    roleId,
    name,
    settings,
  });
};

export const deleteRole = async ({
  serviceId,
  roleId,
}: {
  serviceId: string;
  roleId: Role['roleId'];
}) => {
  const { deleteRole } = roleRepository();

  return await deleteRole({
    serviceId,
    roleId,
  });
};

export const fetchServiceRoleForForm = async (props: {
  serviceId: Service['partitionKey'];
  roleId: Role['roleId'];
}) => {
  const { fetchServiceRoleForForm } = roleRepository();
  return fetchServiceRoleForForm(props);
};
