/**
 * numの値から1~Nの配列を生成
 * @returns [number]
 */
function arrayNumbers(num: number): Array<number> {
  return [...Array(num)].map((_, i) => i + 1);
}

/**
 * 配列中要素に重複がある場合はtrueを返す
 *
 * Setを用いて重複を検出している。
 * 要素がオブジェクトの場合は参照が異なるため、正しく判定できない。
 * 従って、ここではプリミティブな型（※）を要素に持つ配列を対象とする。
 *
 * ※ symbol,undefined,nullは意図的に除いている
 *
 * @see {@link https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Set}
 */
function hasDuplicates<T extends string | number | bigint | boolean>(
  arr: Array<T> | ReadonlyArray<T>,
): boolean {
  return new Set(arr).size !== arr.length;
}

/**
 * 配列中の重複する値を削除する
 * オブジェクトの形式は { id: string; value: string } であることを前提としている
 */
function filterUniqueValues<T extends { id: string; value: string } | null>(
  items: T[],
): T[] {
  return items.filter(
    (currentItem, currentIndex, allItems) =>
      currentIndex ===
      allItems.findIndex((item) => item?.value === currentItem?.value),
  );
}

export { hasDuplicates, arrayNumbers, filterUniqueValues };
