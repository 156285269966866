import { Td } from '@/components/ui/Table';

import { FIELD_NO_VALUE } from '../constants';
import { useI18nFormRole } from '../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../provider';

import { Checkbox } from '@/views/Common/Ui/Checkbox';

const FieldBilling = () => {
  const { t } = useI18nFormRole();

  const {
    isAdminRole,
    input: { billingPermission },
  } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  return (
    <>
      <Td>{t('Payment')}</Td>
      <Td>
        <Checkbox
          checked={billingPermission.read}
          disabled={disabled}
          name="billingPermission.read"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-billing-permission-read',
              payload,
            });
          }}
          label={t('Access to payment setting page')}
        />
      </Td>
      <Td>{FIELD_NO_VALUE}</Td>
      <Td>
        <Checkbox
          checked={billingPermission.update}
          disabled={disabled}
          name="billingPermission.update"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-billing-permission-update',
              payload,
            });
          }}
          label={t('Update plan and\nupdate credit card')}
        />
      </Td>
      <Td>{FIELD_NO_VALUE}</Td>
    </>
  );
};

export default FieldBilling;
