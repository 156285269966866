import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useLoadApis } from '@/hooks/ApiList/useApiListReader';
import { useGetMyService } from '@/hooks/useService';

import Head from '../Head';
import NotFound from '../NotFound';
import Loading from '../ui/Loading';

import styles from './editRole.module.css';

import { FormRole, useFetchRoleForForm } from '@/views/Role/FormRole';

const EditRole = () => {
  const { t } = useTranslation('editRole');
  const { service } = useGetMyService();

  const { roleId } = useParams<{
    roleId: string;
  }>();
  const { apiList } = useLoadApis(service);

  const {
    data: originalRole,
    isLoading,
    isError,
  } = useFetchRoleForForm(roleId, service?.partitionKey);

  return (
    <div className={styles.wrapper}>
      <Head title={t('Role Details')} />
      {!originalRole && isLoading && <Loading />}
      {!originalRole && isError && <NotFound />}
      {service && originalRole && apiList && !isLoading && !isError && (
        <FormRole
          currentServiceId={service.partitionKey}
          originalRole={originalRole}
          apiSchemas={apiList.list}
          editRole={true}
        />
      )}
    </div>
  );
};

export default EditRole;
