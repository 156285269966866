import { Color } from '@tiptap/extension-color';

import type { ColorOptions } from '@tiptap/extension-color';

type CustomColorOptions = ColorOptions & {
  inputRules: boolean;
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-color/src/color.ts
export const CustomColor = Color.extend<CustomColorOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: true,
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            parseHTML: (element) =>
              element.style.color
                ? element.style.color?.replace(/['"]+/g, '')
                : null,
            renderHTML: (attributes) => {
              if (!attributes.color) {
                return {};
              }

              return {
                style: `color: ${attributes.color}`,
                // コピーアンドペースト用に独自の属性を追加
                'data-richeditor-color': true,
              };
            },
          },
        },
      },
    ];
  },
});
