import { useGlobalApiIpAddressRestriction } from '@/views/Common/apiIpAddressRestriction/useApiIpAddressRestrictionReader';
import { useCurrentService } from '@/views/Common/service/useServiceReader';

export const useApiIpAddressRestrictionSettingsLoader = () => {
  const { data: service } = useCurrentService();
  const { data: initialIpList, isLoading: isInitialIpListLoading } =
    useGlobalApiIpAddressRestriction(service?.partitionKey);

  return {
    service,
    initialIpList: initialIpList,
    isInitialIpListLoading,
  };
};
