import { useCallback, useEffect, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';

import { validateUrl } from '@/components/Validations';

import { useAdmin } from '@/hooks/Role/useMyRoles';
import { useStripeActions } from '@/hooks/useStripeActions';

import { useSAMLQuery } from './useSAMLQuery';

import type { Service } from '@/types/services';

import { useInput } from '@/views/Common/useInput';

export const useSAML = (service: Service) => {
  const { t } = useTranslation('serviceSAMLSettings');
  const serviceId = service.partitionKey;

  const {
    useFindSAMLConfiguration,
    useUpdateSAMLConfiguration,
    useDeleteSAMLConfiguration,
    useAllRoles,
  } = useSAMLQuery(serviceId);

  const [Auth0Modal, onClickConfigureWithAuth0] = useModal('root');

  const { data: samlConfiguration, isLoading: isSAMLConfigurationLoading } =
    useFindSAMLConfiguration();

  const {
    mutate: updateSAMLConfiguration,
    isLoading: isSetSAMLConfigurationLoading,
  } = useUpdateSAMLConfiguration();

  const {
    mutate: deleteSAMLConfiguration,
    isLoading: isDeleteSAMLConfigurationLoading,
  } = useDeleteSAMLConfiguration();

  const { data: allRoles = [], isLoading: isAllRolesLoading } = useAllRoles();

  const [
    inputtingIdpMetadataURL,
    onChangeIdpMetadataURL,
    idpMetadataURLError,
    setInputtingIdpMetadataURL,
  ] = useInput('', validateUrl);

  const [
    inputtingIdpMetadataXML,
    onChangeIdpMetadataXML,
    idpMetadataXMLError,
    setInputtingIdpMetadataXML,
  ] = useInput('', () => null);

  const [inputtingDefaultRoleId, setDefaultRoleId] = useState<
    string | undefined
  >();

  const [shouldShowURLField, setShouldShowURLField] = useState(true);

  const [isSAMLOnly, setSAMLOnly] = useState(false);

  const { currentPlan } = useStripeActions(service);

  const [isAdmin = false] = useAdmin();

  /*
   * 初期値をセットするためのuseEffect
   */
  useEffect(() => {
    /*
     * 一度しか実行しないように制御する
     * inputtingDefaultRoleIdがセット済みであればuseEffect実行済み
     * setInputtingIdpMetadataURL, setInputtingIdpMetadataXMLが毎回変更されるためこのようなワークアラウンドになっている
     */
    if (inputtingDefaultRoleId) {
      return;
    }

    // SAML設定値が取得できている&&ロールが取得できている場合のみ初期値をセットする
    if (samlConfiguration && allRoles.length > 0) {
      // デフォルトロールの初期値をセットする
      if (samlConfiguration.defaultRoleId) {
        // SAML設定値があれば、それを初期値とする
        setDefaultRoleId(samlConfiguration.defaultRoleId);
      } else {
        // 設定済みの値が無ければロールの最初を初期値とする
        setDefaultRoleId(allRoles[0].roleId);
      }

      // 設定済みのidpMetadataURLがあればそれを初期値とする
      if (samlConfiguration.idpMetadataURL) {
        setInputtingIdpMetadataURL(samlConfiguration.idpMetadataURL);
      }

      // 設定済みのidpMetadataXMLがあればそれを初期値とする
      if (samlConfiguration.idpMetadataXML) {
        setInputtingIdpMetadataXML(samlConfiguration.idpMetadataXML);
        // XMLのタブを選択状態にする
        setShouldShowURLField(false);
      }

      // SAML Onlyの初期値をセットする
      if (samlConfiguration.samlOnly) {
        setSAMLOnly(samlConfiguration.samlOnly);
      }
    }
  }, [
    isSAMLConfigurationLoading,
    isAllRolesLoading,
    allRoles,
    samlConfiguration,
    setInputtingIdpMetadataURL,
    setInputtingIdpMetadataXML,
    inputtingDefaultRoleId,
  ]);

  /**
   * URLかXMLが設定済みでSAMLが無効なプランであれば、削除だけできる
   */
  const isDeleteOnly: boolean =
    !currentPlan.limit.enableSAML &&
    (!!samlConfiguration?.idpMetadataURL ||
      !!samlConfiguration?.idpMetadataXML);

  const isReadOnlyExceptAdmin = !isAdmin || isDeleteOnly;

  const shouldShowLoginURL: boolean =
    !!samlConfiguration?.loginURL && !isDeleteOnly;

  const isUpdating: boolean =
    isSetSAMLConfigurationLoading || isDeleteSAMLConfigurationLoading;

  const isSubmitButtonDisabled: boolean =
    isUpdating || // 更新中はボタンを無効化する
    isDeleteOnly || // 削除だけできる場合は無効化する
    !!idpMetadataURLError || // URLの入力エラーがあれば無効化する
    !!idpMetadataXMLError || // XMLの入力エラーがあれば無効化する
    (!inputtingIdpMetadataURL.length && !inputtingIdpMetadataXML.length); // URLとXMLのどちらも入力されていなければ無効化する

  const isLoading: boolean = isSAMLConfigurationLoading || isAllRolesLoading;

  const idpMetadataURLForRequest =
    inputtingIdpMetadataURL || samlConfiguration?.idpMetadataURL;
  const idpMetadataXMLForRequest =
    inputtingIdpMetadataXML || samlConfiguration?.idpMetadataXML;
  const defaultRoleIdForRequest =
    inputtingDefaultRoleId || samlConfiguration?.defaultRoleId;

  const onClickSaveButton = useCallback(() => {
    if (!defaultRoleIdForRequest) {
      return;
    }
    if (isSubmitButtonDisabled) {
      return;
    }

    //  URLタブかXMLタブのどちらを選択中かで処理を分ける
    if (shouldShowURLField) {
      updateSAMLConfiguration({
        serviceId: serviceId,
        idpMetadataURL: idpMetadataURLForRequest,
        idpMetadataXML: undefined,
        defaultRoleId: defaultRoleIdForRequest,
        samlOnly: isSAMLOnly,
      });
    } else {
      updateSAMLConfiguration({
        serviceId: serviceId,
        idpMetadataURL: undefined,
        idpMetadataXML: idpMetadataXMLForRequest,
        defaultRoleId: defaultRoleIdForRequest,
        samlOnly: isSAMLOnly,
      });
    }
  }, [
    defaultRoleIdForRequest,
    idpMetadataURLForRequest,
    idpMetadataXMLForRequest,
    isSAMLOnly,
    serviceId,
    isSubmitButtonDisabled,
    updateSAMLConfiguration,
    shouldShowURLField,
  ]);

  const onClickDeleteButton = useCallback(() => {
    const confirmed = window.confirm(
      [
        t('Do you really want to remove the SAML settings?'),
        t(
          'Deleting SAML settings also removes members who have logged in through SAML integration from this service.',
        ),
      ].join('\n'),
    );

    if (confirmed) {
      deleteSAMLConfiguration({ serviceId });
    }
  }, [serviceId, deleteSAMLConfiguration, t]);

  return {
    Auth0Modal,
    onClickConfigureWithAuth0,
    samlConfiguration,
    allRoles,
    onChangeIdpMetadataURL,
    idpMetadataURLError,
    setDefaultRoleId,
    onChangeIdpMetadataXML,
    idpMetadataXMLError,
    isSAMLOnly,
    setSAMLOnly,
    onClickSaveButton,
    onClickDeleteButton,
    isReadOnlyExceptAdmin,
    isDeleteOnly,
    isAdmin,
    shouldShowLoginURL,
    isUpdating,
    isSubmitButtonDisabled,
    isLoading,
    shouldShowURLField,
    setShouldShowURLField,
    inputtingIdpMetadataURL,
    inputtingIdpMetadataXML,
  };
};
