import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Toolbar from '@radix-ui/react-toolbar';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useHeading } from './useHeading';

import type { RichEditorV2Options } from '@/constants/richEditorOptions';
import type { Editor } from '@tiptap/react';

import style from './heading.module.css';

export type Props = {
  editor: Editor;
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
};

export type ViewProps = {
  headingValue: string;
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
  toggleHeadingHandler: (value: string) => void;
  isActiveHeading: boolean;
  editable: boolean;
};

export const HeadingView: React.FC<ViewProps> = ({
  headingValue,
  richEditorV2Options,
  toggleHeadingHandler,
  isActiveHeading,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <DropdownMenu.Root modal={false}>
      <Toolbar.Button asChild>
        <DropdownMenu.Trigger
          className={cx(style.trigger, {
            [style.isActive]: isActiveHeading,
          })}
          aria-label={t('Heading')}
          disabled={!editable}
        >
          {headingValue}
          <span className="material-icons-outlined">keyboard_arrow_down</span>
        </DropdownMenu.Trigger>
      </Toolbar.Button>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={style.content}
          sideOffset={2}
          align="start"
          onEscapeKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <DropdownMenu.Group>
            {richEditorV2Options.includes('headerOne') && (
              <DropdownMenu.Item
                onSelect={() => toggleHeadingHandler(t('Heading1'))}
                className={cx(style.item, style.heading1, {
                  [style.isActive]: headingValue === t('Heading1'),
                })}
              >
                {t('Heading1')}
              </DropdownMenu.Item>
            )}
            {richEditorV2Options.includes('headerTwo') && (
              <DropdownMenu.Item
                onSelect={() => toggleHeadingHandler(t('Heading2'))}
                className={cx(style.item, style.heading2, {
                  [style.isActive]: headingValue === t('Heading2'),
                })}
              >
                {t('Heading2')}
              </DropdownMenu.Item>
            )}
            {richEditorV2Options.includes('headerThree') && (
              <DropdownMenu.Item
                onSelect={() => toggleHeadingHandler(t('Heading3'))}
                className={cx(style.item, style.heading3, {
                  [style.isActive]: headingValue === t('Heading3'),
                })}
              >
                {t('Heading3')}
              </DropdownMenu.Item>
            )}
            {richEditorV2Options.includes('headerFour') && (
              <DropdownMenu.Item
                onSelect={() => toggleHeadingHandler(t('Heading4'))}
                className={cx(style.item, style.heading4, {
                  [style.isActive]: headingValue === t('Heading4'),
                })}
              >
                {t('Heading4')}
              </DropdownMenu.Item>
            )}
            {richEditorV2Options.includes('headerFive') && (
              <DropdownMenu.Item
                onSelect={() => toggleHeadingHandler(t('Heading5'))}
                className={cx(style.item, style.heading5, {
                  [style.isActive]: headingValue === t('Heading5'),
                })}
              >
                {t('Heading5')}
              </DropdownMenu.Item>
            )}
            {richEditorV2Options.includes('paragraph') && (
              <DropdownMenu.Item
                onSelect={() => toggleHeadingHandler(t('Paragraph'))}
                className={cx(style.item, style.paragraph)}
              >
                {t('Paragraph')}
              </DropdownMenu.Item>
            )}
          </DropdownMenu.Group>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export const Heading: React.FC<Props> = ({ editor, richEditorV2Options }) => {
  const { headingValue, toggleHeadingHandler, isActiveHeading } = useHeading({
    editor,
  });

  return (
    <HeadingView
      headingValue={headingValue}
      richEditorV2Options={richEditorV2Options}
      toggleHeadingHandler={toggleHeadingHandler}
      isActiveHeading={isActiveHeading}
      editable={editor.isEditable}
    />
  );
};
