import type * as React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './forbidden.module.css';

const Forbidden: React.FC = () => {
  const { t } = useTranslation('forbidden');

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img className={styles.img} src="/images/icon_role_lock.svg" alt="" />
        <h2 className={styles.title}>
          {t('You do not have access permission')}
        </h2>
      </div>
    </div>
  );
};

export default Forbidden;
