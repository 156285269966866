import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import {
  updateReviewRequest as updateReviewRequestUsecase,
  toggleReviewRequestStatus as toggleReviewRequestStatusUsecase,
  closeReviewRequestAndPublishContent as closeReviewRequestAndPublishContentUsecase,
  createReviewRequestComment as createReviewRequestCommentUsecase,
  deleteReviewRequestComment as deleteReviewRequestCommentUsecase,
} from '@/usecase/reviewUsecase';

import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

import { contentQueryKeys } from '@/views/Common/content/queryKeys';

export const useReview = (service, reqId) => {
  const { t } = useTranslation('hooksReview');
  const cache = useQueryClient();

  const { addToast } = useToasts();

  // レビューリクエストを取得
  const reviewRequestQuery = useQuery({
    queryKey: ['reviewRequest', { reqId }],
    queryFn: () => {
      if (service && reqId) {
        return API.graphql(
          graphqlOperation(queries.findReviewRequest, {
            serviceId: service.partitionKey,
            reqId,
          }),
        ).then((result) => result.data.findReviewRequest);
      }
      return;
    },
    staleTime: Number.POSITIVE_INFINITY,
  });

  // レビューイベントを取得
  const reviewRequestEventsQuery = useQuery({
    queryKey: ['reviewRequestEvents', { reqId }],
    queryFn: () => {
      if (service && reqId) {
        return API.graphql(
          graphqlOperation(queries.getAllReviewRequestEvent, {
            serviceId: service.partitionKey,
            reqId,
          }),
        ).then((result) => JSON.parse(result.data.getAllReviewRequestEvent));
      }
      return;
    },
    staleTime: Number.POSITIVE_INFINITY,
  });

  const { data: reviewRequest } = reviewRequestQuery;
  const { data: reviewRequestEvents } = reviewRequestEventsQuery;

  // レビューリクエストの内容更新
  const { mutate: updateReviewRequest, isLoading: updateReviewRequestLoading } =
    useMutation({
      mutationFn: ({
        title,
        description,
        reservationTime,
        reservationStopTime,
      }) => {
        return updateReviewRequestUsecase({
          serviceId: service.partitionKey,
          reqId,
          title,
          description,
          reservationTime: reservationTime ? reservationTime : undefined,
          reservationStopTime: reservationStopTime
            ? reservationStopTime
            : undefined,
        });
      },
      onSuccess(_, { setEditing, closeReservationModal, toast }) {
        cache.invalidateQueries(['reviewRequest', { reqId }], {
          type: 'all',
        });
        cache.invalidateQueries(['reviewRequestEvents', { reqId }], {
          type: 'all',
        });
        setEditing(false);
        if (toast !== false) {
          addToast(t('Review request details have been updated.'), {
            appearance: 'success',
          });
        }

        closeReservationModal && closeReservationModal();
      },
      onError({ errors }) {
        addToast(
          errors
            ? errors[0].message
            : t('Could not update review request details.'),
          {
            appearance: 'error',
          },
        );
      },
    });

  // レビューリクエストの内容更新
  const {
    mutate: updateReviewRequestReservationTime,
    isLoading: updateReviewRequestReservationTimeLoading,
  } = useMutation({
    mutationFn: ({ reservationTime, reservationStopTime }) => {
      return API.graphql(
        graphqlOperation(mutations.updateReviewRequestReservationTime, {
          serviceId: service.partitionKey,
          reqId,
          reservationTime: reservationTime ? reservationTime : undefined,
          reservationStopTime: reservationStopTime
            ? reservationStopTime
            : undefined,
        }),
      );
    },
    onSuccess(result, { setEditing, closeReservationModal, toast }) {
      if (result?.data?.updateReviewRequestReservationTime?.result === false) {
        addToast(
          result?.data?.updateReviewRequestReservationTime?.message ||
            t('Could not update review request details.'),
          {
            appearance: 'error',
          },
        );
        return;
      }

      cache.invalidateQueries(['reviewRequest', { reqId }], { type: 'all' });
      cache.invalidateQueries(['reviewRequestEvents', { reqId }], {
        type: 'all',
      });
      setEditing(false);
      if (toast !== false) {
        addToast(t('Review request details have been updated.'), {
          appearance: 'success',
        });
      }

      closeReservationModal && closeReservationModal();
    },
    onError({ errors }) {
      addToast(
        errors
          ? errors[0].message
          : t('Could not update review request details.'),
        {
          appearance: 'error',
        },
      );
    },
  });

  // レビューステータスの変更
  const {
    mutate: toggleReviewRequestStatus,
    isLoading: toggleReviewRequestStatusLoading,
  } = useMutation({
    mutationFn: () => {
      return toggleReviewRequestStatusUsecase({
        serviceId: service.partitionKey,
        reqId,
      });
    },
    onSuccess() {
      cache.invalidateQueries(['reviewRequest', { reqId }], { type: 'all' });
      cache.invalidateQueries(['reviewRequestEvents', { reqId }], {
        type: 'all',
      });
      cache.invalidateQueries(['reviewRequests'], { type: 'all' });
      cache.invalidateQueries(['reviewRequestsCount'], { type: 'all' });
      cache.invalidateQueries(['reviewRequestAccessInfo'], { type: 'all' });
      cache.invalidateQueries(['contentList'], { type: 'all' });
      addToast(t('Review status has been updated.'), {
        appearance: 'success',
      });
    },
    onError({ errors }) {
      addToast(
        errors ? errors[0].message : t('Could not update review status.'),
        {
          appearance: 'error',
        },
      );
    },
  });

  // レビューリクエストを終了し、コンテンツを公開する
  const {
    mutate: closeReviewRequestAndPublishContent,
    isLoading: closeReviewRequestAndPublishContentLoading,
  } = useMutation({
    mutationFn: () => {
      return closeReviewRequestAndPublishContentUsecase({
        serviceId: service.partitionKey,
        reqId,
      });
    },
    onSuccess(_, { reservationTime, contentId }) {
      cache.invalidateQueries(['reviewRequest', { reqId }], { type: 'all' });
      cache.invalidateQueries(['reviewRequestEvents', { reqId }], {
        type: 'all',
      });
      cache.invalidateQueries(['reviewRequests'], { type: 'all' });
      cache.invalidateQueries(['reviewRequestsCount'], { type: 'all' });
      cache.invalidateQueries(['reviewRequestAccessInfo'], { type: 'all' });
      cache.invalidateQueries(contentQueryKeys.detail(contentId), {
        type: 'all',
      });
      cache.invalidateQueries(['contentList'], { type: 'all' });
      addToast(
        reservationTime
          ? t('Scheduled to set the content as Published.')
          : t('Content has been published.'),
        {
          appearance: 'success',
        },
      );
    },
    onError({ errors }) {
      addToast(errors ? errors[0].message : t('Could not publish content.'), {
        appearance: 'error',
      });
    },
  });

  // レビューリクエストにコメントする
  const {
    mutate: createReviewRequestComment,
    isLoading: createReviewRequestCommentLoading,
  } = useMutation({
    mutationFn: ({ comment }) => {
      return createReviewRequestCommentUsecase({
        serviceId: service.partitionKey,
        reqId,
        comment,
      });
    },
    onSuccess(_, { setComment }) {
      cache.invalidateQueries(['reviewRequestEvents', { reqId }], {
        type: 'all',
      });
      setComment('');
      addToast(t('Comment has been added.'), {
        appearance: 'success',
      });
    },
    onError({ errors }) {
      addToast(errors ? errors[0].message : t('Could not add comment.'), {
        appearance: 'error',
      });
    },
  });

  // レビューリクエストのコメントを削除する
  const {
    mutate: deleteReviewRequestComment,
    isLoading: deleteReviewRequestCommentLoading,
  } = useMutation({
    mutationFn: ({ eventId }) => {
      return deleteReviewRequestCommentUsecase({
        serviceId: service.partitionKey,
        reqId,
        eventId,
      });
    },
    onSuccess() {
      cache.invalidateQueries(['reviewRequestEvents', { reqId }], {
        type: 'all',
      });
      addToast(t('Comment has been deleted.'), {
        appearance: 'success',
      });
    },
    onError({ errors }) {
      addToast(errors ? errors[0].message : t('Could not delete comment.'), {
        appearance: 'error',
      });
    },
  });

  // レビュアーを追加する
  const { mutate: addReviewer, isLoading: addReviewerLoading } = useMutation({
    mutationFn: ({ reviewerId }) => {
      return API.graphql(
        graphqlOperation(mutations.addReviewer, {
          serviceId: service.partitionKey,
          reqId,
          reviewerId,
        }),
      ).then((result) => result.data.addReviewer);
    },
    onSuccess(data) {
      if (data.result === false) {
        return addToast(
          data.message ? data.message : t('Could not add reviewer.'),
          {
            appearance: 'error',
          },
        );
      }
      cache.invalidateQueries(['reviewRequest', { reqId }], { type: 'all' });
      cache.invalidateQueries(['reviewRequestEvents', { reqId }], {
        type: 'all',
      });
      addToast(t('Reviewer has been added.'), {
        appearance: 'success',
      });
    },
    onError({ errors }) {
      addToast(errors ? errors[0].message : t('Could not add reviewer.'), {
        appearance: 'error',
      });
    },
  });

  // レビュアーを削除する
  const { mutate: removeReviewer, isLoading: removeReviewerLoading } =
    useMutation({
      mutationFn: ({ reviewerId }) => {
        return API.graphql(
          graphqlOperation(mutations.removeReviewer, {
            serviceId: service.partitionKey,
            reqId,
            reviewerId,
          }),
        );
      },
      onSuccess(data) {
        if (data?.data?.removeReviewer?.result === true) {
          cache.invalidateQueries(['reviewRequest', { reqId }], {
            type: 'all',
          });
          cache.invalidateQueries(['reviewRequestEvents', { reqId }], {
            type: 'all',
          });
          addToast(t('Reviewer has been removed.'), {
            appearance: 'success',
          });
        } else {
          addToast(
            data?.data?.removeReviewer?.message
              ? data?.data?.removeReviewer?.message
              : t('Could not delete reviewer.'),
            {
              appearance: 'error',
            },
          );
        }
      },
      onError({ errors }) {
        addToast(errors ? errors[0].message : t('Could not delete reviewer.'), {
          appearance: 'error',
        });
      },
    });

  return {
    /** @type {import('@/entity/review').ReviewRequest | undefined} */
    reviewRequest,
    /** @type {import('@/entity/review').ReviewRequestEvent[] | undefined} */
    reviewRequestEvents,
    /** @type {import('@tanstack/react-query').UseMutateFunction<any, {error:any}, {title:string;description?:string;reservationTime?:Date|string;reservationStopTime?:Date|string;setEditing:import('react').Dispatch<import('react').SetStateAction<boolean>>;closeReservationModal?:() => void;toast?:boolean}>} */
    updateReviewRequest,
    updateReviewRequestLoading,
    /** @type {import('@tanstack/react-query').UseMutateFunction<any, {error:any}, {reservationTime?:Date|string;reservationStopTime?:Date|string;setEditing:import('react').Dispatch<import('react').SetStateAction<boolean>>;closeReservationModal:() => void}>} */
    updateReviewRequestReservationTime,
    updateReviewRequestReservationTimeLoading,
    toggleReviewRequestStatus,
    toggleReviewRequestStatusLoading,
    closeReviewRequestAndPublishContent,
    closeReviewRequestAndPublishContentLoading,
    /** @type {import('@tanstack/react-query').UseMutateFunction<any, {error:any}, {comment:string;setComment:(value: string) => void}>} */
    createReviewRequestComment,
    createReviewRequestCommentLoading,
    deleteReviewRequestComment,
    deleteReviewRequestCommentLoading,
    addReviewer,
    addReviewerLoading,
    removeReviewer,
    removeReviewerLoading,
  };
};
