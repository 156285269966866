class OpenAPIResponseError extends Error {
  status: number;

  constructor(response: Response) {
    super(response.statusText);

    this.name = OpenAPIResponseError.name;
    this.status = response.status;
  }

  isBadRequest() {
    return this.status === 400;
  }

  isUnauthorized() {
    return this.status === 401;
  }

  isForbidden() {
    return this.status === 403;
  }

  isNotFound() {
    return this.status === 404;
  }

  isTooManyRequests() {
    return this.status === 429;
  }
}

function isOpenAPIResponseError(error: unknown): error is OpenAPIResponseError {
  return error instanceof OpenAPIResponseError;
}

export { OpenAPIResponseError, isOpenAPIResponseError };
