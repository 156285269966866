import { useCallback } from 'react';

import type { Props } from './BulletList';

export const useBulletList = ({ editor }: Props) => {
  const toggleBulletListHandler = useCallback(() => {
    editor.chain().focus().toggleBulletList().run();
  }, [editor]);
  const isActiveBulletList = editor.isActive('bulletList');

  return {
    toggleBulletListHandler,
    isActiveBulletList,
  };
};
