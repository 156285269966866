import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { createReview as createReviewUsecase } from '@/usecase/reviewsUsecase';

import * as queries from '../../graphql/queries';

export const useCreateReview = (contentId, history) => {
  const { t } = useTranslation('hooksReview');
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const { mutate: createReviewRequest, isLoading: createReviewRequestLoading } =
    useMutation({
      mutationFn: async ({ title, description }) => {
        return await createReviewUsecase({
          title,
          description,
          contentId: `CONTENT#${contentId}`,
          // レビュー申請時はreservationTimeは作られない
          reservationTime: undefined,
        });
      },
      onSuccess(result) {
        cache.invalidateQueries(['reviewRequests'], { type: 'all' });
        cache.invalidateQueries(['reviewRequestsCount'], { type: 'all' });
        cache.invalidateQueries(['reviewRequestAccessInfo'], { type: 'all' });
        cache.invalidateQueries(['contentList'], { type: 'all' });
        addToast(t('Requested a review.'), {
          appearance: 'success',
        });
        // TODO: GO移植後に条件分岐elseを削除する（レスポンス型がresult.data.reqIdとなるため）
        if (result.data?.reqId) {
          history.push(`/reviews/${result.data.reqId}`);
        } else {
          history.push(`/reviews/${result.reqId}`);
        }
      },
      onError({ errors }) {
        addToast(
          errors ? errors[0].message : t('Could not request a review.'),
          {
            appearance: 'error',
          },
        );
      },
    });
  return { createReviewRequest, createReviewRequestLoading };
};

export const useReviews = (service, authorId, status) => {
  // データを取得
  const fetchData = ({ status }, cursor) => {
    return API.graphql(
      graphqlOperation(queries.getReviewRequestsByStatus, {
        serviceId: service.partitionKey,
        status,
        nextToken: JSON.stringify(cursor),
      }),
    ).then((result) => JSON.parse(result.data.getReviewRequestsByStatus));
  };

  const reviewRequestsQuery = useInfiniteQuery(
    ['reviewRequests', { status }],
    ({ pageParam }) => fetchData({ status }, pageParam),
    {
      staleTime: Number.POSITIVE_INFINITY,
      getNextPageParam: (lastGroup) => {
        return lastGroup.nextToken;
      },
    },
  );

  return [reviewRequestsQuery];
};

// レビュー件数を取得
// 左メニュー用
export const useReviewRequestsCount = (service) => {
  const reviewRequestsCountQuery = useQuery({
    queryKey: ['reviewRequestsCount'],
    queryFn: () => {
      if (service) {
        return API.graphql(
          graphqlOperation(queries.getOpenedReviewRequestsCount, {
            serviceId: service.partitionKey,
          }),
        ).then((result) => result.data.getOpenedReviewRequestsCount);
      }
      return;
    },
    enabled: !!service,
    staleTime: Number.POSITIVE_INFINITY,
  });
  const { data: reviewRequestsCount } = reviewRequestsCountQuery;
  return [reviewRequestsCount];
};
