import cx from 'classnames';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ApiListByRedux, MigrateApi } from '@/entity/api';
import type { ContentEditor } from '@/entity/contentEditor';
import { useLocalStorageItems } from '@/hooks/Content/useLocalStorageItems';
import { useToggleItem } from '@/hooks/Content/useToggleItem';
import { getUserNameWithFallback } from '@/util/service-user';
import { DatePicker } from '@/views/Common/Ui/DatePicker';
import { Modal, ModalContent, ModalTrigger } from '@/views/Common/Ui/Modal';
import { useToggle } from '@/views/Common/Ui/useToggle';

import {
  CONDITIONS,
  SPECIAL_KEYS,
  VALID_KINDS,
} from '../../../constants/contentFilterKey';
import type { SpecialKeys } from '../../../constants/contentFilterKey';
import { useMembers } from '../../../hooks/Member/useMembers';
import type { SelectItem } from '../../../types/field';
import type { Service } from '../../../types/services';
import { formatDatePicker } from '../../../util/date';
import RelationModal from '../../Form/RelationModal';
import SelectButton from '../../ui/SelectButton';
import Selectfield from '../../ui/Selectfield';

import { Icon } from '@/views/Common/Ui/Icon';
import styles from './row.module.css';

interface Filter {
  key: SpecialKeys;
  value: string;
  condition: string;
}

interface Props {
  service: Service;
  apiList: ApiListByRedux['apiList'];
  api: MigrateApi;
  contentCreators: ContentEditor[];
  contentEditors: ContentEditor[];
  filter: Filter;
  index: number;
  onChange: ({ key, value, condition }: Filter, index: number) => void;
  remove: (index: number) => void;
}

const NoPermissionModal: React.FC = () => {
  const { t } = useTranslation('row');
  return (
    <div className={styles.modal}>
      <span className={styles.noPermission}>
        <Icon name="visibility_off" size="large" className={styles.icon} />
        <span className={styles.text}>
          {t(
            'Cannot be displayed due to lack of read permissions for the target API.',
          )}
        </span>
      </span>
    </div>
  );
};

const Row: React.FC<Props> = ({
  service,
  apiList,
  api,
  contentCreators,
  contentEditors,
  filter,
  index,
  onChange,
  remove,
}) => {
  const { t } = useTranslation('row');

  // メンバー表示
  const { serviceUsers: members } = useMembers(service);

  const { key, value, condition } = filter;
  const isInvalidDate = (date: Date) =>
    date && date.getDate ? Number.isNaN(date.getDate()) : false;
  const [date, setDate] = useState(
    isInvalidDate(new Date(value)) ? undefined : dayjs(value).toDate(),
  );
  const [relationId, setRelationId] = useState<string | undefined>();
  const [selectItems, setSelectItems] = useState<SelectItem[] | undefined>([]);
  const relationApi = apiList?.find((_api) => _api.partitionKey === relationId);

  // コンテンツ参照を選択するモーダル
  const [isOpenRelationModal, toggleRelationModal] = useToggle(false);
  const closeRelationModal = useCallback(() => {
    toggleRelationModal(false);
  }, []);

  const getMemberDisplayName = useCallback(
    (member?: ContentEditor): string => {
      if (!member) return '';

      if (member.isApi) return 'API';
      if (member.isRemoved)
        return `${t('User Not Found')} (${member.username})`;

      return getUserNameWithFallback({
        ...member,
        email: member.email || '', // ここではemailは必ずあるため ! でも良いがWarningが出るため || で対応
      });
    },
    [t],
  );

  const sortedContentCreators = useMemo(() => {
    return contentCreators?.sort((a, b) => {
      return getMemberDisplayName(a).localeCompare(getMemberDisplayName(b));
    });
  }, [contentCreators, getMemberDisplayName]);
  const sortedContentEditors = useMemo(() => {
    return contentEditors?.sort((a, b) => {
      return getMemberDisplayName(a).localeCompare(getMemberDisplayName(b));
    });
  }, [contentEditors, getMemberDisplayName]);

  const onChangeDate = useCallback(
    (d: any) => {
      setDate(d);
      onChange({ key, value: dayjs(d).toISOString(), condition }, index);
    },
    [key, condition, index, onChange],
  );
  const selectedKind = useMemo(() => {
    if (SPECIAL_KEYS.includes(key)) {
      if (
        key === 'createdAt' ||
        key === 'updatedAt' ||
        key === 'publishedAt' ||
        key === 'revisedAt'
      ) {
        return 'date';
      }
      return key;
    }
    const field = api.apiFields.find((attr) => attr.fieldId === key);
    return field ? field.kind : 'text';
  }, [api.apiFields, key]);
  const onChangeKey = useCallback(
    (e: any) => {
      const otherKind = api.apiFields.find(
        (attr) => attr.fieldId === e.target.value,
      );
      const kind = SPECIAL_KEYS.includes(e.target.value)
        ? e.target.value === 'createdAt' ||
          e.target.value === 'updatedAt' ||
          e.target.value === 'publishedAt' ||
          e.target.value === 'revisedAt'
          ? 'date'
          : e.target.value
        : otherKind
          ? otherKind.kind
          : undefined;

      const value =
        kind === 'contentStatus'
          ? 'PUBLISH'
          : kind === 'boolean'
            ? 'true'
            : kind === 'editorId' || kind === 'authorId'
              ? kind === 'authorId'
                ? contentCreators[0].username
                : kind === 'editorId'
                  ? contentEditors[0].username
                  : (members && members[0].sub) || ''
              : '';
      onChange(
        {
          key: e.target.value,
          value,
          condition: CONDITIONS[kind][0],
        },
        index,
      );
    },
    [api.apiFields, contentCreators, contentEditors, index, members, onChange],
  );
  const select = useCallback(
    (content: any) => {
      toggleRelationModal(false);
      onChange(
        {
          key,
          value: content.gsiSortKey2.replace(
            `${content.gsiPartitionKey1}_ID#`,
            '',
          ),
          condition,
        },
        index,
      );
    },
    [condition, index, key, onChange, toggleRelationModal],
  );

  // 参照、セレクトフィールドの処理
  useEffect(() => {
    const field = api.apiFields.find((attr) => attr.fieldId === key);
    setRelationId(field && field.referenceKey);
    setSelectItems(field && field.selectItems);
  }, [key, api.apiFields]);

  // 表示項目の制限
  const field = api.apiFields.find((attr) => attr.fieldId === key);
  const localStoragePath = `${api.id}#${api.contentId}#${field?.idValue}`;
  const localStorageItems = useLocalStorageItems(localStoragePath);
  const [items, setItems] = useState(
    localStorageItems || relationApi?.apiFields.map((field) => field.idValue),
  );
  useEffect(() => {
    setItems(
      localStorageItems || relationApi?.apiFields.map((field) => field.idValue),
    );
  }, [localStorageItems, relationApi]);
  const toggleItem = useToggleItem(api, localStoragePath, items, setItems);

  return (
    <div className={styles.row} data-testid="content-filter-row">
      <Selectfield
        value={key}
        onChange={onChangeKey}
        className={styles.firstField}
        size="full"
      >
        <option value="contentStatus">{t('Content status')}</option>
        <option value="createdAt">{t('Date created')}</option>
        <option value="updatedAt">{t('Date updated')}</option>
        <option value="publishedAt">{t('Date published')}</option>
        <option value="revisedAt">{t('Date revised')}</option>
        {(members?.length ?? 0) > 0 && (
          <>
            <option value="authorId">{t('Created by')}</option>
            <option value="editorId">{t('Last updated by')}</option>
          </>
        )}
        {api.apiFields
          .filter((attr) => VALID_KINDS.includes(attr.kind))
          .map((attr, i) => (
            <option value={attr.fieldId} key={i}>
              {attr.name}
            </option>
          ))}
      </Selectfield>
      {selectedKind === 'date' ? (
        <DatePicker
          reactDatePickerProps={{
            selected: date,
            onChange: onChangeDate,
            dateFormat: formatDatePicker({ dateFormat: true }),
            timeCaption: t('Time'),
          }}
          className={cx(styles.datePickerWrapper, styles.override)}
        />
      ) : selectedKind === 'boolean' ? (
        <select
          className={styles.secondField}
          value={value}
          onChange={(e) =>
            onChange({ key, value: e.target.value, condition }, index)
          }
        >
          <option value="true">{t('On')}</option>
          <option value="false">{t('Off')}</option>
        </select>
      ) : selectedKind === 'select' ? (
        <select
          className={styles.secondField}
          value={value}
          onChange={(e) =>
            onChange({ key, value: e.target.value, condition }, index)
          }
        >
          <option value="">{t('Not selected')}</option>
          {selectItems &&
            selectItems.map((item) => (
              <option key={item.id} value={item.value}>
                {item.value}
              </option>
            ))}
        </select>
      ) : selectedKind === 'relation' || selectedKind === 'relationList' ? (
        <Modal open={isOpenRelationModal} onOpenChange={toggleRelationModal}>
          <ModalTrigger asChild>
            <SelectButton
              value={value || t('Not selected')}
              className={styles.selectButton}
            />
          </ModalTrigger>
          <ModalContent size="large" hasSpace={false}>
            {relationApi ? (
              <RelationModal
                select={select}
                api={relationApi}
                close={closeRelationModal}
                items={items}
                toggleItem={toggleItem}
              />
            ) : (
              <NoPermissionModal />
            )}
          </ModalContent>
        </Modal>
      ) : selectedKind === 'contentStatus' ? (
        <select
          value={value}
          className={styles.secondField}
          onChange={(e) =>
            onChange({ key, value: e.target.value, condition }, index)
          }
        >
          <option value="PUBLISH">{t('Published')}</option>
          <option value="DRAFT">{t('Draft')}</option>
          <option value="PUBLISH_AND_DRAFT">{t('Published and Draft')}</option>
          <option value="CLOSED">{t('Unpublished')}</option>
        </select>
      ) : selectedKind === 'editorId' || selectedKind === 'authorId' ? (
        <select
          className={styles.secondField}
          value={value}
          onChange={(e) =>
            onChange({ key, value: e.target.value, condition }, index)
          }
        >
          {
            selectedKind === 'authorId'
              ? sortedContentCreators.map(
                  (creator: ContentEditor, i: number) => {
                    return (
                      <option value={creator.username} key={i}>
                        {getMemberDisplayName(creator)}
                      </option>
                    );
                  },
                )
              : selectedKind === 'editorId'
                ? sortedContentEditors.map(
                    (editor: ContentEditor, i: number) => {
                      return (
                        <option value={editor.username} key={i}>
                          {getMemberDisplayName(editor)}
                        </option>
                      );
                    },
                  )
                : null // ここには来ない
          }
        </select>
      ) : (
        <input
          type="text"
          className={styles.secondField}
          value={value}
          onChange={(e) =>
            onChange({ key, value: e.target.value, condition }, index)
          }
        />
      )}
      <select
        name=""
        id=""
        className={styles.thirdField}
        value={condition}
        onChange={(e) =>
          onChange({ key, value, condition: e.target.value }, index)
        }
      >
        {CONDITIONS[selectedKind].map((c: any, i: number) => (
          <option key={i} value={c}>
            {t(c)}
          </option>
        ))}
      </select>
      <button onClick={() => remove(index)} className={styles.removeBtn}>
        <i className="material-icons">delete</i>
      </button>
    </div>
  );
};

export default Row;
