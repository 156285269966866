import { useCallback } from 'react';

import type { Props } from './Blockquote';

export const useBlockquote = ({ editor }: Props) => {
  const toggleBlockquoteHandler = useCallback(() => {
    editor.chain().focus().toggleBlockquote().run();
  }, [editor]);
  const isActiveBlockquote = editor.isActive('blockquote');

  return {
    toggleBlockquoteHandler,
    isActiveBlockquote,
  };
};
