import type React from 'react';

import styles from './note.module.css';

type Props = {
  html: string;
};

const Note: React.FC<Props> = ({ html }) => {
  return (
    <dl className={styles.container}>
      <dt className={styles.title}>
        <i className={`material-icons-outlined ${styles.icon}`}>info</i>
        Information
      </dt>

      <dd
        className={styles.content}
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
    </dl>
  );
};

export default Note;
