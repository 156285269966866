import * as RadixPopover from '@radix-ui/react-popover';

import style from './popover.module.css';

export type Props = {
  body: React.ReactNode;
  trigger?: React.ReactNode;
  anchor?: React.ReactNode;
} & RadixPopover.PopoverProps &
  RadixPopover.PopoverTriggerProps;

export const Popover: React.FC<Props> = ({
  body,
  trigger,
  anchor,
  ...props
}) => {
  return (
    <RadixPopover.Root {...props}>
      {trigger && (
        <RadixPopover.Trigger asChild disabled={props.disabled}>
          {trigger}
        </RadixPopover.Trigger>
      )}
      {anchor && <RadixPopover.Anchor asChild>{anchor}</RadixPopover.Anchor>}
      <RadixPopover.Portal>
        <RadixPopover.Content
          className={style.content}
          sideOffset={5}
          align="start"
          onEscapeKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          {body}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
};
