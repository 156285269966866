import NotFound from '@/components/NotFound';
import { useLoadApis } from '@/hooks/ApiList/useApiListReader';
import { useGetContent } from '@/hooks/Content/useContentWriter';
import { useCustomFields } from '@/hooks/CustomField/useCustomFieldReader';
import type { CustomField } from '@/types/contents';
import type { MigrateContent } from '@/usecase/contentUsecase';
import { type FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectVersions } from './SelectVersions';

import type { MigrateApi } from '@/entity/api';
import { Loading } from '@/views/Common/Ui/Loading';
import { ContentsComparison } from '@/views/Common/content/ContentsComparison';
import { useCurrentService } from '@/views/Common/service/useServiceReader';
import styles from './contentsCompare.module.css';

const parseVersion = (value: string): number | null => {
  const parsedValue = Number(value);

  if (Number.isSafeInteger(parsedValue)) {
    return parsedValue;
  }

  return null;
};

/**
 * ルートのエントリーポイント
 *
 * Route -> Loader -> ... といった流れで表示コンポーネントに至る
 */
const ContentsCompareRoute: FC = () => {
  const routeParams = useParams<{
    endpoint: string;
    contentId: string;
    versions: string;
  }>();

  // versionsが「1...latest」や「2...5」といった形式であることを確認する
  const versions = routeParams.versions.split('...');
  if (versions.length !== 2) {
    return <NotFound />;
  }

  const sinceVersion = parseVersion(versions[0]);
  const untilVersion = parseVersion(versions[1]);

  // バージョン番号が数値であることを確認する
  if (sinceVersion === null || untilVersion === null) {
    return <NotFound />;
  }

  // バージョン番号に0以下が含まれていないことを確認する
  if (sinceVersion <= 0 || untilVersion <= 0) {
    return <NotFound />;
  }

  return (
    <ContentsCompareLoader
      apiEndpoint={routeParams.endpoint}
      userDefinedContentId={routeParams.contentId}
      sinceVersion={sinceVersion}
      untilVersion={untilVersion}
    />
  );
};

type LoaderProps = {
  apiEndpoint: string;
  userDefinedContentId: string;
  sinceVersion: number;
  untilVersion: number;
};
/**
 * 表示前に必要なデータを読み込む
 */
const ContentsCompareLoader: FC<LoaderProps> = ({
  apiEndpoint,
  userDefinedContentId,
  sinceVersion,
  untilVersion,
}) => {
  const { data: service, isLoading: isServiceLoading } = useCurrentService();

  const { apiList, isApiListLoading } = useLoadApis(service);
  const api = apiList?.list.find((api) => api.apiEndpoint === apiEndpoint);

  const [content] = useGetContent(api, userDefinedContentId);

  // 全てのカスタムフィールドの取得
  const [customFields] = useCustomFields(api?.partitionKey);

  const isLoading =
    isServiceLoading ||
    isApiListLoading ||
    !api ||
    !apiList ||
    !content ||
    !customFields;
  // ローディング表示
  if (isLoading) {
    return <Loading className={styles.loading} />;
  }

  return (
    <ContentsCompareComponent
      api={api}
      customFields={customFields}
      content={content}
      sinceVersion={sinceVersion}
      untilVersion={untilVersion}
    />
  );
};

type ComponentProps = {
  api: MigrateApi;
  customFields: CustomField[];
  content: MigrateContent;
  sinceVersion: number;
  untilVersion: number;
};

const ContentsCompareComponent: FC<ComponentProps> = ({
  api,
  customFields,
  content,
  sinceVersion,
  untilVersion,
}) => {
  const [isNotFound, setIsNotFound] = useState(false);
  const notFound = () => {
    setIsNotFound(true);
    return null;
  };

  if (isNotFound) {
    return <NotFound />;
  }

  return (
    <div className={styles.main}>
      <SelectVersions
        content={content}
        sinceVersion={sinceVersion}
        untilVersion={untilVersion}
        notFound={notFound}
      />
      <div className={styles.contentsComparison}>
        <ContentsComparison
          api={api}
          customFields={customFields}
          contentId={content.partitionKey}
          sinceVersion={sinceVersion}
          untilVersion={untilVersion}
        />
      </div>
    </div>
  );
};

export { ContentsCompareRoute };
