import { textblockTypeInputRule } from '@tiptap/core';
import { Heading } from '@tiptap/extension-heading';

import type { RichEditorV2HeadingOptions } from '@/constants/richEditorOptions';
import type { HeadingOptions, Level } from '@tiptap/extension-heading';

type CustomHeadingOptions = HeadingOptions & {
  inputRules: RichEditorV2HeadingOptions[];
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-heading/src/heading.ts
export const CustomHeading = Heading.extend<CustomHeadingOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: [
        'headerOne',
        'headerTwo',
        'headerThree',
        'headerFour',
        'headerFive',
      ],
    };
  },

  parseHTML() {
    const inputRules = convertInputRules(this.options.inputRules);
    return inputRules.map((level: Level) => ({
      tag: `h${level}`,
      attrs: { level },
    }));
  },

  addCommands() {
    return {
      ...this.parent?.(),
      toggleHeading:
        (attributes) =>
        ({ commands, editor }) => {
          const { $anchor } = editor.state.selection;
          const isImageBlockContent =
            $anchor.parent.content.firstChild?.type?.name === 'imageBlock';

          // imageBlockにはHeadingを適用しない
          if (
            !this.options.levels.includes(attributes.level) ||
            isImageBlockContent
          ) {
            return false;
          }

          return commands.toggleNode(this.name, 'paragraph', attributes);
        },
    };
  },

  addKeyboardShortcuts() {
    const inputRules = convertInputRules(this.options.inputRules);
    return inputRules.reduce(
      (items, level) => ({
        ...items,
        ...{
          [`Mod-Alt-${level}`]: () =>
            this.editor.commands.toggleHeading({ level }),
        },
      }),
      {},
    );
  },

  addInputRules() {
    const inputRules = convertInputRules(this.options.inputRules);
    return inputRules.map((level) => {
      return textblockTypeInputRule({
        find: new RegExp(`^(#{${level}})\\s$`),
        type: this.type,
        getAttributes: {
          level,
        },
      });
    });
  },
});

function convertInputRules(inputRules: RichEditorV2HeadingOptions[]): Level[] {
  const newInputRules = inputRules.map((level) => {
    if (level === 'headerOne') {
      return 1;
    } else if (level === 'headerTwo') {
      return 2;
    } else if (level === 'headerThree') {
      return 3;
    } else if (level === 'headerFour') {
      return 4;
    } else if (level === 'headerFive') {
      return 5;
    }
    // ここには到達しない
    return 1;
  });

  return newInputRules;
}
