import cx from 'classnames';
import { forwardRef } from 'react';

import styles from './input.module.css';

export type Props = React.ComponentPropsWithoutRef<'input'>;

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ className, ...props }, forwardedRef) => {
    return (
      <input
        ref={forwardedRef}
        className={cx(styles.input, className)}
        {...props}
      />
    );
  },
);
Input.displayName = 'Input';
