import { API, graphqlOperation } from 'aws-amplify';
import {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
} from 'react';

import * as queries from '../../graphql/queries';

import type {
  Role,
  ReadLevel,
  ContentWriteLevel,
  RoleKey,
  RoleOperationKey,
} from '@/entity/role';

import { hasAdminPermission } from '@/util/permissions/admin';
import { hasApiReadPermission } from '@/util/permissions/api';
import { hasMemberReadPermission } from '@/util/permissions/member';

interface RoleContext {
  roles: Role[] | undefined;
  reloadRoles: (key?: string) => void;
}
// context object
export const myRolesContext = createContext<RoleContext>({
  roles: undefined,
  reloadRoles: () => {
    // contextで実際に使う関数がセットされる
  },
});

/**
 * 自分のメンバーのRole群とリロードする関数を提供する
 *
 * @see {myRolesContext}
 */
export const useMyRolesContext = () => {
  return useContext(myRolesContext);
};

/**
 * 自分のメンバーのRole群とリロードする関数を提供する
 *
 * ※ このフックは myRolesContext.Provider に値をセットするために利用される
 *
 * @see web/src/components/App/App.tsx で利用し、App配下のコンポーネントでは `useMyRolesContext` を利用すること
 */
export const useMyRoles = () => {
  const [roles, setRoles] = useState<Role[] | undefined>(undefined);

  const reloadRoles = useCallback(async (serviceId: string | undefined) => {
    if (!serviceId) {
      return;
    }

    const result = (await API.graphql(
      graphqlOperation(queries.getMyRoles, { serviceId }),
    )) as { data: { getMyRoles: Role[] } };
    const roles = result.data.getMyRoles;

    //大枠の読み取り権限があるかどうかをあらかじめ設定しておく
    roles.forEach((role) => {
      role.memberPermission.simpleRead = hasMemberReadPermission(
        role.memberPermission.read,
      );
    });

    setRoles(roles);
  }, []);

  return {
    roles,
    reloadRoles,
  };
};

const usePermission = (
  permission: RoleKey,
  operation: RoleOperationKey,
  value: ReadLevel | boolean = true,
  isNot = false,
) => {
  const { roles } = useMyRolesContext();
  const hasPermission = useMemo(() => {
    if (permission === undefined || operation === undefined) {
      return;
    }
    const judgeRole = (role: any) => role[permission][operation] === value;
    return roles && (isNot ? roles.every(judgeRole) : roles.some(judgeRole));
  }, [isNot, operation, permission, roles, value]);

  return [hasPermission] as const;
};
export const usePermissionIsHaveLeastOne = (
  permission: RoleKey,
  operation: RoleOperationKey,
  value: ReadLevel | boolean = true,
) => {
  return usePermission(permission, operation, value, false);
};
export const usePermissionIsNot = (
  permission: RoleKey,
  operation: RoleOperationKey,
  value: ReadLevel | boolean = true,
) => {
  return usePermission(permission, operation, value, true);
};

const useExceptionPermission = (
  apiId: string,
  operation: RoleOperationKey,
  value: ReadLevel | ContentWriteLevel | boolean = true,
  isNot = false,
) => {
  const { roles } = useMyRolesContext();
  const hasPermission = useMemo(() => {
    if (operation === undefined) {
      return;
    }
    const judgeRole = (role: Role) => {
      const ecp = role.exceptionContentPermissions?.find(
        (ecp) => ecp.apiId === apiId,
      );
      const permission = ecp ? ecp.permission : role.defaultContentPermission;
      // @ts-expect-error: 無視してください
      return permission[operation] === value;
    };
    return roles && (isNot ? roles.every(judgeRole) : roles.some(judgeRole));
  }, [apiId, isNot, operation, roles, value]);

  return [hasPermission] as const;
};
export const useExceptionPermissionIsHaveLeastOne = (
  apiId: string,
  operation: RoleOperationKey,
  value: ReadLevel | ContentWriteLevel | boolean = true,
) => {
  return useExceptionPermission(apiId, operation, value, false);
};
export const useExceptionPermissionIsNot = (
  apiId: string,
  operation: RoleOperationKey,
  value: ReadLevel | boolean = true,
) => {
  return useExceptionPermission(apiId, operation, value, true);
};

/**
 * 管理者権限を持っているか否かの情報を提供する
 */
export const useAdmin = () => {
  const { roles } = useMyRolesContext();
  const isAdmin = useMemo(() => {
    return hasAdminPermission(roles);
  }, [roles]);
  return [isAdmin] as const;
};

/**
 * 対象のAPIのREAD権限を持っているか否かの情報を得る関数を提供する
 */
export const useApiPermission = () => {
  const { roles } = useMyRolesContext();
  const hasReadPermission = useCallback(
    (apiId: string) => {
      return hasApiReadPermission({ roles, apiId });
    },
    [roles],
  );
  return [hasReadPermission] as const;
};
