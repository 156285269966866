import { useTranslation } from 'react-i18next';

import S3Image from '@/components/S3Image';
import Button from '@/components/ui/Button';

import { useAnswerMemberInvitation } from '@/hooks/Member/useAnswerMemberInvitation';

import type { Service } from '@/types/services';
import type { FC } from 'react';

import styles from './JoinServiceNotifierModal.module.css';

import { MEDIUM_KIND_IMAGE } from '@/constants/medium';
import { getHost } from '@/util';
import { Modal, ModalContent } from '@/views/Common/Ui/Modal';
import { ServiceAvatar } from '@/views/Common/Ui/ServiceAvatar';

type Props = {
  service: Service;
  isOpen: boolean;
};

const JoinServiceNotifierModal: FC<Props> = ({ service, isOpen }) => {
  const { t } = useTranslation('joinServiceNotifier');

  const { answerMemberInvitationMutation, isLoading } =
    useAnswerMemberInvitation(service.partitionKey);

  return (
    <Modal open={isOpen}>
      <ModalContent size="small" hasSpace={false}>
        <div className={styles.serviceInfoContainer}>
          {service.serviceImage ? (
            <S3Image
              directory={`protected/${service.serviceImage.identityId}/${service.serviceImage.key}`.replace(
                `/${service.serviceImage.key.replace(/.*\//, '')}`,
                '',
              )}
              fileName={service.serviceImage.key.replace(/.*\//, '')}
              kind={MEDIUM_KIND_IMAGE}
              queryString="?fit=crop&w=120&h=120"
              className={styles.serviceImage}
              // NOTE: wrapperのbackground等を透過させるために必要
              style={{ background: 'none', border: 'none' }}
            />
          ) : (
            <ServiceAvatar
              string={service.domain}
              size={128}
              className={styles.serviceImage}
            />
          )}
          <h1 className={styles.serviceName}>{service.serviceName}</h1>
          <p className={styles.message}>
            {t('You have been invited to join the service')}
          </p>
        </div>
        <div className={styles.footer}>
          <Button
            type="secondary"
            size="small"
            value={t('Join')}
            onClick={() => {
              answerMemberInvitationMutation({ accept: true });
            }}
            disabled={isLoading}
          />
          <button
            type="button"
            className={styles.textButton}
            onClick={() => {
              answerMemberInvitationMutation({ accept: false }).then(() => {
                window.location.href = getHost('app');
              });
            }}
            disabled={isLoading}
          >
            {t('Reject')}
          </button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default JoinServiceNotifierModal;
