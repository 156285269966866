import { useLimitCustomFields } from '@/hooks/FieldLimit/useLimitApiField';
import { useGetMyService } from '@/hooks/useService';
import { nanoid } from 'nanoid';
import type React from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { MigrateApi } from '../../../entity/api';
import ApiModel from '../../ApiModel';
import Button from '../../ui/Button';
import styles from './inputModel.module.css';

type Props = {
  api: MigrateApi;
  goNext: () => void;
  goPrevious: () => void;
  fieldsInput: [
    fields: any[],
    _: any,
    fieldsError: string | null,
    setFields: React.Dispatch<React.SetStateAction<any[]>>,
  ];
};

const InputModel: React.FC<Props> = ({
  api,
  goNext,
  goPrevious,
  fieldsInput: [fields, _, fieldsError, setFields],
}) => {
  const { t } = useTranslation('inputModel');
  const hasLocalError = useMemo(() => fieldsError !== null, [fieldsError]);
  const { service, parentService } = useGetMyService();

  const swap = useCallback(
    (x: any, y: any) => {
      if (x === y) {
        return;
      }
      const copy = [...fields];
      copy.splice(y, 0, fields[x]);
      if (x < y) {
        copy.splice(x, 1);
      } else {
        copy.splice(x + 1, 1);
      }
      setFields(copy);
    },
    [fields, setFields],
  );

  const setFieldAt = useCallback(
    (f: any, index: any) => {
      setFields((prev) => {
        const next = [...prev];
        next[index] = f(prev[index]);
        return next;
      });
    },
    [setFields],
  );

  // ANKEN-1273 カスタムフィールド数上限値の設定
  const errorAddCustomField = useLimitCustomFields(
    fields,
    service,
    parentService,
  );

  return (
    <div className={styles.area}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('Define API schema (interface)')}</h2>
        <p className={styles.description}>
          {t('Define your own fields by combining existing fields.')}
        </p>
        <div className={styles.lists}>
          {fields.map((field, i) => (
            <div key={i} className={styles.list}>
              <ApiModel
                api={api}
                useInCustom={true}
                type={api.apiType}
                field={field}
                allFields={fields}
                setFieldAt={setFieldAt}
                index={i}
                swap={swap}
                deleteField={(index) =>
                  setFields(fields.filter((field, j) => j !== index))
                }
              />
            </div>
          ))}
          <div className={styles.addBtn}>
            <Button
              icon="add"
              type="tertiary"
              value={t('Add field')}
              size="full"
              onClick={() =>
                setFields([
                  ...fields,
                  { idValue: nanoid(10), isAdditionalField: true },
                ])
              }
              disabled={errorAddCustomField}
            />
            {errorAddCustomField && (
              <p className={styles.errorText}>
                {t('The maximum number of fields has been reached.')}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <span className={styles.step}>
          <em>2</em>/3 {t('steps')}
        </span>
        <div className={styles.prev}>
          <Button value={t('Back')} type="tertiary" onClick={goPrevious} />
        </div>
        <div className={styles.next}>
          <Button
            className="ga-create-customfield-schema"
            type="secondary"
            value={t('Next')}
            disabled={hasLocalError}
            onClick={goNext}
          />
        </div>
      </div>
    </div>
  );
};

export default InputModel;
