import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useCustomFields } from '../../../hooks/CustomField/useCustomFieldReader';
import useCustomFieldWriter from '../../../hooks/CustomField/useCustomFieldWriter';
import { useExceptionPermissionIsHaveLeastOne } from '../../../hooks/Role/useMyRoles';
import Feedback from '../../Feedback';
import FieldLayout from '../../FieldLayout';
import Head from '../../Head';
import Button from '../../ui/Button';

import type { ApiList, MigrateApi } from '../../../entity/api';

import styles from './layoutSettings.module.css';

import { apiListSelectors } from '@/ducks/apiList';
import { useAppSelector } from '@/store/hooks';

const LayoutSettings: React.FC = () => {
  const { t } = useTranslation('customFieldsLayoutSettings');

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint, fieldId } = useParams<{
    endpoint: string;
    fieldId: string;
  }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );

  const [hasPermission] = useExceptionPermissionIsHaveLeastOne(
    api.partitionKey,
    'apiUpdate',
  );

  // field.position.lengthによって1カラムか2カラムかが決定される. useEffect()内で初期値が入れられる.
  const [column, setColumn] = useState<1 | 2>();
  const [customFields = []] = useCustomFields(api.partitionKey);
  const field = useMemo(
    () => customFields.find((field) => field.fieldId === fieldId),
    [customFields, fieldId],
  );
  const { result, error, loading, update } = useCustomFieldWriter(api);

  const [singleColumnLayout, setSingleColumnLayout] = useState<any[]>([]);
  const [multiColumnLayout, setMultiColumnLayout] = useState<{
    left: any[];
    right: any[];
  }>({
    left: [],
    right: [],
  });

  const updateField = useCallback(() => {
    if (column === 1) {
      const positions = [singleColumnLayout.map((element) => element.idValue)];
      update(field, field?.fieldId, field?.name, field?.fields, positions);
    } else if (column === 2) {
      const positions = [
        multiColumnLayout.left.map((element) => element.idValue),
        multiColumnLayout.right.map((element) => element.idValue),
      ];
      update(field, field?.fieldId, field?.name, field?.fields, positions);
    }
  }, [field, update, column, singleColumnLayout, multiColumnLayout]);

  useEffect(() => {
    // singleColumnLayout, multiColumnLayoutの初期値を決める
    if (!field) {
      return;
    }

    if (field.position.length === 1) {
      const initialSingleColumnLayout = field.position[0].map((idValue) => {
        return {
          ...field.fields.find((element) => element.idValue === idValue),
        };
      });
      setSingleColumnLayout(initialSingleColumnLayout);

      const left = field.position[0].map((idValue) => {
        return {
          ...field.fields.find((element) => element.idValue === idValue),
        };
      });
      const initialMultiColumnLayout = { left: left, right: [] };
      setMultiColumnLayout(initialMultiColumnLayout);
    } else if (field.position.length === 2) {
      const initialSingleColumnLayout = [
        ...field.position[0],
        ...field.position[1],
      ].map((idValue) => {
        return {
          ...field.fields.find((element) => element.idValue === idValue),
        };
      });
      setSingleColumnLayout(initialSingleColumnLayout);

      const left = field.position[0].map((idValue) => {
        return {
          ...field.fields.find((element) => element.idValue === idValue),
        };
      });

      const right = field.position[1].map((idValue) => {
        return {
          ...field.fields.find((element) => element.idValue === idValue),
        };
      });
      const initialMultiColumnLayout = { left: left, right: right };
      setMultiColumnLayout(initialMultiColumnLayout);
    }

    // @ts-expect-error: position長さは1,2のどちらかをとる
    setColumn(field.position.length);
  }, [field]);

  const [isDragOver, setIsDragOver] = useState();

  const onDragStart = useCallback((e: any) => {
    e.dataTransfer.setData('idValue', e.target.getAttribute('data-id-value'));
  }, []);

  const onDragOver = useCallback(
    (e: any) => {
      e.preventDefault();
      const position = e.currentTarget.getAttribute('data-column-name');
      if (isDragOver !== position) {
        setIsDragOver(position);
      }
    },
    [isDragOver],
  );

  /**
   * カラム移動とカラム内並び替えを実現するために、カラム自体ulとカラム内要素liにonDropが実装されている。そのためonDropが2回呼ばれる設計になっている
   */
  const onDrop = useCallback(
    (event: any) => {
      const dataTransfer = event.dataTransfer;
      const dropArea = event.currentTarget;

      // ドラッグ中のfield.idValue
      const dropItemIdValue = dataTransfer.getData('idValue');

      if (!dropItemIdValue) {
        // 対象外の要素がドロップされたとき（通常のリンク要素など
        return;
      }

      // ドロップ先のfield.idValue（カラム全体に対してのイベント時はnull）
      const destinationIdValue = dropArea.getAttribute('data-id-value');

      // カラム全体に対するイベントかどうか
      const isColumnEvent = !destinationIdValue;

      // ドロップ先のカラム名
      const destinationColumn = dropArea.getAttribute('data-column-name');

      if (column === 1) {
        // 1カラムの並び替えの時はカラム全体に対するonDropイベントは無視する

        if (isColumnEvent) {
          return;
        }

        const destinationDataIdIndex = singleColumnLayout.findIndex(
          (e) => e.idValue === destinationIdValue,
        );
        const dropItem = singleColumnLayout.find(
          (e) => e.idValue === dropItemIdValue,
        );
        const updatedSingleColumnLayout = singleColumnLayout.filter(
          (e) => e.idValue !== dropItemIdValue,
        );
        updatedSingleColumnLayout.splice(destinationDataIdIndex, 0, dropItem);
        setSingleColumnLayout(updatedSingleColumnLayout);
      } else if (column === 2) {
        const fromLeft = multiColumnLayout.left.some(
          (element) => element.idValue === dropItemIdValue,
        );
        if (
          (fromLeft && destinationColumn === 'left') ||
          (!fromLeft && destinationColumn === 'right')
        ) {
          // 同じカラム内の並び替え

          if (isColumnEvent) {
            // 同じカラムの並び替えの時はカラム全体に対するonDropイベントは無視する
            return;
          }

          const targetColumnLayout =
            destinationColumn === 'left'
              ? multiColumnLayout.left
              : multiColumnLayout.right;

          const destinationDataIdIndex = targetColumnLayout.findIndex(
            (e) => e.idValue === destinationIdValue,
          );
          const dropItem = targetColumnLayout.find(
            (e) => e.idValue === dropItemIdValue,
          );
          const updatedTargetColumnLayout = targetColumnLayout.filter(
            (e) => e.idValue !== dropItemIdValue,
          );
          updatedTargetColumnLayout.splice(destinationDataIdIndex, 0, dropItem);

          if (destinationColumn === 'left') {
            const newLayoutObject = {
              left: updatedTargetColumnLayout,
              right: multiColumnLayout.right,
            };
            setMultiColumnLayout(newLayoutObject);
          } else {
            const newLayoutObject = {
              left: multiColumnLayout.left,
              right: updatedTargetColumnLayout,
            };
            setMultiColumnLayout(newLayoutObject);
          }
        } else {
          // カラム間の移動
          const destinationColumnLayout =
            destinationColumn === 'left'
              ? multiColumnLayout.left
              : multiColumnLayout.right;

          const sourceColumnLayout =
            destinationColumn === 'left'
              ? multiColumnLayout.right
              : multiColumnLayout.left;

          if (!isColumnEvent) {
            // カラム間の移動の時はカラム内要素に対するonDropイベントは無視する
            return;
          }

          const dropItem = sourceColumnLayout.find(
            (e) => e.idValue === dropItemIdValue,
          );

          destinationColumnLayout.push(dropItem);

          const updatedSourceColumnLayout = sourceColumnLayout.filter(
            (element) => element.idValue !== dropItemIdValue,
          );

          if (destinationColumn === 'left') {
            const newLayoutObject = {
              left: destinationColumnLayout,
              right: updatedSourceColumnLayout,
            };
            setMultiColumnLayout(newLayoutObject);
          } else {
            const newLayoutObject = {
              left: updatedSourceColumnLayout,
              right: destinationColumnLayout,
            };
            setMultiColumnLayout(newLayoutObject);
          }
        }
      }

      // @ts-expect-error
      setIsDragOver(false);
    },
    [singleColumnLayout, multiColumnLayout, column],
  );

  if (field === undefined) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Head title={t('Custom Fields/Layout')} />
      <h2 className={styles.title}>{t('Layout')}</h2>
      <div>
        <ul className={styles.select}>
          <li className={styles.selectItem}>
            <button
              className={
                column === 1
                  ? `${styles.selectButton} ${styles.isSelected}`
                  : styles.selectButton
              }
              onClick={() => setColumn(1)}
            >
              <i className="material-icons-outlined">horizontal_split</i>
              {t('1 Column')}
            </button>
          </li>
          <li className={styles.selectItem}>
            <button
              className={
                column === 2
                  ? `${styles.selectButton} ${styles.isSelected}`
                  : styles.selectButton
              }
              onClick={() => setColumn(2)}
            >
              <i className="material-icons-outlined">vertical_split</i>
              {t('2 Columns')}
            </button>
          </li>
        </ul>
        <div className={styles.container}>
          {column === 1 && (
            <ul
              className={
                isDragOver === 'left'
                  ? `${styles.column} ${styles.isDragOver}`
                  : styles.column
              }
              onDragOver={onDragOver}
              onDrop={onDrop}
              data-column-name="left"
            >
              {singleColumnLayout.map((field) => (
                <FieldLayout
                  field={field}
                  key={field.idValue}
                  onDragStart={onDragStart}
                  onDrop={onDrop}
                  columnName="left"
                />
              ))}
            </ul>
          )}
          {column === 2 && (
            <>
              <ul
                className={
                  isDragOver === 'left'
                    ? `${styles.column} ${styles.isDragOver}`
                    : styles.column
                }
                onDragOver={onDragOver}
                onDrop={onDrop}
                data-column-name="left"
              >
                {multiColumnLayout.left.map((field, i) => (
                  <FieldLayout
                    field={field}
                    key={i}
                    onDragStart={onDragStart}
                    onDrop={onDrop}
                    columnName="left"
                  />
                ))}
              </ul>
              <ul
                className={
                  isDragOver === 'right'
                    ? `${styles.column} ${styles.isDragOver}`
                    : styles.column
                }
                onDragOver={onDragOver}
                onDrop={onDrop}
                data-column-name="right"
              >
                {multiColumnLayout.right.map((field, i) => (
                  <FieldLayout
                    field={field}
                    key={i}
                    onDragStart={onDragStart}
                    onDrop={onDrop}
                    columnName="right"
                  />
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
      {hasPermission && (
        <div className={styles.actions}>
          <Feedback
            type="success"
            message={result ? t('Changed') : undefined}
          />
          <Feedback
            type="failure"
            message={error ? t('Failed to make changes.') : undefined}
          />
          <Button
            type="primary"
            disabled={loading}
            value={t('Save changes')}
            onClick={updateField}
          />
        </div>
      )}
    </div>
  );
};

export default LayoutSettings;
