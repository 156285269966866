import cx from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CustomStatus from '../CustomStatus';
import IconButton from '../IconButton';
import Tooltip from '../Tooltip';

import type { CustomState } from '../../../types/customState';
import type { ContentStatus } from '@/entity/content';

import styles from './contentStatusState.module.css';

type Props = {
  behaviour: ContentStatus;
  customStatus?: [CustomState];
  contentCustomStatusIds?: string[];
  statusStyle?: string;
  doUpdateContentStatus?: (
    status?: string,
    behaviour?: string,
  ) => React.MouseEventHandler;
  isEditing?: boolean;
};

const ContentStatusState: React.FC<Props> = ({
  behaviour,
  customStatus,
  contentCustomStatusIds = [],
  statusStyle = 'publish',
  doUpdateContentStatus,
  isEditing = false,
}) => {
  const { t } = useTranslation('contentStatusState');
  // カスタムステータスの判定
  const isCustomStatus = useMemo(() => {
    return customStatus?.some(
      (v) =>
        contentCustomStatusIds?.includes(v?.id) && v?.behaviour === behaviour,
    );
  }, [customStatus, contentCustomStatusIds, behaviour]);

  const isCustomStatusExists = useMemo(() => {
    return customStatus?.some((v) => v?.behaviour === behaviour);
  }, [customStatus, behaviour]);

  return (
    <div className={cx(styles.wrapper, styles[statusStyle])}>
      <Tooltip
        alignment="left"
        isOnClick={isEditing && isCustomStatusExists}
        trigger={
          <>
            {isCustomStatus ? (
              <>
                {customStatus
                  ?.filter(
                    (v) =>
                      contentCustomStatusIds.includes(v.id) &&
                      v.behaviour === behaviour,
                  )
                  .map((status) => (
                    <React.Fragment key={status.id}>
                      <CustomStatus
                        status={behaviour}
                        name={status.name}
                        color={status.color}
                      />
                    </React.Fragment>
                  ))}
              </>
            ) : (
              <span
                className={cx(styles.status, styles[statusStyle])}
                data-testid="content-status"
              >
                {behaviour === 'PUBLISH'
                  ? t('Published')
                  : behaviour === 'DRAFT'
                    ? t('Draft')
                    : t('Unpublished')}
              </span>
            )}
            {isEditing && doUpdateContentStatus && isCustomStatusExists && (
              <IconButton
                icon="expand_more"
                className={styles.iconButton}
                aria-label={t('Change Status')}
              />
            )}
          </>
        }
      >
        {isEditing && doUpdateContentStatus && isCustomStatusExists && (
          <ul className={styles.tooltip}>
            <li className={styles.tooltipList}>
              <button
                className={cx(styles.tooltipButton)}
                onClick={doUpdateContentStatus(undefined, behaviour)}
              >
                <dl>
                  <dt className={styles.tooltipListTitle}>
                    {behaviour === 'PUBLISH'
                      ? t('Published')
                      : behaviour === 'DRAFT'
                        ? t('Draft')
                        : t('Unpublished')}
                  </dt>
                  <dd className={styles.tooltipListDescription}>
                    {behaviour === 'PUBLISH'
                      ? t('Published Status')
                      : behaviour === 'CLOSED'
                        ? t('Unpublished Status')
                        : t('Draft Status')}
                  </dd>
                </dl>
              </button>
            </li>
            {customStatus
              ?.filter((v) => v.behaviour === behaviour)
              .map((status) => (
                <li className={styles.tooltipList} key={status.id}>
                  <button
                    className={cx(styles.tooltipButton)}
                    onClick={doUpdateContentStatus(
                      status?.id,
                      status.behaviour,
                    )}
                  >
                    <dl>
                      <dt className={styles.tooltipListTitle}>{status.name}</dt>
                      <dd className={styles.tooltipListDescription}>
                        {status.description}
                      </dd>
                    </dl>
                  </button>
                </li>
              ))}
          </ul>
        )}
      </Tooltip>
    </div>
  );
};

export default ContentStatusState;
