import type { MigrateApi } from '@/entity/api';
import { useLocalStorageItems } from '@/hooks/Content/useLocalStorageItems';
import { useToggleItem } from '@/hooks/Content/useToggleItem';
import { nonNullable } from '@/util/type-guard';
import { Modal, ModalContent } from '@/views/Common/Ui/Modal';
import { useToggle } from '@/views/Common/Ui/useToggle';
import { useTotalCount } from '@/views/Common/content/useContentReader';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Errorfield } from '..';
import { useContents } from '../../../hooks/Content/useContentReader';
import { useDragAndDrop } from '../../../hooks/DragAndDrop/useDragAndDrop';
import Button from '../../ui/Button';
import Content from '../RelationContent';
import RelationModal from '../RelationModal';
import styles from './relationListSelect.module.css';

type Props = {
  api: MigrateApi;
  endpoint?: string;
  onChange?: (value: unknown) => void;
  value?: unknown[];
  readOnly?: boolean;
  parentField?: any;
};

const RelationListSelect: React.FC<Props> = ({
  api,
  endpoint,
  onChange = () => {},
  value = [],
  readOnly,
  parentField,
}) => {
  const { t } = useTranslation('relationListSelect');
  const { data: totalCount, isLoading } = useTotalCount(api.partitionKey);

  const [isOpenRelationModal, toggleRelationModal] = useToggle(false);
  const [currentRelation, setCurrentRelation] = useState<number>(); // 何番目

  // 選択済みコンテンツを取得
  const [selectedContents] = useContents(value);

  const edit = useCallback(
    (index: number) => () => {
      toggleRelationModal(true);
      setCurrentRelation(index);
    },
    [toggleRelationModal],
  );

  const remove = useCallback(
    (index: number) => () => {
      const newValue = value.filter((_, i) => i !== index);
      onChange(newValue);
    },
    [onChange, value],
  );

  const add = useCallback(() => {
    toggleRelationModal(true);
    setCurrentRelation(undefined);
  }, [toggleRelationModal]);

  const select = useCallback(
    (content: any) => {
      toggleRelationModal(false);
      const newValue =
        currentRelation === undefined
          ? [...value, content.partitionKey]
          : value.map((v, i) =>
              i === currentRelation ? content.partitionKey : v,
            );
      onChange(newValue);
    },
    [currentRelation, onChange, toggleRelationModal, value],
  );

  const onChangeOrder = useCallback(
    (x: number, y: number) => {
      if (x === y) {
        return;
      }
      const copy = [...value];
      copy.splice(y, 0, copy[x]);
      if (x < y) {
        copy.splice(x, 1);
      } else {
        copy.splice(x + 1, 1);
      }
      onChange(copy);
    },
    [onChange, value],
  );
  const {
    onDragStart,
    onDragOver,
    onDragEnd,
    targetIndex,
    draggable,
    setDraggable,
    // @ts-expect-error
  } = useDragAndDrop(onChangeOrder);

  const hasDraft = useMemo(() => {
    return selectedContents.filter(nonNullable).some((content) => {
      // @ts-expect-error
      return content.contentStatus !== 'PUBLISH';
    });
  }, [selectedContents]);

  const [error, setError] = useState(null);

  const relationListCount =
    parentField?.relationListCountLimitValidation?.relationListCount;
  const relationListCountMin = relationListCount?.min;
  const relationListCountMax = relationListCount?.max;

  useEffect(() => {
    if (
      relationListCountMin === null &&
      relationListCountMax < selectedContents.length
    ) {
      setError(
        // @ts-expect-error
        t('Select {{count}} or less contents', {
          count: relationListCountMax,
        }),
      );
    } else if (
      relationListCountMin > selectedContents.length &&
      relationListCountMax === null
    ) {
      setError(
        // @ts-expect-error
        t('Select {{count}} or more contents', {
          count: relationListCountMin,
        }),
      );
    } else if (
      (relationListCountMin !== null &&
        relationListCountMin > selectedContents.length) ||
      (relationListCountMax !== null &&
        relationListCountMax < selectedContents.length)
    ) {
      setError(
        // @ts-expect-error
        t('Select between {{min}} to {{max}} contents', {
          min: relationListCountMin,
          max: relationListCountMax,
        }),
      );
    } else {
      setError(null);
    }
  }, [
    selectedContents.length,
    relationListCountMin,
    relationListCountMax,
    setError,
    t,
  ]);

  // 表示項目の制限
  const localStoragePath = `${api?.id}#${endpoint}#${parentField.idValue}`;
  const localStorageItems = useLocalStorageItems(localStoragePath);
  const [items, setItems] = useState(
    localStorageItems || api?.apiFields.map((field) => field.idValue),
  );
  // TODO:apiはuseToggleItem内で未使用なので削除してOK
  const toggleItem = useToggleItem(api, localStoragePath, items, setItems);

  if (isLoading) {
    return null;
  }

  if (totalCount === 0 && value.length === 0) {
    // ユーザーの権限で選択できるコンテンツが0件かつ選択済みコンテンツが0件の場合
    // 権限上、選択できるコンテンツがない場合でも、すでに選択ずみのコンテンツがある場合はそれ自体は編集できるため。
    return (
      <Errorfield>
        {Trans({
          t,
          i18nKey: 'There are no contents to refer',
          children: (
            <Link to={`/apis/${api.apiEndpoint}/create`}>
              Create a new content
            </Link>
          ),
        })}
      </Errorfield>
    );
  }

  return (
    <div>
      <div className={styles.selectedContent}>
        {value.length > 0 && (
          <table className={styles.table}>
            <thead className={styles.thead}>
              <tr>
                <th className={styles.th}></th>
                <th className={styles.th}>{t('Delete')}</th>
                <th className={styles.th}>{t('Status')}</th>
                {items.includes('contentId') && (
                  <th className={styles.th}>{t('Content ID')}</th>
                )}
                {items.includes('createdAt') && (
                  <th className={styles.th}>{t('Date Created')}</th>
                )}
                {api.apiFields.map(
                  (field, i) =>
                    items.includes(field.idValue) && (
                      <th
                        key={i}
                        className={`${styles.th} ${styles[field.kind]}`}
                      >
                        {field.name}
                      </th>
                    ),
                )}
              </tr>
            </thead>
            <tbody className={styles.tbody}>
              {selectedContents.map((v, i) => (
                <Content
                  key={i}
                  content={v}
                  fields={api.apiFields}
                  items={items}
                  onClick={readOnly !== true ? edit(i) : undefined}
                  draggable={draggable}
                  setDraggable={setDraggable}
                  onDragStart={onDragStart}
                  onDragOver={onDragOver}
                  onDragEnd={onDragEnd}
                  targetIndex={targetIndex}
                  remove={remove(i)}
                  index={i}
                  isSelected={true}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
      {error !== null && <p className={styles.errorText}>{error}</p>}
      {!relationListCount && (
        <Button
          value={t('Add')}
          type="tertiary"
          onClick={readOnly !== true ? add : undefined}
        />
      )}
      {!relationListCountMax && relationListCount?.min !== undefined && (
        <Button
          value={t('Add')}
          type="tertiary"
          onClick={readOnly !== true ? add : undefined}
        />
      )}
      {relationListCountMax > selectedContents?.length && (
        <Button
          value={t('Add')}
          type="tertiary"
          onClick={readOnly !== true ? add : undefined}
        />
      )}
      {hasDraft && (
        <p className={styles.alert}>
          {t('Only published contents can be obtained by API.')}
        </p>
      )}
      <Modal open={isOpenRelationModal} onOpenChange={toggleRelationModal}>
        <ModalContent size="large" hasSpace={false}>
          <RelationModal
            select={select}
            api={api}
            close={() => toggleRelationModal(false)}
            items={items}
            toggleItem={toggleItem}
          />
        </ModalContent>
      </Modal>
    </div>
  );
};

export default RelationListSelect;
