import { apiListSelectors } from '@/ducks/apiList';
import type { ApiList, MigrateApi } from '@/entity/api';
import { useAppSelector } from '@/store/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { useCustomFields } from '../../../hooks/CustomField/useCustomFieldReader';
import useCustomFieldWriter from '../../../hooks/CustomField/useCustomFieldWriter';
import { useExceptionPermissionIsHaveLeastOne } from '../../../hooks/Role/useMyRoles';
import Feedback from '../../Feedback';
import Head from '../../Head';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import styles from './deleteSettings.module.css';

const DeleteSettings: React.FC = () => {
  const { t } = useTranslation('customFieldsDeleteSettings');

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint, fieldId } = useParams<{
    endpoint: string;
    fieldId: string;
  }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );

  const [hasPermission] = useExceptionPermissionIsHaveLeastOne(
    api.partitionKey,
    'apiUpdate',
  );
  const [customFields = []] = useCustomFields(api.partitionKey);
  const [localError, setLocalError] = useState<string>();
  const field = useMemo(
    () => customFields.find((field) => field.fieldId === fieldId),
    [customFields, fieldId],
  );
  const { result, error, loading, deleteFunc } = useCustomFieldWriter(api);
  useEffect(() => {
    const timerId =
      // @ts-expect-error
      localError !== undefined && setTimeout(() => setLocalError(), 3000);
    // @ts-expect-error
    return () => clearTimeout(timerId);
  }, [localError]);
  const deleteField = useCallback(() => {
    const usedByApi = api.apiFields.some(
      (f) =>
        (f.kind === 'custom' && f.customFieldCreatedAt === field?.createdAt) ||
        (f.kind === 'repeater' &&
          field &&
          f.customFieldCreatedAtList?.includes(field.createdAt)),
    );
    if (usedByApi) {
      setLocalError(
        t('Cannot be deleted because it is being used by the API.'),
      );
      return;
    }
    const res = window.confirm(
      t('Are you sure you want to delete {{customFieldName}}?', {
        customFieldName: field?.name,
      }),
    );
    if (res) {
      deleteFunc(field);
    }
  }, [api.apiFields, t, field, deleteFunc]);

  if (result && loading === false) {
    return <Redirect to={`/apis/${endpoint}/custom-fields`} />;
  }

  if (!hasPermission) {
    return null;
  }

  if (!field) {
    return null;
  }

  return (
    <div>
      <Head title={t('Custom Fields/Delete')} />
      <h2 className={styles.title}>{t('Delete')}</h2>
      <Fieldset
        legend={t('Delete Custom Field')}
        description={t(
          'Proceed with caution. A Custom field cannot be deleted if it is used within a Repeated field.',
        )}
      >
        <Button
          type="danger"
          disabled={loading}
          value={t('Delete {{customFieldName}}', {
            customFieldName: field.name,
          })}
          onClick={deleteField}
          className={styles.button}
        />
      </Fieldset>
      {/* @ts-expect-error */}
      <Feedback type="failure" message={error ? error.message : undefined} />
      <Feedback type="failure" message={localError} />
    </div>
  );
};

export default DeleteSettings;
