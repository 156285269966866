import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useContentsCount from '@/hooks/useContentsCount';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';

import Head from '../../Head';
import { ContentsCountMessage } from './ContentsCountMessage';

import styles from './contentsCountSettings.module.css';

const ContentsSettings: React.FC = () => {
  const { service } = useGetMyService();
  const { t } = useTranslation('serviceContentsCountSettings');
  const { contentsCount, isLoading } = useContentsCount(
    service?.partitionKey || '',
  );
  const { currentPlan } = useStripeActions(service);

  const [limitContentsCount, setLimitContentsCount] = useState<number | null>(
    null,
  );
  useEffect(() => {
    if (currentPlan) {
      setLimitContentsCount(currentPlan.limit?.contentsCount);
    }
  }, [currentPlan]);

  return (
    <div>
      <Head title={t('Contents Count')} />
      <h2 className={styles.title}>{t('Contents Count')}</h2>
      <p>{t('Current number of contents')}</p>
      <p className={styles.sum}>
        <span className={styles.number}>
          {isLoading ? '...' : contentsCount?.toLocaleString()}
        </span>
        {limitContentsCount !== null && (
          <span className={styles.limitNumber}>
            / {limitContentsCount.toLocaleString()}
          </span>
        )}
        <span className={styles.unit}>
          {t('contents', { count: contentsCount })}
        </span>
      </p>
      {limitContentsCount !== null && (
        <ContentsCountMessage limitContentsCount={limitContentsCount} />
      )}
    </div>
  );
};

export default ContentsSettings;
