import cx from 'classnames';
import type React from 'react';

import styles from './externalLink.module.css';

type Props = {
  href: string;
  children: React.ReactNode;
  hasUnderline?: boolean;
  eraseNoreferrer?: boolean;
  className?: string;
  target?: string;
};

const ExternalLink: React.FC<Props> = ({
  href,
  children,
  hasUnderline,
  eraseNoreferrer = false,
  className,
  target = '_blank',
}) => {
  return (
    /* eslint-disable-next-line */
    <a
      className={cx(styles.link, className)}
      href={href}
      target={target}
      rel={eraseNoreferrer ? undefined : 'noopener noreferrer'}
    >
      <span className={hasUnderline ? styles.text : ''}>{children}</span>
      <i className={`material-icons ${styles.icon}`}>launch</i>
    </a>
  );
};

export default ExternalLink;
