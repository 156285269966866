import { useMemo } from 'react';

import { buildDiffNodes } from '../../parts/DiffNodesDisplay';
import { DiffNodesDisplay } from '../../parts/DiffNodesDisplay';

import styles from './image.module.css';

type ImageItem = {
  url: string;
  width: number;
  height: number;
};

type Props = {
  sinceValue: ImageItem[];
  untilValue: ImageItem[];
};

const Image: React.FC<Props> = ({ sinceValue, untilValue }) => {
  const imageMap = useMemo(() => {
    const map: Record<string, ImageItem> = {};

    for (const image of sinceValue) {
      map[image.url] = image;
    }

    for (const image of untilValue) {
      map[image.url] = image;
    }

    return map;
  }, [sinceValue, untilValue]);

  const sinceUrlsString = sinceValue.map((media) => media.url).join('\n');
  const untilUrlsString = untilValue.map((media) => media.url).join('\n');

  const ConversionElement = ({ value }: { value: string }) => {
    return <Element {...imageMap[value]} />;
  };

  const diffNodes = buildDiffNodes({
    sinceValue: sinceUrlsString,
    untilValue: untilUrlsString,
    Element: ConversionElement,
  });

  return <DiffNodesDisplay nodes={diffNodes} position="center" />;
};

type ElementProps = {
  url: string;
  width: number;
  height: number;
};

const Element: React.FC<ElementProps> = ({ url, width, height }) => {
  return (
    <div className={styles.wrapper}>
      <img
        className={styles.image}
        src={url}
        width={width}
        height={height}
        loading="lazy"
        decoding="async"
        // ANKEN-833: メディアにAltを設定できるようになる機能が実装されたら設定する
        alt=""
      />
    </div>
  );
};

export { Image };
export type { ImageItem };
