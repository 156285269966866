import { useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import type { FocusManagement } from './useCodeBlock';
import type { NodeViewProps } from '@tiptap/core';

type Args = {
  editor: NodeViewProps['editor'];
  open: boolean;
  focusManagement: FocusManagement;
  toggleFocusManagement: (state: FocusManagement) => void;
  currentPosition: number;
};

export const useCodeBlockKeyEvent = ({
  editor,
  open,
  focusManagement,
  toggleFocusManagement,
  currentPosition,
}: Args) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const id = uuid();

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      const { $from, $anchor } = editor.state.selection;
      const beforeNodeSize = $anchor.nodeBefore?.nodeSize || 0;
      const firstPos = $from.pos - beforeNodeSize - 1;
      const isCurrentSelectCodeBlock = firstPos === currentPosition;
      // 現在のカーソル位置がキー操作対象のコードブロックかどうか判定
      if (!isCurrentSelectCodeBlock) {
        return;
      }

      const autoCompleteElement = document.getElementById(
        `codeblock-autocomplete-${id}`,
      );

      // 言語選択メニューが出ている時は移動できないようにする
      if (e.code === 'Tab' && open) {
        e.preventDefault();
        return;
      }

      // shift + tab でファイル名にフォーカスが当たっている時は、言語選択にフォーカスを移動
      if (e.shiftKey && e.code === 'Tab' && focusManagement.isInputFocus) {
        e.preventDefault();
        inputRef.current?.blur();
        editor.view.focus();
        toggleFocusManagement({
          isInputFocus: false,
          isAutoCompleteFocus: false,
        });
        return;
      }

      // shift + tab で言語選択にフォーカスが当たっている時は、ファイル名にフォーカスを移動
      if (
        e.shiftKey &&
        e.code === 'Tab' &&
        focusManagement.isAutoCompleteFocus
      ) {
        e.preventDefault();
        autoCompleteElement?.blur();
        inputRef.current?.focus();
        toggleFocusManagement({
          isInputFocus: true,
          isAutoCompleteFocus: false,
        });
        return;
      }

      // shift + tab でコードブロック本文にフォーカスが当たっている時は、デフォルトの挙動をする
      if (e.shiftKey && e.code === 'Tab') {
        return;
      }

      // tab でファイル名にフォーカスが当たっている時は、言語選択にフォーカスを移動
      if (e.code === 'Tab' && focusManagement.isInputFocus) {
        e.preventDefault();
        inputRef.current?.blur();
        autoCompleteElement?.focus();
        toggleFocusManagement({
          isInputFocus: false,
          isAutoCompleteFocus: true,
        });
        return;
      }

      // tab で言語選択にフォーカスが当たっている時は、コードブロック本文にフォーカスを移動
      if (e.code === 'Tab' && focusManagement.isAutoCompleteFocus) {
        e.preventDefault();
        autoCompleteElement?.blur();
        editor.view.focus();

        toggleFocusManagement({
          isInputFocus: false,
          isAutoCompleteFocus: false,
        });
        return;
      }

      // tab でコードブロック本文にフォーカスが当たっている時は、ファイル名にフォーカスを移動
      if (
        e.code === 'Tab' &&
        !focusManagement.isInputFocus &&
        !focusManagement.isAutoCompleteFocus &&
        editor.isFocused
      ) {
        e.preventDefault();
        inputRef.current?.focus();
        toggleFocusManagement({
          isInputFocus: true,
          isAutoCompleteFocus: false,
        });
        return;
      }
    };

    window.addEventListener('keydown', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyUp);
    };
  }, [
    currentPosition,
    editor,
    focusManagement.isAutoCompleteFocus,
    focusManagement.isInputFocus,
    id,
    open,
    toggleFocusManagement,
  ]);

  return { inputRef, id };
};
