import type { ApiListByRedux } from '@/entity/api';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../ui/Button';
import styles from './SelectRelationInModal.module.css';

type Props = {
  apiList: ApiListByRedux['apiList'] | undefined;
  relationKind: 'relation' | 'relationList' | undefined;
  relationContentRef: React.MutableRefObject<HTMLSelectElement | null>;
  setRelation: () => void;
  closeRelationModal: () => void;
};

const SelectRelationInModal: React.FC<Props> = ({
  apiList,
  relationKind,
  relationContentRef,
  setRelation,
  closeRelationModal,
}) => {
  const { t } = useTranslation('selectRelationInModal');

  const isRelation = relationKind === 'relation';
  const selectRelationList = useMemo(
    () =>
      apiList?.filter(({ apiType }) => isRelation || apiType === 'LIST') ?? [],
    [apiList, isRelation],
  );

  return (
    <div>
      {selectRelationList.length > 0 ? (
        <div>
          <p className={styles.modalDescription}>
            {t('Select a Content to Refer')}
          </p>
          <select className={styles.selectfield} ref={relationContentRef}>
            {selectRelationList.map((api, i) => (
              <option key={i} value={api.partitionKey}>
                {api.apiName} ({api.apiEndpoint})
              </option>
            ))}
          </select>
          <div className={styles.modalActionButtonWrapper}>
            <Button
              type="secondary"
              className={styles.modalActionButton}
              onClick={setRelation}
              value={t('Confirm')}
            />
          </div>
        </div>
      ) : (
        <div className={styles.modalAction}>
          <p className={styles.modalDescription}>
            {t('There is no content available for reference.')}
          </p>
          <Button
            type="secondary"
            className={styles.modalActionButton}
            onClick={closeRelationModal}
            value={t('Confirm')}
          />
        </div>
      )}
    </div>
  );
};

export default SelectRelationInModal;
