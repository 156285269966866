import { connect } from 'react-redux';

import { authOperations, authSelectors } from '../../ducks/auth';
import Signup from './Signup';

const mapStateToProps = (state: any) => {
  return authSelectors.getSignupState(state.authState);
};

const mapDispatchToProps = {
  confirmSignup: authOperations.confirmSignup,
  // @ts-expect-error
  signin: authOperations.signin,
  reset: authOperations.resetAuth,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
