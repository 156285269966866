import { nanoid } from 'nanoid';
import { useCallback } from 'react';

import type { Props } from './InputCustomClassField';

export const useInputCustomClassField = ({
  customClassList,
  setCustomClassList,
}: Props) => {
  const addCustomClass = useCallback(() => {
    const newItems = [
      ...customClassList,
      { id: nanoid(10), name: '', value: '' },
    ];
    setCustomClassList(newItems);
  }, [customClassList, setCustomClassList]);

  const removeCustomClass = useCallback(
    (id?: string | null) => () => {
      const newItems = customClassList?.filter((item) => item?.id !== id);
      setCustomClassList(newItems);
    },
    [customClassList, setCustomClassList],
  );

  const onChangeName = useCallback(
    (id?: string | null) => (e?: React.ChangeEvent<HTMLInputElement>) => {
      const value = e?.target.value;
      const newItems = customClassList?.map((item) => {
        if (item?.id === id) {
          return {
            id,
            value: item?.value,
            name: value,
          };
        }
        return item;
      });

      setCustomClassList(newItems);
    },
    [customClassList, setCustomClassList],
  );

  const onChangeValue = useCallback(
    (id?: string | null) => (e?: React.ChangeEvent<HTMLInputElement>) => {
      const value = e?.target.value;
      const newItems = customClassList?.map((item) => {
        if (item?.id === id) {
          return {
            id,
            value: value,
            name: item?.name,
          };
        }
        return item;
      });

      setCustomClassList(newItems);
    },
    [customClassList, setCustomClassList],
  );

  const onChangeOrder = useCallback(
    (x: number, y: number) => {
      if (x === y) {
        return;
      }
      const copy = [...customClassList];
      copy.splice(y, 0, customClassList[x]);
      if (x < y) {
        copy.splice(x, 1);
      } else {
        copy.splice(x + 1, 1);
      }

      setCustomClassList(copy);
    },
    [customClassList, setCustomClassList],
  );

  return {
    addCustomClass,
    removeCustomClass,
    onChangeName,
    onChangeValue,
    onChangeOrder,
  };
};
