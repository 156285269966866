import type React from 'react';
import { createPortal } from 'react-dom';

import styles from './modal.module.css';

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  close: () => void;
}

export const Modal: React.FC<Props> = ({ children, isOpen, close }) => {
  if (!isOpen) {
    return null;
  }
  return createPortal(
    <div className={styles.wrapper}>
      <div className={styles.mask} onClick={close} />
      <div className={styles.container}>{children}</div>
    </div>,
    document.getElementById('root') as HTMLElement,
  );
};
