import { useState, useCallback } from 'react';

import { validateEmpty } from '@/components/Validations';

type ValidatorFn = <V extends string>(value: V) => string | null | undefined;

type Options = {
  /**
   * バリデーター関数
   *
   * @returns バリデーション結果に問題があれば、その旨のメッセージを返す
   */
  validator?: ValidatorFn;
  /**
   * 初期化時にバリデーションを実施する場合はtrue
   */
  validateOnInit?: boolean;
  /**
   * リセット時した直後にバリデーションを実施する場合はtrue
   */
  validateOnReset?: boolean;
};

/**
 * プレーンテキストの入力値の管理とバリデーションをハンドリングするフック
 */
function useInputPlainText(
  initialValue = '',
  {
    validator = validateEmpty,
    validateOnInit = false,
    validateOnReset = false,
  }: Options = {
    validator: validateEmpty,
    validateOnInit: false,
    validateOnReset: false,
  },
) {
  const [value, setValue] = useState(initialValue);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    validateOnInit
      ? () => _wrapValidatorResult(validator(initialValue))
      : undefined,
  );

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const writingValue = e.target.value;
      setValue(writingValue);
      setErrorMessage(_wrapValidatorResult(validator(writingValue)));
    },
    [validator],
  );

  const reset = useCallback(() => {
    setValue(initialValue);
    setErrorMessage(
      validateOnReset
        ? _wrapValidatorResult(validator(initialValue))
        : undefined,
    );
  }, [initialValue, validateOnReset, validator]);

  return [value, onChangeHandler, errorMessage, reset] as const;
}

/**
 * NOTE: Textfieldのerrorプロパティの型を考慮し、validator実行で問題なければundefinedとする
 */
function _wrapValidatorResult(
  validateResult: ReturnType<ValidatorFn>,
): string | undefined {
  return validateResult ?? undefined;
}

export { useInputPlainText };
