import type { ContentStatus } from '@/entity/content';

/**
 * コンテンツのステータスから、コンテンツが公開しているかどうかを判定する
 *
 * 公開している場合はtrueを返す
 *
 * @param status コンテンツのステータス
 */
function isPublishedContent(status: ContentStatus): boolean {
  return status === 'PUBLISH' || status === 'PUBLISH_AND_DRAFT';
}

export { isPublishedContent };
