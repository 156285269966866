import { useQuery } from '@tanstack/react-query';

import { getMyMembers, getMyMember } from '@/usecase/memberUsecase';

const useGetMyMembers = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['myMember'],
    queryFn: async () => {
      return getMyMembers();
    },
    staleTime: Number.POSITIVE_INFINITY,
  });
  return {
    myMembers: data,
    isLoading,
  };
};

const useGetMyMember = (serviceId: string) => {
  const { data, isLoading } = useQuery({
    queryKey: ['myMember', { serviceId }],
    queryFn: async () => {
      return getMyMember(serviceId);
    },
    staleTime: Number.POSITIVE_INFINITY,
  });
  return {
    myMember: data,
    isLoading,
  };
};

export { useGetMyMembers, useGetMyMember };
