import type React from 'react';
import { useCallback } from 'react';

import styles from './params.module.css';

export const listKeys = [
  'limit',
  'offset',
  'orders',
  'q',
  'fields',
  'ids',
  'filters',
  'depth',
  'draftKey',
  'richEditorFormat',
] as const;
const detailKeys = ['draftKey', 'fields', 'depth', 'richEditorFormat'] as const;
const writeKeys = ['status'] as const;
type getKey = (typeof listKeys)[number];
type writeKey = (typeof writeKeys)[number];
export interface Param {
  key: getKey | writeKey;
  value: string;
  check: boolean;
}

interface Props {
  params: Param[];
  change: (params: Param[]) => void;
  draftKey?: string;
  isList: boolean;
  isWriteApi?: boolean;
  disabled?: boolean;
}

const Params: React.FC<Props> = ({
  params,
  change,
  draftKey = '',
  isList,
  isWriteApi = false,
  disabled = false,
}) => {
  const keys = isList ? listKeys : detailKeys;
  const unusedKeys = isWriteApi
    ? writeKeys.filter((key) => !params.some((p) => p.key === key))
    : keys.filter((key) => !params.some((p) => p.key === key));

  const getDefaultValue = useCallback(
    (key: any) => {
      return key === 'draftKey'
        ? draftKey || ''
        : key === 'limit'
          ? '10'
          : key === 'offset'
            ? '0'
            : key === 'orders'
              ? '-createdAt'
              : key === 'depth'
                ? '1'
                : key === 'status'
                  ? 'draft'
                  : '';
    },
    [draftKey],
  );

  const add = useCallback(() => {
    const unusedKeys = keys.filter((key) => !params.some((p) => p.key === key));
    if (unusedKeys.length === 0) {
      return;
    }
    const unusedKey = unusedKeys[0];
    change([
      ...params,
      {
        key: unusedKey,
        value: getDefaultValue(unusedKey),
        check: true,
      },
    ]);
  }, [change, getDefaultValue, keys, params]);

  const remove = useCallback(
    (index: any) => {
      change(params.filter((_, i) => i !== index));
    },
    [change, params],
  );

  const onChangeCheck = useCallback(
    (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      change(
        params.map((param: Param, index: number) => {
          if (i === index) {
            return {
              ...param,
              check: e.target.checked,
            };
          }
          return param;
        }),
      );
    },
    [change, params],
  );
  const onChangeKey = useCallback(
    (i: number) => (e: React.ChangeEvent<HTMLSelectElement>) => {
      change(
        params.map((param: Param, index: number) => {
          if (i === index) {
            const newKey = e.currentTarget.value as getKey;
            return {
              ...param,
              key: newKey,
              value: getDefaultValue(newKey),
            };
          }
          return param;
        }),
      );
    },
    [change, getDefaultValue, params],
  );
  const onChangeValue = useCallback(
    (i: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      change(
        params.map((param: Param, index: number) => {
          if (i === index) {
            return {
              ...param,
              value: e.currentTarget.value,
            };
          }
          return param;
        }),
      );
    },
    [change, params],
  );
  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={`${styles.th} ${styles.check}`}></th>
          <th className={`${styles.th} ${styles.key}`}>Key</th>
          <th className={styles.th}>Value</th>
        </tr>
      </thead>
      <tbody>
        {params.map((param, i) => (
          <tr key={i} className={styles.tr}>
            <td className={`${styles.td} ${styles.check}`}>
              <input
                type="checkbox"
                checked={param.check}
                onChange={onChangeCheck(i)}
                disabled={disabled}
              />
            </td>
            <td className={`${styles.td} ${styles.key}`}>
              <select
                className={styles.select}
                value={param.key}
                onChange={onChangeKey(i)}
              >
                {[param.key, ...unusedKeys].map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </select>
            </td>
            <td className={styles.td}>
              <input
                type="text"
                className={styles.input}
                value={param.value}
                onChange={onChangeValue(i)}
                placeholder={
                  param.key === 'filters'
                    ? 'fieldId[equals]contentId'
                    : param.key === 'fields'
                      ? 'id,title,updatedAt,author.name'
                      : param.key === 'ids'
                        ? 'first_id,second_id,third_id'
                        : param.key === 'limit'
                          ? '10'
                          : param.key === 'offset'
                            ? '0'
                            : param.key === 'orders'
                              ? '-createdAt'
                              : param.key === 'depth'
                                ? '1'
                                : param.key === 'richEditorFormat'
                                  ? 'html | object'
                                  : ''
                }
              />
              {!isWriteApi && (
                <button
                  className={styles.removeButton}
                  onClick={() => remove(i)}
                >
                  <i className="material-icons">clear</i>
                </button>
              )}
            </td>
          </tr>
        ))}
        {!isWriteApi && (
          <tr>
            <td className={styles.td} colSpan={3}>
              <button className={styles.addButton} onClick={add}>
                <i className="material-icons">add</i>
              </button>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Params;
