/* tslint:disable */
/* eslint-disable */
/**
 * マネジメントAPI
 * マネジメントAPI
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * コンテンツ作成者の更新リクエスト
 * @export
 * @interface PatchContentCreatedByRequest
 */
export interface PatchContentCreatedByRequest {
  /**
   *
   * @type {string}
   * @memberof PatchContentCreatedByRequest
   */
  createdBy: string;
}

export function PatchContentCreatedByRequestFromJSON(
  json: any,
): PatchContentCreatedByRequest {
  return PatchContentCreatedByRequestFromJSONTyped(json, false);
}

export function PatchContentCreatedByRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchContentCreatedByRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    createdBy: json['createdBy'],
  };
}

export function PatchContentCreatedByRequestToJSON(
  value?: PatchContentCreatedByRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    createdBy: value.createdBy,
  };
}
