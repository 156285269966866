import cx from 'classnames';
import { useMemo } from 'react';

import Linkify from '@/components/Linkify';

import { ExpandedButton } from '../ExpandedButton';

import styles from './diffNodesDisplay.module.css';

import { Icon } from '@/views/Common/Ui/Icon';

type DiffNode = {
  since: {
    value: React.ReactNode;
    type: 'added' | 'removed' | 'noChange';
  } | null;
  until: {
    value: React.ReactNode;
    type: 'added' | 'removed' | 'noChange';
  } | null;
};

type ViewNode = {
  type: 'expanded' | 'omitted';
  key: number;
};

type Omittable = {
  enabled: boolean;
  expandedStartLineNumbers: number[];
  setExpandedStartLineNumbers: React.Dispatch<React.SetStateAction<number[]>>;
};

type Props = {
  nodes: DiffNode[];
  position?: 'top' | 'center';
  gap?: boolean;
  json?: boolean;
  url?: boolean;
  omittable?: Omittable;
};

const buildViewNodes = (
  nodes: DiffNode[],
  enabled: boolean,
  expandedStartLineNumbers: number[],
): ViewNode[] => {
  if (!enabled) {
    return nodes.map((_, index) => ({
      type: 'expanded',
      key: index,
    }));
  }

  const viewNodes: ViewNode[] = [];

  for (let i = 0; i < nodes.length; i++) {
    // noChangeが3つ以上続いたら省略
    let noChangeCount = 0;
    for (let j = i; j < nodes.length; j++) {
      if (
        nodes[j].since?.type === 'noChange' &&
        nodes[j].until?.type === 'noChange'
      ) {
        noChangeCount++;
      } else {
        break;
      }
    }

    if (noChangeCount >= 3) {
      if (expandedStartLineNumbers.includes(i)) {
        for (let j = i; j < i + noChangeCount; j++) {
          viewNodes.push({ type: 'expanded', key: j });
        }

        i += noChangeCount - 1;
        continue;
      }

      viewNodes.push({
        type: 'expanded',
        key: i,
      });
      viewNodes.push({
        type: 'omitted',
        key: i + 1,
      });
      viewNodes.push({
        type: 'expanded',
        key: i + noChangeCount - 1,
      });

      i += noChangeCount - 1;
      continue;
    }

    viewNodes.push({ type: 'expanded', key: i });
  }

  return viewNodes;
};

const DiffNodesDisplay: React.FC<Props> = ({
  nodes,
  position = 'top',
  gap = false,
  json = false,
  url = false,
  omittable,
}) => {
  const viewNodes: ViewNode[] = useMemo(() => {
    return buildViewNodes(
      nodes,
      omittable?.enabled ?? false,
      omittable?.expandedStartLineNumbers ?? [],
    );
  }, [nodes, omittable?.enabled, omittable?.expandedStartLineNumbers]);

  return (
    <table className={cx(styles.table, gap && styles.gap)}>
      <tbody className={styles.tbody}>
        {viewNodes.map((node) => {
          switch (node.type) {
            case 'expanded': {
              return (
                <NodesRow
                  key={node.key}
                  node={nodes[node.key]}
                  position={position}
                  json={json}
                  url={url}
                />
              );
            }

            case 'omitted': {
              return (
                <ExpandedButtonsRow
                  key={node.key}
                  onClick={() =>
                    omittable?.setExpandedStartLineNumbers?.((prev) =>
                      prev.concat([node.key - 1]),
                    )
                  }
                />
              );
            }
          }
        })}
      </tbody>
    </table>
  );
};

type NodesRowProps = {
  node: DiffNode;
  position: 'top' | 'center';
  json: boolean;
  url: boolean;
};

const NodesRow: React.FC<NodesRowProps> = ({ node, position, json, url }) => {
  return (
    <tr>
      <td className={styles.td}>
        <div
          className={cx(
            styles.nodeGroup,
            node.since?.type === 'removed' && styles.removed,
            node.since === null && styles.empty,
          )}
        >
          <div
            className={cx(styles.icon, position === 'center' && styles.center)}
          >
            {node.since?.type === 'removed' ? <Icon name="remove" /> : null}
          </div>
          <div
            className={cx(
              styles.node,
              position === 'center' && styles.center,
              json && styles.json,
            )}
          >
            {url ? <Linkify>{node.since?.value}</Linkify> : node.since?.value}
          </div>
        </div>
      </td>
      <td className={styles.td}>
        <div
          className={cx(
            styles.nodeGroup,
            node.until?.type === 'added' && styles.added,
            node.until === null && styles.empty,
          )}
        >
          <div
            className={cx(styles.icon, position === 'center' && styles.center)}
          >
            {node.until?.type === 'added' ? <Icon name="add" /> : ''}
          </div>
          <div
            className={cx(
              styles.node,
              position === 'center' && styles.center,
              json && styles.json,
            )}
          >
            {url ? <Linkify>{node.until?.value}</Linkify> : node.until?.value}
          </div>
        </div>
      </td>
    </tr>
  );
};

type ExpandedButtonRowProps = {
  onClick: () => void;
};

const ExpandedButtonsRow: React.FC<ExpandedButtonRowProps> = ({ onClick }) => {
  return (
    <tr className={styles.expandedRow}>
      <td colSpan={2}>
        <ExpandedButton onClick={onClick} />
      </td>
    </tr>
  );
};

export { DiffNodesDisplay, buildViewNodes };
export type { DiffNode, Omittable };
