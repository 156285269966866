import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Medium } from '@/entity/medium';

import { nonNullable } from '@/util/type-guard';

export type ValidateOptions = {
  width?: number;
  height?: number;
};

type UseValidation = (
  medium: Medium | null,
  validateOptions?: ValidateOptions,
) => string[] | null;

export const useValidation: UseValidation = (medium, validateOptions) => {
  const { t } = useTranslation('imageOperation');

  const errors: string[] | null = useMemo(() => {
    if (!medium) return null;

    const errors: string[] = [];

    // 画像サイズのバリデーション
    const { width, height } = validateOptions ?? {};
    const hasValidateSize = width || height;

    if (hasValidateSize) {
      const errorDetail = [
        !width || medium.imageWidth === width
          ? null
          : t('width {{width}}px', { width }),
        !height || medium.imageHeight === height
          ? null
          : t('height {{height}}px', { height }),
      ]
        .filter(nonNullable)
        .join(t('period'));

      errorDetail.length > 0 &&
        errors.push(t('Use image of {{errorDetail}}', { errorDetail }));
    }

    return errors.length > 0 ? errors : null;
  }, [medium, t, validateOptions]);

  return errors;
};
