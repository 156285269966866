import { useTranslation } from 'react-i18next';

import Head from '@/components/Head';
import Button from '@/components/ui/Button';

import { useGetMyService } from '@/hooks/useService';

import { useDeleteSettings } from './useDeleteSettings';

import styles from './deleteSettings.module.css';

import { AlertDialog } from '@/views/Common/Ui/AlertDialog';
import { Fieldset } from '@/views/Common/Ui/Fieldset';

type ViewProps = {
  onDeleteAllMedia: () => void;
  deleteAllMediaLoading: boolean;
  isRunningDeleteAllMedia: boolean;
  serviceName?: string;
  serviceDomain?: string;
};

export const DeleteSettingsView: React.FC<ViewProps> = ({
  onDeleteAllMedia,
  deleteAllMediaLoading,
  isRunningDeleteAllMedia,
  serviceName,
  serviceDomain,
}) => {
  const { t } = useTranslation('mediaDeleteSettings');

  return (
    <div>
      <Head title={t('Delete all media')} />
      <h2 className={styles.title}>{t('Delete all media')}</h2>
      <div className={styles.group}>
        <Fieldset
          label={t('Delete all media of {{serviceName}} service', {
            serviceName,
          })}
          description={t('Warning! Once deleted, this cannot be restored.')}
        >
          <AlertDialog
            trigger={
              <Button
                type="danger"
                value={t('#Delete')}
                className={styles.button}
                disabled={deleteAllMediaLoading || isRunningDeleteAllMedia}
              />
            }
            title={t('Delete all media')}
            description={t(
              'Are you sure you want to delete all media? To perform the deletion, enter {{serviceId}}. Deleted media cannot be restored.',
              { serviceId: serviceDomain },
            )}
            buttonText={t('#Delete')}
            onSubmit={onDeleteAllMedia}
            confirmText={serviceDomain}
          />
        </Fieldset>
      </div>
    </div>
  );
};

export const DeleteSettings: React.FC = () => {
  const { service } = useGetMyService();

  const { onDeleteAllMedia, deleteAllMediaLoading, isRunningDeleteAllMedia } =
    useDeleteSettings(service?.partitionKey || '', service?.serviceName || '');

  return (
    <DeleteSettingsView
      onDeleteAllMedia={onDeleteAllMedia}
      deleteAllMediaLoading={deleteAllMediaLoading}
      isRunningDeleteAllMedia={isRunningDeleteAllMedia}
      serviceName={service?.serviceName}
      serviceDomain={service?.domain}
    />
  );
};
