import { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useGetMyService } from '@/hooks/useService';

import { myRolesContext } from '../../hooks/Role/useMyRoles';
import CheckPlans from '../CheckPlans';

import styles from './privateRoute.module.css';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthorized } = rest;
  const { service, parentService } = useGetMyService();
  const { roles } = useContext(myRolesContext);

  if (isAuthorized === undefined) {
    return (
      <div className={styles.loading}>
        <img src="/images/icon_loading.svg" alt="" />
      </div>
    );
  }

  if (isAuthorized === false) {
    return <Redirect to="/signin" />;
  }

  if (service === undefined) {
    return (
      <div className={styles.loading}>
        <img src="/images/icon_loading.svg" alt="" />
      </div>
    );
  }

  // ProfileやCreateServiceではserviceがないためプランのチェックはしない
  if (service === null) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  // Service選択済みの場合はrolesが取得されるまで待つ
  // getStripeSubscriptionの呼び分けのため
  if (roles === undefined) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <CheckPlans service={service} parentService={parentService}>
          <Component {...props} />
        </CheckPlans>
      )}
    />
  );
};

export default PrivateRoute;
