import { useCallback, useState } from 'react';

import type { Props } from './FloatingMenu';
import type { Editor } from '@tiptap/react';

const isMatchLinkText = (editor: Editor) => {
  const { view, state } = editor;
  const { from, to, $head } = view.state.selection;

  const selectText = state.doc.textBetween(from, to, '');

  const cursorBeforeText = $head.nodeBefore?.textContent;
  const cursorAfterText = $head.nodeAfter?.textContent;
  // cursorが当たっているコンテンツ全体を取得する
  const cursorAllText = (cursorBeforeText || '') + (cursorAfterText || '');
  // selectTextが存在する場合、cursorAllTextは後の文章に範囲が広がってしまうため
  const cursorText = selectText ? cursorBeforeText : cursorAllText;

  return cursorText === selectText && editor.isActive('link');
};

export const useFloatingMenu = ({ editor }: Props) => {
  const [openTooltip, setOpenTooltip] = useState<boolean>(
    isMatchLinkText(editor),
  );
  const onToggleTooltip = useCallback(() => {
    setOpenTooltip((prev) => !prev);

    //tooltipを閉じる際に、新しく入力したリンクテキストの最後にfocusを戻す
    editor.commands.extendMarkRange('link');
    editor.commands.focus(editor.view.state.selection.to);
  }, [editor.commands, editor.view.state.selection.to]);

  return { openTooltip, onToggleTooltip };
};
