import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from './_types';
import type { Role } from '@/entity/role';

import { produce } from '@/lib/immer';

type SetApiPermissionCreateAction = FormRoleInputValueAction<
  'set-api-permission-create',
  Role['apiPermission']['create']
>;
type ApiPermissionAction = SetApiPermissionCreateAction;

/**
 * ApiPermissionのcreateフィールドを更新する
 */
const setApiPermissionCreate: FormRoleInputValueDispatchFn<
  SetApiPermissionCreateAction
> = (currentState, action) => {
  return produce(currentState, (draft) => {
    draft.apiPermission.create = action;
  });
};

export { type ApiPermissionAction, setApiPermissionCreate };
