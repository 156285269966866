import cx from 'classnames';
import type React from 'react';
import { forwardRef, useId } from 'react';

import type { KeyboardEvent } from 'react';

import styles from './switch.module.css';

type Props = {
  size?: 'small' | 'large';
  onChange?: (on: boolean) => void;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  on?: boolean;
  errorText?: string | null;
  disabled?: boolean;
  dataTestid?: string;
} & Omit<React.ComponentProps<'div'>, 'onChange' | 'onClick'>;

type ViewProps = {
  switchRef: React.Ref<HTMLDivElement>;
  labelId: string;
} & Props;

const SwitchView: React.FC<ViewProps> = ({
  switchRef,
  labelId,
  size = 'small',
  children,
  onChange = () => null,
  onClick,
  className,
  on = false,
  errorText = null,
  disabled = false,
  tabIndex = 0,
  title = 'switch',
  dataTestid,
  ...props
}) => {
  // buttonタグではない要素でrole=switchを表現するためにclickイベントとkeyイベントを設定する
  const handleClick = () => {
    !disabled && onChange(!on);
  };
  const handleKeydown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ' ') {
      !disabled && onChange(!on);
    }
  };
  return (
    <div className={className} onClick={onClick}>
      <div className={styles.wrapper}>
        <div
          ref={switchRef}
          role="switch"
          aria-checked={on}
          aria-readonly={disabled}
          aria-labelledby={labelId}
          tabIndex={tabIndex}
          className={cx(
            styles.switch,
            styles[size],
            { [styles.on]: on },
            { [styles.disabled]: disabled },
          )}
          onClick={handleClick}
          onKeyDown={handleKeydown}
          title={title}
          data-testid={dataTestid}
          {...props}
        />
        <div id={labelId} className={`${styles.text} ${styles[size]}`}>
          {children}
        </div>
      </div>
      {errorText !== null && (
        <p role="alert" className={styles.errorText}>
          {errorText}
        </p>
      )}
    </div>
  );
};

const Switch = forwardRef<HTMLDivElement, Props>((props, forwardedRef) => {
  const labelId = useId();

  return <SwitchView switchRef={forwardedRef} labelId={labelId} {...props} />;
});
Switch.displayName = 'Switch';

export default Switch;
