import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToasts } from 'react-toast-notifications';

import { createRole as createRoleUsecase } from '@/usecase/roleUsecase';

import { getRoleOutput } from './convRoleInputOutput';
import { useI18nFormRoleHelpers } from '../i18n';

import type { FormRoleInputValue } from '../provider/stateContext';
import type { Service } from '@/types/services';

import { hasDuplicates } from '@/util/array';

function useCreateRole(serviceId: Service['partitionKey']) {
  const cache = useQueryClient();
  const { t } = useI18nFormRoleHelpers();
  const { addToast } = useToasts();

  const { mutate, data, isLoading } = useMutation({
    mutationFn: async (inputValue: FormRoleInputValue) => {
      // 個別権限でAPIが重複している場合はエラー
      if (
        hasDuplicates(
          inputValue.exceptionContentPermissions.map(({ apiId }) => apiId),
        )
      ) {
        addToast(t('Same API already exists'), { appearance: 'error' });
        return;
      }

      return createRoleUsecase(getRoleOutput(inputValue, { serviceId }, true));
    },
    async onSuccess(data) {
      if (data) {
        await cache.invalidateQueries(['roleList'], { type: 'all' });
        addToast(t('Created role'), {
          appearance: 'success',
        });
      }
    },
    onError(e) {
      console.error(e);
      addToast(t('Could not be created role'), {
        appearance: 'error',
      });
    },
  });

  return [mutate, data, isLoading] as const;
}

export { useCreateRole };
