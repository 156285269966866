import { Td } from '@/components/ui/Table';

import { FIELD_NO_VALUE } from '../../../constants';
import { useI18nFormRole } from '../../../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../../../provider';

import type { PropsWithECP } from '../_types';
import type { FC } from 'react';

import { Checkbox } from '@/views/Common/Ui/Checkbox';

const FieldDevMenu: FC<PropsWithECP> = ({ targetECP: { id, permission } }) => {
  const { t } = useI18nFormRole();

  const { isAdminRole } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  return (
    <>
      <Td>{t('Developer menu')}</Td>
      <Td>
        <Checkbox
          name="showDeveloperMenu"
          onChange={(e) => {
            const nextValue = e.target.checked;
            dispatch({
              type: 'set-ec-permission-showdevelopermenu',
              payload: { id, nextValue },
            });
          }}
          checked={permission.showDeveloperMenu}
          disabled={disabled}
          label={t('Show developer settings')}
        />
      </Td>
      <Td>{FIELD_NO_VALUE}</Td>
      <Td>{FIELD_NO_VALUE}</Td>
      <Td>{FIELD_NO_VALUE}</Td>
    </>
  );
};

export default FieldDevMenu;
