import styles from './item.module.css';

type Props = {
  children: React.ReactNode;
};

const Item: React.FC<Props> = ({ children }) => {
  return (
    <li className={styles.item}>
      <i className="material-icons">check</i>
      {children}
    </li>
  );
};

export default Item;
