import type {
  UpdateParams,
  UpdateResult,
} from '@/repository/environmentRepository';
import { environmentRepository } from '@/repository/environmentRepository';

export const updateEnvironmentName = async ({
  serviceId,
  environmentName,
}: UpdateParams): Promise<UpdateResult> => {
  const { updateEnvironmentName } = environmentRepository(serviceId);
  return await updateEnvironmentName(environmentName);
};

export const deleteEnvironment = async ({
  serviceId,
}: {
  serviceId: string;
}) => {
  const { deleteEnvironment } = environmentRepository(serviceId);
  return await deleteEnvironment({ serviceId });
};
