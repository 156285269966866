import ListItem from '@tiptap/extension-list-item';

import type { ListItemOptions } from '@tiptap/extension-list-item';

type CustomBoldOptions = ListItemOptions & {
  inputRules: string[];
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-list-item/src/list-item.ts
export const CustomListItem = ListItem.extend<CustomBoldOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: ['listBullet', 'listOrdered'],
    };
  },

  parseHTML() {
    const hasBulletList = this.options.inputRules.includes('listBullet');
    const hasOrderedList = this.options.inputRules.includes('listOrdered');

    return hasBulletList || hasOrderedList
      ? [
          {
            tag: convertListItemTag(hasBulletList, hasOrderedList),
          },
        ]
      : [];
  },

  addKeyboardShortcuts() {
    return this.options.inputRules ? { ...this.parent?.() } : {};
  },
});

const convertListItemTag = (
  hasBulletList: boolean,
  hasOrderedList: boolean,
) => {
  // リストが無効で番号付きリストが有効な場合は番号付きリストのタグのみ適用する
  if (!hasBulletList && hasOrderedList) {
    return 'ol > li';
  }

  // リストが有効で番号付きリストが無効な場合はリストのタグのみ適用する
  if (hasBulletList && !hasOrderedList) {
    return 'ul > li';
  }

  return 'li';
};
