import { API, graphqlOperation } from 'aws-amplify';

import * as mutations from '../../graphql/mutations';

import type { GraphQLResult } from '@aws-amplify/api-graphql';

import {
  handleApiResult,
  type ApiResponse,
} from '@/views/Common/handleApiResult';

export const reviewsRepository = () => {
  const createReview = async ({
    contentId,
    title,
    description,
  }: {
    contentId: string;
    title: string;
    description?: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.createReviewRequest, {
          title,
          description,
          contentId,
          // レビュー申請時はreservationTimeは作られない
          reservationTime: undefined,
        }),
      )) as GraphQLResult<{
        createReviewRequest: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected error');
      }

      // TODO: フロントエンドエラー改善
      if (
        typeof result.data.createReviewRequest === 'object' &&
        typeof result.data.createReviewRequest.result === 'boolean'
      ) {
        return handleApiResult(result.data.createReviewRequest);
      } else if (typeof result.data.createReviewRequest === 'string') {
        return JSON.parse(result.data.createReviewRequest);
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected error');
    }
  };

  return { createReview };
};
