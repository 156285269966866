import { useCallback } from 'react';

import type { Props } from './LinkBlock';

import { useLinkBlock as useLinkBlockToolbar } from '@/views/InputField/RichEditorV2/toolbars/Embed/LinkBlock/useLinkBlock';

export const useLinkBlock = ({ editor, onToggleTooltip }: Props) => {
  const {
    isActiveLink,
    inputLinkAttributes,
    onChangeText,
    textError,
    onChangeHref,
    hrefError,
    onChangeTargetBlank,
    onUpdateLink,
    onClearLink,
  } = useLinkBlockToolbar({ editor });

  const onUpdateLinkMenu = useCallback(() => {
    onUpdateLink();
    onToggleTooltip();
  }, [onToggleTooltip, onUpdateLink]);

  const onClearLinkMenu = useCallback(() => {
    onClearLink();
    onToggleTooltip();
  }, [onClearLink, onToggleTooltip]);

  return {
    inputLinkAttributes,
    onChangeText,
    onChangeHref,
    onChangeTargetBlank,
    textError,
    hrefError,
    onUpdateLink: onUpdateLinkMenu,
    onClearLink: onClearLinkMenu,
    isActiveLink,
  };
};
