import { useI18nFormRole } from '../../../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../../../provider';

import type { FormRoleInputValue } from '../../../provider/stateContext';
import type { PropsWithECP } from '../../FieldExceptionContent/_types';
import type { FC } from 'react';

import { Checkbox } from '@/views/Common/Ui/Checkbox';
import { InputGroup } from '@/views/Role/InputGroup';

type Props = Partial<PropsWithECP>;

/**
 * コンテンツ権限項目 - CREATE権限の入力フィールド
 */
const FieldContentCreate: FC<Props> = ({ targetECP }) => {
  const { t } = useI18nFormRole('$contentPermission.$create');

  const { isAdminRole, input } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  const value = getValueIsomorphic(input, targetECP);

  return (
    <InputGroup>
      <Checkbox
        name={'permission.contentCreate.draft'}
        label={t('Draft')}
        checked={value.draft}
        disabled={disabled}
        onChange={(e) => {
          const nextValue = e.target.checked;
          if (targetECP) {
            dispatch({
              type: 'set-ec-permission-contentcreate',
              payload: { id: targetECP.id, nextValue, key: 'draft' },
            });
          } else {
            dispatch({
              type: 'set-dc-permission-contentcreate',
              payload: { key: 'draft', nextValue },
            });
          }
        }}
      />
      <Checkbox
        name={'permission.contentCreate.publish'}
        label={t('Publish')}
        checked={value.publish}
        disabled={disabled}
        onChange={(e) => {
          const nextValue = e.target.checked;
          if (targetECP) {
            dispatch({
              type: 'set-ec-permission-contentcreate',
              payload: { id: targetECP.id, nextValue, key: 'publish' },
            });
          } else {
            dispatch({
              type: 'set-dc-permission-contentcreate',
              payload: { key: 'publish', nextValue },
            });
          }
        }}
      />
    </InputGroup>
  );
};

function getValueIsomorphic(
  input: FormRoleInputValue,
  targetEcp: Props['targetECP'],
) {
  if (targetEcp) {
    return targetEcp.permission.contentCreate;
  }
  return input.defaultContentPermission.contentCreate;
}

export default FieldContentCreate;
