import { useTranslation } from 'react-i18next';
import { Switch, useParams } from 'react-router-dom';

import { useExceptionPermissionIsHaveLeastOne } from '../../hooks/Role/useMyRoles';
import PrivateRoute from '../PrivateRoute';
import ExternalLink from '../ui/ExternalLink';
import Menu from '../ui/Menu';
import MenuItem from '../ui/MenuItem';
import ContentIdSettings from './ContentIdSettings';
import CustomStatusSettings from './CustomStatusSettings';
import DeleteSettings from './DeleteSettings';
import ModelSettings from './ModelSettings';
import NameSettings from './NameSettings';
import PreviewSettings from './PreviewSettings';
import WebhookSettings from './WebhookSettings';

import type { ApiList, MigrateApi } from '@/entity/api';

import styles from './apiSettings.module.css';

import { apiListSelectors } from '@/ducks/apiList';
import { useAppSelector } from '@/store/hooks';
import { IpAddressRestrictionSettings } from '@/views/apis/settings/IpAddressRestrictionSettings';

const ApiSettings: React.FC = () => {
  const { t } = useTranslation('apiSettings');

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint } = useParams<{ endpoint: string }>();
  const { apiType, partitionKey: apiId } = useAppSelector((state) =>
    apiListSelectors.getApi(state.apiListState as ApiList, endpoint),
  ) as MigrateApi;

  const [hasDeleteApiPermission] = useExceptionPermissionIsHaveLeastOne(
    apiId,
    'apiDelete',
  );
  const [hasDeleteContentPermission] = useExceptionPermissionIsHaveLeastOne(
    apiId,
    'contentDelete',
  );
  return (
    <div className={styles.wrapper}>
      <div className={styles.menu}>
        <Menu title={t('API Settings')}>
          <MenuItem to={`/apis/${endpoint}/settings`} exact={true}>
            {t('Basic Information')}
          </MenuItem>
          <MenuItem to={`/apis/${endpoint}/settings/model`}>
            {t('API Schema')}
          </MenuItem>
          <MenuItem to={`/apis/${endpoint}/settings/custom-status`}>
            {t('Custom Status')}
          </MenuItem>
          {apiType === 'LIST' && (
            <MenuItem to={`/apis/${endpoint}/settings/content-id`}>
              {t('Content ID')}
            </MenuItem>
          )}
          <MenuItem to={`/apis/${endpoint}/settings/preview`}>
            {t('Page Preview')}
          </MenuItem>
          <MenuItem to={`/apis/${endpoint}/settings/webhook`}>
            {t('Webhooks')}
          </MenuItem>
          <MenuItem to={`/apis/${endpoint}/settings/ip-restrictions`}>
            {t('API IP Restriction')}
          </MenuItem>
        </Menu>
        <Menu title={t('Document')}>
          <ExternalLink
            href="https://document.microcms.io/content-api/get-list-contents"
            className={styles.link}
          >
            {t('Document')}
          </ExternalLink>
        </Menu>
        {(hasDeleteApiPermission || hasDeleteContentPermission) && (
          <Menu title={t('Proceed with Caution')}>
            <MenuItem to={`/apis/${endpoint}/settings/delete`}>
              {t('Delete')}
            </MenuItem>
          </Menu>
        )}
      </div>
      <div className={styles.flex}>
        <Switch>
          <PrivateRoute
            exact
            path="/apis/:endpoint/settings"
            component={NameSettings}
          />
          <PrivateRoute
            exact
            path="/apis/:endpoint/settings/model"
            component={ModelSettings}
          />
          <PrivateRoute
            exact
            path="/apis/:endpoint/settings/content-id"
            component={ContentIdSettings}
          />
          <PrivateRoute
            exact
            path="/apis/:endpoint/settings/custom-status"
            component={CustomStatusSettings}
          />
          <PrivateRoute
            exact
            path="/apis/:endpoint/settings/preview"
            component={PreviewSettings}
          />
          <PrivateRoute
            exact
            path="/apis/:endpoint/settings/webhook"
            component={WebhookSettings}
          />
          <PrivateRoute
            exact
            path="/apis/:endpoint/settings/ip-restrictions"
            component={IpAddressRestrictionSettings}
          />
          <PrivateRoute
            exact
            path="/apis/:endpoint/settings/delete"
            component={DeleteSettings}
          />
        </Switch>
      </div>
    </div>
  );
};

export default ApiSettings;
