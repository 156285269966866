import { useTranslation, Trans } from 'react-i18next';

import ExternalLink from '../../ui/ExternalLink';
import UpsellModal from '../../ui/UpsellModal';

import type { TemplateType } from './SelectApiTemplate';

import styles from './selectapitemplate.module.css';

type Props = {
  templateType?: TemplateType;
  needApiCount: number;
  createBlogTemplate: () => Promise<void>;
  createNewsTemplate: () => Promise<void>;
  createBannerTemplate: () => Promise<void>;
  close: () => void;
};

export const ChargeAddApiModalChildren: React.FC<Props> = ({
  needApiCount,
  templateType,
  createBlogTemplate,
  createNewsTemplate,
  createBannerTemplate,
  close,
}) => {
  const { t } = useTranslation('selectApiTemplate');

  return (
    <UpsellModal
      title={t(
        'Additional charges will be incurred due to the exceeding number of APIs.',
      )}
      description={
        <>
          <p>
            {t(
              'A monthly fee of 2,000 yen (tax excluded) will be charged per API.',
            )}{' '}
          </p>
          <p className={styles.modalText}>
            {t('This API template will create {{count}} new APIs.', {
              count: needApiCount,
            })}
          </p>
          <div>
            {Trans({
              t,
              i18nKey: 'For more information, click here',
              children: (
                <ExternalLink
                  href="https://microcms.io/pricing"
                  children={<p>click here</p>}
                  hasUnderline={true}
                />
              ),
            })}
          </div>
        </>
      }
      onSubmit={
        templateType === 'blogs'
          ? createBlogTemplate
          : templateType === 'news'
            ? createNewsTemplate
            : templateType === 'banner'
              ? createBannerTemplate
              : undefined
      }
      submitText={t('Continue with creating API')}
      close={close}
    />
  );
};
