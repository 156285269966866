import { useTranslation } from 'react-i18next';

import { useGetMyService } from '@/hooks/useService';

import Head from '../../Head';
import { Table, Thead, Tbody, Tr, Th, Td } from '../../ui/Table';

import styles from './customDomainSettings.module.css';

const CustomDomainSettings: React.FC = () => {
  const { service } = useGetMyService();
  const { t } = useTranslation('serviceCustomDomainSettings');

  return (
    <>
      <Head title={t('Custom Domain Settings')} />
      <h2 className={styles.title}>{t('Custom Domain Settings')}</h2>
      <p className={styles.description}>
        {t(
          'Contact our sales representatives for more information on custom domains.',
        )}
      </p>
      {service &&
        (service.customDomainImageHost || service.customDomainFileHost) && (
          <Table>
            <Thead>
              <Tr>
                <Th>{t('Type')}</Th>
                <Th isLong={true}>{t('Domain Name')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {service.customDomainImageHost && (
                <Tr enableHoverStyle={false}>
                  <Td>{t('Image')}</Td>
                  <Td>
                    <a
                      href={`https://${service.customDomainImageHost}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {service.customDomainImageHost}
                    </a>
                  </Td>
                </Tr>
              )}
              {service.customDomainFileHost && (
                <Tr enableHoverStyle={false}>
                  <Td>{t('File')}</Td>
                  <Td>
                    <a
                      href={`https://${service.customDomainFileHost}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {service.customDomainFileHost}
                    </a>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        )}
    </>
  );
};

export default CustomDomainSettings;
