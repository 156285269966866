import { Bold } from '@tiptap/extension-bold';

import type { BoldOptions } from '@tiptap/extension-bold';

type CustomBoldOptions = BoldOptions & {
  inputRules: boolean;
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-bold/src/bold.ts
export const CustomBold = Bold.extend<CustomBoldOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: true,
    };
  },

  parseHTML() {
    return this.options.inputRules ? this.parent?.() : [];
  },

  addKeyboardShortcuts() {
    return this.options.inputRules ? { ...this.parent?.() } : {};
  },

  addInputRules() {
    return this.options.inputRules ? this.parent?.() || [] : [];
  },

  addPasteRules() {
    return this.options.inputRules ? this.parent?.() || [] : [];
  },
});
