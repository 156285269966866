import { getMediumAttributes } from '@/usecase/mediumUsecase';

import { useApi } from '@/lib/useApi';
import { isReactQueryError } from '@/util/type-guard';

type Args = {
  serviceId: string;
  mediumId: string;
};

export const useImageBlockQuery = () => {
  // メディア情報の取得
  const useGetMediumAttributes = ({ serviceId, mediumId }: Args) => {
    return useApi(
      ['mediumAttributes', { mediumId }],
      async () => await getMediumAttributes({ serviceId, mediumId }),
      {
        onError: (error) => {
          if (isReactQueryError(error)) {
            return {
              message: `${error.status}:${error.message}`,
            };
          }
          if (error instanceof Error) {
            return {
              message: error.message,
            };
          }
          return {
            message: 'Something went wrong...',
          };
        },
      },
    );
  };

  return { useGetMediumAttributes };
};
