import { apiListSelectors } from '@/ducks/apiList';
import type { ApiList, MigrateApi } from '@/entity/api';
import { useAppSelector } from '@/store/hooks';
import { useExistsContentObject } from '@/views/Common/content/useContentReader';
import { Redirect, useParams } from 'react-router-dom';
import Forbidden from '../Forbidden';

const ContentObject = () => {
  // TODO: ReduxをReactQueryに置き換える
  const { endpoint } = useParams<{ endpoint: string }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );

  const { data: existsContentObject, isLoading } = useExistsContentObject(
    api?.partitionKey,
  );

  if (isLoading || existsContentObject === undefined) {
    return null;
  }

  if (!existsContentObject.authorized) {
    return <Forbidden />;
  }

  if (!existsContentObject.exists) {
    return <Redirect to={`/apis/${endpoint}/create`} />;
  }

  return (
    <Redirect
      to={`/apis/${endpoint}/${existsContentObject.userDefinedContentId}`}
    />
  );
};

export default ContentObject;
