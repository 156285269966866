import { useCallback } from 'react';

import type { Props } from './CodeBlock';

export const useCodeBlock = ({ editor }: Props) => {
  const toggleCodeBlockHandler = useCallback(() => {
    editor.chain().focus().toggleCodeBlock().run();
  }, [editor]);
  const isActiveCodeBlock = editor.isActive('codeBlock');

  return {
    toggleCodeBlockHandler,
    isActiveCodeBlock,
  };
};
