import { useTranslation } from 'react-i18next';

import { useSearchRoles } from './useSearchRoles';

import type { Role } from '@/entity/role';

import styles from './searchroles.module.css';

import { Combobox } from '@/views/Common/Ui/Combobox';

export type Props = {
  selectableRoles: Role[] | null;
  selectedRoleIds: string[];
  addRoleId: (roleId: string) => void;
  removeRoleId: (roleId: string) => void;
  closeSearchRoles: () => void;
};

export type RowProps = Role & {
  key: string;
};

export type ViewProps = {
  closeSearchRoles: () => void;
  selectableRoles: RowProps[] | null;
  currentValueKeys: string[];
  filterRule: (item: RowProps, inputValue: string) => boolean;
  onConfirm: (item: RowProps) => void;
};

export const SearchRolesView: React.FC<ViewProps> = ({
  closeSearchRoles,
  selectableRoles,
  currentValueKeys,
  filterRule,
  onConfirm,
}) => {
  const { t } = useTranslation('membersInviteMemberRolesSettingField');

  return (
    <div className={styles.main}>
      <Combobox
        list={selectableRoles}
        currentValueKeys={currentValueKeys}
        filterRule={filterRule}
        ItemComponent={RoleItem}
        onConfirm={onConfirm}
        onEscapeKeyDown={closeSearchRoles}
        inputAriaLabel={t('Search for a role')}
        listAriaLabel={t('Role list')}
        className={styles.main}
      />
    </div>
  );
};

const RoleItem: React.FC<{ item: RowProps }> = ({ item }) => {
  return <div className={styles.item}>{item.name}</div>;
};

export const SearchRoles: React.FC<Props> = (props) => {
  const hooks = useSearchRoles(props);

  return <SearchRolesView {...hooks} />;
};
