import type React from 'react';
import { useMemo } from 'react';

import { useMember } from '../../hooks/Member/useMembers';
import User from '../User';

type Props = {
  username: string;
  theme?: string;
  imageOnly?: boolean;
  nameOnly?: boolean;
};

const Member: React.FC<Props> = ({
  username,
  theme = 'white',
  imageOnly = false,
  nameOnly = false,
}) => {
  const { user } = useMember(username);
  const isApi = useMemo(() => {
    return username && username.startsWith('API#');
  }, [username]);

  if (!isApi && user === undefined) {
    return null;
  }

  return (
    <User
      user={isApi ? { username } : user}
      theme={theme}
      imageOnly={imageOnly}
      nameOnly={nameOnly}
    />
  );
};

export default Member;
