import { useContext, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { myRolesContext } from '../../hooks/Role/useMyRoles';
import { Table, Thead, Tbody, Tr, Th, Td } from '../ui/Table';

import type { ApiKeyPolicy } from '../../types/apiKey';

import styles from './policysetting.module.css';

type Props = {
  defaultPolicy: ApiKeyPolicy;
  setPolicy: (policy: ApiKeyPolicy) => void;
};

const PolicySetting: React.FC<Props> = ({ defaultPolicy, setPolicy }) => {
  const { t } = useTranslation('policySetting');

  const context = useContext(myRolesContext);
  const isAdmin = useMemo(() => {
    return context.roles
      ? context.roles.some((role) => role.isAdmin === true)
      : undefined;
  }, [context.roles]);

  if (defaultPolicy === undefined) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Table>
        <Thead>
          <Tr>
            <Th>GET</Th>
            <Th>
              <div className={styles.row}>
                {Trans({
                  t,
                  i18nKey: 'Get all draft contents',
                })}
                <a
                  href={`https://document.microcms.io/content-api/x-microcms-api-key`}
                  target="docs"
                  className={styles.helpLink}
                >
                  <i className={`material-icons ${styles.helpIcon}`}>
                    help_outline
                  </i>
                </a>
              </div>
            </Th>
            <Th>
              <div className={styles.row}>
                {Trans({
                  t,
                  i18nKey: 'Get all closed contents',
                })}
                <a
                  href={`https://document.microcms.io/content-api/x-microcms-api-key`}
                  target="docs"
                  className={styles.helpLink}
                >
                  <i className={`material-icons ${styles.helpIcon}`}>
                    help_outline
                  </i>
                </a>
              </div>
            </Th>
            <Th>POST</Th>
            <Th>PUT</Th>
            <Th>PATCH</Th>
            <Th>DELETE</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr enableHoverStyle={false}>
            <Td>
              <input
                name="get"
                type="checkbox"
                checked={defaultPolicy?.get}
                disabled={!isAdmin}
                onChange={(e) =>
                  setPolicy({
                    ...defaultPolicy,
                    get: e.target.checked,
                    // 下書き全取得はGETがtrueでないと選択できない使用のため
                    getAllDraft: defaultPolicy?.get === false && false,
                  })
                }
              />
            </Td>
            <Td>
              <input
                name="getAllDraft"
                type="checkbox"
                checked={defaultPolicy?.getAllDraft}
                onChange={(e) =>
                  setPolicy({
                    ...defaultPolicy,
                    getAllDraft: e.target.checked,
                  })
                }
                disabled={!isAdmin || defaultPolicy?.get === false}
              />
            </Td>
            <Td>
              <input
                name="getAllClosed"
                type="checkbox"
                checked={defaultPolicy?.getAllClosed}
                onChange={(e) =>
                  setPolicy({
                    ...defaultPolicy,
                    getAllClosed: e.target.checked,
                  })
                }
                disabled={!isAdmin || defaultPolicy?.get === false}
              />
            </Td>
            <Td>
              <input
                name="post"
                type="checkbox"
                checked={defaultPolicy?.post}
                disabled={!isAdmin}
                onChange={(e) =>
                  setPolicy({ ...defaultPolicy, post: e.target.checked })
                }
              />
            </Td>
            <Td>
              <input
                name="put"
                type="checkbox"
                checked={defaultPolicy?.put}
                disabled={!isAdmin}
                onChange={(e) =>
                  setPolicy({ ...defaultPolicy, put: e.target.checked })
                }
              />
            </Td>
            <Td>
              <input
                name="patch"
                type="checkbox"
                checked={defaultPolicy?.patch}
                disabled={!isAdmin}
                onChange={(e) =>
                  setPolicy({ ...defaultPolicy, patch: e.target.checked })
                }
              />
            </Td>
            <Td>
              <input
                name="delete"
                type="checkbox"
                checked={defaultPolicy?.delete}
                disabled={!isAdmin}
                onChange={(e) =>
                  setPolicy({ ...defaultPolicy, delete: e.target.checked })
                }
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </div>
  );
};

export default PolicySetting;
