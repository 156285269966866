import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/ui/Button';
import Modal from 'src/components/ui/Modal';
import Textfield from 'src/components/ui/Textfield';
import { validateMediumTagName } from 'src/components/Validations';
import { useInput } from 'src/hooks';
import { useUpdateMediumTagName } from 'src/hooks/MediumTags/useMediumTags';

import type { MediumTag } from 'src/types/MediumTag';

type Props = {
  serviceId: string;
  mediumTag?: MediumTag;
  closeModal: () => void;
};

const EditMediumTagModal: React.FC<Props> = ({
  serviceId,
  mediumTag,
  closeModal,
}) => {
  const { t } = useTranslation('editMediumTag');
  const { updateMediumTagName, updateMediumTagNameLoading } =
    useUpdateMediumTagName();

  const [name, onChangeName, nameError] = useInput(
    mediumTag?.name,
    // @ts-expect-error
    validateMediumTagName,
  );

  const onUpdateMediumTagName = useCallback(
    (name: string) => () => {
      updateMediumTagName({
        serviceId,
        mediumTagId: mediumTag?.mediumTagId,
        name: name.trim(),
        closeModal,
      });
    },
    [updateMediumTagName, mediumTag?.mediumTagId, serviceId, closeModal],
  );

  return (
    <Modal
      type="small"
      title={t('Edit Tag')}
      description={t(
        'You can edit the tag name. Any changes you make will be applied to the tag you are setting.',
      )}
      footer={
        <Button
          size="small"
          value={t('Save changes')}
          onClick={onUpdateMediumTagName(name)}
          disabled={updateMediumTagNameLoading || nameError}
        />
      }
    >
      <Textfield
        type="text"
        size="full"
        defaultValue={name}
        onChange={onChangeName}
        errorText={nameError}
      />
    </Modal>
  );
};

export default EditMediumTagModal;
