import Quill from 'quill';
const Parchment = Quill.import('parchment');

// 文字色にHTML属性(data-richeditor-color)を追加するため独自に定義
// 参考 https://github.com/slab/quill/blob/1.3.7/formats/color.js
class ColorAttributor extends Parchment.Attributor.Style {
  value(domNode) {
    let value = super.value(domNode);
    if (!value.startsWith('rgb(')) return value;
    value = value.replace(/^[^\d]+/, '').replace(/[^\d]+$/, '');
    return (
      '#' +
      value
        .split(',')
        .map((component) => {
          return ('00' + Number.parseInt(component).toString(16)).slice(-2);
        })
        .join('')
    );
  }
  add(node, value) {
    const result = super.add(node, value);
    if (result) {
      // HTML属性にdata-richeditor-colorを追加
      node.setAttribute('data-richeditor-color', true);
    }
    return result;
  }
}

export const ColorStyle = new ColorAttributor('color', 'color', {
  scope: Parchment.Scope.INLINE,
});
