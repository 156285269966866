import { apiListSelectors } from '@/ducks/apiList';
import type { ApiList, MigrateApi } from '@/entity/api';
import { useAppSelector } from '@/store/hooks';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useParams } from 'react-router-dom';
import { useExceptionPermissionIsHaveLeastOne } from '../../hooks/Role/useMyRoles';
import IconWithTextNavLink from '../ui/IconWithTextNavLink';
import Menu from '../ui/Menu';
import MenuItem from '../ui/MenuItem';
import DeleteSettings from './DeleteSettings';
import LayoutSettings from './LayoutSettings';
import ModelSettings from './ModelSettings';
import NameSettings from './NameSettings';
import styles from './customFieldsSettings.module.css';

const CustomFieldsSettings: React.FC = () => {
  const { t } = useTranslation('customFieldsSettings');

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint, fieldId } = useParams<{
    endpoint: string;
    fieldId: string;
  }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );

  const [hasPermission] = useExceptionPermissionIsHaveLeastOne(
    api.partitionKey,
    'apiUpdate',
  );
  return (
    <div className={styles.area}>
      <div className={styles.actions}>
        <IconWithTextNavLink
          to={`/apis/${endpoint}/custom-fields`}
          icon="arrow_back_ios"
          text={t('To List')}
        />
      </div>
      <div className={styles.wrapper}>
        <div className={styles.menu}>
          <Menu title={t('Custom Field Settings')}>
            <MenuItem
              to={`/apis/${endpoint}/custom-fields/${fieldId}`}
              exact={true}
            >
              {t('Basic Information')}
            </MenuItem>

            <MenuItem to={`/apis/${endpoint}/custom-fields/${fieldId}/model`}>
              {t('Schema')}
            </MenuItem>
            <MenuItem to={`/apis/${endpoint}/custom-fields/${fieldId}/layout`}>
              {t('Layout')}
            </MenuItem>
          </Menu>
          {hasPermission && (
            <Menu title={t('Proceed with Caution')}>
              <MenuItem
                to={`/apis/${endpoint}/custom-fields/${fieldId}/delete`}
              >
                {t('Delete')}
              </MenuItem>
            </Menu>
          )}
        </div>
        <div className={styles.flex}>
          <Switch>
            <Route
              exact
              path="/apis/:endpoint/custom-fields/:fieldId"
              component={NameSettings}
            />
            <Route
              exact
              path="/apis/:endpoint/custom-fields/:fieldId/model"
              component={ModelSettings}
            />
            <Route
              exact
              path="/apis/:endpoint/custom-fields/:fieldId/layout"
              component={LayoutSettings}
            />
            <Route
              exact
              path="/apis/:endpoint/custom-fields/:fieldId/delete"
              component={DeleteSettings}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default CustomFieldsSettings;
