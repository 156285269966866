import { useTranslation } from 'react-i18next';

import Selectfield from '@/components/ui/Selectfield';

import type { CustomField } from '@/types/contents';

type Props = {
  customFieldCreatedAt: string | null;
  customFields: CustomField[];
  value: string | null;
  onChangeCustomFieldDisplayItem: (customFieldDisplayItem: string) => void;
};

const CustomFieldDisplayItem: React.FC<Props> = ({
  customFieldCreatedAt,
  customFields,
  value = '',
  onChangeCustomFieldDisplayItem,
}) => {
  const { t } = useTranslation('customFieldDisplayItem');

  const customField = customFields.find(
    (customField) => customField.createdAt === customFieldCreatedAt,
  );
  const selectableFields =
    customField?.fields.filter((field) => field.kind === 'text') || [];

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeCustomFieldDisplayItem(e.target.value);
  };

  return (
    <Selectfield
      size="full"
      onChange={handleChange}
      value={value === null ? '' : value}
    >
      <option value="">{t('Default (◯ fields)')}</option>
      {selectableFields.map((field, i) => {
        return (
          <option key={i} value={field.idValue}>
            {field.name} ({field.fieldId})
          </option>
        );
      })}
    </Selectfield>
  );
};

export default CustomFieldDisplayItem;
