import { useTranslation } from 'react-i18next';

import ProfileImage from '@/components/ProfileImage';

import { useSearchMember } from './useSearchMember';

import type { ServiceUser } from '@/entity/member';
import type { Role } from '@/entity/role';

import styles from './searchmember.module.css';

import { filterNotInvitingMembers } from '@/util/member';
import { nonNullable } from '@/util/type-guard';
import { Combobox } from '@/views/Common/Ui/Combobox';

export type RowProps = ServiceUser & {
  key: string;
  roleNames: string[];
};

export const buildRowValues = (
  members?: ServiceUser[],
  roles?: Role[],
): RowProps[] | null => {
  if (members === undefined || roles === undefined) {
    return null;
  }

  // 先にロールの配列をマップ（roleIdをキーとするオブジェクト）に変換しておく
  // これにより、ロール名の検索が定数時間で行えるようになる
  const roleMap: Record<string, Role> = {};
  roles.forEach((role) => {
    roleMap[role.roleId] = role;
  });

  return filterNotInvitingMembers(members)
    .sort((a, b) => (a.name ?? a.email).localeCompare(b.name ?? b.email))
    .map((member) => {
      const roleNames = member.roleIds
        .map((roleId) => roleMap[roleId]?.name)
        .filter(nonNullable);

      return {
        ...member,
        key: member.username,
        roleNames,
      };
    });
};

export type Props = {
  members: RowProps[] | null;
  selectedMemberId: string;
  onClickRow: (username: string) => void;
  closeModal: () => void;
};

export type ViewProps = {
  members: RowProps[] | null;
  closeModal: () => void;
  currentValueKeys: string[];
  filterRule: (item: RowProps, inputValue: string) => boolean;
  onConfirm: (item: RowProps) => void;
};

export const SearchMemberView: React.FC<ViewProps> = ({
  members,
  closeModal,
  currentValueKeys,
  filterRule,
  onConfirm,
}) => {
  const { t } = useTranslation('memberSelect');

  return (
    <Combobox
      list={members}
      currentValueKeys={currentValueKeys}
      filterRule={filterRule}
      ItemComponent={Member}
      onConfirm={onConfirm}
      onEscapeKeyDown={closeModal}
      inputAriaLabel={t('Search for a member')}
      listAriaLabel={t('Select a member')}
      className={styles.main}
    />
  );
};

const Member: React.FC<{ item: RowProps }> = ({ item }) => {
  const { t } = useTranslation('memberSelect');

  return (
    <div className={styles.member}>
      <div>
        <ProfileImage picture={item.picture ?? undefined} />
      </div>
      <div className={styles.memberInfo}>
        <div className={styles.memberNameAndRolesWrapper}>
          <span
            className={styles.memberName}
            data-testid="search-member-info-name"
          >
            {item.name ?? item.email.replace(/@.*$/, '')}
          </span>
          <span className={styles.memberRoles}>
            {item.roleNames.join(t('comma'))}
          </span>
        </div>
        <span className={styles.memberEmail}>{item.email}</span>
      </div>
    </div>
  );
};

export const SearchMember: React.FC<Props> = (props) => {
  const hooks = useSearchMember(props);

  return <SearchMemberView {...hooks} />;
};
