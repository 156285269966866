import { colorToRgbString } from '@/util/color';
import { useCallback, useState } from 'react';
import type { Color, RGBColor } from 'react-color';

import type { Props } from './Color';

const RESET_COLOR = { r: 0, g: 0, b: 0, a: 1 };

export const useColor = ({ editor }: Props) => {
  const [color, setColor] = useState<Color | undefined>(undefined);
  const [isSetColor, setIsSetColor] = useState<boolean>(false);

  /*
   文字色をテキストに適用する
   フロントエンドで参照する値とDBにセットする値は、それぞれ形式が異なる
   フロントエンドで参照する値はRGBのオブジェクトでセットする ex: { r: 255, g: 255, b: 255, a: 1 }
   DBに保存する値はRGBの文字列でセットする ex: 'rgba(255, 255, 255, 1)'
 */
  const setColorHandler = useCallback(
    (color: RGBColor) => {
      // DBには保存する値はRGBの文字列に変換する
      editor.chain().focus().setColor(colorToRgbString(color)).run();
      setColor(color);
    },
    [editor],
  );

  const unsetColorHandler = useCallback(() => {
    editor
      .chain()
      .focus()
      .setMark('textStyle', { color: null })
      .unsetMark('textStyle', { extendEmptyMarkRange: true })
      .run();
    setColor(RESET_COLOR);
  }, [editor]);

  const isActiveColor = Boolean(
    editor.isActive('textStyle') && editor.getAttributes('textStyle').color,
  );

  const setCurrentColorHandler = useCallback(() => {
    setColor(colorToRgbString(editor.getAttributes('textStyle').color));
    setIsSetColor(isActiveColor);
  }, [editor, isActiveColor]);

  return {
    setColorHandler,
    isActiveColor,
    setCurrentColorHandler,
    unsetColorHandler,
    color,
    isSetColor,
  };
};
