import Cookies from 'js-cookie';

import { getPureHost } from '@/util';

export const cookieRepository = () => {
  const setCookie = ({
    key,
    value,
    expires = 365,
  }: {
    key: string;
    value: string;
    expires: number;
  }): void => {
    Cookies.set(key, value, {
      expires,
      path: '/',
      domain: getPureHost(),
      secure: window.location.protocol === 'https',
    });
  };
  return { setCookie };
};
