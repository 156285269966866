import { useState, useEffect } from 'react';

const useLoading = (
  stop: boolean,
): [loading: boolean, startLoading: () => void] => {
  const [loading, setLoading] = useState<boolean>(false);

  const startLoading = () => {
    setLoading(true);
  };

  useEffect(() => {
    stop === true && setLoading(false);
  }, [stop, loading]);

  return [loading, startLoading];
};

export default useLoading;
