import { API, graphqlOperation } from 'aws-amplify';
import { useState, useEffect } from 'react';

import * as queries from '../../graphql/queries';

import type { Contents } from '../../types/contents';

export const useGetContentsByIdandApiId = (partitionKeys: string[]) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Contents[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const result: any = await API.graphql(
        graphqlOperation(queries.getContentsByIdandApiId, {
          contentIds: partitionKeys,
        }),
      );
      const results: Contents[] = JSON.parse(
        result.data.getContentsByIdandApiId,
      ).contents;

      setData(
        results
          .filter((result: any) => result)
          .map((result) => ({
            ...result,
            contentId: result.gsiSortKey2.replace(/.*#CONTENT_ID#/, ''),
          })),
      );
      setLoading(false);
      return results;
    };
    if (partitionKeys?.length > 0) {
      setLoading(true);
      fetchData();
    } else if (data.length > 0) {
      // すでに複数参照設定済みからゼロに変えた場合
      // 無限ループ防止
      setData([]);
    }
  }, [data.length, partitionKeys]);
  return { data, loading };
};
