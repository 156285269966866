import { markPasteRule } from '@tiptap/core';
import { Link } from '@tiptap/extension-link';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { find } from 'linkifyjs';

import type { Editor } from '@tiptap/core';
import type { LinkOptions } from '@tiptap/extension-link';
import type { MarkType } from '@tiptap/pm/model';

type CustomLinkOptions = LinkOptions & {
  inputRules: boolean;
};

// https://github.com/ueberdosis/tiptap/blob/main/packages/extension-link/src/link.ts
export const CustomLink = Link.extend<CustomLinkOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: true,
    };
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      target: {
        default: null,
      },
    };
  },

  parseHTML() {
    return this.options.inputRules ? this.parent?.() : [];
  },

  renderHTML({ mark }) {
    return [
      'a',
      {
        href: mark.attrs.href,
        target: mark.attrs.target,
        rel: mark.attrs.target ? 'noopener noreferrer nofollow' : null,
      },
      0,
    ];
  },

  addPasteRules() {
    return this.options.inputRules
      ? [
          markPasteRule({
            find: (text) =>
              find(text)
                .filter((link) => {
                  if (this.options.validate) {
                    return this.options.validate(link.value);
                  }

                  return true;
                })
                .filter((link) => link.isLink)
                .map((link) => ({
                  text: link.value,
                  index: link.start,
                  data: link,
                })),
            type: this.type,
            getAttributes: (match) => ({
              href: match.data?.href,
            }),
          }),
        ]
      : [];
  },

  addProseMirrorPlugins() {
    const plugins: Plugin[] = [];

    plugins.push(
      clickSelectHandler({
        editor: this.editor,
        type: this.type,
      }),
    );

    return plugins;
  },
});

type ClickHandlerOptions = {
  editor: Editor;
  type: MarkType;
};

function clickSelectHandler(options: ClickHandlerOptions): Plugin {
  return new Plugin({
    key: new PluginKey('handleClickSelectLink'),
    props: {
      handleClick: (_, pos) => {
        if (!options.editor.isActive('link')) {
          return false;
        }

        const cursorBeforeTextLength =
          options.editor.state.selection.$head.nodeBefore?.text?.length || 0;
        const cursorAfterTextLength =
          options.editor.state.selection.$head.nodeAfter?.text?.length || 0;

        const linkTextFromPosition =
          options.editor.state.selection.$head.pos - cursorBeforeTextLength;
        const linkTextToPosition =
          options.editor.state.selection.$head.pos + cursorAfterTextLength;

        const currentPosition = pos;
        const isLinkText =
          linkTextFromPosition <= currentPosition &&
          currentPosition >= linkTextToPosition;
        if (isLinkText) {
          return false;
        }

        options.editor.commands.extendMarkRange('link');

        return true;
      },
      handleKeyDown(_, event) {
        if (!options.editor.isActive('link')) {
          return false;
        }

        if (event.key !== 'Enter') {
          return false;
        }

        options.editor.commands.extendMarkRange('link');

        return true;
      },
    },
  });
}
