import {
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToFirstScrollableAncestor,
  restrictToHorizontalAxis,
  restrictToParentElement,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  rectSortingStrategy,
  rectSwappingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';

import type { Props, ViewProps } from './Sortable';
import type {
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
export const useSortable = ({
  items,
  setItems,
  children,
  axis,
  movableRange,
  strategy,
  sensors,
}: Props): ViewProps => {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const getIndex = (id: UniqueIdentifier) => items.indexOf(id);
  const activeIndex = activeId ? getIndex(activeId) : -1;

  const defaultSensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragStart = ({ active }: DragStartEvent) => {
    if (!active) {
      return;
    }

    setActiveId(active.id);
  };
  const handleDragEnd = ({ over }: DragEndEvent) => {
    setActiveId(null);

    if (over) {
      const overIndex = getIndex(over.id);

      if (activeIndex !== overIndex) {
        setItems((items) => arrayMove(items, activeIndex, overIndex));
      }
    }
  };
  const handleDragCancel = () => {
    setActiveId(null);
  };

  useEffect(() => {
    // activeIdがnullでなければ、ドラッグ中であることを示す。
    // このとき、カーソルをgrabbingに変更する。
    if (activeId !== null) {
      document.body.style.cursor = 'grabbing';
    } else {
      document.body.style.cursor = 'auto';
    }

    return () => {
      document.body.style.cursor = 'auto';
    };
  }, [activeId]);

  return {
    items,
    handleDragStart,
    handleDragEnd,
    handleDragCancel,
    sensors: sensors ?? defaultSensors,
    modifiers: [
      ...(axis === 'x' ? [restrictToHorizontalAxis] : []),
      ...(axis === 'y' ? [restrictToVerticalAxis] : []),
      ...(movableRange === 'window' ? [restrictToWindowEdges] : []),
      ...(movableRange === 'parentElement' ? [restrictToParentElement] : []),
      ...(movableRange === 'scrollArea'
        ? [restrictToFirstScrollableAncestor]
        : []),
    ],
    strategy:
      strategy === 'reorder'
        ? rectSortingStrategy
        : strategy === 'swap'
          ? rectSwappingStrategy
          : undefined,
    children,
  };
};
