import type React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import Params from '../Params';

import type { ApiKey } from '../../../types/apiKey';
import type { Param } from '../Params';

import styles from './method.module.css';

type Props = {
  method: string;
  href: string;
  bodyHref?: string;
  apiKey?: ApiKey;
  resetBody?: () => void;
  defaultBody?: any;
  bodyEl?: any;
  setCurrentBody?: any;
  params?: Param[];
  setParams?: (params: Param[]) => void;
  currentPlan?: any;
};

const Method: React.FC<Props> = ({
  method,
  href,
  bodyHref,
  apiKey,
  resetBody,
  defaultBody,
  bodyEl,
  setCurrentBody,
  params,
  setParams,
  currentPlan,
}) => {
  const { t } = useTranslation('method');
  return (
    <div className={styles.details}>
      <dl className={styles.headers}>
        <dt className={styles.detailTitle}>
          Headers
          <a href={href} target="docs" className={styles.helpLink}>
            <i className={`material-icons ${styles.helpIcon}`}>help_outline</i>
          </a>
        </dt>
        <dd>
          <ul>
            <li className={styles.header}>
              <p className={styles.headerKey}>X-MICROCMS-API-KEY :</p>
              {apiKey ? (
                apiKey.value
              ) : (
                <Link to="/api-keys">{t('Create')}</Link>
              )}
            </li>
            <li className={styles.header}>
              <p className={styles.headerKey}>Content-Type :</p>
              application/json
            </li>
          </ul>
        </dd>
      </dl>
      {method !== 'DELETE' && (
        <dl className={styles.body}>
          {(method === 'POST' || method === 'PUT') && (
            <>
              <dt className={styles.detailTitle}>
                Params
                <a
                  href={
                    method === 'POST'
                      ? 'https://document.microcms.io/content-api/post-content#h9ce528688c'
                      : 'https://document.microcms.io/content-api/put-content#h9ce528688c'
                  }
                  target="docs"
                  className={styles.helpLink}
                >
                  <i className={`material-icons ${styles.helpIcon}`}>
                    help_outline
                  </i>
                </a>
              </dt>
              <dd className={styles.checkbox}>
                {params && setParams && (
                  <Params
                    params={params}
                    change={setParams}
                    isList={false}
                    isWriteApi={true}
                    disabled={!currentPlan.limit.enableAllParams}
                  />
                )}
                {!currentPlan.limit.enableAllParams && (
                  <span className={styles.error}>
                    <i className="material-icons-outlined">error</i>
                    <p className={styles.alert}>
                      {t('Parameters are not available in the current plan.')}
                      <br />
                      {Trans({
                        t,
                        i18nKey: 'Check the billing page.',
                        children: (
                          <Link to="/settings/billing">the billing page</Link>
                        ),
                      })}
                    </p>
                  </span>
                )}
              </dd>
            </>
          )}
          <dt className={styles.detailTitle}>
            Body
            <a href={bodyHref} target="docs" className={styles.helpLink}>
              <i className={`material-icons ${styles.helpIcon}`}>
                help_outline
              </i>
            </a>
            <button className={styles.smallButton} onClick={resetBody}>
              reset
            </button>
          </dt>
          <dd>
            <textarea
              className={styles.bodyArea}
              defaultValue={JSON.stringify(defaultBody, null, 2)}
              ref={bodyEl}
              onChange={(e) => setCurrentBody(e.target.value)}
            />
          </dd>
        </dl>
      )}
    </div>
  );
};

export default Method;
