import { useQuery, useQueryClient } from '@tanstack/react-query';
import { API, Auth, Storage, graphqlOperation } from 'aws-amplify';
import mime from 'mime-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import {
  importContentList as importContentListUsecase,
  moveContentToAnyPlace as moveContentToAnyPlaceUsecase,
  moveContentToDown as moveContentToDownUsecase,
  moveContentToFirst as moveContentToFirstUsecase,
  moveContentToLast as moveContentToLastUsecase,
  moveContentToUp as moveContentToUpUsecase,
} from '@/usecase/contentUsecase';

import * as queries from '../../graphql/queries';

import { contentQueryKeys } from '@/views/Common/content/queryKeys';

const simplifyContent = (result) => ({
  ...result,
  value: result.draftValue
    ? result.draftValue
    : result.closedValue
      ? result.closedValue
      : result.contentValue,
  reservationTime: result.gsiSinglePartitionKey1 && result.reservationTime,
  reservationStopTime:
    result.gsiSinglePartitionKey1 && result.reservationStopTime,
  contentId: result.gsiSortKey2.replace(/.*#CONTENT_ID#/, ''),
});

/**
 *
 * @param {*} contentId
 * @returns {[undefined | import("@/types/contents").Contents]}
 */
export const useContent = (contentId) => {
  const [data, setData] = useState();

  useEffect(() => {
    if (contentId) {
      API.graphql(
        graphqlOperation(queries.getContentsById, {
          contentIds: [contentId],
        }),
      ).then((res) => {
        const json = JSON.parse(res.data.getContentsById);
        if (json.contents && json.contents.length !== 0) {
          const result = json.contents[0];
          setData(result !== null ? simplifyContent(result) : null);
        }
      });
    }
  }, [contentId]);

  return [data];
};

export const useContents = (partitionKeys) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const results = await API.graphql(
        graphqlOperation(queries.getContentsById, {
          contentIds: partitionKeys,
        }),
      ).then((res) => {
        return JSON.parse(res.data.getContentsById).contents;
      });
      setData(results.map((v) => (v !== null ? simplifyContent(v) : null)));
    };

    if (partitionKeys?.length > 0) {
      fetchData();
    }
  }, [partitionKeys]);

  return [data];
};

export const useContentList = () => {
  const { t } = useTranslation('hooksContent');
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const [importResult, setImportResult] = useState();

  const moveToFirst = useCallback(
    async (content) => {
      try {
        const result = await moveContentToFirstUsecase({
          contentId: content.partitionKey,
        });
        if (result.result === true) {
          cache.invalidateQueries(['contentList'], { type: 'all' });
          addToast(t('Content order has been changed.'), {
            appearance: 'success',
          });
        }
      } catch (error) {
        addToast(error.message || t('Content order could not be changed.'), {
          appearance: 'error',
        });
      }
    },
    [cache, addToast, t],
  );

  const moveToUp = useCallback(
    async (content) => {
      try {
        const res = await moveContentToUpUsecase({
          contentId: content.partitionKey,
        });

        if (res.result === true) {
          cache.invalidateQueries(['contentList'], { type: 'all' });
          addToast(t('Content order has been changed.'), {
            appearance: 'success',
          });
        }
      } catch (error) {
        addToast(error.message || t('Content order could not be changed.'), {
          appearance: 'error',
        });
      }
    },
    [cache, addToast, t],
  );

  const moveToDown = useCallback(
    async (content) => {
      try {
        const res = await moveContentToDownUsecase({
          contentId: content.partitionKey,
        });
        if (res.result === true) {
          cache.invalidateQueries(['contentList'], { type: 'all' });
          addToast(t('Content order has been changed.'), {
            appearance: 'success',
          });
        }
      } catch (error) {
        addToast(error.message || t('Content order could not be changed.'), {
          appearance: 'error',
        });
      }
    },
    [cache, addToast, t],
  );

  const moveToLast = useCallback(
    async (content) => {
      try {
        const res = await moveContentToLastUsecase({
          contentId: content.partitionKey,
        });
        if (res.result === true) {
          cache.invalidateQueries(['contentList'], { type: 'all' });
          addToast(t('Content order has been changed.'), {
            appearance: 'success',
          });
        }
      } catch (error) {
        addToast(error.message || t('Content order could not be changed.'), {
          appearance: 'error',
        });
      }
    },
    [cache, addToast, t],
  );

  const moveToAnyPlace = useCallback(
    async (content, targetContent, isLast) => {
      try {
        const res = await moveContentToAnyPlaceUsecase({
          contentId: content.partitionKey,
          targetContentId: targetContent.partitionKey,
          isLast,
        });
        if (res.result === true) {
          cache.invalidateQueries(['contentList'], { type: 'all' });
          addToast(t('Content order has been changed.'), {
            appearance: 'success',
          });
        }
      } catch (error) {
        addToast(error.message || t('Content order could not be changed.'), {
          appearance: 'error',
        });
      }
    },
    [cache, addToast, t],
  );

  const importContentList = useCallback(async (api, file) => {
    const fileName = `service/${api.id}/api/${api.contentId}/import/${file.name}`;
    const contentType = mime.lookup(fileName);
    const level = 'private';
    const bucket = Storage._config.AWSS3.bucket;
    const identityId = (await Auth.currentUserInfo()).id;

    Storage.put(fileName, file, {
      level,
      contentType,
    })
      .then(() => {
        return importContentListUsecase({ bucket, identityId, fileName });
      })
      .then(() => {
        setImportResult(true);
      })
      .catch(() => setImportResult(false));
  }, []);

  // 結果はしばらくしたら消す;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setImportResult(undefined);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [importResult]);

  return [
    moveToFirst,
    moveToUp,
    moveToDown,
    moveToLast,
    moveToAnyPlace,
    importContentList,
    importResult,
  ];
};

/**
 * @param {{
 * api: import('@/entity/api').MigrateApi,
 * } & import('@/API').SearchOptions}
 * @returns {{
 * contents?: any[],
 * totalCount?: number,
 * isLoading: boolean,
 * isError: boolean,
 * }}
 */
export const useSearch = ({ api, q, offset, size, orders, filters }) => {
  const contentListQuery = useQuery({
    queryKey: [
      'contentList',
      api.partitionKey,
      { q, size, offset, orders, filters },
    ],
    queryFn: () => {
      return API.graphql(
        graphqlOperation(queries.searchContents, {
          apiId: api.partitionKey,
          options: { q, size, offset, orders, filters },
        }),
      )
        .then((result) => JSON.parse(result.data.searchContents))
        .then(({ contents, totalCount }) => {
          return {
            totalCount,
            contents: contents
              .filter((v) => v)
              .map((result) => ({
                ...result,
                value: result.draftValue
                  ? result.draftValue
                  : result.closedValue
                    ? result.closedValue
                    : result.contentValue,
                reservationTime:
                  result.gsiSinglePartitionKey1 && result.reservationTime,
                reservationStopTime:
                  result.gsiSinglePartitionKey1 && result.reservationStopTime,
                //旧DBとの整合性を取る
                contentId: result.gsiSortKey2.replace(/.*#CONTENT_ID#/, ''),
                domain: api.domain,
                id: `${api.domain}#${api.apiEndpoint}`,
              })),
          };
        });
    },
    staleTime: Number.POSITIVE_INFINITY,
  });
  const { data: contentList = {}, isLoading, isError } = contentListQuery;
  return { ...contentList, isLoading, isError };
};

export const useContentObject = (api) => {
  const contentObjectQuery = useQuery({
    queryKey: contentQueryKeys.object(api?.partitionKey),
    queryFn: () => {
      if (!api) {
        return null;
      }
      return API.graphql(
        graphqlOperation(queries.searchContents, {
          apiId: api.partitionKey,
          options: { q: '' },
        }),
      )
        .then((result) => JSON.parse(result.data.searchContents))
        .then(({ contents }) => {
          if (contents.length === 0) {
            return null;
          }
          const result = contents[0];
          return {
            ...simplifyContent(result),
            domain: api.domain,
            id: `${api.domain}#${api.apiEndpoint}`,
          };
        });
    },
    staleTime: Number.POSITIVE_INFINITY,
    enabled: !!api,
  });
  const { data: contentObject, isLoading } = contentObjectQuery;
  return { contentObject, isLoading };
};
