import { connect } from 'react-redux';

import { accountSelectors } from '../../ducks/account';
import PrivateRoute from './PrivateRoute';

const mapStateToProps = (state) => {
  return {
    ...accountSelectors.getAccountState(state.accountState),
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
