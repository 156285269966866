import IconButton from '../IconButton';

import type { ReactNode } from 'react';

import styles from './notification.module.css';

type Props = {
  text: ReactNode;
  status?: 'info' | 'warning' | 'error';
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  close?: React.MouseEventHandler<HTMLButtonElement>;
};

const icon = {
  info: 'info',
  warning: 'notifications_none',
  error: 'report_problem',
};

const Notification: React.FC<Props> = ({
  text,
  status = 'info',
  onClick,
  close,
}) => {
  return (
    <div
      className={`${styles.wrapper} ${styles[status]}`}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
    >
      <i className={`material-icons-outlined ${styles.icon}`}>{icon[status]}</i>
      <p className={styles.description}>{text}</p>
      {close && (
        <div className={styles.close}>
          <IconButton icon="clear" onClick={close} />
        </div>
      )}
    </div>
  );
};

export default Notification;
