import { useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import * as queries from '../../../graphql/queries';

const useUpdateAllowedIpList = (
  serviceId?: string,
): [updateAllowedIpList: (ipList: string) => void, isLoading: boolean] => {
  const { t } = useTranslation('serviceIpRestrictionsSettings');
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();
  const cache = useQueryClient();

  const updateAllowedIpList = useCallback(
    (ipList: string) => {
      setIsLoading(true);
      (
        API.graphql(
          graphqlOperation(queries.updateService, { serviceId, ipList }),
        ) as Promise<{
          data: { updateService: { result: boolean; message?: string } };
        }>
      )
        .then((result) => {
          setIsLoading(false);
          if (result.data.updateService.result) {
            addToast(t('Changes have been made.'), {
              appearance: 'success',
            });
            // invalidate
            cache.invalidateQueries(['service'], { type: 'all' });
            cache.invalidateQueries(['services'], { type: 'all' });
          } else {
            addToast(result.data.updateService.message, {
              appearance: 'error',
            });
          }
        })
        .catch((e) => {
          setIsLoading(false);
          addToast(t('Changes have been made.'), {
            appearance: 'error',
          });
        });
    },
    [addToast, cache, serviceId, t],
  );

  return [updateAllowedIpList, isLoading];
};

export default useUpdateAllowedIpList;
