/* tslint:disable */
/* eslint-disable */
/**
 * マネジメントAPI
 * マネジメントAPI
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  type Content,
  ContentFromJSON,
  ContentToJSON,
  type ContentList,
  ContentListFromJSON,
  ContentListToJSON,
  type ContentWhenPatch,
  ContentWhenPatchFromJSON,
  ContentWhenPatchToJSON,
  type DeleteMediumByDirectoryResponse,
  DeleteMediumByDirectoryResponseFromJSON,
  DeleteMediumByDirectoryResponseToJSON,
  ErrorResponse,
  ErrorResponseFromJSON,
  ErrorResponseToJSON,
  type HealthcheckResponse,
  HealthcheckResponseFromJSON,
  HealthcheckResponseToJSON,
  type Media,
  MediaFromJSON,
  MediaToJSON,
  type MediaV2,
  MediaV2FromJSON,
  MediaV2ToJSON,
  type PatchContentCreatedByRequest,
  PatchContentCreatedByRequestFromJSON,
  PatchContentCreatedByRequestToJSON,
  type PatchContentStatusResponse,
  PatchContentStatusResponseFromJSON,
  PatchContentStatusResponseToJSON,
  type PostMediaResponse,
  PostMediaResponseFromJSON,
  PostMediaResponseToJSON,
  type SuccessResponse,
  SuccessResponseFromJSON,
  SuccessResponseToJSON,
} from '../models';

export interface DeleteApiV2MediaRequest {
  url?: string;
}

export interface GetApiV1ContentsEndpointRequest {
  endpoint: string;
  limit?: number;
  offset?: number;
}

export interface GetApiV1ContentsEndpointContentIdRequest {
  endpoint: string;
  contentId: string;
}

export interface GetApiV1MediaRequest {
  limit?: number;
  offset?: number;
  imageOnly?: boolean;
}

export interface GetApiV2MediaRequest {
  limit?: number;
  token?: string;
  imageOnly?: boolean;
  fileName?: string;
}

export interface HealthcheckRequest {
  message?: string;
}

export interface PatchApiV1ContentsEndpointContentIdRequest {
  endpoint: string;
  contentId: string;
  contentWhenPatch?: ContentWhenPatch;
}

export interface PatchApiV1EndpointContentIdCreatedByRequest {
  endpoint: string;
  contentId: string;
  patchContentCreatedByRequest?: PatchContentCreatedByRequest;
}

export interface PatchApiV1EndpointContentIdStatusRequest {
  endpoint: string;
  contentId: string;
  patchContentStatusResponse?: PatchContentStatusResponse;
}

export interface PostApiV1MediaRequest {
  file?: Blob;
}

/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
  /**
   * メディアを削除
   * メディアの削除
   */
  async deleteApiV2MediaRaw(
    requestParameters: DeleteApiV2MediaRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<DeleteMediumByDirectoryResponse>> {
    const queryParameters: any = {};

    if (requestParameters.url !== undefined) {
      queryParameters['url'] = requestParameters.url;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/media`,
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DeleteMediumByDirectoryResponseFromJSON(jsonValue),
    );
  }

  /**
   * メディアを削除
   * メディアの削除
   */
  async deleteApiV2Media(
    requestParameters: DeleteApiV2MediaRequest = {},
    initOverrides?: RequestInit,
  ): Promise<DeleteMediumByDirectoryResponse> {
    const response = await this.deleteApiV2MediaRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * コンテンツ一覧の取得
   * コンテンツ一覧の取得
   */
  async getApiV1ContentsEndpointRaw(
    requestParameters: GetApiV1ContentsEndpointRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Content | ContentList>> {
    if (
      requestParameters.endpoint === null ||
      requestParameters.endpoint === undefined
    ) {
      throw new runtime.RequiredError(
        'endpoint',
        'Required parameter requestParameters.endpoint was null or undefined when calling getApiV1ContentsEndpoint.',
      );
    }

    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters['offset'] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/contents/{endpoint}`.replace(
          `{${'endpoint'}}`,
          encodeURIComponent(String(requestParameters.endpoint)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(
      response,
      (jsonValue) => jsonValue as Content | ContentList,
    );
  }

  /**
   * コンテンツ一覧の取得
   * コンテンツ一覧の取得
   */
  async getApiV1ContentsEndpoint(
    requestParameters: GetApiV1ContentsEndpointRequest,
    initOverrides?: RequestInit,
  ): Promise<Content | ContentList> {
    const response = await this.getApiV1ContentsEndpointRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * コンテンツの詳細を取得
   * コンテンツの詳細取得
   */
  async getApiV1ContentsEndpointContentIdRaw(
    requestParameters: GetApiV1ContentsEndpointContentIdRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Content>> {
    if (
      requestParameters.endpoint === null ||
      requestParameters.endpoint === undefined
    ) {
      throw new runtime.RequiredError(
        'endpoint',
        'Required parameter requestParameters.endpoint was null or undefined when calling getApiV1ContentsEndpointContentId.',
      );
    }

    if (
      requestParameters.contentId === null ||
      requestParameters.contentId === undefined
    ) {
      throw new runtime.RequiredError(
        'contentId',
        'Required parameter requestParameters.contentId was null or undefined when calling getApiV1ContentsEndpointContentId.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/contents/{endpoint}/{contentId}`
          .replace(
            `{${'endpoint'}}`,
            encodeURIComponent(String(requestParameters.endpoint)),
          )
          .replace(
            `{${'contentId'}}`,
            encodeURIComponent(String(requestParameters.contentId)),
          ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ContentFromJSON(jsonValue),
    );
  }

  /**
   * コンテンツの詳細を取得
   * コンテンツの詳細取得
   */
  async getApiV1ContentsEndpointContentId(
    requestParameters: GetApiV1ContentsEndpointContentIdRequest,
    initOverrides?: RequestInit,
  ): Promise<Content> {
    const response = await this.getApiV1ContentsEndpointContentIdRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * メディアの一覧を取得
   * メディアの詳細取得
   */
  async getApiV1MediaRaw(
    requestParameters: GetApiV1MediaRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Media>> {
    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters['offset'] = requestParameters.offset;
    }

    if (requestParameters.imageOnly !== undefined) {
      queryParameters['imageOnly'] = requestParameters.imageOnly;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/media`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MediaFromJSON(jsonValue),
    );
  }

  /**
   * メディアの一覧を取得
   * メディアの詳細取得
   */
  async getApiV1Media(
    requestParameters: GetApiV1MediaRequest = {},
    initOverrides?: RequestInit,
  ): Promise<Media> {
    const response = await this.getApiV1MediaRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * メディアの一覧を取得
   * メディアの一覧取得
   */
  async getApiV2MediaRaw(
    requestParameters: GetApiV2MediaRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<MediaV2>> {
    const queryParameters: any = {};

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit;
    }

    if (requestParameters.token !== undefined) {
      queryParameters['token'] = requestParameters.token;
    }

    if (requestParameters.imageOnly !== undefined) {
      queryParameters['imageOnly'] = requestParameters.imageOnly;
    }

    if (requestParameters.fileName !== undefined) {
      queryParameters['fileName'] = requestParameters.fileName;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v2/media`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MediaV2FromJSON(jsonValue),
    );
  }

  /**
   * メディアの一覧を取得
   * メディアの一覧取得
   */
  async getApiV2Media(
    requestParameters: GetApiV2MediaRequest = {},
    initOverrides?: RequestInit,
  ): Promise<MediaV2> {
    const response = await this.getApiV2MediaRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * ヘルスチェック
   * ヘルスチェック
   */
  async healthcheckRaw(
    requestParameters: HealthcheckRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<HealthcheckResponse>> {
    const queryParameters: any = {};

    if (requestParameters.message !== undefined) {
      queryParameters['message'] = requestParameters.message;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/healthcheck`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      HealthcheckResponseFromJSON(jsonValue),
    );
  }

  /**
   * ヘルスチェック
   * ヘルスチェック
   */
  async healthcheck(
    requestParameters: HealthcheckRequest = {},
    initOverrides?: RequestInit,
  ): Promise<HealthcheckResponse> {
    const response = await this.healthcheckRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * コンテンツの更新
   */
  async patchApiV1ContentsEndpointContentIdRaw(
    requestParameters: PatchApiV1ContentsEndpointContentIdRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.endpoint === null ||
      requestParameters.endpoint === undefined
    ) {
      throw new runtime.RequiredError(
        'endpoint',
        'Required parameter requestParameters.endpoint was null or undefined when calling patchApiV1ContentsEndpointContentId.',
      );
    }

    if (
      requestParameters.contentId === null ||
      requestParameters.contentId === undefined
    ) {
      throw new runtime.RequiredError(
        'contentId',
        'Required parameter requestParameters.contentId was null or undefined when calling patchApiV1ContentsEndpointContentId.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/contents/{endpoint}/{contentId}`
          .replace(
            `{${'endpoint'}}`,
            encodeURIComponent(String(requestParameters.endpoint)),
          )
          .replace(
            `{${'contentId'}}`,
            encodeURIComponent(String(requestParameters.contentId)),
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: ContentWhenPatchToJSON(requestParameters.contentWhenPatch),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * コンテンツの更新
   */
  async patchApiV1ContentsEndpointContentId(
    requestParameters: PatchApiV1ContentsEndpointContentIdRequest,
    initOverrides?: RequestInit,
  ): Promise<void> {
    await this.patchApiV1ContentsEndpointContentIdRaw(
      requestParameters,
      initOverrides,
    );
  }

  /**
   * コンテンツ作成者の更新
   * コンテンツ作成者の更新
   */
  async patchApiV1EndpointContentIdCreatedByRaw(
    requestParameters: PatchApiV1EndpointContentIdCreatedByRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<SuccessResponse>> {
    if (
      requestParameters.endpoint === null ||
      requestParameters.endpoint === undefined
    ) {
      throw new runtime.RequiredError(
        'endpoint',
        'Required parameter requestParameters.endpoint was null or undefined when calling patchApiV1EndpointContentIdCreatedBy.',
      );
    }

    if (
      requestParameters.contentId === null ||
      requestParameters.contentId === undefined
    ) {
      throw new runtime.RequiredError(
        'contentId',
        'Required parameter requestParameters.contentId was null or undefined when calling patchApiV1EndpointContentIdCreatedBy.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/contents/{endpoint}/{contentId}/createdBy`
          .replace(
            `{${'endpoint'}}`,
            encodeURIComponent(String(requestParameters.endpoint)),
          )
          .replace(
            `{${'contentId'}}`,
            encodeURIComponent(String(requestParameters.contentId)),
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchContentCreatedByRequestToJSON(
          requestParameters.patchContentCreatedByRequest,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SuccessResponseFromJSON(jsonValue),
    );
  }

  /**
   * コンテンツ作成者の更新
   * コンテンツ作成者の更新
   */
  async patchApiV1EndpointContentIdCreatedBy(
    requestParameters: PatchApiV1EndpointContentIdCreatedByRequest,
    initOverrides?: RequestInit,
  ): Promise<SuccessResponse> {
    const response = await this.patchApiV1EndpointContentIdCreatedByRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * コンテンツのステータスを更新
   * ステータスの更新
   */
  async patchApiV1EndpointContentIdStatusRaw(
    requestParameters: PatchApiV1EndpointContentIdStatusRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<SuccessResponse>> {
    if (
      requestParameters.endpoint === null ||
      requestParameters.endpoint === undefined
    ) {
      throw new runtime.RequiredError(
        'endpoint',
        'Required parameter requestParameters.endpoint was null or undefined when calling patchApiV1EndpointContentIdStatus.',
      );
    }

    if (
      requestParameters.contentId === null ||
      requestParameters.contentId === undefined
    ) {
      throw new runtime.RequiredError(
        'contentId',
        'Required parameter requestParameters.contentId was null or undefined when calling patchApiV1EndpointContentIdStatus.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/v1/contents/{endpoint}/{contentId}/status`
          .replace(
            `{${'endpoint'}}`,
            encodeURIComponent(String(requestParameters.endpoint)),
          )
          .replace(
            `{${'contentId'}}`,
            encodeURIComponent(String(requestParameters.contentId)),
          ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: PatchContentStatusResponseToJSON(
          requestParameters.patchContentStatusResponse,
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SuccessResponseFromJSON(jsonValue),
    );
  }

  /**
   * コンテンツのステータスを更新
   * ステータスの更新
   */
  async patchApiV1EndpointContentIdStatus(
    requestParameters: PatchApiV1EndpointContentIdStatusRequest,
    initOverrides?: RequestInit,
  ): Promise<SuccessResponse> {
    const response = await this.patchApiV1EndpointContentIdStatusRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   * メディアの追加
   * メディアの追加
   */
  async postApiV1MediaRaw(
    requestParameters: PostApiV1MediaRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<PostMediaResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append('file', requestParameters.file as any);
    }

    const response = await this.request(
      {
        path: `/api/v1/media`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PostMediaResponseFromJSON(jsonValue),
    );
  }

  /**
   * メディアの追加
   * メディアの追加
   */
  async postApiV1Media(
    requestParameters: PostApiV1MediaRequest = {},
    initOverrides?: RequestInit,
  ): Promise<PostMediaResponse> {
    const response = await this.postApiV1MediaRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }
}
