import { API, graphqlOperation } from 'aws-amplify';

import type { GraphQLResult } from '@aws-amplify/api-graphql';

import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';

export const accountRepository = () => {
  const deleteAccount = async () => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.deleteAccount),
      )) as GraphQLResult<{
        deleteAccount:
          | { result: boolean; message?: string; data?: string }
          | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.deleteAccount === 'object' &&
        typeof result.data.deleteAccount.result === 'boolean'
      ) {
        // TODO：以下を共通化（これ以外の分岐はGo移植後に削除する）
        const success = result.data.deleteAccount.result;

        if (success) {
          return result.data.deleteAccount.data;
        } else {
          throw new Error(
            result.data.deleteAccount.message ?? 'Unexpected Error',
          );
        }
        // TODO：ここまで
      } else if (typeof result.data.deleteAccount === 'string') {
        return result.data.deleteAccount;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const createAccountLanguage = async (userLanguage: string) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.createAccount, {
          value: {
            userLanguage,
          },
        }),
      )) as GraphQLResult<{
        createAccount:
          | { result: boolean; message?: string; data?: string }
          | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.createAccount === 'object' &&
        typeof result.data.createAccount.result === 'boolean'
      ) {
        // TODO：以下を共通化（これ以外の分岐はGo移植後に削除する）
        const success = result.data.createAccount.result;
        if (success && typeof result.data.createAccount.data === 'string') {
          return JSON.parse(result.data.createAccount.data);
        } else if (success) {
          return result.data.createAccount.data;
        } else {
          throw new Error(
            result.data.createAccount.message ?? 'Unexpected Error',
          );
        }
        // TODO：ここまで
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  return { deleteAccount, createAccountLanguage };
};
