import { useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useState, useEffect, useCallback } from 'react';

import * as queries from '../../graphql/queries';

import { useApiListInvalidateCache } from '@/views/Common/api/invalidateCache';

const useImportStatus = (api, importResult, resetImportProgress) => {
  const cache = useQueryClient();
  const apiListInvalidateCache = useApiListInvalidateCache();

  const [progress, setProgress] = useState(api.apiImportProgress || {});
  const [timer, setTimer] = useState();
  const [isOpen, setIsOpen] = useState(
    (api.apiImportProgress && api.apiImportProgress.status === 'COMPLETE') ||
      (api.apiImportProgress && api.apiImportProgress.status === 'ERROR'),
  );
  const close = useCallback(() => {
    cache.invalidateQueries(['contentList'], { type: 'all' });

    setIsOpen(false);

    // ここでインポートステータスをリセットすることでAPIに変更が起き
    // totalCountとcontentsの再取得が勝手に始まる
    resetImportProgress(api.partitionKey);
    apiListInvalidateCache();
  }, [cache, resetImportProgress, api.partitionKey, apiListInvalidateCache]);

  useEffect(() => {
    if (
      importResult !== undefined ||
      (api.apiImportProgress && api.apiImportProgress.status === 'RUNNING')
    ) {
      const timer = setInterval(() => {
        API.graphql(
          graphqlOperation(queries.findApi, {
            apiId: api.partitionKey,
          }),
        ).then((res) => {
          setProgress(res.data.findApi.apiImportProgress || {});
        });
      }, 3000);
      setTimer(timer);
    }
  }, [api.apiImportProgress, api.partitionKey, importResult]);

  useEffect(() => {
    if (progress.status === 'COMPLETE' || progress.status === 'ERROR') {
      cache.invalidateQueries(['contentList'], { type: 'all' });
      setIsOpen(true);
      clearInterval(timer);
      setTimer(undefined);
    }
  }, [cache, progress.status, timer]);

  useEffect(() => {
    return () => clearInterval(timer);
  }, [timer]);

  return [progress, isOpen, close];
};

export default useImportStatus;
