import { handleActions, combineActions } from 'redux-actions';

import actions from './actions.js';

const initialState = {};

const reducer = handleActions(
  {
    [actions.successConfirmSignup]: (state) => ({
      confirmSignupSuccess: true,
    }),
    [actions.redirectToConfirmSignup]: (state) => ({
      redirectToConfirmSignup: true,
    }),
    [actions.redirectToMfa]: (state, { payload: { tempUser } }) => ({
      redirectToMfa: true,
      tempUser,
    }),

    [actions.successForgotPassword]: (state) => ({
      forgotPasswordSuccess: true,
    }),
    [actions.successForgotPasswordSubmit]: (state) => initialState,
    [actions.successChangePassword]: (state) => ({
      changePasswordSuccess: true,
    }),
    [actions.successSignout]: (state) => initialState,

    [combineActions(
      actions.failureConfirmSignup,
      actions.failureForgotPassword,
      actions.failureForgotPasswordSubmit,
      actions.failureChangePassword,
      actions.failureSignout,
    )]: (state, { payload: { serverError } }) => ({
      serverError,
    }),

    [actions.resetAuth]: (state) => initialState,
  },
  initialState,
);

export default reducer;
