import { useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { updateMemberRoles as updateMemberRolesUsecase } from '@/usecase/memberRolesUsecase';

import { apiQueryKeys } from '@/views/Common/api/queryKeys';
import * as queries from '../../graphql/queries';
import { myRolesContext } from './useMyRoles';

export const useMemberRoles = (serviceId, targetUsername) => {
  const cache = useQueryClient();
  const { t } = useTranslation('hooksRole');
  const [result, setResult] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const [roleIds, setRoleIds] = useState(undefined);
  const { reloadRoles } = useContext(myRolesContext);

  useEffect(() => {
    if (serviceId) {
      API.graphql(
        graphqlOperation(queries.findServiceUser, {
          userId: targetUsername,
          serviceId,
        }),
      )
        .then((result) => result.data.findServiceUser)
        .then((result) => {
          setRoleIds(JSON.parse(result)?.user?.roleIds);
        });
    }
  }, [serviceId, targetUsername]);

  // 結果はしばらくしたら消す;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setResult(undefined);
      setError(undefined);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [result, error]);

  const updateMemberRoles = useCallback(async () => {
    if (!roleIds || roleIds.length === 0) {
      setError({
        errors: [new Error(t('Assign one or more roles'))],
      });
      return;
    }

    setLoading(true);
    try {
      const result = await updateMemberRolesUsecase({
        serviceId,
        targetUsername,
        roleIds,
      });

      setLoading(false);
      setResult(result);
      reloadRoles(serviceId);
      cache.invalidateQueries(['serviceUsers'], { type: 'all' });
      cache.invalidateQueries(['myRoles', serviceId], { type: 'all' });
      cache.invalidateQueries(['reviewRequestsCount'], { type: 'all' });
      cache.invalidateQueries(apiQueryKeys.list(), { type: 'all' });
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  }, [cache, reloadRoles, roleIds, serviceId, targetUsername, t]);

  return [roleIds, setRoleIds, updateMemberRoles, result, error, loading];
};
