import type React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Switch } from 'react-router-dom';

import CheckRoles from '../CheckRoles';
import Main from '../Main';
import Media from '../Media';
import MediaSettings from '../MediaSettings';
import PrivateRoute from '../PrivateRoute';
import IconWithTextNavLink from '../ui/IconWithTextNavLink';

import styles from './mediaTop.module.css';

const MediaTop: React.FC = () => {
  const { t } = useTranslation('mediaTop');
  return (
    <CheckRoles
      permission="mediumPermission"
      operation="readLevel"
      display={true}
    >
      <Main
        header={
          <header className={styles.header}>
            <div className={styles.tabs}>
              <h2 className={styles.title}>
                <Link to="/media">{t('Manage Media')}</Link>
              </h2>
              <div className={styles.tabSection}>
                <IconWithTextNavLink
                  to="/media/settings"
                  icon="settings"
                  text={t('Media Settings')}
                  enableActiveStyle={true}
                />
              </div>
            </div>
          </header>
        }
      >
        <Switch>
          <PrivateRoute exact path="/media" component={Media} />
          <PrivateRoute path="/media/settings" component={MediaSettings} />
        </Switch>
      </Main>
    </CheckRoles>
  );
};

export default MediaTop;
