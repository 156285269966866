import { API, graphqlOperation } from 'aws-amplify';

import type {
  EnsureContentObjectExists,
  EnsureContentObjectExistsQuery,
  FindContentByVersion,
  FindCustomFieldsQuery,
  SearchContentsQuery,
  SearchOptions,
} from '@/API';
import type {
  BulkContentStatus,
  BulkContentsResponse,
  Content,
  ContentStatus,
} from '@/entity/content';
import type { ApiResponse } from '@/views/Common/handleApiResult';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import { isGraphQLResultErrorsObject } from '@/util/type-guard';
import { handleApiError } from '@/views/Common/handleApiResult';
import { handleApiResult } from '@/views/Common/handleApiResult';

export const contentRepository = () => {
  const findContent = async (
    apiId: string,
    contentId: string,
  ): Promise<Content | null> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.findContent, {
          apiId,
          userDefinedId: contentId,
        }),
      )) as { data: { findContent: Content } };

      if (result === undefined || result === null) {
        return null;
      }

      if (
        result.data.findContent === null ||
        result.data.findContent === undefined
      ) {
        return null;
      }

      const content = result.data.findContent;
      return content;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not get content');
    }
  };

  const searchContents = async (
    apiId: string,
    options: SearchOptions = {},
  ): Promise<{ contents: Content[]; totalCount: number } | null> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.searchContents, {
          apiId,
          options,
        }),
      )) as GraphQLResult<SearchContentsQuery>;

      if (result.data === undefined) {
        return null;
      }

      if (
        result.data.searchContents === null ||
        result.data.searchContents === undefined
      ) {
        return null;
      }

      return JSON.parse(result.data.searchContents);
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not get content');
    }
  };

  const findContentByVersion = async ({
    contentId,
    version,
    denyDraft,
  }: {
    contentId: string;
    version: number;
    denyDraft?: boolean;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.findContentByVersion, {
          contentId,
          version,
          denyDraft,
        }),
      )) as GraphQLResult<{
        findContentByVersion: FindContentByVersion;
      }>;

      if (result.errors && result.errors.length > 0) {
        throw new Error(result.errors[0].message);
      }

      if (result.data === undefined) {
        throw new Error('Unexpected Error');
      }

      return result.data.findContentByVersion;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not get content');
    }
  };

  const ensureContentObjectExists = async (
    apiId: string,
  ): Promise<EnsureContentObjectExists> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.ensureContentObjectExists, {
          apiId,
        }),
      )) as GraphQLResult<EnsureContentObjectExistsQuery>;

      if (
        result.data?.ensureContentObjectExists === undefined ||
        result.data?.ensureContentObjectExists === null
      ) {
        throw new Error('Unexpected Error');
      }

      return result.data?.ensureContentObjectExists;
    } catch (e) {
      return handleApiError(e);
    }
  };

  const updateContent = async ({
    contentId,
    value,
    contentStatus,
  }: {
    contentId: string;
    value: string;
    contentStatus: ContentStatus;
  }): Promise<Content> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.updateContent, {
          contentId,
          value,
          contentStatus,
        }),
      )) as GraphQLResult<{
        updateContent: ApiResponse<Content> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.updateContent === 'object' &&
        typeof result.data.updateContent.result === 'boolean'
      ) {
        return handleApiResult(result.data.updateContent).data;
      } else if (typeof result.data.updateContent === 'string') {
        return JSON.parse(result.data.updateContent) as Content;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      return handleApiError(e);
    }
  };

  const getCustomFields = async (apiId: string) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.findCustomFields, { apiId }),
      )) as GraphQLResult<FindCustomFieldsQuery>;

      return result.data?.findCustomFields || [];
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not get custom fields');
    }
  };

  const createCustomField = async ({
    apiId,
    fieldId,
    name,
    fields,
    position,
  }: {
    apiId: string;
    fieldId: string;
    name: string;
    fields: string;
    position: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.createCustomField, {
          apiId,
          fieldId,
          name,
          fields,
          position,
        }),
      )) as GraphQLResult<{
        createCustomField: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.createCustomField === 'object' &&
        typeof result.data.createCustomField.result === 'boolean'
      ) {
        return handleApiResult(result.data.createCustomField);
      } else if (typeof result.data.createCustomField === 'string') {
        return JSON.parse(result.data.createCustomField);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  const updateCustomField = async ({
    apiId,
    fieldId,
    createdAt,
    name,
    fields,
    position,
  }: {
    apiId: string;
    fieldId: string;
    createdAt: string;
    name: string;
    fields: string;
    position: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.updateCustomField, {
          apiId,
          fieldId,
          createdAt,
          name,
          fields,
          position,
        }),
      )) as GraphQLResult<{
        updateCustomField: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.updateCustomField === 'object' &&
        typeof result.data.updateCustomField.result === 'boolean'
      ) {
        return handleApiResult(result.data.updateCustomField);
      } else if (typeof result.data.updateCustomField === 'string') {
        return JSON.parse(result.data.updateCustomField);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  const deleteCustomField = async ({
    apiId,
    createdAt,
  }: {
    apiId: string;
    createdAt: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.deleteCustomField, {
          apiId,
          createdAt,
        }),
      )) as GraphQLResult<{
        deleteCustomField: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.deleteCustomField === 'object' &&
        typeof result.data.deleteCustomField.result === 'boolean'
      ) {
        return handleApiResult(result.data.deleteCustomField);
      } else if (typeof result.data.deleteCustomField === 'string') {
        return JSON.parse(result.data.deleteCustomField);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      handleApiError(e);
    }
  };

  const updateDraftKey = async (contentId: string) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.updateDraftKey, { contentId }),
      )) as GraphQLResult<{
        updateDraftKey:
          | { result: boolean; message?: string; data?: string }
          | string;
      }>;

      if (!result.data?.updateDraftKey) {
        throw new Error('Unexpected error');
      }

      // TODO:Go移植後に削除する
      if (result === undefined || result === null) {
        throw new Error('Unexpected error');
      }
      if (
        result.data?.updateDraftKey === null ||
        result.data?.updateDraftKey === undefined
      ) {
        throw new Error('Unexpected error');
      }
      // TODO:ここまで

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data?.updateDraftKey === 'object' &&
        typeof result.data?.updateDraftKey.result === 'boolean'
      ) {
        // TODO:以下を共通化（これ以外の分岐はGo移植後に削除する）
        const success = result.data.updateDraftKey.result;

        if (success && typeof result.data.updateDraftKey.data === 'string') {
          return JSON.parse(
            result.data.updateDraftKey.data,
          ).gsiSortKey2.replace(/.*#CONTENT_ID#/, '') as string;
        } else {
          throw new Error(
            result.data.updateDraftKey.message && 'Unexpected error',
          );
        }
        // TODO:ここまで
      } else if (typeof result.data.updateDraftKey === 'string') {
        return JSON.parse(result.data?.updateDraftKey).gsiSortKey2.replace(
          /.*#CONTENT_ID#/,
          '',
        ) as string;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }

      // クエリ実行でバリデーションエラーだった場合は、ここに到達する
      if (isGraphQLResultErrorsObject(error)) {
        throw new Error(error.errors[0].message);
      }

      throw new Error('Could not update draft key');
    }
  };

  const updatePublishedAt = async (contentId: string, publishedAt: Date) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.updatePublishedAt, { contentId, publishedAt }),
      )) as GraphQLResult<{
        updatePublishedAt:
          | { result: boolean; message?: string; data?: string }
          | string;
      }>;

      if (!result.data?.updatePublishedAt) {
        throw new Error('Unexpected error');
      }

      // TODO:Go移植後に削除する
      if (result === undefined || result === null) {
        throw new Error('Unexpected error');
      }
      if (
        result.data?.updatePublishedAt === null ||
        result.data?.updatePublishedAt === undefined
      ) {
        throw new Error('Unexpected error');
      }
      // TODO:ここまで

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data?.updatePublishedAt === 'object' &&
        typeof result.data?.updatePublishedAt.result === 'boolean'
      ) {
        // TODO:以下を共通化（これ以外の分岐はGo移植後に削除する）
        const success = result.data.updatePublishedAt.result;

        if (success && typeof result.data.updatePublishedAt.data === 'string') {
          return JSON.parse(
            result.data.updatePublishedAt.data,
          ).gsiSortKey2.replace(/.*#CONTENT_ID#/, '') as string;
        } else {
          throw new Error(
            result.data.updatePublishedAt.message && 'Unexpected error',
          );
        }
        // TODO:ここまで
      } else if (typeof result.data.updatePublishedAt === 'string') {
        return JSON.parse(result.data?.updatePublishedAt).gsiSortKey2.replace(
          /.*#CONTENT_ID#/,
          '',
        ) as string;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }

      // クエリ実行でバリデーションエラーだった場合は、ここに到達する
      if (isGraphQLResultErrorsObject(error)) {
        throw new Error(error.errors[0].message);
      }

      throw new Error('Could not update publishedAt');
    }
  };

  const deleteDraft = async ({ contentId }: { contentId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.deleteDraft, {
          contentId,
        }),
      )) as GraphQLResult<{
        deleteDraft: ApiResponse;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.deleteDraft === 'object' &&
        typeof result.data.deleteDraft.result === 'boolean'
      ) {
        return handleApiResult(result.data.deleteDraft).data;
      } else if (typeof result.data.deleteDraft === 'string') {
        return JSON.parse(result.data.deleteDraft);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      handleApiError(e);
    }
  };

  const moveContentToFirst = async ({ contentId }: { contentId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.moveContentToFirst, {
          contentId,
        }),
      )) as GraphQLResult<{
        moveContentToFirst: ApiResponse<unknown>;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.moveContentToFirst === 'object' &&
        typeof result.data.moveContentToFirst.result === 'boolean'
      ) {
        return handleApiResult(result.data.moveContentToFirst);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  const moveContentToUp = async ({ contentId }: { contentId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.moveContentToUp, {
          contentId,
        }),
      )) as GraphQLResult<{
        moveContentToUp: ApiResponse<unknown>;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.moveContentToUp === 'object' &&
        typeof result.data.moveContentToUp.result === 'boolean'
      ) {
        return handleApiResult(result.data.moveContentToUp);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  const moveContentToDown = async ({ contentId }: { contentId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.moveContentToDown, {
          contentId,
        }),
      )) as GraphQLResult<{
        moveContentToDown: ApiResponse<unknown>;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.moveContentToDown === 'object' &&
        typeof result.data.moveContentToDown.result === 'boolean'
      ) {
        return handleApiResult(result.data.moveContentToDown);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  const moveContentToLast = async ({ contentId }: { contentId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.moveContentToLast, {
          contentId,
        }),
      )) as GraphQLResult<{
        moveContentToLast: ApiResponse<unknown>;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.moveContentToLast === 'object' &&
        typeof result.data.moveContentToLast.result === 'boolean'
      ) {
        return handleApiResult(result.data.moveContentToLast);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  const moveContentToAnyPlace = async ({
    contentId,
    targetContentId,
    isLast,
  }: {
    contentId: string;
    targetContentId: string;
    isLast: boolean;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.moveContentToAnyPlace, {
          contentId,
          targetContentId,
          isLast,
        }),
      )) as GraphQLResult<{
        moveContentToAnyPlace: ApiResponse<unknown>;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.moveContentToAnyPlace === 'object' &&
        typeof result.data.moveContentToAnyPlace.result === 'boolean'
      ) {
        return handleApiResult(result.data.moveContentToAnyPlace);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  const importContentList = async ({
    bucket,
    identityId,
    fileName,
  }: {
    bucket: string;
    identityId: string;
    fileName: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.startImport, {
          bucket,
          identityId,
          fileName,
        }),
      )) as GraphQLResult<{
        startImport: ApiResponse<unknown>;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.startImport === 'object' &&
        typeof result.data.startImport.result === 'boolean'
      ) {
        return handleApiResult(result.data.startImport);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  const createContent = async ({
    apiId,
    contentId,
    value,
    contentStatus,
  }: {
    apiId: string;
    contentId: string;
    value: string;
    contentStatus: ContentStatus;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.createContent, {
          apiId,
          contentId,
          value,
          contentStatus,
        }),
      )) as GraphQLResult<{
        createContent: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.createContent === 'object' &&
        typeof result.data.createContent.result === 'boolean'
      ) {
        return handleApiResult(result.data.createContent);
      } else if (typeof result.data.createContent === 'string') {
        return JSON.parse(result.data.createContent);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      handleApiError(e);
    }
  };

  const copyContent = async ({ contentId }: { contentId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.copyContent, {
          contentId,
        }),
      )) as GraphQLResult<{
        copyContent: ApiResponse<Content & { contentId: string }> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.copyContent === 'object' &&
        typeof result.data.copyContent.result === 'boolean'
      ) {
        return handleApiResult(result.data.copyContent).data.contentId;
      } else if (typeof result.data.copyContent === 'string') {
        return JSON.parse(result.data.copyContent).contentId as string;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  const setReservationTime = async ({
    contentId,
    startDate,
    stopDate,
  }: {
    contentId: string;
    startDate: string;
    stopDate: string;
  }): Promise<Content> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.setReservationTime, {
          contentId,
          startDate,
          stopDate,
        }),
      )) as GraphQLResult<{
        setReservationTime: ApiResponse<Content> | string;
      }>;

      if (!result.data) {
        throw new Error(); // 　利用している箇所で適切なエラーメッセージをフィードバックしている
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.setReservationTime === 'object' &&
        typeof result.data.setReservationTime.result === 'boolean'
      ) {
        return handleApiResult(result.data.setReservationTime).data;
      } else if (typeof result.data.setReservationTime === 'string') {
        return JSON.parse(result.data.setReservationTime) as Content;
      } else {
        throw new Error(); // 　利用している箇所で適切なエラーメッセージをフィードバックしている
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      // クエリ実行でバリデーションエラーだった場合は、ここに到達する
      if (isGraphQLResultErrorsObject(e)) {
        throw new Error(e.errors[0].message);
      }

      throw new Error('Unexpected Error');
    }
  };

  const updateContentStatus = async ({
    contentId,
    customStatusId,
    behaviour,
  }: {
    contentId: string;
    customStatusId: string;
    behaviour: ContentStatus;
  }): Promise<Content> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.updateContentStatus, {
          contentId,
          customStatusId,
          behaviour,
        }),
      )) as GraphQLResult<{
        updateContentStatus: ApiResponse<Content> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      console.log({
        result,
      });

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.updateContentStatus === 'object' &&
        typeof result.data.updateContentStatus.result === 'boolean'
      ) {
        return handleApiResult(result.data.updateContentStatus).data;
      } else if (typeof result.data.updateContentStatus === 'string') {
        const parsed = JSON.parse(result.data.updateContentStatus) as
          | Content
          | { result: false; message: string };
        if ('result' in parsed && parsed.result === false) {
          throw new Error(parsed.message);
        }
        // 上記のif文でエラーが発生しなかった場合は、正常なレスポンスとしてreturn
        return parsed as Content;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      return handleApiError(e);
    }
  };

  const generateUnusedContentId = async ({
    apiId,
  }: {
    apiId: string;
  }): Promise<string> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.generateUnusedContentId, {
          apiId,
        }),
      )) as GraphQLResult<{
        generateUnusedContentId: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.generateUnusedContentId === 'object' &&
        typeof result.data.generateUnusedContentId.result === 'boolean'
      ) {
        return handleApiResult(result.data.generateUnusedContentId).data;
      } else if (typeof result.data.generateUnusedContentId === 'string') {
        return result.data.generateUnusedContentId;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      return handleApiError(e);
    }
  };

  const updateContentId = async ({
    contentId,
    userDefinedId,
  }: {
    contentId: string;
    userDefinedId: string;
  }): Promise<Content> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.updateContentId, {
          contentId,
          userDefinedId,
        }),
      )) as GraphQLResult<{
        updateContentId: ApiResponse<Content> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.updateContentId === 'object' &&
        typeof result.data.updateContentId.result === 'boolean'
      ) {
        return handleApiResult(result.data.updateContentId).data;
      } else if (typeof result.data.updateContentId === 'string') {
        return JSON.parse(result.data.updateContentId) as Content;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      return handleApiError(e);
    }
  };

  const deleteContent = async ({ contentId }: { contentId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.deleteContent, {
          contentId,
        }),
      )) as GraphQLResult<{
        deleteContent: ApiResponse | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.deleteContent === 'object' &&
        typeof result.data.deleteContent.result === 'boolean'
      ) {
        return handleApiResult(result.data.deleteContent);
      } else if (typeof result.data.deleteContent === 'string') {
        return JSON.parse(result.data.deleteContent) as string;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      handleApiError(e);
    }
  };

  const deleteAllContents = async ({ apiId }: { apiId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.deleteAllContents, {
          apiId,
        }),
      )) as GraphQLResult<{
        deleteAllContents: ApiResponse | null;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (result.data.deleteAllContents === null) {
        return result.data.deleteAllContents;
      } else if (
        typeof result.data.deleteAllContents === 'object' &&
        typeof result.data.deleteAllContents?.result === 'boolean'
      ) {
        return handleApiResult(result.data.deleteAllContents);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      handleApiError(e);
    }
  };

  const bulkDeleteContents = async ({
    apiId,
    contentIds,
  }: {
    apiId: string;
    contentIds: string[];
  }): Promise<BulkContentsResponse> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.bulkDeleteContents, {
          apiId,
          contentIds,
        }),
      )) as GraphQLResult<{
        bulkDeleteContents:
          | ApiResponse<Omit<BulkContentsResponse, 'result'>>
          | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.bulkDeleteContents === 'object' &&
        typeof result.data.bulkDeleteContents?.result === 'boolean'
      ) {
        return handleApiResult(result.data.bulkDeleteContents).data;
      } else if (typeof result.data.bulkDeleteContents === 'string') {
        return JSON.parse(
          result.data.bulkDeleteContents,
        ) as BulkContentsResponse;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      return handleApiError(e);
    }
  };
  const bulkUpdateContentsStatus = async ({
    apiId,
    contentIds,
    contentStatus,
  }: {
    apiId: string;
    contentIds: string[];
    contentStatus: BulkContentStatus;
  }): Promise<BulkContentsResponse> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.bulkUpdateContentsStatus, {
          apiId,
          contentIds,
          contentStatus,
        }),
      )) as GraphQLResult<{
        bulkUpdateContentsStatus: ApiResponse<BulkContentsResponse> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.bulkUpdateContentsStatus === 'object' &&
        typeof result.data.bulkUpdateContentsStatus?.result === 'boolean'
      ) {
        return handleApiResult(result.data.bulkUpdateContentsStatus).data;
      } else if (typeof result.data.bulkUpdateContentsStatus === 'string') {
        return JSON.parse(
          result.data.bulkUpdateContentsStatus,
        ) as BulkContentsResponse;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      return handleApiError(e);
    }
  };

  return {
    findContent,
    searchContents,
    findContentByVersion,
    ensureContentObjectExists,
    updateContent,
    getCustomFields,
    createCustomField,
    updateCustomField,
    deleteCustomField,
    updateDraftKey,
    updatePublishedAt,
    deleteDraft,
    moveContentToFirst,
    moveContentToUp,
    moveContentToDown,
    moveContentToLast,
    moveContentToAnyPlace,
    importContentList,
    createContent,
    copyContent,
    setReservationTime,
    updateContentStatus,
    generateUnusedContentId,
    updateContentId,
    deleteContent,
    deleteAllContents,
    bulkDeleteContents,
    bulkUpdateContentsStatus,
  };
};
