import { OpenAPIResponseError } from '@/lib/openapi';
import { getApi } from '@/openapi';

export const contentsRepository = (endpoint: string, contentId: string) => {
  /**
   * コンテンツの作成者を変更する
   */
  const updateContentCreatedBy = async (createdBy: string) => {
    try {
      const api = await getApi();
      await api.patchApiV1ContentsEndpointContentId({
        endpoint,
        contentId,
        contentWhenPatch: { createdBy },
      });
    } catch (error) {
      // NOTE: 200系以外のステータスコードの場合はエラーとして扱われ、エラーがスローされる
      if (error instanceof Response) {
        throw new OpenAPIResponseError(error);
      }
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not update contentCreatedBy');
    }
  };

  return {
    updateContentCreatedBy,
  };
};
