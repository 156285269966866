import type React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { formatDateToMinutes } from '../../../util/date';
import ContentStatusState from '../ContentStatusState';

import type { MigrateApi } from '@/entity/api';
import type { ContentStatus as Status } from '@/entity/content';
import type { CustomState } from '../../../types/customState';

import styles from './contentStatus.module.css';

import { useHasUpdateContentPermission } from '@/views/Common/Roles/MyRolesPermission';

type Props = {
  status: Status;
  reservationTime?: string | null;
  reservationStopTime?: string | null;
  reviewRequestId?: number | null;
  reviewLink?: boolean;
  customStatus?: [CustomState];
  contentCustomStatusIds?: string[];
  doUpdateContentStatus?: (
    status?: string,
    behaviour?: string,
  ) => React.MouseEventHandler;
  isEditing?: boolean;
  apiId?: MigrateApi['partitionKey'];
  hasReadReviewRequestPermission?: boolean | null;
};

const ContentStatus: React.FC<Props> = ({
  status,
  reservationTime,
  reservationStopTime,
  reviewRequestId,
  reviewLink = false,
  customStatus,
  contentCustomStatusIds = [],
  doUpdateContentStatus,
  isEditing = false,
  apiId,
  hasReadReviewRequestPermission,
}) => {
  const { t } = useTranslation('contentStatus');
  const isReserved = useMemo(
    () => reservationTime || reservationStopTime,
    [reservationTime, reservationStopTime],
  );

  const { hasUpdateContentPermission } = useHasUpdateContentPermission();

  const isEditingOnPublished = useMemo(() => {
    return isEditing && hasUpdateContentPermission('publish', apiId);
  }, [hasUpdateContentPermission, isEditing, apiId]);

  const isEditingOnDraft = useMemo(() => {
    return isEditing && hasUpdateContentPermission('saveDraft', apiId);
  }, [hasUpdateContentPermission, isEditing, apiId]);

  const isEditingOnClosed = useMemo(() => {
    return isEditing && hasUpdateContentPermission('unpublish', apiId);
  }, [hasUpdateContentPermission, isEditing, apiId]);

  return (
    <div className={styles.wrapper}>
      {status === 'PUBLISH' && (
        <ContentStatusState
          behaviour="PUBLISH"
          customStatus={customStatus}
          doUpdateContentStatus={doUpdateContentStatus}
          contentCustomStatusIds={contentCustomStatusIds}
          isEditing={isEditingOnPublished}
        />
      )}
      {(status === 'PUBLISH_AND_DRAFT' ||
        // @ts-expect-error: 'PUBLISH_AND_RESERVATION' は取らない値だが、念のためこの条件は残しておく.
        status === 'PUBLISH_AND_RESERVATION') && (
        <ContentStatusState
          behaviour="PUBLISH"
          statusStyle="and"
          customStatus={customStatus}
          doUpdateContentStatus={doUpdateContentStatus}
          contentCustomStatusIds={contentCustomStatusIds}
          isEditing={isEditingOnPublished}
        />
      )}
      {(status === 'DRAFT' || status === 'PUBLISH_AND_DRAFT') &&
        !reviewRequestId &&
        !reservationTime && (
          <ContentStatusState
            behaviour="DRAFT"
            statusStyle="draft"
            customStatus={customStatus}
            doUpdateContentStatus={doUpdateContentStatus}
            contentCustomStatusIds={contentCustomStatusIds}
            isEditing={isEditingOnDraft}
          />
        )}
      {status === 'CLOSED' && !isReserved && (
        <ContentStatusState
          behaviour="CLOSED"
          statusStyle="closed"
          customStatus={customStatus}
          doUpdateContentStatus={doUpdateContentStatus}
          contentCustomStatusIds={contentCustomStatusIds}
          isEditing={isEditingOnClosed}
        />
      )}
      {(status === 'DRAFT' ||
        status === 'PUBLISH_AND_DRAFT' ||
        status === 'CLOSED') &&
        !reviewRequestId &&
        reservationTime && (
          <span className={`${styles.status} ${styles.and} ${styles.reserved}`}>
            {t('Scheduled')}
          </span>
        )}
      {reviewRequestId &&
        reviewLink &&
        hasReadReviewRequestPermission === true && (
          <Link
            to={`/reviews/${reviewRequestId}`}
            onClick={(e) => e.stopPropagation()}
          >
            <span className={`${styles.status} ${styles.inreview}`}>
              {t('Review requested')}
            </span>
          </Link>
        )}
      {reviewRequestId &&
        reviewLink &&
        hasReadReviewRequestPermission === false && (
          <span className={`${styles.status} ${styles.inreview}`}>
            {t('Review requested')}
          </span>
        )}
      {reviewRequestId && !reviewLink && (
        <span className={`${styles.status} ${styles.inreview}`}>
          {t('Review requested')}
        </span>
      )}
      <div>
        {reservationTime && (
          <span className={styles.reservation}>
            <i className="material-icons">schedule</i>
            {t('Publishing')} : {formatDateToMinutes(reservationTime)}
          </span>
        )}
        {reservationStopTime && (
          <span className={styles.reservation}>
            <i className="material-icons">schedule</i>
            {t('Unpublishing')} : {formatDateToMinutes(reservationStopTime)}
          </span>
        )}
      </div>
    </div>
  );
};

export default ContentStatus;
