import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  Redirect,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

import {
  useCancelMemberInvitation,
  useDeleteMember,
  useMember,
  useReinviteMember,
} from '@/hooks/Member/useMembers';
import { useMemberRoles } from '@/hooks/Role/useMemberRoles';
import { myRolesContext } from '@/hooks/Role/useMyRoles';
import { useRoleList } from '@/hooks/Role/useRoles';
import { useGetMyService } from '@/hooks/useService';

import CheckRoles from '../CheckRoles';
import Feedback from '../Feedback';
import Head from '../Head';
import S3Image from '../S3Image';
import Button from '../ui/Button';
import CopyButton from '../ui/CopyButton';
import IconWithTextButton from '../ui/IconWithTextButton';

import styles from './editMember.module.css';

import { Icon } from '@/views/Common/Ui/Icon';
import { useToasts } from 'react-toast-notifications';

const EditMember: React.FC = () => {
  const { t } = useTranslation('editMember');
  const { service } = useGetMyService();
  const { memberId } = useParams<{
    memberId: string;
  }>();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const pendingMemberId =
    pathname === '/members/invitation' && searchParams.get('id');
  const targetMemberId = pendingMemberId || memberId;

  const { addToast } = useToasts();

  const { user } = useMember(targetMemberId);
  const [roles] = useRoleList(service && service.partitionKey);
  const [
    roleIds,
    setRoleIds,
    updateMemberRoles,
    updateResult,
    updateError,
    updateLoading,
  ] = useMemberRoles(service && service.partitionKey, targetMemberId);
  const [deleteMember, deleteResult, deleteError, deleteLoading] =
    useDeleteMember(service && service.partitionKey);
  const [reinviteMember, reinviteMemberLoading] = useReinviteMember();
  const [
    cancelMemberInvitation,
    cancelMemberInvitationLoading,
    cancelMemberInvitationResponse,
  ] = useCancelMemberInvitation();

  const context = useContext(myRolesContext);
  const { myRoleIds, isAdmin } = useMemo(
    () => ({
      myRoleIds: context.roles?.map((r) => r.roleId),
      isAdmin: context.roles?.some((r) => r.isAdmin),
    }),
    [context.roles],
  );

  const hasReadRolePermission = useMemo(() => {
    return (
      context.roles &&
      context.roles
        .map(
          ({ rolePermission }) =>
            rolePermission.readLevel === 'ALL' ||
            rolePermission.readLevel === 'SAME_ROLE',
        )
        .includes(true)
    );
  }, [context.roles]);

  const onChange = useCallback(
    (e: any) => {
      const { checked, value } = e.target;
      if (checked) {
        // @ts-expect-error
        setRoleIds((roleIds) => [...roleIds, value]);
      } else {
        // @ts-expect-error
        setRoleIds((roleIds) => roleIds.filter((id) => id !== value));
      }
    },
    [setRoleIds],
  );

  /**
   * 削除完了後にトーストでフィードバック表示 & メンバー一覧へリダイレクト
   */
  useEffect(() => {
    if (deleteResult) {
      addToast(t('Member has been removed'), { appearance: 'success' });
      history.replace('/members');
    }
  }, [deleteResult, addToast, t, history]);

  const postDeleteMember = useCallback(() => {
    const allowToDelete = window.confirm(
      t('Are you sure you want to remove {{user}} from your service?', {
        user: user.name || user.email,
      }),
    );
    if (allowToDelete) {
      // @ts-expect-error
      deleteMember(memberId);
    }
  }, [deleteMember, memberId, user, t]);

  const [identityId, fileName] = useMemo(
    () => (user && user.picture ? user.picture.split(',') : []),
    [user],
  );
  if (user === undefined || roles === undefined || roleIds === undefined) {
    return null;
  }
  if (
    // @ts-ignore
    cancelMemberInvitationResponse?.result === true
  ) {
    return <Redirect to="/members" />;
  }
  return (
    <div>
      <Head title={t('Member Details')} />
      <header className={styles.header}>
        <Link to="/members" className={styles.backButton}>
          <i className="material-icons-outlined">arrow_back_ios</i>
        </Link>
        <CheckRoles permission="memberPermission" operation="delete">
          {pendingMemberId ? (
            <div className={styles.linkButton}>
              <IconWithTextButton
                text={t('Cancel Invitation')}
                icon="delete"
                onClick={() => {
                  if (service && user) {
                    cancelMemberInvitation({
                      serviceId: service.partitionKey,
                      email:
                        user?.email || pendingMemberId.replace('PENDING#', ''),
                    });
                  }
                }}
                // @ts-ignore
                disabled={cancelMemberInvitationLoading}
                outlined={true}
              />
            </div>
          ) : (
            <div className={styles.linkButton}>
              <IconWithTextButton
                text={t('Remove from Service')}
                icon="delete"
                onClick={postDeleteMember}
                // @ts-expect-error
                disabled={deleteLoading}
                outlined={true}
              />
            </div>
          )}
          <Feedback
            type="failure"
            message={
              deleteError
                ? // @ts-expect-error
                  (deleteError.errors && deleteError.errors[0].message) ||
                  t('Could not delete')
                : undefined
            }
          />
        </CheckRoles>
        <CheckRoles permission="memberPermission" operation="update">
          {pendingMemberId ? (
            <Button
              type="secondary"
              value={t('Resend invitation')}
              onClick={() => {
                if (service) {
                  reinviteMember({
                    serviceId: service.partitionKey,
                    email:
                      user?.email || pendingMemberId.replace('PENDING#', ''),
                  });
                }
              }}
              disabled={reinviteMemberLoading}
            />
          ) : (
            <Button
              type="secondary"
              value={t('Save changes')}
              // @ts-expect-error
              onClick={updateMemberRoles}
              // @ts-expect-error
              disabled={updateLoading}
            />
          )}
          <Feedback
            type="success"
            message={updateResult ? t('Changes have been made.') : undefined}
          />
          <Feedback
            type="failure"
            message={
              updateError
                ? // @ts-expect-error
                  (updateError.errors && updateError.errors[0].message) ||
                  t('Change could not be made')
                : undefined
            }
          />
        </CheckRoles>
      </header>
      <main className={styles.main}>
        <div className={styles.container}>
          {!!pendingMemberId ? (
            <img
              className={styles.defaultImage}
              src="/images/icon_person.svg"
              alt=""
            />
          ) : identityId && fileName ? (
            <S3Image
              directory={`protected/${identityId}`}
              fileName={fileName}
              kind={'IMAGE'}
              queryString="?fit=crop&w=144&h=144"
              className={styles.selectedImage}
              style={{ background: 'none', border: 'none' }}
            />
          ) : user?.picture ? (
            <img className={styles.defaultImage} src={user.picture} alt="" />
          ) : (
            <img
              className={styles.defaultImage}
              src="/images/icon_person.svg"
              alt=""
            />
          )}
          <div className={styles.memberDetail}>
            {!pendingMemberId && (
              <>
                <p className={styles.name}>{user.name}</p>
                <div className={styles.memberId}>
                  <span>ID: {memberId}</span>
                  <CopyButton value={memberId} />
                </div>
              </>
            )}
            <div className={styles.emailWrapper}>
              <p className={styles.emailLabel}>メールアドレス</p>
              <div className={styles.email}>
                <p>
                  {pendingMemberId
                    ? pendingMemberId.replace('PENDING#', '')
                    : user.email}
                </p>
                {pendingMemberId && (
                  <div className={styles.inviteLabel}>
                    <Icon name="assignment" outlined className={styles.icon} />
                    <p>{t('Pending invitation')}</p>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.roles}>
              <p className={styles.title}>{t('Role')}</p>
              <div className={styles.rolesInfo}>
                {hasReadRolePermission && roles.length !== 0 ? (
                  roles.map((role: any) => (
                    <div key={role.roleId}>
                      <label className={styles.label}>
                        <input
                          type="checkbox"
                          // @ts-expect-error
                          defaultChecked={roleIds.includes(role.roleId)}
                          value={role.roleId}
                          onChange={onChange}
                          // @ts-expect-error
                          disabled={
                            pendingMemberId ||
                            (!isAdmin && !myRoleIds?.includes(role.roleId))
                          }
                        />
                        {role.name}
                      </label>
                    </div>
                  ))
                ) : (
                  <p className={styles.notRole}>
                    {t('Cannot display due to lack of authorization.')}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default EditMember;
