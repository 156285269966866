import { findWebhookSettings } from './findWebhookSettings';
import { getWebhookSettingsCount } from './getWebhookSettingsCount';

const webhookSettingRepository = () => {
  return {
    findWebhookSettings,
    getWebhookSettingsCount,
  };
};

export { webhookSettingRepository };
