import type { Props, ViewProps } from './NumberField';

import { validateNumberField } from '@/views/apis/content/useValidateFields';

export const useNumberField = ({
  field,
  value,
  onChange,
}: Props): ViewProps => {
  const error = validateNumberField(field, value) ?? undefined;

  return {
    label: field.name,
    description: field.description,
    value,
    onChange,
    required: field.required,
    error,
  };
};
