import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import dayjs from 'dayjs';
import { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { cookieRepository } from '@/repository/cookieRepository';

import {
  changeCard as changeCardUsecase,
  deleteCard as deleteCardUsecase,
  changePlanToBusiness as changePlanToBusinessUsecase,
  changePlanToTeam as changePlanToTeamUsecase,
  changePlanToAdvanced as changePlanToAdvancedUsecase,
  changePlanToHobby as changePlanToHobbyUsecase,
  changeBillingName as changeBillingNameUsecase,
  changeBillingEmail as changeBillingEmailUsecase,
  changeBillingPhone as changeBillingPhoneUsecase,
  changeBillingCompany as changeBillingCompanyUsecase,
  changeBillingInvoice as changeBillingInvoiceUsecase,
  purchaseAddons as purchaseAddonsUsecase,
  cancelSubscription as cancelSubscriptionUsecase,
  restartSubscription as restartSubscriptionUsecase,
} from '@/usecase/stripeUsecase';

import { myRolesContext } from './Role/useMyRoles';
import { CHARGE_AUTOMATICALLY } from '../constants/collectionMethodTypes';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';

import { isErrorObject } from '@/util/type-guard';
export const useStripeActions = (service) => {
  const { t } = useTranslation('hooks');
  // 権限を取得
  const { roles } = useContext(myRolesContext);
  const hasReadBillingPermission = useMemo(() => {
    return (
      roles &&
      roles
        .map(({ billingPermission }) => billingPermission.read)
        .includes(true)
    );
  }, [roles]);

  const cache = useQueryClient();

  const { addToast } = useToasts();

  // お支払い情報を登録
  const { mutate: changePaymentInfo, isLoading: changePaymentInfoLoading } =
    useMutation({
      mutationFn: async ({
        stripe,
        cardElement,
        email,
        name,
        phone,
        company,
      }) => {
        if (service) {
          let source;
          if (cardElement) {
            const { token, error } = await stripe.createToken(cardElement);
            source = token;
            if (error) {
              throw error;
            }
          }
          return API.graphql(
            graphqlOperation(mutations.changePaymentInfo, {
              serviceId: service.partitionKey,
              source: source ? source.id : null,
              email,
              name,
              phone,
              company,
            }),
          ).then((result) => JSON.parse(result.data.changePaymentInfo));
        }
        return;
      },
      onSuccess(data, { closeModal }) {
        const { result, message } = data;

        if (result === false) {
          addToast(message, {
            appearance: 'error',
          });
        } else {
          cache.invalidateQueries(['subscriptionData'], { type: 'all' });
          cache.invalidateQueries(['invoiceData'], { type: 'all' });
          closeModal();
          addToast(t('Payment details has been registered.'), {
            appearance: 'success',
          });
        }
      },
      onError(error) {
        addToast(
          error.errors[0].message
            ? error.errors[0].message
            : t('Payment details could not be registered.'),
          {
            appearance: 'error',
          },
        );
      },
    });

  // クレジットカードを登録
  const { mutate: changeCard, isLoading: changeCardLoading } = useMutation({
    mutationFn: async ({ stripe, cardElement }) => {
      if (service) {
        const { token, error } = await stripe.createToken(cardElement);
        if (error) {
          throw error;
        }
        return await changeCardUsecase({
          serviceId: service.partitionKey,
          source: token.id,
        });
      }
      return;
    },
    onSuccess(result, { closeModal }) {
      // TODO:フロントエンドエラー改善。旧Node.jsの場合は正常系レスポンスとして処理している。
      // スキーマ変更後に削除する
      if (result.result === false) {
        addToast(result.message, {
          appearance: 'error',
        });
        return;
      }
      cache.invalidateQueries(['subscriptionData'], { type: 'all' });
      cache.invalidateQueries(['invoiceData'], { type: 'all' });
      closeModal();
      addToast(t('Credit card has been registered.'), {
        appearance: 'success',
      });
    },
    onError(error) {
      addToast(error ? error.message : t('Credit card registration failed.'), {
        appearance: 'error',
      });
    },
  });

  // クレジットカードの削除
  const { mutate: deleteCard } = useMutation({
    mutationFn: async () => {
      if (service) {
        return deleteCardUsecase({ serviceId: service.partitionKey });
      }
      return;
    },
    onSuccess(_, { closeModal }) {
      cache.invalidateQueries(['subscriptionData'], { type: 'all' });
      cache.invalidateQueries(['invoiceData'], { type: 'all' });
      closeModal();
      addToast(t('Credit card has been removed.'), {
        appearance: 'success',
      });
    },
    onError({ errors }) {
      addToast(
        errors ? errors[0].message : t('Credit card could not be deleted.'),
        {
          appearance: 'error',
        },
      );
    },
  });

  const showChangePlanToast = (data) => {
    const resultIsTrue = data === true || data.result === true;
    addToast(resultIsTrue ? t('The plan has been changed.') : data.message, {
      appearance: resultIsTrue ? 'success' : 'error',
      autoDismiss: resultIsTrue,
    });
  };

  // Businessプランに変更
  const {
    mutate: changePlanToBusiness,
    isLoading: changePlanToBusinessLoading,
  } = useMutation({
    mutationFn: () => {
      if (!service) {
        return;
      }
      return changePlanToBusinessUsecase({ serviceId: service.partitionKey });
    },
    onSuccess(data, { closeModal, openPaymentInfoModal }) {
      cache.invalidateQueries(['subscriptionData'], { type: 'all' });
      cache.invalidateQueries(['planList'], { type: 'all' });
      cache.invalidateQueries(['experiencedPaidPlans']);
      closeModal();
      openPaymentInfoModal && openPaymentInfoModal();
      showChangePlanToast(data);
    },
    onError(error) {
      addToast(
        isErrorObject(error) ? t(error.message) : t('Could not change plan.'),
        {
          appearance: 'error',
        },
      );
    },
  });

  // Teamプランに変更
  const { mutate: changePlanToTeam, isLoading: changePlanToTeamLoading } =
    useMutation({
      mutationFn: () => {
        if (!service) {
          return;
        }
        return changePlanToTeamUsecase({ serviceId: service.partitionKey });
      },
      onSuccess(data, { closeModal, openPaymentInfoModal }) {
        cache.invalidateQueries(['subscriptionData'], { type: 'all' });
        cache.invalidateQueries(['planList'], { type: 'all' });
        cache.invalidateQueries(['experiencedPaidPlans']);
        closeModal();
        openPaymentInfoModal && openPaymentInfoModal();
        showChangePlanToast(data);
      },
      onError(error) {
        addToast(
          isErrorObject(error) ? t(error.message) : t('Could not change plan.'),
          {
            appearance: 'error',
          },
        );
      },
    });

  // Advancedプランに変更
  const {
    mutate: changePlanToAdvanced,
    isLoading: changePlanToAdvancedLoading,
  } = useMutation({
    mutationFn: () => {
      if (!service) {
        return;
      }
      return changePlanToAdvancedUsecase({ serviceId: service.partitionKey });
    },
    onSuccess(data, { closeModal, openPaymentInfoModal }) {
      cache.invalidateQueries(['subscriptionData'], { type: 'all' });
      cache.invalidateQueries(['planList'], { type: 'all' });
      cache.invalidateQueries(['experiencedPaidPlans']);
      closeModal();
      openPaymentInfoModal && openPaymentInfoModal();
      showChangePlanToast(data);
    },
    onError(error) {
      addToast(
        isErrorObject(error) ? t(error.message) : t('Could not change plan.'),
        {
          appearance: 'error',
        },
      );
    },
  });

  // Hobbyプランに変更
  const { mutate: changePlanToHobby, isLoading: changePlanToHobbyLoading } =
    useMutation({
      mutationFn: () => {
        if (!service) {
          return;
        }
        return changePlanToHobbyUsecase({ serviceId: service.partitionKey });
      },
      onSuccess(data, { closeModal }) {
        cache.invalidateQueries(['subscriptionData'], { type: 'all' });
        cache.invalidateQueries(['planList'], { type: 'all' });
        cache.invalidateQueries(['experiencedPaidPlans']);
        closeModal();
        showChangePlanToast(data);
      },
      onError(error) {
        addToast(
          isErrorObject(error) ? t(error.message) : t('Could not change plan.'),
          {
            appearance: 'error',
          },
        );
      },
    });

  // 請求先メールアドレスを変更
  const { mutate: changeBillingEmail, isLoading: changeBillingEmailLoading } =
    useMutation({
      mutationFn: ({ email }) => {
        if (!service) {
          return;
        }
        return changeBillingEmailUsecase({
          serviceId: service.partitionKey,
          email,
        });
      },
      onSuccess(data, { closeModal }) {
        // TODO:フロントエンドエラー改善。旧Node.jsの場合は正常系レスポンスとして処理している。
        // スキーマ変更後に削除する
        if (data.result === false) {
          addToast(data.message, {
            appearance: 'error',
          });
          return;
        }
        cache.invalidateQueries(['subscriptionData'], { type: 'all' });
        cache.invalidateQueries(['invoiceData'], { type: 'all' });
        closeModal();
        addToast(t('Changed billing Email address.'), {
          appearance: 'success',
        });
      },
      onError({ errors }) {
        addToast(
          errors
            ? errors.errors[0].message
            : t('Could not change billing email address.'),
          {
            appearance: 'error',
          },
        );
      },
    });

  // 請求先ご担当者名を変更
  const { mutate: changeBillingName, isLoading: changeBillingNameLoading } =
    useMutation({
      mutationFn: ({ name }) => {
        if (!service) {
          return;
        }
        return changeBillingNameUsecase({
          serviceId: service.partitionKey,
          name,
        });
      },
      onSuccess(data, { closeModal }) {
        // TODO:フロントエンドエラー改善。旧Node.jsの場合は正常系レスポンスとして処理している。
        // スキーマ変更後に削除する
        if (data.result === false) {
          addToast(data.message, {
            appearance: 'error',
          });
          return;
        }

        cache.invalidateQueries(['subscriptionData'], { type: 'all' });
        cache.invalidateQueries(['invoiceData'], { type: 'all' });
        closeModal();
        addToast(t('Changed billing contact name.'), {
          appearance: 'success',
        });
      },
      onError({ errors }) {
        addToast(
          errors
            ? errors[0].message
            : t('Could not change billing contact name.'),
          {
            appearance: 'error',
          },
        );
      },
    });

  // 請求先電話番号を変更
  const { mutate: changeBillingPhone, isLoading: changeBillingPhoneLoading } =
    useMutation({
      mutationFn: ({ phone }) => {
        if (!service) {
          return;
        }

        return changeBillingPhoneUsecase({
          serviceId: service.partitionKey,
          phone,
        });
      },
      onSuccess(data, { closeModal }) {
        // TODO:フロントエンドエラー改善。旧Node.jsの場合は正常系レスポンスとして処理している。
        // スキーマ変更後に削除する
        if (data.result === false) {
          addToast(data.message, {
            appearance: 'error',
          });
          return;
        }

        cache.invalidateQueries(['subscriptionData'], { type: 'all' });
        cache.invalidateQueries(['invoiceData'], { type: 'all' });
        closeModal();
        addToast(t('Changed billing phone number.'), {
          appearance: 'success',
        });
      },
      onError({ errors }) {
        addToast(
          errors
            ? errors[0].message
            : t('Could not change billing phone number.'),
          {
            appearance: 'error',
          },
        );
      },
    });

  // 請求先会社名を変更
  const {
    mutate: changeBillingCompany,
    isLoading: changeBillingCompanyLoading,
  } = useMutation({
    mutationFn: ({ company }) => {
      if (!service) {
        return;
      }
      return changeBillingCompanyUsecase({
        serviceId: service.partitionKey,
        company,
      });
    },
    onSuccess(data, { closeModal }) {
      // TODO:フロントエンドエラー改善。旧Node.jsの場合は正常系レスポンスとして処理している。
      // スキーマ変更後に削除する
      if (data.result === false) {
        addToast(data.message, {
          appearance: 'error',
        });
        return;
      }

      cache.invalidateQueries(['subscriptionData'], { type: 'all' });
      cache.invalidateQueries(['invoiceData'], { type: 'all' });
      closeModal();
      addToast(t('Changed billing company name.'), {
        appearance: 'success',
      });
    },
    onError({ errors }) {
      addToast(
        errors
          ? errors[0].message
          : t('Could not change billing company name.'),
        {
          appearance: 'error',
        },
      );
    },
  });

  // お支払い方法を変更
  const {
    mutate: changeBillingInvoice,
    isLoading: changeBillingInvoiceLoading,
  } = useMutation({
    mutationFn: () => {
      if (!service) {
        return;
      }

      return changeBillingInvoiceUsecase({ serviceId: service.partitionKey });
    },
    onSuccess(data, { closeModal }) {
      const { result, message } = data;
      if (result === false) {
        addToast(message, {
          appearance: 'error',
        });
      } else {
        cache.invalidateQueries(['subscriptionData'], { type: 'all' });
        cache.invalidateQueries(['invoiceData'], { type: 'all' });
        closeModal();
        addToast(t('Changed to invoice payment.'), {
          appearance: 'success',
        });
      }
    },
    onError({ errors }) {
      addToast(
        errors ? errors[0].message : t('Could not change to invoice payment.'),
        {
          appearance: 'error',
        },
      );
    },
  });

  // アドオンを追加
  const { mutate: purchaseAddons, isLoading: purchaseAddonsLoading } =
    useMutation({
      mutationFn: ({ type }) => {
        if (!service) {
          return;
        }
        return purchaseAddonsUsecase({
          serviceId: service.partitionKey,
          type,
        });
      },
      onSuccess(_, { closeModal }) {
        cache.invalidateQueries(['subscriptionData'], { type: 'all' });
        cache.invalidateQueries(['planList'], { type: 'all' });
        closeModal();
        addToast(t('Add-on'), {
          appearance: 'success',
        });
      },
      onError(error) {
        addToast(error ? error.message : t('Could not add an add-on.'), {
          appearance: 'error',
        });
      },
    });

  // 課金停止
  const { mutate: cancelSubscription, isLoading: cancelSubscriptionLoading } =
    useMutation({
      mutationFn: ({ cancelSelect, cancelReason }) => {
        if (!service) {
          return;
        }
        return cancelSubscriptionUsecase({
          serviceId: service.partitionKey,
          cancelSelect,
          cancelReason,
        });
      },
      onSuccess(_, { closeModal }) {
        cache.invalidateQueries(['subscriptionData'], { type: 'all' });
        cache.invalidateQueries(['planList'], { type: 'all' });
        closeModal();
        addToast(t('Suspended payment.'), {
          appearance: 'success',
        });
      },
      onError(error) {
        addToast(error ? error.message : t('Could not suspend payment.'), {
          appearance: 'error',
        });
      },
    });

  // サービスを再開する
  const { mutate: restartSubscription, isLoading: restartSubscriptionLoading } =
    useMutation({
      mutationFn: async ({
        stripe,
        cardElement,
        email,
        name,
        phone,
        company,
      }) => {
        if (!service) {
          return;
        }
        let source;
        if (cardElement) {
          const { token, error } = await stripe.createToken(cardElement);
          source = token;
          if (error) {
            throw error;
          }
        }

        return restartSubscriptionUsecase({
          serviceId: service.partitionKey,
          sourceId: source ? source.id : null,
          email,
          name,
          phone,
          company,
        });
      },
      onSuccess() {
        cache.invalidateQueries(['subscriptionData'], { type: 'all' });
        cache.invalidateQueries(['planList'], { type: 'all' });
        addToast(
          t(
            'Service has resumed. It may take some time before the APIs are able to return a response.',
          ),
          {
            appearance: 'success',
          },
        );
      },
      onError(error) {
        addToast(
          error.message ? error.message : t('Could not resume service.'),
          {
            appearance: 'error',
          },
        );
      },
    });

  // 課金データを取得
  /** @type {import('@tanstack/react-query').UseQueryResult<import('@/types/stripe').GetStripeSubscriptionData>} */
  const subscriptionDataQuery = useQuery({
    queryKey: ['subscriptionData'],
    queryFn: async () => {
      return API.graphql(
        graphqlOperation(mutations.getStripeSubscription, {
          serviceId: service.partitionKey,
        }),
      ).then((result) => JSON.parse(result.data.getStripeSubscription));
    },
    enabled: !!service && hasReadBillingPermission,
    staleTime: Number.POSITIVE_INFINITY,
  });

  // invoiceのデータを取得
  /** @type {import('@tanstack/react-query').UseQueryResult<import('@/types/stripe').GetStripeInvoiceData>} */
  const invoiceDataQuery = useQuery({
    queryKey: ['invoiceData'],
    queryFn: async () => {
      return API.graphql(
        graphqlOperation(mutations.getStripeInvoice, {
          serviceId: service.partitionKey,
        }),
      ).then((result) => JSON.parse(result.data.getStripeInvoice));
    },
    enabled: !!service && hasReadBillingPermission,
    staleTime: Number.POSITIVE_INFINITY,
  });

  // トライアルが経験済みかを取得
  /** @type {import('@tanstack/react-query').UseQueryResult<import('@/API').HasExperiencedPaidPlansQuery['hasExperiencedPaidPlans']>} */
  const hasExperiencedPaidPlansQuery = useQuery({
    queryKey: ['experiencedPaidPlans'],
    queryFn: async () => {
      return API.graphql(
        graphqlOperation(queries.hasExperiencedPaidPlans, {
          serviceId: service.partitionKey,
        }),
      ).then((result) => JSON.parse(result.data.hasExperiencedPaidPlans));
    },
    enabled: !!service && hasReadBillingPermission,

    staleTime: Number.POSITIVE_INFINITY,
  });

  // プラン情報を取得
  const planListQuery = useQuery({
    queryKey: ['planList'],
    queryFn: async () => {
      return API.graphql(
        graphqlOperation(queries.getPlanList, {
          serviceId: service && service.partitionKey,
        }),
      ).then((result) => result.data.getPlanList);
    },
    enabled: !!service,
    staleTime: Number.POSITIVE_INFINITY,
  });
  const { data: subscriptionData } = subscriptionDataQuery;
  const { data: invoiceData } = invoiceDataQuery;
  const { data: planListData } = planListQuery;

  const currentPlan = useMemo(() => {
    return (
      planListData &&
      planListData.planTypes &&
      planListData.planTypes
        .flatMap((types) => types.plans)
        .find((plan) => plan.current)
    );
  }, [planListData]);

  /**
   * 分析チームへの連携
   * https://app.clickup.com/t/3ebk8t4
   */
  if (currentPlan) {
    const { setCookie } = cookieRepository();
    setCookie({ key: 'usage#planType', value: currentPlan.name });
  }

  const isInvoice = useMemo(() => {
    return invoiceData && invoiceData.invoice;
  }, [invoiceData]);

  const collectionMethod = useMemo(() => {
    return subscriptionData && subscriptionData.collection_method;
  }, [subscriptionData]);

  const isTrial = useMemo(() => {
    return (
      subscriptionData &&
      subscriptionData.trial_end &&
      dayjs().isBefore(dayjs.unix(subscriptionData.trial_end)) &&
      subscriptionData.collectionMethod === CHARGE_AUTOMATICALLY
    );
  }, [subscriptionData]);

  const actionRequired = useMemo(() => {
    return subscriptionData && !!subscriptionData.actionRequiredValue;
  }, [subscriptionData]);

  // 3Dセキュア認証
  const { mutate: handleCardPayment, isLoading: handleCardPaymentLoading } =
    useMutation({
      mutationFn: ({ stripe }) => {
        return stripe
          .handleCardPayment(subscriptionData.actionRequiredValue)
          .then((result) => {
            if (result.error) {
              // 支払いを承認しない場合、catchに飛ばす
              // 支払いは失敗する
              throw result.error;
            }
            return result;
          });
      },
      onSuccess() {
        cache.invalidateQueries(['subscriptionData'], { type: 'all' });
        addToast(
          t(
            '3D Secure authentication has been completed and payment has been processed.',
          ),
          {
            appearance: 'success',
          },
        );
      },
      onError(error) {
        cache.invalidateQueries(['subscriptionData'], { type: 'all' });
        addToast(
          error ? error.message : t('3D secure authentication has failed.'),
          {
            appearance: 'error',
          },
        );
      },
    });

  return {
    changePaymentInfo,
    changePaymentInfoLoading,
    changeCard,
    changeCardLoading,
    deleteCard,
    changePlanToBusiness,
    changePlanToBusinessLoading,
    changePlanToTeam,
    changePlanToTeamLoading,
    changePlanToAdvanced,
    changePlanToAdvancedLoading,
    changePlanToHobby,
    changePlanToHobbyLoading,
    changeBillingEmail,
    changeBillingEmailLoading,
    changeBillingName,
    changeBillingNameLoading,
    changeBillingPhone,
    changeBillingPhoneLoading,
    changeBillingCompany,
    changeBillingCompanyLoading,
    cancelSubscription,
    cancelSubscriptionLoading,
    restartSubscription,
    restartSubscriptionLoading,
    handleCardPayment,
    handleCardPaymentLoading,
    hasExperiencedPaidPlansQuery,
    invoiceDataQuery,
    subscriptionDataQuery,
    planListQuery,

    /** @type {import('@/API').GetPlanListQuery['getPlanList']} */
    planListData,

    currentPlan,
    isTrial,
    isInvoice,
    actionRequired,
    purchaseAddons,
    purchaseAddonsLoading,
    changeBillingInvoice,
    changeBillingInvoiceLoading,
    collectionMethod,
  };
};
