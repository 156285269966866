import type React from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediumCount } from '@/hooks/Medium/useMediumReader';
import { useGetMyService } from '@/hooks/useService';

import { useStripeActions } from '../../../hooks/useStripeActions';
import Head from '../../Head';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import { useDeleteSettings } from './useDeleteSettings';

import styles from './deleteSettings.module.css';

import { apiListSelectors } from '@/ducks/apiList';
import type { ApiList } from '@/entity/api';
import { useAppSelector } from '@/store/hooks';
import { AlertDialog } from '@/views/Common/Ui/AlertDialog';

const DeleteSettings: React.FC = () => {
  const { t } = useTranslation('serviceDeleteSettings');
  const { service } = useGetMyService();

  // TODO: ReduxをReactQueryに置き換える
  const apiLength = useAppSelector(
    (state) =>
      apiListSelectors.getAllApiLength(state.apiListState as ApiList) as number,
  );

  const [mediumCount] = useMediumCount(
    service?.partitionKey && service.partitionKey,
  );

  const { currentPlan, isInvoice } = useStripeActions(service);
  const apiLengthIsNotZero = useMemo(() => apiLength !== 0, [apiLength]);
  const mediumLengthIsNotZero = useMemo(() => mediumCount !== 0, [mediumCount]);
  const planIsNotFree = useMemo(
    () => currentPlan && currentPlan.name !== 'Hobby',
    [currentPlan],
  );
  const isTemplatePlan = useMemo(
    () => currentPlan && currentPlan.name === 'Template',
    [currentPlan],
  );

  const { deleteService } = useDeleteSettings();

  const onDeleteService = useCallback(() => {
    if (service) {
      deleteService(service);
    }
  }, [deleteService, service]);

  const alertMessage = useMemo(() => {
    return apiLengthIsNotZero
      ? t('Cannot be deleted because API (content) exists.')
      : mediumLengthIsNotZero
        ? t('Cannot be deleted because media exists.')
        : isTemplatePlan
          ? undefined
          : planIsNotFree
            ? t(
                'You are currently subscribed to a paid plan. Please cancel your paid plan from the Payment page.',
              )
            : isInvoice
              ? t('You have an outstanding payment. Please contact us.')
              : undefined;
  }, [
    apiLengthIsNotZero,
    mediumLengthIsNotZero,
    isInvoice,
    isTemplatePlan,
    planIsNotFree,
    t,
  ]);

  return (
    <div>
      <Head title={t('Delete service')} />
      <h2 className={styles.title}>{t('Delete service')}</h2>
      <Fieldset
        legend={t('Delete {{name}} service', { name: service?.serviceName })}
        description={t('Warning! Once deleted, this cannot be restored.')}
      >
        <AlertDialog
          trigger={
            <Button
              type="danger"
              className={styles.button}
              disabled={alertMessage !== undefined}
              value={t('Delete')}
            />
          }
          title={t('Delete service')}
          description={t(
            'Are you sure you want to delete service? To perform the deletion, enter {{serviceId}}. Deleted service cannot be restored.',
            { serviceId: service?.domain || 'CONFIRM' },
          )}
          buttonText={t('Delete')}
          onSubmit={onDeleteService}
          confirmText={service?.domain || 'CONFIRM'}
        />
        {alertMessage && <p className={styles.alert}>{alertMessage}</p>}
      </Fieldset>
    </div>
  );
};

export default DeleteSettings;
