import { useState, useEffect } from 'react';

const useCurrentIp = () => {
  const [currentIp, setCurrentIp] = useState(' - ');
  useEffect(() => {
    fetch('https://api.ipify.org/')
      .then((res) => res.text())
      .then(setCurrentIp);
  }, []);
  return [currentIp];
};

export default useCurrentIp;
