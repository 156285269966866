import TiptapTable from '@tiptap/extension-table';
import { ReactNodeViewRenderer } from '@tiptap/react';

import { Table } from './Table';

import type { TableOptions } from '@tiptap/extension-table';

type CustomTableOptions = TableOptions & {
  inputRules: boolean;
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-table/src/table.ts
export const CustomTable = TiptapTable.extend<CustomTableOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: true,
    };
  },

  parseHTML() {
    return this.options.inputRules ? this.parent?.() : [];
  },

  addKeyboardShortcuts() {
    // ブラウザデフォルトのキーボード操作に合わせるため、テーブル拡張のキーボード操作をOFFにする
    return {};
  },

  addNodeView() {
    return ReactNodeViewRenderer(Table);
  },
});
