import { apiListSelectors } from '@/ducks/apiList';
import type { ApiList } from '@/entity/api';
import { useExceptionPermissionIsNot } from '@/hooks/Role/useMyRoles';
import { useAppSelector } from '@/store/hooks';
import { useHasCreateContentPermission } from '@/views/Common/Roles/MyRolesPermission';
import { ContentsCompare } from '@/views/apis/content/compare/ContentCompare';
import { ContentCompareHeader } from '@/views/apis/content/compare/Header';
import { Switch, useHistory, useParams } from 'react-router-dom';
import ApiSettings from '../ApiSettings';
import ContentHeader from '../ContentHeader';
import ContentList from '../ContentList';
import ContentObject from '../ContentObject';
import CreateContent from '../CreateContent';
import CreateField from '../CreateField';
import CustomFields from '../CustomFields';
import CustomFieldsSettings from '../CustomFieldsSettings';
import EditContent from '../EditContent';
import Forbidden from '../Forbidden';
import Main from '../Main';
import PrivateRoute from '../PrivateRoute';

const ContentTop: React.FC = () => {
  // TODO: ReduxをReactQueryに置き換える
  const { endpoint } = useParams<{ endpoint: string }>();
  const api = useAppSelector((state) =>
    apiListSelectors.getApi(state.apiListState as ApiList, endpoint),
  );

  const [hasnotReadContentPermission] = useExceptionPermissionIsNot(
    api && api.partitionKey,
    'contentReadLevel',
    'NONE',
  );
  const { hasCreateContentPermissionForAny } = useHasCreateContentPermission();

  const history = useHistory();
  const paths = history.location.pathname.split('/');
  const isComparePage = paths.at(-2) === 'compare' && paths.at(4) === 'compare';

  if (api === undefined) {
    return null;
  }

  return (
    <Main
      header={
        isComparePage ? (
          <ContentCompareHeader
            api={api}
            userDefinedContentId={paths.at(3) ?? ''}
          />
        ) : (
          <ContentHeader key={api.partitionKey} api={api} />
        )
      }
    >
      <Switch>
        <PrivateRoute
          exact
          path="/apis/:endpoint"
          component={
            !hasnotReadContentPermission
              ? api.apiType === 'LIST'
                ? ContentList
                : ContentObject
              : Forbidden
          }
        />
        <PrivateRoute
          path="/apis/:endpoint/p/:page"
          component={
            !hasnotReadContentPermission
              ? api.apiType === 'LIST'
                ? ContentList
                : ContentObject
              : Forbidden
          }
        />
        <PrivateRoute
          exact
          path="/apis/:endpoint/create"
          component={
            !hasnotReadContentPermission &&
            hasCreateContentPermissionForAny(api.partitionKey)
              ? CreateContent
              : Forbidden
          }
        />
        <PrivateRoute
          path="/apis/:endpoint/settings"
          component={!hasnotReadContentPermission ? ApiSettings : Forbidden}
        />
        <PrivateRoute
          exact
          path="/apis/:endpoint/custom-fields"
          component={!hasnotReadContentPermission ? CustomFields : Forbidden}
        />
        <PrivateRoute
          exact
          path="/apis/:endpoint/custom-fields/create"
          component={!hasnotReadContentPermission ? CreateField : Forbidden}
        />
        <PrivateRoute
          path="/apis/:endpoint/custom-fields/:fieldId"
          component={
            !hasnotReadContentPermission ? CustomFieldsSettings : Forbidden
          }
        />
        <PrivateRoute
          exact
          path="/apis/:endpoint/:contentId"
          component={!hasnotReadContentPermission ? EditContent : Forbidden}
        />
        <PrivateRoute
          exact
          path="/apis/:endpoint/:contentId/compare/:versions"
          component={!hasnotReadContentPermission ? ContentsCompare : Forbidden}
        />
      </Switch>
    </Main>
  );
};

export default ContentTop;
