import Button from '@/components/ui/Button';

import FieldExceptionContent from './FieldExceptionContent';
import { useI18nFormRole } from '../../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../../provider';

import styles from './FieldsetExceptionContent.module.css';

import { Fieldset } from '@/views/Common/Ui/Fieldset';

const FieldsetExceptionContent = () => {
  const { t } = useI18nFormRole();

  const {
    apiSchemas,
    isAdminRole,
    input: { exceptionContentPermissions },
  } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabledAddButton = isAdminRole || apiSchemas.length === 0;

  return (
    <Fieldset
      label={t('Custom Permission for Selected API')}
      description={t(
        'Custom permissions can be set for each selected API individually. The default permissions for a selected API will be overridden by this custom permission.',
      )}
      className={styles.section}
    >
      <div role="list">
        {exceptionContentPermissions.map((permission, idx) => (
          <div
            key={permission.id}
            data-testid={`exceptionContentPermission-${idx}`}
            role="listitem"
          >
            <FieldExceptionContent
              target={{ id: permission.id, indexOf: idx }}
            />
          </div>
        ))}
      </div>

      <Button
        type="tertiary"
        size="full"
        onClick={() => {
          if (apiSchemas.length > 0) {
            dispatch({
              type: 'add-ec-permission',
              // 個別権限のAPIスキーマの初期値は、先頭のAPIスキーマを選択する
              payload: apiSchemas[0].partitionKey,
            });
          }
        }}
        disabled={disabledAddButton}
        icon="add"
        value={t('Set custom permissions for selected API')}
        data-testid="addECPButton"
      />
    </Fieldset>
  );
};

export default FieldsetExceptionContent;
