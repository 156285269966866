import { NavLink } from 'react-router-dom';

import styles from './menuItem.module.css';

type Props = {
  to: string;
  exact?: boolean;
  children: React.ReactNode;
};

const MenuItem: React.FC<Props> = ({ to, exact, children }) => {
  return (
    <NavLink
      to={to}
      className={styles.link}
      exact={exact}
      activeClassName={styles.selected}
    >
      {children}
    </NavLink>
  );
};

export default MenuItem;
