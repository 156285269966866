import * as RadixAlertDialog from '@radix-ui/react-alert-dialog';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '@/components/ui/Button';

import { useInput } from '../../useInput';
import { Input } from '../Input';

import styles from './alertDialog.module.css';

export type Props = {
  open?: boolean;
  trigger: React.ReactNode;
  title?: string;
  description: string;
  buttonText: string;
  onSubmit: () => void;
  onCancel?: () => void;
  onEscapeKeyDown?: () => void;
  confirmText?: string;
} & RadixAlertDialog.AlertDialogContentProps;

export const AlertDialog: React.FC<Props> = ({
  open,
  trigger,
  title,
  description,
  buttonText,
  onSubmit,
  onCancel,
  confirmText,
  ...props
}) => {
  const { t } = useTranslation('alertDialog');
  const [value, onChangeValue, , setValue] = useInput('', () => null);

  return (
    <RadixAlertDialog.Root
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          setValue('');
        }
      }}
    >
      <RadixAlertDialog.Trigger asChild>{trigger}</RadixAlertDialog.Trigger>
      <RadixAlertDialog.Portal>
        <RadixAlertDialog.Content
          {...props}
          className={cx(styles.container, props.className)}
          // デフォルトだとRadixAlertDialog.Cancelにフォーカスが当たるので、フォーカスを制御する
          onOpenAutoFocus={(e) => {
            props.onOpenAutoFocus?.(e);
            e.preventDefault();
          }}
        >
          {title && <RadixAlertDialog.Title>{title}</RadixAlertDialog.Title>}
          <RadixAlertDialog.Description className={styles.description}>
            {description}
          </RadixAlertDialog.Description>
          {confirmText && (
            <Input
              value={value}
              onChange={onChangeValue}
              placeholder={confirmText}
              autoFocus
            />
          )}
          <div className={styles.actions}>
            <RadixAlertDialog.Action asChild>
              <Button
                type="danger"
                size="auto"
                value={buttonText}
                onClick={onSubmit}
                disabled={confirmText ? value !== confirmText : false}
                autoFocus
              />
            </RadixAlertDialog.Action>
            <RadixAlertDialog.Cancel
              className={styles.cancelButton}
              onClick={onCancel}
            >
              {t('Cancel')}
            </RadixAlertDialog.Cancel>
          </div>
        </RadixAlertDialog.Content>
      </RadixAlertDialog.Portal>
    </RadixAlertDialog.Root>
  );
};
