import type { MigrateApi } from '@/entity/api';
import type { ReviewRequestEvent } from '@/entity/review';
import { parseHistoryVersion } from '@/util/history-version';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatDateToMinutes } from '../../util/date';
import Linkify from '../Linkify';
import Member from '../Member';
import IconButton from '../ui/IconButton';
import styles from './event.module.css';

type Props = {
  event: ReviewRequestEvent;
  api: MigrateApi | null | undefined;
  userDefinedContentId: string | undefined;
  hasAllUpdatePermission: boolean;
  deleteComment: (eventId: string) => void;
  deleteCommentLoading: boolean;
  publishMessage: string;
};

const Event: React.FC<Props> = ({
  event,
  api,
  userDefinedContentId,
  hasAllUpdatePermission,
  deleteComment,
  deleteCommentLoading,
  publishMessage,
}) => {
  const { t } = useTranslation('event');

  const date = useMemo(
    () => dayjs(event.createdAt).fromNow(),
    [event.createdAt],
  );

  if (event.type === 'COMMENT') {
    return (
      <li key={event.eventId}>
        <div className={styles.event}>
          <span className={styles.icon}>
            <i className="material-icons-outlined">chat_bubble_outline</i>
          </span>
          <Member username={event.authorId} theme="black" />
          <p className={styles.detail}>
            {t('Comment')}
            <span className={styles.date}>・{date}</span>
          </p>
        </div>
        <Linkify>
          <p className={styles.comment} data-testid="review-comment">
            {event.comment}
            {hasAllUpdatePermission && (
              <IconButton
                icon="clear"
                className={styles.removeButton}
                aria-label={t('Deleted review comment')}
                onClick={() => deleteComment(event.eventId)}
                disabled={deleteCommentLoading}
              />
            )}
          </p>
        </Linkify>
      </li>
    );
  }
  if (event.type === 'UPDATE_TITLE') {
    return (
      <li key={event.eventId} className={styles.event}>
        <span className={styles.icon}>
          <i className="material-icons-outlined">refresh</i>
        </span>
        <Member username={event.authorId} theme="black" />
        <p className={styles.detail}>
          {t('Changed title:')} {event.oldTitle} → {event.newTitle}
          <span className={styles.date}>・{date}</span>
        </p>
      </li>
    );
  }
  if (event.type === 'UPDATE_RESERVATIONTIME') {
    const scheduleTime = event?.reservationTime || event?.reservationStopTime;

    return (
      <li key={event.eventId} className={styles.event}>
        <span className={styles.icon}>
          <i className="material-icons-outlined">schedule</i>
        </span>
        <Member username={event.authorId} theme="black" />
        <p className={styles.detail}>
          {t('Set schedule:')}
          {scheduleTime ? formatDateToMinutes(scheduleTime) : t('Cleared')}
          <span className={styles.date}>・{date}</span>
        </p>
      </li>
    );
  }
  if (event.type === 'UPDATE_CONTENT') {
    const version = parseHistoryVersion(event.historyVersion);

    return (
      <li key={event.eventId} className={styles.event}>
        <span className={styles.icon}>
          <i className="material-icons-outlined">refresh</i>
        </span>
        <Member username={event.authorId} theme="black" />

        <p className={styles.detail}>
          {api && userDefinedContentId ? (
            <Link
              to={`/apis/${api?.apiEndpoint}/${userDefinedContentId}/compare/${
                version - 1
              }...${version}`}
              className={cx(styles.link)}
              title={t('Show content differences.')}
            >
              {t('Updated content: {{sinceVersion}} to {{untilVersion}}', {
                sinceVersion: `v${version - 1}`,
                untilVersion: `v${version}`,
              })}
            </Link>
          ) : (
            <span>
              {t('Updated content: {{sinceVersion}} to {{untilVersion}}', {
                sinceVersion: `v${version - 1}`,
                untilVersion: `v${version}`,
              })}
            </span>
          )}
          <span className={styles.date}>・{date}</span>
        </p>
      </li>
    );
  }
  if (event.type === 'UPDATE_STATUS') {
    return (
      <li key={event.eventId} className={styles.event}>
        <span className={styles.icon}>
          <i className="material-icons-outlined">refresh</i>
        </span>
        <Member username={event.authorId} theme="black" />
        <p className={styles.detail}>
          {t('Changed status:')}{' '}
          {event.newStatus === 'OPEN' ? t('Re-requested') : t('Unpublished')}
          <span className={styles.date}>・{date}</span>
        </p>
      </li>
    );
  }
  if (event.type === 'PUBLISH_CONTENT') {
    return (
      <li key={event.eventId} className={styles.event}>
        <span className={cx(styles.icon, styles.success)}>
          <i className="material-icons-outlined">check</i>
        </span>
        <Member username={event.authorId} theme="black" />
        <p className={styles.detail}>
          {publishMessage}
          <span className={styles.date}>・{date}</span>
        </p>
      </li>
    );
  }
  if (event.type === 'ADD_REVIEWER') {
    return (
      <li key={event.eventId} className={styles.event}>
        <span className={styles.icon}>
          <i className="material-icons-outlined">person_add</i>
        </span>
        <Member username={event.authorId} theme="black" />
        <p className={styles.detail}>
          {t('Added reviewer:')}{' '}
          <Member username={event.reviewerId} nameOnly={true} />
          <span className={styles.date}>・{date}</span>
        </p>
      </li>
    );
  }
  if (event.type === 'REMOVE_REVIEWER') {
    return (
      <li key={event.eventId} className={styles.event}>
        <span className={styles.icon}>
          <i className="material-icons-outlined">person_remove</i>
        </span>
        <Member username={event.authorId} theme="black" />
        <p className={styles.detail}>
          {t('Deleted reviewer:')}{' '}
          <Member username={event.reviewerId} nameOnly={true} />
          <span className={styles.date}>・{date}</span>
        </p>
      </li>
    );
  }
  return null;
};

export default Event;
