export type SpecialKeys =
  | 'contentStatus'
  | 'createdAt'
  | 'updatedAt'
  | 'publishedAt'
  | 'revisedAt'
  | 'authorId'
  | 'editorId';

export const SPECIAL_KEYS: SpecialKeys[] = [
  'contentStatus',
  'createdAt',
  'updatedAt',
  'publishedAt',
  'revisedAt',
  'authorId',
  'editorId',
];

export const VALID_KINDS = [
  'contentStatus',
  'text',
  'textArea',
  'date',
  'boolean',
  'number',
  'select',
  'relation',
  'relationList',
];

/*
  インデックスにちゃんと型をつけようとした形跡
  type Index =
  | 'contentStatus'
  | 'authorId'
  | 'editorId'
  | 'text'
  | 'textArea'
  | 'date'
  | 'boolean'
  | 'number'
  | 'select'
  | 'relation'
  | 'relationList';

  type ConditionIndex = { [k in Index]: string[] };
*/

// TODO:fix/any
export const CONDITIONS: any = {
  contentStatus: ['[equals]'],
  authorId: ['[equals]'],
  editorId: ['[equals]'],
  text: ['[contains]', '[equals]'],
  textArea: ['[contains]', '[equals]'],
  date: ['[less_than]', '[greater_than]'],
  boolean: ['[equals]'],
  number: ['[contains]', '[equals]', '[less_than]', '[greater_than]'],
  select: ['[contains]'],
  relation: ['[equals]'],
  relationList: ['[contains]'],
};
