import { useCallback, useMemo } from 'react';

import type { Service } from '@/types/services';

import { useDeleteAllMedia } from '@/views/Common/medium/useMediumWriter';
import { useTopicValues } from '@/views/Common/providers/momento';

export const useDeleteSettings = (
  partitionKey: Service['partitionKey'],
  serviceName: Service['serviceName'],
) => {
  const { deleteAllMedia, deleteAllMediaLoading } = useDeleteAllMedia({
    serviceId: partitionKey,
    serviceName: serviceName,
  });

  const onDeleteAllMedia = useCallback(() => {
    deleteAllMedia();
  }, [deleteAllMedia]);

  const topicValues = useTopicValues('deleteAllMedia');
  const isRunningDeleteAllMedia = useMemo(() => {
    if (topicValues.length === 0) {
      return false;
    }

    const topicValuesOfServiceId = topicValues.filter(
      (topicValue) => topicValue.serviceId === partitionKey,
    );

    if (topicValuesOfServiceId.length === 0) {
      return false;
    }

    const latestTopicValue = topicValuesOfServiceId.at(-1);

    // deleteAllMediaがRUNNINGの場合は削除ボタンを非活性にする
    return latestTopicValue?.status === 'RUNNING';
  }, [topicValues, partitionKey]);

  return {
    onDeleteAllMedia,
    deleteAllMediaLoading,
    isRunningDeleteAllMedia,
  };
};
