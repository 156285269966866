import React from 'react';

import styles from './errorfield.module.css';

const Errorfield = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <svg
        width="20px"
        height="18px"
        viewBox="0 0 20 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <path
            d="M3,18 C6.40441194,18 11.2575765,18 17.5594936,18 C19,18 20,16 19.3544074,14.8282212 C16.7261627,10.0578419 14.3449845,5.70055461 12,2 C11,0 9,0 8,2 C7.56529541,2.75085339 5.17352683,7.00032602 0.824694269,14.7484179 C9.20374887e-14,16 1,18 3,18 Z M11,15 L9,15 L9,13 L11,13 L11,15 Z M11,11 L9,11 L9,7 L11,7 L11,11 Z"
            id="path-error"
          ></path>
        </defs>
        <g transform="translate(-1205.000000, -141.000000)">
          <g id="ic/warning" transform="translate(1203.000000, 138.000000)">
            <g transform="translate(2.000000, 3.000000)">
              <mask id="mask-error" fill="white">
                <use xlinkHref="#path-error"></use>
              </mask>
              <g id="c/error" mask="url(#mask-error)" fill="#FA3A4B">
                <g transform="translate(-2.000000, -3.000000)" id="Shape">
                  <rect x="0" y="0" width="24" height="24"></rect>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Errorfield;
