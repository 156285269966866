import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useInput } from '../../../hooks';
import { useConfirmMfa } from '../../../hooks/Auth/useAuth';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import Footer from '../../ui/Footer';
import Textfield from '../../ui/Textfield';
import { validateMfaCode } from '../../Validations';

import styles from './multiFactorAuth.module.css';

type Props = {
  tempUser: any;
};

const MultiFactorAuth: React.FC<Props> = ({ tempUser }) => {
  const { t } = useTranslation('multiFactorAuth');
  const { confirmMfa, confirmMfaLoading } = useConfirmMfa();
  const [code, onChangeCode, codeError] = useInput('', validateMfaCode);

  const submit = useCallback(async () => {
    confirmMfa({ tempUser, code });
  }, [confirmMfa, tempUser, code]);

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <Link to="/" className={styles.logo}>
          <img
            className={styles.logoImg}
            src="/images/logo_black.svg"
            alt="microCMS"
          />
        </Link>
      </header>
      <div className={styles.container}>
        <h2 className={styles.title}>
          {t('Enter Two-Factor authentication code')}
        </h2>
        <p className={styles.description}>
          {t('Enter the number displayed on the authentication application.')}
        </p>
        <div className={styles.formBox}>
          <Fieldset legend={t('Two-Factor authentication code')}>
            <Textfield
              type="text"
              value={code}
              placeholder="123456"
              onChange={onChangeCode}
              errorText={codeError}
            />
          </Fieldset>
          <div className={styles.actions}>
            <Button
              type="secondary"
              value={t('Verify')}
              size="full"
              disabled={codeError !== null || confirmMfaLoading}
              onClick={submit}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MultiFactorAuth;
