import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './selectfield.module.css';

type Props = {
  className?: string;
  children?: React.ReactNode;
  placeholder?: {
    value: string;
    name: string;
  };
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onFocus?: () => void;
  onMouseOver?: () => void;
  style?: React.CSSProperties;
  size?: 'small' | 'medium' | 'large' | 'full';
  name?: string;
  value?: string | string[];
  defaultValue?: string;
  disabled?: boolean;
  multiple?: boolean;
  errorText?: string;
  label?: string;
};

const Selectfield: React.FC<Props> = ({
  className,
  children,
  placeholder,
  onChange = () => null,
  onFocus = () => null,
  onMouseOver = () => null,
  style,
  size = 'medium',
  name,
  value,
  defaultValue,
  disabled,
  multiple,
  errorText = null,
  label,
}) => {
  const { t } = useTranslation('selectfield');
  return (
    <div className={className}>
      <select
        className={cx(
          styles[size],
          errorText !== null
            ? `${styles.input} ${styles.hasError}`
            : styles.input,
        )}
        aria-label={label}
        onChange={onChange}
        onFocus={onFocus}
        onMouseOver={onMouseOver}
        style={style}
        name={name}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        multiple={multiple}
      >
        {placeholder !== undefined && !multiple && (
          <option value={placeholder.value}>{placeholder.name}</option>
        )}
        {children}
      </select>
      {errorText !== null && <p className={styles.errorText}>{errorText}</p>}
      {multiple && (
        <p className={styles.note}>
          {t(
            'Multiple selections can be made by clicking while holding down the Ctrl, Command or Shift key.',
          )}
        </p>
      )}
    </div>
  );
};

export default Selectfield;
export type { Props as SelectfieldProps };
