import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';

import CreateReview from '../CreateReview';
import EditReview from '../EditReview';
import Main from '../Main';
import PrivateRoute from '../PrivateRoute';
import ReviewList from '../ReviewList';
import HelpButton from '../ui/HelpButton';

import styles from './reviewsTop.module.css';

const ReviewsTop = () => {
  const { t } = useTranslation('reviewsTop');

  return (
    <Main
      header={
        <header className={styles.header}>
          <div className={styles.tabs}>
            <div className={styles.tabTitle}>
              <h2 className={styles.title}>{t('Manage Reviews')}</h2>
              <HelpButton
                type="manual"
                id="review"
                className="ga-click-tooltip-review"
              />
            </div>
          </div>
        </header>
      }
    >
      <Switch>
        <PrivateRoute exact path="/reviews" component={ReviewList} />
        <PrivateRoute
          exact
          path="/reviews/create/:contentId"
          component={CreateReview}
        />
        <PrivateRoute exact path="/reviews/:reqId" component={EditReview} />
      </Switch>
    </Main>
  );
};

export default ReviewsTop;
