import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { LinkButton } from '@/components/Form';
import Button from '@/components/ui/Button';
import CopyButton from '@/components/ui/CopyButton';
import Fieldset from '@/components/ui/Fieldset';
import HelpButton from '@/components/ui/HelpButton';
import Loading from '@/components/ui/Loading';
import Modal from '@/components/ui/Modal';
import Selectfield from '@/components/ui/Selectfield';
import Switch from '@/components/ui/Switch';
import Textarea from '@/components/ui/Textarea';
import Textfield from '@/components/ui/Textfield';

import { useGetMyService } from '@/hooks/useService';

import { useSAML } from './useSAML';

import type { Role } from '@/entity/role';
import type { SAMLConfiguration } from '@/entity/saml';
import type { Service } from '@/types/services';
import type { Dispatch, SetStateAction } from 'react';

import styles from './samlSettings.module.css';

import { ReadOnlyTextFieldWithCopyButton } from '@/views/Common/ServiceSettings/SAMLSettings/ReadOnlyTextFieldWithCopyButton';
import { Upsell } from '@/views/Common/plan/Upsell';

type ViewProps = {
  samlConfiguration?: SAMLConfiguration;
  allRoles: Role[];
  Auth0Modal: React.FC<{
    children: React.ReactNode;
  }>;
  onClickConfigureWithAuth0: () => void;
  onChangeIdpMetadataURL: (
    e?: React.ChangeEvent<HTMLInputElement> | undefined,
  ) => void;
  idpMetadataURLError: string | null;
  setDefaultRoleId: Dispatch<SetStateAction<string | undefined>>;
  onChangeIdpMetadataXML: (
    e?: React.ChangeEvent<HTMLTextAreaElement> | undefined,
  ) => void;
  idpMetadataXMLError: string | null;
  isSAMLOnly: boolean;
  setSAMLOnly: Dispatch<SetStateAction<boolean>>;
  onClickSaveButton: () => void;
  onClickDeleteButton: () => void;
  isReadOnlyExceptAdmin: boolean;
  isDeleteOnly: boolean;
  isAdmin: boolean;
  shouldShowLoginURL: boolean;
  isUpdating: boolean;
  isSubmitButtonDisabled: boolean;
  isLoading: boolean;
  shouldShowURLField: boolean;
  setShouldShowURLField: Dispatch<SetStateAction<boolean>>;
  inputtingIdpMetadataURL: string;
  inputtingIdpMetadataXML: string;
};

const SAMLSettingsView: React.FC<ViewProps> = ({
  samlConfiguration,
  allRoles,
  Auth0Modal,
  onClickConfigureWithAuth0,
  onChangeIdpMetadataURL,
  idpMetadataURLError,
  setDefaultRoleId,
  onChangeIdpMetadataXML,
  idpMetadataXMLError,
  isSAMLOnly,
  setSAMLOnly,
  onClickSaveButton,
  onClickDeleteButton,
  isReadOnlyExceptAdmin,
  isDeleteOnly,
  isAdmin,
  shouldShowLoginURL,
  isUpdating,
  isSubmitButtonDisabled,
  isLoading,
  shouldShowURLField,
  setShouldShowURLField,
  inputtingIdpMetadataURL,
  inputtingIdpMetadataXML,
}: ViewProps) => {
  const { t } = useTranslation('serviceSAMLSettings');

  if (isLoading || !samlConfiguration) {
    return <Loading />;
  }

  return (
    <Upsell restrictionKey="enableSAML" samlSetting={samlConfiguration}>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <h2 className={styles.title}>SAML</h2>
          <HelpButton type="manual" id="saml-auth0" />
        </div>
        <h3 className={styles.sectionTitle}>
          {t('Configure identity provider with following microCMS properties')}
        </h3>

        <LinkButton
          value={t('Configure with Auth0')}
          onClick={onClickConfigureWithAuth0}
          style={{ marginTop: 24 }}
        />

        <Auth0Modal>
          <Modal title={t('Configure with Auth0 title')} type="medium">
            <p className={styles.authSettingsModalDescription}>
              {t(
                'Copy the JSON below and paste it into the SAML2 Web App of Auth0',
              )}
            </p>
            <ReadOnlyTextFieldWithCopyButton
              value={JSON.stringify(
                samlConfiguration.serviceProviderInfo.settings,
              )}
            />
          </Modal>
        </Auth0Modal>

        <Fieldset
          legend={t('Service Provider Callback URL')}
          description={t('Service Provider Callback URL description')}
        >
          <ReadOnlyTextFieldWithCopyButton
            value={samlConfiguration.serviceProviderInfo.applicationCallbackURL}
          />
        </Fieldset>

        <Fieldset
          legend={t('Entity ID')}
          description={t('Entity ID description')}
        >
          <ReadOnlyTextFieldWithCopyButton
            value={samlConfiguration.serviceProviderInfo.settings.audience}
          />
        </Fieldset>

        <Fieldset legend={t('Name ID Format')}>
          <ReadOnlyTextFieldWithCopyButton
            value={
              samlConfiguration.serviceProviderInfo.settings
                .nameIdentifierFormat
            }
          />
        </Fieldset>

        <h4 className={styles.subtitle}>Attribute</h4>

        <div className={styles.attributeList}>
          <Fieldset legend={'email:'} className={styles.attributeItem}>
            <ReadOnlyTextFieldWithCopyButton
              value={
                samlConfiguration.serviceProviderInfo.settings.mappings.email
              }
            />
          </Fieldset>

          <Fieldset legend={'name:'} className={styles.attributeItem}>
            <ReadOnlyTextFieldWithCopyButton
              value={
                samlConfiguration.serviceProviderInfo.settings.mappings.name
              }
            />
          </Fieldset>

          <Fieldset legend={'picture:'} className={styles.attributeItem}>
            <ReadOnlyTextFieldWithCopyButton
              value={
                samlConfiguration.serviceProviderInfo.settings.mappings.picture
              }
            />
          </Fieldset>
        </div>

        <h3 className={styles.sectionTitle}>
          {t('Configure microCMS with identity provider properties')}
        </h3>

        <Fieldset
          legend={t('Metadata')}
          description={t(
            'Paste the URL or XML code obtained from your Id provider',
          )}
        />

        <div className={styles.toggleButtonList}>
          <button
            type="button"
            className={cx(
              styles.toggleButton,
              shouldShowURLField && styles.isActive,
            )}
            onClick={() => setShouldShowURLField(true)}
          >
            URL
          </button>
          <button
            type="button"
            className={cx(
              styles.toggleButton,
              !shouldShowURLField && styles.isActive,
            )}
            onClick={() => setShouldShowURLField(false)}
          >
            XML
          </button>
        </div>

        {shouldShowURLField ? (
          <Textfield
            type="text"
            value={inputtingIdpMetadataURL}
            onChange={onChangeIdpMetadataURL}
            errorText={idpMetadataURLError}
            readOnly={isReadOnlyExceptAdmin}
            className={styles.metadataInputField}
            dataTestid="input-idp-metadata-url"
          />
        ) : (
          <Textarea
            value={inputtingIdpMetadataXML}
            onChange={onChangeIdpMetadataXML}
            errorText={idpMetadataXMLError}
            readOnly={isReadOnlyExceptAdmin}
            className={styles.metadataInputField}
          />
        )}

        <Fieldset
          legend={t('Default Roles')}
          description={t(
            'Select the role that will be granted to the user who logs into microCMS for the first time via SAML integration.',
          )}
        >
          <Selectfield
            onChange={(e) => {
              setDefaultRoleId(e.target.value);
            }}
            defaultValue={samlConfiguration.defaultRoleId}
            disabled={isDeleteOnly}
          >
            {allRoles.map((role: any) => (
              <option key={role.roleId} value={role.roleId}>
                {role.name}
              </option>
            ))}
          </Selectfield>
        </Fieldset>

        {shouldShowLoginURL && (
          <>
            <Fieldset
              legend={t('Mandatory')}
              description={t(
                'Users outside of SAML management will not be able to log in.',
              )}
            >
              <Switch
                size="large"
                onChange={setSAMLOnly}
                on={isSAMLOnly}
                disabled={isReadOnlyExceptAdmin}
                dataTestid="saml-only-switch-button"
              />
            </Fieldset>

            {samlConfiguration.loginURL && (
              <Fieldset
                legend={t('Login URL')}
                description={t('Please use the following URL for SAML login.')}
              >
                <div className={styles.login}>
                  <a
                    href={samlConfiguration.loginURL}
                    data-testid="saml-login-url"
                  >
                    {samlConfiguration.loginURL}
                  </a>
                  <CopyButton
                    value={samlConfiguration.loginURL}
                    className={styles.loginURLCopyButton}
                  />
                </div>
              </Fieldset>
            )}
          </>
        )}

        {isAdmin && (
          <div className={styles.actions}>
            <Button
              value={t('Save changes')}
              onClick={onClickSaveButton}
              className={styles.submit}
              disabled={isSubmitButtonDisabled}
            />
            {samlConfiguration.loginURL && (
              <Button
                value={t('Delete')}
                type="danger"
                onClick={onClickDeleteButton}
                disabled={isUpdating}
              />
            )}
          </div>
        )}
      </div>
    </Upsell>
  );
};

export const SAMLSettings: React.FC = () => {
  const { service } = useGetMyService();
  if (!service) {
    return <Loading>Loading...</Loading>;
  }

  return <SAMLSettingsContent service={service} />;
};

export const SAMLSettingsContent: React.FC<{ service: Service }> = ({
  service,
}) => {
  const {
    Auth0Modal,
    onClickConfigureWithAuth0,
    samlConfiguration,
    allRoles,
    onChangeIdpMetadataURL,
    idpMetadataURLError,
    setDefaultRoleId,
    onChangeIdpMetadataXML,
    idpMetadataXMLError,
    isSAMLOnly,
    setSAMLOnly,
    onClickSaveButton,
    onClickDeleteButton,
    isReadOnlyExceptAdmin,
    isDeleteOnly,
    isAdmin,
    shouldShowLoginURL,
    isUpdating,
    isSubmitButtonDisabled,
    isLoading,
    shouldShowURLField,
    setShouldShowURLField,
    inputtingIdpMetadataURL,
    inputtingIdpMetadataXML,
  } = useSAML(service);

  return (
    <SAMLSettingsView
      samlConfiguration={samlConfiguration}
      allRoles={allRoles}
      Auth0Modal={Auth0Modal}
      onClickConfigureWithAuth0={onClickConfigureWithAuth0}
      onChangeIdpMetadataURL={onChangeIdpMetadataURL}
      idpMetadataURLError={idpMetadataURLError}
      setDefaultRoleId={setDefaultRoleId}
      onChangeIdpMetadataXML={onChangeIdpMetadataXML}
      idpMetadataXMLError={idpMetadataXMLError}
      isSAMLOnly={isSAMLOnly}
      setSAMLOnly={setSAMLOnly}
      onClickSaveButton={onClickSaveButton}
      onClickDeleteButton={onClickDeleteButton}
      isReadOnlyExceptAdmin={isReadOnlyExceptAdmin}
      isDeleteOnly={isDeleteOnly}
      isAdmin={isAdmin}
      shouldShowLoginURL={shouldShowLoginURL}
      isUpdating={isUpdating}
      isSubmitButtonDisabled={isSubmitButtonDisabled}
      isLoading={isLoading}
      shouldShowURLField={shouldShowURLField}
      setShouldShowURLField={setShouldShowURLField}
      inputtingIdpMetadataURL={inputtingIdpMetadataURL}
      inputtingIdpMetadataXML={inputtingIdpMetadataXML}
    />
  );
};
