import { getRoleInput } from '../../helpers/convRoleInputOutput';

import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from './_types';
import type { Role } from '@/entity/role';

import {
  highestLevelPermission,
  lowestLevelPermission,
} from '@/constants/role';

type ReplacementWithRoleAction = FormRoleInputValueAction<
  'replacement-with-role',
  Role
>;
type SetHighestLevelPermissionAction = FormRoleInputValueAction<
  'set-highest-level-permission',
  undefined
>;
type SetLowestLevelPermissionAction = FormRoleInputValueAction<
  'set-lowest-level-permission',
  undefined
>;
type ReplacementAction =
  | ReplacementWithRoleAction
  | SetHighestLevelPermissionAction
  | SetLowestLevelPermissionAction;

/**
 * Roleを元に入力値を設定する
 *
 * ※ すでに入力値が設定されている場合はRoleの内容で上書きされる
 */
const replacementWithRole: FormRoleInputValueDispatchFn<
  ReplacementWithRoleAction
> = (_currentState, payload) => {
  return getRoleInput(payload);
};

/**
 * 全ての権限付与
 */
const setHighestLevelPermission: FormRoleInputValueDispatchFn<
  SetHighestLevelPermissionAction
> = (currentState) => {
  return { ...currentState, ...highestLevelPermission };
};

/**
 * 全ての権限解除
 */
const setLowestLevelPermission: FormRoleInputValueDispatchFn<
  SetLowestLevelPermissionAction
> = (currentState) => {
  return { ...currentState, ...lowestLevelPermission };
};

export {
  type ReplacementAction,
  replacementWithRole,
  setHighestLevelPermission,
  setLowestLevelPermission,
};
