import { reviewsRepository } from '@/repository/reviewsRepository';

export const createReview = async ({
  contentId,
  title,
  description,
}: {
  contentId: string;
  title: string;
  description?: string;
}) => {
  const { createReview } = reviewsRepository();
  return await createReview({ contentId, title, description });
};
