import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './footer.module.css';

type Props = {
  text?: string;
};

const Footer: React.FC<Props> = ({ text }) => {
  const { t } = useTranslation('footer');
  const year = useMemo(() => dayjs().format('YYYY'), []);
  return (
    <footer className={styles.wrapper}>
      <ul className={styles.lists}>
        <li className={styles.list}>
          <a
            href="https://microcms.co.jp"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Operating Company')}
          </a>
        </li>
        <li className={styles.list}>
          <a
            href="https://microcms.io/pricing"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Pricing')}
          </a>
        </li>
        <li className={styles.list}>
          <a
            href="https://microcms.io/law"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t(
              'Notation based on the Act on Specified Commercial Transactions',
            )}
          </a>
        </li>
        <li className={styles.list}>
          <a
            href="https://microcms.io/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Terms of service')}
          </a>
        </li>
        <li className={styles.list}>
          <a
            href="https://microcms.io/policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('Privacy Policy')}
          </a>
        </li>
        <li className={styles.list}>
          <a href="https://microcms.io/contact">{t('Contact')}</a>
        </li>
      </ul>
      <p className={styles.cr}>
        Copyright © {year} microCMS Corp. All rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
