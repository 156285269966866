type IconProps = {
  className?: string;
};

export const CodeCommitIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="136"
    height="92"
    viewBox="0 0 136 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
  >
    <g clipPath="url(#clip0_11971_28728)">
      <path
        d="M64 0V38C64 38.8655 64.2807 39.7076 64.8 40.4L69 46L64.8 51.6C64.2807 52.2924 64 53.1345 64 54V92H4C1.79086 92 0 90.2091 0 88V4C0 1.79086 1.79086 0 4 0H64ZM18 24C18 25.1046 18.8954 26 20 26H44C45.1046 26 46 25.1046 46 24V20C46 18.8954 45.1046 18 44 18H20C18.8954 18 18 18.8954 18 20V24ZM26 40C26 41.1046 26.8954 42 28 42H52C53.1046 42 54 41.1046 54 40V36C54 34.8954 53.1046 34 52 34H28C26.8954 34 26 34.8954 26 36V40ZM26 72C26 73.1046 26.8954 74 28 74H52C53.1046 74 54 73.1046 54 72V68C54 66.8954 53.1046 66 52 66H28C26.8954 66 26 66.8954 26 68V72ZM34 56C34 57.1046 34.8954 58 36 58H52C53.1046 58 54 57.1046 54 56V52C54 50.8954 53.1046 50 52 50H36C34.8954 50 34 50.8954 34 52V56Z"
        fill="#F1F3F8"
      />
      <path
        d="M72 0H132C134.209 0 136 1.79086 136 4V88C136 90.2091 134.209 92 132 92H72V55.3334L77.2 48.4C78.2667 46.9778 78.2667 45.0222 77.2 43.6L72 36.6666V0ZM80 24C80 25.1046 80.8954 26 82 26H106C107.105 26 108 25.1046 108 24V20C108 18.8954 107.105 18 106 18H82C80.8954 18 80 18.8954 80 20V24ZM88 40C88 41.1046 88.8954 42 90 42H114C115.105 42 116 41.1046 116 40V36C116 34.8954 115.105 34 114 34H90C88.8954 34 88 34.8954 88 36V40ZM88 72C88 73.1046 88.8954 74 90 74H114C115.105 74 116 73.1046 116 72V68C116 66.8954 115.105 66 114 66H90C88.8954 66 88 66.8954 88 68V72ZM96 56C96 57.1046 96.8954 58 98 58H114C115.105 58 116 57.1046 116 56V52C116 50.8954 115.105 50 114 50H98C96.8954 50 96 50.8954 96 52V56Z"
        fill="#DEDEEB"
      />
    </g>
    <defs>
      <clipPath id="clip0_11971_28728">
        <rect width="136" height="92" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
