import { useQuery } from '@tanstack/react-query';
import * as cheerio from 'cheerio';
import hljs from 'highlight.js';
import 'highlight.js/styles/hybrid.css';
import { useTranslation } from 'react-i18next';

import { MICROCMS_DOCS_API_KEY } from '../../util';

interface image {
  url: string;
}

interface customField {
  fieldId: string;
  url: string;
  ja: string | undefined;
  en: string | undefined;
  richText: string;
  note: string;
  image: image;
  params: string;
}

export const useDocument = (type: string, contentId: string) => {
  const { t } = useTranslation('hooksDocument');
  const getDocumentQuery = useQuery({
    queryKey: ['document', contentId],
    queryFn: () => {
      const endpoint = `https://microcms-docs.microcms.io/api/v1/${type}/${contentId}?fields=id,title,body`;
      return fetch(endpoint, {
        headers: { 'X-MICROCMS-API-KEY': MICROCMS_DOCS_API_KEY },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(t('Information could not be retrieved'));
          }
          return res.json();
        })
        .then(async (res) => {
          const body = await Promise.all(
            (res.body || []).map(async (item: customField) => {
              if (item.fieldId === 'richText') {
                const $ = cheerio.load(item.ja || '');
                $('pre code').each((_, elm) => {
                  const res = hljs.highlightAuto($(elm).text());
                  $(elm).html(res.value);
                  $(elm).addClass('hljs');
                });
                return {
                  ...item,
                  richText: $.html(),
                };
              }
              return item;
            }),
          );
          return {
            ...res,
            body,
          };
        });
    },
    staleTime: Number.POSITIVE_INFINITY,
  });

  const { data: document, error: documentError, isLoading } = getDocumentQuery;

  return {
    document,
    documentError,
    isLoading,
  };
};
