import { API, graphqlOperation } from 'aws-amplify';

import { loadAmplifyAuthConfig } from '../constants/localStorage';
import { MetaContext } from '../context/MetaContext';
import * as queries from '../graphql/queries';
import { getTranslation } from '../i18n'; // app.microcms.io が管理画面の基本ドメイン

export const APP_DEFAULT_DOMAIN = 'app';

export const MICROCMS_API_KEY = '1c801446-5d12-4076-aba6-da78999af9a8'; // microcms.microcms.ioのAPI-Key // TODO: 平文管理しない
export const MICROCMS_STG_API_KEY = '234978b1-f5a6-4f29-8b17-5def6653ef65'; // microcms.microcms.ioのAPI-Key // TODO: 平文管理しない
export const MICROCMS_DOCS_API_KEY = 'e87749e0d7404a6eba3a202dae9fa841227f'; // microcms-docs.microcms.ioのAPI-Key // TODO: 平文管理しない

export const getHost = (serviceName = '') => {
  const loc = window.location;
  const baseHost = getPureHost();
  const portStr = `${loc.port && ':'}${loc.port}`;
  return isWildcardDomain()
    ? `${loc.protocol}//${serviceName}.${baseHost}${portStr}`
    : `${loc.protocol}//${baseHost}${portStr}/?domain=${serviceName}`;
};

export const getDefaultHost = () => {
  const loc = window.location;
  const baseHost = getPureHost();
  const portStr = `${loc.port && ':'}${loc.port}`;
  return `${loc.protocol}//${APP_DEFAULT_DOMAIN}.${baseHost}${portStr}`;
};

export const getPureHost = () => {
  const hostname = window.location.hostname;
  return isWildcardDomain() ? hostname.split('.').slice(1).join('.') : hostname;
};

const isWildcardDomain = () => {
  return !window.location.hostname.endsWith('cloudfront.net');
};

export const getServiceDomain = () => {
  const originHost = window.location.hostname;
  const pureHost = getPureHost();
  const subDomain = originHost.replace(pureHost, '').replace('.', '');
  return subDomain === APP_DEFAULT_DOMAIN ? '' : subDomain;
};

export const getApiHost = () => {
  const domain = getServiceDomain();
  const clientHost = getPureHost();
  return clientHost === 'dev.local'
    ? `http://${domain}.dev.local:3000`
    : `https://${domain}.${clientHost}`;
};

export const getManagementApiHost = () => {
  const domain = getServiceDomain();
  let clientHost = getPureHost();

  const replacements = {
    'microcms-staging.net': 'microcms-staging-management',
    'microcms-deva.net': 'microcms-deva-management',
    default: 'microcms-management',
  };

  const replacement = replacements[clientHost] || replacements['default'];
  clientHost = clientHost.replace(/microcms([^.]*)/, replacement);

  return clientHost === 'dev.local'
    ? `http://${domain}.dev-management.local:9002`
    : `https://${domain}.${clientHost}`;
};

export const getSSOHost = () => {
  const clientHost = getPureHost().replace(/microcms([^.]*)/, 'microcms$1-sso');
  return clientHost === 'dev.local'
    ? `http://app.dev.local:8000`
    : `https://${clientHost}`;
};

export const getResourceUrl = (
  directory,
  fileName = '',
  queryString = '',
  kind = '',
  customDomainImageHost,
  customDomainFileHost,
) => {
  const host = getMediumHost({
    kind,
    customDomainImageHost,
    customDomainFileHost,
  });

  return new Promise((resolve) =>
    resolve(
      `https://${host}/${directory}/${encodeURIComponent(
        fileName,
      )}${queryString}`,
    ),
  );
};

export const getResourceUrlByMediumId = async (serviceId, mediumId) => {
  const medium = await API.graphql(
    graphqlOperation(queries.findMedium, {
      serviceId,
      mediumId,
    }),
  ).then((result) => result.data.findMedium);

  if (medium === null) {
    return null;
  }

  // サービスの取得
  const domain = getServiceDomain();
  const service = await API.graphql(
    graphqlOperation(queries.findServiceByDomain, {
      domain,
    }),
  ).then((result) => result.data.findServiceByDomain);

  const host = getMediumHost({
    kind: medium.kind,
    customDomainImageHost: service.customDomainImageHost,
  });

  return `https://${host}/${medium.directory}/${encodeURIComponent(
    medium.fileName,
  )}`;
};

export const getMediumHost = ({
  kind,
  customDomainImageHost,
  customDomainFileHost,
}) => {
  const isImage = kind === 'IMAGE';
  const isFile = kind === 'FILE';

  // 画像のカスタムドメインをチェック
  if (isImage && customDomainImageHost) {
    return customDomainImageHost;
  }

  // ファイルのカスタムドメインをチェック
  if (isFile && customDomainFileHost) {
    return customDomainFileHost;
  }

  return isImage ? MetaContext.value.imageHost : MetaContext.value.fileHost;
};

export const isMobile = () => {
  var regexp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return window.navigator.userAgent.search(regexp) !== -1;
};

export const convertToEvent = (event) => {
  const { t } = getTranslation('util');
  if (event === 'CREATE_SERVICE') {
    return t('Create Service');
  } else if (event === 'UPDATE_SERVICE') {
    return t('Update Service');
  } else if (event === 'UPDATE_SERVICE_ID') {
    return t('Update ServiceID');
  } else if (event === 'UPDATE_SERVICE_REQUIRE_MFA') {
    return t('Update Two-Factor authentication');
  } else if (event === 'CREATE_API_KEY') {
    return t('Create API Key');
  } else if (event === 'UPDATE_API_KEY') {
    return t('Update API Key');
  } else if (event === 'DELETE_API_KEY') {
    return t('Delete API Key');
  } else if (event === 'CREATE_API') {
    return t('Create API');
  } else if (event === 'UPDATE_API') {
    return t('Update API');
  } else if (event === 'DELETE_API') {
    return t('Delete API');
  } else if (event === 'UPDATE_API_DESCRIPTION') {
    return t('Update API Description');
  } else if (event === 'UPDATE_CONTENT') {
    return t('Update Content');
  } else if (event === 'UPDATE_CONTENT_RESERVATION') {
    return t('Update Content Reservation');
  } else if (event === 'CREATE_CONTENT') {
    return t('Create Content');
  } else if (event === 'DELETE_DRAFT') {
    return t('Delete Content Draft');
  } else if (event === 'DELETE_CONTENT') {
    return t('Delete Content');
  } else if (event === 'UPDATE_CONTENT_SORT_ORDER') {
    return t('Update Content Order');
  } else if (event === 'UPDATE_DRAFT_KEY') {
    return t('Update DraftKey');
  } else if (event === 'UPDATE_CONTENT_ID') {
    return t('Update ContentID');
  } else if (event === 'UPDATE_PUBLISHED_AT') {
    return t('Update Published Date and Time');
  } else if (event === 'UPDATE_CREATED_USER') {
    return t('Update Created User');
  } else if (event === 'DELETE_ALL_CONTENT') {
    return t('Delete All Contents');
  } else if (event === 'ADD_MEDIUM') {
    return t('Add Media');
  } else if (event === 'DELETE_MEDIUM') {
    return t('Delete Media');
  } else if (event === 'ADD_MEMBER') {
    return t('Add Member');
  } else if (event === 'INVITE_MEMBER') {
    return t('Invite Member');
  } else if (event === 'DELETE_MEMBER') {
    return t('Delete Member');
  } else if (event === 'CANCEL_INVITATION') {
    return t('Cancel Member Invitation');
  } else if (event === 'UPDATE_MEMBER_ROLE') {
    return t('Update Member Role Permissions');
  } else if (event === 'CHANGE_PLAN_TO_STANDARD') {
    return t('Change Plan to Standard');
  } else if (event === 'CHANGE_PLAN_TO_BUSINESS') {
    return t('Change Plan to Business');
  } else if (event === 'CHANGE_PAYMENT_INFO') {
    return t('Add Payment Details');
  } else if (event === 'CHANGE_CARD') {
    return t('Change Credit Card Details');
  } else if (event === 'CHANGE_BILLING_EMAIL') {
    return t('Change Payment Email Address');
  } else if (event === 'CHANGE_BILLING_NAME') {
    return t('Change Payment Contact Name');
  } else if (event === 'CHANGE_BILLING_PHONE') {
    return t('Change Payment Phone Number');
  } else if (event === 'CHANGE_BILLING_COMPANY') {
    return t('Change Payment Company Info');
  } else if (event === 'CANCEL_SUBSCRIPTION') {
    return t('Suspend Subscription');
  } else if (event === 'RESTART_SUBSCRIPTION') {
    return t('Resume Subscription');
  } else if (event === 'ADD_REVIEWER') {
    return t('Add Reviewer');
  } else if (event === 'REMOVE_REVIEWER') {
    return t('Delete Reviewer');
  } else if (event === 'UPDATE_MEDIUM') {
    return t('Update Media');
  } else if (event === 'UPDATE_MEDIUM_NAME') {
    return t('Update Media Name');
  } else if (event === 'UPDATE_MEDIUM_CREATED_BY') {
    return t('Update Media Creator');
  } else if (event === 'DELETE_MEDIUM') {
    return t('Delete Media');
  } else if (event === 'CREATE_ROLE') {
    return t('Create Role');
  } else if (event === 'UPDATE_ROLE') {
    return t('Update Role');
  } else if (event === 'DELETE_ROLE') {
    return t('Delete Role');
  } else if (event === 'CREATE_WEBHOOK_SETTING') {
    return t('Create Webhook');
  } else if (event === 'UPDATE_WEBHOOK_SETTING') {
    return t('Update Webhook');
  } else if (event === 'DELETE_WEBHOOK_SETTING') {
    return t('Delete Webhook');
  } else if (event === 'CREATE_WEBHOOK_MEDIUM_SETTING') {
    return t('Create Media Webhook');
  } else if (event === 'UPDATE_WEBHOOK_MEDIUM_SETTING') {
    return t('Update Media Webhook');
  } else if (event === 'DELETE_WEBHOOK_MEDIUM_SETTING') {
    return t('Delete Media Webhook');
  } else if (event === 'CREATE_CUSTOME_FIELD') {
    return t('Create Custom Field');
  } else if (event === 'UPDATE_CUSTOME_FIELD') {
    return t('Update Custom Field');
  } else if (event === 'DELETE_CUSTOME_FIELD') {
    return t('Delete Custom Field');
  } else if (event === 'CREATE_REVIEW_REQUEST') {
    return t('Create Review Request');
  } else if (event === 'TOGGLE_REVIEW_REQUEST') {
    return t('Update Review Request Status');
  } else if (event === 'UPDATE_REVIEW_REQUEST') {
    return t('Update Review Request');
  } else if (event === 'CLOSE_REVIEW_REQUEST_AND_PUBLISH_CONTENT') {
    return t('Finalize Review Request and Publish Content');
  } else if (event === 'CREATE_REVIEW_REQUEST_COMMENT') {
    return t('Create Review Request Comment');
  } else if (event === 'DELETE_REVIEW_REQUEST_COMMENT') {
    return t('Delete Review Request Comment');
  } else if (event === 'DELETE_CARD') {
    return t('Delete Credit Card');
  } else if (event === 'ADDON_AUDIT_LOG') {
    return t('Add-on Audit Log');
  } else if (event === 'ADDON_API_IP_RESTRICTION') {
    return t('Add-on API IP Restriction');
  } else if (event === 'ADDON_ADDITIONAL_ENVIRONMENTS') {
    return t('Add-ons for additional environments (10 environments)');
  } else if (event === 'CREATE_ENVIRONMENT') {
    return t('Create Environment');
  } else if (event === 'UPDATE_ENVIRONMENT_NAME') {
    return t('Update Environment Name');
  } else if (event === 'DELETE_ENVIRONMENT') {
    return t('Delete Environment');
  } else if (event === 'CHANGE_BILLING_INVOICE') {
    return t('Changed to invoice payment.');
  } else if (event === 'CHANGE_PLAN_TO_TEAM') {
    return t('Change Plan to Team');
  } else if (event === 'CHANGE_PLAN_TO_ADVANCED') {
    return t('Change Plan to Advanced');
  } else if (event === 'CHANGE_PLAN_TO_HOBBY') {
    return t('Change Plan to Hobby');
  } else if (event === 'ADD_CUSTOMSTATUS') {
    return t('Add Custom Status');
  } else if (event === 'UPDATE_CUSTOMSTATUS') {
    return t('Update Custom Status');
  } else if (event === 'DELETE_CUSTOMSTATUS') {
    return t('Delete Custom Status');
  } else if (event === 'UPDATE_CREATED_USER') {
    return t('Update Created User');
  } else if (event === 'UPDATE_SERVICE_LANGUAGE') {
    return t('Update Service Language');
  } else if (event === 'CREATE_MEDIUM_TAG') {
    return t('Create Media Tags');
  } else if (event === 'UPDATE_MEDIUM_TAGS') {
    return t('Update Media Tags');
  } else if (event === 'UPDATE_MEDIUM_TAG_NAME') {
    return t('Update Media Tag Name');
  } else if (event === 'DELETE_MEDIUM_TAG') {
    return t('Delete Media Tag');
  } else if (event === 'BULK_DELETE_CONTENTS') {
    return t('Delete multiple content');
  } else if (event === 'BULK_UPDATE_CONTENT_STATUS') {
    return t('Update multiple content');
  } else if (event === 'DELETE_ALL_MEDIA') {
    return t('Delete all media');
  } else {
    return event;
  }
};

/**
 * CSV Injectionの対策として、CSVに出力する文字列をエスケープする。
 * @returns string
 */
export const escapeCsvStr = (str) => {
  if (!str) {
    return '';
  }

  const specialChars = ['=', '+', '-', '@', ';', '/', '\n', '\r'];
  const isInjected = specialChars.includes(str.charAt(0));
  // 特殊記号が含まれている場合は先頭にシングルクォートを付与
  if (isInjected) {
    return sanitizedStr("'" + str);
  }

  return sanitizedStr(str);
};

// タブスペースの削除と\nと\rの文字列を変換する
const sanitizedStr = (str) => {
  return str
    .replace(/,/g, '')
    .replace(/\n/g, '\\\\n')
    .replace(/\r/g, '\\\\r')
    .trimStart();
};

/**
 * 現在のユーザが SAML でログインしたユーザであるかどうか。
 * @returns SAML ユーザなら true, そうでなければ false。
 */
export function isSAMLUser() {
  return Boolean(loadAmplifyAuthConfig());
}

/**
 * 複数環境と SAML ユーザを考慮したURLを組み立てる。
 */
export function buildHost({ domain, childService, saml }) {
  if (isSAMLUser() && saml?.loginURL) {
    const loginURL = new URL(saml?.loginURL);
    const loginToken =
      new URLSearchParams(loginURL.search).get('loginToken') || '';

    const host = getSSOHost();

    const query = new URLSearchParams({ loginToken });
    if (childService?.partitionKey) {
      query.append('environmentServiceId', childService?.partitionKey);
    }

    return `${host}/saml/signin?${query.toString()}`;
  }
  return getHost(domain);
}

/**
 * 拡張フィールドで渡すpostMessageを組み立てる。
 */
export const buildIframePostMessage = ({ id, email, message }) => {
  return {
    id,
    action: 'MICROCMS_GET_DEFAULT_DATA',
    user: {
      email,
    },
    message,
  };
};

/**
 * ブラウザ言語を取得。取得できなかった場合フォールバックとして日本語を返す。
 */
export const getBrowserLanguage = () => {
  const lang = navigator.language || 'ja';
  return lang === 'ja' || lang === 'ja-JP' ? 'ja' : 'en';
};

/**
 * localhostかどうかを判定する
 */
export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
    ),
);

/**
 * urlが有効かどうかを判定する
 */
export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * 開発、ステージング環境かどうかを判定する
 * @returns 開発、ステージング環境ならtrue, そうでなければfalse
 */
export const isDevHost = () => {
  const host = getPureHost();
  const devHosts = ['dev.local', 'microcms-dev.net', 'microcms-staging.net'];

  return devHosts.includes(host);
};
