import deepEqual from 'deep-equal';
import isEmpty from 'is-empty';

import type { MigrateApi } from '@/entity/api';
import type { ContentByVersion } from '@/entity/content';
import type { Field } from '@/types/contents';

type FieldContentComparison = Pick<Field, 'idValue' | 'name' | 'kind'> &
  (
    | {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sinceRaw: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        untilRaw: any;
        unauthorized?: undefined;
      }
    | {
        sinceRaw?: undefined;
        untilRaw?: undefined;
        unauthorized: true;
      }
  );

/**
 * 差分表示のための情報を生成する
 *
 * @param apiFields APIスキーマ
 * @param sinceContent 比較元のコンテンツ
 * @param untilContent 比較先のコンテンツ
 * @param noDiffSkip 差分がない場合にスキップするかどうか
 */
const buildContentComparisonList = (
  apiFields: MigrateApi['apiFields'],
  sinceContent: ContentByVersion['value'],
  untilContent: ContentByVersion['value'],
  noDiffSkip: boolean,
  unauthorizedFieldIds?: string[],
): FieldContentComparison[] => {
  const result: FieldContentComparison[] = [];

  for (const field of apiFields) {
    // 権限が足りない項目があるフィールドはスキップ（nullを入れておくことで権限なしとする）
    if (unauthorizedFieldIds?.includes(field.fieldId)) {
      result.push({
        idValue: field.idValue,
        name: field.name,
        kind: field.kind,
        unauthorized: true,
      });
      continue;
    }

    const sinceRaw = sinceContent[field.fieldId];
    const untilRaw = untilContent[field.fieldId];

    // 差分がない場合はスキップ
    if (noDiffSkip) {
      if (field.kind === 'custom') {
        // カスタムフィールドの場合は、中身を走査してisEmptyの場合はスキップ。
        let isSkip = true;
        for (const key in sinceRaw) {
          // fieldIdは除外
          if (key === 'fieldId') {
            continue;
          }
          if (!isEmpty(sinceRaw[key])) {
            isSkip = false;
            break;
          }
        }
        if (isSkip) {
          for (const key in untilRaw) {
            // fieldIdは除外
            if (key === 'fieldId') {
              continue;
            }
            if (!isEmpty(untilRaw[key])) {
              isSkip = false;
              break;
            }
          }
        }
        if (isSkip) {
          continue;
        }
      }

      if (
        (isEmpty(sinceRaw) && isEmpty(untilRaw)) ||
        deepEqual(sinceRaw, untilRaw)
      ) {
        continue;
      }
    }

    result.push({
      idValue: field.idValue,
      name: field.name,
      kind: field.kind,
      sinceRaw,
      untilRaw,
    });
  }

  return result;
};

export { buildContentComparisonList };
export type { FieldContentComparison };
