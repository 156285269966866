import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '@/components/ui/Button';

import { useUpdateContentCreatedByModal } from './useUpdateContentCreatedByModal';

import type { Service } from '@/types/services';
import type { RowProps } from '@/views/Common/member/MemberSelect/SearchMember/SearchMember';

import styles from './updatecontentcreatedbymodal.module.css';

import { MemberSelect } from '@/views/Common/member/MemberSelect';
import { ModalContent } from '@/views/Common/Ui/Modal';

type ContainerProps = {
  service: Service;
  apiEndpoint: string;
  editorId: string;
  userDefinedContentId: string;
  closeModal: () => void;
};

type Props = {
  editorId: string;
  showMemberSelector: boolean;
  members: RowProps[] | null;
  disableUpdateButton: boolean;
  reverseShowMemberSelector: () => void;
  onClickRow: (username: string) => void;
  onClickChange: () => void;
};

export const UpdateContentCreatedByModalView: React.FC<Props> = ({
  editorId,
  showMemberSelector,
  members,
  disableUpdateButton,
  reverseShowMemberSelector,
  onClickRow,
  onClickChange,
}) => {
  const { t } = useTranslation('updateCreatedByModal');

  return (
    <ModalContent
      title={t('Change Content Creator')}
      onEscapeKeyDown={(e) => {
        showMemberSelector && e.preventDefault();
      }}
    >
      <p className={styles.modalLongDescription}>
        {Trans({
          t,
          i18nKey:
            'This will change the user created this content to another user. If the desired user is not listed, check Roles.',
          children: (
            <Link to="/roles" className={styles.descriptionLink}>
              Roles
            </Link>
          ),
        })}
      </p>
      <MemberSelect
        showMemberSelector={showMemberSelector}
        reverseShowMemberSelector={reverseShowMemberSelector}
        selectedMemberId={editorId}
        members={members}
        onClickRow={onClickRow}
      />
      <Button
        type="secondary"
        value={t('Save changes')}
        size="small"
        className={styles.modalActionButton}
        onClick={onClickChange}
        disabled={disableUpdateButton}
      />
    </ModalContent>
  );
};

export const UpdateContentCreatedByModal: React.FC<ContainerProps> = ({
  service,
  apiEndpoint,
  editorId,
  userDefinedContentId,
  closeModal,
}) => {
  const {
    selectedMemberId,
    showMemberSelector,
    onClickRow,
    reverseShowMemberSelector,
    onClickChange,
    rowValues,
    disableUpdateButton,
  } = useUpdateContentCreatedByModal({
    serviceId: service.partitionKey,
    apiEndpoint,
    editorId,
    userDefinedContentId,
    closeModal,
  });

  return (
    <UpdateContentCreatedByModalView
      editorId={selectedMemberId}
      showMemberSelector={showMemberSelector}
      members={rowValues}
      disableUpdateButton={disableUpdateButton}
      reverseShowMemberSelector={reverseShowMemberSelector}
      onClickRow={onClickRow}
      onClickChange={onClickChange}
    />
  );
};
