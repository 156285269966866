import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';

import { useUpdateContentCreatedByModalQuery } from './useUpdateContentCreatedByModalQuery';

import { contentQueryKeys } from '@/views/Common/content/queryKeys';
import {
  buildRowValues,
  type RowProps,
} from '@/views/Common/member/MemberSelect/SearchMember/SearchMember';

type Props = {
  serviceId: string;
  apiEndpoint: string;
  editorId: string;
  userDefinedContentId: string;
  closeModal: () => void;
};

type ReturnValues = {
  selectedMemberId: string;
  showMemberSelector: boolean;
  onClickRow: (username: string) => void;
  reverseShowMemberSelector: () => void;
  onClickChange: () => void;
  rowValues: RowProps[] | null;
  updateContentCreatedByStatus: string;
  disableUpdateButton: boolean;
};

export const useUpdateContentCreatedByModal = ({
  serviceId,
  apiEndpoint,
  editorId,
  userDefinedContentId,
  closeModal,
}: Props): ReturnValues => {
  //state
  const [selectedMemberId, setSelectedMemberId] = useState(editorId || '');
  const [showMemberSelector, setShowMemberSelector] = useState(false);
  const isSelectInitMember = selectedMemberId === editorId;

  //actions
  const onClickRow = useCallback((username: string) => {
    setSelectedMemberId(username);
    setShowMemberSelector(false);
  }, []);
  const reverseShowMemberSelector = useCallback(() => {
    setShowMemberSelector(!showMemberSelector);
  }, [showMemberSelector]);

  // query
  const { useAllMembers, useAllRoles, useUpdateContentCreatedBy } =
    useUpdateContentCreatedByModalQuery(serviceId);

  // cache
  const cache = useQueryClient();

  const { data: members } = useAllMembers();
  const { data: roles } = useAllRoles();
  const updateContentCreatedBy = useUpdateContentCreatedBy(
    apiEndpoint,
    userDefinedContentId,
  );

  const onClickChange = useCallback(() => {
    updateContentCreatedBy.mutate(selectedMemberId, {
      onSuccess: () => {
        // 該当のコンテンツのキャッシュを削除
        cache.invalidateQueries(contentQueryKeys.detail(userDefinedContentId));

        closeModal();
      },
      onError: () => {
        closeModal();
      },
    });
  }, [
    updateContentCreatedBy,
    selectedMemberId,
    cache,
    userDefinedContentId,
    closeModal,
  ]);

  const isLoading = updateContentCreatedBy.status === 'loading';

  //logic
  const rowValues = useMemo(
    () => buildRowValues(members, roles),
    [members, roles],
  );

  return {
    selectedMemberId,
    showMemberSelector,
    onClickRow,
    reverseShowMemberSelector,
    onClickChange,
    rowValues,
    updateContentCreatedByStatus: updateContentCreatedBy.status,
    disableUpdateButton: isLoading || isSelectInitMember,
  };
};
