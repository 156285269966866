import deepEqual from 'deep-equal';
import { nanoid } from 'nanoid';
import { useCallback, useMemo, useState, useRef } from 'react';
import type { ColorResult, Color } from 'react-color';
import { colorToRgbString } from '@/util/color';

import type { Props } from './InputRichEditorV2ColorListField';

const MAX_COLOR_LIST_COUNT = 20;
const DEFAULT_COLOR = 'rgb(255, 255, 255)';

export const useInputRichEditorV2ColorListField = ({
  colorList,
  setColorList,
}: Props) => {
  const addButtonRef = useRef<HTMLButtonElement>(null);

  const [color, setColor] = useState<Color | undefined>(undefined);
  const [colorItemId, setColorItemId] = useState<string | null>(null);

  const addColorList = useCallback(() => {
    const newItems = [...colorList, { id: nanoid(10), value: DEFAULT_COLOR }];
    setColorList(newItems);
  }, [colorList, setColorList]);

  const removeColorList = useCallback(
    (id: string) => () => {
      const newItems = colorList?.filter((item) => item?.id !== id);
      setColorList(newItems);
      if (addButtonRef.current) {
        addButtonRef.current.focus();
      }
    },
    [colorList, setColorList],
  );

  const handleChangeColor = useCallback(
    (id: string, value: ColorResult) => {
      const newItems = colorList.map((color) =>
        color.id === id
          ? { ...color, value: colorToRgbString(value.rgb) }
          : color,
      );
      // newItemsとcolorListに差分がある時にのみカラープリセットを追加する
      if (!deepEqual(newItems, colorList)) {
        setColor(value.rgb);
        setColorList(newItems);
      }
    },
    [colorList, setColorList],
  );

  // 各文字色の値をクリックしたときに、カラーピッカー色の値をセットする必要があるため、setColorに値をセットする
  const handleSetColor = useCallback((value: string) => {
    setColor(value);
  }, []);

  // 各文字色の削除ボタンを制御するための識別子としてcolorItemIdをセットする
  // 主にホバーした時とフォーカスした時に削除ボタンを表示するために使用する
  const handleSetColorItemId = useCallback((id: string | null) => {
    setColorItemId(id);
  }, []);

  const isMaxColorList = useMemo(() => {
    return colorList.length >= MAX_COLOR_LIST_COUNT;
  }, [colorList.length]);

  return {
    addColorList,
    removeColorList,
    handleChangeColor,
    handleSetColor,
    color,
    addButtonRef,
    colorItemId,
    handleSetColorItemId,
    isMaxColorList,
  };
};
