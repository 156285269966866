import { getAllMembers } from '@/usecase/memberUsecase';

import { useApi } from '@/lib/useApi';

/**
 * @deprecated
 * 代わりに views/Common/member/useMemberReader.ts の useAllMembers を使ってください
 */
export const useAllMembers = (serviceId: string) => {
  return useApi(['serviceUsers'], () => getAllMembers(serviceId));
};
