import { buildDiffNodes } from '../../parts/DiffNodesDisplay';
import { DiffNodesDisplay } from '../../parts/DiffNodesDisplay';

import type { Omittable } from '../../parts/DiffNodesDisplay';

import styles from './richText.module.css';

type Props = {
  sinceValue: string;
  untilValue: string;
  omittable?: Omittable;
};

const RichText: React.FC<Props> = ({ sinceValue, untilValue, omittable }) => {
  const diffNodes = buildDiffNodes({
    sinceValue,
    untilValue,
    Element,
  });

  return (
    <DiffNodesDisplay
      nodes={diffNodes}
      position="center"
      gap
      omittable={omittable}
    />
  );
};

type ElementProps = {
  value: string;
};

const Element: React.FC<ElementProps> = ({ value }) => {
  return (
    <div className={styles.main} dangerouslySetInnerHTML={{ __html: value }} />
  );
};

export { RichText };
