import type { MemberReadPermissionDetail } from '@/entity/role';

import { produce } from '@/lib/immer';

function getMemberPermissionReadIdentifier(
  memberPermissionRead: MemberReadPermissionDetail,
) {
  if (memberPermissionRead.defaultRead === true) {
    return 'ALL';
  } else if (memberPermissionRead.sameRole) {
    return 'SAME_ROLE';
  } else if (
    memberPermissionRead.allowMemberList !== undefined &&
    memberPermissionRead.allowMemberList !== null
  ) {
    return 'ALLOWLIST';
  }
  return 'NONE';
}

function addMemberToAllowList(
  list: Required<MemberReadPermissionDetail>['allowMemberList'],
  userId: Required<MemberReadPermissionDetail>['allowMemberList'][number],
) {
  return produce(list, (draft) => {
    const index = draft.indexOf(userId);
    if (index === -1) {
      draft.push(userId);
    }
  });
}

function removeMemberToAllowList(
  list: Required<MemberReadPermissionDetail>['allowMemberList'],
  userId: Required<MemberReadPermissionDetail>['allowMemberList'][number],
) {
  return produce(list, (draft) => {
    const index = draft.indexOf(userId);
    if (index !== -1) {
      draft.splice(index, 1);
    }
  });
}

export {
  getMemberPermissionReadIdentifier,
  addMemberToAllowList,
  removeMemberToAllowList,
};
