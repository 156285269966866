export default class BackgroundPicker {
  /**
   * @param {Quill} quill
   * @param {Object} options
   */
  constructor(quill, options = {}) {
    this.quill = quill;
    this.options = options;

    this.quill
      .getModule('toolbar')
      .addHandler('background', this.value.bind(this));
  }

  value(value) {
    const sid = this.options.sid;
    if (value === `custom-background-${sid}`) {
      const colorSelect = document.getElementById(`backgroundSelect-${sid}`);

      let picker = document.getElementById(`custom-background-${sid}`);
      if (!picker) {
        picker = document.createElement('input');
        picker.id = `custom-background-${sid}`;
        picker.type = 'color';
        picker.style.visibility = 'hidden';
        picker.value = '#FF0000';
        colorSelect.appendChild(picker);
        picker.addEventListener('input', () => {
          this.quill.format('background', picker.value);
        });
      }
      setTimeout(() => {
        picker.click();
      }, 100);
    } else {
      this.quill.format('background', value);
    }
  }
}
