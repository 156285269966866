import TableCell from '@tiptap/extension-table-cell';

import type { TableCellOptions } from '@tiptap/extension-table-cell';

type CustomTableCellOptions = TableCellOptions & {
  inputRules: boolean;
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-table-cell/src/table-cell.ts
export const CustomTableCell = TableCell.extend<CustomTableCellOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: true,
    };
  },

  parseHTML() {
    return this.options.inputRules ? this.parent?.() : [];
  },
});
