import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from '../_types';
import type { Role } from '@/entity/role';

import { produce } from '@/lib/immer';

// dc は defaultContent の略です

type SetDefaultContentPermissionApiReadAction = FormRoleInputValueAction<
  'set-dc-permission-apiread',
  Role['defaultContentPermission']['apiRead']
>;
type SetDefaultContentPermissionApiUpdateAction = FormRoleInputValueAction<
  'set-dc-permission-apiupdate',
  Role['defaultContentPermission']['apiUpdate']
>;
type SetDefaultContentPermissionApiDeleteAction = FormRoleInputValueAction<
  'set-dc-permission-apidelete',
  Role['defaultContentPermission']['apiDelete']
>;

/**
 * DefaultContentPermissionのapiReadフィールドを更新する
 */
const setDefaultContentPermissionApiRead: FormRoleInputValueDispatchFn<
  SetDefaultContentPermissionApiReadAction
> = (currentState, action) => {
  return produce(currentState, (draft) => {
    draft.defaultContentPermission.apiRead = action;
  });
};

/**
 * DefaultContentPermissionのapiUpdateフィールドを更新する
 */
const setDefaultContentPermissionApiUpdate: FormRoleInputValueDispatchFn<
  SetDefaultContentPermissionApiUpdateAction
> = (currentState, action) => {
  return produce(currentState, (draft) => {
    draft.defaultContentPermission.apiUpdate = action;
  });
};

/**
 * DefaultContentPermissionのapiDeleteフィールドを更新する
 */
const setDefaultContentPermissionApiDelete: FormRoleInputValueDispatchFn<
  SetDefaultContentPermissionApiDeleteAction
> = (currentState, action) => {
  return produce(currentState, (draft) => {
    draft.defaultContentPermission.apiDelete = action;
  });
};

export {
  type SetDefaultContentPermissionApiReadAction,
  type SetDefaultContentPermissionApiUpdateAction,
  type SetDefaultContentPermissionApiDeleteAction,
  setDefaultContentPermissionApiRead,
  setDefaultContentPermissionApiUpdate,
  setDefaultContentPermissionApiDelete,
};
