/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createServiceForTemplates = /* GraphQL */ `
  mutation CreateServiceForTemplates(
    $name: String
    $domain: String
    $image: AWSJSON
    $planName: String
    $trialLengthInSec: Int
    $templateId: String
  ) {
    createServiceForTemplates(
      name: $name
      domain: $domain
      image: $image
      planName: $planName
      trialLengthInSec: $trialLengthInSec
      templateId: $templateId
    )
  }
`;
export const updateServiceRequireMfa = /* GraphQL */ `
  mutation UpdateServiceRequireMfa($serviceId: String, $requireMfa: Boolean) {
    updateServiceRequireMfa(serviceId: $serviceId, requireMfa: $requireMfa) {
      result
      message
    }
  }
`;
export const executeCreateEnvironment = /* GraphQL */ `
  mutation ExecuteCreateEnvironment(
    $environmentName: String
    $serviceId: String
    $contentCopyLevel: EnvironmentCopyContentLevel
  ) {
    executeCreateEnvironment(
      environmentName: $environmentName
      serviceId: $serviceId
      contentCopyLevel: $contentCopyLevel
    ) {
      result
      message
      data
    }
  }
`;
export const updateEnvironmentName = /* GraphQL */ `
  mutation UpdateEnvironmentName(
    $serviceId: String!
    $environmentName: String!
  ) {
    updateEnvironmentName(
      serviceId: $serviceId
      environmentName: $environmentName
    )
  }
`;
export const deleteEnvironment = /* GraphQL */ `
  mutation DeleteEnvironment($serviceId: String) {
    deleteEnvironment(serviceId: $serviceId) {
      result
      message
    }
  }
`;
export const restartApi = /* GraphQL */ `
  mutation RestartApi($serviceId: String) {
    restartApi(serviceId: $serviceId) {
      result
      message
    }
  }
`;
export const createApiKey = /* GraphQL */ `
  mutation CreateApiKey(
    $serviceId: String
    $label: String
    $defaultPolicy: ApiKeyPolicyInput
    $priorityPolicies: [ApiKeyPriorityPolicyInput]
    $managementPolicy: ManagementPolicyInput
  ) {
    createApiKey(
      serviceId: $serviceId
      label: $label
      defaultPolicy: $defaultPolicy
      priorityPolicies: $priorityPolicies
      managementPolicy: $managementPolicy
    ) {
      result
      message
      data {
        apiKeyId
        serviceId
        value
        label
        createdAt
        updatedAt
        createdBy
        updatedBy
        defaultPolicy {
          get
          getAllDraft
          getAllClosed
          post
          put
          patch
          delete
        }
        priorityPolicies {
          apiId
          policy {
            get
            getAllDraft
            getAllClosed
            post
            put
            patch
            delete
          }
        }
        managementPolicy {
          getContent
          patchContentCreatedBy
          patchContentStatus
          getMedia
          postMedia
          deleteMedia
        }
      }
    }
  }
`;
export const updateApiKey = /* GraphQL */ `
  mutation UpdateApiKey(
    $apiKeyId: String
    $label: String
    $defaultPolicy: ApiKeyPolicyInput
    $priorityPolicies: [ApiKeyPriorityPolicyInput]
    $managementPolicy: ManagementPolicyInput
  ) {
    updateApiKey(
      apiKeyId: $apiKeyId
      label: $label
      defaultPolicy: $defaultPolicy
      priorityPolicies: $priorityPolicies
      managementPolicy: $managementPolicy
    ) {
      result
      message
    }
  }
`;
export const deleteApiKey = /* GraphQL */ `
  mutation DeleteApiKey($serviceId: String, $apiKeyId: String) {
    deleteApiKey(serviceId: $serviceId, apiKeyId: $apiKeyId) {
      result
      message
    }
  }
`;
export const createApiForTemplates = /* GraphQL */ `
  mutation CreateApiForTemplates(
    $serviceId: String
    $apiName: String
    $apiEndpoint: String
    $apiType: String
    $apiFields: AWSJSON
    $customFields: AWSJSON
    $apiId: String
    $apiDescription: String
  ) {
    createApiForTemplates(
      serviceId: $serviceId
      apiName: $apiName
      apiEndpoint: $apiEndpoint
      apiType: $apiType
      apiFields: $apiFields
      customFields: $customFields
      apiId: $apiId
      apiDescription: $apiDescription
    )
  }
`;
export const updateApiAllowedIpList = /* GraphQL */ `
  mutation UpdateApiAllowedIpList(
    $apiId: String
    $ipList: String
    $enabled: Boolean
  ) {
    updateApiAllowedIpList(apiId: $apiId, ipList: $ipList, enabled: $enabled) {
      result
      message
    }
  }
`;
export const moveContentToFirst = /* GraphQL */ `
  mutation MoveContentToFirst($contentId: String) {
    moveContentToFirst(contentId: $contentId) {
      result
      message
    }
  }
`;
export const moveContentToUp = /* GraphQL */ `
  mutation MoveContentToUp($contentId: String) {
    moveContentToUp(contentId: $contentId) {
      result
      message
    }
  }
`;
export const moveContentToDown = /* GraphQL */ `
  mutation MoveContentToDown($contentId: String) {
    moveContentToDown(contentId: $contentId) {
      result
      message
    }
  }
`;
export const moveContentToLast = /* GraphQL */ `
  mutation MoveContentToLast($contentId: String) {
    moveContentToLast(contentId: $contentId) {
      result
      message
    }
  }
`;
export const moveContentToAnyPlace = /* GraphQL */ `
  mutation MoveContentToAnyPlace(
    $contentId: String
    $targetContentId: String
    $isLast: Boolean
  ) {
    moveContentToAnyPlace(
      contentId: $contentId
      targetContentId: $targetContentId
      isLast: $isLast
    ) {
      result
      message
    }
  }
`;
export const copyContent = /* GraphQL */ `
  mutation CopyContent($contentId: String) {
    copyContent(contentId: $contentId)
  }
`;
export const updateMedium = /* GraphQL */ `
  mutation UpdateMedium(
    $serviceId: String
    $mediumId: String
    $filePath: String
  ) {
    updateMedium(
      serviceId: $serviceId
      mediumId: $mediumId
      filePath: $filePath
    ) {
      result
      message
    }
  }
`;
export const updateMediumName = /* GraphQL */ `
  mutation UpdateMediumName(
    $serviceId: String
    $mediumId: String
    $fileName: String
  ) {
    updateMediumName(
      serviceId: $serviceId
      mediumId: $mediumId
      fileName: $fileName
    ) {
      result
      message
    }
  }
`;
export const updateMediumCreatedBy = /* GraphQL */ `
  mutation UpdateMediumCreatedBy(
    $serviceId: String
    $mediumId: String
    $createdBy: String
  ) {
    updateMediumCreatedBy(
      serviceId: $serviceId
      mediumId: $mediumId
      createdBy: $createdBy
    ) {
      result
      message
    }
  }
`;
export const deleteAllMedia = /* GraphQL */ `
  mutation DeleteAllMedia($serviceId: String) {
    deleteAllMedia(serviceId: $serviceId) {
      result
      message
    }
  }
`;
export const changePlanToStandard = /* GraphQL */ `
  mutation ChangePlanToStandard($serviceId: String) {
    changePlanToStandard(serviceId: $serviceId)
  }
`;
export const changePlanToBusiness = /* GraphQL */ `
  mutation ChangePlanToBusiness($serviceId: String) {
    changePlanToBusiness(serviceId: $serviceId)
  }
`;
export const changePlanToTeam = /* GraphQL */ `
  mutation ChangePlanToTeam($serviceId: String) {
    changePlanToTeam(serviceId: $serviceId)
  }
`;
export const changePlanToAdvanced = /* GraphQL */ `
  mutation ChangePlanToAdvanced($serviceId: String) {
    changePlanToAdvanced(serviceId: $serviceId)
  }
`;
export const changePlanToHobby = /* GraphQL */ `
  mutation ChangePlanToHobby($serviceId: String) {
    changePlanToHobby(serviceId: $serviceId) {
      result
      message
    }
  }
`;
export const changePlanToTemplate = /* GraphQL */ `
  mutation ChangePlanToTemplate($serviceId: String) {
    changePlanToTemplate(serviceId: $serviceId)
  }
`;
export const changeCard = /* GraphQL */ `
  mutation ChangeCard($serviceId: String, $source: String) {
    changeCard(serviceId: $serviceId, source: $source) {
      result
      message
    }
  }
`;
export const deleteCard = /* GraphQL */ `
  mutation DeleteCard($serviceId: String) {
    deleteCard(serviceId: $serviceId) {
      result
      message
    }
  }
`;
export const changePaymentInfo = /* GraphQL */ `
  mutation ChangePaymentInfo(
    $serviceId: String
    $source: String
    $email: String
    $name: String
    $phone: String
    $company: String
  ) {
    changePaymentInfo(
      serviceId: $serviceId
      source: $source
      email: $email
      name: $name
      phone: $phone
      company: $company
    )
  }
`;
export const changeBillingEmail = /* GraphQL */ `
  mutation ChangeBillingEmail($serviceId: String, $email: String) {
    changeBillingEmail(serviceId: $serviceId, email: $email) {
      result
      message
    }
  }
`;
export const changeBillingName = /* GraphQL */ `
  mutation ChangeBillingName($serviceId: String, $name: String) {
    changeBillingName(serviceId: $serviceId, name: $name) {
      result
      message
    }
  }
`;
export const changeBillingPhone = /* GraphQL */ `
  mutation ChangeBillingPhone($serviceId: String, $phone: String) {
    changeBillingPhone(serviceId: $serviceId, phone: $phone) {
      result
      message
    }
  }
`;
export const changeBillingCompany = /* GraphQL */ `
  mutation ChangeBillingCompany($serviceId: String, $company: String) {
    changeBillingCompany(serviceId: $serviceId, company: $company) {
      result
      message
    }
  }
`;
export const getStripeSubscription = /* GraphQL */ `
  mutation GetStripeSubscription($serviceId: String) {
    getStripeSubscription(serviceId: $serviceId)
  }
`;
export const getStripeInvoice = /* GraphQL */ `
  mutation GetStripeInvoice($serviceId: String) {
    getStripeInvoice(serviceId: $serviceId)
  }
`;
export const cancelSubscription = /* GraphQL */ `
  mutation CancelSubscription(
    $serviceId: String
    $cancelSelect: CancelSelect
    $cancelReason: String
  ) {
    cancelSubscription(
      serviceId: $serviceId
      cancelSelect: $cancelSelect
      cancelReason: $cancelReason
    )
  }
`;
export const restartSubscription = /* GraphQL */ `
  mutation RestartSubscription(
    $serviceId: String
    $source: String
    $email: String
    $name: String
    $phone: String
    $company: String
  ) {
    restartSubscription(
      serviceId: $serviceId
      source: $source
      email: $email
      name: $name
      phone: $phone
      company: $company
    ) {
      result
      message
    }
  }
`;
export const purchaseAddons = /* GraphQL */ `
  mutation PurchaseAddons($serviceId: String, $type: AddonType) {
    purchaseAddons(serviceId: $serviceId, type: $type) {
      result
      message
    }
  }
`;
export const changeBillingInvoice = /* GraphQL */ `
  mutation ChangeBillingInvoice($serviceId: String) {
    changeBillingInvoice(serviceId: $serviceId) {
      result
      message
    }
  }
`;
export const createReviewRequest = /* GraphQL */ `
  mutation CreateReviewRequest(
    $title: String
    $description: String
    $contentId: String
    $reservationTime: String
  ) {
    createReviewRequest(
      title: $title
      description: $description
      contentId: $contentId
      reservationTime: $reservationTime
    ) {
      result
      message
      data {
        serviceId
        reqId
        title
        description
        contentId
        status
        createdAt
        updatedAt
        reservationTime
        reservationStopTime
        reviewers {
          id
        }
        commentCount
        authorId
        viewerGroup
      }
    }
  }
`;
export const updateReviewRequest = /* GraphQL */ `
  mutation UpdateReviewRequest(
    $serviceId: String
    $reqId: Int
    $title: String
    $description: String
  ) {
    updateReviewRequest(
      serviceId: $serviceId
      reqId: $reqId
      title: $title
      description: $description
    ) {
      result
      message
    }
  }
`;
export const updateReviewRequestReservationTime = /* GraphQL */ `
  mutation UpdateReviewRequestReservationTime(
    $serviceId: String
    $reqId: Int
    $reservationTime: String
    $reservationStopTime: String
  ) {
    updateReviewRequestReservationTime(
      serviceId: $serviceId
      reqId: $reqId
      reservationTime: $reservationTime
      reservationStopTime: $reservationStopTime
    ) {
      result
      message
    }
  }
`;
export const toggleReviewRequestStatus = /* GraphQL */ `
  mutation ToggleReviewRequestStatus($serviceId: String, $reqId: Int) {
    toggleReviewRequestStatus(serviceId: $serviceId, reqId: $reqId) {
      result
      message
    }
  }
`;
export const closeReviewRequestAndPublishContent = /* GraphQL */ `
  mutation CloseReviewRequestAndPublishContent(
    $serviceId: String
    $reqId: Int
  ) {
    closeReviewRequestAndPublishContent(serviceId: $serviceId, reqId: $reqId)
  }
`;
export const createReviewRequestComment = /* GraphQL */ `
  mutation CreateReviewRequestComment(
    $serviceId: String
    $reqId: Int
    $comment: String
  ) {
    createReviewRequestComment(
      serviceId: $serviceId
      reqId: $reqId
      comment: $comment
    ) {
      result
      message
      data {
        serviceIdReqId
        eventId
        createdAt
        updatedAt
        type
        authorId
        comment
        oldStatus
        newStatus
        oldTitle
        newTitle
        reservationTime
        reservationStopTime
        historyVersion
        reviewerId
      }
    }
  }
`;
export const deleteReviewRequestComment = /* GraphQL */ `
  mutation DeleteReviewRequestComment(
    $serviceId: String
    $reqId: Int
    $eventId: String
  ) {
    deleteReviewRequestComment(
      serviceId: $serviceId
      reqId: $reqId
      eventId: $eventId
    ) {
      result
      message
    }
  }
`;
export const addReviewer = /* GraphQL */ `
  mutation AddReviewer($serviceId: String, $reqId: Int, $reviewerId: String) {
    addReviewer(serviceId: $serviceId, reqId: $reqId, reviewerId: $reviewerId) {
      result
      message
    }
  }
`;
export const removeReviewer = /* GraphQL */ `
  mutation RemoveReviewer(
    $serviceId: String
    $reqId: Int
    $reviewerId: String
  ) {
    removeReviewer(
      serviceId: $serviceId
      reqId: $reqId
      reviewerId: $reviewerId
    ) {
      result
      message
    }
  }
`;
export const acceptMemberInvitation = /* GraphQL */ `
  mutation AcceptMemberInvitation {
    acceptMemberInvitation {
      result
      message
    }
  }
`;
export const setSAMLConfiguration = /* GraphQL */ `
  mutation SetSAMLConfiguration(
    $serviceId: String!
    $idpMetadataURL: String
    $idpMetadataXML: String
    $defaultRoleId: String!
    $samlOnly: Boolean!
  ) {
    setSAMLConfiguration(
      serviceId: $serviceId
      idpMetadataURL: $idpMetadataURL
      idpMetadataXML: $idpMetadataXML
      defaultRoleId: $defaultRoleId
      samlOnly: $samlOnly
    ) {
      result
      message
    }
  }
`;
export const deleteSAMLConfiguration = /* GraphQL */ `
  mutation DeleteSAMLConfiguration($serviceId: String!) {
    deleteSAMLConfiguration(serviceId: $serviceId) {
      result
      message
    }
  }
`;
export const addCustomStatus = /* GraphQL */ `
  mutation AddCustomStatus(
    $apiId: String!
    $key: String!
    $description: String!
    $name: String!
    $behaviour: CustomStatusBehaviour!
    $color: String!
  ) {
    addCustomStatus(
      apiId: $apiId
      key: $key
      description: $description
      name: $name
      behaviour: $behaviour
      color: $color
    ) {
      result
      message
    }
  }
`;
export const updateCustomStatus = /* GraphQL */ `
  mutation UpdateCustomStatus(
    $apiId: String!
    $customStatusId: String!
    $key: String!
    $description: String!
    $name: String!
    $behaviour: CustomStatusBehaviour!
    $color: String!
  ) {
    updateCustomStatus(
      apiId: $apiId
      customStatusId: $customStatusId
      key: $key
      description: $description
      name: $name
      behaviour: $behaviour
      color: $color
    ) {
      result
      message
    }
  }
`;
export const deleteCustomStatus = /* GraphQL */ `
  mutation DeleteCustomStatus($apiId: String!, $customStatusId: String!) {
    deleteCustomStatus(apiId: $apiId, customStatusId: $customStatusId) {
      result
      message
    }
  }
`;
export const updateContentStatus = /* GraphQL */ `
  mutation UpdateContentStatus(
    $contentId: String!
    $customStatusId: String
    $behaviour: String
  ) {
    updateContentStatus(
      contentId: $contentId
      customStatusId: $customStatusId
      behaviour: $behaviour
    )
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount($value: Value!) {
    createAccount(value: $value) {
      result
      message
    }
  }
`;
export const updateAccountLanguage = /* GraphQL */ `
  mutation UpdateAccountLanguage($value: Value!) {
    updateAccountLanguage(value: $value) {
      result
      message
    }
  }
`;
export const updateAccountSurvey = /* GraphQL */ `
  mutation UpdateAccountSurvey($value: Value!) {
    updateAccountSurvey(value: $value) {
      result
      message
    }
  }
`;
export const updateServiceLanguage = /* GraphQL */ `
  mutation UpdateServiceLanguage(
    $serviceId: String!
    $serviceLanguage: Language!
  ) {
    updateServiceLanguage(
      serviceId: $serviceId
      serviceLanguage: $serviceLanguage
    ) {
      result
      message
    }
  }
`;
export const deleteMediumTag = /* GraphQL */ `
  mutation DeleteMediumTag($serviceId: String!, $mediumTagId: String!) {
    deleteMediumTag(serviceId: $serviceId, mediumTagId: $mediumTagId)
  }
`;
export const updateMediumTagName = /* GraphQL */ `
  mutation UpdateMediumTagName(
    $serviceId: String!
    $mediumTagId: String!
    $name: String!
  ) {
    updateMediumTagName(
      serviceId: $serviceId
      mediumTagId: $mediumTagId
      name: $name
    )
  }
`;
export const updateMediumTags = /* GraphQL */ `
  mutation UpdateMediumTags(
    $serviceId: String!
    $mediumId: String!
    $tags: [String!]
  ) {
    updateMediumTags(serviceId: $serviceId, mediumId: $mediumId, tags: $tags) {
      result
      message
    }
  }
`;
export const createMediumTags = /* GraphQL */ `
  mutation CreateMediumTags(
    $serviceId: String!
    $newMediumTags: [NewMediumTagInput!]
  ) {
    createMediumTags(serviceId: $serviceId, newMediumTags: $newMediumTags) {
      result
      message
      data {
        mediumTagId
        serviceId
        name
        createdAt
        updatedAt
        createdBy
        updatedBy
      }
    }
  }
`;
export const bulkDeleteContents = /* GraphQL */ `
  mutation BulkDeleteContents($apiId: String!, $contentIds: [String!]) {
    bulkDeleteContents(apiId: $apiId, contentIds: $contentIds) {
      result
      message
      data {
        successIds
        failureIds
      }
    }
  }
`;
export const bulkUpdateContentsStatus = /* GraphQL */ `
  mutation BulkUpdateContentsStatus(
    $apiId: String!
    $contentStatus: BulkContentStatus!
    $contentIds: [String!]
  ) {
    bulkUpdateContentsStatus(
      apiId: $apiId
      contentStatus: $contentStatus
      contentIds: $contentIds
    ) {
      result
      message
      data {
        successIds
        failureIds
      }
    }
  }
`;
export const answerMemberInvitation = /* GraphQL */ `
  mutation AnswerMemberInvitation($accept: Boolean!, $serviceId: String!) {
    answerMemberInvitation(accept: $accept, serviceId: $serviceId) {
      result
      message
    }
  }
`;
export const updateGlobalApiIpAddressRestriction = /* GraphQL */ `
  mutation UpdateGlobalApiIpAddressRestriction(
    $serviceId: String!
    $ipList: String!
  ) {
    updateGlobalApiIpAddressRestriction(
      serviceId: $serviceId
      ipList: $ipList
    ) {
      result
      message
    }
  }
`;
