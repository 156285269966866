import cx from 'classnames';

import styles from './textButton.module.css';

export type Props = {
  isSelected: boolean;
  text: string;
  onChangeOption: () => void;
};

export const TextButton: React.FC<Props> = ({
  isSelected,
  text,
  onChangeOption,
}) => {
  return (
    <button
      className={cx(styles.button, {
        [styles.isSelected]: isSelected,
      })}
      onClick={onChangeOption}
    >
      {text}
    </button>
  );
};
