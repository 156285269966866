import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import type { Props, ViewProps } from './SortableItem';

export const useSortableItem = ({
  uniqueIdentifier,
  handle,
  ...props
}: Props): ViewProps => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: uniqueIdentifier });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: 'none',
    ...props.style,
  };

  return {
    rootProps: {
      ...props,
      ref: setNodeRef,
      style,
      ...(!handle ? attributes : undefined),
      ...(!handle ? listeners : undefined),
      tabIndex: !handle ? 0 : undefined,
      'data-state': isDragging ? 'dragging' : 'idle',
    },
    ...(!!handle
      ? {
          handle,
          handleProps: {
            ref: setActivatorNodeRef,
            ...attributes,
            ...listeners,
            'data-state': isDragging ? 'dragging' : 'idle',
          },
        }
      : {
          handle: null,
          handleProps: null,
        }),
  };
};
