import qs from 'query-string';
import type React from 'react';
import { useMemo } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { useCopy } from '../../../hooks/Copy/useCopy';
import IconButton from '../../ui/IconButton';

import type { ApiKey } from '../../../types/apiKey';
import type { Param } from '../Params';

import styles from './code.module.css';

type Props = {
  host: string;
  inputPath: string;
  method: string;
  params: Param[];
  body: string;
  apiKey?: ApiKey;
};

const Curl: React.FC<Props> = ({
  host,
  inputPath,
  method,
  params,
  body,
  apiKey,
}) => {
  const curlGlobParam =
    inputPath.includes('[') || inputPath.includes(']') ? '--glob ' : '';
  const curlPath = useMemo(() => inputPath.split('?')[0], [inputPath]);
  const curlParams = useMemo(() => {
    const query = params
      .filter((p) => p.check)
      .reduce((prev, obj) => ({ ...prev, [obj.key]: obj.value }), {});
    return qs.stringify(query);
  }, [params]);
  const curlValue = useMemo(
    () => `${curlPath}${curlParams ? '?' : ''}${curlParams}`,
    [curlPath, curlParams],
  );
  const code = useMemo(() => {
    if (method === 'GET') {
      return `curl ${curlGlobParam}"${host}/api/v1/${curlValue}" -H "X-MICROCMS-API-KEY: ${
        apiKey?.value ? apiKey?.value : null
      }"`;
    }

    if (method === 'DELETE') {
      return `curl -X DELETE ${curlGlobParam}"${host}/api/v1/${curlValue}" -H "X-MICROCMS-API-KEY: ${
        apiKey?.value ? apiKey?.value : null
      }"`;
    }

    return `curl ${curlGlobParam}-X ${method} "${host}/api/v1/${inputPath}${
      curlParams ? `?${curlParams}` : ''
    }" -H "Content-Type: application/json" -H "X-MICROCMS-API-KEY: ${
      apiKey?.value ? apiKey?.value : null
    }" -d "${body.replace(/"/g, '\\"')}"`;
  }, [
    apiKey?.value,
    body,
    curlGlobParam,
    curlParams,
    curlValue,
    host,
    inputPath,
    method,
  ]);

  const [copied, copyAction, setCopied] = useCopy(code);
  const copyApiKey = () => {
    copyAction();

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <>
      <SyntaxHighlighter
        language="curl"
        style={docco}
        customStyle={{
          fontSize: 14,
          padding: '16px',
          borderRadius: '4px',
          paddingRight: '60px',
        }}
        wrapLongLines={true}
      >
        {code}
      </SyntaxHighlighter>
      <div className={styles.copyButton}>
        <IconButton
          icon={copied ? 'check' : 'content_copy'}
          hasFrame={true}
          onClick={copyApiKey}
        />
      </div>
    </>
  );
};

export default Curl;
