import type React from 'react';
import { useEffect, useContext, useMemo } from 'react';

import { useGetMyService } from '@/hooks/useService';

import { myRolesContext } from '../../hooks/Role/useMyRoles';
import { getHost, getServiceDomain } from '../../util';
import S3Image from '../S3Image';

import type { ServiceList } from '../../types/services';

import styles from './serviceList.module.css';

import { ServiceAvatar } from '@/views/Common/Ui/ServiceAvatar';

type Props = {
  services: ServiceList;
};

const Services: React.FC<Props> = ({ services }) => {
  const { parentService } = useGetMyService();

  // ロールを読み込み
  const context = useContext(myRolesContext);
  useEffect(() => {
    const domain = getServiceDomain();
    const service = services.list.find(
      (list) => list.gsiSinglePartitionKey1 === `DOMAIN#${domain}`,
    );
    context.roles === undefined &&
      service &&
      context.reloadRoles(service.partitionKey);
  }, [context, services.list]);

  // 作成日時が古い順に並べる
  const sortedList = useMemo(() => {
    return [...services.list]
      .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      .filter((service) => service.environment === null);
  }, [services.list]);

  return (
    <ul className={styles.wrapper} data-testid="service-list">
      {sortedList.map(({ domain, serviceName, serviceImage }) => (
        <li key={domain} className={styles.list}>
          {serviceImage !== null ? (
            <a
              href={getHost(domain)}
              className={
                getServiceDomain() === domain ||
                parentService?.domain === domain
                  ? `${styles.img} ${styles.isSelected}`
                  : styles.img
              }
            >
              <S3Image
                directory={`protected/${serviceImage.identityId}/${serviceImage.key}`.replace(
                  `/${serviceImage.key.replace(/.*\//, '')}`,
                  '',
                )}
                fileName={serviceImage.key.replace(/.*\//, '')}
                kind={'IMAGE'}
                queryString="?fit=crop&w=36&h=36"
                className={styles.selectedImage}
                style={{ background: 'none', border: 'none' }}
              />
            </a>
          ) : (
            <a
              href={getHost(domain)}
              title={serviceName}
              className={
                getServiceDomain() === domain ||
                parentService?.domain === domain
                  ? `${styles.identicon} ${styles.isSelected}`
                  : styles.identicon
              }
            >
              <ServiceAvatar string={domain} size={25} />
            </a>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Services;
