import { I18n } from 'aws-amplify';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import { deleteAmplifyAuthConfig } from '../../constants/localStorage';
import { useInput } from '../../hooks';
import { useSignin } from '../../hooks/Auth/useAuth';
import { getServiceDomain, getDefaultHost } from '../../util';
import ConfirmSignup from '../ConfirmSignup';
import Feedback from '../Feedback';
import Head from '../Head';
import Button from '../ui/Button';
import Fieldset from '../ui/Fieldset';
import Footer from '../ui/Footer';
import Textfield from '../ui/Textfield';
import { validateEmail, validatePassword } from '../Validations';
import MultiFactorAuth from './MultiFactorAuth';

import styles from './signin.module.css';

type SininProps = {
  email: string;
  onChangeEmail: any;
  emailError: string;
  serverError: any;
  newSignin: any;
  signinLoading: boolean;
  token: string;
};

const Signin: React.FC<SininProps> = ({
  email,
  onChangeEmail,
  emailError,
  serverError,
  newSignin,
  signinLoading,
  token,
}) => {
  const { t } = useTranslation('signin');
  const [password, onChangePassword, passwordError, setPassword] = useInput(
    '',
    validatePassword,
  );
  const hasLocalError = useMemo(
    () => emailError !== null || passwordError !== null,
    [emailError, passwordError],
  );

  const hasServiceDomain = useMemo(() => getServiceDomain() !== '', []);

  // SAML ユーザは /signin を通らないので、 SAML ユーザ用のデータがもし残っているなら異常な遷移なので、それを削除してキレイにする。
  useEffect(() => {
    deleteAmplifyAuthConfig();
  }, []);

  useEffect(() => {
    if (serverError !== undefined) {
      setPassword('');
    }
  }, [serverError, setPassword]);

  const submit = useCallback(() => {
    newSignin({ email, password });
  }, [newSignin, email, password]);

  //ログインはデフォルトドメインでさせる
  if (hasServiceDomain) {
    window.location.href = `${getDefaultHost()}/signin`;
    return <></>;
  }

  return (
    <div className={styles.wrapper}>
      <Head title={t('Login')} />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.logo}>
            <img
              className={styles.logoImg}
              src="/images/logo_black.svg"
              alt="microCMS"
            />
          </div>
          <p className={styles.description}>
            {t('Please login with your microCMS account.')}
            <br />
            {Trans({
              t,
              i18nKey: 'Create an account here if you do not have one.',
              children: <Link to="/signup">register here</Link>,
            })}
          </p>
          <div className={styles.formBox}>
            <Fieldset legend={t('Email Address')}>
              {token ? (
                <p className={styles.inviteEmail}>{email}</p>
              ) : (
                <Textfield
                  type="text"
                  name="email"
                  placeholder="mailaddress@microcms.io"
                  onChange={onChangeEmail}
                  errorText={emailError}
                />
              )}
            </Fieldset>
            <Fieldset legend={t('Password')}>
              <Textfield
                type="password"
                name="password"
                placeholder="************"
                value={password}
                onChange={onChangePassword}
                onEnter={submit}
                errorText={passwordError}
              />
            </Fieldset>
            <div className={styles.actions}>
              <Feedback
                message={
                  serverError && I18n.get(serverError.code, serverError.message)
                }
              />
              <Button
                className={
                  token ? 'ga-invite-signup-button' : 'ga-signin-button'
                }
                type="secondary"
                value={t('Login')}
                size="full"
                disabled={hasLocalError || signinLoading}
                onClick={submit}
                data-cy="signin-button"
              />
            </div>
            <div className={styles.links}>
              <Link className={styles.link} to="/forgot-password">
                {t('Forgot your password?')}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

type SigninFlowProps = {
  reset: () => void;
};

const SigninFlow: React.FC<SigninFlowProps> = ({ reset, ...props }) => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const token = params.get('token') || '';
  const invitedEmail = params.get('email') || '';

  const [email, onChangeEmail, emailError] = useInput(
    invitedEmail,
    validateEmail,
  );
  //readonlyのケースもあるので初回に一度バリデーションを実行しておく
  useEffect(() => {
    invitedEmail && onChangeEmail({ target: { value: invitedEmail } });
  }, [invitedEmail, onChangeEmail]);

  const [page, setPage] = useState(1);
  const {
    user,
    signin: newSignin,
    signinLoading,
    redirectToMfa,
    redirectToConfirmSignup,
  } = useSignin();

  useEffect(() => {
    return () => {
      reset();
    };
  }, [reset]);

  useEffect(() => {
    if (redirectToConfirmSignup === true) {
      setPage(2);
    }
  }, [redirectToConfirmSignup]);

  useEffect(() => {
    if (redirectToMfa === true) {
      setPage(3);
    }
  }, [redirectToMfa]);

  if (page === 1) {
    return (
      // @ts-expect-error
      <Signin
        {...props}
        email={email}
        onChangeEmail={onChangeEmail}
        emailError={emailError}
        newSignin={newSignin}
        signinLoading={signinLoading}
        token={token}
      />
    );
  }
  if (page === 2) {
    // @ts-expect-error
    return <ConfirmSignup {...props} email={email} />;
  }
  if (page === 3) {
    return <MultiFactorAuth {...props} tempUser={user} />;
  }
  return <></>;
};

export default SigninFlow;
