import { API, graphqlOperation } from 'aws-amplify';

import {
  createApi,
  updateApi,
  updateApiDescription,
  resetImportProgress as resetImportProgressUsecase,
} from '@/usecase/apiUsecase';

import actions from './actions';
import * as queries from '../../graphql/queries';
import { loadApis, loadApi } from '../apiList/operations';

export const checkSameEndpoint = (service, apiEndpoint) => async (dispatch) => {
  const canCreate = (
    await API.graphql(
      graphqlOperation(queries.checkApiEndpoint, {
        serviceId: service.partitionKey,
        endpoint: apiEndpoint,
      }),
    )
  ).data.checkApiEndpoint;
  const action = canCreate
    ? actions.networking.findNoSameEndpoint()
    : actions.networking.findSameEndpoint();
  dispatch(action);
};

export const postApi =
  (apiName, apiEndpoint, apiType, apiFields, customFields, service) =>
  async (dispatch) => {
    try {
      const result = await createApi({
        serviceId: service.partitionKey,
        apiName,
        apiEndpoint,
        apiType,
        apiFields,
        customFields,
      });

      dispatch(actions.networking.successPostApi(result));
      dispatch(loadApis(service));
    } catch (error) {
      dispatch(actions.networking.failurePostApi(error));
    }
  };

export const putApi =
  ({
    apiId,
    name,
    endpoint,
    fields,
    previewUrl,
    previewLinkIsVisible,
    sortOrderValue,
    contentIdSetting,
  }) =>
  async (dispatch) => {
    try {
      const result = await updateApi({
        apiId,
        name,
        endpoint,
        fields,
        previewUrl,
        previewLinkIsVisible,
        sortOrderValue,
        contentIdSetting,
      });

      dispatch(actions.networking.successPutApi(result));
      dispatch(loadApi(apiId));
      if (endpoint !== undefined) {
        // エンドポイントを変更する場合は変更後のエンドポイント用のURLにリダイレクトさせる
        window.location.href = `/apis/${endpoint}/settings`;
      }
      setTimeout(() => dispatch(actions.resetApi()), 3000); // モーダルをしばらくしたら消す
    } catch (error) {
      // incident-80: https://microcmsio.slack.com/archives/C071EB5UNDC タイムアウトエラーの場合は無視して成功として扱う
      if (error.message === 'Execution timed out.') {
        dispatch(actions.networking.successPutApi());
        dispatch(loadApi(apiId));

        setTimeout(() => dispatch(actions.resetApi()), 3000); // モーダルをしばらくしたら消す
        return;
      }

      dispatch(actions.networking.failurePutApi(error));
      setTimeout(() => dispatch(actions.resetApi()), 6000); // モーダルをしばらくしたら消す
    }
  };

export const putApiDescription =
  ({ apiId, description }) =>
  async (dispatch) => {
    try {
      await updateApiDescription({ apiId, description });

      dispatch(actions.networking.successPutApiDescription());
      dispatch(loadApi(apiId));
      setTimeout(() => dispatch(actions.resetApi()), 3000); // モーダルをしばらくしたら消す
    } catch {
      dispatch(actions.networking.failurePutApiDescription());
    }
  };

/**
 * インポートのステータスをリセットする
 */
export const resetImportProgress = (apiId) => async (dispatch) => {
  try {
    await resetImportProgressUsecase({ apiId });
    dispatch(loadApi(apiId));
  } catch {
    // TODO: エラー処理
  }
};

export const resetApi = actions.resetApi;
