import { createActions } from 'redux-actions';

const actions = createActions({
  NETWORKING: {
    FIND_SAME_ENDPOINT: undefined,
    FIND_NO_SAME_ENDPOINT: undefined,
    SUCCESS_POST_API: (param) => param,
    FAILURE_POST_API: (error) => error,
    SUCCESS_PUT_API: (param) => param,
    FAILURE_PUT_API: (error) => error,
    SUCCESS_PUT_API_DESCRIPTION: (param) => param,
    FAILURE_PUT_API_DESCRIPTION: (error) => error,
    SUCCESS_POST_OR_PUT_AUTH: () => undefined,
    FAILURE_POST_OR_PUT_AUTH: (error) => error,
  },
  RESET_API: undefined,
});

export default actions;
