import * as Popover from '@radix-ui/react-popover';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import ContentHistories from '../ContentHistories';
import Member from '../Member';

import type { MigrateApi } from '@/entity/api';
import type { ContentStatus } from '@/entity/content';
import type { Contents } from '@/types/contents';
import type { CustomState } from '@/types/customState';
import type { Service } from '@/types/services';

import styles from './editContent.module.css';

import { useHasUpdateContentPermission } from '@/views/Common/Roles/MyRolesPermission';
import { Icon } from '@/views/Common/Ui/Icon';
import { Modal, ModalTrigger } from '@/views/Common/Ui/Modal';
import { SideModal, SideModalTrigger } from '@/views/Common/Ui/SideModal';
import { useToggle } from '@/views/Common/Ui/useToggle';
import { UpdateContentCreatedByModal } from '@/views/EditContent/UpdateContentCreatedByModal/UpdateContentCreatedByModal';
import { UpdatePublishedAtModal } from '@/views/EditContent/UpdatePublishedAtModal';

type Props = {
  publishedAt: string;
  updatedAt: string;
  editorId: string;
  authorId: string;
  needSave: () => boolean;
  contentStatus: ContentStatus;
  customStatus?: [CustomState];
  userDefinedContentId: string;
  partitionKey: string;
  updateContentIdLoading: boolean;
  service: Service;
  endpoint: string;
  setSelectedHistory: React.Dispatch<React.SetStateAction<Contents | null>>;
  apiId: MigrateApi['partitionKey'];
  enableSelectHistory: boolean;
};

const MetaRight: React.FC<Props> = ({
  publishedAt,
  updatedAt,
  editorId,
  authorId,
  needSave,
  contentStatus,
  customStatus,
  userDefinedContentId,
  partitionKey,
  updateContentIdLoading,
  service,
  endpoint,
  setSelectedHistory,
  apiId,
  enableSelectHistory,
}) => {
  const { t } = useTranslation('editContent');

  const { hasUpdateContentPermission } = useHasUpdateContentPermission();

  const [
    isOpenUpdateCreatedByModal,
    toggleUpdateCreatedByModal,
    ,
    closeUpdateCreatedByModal,
  ] = useToggle(false);

  return (
    <div className={styles.metaRight}>
      {publishedAt && (
        <dl className={styles.keyValue}>
          <dt className={styles.key}>{t('Date Published')}</dt>
          <dd>
            <UpdatePublishedAtModal
              needSave={needSave}
              contentStatus={contentStatus}
              contentId={partitionKey}
              publishedAt={publishedAt}
              disabledOfTrigger={updateContentIdLoading}
              apiId={apiId}
            />
          </dd>
        </dl>
      )}
      <dl className={styles.keyValue}>
        <dt className={styles.key}>{t('Created By')}</dt>
        {!hasUpdateContentPermission('changeAuthor', apiId) ? (
          authorId && (
            <dd className={styles.editor}>
              <Member username={authorId} imageOnly={true} />
            </dd>
          )
        ) : (
          <Modal
            open={isOpenUpdateCreatedByModal}
            onOpenChange={toggleUpdateCreatedByModal}
          >
            <ModalTrigger className={styles.linkButton}>
              {authorId && (
                <>
                  <dd className={styles.editor}>
                    <Member username={authorId} imageOnly={true} />
                  </dd>
                  <Icon name="edit" />
                </>
              )}
            </ModalTrigger>
            <UpdateContentCreatedByModal
              service={service}
              apiEndpoint={endpoint}
              editorId={authorId}
              userDefinedContentId={userDefinedContentId}
              closeModal={closeUpdateCreatedByModal}
            />
          </Modal>
        )}
      </dl>
      <dl className={styles.keyValue}>
        <dt className={styles.key}>{t('Last Updated')}</dt>
        <dd>{dayjs(updatedAt).fromNow()}</dd>
        {editorId && (
          <dd className={styles.editor}>
            <Member username={editorId} imageOnly={true} />
          </dd>
        )}
      </dl>
      <SideModal>
        <SideModalTrigger className={styles.linkButton}>
          <Icon name="history" />
          <span>{t('Update History')}</span>
        </SideModalTrigger>
        <ContentHistories
          previewHistory={setSelectedHistory}
          partitionKey={partitionKey}
          customStatus={customStatus}
          enableSelectHistory={enableSelectHistory}
          apiEndpoint={endpoint}
          userDefinedContentId={userDefinedContentId}
        />
      </SideModal>
    </div>
  );
};

const MetaRightWrapper: React.FC<Props> = (props) => {
  const { t } = useTranslation('editContent');

  return (
    <>
      <Popover.Root>
        <Popover.Trigger
          className={styles.metaRightMoreIcon}
          aria-label={t('Other operations on content')}
        >
          <Icon name="more_horiz" size="large" />
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className={styles.metaRightPopoverContent}
            style={{ zIndex: 20 }}
            align="end"
            hideWhenDetached
          >
            <MetaRight {...props} />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      <MetaRight {...props} />
    </>
  );
};

export default MetaRightWrapper;
