import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { createAccountLanguage as createAccountLanguageUsecase } from '@/usecase/accountUsecase';

import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { changeLanguage } from '../../i18n';

export const useGetAccountLanguage = () => {
  const getAccountLanguageQuery = useQuery({
    queryKey: ['accountLanguage'],
    queryFn: async () => {
      const response: any = await API.graphql(
        graphqlOperation(queries.getAccountLanguage),
      );

      return response.data.getAccountLanguage;
    },
  });

  const { data: accountLanguage } = getAccountLanguageQuery;

  return { accountLanguage };
};

export const useCreateAccount = () => {
  const { t } = useTranslation('hooksAccount');
  const history = useHistory();
  const { addToast } = useToasts();

  const {
    mutate: createAccount,
    isLoading: createAccountLoading,
    isSuccess: createCreateResult,
  } = useMutation<any, Error, any>({
    mutationFn: async ({ question }) => {
      const response: any = await API.graphql(
        graphqlOperation(mutations.createAccount, {
          value: {
            question,
          },
        }),
      );
      const result = response.data.createAccount;

      return { result };
    },
    onSuccess(data, { redirectPath }) {
      if (data.result.result === false) {
        const errorMessage = data.result.message;
        addToast(
          errorMessage ? errorMessage : t('Could not send the survey.'),
          {
            appearance: 'error',
          },
        );
        return;
      }
      addToast(t('Survey has been sent.'), { appearance: 'success' });
      history.push(redirectPath);
    },
    onError(result: any) {
      const errorMessage = result.message;
      addToast(errorMessage ? errorMessage : t('Could not send the survey.'), {
        appearance: 'error',
      });
    },
  });

  return {
    createAccount,
    createAccountLoading,
    createCreateResult,
  };
};

export const useCreateAccountLanguage = () => {
  const { mutate: createAccountLanguage } = useMutation<any, Error, any>({
    mutationFn: async ({ userLanguage }) => {
      return createAccountLanguageUsecase(userLanguage);
    },
  });

  return {
    createAccountLanguage,
  };
};

type Data = {
  result: {
    result: boolean;
    message: string;
  };
  userLanguage: 'ja' | 'en';
};

type Variables = {
  userLanguage: 'ja' | 'en';
};

export const useUpdateAccountLanguage = () => {
  const { t } = useTranslation('hooksAccount');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  const {
    mutate: updateAccountLanguage,
    isLoading: updateAccountLanguageLoading,
    isSuccess: updateAccountLanguageLoadingResult,
  } = useMutation<Data, Error, Variables>({
    mutationFn: async ({ userLanguage }) => {
      const response: any = await API.graphql(
        graphqlOperation(mutations.updateAccountLanguage, {
          value: {
            userLanguage,
          },
        }),
      );
      const result = response.data.updateAccountLanguage;

      return { result, userLanguage };
    },
    onSuccess({ result, userLanguage }) {
      if (result.result === false) {
        addToast(result.message, {
          appearance: 'error',
        });
        return;
      }

      cache.invalidateQueries(['accountLanguage'], { type: 'all' });
      changeLanguage(userLanguage);
      addToast(t('Changed language.'), { appearance: 'success' });
    },
    onError({ message }) {
      addToast(message ? message : t('Language could not be changed.'), {
        appearance: 'error',
      });
    },
  });

  return {
    updateAccountLanguage,
    updateAccountLanguageLoading,
    updateAccountLanguageLoadingResult,
  };
};

export const useUpdateAccountSurvey = () => {
  const { t } = useTranslation('hooksAccount');
  const { addToast } = useToasts();
  const history = useHistory();

  const {
    mutate: updateAccountSurvey,
    isLoading: updateAccountSurveyLoading,
    isSuccess: updateAccountSurveyLoadingResult,
  } = useMutation<any, Error, any>({
    mutationFn: async ({ question }) => {
      const response: any = await API.graphql(
        graphqlOperation(mutations.updateAccountSurvey, {
          value: {
            question,
          },
        }),
      );
      const result = response.data.updateAccountSurvey;

      return { result };
    },
    onSuccess(data, { redirectPath }) {
      if (data.result.result === false) {
        const errorMessage = data.result.message;
        addToast(
          errorMessage ? errorMessage : t('Could not send the survey.'),
          {
            appearance: 'error',
          },
        );
        return;
      }
      addToast(t('Survey has been sent.'), { appearance: 'success' });
      history.push(redirectPath);
    },
    onError({ message }) {
      addToast(message ? message : t('Could not send the survey.'), {
        appearance: 'error',
      });
    },
  });

  return {
    updateAccountSurvey,
    updateAccountSurveyLoading,
    updateAccountSurveyLoadingResult,
  };
};
