import { useCallback, useMemo } from 'react';

import { useExceptionPermissionIsHaveLeastOne } from '@/hooks/Role/useMyRoles';

import type { MigrateApi } from '@/entity/api';
import type { ContentStatus } from '@/entity/content';

import { getContentUpdatePermissionEntryByStatus } from '@/util/permissions/content';
import {
  useHasCreateContentPermission,
  useHasUpdateContentPermission,
} from '@/views/Common/Roles/MyRolesPermission';

/**
 * コンテンツ一覧でコンテンツ操作の権限をチェックするカスタムフック
 */
function useContentOperationPermissionOnContentList(input: {
  apiId: MigrateApi['partitionKey'];
}) {
  const { hasCreateContentPermissionForAny } = useHasCreateContentPermission();
  const { hasUpdateContentPermission } = useHasUpdateContentPermission();
  const [hasDeleteContentPermission] = useExceptionPermissionIsHaveLeastOne(
    input.apiId,
    'contentDelete',
  );

  /**
   * コンテンツの作成権限がある場合はtrue
   * 新規作成時に公開または下書き保存の権限があるかで判断する
   */
  const hasCreatePermission = useMemo(
    () => hasCreateContentPermissionForAny(input.apiId),
    [input.apiId, hasCreateContentPermissionForAny],
  );

  /**
   * 対象のコンテンツに対して、並び替え権限がある場合はtrue
   */
  const hasReorderPermission = useCallback(
    (contentStatus: ContentStatus) => {
      return hasUpdateContentPermission(
        getContentUpdatePermissionEntryByStatus('reorder', contentStatus),
        input.apiId,
      );
    },
    [input.apiId, hasUpdateContentPermission],
  );

  /**
   * 公開権限がある場合はtrue
   */
  const hasPublishPermission = useMemo(() => {
    return hasUpdateContentPermission('publish', input.apiId);
  }, [input.apiId, hasUpdateContentPermission]);

  /**
   * 「公開中コンテンツを下書きに戻す」の権限がある場合はtrue
   */
  const hasRevertToDraftPublishedPermission = useMemo(() => {
    return hasUpdateContentPermission('revertToDraft', input.apiId);
  }, [input.apiId, hasUpdateContentPermission]);

  /**
   * 「非公開コンテンツを下書きに戻す」の権限がある場合はtrue
   */
  const hasRevertToDraftUnpublishedPermission = useMemo(() => {
    return hasUpdateContentPermission('revertToDraftUnpublished', input.apiId);
  }, [input.apiId, hasUpdateContentPermission]);

  /**
   * コンテンツの一括操作ができる場合はtrue
   *
   * 一括操作は次のものがある
   * - 削除
   * - 公開
   * - 下書きに戻す
   *
   * それぞれのうちに1つでも権限があればtrueを返す
   */
  const hasBulkOperationPermission = useMemo(() => {
    return (
      hasDeleteContentPermission ||
      hasPublishPermission ||
      hasRevertToDraftPublishedPermission ||
      hasRevertToDraftUnpublishedPermission
    );
  }, [
    hasDeleteContentPermission,
    hasPublishPermission,
    hasRevertToDraftPublishedPermission,
    hasRevertToDraftUnpublishedPermission,
  ]);

  return {
    hasCreatePermission,
    hasDeleteContentPermission,
    hasReorderPermission,
    hasPublishPermission,
    hasRevertToDraftPublishedPermission,
    hasRevertToDraftUnpublishedPermission,
    hasBulkOperationPermission,
  };
}

export { useContentOperationPermissionOnContentList };
