import { Td } from '@/components/ui/Table';

import { useI18nFormRole } from '../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../provider';

import { Select } from '@/views/Common/Roles/Select';
import { Checkbox } from '@/views/Common/Ui/Checkbox';

const FieldMedium = () => {
  const { t } = useI18nFormRole();

  const {
    isAdminRole,
    input: { mediumPermission },
  } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  return (
    <>
      <Td>{t('Media')}</Td>
      <Td>
        <Select
          value={mediumPermission.readLevel}
          onValueChange={(value) =>
            dispatch({
              type: 'set-medium-permission-read',
              payload: value,
            })
          }
          disabled={disabled}
          name="mediumPermission.readLevel"
          items={[
            {
              value: 'ALL',
              label: t('All media'),
              description: t('Able to view all media (images, files, etc.).'),
            },
            {
              value: 'SAME_ROLE',
              label: t('Only media uploaded by members of this role'),
              description: t(
                'Able to view only media uploaded by members in the same role.',
              ),
            },
            {
              value: 'CREATED',
              label: t('Only media uploaded by yourself'),
              description: t('Able to view only media uploaded by you.'),
            },
            {
              value: 'NONE',
              label: t('None'),
              description: t('Media cannot be viewed.'),
            },
          ]}
        />
      </Td>
      <Td>
        <Checkbox
          checked={mediumPermission.create}
          disabled={disabled}
          name="mediumPermission.create"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-medium-permission-create',
              payload,
            });
          }}
          label={t('Upload')}
        />
      </Td>
      <Td>
        <Checkbox
          checked={mediumPermission.update}
          disabled={disabled}
          name="mediumPermission.update"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-medium-permission-update',
              payload,
            });
          }}
          label={t('Re-upload')}
        />
      </Td>
      <Td>
        <Checkbox
          checked={mediumPermission.delete}
          disabled={disabled}
          name="mediumPermission.delete"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-medium-permission-delete',
              payload,
            });
          }}
          label={t('Delete media')}
        />
      </Td>
    </>
  );
};

export default FieldMedium;
