import cx from 'classnames';
import { useId } from 'react';

import type { ComponentProps } from 'react';

import styles from './checkbox.module.css';

export type Props = {
  label?: string;
  className?: string;
} & ComponentProps<'input'>;

export type ViewProps = {
  inputId: string;
} & Props;

export const CheckboxView: React.FC<ViewProps> = ({
  inputId,
  label,
  className,
  ...props
}) => {
  return (
    <div className={cx(styles.main, className)}>
      <input
        type="checkbox"
        id={inputId}
        className={cx(styles.input, styles.override)}
        {...props}
      />
      {label && (
        <label htmlFor={inputId} className={styles.label}>
          {label}
        </label>
      )}
    </div>
  );
};

export const Checkbox: React.FC<Props> = (props) => {
  const inputId = useId();

  return <CheckboxView inputId={inputId} {...props} />;
};
