import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoadApis } from '@/hooks/ApiList/useApiListReader';
import { useReviews } from '@/hooks/Review/useReviews';
import { useGetMyService } from '@/hooks/useService';

import Head from '../Head';

import type { ReviewRequest } from '@/entity/review';
import type { CognitoUser } from '@/entity/user';

import styles from './reviewList.module.css';

import { ReviewList as ReviewListView } from '@/views/ReviewList';

type Props = {
  cognitoUser: CognitoUser;
};

const ReviewList: React.FC<Props> = ({ cognitoUser }) => {
  const { t } = useTranslation('reviewList');
  const { service } = useGetMyService();
  const [selectedStatus, setStatus] = useState<'OPEN' | 'CLOSE'>('OPEN');
  const [reviewRequestsQuery] = useReviews(
    service,
    cognitoUser.username,
    selectedStatus,
  );
  const { fetchNextPage, data, hasNextPage } = reviewRequestsQuery;

  const { apiList } = useLoadApis(service);
  const apiIds = apiList && apiList.list.map((api) => api.partitionKey);

  // TODO:listに型を直接当てているが、useReviewsの返り値で型推論できるように変更する
  const list: ReviewRequest[] | undefined = useMemo(() => {
    return data ? data.pages.flatMap(({ items }) => items) : undefined;
  }, [data]);

  return (
    <div className={styles.wrapper}>
      <Head title={t('Reviews')} />
      {apiIds && (
        <ReviewListView
          apiIds={apiIds}
          reviewList={list}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          selectedStatus={selectedStatus}
          setStatus={setStatus}
        />
      )}
    </div>
  );
};

export default ReviewList;
