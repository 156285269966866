import { authRepository } from '@/repository/authRepository';

export const verifyMemberInvitation = async ({ token }: { token: string }) => {
  const { verifyMemberInvitation } = authRepository();
  return await verifyMemberInvitation({
    token,
  });
};

export const acceptMemberInvitation = async () => {
  const { acceptMemberInvitation } = authRepository();
  return await acceptMemberInvitation();
};
