import type { ReviewRequestEvent } from '@/entity/review';
import { useMemo, useState } from 'react';

type DisplayEventNode = {
  key: string;
} & (
  | {
      type: 'expanded';
      event: ReviewRequestEvent;
      expandEvents?: undefined;
    }
  | {
      type: 'omitted';
      event?: undefined;
      expandEvents: () => void;
    }
);

/**
 * レビューイベントの表示を制御するカスタムフック
 * 「UPDATE_CONTENT」イベントが10件以上続いている箇所は、初期表示では省略して表示する。
 */
const useDisplayEvents = (events: ReviewRequestEvent[]): DisplayEventNode[] => {
  const [expandedStartIndex, setExpandedStartIndex] = useState<number[]>([]);

  return useMemo(() => {
    const result: DisplayEventNode[] = [];

    // 「UPDATE_CONTENT」イベントが10件以上続いている箇所は、最初と最後の2件ずつ以外は省略して表示する。
    for (let i = 0; i < events.length; i++) {
      // 続いているUPDATE_CONTENTの数を数える
      let updateContentCount = 0;
      for (let j = i; j < events.length; j++) {
        if (events[j].type === 'UPDATE_CONTENT') {
          updateContentCount++;
        } else {
          break;
        }
      }

      // UPDATE_CONTENTが10件以上続いている場合
      if (updateContentCount >= 10) {
        if (expandedStartIndex.includes(i)) {
          for (let j = i; j < i + updateContentCount; j++) {
            result.push({
              type: 'expanded',
              key: events[j].eventId,
              event: events[j],
            });
          }

          i += updateContentCount - 1;
          continue;
        }

        // ループ内でiを変更するため、currentIndexを別変数に格納（これがないとexpandEventsで加算されたiが適用される）
        const currentIndex = i;

        result.push({
          type: 'expanded',
          key: events[currentIndex].eventId,
          event: events[currentIndex],
        });
        result.push({
          type: 'expanded',
          key: events[currentIndex + 1].eventId,
          event: events[currentIndex + 1],
        });
        result.push({
          type: 'omitted',
          key: `${events[currentIndex + 2].eventId}-${events[currentIndex + updateContentCount - 3].eventId}`,
          expandEvents: () => {
            setExpandedStartIndex((prev) => [...prev, currentIndex]);
          },
        });
        result.push({
          type: 'expanded',
          key: events[currentIndex + updateContentCount - 2].eventId,
          event: events[currentIndex + updateContentCount - 2],
        });
        result.push({
          type: 'expanded',
          key: events[currentIndex + updateContentCount - 1].eventId,
          event: events[currentIndex + updateContentCount - 1],
        });

        i += updateContentCount - 1;
        continue;
      }

      // UPDATE_CONTENTが10件未満の場合はそのまま表示（updateContentCountの計算を何度もしないようにパフォーマンス考慮の実装）
      if (updateContentCount > 0) {
        for (let j = i; j < i + updateContentCount; j++) {
          result.push({
            type: 'expanded',
            key: events[j].eventId,
            event: events[j],
          });
        }

        i += updateContentCount - 1;
        continue;
      }

      result.push({
        type: 'expanded',
        key: events[i].eventId,
        event: events[i],
      });
    }

    return result;
  }, [events, expandedStartIndex]);
};

export { useDisplayEvents };
