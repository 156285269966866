import cx from 'classnames';
import dayjs from 'dayjs';
import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDatePicker } from '../../../util/date';
import { CONDITIONS } from '../../Media/useMediaFilter';
import Selectfield from '../../ui/Selectfield';
import { TagsRow } from './TagsRow';
import { useAllMembers } from './useAllMembers';

import type { MediumTag } from '@/types/MediumTag';
import type { ListMediumTags } from '@/types/MediumTag';
import type { Filter } from '../../Media/useMediaFilter';

import styles from './row.module.css';

import { DatePicker } from '@/views/Common/Ui/DatePicker';

type Format = 'image' | 'video' | 'audio' | 'pdf' | 'csv';
const formats: Format[] = ['image', 'video', 'audio', 'pdf', 'csv'];

type Props = {
  serviceId: string;
  filter: Filter;
  filtersArray: Filter[];
  setFiltersArray: React.Dispatch<React.SetStateAction<Filter[]>>;
  removeFilter: (index: string) => void;
  listMediumTags?: ListMediumTags;
};

const Row: React.FC<Props> = ({
  serviceId,
  filter,
  filtersArray,
  setFiltersArray,
  removeFilter,
  listMediumTags,
}) => {
  const { t } = useTranslation('mediaFilterRow');
  const { id, key, condition, value } = filter;
  const { data: members } = useAllMembers(serviceId);
  const sortedMembers = useMemo(() => {
    return members?.sort((a, b) =>
      (a.name || a.email).localeCompare(b.name || b.email),
    );
  }, [members]);

  const onChangeKey = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const key = e.target.value;
      const value =
        key === 'createdBy' && members
          ? members[0].username
          : key === 'format'
            ? formats[0]
            : '';
      const newFiltersArray = filtersArray.map((filterArray) => {
        if (filterArray.id === id) {
          return {
            ...filterArray,
            key,
            value,
            condition: CONDITIONS[key][0],
          };
        }
        return filterArray;
      });
      setFiltersArray(newFiltersArray);
    },
    [filtersArray, id, members, setFiltersArray],
  );

  const isInvalidDate = (date: Date) =>
    date && date.getDate ? Number.isNaN(date.getDate()) : false;
  const [date, setDate] = useState<Date | undefined>(
    isInvalidDate(new Date(value)) ? undefined : dayjs(value).toDate(),
  );
  const onChangeDate = useCallback(
    (date: Date) => {
      setDate(date);
      const newFiltersArray = filtersArray.map((filterArray) => {
        if (filterArray.id === id) {
          return {
            ...filterArray,
            value: date ? dayjs(date).toISOString() : '',
          };
        }
        return filterArray;
      });
      setFiltersArray(newFiltersArray);
    },
    [filtersArray, setFiltersArray, id],
  );

  const onChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newFiltersArray = filtersArray.map((filterArray) => {
        if (filterArray.id === id) {
          return {
            ...filterArray,
            value: e.target.value,
          };
        }
        return filterArray;
      });
      setFiltersArray(newFiltersArray);
    },
    [filtersArray, id, setFiltersArray],
  );

  const [tagName, setTagName] = useState<MediumTag['name']>(
    listMediumTags?.tags.find((tag) => tag.mediumTagId === value)?.name ?? '',
  );

  const searchTags = useMemo(() => {
    if (!listMediumTags) {
      return [];
    }

    return listMediumTags.tags.filter((tag) => tag.name.includes(tagName));
  }, [listMediumTags, tagName]);

  const onChangeTags = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTagName(e.target.value);
      const newFiltersArray = filtersArray.map((filterArray) => {
        if (filterArray.id === id) {
          return {
            ...filterArray,
            value:
              listMediumTags?.tags?.find((tag) => tag.name === e.target.value)
                ?.mediumTagId ?? '',
          };
        }
        return filterArray;
      });
      setFiltersArray(newFiltersArray);
    },
    [filtersArray, id, listMediumTags?.tags, setFiltersArray],
  );

  const handleTagSubmit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, value: string) => {
      const newFiltersArray = filtersArray.map((filterArray) => {
        if (filterArray.id === id) {
          return {
            ...filterArray,
            value,
          };
        }
        return filterArray;
      });
      setTagName(
        searchTags?.find((tag) => tag.mediumTagId === value)?.name ?? '',
      );
      setFiltersArray(newFiltersArray);
      e.stopPropagation();
    },
    [filtersArray, id, searchTags, setFiltersArray],
  );

  const onChangeCondition = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newFiltersArray = filtersArray.map((filterArray) => {
        if (filterArray.id === id) {
          return {
            ...filterArray,
            condition: e.target.value,
          };
        }
        return filterArray;
      });
      setFiltersArray(newFiltersArray);
    },
    [filtersArray, id, setFiltersArray],
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <Selectfield
          value={key}
          onChange={onChangeKey}
          className={styles.firstField}
          size="full"
        >
          <option value="format">{t('File Format')}</option>
          <option value="createdAt">{t('Date Created')}</option>
          {(members?.length ?? 0) > 0 && (
            <option value="createdBy">{t('Created By')}</option>
          )}
          <option value="tags">{t('Tag')}</option>
        </Selectfield>
        {key === 'createdAt' && (
          <DatePicker
            reactDatePickerProps={{
              selected: date,
              onChange: onChangeDate,
              dateFormat: formatDatePicker({ dateFormat: true }),
              timeCaption: t('Time'),
            }}
            className={cx(styles.datePickerWrapper, styles.override)}
          />
        )}
        {key === 'createdBy' && (
          <select
            className={styles.secondField}
            value={value}
            onChange={onChangeValue}
          >
            {sortedMembers?.map((member) => {
              return (
                <option value={member.username} key={member.username}>
                  {member.name || member.email.split('@')[0]}
                </option>
              );
            })}
          </select>
        )}
        {key === 'format' && (
          <select
            className={styles.secondField}
            value={value}
            onChange={onChangeValue}
          >
            {formats?.map((format, index: number) => {
              return (
                <option value={format} key={index}>
                  {t(format)}
                </option>
              );
            })}
          </select>
        )}
        {key === 'tags' && (
          <TagsRow
            tagName={tagName}
            searchTags={searchTags}
            onChangeTags={onChangeTags}
            handleTagSubmit={handleTagSubmit}
          />
        )}
        <select
          name=""
          id=""
          className={styles.thirdField}
          value={condition}
          onChange={onChangeCondition}
        >
          {CONDITIONS[key].map((condition, i: number) => (
            <option key={i} value={condition}>
              {t(condition)}
            </option>
          ))}
        </select>
        <button onClick={() => removeFilter(id)} className={styles.removeBtn}>
          <i className="material-icons">delete</i>
        </button>
      </div>
      <p className={styles.and}>{t('And')}</p>
    </div>
  );
};

export default Row;
