import type { Role, RoleReadLevel } from '@/entity/role';

/**
 * rolePermissionのreadとreadLevelの値からRoleReadLevelを取得する
 *
 * NOTE: 「rolePermission.readのマイグレーション後は削除」というコメントがあるが、念の為このロジックは残しておく
 */
function getRolePermissionReadIsomorphic(
  rolePermissionRead: Pick<Role['rolePermission'], 'read' | 'readLevel'>,
): RoleReadLevel {
  if (!rolePermissionRead.readLevel) {
    if (rolePermissionRead.read === true) {
      return 'ALL';
    } else if (rolePermissionRead.read === false) {
      return 'NONE';
    } else {
      return 'ALL';
    }
  }

  return rolePermissionRead.readLevel;
}

export { getRolePermissionReadIsomorphic };
