import { useQuery } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from '../../graphql/queries';

import type { ApiKey } from '../../types/apiKey';

export const useApiKeysReader = (serviceId: string) => {
  const apiQuery = useQuery({
    queryKey: ['apiKey'],
    queryFn: async () => {
      if (serviceId) {
        const result: any = await API.graphql(
          graphqlOperation(queries.readApiKey, { serviceId }),
        );
        return result.data.readApiKey as ApiKey[];
      }
    },
    staleTime: Number.POSITIVE_INFINITY,
  });
  const { data: apiKeys } = apiQuery;

  return [apiKeys];
};

export const useApiKeyReader = (serviceId: string, apiKeyId: string) => {
  const apiQuery = useQuery({
    queryKey: ['apiKey'],
    queryFn: async () => {
      if (apiKeyId) {
        const result: any = await API.graphql(
          graphqlOperation(queries.readApiKey, { serviceId, apiKeyId }),
        );

        return result.data.readApiKey as ApiKey[];
      }
    },
    staleTime: Number.POSITIVE_INFINITY,
  });
  const { data, isLoading: apiKeyIsLoading } = apiQuery;
  const apiKey = data?.find((data) => data.apiKeyId === apiKeyId);

  return { apiKey, apiKeyIsLoading };
};

// メソッドごとに権限が許可されたAPIキーのみを取得するHooks
export const useValidApiKey = (
  serviceId: string,
  apiId: string,
  method: 'get' | 'getAllDraft' | 'post' | 'put' | 'patch' | 'delete',
): ApiKey | undefined => {
  const [apiKeys] = useApiKeysReader(serviceId);

  return apiKeys?.find((apiKey) => {
    // デフォルト権限判定
    const defaultPermission = apiKey.defaultPolicy[method];
    // 個別権限取得判定
    const priorityPolicy = apiKey.priorityPolicies.find(
      (policy) => policy.apiId === apiId,
    );
    const priorityPermission = priorityPolicy?.policy[method];
    // 与えられたメソッドの権限がtrueのAPIキーのみ返す
    return (
      (defaultPermission && priorityPermission !== false) || priorityPermission
    );
  });
};
