import { useTranslation } from 'react-i18next';

import styles from './notFound.module.css';

const NotFound = () => {
  const { t } = useTranslation('notFound');
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <img className={styles.img} src="/images/icon_not_found.svg" alt="" />
        <h2 className={styles.title}>{t('Page not found')}</h2>
      </div>
    </div>
  );
};

export default NotFound;
