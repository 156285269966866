import {
  useUpdateQueryParam,
  useDeleteQueryParam,
} from '@/hooks/useQueryParams';

import type { Direction } from './SortButtonTs';

export const useHandleSortButtonClick = (defaultOrderValue: string) => {
  const updateOrderParam = useUpdateQueryParam('order');
  const deleteOrderParam = useDeleteQueryParam('order');

  return (name: string, direction: Direction) => {
    if (direction === 'asc') {
      updateOrderParam(`-${name}`);
    } else if (name === defaultOrderValue) {
      deleteOrderParam();
    } else {
      updateOrderParam(name);
    }
  };
};

export const getSortDirection = (
  order: string,
  sortName: string,
): Direction => {
  if (sortName === order) {
    return 'asc';
  } else if (`-${sortName}` === order) {
    return 'desc';
  }
  return 'none';
};
