import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import CopyButton from '@/components/ui/CopyButton';

import { useApiKeysReader } from '@/hooks/ApiKey/useApiKeyReader';
import { myRolesContext } from '@/hooks/Role/useMyRoles';
import { useGetMyService } from '@/hooks/useService';

import ApiKeyModal from './ApiKeyModal';
import Head from '../Head';
import Member from '../Member';
import Button from '../ui/Button';
import Loading from '../ui/Loading';
import { Table, Thead, Tbody, Tr, Th, Td } from '../ui/Table';

import styles from './apikeylist.module.css';

import { formatDateToMinutes } from '@/util/date';
import { Modal, ModalTrigger } from '@/views/Common/Ui/Modal';
import { useToggle } from '@/views/Common/Ui/useToggle';

const ApiKeyList: React.FC = () => {
  const { t } = useTranslation('apiKeyList');
  const { service } = useGetMyService();
  const history = useHistory();
  const context = useContext(myRolesContext);
  // 管理者権限
  const isAdmin = useMemo(() => {
    return context.roles
      ? context.roles.some((role) => role.isAdmin === true)
      : undefined;
  }, [context.roles]);

  //モーダルの開閉
  const [isOpenCreateApiKeyModal, toggleOpenCreateApiKeyModal] =
    useToggle(false);
  const openCreateApiKeyModal = useCallback(() => {
    toggleOpenCreateApiKeyModal(true);
  }, [toggleOpenCreateApiKeyModal]);
  const closeCreateApiKeyModal = useCallback(() => {
    toggleOpenCreateApiKeyModal(false);
  }, [toggleOpenCreateApiKeyModal]);

  //APIキーの取得
  const [apiKeys] = useApiKeysReader(service?.partitionKey || '');

  if (!service?.partitionKey) return <Loading />;

  return (
    <div className={styles.wrapper}>
      <Head title={t('API Keys')} />
      {isAdmin && (
        <header className={styles.header}>
          <Modal
            open={isOpenCreateApiKeyModal}
            onOpenChange={toggleOpenCreateApiKeyModal}
          >
            <ModalTrigger asChild>
              <Button
                icon="add"
                value={t('Add')}
                type="secondary"
                onClick={openCreateApiKeyModal}
              />
            </ModalTrigger>
            {apiKeys && (
              <ApiKeyModal
                service={service}
                apiKeys={apiKeys}
                close={closeCreateApiKeyModal}
              />
            )}
          </Modal>
        </header>
      )}
      <main className={styles.main}>
        <div className={styles.tableWrap}>
          {apiKeys && apiKeys.length > 0 && (
            <Table>
              <Thead>
                <Tr>
                  <Th>{t('API Key Name')}</Th>
                  <Th>{t('API Key')}</Th>
                  <Th>{t('Created By')}</Th>
                  <Th>{t('Updated By')}</Th>
                  <Th>{t('Date Created')}</Th>
                  <Th>{t('Date Updated')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {apiKeys &&
                  apiKeys
                    .sort((newerApiKey, olderApiKey) =>
                      olderApiKey.createdAt.localeCompare(
                        newerApiKey.createdAt,
                      ),
                    )
                    .map((apiKey) => (
                      <Tr
                        key={apiKey?.apiKeyId}
                        onClick={() =>
                          history.push(
                            `/api-keys/${apiKey?.apiKeyId.replace(
                              'API_KEY#',
                              '',
                            )}`,
                          )
                        }
                        data-testid="api-key-list-table-row"
                      >
                        <Td>{apiKey?.label ? apiKey?.label : '-'}</Td>
                        <Td>
                          <div className={styles.row}>
                            <span className={styles.key}>
                              {`*******************${(apiKey?.value).slice(
                                -4,
                              )}`}
                            </span>
                            <CopyButton value={apiKey?.value} />
                          </div>
                        </Td>
                        <Td>
                          {apiKey?.createdBy ? (
                            <Member
                              username={apiKey?.createdBy}
                              theme="black"
                            />
                          ) : (
                            <p>-</p>
                          )}
                        </Td>
                        <Td>
                          {apiKey?.updatedBy ? (
                            <Member
                              username={apiKey?.updatedBy}
                              theme="black"
                            />
                          ) : (
                            <p>-</p>
                          )}
                        </Td>
                        <Td>{formatDateToMinutes(apiKey?.createdAt)}</Td>
                        <Td>{formatDateToMinutes(apiKey?.updatedAt)}</Td>
                      </Tr>
                    ))}
              </Tbody>
            </Table>
          )}
          {apiKeys?.length === 0 && (
            <div className={styles.empty}>
              <img
                className={styles.emptyIcon}
                src="/images/icon_empty.svg"
                alt=""
              />
              {t('No API Keys')}
              {isAdmin && (
                <button
                  className={styles.emptyActions}
                  onClick={openCreateApiKeyModal}
                >
                  <p>{t('Add API Key')}</p>
                </button>
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default ApiKeyList;
