import { connect } from 'react-redux';

import { accountSelectors } from '../../ducks/account';
import ReviewList from './ReviewList';

const mapStateToProps = ({ accountState }: any) => {
  return {
    cognitoUser: accountSelectors.getCognitoUser(accountState),
  };
};

export default connect(mapStateToProps)(ReviewList);
