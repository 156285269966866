import cx from 'classnames';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/ui/Button';
import IconButton from '@/components/ui/IconButton';

import { useDragAndDrop } from '@/hooks/DragAndDrop/useDragAndDrop';

import { useInputCustomClassField } from './useInputCustomClassField';

import type { CustomClass as CustomClassType } from '@/types/field';

import styles from './inputCustomClassField.module.css';

import { Input } from '@/views/Common/Ui/Input';
import { validateText } from '@/views/Common/validations';

export type Props = {
  customClassList: CustomClassType[];
  setCustomClassList: React.Dispatch<CustomClassType[]>;
};

export type ViewProps = {
  customClassList: CustomClassType[];
  addCustomClass: () => void;
  removeCustomClass: (id?: string | null) => () => void;
  onChangeName: (
    id?: string | null,
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeValue: (
    id?: string | null,
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  labelInDisplayNameId: string;
  labelInClassNameId: string;
  errorId: string;
  Handle: () => JSX.Element;
  draggable: boolean;
  onDragStart: (e: React.DragEvent<HTMLLIElement>) => void;
  onDragOver: (e: React.DragEvent<HTMLLIElement>) => void;
  onDragEnd: (e: React.DragEvent<HTMLLIElement>) => void;
  targetIndex: number;
};

export const InputCustomClassFieldView: React.FC<ViewProps> = ({
  customClassList,
  addCustomClass,
  removeCustomClass,
  onChangeName,
  onChangeValue,
  labelInDisplayNameId,
  labelInClassNameId,
  errorId,
  Handle,
  draggable,
  onDragStart,
  onDragOver,
  onDragEnd,
  targetIndex,
}) => {
  const { t } = useTranslation('inputCustomClassField');
  return (
    <div>
      {customClassList.length > 0 && (
        <div className={styles.labelContainer}>
          <div id={labelInDisplayNameId} className={styles.label}>
            {t('Display Name')}
          </div>
          <div id={labelInClassNameId} className={styles.label}>
            {t('class Name')}
          </div>
        </div>
      )}
      {customClassList.length !== 0 && (
        <ul className={styles.customClassList}>
          {customClassList.map((item, i) => (
            <li
              key={item?.id}
              data-index={i}
              className={
                Number(targetIndex) === i + 1 &&
                i === customClassList.length - 1
                  ? cx(styles.listItem, styles.isDragOver, styles.isNext)
                  : Number(targetIndex) === i
                    ? cx(styles.listItem, styles.isDragOver)
                    : styles.listItem
              }
              draggable={draggable}
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDragEnd={onDragEnd}
            >
              <span className={styles.handle}>
                <Handle />
              </span>
              <div className={styles.inputContainer}>
                <div className={styles.inputContent}>
                  <Input
                    type="text"
                    className={cx(styles.input, {
                      [styles.hasError]: item.name === '',
                    })}
                    placeholder={t('Marker')}
                    onChange={onChangeName(item?.id)}
                    defaultValue={item.name || ''}
                    aria-labelledby={labelInDisplayNameId}
                    aria-invalid={item.name === ''}
                    aria-describedby={errorId}
                  />
                  {item.name === '' && (
                    <p role="alert" id={errorId} className={styles.error}>
                      {validateText(item.name)}
                    </p>
                  )}
                </div>
                <div className={styles.inputContent}>
                  <Input
                    type="text"
                    className={cx(styles.input, {
                      [styles.hasError]: item.value === '',
                    })}
                    placeholder="marker"
                    onChange={onChangeValue(item?.id)}
                    defaultValue={item.value || ''}
                    aria-labelledby={labelInClassNameId}
                    aria-invalid={item.value === ''}
                    aria-describedby={errorId}
                  />
                  {item.value === '' && (
                    <p role="alert" id={errorId} className={styles.error}>
                      {validateText(item.value)}
                    </p>
                  )}
                </div>
              </div>
              <IconButton
                className={styles.clearButton}
                icon="clear"
                onClick={removeCustomClass(item?.id)}
                aria-label={t('Remove')}
              />
            </li>
          ))}
        </ul>
      )}
      <Button
        type="tertiary"
        value={t('Add')}
        onClick={addCustomClass}
        icon="add"
      />
    </div>
  );
};

export const InputCustomClassField: React.FC<Props> = ({
  customClassList,
  setCustomClassList,
}) => {
  const labelInDisplayNameId = useId();
  const labelInClassNameId = useId();
  const errorId = useId();

  const {
    addCustomClass,
    removeCustomClass,
    onChangeName,
    onChangeValue,
    onChangeOrder,
  } = useInputCustomClassField({
    customClassList,
    setCustomClassList,
  });

  const { Handle, draggable, onDragStart, onDragOver, onDragEnd, targetIndex } =
    // @ts-expect-error
    useDragAndDrop(onChangeOrder);

  return (
    <InputCustomClassFieldView
      customClassList={customClassList}
      addCustomClass={addCustomClass}
      removeCustomClass={removeCustomClass}
      onChangeName={onChangeName}
      onChangeValue={onChangeValue}
      labelInDisplayNameId={labelInDisplayNameId}
      labelInClassNameId={labelInClassNameId}
      errorId={errorId}
      Handle={Handle}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDragEnd={onDragEnd}
      targetIndex={targetIndex}
    />
  );
};
