import type { MigrateApi } from '@/entity/api';
import type { ContentPermission } from '@/entity/role';
import { useMyRolesContext } from '@/hooks/Role/useMyRoles';
import { isAdminRole } from '@/util/permissions/admin';
import { getExceptionContentPermissionByApiId } from '@/util/role';
import { useCallback } from 'react';

function useHasUpdateContentPermission() {
  const { roles } = useMyRolesContext();

  /**
   * 閲覧者がコンテンツのUPDATE権限を持っている場合はtrue
   *
   * @param permissionEntry 権限をチェックする項目
   * @param apiId 個別権限まで考慮する場合は、引数apiIdに対象のAPIのIDを指定してください
   */
  const hasUpdateContentPermission = useCallback(
    (
      permissionEntry: keyof ContentPermission['contentUpdate'],
      apiId?: MigrateApi['partitionKey'],
    ) => {
      if (!roles) {
        return false;
      }
      return roles.some((r) => {
        if (isAdminRole(r)) {
          return true;
        }

        const ecp = getExceptionContentPermissionByApiId(r, apiId);
        // NOTE: 個別権限があれば、そちらの権限設定が優先される
        const permission = ecp ? ecp.permission : r.defaultContentPermission;
        return permission.contentUpdate[permissionEntry];
      });
    },
    [roles],
  );

  return {
    hasUpdateContentPermission,
  };
}

export { useHasUpdateContentPermission };
