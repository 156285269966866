import { useRef, useState } from 'react';

import type { FieldKind } from '@/types/field';

type ValidKind = Extract<
  FieldKind,
  'select' | 'relation' | 'relationList' | 'custom' | 'repeater' | 'iframe'
>;

type AdditionalSettings =
  | {
      isOpenModal: false;
      kind: null;
    }
  | {
      isOpenModal: true;
      kind: ValidKind;
    };

type UseChangeKindReturn = {
  additionalSettings: AdditionalSettings;
  additionalSettingsReturnFocusRef: React.RefObject<HTMLButtonElement>;
  closeAdditionalSettingsModal: () => void;
  handleChangeKind: (kind: ValidKind) => void;
};

export const useChangeKind = (): UseChangeKindReturn => {
  const [additionalSettings, setAdditionalSettings] =
    useState<AdditionalSettings>({
      isOpenModal: false,
      kind: null,
    });

  const additionalSettingsReturnFocusRef = useRef<HTMLButtonElement>(null);

  const closeAdditionalSettingsModal = () => {
    setAdditionalSettings({
      isOpenModal: false,
      kind: null,
    });
  };

  const handleChangeKind = (kind: ValidKind) => {
    setAdditionalSettings({
      isOpenModal: true,
      kind,
    });
  };

  return {
    additionalSettings,
    additionalSettingsReturnFocusRef,
    closeAdditionalSettingsModal,
    handleChangeKind,
  };
};
