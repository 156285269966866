import { BulletList } from './BulletList';
import { OrderedList } from './OrderedList';

import type { RichEditorV2Options } from '@/constants/richEditorOptions';
import type { Editor } from '@tiptap/react';

import style from './list.module.css';

export type Props = {
  editor: Editor;
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
};

export type ViewProps = {
  editor: Editor;
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
};

export const ListView: React.FC<ViewProps> = ({
  editor,
  richEditorV2Options,
}) => {
  return (
    <div className={style.main}>
      {richEditorV2Options.includes('listBullet') && (
        <BulletList editor={editor} />
      )}
      {richEditorV2Options.includes('listOrdered') && (
        <OrderedList editor={editor} />
      )}
    </div>
  );
};

export const List: React.FC<Props> = ({ editor, richEditorV2Options }) => {
  return <ListView editor={editor} richEditorV2Options={richEditorV2Options} />;
};
