import type React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Fieldset from '../../ui/Fieldset';
import Switch from '../../ui/Switch';
import Textfield from '../../ui/Textfield';
import { validateImageFieldSize } from '../../Validations/index';
import styles from '../apiModel.module.css';

type ImageSize = {
  width?: number;
  height?: number;
};

type ImageSizeValidation = {
  imageSize: ImageSize;
};

type Props = {
  imageSizeValidation: ImageSizeValidation;
  idValue: string;
  onChange: (validation?: ImageSizeValidation) => void;
  onError: (error?: string) => void;
};

const ImageSizeValidationField: React.FC<Props> = ({
  imageSizeValidation,
  idValue,
  onChange = () => {},
  onError = () => {},
}) => {
  const { t } = useTranslation('imageSizeValidationField');
  const [enabled, setEnabled] = useState(() => !!imageSizeValidation);
  const [width, setWidth] = useState<number | undefined>(
    () => imageSizeValidation?.imageSize?.width,
  );
  const [height, setHeight] = useState<number | undefined>(
    () => imageSizeValidation?.imageSize?.height,
  );
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [validation, setValidation] = useState<ImageSizeValidation>();

  const onChangeWidth = useCallback(({ target }: any) => {
    setWidth(target.value ? Number(target.value) : undefined);
  }, []);

  const onChangeHeight = useCallback(({ target }: any) => {
    setHeight(target.value ? Number(target.value) : undefined);
  }, []);

  useEffect(() => {
    onError(errorText);
  }, [errorText, onError]);

  useEffect(() => {
    onChange(validation);
  }, [onChange, validation]);

  useEffect(() => {
    if (!enabled) {
      setValidation(undefined);
      setErrorText(undefined);
      return;
    }
    const error = validateImageFieldSize(width, height);
    setErrorText(error);
    if (!error) {
      setValidation({ imageSize: { width, height } });
    }
  }, [enabled, height, width]);

  return (
    <Fieldset
      legend={t('Image Size Limits')}
      description={t('Limit the size of the image height and width.')}
    >
      <Switch
        on={enabled}
        onChange={(state) => setEnabled(state)}
        size="large"
      />
      {enabled && (
        <>
          <div className={styles.validationContent}>
            <Textfield
              defaultValue={width}
              id={`${idValue}_validation-image-size-width`}
              className={styles.validationContentField}
              labelName="width"
              type="number"
              placeholder={t('E.g., 600')}
              onChange={onChangeWidth}
              style={{ width: '100px' }}
              hasError={!!errorText}
            />
            <Textfield
              defaultValue={height}
              id={`${idValue}_validation-image-size-height`}
              className={styles.validationContentField}
              labelName="height"
              type="number"
              placeholder={t('E.g., 400')}
              onChange={onChangeHeight}
              style={{ width: '100px' }}
              hasError={!!errorText}
            />
          </div>

          {errorText && ( // @ts-expect-error
            <p className={styles.errorText}>{t(errorText)}</p>
          )}
        </>
      )}
    </Fieldset>
  );
};

export default ImageSizeValidationField;
