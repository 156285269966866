import { useMemo } from 'react';

export const useLocalStorageItems = (path: string) => {
  const localStorageItems = useMemo(() => {
    const _items = window.localStorage.getItem(`${path}`);
    return _items === null ? undefined : JSON.parse(_items);
  }, [path]);

  return localStorageItems;
};
