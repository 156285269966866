import { GraphQLError } from 'graphql';

import type { GraphQLResult } from '@aws-amplify/api-graphql';

export const isGraphQLErrorResult = <T = object>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  arg: any,
): arg is GraphQLResult<T> => {
  return (
    arg !== null &&
    typeof arg === 'object' &&
    (arg.data !== undefined ? typeof arg.data === 'object' : true) &&
    (arg.errors !== undefined ? Array.isArray(arg.errors) : true)
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isGraphQLError = (arg: any): arg is GraphQLError => {
  return arg instanceof GraphQLError;
};

export const handleApiError = (e: unknown) => {
  if (e instanceof Error) {
    throw e;
  }

  if (isGraphQLErrorResult(e)) {
    if (e.errors && e.errors.length > 0) {
      throw new Error(e.errors[0].message);
    }
  }

  throw new Error('Unexpected Error');
};

export type ApiResponse<T = undefined> =
  | {
      result: true;
      data: T;
      message?: string | null;
    }
  | {
      result: false;
      message?: string | null;
    };

export const handleApiResult = <T>(apiResponse: ApiResponse<T>) => {
  if (apiResponse.result) {
    return apiResponse;
  } else {
    throw new Error(apiResponse.message ?? 'Unexpected Error');
  }
};
