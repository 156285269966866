const richEditorV2HeadingOptions = [
  'headerOne',
  'headerTwo',
  'headerThree',
  'headerFour',
  'headerFive',
] as const;

export type RichEditorV2HeadingOptions =
  (typeof richEditorV2HeadingOptions)[number];

const richEditorV2UndoRedoOptions = ['undo', 'redo'] as const;

export type RichEditorV2UndoRedoOptions =
  (typeof richEditorV2UndoRedoOptions)[number];

export const richEditorV2Options = [
  ...richEditorV2HeadingOptions,
  ...richEditorV2UndoRedoOptions,
  'paragraph',
  'bold',
  'italic',
  'underline',
  'strike',
  'code',
  'blockquote',
  'codeBlock',
  'listBullet',
  'listOrdered',
  'link',
  'image',
  'table',
  'horizontalRule',
  'textAlign',
  'customClass',
  'oembedly',
  'clean',
  'color',
] as const;

export type RichEditorV2Options = (typeof richEditorV2Options)[number];
