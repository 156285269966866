import { API, graphqlOperation } from 'aws-amplify';

import type { UpdateWebhookEnabledSettingQuery } from '@/API';
import type { WebhookSetting } from '@/entity/webhookSettings';
import type { ApiResponse } from '@/views/Common/handleApiResult';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

import * as queries from '@/graphql/queries';

export const webhookSettingWriterRepository = (apiId: string) => {
  const createWebhookSetting = async ({
    target,
    values,
    events,
  }: {
    target: string;
    values: object;
    events: string[];
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.createWebhookSetting, {
          apiId,
          target,
          settingValues: JSON.stringify(values),
          handleEvents: events,
        }),
      )) as GraphQLResult<{
        createWebhookSetting: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected error');
      }

      // TODO: フロントエンドエラー対応
      if (
        typeof result.data.createWebhookSetting === 'object' &&
        typeof result.data.createWebhookSetting.result === 'boolean'
      ) {
        const success = result.data.createWebhookSetting.result;
        if (success) {
          return result.data.createWebhookSetting;
        } else {
          throw new Error(
            result.data.createWebhookSetting.message ?? 'Unexpected error',
          );
        }
      } else if (typeof result.data.createWebhookSetting === 'string') {
        return result.data.createWebhookSetting;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected error');
    }
  };

  const updateWebhookSetting = async ({
    current,
    values,
    events,
  }: {
    current: WebhookSetting;
    values: object;
    events: string[];
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.updateWebhookSetting, {
          apiId: current.apiId,
          createdAt: current.createdAt,
          settingValues: JSON.stringify(values),
          handleEvents: events,
        }),
      )) as GraphQLResult<{
        updateWebhookSetting: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected error');
      }

      // TODO: フロントエンドエラー対応
      if (
        typeof result.data.updateWebhookSetting === 'object' &&
        typeof result.data.updateWebhookSetting.result === 'boolean'
      ) {
        const success = result.data.updateWebhookSetting.result;
        if (success) {
          return result.data.updateWebhookSetting;
        } else {
          throw new Error(
            result.data.updateWebhookSetting.message ?? 'Unexpected error',
          );
        }
      } else if (typeof result.data.updateWebhookSetting === 'string') {
        return result.data.updateWebhookSetting;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected error');
    }
  };

  const deleteWebhookSetting = async ({
    current,
  }: {
    current: WebhookSetting;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.deleteWebhookSetting, {
          apiId: current.apiId,
          createdAt: current.createdAt,
        }),
      )) as GraphQLResult<{
        deleteWebhookSetting: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected error');
      }

      // TODO: フロントエンドエラー対応
      if (
        typeof result.data.deleteWebhookSetting === 'object' &&
        typeof result.data.deleteWebhookSetting.result === 'boolean'
      ) {
        const success = result.data.deleteWebhookSetting.result;
        if (success) {
          return result.data.deleteWebhookSetting;
        } else {
          throw new Error(
            result.data.deleteWebhookSetting.message ?? 'Unexpected error',
          );
        }
      } else if (typeof result.data.deleteWebhookSetting === 'string') {
        return result.data.deleteWebhookSetting;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected error');
    }
  };

  const updateWebhookEnabledSetting = async ({
    current,
    enabled,
  }: {
    current: WebhookSetting;
    enabled: boolean;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.updateWebhookEnabledSetting, {
          apiId: current.apiId,
          createdAt: current.createdAt,
          enabled,
        }),
      )) as GraphQLResult<UpdateWebhookEnabledSettingQuery>;

      if (!result.data) {
        throw new Error('Unexpected error');
      }

      const success = result.data.updateWebhookEnabledSetting?.result;
      if (success) {
        return result.data.updateWebhookEnabledSetting;
      } else {
        throw new Error(
          result.data.updateWebhookEnabledSetting?.message ??
            'Unexpected error',
        );
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected error');
    }
  };

  return {
    createWebhookSetting,
    updateWebhookSetting,
    deleteWebhookSetting,
    updateWebhookEnabledSetting,
  };
};
