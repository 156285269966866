import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';

import { allowedFileNameLength } from '@/components/Validations';

import * as queries from '../../graphql/queries';

import type { Medium } from '@/entity/medium';

export const useMediumCount = (
  serviceId: string | undefined,
  option: { onlyImage: boolean } = { onlyImage: false },
) => {
  const mediumCountQuery = useQuery({
    queryKey: ['mediumCount', option],
    queryFn: async () => {
      if (serviceId) {
        const result: any = await API.graphql(
          graphqlOperation(queries.getMediumCount, {
            serviceId,
            onlyImage: option.onlyImage,
          }),
        );
        return result.data.getMediumCount;
      }
      return;
    },
    enabled: !!serviceId,
    staleTime: Number.POSITIVE_INFINITY,
  });
  const { data: count } = mediumCountQuery;
  return [count];
};

/**
 * @deprecated
 * views/Common/medium/useMediumReader.tsのuseMediumを使ってください
 */
export const useMedium = (
  serviceId: string | undefined,
  mediumId: string | undefined,
) => {
  const mediumQuery = useQuery({
    queryKey: ['medium', { mediumId }],
    queryFn: async () => {
      if (serviceId && mediumId) {
        const result: any = await API.graphql(
          graphqlOperation(queries.findMedium, {
            serviceId,
            mediumId,
          }),
        );
        return result.data.findMedium;
      }
      return;
    },
    cacheTime: 0,
    enabled: !!mediumId,
  });
  const { data: medium } = mediumQuery;
  return medium;
};

type MediaArgs = {
  onlyImage: boolean;
  text: string;
  filters: string;
};

/**
 * メディアを取得するカスタムフックです。
 * バックエンドのfindMediaを呼び出します。
 *
 * textが1000文字以上の場合は取得しません。呼び出し側でエラー表示をするなどしてください。
 */
export const useMedia = (
  serviceId: string | undefined,
  onlyImage: boolean,
  text: string,
  filters: string,
) => {
  // データを取得
  const fetchData = async (
    { onlyImage, text, filters }: MediaArgs,
    cursor: string,
  ) => {
    if (!serviceId) {
      return null;
    }
    if (onlyImage) {
      const result = (await API.graphql(
        graphqlOperation(queries.findMedia, {
          serviceId,
          fileNameQuery: text,
          filters,
          onlyImage: true,
          nextToken: JSON.stringify(cursor),
        }),
      )) as { data: { findMedia: any } };
      return JSON.parse(result.data.findMedia);
    }
    const result = (await API.graphql(
      graphqlOperation(queries.findMedia, {
        serviceId,
        fileNameQuery: text,
        filters,
        nextToken: JSON.stringify(cursor),
      }),
    )) as { data: { findMedia: any } };
    return JSON.parse(result.data.findMedia);
  };

  const mediaQuery = useInfiniteQuery<
    { items: Medium[]; nextToken: unknown },
    MediaArgs
  >(
    ['media', { onlyImage, text, filters }],
    ({ pageParam }) => fetchData({ onlyImage, text, filters }, pageParam),
    {
      staleTime: Number.POSITIVE_INFINITY,
      enabled: !!serviceId && allowedFileNameLength(text), // テキストが1000文字より大きい場合は取得しない
      getNextPageParam: (lastPage) => {
        return lastPage.nextToken;
      },
    },
  );

  return mediaQuery;
};
