const beautifyJson = (object: unknown, space = 2) => {
  try {
    return JSON.stringify(object, null, space);
  } catch (e) {
    console.error(e);

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#exceptions
    // NOTE: TypeError があり得るので、エラー時のフォールバック値を設定
    return '{}';
  }
};

export { beautifyJson };
