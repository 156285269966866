import type React from 'react';
import { useCallback } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';

import { useEnvironments } from '@/hooks/Environment/useEnvironments';
import { useGetMyService } from '@/hooks/useService';

import CheckRoles from '../../CheckRoles';
import CreateEnvironment from '../../CreateEnvironment';
import Head from '../../Head';
import Button from '../../ui/Button';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../ui/Table';

import type { Environment } from '@/types/services';

import styles from './environmentsSettings.module.css';

import { buildHost } from '@/util';
import { formatDateToMinutes } from '@/util/date';
import { Upsell } from '@/views/Common/plan/Upsell';
import { EnvironmentsSettingsTooltip } from '@/views/ServiceSettings/EnvironmentSettings/EnvironmentsSettingsTooltip';
import { useSAMLQuery } from '@/views/ServiceSettings/SAMLSettings/useSAMLQuery';

const EnvironmentsSettings: React.FC = () => {
  const { t } = useTranslation('serviceEnvironmentsSettings');
  const { service, parentService } = useGetMyService();
  // 環境
  const { environments, deleteEnvironment, deleteEnvironmentLoading } =
    useEnvironments(
      service?.partitionKey,
      parentService !== null ? parentService.partitionKey : undefined,
    );

  const { useFindSAMLConfiguration } = useSAMLQuery(
    service?.partitionKey || '',
  );
  const { data: samlConfiguration } = useFindSAMLConfiguration();

  const [EnvModal, openEnvModal, closeEnvModal] = useModal('root');

  const onDeleteEnvironment = useCallback(
    (serviceId: string) => {
      deleteEnvironment({ serviceId });
    },
    [deleteEnvironment],
  );

  if (!service || !environments) {
    return null;
  }
  if (!samlConfiguration) {
    return null;
  }
  return (
    <Upsell restrictionKey="handleEnvironments">
      <>
        <Head title={t('Environment Settings')} />
        <h2 className={styles.title}>{t('Environment Settings')}</h2>
        <Table aria-label={t('Environment list')}>
          <Thead>
            <Tr>
              <Th isLong={true}>{t('Environment Name')}</Th>
              <Th isLong={true}>{t('Date Created')}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr enableHoverStyle={false}>
              <Td>
                <a
                  href={buildHost({
                    domain: (parentService || service).domain,
                    childService: {},
                    saml: samlConfiguration,
                  })}
                >
                  <dl>
                    <dt className={styles.envName}>{t('Production')}</dt>
                    <dd>{(parentService || service).domain}</dd>
                  </dl>
                </a>
              </Td>
              <Td>
                {formatDateToMinutes(
                  (parentService || service).createdAt || '',
                )}
              </Td>
              <Td></Td>
            </Tr>
            {environments.map(
              ({ name, domain, createdAt, partitionKey }: Environment) => (
                <Tr enableHoverStyle={false} key={domain}>
                  <Td>
                    <a
                      href={buildHost({
                        domain,
                        childService: { partitionKey },
                        saml: samlConfiguration,
                      })}
                    >
                      <dl>
                        <dt className={styles.envName}>{name}</dt>
                        <dd>{domain}</dd>
                      </dl>
                    </a>
                  </Td>
                  <Td>{formatDateToMinutes(createdAt)}</Td>
                  <Td>
                    <EnvironmentsSettingsTooltip
                      envServiceId={partitionKey}
                      name={name}
                      onDeleteEnvironment={onDeleteEnvironment}
                      deleteEnvironmentLoading={deleteEnvironmentLoading}
                    />
                  </Td>
                </Tr>
              ),
            )}
          </Tbody>
        </Table>
        <CheckRoles permission="environmentPermission" operation="create">
          <div className={styles.actions}>
            <Button
              type="secondary"
              value={t('Create environment')}
              onClick={openEnvModal}
            />
          </div>
        </CheckRoles>
        <EnvModal>
          <CreateEnvironment
            service={service}
            parentService={parentService}
            closeEnvModal={closeEnvModal}
          />
        </EnvModal>
      </>
    </Upsell>
  );
};

export default EnvironmentsSettings;
