import * as Toolbar from '@radix-ui/react-toolbar';
import * as Tooltip from '@radix-ui/react-tooltip';
import cx from 'classnames';
import { forwardRef } from 'react';

import type { ComponentProps } from 'react';

import styles from './iconbutton.module.css';

type Props = {
  icon: React.ReactNode;
  isActive: boolean;
  isButtonElement?: boolean;
} & ComponentProps<'button'>;

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  ({ icon, isActive, ...props }, ref) => {
    return (
      <Tooltip.Provider delayDuration={0}>
        <Tooltip.Root>
          <Toolbar.Button asChild>
            <Tooltip.Trigger asChild>
              <button
                ref={ref}
                className={cx(styles.button, {
                  [styles.isActive]: isActive,
                })}
                {...props}
              >
                <span aria-hidden>{icon}</span>
              </button>
            </Tooltip.Trigger>
          </Toolbar.Button>
          <Tooltip.Portal>
            <Tooltip.Content className={styles.tooltipContent} sideOffset={1}>
              {props['aria-label']}
              <Tooltip.Arrow
                className={styles.tooltipArrow}
                width={5}
                height={3}
              />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    );
  },
);

IconButton.displayName = 'IconButton';
