import { isPublishedContent } from '../content';

import type { ContentStatus } from '@/entity/content';
import type { ContentPermission } from '@/entity/role';

type ContentUpdatePermissionEntry = ContentPermission['contentUpdate'];

/**
 * コンテンツのステータスに応じた権限項目を取得する
 *
 * @param status コンテンツのステータス
 * @param operation 操作の種類
 * @example
 * const status = content.contentStatus; // 'PUBLISH' の場合
 * getContentPermissionEntryByStatus(status, 'reorder');
 * // => 'reorderPublished'
 */
function getContentUpdatePermissionEntryByStatus(
  operation: 'reorder',
  status: ContentStatus,
): keyof Pick<
  ContentUpdatePermissionEntry,
  'reorderUnpublished' | 'reorderPublished'
>;
function getContentUpdatePermissionEntryByStatus(
  operation: 'changePublicationDate',
  status: ContentStatus,
): keyof Pick<
  ContentUpdatePermissionEntry,
  'changePublicationDateUnpublished' | 'changePublicationDatePublished'
>;
function getContentUpdatePermissionEntryByStatus(
  operation: 'changeContentId',
  status: ContentStatus,
): keyof Pick<
  ContentUpdatePermissionEntry,
  'changeContentIdUnpublished' | 'changeContentIdPublished'
>;
function getContentUpdatePermissionEntryByStatus(
  operation: 'revertToDraft',
  status: ContentStatus,
): keyof Pick<
  ContentUpdatePermissionEntry,
  'revertToDraft' | 'revertToDraftUnpublished'
>;
function getContentUpdatePermissionEntryByStatus(
  operation:
    | 'reorder'
    | 'changePublicationDate'
    | 'changeContentId'
    | 'revertToDraft',
  status: ContentStatus,
): keyof Pick<
  ContentUpdatePermissionEntry,
  | 'reorderUnpublished'
  | 'reorderPublished'
  | 'changePublicationDateUnpublished'
  | 'changePublicationDatePublished'
  | 'changeContentIdUnpublished'
  | 'changeContentIdPublished'
  | 'revertToDraft'
  | 'revertToDraftUnpublished'
> {
  switch (operation) {
    case 'reorder':
      return isPublishedContent(status)
        ? 'reorderPublished'
        : 'reorderUnpublished';
    case 'changePublicationDate':
      return isPublishedContent(status)
        ? 'changePublicationDatePublished'
        : 'changePublicationDateUnpublished';
    case 'changeContentId':
      return isPublishedContent(status)
        ? 'changeContentIdPublished'
        : 'changeContentIdUnpublished';
    case 'revertToDraft':
      return isPublishedContent(status)
        ? 'revertToDraft'
        : 'revertToDraftUnpublished';
  }
}

export { getContentUpdatePermissionEntryByStatus };
