import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useStrike } from './useStrike';
import { IconButton } from '../../../IconButton';
import styles from '../../../richeditorv2.module.css';

import type { Editor } from '@tiptap/react';

import { StrikeIcon } from '@/views/Common/Icons';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  toggleStrikeHandler: () => void;
  isActiveStrike: boolean;
  editable: boolean;
};

export const StrikeView: React.FC<ViewProps> = ({
  toggleStrikeHandler,
  isActiveStrike,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <IconButton
      icon={
        <StrikeIcon
          className={cx(styles.icon, {
            [styles.isActive]: isActiveStrike,
          })}
        />
      }
      aria-label={t('Strike')}
      isActive={isActiveStrike}
      onClick={toggleStrikeHandler}
      disabled={!editable}
    />
  );
};

export const Strike: React.FC<Props> = ({ editor }) => {
  const { toggleStrikeHandler, isActiveStrike } = useStrike({ editor });

  return (
    <StrikeView
      toggleStrikeHandler={toggleStrikeHandler}
      isActiveStrike={isActiveStrike}
      editable={editor.isEditable}
    />
  );
};
