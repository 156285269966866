import { API, graphqlOperation } from 'aws-amplify';

import type { FindMediaQuery, FindMediaQueryVariables } from '@/API';
import type { Medium } from '@/entity/medium';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';
import { handleApiError } from '@/views/Common/handleApiResult';
import {
  handleApiResult,
  type ApiResponse,
} from '@/views/Common/handleApiResult';

type FindMediaArgs = FindMediaQueryVariables;

type NextToken = { updatedAt?: string } | null;

export type FindMediaResult = {
  items: Medium[];
  nextToken: NextToken;
};

type AddMediumArgs = {
  serviceId: string;
  fileName: string;
  identityId: string;
};

export const mediumRepository = () => {
  /**
   * メディア一覧を取得する
   *
   * 以下の条件で取得できる
   * - ファイル名による検索
   * - フィルターによる検索
   * - 画像のみの検索
   *
   * ページングによる取得も可能
   * - nextTokenを指定することで次のページを取得できる
   *   - nextTokenは { updatedAt: string } を JSON.stringify して指定する
   */
  const findMedia = async ({
    serviceId,
    fileNameQuery,
    filters,
    onlyImage,
    nextToken,
  }: FindMediaArgs): Promise<FindMediaResult | null> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.findMedia, {
          serviceId,
          fileNameQuery,
          filters,
          onlyImage,
          nextToken,
        }),
      )) as { data: FindMediaQuery };

      const typedResult = result.data.findMedia
        ? (JSON.parse(result.data.findMedia) as FindMediaResult)
        : null;

      return typedResult;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not fetch all medium');
    }
  };

  /**
   * メディア（単一）を取得する
   */
  const findMedium = async (
    serviceId: string,
    mediumId: Medium['mediumId'],
  ): Promise<Medium | null> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.findMedium, {
          serviceId,
          mediumId,
        }),
      )) as { data: { findMedium: Medium } };

      if (result === undefined || result === null) {
        return null;
      }

      if (
        result.data.findMedium === null ||
        result.data.findMedium === undefined
      ) {
        return null;
      }

      const medium = result.data.findMedium;
      return medium;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not get medium');
    }
  };

  /**
   * DynamoDBにアップロード済みのメディアを追加する
   * 返り値はmediumId
   */
  const addMedium = async ({
    serviceId,
    fileName,
    identityId,
  }: AddMediumArgs): Promise<string | null> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.addMedium, {
          serviceId,
          fileName,
          identityId,
        }),
      )) as GraphQLResult<{
        addMedium:
          | {
              result: boolean;
              message?: string;
              data?: string;
            }
          | string;
      }>;

      if (!result.data?.addMedium) {
        throw new Error('Unexpected error');
      }
      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.addMedium === 'object' &&
        typeof result.data.addMedium.result === 'boolean'
      ) {
        // TODO:以下を共通化（これ以外の分岐はGo移植後に削除する）
        const success = result.data.addMedium.result;

        if (success && typeof result.data.addMedium.data === 'string') {
          return result.data.addMedium.data as string;
        } else {
          throw new Error(result.data.addMedium.message && 'Unexpected error');
        }
        // TODO:ここまで
      } else if (typeof result.data.addMedium === 'string') {
        return JSON.parse(result.data.addMedium) as string;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Could not add medium');
    }
  };

  const updateMedium = async ({
    serviceId,
    mediumId,
    filePath,
  }: {
    serviceId: string;
    mediumId: string;
    filePath: string;
  }): Promise<ApiResponse> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.updateMedium, {
          serviceId,
          mediumId,
          filePath,
        }),
      )) as GraphQLResult<{
        updateMedium: ApiResponse;
      }>;

      if (!result.data?.updateMedium) {
        throw new Error('Unexpected error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.updateMedium === 'object' &&
        typeof result.data.updateMedium.result === 'boolean'
      ) {
        return handleApiResult(result.data.updateMedium);
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Could not update medium');
    }
  };

  const updateMediumCreatedBy = async ({
    serviceId,
    mediumId,
    createdBy,
  }: {
    serviceId: string;
    mediumId: string;
    createdBy: string;
  }): Promise<ApiResponse> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.updateMediumCreatedBy, {
          serviceId,
          mediumId,
          createdBy,
        }),
      )) as GraphQLResult<{
        updateMediumCreatedBy: ApiResponse;
      }>;

      if (!result.data?.updateMediumCreatedBy) {
        throw new Error('Unexpected error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.updateMediumCreatedBy === 'object' &&
        typeof result.data.updateMediumCreatedBy.result === 'boolean'
      ) {
        return handleApiResult(result.data.updateMediumCreatedBy);
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Could not update medium createdBy');
    }
  };

  const deleteAllMedia = async ({ serviceId }: { serviceId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.deleteAllMedia, {
          serviceId,
        }),
      )) as GraphQLResult<{
        deleteAllMedia: ApiResponse | null;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      if (
        result.data.deleteAllMedia === null ||
        result.data.deleteAllMedia === undefined
      ) {
        throw new Error('Unexpected Error');
      }

      return handleApiResult(result.data.deleteAllMedia);
    } catch (e) {
      handleApiError(e);
    }
  };

  return {
    findMedia,
    findMedium,
    addMedium,
    updateMedium,
    updateMediumCreatedBy,
    deleteAllMedia,
  };
};
