import type React from 'react';
import { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { useCopy } from '../../../hooks/Copy/useCopy';
import IconButton from '../../ui/IconButton';

import type { Param } from '../Params';

import styles from './code.module.css';

interface Props {
  inputPath: string;
  params: Param[];
  method: string;
  body: string;
}

const JavaScript: React.FC<Props> = ({ inputPath, params, method, body }) => {
  const { t } = useTranslation('code');
  const [endpoint, contentId] = inputPath.split('/');
  const _params = useMemo(() => {
    return params.filter((p) => p.check);
  }, [params]);
  const paramsString = useMemo(() => {
    const getValue = (key: string, value: string) => {
      if (['limit', 'offset', 'depth'].includes(key)) {
        return value;
      }
      return `'${value}'`;
    };
    return _params
      .map((p) => `${p.key}: ${getValue(p.key, p.value)}`)
      .join(`, `);
  }, [_params]);

  const enabledParams = params
    .filter((p) => p.check)
    .reduce((prev, obj) => ({ ...prev, [obj.key]: obj.value }), {}) as {
    status: string;
  };

  const code =
    method === 'GET'
      ? `client
      .get({
        endpoint: '${endpoint}',` +
        (contentId
          ? `
        contentId: '${contentId}',`
          : '') +
        (_params.length > 0
          ? `
        queries: { ${paramsString} }`
          : '') +
        `
      })
      .then((res) => console.log(res));`
      : method === 'POST' || method === 'PUT'
        ? `client
      .create({
        endpoint: '${endpoint}',` +
          (contentId
            ? `
        contentId: '${contentId}',`
            : '') +
          `
        content: JSON.parse('${body}'),` +
          (enabledParams.status === 'draft'
            ? `
        isDraft: true,`
            : '') +
          `
      })
      .then((res) => console.log(res.id));`
        : method === 'PATCH'
          ? `client
      .update({
        endpoint: '${endpoint}',
        contentId: '${contentId}',
        content: JSON.parse('${body}'),
      })
      .then((res) => console.log(res.id));`
          : method === 'DELETE'
            ? `client
      .delete({
        endpoint: '${endpoint}',
        contentId: '${contentId}',
      })
      .then(() => console.log('delete!'));`
            : t('Not supported');

  const [copied, copyAction, setCopied] = useCopy(code);
  const copyApiKey = () => {
    copyAction();

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <>
      <SyntaxHighlighter
        language="javascript"
        style={docco}
        customStyle={{ fontSize: 14, padding: '16px', borderRadius: '4px' }}
      >
        {code}
      </SyntaxHighlighter>
      <p className={styles.note}>
        {Trans({
          t,
          i18nKey: 'Above is an example using microcms-js-sdk.',
          children: (
            <a
              href="https://github.com/microcmsio/microcms-js-sdk"
              target="sdk"
            >
              microcms-js-sdk
            </a>
          ),
        })}
      </p>
      <div className={styles.copyButton}>
        <IconButton
          icon={copied ? 'check' : 'content_copy'}
          hasFrame={true}
          onClick={copyApiKey}
        />
      </div>
    </>
  );
};

export default JavaScript;
