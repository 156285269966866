import cx from 'classnames';
import { forwardRef } from 'react';

import styles from './FieldTitle.module.css';

type Props = {
  variant?: 'default' | 'v-top';
} & JSX.IntrinsicElements['span'];

/**
 * 権限項目のタイトル
 */
const FieldTitle = forwardRef<HTMLSpanElement, Props>(function FieldTitle(
  { variant = 'default', children, className, ...otherProps },
  ref,
) {
  return (
    <span
      {...otherProps}
      ref={ref}
      className={cx(styles.title, className)}
      data-variant={variant}
    >
      {children}
    </span>
  );
});

export default FieldTitle;
