import { useQuery } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useState, useCallback } from 'react';

import type { Service, ServiceList } from '@/types/services';

import * as queries from '@/graphql/queries';

export const useGetMyServices = () => {
  // TODO useGetMyServiceとrepositoryを共通化
  const getMyServices = async () => {
    const services = (await API.graphql(
      graphqlOperation(queries.getMyServices),
    )) as { data: { getMyServices: Service[] } };

    const serviceList: Service[] = services.data.getMyServices;

    return {
      list: serviceList.map((serviceList) => {
        return {
          ...serviceList,
          domain: serviceList.gsiSinglePartitionKey1.replace('DOMAIN#', ''),
        };
      }),
    };
  };
  const servicesQuery = useQuery<ServiceList>({
    queryKey: ['services'],
    queryFn: getMyServices,
    staleTime: Number.POSITIVE_INFINITY,
  });
  const {
    data: services,
    isLoading: servicesLoading,
    isSuccess: hasServices,
  } = servicesQuery;

  const [filteredServices, setFilteredServices] = useState<
    Service[] | undefined
  >();

  const searchServices = useCallback(
    (value: string) => {
      if (!value) {
        setFilteredServices(undefined);
        return;
      }
      return setFilteredServices(
        services?.list.filter(
          (item) =>
            (item.serviceName && item.serviceName.indexOf(value) > -1) ||
            (item.domain && item.domain.indexOf(value) > -1),
        ),
      );
    },
    [services],
  );

  return {
    services,
    servicesLoading,
    hasServices,
    filteredServices,
    searchServices,
  };
};
