import { atom } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import { useCallback, useMemo } from 'react';

import { useValidateAllFields } from './useValidateFields';

import type { ContentValue } from '@/entity/content';
import type { CustomField } from '@/types/contents';
import type { Field } from '@/types/field';
import type { PrimitiveAtom } from 'jotai';

export const useContent = (
  initialData: ContentValue,
  fields: Field[],
  customFields: CustomField[],
): {
  contentAtom: PrimitiveAtom<ContentValue>;
  getContent: () => ContentValue;
  validateAllFields: () => boolean;
} => {
  const contentAtom = useMemo(() => atom(initialData), [initialData]);
  const getContent = useAtomCallback(
    useCallback((get) => get(contentAtom), [contentAtom]),
  );

  const validateAllFields = useValidateAllFields(
    getContent,
    fields,
    customFields,
  );

  return {
    contentAtom,
    getContent,
    validateAllFields,
  };
};
