import { apiKeyRepository } from '@/repository/apiKeyRepository';

import type {
  ApiKeyPolicy,
  ApiKeyPriorityPolicy,
  ManagementPolicy,
} from '@/types/apiKey';

export const createApiKey = async ({
  serviceId,
  label,
  defaultPolicy,
  priorityPolicies,
  managementPolicy,
}: {
  serviceId: string;
  label?: string;
  defaultPolicy: ApiKeyPolicy;
  priorityPolicies?: ApiKeyPriorityPolicy[];
  managementPolicy?: ManagementPolicy | null;
}) => {
  const { createApiKey } = apiKeyRepository();
  return await createApiKey({
    serviceId,
    label,
    defaultPolicy,
    priorityPolicies,
    managementPolicy,
  });
};

export const updateApiKey = async ({
  apiKeyId,
  label,
  defaultPolicy,
  priorityPolicies,
  managementPolicy,
}: {
  apiKeyId: string;
  label?: string;
  defaultPolicy: ApiKeyPolicy;
  priorityPolicies?: ApiKeyPriorityPolicy[];
  managementPolicy?: ManagementPolicy | null;
}) => {
  const { updateApiKey } = apiKeyRepository();
  return await updateApiKey({
    apiKeyId,
    label,
    defaultPolicy,
    priorityPolicies,
    managementPolicy,
  });
};
