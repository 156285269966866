import { ContentsComparisonComponent } from '../../../ContentsComparison';
import { useI18nContentsComparison } from '../../../useI18nContentsComparison';

import type { MigrateApi } from '@/entity/api';
import type { CustomField } from '@/types/contents';

import styles from './custom.module.css';

import { nonNullable } from '@/util/type-guard';

type Props = {
  apiField: MigrateApi['apiFields'][number];
  customFields: CustomField[];
  sinceValue: Record<string, unknown>;
  untilValue: Record<string, unknown>;
};

const Custom: React.FC<Props> = ({
  apiField,
  customFields,
  sinceValue,
  untilValue,
}) => {
  const { t } = useI18nContentsComparison();

  const customField = customFields.find(
    (field) => field.createdAt === apiField.customFieldCreatedAt,
  );

  if (!customField) {
    // カスタムフィールドがすでに削除されているケース
    return (
      <p className={styles.error}>
        {t('The relevant custom field has already been deleted.')}
      </p>
    );
  }

  const fieldIds = customField.position.flat();
  const fields = fieldIds
    .map((fieldId) => {
      return customField.fields.find((field) => field.idValue === fieldId);
    })
    .filter(nonNullable);

  return (
    <div className={styles.main}>
      <p className={styles.name}>{customField.name}</p>
      <ContentsComparisonComponent
        apiFields={fields}
        customFields={customFields}
        sinceContent={sinceValue}
        untilContent={untilValue}
      />
    </div>
  );
};

export { Custom };
