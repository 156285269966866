import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from './_types';

import { produce } from '@/lib/immer';

type SetNameAction = FormRoleInputValueAction<'set-name', string>;
type NameAction = SetNameAction;

const setName: FormRoleInputValueDispatchFn<SetNameAction> = (
  currentState,
  payload,
) => {
  return produce(currentState, (draft) => {
    draft.name = payload;
  });
};

export { type NameAction, setName };
