import cx from 'classnames';

import { useI18nContentsComparison } from '../../../useI18nContentsComparison';

import styles from './expandedButton.module.css';

import { Icon } from '@/views/Common/Ui/Icon';

type Props = React.ComponentPropsWithoutRef<'button'>;

const ExpandedButton: React.FC<Props> = ({ className, onClick, ...props }) => {
  const { t } = useI18nContentsComparison();

  return (
    <button onClick={onClick} className={cx(styles.main, className)} {...props}>
      <Icon name="unfold_more" />
      <span>{t('Expand')}</span>
    </button>
  );
};

export { ExpandedButton };
