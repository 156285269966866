import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from './_types';
import type { Role } from '@/entity/role';

import { produce } from '@/lib/immer';

type SetRolePermissionCreateAction = FormRoleInputValueAction<
  'set-role-permission-create',
  Role['rolePermission']['create']
>;
type SetRolePermissionReadAction = FormRoleInputValueAction<
  'set-role-permission-read',
  NonNullable<Role['rolePermission']['readLevel']> // readLevelがundefinedを含んでしまっているのでNonNullableで除外
>;
type SetRolePermissionUpdateAction = FormRoleInputValueAction<
  'set-role-permission-update',
  Role['rolePermission']['update']
>;
type SetRolePermissionDeleteAction = FormRoleInputValueAction<
  'set-role-permission-delete',
  Role['rolePermission']['delete']
>;
type RolePermissionAction =
  | SetRolePermissionCreateAction
  | SetRolePermissionReadAction
  | SetRolePermissionUpdateAction
  | SetRolePermissionDeleteAction;

/**
 * RolePermissionのcreateフィールドを更新する
 */
const setRolePermissionCreate: FormRoleInputValueDispatchFn<
  SetRolePermissionCreateAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.rolePermission.create = payload;
  });
};

/**
 * RolePermissionのreadフィールドとreadLevelフィールドを更新する
 */
const setRolePermissionRead: FormRoleInputValueDispatchFn<
  SetRolePermissionReadAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.rolePermission.readLevel = payload;
    // NOTE: ここは過去のフィールドだが、現存しているのでそのまま残しておく
    draft.rolePermission.read = payload === 'ALL' || payload === 'SAME_ROLE';
  });
};

/**
 * RolePermissionのupdateフィールドを更新する
 */
const setRolePermissionUpdate: FormRoleInputValueDispatchFn<
  SetRolePermissionUpdateAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.rolePermission.update = payload;
  });
};

/**
 * RolePermissionのdeleteフィールドを更新する
 */
const setRolePermissionDelete: FormRoleInputValueDispatchFn<
  SetRolePermissionDeleteAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.rolePermission.delete = payload;
  });
};

export {
  type RolePermissionAction,
  setRolePermissionCreate,
  setRolePermissionRead,
  setRolePermissionUpdate,
  setRolePermissionDelete,
};
