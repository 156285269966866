import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './breadcrumbs.module.css';

import { Icon } from '@/views/Common/Ui/Icon';

type Props = {
  apiName: string;
  apiEndpoint: string;
  userDefinedContentId: string;
};

const Breadcrumbs: React.FC<Props> = ({
  apiName,
  apiEndpoint,
  userDefinedContentId,
}) => {
  const { t } = useTranslation('apisContentCompare');

  return (
    <div
      role="navigation"
      aria-label={t('breadcrumbs')}
      className={styles.list}
    >
      <Link to={`/apis/${apiEndpoint}`}>{apiName}</Link>
      <Icon
        name="navigate_next"
        size="large"
        outlined
        className={styles.icon}
      />
      <Link to={`/apis/${apiEndpoint}/${userDefinedContentId}`}>
        {userDefinedContentId}
      </Link>
    </div>
  );
};

export { Breadcrumbs };
