import type React from 'react';

import styles from './richText.module.css';

type Props = {
  html: string;
};

const RichText: React.FC<Props> = ({ html }) => {
  return (
    <div
      className={styles.container}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};

export default RichText;
