import { useCallback, useState } from 'react';

import type { LinkAttributes, Props } from './LinkBlock';

import { validateLink, validateText } from '@/views/Common/validations';

export const useLinkBlock = ({ editor }: Props) => {
  const isActiveLink = editor.isActive('link');

  const { view, state } = editor;
  const { from, to } = view.state.selection;
  // 選択中のテキストが存在する場合は優先して表示する
  const selectText = state.doc.textBetween(from, to, '');
  // カーソルが当たっている<a>タグのテキスト全体を表示する
  const cursorText =
    (view.state.selection.$head.nodeBefore?.textContent || '') +
    (view.state.selection.$head.nodeAfter?.textContent || '');
  const text = selectText || cursorText;

  const [inputLinkAttributes, setLinkAttributes] = useState<LinkAttributes>({
    text,
    href: editor.getAttributes('link').href || '',
    target: editor.getAttributes('link').target,
  });

  const [textError, setTextError] = useState<string | undefined>(undefined);
  const onChangeText = useCallback((text: LinkAttributes['text']) => {
    setLinkAttributes((prev) => ({ ...prev, text }));
    setTextError(validateText(text));
  }, []);

  const [hrefError, setHrefError] = useState<string | undefined>(undefined);
  const onChangeHref = useCallback((href: LinkAttributes['href']) => {
    setLinkAttributes((prev) => ({ ...prev, href }));
    setHrefError(validateLink(href));
  }, []);

  const onChangeTargetBlank = useCallback((isTargetBlank: boolean) => {
    setLinkAttributes((prev) => ({
      ...prev,
      target: isTargetBlank ? '_blank' : null,
    }));
  }, []);

  const onSubmitLink = useCallback(() => {
    if (validateLink(inputLinkAttributes.href)) {
      setHrefError(validateLink(inputLinkAttributes.href));
      return;
    }

    editor
      .chain()
      .focus()
      .setLink({
        href: inputLinkAttributes.href,
        target: inputLinkAttributes.target,
      })
      .run();
    editor.view.focus();
  }, [editor, inputLinkAttributes.href, inputLinkAttributes.target]);

  const onUpdateLink = useCallback(() => {
    if (validateLink(inputLinkAttributes.href)) {
      setHrefError(validateLink(inputLinkAttributes.href));
      return;
    }

    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .command(({ tr, state }) => {
        // リンクに付与されているmarksを取得
        const marks = state.selection.$head.marks();
        const { from, to } = state.selection;

        if (inputLinkAttributes.text) {
          // 現在位置を対象に、リンクのhrefとテキストを更新する
          tr.deleteRange(from, to).replaceWith(
            from,
            from,
            state.schema.text(inputLinkAttributes.text, [
              ...marks,
              state.schema.marks.link.create({
                href: inputLinkAttributes.href,
              }),
            ]),
          );
        }

        return true;
      })
      .extendMarkRange('link')
      .setLink({
        href: inputLinkAttributes.href,
        target: inputLinkAttributes.target,
      })
      .run();
    editor.view.focus();
  }, [editor, inputLinkAttributes]);

  const onClearLink = useCallback(() => {
    editor.chain().focus().unsetLink().run();
  }, [editor]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onToggleLinkSetting = useCallback(
    (nextState?: boolean) => {
      setIsOpen((prevState) => nextState ?? !prevState);
      if (!nextState) {
        setLinkAttributes({
          text: '',
          href: '',
          target: null,
        });
        setHrefError(undefined);
      } else {
        setLinkAttributes({
          text,
          href: editor.getAttributes('link').href || '',
          target: editor.getAttributes('link').target,
        });
      }
    },
    [editor, text],
  );

  return {
    inputLinkAttributes,
    onChangeText,
    onChangeHref,
    onChangeTargetBlank,
    textError,
    hrefError,
    onSubmitLink,
    onUpdateLink,
    onClearLink,
    isActiveLink,
    isOpen,
    onToggleLinkSetting,
  };
};
