import { useTranslation } from 'react-i18next';

import Button from '@/components/ui/Button';

import { useUpdatePublishedAtModalContent } from './useUpdatePublishedAtModalContent';

import styles from './updatepublishedatmodalcontent.module.css';

import { formatDatePicker } from '@/util/date';
import { DatePicker } from '@/views/Common/Ui/DatePicker';
import { ModalContent } from '@/views/Common/Ui/Modal';

export type Props = {
  contentId: string;
  publishedAt: string;
  closeEditPublishedAtModal: () => void;
};

export type ViewProps = {
  onChangePublishedAt: (date: Date) => void;
  doUpdatePublishedAt: () => void;
  selectedPublishedAt: Date;
  disabled: boolean;
};

export const UpdatePublishedAtModalContentView: React.FC<ViewProps> = ({
  onChangePublishedAt,
  doUpdatePublishedAt,
  selectedPublishedAt,
  disabled,
}) => {
  const { t } = useTranslation('updatePublishedAtModal');

  return (
    <ModalContent
      title={t('Change Published Date and Time')}
      description={t(
        '*The value of `publishedAt` is changed when published date and time is changed.',
      )}
    >
      <DatePicker
        reactDatePickerProps={{
          selected: selectedPublishedAt,
          onChange: onChangePublishedAt,
          showTimeSelect: true,
          timeFormat: 'HH:mm',
          timeIntervals: 15,
          dateFormat: formatDatePicker({ dateFormat: false }),
          timeCaption: t('time'),
          wrapperClassName: styles.inputWrapper,
          preventOpenOnFocus: true,
        }}
      />
      <div className={styles.modalActionButtonWrapper}>
        <Button
          className={styles.modalActionButton}
          value={t('Save changes')}
          onClick={doUpdatePublishedAt}
          disabled={disabled}
        />
      </div>
    </ModalContent>
  );
};

export const UpdatePublishedAtModalContent: React.FC<Props> = ({
  contentId,
  publishedAt,
  closeEditPublishedAtModal,
}) => {
  const {
    onChangePublishedAt,
    doUpdatePublishedAt,
    selectedPublishedAt,
    disabled,
  } = useUpdatePublishedAtModalContent({
    contentId,
    publishedAt,
    closeEditPublishedAtModal,
  });

  return (
    <UpdatePublishedAtModalContentView
      onChangePublishedAt={onChangePublishedAt}
      doUpdatePublishedAt={doUpdatePublishedAt}
      selectedPublishedAt={selectedPublishedAt}
      disabled={disabled}
    />
  );
};
