import { useTranslation } from 'react-i18next';

import type { KeyPrefix } from 'i18next';

/**
 * WebhookSettings関連のi18nを扱うカスタムフック
 *
 * @param keyPrefix - ネストしたキーを指定する場合に使用してください
 */
const useI18nWebhookSettings = <K extends KeyPrefix<'webhookSettings'>>(
  keyPrefix?: K,
) => {
  return useTranslation<'webhookSettings', K>('webhookSettings', {
    keyPrefix,
  });
};

export { useI18nWebhookSettings };
