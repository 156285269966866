import * as Tabs from '@radix-ui/react-tabs';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Linkify from '@/components/Linkify';

import { useMainTabs } from './useMainTabs';

import type { ReviewRequest } from '@/entity/review';

import styles from './mainTabs.module.css';

import { Icon } from '@/views/Common/Ui/Icon';
import { InfoTooltip } from '@/views/Common/Ui/InfoTooltip';

type Props = {
  reviewStatus: ReviewRequest['status'];
  reviewChildren: React.ReactNode;
  comparisonChildren: React.ReactNode;
  className?: string;
};

type ViewProps = Props & {
  tab: string;
  handleTabChange: (value: string) => void;
};

const MainTabsView: React.FC<ViewProps> = ({
  reviewStatus,
  reviewChildren,
  comparisonChildren,
  className,
  tab,
  handleTabChange,
}) => {
  const { t } = useTranslation('reviewsReqIdEditReview');

  return (
    <Tabs.Root
      className={className}
      defaultValue="review"
      value={tab}
      onValueChange={handleTabChange}
    >
      <Tabs.List className={styles.list}>
        <Tabs.Trigger asChild value="review">
          <div className={cx(styles.triggerStyle, styles.reviewTrigger)}>
            <Icon name="question_answer" outlined />
            <span>{t('Review')}</span>
          </div>
        </Tabs.Trigger>
        {reviewStatus === 'OPEN' && (
          <div className={cx(styles.triggerStyle, styles.comparisonTrigger)}>
            <Tabs.Trigger value="comparison" className={styles.tabTrigger}>
              <Icon name="settings_ethernet" outlined />
              <span>{t('Change difference')}</span>
            </Tabs.Trigger>
            <InfoTooltip
              title={t('What is a change differential?')}
              aria-label={t('Help')}
              iconName="help_outline"
              className={styles.helpIcon}
            >
              <p className={styles.helpLink}>
                <Linkify>
                  {t(
                    'It shows the difference between the published content at the time of the review application and the latest draft content.\nFor more information, please see the following link: https://document.microcms.io/manual/review',
                  )}
                </Linkify>
              </p>
            </InfoTooltip>
          </div>
        )}
      </Tabs.List>
      <Tabs.Content value="review" className={styles.content}>
        {reviewChildren}
      </Tabs.Content>
      {reviewStatus === 'OPEN' && (
        <Tabs.Content
          value="comparison"
          className={cx(styles.content, styles.comparison)}
        >
          {comparisonChildren}
        </Tabs.Content>
      )}
    </Tabs.Root>
  );
};

const MainTabs: React.FC<Props> = (props) => {
  const hooks = useMainTabs({ reviewStatus: props.reviewStatus });

  return <MainTabsView {...props} {...hooks} />;
};

export { MainTabs };
