type IconProps = {
  className?: string;
};

// リッチエディタv2で利用するアイコン群
export const BoldIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99327)">
      <path d="M11.7 8.0925C12.4275 7.59 12.9375 6.765 12.9375 6C12.9375 4.305 11.625 3 9.9375 3H5.25V13.5H10.53C12.0975 13.5 13.3125 12.225 13.3125 10.6575C13.3125 9.5175 12.6675 8.5425 11.7 8.0925ZM7.5 4.875H9.75C10.3725 4.875 10.875 5.3775 10.875 6C10.875 6.6225 10.3725 7.125 9.75 7.125H7.5V4.875ZM10.125 11.625H7.5V9.375H10.125C10.7475 9.375 11.25 9.8775 11.25 10.5C11.25 11.1225 10.7475 11.625 10.125 11.625Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99327">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ItalicIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99331)">
      <path d="M7.5 3V5.25H9.1575L6.5925 11.25H4.5V13.5H10.5V11.25H8.8425L11.4075 5.25H13.5V3H7.5Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99331">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const UnderlineIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99335)">
      <path d="M9 12.75C11.4825 12.75 13.5 10.7325 13.5 8.25V2.25H11.625V8.25C11.625 9.6975 10.4475 10.875 9 10.875C7.5525 10.875 6.375 9.6975 6.375 8.25V2.25H4.5V8.25C4.5 10.7325 6.5175 12.75 9 12.75ZM3.75 14.25V15.75H14.25V14.25H3.75Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99335">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const StrikeIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99339)">
      <path d="M5.43 6.5625C5.235 6.2025 5.1375 5.79 5.1375 5.31C5.1375 4.8525 5.235 4.44 5.4375 4.0575C5.6325 3.6825 5.91 3.36 6.27 3.09C6.63 2.8275 7.0575 2.6175 7.545 2.4675C8.04 2.325 8.5875 2.25 9.18 2.25C9.7875 2.25 10.335 2.3325 10.8375 2.505C11.3325 2.67 11.76 2.91 12.105 3.21C12.4575 3.51 12.7275 3.87 12.915 4.2825C13.1025 4.695 13.2 5.145 13.2 5.64H10.9425C10.9425 5.4075 10.905 5.1975 10.83 5.0025C10.7625 4.8 10.65 4.635 10.5 4.4925C10.35 4.35 10.1625 4.245 9.9375 4.1625C9.7125 4.0875 9.4425 4.0425 9.1425 4.0425C8.85 4.0425 8.5875 4.0725 8.37 4.14C8.1525 4.2075 7.9725 4.2975 7.83 4.41C7.6875 4.53 7.575 4.665 7.5 4.8225C7.425 4.98 7.3875 5.145 7.3875 5.3175C7.3875 5.6775 7.575 5.9775 7.9425 6.225C8.2275 6.4125 8.52 6.585 9 6.75H5.5425C5.505 6.69 5.46 6.6225 5.43 6.5625V6.5625ZM15.75 9V7.5H2.25V9H9.465C9.6 9.0525 9.765 9.105 9.8775 9.15C10.155 9.2775 10.3725 9.405 10.53 9.5325C10.6875 9.66 10.7925 9.8025 10.8525 9.96C10.905 10.11 10.935 10.2825 10.935 10.4775C10.935 10.65 10.8975 10.815 10.83 10.9725C10.7625 11.1225 10.6575 11.2575 10.515 11.37C10.3725 11.4825 10.2 11.565 9.9825 11.6325C9.765 11.6925 9.51 11.73 9.225 11.73C8.9025 11.73 8.6025 11.7 8.34 11.6325C8.0775 11.565 7.845 11.46 7.6575 11.3175C7.47 11.175 7.32 10.9875 7.215 10.755C7.11 10.5225 7.0275 10.185 7.0275 9.8475H4.8C4.8 10.26 4.86 10.695 4.98 11.0325C5.1 11.37 5.2575 11.67 5.4675 11.94C5.6775 12.2025 5.9175 12.435 6.2025 12.63C6.48 12.825 6.7875 12.99 7.1175 13.1175C7.4475 13.245 7.7925 13.3425 8.1525 13.41C8.5125 13.47 8.8725 13.5075 9.2325 13.5075C9.8325 13.5075 10.38 13.44 10.8675 13.2975C11.355 13.155 11.775 12.96 12.12 12.705C12.465 12.45 12.735 12.1275 12.9225 11.7525C13.11 11.3775 13.2075 10.95 13.2075 10.47C13.2075 10.02 13.1325 9.615 12.975 9.2625C12.9375 9.18 12.8925 9.09 12.8475 9.015H15.75V9Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99339">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CodeIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99343)">
      <path d="M7.05 12.45L3.6 9L7.05 5.55L6 4.5L1.5 9L6 13.5L7.05 12.45ZM10.95 12.45L14.4 9L10.95 5.55L12 4.5L16.5 9L12 13.5L10.95 12.45Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99343">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CodeBlockIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7068_92304)">
      <path d="M7.05 9.45L3.6 6L7.05 2.55L6 1.5L1.5 6L6 10.5L7.05 9.45ZM10.95 9.45L14.4 6L10.95 2.55L12 1.5L16.5 6L12 10.5L10.95 9.45Z" />
      <path d="M2.52801 17.072C2.39201 17.072 2.25201 17.038 2.10801 16.97C1.96801 16.902 1.83801 16.804 1.71801 16.676H1.69401L1.62201 17H0.932007V12.794H1.81401V13.826L1.78401 14.294H1.80201C1.91801 14.186 2.04601 14.102 2.18601 14.042C2.33001 13.982 2.47401 13.952 2.61801 13.952C2.87001 13.952 3.08401 14.014 3.26001 14.138C3.44001 14.262 3.57801 14.438 3.67401 14.666C3.77001 14.89 3.81801 15.154 3.81801 15.458C3.81801 15.798 3.75601 16.09 3.63201 16.334C3.51201 16.574 3.35401 16.758 3.15801 16.886C2.96201 17.01 2.75201 17.072 2.52801 17.072ZM2.33001 16.352C2.43801 16.352 2.53601 16.322 2.62401 16.262C2.71201 16.202 2.78201 16.108 2.83401 15.98C2.88601 15.848 2.91201 15.68 2.91201 15.476C2.91201 15.296 2.89201 15.148 2.85201 15.032C2.81201 14.912 2.75201 14.822 2.67201 14.762C2.59601 14.702 2.49401 14.672 2.36601 14.672C2.27001 14.672 2.17601 14.694 2.08401 14.738C1.99601 14.778 1.90601 14.848 1.81401 14.948V16.154C1.89801 16.222 1.98401 16.272 2.07201 16.304C2.16401 16.336 2.25001 16.352 2.33001 16.352Z" />
      <path d="M6.16766 17.072C5.79566 17.072 5.51366 16.96 5.32166 16.736C5.12966 16.512 5.03366 16.212 5.03366 15.836V13.49H4.10366V12.8H5.91566V15.872C5.91566 16.056 5.96566 16.184 6.06566 16.256C6.16566 16.324 6.27566 16.358 6.39566 16.358C6.46766 16.358 6.53966 16.35 6.61166 16.334C6.68766 16.318 6.77166 16.294 6.86366 16.262L7.05566 16.91C6.90366 16.958 6.76366 16.996 6.63566 17.024C6.51166 17.056 6.35566 17.072 6.16766 17.072Z" />
      <path d="M8.89532 17.072C8.63532 17.072 8.38932 17.012 8.15732 16.892C7.92532 16.768 7.73732 16.59 7.59332 16.358C7.44932 16.122 7.37732 15.84 7.37732 15.512C7.37732 15.184 7.44932 14.904 7.59332 14.672C7.73732 14.436 7.92532 14.258 8.15732 14.138C8.38932 14.014 8.63532 13.952 8.89532 13.952C9.15532 13.952 9.40132 14.014 9.63332 14.138C9.86532 14.258 10.0533 14.436 10.1973 14.672C10.3413 14.904 10.4133 15.184 10.4133 15.512C10.4133 15.84 10.3413 16.122 10.1973 16.358C10.0533 16.59 9.86532 16.768 9.63332 16.892C9.40132 17.012 9.15532 17.072 8.89532 17.072ZM8.89532 16.358C9.03132 16.358 9.14332 16.324 9.23132 16.256C9.32332 16.188 9.39132 16.09 9.43532 15.962C9.48332 15.834 9.50732 15.684 9.50732 15.512C9.50732 15.34 9.48332 15.19 9.43532 15.062C9.39132 14.934 9.32332 14.836 9.23132 14.768C9.14332 14.7 9.03132 14.666 8.89532 14.666C8.75932 14.666 8.64532 14.7 8.55332 14.768C8.46532 14.836 8.39732 14.934 8.34932 15.062C8.30532 15.19 8.28332 15.34 8.28332 15.512C8.28332 15.684 8.30532 15.834 8.34932 15.962C8.39732 16.09 8.46532 16.188 8.55332 16.256C8.64532 16.324 8.75932 16.358 8.89532 16.358Z" />
      <path d="M12.451 17.072C12.139 17.072 11.857 17.012 11.605 16.892C11.357 16.768 11.159 16.59 11.011 16.358C10.867 16.122 10.795 15.84 10.795 15.512C10.795 15.184 10.875 14.904 11.035 14.672C11.195 14.436 11.405 14.258 11.665 14.138C11.929 14.014 12.213 13.952 12.517 13.952C12.753 13.952 12.967 13.99 13.159 14.066C13.351 14.138 13.509 14.234 13.633 14.354L13.225 14.9C13.109 14.82 12.997 14.762 12.889 14.726C12.785 14.686 12.677 14.666 12.565 14.666C12.397 14.666 12.247 14.7 12.115 14.768C11.983 14.836 11.881 14.934 11.809 15.062C11.737 15.19 11.701 15.34 11.701 15.512C11.701 15.684 11.735 15.834 11.803 15.962C11.875 16.09 11.973 16.188 12.097 16.256C12.225 16.324 12.371 16.358 12.535 16.358C12.691 16.358 12.833 16.33 12.961 16.274C13.093 16.218 13.213 16.152 13.321 16.076L13.675 16.634C13.499 16.782 13.301 16.892 13.081 16.964C12.865 17.036 12.655 17.072 12.451 17.072Z" />
      <path d="M14.1466 17V12.794H15.0286V15.224H15.0526L16.1446 14.024H17.1106L15.0286 16.25V17H14.1466ZM16.2466 17L15.3826 15.65L15.8866 15.104L17.2006 17H16.2466Z" />
    </g>
    <defs>
      <clipPath id="clip0_7068_92304">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const BlockquoteIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99381)">
      <path d="M4.5 12.75H6.75L8.25 9.75V5.25H3.75V9.75H6L4.5 12.75ZM10.5 12.75H12.75L14.25 9.75V5.25H9.75V9.75H12L10.5 12.75Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99381">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const HorizontalRuleIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99371)">
      <path d="M15 8.25H3V9.75H15V8.25Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99371">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const BulletListIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99390)">
      <path d="M3 7.875C2.3775 7.875 1.875 8.3775 1.875 9C1.875 9.6225 2.3775 10.125 3 10.125C3.6225 10.125 4.125 9.6225 4.125 9C4.125 8.3775 3.6225 7.875 3 7.875ZM3 3.375C2.3775 3.375 1.875 3.8775 1.875 4.5C1.875 5.1225 2.3775 5.625 3 5.625C3.6225 5.625 4.125 5.1225 4.125 4.5C4.125 3.8775 3.6225 3.375 3 3.375ZM3 12.375C2.3775 12.375 1.875 12.885 1.875 13.5C1.875 14.115 2.385 14.625 3 14.625C3.615 14.625 4.125 14.115 4.125 13.5C4.125 12.885 3.6225 12.375 3 12.375ZM5.25 14.25H15.75V12.75H5.25V14.25ZM5.25 9.75H15.75V8.25H5.25V9.75ZM5.25 3.75V5.25H15.75V3.75H5.25Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99390">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const OrderedListIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99393)">
      <path d="M1.5 12.75H3V13.125H2.25V13.875H3V14.25H1.5V15H3.75V12H1.5V12.75ZM2.25 6H3V3H1.5V3.75H2.25V6ZM1.5 8.25H2.85L1.5 9.825V10.5H3.75V9.75H2.4L3.75 8.175V7.5H1.5V8.25ZM5.25 3.75V5.25H15.75V3.75H5.25ZM5.25 14.25H15.75V12.75H5.25V14.25ZM5.25 9.75H15.75V8.25H5.25V9.75Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99393">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ClearIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 3H13" strokeWidth="2" strokeLinecap="round" />
    <path d="M6 12L9.35 3" strokeWidth="2" strokeLinecap="round" />
    <path d="M11 11L15 15" strokeLinecap="round" />
    <path d="M15 11L11 15" strokeLinecap="round" />
    <line x1="2.5" y1="14.5" x2="8.5" y2="14.5" strokeLinecap="round" />
  </svg>
);

export const TextAlignLeftIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99363)">
      <path d="M3 16.5H1.5V1.5H3V16.5ZM16.5 5.25H4.5V7.5H16.5V5.25ZM12 10.5H4.5V12.75H12V10.5Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99363">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const TextAlignCenterIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7285_95864)">
      <path d="M8.25 1.5H9.75V5.25H15.75V7.5H9.75V10.5H13.5V12.75H9.75V16.5H8.25V12.75H4.5V10.5H8.25V7.5H2.25V5.25H8.25V1.5Z" />
    </g>
    <defs>
      <clipPath id="clip0_7285_95864">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const TextAlignRightIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7285_95873)">
      <path d="M15 2H16.5V17H15V2ZM1.5 8H13.5V5.75H1.5V8ZM6 13.25H13.5V11H6V13.25Z" />
    </g>
    <defs>
      <clipPath id="clip0_7285_95873">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ImageBlockIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99399)">
      <path d="M14.25 3.75V14.25H3.75V3.75H14.25ZM14.25 2.25H3.75C2.925 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.925 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM10.605 8.895L8.355 11.7975L6.75 9.855L4.5 12.75H13.5L10.605 8.895Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99399">
        <rect width="18" height="18" />
      </clipPath>
    </defs>
  </svg>
);

export const TableIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99384)">
      <path d="M15 2.25H3.75C2.925 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.925 15.75 3.75 15.75H15C15.825 15.75 16.5 15.075 16.5 14.25V3.75C16.5 2.925 15.825 2.25 15 2.25ZM15 3.75V6H3.75V3.75H15ZM11.25 14.25H7.5V7.5H11.25V14.25ZM3.75 7.5H6V14.25H3.75V7.5ZM12.75 14.25V7.5H15V14.25H12.75Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99384">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const AddColumnAfterIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.9901 8.20018H15.8501V6.06018H14.3501V8.20018H12.2101V9.70018H14.3501V11.8402H15.8501V9.70018H17.9901V8.20018Z" />
    <path d="M15.8501 4.5602V3.7002C15.8501 2.8702 15.1801 2.2002 14.3501 2.2002H3.1001C2.2701 2.2002 1.6001 2.8702 1.6001 3.7002V14.2002C1.6001 15.0202 2.2701 15.7002 3.1001 15.7002H14.3501C15.1801 15.7002 15.8501 15.0202 15.8501 14.2002V13.3402H14.3501V14.2002H9.4701V3.7002H14.3501V4.5602H15.8501ZM7.9701 14.2002H3.1001V3.7002H7.9801V14.2002H7.9701Z" />
  </svg>
);

export const AddColumnBeforeIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 9.70018H2.14V11.8402H3.64V9.70018H5.78V8.20018H3.64V6.06018H2.14V8.20018H0V9.70018Z" />
    <path d="M2.14001 13.3402V14.2002C2.14001 15.0302 2.81001 15.7002 3.64001 15.7002H14.89C15.72 15.7002 16.39 15.0302 16.39 14.2002V3.7002C16.39 2.8802 15.72 2.2002 14.89 2.2002H3.64001C2.81001 2.2002 2.14001 2.8802 2.14001 3.7002V4.5602H3.64001V3.7002H8.52001V14.2002H3.64001V13.3402H2.14001ZM10.02 3.7002H14.9V14.2002H10.02V3.7002Z" />
  </svg>
);

export const DeleteColumnIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.9699 7.43018L16.9099 6.37018L15.3899 7.89018L13.8699 6.37018C13.8199 6.42018 12.8099 7.43018 12.8099 7.43018L14.3299 8.95018L12.8099 10.4702C12.8599 10.5202 13.8699 11.5302 13.8699 11.5302L15.3899 10.0102L16.9099 11.5302L17.9699 10.4702L16.4499 8.95018L17.9699 7.43018Z" />
    <path d="M16.1499 5.4602V3.7002C16.1499 2.8702 15.4799 2.2002 14.6499 2.2002H3.3999C2.5699 2.2002 1.8999 2.8702 1.8999 3.7002V14.2002C1.8999 15.0202 2.5699 15.7002 3.3999 15.7002H14.6499C15.4799 15.7002 16.1499 15.0202 16.1499 14.2002V12.4402H14.6499V14.2002H9.7699V3.7002H14.6499V5.4602H16.1499ZM8.2699 14.2002H3.3999V3.7002H8.2799V14.2002H8.2699Z" />
  </svg>
);

export const AddRowAfterIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.08011 15.4601L8.22011 15.4601L8.22011 17.6001L9.72011 17.6001L9.72011 15.4601L11.8601 15.4601L11.8601 13.9601L9.72011 13.9601L9.72011 11.8201L8.22011 11.8201L8.22011 13.9601L6.08011 13.9601L6.08011 15.4601Z" />
    <path d="M3.3501 15.4601L4.5801 15.4601L4.5801 13.9601L3.3501 13.9601L3.3501 9.74008L14.6001 9.74008L14.6001 13.9601L13.3701 13.9601L13.3701 15.4601L14.6001 15.4601C15.4201 15.4601 16.1001 14.7901 16.1001 13.9601L16.1001 4.03008C16.1001 3.20008 15.4201 2.53008 14.6001 2.53008L3.3501 2.53008C2.5201 2.53008 1.8501 3.20008 1.8501 4.03008L1.8501 13.9601C1.8501 14.7901 2.5201 15.4601 3.3501 15.4601ZM3.3501 4.03008L14.6001 4.03008L14.6001 8.25008L3.3501 8.25008L3.3501 4.03008Z" />
  </svg>
);

export const AddRowBeforeIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.9199 2.64H9.77989V0.5H8.27989V2.64H6.13989V4.14H8.27989V6.28H9.77989V4.14H11.9199V2.64Z" />
    <path d="M14.6499 2.64001H13.4199V4.14001H14.6499V8.36001H3.3999V4.14001H4.6299V2.64001H3.3999C2.5799 2.64001 1.8999 3.31001 1.8999 4.14001V14.07C1.8999 14.9 2.5799 15.57 3.3999 15.57H14.6499C15.4799 15.57 16.1499 14.9 16.1499 14.07V4.14001C16.1499 3.31001 15.4799 2.64001 14.6499 2.64001ZM14.6499 14.07H3.3999V9.85001H14.6499V14.07Z" />
  </svg>
);

export const DeleteRowIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.6199 1.7602L10.5599 0.700195L9.02994 2.2302L7.49994 0.700195C7.44994 0.750195 6.43994 1.7602 6.43994 1.7602L7.96994 3.2902L6.46994 4.7902L7.52994 5.8502L9.02994 4.3502L10.5299 5.8502L11.5899 4.7902L10.0899 3.2902L11.6199 1.7602Z" />
    <path d="M14.6499 2.53021H12.4999V4.03021H14.6499V8.25021H3.3999V4.03021H5.5499V2.53021H3.3999C2.5799 2.53021 1.8999 3.20021 1.8999 4.03021V13.9602C1.8999 14.7902 2.5799 15.4602 3.3999 15.4602H14.6499C15.4799 15.4602 16.1499 14.7902 16.1499 13.9602V4.03021C16.1499 3.20021 15.4799 2.53021 14.6499 2.53021ZM3.3999 13.9602V9.74021H14.6499V13.9602H3.3999Z" />
  </svg>
);

export const DeleteTableIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.6499 2.2002H3.3999C2.5699 2.2002 1.8999 2.8702 1.8999 3.7002V14.2002C1.8999 15.0302 2.5699 15.7002 3.3999 15.7002H14.6499C15.4699 15.7002 16.1499 15.0302 16.1499 14.2002V3.7002C16.1499 2.8702 15.4699 2.2002 14.6499 2.2002ZM14.6499 14.2002H3.3999V3.7002H14.6499V14.2002Z" />
    <path d="M7.30988 11.7302L9.02988 10.0102L10.7499 11.7302L11.8099 10.6702L10.0899 8.95017L11.8099 7.23017L10.7499 6.17017L9.02988 7.89017L7.30988 6.17017L6.24988 7.23017L7.96988 8.95017L6.24988 10.6702L7.30988 11.7302Z" />
  </svg>
);

export const MergeCellsIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.3999 3.7002H8.2799V2.2002H3.3999C2.5799 2.2002 1.8999 2.8702 1.8999 3.7002V6.7002H3.3999V3.7002Z" />
    <path d="M14.6499 3.7002V6.7002H16.1499V3.7002C16.1499 2.8702 15.4799 2.2002 14.6499 2.2002H9.7699V3.7002H14.6499Z" />
    <path d="M14.6499 14.2002H9.77991V15.7002H14.6499C15.4799 15.7002 16.1499 15.0202 16.1499 14.2002V11.2002H14.6499V14.2002Z" />
    <path d="M3.3999 14.2002V11.2002H1.8999V14.2002C1.8999 15.0202 2.5799 15.7002 3.3999 15.7002H8.2799V14.2002H3.3999Z" />
    <path d="M16.1499 8.20022H12.2599V6.73022L9.7699 8.95022L12.2599 11.1702V9.70022H16.1499V8.20022Z" />
    <path d="M8.2699 8.95022L5.7799 6.73022V8.20022H1.8999V9.70022H5.7799V11.1702L8.2699 8.95022Z" />
  </svg>
);

export const SplitCellsIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.40002 3.7002H8.28002V2.2002H3.40002C2.58002 2.2002 1.90002 2.8702 1.90002 3.7002V6.1302H3.40002V3.7002Z" />
    <path d="M14.65 3.7002V6.1302H16.15V3.7002C16.15 2.8702 15.48 2.2002 14.65 2.2002H9.77002V3.7002H14.65Z" />
    <path d="M14.65 14.2002H9.78003V15.7002H14.65C15.48 15.7002 16.15 15.0202 16.15 14.2002V11.7702H14.65V14.2002Z" />
    <path d="M3.40002 14.2002V11.7702H1.90002V14.2002C1.90002 15.0202 2.58002 15.7002 3.40002 15.7002H8.28002V14.2002H3.40002Z" />
    <path d="M9.77002 8.20022H13.66V6.73022L16.15 8.95022L13.66 11.1702V9.70022H9.77002V8.20022Z" />
    <path d="M1.90002 8.95022L4.39002 6.73022V8.20022H8.27002V9.70022H4.39002V11.1702L1.90002 8.95022Z" />
  </svg>
);

export const LinkIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99396)">
      <path d="M2.925 9C2.925 7.7175 3.9675 6.675 5.25 6.675H8.25V5.25H5.25C3.18 5.25 1.5 6.93 1.5 9C1.5 11.07 3.18 12.75 5.25 12.75H8.25V11.325H5.25C3.9675 11.325 2.925 10.2825 2.925 9ZM6 9.75H12V8.25H6V9.75ZM12.75 5.25H9.75V6.675H12.75C14.0325 6.675 15.075 7.7175 15.075 9C15.075 10.2825 14.0325 11.325 12.75 11.325H9.75V12.75H12.75C14.82 12.75 16.5 11.07 16.5 9C16.5 6.93 14.82 5.25 12.75 5.25Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99396">
        <rect width="18" height="18" />
      </clipPath>
    </defs>
  </svg>
);

export const FullScreenIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99435)">
      <path d="M5.25 10.5H3.75V14.25H7.5V12.75H5.25V10.5ZM3.75 7.5H5.25V5.25H7.5V3.75H3.75V7.5ZM12.75 12.75H10.5V14.25H14.25V10.5H12.75V12.75ZM10.5 3.75V5.25H12.75V7.5H14.25V3.75H10.5Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99435">
        <rect width="18" height="18" />
      </clipPath>
    </defs>
  </svg>
);

export const CloseFullScreenIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99300)">
      <path d="M3.75 12H6V14.25H7.5V10.5H3.75V12ZM6 6H3.75V7.5H7.5V3.75H6V6ZM10.5 14.25H12V12H14.25V10.5H10.5V14.25ZM12 6V3.75H10.5V7.5H14.25V6H12Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99300">
        <rect width="18" height="18" />
      </clipPath>
    </defs>
  </svg>
);

export const UndoIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99303)">
      <path d="M9.375 6C7.3875 6 5.5875 6.7425 4.2 7.95L1.5 5.25V12H8.25L5.535 9.285C6.5775 8.415 7.905 7.875 9.375 7.875C12.03 7.875 14.2875 9.6075 15.075 12L16.8525 11.415C15.81 8.2725 12.8625 6 9.375 6Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99303">
        <rect width="18" height="18" />
      </clipPath>
    </defs>
  </svg>
);

export const RedoIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99306)">
      <path d="M13.8 7.95C12.4125 6.7425 10.6125 6 8.62503 6C5.13753 6 2.19003 8.2725 1.15503 11.415L2.92503 12C3.71253 9.6075 5.96253 7.875 8.62503 7.875C10.0875 7.875 11.4225 8.415 12.465 9.285L9.75003 12H16.5V5.25L13.8 7.95Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99306">
        <rect width="18" height="18" />
      </clipPath>
    </defs>
  </svg>
);

export const EmbedBlockIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6893_99402)">
      <path d="M13.5 12.06C12.93 12.06 12.42 12.285 12.03 12.6375L6.6825 9.525C6.72 9.3525 6.75 9.18 6.75 9C6.75 8.82 6.72 8.6475 6.6825 8.475L11.97 5.3925C12.375 5.7675 12.9075 6 13.5 6C14.745 6 15.75 4.995 15.75 3.75C15.75 2.505 14.745 1.5 13.5 1.5C12.255 1.5 11.25 2.505 11.25 3.75C11.25 3.93 11.28 4.1025 11.3175 4.275L6.03 7.3575C5.625 6.9825 5.0925 6.75 4.5 6.75C3.255 6.75 2.25 7.755 2.25 9C2.25 10.245 3.255 11.25 4.5 11.25C5.0925 11.25 5.625 11.0175 6.03 10.6425L11.37 13.7625C11.3325 13.92 11.31 14.085 11.31 14.25C11.31 15.4575 12.2925 16.44 13.5 16.44C14.7075 16.44 15.69 15.4575 15.69 14.25C15.69 13.0425 14.7075 12.06 13.5 12.06ZM13.5 3C13.9125 3 14.25 3.3375 14.25 3.75C14.25 4.1625 13.9125 4.5 13.5 4.5C13.0875 4.5 12.75 4.1625 12.75 3.75C12.75 3.3375 13.0875 3 13.5 3ZM4.5 9.75C4.0875 9.75 3.75 9.4125 3.75 9C3.75 8.5875 4.0875 8.25 4.5 8.25C4.9125 8.25 5.25 8.5875 5.25 9C5.25 9.4125 4.9125 9.75 4.5 9.75ZM13.5 15.015C13.0875 15.015 12.75 14.6775 12.75 14.265C12.75 13.8525 13.0875 13.515 13.5 13.515C13.9125 13.515 14.25 13.8525 14.25 14.265C14.25 14.6775 13.9125 15.015 13.5 15.015Z" />
    </g>
    <defs>
      <clipPath id="clip0_6893_99402">
        <rect width="18" height="18" />
      </clipPath>
    </defs>
  </svg>
);

export const ColorIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11594_30521)">
      <path d="M1.5 15H16.5V18H1.5V15ZM4.1175 12.75H5.9325L6.885 10.065H11.1225L12.0675 12.75H13.8825L9.9375 2.25H8.0625L4.1175 12.75ZM7.4325 8.5425L8.955 4.2H9.045L10.5675 8.5425H7.4325Z" />
    </g>
    <defs>
      <clipPath id="clip0_11594_30521">
        <rect width="18" height="18" />
      </clipPath>
    </defs>
  </svg>
);
