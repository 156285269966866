import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { usePutServiceId } from '@/hooks/Service/useServiceWriter';
import { useGetMyService } from '@/hooks/useService';

import Head from '../../Head';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import Textfield from '../../ui/Textfield';
import { validateServiceDomain } from '../../Validations';

import styles from './idSettings.module.css';

import { useInput } from '@/hooks';
import { getPureHost } from '@/util';
import { AlertDialog } from '@/views/Common/Ui/AlertDialog';

const IdSettings: React.FC = () => {
  const { service } = useGetMyService();
  const { t } = useTranslation('serviceIdSettings');
  const { putServiceId, putServiceIdLoading } = usePutServiceId();

  const currentDomain = service?.gsiSinglePartitionKey1.replace('DOMAIN#', '');
  const [newDomain, onChangeDomain, domainError] = useInput(
    undefined,
    // @ts-expect-error
    validateServiceDomain,
  );

  const submit = useCallback(() => {
    // @ts-expect-error
    putServiceId({
      partitionKey: service?.partitionKey,
      domain: newDomain ? newDomain : currentDomain,
    });
  }, [putServiceId, service?.partitionKey, newDomain, currentDomain]);

  // 旧画像形式以前に作成されたサービスかを判定 2/20以前
  const oldImageFormatDate = dayjs('2021-02-20T00:00:00+09:00');
  const oldImageFormatService =
    oldImageFormatDate.unix() > dayjs(service?.createdAt).unix();

  return (
    <div>
      <Head title={t('Change Service ID')} />
      <h2 className={styles.title}>{t('Change Service ID')}</h2>
      <Fieldset
        legend={t('Change {{serviceName}} Service ID', {
          serviceName: service?.serviceName,
        })}
        description={t(
          'Warning! Changing the service ID will change ALL API endpoints.',
        )}
      >
        <div className={styles.actions}>
          <Textfield
            type="text"
            defaultValue={currentDomain}
            onChange={onChangeDomain}
            errorText={domainError}
            dataTestid="id-settings-textfield-service-id"
          />
          <span className={styles.host}>.{getPureHost()}</span>
        </div>
        {oldImageFormatService && (
          <p className={styles.alert}>
            {t(
              'Service ID cannot be changed if it was created before February 20, 2021.',
            )}
          </p>
        )}
      </Fieldset>
      <div className={styles.actionButton}>
        <AlertDialog
          trigger={
            <Button
              type="danger"
              disabled={
                domainError ||
                putServiceIdLoading ||
                !newDomain ||
                newDomain === currentDomain ||
                oldImageFormatService
              }
              value={t('Save changes')}
            />
          }
          title={t('Change Service ID')}
          description={t(
            'Are you sure you want to change your service ID? Changing the service ID will change all API endpoints.',
          )}
          buttonText={t('#Save changes')}
          onSubmit={submit}
        />
      </div>
    </div>
  );
};

export default IdSettings;
