import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  createApi as createApiUsecase,
  deleteApi as deleteApiUsecase,
} from '@/usecase/apiUsecase';

import type { ApiType, CustomField, Field } from '@/API';
import type { Api } from '@/entity/api';
import type { Service } from '@/types/services';

import { useApiListInvalidateCache } from '@/views/Common/api/invalidateCache';

type CreateApi = {
  apiName: string;
  apiEndpoint: string;
  apiType: ApiType;
  apiFields: Field[];
  customFields: CustomField[];
};

export const useCreateApi = (serviceId: string) => {
  const { t } = useTranslation('hooksApi');
  const { addToast } = useToasts();

  const {
    mutate: createApi,
    mutateAsync: createApiAsync,
    isLoading: createApiIsLoading,
    isSuccess: createApiIsSuccess,
    isError: createApiIsError,
  } = useMutation<unknown, Error, CreateApi>({
    mutationFn: async ({
      apiName,
      apiEndpoint,
      apiType,
      apiFields,
      customFields,
    }) => {
      return await createApiUsecase({
        serviceId,
        apiName,
        apiEndpoint,
        apiType,
        apiFields,
        customFields,
      });
    },
    onError() {
      addToast(t('API could not be created.'), {
        appearance: 'error',
      });
    },
  });

  return {
    createApi,
    createApiAsync,
    createApiIsLoading,
    createApiIsSuccess,
    createApiIsError,
  };
};

export const useDeleteApi = (api: Api, service?: Service | null) => {
  const { t } = useTranslation('hooksApi');
  const apiListInvalidateCache = useApiListInvalidateCache();
  const { addToast } = useToasts();
  const history = useHistory();

  const { mutate: deleteApi, isLoading: deleteApiLoading } = useMutation({
    mutationFn: () => {
      return deleteApiUsecase({ apiId: api.partitionKey });
    },
    onSuccess() {
      apiListInvalidateCache();
      addToast(t('API has been deleted.'), {
        appearance: 'success',
      });
      history.push('/apis');
    },
    onError({ message }) {
      addToast(message ? message : t('API could not be deleted.'), {
        appearance: 'error',
      });
    },
  });

  return { deleteApi, deleteApiLoading };
};
