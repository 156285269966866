import type React from 'react';
import { useTranslation } from 'react-i18next';

import { optionTypes } from '../../constants/optionTypes';
import { richEditorV2Options } from '../../constants/richEditorOptions';
import {
  RichEditor,
  Filefield,
  RelationSelect,
  RelationListSelect,
  Iframefield,
} from '../Form';
import Fieldset from '../ui/Fieldset';
import Selectfield from '../ui/Selectfield';
import Switch from '../ui/Switch';
import Textarea from '../ui/Textarea';
import Textfield from '../ui/Textfield';

import styles from './fieldLayout.module.css';

import { DatePicker } from '@/views/Common/Ui/DatePicker';
import { RichEditorV2 } from '@/views/InputField/RichEditorV2';

type Props = {
  field: any;
  onDragStart: (e: any) => void;
  onDrop: (e: any) => void;
  columnName: 'left' | 'right';
};

const FieldLayout: React.FC<Props> = ({
  field,
  onDragStart,
  onDrop,
  columnName,
}) => {
  const { t } = useTranslation('fieldLayout');

  const richEditorOptions =
    field.richEditorOptions === null ? optionTypes : field.richEditorOptions;
  return (
    <li
      className={styles.list}
      draggable
      onDragStart={onDragStart}
      onDrop={onDrop}
      data-id-value={field.idValue}
      data-column-name={columnName}
    >
      {field.kind === 'text' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          <Textfield type="text" readOnly />
        </Fieldset>
      ) : field.kind === 'textArea' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          <Textarea readOnly />
        </Fieldset>
      ) : field.kind === 'richEditor' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          {/* @ts-expect-error */}
          <RichEditor readOnly richEditorOptions={richEditorOptions} />
        </Fieldset>
      ) : field.kind === 'richEditorV2' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          <RichEditorV2
            onChange={() => {}}
            defaultValue={{}}
            richEditorV2Options={
              !field.richEditorV2Options
                ? richEditorV2Options
                : field.richEditorV2Options
            }
            editable={false}
            customClassList={[]}
            colorList={[]}
          />
        </Fieldset>
      ) : field.kind === 'media' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          <img
            className={styles.media}
            draggable={false}
            src="/images/icon_default_image.svg"
            alt=""
          />
        </Fieldset>
      ) : field.kind === 'mediaList' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          <div className={styles.mediaList}>
            <img
              draggable={false}
              src="/images/icon_default_image.svg"
              alt=""
            />
            <img
              draggable={false}
              src="/images/icon_default_image.svg"
              alt=""
            />
          </div>
        </Fieldset>
      ) : field.kind === 'file' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          {/* @ts-expect-error */}
          <Filefield readOnly />
        </Fieldset>
      ) : field.kind === 'date' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          <DatePicker
            reactDatePickerProps={{
              // readOnlyなので、イベントを何も返さない
              onChange: () => null,
              readOnly: true,
            }}
          />
        </Fieldset>
      ) : field.kind === 'boolean' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          <Switch on={false} size="large" />
        </Fieldset>
      ) : field.kind === 'select' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          <Selectfield
            disabled={true}
            multiple={field.multipleSelect}
            placeholder={{
              value: '',
              name: t('Select'),
            }}
          >
            {/* @ts-expect-error */}
            {field.selectItems.map((item) => (
              <option key={item.id} value={item.id}>
                {item.value}
              </option>
            ))}
          </Selectfield>
        </Fieldset>
      ) : field.kind === 'relation' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          <RelationSelect
            referenceKey={field.referenceKey}
            parentField={field}
            readOnly
          />
        </Fieldset>
      ) : field.kind === 'relationList' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          <RelationListSelect
            referenceKey={field.referenceKey}
            parentField={field}
            readOnly
          />
        </Fieldset>
      ) : field.kind === 'iframe' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          {/* @ts-expect-error */}
          <Iframefield title={field.idValue} src={field.iframeUrl} />
        </Fieldset>
      ) : field.kind === 'number' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          <Textfield type="number" readOnly />
        </Fieldset>
      ) : field.kind === 'custom' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          <div className={styles.area}>{t('Custom Fields')}</div>
        </Fieldset>
      ) : field.kind === 'repeater' ? (
        <Fieldset
          legend={field.name}
          description={field.description}
          option={!field.required}
        >
          <div className={styles.area}>{t('Repeated Fields')}</div>
        </Fieldset>
      ) : null}
    </li>
  );
};

export default FieldLayout;
