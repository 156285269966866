import { API, graphqlOperation } from 'aws-amplify';

import type { ApiResponse } from '@/views/Common/handleApiResult';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

import * as mutations from '@/graphql/mutations';
import { handleApiResult } from '@/views/Common/handleApiResult';

export type UpdateParams = {
  serviceId: string;
  environmentName: string;
  closeEnvNameModal: () => void;
};

export type UpdateResult =
  | {
      result: {
        result: boolean;
        message: string;
      };
    }
  | {
      result: null;
    };

export const environmentRepository = (serviceId: string) => {
  const updateEnvironmentName = async (
    environmentName: string,
  ): Promise<UpdateResult> => {
    try {
      const result = await API.graphql(
        graphqlOperation(mutations.updateEnvironmentName, {
          serviceId,
          environmentName,
        }),
      );
      const typedResult = result as {
        data: { updateEnvironmentName: string };
      };

      if (typedResult.data.updateEnvironmentName != null) {
        const parseData = JSON.parse(
          typedResult.data.updateEnvironmentName,
        ) as {
          result: boolean;
          message: string;
        };
        return { result: parseData };
      }

      return { result: null };
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not update environment name');
    }
  };

  const deleteEnvironment = async ({ serviceId }: { serviceId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.deleteEnvironment, {
          serviceId,
        }),
      )) as GraphQLResult<{
        deleteEnvironment: ApiResponse | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.deleteEnvironment === 'object' &&
        typeof result.data.deleteEnvironment.result === 'boolean'
      ) {
        return handleApiResult(result.data.deleteEnvironment);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }
      throw new Error('Unexpected Error');
    }
  };

  return { updateEnvironmentName, deleteEnvironment };
};
