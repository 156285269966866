import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';

import IpDenied from '@/components/IpDenied/IpDenied';
import Maintenance from '@/components/Maintenance';
import MfaDenied from '@/components/MfaDenied/MfaDenied';
import SAMLDenied from '@/components/SAMLDenied/SAMLDenied';
import Toast from '@/components/ui/Toast';

import type { ComponentType } from 'react';
import type { ToastProps } from 'react-toast-notifications';

import { generateQueryClient } from '@/lib/queryClient';
import { store } from '@/store';

type Props = {
  children: React.ReactNode;
  results: {
    ipResult: boolean;
    mfaResult: boolean;
    samlResult: boolean;
  };
};

const queryClient = generateQueryClient();

export const AppProvider: React.FC<Props> = ({ children, results }) => {
  return (
    <Provider store={store}>
      <Maintenance>
        <SAMLDenied allowAccess={results.samlResult}>
          <MfaDenied allowAccess={results.mfaResult}>
            <IpDenied allowAccess={results.ipResult}>
              <QueryClientProvider client={queryClient}>
                <ToastProvider
                  autoDismiss={true}
                  autoDismissTimeout={3000}
                  components={{ Toast: Toast as ComponentType<ToastProps> }}
                >
                  <CookiesProvider>{children}</CookiesProvider>
                </ToastProvider>
                <ReactQueryDevtools
                  initialIsOpen={false}
                  position="bottom-right"
                />
              </QueryClientProvider>
            </IpDenied>
          </MfaDenied>
        </SAMLDenied>
      </Maintenance>
    </Provider>
  );
};
