import { Extension } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    customClass: {
      setCustomClass: (customClassId: string) => ReturnType;
      unsetCustomClass: () => ReturnType;
    };
  }
}

export const CustomClass = Extension.create({
  name: 'customClass',

  addOptions() {
    return {
      types: ['textStyle'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        attributes: {
          customClassId: {
            default: null,
            parseHTML: (element) => element.getAttribute('data-custom-class'),
            renderHTML: (attributes) => {
              if (!attributes.customClassId) {
                return {};
              }

              return {
                'data-custom-class': attributes.customClassId,
              };
            },
          },
        },
        types: this.options.types,
      },
    ];
  },

  addCommands() {
    return {
      setCustomClass:
        (customClassId) =>
        ({ chain }) => {
          return chain().setMark('textStyle', { customClassId }).run();
        },
      unsetCustomClass:
        () =>
        ({ chain }) => {
          return chain()
            .setMark('textStyle', { customClassId: null })
            .unsetMark('textStyle', { extendEmptyMarkRange: true })
            .run();
        },
    };
  },
});
