import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useContentCapacityRead } from './ContentCapacityProvider';

import styles from './contentcapacity.module.css';

import { InfoTooltip } from '@/views/Common/Ui/InfoTooltip';

export type Props = {};

export type ViewProps = {
  capacityPercentage: number | null;
  isCapacityExceeded: boolean;
};

export const ContentCapacityView: React.FC<ViewProps> = ({
  capacityPercentage,
  isCapacityExceeded,
}) => {
  const { t } = useTranslation('apisContentContentCapacity');

  return (
    <div className={styles.main}>
      <span className={styles.title}>{t('Content Free Capacity')}</span>
      {capacityPercentage !== null ? (
        <>
          <span
            className={cx(
              styles.capacity,
              isCapacityExceeded && styles.exceeded,
            )}
          >
            {capacityPercentage}%
          </span>
          {isCapacityExceeded && (
            <InfoTooltip aria-label={t('Info')} iconName="info">
              <p>
                {t(
                  'Saving may fail because the capacity limit has been exceeded.',
                )}
              </p>
            </InfoTooltip>
          )}
        </>
      ) : (
        <span>...</span>
      )}
    </div>
  );
};

export const ContentCapacity: React.FC<Props> = () => {
  const capacityPercentage = useContentCapacityRead();

  const isCapacityExceeded = capacityPercentage === 0;

  return (
    <ContentCapacityView
      capacityPercentage={capacityPercentage}
      isCapacityExceeded={isCapacityExceeded}
    />
  );
};
