import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCustomFields } from '../../../hooks/CustomField/useCustomFieldReader';
import { useStripeActions } from '../../../hooks/useStripeActions';
import Feedback from '../../Feedback';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import Textfield from '../../ui/Textfield';

import type { MigrateApi } from '../../../entity/api';
import type { Service } from '../../../types/services';

import styles from './inputBasicInfo.module.css';

type Props = {
  service: Service;
  api: MigrateApi;
  goNext: () => void;
  nameInput: [name: string, onChangeName: () => void, nameError: string | null];
  idInput: [id: string, onChangeId: () => void, idError: string | null];
};

const InputBasicInfo: React.FC<Props> = ({
  service,
  api,
  goNext,
  nameInput: [name, onChangeName, nameError],
  idInput: [id, onChangeId, idError],
}) => {
  const { t } = useTranslation('inputBasicInfo');
  const [customFields = []] = useCustomFields(api.partitionKey);
  const { currentPlan } = useStripeActions(service);
  const hasLocalError = useMemo(
    () => nameError !== null || idError !== null,
    [nameError, idError],
  );
  const hasMaxFields = useMemo(() => {
    if (
      currentPlan &&
      currentPlan.limit.customFieldCount !== null &&
      currentPlan.limit.customFieldCount <= customFields.length
    ) {
      return true;
    }
    return false;
  }, [currentPlan, customFields.length]);

  const [duplicationError, setDuplicationError] = useState(false);

  const next = useCallback(() => {
    if (customFields.some((field) => field.fieldId === id)) {
      setDuplicationError(true);
    } else {
      setDuplicationError(false);
      goNext();
    }
  }, [goNext, customFields, id]);

  return (
    <div className={styles.area}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>
          {t('Enter Basic Information for Custom Field')}
        </h2>
        <div className={styles.container}>
          <Fieldset
            legend={t('Custom Field Name')}
            description={t(
              'Enter brief description of the field you wish to create.',
            )}
          >
            <Textfield
              type="text"
              placeholder={t(
                'E.g., product introduction, related links, table of contents',
              )}
              defaultValue={name}
              onChange={onChangeName}
              errorText={nameError}
            />
          </Fieldset>
          <Fieldset
            legend={t('Custom Field ID')}
            description={t(
              'Enter ID of the field you wish to create. This will be used for identifying the field within an API response.',
            )}
          >
            <Textfield
              type="text"
              placeholder={t('e.g., product, relatedLinks, tableOfContents')}
              defaultValue={id}
              onChange={onChangeId}
              errorText={idError}
            />
          </Fieldset>
        </div>
        {hasMaxFields && (
          <p className={styles.countAlert}>
            {t(
              'A new Custom field cannot be created because the maximum number of Custom fields has been reached.',
            )}
            <Link to="/settings/billing">
              {t('For more information, click here.')}
            </Link>
            。
          </p>
        )}
      </div>
      <div className={styles.actions}>
        <span className={styles.step}>
          <em>1</em>/3 {t('steps')}
        </span>
        <div className={styles.next}>
          <Button
            className="ga-create-customfield-basic-info"
            type="secondary"
            value={t('Next')}
            disabled={hasLocalError || hasMaxFields}
            onClick={hasMaxFields ? undefined : next}
          />
          <Feedback
            type="failure"
            message={
              duplicationError
                ? t('Same Custom field ID already exists.')
                : undefined
            }
          />
        </div>
      </div>
    </div>
  );
};

export default InputBasicInfo;
