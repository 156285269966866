import type React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Fieldset from '../../ui/Fieldset';
import Switch from '../../ui/Switch';

type Props = {
  isUnique: boolean;
  onChange: (enabled: boolean) => void;
};

const UniqueValidationField: React.FC<Props> = ({ isUnique, onChange }) => {
  const { t } = useTranslation('uniqueValidationField');
  const [enabled, setEnabled] = useState(() => !!isUnique);

  useEffect(() => {
    onChange(enabled);
  }, [onChange, enabled]);

  return (
    <Fieldset
      legend={t('Do not allow duplicates(Unique)')}
      description={t(
        'If other content has the same value, the content cannot be saved. Up to five fields can have this setting for one API.',
      )}
    >
      <Switch
        on={enabled}
        onChange={(state) => setEnabled(state)}
        size="large"
      />
    </Fieldset>
  );
};

export default UniqueValidationField;
