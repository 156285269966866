import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { updateEnvironmentName } from '@/usecase/environmentSettingsUsecase';

export const useUpdateEnvironmentNameModalQuery = () => {
  const useUpdateEnvironmentName = () => {
    const { t } = useTranslation('updateEnvironmentNameModal');
    const cache = useQueryClient();
    const { addToast } = useToasts();

    return useMutation(updateEnvironmentName, {
      onSuccess({ result }, { closeEnvNameModal }) {
        if (!result) {
          addToast(t('Environment name could not be changed.'), {
            appearance: 'error',
          });
          return;
        }
        if (result.result === false) {
          addToast(
            result.message
              ? result.message
              : t('Environment name could not be changed.'),
            { appearance: 'error' },
          );
          return;
        }
        cache.invalidateQueries(['environments'], { type: 'all' });
        addToast(t('Changed environment name.'), { appearance: 'success' });
        closeEnvNameModal();
      },
      onError(error: Error) {
        addToast(
          error.message
            ? error.message
            : t('Environment name could not be changed.'),
          {
            appearance: 'error',
          },
        );
      },
    });
  };

  return {
    useUpdateEnvironmentName,
  };
};
