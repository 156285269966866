import { getGlobalApiIpAddressRestriction } from '@/usecase/apiIpAddressRestrictionUsecase';

import { apiIpAddressRestrictionQueryKeys } from './queryKeys';

import { useApi } from '@/lib/useApi';

export const useGlobalApiIpAddressRestriction = (
  serviceId?: string | null | undefined,
) => {
  return useApi(
    apiIpAddressRestrictionQueryKeys.global,
    async () => {
      if (!serviceId) {
        return undefined;
      }

      return getGlobalApiIpAddressRestriction(serviceId);
    },
    { enabled: !!serviceId, staleTime: Number.POSITIVE_INFINITY },
  );
};
