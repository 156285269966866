import {
  FloatingFocusManager,
  autoUpdate,
  useDismiss,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import { useFloating, useInteractions } from '@floating-ui/react';
import cx from 'classnames';
import { useRef } from 'react';

import { useCombobox } from './useCombobox';
import { Loading } from '../Loading';

import styles from './combobox.module.css';

import { Icon } from '@/views/Common/Ui/Icon';
import { Input } from '@/views/Common/Ui/Input';

type Item = {
  key: string;
};

type Props<T extends Item> = {
  list?: T[] | null | undefined;
  currentValueKeys: string[];
  filterRule: (item: T, inputValue: string) => boolean;
  equalRule?: (item: T, key: string) => boolean;
  ItemComponent: React.FC<{ item: T }>;
  onConfirm: (item: T) => void;
  onEscapeKeyDown: () => void;
  inputAriaLabel: string;
  listAriaLabel: string;
  className?: string;
};

type ViewProps<T extends Item> = {
  currentValueKeys: string[];
  onConfirm: (item: T) => void;
  ItemComponent: React.FC<{ item: T }>;
  inputAriaLabel: string;
  listAriaLabel: string;
  filteredList: T[] | null;
  activeIndex: number | null;
  setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onInputKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
};

const ComboboxView = <T extends Item>({
  currentValueKeys,
  onConfirm,
  ItemComponent,
  inputAriaLabel,
  listAriaLabel,
  filteredList,
  activeIndex,
  setActiveIndex,
  onInputChange,
  onInputKeyDown,
  className,
}: ViewProps<T>) => {
  const listRef = useRef<Array<HTMLElement | null>>([]);

  const { refs, context } = useFloating<HTMLInputElement>({
    open: true,
    whileElementsMounted: autoUpdate,
  });

  const role = useRole(context, { role: 'listbox' });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNav],
  );

  return (
    <div className={cx(styles.main, className)}>
      {filteredList === null ? (
        <Loading className={styles.loading} />
      ) : (
        <>
          <div className={styles.inputGroup}>
            <Input
              {...getReferenceProps({
                ref: refs.setReference,
                className: styles.input,
                onChange: onInputChange,
                onKeyDown: onInputKeyDown,
                'aria-label': inputAriaLabel,
                'aria-autocomplete': 'list',
              })}
            />
            <Icon name="search" size="large" className={styles.inputIcon} />
          </div>
          <FloatingFocusManager context={context} initialFocus={-1}>
            <ul
              {...getFloatingProps({
                ref: refs.setFloating,
                className: styles.list,
                'aria-label': listAriaLabel,
              })}
            >
              {filteredList.map((item, index) => {
                const selected = currentValueKeys.includes(item.key);

                return (
                  <li
                    key={item.key}
                    {...getItemProps({
                      ref(node) {
                        listRef.current[index] = node;
                      },
                      onClick() {
                        onConfirm(item);
                      },
                      role: 'option',
                      'aria-selected': selected,
                      className: cx(
                        styles.item,
                        activeIndex === index && styles.active,
                      ),
                    })}
                  >
                    <ItemComponent item={item} />
                    {selected && (
                      <Icon
                        name="check"
                        size="large"
                        className={cx(styles.selectedIcon)}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </FloatingFocusManager>
        </>
      )}
    </div>
  );
};

const Combobox = <T extends Item>(props: Props<T>) => {
  const hooks = useCombobox(props);

  return <ComboboxView {...hooks} />;
};

export { Combobox };
export type { Props as ComboboxProps, Item as ComboboxItem };
