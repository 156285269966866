import type React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';

import CheckRoles from '../CheckRoles';
import EditMember from '../EditMember';
import Main from '../Main';
import MemberList from '../MemberList';
import PrivateRoute from '../PrivateRoute';

import styles from './membersTop.module.css';

const MembersTop: React.FC = () => {
  const { t } = useTranslation('membersTop');
  return (
    <Main
      header={
        <header className={styles.header}>
          <h2 className={styles.title}>{t('Manage Members')}</h2>
        </header>
      }
    >
      <CheckRoles
        permission="memberPermission"
        operation="simpleRead"
        display={true}
      >
        <Switch>
          <PrivateRoute exact path="/members" component={MemberList} />
          <PrivateRoute
            exact
            path="/members/:memberId"
            component={EditMember}
          />
          <PrivateRoute
            exact
            path="/members/invitation"
            component={EditMember}
          />
        </Switch>
      </CheckRoles>
    </Main>
  );
};

export default MembersTop;
