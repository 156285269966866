import { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

import { useApiKeysReader } from '../../../hooks/ApiKey/useApiKeyReader';
import { useMembers } from '../../../hooks/Member/useMembers';
import { myRolesContext } from '../../../hooks/Role/useMyRoles';
import { useRoleList } from '../../../hooks/Role/useRoles';
import CheckRoles from '../../CheckRoles';

import type { Service } from '../../../types/services';

import styles from './permission.module.css';

interface Props {
  service: Service;
  close: () => void;
  isChildEnvironment: boolean;
}

const PermissionList: React.FC<Props> = ({
  service,
  close,
  isChildEnvironment,
}) => {
  // 権限リストの取得
  const { serviceUsers: members } = useMembers(service);
  const [roles] = useRoleList(service && service.partitionKey);
  const [apiKeys] = useApiKeysReader(service?.partitionKey);
  const { t } = useTranslation('permissionList');

  const context = useContext(myRolesContext);

  const allPermission = useMemo(() => {
    return context.roles
      ? context.roles.some(
          (role: any) =>
            role.memberPermission.simpleRead === true ||
            role.rolePermission.readLevel === 'ALL' ||
            role.rolePermission.readLevel === 'SAME_ROLE' ||
            role.apiKeyPermission.read === true,
        )
      : undefined;
  }, [context.roles]);

  return (
    <div className={styles.permissions}>
      {allPermission && (
        <dl>
          <dt className={styles.title}>
            <Link to="/members" onClick={close}>
              {t('Permissions')}
            </Link>
          </dt>
          <dd className={styles.length}>
            {!isChildEnvironment && (
              <>
                <CheckRoles
                  permission="memberPermission"
                  operation="simpleRead"
                >
                  <NavLink
                    to="/members"
                    activeClassName={styles.isActive}
                    onClick={close}
                  >
                    <i className={`material-icons-outlined`}>person</i>
                    {t('Number of members', {
                      count: (members && members.length) || 0,
                    })}
                  </NavLink>
                </CheckRoles>
                <CheckRoles permission="rolePermission" operation="readLevel">
                  <NavLink
                    to="/roles"
                    activeClassName={styles.isActive}
                    onClick={close}
                  >
                    <i className={`material-icons-outlined`}>group</i>
                    {t('Number of roles', {
                      count: (roles && roles.length) || 0,
                    })}
                  </NavLink>
                </CheckRoles>
              </>
            )}
            <CheckRoles permission="apiKeyPermission">
              <NavLink
                to="/api-keys"
                activeClassName={styles.isActive}
                onClick={close}
              >
                <i className={`material-icons-outlined`}>vpn_key</i>
                {t('Number of API keys', {
                  count: (apiKeys && apiKeys.length) || 0,
                })}
              </NavLink>
            </CheckRoles>
          </dd>
        </dl>
      )}
    </div>
  );
};

export default PermissionList;
