import { Td } from '@/components/ui/Table';

import { FIELD_NO_VALUE } from '../constants';
import { useI18nFormRole } from '../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../provider';

import { Select } from '@/views/Common/Roles/Select';
import { Checkbox } from '@/views/Common/Ui/Checkbox';

const FieldReviewRequest = () => {
  const { t } = useI18nFormRole();

  const {
    isAdminRole,
    input: { reviewRequestPermission },
  } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  return (
    <>
      <Td>{t('Review requests')}</Td>
      <Td>
        <Select
          value={reviewRequestPermission.readLevel}
          onValueChange={(value) =>
            dispatch({
              type: 'set-reviewrequest-permission-read',
              payload: value,
            })
          }
          disabled={disabled}
          name="reviewRequestPermission.readLevel"
          items={[
            {
              value: 'ALL',
              label: t('All reviews'),
              description: t('View all review requests.'),
            },
            {
              value: 'SAME_ROLE',
              label: t('Only reviews created by members of this role'),
              description: t(
                'Able to view only review created by members in the same role.',
              ),
            },
            {
              value: 'CREATED',
              label: t('Only reviews you requested for'),
              description: t(
                'Able to view only the reviews you have submitted.',
              ),
            },
            {
              value: 'NONE',
              label: t('None'),
              description: t('Review requests cannot be displayed.'),
            },
          ]}
        />
      </Td>
      <Td>
        <Checkbox
          checked={reviewRequestPermission.create}
          disabled={disabled}
          name="reviewRequestPermission.create"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-reviewrequest-permission-create',
              payload,
            });
          }}
          label={t('Request reviews')}
        />
      </Td>
      <Td>
        <Select
          value={reviewRequestPermission.updateLevel}
          onValueChange={(value) =>
            dispatch({
              type: 'set-reviewrequest-permission-update',
              payload: value,
            })
          }
          disabled={disabled}
          name="reviewRequestPermission.updateLevel"
          items={[
            {
              value: 'ALL',
              label: t('Edit review forms and approve'),
              description: t(
                'In addition to editing and comments, you can delete comments and approve reviews (publish content).',
              ),
            },
            {
              value: 'EDIT',
              label: t('Edit review forms only'),
              description: t(
                'Able to close, reapply, post comments, and edit the review title and description.',
              ),
            },
            {
              value: 'NONE',
              label: t('None'),
              description: t('Reviews cannot be edited.'),
            },
          ]}
        />
      </Td>
      <Td>{FIELD_NO_VALUE}</Td>
    </>
  );
};

export default FieldReviewRequest;
