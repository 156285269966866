import { CodeCommitIcon } from '../../Icons';
import { Loading } from '../../Ui/Loading';
import { useContentsByVersions } from '../useContentReader';
import { ContentsComparisonViewer } from './ContentsComparisonViewer';
import { buildContentComparisonList } from './buildContentComparisonList';
import { useI18nContentsComparison } from './useI18nContentsComparison';

import type { MigrateApi } from '@/entity/api';
import type { ContentByVersion } from '@/entity/content';
import type { CustomField } from '@/types/contents';

import styles from './contentsComparison.module.css';

type LoaderProps = {
  api: MigrateApi;
  customFields: CustomField[];
  contentId: string;
  sinceVersion: number;
  untilVersion: number;
};

const ContentsComparisonLoader: React.FC<LoaderProps> = ({
  api,
  customFields,
  contentId,
  sinceVersion,
  untilVersion,
}) => {
  const [
    { data: sinceContent, isLoading: isSinceContentLoading },
    { data: untilContent, isLoading: isUntilContentLoading },
  ] = useContentsByVersions(contentId, [
    { version: sinceVersion },
    { version: untilVersion },
  ]);

  if (
    isSinceContentLoading ||
    isUntilContentLoading ||
    !sinceContent ||
    !untilContent
  ) {
    return <Loading className={styles.loading} />;
  }

  const unauthorizedFieldIds = Array.from(
    new Set(
      sinceContent.unauthorizedFieldIds.concat(
        untilContent.unauthorizedFieldIds,
      ),
    ),
  );

  return (
    <ContentsComparisonComponent
      key={`${sinceVersion}-${untilVersion}`}
      apiFields={api.apiFields}
      customFields={customFields}
      sinceContent={sinceContent.value}
      untilContent={untilContent.value}
      noDiffSkip
      unauthorizedFieldIds={unauthorizedFieldIds}
    />
  );
};

type ComponentProps = {
  apiFields: MigrateApi['apiFields'];
  customFields: CustomField[];
  sinceContent: ContentByVersion['value'];
  untilContent: ContentByVersion['value'];
  noDiffSkip?: boolean;
  unauthorizedFieldIds?: string[];
};

const ContentsComparisonComponent: React.FC<ComponentProps> = ({
  apiFields,
  customFields,
  sinceContent,
  untilContent,
  noDiffSkip = false,
  unauthorizedFieldIds,
}) => {
  const { t } = useI18nContentsComparison();

  const comparisonList = buildContentComparisonList(
    apiFields,
    sinceContent,
    untilContent,
    noDiffSkip,
    unauthorizedFieldIds,
  );

  if (comparisonList.length === 0) {
    return (
      <div className={styles.noDiff}>
        <CodeCommitIcon />
        <p>{t('No differentials.')}</p>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      {comparisonList.map((comparison) => {
        const apiField = apiFields.find(
          (field) => field.idValue === comparison.idValue,
        );
        if (!apiField) {
          // 実装上ここには来ないはずだが、型を絞り込むためにnullを返す
          return null;
        }

        return (
          <ContentsComparisonViewer
            key={comparison.idValue}
            apiField={apiField}
            customFields={customFields}
            comparison={comparison}
          />
        );
      })}
    </div>
  );
};

export {
  ContentsComparisonLoader as ContentsComparison,
  ContentsComparisonComponent,
};
