import type { FieldKind } from '@/types/field';

type RelatedContentIds = {
  partitionKey: string;
  apiId: string;
  gsiSinglePartitionKey1: string;
  sortKey: string;
};

/**
 * コンテンツのステータス識別子
 *
 * 'PUBLISH_AND_RESERVATION' 過去に定義があったがDBに存在しない値であり、さらに書き込みもされない値なので削除した.
 */
export type ContentStatus =
  | 'DRAFT'
  | 'PUBLISH'
  | 'PUBLISH_AND_DRAFT'
  | 'CLOSED';

export type Content = {
  partitionKey: string;
  sortKey: string;
  gsiPartitionKey1: string;
  gsiSortKey1: string;
  gsiPartitionKey2: string;
  gsiSortKey2: string;
  gsiPartitionKey3: string;
  gsiSortKey3: string;
  contentStatus: ContentStatus;
  contentValue?: string;
  draftValue?: string;
  closedValue?: string;
  gsiSinglePartitionKey1?: string;
  contentDraftKey?: string;
  contentCustomStatusIds?: string[];
  reservationTime?: string;
  reservationStopTime?: string;
  relatedContentIds?: RelatedContentIds[];

  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  revisedAt: string;
  closedAt?: string;
  editorId: string;
  authorId: string;
  // inputSource?: InputSource
  // groups: string[]
};

export type ContentValue = Record<string, Partial<Record<FieldKind, unknown>>>;

export type BulkContentsResponse = {
  result?: boolean;
  successIds?: string[];
  failureIds?: string[];
};

export type BulkContentStatus = 'PUBLISH' | 'DRAFT';

export const getContentId = (content: Content): string => {
  return content.gsiSortKey2.replace(/.*#CONTENT_ID#/, '');
};

export type ContentByVersion = {
  /**
   * コンテンツAPIで取得できる値の形式
   * @example { id: 'contentId', title: 'title', body: "<p>body</p>", ... }
   */
  value: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  unauthorizedFieldIds: string[];
};
