import cx from 'classnames';

import { useI18nContentsComparison } from '../../../useI18nContentsComparison';

import styles from './modeChangeButtons.module.css';

type Props<T extends string> = {
  currentMode: T;
  modeList: [{ key: T; label: string }, { key: T; label: string }];
  setMode: React.Dispatch<React.SetStateAction<T>>;
};

const ModeChangeButtons = <T extends string>({
  currentMode,
  modeList,
  setMode,
}: Props<T>) => {
  const { t } = useI18nContentsComparison();

  return (
    <div className={styles.main}>
      <div className={styles.group}>
        <p className={styles.text}>{t('Display format')}</p>
        <div className={styles.buttons}>
          <button
            title={t('Change the display format to {{label}} format', {
              label: modeList[0].label,
            })}
            type="button"
            onClick={() => setMode(modeList[0].key)}
            className={cx(
              styles.button,
              currentMode === modeList[0].key && styles.active,
            )}
          >
            {modeList[0].label}
          </button>
          <button
            title={t('Change the display format to {{label}} format', {
              label: modeList[1].label,
            })}
            type="button"
            onClick={() => setMode(modeList[1].key)}
            className={cx(
              styles.button,
              currentMode === modeList[1].key && styles.active,
            )}
          >
            {modeList[1].label}
          </button>
        </div>
      </div>
    </div>
  );
};

export { ModeChangeButtons };
