import { useState, useEffect, useCallback } from 'react';

import {
  createCustomField as createCustomFieldUsecase,
  updateCustomField as updateCustomFieldUsecase,
  deleteCustomField as deleteCustomFieldUsecase,
} from '@/usecase/contentUsecase';

const useCustomFieldWriter = (api) => {
  const apiId = api.partitionKey;

  const [result, setResult] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  // 結果はしばらくしたら消す;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setResult(undefined);
      setError(undefined);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [result, error]);

  const create = useCallback(
    (fieldId, name, fields, position) => {
      setLoading(true);
      createCustomFieldUsecase({
        apiId,
        fieldId,
        name,
        fields,
        position,
      })
        .then((result) => setResult(result))
        .catch((error) => setError(error))
        .finally(() => setLoading(false));
    },
    [apiId],
  );

  const update = useCallback(
    (currentField, fieldId, name, fields, position) => {
      setLoading(true);
      updateCustomFieldUsecase({
        apiId: currentField.apiId,
        fieldId,
        createdAt: currentField.createdAt,
        name,
        fields,
        position,
      })
        .then((result) => {
          if (currentField.fieldId !== fieldId) {
            window.location.href = `/apis/${api.apiEndpoint}/custom-fields/${fieldId}`;
          }
          setResult(result);
        })
        .catch((error) => setError(error))
        .finally(() => setLoading(false));
    },
    [api.apiEndpoint],
  );

  const deleteFunc = useCallback((currentField) => {
    setLoading(true);
    deleteCustomFieldUsecase({
      apiId: currentField.apiId,
      createdAt: currentField.createdAt,
    })
      .then((result) => setResult(result))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  return { result, error, create, update, deleteFunc, loading };
};

export default useCustomFieldWriter;
