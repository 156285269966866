import {
  createContent as createContentUsecase,
  deleteContent as deleteContentUsecase,
  setReservationTime as setReservationTimeUsecase,
  updateContentId as updateContentIdUsecase,
  updateContentStatus as updateContentStatusUsecase,
  updateContent as updateContentUsecase,
} from '@/usecase/contentUsecase';
import { deleteDraft as deleteDraftUsecase } from '@/usecase/contentUsecase';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import * as queries from '../../graphql/queries';

import { uniformQueryKeyParam } from '@/util/query-key';
import { contentQueryKeys } from '@/views/Common/content/queryKeys';

/**
 * @param {import('@/entity/api').MigrateApi | null | undefined} api
 * @param {string | undefined} contentId
 * @param {number | undefined} staleTime
 * @returns {[contentData: import('@/usecase/contentUsecase/contentUsecase').MigrateContent | undefined, getContent: () => Promise<import('@/usecase/contentUsecase/contentUsecase').MigrateContent | undefined]}
 */
export const useGetContent = (
  api,
  contentId,
  staleTime = Number.POSITIVE_INFINITY,
) => {
  const getContent = useCallback(() => {
    return API.graphql(
      graphqlOperation(queries.findContent, {
        apiId: api.partitionKey,
        userDefinedId: contentId,
      }),
    )
      .then((result) => result.data.findContent)
      .then((result) => {
        if (result === undefined || result === null) {
          return;
        }
        const content = {
          ...result,
          //旧DBとの整合性を取る
          contentId: result.gsiSortKey2.replace(/.*#CONTENT_ID#/, ''),
          domain: api.domain,
          id: `${api.domain}#${api.apiEndpoint}`,
          data: JSON.parse(
            result.contentStatus === 'PUBLISH'
              ? result.contentValue
              : result.contentStatus === 'CLOSED'
                ? result.closedValue
                : result.draftValue,
          ),
          reservationTime:
            result.gsiSinglePartitionKey1 && result.reservationTime,
          reservationStopTime:
            result.gsiSinglePartitionKey1 && result.reservationStopTime,
          contentDraftKey:
            (result.contentStatus.includes('DRAFT') ||
              result.contentStatus.includes('RESERVATION')) &&
            result.contentDraftKey,
        };
        return content;
      });
  }, [api?.apiEndpoint, api?.domain, api?.partitionKey, contentId]);

  const { data: contentData } = useQuery({
    queryKey: contentQueryKeys.detail(contentId, api?.partitionKey),
    queryFn: getContent,
    staleTime,
    refetchOnMount: true,
    enabled: !!api && !!api.partitionKey && !!contentId,
  });

  return [contentData, getContent];
};

/**
 * コンテンツの作成実行
 *
 * @param {import('@/entity/api').MigrateApi} api
 * @returns {[create: (contentId: string, value: any, contentStatus: string) => Promise<boolean|undefined>, loading: boolean]}
 */
export const useCreateContent = (api) => {
  const { t } = useTranslation('hooksContent');
  const { addToast } = useToasts();
  const cache = useQueryClient();
  /** @type {ReturnType<typeof import('react').useState<boolean>>} */
  const [loading, setLoading] = useState(false);

  const create = useCallback(
    async (contentId, value, contentStatus) => {
      // 公開時はconfirmする
      const res =
        contentStatus === 'PUBLISH'
          ? window.confirm(t('Are you sure you want to publish the content?'))
          : true;
      if (res === false) {
        return;
      }

      setLoading(true);

      try {
        await createContentUsecase({
          apiId: api.partitionKey,
          contentId,
          value: JSON.stringify(value),
          contentStatus,
        });

        if (api.apiType === 'PAGE') {
          await cache.invalidateQueries(
            contentQueryKeys.object(api.partitionKey),
            {
              type: 'all',
            },
          );
        }
        await cache.invalidateQueries(contentQueryKeys.detail(contentId), {
          type: 'all',
        });
        await cache.invalidateQueries(contentQueryKeys.list(api.partitionKey), {
          type: 'all',
        });

        setLoading(false);

        return true;
      } catch (error) {
        setLoading(false);
        addToast(error.message, {
          appearance: 'error',
        });

        return false;
      }
    },
    [t, api, cache, addToast],
  );

  return [create, loading];
};

/**
 * @param {Object} param
 * @param {function} param.setHistory
 * @param {number | null | undefined} param.reviewRequestId
 * @returns {[update: (contentPartitionKey: string, value: any, contentStatus: string) => void, result: string | undefined, loading: boolean]}
 */
export const useEditContent = ({ setHistory, reviewRequestId }) => {
  const { t } = useTranslation('hooksContent');
  const { addToast } = useToasts();
  const cache = useQueryClient();
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(false);

  // 結果はしばらくしたら消す;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setResult(undefined);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [result]);

  const update = useCallback(
    (contentPartitionKey, value, contentStatus) => {
      // 公開時はconfirmする
      const res =
        contentStatus === 'PUBLISH'
          ? window.confirm(t('Are you sure you want to publish the content?'))
          : true;
      if (res === false) {
        return;
      }

      setLoading(true);
      updateContentUsecase({
        contentId: contentPartitionKey,
        value: JSON.stringify(value),
        contentStatus,
      })
        .then((result) => {
          setLoading(false);
          setResult(result.contentId);
          setHistory(null);
          cache.invalidateQueries(contentQueryKeys.detail(result.contentId), {
            type: 'all',
          });
          cache.invalidateQueries(contentQueryKeys.list(), { type: 'all' });
          cache.invalidateQueries(
            contentQueryKeys.histories(contentPartitionKey),
            { type: 'all' },
          );
          cache.invalidateQueries(
            contentQueryKeys.version(contentPartitionKey),
            { type: 'all' },
          );
          reviewRequestId &&
            cache.invalidateQueries(
              [
                'reviewRequestEvents',
                { reqId: uniformQueryKeyParam(reviewRequestId) },
              ],
              {
                type: 'all',
              },
            );
          addToast(t('The content has been saved'), {
            appearance: 'success',
          });
        })
        .catch((error) => {
          setLoading(false);
          addToast(error.message, {
            appearance: 'error',
          });
        });
    },
    [t, setHistory, cache, reviewRequestId, addToast],
  );

  return [update, result, loading];
};

export const useDeleteDraft = () => {
  const { t } = useTranslation('hooksContent');
  const { addToast } = useToasts();
  const cache = useQueryClient();
  const [loading, setLoading] = useState(false);

  const deleteDraft = useCallback(
    (contentPartitionKey) => {
      setLoading(true);
      deleteDraftUsecase({ contentId: contentPartitionKey })
        .then((result) => {
          setLoading(false);
          cache.invalidateQueries(contentQueryKeys.detail(result.contentId), {
            type: 'all',
          });
          cache.invalidateQueries(['contentList'], { type: 'all' });
          cache.invalidateQueries(
            contentQueryKeys.histories(contentPartitionKey),
            {
              type: 'all',
            },
          );
          addToast(t('The Draft has been discarded'), {
            appearance: 'success',
          });
        })
        .catch((error) => {
          setLoading(false);
          addToast(error.message, {
            appearance: 'error',
          });
        });
    },
    [cache, addToast, t],
  );

  return [deleteDraft, loading];
};

export const useDeleteContent = () => {
  const { t } = useTranslation('hooksContent');
  const cache = useQueryClient();
  const [result, setResult] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  // 結果はしばらくしたら消す;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setResult(undefined);
      setError(undefined);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [result, error]);

  const deleteContent = useCallback(
    (contentPartitionKey) => {
      setLoading(true);
      deleteContentUsecase({ contentId: contentPartitionKey })
        .then((result) => {
          cache.invalidateQueries(['contentList'], { type: 'all' });
          setLoading(false);
          addToast(t('Content has been deleted'), { appearance: 'success' });
          setResult(result);
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
          addToast(error ? error.message : t('Content could not be deleted.'), {
            appearance: 'error',
          });
        });
    },
    [addToast, cache, t],
  );

  return [deleteContent, result, error, loading];
};

export const useUpdateContentId = (endpoint) => {
  const { t } = useTranslation('hooksContent');
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const history = useHistory();

  const { mutate: updateContentId, isLoading: updateContentIdLoading } =
    useMutation({
      mutationFn: ({ contentPartitionKey, newContentId }) =>
        updateContentIdUsecase({
          contentId: contentPartitionKey,
          userDefinedId: newContentId,
        }),
      onSuccess(data) {
        cache.invalidateQueries(['contentList'], { type: 'all' });
        addToast(t('ContentId has been changed.'), {
          appearance: 'success',
        });
        history.replace(`/apis/${endpoint}/${data.contentId}`);
      },
      onError(error) {
        addToast(error ? error.message : t('ContentId could not be deleted.'), {
          appearance: 'error',
        });
      },
    });

  return [updateContentId, updateContentIdLoading];
};

export const useSetReservationTime = (contentId) => {
  const { t } = useTranslation('hooksContent');
  const { addToast } = useToasts();
  const cache = useQueryClient();
  const [loading, setLoading] = useState(false);

  const setReservationTime = useCallback(
    (startDate, stopDate) => {
      setLoading(true);
      setReservationTimeUsecase({
        contentId,
        startDate,
        stopDate,
      })
        .then((result) => {
          cache.invalidateQueries(contentQueryKeys.detail(result.contentId), {
            type: 'all',
          });
          cache.invalidateQueries(['contentList'], { type: 'all' });
          addToast(t('Scheduling has been succeeded'), {
            appearance: 'success',
          });
        })
        .catch((error) => {
          // エラーメッセージが明記されていれば、それを表示する。なければデフォルトのメッセージを表示する。
          if (error.message) {
            addToast(error.message, { appearance: 'error' });
          } else {
            addToast(t('Failed to set schedule'), {
              appearance: 'error',
            });
          }
        })
        .finally(() => setLoading(false));
    },
    [cache, contentId, addToast, t],
  );

  return {
    setReservationTime,
    loading,
  };
};

export const useUpdateContentStatus = () => {
  const { t } = useTranslation('hooksContent');
  const cache = useQueryClient();
  const [result, setResult] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  // 結果はしばらくしたら消す;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setResult(undefined);
    }, 1);
    return () => clearTimeout(timeoutId);
  }, [result]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setError(undefined);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [error]);

  const updateContentStatus = useCallback(
    ({ partitionKey, customStatusId, behaviour }) => {
      setLoading(true);
      updateContentStatusUsecase({
        contentId: partitionKey,
        customStatusId,
        behaviour,
      })
        .then((result) => {
          setLoading(false);
          setResult(result);
          cache.invalidateQueries(contentQueryKeys.detail(result.contentId), {
            type: 'all',
          });
          cache.invalidateQueries(['contentList'], { type: 'all' });
          addToast(t('Status has been updated.'), { appearance: 'success' });
        })
        .catch((error) => {
          setLoading(false);
          setError(error);
          addToast(error ? error.message : t('Status could not be updated.'), {
            appearance: 'error',
          });
        });
    },
    [cache, addToast, t],
  );

  return [updateContentStatus, result, error, loading];
};
