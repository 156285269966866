import { useTranslation } from 'react-i18next';

import S3Image from '@/components/S3Image';

import { NoPermission } from '../shared/NoPermission';

import styles from './medialist.module.css';

export type Props = ViewProps;

export type ViewProps = {
  value?: {
    list: (
      | {
          isAuthorized: true;
          data: { directory: string; fileName: string };
        }
      | {
          isAuthorized: false;
          data: null;
        }
    )[];
    totalCount: number;
  } | null;
  customDomainImageHost?: string;
};

export const MediaListView: React.FC<ViewProps> = ({
  value,
  customDomainImageHost,
}) => {
  const { t } = useTranslation('contentListMediaList');

  return (
    <div className={styles.main}>
      {value?.list && Array.isArray(value.list) && value.list.length > 0 && (
        <div className={styles.images}>
          {value.list.map((v, i) =>
            v.isAuthorized ? (
              <S3Image
                key={i}
                directory={v.data.directory}
                fileName={v.data.fileName}
                kind="IMAGE"
                queryString="?w=180"
                customDomainImageHost={customDomainImageHost}
              />
            ) : (
              <NoPermission key={i} className={styles.noPermission} />
            ),
          )}
        </div>
      )}
      {value?.totalCount !== null && value?.totalCount !== undefined && (
        <p className={styles.totalCount}>
          {t('Total of {{count}}', {
            count: value.totalCount,
          })}
        </p>
      )}
    </div>
  );
};

export const MediaList: React.FC<Props> = (props) => {
  return <MediaListView {...props} />;
};
