import { useState, useEffect, useCallback } from 'react';

import { deleteAccount as deleteAccountUsecase } from '@/usecase/accountUsecase';

const useDeleteAccount = () => {
  const [result, setResult] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  // 結果はしばらくしたら消す;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setResult(undefined);
      setError(undefined);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [result, error]);

  // TODO：React Queryに書き換える
  const deleteAccount = useCallback(async () => {
    setLoading(true);
    try {
      const result = await deleteAccountUsecase();
      setResult(result);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  return [result, error, deleteAccount, loading];
};
export default useDeleteAccount;
