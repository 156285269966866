import { localStorage } from '@/constants/localStorage';
import { apiListSelectors } from '@/ducks/apiList';
import type { ApiList, MigrateApi } from '@/entity/api';
import {
  useGetContent,
  useUpdateContentId,
} from '@/hooks/Content/useContentWriter';
import { useCustomFields } from '@/hooks/CustomField/useCustomFieldReader';
import { useCustomStatus } from '@/hooks/CustomStatus/useCustomStatus';
import { useOpenedReviewRequestAccessInfo } from '@/hooks/Review/useOpenedReviewRequestAccessInfo';
import { useExceptionPermissionIsHaveLeastOne } from '@/hooks/Role/useMyRoles';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';
import { useAppSelector } from '@/store/hooks';
import type { Contents, CustomField } from '@/types/contents';
import type { Service } from '@/types/services';
import type { MigrateContent } from '@/usecase/contentUsecase/contentUsecase';
import { getContentUpdatePermissionEntryByStatus } from '@/util/permissions/content';
import { useHasUpdateContentPermission } from '@/views/Common/Roles/MyRolesPermission';
import { Modal, ModalContent, ModalTrigger } from '@/views/Common/Ui/Modal';
import {
  SideModal,
  SideModalContent,
  SideModalTrigger,
} from '@/views/Common/Ui/SideModal';
import { useToggle } from '@/views/Common/Ui/useToggle';
import { useInput } from '@/views/Common/useInput';
import { UpdateDraftKey } from '@/views/EditContent/UpdateDraftKey';
import { ContentCapacity } from '@/views/apis/content/ContentCapacity';
import { ContentCapacityProvider } from '@/views/apis/content/ContentCapacity/ContentCapacityProvider';
import { ContentDetail } from '@/views/apis/content/ContentDetail';
import { useContent } from '@/views/apis/content/useContent';
import cx from 'classnames';
import dayjs from 'dayjs';
import deepEqual from 'deep-equal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { getPureHost } from '../../util';
import ApiRequest from '../ApiRequest';
import ContentActions from '../ContentActions';
import { RelatedContentsModal } from '../Form';
import Head from '../Head';
import { validateContentId } from '../Validations';
import Button from '../ui/Button';
import IconWithTextButton from '../ui/IconWithTextButton';
import Loading from '../ui/Loading';
import Textfield from '../ui/Textfield';
import MetaRight from './MetaRight';
import styles from './editContent.module.css';

type Params = {
  endpoint: string;
  contentId: string;
};

type ViewProps = {
  service: Service;
  api: MigrateApi;
  customFields: CustomField[];
  params: Params;
  contentData: MigrateContent;
  historyVersion: number | null;
  setSelectedHistory: React.Dispatch<React.SetStateAction<Contents | null>>;
};

// 現状Viewではないが、views/配下の命名に合わせている
const EditContentView: React.FC<ViewProps> = ({
  service,
  api,
  customFields,
  params,
  contentData,
  historyVersion,
  setSelectedHistory,
}) => {
  const { t } = useTranslation('editContent');

  const history = useHistory();
  const { endpoint, contentId } = params;

  const [
    isOpenRelatedContentModal,
    toggleRelatedContentModal,
    openRelatedContentModal,
    closeRelatedContentModal,
  ] = useToggle(false);

  const [isOpenEditContentIdModal, toggleEditContentIdModal] = useToggle(false);

  //権限を取得
  const [hasReadDeveloperPermission] = useExceptionPermissionIsHaveLeastOne(
    api.partitionKey,
    'showDeveloperMenu',
  );

  const { currentPlan } = useStripeActions(service);
  const { customStatus } = useCustomStatus(api.partitionKey);

  const needGuide = useMemo(() => {
    if (window.localStorage.getItem('api-preview-guide') === null) {
      return true;
    }
    return false;
  }, []);
  const [newContentId, onChangeNewContentId, newContentIdError] = useInput(
    contentId,
    validateContentId,
  );

  // 最初のコンテンツ作成時のみ、APIプレビュー紹介ダイアログを表示
  const [cookies, setCookie] = useCookies<
    string,
    {
      'usage#apiCreated'?: boolean;
      'usage#apiPreviewPromoteDone'?: boolean;
    }
  >(['usage#apiCreated']);
  const [isOpenApiPreviewPromoteModal, toggleApiPreviewPromoteModal] =
    useToggle(
      !!(
        cookies['usage#apiCreated'] && !cookies['usage#apiPreviewPromoteDone']
      ),
    );
  const closeApiPreviewPromoteModal = useCallback(() => {
    setCookie('usage#apiPreviewPromoteDone', true, {
      path: '/',
      domain: getPureHost(),
      secure: window.location.protocol === 'https',
      expires: dayjs().add(20, 'year').toDate(),
    });
    toggleApiPreviewPromoteModal(false);
  }, [setCookie, toggleApiPreviewPromoteModal]);

  // APIプレビュー
  const [isOpenApiRequestModal, toggleApiRequestModal] = useToggle(false);
  const openApiRequestModal = useCallback(() => {
    closeApiPreviewPromoteModal();
    window.localStorage.setItem('api-preview-guide', String(true));
    toggleApiRequestModal(true);
  }, [closeApiPreviewPromoteModal, toggleApiRequestModal]);
  const closeApiRequestModal = useCallback(() => {
    toggleApiRequestModal(false);
  }, [toggleApiRequestModal]);

  const {
    data,
    updatedAt,
    publishedAt,
    contentStatus,
    partitionKey,
    reservationTime,
    reservationStopTime,
    editorId,
    authorId,
    contentDraftKey,
    contentCustomStatusIds,
  }: MigrateContent = contentData;

  const { contentAtom, getContent, validateAllFields } = useContent(
    data,
    api.apiFields,
    customFields,
  );

  const { hasUpdateContentPermission } = useHasUpdateContentPermission();
  /**
   * 「コンテンツIDを変更」ができる場合はtrue
   */
  const canChangeContentId = useMemo(() => {
    return hasUpdateContentPermission(
      getContentUpdatePermissionEntryByStatus('changeContentId', contentStatus),
      api.partitionKey,
    );
  }, [hasUpdateContentPermission, api.partitionKey, contentStatus]);

  const needSave = useCallback(
    () => !deepEqual(getContent(), data),
    [data, getContent],
  );
  const openEditContentIdModal = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!canChangeContentId) {
        return;
      }

      if (needSave()) {
        window.alert(
          t(
            'Changes have not been saved. Save and then change the content ID.',
          ),
        );
        event.preventDefault();
        return;
      }
      toggleEditContentIdModal(true);
    },
    [needSave, t, toggleEditContentIdModal, canChangeContentId],
  );

  //レビューが出ているか等を取得
  const openedReviewRequestAccessInfo = useOpenedReviewRequestAccessInfo({
    api,
    contentId: partitionKey,
  });

  const [updateContentId, updateContentIdLoading] = useUpdateContentId(
    endpoint,
  ) as [
    (args: { contentPartitionKey: string; newContentId: string }) => void,
    boolean,
  ];

  const doUpdateContentId = useCallback(() => {
    if (window.confirm(t('Do you want to change the contentId?'))) {
      toggleEditContentIdModal(false);
      updateContentId({
        contentPartitionKey: partitionKey,
        newContentId,
      });
    }
  }, [
    t,
    toggleEditContentIdModal,
    updateContentId,
    partitionKey,
    newContentId,
  ]);

  // コンテンツ参照情報を取得
  const contentIds = useMemo(
    () =>
      contentData?.relatedContentIds?.map((id) =>
        id?.partitionKey?.replace(/.*CONTENT_RELATION#/, ''),
      ),
    [contentData],
  );

  if (contentData === null || data === null) {
    return (
      <div className={styles.error}>
        <h3 className={styles.errorTitle}>{t('Content does not exist')}</h3>
        <Link to={`/apis/${endpoint}`}>{t('Go to contents list page')}</Link>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Head title={t('Content Details')} />
      <ContentActions
        api={api}
        getContent={getContent}
        contentId={contentId}
        contentStatus={contentStatus}
        contentDraftKey={contentDraftKey}
        endpoint={endpoint}
        data={data}
        reservationTime={reservationTime}
        reservationStopTime={reservationStopTime}
        history={history}
        customFields={customFields}
        partitionKey={partitionKey}
        validateOnBeforePublish={validateAllFields}
        setHistory={setSelectedHistory}
        contentCustomStatusIds={contentCustomStatusIds}
        customStatus={customStatus}
        currentPlan={currentPlan}
        openedReviewRequestAccessInfo={openedReviewRequestAccessInfo.data}
        openedReviewRequestAccessInfoIsLoading={
          openedReviewRequestAccessInfo.isLoading
        }
        isEditing
      />
      <div id="main" className={styles.main}>
        <div className={styles.detail}>
          <div className={styles.setLeft}>
            {contentDraftKey && (
              <UpdateDraftKey
                contentId={partitionKey}
                contentDraftKey={contentDraftKey}
                hasUpdateDraftKeyPermission={hasUpdateContentPermission(
                  'changeDraftKey',
                  api.partitionKey,
                )}
              />
            )}
          </div>
          <div className={styles.setRight}>
            {hasReadDeveloperPermission && ( // 開発者のみ見せる
              <SideModal
                open={isOpenApiRequestModal}
                onOpen={openApiRequestModal}
                onClose={closeApiRequestModal}
              >
                <SideModalTrigger asChild>
                  <IconWithTextButton
                    icon="send"
                    text={t('Preview API')}
                    className={`${
                      needGuide ? styles.emphasis : ''
                    } ga-api-preview-link`}
                  />
                </SideModalTrigger>
                <SideModalContent size="large">
                  <ApiRequest
                    contentId={contentId}
                    draftKey={contentDraftKey}
                  />
                </SideModalContent>
              </SideModal>
            )}
          </div>
        </div>
        <div className={styles.area}>
          <div id="content-meta" className={styles.meta}>
            <div className={styles.metaLeft}>
              {api.apiType === 'LIST' && (
                <dl className={styles.keyValue}>
                  <dt className={styles.key}>{t('Content ID')}</dt>
                  <dd>
                    <Modal
                      open={isOpenEditContentIdModal}
                      onOpenChange={toggleEditContentIdModal}
                    >
                      <ModalTrigger
                        className={styles.linkButton}
                        onClick={openEditContentIdModal}
                        disabled={!canChangeContentId || updateContentIdLoading}
                        aria-label={t('Change contentId')}
                      >
                        {contentId}
                        {canChangeContentId && (
                          <i className={`material-icons ${styles.icon}`}>
                            edit
                          </i>
                        )}
                      </ModalTrigger>
                      <ModalContent
                        title={t('Change contentId')}
                        description={t(
                          '*Changing contentId will also change the API endpoint. This operation will be in production immediately.',
                        )}
                      >
                        <Textfield
                          defaultValue={contentId}
                          onChange={onChangeNewContentId}
                          errorText={newContentIdError}
                        />
                        <div className={styles.modalActionButtonWrapper}>
                          <Button
                            className={styles.modalActionButton}
                            value={t('Save changes')}
                            onClick={doUpdateContentId}
                            disabled={
                              !!newContentIdError || contentId === newContentId
                            }
                          />
                        </div>
                      </ModalContent>
                    </Modal>
                  </dd>
                  {contentIds?.length !== undefined &&
                    contentIds.length > 0 && (
                      <dd className={styles.relatedContent}>
                        <Modal
                          open={isOpenRelatedContentModal}
                          onOpenChange={toggleRelatedContentModal}
                        >
                          <ModalTrigger
                            className={styles.relatedContentText}
                            onClick={openRelatedContentModal}
                          >
                            <span>
                              {t('Referenced by {{count}} contents', {
                                count: contentIds?.length,
                              })}
                            </span>
                          </ModalTrigger>
                          <ModalContent size="large">
                            <RelatedContentsModal
                              service={service}
                              contentIds={contentIds}
                              close={closeRelatedContentModal}
                            />
                          </ModalContent>
                        </Modal>
                      </dd>
                    )}
                </dl>
              )}
              <ContentCapacity />
            </div>
            <MetaRight
              publishedAt={publishedAt}
              updatedAt={updatedAt}
              editorId={editorId}
              authorId={authorId}
              needSave={needSave}
              contentStatus={contentStatus}
              customStatus={customStatus}
              userDefinedContentId={contentId}
              partitionKey={partitionKey}
              updateContentIdLoading={updateContentIdLoading}
              service={service}
              endpoint={endpoint}
              setSelectedHistory={setSelectedHistory}
              apiId={api.partitionKey}
              enableSelectHistory={currentPlan?.limit.history}
            />
          </div>
          <div className={styles.content}>
            <ContentDetail
              api={api}
              endpoint={endpoint}
              customFields={customFields}
              contentAtom={contentAtom}
              getContent={getContent}
            />
          </div>
        </div>
        {historyVersion && (
          <div className={styles.historyCard}>
            {t('Displaying history v{{version}}', { version: historyVersion })}
          </div>
        )}
      </div>
      <Modal
        open={isOpenApiPreviewPromoteModal}
        onClose={closeApiPreviewPromoteModal}
      >
        <ModalContent>
          <h3 className={styles.modalTitle}>
            <img
              className={styles.modalIcon}
              src="/images/cracker.gif"
              alt=""
            />
            {t('Excellent job!')}
            <br />
            {t('A content has been created.')}
          </h3>
          <p className={styles.modalDescription}>
            {t('The API is able to get and handle the content.')}
            <br />
            {t('Try out a new request with the Preview API function.')}
          </p>
          <div className={styles.modalActions}>
            <Button
              value={t('Open Preview API')}
              onClick={openApiRequestModal}
              className={cx('ga-api-preview-link', styles.modalActionButton)}
            />
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
};

export const EditContent = () => {
  // TODO:以下のロジックをuseEditContentを作成、移動する
  const { service, isLoading } = useGetMyService();
  const { endpoint, contentId } = useParams<{
    endpoint: string;
    contentId: string;
  }>();
  // TODO: ReduxをReactQueryに置き換える
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );

  // 再アクセスした際にリダイレクトするため、ローカルストレージにURLを保存
  useEffect(() => {
    // オブジェクト形式のコンテンツのみ。リスト形式の場合ContentListにリダイレクトするため
    if (api.apiType === 'PAGE') {
      window.localStorage.setItem(
        localStorage.apiPath,
        `/apis/${endpoint}/${contentId}`,
      );
    }
  }, [api.apiType, contentId, endpoint]);
  // TODO:ここまで

  // 全てのカスタムフィールドの取得
  const [customFields] = useCustomFields(api.partitionKey) as [
    CustomField[] | undefined,
    () => void,
  ];

  // 履歴機能
  const [selectedHistory, setSelectedHistory] = useState<Contents | null>(null);
  const historyVersion = !!selectedHistory
    ? Number.parseInt(selectedHistory.sortKey.replace('VERSION#', ''))
    : null;

  // URLに基づくコンテンツを取得
  const [contentData] = useGetContent(api, contentId, 0);

  const readyServerData =
    api !== undefined &&
    customFields !== undefined &&
    contentData !== undefined;

  if (!service) {
    return null;
  }

  if (isLoading || !readyServerData) {
    return <Loading />;
  }

  const keyObject = {
    endpoint,
    contentId,
    // 公開中かつ下書きがある場合のみ同じコンテンツIDで2つのデータが存在するため、コンポーネントを初期化する
    publishAndDraft:
      (contentData as { contentStatus: string }).contentStatus ===
      'PUBLISH_AND_DRAFT',
    // 履歴を使用している場合は、履歴のバージョンをキーにする
    historyVersion,
  };

  const defaultContentValue = selectedHistory?.contentStatus.includes('DRAFT')
    ? selectedHistory?.draftValue
    : selectedHistory?.contentStatus.includes('CLOSED')
      ? selectedHistory?.closedValue
      : selectedHistory?.contentValue ?? contentData.data;

  return (
    <ContentCapacityProvider defaultValue={defaultContentValue}>
      <EditContentView
        key={JSON.stringify(keyObject)}
        service={service}
        api={api}
        customFields={customFields}
        params={{ endpoint, contentId }}
        contentData={{
          ...contentData,
          // 履歴を使用している場合は、履歴のバージョンに応じたデータを表示する
          authorId: selectedHistory?.authorId ?? contentData.authorId,
          data: defaultContentValue,
        }}
        historyVersion={historyVersion}
        setSelectedHistory={setSelectedHistory}
      />
    </ContentCapacityProvider>
  );
};
