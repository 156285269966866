import { useI18nWebhookSettings } from '../i18n';

import type { WebhookEvents } from '../../../types/webhook';
import type { PlanDetail } from '@/API';

import { WebhookEvent } from '@/constants/webhookSettings';

/**
 * Webhookの通知項目のUIに関する情報を提供する
 */
const useWebhookEvents = (currentPlan: PlanDetail | undefined) => {
  const { t } = useI18nWebhookSettings('$eventItem');
  const enableCustomStatusPlan = currentPlan
    ? currentPlan.limit.customStatusCount > 0
    : false;

  const webhookEvents: WebhookEvents[] = [
    {
      label: t(
        'Trigger Webhook when a content is Published or a Published content is updated by following methods.',
      ),
      values: [
        {
          label: t('Publish contents on the content editing page'),
          value: WebhookEvent.PUBLISH_CONTENT_BY_SCREEN,
        },
        {
          label: t('Publish contents via API'),
          value: WebhookEvent.PUBLISH_CONTENT_BY_WRITE_API,
        },
        {
          label: t('Publish contents via review'),
          value: WebhookEvent.PUBLISH_CONTENT_BY_REVIEW,
        },
        {
          label: t('Publish contents via scheduled'),
          value: WebhookEvent.PUBLISH_CONTENT_BY_RESERVATION,
        },
        {
          label: t('Sorting contents'),
          value: WebhookEvent.PUBLISH_CONTENT_BY_CHANGE_ORDER,
        },
        {
          label: t('Changing content ID'),
          value: WebhookEvent.PUBLISH_CONTENT_BY_CHANGE_CONTENT_ID,
        },
        {
          label: t('Changing published date / time'),
          value: WebhookEvent.PUBLISH_CONTENT_BY_CHANGE_PUBLISHED_AT,
        },
        enableCustomStatusPlan
          ? {
              label: t('Change Custom status'),
              value: WebhookEvent.PUBLISH_CONTENT_BY_CHANGE_CONTENT_STATUS,
            }
          : {},
      ],
    },
    {
      label: t(
        'Trigger Webhook when a content is Unpublished by following methods.',
      ),
      values: [
        {
          label: t(
            'Unpublish contents or revert published content to draft on the content editing page',
          ),
          value: WebhookEvent.UNPUBLISH_CONTENT_BY_SCREEN,
        },
        {
          label: t('Unpublish contents via scheduled'),
          value: WebhookEvent.UNPUBLISH_CONTENT_BY_RESERVATION,
        },
        {
          label: t('Sorting contents'),
          value: WebhookEvent.UNPUBLISH_CONTENT_BY_CHANGE_ORDER,
        },
        {
          label: t('Changing content ID'),
          value: WebhookEvent.UNPUBLISH_CONTENT_BY_CHANGE_CONTENT_ID,
        },
        {
          label: t('Changing published date / time'),
          value: WebhookEvent.UNPUBLISH_CONTENT_BY_CHANGE_PUBLISHED_AT,
        },
        enableCustomStatusPlan
          ? {
              label: t('Change Custom status'),
              value: WebhookEvent.UNPUBLISH_CONTENT_BY_CHANGE_CONTENT_STATUS,
            }
          : {},
      ],
    },
    {
      label: t(
        'Trigger Webhook when a content is changed to Draft or a Draft content is updated by following methods.',
      ),
      values: [
        {
          label: t('Save contents as draft on the content editing page'),
          value: WebhookEvent.SAVE_DRAFT_CONTENT_BY_SCREEN,
        },
        {
          label: t('Save contents as draft via API'),
          value: WebhookEvent.SAVE_DRAFT_CONTENT_BY_WRITE_API,
        },
        {
          label: t('Sorting contents'),
          value: WebhookEvent.SAVE_DRAFT_CONTENT_BY_CHANGE_ORDER,
        },
        {
          label: t('Changing content ID'),
          value: WebhookEvent.SAVE_DRAFT_CONTENT_BY_CHANGE_CONTENT_ID,
        },
        {
          label: t('Changing published date / time'),
          value: WebhookEvent.SAVE_DRAFT_CONTENT_BY_CHANGE_PUBLISHED_AT,
        },
        enableCustomStatusPlan
          ? {
              label: t('Change Custom status'),
              value: WebhookEvent.SAVE_DRAFT_CONTENT_BY_CHANGE_CONTENT_STATUS,
            }
          : {},
      ],
    },
    {
      label: t(
        'Trigger Webhook when a Published content is deleted by following methods.',
      ),
      values: [
        {
          label: t('Delete publishing contents on the content editing page'),
          value: WebhookEvent.DELETE_PUBLISH_CONTENT_BY_SCREEN,
        },
        {
          label: t('Delete publishing contents via API'),
          value: WebhookEvent.DELETE_PUBLISH_CONTENT_BY_WRITE_API,
        },
      ],
    },
    {
      label: t(
        'Trigger Webhook when a Unpublished content is deleted by following methods.',
      ),
      values: [
        {
          label: t('Delete unpublishing contents on the content editing page'),
          value: WebhookEvent.DELETE_UNPUBLISH_CONTENT_BY_SCREEN,
        },
        {
          label: t('Delete unpublishing contents via API'),
          value: WebhookEvent.DELETE_UNPUBLISH_CONTENT_BY_WRITE_API,
        },
      ],
    },
    {
      label: t(
        'Trigger Webhook when a Draft content is deleted by following methods.',
      ),
      values: [
        {
          label: t('Delete draft contents on the content editing page'),
          value: WebhookEvent.DELETE_CONTENT_BY_SCREEN,
        },
        {
          label: t('Delete draft contents via API'),
          value: WebhookEvent.DELETE_CONTENT_BY_WRITE_API,
        },
      ],
    },
    {
      label: t(
        'Trigger Webhook when a `Published and Draft` content is deleted draft by following methods.',
      ),
      values: [
        {
          label: t('Deleted a draft of contents on the content editing page'),
          value: WebhookEvent.DELETE_DRAFT_BY_SCREEN,
        },
      ],
    },
    {
      label: t('Trigger Webhook when an API setting is changed.'),
      values: [
        {
          label: t('When API setting is changed'),
          value: WebhookEvent.UPDATE_API,
        },
      ],
    },
    {
      label: t('Trigger Webhook when an API is deleted.'),
      values: [
        {
          label: t('When API is deleted'),
          value: WebhookEvent.DELETE_API,
        },
      ],
    },
  ];

  return webhookEvents;
};

export { useWebhookEvents };
