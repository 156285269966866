import type React from 'react';
import { useTranslation } from 'react-i18next';

import { useDocument } from '../../../hooks/Document/useDocument';
import ExternalLink from '../ExternalLink';
import Note from '../Note';
import RichText from '../RichText';

import styles from './helpBody.module.css';

type Props = {
  type: string;
  id: string;
};

const HelpBody: React.FC<Props> = ({ type, id }) => {
  const { t } = useTranslation('helpBody');
  const { document, documentError, isLoading } = useDocument(type, id);

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <img src="/images/icon_loading.svg" alt="" />
      </div>
    );
  }

  if (documentError) {
    return (
      <p className={styles.error}>{t('Information could not be retrieved')}</p>
    );
  }

  return (
    <div>
      <h3 className={styles.title}>{document?.title.ja || ''}</h3>
      {document?.body?.map((item: any, i: number) =>
        item.fieldId === 'richText' ? (
          <RichText key={i} html={item.ja || ''} />
        ) : item.fieldId === 'note' ? (
          <Note key={i} html={item.ja || ''} />
        ) : item.fieldId === 'linkCard' ? (
          <ExternalLink key={i} href={item.ja || ''}>
            {item.ja || ''}
          </ExternalLink>
        ) : null,
      )}
    </div>
  );
};

export default HelpBody;
