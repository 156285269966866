import { useCallback } from 'react';

import type { Props } from './Strike';

export const useStrike = ({ editor }: Props) => {
  const toggleStrikeHandler = useCallback(() => {
    editor.chain().focus().toggleStrike().run();
  }, [editor]);
  const isActiveStrike = editor.isActive('strike');

  return {
    toggleStrikeHandler,
    isActiveStrike,
  };
};
