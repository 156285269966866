import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import HoverDescriptionCard from '@/components/CreateApi/SelectApiTemplate/HoverDescriptionCard';

import { useWindowDimensions } from '@/hooks/useWindowDimentions';

import type { Dispatch, SetStateAction } from 'react';

import styles from './select-template.module.css';

type Props = {
  setStep: Dispatch<SetStateAction<number>>;
};

export const SelectTemplate = ({ setStep }: Props) => {
  const { t } = useTranslation('selectTemplate');
  const { width } = useWindowDimensions();

  const firstFocusRef = useCallback((node: HTMLButtonElement | null) => {
    node?.focus();
  }, []);

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{t('Create Service')}</h2>
      <p className={styles.description}>
        {t('Create Service on your own or select a template.')}
      </p>
      <div className={styles.templates}>
        <button
          ref={firstFocusRef}
          className={`${styles.createServiceCard}`}
          data-gtm-track-event-name="create_service_from_scratch"
          onClick={() => setStep(1)}
        >
          <div className={styles.mask}>
            <p className={styles.maskDescription}>
              {t('Create Service by yourself.')}
            </p>
            <div className={styles.maskTitle}>
              <span>
                <p>{t('Create your own')}</p>
                <i className="material-icons-outlined">navigate_next</i>
              </span>
            </div>
          </div>
          <div className={styles.imgBlock}>
            <img
              src="/images/icon_edit_white.svg"
              alt={t('Edit Icon')}
              width={48}
              height={48}
            />
          </div>
          <div className={styles.createApiCardText}>
            <p>{t('Create your own')}</p>
          </div>
        </button>
        <HoverDescriptionCard
          title={t('Template')}
          description={t('Create Service from Templates.')}
          className={`${styles.createTemplateCard}`}
          imgUrl={
            width > 1080
              ? '/images/api-template-blog-desktop.svg'
              : width < 1080 && width > 650
                ? '/images/api-template-blog-tablet.svg'
                : '/images/api-template-blog-mobile.svg'
          }
          data-gtm-track-event-name="create_service_from_template"
          onClick={() => (location.href = 'https://templates.microcms.io/')}
        />
      </div>
    </div>
  );
};
