import { createActions } from 'redux-actions';

const actions = createActions({
  FAILURE_CONFIRM_SIGNUP: (serverError) => ({ serverError }),
  SUCCESS_CONFIRM_SIGNUP: undefined,

  REDIRECT_TO_CONFIRM_SIGNUP: undefined,
  REDIRECT_TO_MFA: (tempUser) => ({ tempUser }),

  FAILURE_FORGOT_PASSWORD: (serverError) => ({ serverError }),
  SUCCESS_FORGOT_PASSWORD: undefined,
  FAILURE_FORGOT_PASSWORD_SUBMIT: (serverError) => ({ serverError }),
  SUCCESS_FORGOT_PASSWORD_SUBMIT: undefined,
  FAILURE_CHANGE_PASSWORD: (serverError) => ({ serverError }),
  SUCCESS_CHANGE_PASSWORD: undefined,

  FAILURE_SIGNOUT: (serverError) => ({ serverError }),
  SUCCESS_SIGNOUT: undefined,

  RESET_AUTH: undefined,
});

export default actions;
