import { apiListSelectors } from '@/ducks/apiList';
import type { ApiList, MigrateApi } from '@/entity/api';
import { useLimitCustomFields } from '@/hooks/FieldLimit/useLimitApiField';
import { useGetMyService } from '@/hooks/useService';
import { useAppSelector } from '@/store/hooks';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useCustomFields } from '../../hooks/CustomField/useCustomFieldReader';
import { useExceptionPermissionIsHaveLeastOne } from '../../hooks/Role/useMyRoles';
import Head from '../Head';
import Button from '../ui/Button';
import { EmptyWrapper } from './EmptyWrapper';
import { FieldsWrapper } from './FieldsWrapper';
import styles from './customFields.module.css';

const CustomFields: React.FC = () => {
  const { t } = useTranslation('customFields');
  const { service, parentService } = useGetMyService();
  const history = useHistory();

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint } = useParams<{ endpoint: string }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );

  const [updatable] = useExceptionPermissionIsHaveLeastOne(
    api.partitionKey,
    'apiUpdate',
  );

  const [customFields] = useCustomFields(api.partitionKey);
  // ANKEN-1305 カスタムフィールド数上限値の設定
  const errorAddCustomField = useLimitCustomFields(
    customFields,
    service,
    parentService,
  );

  const addCustomField = useCallback(() => {
    history.push(`/apis/${endpoint}/custom-fields/create`);
  }, [endpoint, history]);

  if (customFields === undefined) {
    return null;
  }

  return (
    <div className={styles.upsellWrapper}>
      <Head title={t('Custom Fields/List')} />
      {updatable && (
        <div className={styles.actions}>
          <Link to={`/apis/${endpoint}/custom-fields/create`}>
            <Button
              icon="add"
              value={t('Add')}
              type="secondary"
              disabled={errorAddCustomField}
            />
          </Link>
          {errorAddCustomField && (
            <div className={styles.error}>
              <p>
                {t('The number of custom fields has reached the upper limit.')}
              </p>
            </div>
          )}
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.detail}>
          <p>
            {t('Display {{count}} / total {{count}} items', {
              count: customFields.length,
            })}
          </p>
        </div>
        {customFields?.length > 0 && (
          <FieldsWrapper
            customFields={customFields}
            history={history}
            endpoint={endpoint}
          />
        )}
        {customFields?.length === 0 && (
          <EmptyWrapper addCustomField={addCustomField} endpoint={endpoint} />
        )}
      </div>
    </div>
  );
};

export default CustomFields;
