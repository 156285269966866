import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { updateMediumTags as updateMediumTagsUsecase } from '@/usecase/mediumTagUsecase';

import type { Medium } from '@/entity/medium';

type UpdateParams = {
  medium: Medium;
  tags: string[];
  closeModal: () => void;
};

export const useUpdateMediumTags = () => {
  const { t } = useTranslation('hooksMedium');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  const { mutate: updateMediumTags, isLoading: updateMediumTagsLoading } =
    useMutation({
      mutationFn: async ({ medium, tags }: UpdateParams) => {
        return updateMediumTagsUsecase({
          serviceId: medium.serviceId,
          mediumId: medium.mediumId,
          tags,
        });
      },
      onSuccess(_, { closeModal }) {
        cache.invalidateQueries(['media'], { type: 'all' });
        closeModal();
        addToast(t('Media tags has been updated.'), {
          appearance: 'success',
        });
      },
      onError({ message }) {
        addToast(message ? message : t('Could not update media tags.'), {
          appearance: 'error',
        });
      },
    });
  return { updateMediumTags, updateMediumTagsLoading };
};
