import { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '../../ui/Button';
import styles from '../apiModel.module.css';

import type { MigrateApi } from '@/entity/api';
import type { CustomField } from '../../../types/contents';

type SelectRepeaterFieldProps = {
  customFields: CustomField[];
  list: string[];
  setList: React.Dispatch<string[]>;
};

export const SelectRepeaterField: React.FC<SelectRepeaterFieldProps> = ({
  customFields = [],
  list = [],
  setList,
}) => {
  const { t } = useTranslation('selectRepeaterField');

  const _setList = useCallback(
    (e: any) => {
      const value = e.target.value;
      const newList = e.target.checked
        ? [...list, value]
        : list.filter((item) => item !== value);
      setList(newList);
    },
    [list, setList],
  );
  if (customFields.length === 0) {
    return (
      <p className={styles.errorText}>
        {t('A Custom field has not been created.')}
      </p>
    );
  }
  return (
    <div className={styles.checkboxes}>
      {customFields.map((field, i) => {
        return (
          <label className={styles.checkbox} key={i}>
            <input
              type="checkbox"
              value={field.createdAt}
              checked={list.includes(field.createdAt)}
              onChange={_setList}
            ></input>
            {field.name} ({field.fieldId})
          </label>
        );
      })}
    </div>
  );
};

type SelectRepeaterFieldInModalProps = {
  api: MigrateApi;
  createdAtList?: string[];
  setRepeater: (list: string[]) => void;
  closeRepeaterModal: () => void;
  customFields: CustomField[];
};

export const SelectRepeaterFieldInModal: React.FC<
  SelectRepeaterFieldInModalProps
> = ({
  api,
  createdAtList,
  setRepeater,
  closeRepeaterModal,
  customFields = [],
}) => {
  const { t } = useTranslation('selectRepeaterField');

  const [list, setList] = useState(createdAtList || []);
  const set = useCallback(() => {
    setRepeater(list);
  }, [list, setRepeater]);
  return (
    <div>
      {customFields.length > 0 ? (
        <div>
          <p className={styles.modalDescription}>
            {t('Select Custom fields you wish to use.')}
          </p>
          <SelectRepeaterField
            customFields={customFields}
            list={list}
            setList={setList}
          />
          <div className={styles.modalActionButtonWrapper}>
            <Button
              type="secondary"
              value={t('Confirm')}
              onClick={list.length === 0 ? undefined : set}
              disabled={list.length === 0}
              className={styles.modalActionButton}
            />
          </div>
        </div>
      ) : (
        <div className={styles.modalAction}>
          <p className={styles.modalDescription}>
            {t('A Custom field has not been created.')}
            <br />
            {Trans({
              t,
              i18nKey: 'Create it here.',
              children: (
                <Link to={`/apis/${api.apiEndpoint}/custom-fields/create`}>
                  here
                </Link>
              ),
            })}
          </p>
          <Button
            type="secondary"
            value={t('Close')}
            onClick={closeRepeaterModal}
            className={styles.modalActionButton}
          />
        </div>
      )}
    </div>
  );
};
