import { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useInput } from '../../../hooks';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import Switch from '../../ui/Switch';
import Textfield from '../../ui/Textfield';
import { validateImageSize } from '../../Validations';
import { validateLink } from '@/views/Common/validations/validateLink';

import styles from './imageEditor.module.css';

type Props = {
  src: string;
  naturalWidth: number;
  naturalHeight: number;
  width: number;
  height: number;
  alt: string;
  link: string;
  isTargetBlank: boolean;
  edit: any;
  close: () => void;
};

const ImageEditor: React.FC<Props> = ({
  src,
  naturalWidth,
  naturalHeight,
  width,
  height,
  alt = '',
  link = '',
  isTargetBlank = false,
  edit,
  close,
}) => {
  const { t } = useTranslation('richEditor');
  const ratio = useMemo(
    () => naturalWidth / naturalHeight,
    [naturalWidth, naturalHeight],
  );
  const [_width, setWidth] = useState(width || naturalWidth);
  const [_height, setHeight] = useState(height || naturalHeight);
  const [_alt, setAlt] = useState(alt);
  const [_link, onChangeLink, linkError = null] = useInput(
    link,
    // @ts-expect-error
    validateLink,
    true,
  );
  const [_isTargetBlank, setIsTargetBlank] = useState(isTargetBlank);
  const [sizeError, setSizeError] = useState<string | null>(null);

  useEffect(() => {
    setSizeError(validateImageSize(_width, Math.floor(_width / ratio)));
  }, [_width, ratio]);

  const onChangeWidth = useCallback(
    (e: any) => {
      setWidth(e.target.value);
      setHeight(Math.floor(e.target.value / ratio));
    },
    [ratio],
  );

  const onChangeHeight = useCallback(
    (e: any) => {
      setWidth(Math.floor(e.target.value * ratio));
      setHeight(e.target.value);
    },
    [ratio],
  );

  const onChangeAlt = useCallback((e: any) => {
    setAlt(e.target.value);
  }, []);

  const onClick = useCallback(() => {
    edit({
      width: _width,
      height: _height,
      alt: _alt,
      link: _link,
      isTargetBlank: _isTargetBlank,
    });
    close();
  }, [edit, _width, _height, _alt, _link, _isTargetBlank, close]);
  return (
    <div className={styles.wrapper}>
      <div className={styles.mask} onClick={close} />
      <div className={styles.container}>
        <h4 className={styles.title}>{t('Image Settings')}</h4>
        <img src={src} className={styles.image} alt="" />
        <div className={styles.flex}>
          <Fieldset legend={t('Width')} style={{ width: 140 }}>
            <Textfield type="number" value={_width} onChange={onChangeWidth} />
          </Fieldset>
          <div className={styles.link}>
            <i className="material-icons-outlined">link</i>
          </div>
          <Fieldset legend={t('Height')} style={{ width: 140 }}>
            <Textfield
              type="number"
              value={_height}
              onChange={onChangeHeight}
            />
          </Fieldset>
        </div>
        {sizeError !== null && <p className={styles.errorText}>{sizeError}</p>}
        <Fieldset legend={t('Alt')}>
          <Textfield type="text" value={_alt} onChange={onChangeAlt} />
        </Fieldset>
        <Fieldset legend={t('Link')}>
          <Textfield
            placeholder={t('Enter URL')}
            type="text"
            value={_link}
            errorText={linkError}
            onChange={onChangeLink}
          />
          <div className={styles.switch}>
            <Switch on={_isTargetBlank} onChange={setIsTargetBlank}>
              {t('Open in a new tab')}
            </Switch>
          </div>
        </Fieldset>
        <div className={styles.actions}>
          <Button
            value={t('Save changes')}
            size="full"
            type="secondary"
            onClick={onClick}
            disabled={sizeError !== null || linkError !== null}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageEditor;
