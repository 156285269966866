import Actions from './Actions';
import Main from './Main';
import { FormRoleProvider } from './provider';

import type { MigrateApi } from '@/entity/api';
import type { Role } from '@/entity/role';
import type { Service } from '@/types/services';
import type { FC, ReactNode } from 'react';

import { Upsell } from '@/views/Common/plan/Upsell';
import styles from './FormRole.module.css';

type Props = {
  originalRole?: Role;
  apiSchemas: MigrateApi[];
  currentServiceId: Service['partitionKey'];
  editRole: boolean;
  /**
   * プランの上限数に達している場合の表示内容
   *
   * 未指定の場合は、通常のフォームを表示
   */
  contentLimitPlan?: ReactNode;
};

const View: FC<
  Pick<Props, 'currentServiceId' | 'editRole' | 'contentLimitPlan'>
> = ({ currentServiceId, editRole, contentLimitPlan }) => {
  const MainContent = (): ReactNode => (
    <main className={styles.main}>
      {contentLimitPlan ? contentLimitPlan : <Main />}
    </main>
  );

  return (
    <>
      <Actions currentServiceId={currentServiceId} />
      {/* ロール編集画面ではUpsellを表示しない */}
      {editRole ? (
        <MainContent />
      ) : (
        <Upsell restrictionKey="roleCount">
          <MainContent />
        </Upsell>
      )}
    </>
  );
};

const FormRole: FC<Props> = ({
  originalRole,
  apiSchemas = [],
  currentServiceId,
  editRole,
  contentLimitPlan,
}) => {
  return (
    <FormRoleProvider originalRole={originalRole} apiSchemas={apiSchemas}>
      <View
        currentServiceId={currentServiceId}
        editRole={editRole}
        contentLimitPlan={contentLimitPlan}
      />
    </FormRoleProvider>
  );
};

export { FormRole };
