import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { bulkUpdateContentsStatus as bulkUpdateContentsStatusUsecase } from '@/usecase/contentUsecase';

import { contentQueryKeys } from './queryKeys';

import type { BulkContentsResponse, BulkContentStatus } from '@/entity/content';
import type { ContentStatus as ContentStatusType } from '@/entity/content';

import { useRegisterTopicEvent } from '@/views/Common/providers/momento';

export const useBulkUpdateContentsStatus = (apiId: string) => {
  const { t } = useTranslation('hooksContent');
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const { register } = useRegisterTopicEvent();

  const {
    mutateAsync: bulkUpdateContentsStatusAsync,
    isLoading: bulkUpdateContentsStatusLoading,
  } = useMutation<
    BulkContentsResponse,
    Error,
    {
      selectedContents: string[];
      contentStatus: BulkContentStatus;
      setSelectedContents: React.Dispatch<
        React.SetStateAction<
          { contentStatus: ContentStatusType; partitionKey: string }[]
        >
      >;
      setBulkLoadingState: React.Dispatch<
        React.SetStateAction<{ loading: boolean; message: string } | null>
      >;
    }
  >({
    mutationFn: async ({ selectedContents, contentStatus }) => {
      return bulkUpdateContentsStatusUsecase({
        apiId,
        contentIds: selectedContents,
        contentStatus,
      });
    },
    onSuccess(
      data,
      { contentStatus, setSelectedContents, setBulkLoadingState },
    ) {
      register((topicValue, done) => {
        if (
          topicValue.eventName === 'bulkUpdateContentsStatus' &&
          (topicValue.data as { apiId: string; userId: string })?.apiId ===
            apiId
        ) {
          if (topicValue.status === 'SUCCEEDED') {
            const { successIds, failureIds } = JSON.parse(
              topicValue.message,
            ) as {
              result: boolean;
              successIds: string[];
              failureIds: string[];
            };
            if (contentStatus === 'PUBLISH') {
              if (successIds?.length) {
                addToast(
                  t('{{count}} contents were published.', {
                    count: successIds.length,
                  }),
                  {
                    appearance: 'success',
                  },
                );
              }
              if (failureIds?.length) {
                addToast(
                  t(
                    'Failed to publish {{count}} content items \n Content ID:{{ids}} \n\n A content that is being set Scheduled Publishing Date / Time or in pending review or incorrect format cannot be published.',
                    {
                      count: failureIds?.length,
                      ids: failureIds?.join(t('period')),
                    },
                  ),
                  {
                    appearance: 'error',
                    autoDismiss: false,
                  },
                );
              }
            }
            if (contentStatus === 'DRAFT') {
              if (successIds?.length) {
                addToast(
                  t('{{count}} contents were returned to draft.', {
                    count: successIds.length,
                  }),
                  {
                    appearance: 'success',
                  },
                );
              }
              if (failureIds?.length) {
                addToast(
                  t(
                    'Failed to change to draft {{count}} content items \n Content ID:{{ids}} \n\n A content that is being set Unpublishing Date / time or incorrect format cannot be published.',
                    {
                      count: failureIds?.length,
                      ids: failureIds?.join(t('period')),
                    },
                  ),
                  {
                    appearance: 'error',
                    autoDismiss: false,
                  },
                );
              }
            }
            setSelectedContents([]);
            setBulkLoadingState(null);

            cache.invalidateQueries(contentQueryKeys.list(apiId));
            cache.invalidateQueries(contentQueryKeys.histories(), {
              type: 'all',
            });
            cache.invalidateQueries(contentQueryKeys.version(), {
              type: 'all',
            });
            done();
          } else if (topicValue.status === 'FAILED') {
            addToast(topicValue.message || t('Content could not be updated.'), {
              appearance: 'error',
            });
            setSelectedContents([]);
            setBulkLoadingState(null);
            done();
          }
        }
      });
    },
    onError({ message }) {
      addToast(message ? message : t('Content could not be updated.'), {
        appearance: 'error',
      });
    },
  });

  return { bulkUpdateContentsStatusAsync, bulkUpdateContentsStatusLoading };
};
