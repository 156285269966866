import { useCallback, useMemo } from 'react';

import type { Filter } from '../Media/useMediaFilter';
import type { ServiceUser } from '@/entity/member';
import type { ListMediumTags } from '@/types/MediumTag';

type Args = {
  defaultFiltersArray: Filter[];
  onSubmitFiltersArray: (defaultFiltersArray: Filter[]) => void;
  members?: ServiceUser[];
  listMediumTags?: ListMediumTags;
};

type ReturnValue = {
  mediaFilters: Filter[];
  onDeleteFilter: (id: string) => void;
};

export const useMediaFilterList = ({
  defaultFiltersArray,
  onSubmitFiltersArray,
  members,
  listMediumTags,
}: Args): ReturnValue => {
  const mediaFilters = useMemo(() => {
    const array = defaultFiltersArray.map((filter) => {
      if (filter.key === 'createdBy') {
        const member = members?.find(
          (member) => member.username === filter.value,
        );
        return {
          ...filter,
          value: (member?.name || member?.email.split('@')[0]) ?? '',
        };
      }

      if (filter.key === 'tags') {
        const tagName = listMediumTags?.tags.find(
          (tag) => tag.mediumTagId === filter.value,
        )?.name;
        return {
          ...filter,
          value: tagName ?? '',
        };
      }
      return filter;
    });
    return array;
  }, [defaultFiltersArray, members, listMediumTags]);

  const onDeleteFilter = useCallback(
    (id: string) => {
      const newFiltersArray = defaultFiltersArray.filter(
        (filter) => filter.id !== id,
      );
      onSubmitFiltersArray(newFiltersArray);
    },
    [defaultFiltersArray, onSubmitFiltersArray],
  );

  return { mediaFilters, onDeleteFilter };
};
