import { Td } from '@/components/ui/Table';

import { FIELD_NO_VALUE } from '../../../constants';
import { useI18nFormRole } from '../../../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../../../provider';

import type { PropsWithECP } from '../_types';
import type { FC } from 'react';

import { Select } from '@/views/Common/Roles/Select';
import { Checkbox } from '@/views/Common/Ui/Checkbox';

const FieldReviewRequest: FC<PropsWithECP> = ({
  targetECP: { id, reviewRequestPermission },
}) => {
  const { t } = useI18nFormRole();

  const { isAdminRole } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  return (
    <>
      <Td>{t('Review requests')}</Td>
      <Td>
        <Select
          name="reviewRequestPermission.readLevel"
          onValueChange={(value) =>
            dispatch({
              type: 'set-ec-permission-reviewrequestread',
              payload: {
                id,
                nextValue: value,
              },
            })
          }
          value={reviewRequestPermission.readLevel}
          disabled={disabled}
          items={[
            {
              value: 'ALL',
              label: t('All reviews'),
              description: t('View all review requests.'),
            },
            {
              value: 'SAME_ROLE',
              label: t('Only reviews created by members of this role'),
              description: t(
                'Able to view only review created by members in the same role.',
              ),
            },
            {
              value: 'CREATED',
              label: t('Only reviews you requested for'),
              description: t(
                'Able to view only the reviews you have submitted.',
              ),
            },
            {
              value: 'NONE',
              label: t('None'),
              description: t('Review requests cannot be displayed.'),
            },
          ]}
        />
      </Td>
      <Td>
        <Checkbox
          name="create"
          onChange={(e) => {
            const nextValue = e.target.checked;
            dispatch({
              type: 'set-ec-permission-reviewrequestcreate',
              payload: { id, nextValue },
            });
          }}
          checked={reviewRequestPermission.create}
          disabled={disabled}
          label={t('Request reviews')}
        />
      </Td>
      <Td>
        <Select
          name="reviewRequestPermission.updateLevel"
          onValueChange={(value) =>
            dispatch({
              type: 'set-ec-permission-reviewrequestupdate',
              payload: {
                id,
                nextValue: value,
              },
            })
          }
          value={reviewRequestPermission.updateLevel}
          disabled={disabled}
          items={[
            {
              value: 'ALL',
              label: t('Edit review forms and approve'),
              description: t(
                'In addition to editing and comments, you can delete comments and approve reviews (publish content).',
              ),
            },
            {
              value: 'EDIT',
              label: t('Edit review forms only'),
              description: t(
                'Able to close, reapply, post comments, and edit the review title and description.',
              ),
            },
            {
              value: 'NONE',
              label: t('None'),
              description: t('Reviews cannot be edited.'),
            },
          ]}
        />
      </Td>
      <Td>{FIELD_NO_VALUE}</Td>
    </>
  );
};

export default FieldReviewRequest;
