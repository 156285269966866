import { colorToHexString } from '@/util/color';
import { useTranslation } from 'react-i18next';
import { ColorPicker } from './ColorPicker';

import Button from '@/components/ui/Button';

import { useInputRichEditorV2ColorListField } from './useInputRichEditorV2ColorListField';

import type { RichEditorV2Color } from '@/types/field';

import type { Color, ColorResult } from 'react-color';

import styles from './inputRichEditorV2ColorListField.module.css';

import { Popover } from '@/views/InputField/RichEditorV2/Popover';

export type Props = {
  colorList: RichEditorV2Color[];
  setColorList: React.Dispatch<RichEditorV2Color[]>;
};

export type ViewProps = {
  colorList: RichEditorV2Color[];
  addColorList: () => void;
  removeColorList: (id: string) => () => void;
  handleChangeColor: (id: string, value: ColorResult) => void;
  handleSetColor: React.Dispatch<string>;
  color?: Color;
  isMaxColorList: boolean;
  addButtonRef: React.RefObject<HTMLButtonElement>;
  colorItemId: string | null;
  handleSetColorItemId: (id: string | null) => void;
};

export const InputRichEditorV2ColorListFieldView: React.FC<ViewProps> = ({
  colorList,
  addColorList,
  removeColorList,
  handleChangeColor,
  handleSetColor,
  color,
  isMaxColorList,
  addButtonRef,
  colorItemId,
  handleSetColorItemId,
}) => {
  const { t } = useTranslation('inputRichEditorV2ColorListField');

  return (
    <div className={styles.main}>
      {colorList.length !== 0 && (
        <ul className={styles.colorList}>
          {colorList.map((item) => (
            <li
              key={item.id}
              onMouseEnter={() => handleSetColorItemId(item.id)}
              onMouseLeave={() => handleSetColorItemId(null)}
            >
              <Popover
                modal={true}
                onOpenChange={(open) => {
                  if (!open) {
                    handleSetColor('');
                  }
                }}
                trigger={
                  <div className={styles.colorButton}>
                    <button
                      type="button"
                      aria-label={t('{{colorValue}} color presets', {
                        colorValue: colorToHexString(item.value),
                      })}
                      style={{ backgroundColor: item.value }}
                      className={styles.colorItem}
                      onClick={() => handleSetColor(item.value)}
                      onFocus={() => handleSetColorItemId(item.id)}
                      onKeyDown={(e) => {
                        if (e.key === 'Tab' && e.shiftKey) {
                          handleSetColorItemId(null);
                        }
                      }}
                    />
                    {!color && colorItemId === item.id && (
                      <button
                        type="button"
                        aria-label={t('Delete button for {{colorValue}}', {
                          colorValue: colorToHexString(item.value),
                        })}
                        className={styles.removeButton}
                        onClick={removeColorList(item.id)}
                        onBlur={() => {
                          // colorListの最後の値のフォーカスが外れた時に削除ボタンを非表示にする
                          if (colorList[colorList.length - 1].id === item.id) {
                            handleSetColorItemId(null);
                            addButtonRef.current?.focus();
                          }
                        }}
                      >
                        <i className="material-icons">clear</i>
                      </button>
                    )}
                  </div>
                }
                body={
                  <ColorPicker
                    color={color}
                    colorItemId={item.id}
                    handleChangeColor={handleChangeColor}
                  />
                }
              />
            </li>
          ))}
        </ul>
      )}
      <Button
        type="tertiary"
        ref={addButtonRef}
        value={t('Add')}
        onClick={addColorList}
        icon="add"
        disabled={isMaxColorList}
      />
    </div>
  );
};

export const InputRichEditorV2ColorListField: React.FC<Props> = ({
  colorList,
  setColorList,
}) => {
  const {
    addColorList,
    removeColorList,
    handleChangeColor,
    handleSetColor,
    color,
    isMaxColorList,
    addButtonRef,
    colorItemId,
    handleSetColorItemId,
  } = useInputRichEditorV2ColorListField({
    colorList,
    setColorList,
  });

  return (
    <InputRichEditorV2ColorListFieldView
      colorList={colorList}
      addColorList={addColorList}
      removeColorList={removeColorList}
      handleChangeColor={handleChangeColor}
      handleSetColor={handleSetColor}
      color={color}
      isMaxColorList={isMaxColorList}
      addButtonRef={addButtonRef}
      colorItemId={colorItemId}
      handleSetColorItemId={handleSetColorItemId}
    />
  );
};
