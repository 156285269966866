import { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Redirect } from 'react-router-dom';

import { myRolesContext, useAdmin } from '@/hooks/Role/useMyRoles';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';

import AmountSettings from './AmountSettings';
import AuditLogSettings from './AuditLogSettings';
import BillingSettings from './BillingSettings';
import ContentsCountSettings from './ContentsCountSettings';
import CustomDomainSettings from './CustomDomainSettings';
import DeleteSettings from './DeleteSettings';
import EnvironmentsSettings from './EnvironmentsSettings';
import IdSettings from './IdSettings';
import IpRestrictionsSettings from './IpRestrictionsSettings';
import NameSettings from './NameSettings';
import RequireMfaSettings from './RequireMfaSettings';
import Forbidden from '../Forbidden';
import NotFound from '../NotFound';
import PrivateRoute from '../PrivateRoute';
import Loading from '../ui/Loading';
import Menu from '../ui/Menu';
import MenuItem from '../ui/MenuItem';

import styles from './serviceSettings.module.css';

import { ApiIpAddressRestrictionSettings } from '@/views/ServiceSettings/ApiIpAddressRestrictionSettings';
import ExportService from '@/views/ServiceSettings/ExportService';
import LanguageSettings from '@/views/ServiceSettings/LanguageSettings';
import { SAMLSettings } from '@/views/ServiceSettings/SAMLSettings/SAMLSettings';

const ServiceSettings: React.FC = () => {
  const { service, hasService, isLoading, isChildEnvironment } =
    useGetMyService();
  const { t } = useTranslation('serviceSettings');
  // 権限を取得
  const { roles } = useContext(myRolesContext);

  const { currentPlan } = useStripeActions(service);

  // 管理者権限
  const [isAdmin] = useAdmin();

  // メンバー権限
  const hasReadMemberPermission = useMemo(() => {
    return (
      roles &&
      roles
        .map(({ memberPermission }) => memberPermission.simpleRead)
        .includes(true)
    );
  }, [roles]);

  // ロール権限
  const hasReadRolePermission = useMemo(() => {
    return (
      roles &&
      roles
        .map(
          ({ rolePermission }) =>
            rolePermission.readLevel === 'ALL' ||
            rolePermission.readLevel === 'SAME_ROLE',
        )
        .includes(true)
    );
  }, [roles]);

  // 利用量権限
  const hasReadUsagePermission = useMemo(() => {
    return (
      roles &&
      roles.map(({ usagePermission }) => usagePermission.read).includes(true)
    );
  }, [roles]);

  // 環境権限
  const hasReadEnvironmentPermission = useMemo(() => {
    return (
      roles &&
      roles
        .map(({ environmentPermission }) => environmentPermission.read)
        .includes(true)
    );
  }, [roles]);

  // ApiKey権限
  const hasReadApiKeyPermission = useMemo(() => {
    return (
      roles &&
      roles.map(({ apiKeyPermission }) => apiKeyPermission.read).includes(true)
    );
  }, [roles]);

  // お支払い権限
  const hasReadBillingPermission = useMemo(() => {
    return (
      roles &&
      roles
        .map(({ billingPermission }) => billingPermission.read)
        .includes(true)
    );
  }, [roles]);

  if (isLoading) return <Loading />;

  // 自身のサービスに存在しない場合はリダイレクト
  if (!hasService || !service) {
    return <Redirect to="/not-found" />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.menu}>
        <Menu title={t('Service Settings')}>
          <MenuItem to="/settings" exact={true}>
            {t('Basic Information')}
          </MenuItem>
          {hasReadMemberPermission && !isChildEnvironment && (
            <MenuItem to="/members" exact={true}>
              {t('Member')} <i className="material-icons">launch</i>
            </MenuItem>
          )}
          {hasReadRolePermission && !isChildEnvironment && (
            <MenuItem to="/roles" exact={true}>
              {t('Role')} <i className="material-icons">launch</i>
            </MenuItem>
          )}
          {hasReadApiKeyPermission && (
            <MenuItem to="/api-keys" exact={true}>
              {t('API Key')} <i className="material-icons">launch</i>
            </MenuItem>
          )}
          {hasReadBillingPermission && !isChildEnvironment && (
            <MenuItem to="/settings/billing">{t('Payment')}</MenuItem>
          )}
          {hasReadEnvironmentPermission && !isChildEnvironment && (
            <MenuItem to="/settings/environments">{t('Environment')}</MenuItem>
          )}
          {currentPlan && currentPlan.name === 'Enterprise' && (
            <MenuItem to="/settings/custom-domain">
              {t('Custom Domain')}
            </MenuItem>
          )}
          {isAdmin && (
            <MenuItem to="/settings/language" exact={true}>
              {t('Notification Language')}
            </MenuItem>
          )}
        </Menu>
        {!isChildEnvironment && (
          <Menu title={t('Data Log')}>
            {hasReadUsagePermission && (
              <MenuItem to="/settings/amount">
                {t('Data Transfer Amount')}
              </MenuItem>
            )}
            {hasReadUsagePermission && (
              <MenuItem to="/settings/contents-count">
                {t('Contents Count')}
              </MenuItem>
            )}
            {isAdmin && hasReadUsagePermission && (
              <MenuItem to="/settings/audit-log">{t('Audit Log')}</MenuItem>
            )}
          </Menu>
        )}
        {isAdmin && (
          <Menu title={t('Security')}>
            <MenuItem to="/settings/require-mfa" exact={true}>
              {t('Two-Factor authentication')}
            </MenuItem>
            <MenuItem to="/settings/ip-restrictions" exact={true}>
              {t('Administration console IP Restriction')}
            </MenuItem>
            <MenuItem to="/settings/api-ip-restrictions" exact={true}>
              {t('API IP Restriction')}
            </MenuItem>
            <MenuItem to="/settings/saml" exact={true}>
              SAML
            </MenuItem>
          </Menu>
        )}
        {isAdmin && !isChildEnvironment && (
          <Menu title={t('Others')}>
            <MenuItem to="/settings/template" exact={true}>
              {t('Export Service')}
            </MenuItem>
          </Menu>
        )}
        {isAdmin && !isChildEnvironment && (
          <Menu title={t('Proceed with Caution')}>
            <MenuItem to="/settings/service-id">
              {t('Change Service ID')}
            </MenuItem>
            <MenuItem to="/settings/delete">{t('Delete service')}</MenuItem>
          </Menu>
        )}
      </div>
      <div className={styles.flex}>
        <Switch>
          <PrivateRoute exact path="/settings" component={NameSettings} />
          <PrivateRoute
            exact
            path="/settings/require-mfa"
            component={isAdmin ? RequireMfaSettings : Forbidden}
          />
          {!isChildEnvironment && (
            <PrivateRoute
              exact
              path="/settings/amount"
              component={hasReadUsagePermission ? AmountSettings : Forbidden}
            />
          )}
          <PrivateRoute
            exact
            path="/settings/contents-count"
            component={ContentsCountSettings}
          />
          {!isChildEnvironment && (
            <PrivateRoute
              exact
              path="/settings/billing"
              component={hasReadBillingPermission ? BillingSettings : Forbidden}
            />
          )}
          {!isChildEnvironment && (
            <PrivateRoute
              exact
              path="/settings/environments"
              component={
                hasReadEnvironmentPermission ? EnvironmentsSettings : Forbidden
              }
            />
          )}
          <PrivateRoute
            exact
            path="/settings/custom-domain"
            component={CustomDomainSettings}
          />
          {isAdmin && (
            <PrivateRoute
              exact
              path="/settings/language"
              component={LanguageSettings}
            />
          )}
          {isAdmin && (
            <PrivateRoute
              exact
              path="/settings/template"
              component={ExportService}
            />
          )}
          {isAdmin && !isChildEnvironment && (
            <PrivateRoute
              exact
              path="/settings/audit-log"
              component={AuditLogSettings}
            />
          )}
          {isAdmin && (
            <PrivateRoute
              exact
              path="/settings/ip-restrictions"
              component={IpRestrictionsSettings}
            />
          )}
          {isAdmin && (
            <PrivateRoute
              exact
              path="/settings/api-ip-restrictions"
              component={ApiIpAddressRestrictionSettings}
            />
          )}
          {isAdmin && (
            <PrivateRoute
              exact
              path="/settings/saml"
              component={isAdmin ? SAMLSettings : Forbidden}
            />
          )}
          {isAdmin && !isChildEnvironment && (
            <PrivateRoute
              exact
              path="/settings/service-id"
              component={IdSettings}
            />
          )}
          {isAdmin && !isChildEnvironment && (
            <PrivateRoute
              exact
              path="/settings/delete"
              component={DeleteSettings}
            />
          )}
          <PrivateRoute component={NotFound} />
        </Switch>
      </div>
    </div>
  );
};

export default ServiceSettings;
