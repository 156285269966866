import TextAlign from '@tiptap/extension-text-align';

import type { TextAlignOptions } from '@tiptap/extension-text-align';

type CustomTextAlignOptions = TextAlignOptions & {
  inputRules: boolean;
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-text-align/src/text-align.ts
export const CustomTextAlign = TextAlign.extend<CustomTextAlignOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: true,
    };
  },

  addGlobalAttributes() {
    return this.options.inputRules ? this.parent?.() || [] : [];
  },

  addKeyboardShortcuts() {
    return this.options.inputRules ? { ...this.parent?.() } : {};
  },
});
