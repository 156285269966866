/* tslint:disable */
/* eslint-disable */
/**
 * マネジメントAPI
 * マネジメントAPI
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 成功時のレスポンス
 * @export
 * @interface DeleteMediumByDirectoryResponse
 */
export interface DeleteMediumByDirectoryResponse {
  /**
   *
   * @type {string}
   * @memberof DeleteMediumByDirectoryResponse
   */
  id: string;
}

export function DeleteMediumByDirectoryResponseFromJSON(
  json: any,
): DeleteMediumByDirectoryResponse {
  return DeleteMediumByDirectoryResponseFromJSONTyped(json, false);
}

export function DeleteMediumByDirectoryResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): DeleteMediumByDirectoryResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
  };
}

export function DeleteMediumByDirectoryResponseToJSON(
  value?: DeleteMediumByDirectoryResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
  };
}
