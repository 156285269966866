import { useEffect } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';

import CreateService from '../CreateService';
import Head from '../Head';
import Button from '../ui/Button';
import Modal from '../ui/Modal';

import styles from './welcome.module.css';

const Welcome: React.FC = () => {
  const { t } = useTranslation('welcome');
  const [WelcomeModal, openWelcomeModal, closeWelcomeModal] = useModal('root');
  useEffect(() => {
    openWelcomeModal();
  }, [openWelcomeModal]);

  return (
    <div>
      <WelcomeModal>
        <Modal
          type="medium"
          title={t('Thank you for registering with microCMS!')}
        >
          <Head title="Welcome" />
          <p className={styles.description}>
            {t("Let's start with following steps.")}
          </p>
          <ul className={styles.steps}>
            <li className={styles.step}>
              <div className={styles.stepImg}>
                <img src="/images/icon_welcome_service.svg" alt="" />
              </div>
              <p className={styles.stepDescription}>{t('Create a service')}</p>
            </li>
            <li className={styles.step}>
              <div className={styles.stepImg}>
                <img src="/images/icon_welcome_contents.svg" alt="" />
              </div>
              <p className={styles.stepDescription}>{t('Create contents')}</p>
            </li>
            <li className={styles.step}>
              <div className={styles.stepImg}>
                <img src="/images/icon_welcome_api.svg" alt="" />
              </div>
              <p className={styles.stepDescription}>{t('Use with API')}</p>
            </li>
          </ul>
          <Button
            type="secondary"
            size="large"
            value={t('Start')}
            className={styles.button}
            onClick={closeWelcomeModal}
          />
        </Modal>
      </WelcomeModal>
      <CreateService />
    </div>
  );
};

export default Welcome;
