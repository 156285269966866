import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { removeReviewerUsecase } from '@/usecase/reviewerUsecase';

import type { RemoveReviewerMutation } from '@/API';
import type { GraphQLError } from 'graphql';

import { uniformQueryKeyParam } from '@/util/query-key';

function useRemoveReviewer() {
  const { t } = useTranslation('hooksReview');
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  const { mutate, isLoading } = useMutation<
    RemoveReviewerMutation['removeReviewer'],
    { errors: Array<GraphQLError> },
    Parameters<typeof removeReviewerUsecase>[0]
  >({
    mutationFn: async (variables) => {
      const result = await removeReviewerUsecase(variables);
      return result;
    },
    async onSuccess(data, { reqId }) {
      // レスポンスのmessageにエラーメッセージが入っているので、それを表示する
      if (data?.message) {
        return addToast(data.message, {
          appearance: 'error',
        });
      }
      if (!data?.result) {
        return addToast(t('Could not delete reviewer.'), {
          appearance: 'error',
        });
      }

      const queryKeyReqId = uniformQueryKeyParam(reqId);

      await queryClient.refetchQueries({
        queryKey: ['reviewRequest', { reqId: queryKeyReqId }],
      });
      await queryClient.refetchQueries({
        queryKey: ['reviewRequestEvents', { reqId: queryKeyReqId }],
      });

      addToast(t('Reviewer has been removed.'), {
        appearance: 'success',
      });
    },
    onError({ errors }) {
      addToast(errors ? errors[0].message : t('Could not delete reviewer.'), {
        appearance: 'error',
      });
    },
  });

  return { removeReviewer: mutate, isLoading };
}

export { useRemoveReviewer };
