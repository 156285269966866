/* tslint:disable */
/* eslint-disable */
/**
 * マネジメントAPI
 * マネジメントAPI
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface PatchContentStatusResponse
 */
export interface PatchContentStatusResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof PatchContentStatusResponse
   */
  status?: Array<PatchContentStatusResponseStatusEnum>;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchContentStatusResponseStatusEnum {
  Publish = 'PUBLISH',
  Draft = 'DRAFT',
}

export function PatchContentStatusResponseFromJSON(
  json: any,
): PatchContentStatusResponse {
  return PatchContentStatusResponseFromJSONTyped(json, false);
}

export function PatchContentStatusResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PatchContentStatusResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
  };
}

export function PatchContentStatusResponseToJSON(
  value?: PatchContentStatusResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: value.status,
  };
}
