import { API, graphqlOperation } from 'aws-amplify';

import type { contents as Contents, GetPlanListQuery } from '@/API';
import type { Service } from '@/types/services';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

import * as queries from '@/graphql/queries';
import { nonNullable } from '@/util/type-guard';

export const serviceRepository = () => {
  const findServiceByDomain = async (
    domain: string,
  ): Promise<Contents | null> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.findServiceByDomain, {
          domain,
        }),
      )) as { data: { findServiceByDomain: Contents } };

      if (result === undefined || result === null) {
        return null;
      }

      if (
        result.data.findServiceByDomain === null ||
        result.data.findServiceByDomain === undefined
      ) {
        return null;
      }

      const service = result.data.findServiceByDomain;
      return service;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not find service');
    }
  };

  /**
   * 自分が所属しているサービス一覧を取得する
   * （環境サービスも含む）
   */
  const getMyServices = async () => {
    try {
      const services = (await API.graphql(
        graphqlOperation(queries.getMyServices),
      )) as { data: { getMyServices?: Service[] | null } };

      const serviceList = services.data.getMyServices;

      if (!serviceList) {
        return [];
      }

      return serviceList
        .map((serviceList) => {
          if (!serviceList) {
            return null;
          }

          return {
            ...serviceList,
            domain: serviceList.gsiSinglePartitionKey1?.replace('DOMAIN#', ''),
          };
        })
        .filter(nonNullable);
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not get my services');
    }
  };

  const getPlanList = async (serviceId: string) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.getPlanList, {
          serviceId,
        }),
      )) as GraphQLResult<GetPlanListQuery>;

      return result.data?.getPlanList || null;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not get plan list');
    }
  };

  return { findServiceByDomain, getMyServices, getPlanList };
};
