import dayjs from 'dayjs';
import { useCallback, useState } from 'react';

import { useUpdatePublishedAtModalContentQuery } from './useUpdatePublishedAtModalContentQuery';

import type { Props } from './UpdatePublishedAtModalContent';

export const useUpdatePublishedAtModalContent = ({
  publishedAt,
  contentId,
  closeEditPublishedAtModal,
}: Props) => {
  const [newPublishedAt, setNewPublishedAt] = useState(
    publishedAt ? dayjs(publishedAt).toDate() : undefined,
  );

  const onChangePublishedAt = useCallback(
    (date?: Date) => {
      setNewPublishedAt(date);
    },
    [setNewPublishedAt],
  );

  const { mutate: updatePublishedAt, isLoading: updatePublishedAtIsLoading } =
    useUpdatePublishedAtModalContentQuery();

  const doUpdatePublishedAt = useCallback(() => {
    if (!newPublishedAt) {
      return;
    }
    updatePublishedAt({
      contentId,
      publishedAt: newPublishedAt,
      closeEditPublishedAtModal,
    });
  }, [newPublishedAt, updatePublishedAt, contentId, closeEditPublishedAtModal]);

  const disabled = !newPublishedAt || updatePublishedAtIsLoading;
  const selectedPublishedAt = newPublishedAt
    ? newPublishedAt
    : dayjs(publishedAt).toDate();

  return {
    onChangePublishedAt,
    doUpdatePublishedAt,
    selectedPublishedAt,
    disabled,
  };
};
