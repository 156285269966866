import { I18n } from 'aws-amplify';
import type React from 'react';
import { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoading, useInput } from '../../../hooks';
import { isSAMLUser as getIsSAMLUserFlag } from '../../../util';
import Feedback from '../../Feedback';
import Button from '../../ui/Button';
import { validatePassword } from '../../Validations';

import styles from './password.module.css';

import { Textfield } from '@/views/Common/Ui/Textfield';

type Props = {
  changePasswordSuccess?: boolean;
  serverError?: {
    code: number;
    message: string;
  };
  changePassword: (oldPassword: string, newPassword: string) => void;
};

const Password: React.FC<Props> = ({
  changePasswordSuccess,
  serverError,
  changePassword,
}) => {
  const { t } = useTranslation('profilePassword');
  const [loading, startLoading] = useLoading(
    serverError !== undefined || changePasswordSuccess !== undefined,
  );

  const [oldPassword, onChangeOldPassword, oldPasswordError] = useInput(
    '',
    validatePassword,
  );

  const [newPassword, onChangeNewPassword, newPasswordError] = useInput(
    '',
    validatePassword,
  );
  const [
    newPasswordConfirm,
    onChangeNewPasswordConfirm,
    newPasswordConfirmError,
  ] = useInput('', validatePassword);
  const [confirmError, setConfirmError] = useState(false);

  // 変更の保存
  const submit = useCallback(() => {
    if (newPassword !== newPasswordConfirm) {
      setConfirmError(true);
      return;
    }
    setConfirmError(false);
    startLoading();
    changePassword(oldPassword, newPassword);
  }, [
    startLoading,
    oldPassword,
    newPassword,
    newPasswordConfirm,
    changePassword,
  ]);

  const isSAMLUser = useMemo(() => getIsSAMLUserFlag(), []);

  if (isSAMLUser) {
    return (
      <div>
        <h2 className={styles.title}>{t('Change Password')}</h2>
        <div className={styles.wrapper}>
          <p>{t('This setting cannot be changed with your account. ')}</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2 className={styles.title}>{t('Change Password')}</h2>
      <div className={styles.formGroup}>
        <Textfield
          label={t('Current Password')}
          type="password"
          onChange={onChangeOldPassword}
          error={oldPasswordError}
        />
        <Textfield
          label={t('New Password')}
          description={t(
            'Must be at least 8 characters. Must include uppercase letters, lowercase letters and numbers.',
          )}
          type="password"
          onChange={onChangeNewPassword}
          error={newPasswordError}
        />
        <Textfield
          label={t('New Password(confirmation)')}
          type="password"
          onChange={onChangeNewPasswordConfirm}
          error={newPasswordConfirmError}
        />
      </div>
      <div className={styles.actions}>
        <Feedback
          type={serverError || confirmError === true ? 'failure' : 'success'}
          message={
            (changePasswordSuccess && t('Password changed.')) ||
            (confirmError && t('New password (confirmation) does not match')) ||
            (serverError && I18n.get(serverError.code, serverError.message))
          }
        />
        <Button
          type="primary"
          value={t('Save changes')}
          disabled={loading}
          onClick={submit}
        />
      </div>
    </div>
  );
};

export default Password;
