import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { MigrateApi } from '@/entity/api';
import type { ContentStatus } from '@/entity/content';

import styles from './updatepublishedatmodaltrigger.module.css';

import { formatDate } from '@/util/date';
import { getContentUpdatePermissionEntryByStatus } from '@/util/permissions/content';
import { useHasUpdateContentPermission } from '@/views/Common/Roles/MyRolesPermission';
import { Icon } from '@/views/Common/Ui/Icon';
import { ModalTrigger } from '@/views/Common/Ui/Modal';

export type Props = {
  contentStatus: ContentStatus;
  onClick: () => void;
  disabled?: boolean;
  publishedAt: string;
  apiId: MigrateApi['partitionKey'];
};

export type ViewProps = {
  onClick: () => void;
  disabled?: boolean;
  publishedAt: string;
};

export const UpdatePublishedAtModalTriggerView: React.FC<ViewProps> = ({
  onClick,
  disabled,
  publishedAt,
}) => {
  const { t } = useTranslation('updatePublishedAtModal');

  return (
    <ModalTrigger
      aria-label={t('Change Published Date and Time')}
      className={styles.main}
      onClick={onClick}
      disabled={disabled}
    >
      {formatDate(publishedAt)}
      {!disabled && <Icon name="edit" />}
    </ModalTrigger>
  );
};

export const UpdatePublishedAtModalTrigger: React.FC<Props> = ({
  contentStatus,
  onClick,
  disabled,
  publishedAt,
  apiId,
}) => {
  const { hasUpdateContentPermission } = useHasUpdateContentPermission();

  const isEditable = useMemo(() => {
    return hasUpdateContentPermission(
      getContentUpdatePermissionEntryByStatus(
        'changePublicationDate',
        contentStatus,
      ),
      apiId,
    );
  }, [contentStatus, hasUpdateContentPermission, apiId]);

  return (
    <UpdatePublishedAtModalTriggerView
      onClick={onClick}
      disabled={disabled || !isEditable}
      publishedAt={publishedAt}
    />
  );
};
