import { Td } from '@/components/ui/Table';

import { useI18nFormRole } from '../../../i18n';
import FieldContentCreate from '../../shared/FieldContent/FieldContentCreate';
import FieldContentDelete from '../../shared/FieldContent/FieldContentDelete';
import FieldContentRead from '../../shared/FieldContent/FieldContentRead';
import FieldContentUpdate from '../../shared/FieldContent/FieldContentUpdate';
import FieldTitle from '../../shared/FieldTitle';

import type { PropsWithECP } from '../_types';
import type { FC } from 'react';

const FieldContent: FC<PropsWithECP> = ({ targetECP }) => {
  const { t } = useI18nFormRole('$contentPermission');

  return (
    <>
      <Td verticalAlign="top">
        <FieldTitle variant="v-top">{t('Contents')}</FieldTitle>
      </Td>
      <Td verticalAlign="top">
        <FieldContentRead targetECP={targetECP} />
      </Td>
      <Td verticalAlign="top">
        <FieldContentCreate targetECP={targetECP} />
      </Td>
      <Td verticalAlign="top">
        <FieldContentUpdate targetECP={targetECP} />
      </Td>
      <Td verticalAlign="top">
        <FieldContentDelete targetECP={targetECP} />
      </Td>
    </>
  );
};

export default FieldContent;
