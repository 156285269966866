import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from '../_types';
import type { ContentPermission, Role } from '@/entity/role';

import { produce } from '@/lib/immer';

type SetDefaultContentPermissionContentCreateAction<
  K extends
    keyof ContentPermission['contentCreate'] = keyof ContentPermission['contentCreate'],
  V extends
    ContentPermission['contentCreate'][K] = ContentPermission['contentCreate'][K],
> = FormRoleInputValueAction<
  'set-dc-permission-contentcreate',
  { key: K; nextValue: V }
>;
type SetDefaultContentPermissionContentReadAction = FormRoleInputValueAction<
  'set-dc-permission-contentread',
  Role['defaultContentPermission']['contentReadLevel']
>;
type SetDefaultContentPermissionContentUpdateAction<
  K extends
    keyof ContentPermission['contentUpdate'] = keyof ContentPermission['contentUpdate'],
  V extends
    ContentPermission['contentUpdate'][K] = ContentPermission['contentUpdate'][K],
> = FormRoleInputValueAction<
  'set-dc-permission-contentupdate',
  { key: K; nextValue: V }
>;
type SetDefaultContentPermissionContentDeleteAction = FormRoleInputValueAction<
  'set-dc-permission-contentdelete',
  Role['defaultContentPermission']['contentDelete']
>;

/**
 * DefaultContentPermissionのcontentCreateフィールドを更新する
 */
const setDefaultContentPermissionContentCreate: FormRoleInputValueDispatchFn<
  SetDefaultContentPermissionContentCreateAction
> = (currentState, action) => {
  return produce(currentState, (draft) => {
    draft.defaultContentPermission.contentCreate[action.key] = action.nextValue;
  });
};

/**
 * DefaultContentPermissionのcontentReadLevelフィールドを更新する
 */
const setDefaultContentPermissionContentRead: FormRoleInputValueDispatchFn<
  SetDefaultContentPermissionContentReadAction
> = (currentState, action) => {
  return produce(currentState, (draft) => {
    draft.defaultContentPermission.contentReadLevel = action;
  });
};

/**
 * DefaultContentPermissionのcontentUpdateフィールドを更新する
 */
const setDefaultContentPermissionContentUpdate: FormRoleInputValueDispatchFn<
  SetDefaultContentPermissionContentUpdateAction
> = (currentState, action) => {
  return produce(currentState, (draft) => {
    draft.defaultContentPermission.contentUpdate[action.key] = action.nextValue;
  });
};

/**
 * DefaultContentPermissionのcontentDeleteフィールドを更新する
 */
const setDefaultContentPermissionContentDelete: FormRoleInputValueDispatchFn<
  SetDefaultContentPermissionContentDeleteAction
> = (currentState, action) => {
  return produce(currentState, (draft) => {
    draft.defaultContentPermission.contentDelete = action;
  });
};

export {
  type SetDefaultContentPermissionContentCreateAction,
  type SetDefaultContentPermissionContentReadAction,
  type SetDefaultContentPermissionContentUpdateAction,
  type SetDefaultContentPermissionContentDeleteAction,
  setDefaultContentPermissionContentCreate,
  setDefaultContentPermissionContentRead,
  setDefaultContentPermissionContentUpdate,
  setDefaultContentPermissionContentDelete,
};
