import React from 'react';
import Linkify from 'react-linkify';

const linkDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

const LinkifyWrap = ({ children }) => {
  return <Linkify componentDecorator={linkDecorator}>{children}</Linkify>;
};

export default LinkifyWrap;
