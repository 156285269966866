import * as Popover from '@radix-ui/react-popover';
import { useTranslation } from 'react-i18next';

import Member from '@/components/Member';

import { SearchMember } from './SearchMember';
import { Icon } from '../../Ui/Icon';

import type { RowProps } from './SearchMember/SearchMember';

import styles from './memberselect.module.css';

export type Props = ViewProps;

export type ViewProps = {
  selectedMemberId: string;
  showMemberSelector: boolean;
  reverseShowMemberSelector: () => void;
  members: RowProps[] | null;
  onClickRow: (username: string) => void;
};

export const MemberSelectView: React.FC<ViewProps> = ({
  selectedMemberId,
  showMemberSelector,
  reverseShowMemberSelector,
  members,
  onClickRow,
}) => {
  const { t } = useTranslation('memberSelect');

  return (
    <Popover.Root
      open={showMemberSelector}
      onOpenChange={reverseShowMemberSelector}
    >
      <Popover.Trigger
        type="button"
        aria-label={t('Select a member')}
        className={styles.selectMemberButton}
      >
        <Member username={selectedMemberId} theme="black" />
        <Icon name="expand_more" className={styles.buttonArrowIcon} />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side="bottom"
          sideOffset={8}
          avoidCollisions={false}
          style={{ zIndex: 100, pointerEvents: 'auto' }}
          hideWhenDetached
          onEscapeKeyDown={reverseShowMemberSelector}
        >
          <SearchMember
            selectedMemberId={selectedMemberId}
            members={members}
            onClickRow={onClickRow}
            closeModal={reverseShowMemberSelector}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export const MemberSelect: React.FC<Props> = (props) => {
  return <MemberSelectView {...props} />;
};
