/* tslint:disable */
/* eslint-disable */
/**
 * マネジメントAPI
 * マネジメントAPI
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 成功時のレスポンス
 * @export
 * @interface PostMediaResponse
 */
export interface PostMediaResponse {
  /**
   *
   * @type {string}
   * @memberof PostMediaResponse
   */
  url: string;
}

export function PostMediaResponseFromJSON(json: any): PostMediaResponse {
  return PostMediaResponseFromJSONTyped(json, false);
}

export function PostMediaResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PostMediaResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    url: json['url'],
  };
}

export function PostMediaResponseToJSON(value?: PostMediaResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    url: value.url,
  };
}
