import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { useGetMyService } from '@/hooks/useService';
import {
  useWebhookMediumSetting,
  useWebhookMediumSettingCrud,
} from '@/hooks/Webhook/useWebhookMedium';

import CheckRoles from '../../CheckRoles';
import Head from '../../Head';
import Button from '../../ui/Button';
import Textfield from '../../ui/Textfield';
import { validateUrl } from '../../Validations';

import styles from './webhookSettings.module.css';

import { useInput } from '@/hooks';

const MediaWebhook: React.FC = () => {
  const { service } = useGetMyService();
  const { t } = useTranslation('mediaWebhookSettings');
  const [events, setEvents] = useState<any[]>([]);
  const [name, onChangeName, nameError] = useInput(undefined, () => null, true);
  const [customUrl, onChangeCustomUrl, customUrlError] = useInput(
    undefined,
    // @ts-expect-error
    validateUrl,
  );
  const {
    createWebhookMedium,
    createWebhookMediumLoading,
    updateWebhookMedium,
    updateWebhookMediumLoading,
    deleteWebhookMedium,
    deleteWebhookMediumLoading,
  } = useWebhookMediumSettingCrud(service?.partitionKey);
  const [webhookMedium, isLoading] = useWebhookMediumSetting(
    service?.partitionKey,
  );
  const _name =
    name || (webhookMedium && webhookMedium[0]?.settingValues?.name);
  const _customUrl =
    customUrl || (webhookMedium && webhookMedium[0]?.settingValues?.url);

  const isNew = useMemo(
    () => webhookMedium && webhookMedium[0]?.apiId === undefined,
    [webhookMedium],
  );

  useEffect(() => {
    if (webhookMedium) {
      setEvents((events) => webhookMedium[0]?.handleEvents || events);
    }
  }, [webhookMedium]);

  const onChangeEvents = useCallback(
    (e: any) => {
      const value = e.target.value;
      const newEvents = e.target.checked
        ? [...events, value]
        : events.filter((event) => event !== value);
      setEvents(newEvents);
    },
    [setEvents, events],
  );
  const submit = useCallback(() => {
    if (isNew) {
      // @ts-expect-error
      createWebhookMedium({
        values: {
          name,
          url: customUrl,
        },
        events: events.join(',').split(','),
      });
    } else if (_customUrl) {
      // @ts-expect-error
      updateWebhookMedium({
        current: webhookMedium[0],
        values: {
          ...webhookMedium[0]?.settingValues,
          name: _name || null,
          url: _customUrl || null,
        },
        events: events.join(',').split(','),
      });
    }
  }, [
    _customUrl,
    _name,
    createWebhookMedium,
    customUrl,
    events,
    isNew,
    name,
    updateWebhookMedium,
    webhookMedium,
  ]);

  const remove = useCallback(() => {
    // @ts-expect-error
    deleteWebhookMedium({
      current: webhookMedium[0],
    });
  }, [deleteWebhookMedium, webhookMedium]);

  if (!webhookMedium) {
    return null;
  }

  return (
    <div>
      <div className={styles.wrapper}>
        <Head title={t('Media Settings')} />
        <div className={styles.flex}>
          <h2 className={styles.pageTitle}>{t('Webhooks')}</h2>
          <p className={styles.description}>
            {t('Webhook can be triggered when media is changed.')}
          </p>
          {!isLoading && (
            <div>
              <p
                className={styles.timingGroupLabel}
                style={{ paddingTop: '10px' }}
              >
                {t('Basic Settings')}
              </p>
              <p className={styles.description}>
                {t('Set Webhook name which can be identified. (Optional)')}
              </p>
              <Textfield
                type="text"
                placeholder={t('Webhook Name')}
                defaultValue={
                  webhookMedium && webhookMedium[0]?.settingValues?.name
                }
                onChange={onChangeName}
                errorText={nameError}
              />
              <p className={styles.description} style={{ paddingTop: '10px' }}>
                {Trans({
                  t,
                  i18nKey:
                    'Notify any URL of media updates via POST request. See the documentation for details.',
                  children: (
                    <a
                      href="https://document.microcms.io/manual/medium-webhook-setting"
                      target="docs"
                    >
                      the documentation
                    </a>
                  ),
                })}
              </p>
              <Textfield
                type="text"
                placeholder="https://webhook.example.com/your_receiver"
                defaultValue={
                  webhookMedium && webhookMedium[0]?.settingValues?.url
                }
                onChange={onChangeCustomUrl}
                errorText={customUrlError}
              />
              <p
                className={styles.timingGroupLabel}
                style={{ paddingTop: '10px' }}
              >
                {t('Notification timing settings')}
              </p>
              <div className={styles.section}>
                <p className={styles.description}>
                  {t(
                    'Webhook can be triggered when media is created, updated or deleted.',
                  )}
                </p>
                <div className={styles.checkboxes}>
                  <div className={styles.timingDetailLabel}>
                    <label className={styles.checkbox}>
                      <input
                        type="checkbox"
                        value="ADD_MEDIUM"
                        checked={events.includes('ADD_MEDIUM')}
                        onChange={onChangeEvents}
                      ></input>
                      {t('When media is created')}
                    </label>
                  </div>
                  <div className={styles.timingDetailLabel}>
                    <label className={styles.checkbox}>
                      <input
                        type="checkbox"
                        value="UPDATE_MEDIUM"
                        checked={events.includes('UPDATE_MEDIUM')}
                        onChange={onChangeEvents}
                      ></input>
                      {t('When media is updated')}
                    </label>
                  </div>
                  <div className={styles.timingDetailLabel}>
                    <label className={styles.checkbox}>
                      <input
                        type="checkbox"
                        value="DELETE_MEDIUM"
                        checked={events.includes('DELETE_MEDIUM')}
                        onChange={onChangeEvents}
                      ></input>
                      {t('When media is deleted')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}

          <CheckRoles permission="mediumPermission" operation="update">
            <div className={styles.actions}>
              <Button
                value={isNew ? t('Setup') : t('Save changes')}
                style={{ marginRight: 20 }}
                onClick={submit}
                disabled={
                  createWebhookMediumLoading ||
                  updateWebhookMediumLoading ||
                  deleteWebhookMediumLoading ||
                  _customUrl === undefined ||
                  customUrlError ||
                  events.length === 0
                }
              />
              {!isNew && (
                <Button
                  type="danger"
                  disabled={deleteWebhookMediumLoading}
                  value={t('Delete')}
                  onClick={remove}
                />
              )}
            </div>
          </CheckRoles>
        </div>
      </div>
    </div>
  );
};

export default MediaWebhook;
