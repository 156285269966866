/* tslint:disable */
/* eslint-disable */
/**
 * マネジメントAPI
 * マネジメントAPI
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  type Medium,
  MediumFromJSON,
  MediumFromJSONTyped,
  MediumToJSON,
} from './Medium';

/**
 * メディアリスト
 * @export
 * @interface Media
 */
export interface Media {
  /**
   *
   * @type {Array<Medium>}
   * @memberof Media
   */
  media: Array<Medium>;
  /**
   *
   * @type {number}
   * @memberof Media
   */
  totalCount: number;
  /**
   *
   * @type {number}
   * @memberof Media
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof Media
   */
  limit: number;
}

export function MediaFromJSON(json: any): Media {
  return MediaFromJSONTyped(json, false);
}

export function MediaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Media {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    media: (json['media'] as Array<any>).map(MediumFromJSON),
    totalCount: json['totalCount'],
    offset: json['offset'],
    limit: json['limit'],
  };
}

export function MediaToJSON(value?: Media | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    media: (value.media as Array<any>).map(MediumToJSON),
    totalCount: value.totalCount,
    offset: value.offset,
    limit: value.limit,
  };
}
