type ReviewRequestStatus = 'OPEN' | 'CLOSE';
type ReviewRequestReviewer = {
  id: string;
};

export type ReviewRequest = {
  serviceId: string;
  reqId: number;
  title: string;
  contentId: string;
  status: ReviewRequestStatus;
  createdAt: string;
  updatedAt: string;
  reviewers: ReviewRequestReviewer[] | null;
  authorId: string;
  viewerGroup: string;
  description: string | null;
  reservationTime: string | null;
  reservationStopTime: string | null;
  commentCount?: number;
};

/**
 * @see web/amplify/backend/api/microcmsql/schema.graphql ReviewRequestEventを参照
 */
export type ReviewRequestEvent = {
  /**
   * {serviceId}#{reqId} といった値を持つ
   *
   * @example
   * 'SERVICE#d86cc3a1-ef59-407f-8de6-a212d27082eb#4'
   */
  serviceIdReqId: string;
  eventId: string;

  createdAt: string;
  updatedAt: string;
  authorId: string;
} & (
  | {
      type: 'COMMENT';
      comment: string;
      oldStatus: null;
      newStatus: null;
      oldTitle: null;
      newTitle: null;
      reservationTime: null;
      reservationStopTime: null;
      historyVersion: null;
      reviewerId: null;
    }
  | {
      type: 'UPDATE_STATUS';
      comment: null;
      oldStatus: ReviewRequestStatus;
      newStatus: ReviewRequestStatus;
      oldTitle: null;
      newTitle: null;
      reservationTime: null;
      reservationStopTime: null;
      historyVersion: null;
      reviewerId: null;
    }
  | {
      type: 'UPDATE_TITLE';
      comment: null;
      oldStatus: null;
      newStatus: null;
      oldTitle: string;
      newTitle: string;
      reservationTime: null;
      reservationStopTime: null;
      historyVersion: null;
      reviewerId: null;
    }
  | {
      type: 'UPDATE_RESERVATIONTIME';
      comment: null;
      oldStatus: null;
      newStatus: null;
      oldTitle: null;
      newTitle: null;
      reservationTime: string | null;
      reservationStopTime: string | null;
      historyVersion: null;
      reviewerId: null;
    }
  | {
      type: 'UPDATE_CONTENT';
      comment: null;
      oldStatus: null;
      newStatus: null;
      oldTitle: null;
      newTitle: null;
      reservationTime: null;
      reservationStopTime: null;
      historyVersion: string;
      reviewerId: null;
    }
  | {
      type: 'PUBLISH_CONTENT';
      comment: null;
      oldStatus: null;
      newStatus: null;
      oldTitle: null;
      newTitle: null;
      reservationTime: null;
      reservationStopTime: null;
      historyVersion: null;
      reviewerId: null;
    }
  | {
      type: 'ADD_REVIEWER' | 'REMOVE_REVIEWER';
      comment: null;
      oldStatus: null;
      newStatus: null;
      oldTitle: null;
      newTitle: null;
      reservationTime: null;
      reservationStopTime: null;
      historyVersion: null;
      reviewerId: string;
    }
);

export type ReviewRequestEventType =
  | 'COMMENT'
  | 'UPDATE_STATUS'
  | 'UPDATE_TITLE'
  | 'UPDATE_RESERVATIONTIME'
  | 'UPDATE_CONTENT'
  | 'PUBLISH_CONTENT'
  | 'ADD_REVIEWER'
  | 'REMOVE_REVIEWER';

/**
 * serviceIdとreqIdを結合した文字列を返す
 * ReviewRequestEventのserviceIdReqIdの形式に従う
 */
export const createServiceIdReqId = (serviceId: string, reqId: string) =>
  `${serviceId}#${reqId}`;
