import { Trans, useTranslation } from 'react-i18next';

import Head from '@/components/Head';
import Button from '@/components/ui/Button';
import ExternalLink from '@/components/ui/ExternalLink';
import Loading from '@/components/ui/Loading';
import Switch from '@/components/ui/Switch';

import { useGetMyService } from '@/hooks/useService';

import { useExportService } from './useExportService';

import styles from './ExportService.module.css';

export type ViewProps = {
  apiSchemaIsLoading?: boolean;
  exportHandler: () => void;
  isToggleOn: boolean;
  switchToggleState: (nextValue?: boolean | undefined) => void;
  currentPlan: string;
};

const serviceTemplatesUrl = 'https://templates.microcms.io/';
const aboutPageUrl = 'https://document.microcms.io/manual/templates';

export const ExportServiceView: React.FC<ViewProps> = ({
  exportHandler,
  apiSchemaIsLoading,
  isToggleOn,
  switchToggleState,
  currentPlan,
}) => {
  const { t } = useTranslation('exportService');

  return (
    <div>
      <Head title={t('Template')} />
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('Template')}</h2>
        <p className={styles.description}>
          {Trans({
            t,
            i18nKey:
              'You can publish your original template on <l>microCMS Templates</l>.',
            components: {
              l: (
                <ExternalLink
                  href={serviceTemplatesUrl}
                  hasUnderline={true}
                  eraseNoreferrer={true}
                >
                  microCMS Templates
                </ExternalLink>
              ),
            },
          })}
        </p>
      </div>
      <div className={styles.wrapper}>
        <h3 className={styles.sub_title}>{t('Applying templates plan')}</h3>
        <p className={styles.description}>
          {t('Plan for Template is applied to this service.')}
          <br />
          {t(
            'Plans for templates have a higher limit on the number of APIs and API keys, and are suitable for creating templates for paid plans.',
          )}
        </p>
        <p>
          <ExternalLink
            href={aboutPageUrl}
            hasUnderline={true}
            eraseNoreferrer={true}
          >
            {t('About the Plan for Templates')}
          </ExternalLink>
        </p>
        <div className={styles.annotated_section}>
          <h4>{t('Attention')}</h4>
          <ul>
            <li>
              <span>
                {t(
                  'Once a plan for a template is applied, it cannot be reverted.',
                )}
              </span>
            </li>
            <li>
              {t(
                'It is prohibited to operate the services of the plan for templates in production.',
              )}
            </li>
            <li>
              {t('The creation limit for the number of contents is set low.')}
            </li>
          </ul>
        </div>
        <div className={styles.switch}>
          <Switch
            size="large"
            on={isToggleOn}
            onChange={switchToggleState}
            disabled={isToggleOn || currentPlan !== 'Hobby'}
            className={styles.disabled}
            title={t('Apply Plan for Template')}
          />
          {isToggleOn ? (
            <p className={styles.applied}>{t('Plan for template applied')}</p>
          ) : currentPlan !== 'Hobby' ? (
            <p className={styles.bold}>
              {t('Non-Hobby plans cannot be transferred to Template plan')}
            </p>
          ) : (
            <p className={styles.bold}>{t('Apply Plan for Template')}</p>
          )}
        </div>
      </div>
      <div className={styles.wrapper}>
        <h3 className={styles.sub_title}>{t('Export')}</h3>
        <p className={styles.description}>
          {t(
            'You can export microCMS service information to be applied and published to microCMS templates from the API created in the configuration of your choice.',
          )}
        </p>
        <div className={styles.actions}>
          <Button
            type="secondary"
            value={t('Export')}
            onClick={exportHandler}
            disabled={apiSchemaIsLoading}
          />
        </div>
      </div>
    </div>
  );
};

const ExportService: React.FC = () => {
  const { service } = useGetMyService();
  const {
    useGetApiSchema,
    exportHandler,
    currentPlan,
    isToggleOn,
    switchToggleState,
  } = useExportService(service);

  const { isFetching: apiSchemaIsLoading } = useGetApiSchema();

  if (!currentPlan) return <Loading />;

  return (
    <ExportServiceView
      exportHandler={exportHandler}
      apiSchemaIsLoading={apiSchemaIsLoading}
      isToggleOn={isToggleOn}
      switchToggleState={switchToggleState}
      currentPlan={currentPlan}
    />
  );
};

export default ExportService;
