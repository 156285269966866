import { API, graphqlOperation } from 'aws-amplify';

import type { MediumTag, NewMediumTag } from '@/types/MediumTag';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

import * as mutations from '@/graphql/mutations';
import {
  handleApiResult,
  type ApiResponse,
  handleApiError,
} from '@/views/Common/handleApiResult';

export const mediumTagRepository = () => {
  const createMediumTags = async ({
    serviceId,
    newMediumTags,
  }: {
    serviceId: string;
    newMediumTags: NewMediumTag[];
  }): Promise<MediumTag[]> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.createMediumTags, {
          serviceId,
          newMediumTags,
        }),
      )) as GraphQLResult<{
        createMediumTags: ApiResponse<MediumTag[]> | string;
      }>;

      if (!result.data?.createMediumTags) {
        throw new Error('Unexpected error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.createMediumTags === 'object' &&
        typeof result.data.createMediumTags.result === 'boolean'
      ) {
        return handleApiResult(result.data.createMediumTags).data;
      } else if (typeof result.data.createMediumTags === 'string') {
        return JSON.parse(result.data.createMediumTags) as MediumTag[];
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      return handleApiError(e);
    }
  };

  const updateMediumTags = async ({
    serviceId,
    mediumId,
    tags,
  }: {
    serviceId: string;
    mediumId: string;
    tags: string[];
  }): Promise<ApiResponse> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.updateMediumTags, {
          serviceId,
          mediumId,
          tags,
        }),
      )) as GraphQLResult<{
        updateMediumTags: ApiResponse | string;
      }>;

      if (!result.data?.updateMediumTags) {
        throw new Error('Unexpected error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.updateMediumTags === 'object' &&
        typeof result.data.updateMediumTags.result === 'boolean'
      ) {
        return handleApiResult(result.data.updateMediumTags);
      } else if (typeof result.data.updateMediumTags === 'string') {
        return JSON.parse(result.data.updateMediumTags);
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      return handleApiError(e);
    }
  };
  return { createMediumTags, updateMediumTags };
};
