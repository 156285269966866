import * as RadixPopover from '@radix-ui/react-popover';
import cx from 'classnames';
import type React from 'react';
import { useState, useId } from 'react';

import styles from './popover.module.css';

export type ViewProps = {
  labelId: string;
  isOpenPopover: boolean;
  setIsOpenPopover: React.Dispatch<boolean>;
} & Props;

export type Props = {
  /**
   * 次の値を指定することで、Popoverの表示・非表示を制御する。
   * - true: 表示
   * - false: 非表示
   */
  enabled: boolean;
  /**
   * Popoverに表示するコンテンツ
   */
  content: React.ReactNode;
  /**
   * Popoverの表示位置
   */
  contentPosition: {
    side: RadixPopover.PopoverContentProps['side'];
    align: RadixPopover.PopoverContentProps['align'];
  };
  /**
   * triggerの対象サイズを調整
   */
  triggerSize?: 'auto' | 'full';
  children: React.ReactElement;
};

/**
 * enabledがtrueの場合に、Popoverが表示される。
 * Popoverを表示させない場合enabledがfalseを指定する。
 * また、childrenにはPopoverのトリガーとなる要素を指定する。（buttonやtabIndexが指定されているspanなど）
 *
 * @see {@link https://www.radix-ui.com/primitives/docs/components/popover} 利用するPopover
 */
export const PopoverView: React.FC<ViewProps> = ({
  isOpenPopover,
  setIsOpenPopover,
  labelId,
  enabled,
  content,
  contentPosition,
  triggerSize = 'auto',
  children,
}) => {
  if (enabled) {
    return (
      <RadixPopover.Root
        open={isOpenPopover}
        onOpenChange={(newIsOpen) => {
          setIsOpenPopover(newIsOpen);
        }}
      >
        <RadixPopover.Trigger
          className={cx(styles.popoverTrigger, styles[triggerSize])}
          aria-labelledby={labelId}
          asChild
        >
          {children}
        </RadixPopover.Trigger>
        <RadixPopover.Portal>
          <RadixPopover.Content
            className={styles.popoverContent}
            sideOffset={0}
            side={contentPosition.side}
            align={contentPosition.align}
            id={labelId}
          >
            {content}
            <RadixPopover.Arrow
              className={styles.popoverArrow}
              width={20}
              height={12}
            />
          </RadixPopover.Content>
        </RadixPopover.Portal>
      </RadixPopover.Root>
    );
  }

  return children;
};

export const Popover: React.FC<Props> = (props) => {
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const labelId = useId();

  return (
    <PopoverView
      isOpenPopover={isOpenPopover}
      setIsOpenPopover={setIsOpenPopover}
      labelId={labelId}
      {...props}
    />
  );
};
