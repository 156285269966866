/**
 * コンテンツ履歴のSortKeyには、以下のような形式の文字列が使われています。
 * "VERSION#000001"
 * この関数は、このSortKeyをパースして、バージョン番号を取得します。
 * @param sortKey
 * @returns バージョン番号
 */
export const parseHistoryVersion = (sortKey: string): number => {
  return Number.parseInt(sortKey.replace('VERSION#', ''));
};
