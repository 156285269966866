import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Editor } from '@tiptap/react';

type Args = {
  editor: Editor;
};

export const useHeading = ({ editor }: Args) => {
  const { t } = useTranslation('richEditorV2');
  const isActiveHeading = editor.isActive('heading');
  const isActiveHeading1 = editor.isActive('heading', { level: 1 });
  const isActiveHeading2 = editor.isActive('heading', { level: 2 });
  const isActiveHeading3 = editor.isActive('heading', { level: 3 });
  const isActiveHeading4 = editor.isActive('heading', { level: 4 });
  const isActiveHeading5 = editor.isActive('heading', { level: 5 });

  const headingValue = useMemo(() => {
    if (isActiveHeading1) {
      return t('Heading1');
    } else if (isActiveHeading2) {
      return t('Heading2');
    } else if (isActiveHeading3) {
      return t('Heading3');
    } else if (isActiveHeading4) {
      return t('Heading4');
    } else if (isActiveHeading5) {
      return t('Heading5');
    } else {
      return t('Paragraph');
    }
  }, [
    isActiveHeading1,
    isActiveHeading2,
    isActiveHeading3,
    isActiveHeading4,
    isActiveHeading5,
    t,
  ]);

  const toggleHeadingHandler = useCallback(
    (value: string) => {
      if (value === t('Heading1')) {
        editor.chain().focus().toggleHeading({ level: 1 }).run();
      } else if (value === t('Heading2')) {
        editor.chain().focus().toggleHeading({ level: 2 }).run();
      } else if (value === t('Heading3')) {
        editor.chain().focus().toggleHeading({ level: 3 }).run();
      } else if (value === t('Heading4')) {
        editor.chain().focus().toggleHeading({ level: 4 }).run();
      } else if (value === t('Heading5')) {
        editor.chain().focus().toggleHeading({ level: 5 }).run();
      } else {
        editor.chain().focus().setParagraph().run();
      }
      editor.view.focus();
    },
    [editor, t],
  );
  return { headingValue, toggleHeadingHandler, isActiveHeading };
};
