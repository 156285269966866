/**
 * @ts-check
 */

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import {
  cancelMemberInvitation as cancelMemberInvitationUsecase,
  inviteMembers as inviteMembersUsecase,
} from '@/usecase/memberUsecase';

import * as queries from '../../graphql/queries';
import sortMembers from './sortMembers';

import styles from './useMembers.module.css';

export const useMember = (userId) => {
  // userIdからユーザー情報を取得
  const userQuery = useQuery({
    queryKey: ['user', userId],
    queryFn: () => {
      return API.graphql(
        graphqlOperation(queries.findUser, { email: userId }),
      ).then((result) => {
        if (result.data.findUser !== null) {
          return JSON.parse(result.data.findUser);
        }
        return {};
      });
    },
    staleTime: Number.POSITIVE_INFINITY,
    enabled: !!userId,
  });
  const { data: user } = userQuery;

  return {
    user,
  };
};

/**
 * @param {string|undefined} order
 */
export const useMembers = (service, order = undefined) => {
  /**
   * サービスに属しているメンバー一覧を取得
   *
   * @type {import('@tanstack/react-query').UseQueryResult<Array<import('@/entity/member').ServiceUser>>}
   */
  const serviceUsersQuery = useQuery({
    queryKey: ['serviceUsers', service.partitionKey],
    queryFn: () => {
      return API.graphql(
        graphqlOperation(queries.findServiceUsers, {
          serviceId: service.partitionKey,
        }),
      ).then((result) => {
        if (result.data.findServiceUsers.length > 0) {
          return result.data.findServiceUsers;
        }
        return [];
      });
    },
    staleTime: Number.POSITIVE_INFINITY,
  });
  const { data: serviceUsers, isLoading } = serviceUsersQuery;

  const [filteredUsers, setFilteredUsers] = useState();
  const searchUsers = useCallback(
    (value) => {
      if (!serviceUsers || !value) {
        setFilteredUsers(undefined);
        return;
      }
      return setFilteredUsers(
        serviceUsers.filter(
          (item) =>
            (item.name && item.name.indexOf(value) > -1) ||
            (item.email && item.email.indexOf(value) > -1),
        ),
      );
    },
    [serviceUsers],
  );

  const sortedAndFilteredUsers = useMemo(() => {
    const usersToSort = filteredUsers || serviceUsers;

    if (!usersToSort) {
      return undefined;
    }

    return sortMembers(usersToSort, order);
  }, [filteredUsers, serviceUsers, order]);

  return {
    serviceUsers: sortedAndFilteredUsers,
    /** @type {Array<import('@/entity/member').ServiceUser> | undefined} */
    filteredUsers,
    setFilteredUsers,
    searchUsers,
    isLoading,
  };
};

export const useInviteMembers = (serviceId) => {
  const { t } = useTranslation('hooksMember');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  const {
    mutate: inviteMembers,
    isLoading: inviteMembersLoading,
    isSuccess: inviteMembersResult,
  } = useMutation({
    mutationFn: async ({ emails, roleIds }) => {
      return await inviteMembersUsecase({ serviceId, emails, roleIds });
    },
    onSuccess(data) {
      const parsed = JSON.parse(data);

      const validation = parsed.validation;
      if (validation == null) {
        addToast(t('An unexpected error has occurred.'), {
          appearance: 'error',
        });
        return;
      }
      if (!validation.result) {
        addToast(validation.message || t('An unexpected error has occurred.'), {
          appearance: 'error',
        });
        return;
      }

      const invitations = parsed.invitations;
      if (invitations == null) {
        addToast(t('An unexpected error has occurred.'), {
          appearance: 'error',
        });
        return;
      }
      const successes = invitations.filter((result) => result.result);
      const failures = invitations.filter((result) => !result.result);

      if (successes.length > 0) {
        cache.invalidateQueries(['serviceUsers'], { type: 'all' });
        addToast(
          t('{{success_count}} people were successfully invited.', {
            success_count: successes.length,
          }),
          { appearance: 'success' },
        );
      }

      if (failures.length > 0) {
        for (const failure of failures) {
          addToast(
            <span className={styles.emailMessage}>
              {t('Failed to invite {{email}}', { email: failure.email })}
              <br />
              {failure.message}
            </span>,
            {
              appearance: 'error',
              autoDismiss: false,
            },
          );
        }
      }
    },
    onError({ error }) {
      console.warn({ error });
      addToast(t('An unexpected error has occurred.'), {
        appearance: 'error',
      });
    },
  });

  return [inviteMembers, inviteMembersLoading, inviteMembersResult];
};

/** @type {() => [import('@tanstack/react-query').UseMutateFunction<any, { error: any; }, { serviceId: string; email: string; }, unknown>, boolean]} */
export const useReinviteMember = () => {
  const { t } = useTranslation('hooksMember');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  const { mutate: reinviteMember, isLoading: reinviteMemberLoading } =
    useMutation({
      mutationFn: async (
        /** @type {{ serviceId: string, email: string }} */
        props,
      ) => {
        return API.graphql(
          graphqlOperation(queries.reinviteMember, {
            serviceId: props.serviceId,
            email: props.email,
          }),
        ).then((res) => res.data.reinviteMember);
      },
      onSuccess(data) {
        if (data.result === false) {
          addToast(data.message, { appearance: 'error' });
          return;
        }
        cache.invalidateQueries(['serviceUsers'], { type: 'all' });
        addToast(t('An invitation email has been sent again.'), {
          appearance: 'success',
        });
      },
      onError({ error }) {
        console.log(error);
        addToast(t('An unexpected error has occurred.'), {
          appearance: 'error',
        });
      },
    });

  return [reinviteMember, reinviteMemberLoading];
};

/** @type {() => [import('@tanstack/react-query').UseMutateFunction<{ result: true; data: undefined; message?: string | null | undefined; }, unknown, { serviceId: string; email: string; }, unknown>, boolean, { result: true; data: undefined; message?: string | null | undefined; } | undefined]} */
export const useCancelMemberInvitation = () => {
  const { t } = useTranslation('hooksMember');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  const {
    mutate: cancelMemberInvitation,
    isLoading: cancelMemberInvitationLoading,
    data: cancelMemberInvitationResponse,
  } = useMutation({
    mutationFn: async (
      /** @type {{ serviceId: string, email: string }} */
      props,
    ) => {
      return await cancelMemberInvitationUsecase({
        serviceId: props.serviceId,
        email: props.email,
      });
    },
    onSuccess(data) {
      if (data.result === false) {
        addToast(data.message, { appearance: 'error' });
        return;
      }
      cache.invalidateQueries(['serviceUsers'], { type: 'all' });
      addToast(t('Invitation has been canceled.'), { appearance: 'success' });
    },
    onError() {
      addToast(t('An unexpected error has occurred.'), {
        appearance: 'error',
      });
    },
  });

  return [
    cancelMemberInvitation,
    cancelMemberInvitationLoading,
    cancelMemberInvitationResponse,
  ];
};

export const useDeleteMember = (serviceId) => {
  const cache = useQueryClient();
  const [result, setResult] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  // 結果はしばらくしたら消す;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setResult(undefined);
      setError(undefined);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [result, error]);

  const deleteMember = useCallback(
    (memberId) => {
      if (serviceId) {
        setLoading(true);
        API.graphql(
          graphqlOperation(queries.deleteMember, {
            serviceId,
            sub: memberId,
          }),
        )
          .then((result) => {
            setLoading(false);
            setResult(result);
            cache.invalidateQueries(['serviceUsers'], { type: 'all' });
          })
          .catch((error) => {
            setLoading(false);
            setError(error);
          });
      }
    },
    [cache, serviceId],
  );

  return [deleteMember, result, error, loading];
};
