import type { ContentValue } from '@/entity/content';
import type { Medium } from '@/entity/medium';
import { usePermissionIsHaveLeastOne } from '@/hooks/Role/useMyRoles';
import type { SelectItem } from '@/types/field';
import { CheckboxGroup } from '@/views/Common/Ui/CheckboxGroup';
import { DatePicker } from '@/views/Common/Ui/DatePicker';
import { Fieldset } from '@/views/Common/Ui/Fieldset';
import { Icon } from '@/views/Common/Ui/Icon';
import type { MediaFieldValue } from '@/views/InputField/MediaField';
import { MediaField } from '@/views/InputField/MediaField';
import type { MediaListFieldValue } from '@/views/InputField/MediaListField';
import { MediaListField } from '@/views/InputField/MediaListField';
import { NumberField } from '@/views/InputField/NumberField';
import { RichEditorV2 } from '@/views/InputField/RichEditorV2';
import { TextArea } from '@/views/InputField/TextArea/TextArea';
import { TextField } from '@/views/InputField/TextField';
import { useField } from '@/views/apis/content/useField';
import type { JSONContent } from '@tiptap/react';
import cx from 'classnames';
import dayjs from 'dayjs';
import type { PrimitiveAtom } from 'jotai';
import type React from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { optionTypes } from '../../constants/optionTypes';
import { richEditorV2Options } from '../../constants/richEditorOptions';
import type { Api, MigrateApi } from '../../entity/api';
import { useUserAttributes } from '../../hooks/Profile/useProfile';
import type { CustomField, Field } from '../../types/contents';
import { formatDatePicker } from '../../util/date';
import {
  Customfield,
  Filefield,
  Iframefield,
  RelationListSelect,
  RelationSelect,
  Repeaterfield,
  RichEditor,
} from '../Form';
import Linkify from '../Linkify';
import Selectfield from '../ui/Selectfield';
import Switch from '../ui/Switch';
import styles from './inputField.module.css';

type Props = {
  api: MigrateApi;
  field: Field;
  endpoint: string;
  customFields: CustomField[];
  contentAtom: PrimitiveAtom<ContentValue>;
  getContent: () => ContentValue;
  repeatCount?: number;
};

const InputField: React.FC<Props> = ({
  api,
  field,
  endpoint,
  customFields,
  contentAtom: parentContentAtom,
  getContent,
  repeatCount,
}) => {
  const { t } = useTranslation('inputField');
  const { userAttributes } = useUserAttributes();
  const [hasMediumCreatePermission] = usePermissionIsHaveLeastOne(
    'mediumPermission',
    'create',
    true,
  );

  const { value, onChange, contentAtomForCustomAndRepeater } = useField({
    parentContentAtom,
    field,
    getContent,
  });

  // ANKEN-1164 繰り返しフィールドの繰り返し数上限
  // 繰り返し数が設定されている場合はその数を、設定されていない場合は0をセットする
  const count = useMemo(() => (repeatCount ? repeatCount : 0), [repeatCount]);

  const onChangeText = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      onChange(target.value);
    },
    [onChange],
  );
  const onChangeTextArea = useCallback(
    ({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(target.value);
    },
    [onChange],
  );

  const onChangeDate = useCallback(
    (d: Date) => {
      onChange(d === null ? undefined : d);
    },
    [onChange],
  );

  const onChangeNumber = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      onChange(target.value === '' ? undefined : target.value);
    },
    [onChange],
  );

  const onChangeBoolean = useCallback(
    (value: boolean) => {
      onChange(value);
    },
    [onChange],
  );

  const onChangeMedium = useCallback(
    (value: MediaFieldValue) => {
      onChange({ mediumId: value.mediumId });
    },
    [onChange],
  );

  const onChangeMediumList = useCallback(
    (value: MediaListFieldValue) => {
      onChange(value);
    },
    [onChange],
  );

  const onChangeSingleSelect = useCallback(
    ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
      onChange(target.value !== '' ? [target.value] : []);
    },
    [onChange],
  );

  const onChangeMultipleSelect = useCallback(
    (payload: string) => () => {
      const values = value as string[] | undefined;
      onChange(
        values?.includes(payload)
          ? values.filter((v) => v !== payload)
          : [...(values ?? []), payload],
      );
    },
    [onChange, value],
  );

  const onChangeIframe = useCallback(
    (value: unknown) => {
      onChange(value);
    },
    [onChange],
  );

  const onChangeRichEditor = useCallback(
    (value: string) => {
      onChange(value);
    },
    [onChange],
  );

  const onChangeRichEditorV2 = useCallback(
    (value?: JSONContent) => {
      onChange(value);
    },
    [onChange],
  );

  const onChangeRelation = useCallback(
    (value: unknown) => {
      onChange(value);
    },
    [onChange],
  );

  const onChangeRelationList = useCallback(
    (value: unknown) => {
      onChange(value);
    },
    [onChange],
  );

  const removeValue = useCallback(() => onChange(undefined), [onChange]);

  // 既存のリッチエディタはrichEditorOptionsがnullになるので
  // nullの場合はすべてのオプションをつける
  const richEditorOptions =
    field.richEditorOptions === null ? optionTypes : field.richEditorOptions;

  // 設定されていないセレクトフィールドの値を削除する
  // APIスキーマ設定から選択肢削除時に起こりうるケース
  const resetInvalidSelectfieldValue = (
    selectItems: [SelectItem] | unknown,
  ) => {
    // selectItemsかvalueが配列でない場合、存在しないとみなして処理を終了する
    if (!Array.isArray(selectItems) || !Array.isArray(value)) return;

    // value内の要素の内、selectItemsに存在しないものを削除する
    const values = value as string[];
    values.forEach((val) => {
      if (!selectItems.some((item) => item.id === val)) {
        onChange(values.filter((v) => v !== val));
      }
    });
  };

  if (field.kind === 'select') {
    resetInvalidSelectfieldValue(field.selectItems);
  }

  return field.kind === 'text' ? (
    <TextField
      field={field}
      value={(value as string | undefined) ?? ''}
      onChange={onChangeText}
    />
  ) : field.kind === 'textArea' ? (
    <TextArea
      field={field}
      value={(value as string | undefined) ?? ''}
      onChange={onChangeTextArea}
    />
  ) : field.kind === 'richEditor' ? (
    <Fieldset
      label={field.name}
      description={
        field.description ? <Linkify>{field.description}</Linkify> : undefined
      }
      required={field.required}
    >
      <RichEditor
        onChange={onChangeRichEditor}
        richEditorOptions={richEditorOptions}
        defaultValue={value || {}}
      />
    </Fieldset>
  ) : field.kind === 'richEditorV2' ? (
    <Fieldset
      label={field.name}
      description={<Linkify>{field.description}</Linkify>}
      required={field.required}
    >
      <RichEditorV2
        onChange={onChangeRichEditorV2}
        defaultValue={value || {}}
        richEditorV2Options={
          field.richEditorV2Options === null ||
          field.richEditorV2Options === undefined
            ? richEditorV2Options
            : field.richEditorV2Options
        }
        customClassList={field.customClassList || []}
        colorList={field.richEditorV2ColorList || []}
      />
    </Fieldset>
  ) : field.kind === 'media' ? (
    <Fieldset
      label={field.name}
      description={
        field.description ? <Linkify>{field.description}</Linkify> : undefined
      }
      required={field.required}
    >
      <MediaField
        onChange={onChangeMedium}
        onDelete={removeValue}
        value={(value as MediaFieldValue) ?? null}
        field={field}
        hasMediumCreatePermission={hasMediumCreatePermission || false}
      />
    </Fieldset>
  ) : field.kind === 'mediaList' ? (
    <Fieldset
      label={field.name}
      description={
        field.description ? <Linkify>{field.description}</Linkify> : undefined
      }
      required={field.required}
    >
      <MediaListField
        onChange={onChangeMediumList}
        value={(value as MediaListFieldValue) ?? null}
        field={field}
        hasMediumCreatePermission={hasMediumCreatePermission || false}
      />
    </Fieldset>
  ) : field.kind === 'file' ? (
    <Fieldset
      label={field.name}
      description={
        field.description ? <Linkify>{field.description}</Linkify> : undefined
      }
      required={field.required}
    >
      <Filefield
        onChange={onChangeMedium}
        onDelete={removeValue}
        value={(value as Medium) || null}
      />
    </Fieldset>
  ) : field.kind === 'date' ? (
    <Fieldset
      label={field.name}
      description={
        field.description ? <Linkify>{field.description}</Linkify> : undefined
      }
      required={field.required}
    >
      <div className={styles.inputDateWrapper}>
        <DatePicker
          className={cx(styles.inputDate, styles.override)}
          reactDatePickerProps={{
            selected: value ? dayjs(value as string).toDate() : undefined,
            onChange: onChangeDate,
            showTimeSelect: !field.dateFormat,
            timeFormat: 'HH:mm',
            timeIntervals: 15,
            dateFormat: formatDatePicker({ dateFormat: !!field.dateFormat }),
            timeCaption: t('Time'),
          }}
        />
        {!!value && (
          <button
            onClick={removeValue}
            className={styles.inputDateRemoveButton}
          >
            <Icon name="clear" size="large" />
          </button>
        )}
      </div>
    </Fieldset>
  ) : field.kind === 'boolean' ? (
    <Fieldset
      label={field.name}
      description={
        field.description ? <Linkify>{field.description}</Linkify> : undefined
      }
      required={field.required}
    >
      <Switch
        on={(value as boolean) || false}
        onChange={onChangeBoolean}
        size="large"
      />
    </Fieldset>
  ) : field.kind === 'select' ? (
    field.multipleSelect ? (
      <CheckboxGroup
        label={field.name}
        description={
          field.description ? <Linkify>{field.description}</Linkify> : undefined
        }
        required={field.required}
        items={
          field.selectItems?.map((item) => ({
            value: item.id,
            label: item.value,
            checked: (value as string[])?.includes(item.id),
            onChange: onChangeMultipleSelect(item.id),
          })) ?? []
        }
        scrollable
      />
    ) : (
      <Fieldset
        label={field.name}
        description={
          field.description ? <Linkify>{field.description}</Linkify> : undefined
        }
        required={field.required}
      >
        <Selectfield
          value={(value as [string] | undefined)?.[0] || ''}
          style={{ width: 'auto' }}
          onChange={onChangeSingleSelect}
          placeholder={{
            value: '',
            name: t('Select'),
          }}
        >
          {field.selectItems?.map((item) => (
            <option key={item.id} value={item.id}>
              {item.value}
            </option>
          ))}
        </Selectfield>
      </Fieldset>
    )
  ) : field.kind === 'relation' ? (
    <Fieldset
      label={field.name}
      description={
        field.description ? <Linkify>{field.description}</Linkify> : undefined
      }
      required={field.required}
    >
      <RelationSelect
        onChange={onChangeRelation}
        referenceKey={field.referenceKey ?? ''}
        endpoint={endpoint}
        value={value}
        parentField={field}
      />
    </Fieldset>
  ) : field.kind === 'relationList' ? (
    <Fieldset
      label={field.name}
      description={
        field.description ? <Linkify>{field.description}</Linkify> : undefined
      }
      required={field.required}
    >
      <RelationListSelect
        onChange={onChangeRelationList}
        referenceKey={field.referenceKey ?? ''}
        endpoint={endpoint}
        value={value as unknown[]}
        parentField={field}
      />
    </Fieldset>
  ) : field.kind === 'number' ? (
    <NumberField
      field={field}
      value={(value as number | string | undefined) ?? ''}
      onChange={onChangeNumber}
    />
  ) : field.kind === 'iframe' ? (
    <Fieldset
      label={field.name}
      description={
        field.description ? <Linkify>{field.description}</Linkify> : undefined
      }
      required={field.required}
    >
      <Iframefield
        title={field.idValue}
        src={field.iframeUrl}
        value={value}
        onChange={onChangeIframe}
        email={userAttributes?.email}
      />
    </Fieldset>
  ) : field.kind === 'custom' ? (
    <Fieldset
      label={field.name}
      description={
        field.description ? <Linkify>{field.description}</Linkify> : undefined
      }
      required={field.required}
    >
      <Customfield
        api={api}
        contentAtom={
          contentAtomForCustomAndRepeater as PrimitiveAtom<ContentValue>
        }
        getContent={getContent}
        customFields={customFields}
        customFieldCreatedAt={field.customFieldCreatedAt ?? ''}
      />
    </Fieldset>
  ) : field.kind === 'repeater' ? (
    <Fieldset
      label={field.name}
      description={
        field.description ? <Linkify>{field.description}</Linkify> : undefined
      }
      required={field.required}
    >
      <Repeaterfield
        api={api}
        parentField={field}
        contentAtom={
          contentAtomForCustomAndRepeater as PrimitiveAtom<unknown[]>
        }
        getContent={getContent}
        customFields={customFields}
        repeatCount={count}
      />
    </Fieldset>
  ) : (
    <></>
  );
};

export default InputField;
