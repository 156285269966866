import i18n from '../i18n';

import type { FieldKind } from '@/types/field';

export type FieldType = {
  id: FieldKind;
  name: string;
  icon: string;
  description: string;
  can_use_in_custom?: boolean;
  restrictionKey?: string;
  isDeprecated?: boolean;
};

export const useFieldTypes = (): Record<string, FieldType> => {
  return {
    text: {
      id: 'text',
      name: i18n.t('Text Field', { ns: 'constants' }),
      icon: 'title',
      description: i18n.t(
        'One line of input text which is suitable for titles or others.',
        { ns: 'constants' },
      ),
    },
    textArea: {
      id: 'textArea',
      name: i18n.t('Text Area', { ns: 'constants' }),
      icon: 'notes',
      description: i18n.t('A multi-line text input area for plain text.', {
        ns: 'constants',
      }),
    },

    richEditorV2: {
      id: 'richEditorV2',
      name: i18n.t('Rich Text Editor', { ns: 'constants' }),
      icon: 'text_fields',
      description: i18n.t(
        'In addition to the Old Rich text editor features, tables, horizontal rule, etc. are available.',
        { ns: 'constants' },
      ),
    },

    richEditor: {
      id: 'richEditor',
      name: i18n.t('Old Rich Text Editor', { ns: 'constants' }),
      icon: 'text_fields',
      description: i18n.t(
        'Rich text editor is for editing multiple lines of text in free format. The content can be retrieved in HTML format from the API.',
        { ns: 'constants' },
      ),
      isDeprecated: true,
    },
    media: {
      id: 'media',
      name: i18n.t('Image', { ns: 'constants' }),
      icon: 'insert_photo',
      description: i18n.t(
        'Image type field. An image URL is returned from API.',
        { ns: 'constants' },
      ),
    },
    mediaList: {
      id: 'mediaList',
      name: i18n.t('Multiple Images', { ns: 'constants' }),
      icon: 'collections',
      description: i18n.t(
        'Multiple images type field. Multiple images URL is returned from API.',
        {
          ns: 'constants',
        },
      ),
    },
    date: {
      id: 'date',
      name: i18n.t('Date', { ns: 'constants' }),
      icon: 'date_range',
      description: i18n.t(
        'Date type field. Date and time can be selected with a calendar.',
        { ns: 'constants' },
      ),
    },
    boolean: {
      id: 'boolean',
      name: i18n.t('Boolean', { ns: 'constants' }),
      icon: 'toggle_on',
      description: i18n.t(
        'Boolean type field which can be turned on/off with a switch.',
        { ns: 'constants' },
      ),
    },
    select: {
      id: 'select',
      name: i18n.t('Selectable Field', { ns: 'constants' }),
      icon: 'list',
      description: i18n.t(
        'This field allows you to select a value from a defined list. Multiple selections can also be set.',
        { ns: 'constants' },
      ),
    },
    relation: {
      id: 'relation',
      name: i18n.t('Content Reference', { ns: 'constants' }),
      icon: 'settings_ethernet',
      description: i18n.t(
        'This field can include responses from other content. If the reference is a list type, this will become selectable.',
        { ns: 'constants' },
      ),
    },
    relationList: {
      id: 'relationList',
      name: i18n.t('Multiple Content References', { ns: 'constants' }),
      icon: 'settings_ethernet',
      description: i18n.t(
        'Multiple references to other contents are possible. The response will be in array format.',
        { ns: 'constants' },
      ),
    },
    number: {
      id: 'number',
      name: i18n.t('Number', { ns: 'constants' }),
      icon: 'looks_one',
      description: i18n.t(
        'Number type field. A keyboard of the number type is provided for input.',
        { ns: 'constants' },
      ),
    },
    custom: {
      id: 'custom',
      name: i18n.t('Custom', { ns: 'constants' }),
      icon: 'vertical_split',
      description: i18n.t(
        'Custom Fields. Pre-configured Custom fields can be used for input.',
        { ns: 'constants' },
      ),
      can_use_in_custom: false,
    },
    repeater: {
      id: 'repeater',
      name: i18n.t('Repeat', { ns: 'constants' }),
      icon: 'view_agenda',
      description: i18n.t(
        'Multiple Custom fields can be selected and repeated',
        {
          ns: 'constants',
        },
      ),
    },
    file: {
      id: 'file',
      name: i18n.t('File', { ns: 'constants' }),
      icon: 'insert_drive_file',
      description: i18n.t('File type filed. A file URL is returned from API.', {
        ns: 'constants',
      }),
      restrictionKey: 'fileUpload',
    },
    iframe: {
      id: 'iframe',
      name: i18n.t('Extended Field', { ns: 'constants' }),
      icon: 'extension',
      description: i18n.t('Extended fields can read external data', {
        ns: 'constants',
      }),
      restrictionKey: 'iframe',
    },
  };
};
