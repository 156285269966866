import type React from 'react';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { myRolesContext } from '../../hooks/Role/useMyRoles';
import PolicySetting from '../PolicySetting';
import Fieldset from '../ui/Fieldset';
import Selectfield from '../ui/Selectfield';

import type { ApiList } from '../../entity/api';
import type { ApiKeyPriorityPolicy } from '../../types/apiKey';

type Props = {
  apiList: ApiList;
  priorityPolicy: ApiKeyPriorityPolicy;
  setPriorityPolicy: (priorityPolicy: ApiKeyPriorityPolicy) => void;
};

const PriorityPolicy: React.FC<Props> = ({
  apiList,
  priorityPolicy,
  setPriorityPolicy,
}) => {
  const { t } = useTranslation('priorityPolicy');
  const context = useContext(myRolesContext);
  const isAdmin = useMemo(() => {
    return context.roles
      ? context.roles.some((role) => role.isAdmin === true)
      : undefined;
  }, [context.roles]);

  return (
    <Fieldset
      legend={t('Target API')}
      description={t('Select an API and customize permissions')}
    >
      <Selectfield
        name="apiId"
        size="full"
        onChange={(e) =>
          setPriorityPolicy({
            apiId: e.target.value,
            policy: priorityPolicy.policy,
          })
        }
        value={priorityPolicy.apiId}
        disabled={!isAdmin}
      >
        {apiList &&
          apiList.list.map((api) => (
            <option key={api.partitionKey} value={api.partitionKey}>
              {api.apiName}
            </option>
          ))}
      </Selectfield>
      <PolicySetting
        defaultPolicy={priorityPolicy.policy}
        setPolicy={(policy) =>
          setPriorityPolicy({ apiId: priorityPolicy.apiId, policy })
        }
      />
    </Fieldset>
  );
};

export default PriorityPolicy;
