import { findIndexECPs } from '../../../helpers/findIndexExceptionContentPermissions';

import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from '../_types';
import type { EditableInputExceptionContentPermission } from '@/entity/role';

import { produce } from '@/lib/immer';

type SetECPermissionReviewRequestCreateAction = FormRoleInputValueAction<
  'set-ec-permission-reviewrequestcreate',
  {
    id: EditableInputExceptionContentPermission['id'];
    nextValue: EditableInputExceptionContentPermission['reviewRequestPermission']['create'];
  }
>;
type SetECPermissionReviewRequestReadAction = FormRoleInputValueAction<
  'set-ec-permission-reviewrequestread',
  {
    id: EditableInputExceptionContentPermission['id'];
    nextValue: EditableInputExceptionContentPermission['reviewRequestPermission']['readLevel'];
  }
>;
type SetECPermissionReviewRequestUpdateAction = FormRoleInputValueAction<
  'set-ec-permission-reviewrequestupdate',
  {
    id: EditableInputExceptionContentPermission['id'];
    nextValue: EditableInputExceptionContentPermission['reviewRequestPermission']['updateLevel'];
  }
>;

/**
 * 個別権限の指定したIDの要素にあるreviewRequestPermission.createを更新する
 */
const setECPermissionReviewRequestCreate: FormRoleInputValueDispatchFn<
  SetECPermissionReviewRequestCreateAction
> = (currentState, { id, nextValue }) => {
  return produce(currentState, (draft) => {
    const index = findIndexECPs(draft.exceptionContentPermissions, id);
    if (index >= 0) {
      const perm =
        draft.exceptionContentPermissions[index].reviewRequestPermission;
      if (perm) perm.create = nextValue;
    }
  });
};

/**
 * 個別権限の指定したIDの要素にあるreviewRequestPermission.readLevelを更新する
 */
const setECPermissionReviewRequestRead: FormRoleInputValueDispatchFn<
  SetECPermissionReviewRequestReadAction
> = (currentState, { id, nextValue }) => {
  return produce(currentState, (draft) => {
    const index = findIndexECPs(draft.exceptionContentPermissions, id);
    if (index >= 0) {
      const perm =
        draft.exceptionContentPermissions[index].reviewRequestPermission;
      if (perm) perm.readLevel = nextValue;
    }
  });
};

/**
 * 個別権限の指定したIDの要素にあるreviewRequestPermission.updateLevelを更新する
 */
const setECPermissionReviewRequestUpdate: FormRoleInputValueDispatchFn<
  SetECPermissionReviewRequestUpdateAction
> = (currentState, { id, nextValue }) => {
  return produce(currentState, (draft) => {
    const index = findIndexECPs(draft.exceptionContentPermissions, id);
    if (index >= 0) {
      const perm =
        draft.exceptionContentPermissions[index].reviewRequestPermission;
      if (perm) perm.updateLevel = nextValue;
    }
  });
};

type ExceptionContentPermissionReviewRequestAction =
  | SetECPermissionReviewRequestCreateAction
  | SetECPermissionReviewRequestReadAction
  | SetECPermissionReviewRequestUpdateAction;

export {
  type ExceptionContentPermissionReviewRequestAction,
  setECPermissionReviewRequestCreate,
  setECPermissionReviewRequestRead,
  setECPermissionReviewRequestUpdate,
};
