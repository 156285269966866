import cx from 'classnames';

import styles from './highlight.module.css';

type Props = {
  type: 'removed' | 'added';
  children: React.ReactNode;
};

const Highlight: React.FC<Props> = ({ type, children }) => {
  return (
    <span
      className={cx(
        styles.main,
        type === 'removed' && styles.removed,
        type === 'added' && styles.added,
      )}
    >
      {children}
    </span>
  );
};

export { Highlight };
