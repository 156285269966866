import { findIndexECPs } from '../../../../helpers/findIndexExceptionContentPermissions';

import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from '../../_types';
import type { EditableInputExceptionContentPermission } from '@/entity/role';

import { produce } from '@/lib/immer';

type ContentPermission = EditableInputExceptionContentPermission['permission'];

type SetECPermissionContentCreateAction<
  K extends
    keyof ContentPermission['contentCreate'] = keyof ContentPermission['contentCreate'],
  V extends
    ContentPermission['contentCreate'][K] = ContentPermission['contentCreate'][K],
> = FormRoleInputValueAction<
  'set-ec-permission-contentcreate',
  {
    id: EditableInputExceptionContentPermission['id'];
    key: K;
    nextValue: V;
  }
>;
type SetECPermissionContentReadAction = FormRoleInputValueAction<
  'set-ec-permission-contentread',
  {
    id: EditableInputExceptionContentPermission['id'];
    nextValue: EditableInputExceptionContentPermission['permission']['contentReadLevel'];
  }
>;
type SetECPermissionContentUpdateAction<
  K extends
    keyof ContentPermission['contentUpdate'] = keyof ContentPermission['contentUpdate'],
  V extends
    ContentPermission['contentUpdate'][K] = ContentPermission['contentUpdate'][K],
> = FormRoleInputValueAction<
  'set-ec-permission-contentupdate',
  {
    id: EditableInputExceptionContentPermission['id'];
    key: K;
    nextValue: V;
  }
>;
type SetECPermissionContentDeleteAction = FormRoleInputValueAction<
  'set-ec-permission-contentdelete',
  {
    id: EditableInputExceptionContentPermission['id'];
    nextValue: EditableInputExceptionContentPermission['permission']['contentDelete'];
  }
>;

/**
 * 個別権限の指定したIDの要素にあるpermission.contentCreateを更新する
 */
const setECPermissionContentCreate: FormRoleInputValueDispatchFn<
  SetECPermissionContentCreateAction
> = (currentState, { id, key, nextValue }) => {
  return produce(currentState, (draft) => {
    const index = findIndexECPs(draft.exceptionContentPermissions, id);
    if (index >= 0) {
      const perm = draft.exceptionContentPermissions[index].permission;
      if (perm) perm.contentCreate[key] = nextValue;
    }
  });
};

/**
 * 個別権限の指定したIDの要素にあるpermission.contentReadLevelを更新する
 */
const setECPermissionContentRead: FormRoleInputValueDispatchFn<
  SetECPermissionContentReadAction
> = (currentState, { id, nextValue }) => {
  return produce(currentState, (draft) => {
    const index = findIndexECPs(draft.exceptionContentPermissions, id);
    if (index >= 0) {
      const perm = draft.exceptionContentPermissions[index].permission;
      if (perm) perm.contentReadLevel = nextValue;
    }
  });
};

/**
 * 個別権限の指定したIDの要素にあるpermission.contentUpdateを更新する
 */
const setECPermissionContentUpdate: FormRoleInputValueDispatchFn<
  SetECPermissionContentUpdateAction
> = (currentState, { id, key, nextValue }) => {
  return produce(currentState, (draft) => {
    const index = findIndexECPs(draft.exceptionContentPermissions, id);
    if (index >= 0) {
      const perm = draft.exceptionContentPermissions[index].permission;
      if (perm) perm.contentUpdate[key] = nextValue;
    }
  });
};

/**
 * 個別権限の指定したIDの要素にあるpermission.contentDeleteを更新する
 */
const setECPermissionContentDelete: FormRoleInputValueDispatchFn<
  SetECPermissionContentDeleteAction
> = (currentState, { id, nextValue }) => {
  return produce(currentState, (draft) => {
    const index = findIndexECPs(draft.exceptionContentPermissions, id);
    if (index >= 0) {
      const perm = draft.exceptionContentPermissions[index].permission;
      if (perm) perm.contentDelete = nextValue;
    }
  });
};

export {
  type SetECPermissionContentCreateAction,
  type SetECPermissionContentReadAction,
  type SetECPermissionContentUpdateAction,
  type SetECPermissionContentDeleteAction,
  setECPermissionContentCreate,
  setECPermissionContentRead,
  setECPermissionContentUpdate,
  setECPermissionContentDelete,
};
