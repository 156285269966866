import type { OptionsField } from '@/entity/iframely';

export const convertOptionsToQuery = (
  optionValue?: Record<string, OptionsField['value']>,
): { optionQuery: string } => {
  if (!optionValue) {
    return { optionQuery: '' };
  }
  const keyValuePairs = Object.entries(optionValue).map(([key, value]) => {
    if (!key || !value) {
      return;
    }
    return `${key}=${value}`;
  });

  return {
    optionQuery: `&${keyValuePairs.filter((v) => v !== undefined).join('&')}`,
  };
};

export const convertInputOptions = (
  options?: Record<string, OptionsField>,
): Record<string, string | boolean | number> | undefined => {
  if (!options) {
    return;
  }
  const entries = Object.entries(options).map(([key, optionField]) => [
    key,
    optionField.value,
  ]);
  const convertedOptions = Object.fromEntries(entries);

  return convertedOptions;
};
