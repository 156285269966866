import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useBlockquote } from './useBlockquote';
import { IconButton } from '../../../IconButton';
import styles from '../../../richeditorv2.module.css';

import type { Editor } from '@tiptap/react';

import { BlockquoteIcon } from '@/views/Common/Icons';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  toggleBlockquoteHandler: () => void;
  isActiveBlockquote: boolean;
  editable: boolean;
};

export const BlockquoteView: React.FC<ViewProps> = ({
  toggleBlockquoteHandler,
  isActiveBlockquote,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <IconButton
      icon={
        <BlockquoteIcon
          className={cx(styles.icon, {
            [styles.isActive]: isActiveBlockquote,
          })}
        />
      }
      aria-label={t('Blockquote')}
      isActive={isActiveBlockquote}
      onClick={toggleBlockquoteHandler}
      disabled={!editable}
    />
  );
};

export const Blockquote: React.FC<Props> = ({ editor }) => {
  const { toggleBlockquoteHandler, isActiveBlockquote } = useBlockquote({
    editor,
  });

  return (
    <BlockquoteView
      toggleBlockquoteHandler={toggleBlockquoteHandler}
      isActiveBlockquote={isActiveBlockquote}
      editable={editor.isEditable}
    />
  );
};
