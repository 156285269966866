import cx from 'classnames';
import { useState, useCallback } from 'react';

import type { MediumTag } from '@/types/MediumTag';

import styles from './tagsRow.module.css';

type Props = {
  tagName: MediumTag['name'];
  searchTags: MediumTag[];
  onChangeTags: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleTagSubmit: (
    e: React.MouseEvent<HTMLButtonElement>,
    value: string,
  ) => void;
};

export const TagsRow: React.FC<Props> = ({
  tagName,
  searchTags,
  onChangeTags,
  handleTagSubmit,
}) => {
  const [focus, setFocus] = useState<number>(0);
  const handleFocus = useCallback((index: number) => {
    setFocus(index);
  }, []);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOpenTags = useCallback(() => {
    setFocus(-1);
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <div className={styles.tagContainer}>
      <input
        type="text"
        className={styles.tagField}
        onClick={handleOpenTags}
        onFocus={handleOpenTags}
        value={tagName}
        onChange={onChangeTags}
      />
      {isOpen && (
        <div>
          <div className={styles.mask} onClick={handleOpenTags} />
          <ul className={styles.tagList}>
            {searchTags.map((tag, index: number) => {
              // TODO:ここのフォーカス管理は本来上下矢印keyで移動できるようにするべき
              const isLast = index === searchTags.length - 1;
              return (
                <li key={tag.mediumTagId} className={styles.tagItem}>
                  <button
                    className={styles.tagButton}
                    onClick={(e) => {
                      handleTagSubmit(e, tag.mediumTagId);
                      handleOpenTags();
                    }}
                    onFocus={() => handleFocus(index)}
                    // タブで移動するしていると開いたままになってしまうので、最後の要素で閉じる
                    // TODO:本来はesc}キーで閉じるなどのfocusTrap処理が必要
                    onBlur={isLast ? handleOpenTags : undefined}
                  >
                    {tag.name}
                  </button>
                  {tag.name === tagName && (
                    <i
                      className={cx(
                        'material-icons-outlined',
                        styles.isSelectedIcon,
                        {
                          [styles.focus]: focus === index,
                        },
                      )}
                    >
                      check
                    </i>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};
