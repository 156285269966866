import { connect } from 'react-redux';

import { authOperations, authSelectors } from '../../ducks/auth';
import ForgotPassword from './ForgotPassword';

const mapStateToProps = (state: any) => {
  return authSelectors.getForgotPasswordState(state.authState);
};

const mapDispatchToProps = {
  reset: authOperations.resetAuth,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
