import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Expander from '../../../Expander';
import Button from '../../../ui/Button';
import Switch from '../../../ui/Switch';
import Textfield from '../../../ui/Textfield';
import styles from '../webhookSettings.module.css';

import type { WebhookEvents } from '../../../../types/webhook';
import type {
  CreateNewWebhookSetting,
  CreateNewWebhookSettingForGithubActions,
  WebhookSetting,
  WebhookSettingForGithubActions,
} from '@/entity/webhookSettings';

import { useInput } from '@/views/Common/useInput';
import { useInputWebhookEvents } from '@/views/WebhookSettings/helpers/useInputWebehookEvents';

type Props = {
  setting:
    | WebhookSettingForGithubActions
    | CreateNewWebhookSettingForGithubActions;
  settings: Array<WebhookSetting | CreateNewWebhookSetting>;
  createSetting: any;
  updateSetting: any;
  deleteSetting: any;
  loading: boolean;
  updatable?: boolean;
  onChangeEnabled: (setting: any) => void;
  updateWebhookEnableLoading: boolean;
  webhookEvents: WebhookEvents[];
};

const GitHubActionsWebhook: React.FC<Props> = ({
  setting,
  settings,
  createSetting,
  updateSetting,
  deleteSetting,
  loading,
  updatable,
  onChangeEnabled,
  updateWebhookEnableLoading,
  webhookEvents,
}) => {
  const { t } = useTranslation('webhooks');
  const isNew = useMemo(
    () => setting && setting.apiId === undefined,
    [setting],
  );

  const [name, onChangeName, nameError] = useInput(
    (setting && setting.settingValues && setting.settingValues.name) || '',
    () => null,
    true,
  );
  const [token, onChangeToken, tokenError] = useInput(
    (setting && setting.settingValues && setting.settingValues.token) || '',
    () => null,
    true,
  );
  const [repoUser, onChangeRepoUser, repoUserError] = useInput(
    (setting && setting.settingValues && setting.settingValues.repoUser) || '',
    () => null,
    true,
  );
  const [repoName, onChangeRepoName, repoNameError] = useInput(
    (setting && setting.settingValues && setting.settingValues.repoName) || '',
    () => null,
    true,
  );
  const [eventType, onChangeEventType, eventTypeError] = useInput(
    (setting && setting.settingValues && setting.settingValues.eventType) || '',
    () => null,
    true,
  );

  const [events, onChangeEvents] = useInputWebhookEvents(setting.handleEvents);

  const remove = useCallback(() => {
    deleteSetting(setting);
  }, [deleteSetting, setting]);

  const submit = useCallback(() => {
    if (isNew) {
      createSetting(
        'GITHUB_ACTIONS',
        {
          name,
          token,
          repoUser,
          repoName,
          eventType,
        },
        events.join(',').split(','),
      );
    } else {
      updateSetting(
        setting,
        {
          name: name || null,
          repoUser: repoUser || null,
          repoName: repoName || null,
          eventType: eventType || null,
          // バックエンドから***といった形で渡ってくるのでそのままであれば変更がないと判断する
          ...(/^\**$/.test(token) ? {} : { token }),
        },
        events.join(',').split(','),
      );
    }
  }, [
    isNew,
    createSetting,
    name,
    token,
    repoUser,
    repoName,
    eventType,
    events,
    updateSetting,
    setting,
  ]);

  if (setting === undefined) {
    return null;
  }

  return (
    <Expander
      title={
        <div className={styles.title}>
          <img className={styles.icon} src="/images/icon_github.png" alt="" />
          <span className={styles.name}>GitHub Actions</span>
          {setting && setting.settingValues && setting.settingValues.name && (
            <span className={styles.webhookName}>
              {setting.settingValues.name}
            </span>
          )}
          <Switch
            className={styles.switch}
            on={
              setting.enabled === null
                ? true
                : typeof setting.enabled === 'undefined'
                  ? true
                  : setting.enabled
            }
            onChange={() => onChangeEnabled(setting)}
            onClick={(e) => e.stopPropagation()}
            disabled={
              updateWebhookEnableLoading ||
              settings.length !==
                settings.filter((setting: any) => setting.apiId !== undefined)
                  .length /* 作成中を含むwebhook要素数と作成ずみのwebhookの数を比較　*/
            }
          />
        </div>
      }
      defaultOpen={isNew}
    >
      <div className={styles.sectionWrapper}>
        <p className={styles.timingGroupLabel}>{t('Basic Settings')}</p>
        <p className={styles.description}>
          {t('Set Webhook name which can be identified. (Optional)')}
        </p>
        <Textfield
          type="text"
          placeholder={t('Webhook Name')}
          defaultValue={name}
          onChange={onChangeName}
          errorText={nameError}
        />
        <p className={styles.description}>{t('Set up a GitHub token.')}</p>
        <Textfield
          type="text"
          placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
          defaultValue={token}
          onChange={onChangeToken}
          errorText={tokenError}
          readOnly={!updatable}
        />
        <p className={styles.description}>
          {t(
            'Enter your repository username. Usually, this is the :user in https://github.com/:user/:repo',
          )}
        </p>
        <Textfield
          type="text"
          placeholder={t('Username')}
          defaultValue={repoUser}
          onChange={onChangeRepoUser}
          errorText={repoUserError}
          readOnly={!updatable}
        />
        <p className={styles.description}>
          {t(
            'Enter your repository name. Usually, this is the :repo in https://github.com/:user/:repo',
          )}
        </p>
        <Textfield
          type="text"
          placeholder={t('Repository Name')}
          defaultValue={repoName}
          onChange={onChangeRepoName}
          errorText={repoNameError}
          readOnly={!updatable}
        />
        <p className={styles.description}>
          {t(
            'Specify name of the trigger event. This Webhook supports repository_dispatch.',
          )}
        </p>
        <Textfield
          type="text"
          placeholder={t('Trigger Event Name')}
          defaultValue={eventType}
          onChange={onChangeEventType}
          errorText={eventTypeError}
          readOnly={!updatable}
        />
      </div>
      <div className={styles.sectionWrapper}>
        <p className={styles.timingGroupLabel}>
          {t('Notification timing settings')}
        </p>
        {webhookEvents.map(({ label, values }, i) => (
          <div className={styles.section} key={i}>
            <p className={styles.description}>{label}</p>
            <div className={styles.checkboxes}>
              {values.map(
                ({ label, value }, i) =>
                  label &&
                  value && (
                    <div className={styles.timingDetailLabel} key={i}>
                      <label className={styles.checkbox}>
                        <input
                          type="checkbox"
                          value={value}
                          checked={events.includes(value)}
                          onChange={onChangeEvents}
                          disabled={!updatable}
                        ></input>
                        {label}
                      </label>
                    </div>
                  ),
              )}
            </div>
          </div>
        ))}
        {updatable && (
          <div className={styles.actions}>
            <Button
              type="primary"
              className="ga-api-settings-webhook-github-actions"
              disabled={
                loading ||
                !token ||
                !repoUser ||
                !repoName ||
                !eventType ||
                events.length === 0
              }
              value={isNew ? t('Setup') : t('Save changes')}
              onClick={submit}
            />
            {!isNew && (
              <Button
                type="danger"
                disabled={loading}
                value={t('Delete')}
                onClick={remove}
              />
            )}
          </div>
        )}
      </div>
    </Expander>
  );
};

export default GitHubActionsWebhook;
