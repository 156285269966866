import { useCallback, useMemo } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { useExceptionPermissionIsHaveLeastOne } from '@/hooks/Role/useMyRoles';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';

import Head from '../../Head';
import Button from '../../ui/Button';
import Modal from '../../ui/Modal';
import AmplifyWebhook from './Webhooks/AmplifyWebhook';
import ChatworkWebhook from './Webhooks/ChatworkWebhook';
import CloudflarePagesWebhook from './Webhooks/CloudflarePagesWebhook';
import CustomWebhook from './Webhooks/CustomWebhook';
import EmailWebhook from './Webhooks/EmailWebhook';
import GitHubActionsWebhook from './Webhooks/GitHubActionsWebhook';
import NetlifyWebhook from './Webhooks/NetlifyWebhook';
import SlackWebhook from './Webhooks/SlackWebhook';
import VercelWebhook from './Webhooks/VercelWebhook';

import type { ApiList, MigrateApi } from '@/entity/api';
import type {
  WebhookSetting,
  WebhookSettingTargetIdentifier,
} from '@/entity/webhookSettings';

import styles from './webhookSettings.module.css';

import { WebhookSettingTarget } from '@/constants/webhookSettings';
import { apiListSelectors } from '@/ducks/apiList';
import { useAppSelector } from '@/store/hooks';
import { isWebhookEnabled } from '@/util/webhookSetting';
import { useReadWebhookSettings } from '@/views/WebhookSettings/helpers/useReadWebhookSettings';
import { useWebhookEvents } from '@/views/WebhookSettings/helpers/useWebhookEvents';
import {
  useCreateWebhookSetting,
  useDeleteWebhookSetting,
  useUpdateWebhookEnabledSetting,
  useUpdateWebhookSetting,
} from '@/views/WebhookSettings/helpers/useWriteWebhookSettings';

const WebhookSettings: React.FC = () => {
  const { t } = useTranslation('webhookSettings');
  const { service } = useGetMyService();

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint } = useParams<{ endpoint: string }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );

  const { currentPlan } = useStripeActions(service);
  const webhookEvents = useWebhookEvents(currentPlan);
  const [updatable] = useExceptionPermissionIsHaveLeastOne(
    api.partitionKey,
    'apiUpdate',
  );

  const { partitionKey: apiId, gsiPartitionKey2: serviceId } = api;
  const [WebhookModal, openWebhookModal, closeWebhookModal] = useModal('root');
  const [settings, allSettingsCount, addNewSetting] = useReadWebhookSettings(
    serviceId,
    apiId,
  );
  const [createWebhookSetting, isLoadingCreate] = useCreateWebhookSetting(
    serviceId,
    apiId,
  );
  const [updateWebhookSetting, isLoadingUpdate] = useUpdateWebhookSetting(
    serviceId,
    apiId,
  );
  const [deleteWebhookSetting, isLoadingDelete] = useDeleteWebhookSetting(
    serviceId,
    apiId,
  );
  const [updateWebhookEnable, updateWebhookEnableLoading] =
    useUpdateWebhookEnabledSetting(serviceId, apiId);

  const isOverLimit = useMemo(() => {
    return (
      currentPlan &&
      currentPlan.limit.webhookCount !== null &&
      currentPlan.limit.webhookCount !== undefined &&
      currentPlan.limit.webhookCount <= allSettingsCount
    );
  }, [allSettingsCount, currentPlan]);

  const isLoading = useMemo(() => {
    return isLoadingCreate || isLoadingUpdate || isLoadingDelete;
  }, [isLoadingCreate, isLoadingUpdate, isLoadingDelete]);

  const addNewWebhookSetting = useCallback(
    (target: WebhookSettingTargetIdentifier) => {
      closeWebhookModal();
      addNewSetting(target);
    },
    [closeWebhookModal, addNewSetting],
  );

  const createSetting = useCallback(
    (
      target: WebhookSetting['target'],
      values: WebhookSetting['settingValues'],
      events: WebhookSetting['handleEvents'],
    ) => {
      createWebhookSetting({
        target,
        values,
        events,
      });
    },
    [createWebhookSetting],
  );

  const updateSetting = useCallback(
    (
      current: WebhookSetting,
      values: WebhookSetting['settingValues'],
      events: WebhookSetting['handleEvents'],
    ) => {
      updateWebhookSetting({ current, values, events });
    },
    [updateWebhookSetting],
  );

  const deleteSetting = useCallback(
    (current: WebhookSetting) => {
      deleteWebhookSetting({ current });
    },
    [deleteWebhookSetting],
  );

  const onChangeWebhookEnabled = useCallback(
    (setting: any) => {
      if (isWebhookEnabled(setting.enabled)) {
        updateWebhookEnable({ current: setting, enabled: false });
      } else {
        updateWebhookEnable({ current: setting, enabled: true });
      }
    },
    [updateWebhookEnable],
  );

  return (
    <div>
      <Head title={t('API/Webhooks')} />
      <h2 className={styles.pageTitle}>{t('Webhooks')}</h2>
      <p className={styles.description}>
        {t('Webhook is triggered when a content or an API setting is changed.')}
      </p>

      <ul>
        {settings.map((setting, i) => {
          // NOTE: まだ登録していない追加中の設定があることを考慮
          const key = (setting.createdAt || '') + i;

          return (
            <li className={styles.list} key={key}>
              <div className={styles.webhookTarget}>
                {setting.target === WebhookSettingTarget.SLACK && (
                  <div className={styles.webhookTarget}>
                    <SlackWebhook
                      setting={setting}
                      settings={settings}
                      createSetting={createSetting}
                      updateSetting={updateSetting}
                      deleteSetting={deleteSetting}
                      loading={isLoading}
                      updatable={updatable}
                      onChangeEnabled={onChangeWebhookEnabled}
                      updateWebhookEnableLoading={updateWebhookEnableLoading}
                      webhookEvents={webhookEvents}
                    />
                  </div>
                )}

                {setting.target === WebhookSettingTarget.CHATWORK && (
                  <div className={styles.webhookTarget}>
                    <ChatworkWebhook
                      setting={setting}
                      settings={settings}
                      createSetting={createSetting}
                      updateSetting={updateSetting}
                      deleteSetting={deleteSetting}
                      loading={isLoading}
                      updatable={updatable}
                      onChangeEnabled={onChangeWebhookEnabled}
                      updateWebhookEnableLoading={updateWebhookEnableLoading}
                      webhookEvents={webhookEvents}
                    />
                  </div>
                )}

                {setting.target === WebhookSettingTarget.NETLIFY && (
                  <div className={styles.webhookTarget}>
                    <NetlifyWebhook
                      setting={setting}
                      settings={settings}
                      createSetting={createSetting}
                      updateSetting={updateSetting}
                      deleteSetting={deleteSetting}
                      loading={isLoading}
                      updatable={updatable}
                      onChangeEnabled={onChangeWebhookEnabled}
                      updateWebhookEnableLoading={updateWebhookEnableLoading}
                      webhookEvents={webhookEvents}
                    />
                  </div>
                )}

                {setting.target === WebhookSettingTarget.CLOUDFLARE_PAGES && (
                  <div className={styles.webhookTarget}>
                    <CloudflarePagesWebhook
                      setting={setting}
                      settings={settings}
                      createSetting={createSetting}
                      updateSetting={updateSetting}
                      deleteSetting={deleteSetting}
                      loading={isLoading}
                      updatable={updatable}
                      onChangeEnabled={onChangeWebhookEnabled}
                      updateWebhookEnableLoading={updateWebhookEnableLoading}
                      webhookEvents={webhookEvents}
                    />
                  </div>
                )}

                {setting.target === WebhookSettingTarget.VERCEL && (
                  <div className={styles.webhookTarget}>
                    <VercelWebhook
                      setting={setting}
                      settings={settings}
                      createSetting={createSetting}
                      updateSetting={updateSetting}
                      deleteSetting={deleteSetting}
                      loading={isLoading}
                      updatable={updatable}
                      onChangeEnabled={onChangeWebhookEnabled}
                      updateWebhookEnableLoading={updateWebhookEnableLoading}
                      webhookEvents={webhookEvents}
                    />
                  </div>
                )}

                {setting.target === WebhookSettingTarget.AMPLIFY && (
                  <div className={styles.webhookTarget}>
                    <AmplifyWebhook
                      setting={setting}
                      settings={settings}
                      createSetting={createSetting}
                      updateSetting={updateSetting}
                      deleteSetting={deleteSetting}
                      loading={isLoading}
                      updatable={updatable}
                      onChangeEnabled={onChangeWebhookEnabled}
                      updateWebhookEnableLoading={updateWebhookEnableLoading}
                      webhookEvents={webhookEvents}
                    />
                  </div>
                )}

                {setting.target === WebhookSettingTarget.GITHUB_ACTIONS && (
                  <div className={styles.webhookTarget}>
                    <GitHubActionsWebhook
                      setting={setting}
                      settings={settings}
                      createSetting={createSetting}
                      updateSetting={updateSetting}
                      deleteSetting={deleteSetting}
                      loading={isLoading}
                      updatable={updatable}
                      onChangeEnabled={onChangeWebhookEnabled}
                      updateWebhookEnableLoading={updateWebhookEnableLoading}
                      webhookEvents={webhookEvents}
                    />
                  </div>
                )}

                {setting.target === WebhookSettingTarget.EMAIL && (
                  <div className={styles.webhookTarget}>
                    <EmailWebhook
                      setting={setting}
                      settings={settings}
                      createSetting={createSetting}
                      updateSetting={updateSetting}
                      deleteSetting={deleteSetting}
                      loading={isLoading}
                      updatable={updatable}
                      onChangeEnabled={onChangeWebhookEnabled}
                      updateWebhookEnableLoading={updateWebhookEnableLoading}
                      webhookEvents={webhookEvents}
                    />
                  </div>
                )}

                {setting.target === WebhookSettingTarget.CUSTOM && (
                  <div className={styles.webhookTarget}>
                    <CustomWebhook
                      setting={setting}
                      settings={settings}
                      createSetting={createSetting}
                      updateSetting={updateSetting}
                      deleteSetting={deleteSetting}
                      loading={isLoading}
                      updatable={updatable}
                      onChangeEnabled={onChangeWebhookEnabled}
                      updateWebhookEnableLoading={updateWebhookEnableLoading}
                      webhookEvents={webhookEvents}
                    />
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>

      <div className={styles.addButton}>
        {isOverLimit && (
          <p className={styles.errorText}>
            {t(
              'The number of webhook settings has reached the limit and cannot be added.',
            )}
            <Link to="/settings/billing">
              {t('For more information, click here.')}
            </Link>
          </p>
        )}
        {!updatable && (
          <p className={styles.errorText}>
            {t('You do not have permission to configure Webhook.')}
          </p>
        )}
        <Button
          type="tertiary"
          size="full"
          icon="add"
          value={t('Add')}
          disabled={isOverLimit || !updatable}
          onClick={openWebhookModal}
        />
      </div>

      <WebhookModal>
        <Modal
          type="small"
          title={t('Select a service')}
          description={t(
            'Select a service for which you want Webhook notifications.',
          )}
        >
          <ul>
            <li
              className={styles.modalList}
              onClick={() => addNewWebhookSetting('SLACK')}
            >
              <div className={styles.title}>
                <img
                  className={styles.icon}
                  src="/images/icon_slack.png"
                  alt=""
                />
                <span className={styles.name}>Slack</span>
              </div>
            </li>
            <li
              className={styles.modalList}
              onClick={() => addNewWebhookSetting('CHATWORK')}
            >
              <div className={styles.title}>
                <img
                  className={styles.icon}
                  src="/images/icon_chatwork.png"
                  alt=""
                />
                <span className={styles.name}>Chatwork</span>
              </div>
            </li>
            <li
              className={styles.modalList}
              onClick={() => addNewWebhookSetting('NETLIFY')}
            >
              <div className={styles.title}>
                <img
                  className={styles.icon}
                  src="/images/icon_netlify.png"
                  alt=""
                />
                <span className={styles.name}>Netlify</span>
              </div>
            </li>
            <li
              className={styles.modalList}
              onClick={() => addNewWebhookSetting('CLOUDFLARE_PAGES')}
            >
              <div className={styles.title}>
                <img
                  className={styles.icon}
                  src="/images/icon_cloudflare.png"
                  alt=""
                />
                <span className={styles.name}>Cloudflare Pages</span>
              </div>
            </li>
            <li
              className={styles.modalList}
              onClick={() => addNewWebhookSetting('VERCEL')}
            >
              <div className={styles.title}>
                <img
                  className={styles.icon}
                  src="/images/icon_vercel.png"
                  alt=""
                />
                <span className={styles.name}>Vercel</span>
              </div>
            </li>
            <li
              className={styles.modalList}
              onClick={() => addNewWebhookSetting('AMPLIFY')}
            >
              <div className={styles.title}>
                <img
                  className={styles.icon}
                  src="/images/icon_amplify.png"
                  alt=""
                />
                <span className={styles.name}>AWS Amplify</span>
              </div>
            </li>
            <li
              className={styles.modalList}
              onClick={() => addNewWebhookSetting('GITHUB_ACTIONS')}
            >
              <div className={styles.title}>
                <img
                  className={styles.icon}
                  src="/images/icon_github.png"
                  alt=""
                />
                <span className={styles.name}>GitHub Actions</span>
              </div>
            </li>
            <li
              className={styles.modalList}
              onClick={() => addNewWebhookSetting('EMAIL')}
            >
              <div className={styles.title}>
                <img
                  className={styles.icon}
                  src="/images/icon_email.svg"
                  alt=""
                />
                <span className={styles.name}>{t('Email Notification')}</span>
              </div>
            </li>
            <li
              className={styles.modalList}
              onClick={() => addNewWebhookSetting('CUSTOM')}
            >
              <div className={styles.title}>
                <img
                  className={styles.icon}
                  src="/images/icon_custom.png"
                  alt=""
                />
                <span className={styles.name}>{t('Custom Notification')}</span>
              </div>
            </li>
          </ul>
        </Modal>
      </WebhookModal>
    </div>
  );
};

export default WebhookSettings;
