import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Auth, I18n } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import type { CognitoUser } from '@/entity/user';

export interface userAttributes {
  name?: string;
  email?: string;
  picture?: string;
  email_verified?: 'true' | 'false';
}

export const useUserAttributes = () => {
  const getCognitoUser = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    if (cognitoUser) {
      return Auth.userAttributes(cognitoUser).then((result) => {
        return result.reduce<userAttributes>(
          (o, c) => ({ ...o, [c.getName()]: c.getValue() }),
          {},
        );
      });
    }
    return;
  };
  const userAttributesQuery = useQuery({
    queryKey: ['userAttributes'],
    queryFn: getCognitoUser,
    staleTime: Number.POSITIVE_INFINITY,
  });
  const { data: userAttributes = {} } = userAttributesQuery;
  return { userAttributes };
};

interface updateUserAttributes {
  params: any;
}

export const useUpdateUserAttributes = () => {
  const { t } = useTranslation('hooksProfile');
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const { cognitoUser } = useCognitoUser();
  const {
    mutate: updateUserAttributes,
    isLoading: updateUserAttributesLoading,
  } = useMutation<string, Error, updateUserAttributes>({
    mutationFn: ({ params }) => Auth.updateUserAttributes(cognitoUser, params),
    onSuccess() {
      cache.invalidateQueries(['userAttributes'], { type: 'all' });
      addToast(t('Saved changes.'), {
        appearance: 'success',
      });
    },
    onError(error) {
      addToast(
        error
          ? I18n.get(error.name, error.message)
          : t('Could not save changes.'),
        {
          appearance: 'error',
        },
      );
    },
  });
  return { updateUserAttributes, updateUserAttributesLoading };
};

interface verifyCurrentUserAttributeSubmit {
  code: string;
}
export const useVerifyCurrentUserAttributeSubmit = () => {
  const { t } = useTranslation('hooksProfile');
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const {
    mutate: verifyCurrentUserAttributeSubmit,
    isLoading: verifyCurrentUserAttributeSubmitLoading,
  } = useMutation<string, Error, verifyCurrentUserAttributeSubmit>({
    mutationFn: ({ code }) =>
      Auth.verifyCurrentUserAttributeSubmit('email', code),
    onSuccess() {
      cache.invalidateQueries(['userAttributes'], { type: 'all' });
      addToast(t('Email address has been verified.'), {
        appearance: 'success',
      });
    },
    onError(error) {
      addToast(
        error
          ? I18n.get(error.name, error.message)
          : t('Email address verification failed.'),
        {
          appearance: 'error',
        },
      );
    },
  });
  return {
    verifyCurrentUserAttributeSubmit,
    verifyCurrentUserAttributeSubmitLoading,
  };
};

export const useCognitoUser = () => {
  const cognitoUserQuery = useQuery<CognitoUser>({
    queryKey: ['cognitoUser'],
    queryFn: () => {
      return Auth.currentAuthenticatedUser();
    },
    staleTime: Number.POSITIVE_INFINITY,
  });
  const { data: cognitoUser, isLoading: cognitoUserIsLoading } =
    cognitoUserQuery;

  return { cognitoUser, cognitoUserIsLoading };
};

interface verifyMfaToken {
  user: any;
  code: string;
}

export const useVerifyMfaToken = (closeQRcode: () => void) => {
  const { t } = useTranslation('hooksProfile');
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const { mutate: verifyMfaToken, isLoading: verifyMfaTokenLoading } =
    useMutation<string, Error, verifyMfaToken>({
      mutationFn: ({ user, code }) =>
        Auth.verifyTotpToken(user, code).then(() =>
          Auth.setPreferredMFA(user, 'TOTP'),
        ),
      onSuccess() {
        cache.invalidateQueries(['cognitoUser'], { type: 'all' });
        addToast(t('Two-Factor authentication has been set up.'), {
          appearance: 'success',
        });
        closeQRcode();
      },
      onError(error) {
        addToast(
          error ? I18n.get(error.name, error.message) : t('Input error'),
          {
            appearance: 'error',
          },
        );
      },
    });
  return {
    verifyMfaToken,
    verifyMfaTokenLoading,
  };
};

interface removeMfaToken {
  user: any;
}

export const useRemoveMfaToken = () => {
  const { t } = useTranslation('hooksProfile');
  const cache = useQueryClient();
  const { addToast } = useToasts();
  const { mutate: removeMfaToken, isLoading: removeMfaTokenLoading } =
    useMutation<string, Error, removeMfaToken>({
      mutationFn: ({ user }) => Auth.setPreferredMFA(user, 'NOMFA'),
      onSuccess() {
        cache.invalidateQueries(['cognitoUser'], { type: 'all' });
        addToast(t('Two-Factor authentication has been removed.'), {
          appearance: 'success',
        });
      },
      onError(error) {
        addToast(
          error
            ? I18n.get(error.name, error.message)
            : t('Failed to remove Two-Factor authentication.'),
          {
            appearance: 'error',
          },
        );
      },
    });
  return {
    removeMfaToken,
    removeMfaTokenLoading,
  };
};
