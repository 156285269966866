import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { formatDateToMinutes } from '@/util/date';

type ReturnValue = {
  onHistoryPush: (reqId: number) => void;
  fromNowHandler: (date: string) => string;
  formatDateToMinutesHandler: (date: string) => string;
};

export const useReviewList = (): ReturnValue => {
  const history = useHistory();

  const onHistoryPush = useCallback(
    (reqId: number) => {
      history.push(`/reviews/${reqId}`);
    },
    [history],
  );

  const fromNowHandler = (date: string) => {
    return dayjs(date).fromNow();
  };

  const formatDateToMinutesHandler = (date: string) => {
    return formatDateToMinutes(date);
  };

  return {
    onHistoryPush,
    fromNowHandler,
    formatDateToMinutesHandler,
  };
};
