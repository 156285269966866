import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Selectfield from '@/components/ui/Selectfield';
import Switch from '@/components/ui/Switch';

import { EmbedPreview } from '../../EmbedPreview';

import type { IframelyResponse, OptionsField } from '@/entity/iframely';

import styles from './editembedoptions.module.css';

import { Fieldset } from '@/views/Common/Ui/Fieldset';
import { Range } from '@/views/Common/Ui/Range';
import { Textfield } from '@/views/Common/Ui/Textfield';

export type Props = {
  iframelyResponseData: IframelyResponse;
  inputOptions?: Record<string, OptionsField['value']>;
  onChangeInputOptions: (value: OptionsField['value'], key: string) => void;
};

export type ViewProps = {
  options: IframelyResponse['options'];
  url: IframelyResponse['url'];
  html: IframelyResponse['html'];
  error?: IframelyResponse['error'];
  inputOptions?: Record<string, OptionsField['value']>;
  onChangeInputOptions: (option: OptionsField['value'], key: string) => void;
};

export const EditEmbedOptionsView: React.FC<ViewProps> = ({
  options,
  url,
  html,
  error,
  inputOptions,
  onChangeInputOptions,
}) => {
  const { t } = useTranslation('richEditorV2');

  if (error) {
    return (
      <div className={styles.errorResult}>
        <p>{t('Embedding is not supported.')}</p>
      </div>
    );
  }

  return (
    <div
      className={cx(styles.previewResult, {
        [styles.previewOnly]: !options,
      })}
    >
      {options && (
        <div className={styles.previewSettings}>
          {Object.keys(options).map((key, i) => {
            const option = options?.[key];
            if (!key || !option) {
              return null;
            }

            const inputValue = inputOptions?.[key];
            if (typeof option.value === 'string' && option.values) {
              const keyValue = Object.keys(option.values)[0];
              return (
                <Switch
                  key={i}
                  on={!!inputValue}
                  onChange={(value) =>
                    onChangeInputOptions(value ? keyValue : '', key)
                  }
                >
                  {Object.values(option.values)[0]}
                </Switch>
              );
            }
            if (typeof option.value === 'string') {
              return (
                <Textfield
                  key={i}
                  label={option.label}
                  placeholder={option.placeholder}
                  value={
                    typeof inputValue === 'string' ? inputValue : option.value
                  }
                  onChange={(event) =>
                    onChangeInputOptions(event.target.value, key)
                  }
                />
              );
            }
            if (typeof option.value === 'boolean') {
              return (
                <Switch
                  key={i}
                  on={!!inputValue}
                  onChange={(value) => onChangeInputOptions(value, key)}
                >
                  {option.label}
                </Switch>
              );
            }
            if (typeof option.value === 'number' && option.values) {
              // TODO:EmbedSelectBoxコンポーネントに分ける
              const value = Object.keys(option.values).find(
                (value) => value === inputValue,
              );
              return (
                <Fieldset key={i} label={option.label}>
                  <Selectfield
                    style={{ width: '100%' }}
                    value={value}
                    onChange={(event) =>
                      onChangeInputOptions(event.target.value, key)
                    }
                  >
                    {Object.keys(option.values).map((key, i) => {
                      return (
                        <option key={i} value={key}>
                          {key}
                        </option>
                      );
                    })}
                  </Selectfield>
                </Fieldset>
              );
            }
            if (typeof option.value === 'number' && option.range) {
              return (
                <Fieldset key={i} label={option.label}>
                  <Range
                    label={option.label}
                    min={option.range.min}
                    max={option.range.max}
                    value={
                      typeof inputValue === 'number'
                        ? [inputValue]
                        : [option.value]
                    }
                    onValueChange={(value) =>
                      onChangeInputOptions(value[0], key)
                    }
                  />
                </Fieldset>
              );
            }
          })}
        </div>
      )}
      <div className={styles.previewEmbed}>
        <EmbedPreview url={url} html={html} options={options} />
      </div>
    </div>
  );
};

export const EditEmbedOptions: React.FC<Props> = ({
  iframelyResponseData,
  inputOptions,
  onChangeInputOptions,
}) => {
  return (
    <EditEmbedOptionsView
      options={iframelyResponseData.options}
      html={iframelyResponseData.html}
      url={iframelyResponseData.url}
      error={iframelyResponseData.error}
      inputOptions={inputOptions}
      onChangeInputOptions={onChangeInputOptions}
    />
  );
};
