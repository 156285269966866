import {
  getInputExceptionContentPermissions,
  getOutputExceptionContentPermissions,
} from './convExceptionContentPermissions';

import type { FormRoleInputValue } from '../provider/stateContext';
import type {
  Role,
  CreateRoleVars,
  UpdateRoleVars,
  CreateRoleVarsStringified,
  UpdateRoleVarsStringified,
} from '@/entity/role';

/**
 * Roleを入力用の値に変換して返却する
 */
function getRoleInput<
  T extends Pick<
    Role,
    | 'name'
    | 'rolePermission'
    | 'memberPermission'
    | 'usagePermission'
    | 'billingPermission'
    | 'apiKeyPermission'
    | 'reviewRequestPermission'
    | 'mediumPermission'
    | 'apiPermission'
    | 'defaultContentPermission'
    | 'environmentPermission'
    | 'exceptionContentPermissions'
  >,
>(originalRole: T): FormRoleInputValue {
  const input: FormRoleInputValue = {
    name: originalRole.name,
    rolePermission: originalRole.rolePermission,
    memberPermission: originalRole.memberPermission,
    usagePermission: originalRole.usagePermission,
    billingPermission: originalRole.billingPermission,
    apiKeyPermission: originalRole.apiKeyPermission,
    reviewRequestPermission: originalRole.reviewRequestPermission,
    mediumPermission: originalRole.mediumPermission,
    apiPermission: originalRole.apiPermission,
    defaultContentPermission: originalRole.defaultContentPermission,
    environmentPermission: originalRole.environmentPermission,
    exceptionContentPermissions: getInputExceptionContentPermissions(
      originalRole.exceptionContentPermissions,
    ),
  };

  return input;
}

/**
 * Roleを作成/更新用のクエリのオブジェクトに変換して返却する
 */
function getRoleOutput(
  input: FormRoleInputValue,
  meta: { serviceId: string },
  settingsStringify?: false,
): CreateRoleVars;
function getRoleOutput(
  input: FormRoleInputValue,
  meta: { serviceId: string },
  settingsStringify: true,
): CreateRoleVarsStringified;
function getRoleOutput(
  input: FormRoleInputValue,
  meta: { serviceId: string; roleId: string },
  settingsStringify?: false,
): UpdateRoleVars;
function getRoleOutput(
  input: FormRoleInputValue,
  meta: { serviceId: string; roleId: string },
  settingsStringify: true,
): UpdateRoleVarsStringified;
function getRoleOutput(
  input: FormRoleInputValue,
  meta: { serviceId: string } | { serviceId: string; roleId: string },
  settingsStringify?: boolean,
):
  | CreateRoleVars
  | CreateRoleVarsStringified
  | UpdateRoleVars
  | UpdateRoleVarsStringified {
  const settings: CreateRoleVars['settings'] = {
    rolePermission: input.rolePermission,
    memberPermission: input.memberPermission,
    usagePermission: input.usagePermission,
    billingPermission: input.billingPermission,
    apiKeyPermission: input.apiKeyPermission,
    reviewRequestPermission: input.reviewRequestPermission,
    mediumPermission: input.mediumPermission,
    apiPermission: input.apiPermission,
    defaultContentPermission: input.defaultContentPermission,
    environmentPermission: input.environmentPermission,
    exceptionContentPermissions: getOutputExceptionContentPermissions(
      input.exceptionContentPermissions,
    ),
  };

  if (settingsStringify) {
    const output: CreateRoleVarsStringified = {
      serviceId: meta.serviceId,
      name: input.name,
      settings: JSON.stringify(settings),
    };
    if ('roleId' in meta) {
      (output as UpdateRoleVarsStringified).roleId = meta.roleId;
    }
    return output;
  }

  const output: CreateRoleVars = {
    serviceId: meta.serviceId,
    name: input.name,
    settings,
  };
  if ('roleId' in meta) {
    (output as UpdateRoleVars).roleId = meta.roleId;
  }
  return output;
}

export { getRoleInput, getRoleOutput };
