import { apiIpAddressRestrictionRepository } from '@/repository/apiIpAddressRestrictionRepository';

export const getGlobalApiIpAddressRestriction = (serviceId: string) => {
  const { getGlobalApiIpAddressRestriction } =
    apiIpAddressRestrictionRepository();

  return getGlobalApiIpAddressRestriction(serviceId);
};

type UpdateGlobalApiIpAddressRestrictionArgs = {
  serviceId: string;
  ipList: string;
};

export const updateGlobalApiIpAddressRestriction = ({
  serviceId,
  ipList,
}: UpdateGlobalApiIpAddressRestrictionArgs) => {
  const { updateGlobalApiIpAddressRestriction } =
    apiIpAddressRestrictionRepository();

  return updateGlobalApiIpAddressRestriction({ serviceId, ipList });
};

type UpdateApiIpAddressRestrictionArgs = {
  apiId: string;
  ipList: string;
  enabled: boolean;
};

export const updateApiIpAddressRestriction = ({
  apiId,
  ipList,
  enabled,
}: UpdateApiIpAddressRestrictionArgs) => {
  const { updateApiIpAddressRestriction } = apiIpAddressRestrictionRepository();

  return updateApiIpAddressRestriction({ apiId, ipList, enabled });
};
