import empty from 'is-empty';
import { useState, useCallback } from 'react';

const noop = () => {};

/**
 * @deprecated
 * TypeScript化した@/views/Common/useInput.tsを利用してください
 */
const useInput = (initial, validateFunc = noop, allowEmpty = false) => {
  const [value, setValue] = useState(() => initial);
  const [error, setError] = useState();

  const setValueWithValidation = useCallback(
    (v) => {
      if (typeof v === 'function') {
        setValue((prev) => {
          const nextValue = v(prev);
          setError(
            allowEmpty && empty(nextValue) ? null : validateFunc(nextValue),
          );
          return nextValue;
        });
        return;
      }
      setValue(v);
      setError(allowEmpty && empty(v) ? null : validateFunc(v));
    },
    [setValue, setError, validateFunc, allowEmpty],
  );

  const onChange = useCallback(
    (e) => {
      const value = e.target.value;
      setValueWithValidation(value);
    },
    [setValueWithValidation],
  );

  return [value, onChange, error, setValueWithValidation];
};

export default useInput;
