import { API, graphqlOperation } from 'aws-amplify';

import * as mutations from '../../graphql/mutations';

import type { AddReviewerMutationVariables, AddReviewerMutation } from '@/API';
import type { NonNullishFields } from '@/util/utility-type';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

async function addReviewerRepository(
  variables: NonNullishFields<AddReviewerMutationVariables>,
) {
  const result = (await API.graphql<AddReviewerMutation>(
    graphqlOperation(mutations.addReviewer, variables),
  )) as GraphQLResult<AddReviewerMutation>; // 基本的に、ObservableではなくGraphQLResultの型になるのでアサーションしている

  return result;
}

export { addReviewerRepository };
