import { useContentList, useSearch } from '@/hooks/Content/useContentReader';
import { useDeleteContent } from '@/hooks/Content/useContentWriter';
import { useBulkDeleteContents } from '@/hooks/Content/useContentWriterTs';
import { useCustomStatus } from '@/hooks/CustomStatus/useCustomStatus';
import { useExceptionPermissionIsHaveLeastOne } from '@/hooks/Role/useMyRoles';
import { useDebounce } from '@/hooks/useDebounce';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';
import cx from 'classnames';
import * as CSV from 'csv-string';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import queryString from 'query-string';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Prompt, useHistory, useParams } from 'react-router-dom';
import { formatDate, formatDateToMinutes } from '../../util/date';
import ApiRequest from '../ApiRequest';
import ContentFilters from '../ContentFilters';
import Feedback from '../Feedback';
import { SortButton } from '../Form';
import Head from '../Head';
import IframeContent from '../IframeContent';
import ImportStatus from '../ImportStatus';
import Member from '../Member';
import S3Image from '../S3Image';
import { validateCSV } from '../Validations';
import Button from '../ui/Button';
import ContentStatus from '../ui/ContentStatus';
import DropArea from '../ui/DropArea';
import Error from '../ui/Error';
import IconWithTextButton from '../ui/IconWithTextButton';
import Modal from '../ui/Modal';
import Pagination from '../ui/Pagination';
import Searchfield from '../ui/Searchfield';
import Selectfield from '../ui/Selectfield';
import Switch from '../ui/Switch';
import { Table, Tbody, Td, Th, Thead, Tr } from '../ui/Table';
import Tooltip from '../ui/Tooltip';
import { ContentListEmpty } from './ContentListEmpty';
import CustomField from './CustomField';
import { useContentListPresenter } from './useContentListPresenter';
import { useContentOperationPermissionOnContentList } from './useContentOperationPermissionOnContentList';

import type {
  BulkContentStatus,
  ContentStatus as ContentStatusType,
} from '@/entity/content';

import styles from './contentList.module.css';

import { localStorage } from '@/constants/localStorage';
import { apiListSelectors } from '@/ducks/apiList';
import type { ApiList, ApiListByRedux, MigrateApi } from '@/entity/api';
import { useAppSelector } from '@/store/hooks';
import { AlertDialog } from '@/views/Common/Ui/AlertDialog';
import { ModalContent, Modal as UiModal } from '@/views/Common/Ui/Modal';
import {
  SideModal,
  SideModalContent,
  SideModalTrigger,
} from '@/views/Common/Ui/SideModal';
import { useToggle } from '@/views/Common/Ui/useToggle';
import { useBulkUpdateContentsStatus } from '@/views/Common/content/useContentWriter';
import { MediaList } from '@/views/ContentList/MediaList';
import { Relation } from '@/views/ContentList/Relation';
import { RelationList } from '@/views/ContentList/RelationList';
import { NoPermission } from '@/views/ContentList/shared/NoPermission';

const DEFAULT_SIZE = 25;
const MAX_SIZE = 100;

const ContentList: React.FC = () => {
  const { t } = useTranslation('contentList');
  const { service } = useGetMyService();
  const flags = useFlags();

  const history = useHistory();
  const {
    filters: defaultFilters = '',
    q: defaultQ = '' as string,
    size: defaultSize = DEFAULT_SIZE,
    offset: defaultOffset = 0,
    orders: defaultOrders = '',
  } = queryString.parse(history.location.search);

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint } = useParams<{ endpoint: string }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );

  const [csvFiles, setCsvFiles] = useState<File[]>([]);
  const [csvContentsLength, setCsvContentsLength] = useState(0);
  const [csvError, setCsvError] = useState<string | null>(null);
  const [contentIndex, setContentIndex] = useState<number | null>(null);
  const [bulkLoadingState, setBulkLoadingState] = useState<{
    loading: boolean;
    message: string;
  } | null>(null);
  const { currentPlan } = useStripeActions(service);
  const { customStatus } = useCustomStatus(api.partitionKey);

  const [selectedContents, setSelectedContents] = useState<
    { contentStatus: ContentStatusType; partitionKey: string }[]
  >([]);

  const [deleteContent] = useDeleteContent();
  const { bulkDeleteContentsAsync, bulkDeleteContentsLoading } =
    useBulkDeleteContents(api.partitionKey);
  const { bulkUpdateContentsStatusAsync, bulkUpdateContentsStatusLoading } =
    useBulkUpdateContentsStatus(api.partitionKey);
  const { historyPushStateContentId } = useContentListPresenter(api);

  //権限を取得
  const [hasReadDeveloperPermission] = useExceptionPermissionIsHaveLeastOne(
    api.partitionKey,
    'showDeveloperMenu',
  );
  const {
    hasCreatePermission,
    hasDeleteContentPermission,
    hasReorderPermission,
    hasPublishPermission,
    hasRevertToDraftPublishedPermission,
    hasRevertToDraftUnpublishedPermission,
    hasBulkOperationPermission,
  } = useContentOperationPermissionOnContentList({
    apiId: api.partitionKey,
  });

  const stopPropagation = useCallback((e: any) => {
    e.stopPropagation();
  }, []);

  const [options, setOptions] = useState<{
    api: MigrateApi;
    offset: number;
    size: number;
    q: string;
    filters: string;
    orders?: string;
  }>({
    api,
    offset: Number(defaultOffset),
    size: Number(defaultSize),
    q: String(defaultQ),
    filters: String(defaultFilters),
    orders: String(defaultOrders),
  });

  const { offset } = options;

  const [
    moveToFirst,
    moveToUp,
    moveToDown,
    moveToLast,
    moveToAnyPlace,
    importContentList,
    importResult,
  ] = useContentList();

  // 検索
  const [inputSearchText, setSearchText] = useState(defaultQ as string);
  const [q = ''] = useDebounce(inputSearchText, 300); //実際に検索を行うのは少し待ってからにする

  const { contents, totalCount, isLoading, isError } = useSearch(options);

  // 表示件数
  const [displayCount, setDisplayCount] = useState(defaultSize);

  // フィルター
  const [filtersString, setFiltersString] = useState(defaultFilters as string);
  const isSomeFilterActive = filtersString !== '';

  // アクセスした際にlocalStrageにURLを保存して、再アクセスした際にリダイレクトする
  useEffect(() => {
    window.localStorage.setItem(localStorage.apiPath, `/apis/${endpoint}`);
  }, [endpoint]);

  // 検索文字列に変更があった場合、ページングをリセット
  useEffect(() => {
    if (options.q !== q) {
      setOptions((option) => ({
        ...option,
        q,
        offset: 0,
      }));
    }
  }, [options.q, q]);

  // フィルター文字列に変更があった場合、ページングをリセット
  useEffect(() => {
    if (options.filters !== filtersString) {
      setOptions((option) => ({
        ...option,
        filters: filtersString,
        offset: 0,
      }));
    }
  }, [filtersString, options.filters]);

  // sizeの値が101以上だったら100件に設定
  // 文字列(NaN判定)だったらDEFAULT_SIZEにリセット
  useEffect(() => {
    if (options.size > MAX_SIZE) {
      setOptions((option) => ({
        ...option,
        size: MAX_SIZE,
      }));
      setDisplayCount(MAX_SIZE);
    } else if (isNaN(options.size)) {
      setOptions((option) => ({
        ...option,
        size: DEFAULT_SIZE,
      }));
    }
  }, [options.size]);

  // オプションが変更された際にURLにパラメータを付与する
  // 詳細画面からブラウザバックで戻ってきた際に元の検索結果ページを表示させるため
  useEffect(() => {
    const searchParams = {
      filters: options.filters === '' ? undefined : options.filters,
      offset: options.offset === 0 ? undefined : options.offset,
      q: options.q === '' ? undefined : options.q,
      size:
        options.size > MAX_SIZE
          ? MAX_SIZE
          : isNaN(options.size)
            ? undefined
            : options.size === DEFAULT_SIZE
              ? undefined
              : options.size,
      orders: options.orders === '' ? undefined : options.orders,
    };
    const searchString = queryString.stringify(searchParams);
    history.push({ search: `?${searchString}` });
  }, [history, options]);

  // APIを切り替えた場合、すべてのオプションをリセット
  useEffect(() => {
    if (options.api.partitionKey !== api.partitionKey) {
      setOptions({
        api,
        offset: 0,
        size: DEFAULT_SIZE,
        q: '',
        filters: '',
      });
      setSearchText('');
      setFiltersString('');
      setSelectedContents([]);
    }
  }, [api, options.api]);

  // フィルター
  const [isOpenFiltersModal, toggleFiltersModal] = useToggle(false);
  const closeFilters = useCallback(() => {
    toggleFiltersModal(false);
  }, [toggleFiltersModal]);

  // モーダル
  const [FilterModal, openFilterModal] = useModal('root');
  const [DisplayCountModal, openDisplayCountModal, closeDisplayCountModal] =
    useModal('root');
  const [ImportModal, openImportModal, closeImportModal] = useModal('root');

  // ソート
  const setOrders = useCallback((name: any) => {
    const transformOrders = (orders: string | undefined) => {
      if (orders === name) {
        return `-${name}`;
      } else if (orders === `-${name}`) {
        return undefined;
      } else {
        return name;
      }
    };
    setOptions((options) => {
      return {
        ...options,
        orders: transformOrders(options.orders),
      };
    });
  }, []);

  // 表示件数
  const setCount = useCallback(
    (count: any) => {
      setOptions((option) => {
        return {
          ...option,
          size: count,
          offset: 0,
        };
      });
      closeDisplayCountModal();
    },
    [closeDisplayCountModal],
  );

  const localStorageItems = useMemo(() => {
    const _items = window.localStorage.getItem(`${api.id}#${api.contentId}`);
    return _items === null ? undefined : JSON.parse(_items);
  }, [api]);

  const [items, setItems] = useState(
    localStorageItems || api.apiFields.map((field) => field.idValue),
  );

  const hasSelectedContents = useMemo(
    () => selectedContents.length !== 0,
    [selectedContents.length],
  );

  useEffect(() => {
    setItems(localStorageItems || api.apiFields.map((field) => field.idValue));
  }, [localStorageItems, api]);

  const toggleItem = useCallback(
    (idValue: any) => (on: any) => {
      const _items = on
        ? [...items, idValue]
        : // @ts-expect-error
          items.filter((item) => item !== idValue);
      setItems(_items);
      window.localStorage.setItem(
        `${api.id}#${api.contentId}`,
        JSON.stringify(_items),
      );
    },
    [items, api],
  );

  const doDeleteContent = useCallback(
    (content: any) => {
      const allowToDelete = window.confirm(
        t(
          'Once deleted, this cannot be restored. Do you really want to delete it?',
        ),
      );
      if (allowToDelete) {
        // @ts-expect-error
        deleteContent(content.partitionKey);
      }
    },
    [deleteContent, t],
  );

  const insertRow = useCallback(
    async (index: any, targetIndex: any) => {
      if (contents === undefined) {
        return;
      }
      // 位置を移動しない場合は何もしない
      if (index === targetIndex || index === targetIndex - 1) {
        return;
      }

      const isLastPosition =
        // 表示件数以下のコンテンツが表示されたページ内の最後尾
        offset + targetIndex ===
          Number.parseInt(totalCount?.toString() ?? '0') ||
        // 表示件数分のコンテンツが表示されたページ内の最後尾
        targetIndex === Number.parseInt(displayCount?.toString() ?? '0');

      if (isLastPosition) {
        // 最後尾の場合
        // @ts-expect-error
        return moveToAnyPlace(contents[index], contents[targetIndex - 1], true);
      }

      // 一番上、間に移動する場合
      // @ts-expect-error
      return moveToAnyPlace(contents[index], contents[targetIndex], false);
    },
    [contents, moveToAnyPlace, offset, totalCount],
  );

  // 並びかえ
  const [beforeIndex, setBeforeIndex] = useState(-1);
  const [afterIndex, setAfterIndex] = useState(-1);
  const [draggable, setDraggable] = useState(false);
  const onDragStart = useCallback((e: any) => {
    setBeforeIndex(e.target.getAttribute('data-index'));
  }, []);

  const onDragOver = useCallback(
    (e: any) => {
      if (draggable === false) {
        return;
      }
      e.preventDefault();
      const { top, height } = e.currentTarget.getBoundingClientRect();
      const y = e.pageY - top - window.pageYOffset;

      // 要素の半分より上か下か判定
      setAfterIndex(
        Number(e.currentTarget.getAttribute('data-index')) +
          (y < height / 2 ? 0 : 1),
      );
    },
    [draggable],
  );
  const onDragEnd = useCallback(
    (e: any) => {
      insertRow(Number(beforeIndex), Number(afterIndex));
      setBeforeIndex(-1);
      setAfterIndex(-1);
      setDraggable(false);
    },
    [insertRow, beforeIndex, afterIndex],
  );

  const csvFileName = useMemo(() => 'input-format.csv', []);

  const downloadSampleCsv = useCallback(() => {
    const fields = api.apiFields;
    const content = `"${t(
      'Content ID\nBlank is acceptable. Enter a specific value if it is needed.',
    )}",${fields.map((f) => f.fieldId).join(',')}\n${[0, 1]
      .map(
        (n) =>
          `,${fields
            .map((f) => f.kind)
            .map((kind) =>
              n === 0 && kind === 'text'
                ? t('Text1')
                : n === 0 &&
                    (kind === 'textArea' ||
                      kind === 'richEditor' ||
                      kind === 'richEditorV2')
                  ? `"${t('Enter multiple lines of text\nHello')}"`
                  : n === 0 &&
                      (kind === 'media' ||
                        kind === 'mediaList' ||
                        kind === 'file' ||
                        kind === 'custom' ||
                        kind === 'repeater')
                    ? `(${t('Import is not supported.')})`
                    : n === 0 && kind === 'date'
                      ? // @ts-expect-error
                        dayjs().toISOString(true)
                      : n === 0 && kind === 'number'
                        ? '0'
                        : n === 0 && kind === 'boolean'
                          ? 'true'
                          : n === 0 && kind === 'select'
                            ? t('Options')
                            : n === 0 && kind === 'relation'
                              ? t('Reference Id')
                              : n === 0 && kind === 'relationList'
                                ? `"${t(`Reference Id1,Reference Id2`)}"`
                                : n === 1 && kind === 'text'
                                  ? t('Text2')
                                  : n === 1 &&
                                      (kind === 'textArea' ||
                                        kind === 'richEditor' ||
                                        kind === 'richEditorV2')
                                    ? `"${t('Enter multiple lines of text\nGood evening')}"`
                                    : n === 1 &&
                                        (kind === 'media' ||
                                          kind === 'mediaList' ||
                                          kind === 'file' ||
                                          kind === 'custom' ||
                                          kind === 'repeater')
                                      ? `(${t('Import is not supported.')})`
                                      : n === 1 && kind === 'date'
                                        ? // @ts-expect-error
                                          dayjs().toISOString(true)
                                        : n === 1 && kind === 'number'
                                          ? '1'
                                          : n === 1 && kind === 'boolean'
                                            ? 'false'
                                            : n === 1 && kind === 'select'
                                              ? t('Options')
                                              : n === 1 && kind === 'relation'
                                                ? t('Reference Id')
                                                : n === 1 &&
                                                    kind === 'relationList'
                                                  ? `"${t('Reference Id1,Reference Id2,Reference Id3')}"`
                                                  : '',
            )
            .join(',')}`,
      )
      .join('\n')}`;
    const blob = new Blob([content], { type: 'text/plain' });

    // @ts-expect-error
    if (window.navigator.msSaveBlob) {
      // @ts-expect-error
      window.navigator.msSaveBlob(blob, csvFileName);

      // msSaveOrOpenBlobの場合はファイルを保存せずに開ける
      // @ts-expect-error
      window.navigator.msSaveOrOpenBlob(blob, csvFileName);
    } else {
      // @ts-expect-error
      document.getElementById('download').href =
        window.URL.createObjectURL(blob);
    }
  }, [api.apiFields, csvFileName, t]);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const file = acceptedFiles[0];
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        // @ts-expect-error
        const data = CSV.parse(reader.result);
        const validateResult = validateCSV(
          data,
          currentPlan.limit.importUpperLimit,
        );
        setCsvError(validateResult);
        if (validateResult === null) {
          const contentsLength = data.length - 1;
          setCsvContentsLength(contentsLength);
        } else {
          setCsvContentsLength(0);
        }
      };
      setCsvFiles(acceptedFiles);
      reader.readAsText(file);
    },
    [currentPlan.limit.importUpperLimit],
  );

  const onRemoveFile = useCallback(() => {
    setCsvFiles([]);
    setCsvError(null);
    setCsvContentsLength(0);
  }, []);

  const startImport = useCallback(() => {
    closeImportModal();

    const file = csvFiles[0];
    // @ts-expect-error
    importContentList(api, file);
  }, [api, csvFiles, closeImportModal, importContentList]);

  /**
   * 行をクリックすると、コンテンツ詳細へ遷移する
   */
  const onClickRow = useCallback(
    (contentId: string, e: React.MouseEvent) => {
      if (!hasSelectedContents) {
        const targetUrl = `/apis/${api.apiEndpoint}/${contentId}`;
        if (e.metaKey || e.ctrlKey) {
          // 別タブで開く
          window.open(targetUrl);
          return;
        }
        history.push(targetUrl);
      }
    },
    [history, api.apiEndpoint, hasSelectedContents],
  );

  const onAllSelectContents = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!hasBulkOperationPermission) {
        return;
      }

      const { checked } = e.target;
      // @ts-expect-error
      const contentIds = contents.map((c) => ({
        contentStatus: c.contentStatus,
        partitionKey: c.partitionKey,
      }));
      setSelectedContents(checked ? contentIds : []);
    },
    [contents, hasBulkOperationPermission],
  );

  const onSelectContent = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      contentStatus: ContentStatusType,
      partitionKey: string,
    ) => {
      if (!hasBulkOperationPermission) {
        return;
      }

      const { checked } = e.target;
      const newSelectedContents = checked
        ? [
            ...selectedContents,
            {
              contentStatus,
              partitionKey,
            },
          ]
        : selectedContents.filter(
            (select) => select.partitionKey != partitionKey,
          );

      setSelectedContents(newSelectedContents);
    },
    [selectedContents, hasBulkOperationPermission],
  );

  const onClearSelectedContents = useCallback(() => {
    setSelectedContents([]);
  }, []);

  const onBulkDeleteContents = useCallback(
    async (selectedContents: string[]) => {
      setBulkLoadingState({ loading: true, message: t('Delete contents...') });

      try {
        const result = await bulkDeleteContentsAsync({ selectedContents });

        if (result) {
          setSelectedContents([]);
          setBulkLoadingState(null);
        }
      } catch {
        setBulkLoadingState(null);
      }
    },
    [bulkDeleteContentsAsync, t],
  );

  const onBulkUpdateContentsStatus = useCallback(
    async (selectedContents: string[], contentStatus: BulkContentStatus) => {
      setBulkLoadingState({
        loading: true,
        message:
          contentStatus === 'PUBLISH'
            ? t('Publish contents...')
            : t('Change to draft contents...'),
      });

      try {
        await bulkUpdateContentsStatusAsync({
          selectedContents,
          contentStatus,
          setSelectedContents,
          setBulkLoadingState,
        });
      } catch {
        setBulkLoadingState(null);
      }
    },
    [bulkUpdateContentsStatusAsync, t],
  );

  const selectedContentIds = useMemo(() => {
    return selectedContents.map((content) => content.partitionKey);
  }, [selectedContents]);

  const isBulkUpdatePublishStatus = useMemo(() => {
    if (!hasPublishPermission) {
      return false;
    }
    return selectedContents.some(
      (content) =>
        content.contentStatus === 'DRAFT' ||
        content.contentStatus === 'PUBLISH_AND_DRAFT' ||
        content.contentStatus === 'CLOSED',
    );
  }, [selectedContents, hasPublishPermission]);

  const isBulkUpdateChangeToDraftStatus = useMemo(() => {
    if (
      !hasRevertToDraftPublishedPermission &&
      !hasRevertToDraftUnpublishedPermission
    ) {
      return false;
    }
    return selectedContents.some(
      (content) =>
        content.contentStatus === 'PUBLISH' ||
        content.contentStatus === 'CLOSED',
    );
  }, [
    selectedContents,
    hasRevertToDraftPublishedPermission,
    hasRevertToDraftUnpublishedPermission,
  ]);

  return (
    <div className={styles.wrapper}>
      <Head title={t('Contents')} />
      <Prompt
        when={hasSelectedContents}
        message={() => {
          return t('Selection is canceled, are you sure you want to move it?');
        }}
      />
      <div className={styles.actions}>
        <div className={styles.search}>
          <Searchfield
            onChange={(e) => setSearchText(e.target.value)}
            defaultValue={defaultQ as string}
            readOnly={hasSelectedContents}
          />
        </div>
        <span className={styles.filter}>
          <SideModal
            open={isOpenFiltersModal}
            onOpenChange={toggleFiltersModal}
          >
            <SideModalTrigger asChild>
              <IconWithTextButton
                icon="filter_list"
                text={t('Filter')}
                disabled={hasSelectedContents}
              />
            </SideModalTrigger>
            <SideModalContent>
              <ContentFilters
                close={closeFilters}
                api={api}
                defaultFiltersString={filtersString}
                setFiltersString={setFiltersString}
              />
            </SideModalContent>
          </SideModal>
          {isSomeFilterActive && (
            <span className={styles.filtersLength}>
              {filtersString?.split('[and]').length}
            </span>
          )}
        </span>
        <IconWithTextButton
          icon="visibility"
          text={t('Select Display Columns')}
          outlined={true}
          onClick={openFilterModal}
          disabled={hasSelectedContents}
        />
        <div className={styles.actionList}>
          <ImportStatus api={api} importResult={importResult} />
          {hasCreatePermission && (
            <Link to={`/apis/${api.apiEndpoint}/create`}>
              <Button
                className="ga-content-add-main"
                icon="add"
                value={t('Add')}
                type="secondary"
                disabled={hasSelectedContents}
              ></Button>
            </Link>
          )}
        </div>
      </div>
      <div className={styles.main}>
        {/* NOTE: 選択中のコンテンツに対する操作, チェックボックスが選択された時に表示される */}
        {hasSelectedContents && (
          <div className={styles.selectedContentsActions}>
            <div className={styles.row}>
              <Tooltip
                className={styles.selectedContentsTooltip}
                alignment="left"
                isOnClick={
                  !bulkDeleteContentsLoading || bulkUpdateContentsStatusLoading
                }
                trigger={
                  <button
                    type="button"
                    className={styles.selectedContentsButton}
                    disabled={
                      bulkDeleteContentsLoading ||
                      bulkUpdateContentsStatusLoading ||
                      !hasBulkOperationPermission ||
                      bulkLoadingState !== null
                    }
                    data-testid="selected-contents-button"
                  >
                    <span>
                      {t('{{count}} selected', {
                        count: selectedContents.length,
                      })}
                    </span>
                    <i
                      className={cx(
                        'material-icons',
                        styles.selectedContentsButtonIcon,
                      )}
                    >
                      expand_more
                    </i>
                  </button>
                }
              >
                <ul className={styles.tooltip}>
                  {hasPublishPermission && (
                    <li
                      className={cx(
                        styles.tooltipList,
                        styles.selectedContentsTooltipList,
                        !isBulkUpdatePublishStatus && styles.disabled,
                      )}
                    >
                      <AlertDialog
                        trigger={
                          <button
                            type="button"
                            className={styles.tooltipButton}
                            disabled={!isBulkUpdatePublishStatus}
                            onClick={stopPropagation}
                          >
                            <i className="material-icons-outlined">public</i>
                            {t('Publish')}
                          </button>
                        }
                        description={t(
                          'Are you sure you want to publish the content?',
                        )}
                        buttonText={t('Publish')}
                        onSubmit={() =>
                          onBulkUpdateContentsStatus(
                            selectedContentIds,
                            'PUBLISH',
                          )
                        }
                      />
                    </li>
                  )}

                  {(hasRevertToDraftPublishedPermission ||
                    hasRevertToDraftUnpublishedPermission) && (
                    <li
                      className={cx(
                        styles.tooltipList,
                        styles.selectedContentsTooltipList,
                        !isBulkUpdateChangeToDraftStatus && styles.disabled,
                      )}
                    >
                      <AlertDialog
                        trigger={
                          <button
                            type="button"
                            className={styles.tooltipButton}
                            disabled={!isBulkUpdateChangeToDraftStatus}
                            onClick={stopPropagation}
                          >
                            <i className="material-icons-outlined">undo</i>
                            {t('Change to Draft')}
                          </button>
                        }
                        description={t(
                          'Are you sure you want to change to draft the content?',
                        )}
                        buttonText={t('Change to Draft')}
                        onSubmit={() =>
                          onBulkUpdateContentsStatus(
                            selectedContentIds,
                            'DRAFT',
                          )
                        }
                      />
                    </li>
                  )}
                  {hasDeleteContentPermission && (
                    <li
                      className={cx(
                        styles.tooltipList,
                        styles.selectedContentsTooltipList,
                      )}
                    >
                      <AlertDialog
                        trigger={
                          <button
                            type="button"
                            className={styles.tooltipButton}
                            data-testid="bulk-delete-contents-button"
                            onClick={stopPropagation}
                          >
                            <i className="material-icons-outlined">delete</i>
                            {t('#Delete content')}
                          </button>
                        }
                        description={t(
                          'Are you sure you want to delete the content? Deleted content cannot be restored.',
                        )}
                        buttonText={t('Delete')}
                        onSubmit={() =>
                          onBulkDeleteContents(selectedContentIds)
                        }
                      />
                    </li>
                  )}
                </ul>
              </Tooltip>
              <div className={styles.clear}>
                <button
                  type="button"
                  className={styles.textButton}
                  onClick={onClearSelectedContents}
                  disabled={bulkDeleteContentsLoading}
                >
                  {t('Unselect all')}
                </button>
              </div>
            </div>
          </div>
        )}
        <div className={styles.contentDetail}>
          <button className={styles.textButton} onClick={openDisplayCountModal}>
            <p>
              {totalCount
                ? t('{{offset}} ~ {{count}} of total {{totalCount}}', {
                    offset: offset + 1,
                    count:
                      totalCount > offset + options.size
                        ? offset + options.size
                        : totalCount,
                    totalCount,
                  })
                : ''}
            </p>
          </button>
          {contents !== undefined && contents.length !== 0 && (
            <div className={styles.pagination}>
              <Pagination
                offset={offset}
                // @ts-expect-error
                setOptions={setOptions}
                totalCount={totalCount ?? 0}
                pageLength={options.size}
              />
            </div>
          )}
          {hasReadDeveloperPermission && ( // 開発者のみ見せる
            <SideModal>
              <SideModalTrigger asChild>
                <IconWithTextButton
                  icon="send"
                  text={t('Preview API')}
                  className="ga-api-preview-link"
                />
              </SideModalTrigger>
              <SideModalContent size="large">
                <ApiRequest />
              </SideModalContent>
            </SideModal>
          )}
        </div>
        {isLoading && (
          <div className={styles.loading}>
            <img src="/images/icon_loading.svg" alt="" />
          </div>
        )}
        {isError && (
          <div className={styles.error}>
            <Error />
          </div>
        )}
        {!isLoading && !isError && (
          <div className={styles.tableWrap}>
            {contents && contents.length > 0 && (
              <Table aria-label={t('Contents')}>
                <Thead>
                  <Tr>
                    <Th>
                      {hasBulkOperationPermission && (
                        <input
                          type="checkbox"
                          className={styles.checkbox}
                          onChange={onAllSelectContents}
                          checked={hasSelectedContents}
                          data-testid="all-select-contents"
                        />
                      )}
                    </Th>
                    <Th>{t('Status')}</Th>
                    {items.includes('contentId') && <Th>{t('Content ID')}</Th>}
                    {items.includes('authorId') && <Th>{t('Created By')}</Th>}
                    {items.includes('editorId') && (
                      <Th>{t('Last Updated By')}</Th>
                    )}
                    {items.includes('createdAt') && (
                      <Th>
                        <SortButton
                          name="createdAt"
                          orders={options.orders}
                          onClick={() => setOrders('createdAt')}
                          disabled={hasSelectedContents}
                        >
                          {t('Date Created')}
                        </SortButton>
                      </Th>
                    )}
                    {items.includes('updatedAt') && (
                      <Th>
                        <SortButton
                          name="updatedAt"
                          orders={options.orders}
                          onClick={() => setOrders('updatedAt')}
                          disabled={hasSelectedContents}
                        >
                          {t('Date Updated')}
                        </SortButton>
                      </Th>
                    )}
                    {items.includes('publishedAt') && (
                      <Th>
                        <SortButton
                          name="publishedAt"
                          orders={options.orders}
                          onClick={() => setOrders('publishedAt')}
                          disabled={hasSelectedContents}
                        >
                          {t('Date Published')}
                        </SortButton>
                      </Th>
                    )}
                    {items.includes('revisedAt') && (
                      <Th>
                        <SortButton
                          name="revisedAt"
                          orders={options.orders}
                          onClick={() => setOrders('revisedAt')}
                          disabled={hasSelectedContents}
                        >
                          {t('Date Revised')}
                        </SortButton>
                      </Th>
                    )}
                    {api.apiFields.map(
                      (field, i) =>
                        items.includes(field.idValue) && (
                          <Th
                            key={i}
                            isLong={[
                              'text',
                              'textArea',
                              'richEditor',
                              'richEditorV2',
                            ].includes(field.kind)}
                          >
                            {['date', 'boolean', 'number'].includes(
                              field.kind,
                            ) ? (
                              <SortButton
                                name={field.fieldId}
                                orders={options.orders}
                                onClick={() => setOrders(field.fieldId)}
                                disabled={hasSelectedContents}
                              >
                                {field.name}
                              </SortButton>
                            ) : (
                              field.name
                            )}
                          </Th>
                        ),
                    )}

                    {/* NOTE: ツールチップの列のためのヘッダー */}
                    <Th className={styles.th}></Th>
                  </Tr>
                </Thead>
                <Tbody className={styles.tbody}>
                  {contents.map((content, i: number) => {
                    const reorderable = hasReorderPermission(
                      content.contentStatus,
                    );

                    return (
                      <Tr
                        key={content.partitionKey}
                        insertPrev={Number(afterIndex) === i}
                        insertNext={
                          Number(afterIndex) === i + 1 &&
                          i === contents.length - 1
                        }
                        draggable={draggable}
                        onDragStart={onDragStart}
                        onDragOver={onDragOver}
                        onDragEnd={onDragEnd}
                        onMouseEnter={() => setContentIndex(i)}
                        onMouseLeave={() => setContentIndex(null)}
                        onClick={(e) => onClickRow(content.contentId, e)}
                        data-index={i}
                        isSelected={selectedContents?.includes(
                          content.partitionKey,
                        )}
                        enableHoverStyle={!hasSelectedContents}
                      >
                        <Td>
                          <div className={styles.row}>
                            {/* ドラッグ&ドロップで並び替えするためのUI, 権限がない場合やコンテンツ選択モードの場合には非表示となる */}
                            {!hasSelectedContents &&
                            reorderable &&
                            contentIndex === i ? (
                              <button
                                onMouseDown={() => setDraggable(true)}
                                onClick={(e) => e.stopPropagation()}
                                className={styles.handle}
                                disabled={!!options.orders || !reorderable}
                              >
                                <i className="material-icons">drag_handle</i>
                              </button>
                            ) : (
                              <div className={styles.emptyBlock}></div>
                            )}

                            {hasBulkOperationPermission && (
                              <input
                                type="checkbox"
                                className={styles.contentCheckbox}
                                checked={selectedContentIds.includes(
                                  content.partitionKey,
                                )}
                                onClick={(e) => e.stopPropagation()}
                                value={[
                                  content.contentStatus,
                                  content.partitionKey,
                                ]}
                                onChange={(e) =>
                                  onSelectContent(
                                    e,
                                    content.contentStatus,
                                    content.partitionKey,
                                  )
                                }
                              />
                            )}
                          </div>
                        </Td>
                        <Td>
                          <ContentStatus
                            status={content.contentStatus}
                            reservationTime={content.reservationTime}
                            reservationStopTime={content.reservationStopTime}
                            reviewRequestId={content.reviewRequestId}
                            customStatus={customStatus}
                            contentCustomStatusIds={
                              content.contentCustomStatusIds
                            }
                          />
                        </Td>
                        {items.includes('contentId') && (
                          <Td>{content.contentId}</Td>
                        )}
                        {items.includes('authorId') && (
                          <Td>
                            {content.authorId ? (
                              <Member
                                username={content.authorId}
                                theme="black"
                              />
                            ) : (
                              '-'
                            )}
                          </Td>
                        )}
                        {items.includes('editorId') && (
                          <Td>
                            {content.editorId ? (
                              <Member
                                username={content.editorId}
                                theme="black"
                              />
                            ) : (
                              '-'
                            )}
                          </Td>
                        )}
                        {items.includes('createdAt') && (
                          <Td className={styles.date}>
                            {formatDateToMinutes(content.createdAt)}
                          </Td>
                        )}
                        {items.includes('updatedAt') && (
                          <Td>{dayjs(content.updatedAt).fromNow()}</Td>
                        )}
                        {items.includes('publishedAt') && (
                          <Td className={styles.date}>
                            {content.publishedAt
                              ? formatDateToMinutes(content.publishedAt)
                              : '-'}
                          </Td>
                        )}
                        {items.includes('revisedAt') && (
                          <Td className={styles.date}>
                            {content.revisedAt
                              ? formatDateToMinutes(content.revisedAt)
                              : '-'}
                          </Td>
                        )}
                        {api.apiFields
                          .map((field, index) => ({
                            field,
                            index,
                            value:
                              content.value &&
                              content.value[field.idValue] &&
                              content.value[field.idValue][field.kind],
                          }))
                          .map(({ field, index, value }) => {
                            if (!items.includes(field.idValue)) {
                              return null;
                            }
                            if (field.kind === 'boolean') {
                              return (
                                <Td key={index}>
                                  <Switch on={value || false} />
                                </Td>
                              );
                            }
                            if (value === undefined) {
                              return <Td key={index}>-</Td>;
                            }
                            if (field.kind === 'textArea') {
                              return <Td key={index}>{value}</Td>;
                            }
                            if (field.kind === 'richEditor') {
                              return <Td key={index}>{value}</Td>;
                            }
                            if (field.kind === 'richEditorV2') {
                              return <Td key={index}>{value}</Td>;
                            }
                            if (field.kind === 'date') {
                              return (
                                <Td key={index}>
                                  {field.dateFormat === true
                                    ? formatDate(value)
                                    : formatDateToMinutes(value)}
                                </Td>
                              );
                            }
                            if (field.kind === 'media') {
                              return (
                                <Td key={index}>
                                  {value.isAuthorized ? (
                                    <S3Image
                                      directory={value.data.directory}
                                      fileName={value.data.fileName}
                                      kind="IMAGE"
                                      queryString="?w=180"
                                      className={styles.media}
                                      customDomainImageHost={
                                        service?.customDomainImageHost
                                      }
                                    />
                                  ) : (
                                    <NoPermission />
                                  )}
                                </Td>
                              );
                            }
                            if (field.kind === 'mediaList') {
                              return (
                                <Td key={index}>
                                  <MediaList
                                    value={value}
                                    customDomainImageHost={
                                      service?.customDomainImageHost
                                    }
                                  />
                                </Td>
                              );
                            }
                            if (field.kind === 'file') {
                              return (
                                <Td key={index}>
                                  {value ? t('Exist') : t('Not Exist')}
                                </Td>
                              );
                            }
                            if (field.kind === 'select') {
                              return (
                                <Td key={index}>
                                  {value.length === 0 ? (
                                    '-'
                                  ) : field.multipleSelect ? (
                                    // @ts-expect-error
                                    value.map((v, i: number) => (
                                      <p key={i}>
                                        {
                                          // @ts-expect-error
                                          field.selectItems.find(
                                            (item) => item.id === v,
                                          )?.value
                                        }
                                      </p>
                                    ))
                                  ) : (
                                    <p>
                                      {
                                        // @ts-expect-error
                                        field.selectItems.find(
                                          (item) => item.id === value[0],
                                        )?.value
                                      }
                                    </p>
                                  )}
                                </Td>
                              );
                            }
                            if (field.kind === 'relation') {
                              return (
                                <Td key={index}>
                                  <Relation
                                    content={value}
                                    displayItem={field.referenceDisplayItem}
                                  />
                                </Td>
                              );
                            }
                            if (field.kind === 'relationList') {
                              return (
                                <Td key={index}>
                                  <RelationList
                                    contents={value}
                                    displayItem={field.referenceDisplayItem}
                                  />
                                </Td>
                              );
                            }
                            if (field.kind === 'iframe') {
                              return (
                                <Td key={index}>
                                  {!value ? (
                                    '-'
                                  ) : (
                                    <IframeContent value={value} />
                                  )}
                                </Td>
                              );
                            }
                            // ここ
                            if (field.kind === 'custom') {
                              return (
                                <Td key={index}>
                                  <CustomField
                                    field={field}
                                    customFieldContent={value}
                                  />
                                </Td>
                              );
                            }
                            if (field.kind === 'repeater') {
                              return (
                                <Td key={index}>
                                  <span className={styles.nowrap}>
                                    {value
                                      ? `${t('{{count}} Custom fields', {
                                          count: value.length,
                                        })}`
                                      : '-'}
                                  </span>
                                </Td>
                              );
                            }
                            return <Td key={index}>{value}</Td>;
                          })}

                        <Td className={styles.tooltipCell}>
                          {/* NOTE: チェックボックスで選択中の場合は、Tooltipは非表示 */}
                          {!hasSelectedContents && (
                            <Tooltip
                              trigger={
                                <i
                                  className={`material-icons ${styles.menuIcon}`}
                                >
                                  more_vert
                                </i>
                              }
                            >
                              <ul className={styles.tooltip}>
                                <li
                                  className={cx(
                                    styles.tooltipList,
                                    !reorderable && styles.disabled,
                                  )}
                                  onClick={
                                    reorderable
                                      ? // @ts-expect-error: moveToFirst()の引数の型が不明
                                        () => moveToFirst(content)
                                      : stopPropagation
                                  }
                                >
                                  <i className="material-icons">first_page</i>
                                  {t('Move to top')}
                                </li>
                                {!isSomeFilterActive &&
                                  q === '' &&
                                  !options.orders && (
                                    <li
                                      className={cx(
                                        styles.tooltipList,
                                        !reorderable && styles.disabled,
                                      )}
                                      onClick={
                                        reorderable
                                          ? // @ts-expect-error: moveToUp()の引数の型が不明
                                            () => moveToUp(content)
                                          : stopPropagation
                                      }
                                    >
                                      <i className="material-icons">
                                        keyboard_arrow_up
                                      </i>
                                      {t('Move up')}
                                    </li>
                                  )}
                                {!isSomeFilterActive &&
                                  q === '' &&
                                  !options.orders && (
                                    <li
                                      className={cx(
                                        styles.tooltipList,
                                        !reorderable && styles.disabled,
                                      )}
                                      onClick={
                                        reorderable
                                          ? // @ts-expect-error: moveToDown()の引数の型が不明
                                            () => moveToDown(content)
                                          : stopPropagation
                                      }
                                    >
                                      <i className="material-icons">
                                        keyboard_arrow_down
                                      </i>
                                      {t('Move down')}
                                    </li>
                                  )}
                                <li
                                  className={cx(
                                    styles.tooltipList,
                                    !reorderable && styles.disabled,
                                  )}
                                  onClick={
                                    reorderable
                                      ? // @ts-expect-error: moveToLast()の引数の型が不明
                                        () => moveToLast(content)
                                      : stopPropagation
                                  }
                                >
                                  <i className="material-icons">last_page</i>
                                  {t('Move to bottom')}
                                </li>
                              </ul>

                              <ul className={styles.tooltip}>
                                <li
                                  className={cx(
                                    styles.tooltipList,
                                    !hasCreatePermission && styles.disabled,
                                    'track-click-by-gtm',
                                  )}
                                  data-gtm-track-event-name="click_duplicate_content"
                                  onClick={
                                    hasCreatePermission
                                      ? () =>
                                          historyPushStateContentId({
                                            contentId:
                                              content.gsiSortKey2.replace(
                                                /.*#CONTENT_ID#/,
                                                '',
                                              ),
                                          })
                                      : stopPropagation
                                  }
                                >
                                  <i className="material-icons">content_copy</i>
                                  {t('Copy content and create new content')}
                                </li>

                                <li
                                  className={cx(
                                    styles.tooltipList,
                                    !hasDeleteContentPermission &&
                                      styles.disabled,
                                  )}
                                  onClick={
                                    hasDeleteContentPermission
                                      ? () => doDeleteContent(content)
                                      : stopPropagation
                                  }
                                >
                                  <i className="material-icons">clear</i>
                                  {t('Delete content')}
                                </li>
                              </ul>
                            </Tooltip>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}
            {contents?.length === 0 && (
              <ContentListEmpty
                api={api}
                options={options}
                openImportModal={openImportModal}
              />
            )}
            {contents?.length !== 0 && (
              <div className={styles.paginationFooter}>
                <Pagination
                  offset={offset}
                  // @ts-expect-error
                  setOptions={setOptions}
                  totalCount={totalCount ?? 0}
                  pageLength={options.size}
                  history={history}
                  disabled={hasSelectedContents}
                />
              </div>
            )}
          </div>
        )}

        <ImportModal>
          <Modal title={t('Import Content from CSV File')} type="medium">
            <ol className={styles.numberList}>
              <li>
                {t('First, download the sample CSV file.')}
                <br />
                <a
                  href="."
                  id="download"
                  download={csvFileName}
                  onClick={downloadSampleCsv}
                >
                  {csvFileName}
                </a>
              </li>
              <li>
                {Trans({
                  t,
                  i18nKey:
                    'Replace the contents after the first line of the CSV file with the content you wish to import. Then, upload it. The current plan allows importing a file with maximum of {{limit}} lines.',
                  children: <strong>a maximum of limit</strong>,
                  values: {
                    limit: (currentPlan
                      ? currentPlan.limit.importUpperLimit
                      : 1000
                    ).toLocaleString(),
                  },
                })}
              </li>
            </ol>
            <p className={styles.caution}>
              {t(
                '*Import can be performed only when the number of contents is 0.',
              )}
            </p>
            <DropArea
              className={styles.dropArea}
              onDrop={onDrop}
              removeFile={onRemoveFile}
              targetFiles={csvFiles}
              accept={{ 'text/csv': [] }}
            />
            <div className={styles.modalActions}>
              {csvError && <p className={styles.errorText}>{csvError}</p>}
              <Button
                type="primary"
                size="large"
                disabled={csvContentsLength === 0}
                value={t('Start importing {{count}} lines', {
                  count: csvContentsLength,
                })}
                onClick={startImport}
                className={styles.modalActionButton}
              />
            </div>
          </Modal>
        </ImportModal>

        <FilterModal>
          <Modal
            title={t('Select the items you wish to display')}
            type="medium"
          >
            <ul className={styles.filterLists}>
              <li className={styles.filterList}>
                <Switch
                  on={items.includes('contentId')}
                  onChange={toggleItem('contentId')}
                >
                  {t('Content ID')}
                </Switch>
              </li>
              <li className={styles.filterList}>
                <Switch
                  on={items.includes('authorId')}
                  onChange={toggleItem('authorId')}
                >
                  {t('Created By')}
                </Switch>
              </li>
              <li className={styles.filterList}>
                <Switch
                  on={items.includes('editorId')}
                  onChange={toggleItem('editorId')}
                >
                  {t('Last Updated By')}
                </Switch>
              </li>
              <li className={styles.filterList}>
                <Switch
                  on={items.includes('createdAt')}
                  onChange={toggleItem('createdAt')}
                >
                  {t('Date Created')}
                </Switch>
              </li>
              <li className={styles.filterList}>
                <Switch
                  on={items.includes('updatedAt')}
                  onChange={toggleItem('updatedAt')}
                >
                  {t('Date Updated')}
                </Switch>
              </li>
              <li className={styles.filterList}>
                <Switch
                  on={items.includes('publishedAt')}
                  onChange={toggleItem('publishedAt')}
                >
                  {t('Date Published')}
                </Switch>
              </li>
              <li className={styles.filterList}>
                <Switch
                  on={items.includes('revisedAt')}
                  onChange={toggleItem('revisedAt')}
                >
                  {t('Date Revised')}
                </Switch>
              </li>
              {api.apiFields.map((field, i) => (
                <li key={i} className={styles.filterList}>
                  <Switch
                    on={items.includes(field.idValue)}
                    onChange={toggleItem(field.idValue)}
                  >
                    {field.name}
                  </Switch>
                </li>
              ))}
            </ul>
          </Modal>
        </FilterModal>

        <DisplayCountModal>
          <Modal title={t('Select the number of cases to display')}>
            <div className={styles.displayCountWrapper}>
              <Selectfield
                className={styles.selectBox}
                // @ts-expect-error
                value={displayCount}
                onChange={(e) => setDisplayCount(e.target.value)}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
              </Selectfield>
              <Button
                type="secondary"
                onClick={() => setCount(Number(displayCount))}
                value={t('Apply')}
                size="small"
              />
            </div>
          </Modal>
        </DisplayCountModal>

        <UiModal open={bulkLoadingState?.loading}>
          <ModalContent className={styles.modalContainer}>
            <div className={styles.modalLoading}>
              <img
                src="/images/icon_loading.svg"
                alt=""
                width="38"
                height="38"
              />
              {bulkLoadingState?.message}
            </div>
          </ModalContent>
        </UiModal>

        <Feedback
          type="failure"
          message={
            // @ts-expect-error
            importResult === false ? t('Could not start import') : undefined
          }
        />
        <Feedback
          type="success"
          message={
            // @ts-expect-error
            importResult === true ? t('Start import') : undefined
          }
        />
      </div>
    </div>
  );
};

export default ContentList;
