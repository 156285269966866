import { useQuery } from '@tanstack/react-query';

import { fetchServiceRoleForForm } from '@/usecase/roleUsecase/roleUsecase';

import type { Role } from '@/entity/role';
import type { Service } from '@/types/services';

import { uniformRoleId } from '@/util/role';

function useFetchRoleForForm(
  roleId: Role['roleId'],
  serviceId?: Service['partitionKey'],
) {
  const uniformedRoleId = uniformRoleId(roleId);

  return useQuery({
    queryKey: ['formRole', { serviceId, roleId: uniformedRoleId }],
    queryFn: async () => {
      // enabledで保証される内容だが、型安全のために追加
      if (!serviceId) {
        throw new Error('serviceIdとroleIdが必要です');
      }
      return fetchServiceRoleForForm({ serviceId, roleId });
    },
    enabled: !!serviceId && !!roleId, // serviceIdとroleIdがある場合のみ実行
    staleTime: Number.POSITIVE_INFINITY,
  });
}

export { useFetchRoleForForm };
