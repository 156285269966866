import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import type { Props } from './ImageBlock';
import type { Medium } from '@/entity/medium';

import { useToggle } from '@/views/Common/Ui/useToggle';

export const useImageBlock = ({ editor }: Props) => {
  const { addToast } = useToasts();
  const { t } = useTranslation('richEditorV2');

  const [isOpenMediaModal, toggleMediaModal] = useToggle(false);

  const setImageBlockHandler = useCallback(
    (medium: Medium) => async () => {
      toggleMediaModal(false);

      const isOldImage = medium.path && medium.identityId;
      if (isOldImage) {
        addToast(t('Using the old format image requires re-uploading.'), {
          appearance: 'error',
        });
        return;
      }

      if (medium.mediumId && medium.serviceId) {
        if (!medium.imageWidth || !medium.imageHeight) {
          return;
        }

        editor
          .chain()
          .focus()
          .setImage({
            medium: {
              mediumId: medium.mediumId,
              serviceId: medium.serviceId,
            },
            alt: '',
            link: '',
            isTargetBlank: false,
          })
          .run();
      }
    },
    [addToast, editor, t, toggleMediaModal],
  );

  return {
    isOpenMediaModal,
    toggleMediaModal,
    setImageBlockHandler,
  };
};
