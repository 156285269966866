import { findMedium } from '@/usecase/mediumUsecase';

import { useApi } from '@/lib/useApi';

type UseMediumArgs = {
  serviceId?: string | null;
  mediumId?: string | null;
};

export const useMedium = ({ serviceId, mediumId }: UseMediumArgs) => {
  return useApi(
    ['medium', { serviceId, mediumId }],
    async ({ serviceId, mediumId }) => findMedium({ serviceId, mediumId }),
    { enabled: !!serviceId && !!mediumId, staleTime: Number.POSITIVE_INFINITY },
  );
};
