import type React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';

import CheckRoles from '../CheckRoles';
import CreateRole from '../CreateRole';
import EditRole from '../EditRole';
import Main from '../Main';
import PrivateRoute from '../PrivateRoute';
import RoleList from '../RoleList';
import HelpButton from '../ui/HelpButton';

import styles from './rolesTop.module.css';

const RolesTop: React.FC = () => {
  const { t } = useTranslation('rolesTop');
  return (
    <Main
      header={
        <header className={styles.header}>
          <h2 className={styles.title}>
            {t('Manage Roles')}
            <HelpButton
              type="manual"
              id="roles"
              className="ga-click-tooltip-role"
            />
          </h2>
        </header>
      }
    >
      <CheckRoles
        permission="rolePermission"
        operation="readLevel"
        display={true}
      >
        <Switch>
          <PrivateRoute exact path="/roles" component={RoleList} />
          <PrivateRoute exact path="/roles/create" component={CreateRole} />
          <PrivateRoute exact path="/roles/:roleId" component={EditRole} />
        </Switch>
      </CheckRoles>
    </Main>
  );
};

export default RolesTop;
