import HorizontalRule from '@tiptap/extension-horizontal-rule';

import type { HorizontalRuleOptions } from '@tiptap/extension-horizontal-rule';

type CustomHorizontalRuleOptions = HorizontalRuleOptions & {
  inputRules: boolean;
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-horizontal-rule/src/horizontal-rule.ts
export const CustomHorizontalRule =
  HorizontalRule.extend<CustomHorizontalRuleOptions>({
    addOptions() {
      return {
        ...this.parent?.(),
        inputRules: true,
      };
    },
    parseHTML() {
      return this.options.inputRules ? this.parent?.() : [];
    },

    addInputRules() {
      return this.options.inputRules ? this.parent?.() || [] : [];
    },
  });
