import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { apiQueryKeys } from './queryKeys';
import { useCurrentService } from '../service/useServiceReader';

import { loadApis } from '@/ducks/apiList/operations';
import { useAppDispatch } from '@/store/hooks';

/**
 * TanstackQueryの['apiList']キャッシュを無効化するための関数を提供する
 * Reduxの削除が済むまでは、Redux経由のAPIリストの再読み込みも行う
 */
const useApiListInvalidateCache = () => {
  // TODO: Redux削除
  const dispatch = useAppDispatch();
  const { data: service } = useCurrentService();
  // ここまで

  const queryClient = useQueryClient();

  const invalidateCache = useCallback(() => {
    if (service) {
      dispatch(loadApis(service));
    }

    queryClient.invalidateQueries({
      queryKey: apiQueryKeys.list(),
      type: 'all',
    });
  }, [dispatch, queryClient, service]);

  return invalidateCache;
};

export { useApiListInvalidateCache };
