import { useMemo } from 'react';

import type { Props } from './RolesSettingField';
import type { Role } from '@/entity/role';

import { nonNullable } from '@/util/type-guard';
import { useToggle } from '@/views/Common/Ui/useToggle';

export const useRolesSettingField = ({
  myRoles,
  allRoles,
  selectedRoleIds,
}: Props) => {
  const [isOpenSearchRoles, toggleSearchRoles] = useToggle(false);

  const selectableRoles = useMemo(() => {
    if (!allRoles || !myRoles) {
      return null;
    }

    let resultRoles: Role[];

    // 管理者権限を持つユーザーは全ロールを選択可能
    if (myRoles.some((r) => r.isAdmin)) {
      resultRoles = allRoles;
    } else {
      resultRoles = allRoles.filter((role) => {
        // 招待可能なロール（自分が所属しているロール）のみ表示する
        return myRoles.map((r) => r.roleId).includes(role.roleId);
      });
    }

    return resultRoles.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
  }, [allRoles, myRoles]);

  const selectedRoleNames: { name: string; id: string; createdAt: string }[] =
    useMemo(() => {
      const allRolesMap: { [key: string]: Role } = {};
      allRoles?.forEach((role) => {
        allRolesMap[role.roleId] = role;
      });

      return selectedRoleIds
        .map((roleId) => {
          const role = allRolesMap[roleId];
          if (!role) {
            return null;
          }
          return { name: role.name, id: roleId, createdAt: role.createdAt };
        })
        .filter(nonNullable)
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt));
    }, [allRoles, selectedRoleIds]);

  return {
    isOpenSearchRoles,
    toggleSearchRoles,
    selectableRoles,
    selectedRoleNames,
  };
};
