import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useServiceWriter } from '@/hooks/Service/useServiceWriter';
import { useGetMyService } from '@/hooks/useService';

import Head from '../../Head';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import Switch from '../../ui/Switch';

import styles from './requireMfaSettings.module.css';

import { getServiceDomain } from '@/util';
import { Upsell } from '@/views/Common/plan/Upsell';
import { useSAMLQuery } from '@/views/ServiceSettings/SAMLSettings/useSAMLQuery';

const RequireMfaSettings: React.FC = () => {
  const { t } = useTranslation('serviceRequireMfaSettings');
  const { service } = useGetMyService();
  const domain = getServiceDomain();
  // @ts-expect-error
  const [requireMfa, setRequireMfa] = useState(service && service.requireMfa);
  const [updateRequireMfa, loading] = useServiceWriter();

  const onChangeRequireMfa = useCallback((bool: any) => {
    setRequireMfa(bool);
  }, []);

  const update = useCallback(() => {
    // @ts-expect-error
    updateRequireMfa(service.partitionKey, requireMfa);
  }, [requireMfa, service?.partitionKey, updateRequireMfa]);

  const { useFindSAMLConfiguration } = useSAMLQuery(
    service?.partitionKey || '',
  );
  const { data: samlConfiguration } = useFindSAMLConfiguration();

  const isSAMLConfigured = Boolean(samlConfiguration?.loginURL);
  const isSAMLOnly = Boolean(samlConfiguration?.samlOnly);

  if (service && Object.keys(service).length === 0) {
    return null;
  }
  if (!samlConfiguration) {
    return null;
  }

  return (
    <Upsell restrictionKey="requireMfa">
      <Head title={t('Two-Factor authentication')} />
      <h2 className={styles.title}>{t('Two-Factor authentication')}</h2>
      <Fieldset
        legend={t('Mandatory Two-Factor authentication')}
        description={
          t(
            'Users who have not set up Two-Factor authentication will not be able to view or edit the contents of this service.',
          ) +
          (isSAMLConfigured
            ? t('This does not affect users with SAML integration.')
            : '')
        }
      >
        <Switch
          children={t('Required')}
          onChange={onChangeRequireMfa}
          on={requireMfa}
          disabled={isSAMLConfigured && isSAMLOnly}
        />
      </Fieldset>
      <div className={styles.actions}>
        <Button
          type="primary"
          gaon="click"
          gaeventcategory="service"
          gaeventaction="update_require_mfa"
          gaeventlabel={domain}
          // @ts-expect-error
          disabled={loading || (isSAMLConfigured && isSAMLOnly)}
          value={t('Save changes')}
          onClick={update}
        />
        {isSAMLConfigured && isSAMLOnly && (
          <p className={styles.alert}>
            {t(
              'This feature is not available if you have set SAML integration as mandatory.',
            )}
          </p>
        )}
      </div>
    </Upsell>
  );
};

export default RequireMfaSettings;
