export const getSignupState = ({ serverError, confirmSignupSuccess }) => ({
  serverError,
  confirmSignupSuccess,
});

export const getSigninState = ({ serverError }) => ({
  serverError,
});

export const getForgotPasswordState = ({
  serverError,
  forgotPasswordSuccess,
}) => ({ serverError, forgotPasswordSuccess });

export const getChangePasswordState = ({
  serverError,
  changePasswordSuccess,
}) => ({ serverError, changePasswordSuccess });
