import { apiListSelectors } from '@/ducks/apiList';
import type { ApiList } from '@/entity/api';
import { useLoading } from '@/hooks';
import {
  isLimitApiFields,
  useLimitApiFields,
} from '@/hooks/FieldLimit/useLimitApiField';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';
import { useAppSelector } from '@/store/hooks';
import { getPureHost } from '@/util';
import { useApiListInvalidateCache } from '@/views/Common/api/invalidateCache';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { nanoid } from 'nanoid';
import { useCallback, useMemo } from 'react';
import type React from 'react';
import { useCookies } from 'react-cookie';
import { useModal } from 'react-hooks-use-modal';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import ApiModel from '../../ApiModel';
import { PrimaryButton } from '../../Form';
import Button from '../../ui/Button';
import styles from './inputModel.module.css';

type Props = {
  goPrevious: () => void;
  fieldsInput: any;
  customFieldsInput: any;
  objects: any;
  postApi: any;
  postResult: boolean;
};

const InputModel: React.FC<Props> = ({
  goPrevious,
  fieldsInput: [fields, _, fieldsError, setFields],
  customFieldsInput: [__, ___, customFieldsError, setCustomFields],
  objects,
  postApi,
  postResult,
}) => {
  const { t } = useTranslation('createApiInputModel');
  const { service, parentService } = useGetMyService();
  const history = useHistory();

  const flags = useFlags();

  // ANKEN-1163 フィールド数上限値の設定
  const maxFieldDate = flags.maxFieldDate;
  const maxFieldNumber = flags.maxFieldNumber;

  // フィールド数の上限値チェック
  const errorAddField = useLimitApiFields(fields, service, parentService);

  // TODO: ReduxをReactQueryに置き換える
  const apiLength = useAppSelector(
    (state) =>
      apiListSelectors.getAllApiLength(state.apiListState as ApiList) as number,
  );

  const { currentPlan } = useStripeActions(service);
  const hasLocalError = useMemo(
    () =>
      fieldsError !== null ||
      (customFieldsError !== null && customFieldsError !== undefined),
    [customFieldsError, fieldsError],
  );
  const [loading, startLoading] = useLoading(postResult !== undefined);

  const swap = useCallback(
    (x: any, y: any) => {
      if (x === y) {
        return;
      }
      const copy = [...fields];
      copy.splice(y, 0, fields[x]);
      if (x < y) {
        copy.splice(x, 1);
      } else {
        copy.splice(x + 1, 1);
      }
      setFields(copy);
    },
    [fields, setFields],
  );

  const [ConfirmModal, openConfirmModal] = useModal('root');

  // 追加課金によりAPI追加ができる場合
  const willBeChargedWhenAddApi = useMemo(() => {
    return (
      currentPlan &&
      currentPlan.limit.apiCount !== null &&
      currentPlan.limit.apiCountOveragePrice !== null &&
      currentPlan.limit.apiCountOverageUnit !== null &&
      currentPlan.limit.apiCount <= apiLength
    );
  }, [apiLength, currentPlan]);

  const apiListInvalidateCache = useApiListInvalidateCache();

  const [, setCookie] = useCookies(['usage#apiCreated']);

  const firstFocusRef = useCallback((node: HTMLButtonElement | null) => {
    node?.focus();
  }, []);

  const createApi = useCallback(async () => {
    startLoading();
    const { name, endpoint, type, fields, customFields } = objects;
    await postApi(name, endpoint, type, fields, customFields, service);
    setCookie('usage#apiCreated', true, {
      path: '/',
      domain: getPureHost(),
      secure: window.location.protocol === 'https',
      expires: dayjs().add(20, 'year').toDate(),
    });

    history.push(`/apis/${endpoint}`);

    // TODO:作成後に呼ぶ。暫定で作成可否に関わらず呼んでいる
    apiListInvalidateCache();
  }, [
    startLoading,
    objects,
    postApi,
    service,
    setCookie,
    history,
    apiListInvalidateCache,
  ]);

  const inputFile = useCallback(
    ([file]: any) => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          // @ts-expect-error
          const fileContent = JSON.parse(reader.result);
          const { apiFields, customFields } = Array.isArray(fileContent)
            ? { apiFields: fileContent, customFields: [] } //旧バージョンエクスポートファイルはAPIのフィールドのみを出力していた
            : fileContent;
          const isValid = [
            ...apiFields,
            ...customFields.flatMap((f: any) => f.fields),
          ].every((f) => f.fieldId && f.name && f.kind); //簡易的すぎるかも。とはいえどこまでチェックするのか難しい...
          if (!isValid) {
            throw new Error(
              t('File is incorrect. Check the file to be imported.'),
            );
          }

          // ANKEN-1163 フィールド数上限値のチェック
          const isLimitNumApiFields = isLimitApiFields(
            { fields: apiFields, service, parentService },
            { maxFieldDate, maxFieldNumber: maxFieldNumber + 1 },
          );

          // フィールド数の上限を超えている場合はエラーを表示
          if (isLimitNumApiFields) {
            throw new Error(
              t(
                'The import cannot proceed as it exceeds the maximum field limit of {{limit}}.',
                {
                  limit: maxFieldNumber,
                },
              ),
            );
          }

          setFields(
            apiFields.map((f: any) => ({
              ...f,
              idValue: nanoid(10),
              isAdditionalField: true,
            })),
          );
          setCustomFields(
            customFields.map((f: any) => ({
              ...f,
              idValue: nanoid(10),
              isAdditionalField: true,
            })),
          );
        } catch (error) {
          if (error instanceof Error) {
            alert(error.message);
          } else {
            alert(t('File is incorrect. Check the file to be imported.'));
          }
        } finally {
          // @ts-expect-error
          document.getElementById('file').value = '';
        }
      };
      reader.readAsText(file);
    },
    [
      setCustomFields,
      setFields,
      t,
      maxFieldNumber,
      maxFieldDate,
      service,
      parentService,
    ],
  );

  const setFieldAt = useCallback(
    (f: any, index: any) => {
      setFields((prev: any) => {
        const next = [...prev];
        next[index] = f(prev[index]);
        return next;
      });
    },
    [setFields],
  );

  return (
    <div className={styles.area}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('Define API schema')}</h2>
        <p className={styles.description}>
          {t(
            'Content ID (id) and various date / time (`createdAt`, `updatedAt`, `publishedAt`, `revisedAt`) will be automatically created.',
          )}
          <br />
          <input
            id="file"
            type="file"
            accept="application/json"
            onChange={(e) => inputFile(e.target.files)}
            style={{ display: 'none' }}
          />
          {Trans({
            t,
            i18nKey: 'To import files, click here.',
            children: (
              <button
                ref={firstFocusRef}
                className={styles.link}
                onClick={() => document.getElementById('file')?.click()}
              >
                click here
              </button>
            ),
          })}
        </p>
        <div className={styles.lists}>
          {/* @TODO createdAtの例を載せる？ */}
          {fields.map((field: any, i: number) => (
            <div key={i} className={styles.list}>
              {/*  @ts-expect-error */}
              <ApiModel
                allowCustomField={false}
                type={objects.type}
                field={field}
                allFields={fields}
                setFieldAt={setFieldAt}
                index={i}
                swap={swap}
                deleteField={(index) =>
                  setFields(
                    fields.filter((field: any, j: number) => j !== index),
                  )
                }
              />
            </div>
          ))}
          <div className={styles.addBtn}>
            <Button
              value={t('Add field')}
              type="tertiary"
              size="full"
              icon="add"
              onClick={() =>
                setFields([
                  ...fields,
                  { idValue: nanoid(10), isAdditionalField: true },
                ])
              }
              disabled={errorAddField}
            />
            {errorAddField && (
              <p className={styles.errorText}>
                {t('The maximum number of fields has been reached.')}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <span className={styles.step}>
          <em>3</em>/3 {t('Steps')}
        </span>
        <div className={styles.prev}>
          <Button value={t('Back')} type="tertiary" onClick={goPrevious} />
        </div>
        <div className={styles.next}>
          <Button
            value={t('Create')}
            type="secondary"
            disabled={hasLocalError || loading}
            onClick={
              willBeChargedWhenAddApi ? () => openConfirmModal() : createApi
            }
            className="ga-create-api-schema"
          />
        </div>
      </div>
      <ConfirmModal>
        <div className={styles.confirmModal}>
          <p>
            {t(
              'The maximum number of APIs that can be created under the current plan has been reached.',
            )}
            <br />
            {t(
              'A monthly fee of {{price}} yen (tax excluded) will be charged for each additional API. (The fee for the current month is calculated on a pro-rated basis.)',
              {
                price: currentPlan.limit.apiCountOveragePrice?.toLocaleString(),
              },
            )}
            <br />
            <Link to="/settings/billing">{t('Check the current plan.')}</Link>
          </p>
          <div className={styles.modalAction}>
            <PrimaryButton
              className="ga-create-api-schema"
              value={t('Create API')}
              onClick={createApi}
              disabled={loading}
            />
          </div>
        </div>
      </ConfirmModal>
    </div>
  );
};

export default InputModel;
