import styles from './contentListRelation.module.css';

export type Props = {
  content: {
    partitionKey: string;
    contentId: string;
    value: Record<string, { text: string }>;
  };
  displayItem?: string;
};

const ContentListRelation: React.FC<Props> = ({ content, displayItem }) => {
  if (displayItem && content && content.value && content.value[displayItem]) {
    const obj = content.value[displayItem];
    const kind = Object.keys(obj)[0];
    if (kind === 'text') {
      return <div className={styles.wrapper}>{obj[kind]}</div>;
    }
  }
  return (
    <div className={styles.wrapper}>{content ? content.contentId : '-'}</div>
  );
};

export default ContentListRelation;
