import { useId } from 'react';

type UseFieldIdsArgs = {
  description?: React.ReactNode;
  error?: string;
};

type UseFieldIdsReturn = {
  labelId: string;
  descriptionId?: string;
  errorId?: string;
};

export const useFieldIds = ({
  description,
  error,
}: UseFieldIdsArgs): UseFieldIdsReturn => {
  const labelId = useId();
  const descriptionId = useId();
  const errorId = useId();

  return {
    labelId,
    descriptionId: description !== undefined ? descriptionId : undefined,
    errorId: error !== undefined ? errorId : undefined,
  };
};
