import { useState, useEffect, useCallback } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';

import { useLoadApis } from '@/hooks/ApiList/useApiListReader';
import { useStripeActions } from '@/hooks/useStripeActions';
import { useWindowDimensions } from '@/hooks/useWindowDimentions';

import { ChargeAddApiModalChildren } from './ChargeAddApiModalChildren';
import { DuplicateEndpointModalChildren } from './DuplicateEndpointModalChildren';
import HoverDescriptionCard from './HoverDescriptionCard';
import {
  useCreateBannerTemplate,
  useCreateBlogTemplate,
  useCreateNewsTemplate,
} from './useCreateTemplates';
import { checkApiEndpoint } from './utils/checkApiEndpoint';
import { checkApiTemplateCountLimit } from './utils/checkTemplateCountLimit';
import UpsellModal from '../../ui/UpsellModal';

import type { Endpoint } from './utils/checkApiEndpoint';
import type { Service } from '@/types/services';

import styles from './selectapitemplate.module.css';

export type TemplateType = 'blogs' | 'news' | 'banner' | string;

type Props = {
  service: Service;
  goNext: () => React.SetStateAction<number>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const SelectApiTemplate: React.FC<Props> = ({
  service,
  goNext,
  setIsLoading,
}) => {
  const { t } = useTranslation('selectApiTemplate');
  const { currentPlan } = useStripeActions(service);
  const { apiList } = useLoadApis(service);
  const { width } = useWindowDimensions();

  const [needApiCount, setNeedApiCount] = useState<number>(1);
  const [UpsellApiModal, openUpsellApiModal] = useModal('root');
  const [UpsellApiCountModal, openUpsellApiCountModal] = useModal('root');

  const [endpoint, setEndpoint] = useState<string>();
  const [
    DuplicateEndpointModal,
    openDuplicateEndpointModal,
    closeDuplicateEndpointModal,
  ] = useModal('root');

  const [templateType, setTemplateType] = useState<TemplateType>();
  const [ChargedAddApiModal, openChargedAddApiModal, closeChargedAddApiModal] =
    useModal('root');

  const createBlogTemplate = useCreateBlogTemplate({
    service,
    setIsLoading,
  });

  const createNewsTemplate = useCreateNewsTemplate({
    service,
    setIsLoading,
  });

  const createBannerTemplate = useCreateBannerTemplate({
    service,
    setIsLoading,
  });

  const apiListLength = (apiList && apiList.list.length) || 0;
  const onCreateApiTemplate = useCallback(
    (templateEndpoints: Endpoint[], needApiCount: 1 | 2) => {
      const duplicateEndpoint = checkApiEndpoint({
        apiList,
        templateEndpoints,
      });

      // APIテンプレートで作成されるエンドポイントが、重複している場合エラーモーダルを出す
      if (duplicateEndpoint !== undefined) {
        setEndpoint(duplicateEndpoint);
        openDuplicateEndpointModal();
        return;
      }

      const { canCreateApiTemplate } = checkApiTemplateCountLimit({
        apiListLength,
        currentPlan,
        needApiCount,
      });

      // 追加課金すればAPI追加できるケースは課金モーダルを出す
      if (canCreateApiTemplate === 'willBeChargedWhenAddApi') {
        setTemplateType(templateEndpoints[0]);
        setNeedApiCount(needApiCount);
        openChargedAddApiModal();
        return;
      }

      // APIの個数がプラン制限を超えてしまう場合エラーモーダルを出す
      if (!canCreateApiTemplate) {
        setNeedApiCount(needApiCount);
        openUpsellApiCountModal();
        return;
      }

      // 渡されたエンドポイントによってテンプレートを作成する
      if (templateEndpoints.includes('blogs')) {
        createBlogTemplate();
      } else if (templateEndpoints.includes('news')) {
        createNewsTemplate();
      } else if (templateEndpoints.includes('banner')) {
        createBannerTemplate();
      }
    },
    [
      apiList,
      apiListLength,
      createBannerTemplate,
      createBlogTemplate,
      createNewsTemplate,
      currentPlan,
      openChargedAddApiModal,
      openDuplicateEndpointModal,
      openUpsellApiCountModal,
    ],
  );

  // APIが1つも作成できない時はモーダルを開く
  const limitApiCount = apiList && currentPlan.limit.apiCount === apiListLength;
  useEffect(() => {
    if (limitApiCount && currentPlan.name === 'Hobby') {
      openUpsellApiModal();
    }
  }, [
    apiList,
    currentPlan.limit.apiCount,
    currentPlan.name,
    limitApiCount,
    openUpsellApiModal,
  ]);

  const firstFocusRef = useCallback((node: HTMLButtonElement | null) => {
    node?.focus();
  }, []);

  if (limitApiCount === undefined) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>{t('Create API')}</h2>
      <p className={styles.description}>
        {t('Create API on your own or select a template.')}
      </p>
      <div className={styles.templates}>
        <button
          ref={firstFocusRef}
          className={`${styles.createApiCard} ga-select-api-template-custom`}
          onClick={
            currentPlan.name !== 'Hobby'
              ? goNext
              : !limitApiCount
                ? goNext
                : openUpsellApiModal
          }
        >
          <div className={styles.mask}>
            <p className={styles.maskDescription}>
              {t('Create your own API by yourself.')}
            </p>
            <div className={styles.maskTitle}>
              <span>
                <p>{t('Create your own')}</p>
                <i className="material-icons-outlined">navigate_next</i>
              </span>
            </div>
          </div>
          <div className={styles.imgBlock}>
            <img
              src="/images/icon_edit_white.svg"
              alt={t('Edit Icon')}
              width={48}
              height={48}
            />
          </div>
          <div className={styles.createApiCardText}>
            <p>{t('Create your own')}</p>
          </div>
        </button>
        <HoverDescriptionCard
          title={t('Blog')}
          description={t(
            'For those who create blogs. Create an API for articles and categories.',
          )}
          imgUrl={
            width > 1080
              ? '/images/api-template-blog-desktop.svg'
              : width < 1080 && width > 650
                ? '/images/api-template-blog-tablet.svg'
                : '/images/api-template-blog-mobile.svg'
          }
          onClick={() => onCreateApiTemplate(['blogs', 'categories'], 2)}
          className="ga-select-api-template-blog"
        />
        <HoverDescriptionCard
          title={t('Announcement')}
          description={t('For use with updates and news.')}
          indentionDescription={t('Creates API for announcements and tags.')}
          imgUrl={
            width > 1080
              ? '/images/api-template-news-desktop.svg'
              : width < 1080 && width > 650
                ? '/images/api-template-news-tablet.svg'
                : '/images/api-template-news-mobile.svg'
          }
          onClick={() => onCreateApiTemplate(['news', 'categories'], 2)}
          className="ga-select-api-template-news"
        />
        <HoverDescriptionCard
          title={t('Banner')}
          description={t('For marketing or managing banners.')}
          indentionDescription={t('Create API for banner elements.')}
          imgUrl={
            width > 1080
              ? '/images/api-template-banner-desktop.svg'
              : width < 1080 && width > 650
                ? '/images/api-template-banner-tablet.svg'
                : '/images/api-template-banner-mobile.svg'
          }
          onClick={() => onCreateApiTemplate(['banner'], 1)}
          className="ga-select-api-template-banner"
        />
      </div>
      <UpsellApiCountModal>
        <UpsellModal
          title={t(
            'Cannot be added because the maximum number of APIs will be exceeded.',
          )}
          description={
            <>
              <p>
                {t(
                  'Unable to create a new API with the current plan as {{count}} APIs will be created additionally.',
                  { count: needApiCount },
                )}
              </p>
              <p>{t('Team or higher plan is required to create a new API.')}</p>
            </>
          }
          isHobby={true}
        />
      </UpsellApiCountModal>
      <UpsellApiModal>
        <UpsellModal
          title={t(
            'Cannot add a new API because the maximum number of APIs has been reached',
          )}
          description={
            <p>{t('Team or higher plan is required to create a new API.')}</p>
          }
          isHobby={true}
        />
      </UpsellApiModal>
      <DuplicateEndpointModal>
        <DuplicateEndpointModalChildren
          endpoint={endpoint || ''}
          close={closeDuplicateEndpointModal}
        />
      </DuplicateEndpointModal>
      <ChargedAddApiModal>
        <ChargeAddApiModalChildren
          needApiCount={needApiCount}
          templateType={templateType}
          createNewsTemplate={createNewsTemplate}
          createBlogTemplate={createBlogTemplate}
          createBannerTemplate={createBannerTemplate}
          close={closeChargedAddApiModal}
        />
      </ChargedAddApiModal>
    </div>
  );
};

export default SelectApiTemplate;
