import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ProfileMenu } from './ProfileMenu';
import { MetaContext } from '../../context/MetaContext';
import { useAnnouncement } from '../../hooks/Announcement/useAnnouncement';
import { useUserAttributes } from '../../hooks/Profile/useProfile';
import { useGetMyServices } from '../../hooks/ServiceList/useServiceList';
import {
  getDefaultHost,
  getServiceDomain,
  isSAMLUser as getIsSAMLUserFlag,
} from '../../util';
import Announcement from '../Announcement';
import ServiceList from '../ServiceList';

import styles from './nav.module.css';

const Nav: React.FC = () => {
  const { t } = useTranslation('nav');
  const { services } = useGetMyServices();
  const {
    announcement,
    announcementError,
    hasLatestAnnouncement,
    isOpenAnnouncement,
    openAnnouncement,
    closeAnnouncement,
  } = useAnnouncement();
  const { userAttributes } = useUserAttributes();

  const hasServiceDomain = useMemo(() => getServiceDomain() !== '', []);
  const host = useMemo(() => getDefaultHost(), []);
  const isSAMLUser = useMemo(() => getIsSAMLUserFlag(), []);

  const denyMultiService = isSAMLUser || MetaContext.value.isEnterprise;

  if (services === undefined) return null;

  return (
    <nav className={styles.wrapper}>
      <div className={styles.fixed}>
        <div className={styles.scrollArea}>
          <ServiceList services={services} />
          {denyMultiService ? (
            <></>
          ) : getServiceDomain() === '' ? (
            <Link
              to="/create-service"
              className={styles.add}
              data-testid="link-create-service"
            >
              <img src="/images/icon_plus_white.svg" alt={t('Add')} />
            </Link>
          ) : (
            <a
              href={`${getDefaultHost()}/create-service`}
              className={styles.add}
              data-testid="link-create-service"
            >
              <img src="/images/icon_plus_white.svg" alt={t('Add')} />
            </a>
          )}
        </div>
        <div className={styles.fixedArea}>
          <button
            className={styles.button}
            title={t('Announcement')}
            aria-label={t('Announcement')}
            onClick={openAnnouncement}
          >
            <span className="material-icons-outlined">notifications</span>
            {hasLatestAnnouncement && <div className={styles.notify} />}
          </button>
          <a
            href="https://status.microcms.io"
            className={styles.button}
            title={t('Fault information')}
            aria-label={t('Fault information')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="material-icons-outlined">cell_tower</span>
          </a>
          <ProfileMenu
            userAttributes={userAttributes}
            hasServiceDomain={hasServiceDomain}
            host={host}
            openAnnouncement={openAnnouncement}
            isSAMLUser={isSAMLUser}
          />
        </div>
      </div>
      <Announcement
        isOpen={isOpenAnnouncement}
        close={closeAnnouncement}
        data={announcement}
        error={announcementError}
      />
    </nav>
  );
};

export default Nav;
