import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { Contents } from '@/types/contents';
import type { CustomState } from '@/types/customState';
import { formatDateToMinutes } from '@/util/date';
import { parseHistoryVersion } from '@/util/history-version';
import { Icon } from '@/views/Common/Ui/Icon';
import { Modal, ModalContent, ModalTrigger } from '@/views/Common/Ui/Modal';
import { SideModalContent } from '@/views/Common/Ui/SideModal';
import { useToggle } from '@/views/Common/Ui/useToggle';
import { Upsell } from '@/views/Common/plan/Upsell';

import Member from '../Member';
import Button from '../ui/Button';
import ContentStatus from '../ui/ContentStatus';
import { Table, Tbody, Td, Th, Thead, Tr } from '../ui/Table';

import styles from './contentHistories.module.css';
import { useContentHistories } from './useContentHistories';

type Props = {
  previewHistory: (content: Contents) => void;
  partitionKey: string;
  customStatus?: [CustomState];
  enableSelectHistory: boolean;
  apiEndpoint: string;
  userDefinedContentId: string;
};

const ContentHistories: React.FC<Props> = ({
  previewHistory,
  partitionKey,
  customStatus,
  enableSelectHistory,
  apiEndpoint,
  userDefinedContentId,
}) => {
  const { t } = useTranslation('contentHistories');

  const [isOpenUpsellModal, toggleOpenUpsellModal] = useToggle(false);

  const selectHistory = (content: Contents) => {
    previewHistory(content);
  };

  const { data, fetchNextPage, hasNextPage, isFetching } =
    useContentHistories(partitionKey);

  return (
    <SideModalContent size="large">
      {data?.pages === undefined ? (
        <></>
      ) : data.pages.length !== 0 ? (
        <Modal open={isOpenUpsellModal} onOpenChange={toggleOpenUpsellModal}>
          <section>
            <h4 className={styles.title}>{t('Revision history')}</h4>
            <Table aria-label={t('History List')}>
              <Thead>
                <Tr>
                  <Th>{t('Content Version')}</Th>
                  <Th>{t('Content status')}</Th>
                  <Th>{t('Updated by')}</Th>
                  <Th>{t('Date Updated')}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.pages.map((histories, i) => (
                  <React.Fragment key={i}>
                    {histories?.items &&
                      histories.items.length > 0 &&
                      histories.items.map((content, i) => {
                        const version = parseHistoryVersion(content.sortKey);

                        return (
                          <Tr key={i} enableHoverStyle={false} isSlim={true}>
                            <Td>
                              {version === 1 ? (
                                <p className={styles.versionName}>
                                  {`v${version}`}
                                </p>
                              ) : (
                                <Link
                                  to={`/apis/${apiEndpoint}/${userDefinedContentId}/compare/${
                                    version - 1
                                  }...${version}`}
                                  className={styles.versionLink}
                                  title={t('Show content differences.')}
                                >
                                  {t('{{sinceVersion}} to {{untilVersion}}', {
                                    sinceVersion: `v${version - 1}`,
                                    untilVersion: `v${version}`,
                                  })}
                                </Link>
                              )}
                            </Td>
                            <Td>
                              <ContentStatus
                                status={content.contentStatus}
                                reservationTime={content.reservationTime}
                                reservationStopTime={
                                  content.reservationStopTime
                                }
                                contentCustomStatusIds={
                                  content.contentCustomStatusIds
                                }
                                customStatus={customStatus}
                              />
                            </Td>
                            <Td>
                              {content.editorId ? (
                                <Member
                                  username={content.editorId}
                                  theme="black"
                                />
                              ) : (
                                '-'
                              )}
                            </Td>
                            <Td>{formatDateToMinutes(content.updatedAt)}</Td>
                            <Td>
                              {enableSelectHistory ? (
                                <button
                                  type="button"
                                  className={styles.previewButton}
                                  onClick={() => selectHistory(content)}
                                >
                                  {t('Preview')}
                                </button>
                              ) : (
                                <ModalTrigger className={styles.previewButton}>
                                  <Icon name="lock" outlined />
                                  <span>{t('Preview')}</span>
                                </ModalTrigger>
                              )}
                            </Td>
                          </Tr>
                        );
                      })}
                  </React.Fragment>
                ))}
              </Tbody>
            </Table>
            {hasNextPage && (
              <div className={styles.footer}>
                <Button
                  type="tertiary"
                  size="full"
                  value={t('See more')}
                  onClick={() => fetchNextPage()}
                  disabled={isFetching}
                />
              </div>
            )}
          </section>
          <ModalContent size="medium">
            <Upsell restrictionKey="history" />
          </ModalContent>
        </Modal>
      ) : (
        <p className={styles.noContent}>{t('No histories')}</p>
      )}
    </SideModalContent>
  );
};

export default ContentHistories;
