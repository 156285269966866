import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useInput } from '../../hooks';
import { useEnvironments } from '../../hooks/Environment/useEnvironments';
import { useStripeActions } from '../../hooks/useStripeActions';
import Upsell from '../Upsell';
import { validateEnvironmentName } from '../Validations';
import Button from '../ui/Button';
import ExternalLink from '../ui/ExternalLink';
import Modal from '../ui/Modal';
import Selectfield from '../ui/Selectfield';

import { Fieldset } from '@/views/Common/Ui/Fieldset';
import { Textfield } from '@/views/Common/Ui/Textfield';
import styles from './createEnvironment.module.css';

type Props = {
  service: any;
  parentService: any;
  closeEnvModal: () => void;
};

const CreateEnvironment: React.FC<Props> = ({
  service,
  parentService,
  closeEnvModal,
}) => {
  const {
    createEnvironment,
    createEnvironmentLoading,
    environments,
  }: {
    createEnvironment: any;
    createEnvironmentLoading: boolean;
    environments: any[];
  } = useEnvironments(
    service.partitionKey,
    parentService !== null ? parentService.partitionKey : undefined,
  );
  const [environmentName, onChangeEnvironmentName, environmentNameError] =
    // @ts-expect-error
    useInput('', validateEnvironmentName);
  const [contentCopyLevel, onChangeContentCopyLevel] = useInput('ALL');
  const { currentPlan } = useStripeActions(service);
  const { t } = useTranslation('createEnvironment');

  // 追加課金による環境追加ができない場合;
  const hasMaxEnv = useMemo(() => {
    return (
      currentPlan &&
      currentPlan.limit.additionalEnvironments === false &&
      0 < environments.length
    );
  }, [currentPlan, environments.length]);

  return (
    <Upsell restrictionKey={hasMaxEnv ? 'additionalEnvironments' : undefined}>
      <Modal
        title={t('Create a new environment')}
        description={t(
          'Create a new environment that is a copy of the production environment. See the documentation for details.',
        )}
      >
        <ExternalLink
          href="https://document.microcms.io/manual/environments"
          className={styles.link}
        >
          {t('Learn more')}
        </ExternalLink>
        <div className={styles.group}>
          <Textfield
            label={t('Environment name')}
            placeholder={t('Development environment')}
            onChange={onChangeEnvironmentName}
            error={environmentNameError}
          />
          <Fieldset label={t('Scope of Contents Copied')}>
            <Selectfield size="full" onChange={onChangeContentCopyLevel}>
              <option value="ALL">{t('Copy all')}</option>
              <option value="PUBLISHED">
                {t('Copy published contents only')}
              </option>
              <option value="NONE">{t('Do not copy')}</option>
            </Selectfield>
          </Fieldset>
        </div>
        <p className={styles.note}>
          {t(
            'If the number of contents and media is large, it can take up to 30 minutes.',
          )}
          <br />
          {t('Processing continues even if this screen is closed.')}
        </p>
        <Button
          className={styles.button}
          size="small"
          value={createEnvironmentLoading ? t('Creating') : t('Create')}
          disabled={environmentNameError || createEnvironmentLoading}
          onClick={() =>
            createEnvironment({
              environmentName,
              contentCopyLevel,
              closeEnvModal,
            })
          }
        />
      </Modal>
    </Upsell>
  );
};

export default CreateEnvironment;
