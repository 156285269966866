import cx from 'classnames';

import styles from './fieldset.module.css';

type Props = {
  legend: string;
  description?: string;
  customDescription?: React.ReactNode;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  option?: boolean;
  help?: string;
  className?: string;
};

/**
 * @deprecated
 * このコンポーネントを使用している箇所は徐々に置き換えていきます
 * 詳細は以下のSlackを参照
 * https://microcmsio.slack.com/archives/CH9MHTEHX/p1670488563402159
 */
const Fieldset: React.FC<Props> = ({
  legend,
  description,
  customDescription,
  children,
  style,
  option = true,
  help,
  className = '',
}) => {
  return (
    <dl className={cx(styles.fieldset, className)} style={style}>
      {legend && (
        <dt className={styles.legend}>
          <div className={styles.flex}>
            <span>{legend}</span>

            {option === false && <span className={styles.required}>*</span>}
            {help && (
              <a href={help} target="docs" className={styles.helpLink}>
                <i className={`material-icons ${styles.helpIcon}`}>
                  help_outline
                </i>
              </a>
            )}
          </div>
        </dt>
      )}
      {description !== undefined && (
        <dd className={styles.description}>{description}</dd>
      )}
      {customDescription !== undefined && (
        <dd className={styles.description}>{customDescription}</dd>
      )}
      <dd className={styles.content}>{children}</dd>
    </dl>
  );
};

export default Fieldset;
