import { nanoid } from 'nanoid';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { useMatchMedia } from '@/hooks/useMatchMedia';

import type { Props, ViewProps } from './MediaListField';
import type { MediaFieldValue } from '../MediaField';
import type { MediaListLayout } from '@/entity/field';
import type { UniqueIdentifier } from '@dnd-kit/core';

import { nonNullable } from '@/util/type-guard';

export const useMediaListField = ({
  onChange,
  value,
  field,
  hasMediumCreatePermission,
}: Props): ViewProps => {
  const { t } = useTranslation('mediaListField');
  const { addToast } = useToasts();

  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const scrollImagesRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      const target = mutations.find(
        (mutation) => mutation.target === scrollImagesRef.current,
      );

      // scrollImagesに追加した要素がある場合
      if (!!target && target.addedNodes.length > 0) {
        // 横並び（スクロール）の際に追加した要素が見えるように一番右にスクロールさせる
        // 要素の幅を計算したりしてちょうどの位置にスクロールさせてもいいが、冗長なため以下の処理にしている
        scrollAreaRef.current?.scrollTo({ left: 9999 });
      }
    });

    scrollImagesRef.current &&
      observer.observe(scrollImagesRef.current, {
        childList: true,
      });

    return () => {
      observer.disconnect();
    };
  }, []);

  const sortableItems = value ? value.map(({ id }) => id) : [];
  const setSortableItems: (
    dispatch: (value: UniqueIdentifier[]) => UniqueIdentifier[],
  ) => void = (dispatch) => {
    onChange(
      dispatch(sortableItems).map((id) => {
        const item = value?.find((v) => v.id === id);
        if (!item) {
          // idが見つからないケースは基本的に存在しないため、エラーを投げる
          throw new Error('item not found');
        }

        return item;
      }),
    );
  };

  const isMobile = useMatchMedia('(max-width: 600px)');
  const layout: MediaListLayout = isMobile
    ? 'GRID_2'
    : field?.mediaListLayout ?? 'HORIZONTAL_SCROLL';

  const handleChangeOnSelected = (
    updatedValue: MediaFieldValue,
    id: string,
  ) => {
    if (!!value) {
      onChange(
        value.map((v) => (v.id === id ? { ...updatedValue, id: nanoid() } : v)),
      );
    }
  };
  const handleDeleteOnSelected = (id: string) => {
    if (!!value) {
      onChange(value.filter((v) => v.id !== id));
    }
  };
  const handleDropAcceptedOnSelected = (
    resultMediumIds: (string | null)[] | null,
    id: string,
  ) => {
    if (!!value) {
      const newMediumId = resultMediumIds?.[0];

      if (!newMediumId) {
        addToast(t('Failed to upload images'), {
          appearance: 'error',
        });
        return;
      }

      onChange(
        value.map((v) =>
          v.id === id ? { mediumId: newMediumId, id: nanoid() } : v,
        ),
      );
    }
  };

  const handleChangeOnUnselected = (updatedValue: MediaFieldValue) => {
    onChange([
      ...(value ?? []),
      { mediumId: updatedValue.mediumId, id: nanoid() },
    ]);
  };
  const handleDropAcceptedOnUnselected = (
    resultMediumIds: (string | null)[] | null,
  ) => {
    if (resultMediumIds === null) {
      addToast(t('Failed to upload images'), {
        appearance: 'error',
      });
      return;
    }

    onChange([
      ...(value ?? []),
      ...resultMediumIds.filter(nonNullable).map((mediumId) => ({
        mediumId,
        id: nanoid(),
      })),
    ]);
  };

  return {
    scrollAreaRef,
    scrollImagesRef,
    value,
    field,
    sortableItems,
    setSortableItems,
    layout,
    handleChangeOnSelected,
    handleDropAcceptedOnSelected,
    handleDeleteOnSelected,
    handleChangeOnUnselected,
    handleDropAcceptedOnUnselected,
    hasMediumCreatePermission,
  };
};
