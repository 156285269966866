import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLoadApis } from '@/hooks/ApiList/useApiListReader';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';

import Head from '../Head';

import styles from './createRole.module.css';

import { useAllRoles } from '@/views/Common/Roles/useRolesReader';
import { FormRole } from '@/views/Role/FormRole';

const CreateRole = () => {
  const { t } = useTranslation('createRole');
  const { service } = useGetMyService();
  const { currentPlan } = useStripeActions(service);

  const { apiList } = useLoadApis(service);
  const { data: roles } = useAllRoles(service?.partitionKey);

  // プランごとの作成上限に達しているか
  const hasMaxCount = useMemo(() => {
    return (
      currentPlan &&
      roles &&
      currentPlan.limit.roleCount !== null &&
      currentPlan.limit.roleCount <= roles.length
    );
  }, [currentPlan, roles]);

  if (!currentPlan || !roles || !apiList) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Head title={t('Create Roles')} />
      {service && (
        <FormRole
          currentServiceId={service.partitionKey}
          apiSchemas={apiList.list}
          editRole={false}
          contentLimitPlan={
            hasMaxCount ? (
              <dl className={styles.alert}>
                <dt className={styles.title}>
                  {t(
                    'The maximum number of roles that can be created under the current plan has been reached.',
                  )}
                </dt>
                <dd>
                  <Link to="/settings/billing">
                    {t('Check the current plan.')}
                  </Link>
                </dd>
              </dl>
            ) : null
          }
        />
      )}
    </div>
  );
};

export default CreateRole;
