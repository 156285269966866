import { handleActions } from 'redux-actions';

import actions from './actions.js';

const initialState = {};

const reducer = handleActions(
  {
    [actions.loadApiList]: (state, { payload }) => {
      const { apis, service } = payload;

      const list = apis
        .map((api) => {
          // ANKEN-1357: 既存のAPIレスポンスに影響を与えないため、権限のないレスポンスはundefinedを返す
          if (api?._restricted) {
            return;
          }

          return {
            ...api,
            //新DB移行にあたって、旧DBで取得できていた値を調整して入れておく
            apiEndpoint: api.gsiSortKey2.replace('ENDPOINT#', ''),
            contentId: api.gsiSortKey2.replace('ENDPOINT#', ''),
            domain: service.gsiSinglePartitionKey1.replace('DOMAIN#', ''),
            id: service.gsiSinglePartitionKey1.replace('DOMAIN#', ''),
            serviceId: service.partitionKey, //各所で必要になるため予め持っておく
          };
        })
        .filter((api) => !!api);

      return {
        ...state,
        list,
      };
    },
    [actions.loadApi]: (state, { payload }) => {
      return {
        ...state,
        list: state.list.map((data) => {
          if (data.partitionKey === payload.partitionKey) {
            return { ...data, ...payload };
          }
          return data;
        }),
      };
    },
  },
  initialState,
);

export default reducer;
