import TableHeader from '@tiptap/extension-table-header';

import type { TableHeaderOptions } from '@tiptap/extension-table-header';

type CustomTableHeaderOptions = TableHeaderOptions & {
  inputRules: boolean;
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-table-header/src/table-header.ts
export const CustomTableHeader = TableHeader.extend<CustomTableHeaderOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: true,
    };
  },

  parseHTML() {
    return this.options.inputRules ? this.parent?.() : [];
  },
});
