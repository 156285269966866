import type React from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useInput } from '../../../hooks';
import { useCustomFields } from '../../../hooks/CustomField/useCustomFieldReader';
import useCustomFieldWriter from '../../../hooks/CustomField/useCustomFieldWriter';
import { useExceptionPermissionIsHaveLeastOne } from '../../../hooks/Role/useMyRoles';
import Feedback from '../../Feedback';
import Head from '../../Head';
import { validateApiFieldId, validateCustomFieldName } from '../../Validations';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import Textfield from '../../ui/Textfield';

import styles from './nameSettings.module.css';

import { apiListSelectors } from '@/ducks/apiList';
import type { ApiList, MigrateApi } from '@/entity/api';
import { useAppSelector } from '@/store/hooks';

const NameSettings: React.FC = () => {
  const { t } = useTranslation('customFieldsNameSettings');

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint, fieldId: _fieldId } = useParams<{
    endpoint: string;
    fieldId: string;
  }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );

  //権限を取得
  const [hasPermission] = useExceptionPermissionIsHaveLeastOne(
    api.partitionKey,
    'apiUpdate',
  );

  const [customFields = []] = useCustomFields(api.partitionKey);
  const field = useMemo(
    () => customFields.find((field) => field.fieldId === _fieldId),
    [customFields, _fieldId],
  );

  const [name, onChangeName, nameError] = useInput(
    undefined,
    // @ts-expect-error
    validateCustomFieldName,
  );
  const [fieldId, onChangeFieldId, fieldIdError] = useInput(
    _fieldId,
    // @ts-expect-error
    validateApiFieldId,
  );
  const { result, error, loading, update } = useCustomFieldWriter(api);
  const updateField = useCallback(() => {
    const _name = name === undefined ? field?.name : name;
    update(field, fieldId, _name, field?.fields, field?.position);
  }, [name, fieldId, field, update]);

  if (field === undefined) {
    return null;
  }
  return (
    <div>
      <div>
        <Head title={t('Custom Fields/Settings')} />
        <h2 className={styles.title}>{t('Basic Information')}</h2>
        <Fieldset
          legend={t('Custom Field Name')}
          description={t('Enter description of the field.')}
        >
          <Textfield
            type="text"
            defaultValue={field.name}
            onChange={onChangeName}
            errorText={nameError}
            readOnly={!hasPermission}
          />
        </Fieldset>
        <Fieldset
          legend={t('Custom Field ID')}
          description={t('Enter field ID.')}
        >
          <Textfield
            type="text"
            defaultValue={field.fieldId}
            onChange={onChangeFieldId}
            errorText={fieldIdError}
            readOnly={!hasPermission}
          />
        </Fieldset>
      </div>
      {hasPermission && (
        <div className={styles.actions}>
          <Feedback
            type="success"
            message={result ? t('Changed') : undefined}
          />
          <Feedback
            type="failure"
            message={error ? t('Failed to make changes.') : undefined}
          />
          <Button
            type="primary"
            disabled={loading || nameError}
            value={t('Save changes')}
            onClick={updateField}
          />
        </div>
      )}
    </div>
  );
};

export default NameSettings;
