import { Auth } from 'aws-amplify';

import { DefaultApi, Configuration } from '../openapi/management';
import { getManagementApiHost } from '../util';

export async function getApi() {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken();
  const token = accessToken.getJwtToken();

  return new DefaultApi(
    new Configuration({
      basePath: getManagementApiHost(),
      headers: { authorization: `Bearer ${token}` },
    }),
  );
}
