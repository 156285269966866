import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { getResourceUrl } from '../../util';
import S3Image from '../S3Image';
import ExternalLink from '../ui/ExternalLink';

import styles from './mediaApiRequest.module.css';

type Props = {
  medium: any;
  customDomainImageHost: string | undefined;
};

const MediaApiRequest: React.FC<Props> = ({
  medium = {},
  customDomainImageHost,
}) => {
  const { t } = useTranslation('mediaApiRequest');
  const [src, setSrc] = useState('');
  const pattern = useMemo(
    () => [
      '?w=300',
      '?h=200',
      '?fit=crop&w=200&h=200',
      '?fit=fill&fill-color=331cbf&w=500&h=300',
    ],
    [],
  );
  useEffect(() => {
    // useEffectに直接async指定は出来なかったため、ここで関数定義
    const get = async () => {
      const _src = await getResourceUrl(
        medium.directory,
        medium.fileName,
        '',
        medium.kind,
        customDomainImageHost,
      );
      setSrc(_src);
    };
    get();
  }, [medium, customDomainImageHost]);

  return (
    <div>
      <p className={styles.description}>
        {t(
          'Parameters can be added after an image URL to resize or crop the image.',
        )}
        <br />
        {/* @ts-ignore */}
        <Trans t={t} i18nKey={'For more information, refer to API documents'}>
          <ExternalLink href="https://document.microcms.io/image-api/introduction">
            API documents
          </ExternalLink>
        </Trans>
        <br />
        {t('However, this cannot be applied to svg images.')}
      </p>
      {pattern.map((queryString, i) => (
        <dl key={i}>
          <dt className={styles.example}>
            {t('e.g. ')} <span>{queryString}</span>
          </dt>
          <dd>
            <a
              href={`${src}${queryString}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S3Image
                className={styles.selectedImage}
                directory={medium.directory}
                fileName={medium.fileName}
                queryString={queryString}
                kind={medium.kind}
                customDomainImageHost={customDomainImageHost}
              />
            </a>
          </dd>
        </dl>
      ))}
    </div>
  );
};

export default MediaApiRequest;
