/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type NormalResponse = {
  __typename: "NormalResponse",
  result: boolean,
  message?: string | null,
};

export type BaseResponse = {
  __typename: "BaseResponse",
  result: boolean,
  message?: string | null,
};

export type StringResponse = {
  __typename: "StringResponse",
  result: boolean,
  message?: string | null,
  data?: string | null,
};

export type MemberResponse = {
  __typename: "MemberResponse",
  result: boolean,
  message?: string | null,
  data?: Member | null,
};

export type Member = {
  __typename: "Member",
  id: string,
  serviceId: string,
  roleIds: Array< string | null >,
  createdAt?: string | null,
  updatedAt?: string | null,
  viewerGroup?: string | null,
  inviting?: boolean | null,
};

export type MembersResponse = {
  __typename: "MembersResponse",
  result: boolean,
  message?: string | null,
  data?:  Array<Member | null > | null,
};

export type ApiKeyResponse = {
  __typename: "ApiKeyResponse",
  result: boolean,
  message?: string | null,
  data?: ApiKey | null,
};

export type ApiKey = {
  __typename: "ApiKey",
  apiKeyId: string,
  serviceId: string,
  value: string,
  label?: string | null,
  createdAt: string,
  updatedAt: string,
  createdBy?: string | null,
  updatedBy?: string | null,
  defaultPolicy: ApiKeyPolicy,
  priorityPolicies?:  Array<ApiKeyPriorityPolicy | null > | null,
  managementPolicy?: ManagementPolicy | null,
};

export type ApiKeyPolicy = {
  __typename: "ApiKeyPolicy",
  get: boolean,
  getAllDraft: boolean,
  getAllClosed?: boolean | null,
  post: boolean,
  put: boolean,
  patch: boolean,
  delete: boolean,
};

export type ApiKeyPriorityPolicy = {
  __typename: "ApiKeyPriorityPolicy",
  apiId: string,
  policy: ApiKeyPolicy,
};

export type ManagementPolicy = {
  __typename: "ManagementPolicy",
  getContent?: boolean | null,
  patchContentCreatedBy?: boolean | null,
  patchContentStatus?: boolean | null,
  getMedia?: boolean | null,
  postMedia?: boolean | null,
  deleteMedia?: boolean | null,
};

export type ReviewRequestResponse = {
  __typename: "ReviewRequestResponse",
  result: boolean,
  message?: string | null,
  data?: ReviewRequest | null,
};

export type ReviewRequest = {
  __typename: "ReviewRequest",
  serviceId: string,
  reqId: number,
  title: string,
  description?: string | null,
  contentId: string,
  status: ReviewRequestStatus,
  createdAt: string,
  updatedAt: string,
  reservationTime?: string | null,
  reservationStopTime?: string | null,
  reviewers?:  Array<ReviewRequestReviewer | null > | null,
  commentCount: number,
  authorId: string,
  viewerGroup: string,
};

export enum ReviewRequestStatus {
  OPEN = "OPEN",
  CLOSE = "CLOSE",
}


export type ReviewRequestReviewer = {
  __typename: "ReviewRequestReviewer",
  id: string,
};

export type ReviewRequestEventResponse = {
  __typename: "ReviewRequestEventResponse",
  result: boolean,
  message?: string | null,
  data?: ReviewRequestEvent | null,
};

export type ReviewRequestEvent = {
  __typename: "ReviewRequestEvent",
  serviceIdReqId: string,
  eventId: string,
  createdAt: string,
  updatedAt: string,
  type: ReviewRequestEventType,
  authorId: string,
  comment?: string | null,
  oldStatus?: ReviewRequestStatus | null,
  newStatus?: ReviewRequestStatus | null,
  oldTitle?: string | null,
  newTitle?: string | null,
  reservationTime?: string | null,
  reservationStopTime?: string | null,
  historyVersion?: string | null,
  reviewerId?: string | null,
};

export enum ReviewRequestEventType {
  COMMENT = "COMMENT",
  UPDATE_STATUS = "UPDATE_STATUS",
  UPDATE_TITLE = "UPDATE_TITLE",
  UPDATE_RESERVATIONTIME = "UPDATE_RESERVATIONTIME",
  UPDATE_CONTENT = "UPDATE_CONTENT",
  PUBLISH_CONTENT = "PUBLISH_CONTENT",
  ADD_REVIEWER = "ADD_REVIEWER",
  REMOVE_REVIEWER = "REMOVE_REVIEWER",
}


export type MediumTagsResponse = {
  __typename: "MediumTagsResponse",
  result: boolean,
  message?: string | null,
  data?:  Array<MediumTag | null > | null,
};

export type MediumTag = {
  __typename: "MediumTag",
  mediumTagId: string,
  serviceId: string,
  name: string,
  createdAt: string,
  updatedAt: string,
  createdBy: string,
  updatedBy: string,
};

export type BulkDeleteContentsResponse = {
  __typename: "BulkDeleteContentsResponse",
  result: boolean,
  message?: string | null,
  data?: BulkDeleteContentsData | null,
};

export type BulkDeleteContentsData = {
  __typename: "BulkDeleteContentsData",
  successIds?: Array< string | null > | null,
  failureIds?: Array< string | null > | null,
};

export type BulkUpdateContentsStatusResponse = {
  __typename: "BulkUpdateContentsStatusResponse",
  result: boolean,
  message?: string | null,
  data?: BulkUpdateContentsStatusData | null,
};

export type BulkUpdateContentsStatusData = {
  __typename: "BulkUpdateContentsStatusData",
  successIds?: Array< string | null > | null,
  failureIds?: Array< string | null > | null,
};

export type RoleResponse = {
  __typename: "RoleResponse",
  result: boolean,
  message?: string | null,
  data?: Role | null,
};

export type Role = {
  __typename: "Role",
  serviceId: string,
  roleId: string,
  name: string,
  isAdmin: boolean,
  createdAt: string,
  updatedAt: string,
  viewerGroup: string,
  rolePermission: RolePermission,
  memberPermission: MemberPermission,
  usagePermission: ReadPermission,
  billingPermission: ReadUpdatePermission,
  environmentPermission: Permission,
  apiKeyPermission: ReadPermission,
  reviewRequestPermission: ReviewRequestPermission,
  mediumPermission: MediumPermission,
  apiPermission: CreatePermission,
  defaultContentPermission: ContentPermission,
  exceptionContentPermissions?:  Array<ExceptionContentPermission | null > | null,
};

export type RolePermission = {
  __typename: "RolePermission",
  create: boolean,
  read?: boolean | null,
  readLevel?: RoleReadLevel | null,
  update: boolean,
  delete: boolean,
};

export enum RoleReadLevel {
  ALL = "ALL",
  SAME_ROLE = "SAME_ROLE",
  NONE = "NONE",
}


export type MemberPermission = {
  __typename: "MemberPermission",
  create: boolean,
  read: MemberReadPermissionDetail,
  update: boolean,
  delete: boolean,
};

export type MemberReadPermissionDetail = {
  __typename: "MemberReadPermissionDetail",
  defaultRead: boolean,
  allowMemberList?: Array< string | null > | null,
  sameRole?: boolean | null,
};

export type ReadPermission = {
  __typename: "ReadPermission",
  read: boolean,
};

export type ReadUpdatePermission = {
  __typename: "ReadUpdatePermission",
  read: boolean,
  update: boolean,
};

export type Permission = {
  __typename: "Permission",
  create: boolean,
  read: boolean,
  update: boolean,
  delete: boolean,
};

export type ReviewRequestPermission = {
  __typename: "ReviewRequestPermission",
  readLevel: ReviewRequestReadLevel,
  create: boolean,
  updateLevel: ReviewRequestUpdateLevel,
};

export enum ReviewRequestReadLevel {
  ALL = "ALL",
  CREATED = "CREATED",
  SAME_ROLE = "SAME_ROLE",
  NONE = "NONE",
}


export enum ReviewRequestUpdateLevel {
  ALL = "ALL",
  EDIT = "EDIT",
  NONE = "NONE",
}


export type MediumPermission = {
  __typename: "MediumPermission",
  create: boolean,
  readLevel: MediumReadLevel,
  update: boolean,
  delete: boolean,
};

export enum MediumReadLevel {
  ALL = "ALL",
  CREATED = "CREATED",
  SAME_ROLE = "SAME_ROLE",
  NONE = "NONE",
}


export type CreatePermission = {
  __typename: "CreatePermission",
  create: boolean,
};

export type ContentPermission = {
  __typename: "ContentPermission",
  apiRead: boolean,
  apiUpdate: boolean,
  apiDelete: boolean,
  contentCreate: ContentPermissionContentCreate,
  contentReadLevel: ContentReadLevel,
  contentUpdate: ContentPermissionContentUpdate,
  contentDelete: boolean,
  showDeveloperMenu: boolean,
};

export type ContentPermissionContentCreate = {
  __typename: "ContentPermissionContentCreate",
  draft: boolean,
  publish: boolean,
};

export enum ContentReadLevel {
  ALL = "ALL",
  CREATED = "CREATED",
  SAME_ROLE = "SAME_ROLE",
  NONE = "NONE",
}


export type ContentPermissionContentUpdate = {
  __typename: "ContentPermissionContentUpdate",
  saveDraft: boolean,
  removeDraft: boolean,
  changeContentIdUnpublished: boolean,
  changeContentIdPublished: boolean,
  reorderUnpublished: boolean,
  reorderPublished: boolean,
  changePublicationDateUnpublished: boolean,
  changePublicationDatePublished: boolean,
  changeAuthor: boolean,
  changeDraftKey: boolean,
  publish: boolean,
  unpublish: boolean,
  revertToDraft: boolean,
  revertToDraftUnpublished: boolean,
};

export type ExceptionContentPermission = {
  __typename: "ExceptionContentPermission",
  apiId: string,
  permission?: ContentPermission | null,
  reviewRequestPermission?: ReviewRequestPermission | null,
};

export enum EnvironmentCopyContentLevel {
  ALL = "ALL",
  PUBLISHED = "PUBLISHED",
  NONE = "NONE",
}


export type ApiKeyPolicyInput = {
  get: boolean,
  getAllDraft: boolean,
  getAllClosed?: boolean | null,
  post: boolean,
  put: boolean,
  patch: boolean,
  delete: boolean,
};

export type ApiKeyPriorityPolicyInput = {
  apiId: string,
  policy: ApiKeyPolicyInput,
};

export type ManagementPolicyInput = {
  getContent?: boolean | null,
  patchContentCreatedBy?: boolean | null,
  patchContentStatus?: boolean | null,
  getMedia?: boolean | null,
  postMedia?: boolean | null,
  deleteMedia?: boolean | null,
};

export enum CancelSelect {
  SERVICE = "SERVICE",
  MATTER = "MATTER",
  OTHERS = "OTHERS",
}


export enum AddonType {
  auditLog = "auditLog",
  apiIpRestriction = "apiIpRestriction",
  additionalEnvironments = "additionalEnvironments",
}


export enum CustomStatusBehaviour {
  PUBLISH = "PUBLISH",
  DRAFT = "DRAFT",
  CLOSED = "CLOSED",
}


export type Value = {
  question?: Question | null,
  userLanguage?: Language | null,
};

export type Question = {
  job?: string | null,
  product?: string | null,
  teamSize?: string | null,
  comeFrom?: string | null,
  isInvitedUser?: boolean | null,
};

export enum Language {
  ja = "ja",
  en = "en",
}


export type NewMediumTagInput = {
  name: string,
};

export enum BulkContentStatus {
  PUBLISH = "PUBLISH",
  DRAFT = "DRAFT",
}


export enum ContentStatus {
  DRAFT = "DRAFT",
  PUBLISH = "PUBLISH",
  PUBLISH_AND_DRAFT = "PUBLISH_AND_DRAFT",
  CLOSED = "CLOSED",
}


export enum WebhookTarget {
  SLACK = "SLACK",
  CHATWORK = "CHATWORK",
  NETLIFY = "NETLIFY",
  CLOUDFLARE_PAGES = "CLOUDFLARE_PAGES",
  VERCEL = "VERCEL",
  AMPLIFY = "AMPLIFY",
  GITHUB_ACTIONS = "GITHUB_ACTIONS",
  EMAIL = "EMAIL",
  CUSTOM = "CUSTOM",
}


export enum WebhookEvent {
  UPDATE_API = "UPDATE_API",
  DELETE_API = "DELETE_API",
  PUBLISH_CONTENT_BY_SCREEN = "PUBLISH_CONTENT_BY_SCREEN",
  PUBLISH_CONTENT_BY_WRITE_API = "PUBLISH_CONTENT_BY_WRITE_API",
  PUBLISH_CONTENT_BY_REVIEW = "PUBLISH_CONTENT_BY_REVIEW",
  PUBLISH_CONTENT_BY_RESERVATION = "PUBLISH_CONTENT_BY_RESERVATION",
  PUBLISH_CONTENT_BY_CHANGE_ORDER = "PUBLISH_CONTENT_BY_CHANGE_ORDER",
  PUBLISH_CONTENT_BY_CHANGE_CONTENT_ID = "PUBLISH_CONTENT_BY_CHANGE_CONTENT_ID",
  PUBLISH_CONTENT_BY_CHANGE_PUBLISHED_AT = "PUBLISH_CONTENT_BY_CHANGE_PUBLISHED_AT",
  PUBLISH_CONTENT_BY_CHANGE_CONTENT_STATUS = "PUBLISH_CONTENT_BY_CHANGE_CONTENT_STATUS",
  UNPUBLISH_CONTENT_BY_SCREEN = "UNPUBLISH_CONTENT_BY_SCREEN",
  UNPUBLISH_CONTENT_BY_RESERVATION = "UNPUBLISH_CONTENT_BY_RESERVATION",
  UNPUBLISH_CONTENT_BY_CHANGE_ORDER = "UNPUBLISH_CONTENT_BY_CHANGE_ORDER",
  UNPUBLISH_CONTENT_BY_CHANGE_CONTENT_ID = "UNPUBLISH_CONTENT_BY_CHANGE_CONTENT_ID",
  UNPUBLISH_CONTENT_BY_CHANGE_PUBLISHED_AT = "UNPUBLISH_CONTENT_BY_CHANGE_PUBLISHED_AT",
  UNPUBLISH_CONTENT_BY_CHANGE_CONTENT_STATUS = "UNPUBLISH_CONTENT_BY_CHANGE_CONTENT_STATUS",
  SAVE_DRAFT_CONTENT_BY_SCREEN = "SAVE_DRAFT_CONTENT_BY_SCREEN",
  SAVE_DRAFT_CONTENT_BY_WRITE_API = "SAVE_DRAFT_CONTENT_BY_WRITE_API",
  SAVE_DRAFT_CONTENT_BY_CHANGE_ORDER = "SAVE_DRAFT_CONTENT_BY_CHANGE_ORDER",
  SAVE_DRAFT_CONTENT_BY_CHANGE_CONTENT_ID = "SAVE_DRAFT_CONTENT_BY_CHANGE_CONTENT_ID",
  SAVE_DRAFT_CONTENT_BY_CHANGE_PUBLISHED_AT = "SAVE_DRAFT_CONTENT_BY_CHANGE_PUBLISHED_AT",
  SAVE_DRAFT_CONTENT_BY_CHANGE_CONTENT_STATUS = "SAVE_DRAFT_CONTENT_BY_CHANGE_CONTENT_STATUS",
  DELETE_PUBLISH_CONTENT_BY_SCREEN = "DELETE_PUBLISH_CONTENT_BY_SCREEN",
  DELETE_PUBLISH_CONTENT_BY_WRITE_API = "DELETE_PUBLISH_CONTENT_BY_WRITE_API",
  DELETE_UNPUBLISH_CONTENT_BY_SCREEN = "DELETE_UNPUBLISH_CONTENT_BY_SCREEN",
  DELETE_UNPUBLISH_CONTENT_BY_WRITE_API = "DELETE_UNPUBLISH_CONTENT_BY_WRITE_API",
  DELETE_CONTENT_BY_SCREEN = "DELETE_CONTENT_BY_SCREEN",
  DELETE_CONTENT_BY_WRITE_API = "DELETE_CONTENT_BY_WRITE_API",
  DELETE_DRAFT_BY_SCREEN = "DELETE_DRAFT_BY_SCREEN",
  ADD_MEDIUM = "ADD_MEDIUM",
  UPDATE_MEDIUM = "UPDATE_MEDIUM",
  DELETE_MEDIUM = "DELETE_MEDIUM",
}


export type contents = {
  __typename: "contents",
  partitionKey: string,
  sortKey: string,
  gsiPartitionKey1?: string | null,
  gsiSortKey1?: string | null,
  gsiPartitionKey2?: string | null,
  gsiSortKey2?: string | null,
  gsiPartitionKey3?: string | null,
  gsiSortKey3?: string | null,
  gsiSinglePartitionKey1?: string | null,
  serviceName?: string | null,
  serviceImage?: S3Object | null,
  servicePostCount?: number | null,
  serviceAllowedIpList?: string | null,
  requireMfa?: boolean | null,
  environment?: Environment | null,
  apiStopUntil?: string | null,
  serviceLanguage?: Language | null,
  serviceViewerGroup?: string | null,
  memberViewerGroup?: string | null,
  roleViewerGroup?: string | null,
  mediumViewerGroup?: string | null,
  reviewRequestViewerGroup?: string | null,
  apiViewerGroup?: string | null,
  contentViewerGroup?: string | null,
  viewerGroup?: string | null,
  apiName?: string | null,
  apiDescription?: string | null,
  apiType?: ApiType | null,
  apiFields?:  Array<Field | null > | null,
  apiPreviewUrl?: string | null,
  apiPreviewLinkIsVisible?: boolean | null,
  apiImportProgress?: ApiImportProgress | null,
  apiAllowedIpList?: string | null,
  contentIdSetting?: ContentIdSetting | null,
  contentValue?: string | null,
  draftValue?: string | null,
  closedValue?: string | null,
  contentDraftKey?: string | null,
  contentStatus?: ContentStatus | null,
  contentCustomStatusIds?: Array< string | null > | null,
  reservationTime?: string | null,
  reservationStopTime?: string | null,
  authType?: AuthType | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  publishedAt?: string | null,
  revisedAt?: string | null,
  closedAt?: string | null,
  editorId?: string | null,
  authorId?: string | null,
  inputSource?: InputSource | null,
  groups?: Array< string | null > | null,
  suspended?: boolean | null,
  relatedContentIds?:  Array<RelatedContentIds | null > | null,
  customDomainImageHost?: string | null,
  customDomainFileHost?: string | null,
  _restricted?: ContentsRestricted | null,
};

export type S3Object = {
  __typename: "S3Object",
  key?: string | null,
  level?: string | null,
  identityId?: string | null,
};

export type Environment = {
  __typename: "Environment",
  name: string,
  parent: string,
};

export enum ApiType {
  PAGE = "PAGE",
  LIST = "LIST",
}


export type Field = {
  __typename: "Field",
  idValue: string,
  fieldId: string,
  name: string,
  kind: FieldKind,
  description?: string | null,
  required?: boolean | null,
  richEditorMultiParagraph?: boolean | null,
  richEditorImageSize?: boolean | null,
  referenceKey?: string | null,
  referenceDisplayItem?: string | null,
  dateFormat?: boolean | null,
  booleanInitialValue?: boolean | null,
  selectItems?:  Array<SelectItem | null > | null,
  selectInitialValue?: Array< string | null > | null,
  richEditorOptions?: Array< string | null > | null,
  richEditorV2Options?: Array< string | null > | null,
  richEditorV2ColorList?:  Array<RichEditorV2Color | null > | null,
  customClassList?:  Array<CustomClass | null > | null,
  multipleSelect?: boolean | null,
  iframeUrl?: string | null,
  customFieldCreatedAt?: string | null,
  customFieldDisplayItem?: string | null,
  customFieldCreatedAtList?: Array< string | null > | null,
  textSizeLimitValidation?: TextSizeLimitValidation | null,
  numberSizeLimitValidation?: NumberSizeLimitValidation | null,
  relationListCountLimitValidation?: RelationListCountLimitValidation | null,
  repeaterCountLimitValidation?: RepeaterCountLimitValidation | null,
  imageSizeValidation?: ImageSizeValidation | null,
  mediaListLayout?: MediaListLayout | null,
  patternMatchValidation?: PatternMatchValidation | null,
  isUnique?: boolean | null,
};

export enum FieldKind {
  text = "text",
  textArea = "textArea",
  richEditor = "richEditor",
  richEditorV2 = "richEditorV2",
  media = "media",
  mediaList = "mediaList",
  file = "file",
  date = "date",
  number = "number",
  boolean = "boolean",
  select = "select",
  relation = "relation",
  relationList = "relationList",
  iframe = "iframe",
  custom = "custom",
  repeater = "repeater",
}


export type SelectItem = {
  __typename: "SelectItem",
  id: string,
  value?: string | null,
};

export type RichEditorV2Color = {
  __typename: "RichEditorV2Color",
  id: string,
  value: string,
};

export type CustomClass = {
  __typename: "CustomClass",
  id?: string | null,
  name?: string | null,
  value?: string | null,
};

export type TextSizeLimitValidation = {
  __typename: "TextSizeLimitValidation",
  textSize: NumberRange,
};

export type NumberRange = {
  __typename: "NumberRange",
  min?: number | null,
  max?: number | null,
};

export type NumberSizeLimitValidation = {
  __typename: "NumberSizeLimitValidation",
  numberSize: NumberRange,
};

export type RelationListCountLimitValidation = {
  __typename: "RelationListCountLimitValidation",
  relationListCount: NumberRange,
};

export type RepeaterCountLimitValidation = {
  __typename: "RepeaterCountLimitValidation",
  repeatCount: NumberRange,
};

export type ImageSizeValidation = {
  __typename: "ImageSizeValidation",
  imageSize: ImageSize,
};

export type ImageSize = {
  __typename: "ImageSize",
  width?: number | null,
  height?: number | null,
};

export enum MediaListLayout {
  HORIZONTAL_SCROLL = "HORIZONTAL_SCROLL",
  GRID_2 = "GRID_2",
  GRID_3 = "GRID_3",
  GRID_4 = "GRID_4",
}


export type PatternMatchValidation = {
  __typename: "PatternMatchValidation",
  regexp: RegExp,
};

export type RegExp = {
  __typename: "RegExp",
  pattern: string,
  flags?: string | null,
};

export type ApiImportProgress = {
  __typename: "ApiImportProgress",
  status?: ProgressStatus | null,
  date?: string | null,
  message?: string | null,
};

export enum ProgressStatus {
  RUNNING = "RUNNING",
  ERROR = "ERROR",
  COMPLETE = "COMPLETE",
}


export type ContentIdSetting = {
  __typename: "ContentIdSetting",
  allowList: Array< ContentIdCharacter | null >,
  length?: number | null,
};

export enum ContentIdCharacter {
  lowercase = "lowercase",
  uppercase = "uppercase",
  number = "number",
  symbol = "symbol",
}


export enum AuthType {
  DEVELOPER = "DEVELOPER",
  EDITOR = "EDITOR",
  WRITER = "WRITER",
  NONE = "NONE",
}


export enum InputSource {
  APPSYNC = "APPSYNC",
  FILE = "FILE",
  SCRIPT = "SCRIPT",
  API = "API",
}


export type RelatedContentIds = {
  __typename: "RelatedContentIds",
  partitionKey?: string | null,
  apiId?: string | null,
  gsiSinglePartitionKey1?: string | null,
  sortKey?: string | null,
};

export type ContentsRestricted = {
  __typename: "ContentsRestricted",
  type: ContentsRestrictedType,
  reason?: string | null,
};

export enum ContentsRestrictedType {
  API_READ_PERMISSION = "API_READ_PERMISSION",
}


export type CustomField = {
  __typename: "CustomField",
  apiId: string,
  createdAt: string,
  fieldId: string,
  name: string,
  fields:  Array<Field | null >,
  position?: Array< Array< string | null > | null > | null,
  updatedAt?: string | null,
  viewerGroup?: string | null,
};

export type WebhookSetting = {
  __typename: "WebhookSetting",
  apiId: string,
  createdAt: string,
  target: WebhookTarget,
  settingValues?: string | null,
  handleEvents?: Array< WebhookEvent | null > | null,
  serviceId: string,
  updatedAt?: string | null,
  viewerGroup?: string | null,
  enabled?: boolean | null,
};

export type ContentEditor = {
  __typename: "ContentEditor",
  username: string,
  email?: string | null,
  name?: string | null,
  isRemoved: boolean,
  isApi: boolean,
};

export type ServiceUser = {
  __typename: "ServiceUser",
  sub?: string | null,
  email_verified?: boolean | null,
  email?: string | null,
  mfaSetting?: boolean | null,
  username?: string | null,
  picture?: string | null,
  name?: string | null,
  isSAML?: boolean | null,
  roleIds?: Array< string | null > | null,
  inviting?: boolean | null,
};

export type SearchOptions = {
  q?: string | null,
  size?: number | null,
  offset?: number | null,
  orders?: string | null,
  filters?: string | null,
};

export type Medium = {
  __typename: "Medium",
  serviceId: string,
  mediumId: string,
  fileName: string,
  directory: string,
  createdAt: string,
  updatedAt: string,
  kind: MediumKind,
  uploader: string,
  contentType: string,
  contentLength: number,
  imageWidth?: number | null,
  imageHeight?: number | null,
  viewerGroup?: string | null,
  relatedContentIds?:  Array<RelatedContentIds | null > | null,
  tags?: Array< string | null > | null,
};

export enum MediumKind {
  IMAGE = "IMAGE",
  FILE = "FILE",
}


export type GlobalApiIpAddressRestriction = {
  __typename: "GlobalApiIpAddressRestriction",
  ipList: string,
};

export type FindContentByVersion = {
  __typename: "FindContentByVersion",
  value: string,
  unauthorizedFieldIds: Array< string >,
};

export type EnsureContentObjectExists = {
  __typename: "EnsureContentObjectExists",
  userDefinedContentId: string,
  exists: boolean,
  authorized: boolean,
};

export type ReviewRequestAccessInfo = {
  __typename: "ReviewRequestAccessInfo",
  reqId?: number | null,
  readable?: boolean | null,
};

export type PlanList = {
  __typename: "PlanList",
  suspended?: boolean | null,
  planTypes?:  Array<PlanType | null > | null,
};

export type PlanType = {
  __typename: "PlanType",
  type: PlanTypeValue,
  plans:  Array<PlanDetail | null >,
};

export enum PlanTypeValue {
  CORPORATE = "CORPORATE",
  INDIVISUAL = "INDIVISUAL",
  OLD = "OLD",
}


export type PlanDetail = {
  __typename: "PlanDetail",
  name: string,
  current: boolean,
  description?: string | null,
  displayName?: string | null,
  minimumMonthPrice?: number | null,
  minimumMonthTaxIncludedPrice?: number | null,
  trialLength?: string | null,
  limit: PlanLimit,
};

export type PlanLimit = {
  __typename: "PlanLimit",
  transfer?: string | null,
  transferOveragePrice?: number | null,
  transferOverageUnit?: string | null,
  contentsCount?: number | null,
  memberCount?: number | null,
  memberCountOveragePrice?: number | null,
  memberCountOverageUnit?: string | null,
  importUpperLimit?: number | null,
  maxMediumSizeInMb?: number | null,
  roleCount?: number | null,
  apiCount?: number | null,
  apiCountOveragePrice?: number | null,
  apiCountOverageUnit?: string | null,
  webhookCount?: number | null,
  customFieldCount?: number | null,
  fileUpload: boolean,
  history: boolean,
  ipRestriction: boolean,
  ipRestrictionCount: number,
  apiIpRestriction: boolean,
  apiIpRestrictionCount: number,
  additionalEnvironments: boolean,
  auditLog: boolean,
  requireMfa: boolean,
  iframe: boolean,
  premiumSupport: boolean,
  indivisualInfra: boolean,
  sla: boolean,
  handleEnvironments: boolean,
  enableAllParams: boolean,
  enableAddons: boolean,
  maxApiKeyCount?: number | null,
  enableSendInvoice: boolean,
  customStatusCount: number,
  enableSAML: boolean,
  mediumTags: number,
};

export type SAMLConfigurationResponse = {
  __typename: "SAMLConfigurationResponse",
  serviceProviderInfo?: SAMLConfigurationServiceProviderInfo | null,
  idpMetadataURL?: string | null,
  idpMetadataXML?: string | null,
  defaultRoleId?: string | null,
  samlOnly: boolean,
  loginURL?: string | null,
};

export type SAMLConfigurationServiceProviderInfo = {
  __typename: "SAMLConfigurationServiceProviderInfo",
  applicationCallbackURL: string,
  settings: SAMLConfigurationSettings,
};

export type SAMLConfigurationSettings = {
  __typename: "SAMLConfigurationSettings",
  audience: string,
  nameIdentifierFormat: string,
  mappings: SAMLConfigurationMappings,
};

export type SAMLConfigurationMappings = {
  __typename: "SAMLConfigurationMappings",
  email: string,
  name: string,
  picture: string,
};

export type CustomState = {
  __typename: "CustomState",
  id: string,
  apiId: string,
  key: string,
  description: string,
  name: string,
  behaviour: CustomStatusBehaviour,
  color: string,
  createdAt: string,
  updatedAt?: string | null,
};

export type HaalthcheckGo = {
  __typename: "HaalthcheckGo",
  message?: string | null,
};

export type MediumTagList = {
  __typename: "MediumTagList",
  tags:  Array<MediumTag | null >,
};

export type CreateServiceForTemplatesMutationVariables = {
  name?: string | null,
  domain?: string | null,
  image?: string | null,
  planName?: string | null,
  trialLengthInSec?: number | null,
  templateId?: string | null,
};

export type CreateServiceForTemplatesMutation = {
  createServiceForTemplates?: string | null,
};

export type UpdateServiceRequireMfaMutationVariables = {
  serviceId?: string | null,
  requireMfa?: boolean | null,
};

export type UpdateServiceRequireMfaMutation = {
  updateServiceRequireMfa?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type ExecuteCreateEnvironmentMutationVariables = {
  environmentName?: string | null,
  serviceId?: string | null,
  contentCopyLevel?: EnvironmentCopyContentLevel | null,
};

export type ExecuteCreateEnvironmentMutation = {
  executeCreateEnvironment?:  {
    __typename: "StringResponse",
    result: boolean,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type UpdateEnvironmentNameMutationVariables = {
  serviceId: string,
  environmentName: string,
};

export type UpdateEnvironmentNameMutation = {
  updateEnvironmentName?: string | null,
};

export type DeleteEnvironmentMutationVariables = {
  serviceId?: string | null,
};

export type DeleteEnvironmentMutation = {
  deleteEnvironment?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type RestartApiMutationVariables = {
  serviceId?: string | null,
};

export type RestartApiMutation = {
  restartApi?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type CreateApiKeyMutationVariables = {
  serviceId?: string | null,
  label?: string | null,
  defaultPolicy?: ApiKeyPolicyInput | null,
  priorityPolicies?: Array< ApiKeyPriorityPolicyInput | null > | null,
  managementPolicy?: ManagementPolicyInput | null,
};

export type CreateApiKeyMutation = {
  createApiKey?:  {
    __typename: "ApiKeyResponse",
    result: boolean,
    message?: string | null,
    data?:  {
      __typename: "ApiKey",
      apiKeyId: string,
      serviceId: string,
      value: string,
      label?: string | null,
      createdAt: string,
      updatedAt: string,
      createdBy?: string | null,
      updatedBy?: string | null,
      defaultPolicy:  {
        __typename: "ApiKeyPolicy",
        get: boolean,
        getAllDraft: boolean,
        getAllClosed?: boolean | null,
        post: boolean,
        put: boolean,
        patch: boolean,
        delete: boolean,
      },
      priorityPolicies?:  Array< {
        __typename: "ApiKeyPriorityPolicy",
        apiId: string,
        policy:  {
          __typename: "ApiKeyPolicy",
          get: boolean,
          getAllDraft: boolean,
          getAllClosed?: boolean | null,
          post: boolean,
          put: boolean,
          patch: boolean,
          delete: boolean,
        },
      } | null > | null,
      managementPolicy?:  {
        __typename: "ManagementPolicy",
        getContent?: boolean | null,
        patchContentCreatedBy?: boolean | null,
        patchContentStatus?: boolean | null,
        getMedia?: boolean | null,
        postMedia?: boolean | null,
        deleteMedia?: boolean | null,
      } | null,
    } | null,
  } | null,
};

export type UpdateApiKeyMutationVariables = {
  apiKeyId?: string | null,
  label?: string | null,
  defaultPolicy?: ApiKeyPolicyInput | null,
  priorityPolicies?: Array< ApiKeyPriorityPolicyInput | null > | null,
  managementPolicy?: ManagementPolicyInput | null,
};

export type UpdateApiKeyMutation = {
  updateApiKey?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type DeleteApiKeyMutationVariables = {
  serviceId?: string | null,
  apiKeyId?: string | null,
};

export type DeleteApiKeyMutation = {
  deleteApiKey?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type CreateApiForTemplatesMutationVariables = {
  serviceId?: string | null,
  apiName?: string | null,
  apiEndpoint?: string | null,
  apiType?: string | null,
  apiFields?: string | null,
  customFields?: string | null,
  apiId?: string | null,
  apiDescription?: string | null,
};

export type CreateApiForTemplatesMutation = {
  createApiForTemplates?: string | null,
};

export type UpdateApiAllowedIpListMutationVariables = {
  apiId?: string | null,
  ipList?: string | null,
  enabled?: boolean | null,
};

export type UpdateApiAllowedIpListMutation = {
  updateApiAllowedIpList?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type MoveContentToFirstMutationVariables = {
  contentId?: string | null,
};

export type MoveContentToFirstMutation = {
  moveContentToFirst?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type MoveContentToUpMutationVariables = {
  contentId?: string | null,
};

export type MoveContentToUpMutation = {
  moveContentToUp?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type MoveContentToDownMutationVariables = {
  contentId?: string | null,
};

export type MoveContentToDownMutation = {
  moveContentToDown?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type MoveContentToLastMutationVariables = {
  contentId?: string | null,
};

export type MoveContentToLastMutation = {
  moveContentToLast?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type MoveContentToAnyPlaceMutationVariables = {
  contentId?: string | null,
  targetContentId?: string | null,
  isLast?: boolean | null,
};

export type MoveContentToAnyPlaceMutation = {
  moveContentToAnyPlace?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type CopyContentMutationVariables = {
  contentId?: string | null,
};

export type CopyContentMutation = {
  copyContent?: string | null,
};

export type UpdateMediumMutationVariables = {
  serviceId?: string | null,
  mediumId?: string | null,
  filePath?: string | null,
};

export type UpdateMediumMutation = {
  updateMedium?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type UpdateMediumNameMutationVariables = {
  serviceId?: string | null,
  mediumId?: string | null,
  fileName?: string | null,
};

export type UpdateMediumNameMutation = {
  updateMediumName?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type UpdateMediumCreatedByMutationVariables = {
  serviceId?: string | null,
  mediumId?: string | null,
  createdBy?: string | null,
};

export type UpdateMediumCreatedByMutation = {
  updateMediumCreatedBy?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type DeleteAllMediaMutationVariables = {
  serviceId?: string | null,
};

export type DeleteAllMediaMutation = {
  deleteAllMedia?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type ChangePlanToStandardMutationVariables = {
  serviceId?: string | null,
};

export type ChangePlanToStandardMutation = {
  changePlanToStandard?: string | null,
};

export type ChangePlanToBusinessMutationVariables = {
  serviceId?: string | null,
};

export type ChangePlanToBusinessMutation = {
  changePlanToBusiness?: string | null,
};

export type ChangePlanToTeamMutationVariables = {
  serviceId?: string | null,
};

export type ChangePlanToTeamMutation = {
  changePlanToTeam?: string | null,
};

export type ChangePlanToAdvancedMutationVariables = {
  serviceId?: string | null,
};

export type ChangePlanToAdvancedMutation = {
  changePlanToAdvanced?: string | null,
};

export type ChangePlanToHobbyMutationVariables = {
  serviceId?: string | null,
};

export type ChangePlanToHobbyMutation = {
  changePlanToHobby?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type ChangePlanToTemplateMutationVariables = {
  serviceId?: string | null,
};

export type ChangePlanToTemplateMutation = {
  changePlanToTemplate?: string | null,
};

export type ChangeCardMutationVariables = {
  serviceId?: string | null,
  source?: string | null,
};

export type ChangeCardMutation = {
  changeCard?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type DeleteCardMutationVariables = {
  serviceId?: string | null,
};

export type DeleteCardMutation = {
  deleteCard?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type ChangePaymentInfoMutationVariables = {
  serviceId?: string | null,
  source?: string | null,
  email?: string | null,
  name?: string | null,
  phone?: string | null,
  company?: string | null,
};

export type ChangePaymentInfoMutation = {
  changePaymentInfo?: string | null,
};

export type ChangeBillingEmailMutationVariables = {
  serviceId?: string | null,
  email?: string | null,
};

export type ChangeBillingEmailMutation = {
  changeBillingEmail?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type ChangeBillingNameMutationVariables = {
  serviceId?: string | null,
  name?: string | null,
};

export type ChangeBillingNameMutation = {
  changeBillingName?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type ChangeBillingPhoneMutationVariables = {
  serviceId?: string | null,
  phone?: string | null,
};

export type ChangeBillingPhoneMutation = {
  changeBillingPhone?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type ChangeBillingCompanyMutationVariables = {
  serviceId?: string | null,
  company?: string | null,
};

export type ChangeBillingCompanyMutation = {
  changeBillingCompany?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type GetStripeSubscriptionMutationVariables = {
  serviceId?: string | null,
};

export type GetStripeSubscriptionMutation = {
  getStripeSubscription?: string | null,
};

export type GetStripeInvoiceMutationVariables = {
  serviceId?: string | null,
};

export type GetStripeInvoiceMutation = {
  getStripeInvoice?: string | null,
};

export type CancelSubscriptionMutationVariables = {
  serviceId?: string | null,
  cancelSelect?: CancelSelect | null,
  cancelReason?: string | null,
};

export type CancelSubscriptionMutation = {
  cancelSubscription?: boolean | null,
};

export type RestartSubscriptionMutationVariables = {
  serviceId?: string | null,
  source?: string | null,
  email?: string | null,
  name?: string | null,
  phone?: string | null,
  company?: string | null,
};

export type RestartSubscriptionMutation = {
  restartSubscription?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type PurchaseAddonsMutationVariables = {
  serviceId?: string | null,
  type?: AddonType | null,
};

export type PurchaseAddonsMutation = {
  purchaseAddons?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type ChangeBillingInvoiceMutationVariables = {
  serviceId?: string | null,
};

export type ChangeBillingInvoiceMutation = {
  changeBillingInvoice?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type CreateReviewRequestMutationVariables = {
  title?: string | null,
  description?: string | null,
  contentId?: string | null,
  reservationTime?: string | null,
};

export type CreateReviewRequestMutation = {
  createReviewRequest?:  {
    __typename: "ReviewRequestResponse",
    result: boolean,
    message?: string | null,
    data?:  {
      __typename: "ReviewRequest",
      serviceId: string,
      reqId: number,
      title: string,
      description?: string | null,
      contentId: string,
      status: ReviewRequestStatus,
      createdAt: string,
      updatedAt: string,
      reservationTime?: string | null,
      reservationStopTime?: string | null,
      reviewers?:  Array< {
        __typename: "ReviewRequestReviewer",
        id: string,
      } | null > | null,
      commentCount: number,
      authorId: string,
      viewerGroup: string,
    } | null,
  } | null,
};

export type UpdateReviewRequestMutationVariables = {
  serviceId?: string | null,
  reqId?: number | null,
  title?: string | null,
  description?: string | null,
};

export type UpdateReviewRequestMutation = {
  updateReviewRequest?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type UpdateReviewRequestReservationTimeMutationVariables = {
  serviceId?: string | null,
  reqId?: number | null,
  reservationTime?: string | null,
  reservationStopTime?: string | null,
};

export type UpdateReviewRequestReservationTimeMutation = {
  updateReviewRequestReservationTime?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type ToggleReviewRequestStatusMutationVariables = {
  serviceId?: string | null,
  reqId?: number | null,
};

export type ToggleReviewRequestStatusMutation = {
  toggleReviewRequestStatus?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type CloseReviewRequestAndPublishContentMutationVariables = {
  serviceId?: string | null,
  reqId?: number | null,
};

export type CloseReviewRequestAndPublishContentMutation = {
  closeReviewRequestAndPublishContent?: boolean | null,
};

export type CreateReviewRequestCommentMutationVariables = {
  serviceId?: string | null,
  reqId?: number | null,
  comment?: string | null,
};

export type CreateReviewRequestCommentMutation = {
  createReviewRequestComment?:  {
    __typename: "ReviewRequestEventResponse",
    result: boolean,
    message?: string | null,
    data?:  {
      __typename: "ReviewRequestEvent",
      serviceIdReqId: string,
      eventId: string,
      createdAt: string,
      updatedAt: string,
      type: ReviewRequestEventType,
      authorId: string,
      comment?: string | null,
      oldStatus?: ReviewRequestStatus | null,
      newStatus?: ReviewRequestStatus | null,
      oldTitle?: string | null,
      newTitle?: string | null,
      reservationTime?: string | null,
      reservationStopTime?: string | null,
      historyVersion?: string | null,
      reviewerId?: string | null,
    } | null,
  } | null,
};

export type DeleteReviewRequestCommentMutationVariables = {
  serviceId?: string | null,
  reqId?: number | null,
  eventId?: string | null,
};

export type DeleteReviewRequestCommentMutation = {
  deleteReviewRequestComment?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type AddReviewerMutationVariables = {
  serviceId?: string | null,
  reqId?: number | null,
  reviewerId?: string | null,
};

export type AddReviewerMutation = {
  addReviewer?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type RemoveReviewerMutationVariables = {
  serviceId?: string | null,
  reqId?: number | null,
  reviewerId?: string | null,
};

export type RemoveReviewerMutation = {
  removeReviewer?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type AcceptMemberInvitationMutation = {
  acceptMemberInvitation?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type SetSAMLConfigurationMutationVariables = {
  serviceId: string,
  idpMetadataURL?: string | null,
  idpMetadataXML?: string | null,
  defaultRoleId: string,
  samlOnly: boolean,
};

export type SetSAMLConfigurationMutation = {
  setSAMLConfiguration?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type DeleteSAMLConfigurationMutationVariables = {
  serviceId: string,
};

export type DeleteSAMLConfigurationMutation = {
  deleteSAMLConfiguration?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type AddCustomStatusMutationVariables = {
  apiId: string,
  key: string,
  description: string,
  name: string,
  behaviour: CustomStatusBehaviour,
  color: string,
};

export type AddCustomStatusMutation = {
  addCustomStatus?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type UpdateCustomStatusMutationVariables = {
  apiId: string,
  customStatusId: string,
  key: string,
  description: string,
  name: string,
  behaviour: CustomStatusBehaviour,
  color: string,
};

export type UpdateCustomStatusMutation = {
  updateCustomStatus?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type DeleteCustomStatusMutationVariables = {
  apiId: string,
  customStatusId: string,
};

export type DeleteCustomStatusMutation = {
  deleteCustomStatus?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type UpdateContentStatusMutationVariables = {
  contentId: string,
  customStatusId?: string | null,
  behaviour?: string | null,
};

export type UpdateContentStatusMutation = {
  updateContentStatus?: string | null,
};

export type CreateAccountMutationVariables = {
  value: Value,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type UpdateAccountLanguageMutationVariables = {
  value: Value,
};

export type UpdateAccountLanguageMutation = {
  updateAccountLanguage?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type UpdateAccountSurveyMutationVariables = {
  value: Value,
};

export type UpdateAccountSurveyMutation = {
  updateAccountSurvey?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type UpdateServiceLanguageMutationVariables = {
  serviceId: string,
  serviceLanguage: Language,
};

export type UpdateServiceLanguageMutation = {
  updateServiceLanguage?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type DeleteMediumTagMutationVariables = {
  serviceId: string,
  mediumTagId: string,
};

export type DeleteMediumTagMutation = {
  deleteMediumTag?: string | null,
};

export type UpdateMediumTagNameMutationVariables = {
  serviceId: string,
  mediumTagId: string,
  name: string,
};

export type UpdateMediumTagNameMutation = {
  updateMediumTagName?: string | null,
};

export type UpdateMediumTagsMutationVariables = {
  serviceId: string,
  mediumId: string,
  tags?: Array< string > | null,
};

export type UpdateMediumTagsMutation = {
  updateMediumTags?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type CreateMediumTagsMutationVariables = {
  serviceId: string,
  newMediumTags?: Array< NewMediumTagInput > | null,
};

export type CreateMediumTagsMutation = {
  createMediumTags?:  {
    __typename: "MediumTagsResponse",
    result: boolean,
    message?: string | null,
    data?:  Array< {
      __typename: "MediumTag",
      mediumTagId: string,
      serviceId: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      createdBy: string,
      updatedBy: string,
    } | null > | null,
  } | null,
};

export type BulkDeleteContentsMutationVariables = {
  apiId: string,
  contentIds?: Array< string > | null,
};

export type BulkDeleteContentsMutation = {
  bulkDeleteContents?:  {
    __typename: "BulkDeleteContentsResponse",
    result: boolean,
    message?: string | null,
    data?:  {
      __typename: "BulkDeleteContentsData",
      successIds?: Array< string | null > | null,
      failureIds?: Array< string | null > | null,
    } | null,
  } | null,
};

export type BulkUpdateContentsStatusMutationVariables = {
  apiId: string,
  contentStatus: BulkContentStatus,
  contentIds?: Array< string > | null,
};

export type BulkUpdateContentsStatusMutation = {
  bulkUpdateContentsStatus?:  {
    __typename: "BulkUpdateContentsStatusResponse",
    result: boolean,
    message?: string | null,
    data?:  {
      __typename: "BulkUpdateContentsStatusData",
      successIds?: Array< string | null > | null,
      failureIds?: Array< string | null > | null,
    } | null,
  } | null,
};

export type AnswerMemberInvitationMutationVariables = {
  accept: boolean,
  serviceId: string,
};

export type AnswerMemberInvitationMutation = {
  answerMemberInvitation?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type UpdateGlobalApiIpAddressRestrictionMutationVariables = {
  serviceId: string,
  ipList: string,
};

export type UpdateGlobalApiIpAddressRestrictionMutation = {
  updateGlobalApiIpAddressRestriction?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type CreateServiceQueryVariables = {
  name?: string | null,
  domain?: string | null,
  image?: string | null,
  planName?: string | null,
  trialLengthInSec?: number | null,
};

export type CreateServiceQuery = {
  createService?: string | null,
};

export type UpdateServiceQueryVariables = {
  serviceId?: string | null,
  ipList?: string | null,
  name?: string | null,
  image?: string | null,
};

export type UpdateServiceQuery = {
  updateService?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type UpdateServiceIdQueryVariables = {
  serviceId?: string | null,
  domain?: string | null,
};

export type UpdateServiceIdQuery = {
  updateServiceId?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type DeleteServiceQueryVariables = {
  serviceId?: string | null,
};

export type DeleteServiceQuery = {
  deleteService?: string | null,
};

export type AddMediumQueryVariables = {
  serviceId?: string | null,
  fileName?: string | null,
  identityId?: string | null,
};

export type AddMediumQuery = {
  addMedium?:  {
    __typename: "StringResponse",
    result: boolean,
    message?: string | null,
    data?: string | null,
  } | null,
};

export type DeleteMediumQueryVariables = {
  serviceId?: string | null,
  mediumId?: string | null,
  path?: string | null,
};

export type DeleteMediumQuery = {
  deleteMedium?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type CreateApiQueryVariables = {
  serviceId?: string | null,
  apiName?: string | null,
  apiEndpoint?: string | null,
  apiType?: string | null,
  apiFields?: string | null,
  customFields?: string | null,
  apiId?: string | null,
};

export type CreateApiQuery = {
  createApi?: string | null,
};

export type UpdateApiQueryVariables = {
  apiId?: string | null,
  name?: string | null,
  endpoint?: string | null,
  fields?: string | null,
  previewUrl?: string | null,
  previewLinkIsVisible?: boolean | null,
  sortOrderValue?: string | null,
  contentIdSetting?: string | null,
};

export type UpdateApiQuery = {
  updateApi?: string | null,
};

export type UpdateApiDescriptionQueryVariables = {
  apiId?: string | null,
  description?: string | null,
};

export type UpdateApiDescriptionQuery = {
  updateApiDescription?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type ResetImportProgressQueryVariables = {
  apiId?: string | null,
};

export type ResetImportProgressQuery = {
  resetImportProgress?: string | null,
};

export type DeleteApiQueryVariables = {
  apiId?: string | null,
};

export type DeleteApiQuery = {
  deleteApi?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type DeleteAllContentsQueryVariables = {
  apiId?: string | null,
};

export type DeleteAllContentsQuery = {
  deleteAllContents?: string | null,
};

export type CreateContentQueryVariables = {
  apiId?: string | null,
  contentId?: string | null,
  value?: string | null,
  contentStatus?: ContentStatus | null,
};

export type CreateContentQuery = {
  createContent?: string | null,
};

export type UpdateContentQueryVariables = {
  contentId?: string | null,
  value?: string | null,
  contentStatus?: ContentStatus | null,
};

export type UpdateContentQuery = {
  updateContent?: string | null,
};

export type SetReservationTimeQueryVariables = {
  contentId?: string | null,
  startDate?: string | null,
  stopDate?: string | null,
};

export type SetReservationTimeQuery = {
  setReservationTime?: string | null,
};

export type DeleteDraftQueryVariables = {
  contentId?: string | null,
};

export type DeleteDraftQuery = {
  deleteDraft?: string | null,
};

export type UpdateDraftKeyQueryVariables = {
  contentId?: string | null,
};

export type UpdateDraftKeyQuery = {
  updateDraftKey?: string | null,
};

export type UpdateContentIdQueryVariables = {
  contentId?: string | null,
  userDefinedId?: string | null,
};

export type UpdateContentIdQuery = {
  updateContentId?: string | null,
};

export type UpdatePublishedAtQueryVariables = {
  contentId?: string | null,
  publishedAt?: string | null,
};

export type UpdatePublishedAtQuery = {
  updatePublishedAt?: string | null,
};

export type UpdateContentSortOrderQueryVariables = {
  contentId?: string | null,
  sortOrderValue?: string | null,
};

export type UpdateContentSortOrderQuery = {
  updateContentSortOrder?: string | null,
};

export type DeleteContentQueryVariables = {
  contentId?: string | null,
};

export type DeleteContentQuery = {
  deleteContent?: string | null,
};

export type InviteMemberQueryVariables = {
  serviceId: string,
  email: string,
  roleIds?: Array< string > | null,
};

export type InviteMemberQuery = {
  inviteMember?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type InviteMembersQueryVariables = {
  serviceId: string,
  emails: Array< string >,
  roleIds: Array< string >,
};

export type InviteMembersQuery = {
  inviteMembers?: string | null,
};

export type ReinviteMemberQueryVariables = {
  serviceId: string,
  email: string,
};

export type ReinviteMemberQuery = {
  reinviteMember?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type CancelMemberInvitationQueryVariables = {
  serviceId: string,
  email: string,
};

export type CancelMemberInvitationQuery = {
  cancelMemberInvitation?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type VerifyMemberInvitationQueryVariables = {
  token: string,
};

export type VerifyMemberInvitationQuery = {
  verifyMemberInvitation?: string | null,
};

export type IsInvitedFromServicesQuery = {
  isInvitedFromServices?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type UpdateMemberRolesQueryVariables = {
  serviceId?: string | null,
  sub?: string | null,
  roleIds?: Array< string | null > | null,
};

export type UpdateMemberRolesQuery = {
  updateMemberRoles?: boolean | null,
};

export type DeleteMemberQueryVariables = {
  serviceId?: string | null,
  sub?: string | null,
};

export type DeleteMemberQuery = {
  deleteMember?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type CreateRoleQueryVariables = {
  serviceId?: string | null,
  name?: string | null,
  settings?: string | null,
};

export type CreateRoleQuery = {
  createRole?: string | null,
};

export type UpdateRoleQueryVariables = {
  serviceId?: string | null,
  roleId?: string | null,
  name?: string | null,
  settings?: string | null,
};

export type UpdateRoleQuery = {
  updateRole?: boolean | null,
};

export type DeleteRoleQueryVariables = {
  serviceId?: string | null,
  roleId?: string | null,
};

export type DeleteRoleQuery = {
  deleteRole?: boolean | null,
};

export type CreateWebhookSettingQueryVariables = {
  apiId?: string | null,
  target?: WebhookTarget | null,
  settingValues?: string | null,
  handleEvents?: Array< WebhookEvent | null > | null,
};

export type CreateWebhookSettingQuery = {
  createWebhookSetting?: string | null,
};

export type CreateWebhookMediumSettingQueryVariables = {
  apiId?: string | null,
  settingValues?: string | null,
  handleEvents?: Array< WebhookEvent | null > | null,
};

export type CreateWebhookMediumSettingQuery = {
  createWebhookMediumSetting?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type UpdateWebhookSettingQueryVariables = {
  apiId?: string | null,
  createdAt?: string | null,
  settingValues?: string | null,
  handleEvents?: Array< WebhookEvent | null > | null,
};

export type UpdateWebhookSettingQuery = {
  updateWebhookSetting?: string | null,
};

export type UpdateWebhookEnabledSettingQueryVariables = {
  apiId?: string | null,
  createdAt?: string | null,
  enabled?: boolean | null,
};

export type UpdateWebhookEnabledSettingQuery = {
  updateWebhookEnabledSetting?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type UpdateWebhookMediumSettingQueryVariables = {
  apiId?: string | null,
  createdAt?: string | null,
  settingValues?: string | null,
  handleEvents?: Array< WebhookEvent | null > | null,
};

export type UpdateWebhookMediumSettingQuery = {
  updateWebhookMediumSetting?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type DeleteWebhookSettingQueryVariables = {
  apiId?: string | null,
  createdAt?: string | null,
};

export type DeleteWebhookSettingQuery = {
  deleteWebhookSetting?: string | null,
};

export type DeleteWebhookMediumSettingQueryVariables = {
  apiId?: string | null,
  createdAt?: string | null,
};

export type DeleteWebhookMediumSettingQuery = {
  deleteWebhookMediumSetting?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type CreateCustomFieldQueryVariables = {
  apiId?: string | null,
  fieldId?: string | null,
  name?: string | null,
  fields?: string | null,
  position?: string | null,
};

export type CreateCustomFieldQuery = {
  createCustomField?: string | null,
};

export type UpdateCustomFieldQueryVariables = {
  apiId?: string | null,
  fieldId?: string | null,
  createdAt?: string | null,
  name?: string | null,
  fields?: string | null,
  position?: string | null,
};

export type UpdateCustomFieldQuery = {
  updateCustomField?: string | null,
};

export type DeleteCustomFieldQueryVariables = {
  apiId?: string | null,
  createdAt?: string | null,
};

export type DeleteCustomFieldQuery = {
  deleteCustomField?: string | null,
};

export type DeleteAccountQuery = {
  deleteAccount?: string | null,
};

export type GenerateUnusedContentIdQueryVariables = {
  apiId?: string | null,
};

export type GenerateUnusedContentIdQuery = {
  generateUnusedContentId?: string | null,
};

export type StartImportQueryVariables = {
  bucket?: string | null,
  identityId?: string | null,
  fileName?: string | null,
};

export type StartImportQuery = {
  startImport?:  {
    __typename: "NormalResponse",
    result: boolean,
    message?: string | null,
  } | null,
};

export type GetMetaQuery = {
  getMeta?: string | null,
};

export type FindServiceByDomainQueryVariables = {
  domain?: string | null,
};

export type FindServiceByDomainQuery = {
  findServiceByDomain?:  {
    __typename: "contents",
    partitionKey: string,
    sortKey: string,
    gsiPartitionKey1?: string | null,
    gsiSortKey1?: string | null,
    gsiPartitionKey2?: string | null,
    gsiSortKey2?: string | null,
    gsiPartitionKey3?: string | null,
    gsiSortKey3?: string | null,
    gsiSinglePartitionKey1?: string | null,
    serviceName?: string | null,
    serviceImage?:  {
      __typename: "S3Object",
      key?: string | null,
      level?: string | null,
      identityId?: string | null,
    } | null,
    servicePostCount?: number | null,
    serviceAllowedIpList?: string | null,
    requireMfa?: boolean | null,
    environment?:  {
      __typename: "Environment",
      name: string,
      parent: string,
    } | null,
    apiStopUntil?: string | null,
    serviceLanguage?: Language | null,
    serviceViewerGroup?: string | null,
    memberViewerGroup?: string | null,
    roleViewerGroup?: string | null,
    mediumViewerGroup?: string | null,
    reviewRequestViewerGroup?: string | null,
    apiViewerGroup?: string | null,
    contentViewerGroup?: string | null,
    viewerGroup?: string | null,
    apiName?: string | null,
    apiDescription?: string | null,
    apiType?: ApiType | null,
    apiFields?:  Array< {
      __typename: "Field",
      idValue: string,
      fieldId: string,
      name: string,
      kind: FieldKind,
      description?: string | null,
      required?: boolean | null,
      richEditorMultiParagraph?: boolean | null,
      richEditorImageSize?: boolean | null,
      referenceKey?: string | null,
      referenceDisplayItem?: string | null,
      dateFormat?: boolean | null,
      booleanInitialValue?: boolean | null,
      selectItems?:  Array< {
        __typename: "SelectItem",
        id: string,
        value?: string | null,
      } | null > | null,
      selectInitialValue?: Array< string | null > | null,
      richEditorOptions?: Array< string | null > | null,
      richEditorV2Options?: Array< string | null > | null,
      richEditorV2ColorList?:  Array< {
        __typename: "RichEditorV2Color",
        id: string,
        value: string,
      } | null > | null,
      customClassList?:  Array< {
        __typename: "CustomClass",
        id?: string | null,
        name?: string | null,
        value?: string | null,
      } | null > | null,
      multipleSelect?: boolean | null,
      iframeUrl?: string | null,
      customFieldCreatedAt?: string | null,
      customFieldDisplayItem?: string | null,
      customFieldCreatedAtList?: Array< string | null > | null,
      textSizeLimitValidation?:  {
        __typename: "TextSizeLimitValidation",
        textSize:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      numberSizeLimitValidation?:  {
        __typename: "NumberSizeLimitValidation",
        numberSize:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      relationListCountLimitValidation?:  {
        __typename: "RelationListCountLimitValidation",
        relationListCount:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      repeaterCountLimitValidation?:  {
        __typename: "RepeaterCountLimitValidation",
        repeatCount:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      imageSizeValidation?:  {
        __typename: "ImageSizeValidation",
        imageSize:  {
          __typename: "ImageSize",
          width?: number | null,
          height?: number | null,
        },
      } | null,
      mediaListLayout?: MediaListLayout | null,
      patternMatchValidation?:  {
        __typename: "PatternMatchValidation",
        regexp:  {
          __typename: "RegExp",
          pattern: string,
          flags?: string | null,
        },
      } | null,
      isUnique?: boolean | null,
    } | null > | null,
    apiPreviewUrl?: string | null,
    apiPreviewLinkIsVisible?: boolean | null,
    apiImportProgress?:  {
      __typename: "ApiImportProgress",
      status?: ProgressStatus | null,
      date?: string | null,
      message?: string | null,
    } | null,
    apiAllowedIpList?: string | null,
    contentIdSetting?:  {
      __typename: "ContentIdSetting",
      allowList: Array< ContentIdCharacter | null >,
      length?: number | null,
    } | null,
    contentValue?: string | null,
    draftValue?: string | null,
    closedValue?: string | null,
    contentDraftKey?: string | null,
    contentStatus?: ContentStatus | null,
    contentCustomStatusIds?: Array< string | null > | null,
    reservationTime?: string | null,
    reservationStopTime?: string | null,
    authType?: AuthType | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    publishedAt?: string | null,
    revisedAt?: string | null,
    closedAt?: string | null,
    editorId?: string | null,
    authorId?: string | null,
    inputSource?: InputSource | null,
    groups?: Array< string | null > | null,
    suspended?: boolean | null,
    relatedContentIds?:  Array< {
      __typename: "RelatedContentIds",
      partitionKey?: string | null,
      apiId?: string | null,
      gsiSinglePartitionKey1?: string | null,
      sortKey?: string | null,
    } | null > | null,
    customDomainImageHost?: string | null,
    customDomainFileHost?: string | null,
    _restricted?:  {
      __typename: "ContentsRestricted",
      type: ContentsRestrictedType,
      reason?: string | null,
    } | null,
  } | null,
};

export type CheckApiEndpointQueryVariables = {
  serviceId?: string | null,
  endpoint?: string | null,
};

export type CheckApiEndpointQuery = {
  checkApiEndpoint?: boolean | null,
};

export type FindApiQueryVariables = {
  apiId?: string | null,
};

export type FindApiQuery = {
  findApi?:  {
    __typename: "contents",
    partitionKey: string,
    sortKey: string,
    gsiPartitionKey1?: string | null,
    gsiSortKey1?: string | null,
    gsiPartitionKey2?: string | null,
    gsiSortKey2?: string | null,
    gsiPartitionKey3?: string | null,
    gsiSortKey3?: string | null,
    gsiSinglePartitionKey1?: string | null,
    serviceName?: string | null,
    serviceImage?:  {
      __typename: "S3Object",
      key?: string | null,
      level?: string | null,
      identityId?: string | null,
    } | null,
    servicePostCount?: number | null,
    serviceAllowedIpList?: string | null,
    requireMfa?: boolean | null,
    environment?:  {
      __typename: "Environment",
      name: string,
      parent: string,
    } | null,
    apiStopUntil?: string | null,
    serviceLanguage?: Language | null,
    serviceViewerGroup?: string | null,
    memberViewerGroup?: string | null,
    roleViewerGroup?: string | null,
    mediumViewerGroup?: string | null,
    reviewRequestViewerGroup?: string | null,
    apiViewerGroup?: string | null,
    contentViewerGroup?: string | null,
    viewerGroup?: string | null,
    apiName?: string | null,
    apiDescription?: string | null,
    apiType?: ApiType | null,
    apiFields?:  Array< {
      __typename: "Field",
      idValue: string,
      fieldId: string,
      name: string,
      kind: FieldKind,
      description?: string | null,
      required?: boolean | null,
      richEditorMultiParagraph?: boolean | null,
      richEditorImageSize?: boolean | null,
      referenceKey?: string | null,
      referenceDisplayItem?: string | null,
      dateFormat?: boolean | null,
      booleanInitialValue?: boolean | null,
      selectItems?:  Array< {
        __typename: "SelectItem",
        id: string,
        value?: string | null,
      } | null > | null,
      selectInitialValue?: Array< string | null > | null,
      richEditorOptions?: Array< string | null > | null,
      richEditorV2Options?: Array< string | null > | null,
      richEditorV2ColorList?:  Array< {
        __typename: "RichEditorV2Color",
        id: string,
        value: string,
      } | null > | null,
      customClassList?:  Array< {
        __typename: "CustomClass",
        id?: string | null,
        name?: string | null,
        value?: string | null,
      } | null > | null,
      multipleSelect?: boolean | null,
      iframeUrl?: string | null,
      customFieldCreatedAt?: string | null,
      customFieldDisplayItem?: string | null,
      customFieldCreatedAtList?: Array< string | null > | null,
      textSizeLimitValidation?:  {
        __typename: "TextSizeLimitValidation",
        textSize:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      numberSizeLimitValidation?:  {
        __typename: "NumberSizeLimitValidation",
        numberSize:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      relationListCountLimitValidation?:  {
        __typename: "RelationListCountLimitValidation",
        relationListCount:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      repeaterCountLimitValidation?:  {
        __typename: "RepeaterCountLimitValidation",
        repeatCount:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      imageSizeValidation?:  {
        __typename: "ImageSizeValidation",
        imageSize:  {
          __typename: "ImageSize",
          width?: number | null,
          height?: number | null,
        },
      } | null,
      mediaListLayout?: MediaListLayout | null,
      patternMatchValidation?:  {
        __typename: "PatternMatchValidation",
        regexp:  {
          __typename: "RegExp",
          pattern: string,
          flags?: string | null,
        },
      } | null,
      isUnique?: boolean | null,
    } | null > | null,
    apiPreviewUrl?: string | null,
    apiPreviewLinkIsVisible?: boolean | null,
    apiImportProgress?:  {
      __typename: "ApiImportProgress",
      status?: ProgressStatus | null,
      date?: string | null,
      message?: string | null,
    } | null,
    apiAllowedIpList?: string | null,
    contentIdSetting?:  {
      __typename: "ContentIdSetting",
      allowList: Array< ContentIdCharacter | null >,
      length?: number | null,
    } | null,
    contentValue?: string | null,
    draftValue?: string | null,
    closedValue?: string | null,
    contentDraftKey?: string | null,
    contentStatus?: ContentStatus | null,
    contentCustomStatusIds?: Array< string | null > | null,
    reservationTime?: string | null,
    reservationStopTime?: string | null,
    authType?: AuthType | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    publishedAt?: string | null,
    revisedAt?: string | null,
    closedAt?: string | null,
    editorId?: string | null,
    authorId?: string | null,
    inputSource?: InputSource | null,
    groups?: Array< string | null > | null,
    suspended?: boolean | null,
    relatedContentIds?:  Array< {
      __typename: "RelatedContentIds",
      partitionKey?: string | null,
      apiId?: string | null,
      gsiSinglePartitionKey1?: string | null,
      sortKey?: string | null,
    } | null > | null,
    customDomainImageHost?: string | null,
    customDomainFileHost?: string | null,
    _restricted?:  {
      __typename: "ContentsRestricted",
      type: ContentsRestrictedType,
      reason?: string | null,
    } | null,
  } | null,
};

export type FindApisQueryVariables = {
  serviceId?: string | null,
};

export type FindApisQuery = {
  findApis?:  Array< {
    __typename: "contents",
    partitionKey: string,
    sortKey: string,
    gsiPartitionKey1?: string | null,
    gsiSortKey1?: string | null,
    gsiPartitionKey2?: string | null,
    gsiSortKey2?: string | null,
    gsiPartitionKey3?: string | null,
    gsiSortKey3?: string | null,
    gsiSinglePartitionKey1?: string | null,
    serviceName?: string | null,
    serviceImage?:  {
      __typename: "S3Object",
      key?: string | null,
      level?: string | null,
      identityId?: string | null,
    } | null,
    servicePostCount?: number | null,
    serviceAllowedIpList?: string | null,
    requireMfa?: boolean | null,
    environment?:  {
      __typename: "Environment",
      name: string,
      parent: string,
    } | null,
    apiStopUntil?: string | null,
    serviceLanguage?: Language | null,
    serviceViewerGroup?: string | null,
    memberViewerGroup?: string | null,
    roleViewerGroup?: string | null,
    mediumViewerGroup?: string | null,
    reviewRequestViewerGroup?: string | null,
    apiViewerGroup?: string | null,
    contentViewerGroup?: string | null,
    viewerGroup?: string | null,
    apiName?: string | null,
    apiDescription?: string | null,
    apiType?: ApiType | null,
    apiFields?:  Array< {
      __typename: "Field",
      idValue: string,
      fieldId: string,
      name: string,
      kind: FieldKind,
      description?: string | null,
      required?: boolean | null,
      richEditorMultiParagraph?: boolean | null,
      richEditorImageSize?: boolean | null,
      referenceKey?: string | null,
      referenceDisplayItem?: string | null,
      dateFormat?: boolean | null,
      booleanInitialValue?: boolean | null,
      selectItems?:  Array< {
        __typename: "SelectItem",
        id: string,
        value?: string | null,
      } | null > | null,
      selectInitialValue?: Array< string | null > | null,
      richEditorOptions?: Array< string | null > | null,
      richEditorV2Options?: Array< string | null > | null,
      richEditorV2ColorList?:  Array< {
        __typename: "RichEditorV2Color",
        id: string,
        value: string,
      } | null > | null,
      customClassList?:  Array< {
        __typename: "CustomClass",
        id?: string | null,
        name?: string | null,
        value?: string | null,
      } | null > | null,
      multipleSelect?: boolean | null,
      iframeUrl?: string | null,
      customFieldCreatedAt?: string | null,
      customFieldDisplayItem?: string | null,
      customFieldCreatedAtList?: Array< string | null > | null,
      textSizeLimitValidation?:  {
        __typename: "TextSizeLimitValidation",
        textSize:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      numberSizeLimitValidation?:  {
        __typename: "NumberSizeLimitValidation",
        numberSize:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      relationListCountLimitValidation?:  {
        __typename: "RelationListCountLimitValidation",
        relationListCount:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      repeaterCountLimitValidation?:  {
        __typename: "RepeaterCountLimitValidation",
        repeatCount:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      imageSizeValidation?:  {
        __typename: "ImageSizeValidation",
        imageSize:  {
          __typename: "ImageSize",
          width?: number | null,
          height?: number | null,
        },
      } | null,
      mediaListLayout?: MediaListLayout | null,
      patternMatchValidation?:  {
        __typename: "PatternMatchValidation",
        regexp:  {
          __typename: "RegExp",
          pattern: string,
          flags?: string | null,
        },
      } | null,
      isUnique?: boolean | null,
    } | null > | null,
    apiPreviewUrl?: string | null,
    apiPreviewLinkIsVisible?: boolean | null,
    apiImportProgress?:  {
      __typename: "ApiImportProgress",
      status?: ProgressStatus | null,
      date?: string | null,
      message?: string | null,
    } | null,
    apiAllowedIpList?: string | null,
    contentIdSetting?:  {
      __typename: "ContentIdSetting",
      allowList: Array< ContentIdCharacter | null >,
      length?: number | null,
    } | null,
    contentValue?: string | null,
    draftValue?: string | null,
    closedValue?: string | null,
    contentDraftKey?: string | null,
    contentStatus?: ContentStatus | null,
    contentCustomStatusIds?: Array< string | null > | null,
    reservationTime?: string | null,
    reservationStopTime?: string | null,
    authType?: AuthType | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    publishedAt?: string | null,
    revisedAt?: string | null,
    closedAt?: string | null,
    editorId?: string | null,
    authorId?: string | null,
    inputSource?: InputSource | null,
    groups?: Array< string | null > | null,
    suspended?: boolean | null,
    relatedContentIds?:  Array< {
      __typename: "RelatedContentIds",
      partitionKey?: string | null,
      apiId?: string | null,
      gsiSinglePartitionKey1?: string | null,
      sortKey?: string | null,
    } | null > | null,
    customDomainImageHost?: string | null,
    customDomainFileHost?: string | null,
    _restricted?:  {
      __typename: "ContentsRestricted",
      type: ContentsRestrictedType,
      reason?: string | null,
    } | null,
  } | null > | null,
};

export type FindCustomFieldsQueryVariables = {
  apiId?: string | null,
};

export type FindCustomFieldsQuery = {
  findCustomFields?:  Array< {
    __typename: "CustomField",
    apiId: string,
    createdAt: string,
    fieldId: string,
    name: string,
    fields:  Array< {
      __typename: "Field",
      idValue: string,
      fieldId: string,
      name: string,
      kind: FieldKind,
      description?: string | null,
      required?: boolean | null,
      richEditorMultiParagraph?: boolean | null,
      richEditorImageSize?: boolean | null,
      referenceKey?: string | null,
      referenceDisplayItem?: string | null,
      dateFormat?: boolean | null,
      booleanInitialValue?: boolean | null,
      selectItems?:  Array< {
        __typename: "SelectItem",
        id: string,
        value?: string | null,
      } | null > | null,
      selectInitialValue?: Array< string | null > | null,
      richEditorOptions?: Array< string | null > | null,
      richEditorV2Options?: Array< string | null > | null,
      richEditorV2ColorList?:  Array< {
        __typename: "RichEditorV2Color",
        id: string,
        value: string,
      } | null > | null,
      customClassList?:  Array< {
        __typename: "CustomClass",
        id?: string | null,
        name?: string | null,
        value?: string | null,
      } | null > | null,
      multipleSelect?: boolean | null,
      iframeUrl?: string | null,
      customFieldCreatedAt?: string | null,
      customFieldDisplayItem?: string | null,
      customFieldCreatedAtList?: Array< string | null > | null,
      textSizeLimitValidation?:  {
        __typename: "TextSizeLimitValidation",
        textSize:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      numberSizeLimitValidation?:  {
        __typename: "NumberSizeLimitValidation",
        numberSize:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      relationListCountLimitValidation?:  {
        __typename: "RelationListCountLimitValidation",
        relationListCount:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      repeaterCountLimitValidation?:  {
        __typename: "RepeaterCountLimitValidation",
        repeatCount:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      imageSizeValidation?:  {
        __typename: "ImageSizeValidation",
        imageSize:  {
          __typename: "ImageSize",
          width?: number | null,
          height?: number | null,
        },
      } | null,
      mediaListLayout?: MediaListLayout | null,
      patternMatchValidation?:  {
        __typename: "PatternMatchValidation",
        regexp:  {
          __typename: "RegExp",
          pattern: string,
          flags?: string | null,
        },
      } | null,
      isUnique?: boolean | null,
    } | null >,
    position?: Array< Array< string | null > | null > | null,
    updatedAt?: string | null,
    viewerGroup?: string | null,
  } | null > | null,
};

export type ReadApiKeyQueryVariables = {
  apiKeyId?: string | null,
  serviceId?: string | null,
  value?: string | null,
};

export type ReadApiKeyQuery = {
  readApiKey?:  Array< {
    __typename: "ApiKey",
    apiKeyId: string,
    serviceId: string,
    value: string,
    label?: string | null,
    createdAt: string,
    updatedAt: string,
    createdBy?: string | null,
    updatedBy?: string | null,
    defaultPolicy:  {
      __typename: "ApiKeyPolicy",
      get: boolean,
      getAllDraft: boolean,
      getAllClosed?: boolean | null,
      post: boolean,
      put: boolean,
      patch: boolean,
      delete: boolean,
    },
    priorityPolicies?:  Array< {
      __typename: "ApiKeyPriorityPolicy",
      apiId: string,
      policy:  {
        __typename: "ApiKeyPolicy",
        get: boolean,
        getAllDraft: boolean,
        getAllClosed?: boolean | null,
        post: boolean,
        put: boolean,
        patch: boolean,
        delete: boolean,
      },
    } | null > | null,
    managementPolicy?:  {
      __typename: "ManagementPolicy",
      getContent?: boolean | null,
      patchContentCreatedBy?: boolean | null,
      patchContentStatus?: boolean | null,
      getMedia?: boolean | null,
      postMedia?: boolean | null,
      deleteMedia?: boolean | null,
    } | null,
  } | null > | null,
};

export type FindWebhookSettingsQueryVariables = {
  apiId?: string | null,
};

export type FindWebhookSettingsQuery = {
  findWebhookSettings?:  Array< {
    __typename: "WebhookSetting",
    apiId: string,
    createdAt: string,
    target: WebhookTarget,
    settingValues?: string | null,
    handleEvents?: Array< WebhookEvent | null > | null,
    serviceId: string,
    updatedAt?: string | null,
    viewerGroup?: string | null,
    enabled?: boolean | null,
  } | null > | null,
};

export type FindWebhookMediumSettingsQueryVariables = {
  apiId?: string | null,
};

export type FindWebhookMediumSettingsQuery = {
  findWebhookMediumSettings?:  Array< {
    __typename: "WebhookSetting",
    apiId: string,
    createdAt: string,
    target: WebhookTarget,
    settingValues?: string | null,
    handleEvents?: Array< WebhookEvent | null > | null,
    serviceId: string,
    updatedAt?: string | null,
    viewerGroup?: string | null,
    enabled?: boolean | null,
  } | null > | null,
};

export type GetWebhookSettingsCountQueryVariables = {
  serviceId?: string | null,
};

export type GetWebhookSettingsCountQuery = {
  getWebhookSettingsCount?: number | null,
};

export type FindContentQueryVariables = {
  apiId?: string | null,
  userDefinedId?: string | null,
};

export type FindContentQuery = {
  findContent?:  {
    __typename: "contents",
    partitionKey: string,
    sortKey: string,
    gsiPartitionKey1?: string | null,
    gsiSortKey1?: string | null,
    gsiPartitionKey2?: string | null,
    gsiSortKey2?: string | null,
    gsiPartitionKey3?: string | null,
    gsiSortKey3?: string | null,
    gsiSinglePartitionKey1?: string | null,
    serviceName?: string | null,
    serviceImage?:  {
      __typename: "S3Object",
      key?: string | null,
      level?: string | null,
      identityId?: string | null,
    } | null,
    servicePostCount?: number | null,
    serviceAllowedIpList?: string | null,
    requireMfa?: boolean | null,
    environment?:  {
      __typename: "Environment",
      name: string,
      parent: string,
    } | null,
    apiStopUntil?: string | null,
    serviceLanguage?: Language | null,
    serviceViewerGroup?: string | null,
    memberViewerGroup?: string | null,
    roleViewerGroup?: string | null,
    mediumViewerGroup?: string | null,
    reviewRequestViewerGroup?: string | null,
    apiViewerGroup?: string | null,
    contentViewerGroup?: string | null,
    viewerGroup?: string | null,
    apiName?: string | null,
    apiDescription?: string | null,
    apiType?: ApiType | null,
    apiFields?:  Array< {
      __typename: "Field",
      idValue: string,
      fieldId: string,
      name: string,
      kind: FieldKind,
      description?: string | null,
      required?: boolean | null,
      richEditorMultiParagraph?: boolean | null,
      richEditorImageSize?: boolean | null,
      referenceKey?: string | null,
      referenceDisplayItem?: string | null,
      dateFormat?: boolean | null,
      booleanInitialValue?: boolean | null,
      selectItems?:  Array< {
        __typename: "SelectItem",
        id: string,
        value?: string | null,
      } | null > | null,
      selectInitialValue?: Array< string | null > | null,
      richEditorOptions?: Array< string | null > | null,
      richEditorV2Options?: Array< string | null > | null,
      richEditorV2ColorList?:  Array< {
        __typename: "RichEditorV2Color",
        id: string,
        value: string,
      } | null > | null,
      customClassList?:  Array< {
        __typename: "CustomClass",
        id?: string | null,
        name?: string | null,
        value?: string | null,
      } | null > | null,
      multipleSelect?: boolean | null,
      iframeUrl?: string | null,
      customFieldCreatedAt?: string | null,
      customFieldDisplayItem?: string | null,
      customFieldCreatedAtList?: Array< string | null > | null,
      textSizeLimitValidation?:  {
        __typename: "TextSizeLimitValidation",
        textSize:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      numberSizeLimitValidation?:  {
        __typename: "NumberSizeLimitValidation",
        numberSize:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      relationListCountLimitValidation?:  {
        __typename: "RelationListCountLimitValidation",
        relationListCount:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      repeaterCountLimitValidation?:  {
        __typename: "RepeaterCountLimitValidation",
        repeatCount:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      imageSizeValidation?:  {
        __typename: "ImageSizeValidation",
        imageSize:  {
          __typename: "ImageSize",
          width?: number | null,
          height?: number | null,
        },
      } | null,
      mediaListLayout?: MediaListLayout | null,
      patternMatchValidation?:  {
        __typename: "PatternMatchValidation",
        regexp:  {
          __typename: "RegExp",
          pattern: string,
          flags?: string | null,
        },
      } | null,
      isUnique?: boolean | null,
    } | null > | null,
    apiPreviewUrl?: string | null,
    apiPreviewLinkIsVisible?: boolean | null,
    apiImportProgress?:  {
      __typename: "ApiImportProgress",
      status?: ProgressStatus | null,
      date?: string | null,
      message?: string | null,
    } | null,
    apiAllowedIpList?: string | null,
    contentIdSetting?:  {
      __typename: "ContentIdSetting",
      allowList: Array< ContentIdCharacter | null >,
      length?: number | null,
    } | null,
    contentValue?: string | null,
    draftValue?: string | null,
    closedValue?: string | null,
    contentDraftKey?: string | null,
    contentStatus?: ContentStatus | null,
    contentCustomStatusIds?: Array< string | null > | null,
    reservationTime?: string | null,
    reservationStopTime?: string | null,
    authType?: AuthType | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    publishedAt?: string | null,
    revisedAt?: string | null,
    closedAt?: string | null,
    editorId?: string | null,
    authorId?: string | null,
    inputSource?: InputSource | null,
    groups?: Array< string | null > | null,
    suspended?: boolean | null,
    relatedContentIds?:  Array< {
      __typename: "RelatedContentIds",
      partitionKey?: string | null,
      apiId?: string | null,
      gsiSinglePartitionKey1?: string | null,
      sortKey?: string | null,
    } | null > | null,
    customDomainImageHost?: string | null,
    customDomainFileHost?: string | null,
    _restricted?:  {
      __typename: "ContentsRestricted",
      type: ContentsRestrictedType,
      reason?: string | null,
    } | null,
  } | null,
};

export type FindHistoriesQueryVariables = {
  contentId?: string | null,
  nextToken?: string | null,
};

export type FindHistoriesQuery = {
  findHistories?: string | null,
};

export type FindContentCreatorsQueryVariables = {
  apiId?: string | null,
};

export type FindContentCreatorsQuery = {
  findContentCreators?:  Array< {
    __typename: "ContentEditor",
    username: string,
    email?: string | null,
    name?: string | null,
    isRemoved: boolean,
    isApi: boolean,
  } | null > | null,
};

export type FindContentEditorsQueryVariables = {
  apiId?: string | null,
};

export type FindContentEditorsQuery = {
  findContentEditors?:  Array< {
    __typename: "ContentEditor",
    username: string,
    email?: string | null,
    name?: string | null,
    isRemoved: boolean,
    isApi: boolean,
  } | null > | null,
};

export type GetMyRolesQueryVariables = {
  serviceId?: string | null,
};

export type GetMyRolesQuery = {
  getMyRoles?:  Array< {
    __typename: "Role",
    serviceId: string,
    roleId: string,
    name: string,
    isAdmin: boolean,
    createdAt: string,
    updatedAt: string,
    viewerGroup: string,
    rolePermission:  {
      __typename: "RolePermission",
      create: boolean,
      read?: boolean | null,
      readLevel?: RoleReadLevel | null,
      update: boolean,
      delete: boolean,
    },
    memberPermission:  {
      __typename: "MemberPermission",
      create: boolean,
      read:  {
        __typename: "MemberReadPermissionDetail",
        defaultRead: boolean,
        allowMemberList?: Array< string | null > | null,
        sameRole?: boolean | null,
      },
      update: boolean,
      delete: boolean,
    },
    usagePermission:  {
      __typename: "ReadPermission",
      read: boolean,
    },
    billingPermission:  {
      __typename: "ReadUpdatePermission",
      read: boolean,
      update: boolean,
    },
    environmentPermission:  {
      __typename: "Permission",
      create: boolean,
      read: boolean,
      update: boolean,
      delete: boolean,
    },
    apiKeyPermission:  {
      __typename: "ReadPermission",
      read: boolean,
    },
    reviewRequestPermission:  {
      __typename: "ReviewRequestPermission",
      readLevel: ReviewRequestReadLevel,
      create: boolean,
      updateLevel: ReviewRequestUpdateLevel,
    },
    mediumPermission:  {
      __typename: "MediumPermission",
      create: boolean,
      readLevel: MediumReadLevel,
      update: boolean,
      delete: boolean,
    },
    apiPermission:  {
      __typename: "CreatePermission",
      create: boolean,
    },
    defaultContentPermission:  {
      __typename: "ContentPermission",
      apiRead: boolean,
      apiUpdate: boolean,
      apiDelete: boolean,
      contentCreate:  {
        __typename: "ContentPermissionContentCreate",
        draft: boolean,
        publish: boolean,
      },
      contentReadLevel: ContentReadLevel,
      contentUpdate:  {
        __typename: "ContentPermissionContentUpdate",
        saveDraft: boolean,
        removeDraft: boolean,
        changeContentIdUnpublished: boolean,
        changeContentIdPublished: boolean,
        reorderUnpublished: boolean,
        reorderPublished: boolean,
        changePublicationDateUnpublished: boolean,
        changePublicationDatePublished: boolean,
        changeAuthor: boolean,
        changeDraftKey: boolean,
        publish: boolean,
        unpublish: boolean,
        revertToDraft: boolean,
        revertToDraftUnpublished: boolean,
      },
      contentDelete: boolean,
      showDeveloperMenu: boolean,
    },
    exceptionContentPermissions?:  Array< {
      __typename: "ExceptionContentPermission",
      apiId: string,
      permission?:  {
        __typename: "ContentPermission",
        apiRead: boolean,
        apiUpdate: boolean,
        apiDelete: boolean,
        contentCreate:  {
          __typename: "ContentPermissionContentCreate",
          draft: boolean,
          publish: boolean,
        },
        contentReadLevel: ContentReadLevel,
        contentUpdate:  {
          __typename: "ContentPermissionContentUpdate",
          saveDraft: boolean,
          removeDraft: boolean,
          changeContentIdUnpublished: boolean,
          changeContentIdPublished: boolean,
          reorderUnpublished: boolean,
          reorderPublished: boolean,
          changePublicationDateUnpublished: boolean,
          changePublicationDatePublished: boolean,
          changeAuthor: boolean,
          changeDraftKey: boolean,
          publish: boolean,
          unpublish: boolean,
          revertToDraft: boolean,
          revertToDraftUnpublished: boolean,
        },
        contentDelete: boolean,
        showDeveloperMenu: boolean,
      } | null,
      reviewRequestPermission?:  {
        __typename: "ReviewRequestPermission",
        readLevel: ReviewRequestReadLevel,
        create: boolean,
        updateLevel: ReviewRequestUpdateLevel,
      } | null,
    } | null > | null,
  } | null > | null,
};

export type ListServiceRolesQueryVariables = {
  serviceId?: string | null,
};

export type ListServiceRolesQuery = {
  listServiceRoles?:  Array< {
    __typename: "Role",
    serviceId: string,
    roleId: string,
    name: string,
    isAdmin: boolean,
    createdAt: string,
    updatedAt: string,
    viewerGroup: string,
    rolePermission:  {
      __typename: "RolePermission",
      create: boolean,
      read?: boolean | null,
      readLevel?: RoleReadLevel | null,
      update: boolean,
      delete: boolean,
    },
    memberPermission:  {
      __typename: "MemberPermission",
      create: boolean,
      read:  {
        __typename: "MemberReadPermissionDetail",
        defaultRead: boolean,
        allowMemberList?: Array< string | null > | null,
        sameRole?: boolean | null,
      },
      update: boolean,
      delete: boolean,
    },
    usagePermission:  {
      __typename: "ReadPermission",
      read: boolean,
    },
    billingPermission:  {
      __typename: "ReadUpdatePermission",
      read: boolean,
      update: boolean,
    },
    environmentPermission:  {
      __typename: "Permission",
      create: boolean,
      read: boolean,
      update: boolean,
      delete: boolean,
    },
    apiKeyPermission:  {
      __typename: "ReadPermission",
      read: boolean,
    },
    reviewRequestPermission:  {
      __typename: "ReviewRequestPermission",
      readLevel: ReviewRequestReadLevel,
      create: boolean,
      updateLevel: ReviewRequestUpdateLevel,
    },
    mediumPermission:  {
      __typename: "MediumPermission",
      create: boolean,
      readLevel: MediumReadLevel,
      update: boolean,
      delete: boolean,
    },
    apiPermission:  {
      __typename: "CreatePermission",
      create: boolean,
    },
    defaultContentPermission:  {
      __typename: "ContentPermission",
      apiRead: boolean,
      apiUpdate: boolean,
      apiDelete: boolean,
      contentCreate:  {
        __typename: "ContentPermissionContentCreate",
        draft: boolean,
        publish: boolean,
      },
      contentReadLevel: ContentReadLevel,
      contentUpdate:  {
        __typename: "ContentPermissionContentUpdate",
        saveDraft: boolean,
        removeDraft: boolean,
        changeContentIdUnpublished: boolean,
        changeContentIdPublished: boolean,
        reorderUnpublished: boolean,
        reorderPublished: boolean,
        changePublicationDateUnpublished: boolean,
        changePublicationDatePublished: boolean,
        changeAuthor: boolean,
        changeDraftKey: boolean,
        publish: boolean,
        unpublish: boolean,
        revertToDraft: boolean,
        revertToDraftUnpublished: boolean,
      },
      contentDelete: boolean,
      showDeveloperMenu: boolean,
    },
    exceptionContentPermissions?:  Array< {
      __typename: "ExceptionContentPermission",
      apiId: string,
      permission?:  {
        __typename: "ContentPermission",
        apiRead: boolean,
        apiUpdate: boolean,
        apiDelete: boolean,
        contentCreate:  {
          __typename: "ContentPermissionContentCreate",
          draft: boolean,
          publish: boolean,
        },
        contentReadLevel: ContentReadLevel,
        contentUpdate:  {
          __typename: "ContentPermissionContentUpdate",
          saveDraft: boolean,
          removeDraft: boolean,
          changeContentIdUnpublished: boolean,
          changeContentIdPublished: boolean,
          reorderUnpublished: boolean,
          reorderPublished: boolean,
          changePublicationDateUnpublished: boolean,
          changePublicationDatePublished: boolean,
          changeAuthor: boolean,
          changeDraftKey: boolean,
          publish: boolean,
          unpublish: boolean,
          revertToDraft: boolean,
          revertToDraftUnpublished: boolean,
        },
        contentDelete: boolean,
        showDeveloperMenu: boolean,
      } | null,
      reviewRequestPermission?:  {
        __typename: "ReviewRequestPermission",
        readLevel: ReviewRequestReadLevel,
        create: boolean,
        updateLevel: ReviewRequestUpdateLevel,
      } | null,
    } | null > | null,
  } | null > | null,
};

export type GetServiceRoleQueryVariables = {
  serviceId?: string | null,
  roleId?: string | null,
};

export type GetServiceRoleQuery = {
  getServiceRole?:  {
    __typename: "Role",
    serviceId: string,
    roleId: string,
    name: string,
    isAdmin: boolean,
    createdAt: string,
    updatedAt: string,
    viewerGroup: string,
    rolePermission:  {
      __typename: "RolePermission",
      create: boolean,
      read?: boolean | null,
      readLevel?: RoleReadLevel | null,
      update: boolean,
      delete: boolean,
    },
    memberPermission:  {
      __typename: "MemberPermission",
      create: boolean,
      read:  {
        __typename: "MemberReadPermissionDetail",
        defaultRead: boolean,
        allowMemberList?: Array< string | null > | null,
        sameRole?: boolean | null,
      },
      update: boolean,
      delete: boolean,
    },
    usagePermission:  {
      __typename: "ReadPermission",
      read: boolean,
    },
    billingPermission:  {
      __typename: "ReadUpdatePermission",
      read: boolean,
      update: boolean,
    },
    environmentPermission:  {
      __typename: "Permission",
      create: boolean,
      read: boolean,
      update: boolean,
      delete: boolean,
    },
    apiKeyPermission:  {
      __typename: "ReadPermission",
      read: boolean,
    },
    reviewRequestPermission:  {
      __typename: "ReviewRequestPermission",
      readLevel: ReviewRequestReadLevel,
      create: boolean,
      updateLevel: ReviewRequestUpdateLevel,
    },
    mediumPermission:  {
      __typename: "MediumPermission",
      create: boolean,
      readLevel: MediumReadLevel,
      update: boolean,
      delete: boolean,
    },
    apiPermission:  {
      __typename: "CreatePermission",
      create: boolean,
    },
    defaultContentPermission:  {
      __typename: "ContentPermission",
      apiRead: boolean,
      apiUpdate: boolean,
      apiDelete: boolean,
      contentCreate:  {
        __typename: "ContentPermissionContentCreate",
        draft: boolean,
        publish: boolean,
      },
      contentReadLevel: ContentReadLevel,
      contentUpdate:  {
        __typename: "ContentPermissionContentUpdate",
        saveDraft: boolean,
        removeDraft: boolean,
        changeContentIdUnpublished: boolean,
        changeContentIdPublished: boolean,
        reorderUnpublished: boolean,
        reorderPublished: boolean,
        changePublicationDateUnpublished: boolean,
        changePublicationDatePublished: boolean,
        changeAuthor: boolean,
        changeDraftKey: boolean,
        publish: boolean,
        unpublish: boolean,
        revertToDraft: boolean,
        revertToDraftUnpublished: boolean,
      },
      contentDelete: boolean,
      showDeveloperMenu: boolean,
    },
    exceptionContentPermissions?:  Array< {
      __typename: "ExceptionContentPermission",
      apiId: string,
      permission?:  {
        __typename: "ContentPermission",
        apiRead: boolean,
        apiUpdate: boolean,
        apiDelete: boolean,
        contentCreate:  {
          __typename: "ContentPermissionContentCreate",
          draft: boolean,
          publish: boolean,
        },
        contentReadLevel: ContentReadLevel,
        contentUpdate:  {
          __typename: "ContentPermissionContentUpdate",
          saveDraft: boolean,
          removeDraft: boolean,
          changeContentIdUnpublished: boolean,
          changeContentIdPublished: boolean,
          reorderUnpublished: boolean,
          reorderPublished: boolean,
          changePublicationDateUnpublished: boolean,
          changePublicationDatePublished: boolean,
          changeAuthor: boolean,
          changeDraftKey: boolean,
          publish: boolean,
          unpublish: boolean,
          revertToDraft: boolean,
          revertToDraftUnpublished: boolean,
        },
        contentDelete: boolean,
        showDeveloperMenu: boolean,
      } | null,
      reviewRequestPermission?:  {
        __typename: "ReviewRequestPermission",
        readLevel: ReviewRequestReadLevel,
        create: boolean,
        updateLevel: ReviewRequestUpdateLevel,
      } | null,
    } | null > | null,
  } | null,
};

export type GetMyServicesQuery = {
  getMyServices?:  Array< {
    __typename: "contents",
    partitionKey: string,
    sortKey: string,
    gsiPartitionKey1?: string | null,
    gsiSortKey1?: string | null,
    gsiPartitionKey2?: string | null,
    gsiSortKey2?: string | null,
    gsiPartitionKey3?: string | null,
    gsiSortKey3?: string | null,
    gsiSinglePartitionKey1?: string | null,
    serviceName?: string | null,
    serviceImage?:  {
      __typename: "S3Object",
      key?: string | null,
      level?: string | null,
      identityId?: string | null,
    } | null,
    servicePostCount?: number | null,
    serviceAllowedIpList?: string | null,
    requireMfa?: boolean | null,
    environment?:  {
      __typename: "Environment",
      name: string,
      parent: string,
    } | null,
    apiStopUntil?: string | null,
    serviceLanguage?: Language | null,
    serviceViewerGroup?: string | null,
    memberViewerGroup?: string | null,
    roleViewerGroup?: string | null,
    mediumViewerGroup?: string | null,
    reviewRequestViewerGroup?: string | null,
    apiViewerGroup?: string | null,
    contentViewerGroup?: string | null,
    viewerGroup?: string | null,
    apiName?: string | null,
    apiDescription?: string | null,
    apiType?: ApiType | null,
    apiFields?:  Array< {
      __typename: "Field",
      idValue: string,
      fieldId: string,
      name: string,
      kind: FieldKind,
      description?: string | null,
      required?: boolean | null,
      richEditorMultiParagraph?: boolean | null,
      richEditorImageSize?: boolean | null,
      referenceKey?: string | null,
      referenceDisplayItem?: string | null,
      dateFormat?: boolean | null,
      booleanInitialValue?: boolean | null,
      selectItems?:  Array< {
        __typename: "SelectItem",
        id: string,
        value?: string | null,
      } | null > | null,
      selectInitialValue?: Array< string | null > | null,
      richEditorOptions?: Array< string | null > | null,
      richEditorV2Options?: Array< string | null > | null,
      richEditorV2ColorList?:  Array< {
        __typename: "RichEditorV2Color",
        id: string,
        value: string,
      } | null > | null,
      customClassList?:  Array< {
        __typename: "CustomClass",
        id?: string | null,
        name?: string | null,
        value?: string | null,
      } | null > | null,
      multipleSelect?: boolean | null,
      iframeUrl?: string | null,
      customFieldCreatedAt?: string | null,
      customFieldDisplayItem?: string | null,
      customFieldCreatedAtList?: Array< string | null > | null,
      textSizeLimitValidation?:  {
        __typename: "TextSizeLimitValidation",
        textSize:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      numberSizeLimitValidation?:  {
        __typename: "NumberSizeLimitValidation",
        numberSize:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      relationListCountLimitValidation?:  {
        __typename: "RelationListCountLimitValidation",
        relationListCount:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      repeaterCountLimitValidation?:  {
        __typename: "RepeaterCountLimitValidation",
        repeatCount:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      imageSizeValidation?:  {
        __typename: "ImageSizeValidation",
        imageSize:  {
          __typename: "ImageSize",
          width?: number | null,
          height?: number | null,
        },
      } | null,
      mediaListLayout?: MediaListLayout | null,
      patternMatchValidation?:  {
        __typename: "PatternMatchValidation",
        regexp:  {
          __typename: "RegExp",
          pattern: string,
          flags?: string | null,
        },
      } | null,
      isUnique?: boolean | null,
    } | null > | null,
    apiPreviewUrl?: string | null,
    apiPreviewLinkIsVisible?: boolean | null,
    apiImportProgress?:  {
      __typename: "ApiImportProgress",
      status?: ProgressStatus | null,
      date?: string | null,
      message?: string | null,
    } | null,
    apiAllowedIpList?: string | null,
    contentIdSetting?:  {
      __typename: "ContentIdSetting",
      allowList: Array< ContentIdCharacter | null >,
      length?: number | null,
    } | null,
    contentValue?: string | null,
    draftValue?: string | null,
    closedValue?: string | null,
    contentDraftKey?: string | null,
    contentStatus?: ContentStatus | null,
    contentCustomStatusIds?: Array< string | null > | null,
    reservationTime?: string | null,
    reservationStopTime?: string | null,
    authType?: AuthType | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    publishedAt?: string | null,
    revisedAt?: string | null,
    closedAt?: string | null,
    editorId?: string | null,
    authorId?: string | null,
    inputSource?: InputSource | null,
    groups?: Array< string | null > | null,
    suspended?: boolean | null,
    relatedContentIds?:  Array< {
      __typename: "RelatedContentIds",
      partitionKey?: string | null,
      apiId?: string | null,
      gsiSinglePartitionKey1?: string | null,
      sortKey?: string | null,
    } | null > | null,
    customDomainImageHost?: string | null,
    customDomainFileHost?: string | null,
    _restricted?:  {
      __typename: "ContentsRestricted",
      type: ContentsRestrictedType,
      reason?: string | null,
    } | null,
  } | null > | null,
};

export type FindUserQueryVariables = {
  email?: string | null,
};

export type FindUserQuery = {
  findUser?: string | null,
};

export type FindServiceUserQueryVariables = {
  serviceId?: string | null,
  userId?: string | null,
};

export type FindServiceUserQuery = {
  findServiceUser?: string | null,
};

export type FindServiceUsersQueryVariables = {
  serviceId?: string | null,
};

export type FindServiceUsersQuery = {
  findServiceUsers?:  Array< {
    __typename: "ServiceUser",
    sub?: string | null,
    email_verified?: boolean | null,
    email?: string | null,
    mfaSetting?: boolean | null,
    username?: string | null,
    picture?: string | null,
    name?: string | null,
    isSAML?: boolean | null,
    roleIds?: Array< string | null > | null,
    inviting?: boolean | null,
  } | null > | null,
};

export type FindServiceUsersAssignableReviewRequestQueryVariables = {
  serviceId: string,
  reqId: number,
};

export type FindServiceUsersAssignableReviewRequestQuery = {
  findServiceUsersAssignableReviewRequest?:  Array< {
    __typename: "ServiceUser",
    sub?: string | null,
    email_verified?: boolean | null,
    email?: string | null,
    mfaSetting?: boolean | null,
    username?: string | null,
    picture?: string | null,
    name?: string | null,
    isSAML?: boolean | null,
    roleIds?: Array< string | null > | null,
    inviting?: boolean | null,
  } | null > | null,
};

export type GetMyMemberQueryVariables = {
  serviceId?: string | null,
};

export type GetMyMemberQuery = {
  getMyMember?:  {
    __typename: "MemberResponse",
    result: boolean,
    message?: string | null,
    data?:  {
      __typename: "Member",
      id: string,
      serviceId: string,
      roleIds: Array< string | null >,
      createdAt?: string | null,
      updatedAt?: string | null,
      viewerGroup?: string | null,
      inviting?: boolean | null,
    } | null,
  } | null,
};

export type GetMyMembersQuery = {
  getMyMembers?:  {
    __typename: "MembersResponse",
    result: boolean,
    message?: string | null,
    data?:  Array< {
      __typename: "Member",
      id: string,
      serviceId: string,
      roleIds: Array< string | null >,
      createdAt?: string | null,
      updatedAt?: string | null,
      viewerGroup?: string | null,
      inviting?: boolean | null,
    } | null > | null,
  } | null,
};

export type GetBandwidthLogQueryVariables = {
  serviceId?: string | null,
};

export type GetBandwidthLogQuery = {
  getBandwidthLog?: string | null,
};

export type SearchContentsQueryVariables = {
  apiId?: string | null,
  options?: SearchOptions | null,
};

export type SearchContentsQuery = {
  searchContents?: string | null,
};

export type GetContentsCountQueryVariables = {
  serviceId?: string | null,
};

export type GetContentsCountQuery = {
  getContentsCount?: number | null,
};

export type GetContentsByIdQueryVariables = {
  contentIds?: Array< string | null > | null,
};

export type GetContentsByIdQuery = {
  getContentsById?: string | null,
};

export type GetContentsByIdandApiIdQueryVariables = {
  contentIds?: Array< string | null > | null,
};

export type GetContentsByIdandApiIdQuery = {
  getContentsByIdandApiId?: string | null,
};

export type FindMediumQueryVariables = {
  serviceId?: string | null,
  mediumId?: string | null,
};

export type FindMediumQuery = {
  findMedium?:  {
    __typename: "Medium",
    serviceId: string,
    mediumId: string,
    fileName: string,
    directory: string,
    createdAt: string,
    updatedAt: string,
    kind: MediumKind,
    uploader: string,
    contentType: string,
    contentLength: number,
    imageWidth?: number | null,
    imageHeight?: number | null,
    viewerGroup?: string | null,
    relatedContentIds?:  Array< {
      __typename: "RelatedContentIds",
      partitionKey?: string | null,
      apiId?: string | null,
      gsiSinglePartitionKey1?: string | null,
      sortKey?: string | null,
    } | null > | null,
    tags?: Array< string | null > | null,
  } | null,
};

export type FindMediaQueryVariables = {
  serviceId?: string | null,
  fileNameQuery?: string | null,
  filters?: string | null,
  onlyImage?: boolean | null,
  nextToken?: string | null,
};

export type FindMediaQuery = {
  findMedia?: string | null,
};

export type GetMediumCountQueryVariables = {
  serviceId?: string | null,
  onlyImage?: boolean | null,
};

export type GetMediumCountQuery = {
  getMediumCount?: number | null,
};

export type FindAuditLogsQueryVariables = {
  serviceId?: string | null,
  limit?: number | null,
  offset?: number | null,
  startDate?: string | null,
  endDate?: string | null,
};

export type FindAuditLogsQuery = {
  findAuditLogs?: string | null,
};

export type GetEnvironmentsQueryVariables = {
  serviceId?: string | null,
};

export type GetEnvironmentsQuery = {
  getEnvironments?:  Array< {
    __typename: "contents",
    partitionKey: string,
    sortKey: string,
    gsiPartitionKey1?: string | null,
    gsiSortKey1?: string | null,
    gsiPartitionKey2?: string | null,
    gsiSortKey2?: string | null,
    gsiPartitionKey3?: string | null,
    gsiSortKey3?: string | null,
    gsiSinglePartitionKey1?: string | null,
    serviceName?: string | null,
    serviceImage?:  {
      __typename: "S3Object",
      key?: string | null,
      level?: string | null,
      identityId?: string | null,
    } | null,
    servicePostCount?: number | null,
    serviceAllowedIpList?: string | null,
    requireMfa?: boolean | null,
    environment?:  {
      __typename: "Environment",
      name: string,
      parent: string,
    } | null,
    apiStopUntil?: string | null,
    serviceLanguage?: Language | null,
    serviceViewerGroup?: string | null,
    memberViewerGroup?: string | null,
    roleViewerGroup?: string | null,
    mediumViewerGroup?: string | null,
    reviewRequestViewerGroup?: string | null,
    apiViewerGroup?: string | null,
    contentViewerGroup?: string | null,
    viewerGroup?: string | null,
    apiName?: string | null,
    apiDescription?: string | null,
    apiType?: ApiType | null,
    apiFields?:  Array< {
      __typename: "Field",
      idValue: string,
      fieldId: string,
      name: string,
      kind: FieldKind,
      description?: string | null,
      required?: boolean | null,
      richEditorMultiParagraph?: boolean | null,
      richEditorImageSize?: boolean | null,
      referenceKey?: string | null,
      referenceDisplayItem?: string | null,
      dateFormat?: boolean | null,
      booleanInitialValue?: boolean | null,
      selectItems?:  Array< {
        __typename: "SelectItem",
        id: string,
        value?: string | null,
      } | null > | null,
      selectInitialValue?: Array< string | null > | null,
      richEditorOptions?: Array< string | null > | null,
      richEditorV2Options?: Array< string | null > | null,
      richEditorV2ColorList?:  Array< {
        __typename: "RichEditorV2Color",
        id: string,
        value: string,
      } | null > | null,
      customClassList?:  Array< {
        __typename: "CustomClass",
        id?: string | null,
        name?: string | null,
        value?: string | null,
      } | null > | null,
      multipleSelect?: boolean | null,
      iframeUrl?: string | null,
      customFieldCreatedAt?: string | null,
      customFieldDisplayItem?: string | null,
      customFieldCreatedAtList?: Array< string | null > | null,
      textSizeLimitValidation?:  {
        __typename: "TextSizeLimitValidation",
        textSize:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      numberSizeLimitValidation?:  {
        __typename: "NumberSizeLimitValidation",
        numberSize:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      relationListCountLimitValidation?:  {
        __typename: "RelationListCountLimitValidation",
        relationListCount:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      repeaterCountLimitValidation?:  {
        __typename: "RepeaterCountLimitValidation",
        repeatCount:  {
          __typename: "NumberRange",
          min?: number | null,
          max?: number | null,
        },
      } | null,
      imageSizeValidation?:  {
        __typename: "ImageSizeValidation",
        imageSize:  {
          __typename: "ImageSize",
          width?: number | null,
          height?: number | null,
        },
      } | null,
      mediaListLayout?: MediaListLayout | null,
      patternMatchValidation?:  {
        __typename: "PatternMatchValidation",
        regexp:  {
          __typename: "RegExp",
          pattern: string,
          flags?: string | null,
        },
      } | null,
      isUnique?: boolean | null,
    } | null > | null,
    apiPreviewUrl?: string | null,
    apiPreviewLinkIsVisible?: boolean | null,
    apiImportProgress?:  {
      __typename: "ApiImportProgress",
      status?: ProgressStatus | null,
      date?: string | null,
      message?: string | null,
    } | null,
    apiAllowedIpList?: string | null,
    contentIdSetting?:  {
      __typename: "ContentIdSetting",
      allowList: Array< ContentIdCharacter | null >,
      length?: number | null,
    } | null,
    contentValue?: string | null,
    draftValue?: string | null,
    closedValue?: string | null,
    contentDraftKey?: string | null,
    contentStatus?: ContentStatus | null,
    contentCustomStatusIds?: Array< string | null > | null,
    reservationTime?: string | null,
    reservationStopTime?: string | null,
    authType?: AuthType | null,
    createdAt?: string | null,
    updatedAt?: string | null,
    publishedAt?: string | null,
    revisedAt?: string | null,
    closedAt?: string | null,
    editorId?: string | null,
    authorId?: string | null,
    inputSource?: InputSource | null,
    groups?: Array< string | null > | null,
    suspended?: boolean | null,
    relatedContentIds?:  Array< {
      __typename: "RelatedContentIds",
      partitionKey?: string | null,
      apiId?: string | null,
      gsiSinglePartitionKey1?: string | null,
      sortKey?: string | null,
    } | null > | null,
    customDomainImageHost?: string | null,
    customDomainFileHost?: string | null,
    _restricted?:  {
      __typename: "ContentsRestricted",
      type: ContentsRestrictedType,
      reason?: string | null,
    } | null,
  } | null > | null,
};

export type GetGlobalApiIpAddressRestrictionQueryVariables = {
  serviceId: string,
};

export type GetGlobalApiIpAddressRestrictionQuery = {
  getGlobalApiIpAddressRestriction?:  {
    __typename: "GlobalApiIpAddressRestriction",
    ipList: string,
  } | null,
};

export type FindContentByVersionQueryVariables = {
  contentId: string,
  version: number,
  denyDraft?: boolean | null,
};

export type FindContentByVersionQuery = {
  findContentByVersion?:  {
    __typename: "FindContentByVersion",
    value: string,
    unauthorizedFieldIds: Array< string >,
  } | null,
};

export type EnsureContentObjectExistsQueryVariables = {
  apiId: string,
};

export type EnsureContentObjectExistsQuery = {
  ensureContentObjectExists?:  {
    __typename: "EnsureContentObjectExists",
    userDefinedContentId: string,
    exists: boolean,
    authorized: boolean,
  } | null,
};

export type GetFeatureFlagValueQueryVariables = {
  flagName?: string | null,
};

export type GetFeatureFlagValueQuery = {
  getFeatureFlagValue?: string | null,
};

export type CheckUserStateQueryVariables = {
  domain?: string | null,
};

export type CheckUserStateQuery = {
  checkUserState?: string | null,
};

export type GetOembedResultQueryVariables = {
  url?: string | null,
};

export type GetOembedResultQuery = {
  getOembedResult?: string | null,
};

export type GetOembedlyResultQueryVariables = {
  url?: string | null,
};

export type GetOembedlyResultQuery = {
  getOembedlyResult?: string | null,
};

export type GetIframelyResultQueryVariables = {
  url: string,
  optionQuery?: string | null,
};

export type GetIframelyResultQuery = {
  getIframelyResult?: string | null,
};

export type FindReviewRequestQueryVariables = {
  serviceId?: string | null,
  reqId?: number | null,
};

export type FindReviewRequestQuery = {
  findReviewRequest?:  {
    __typename: "ReviewRequest",
    serviceId: string,
    reqId: number,
    title: string,
    description?: string | null,
    contentId: string,
    status: ReviewRequestStatus,
    createdAt: string,
    updatedAt: string,
    reservationTime?: string | null,
    reservationStopTime?: string | null,
    reviewers?:  Array< {
      __typename: "ReviewRequestReviewer",
      id: string,
    } | null > | null,
    commentCount: number,
    authorId: string,
    viewerGroup: string,
  } | null,
};

export type GetOpenedReviewRequestAccessInfoQueryVariables = {
  serviceId?: string | null,
  contentId?: string | null,
};

export type GetOpenedReviewRequestAccessInfoQuery = {
  getOpenedReviewRequestAccessInfo?:  {
    __typename: "ReviewRequestAccessInfo",
    reqId?: number | null,
    readable?: boolean | null,
  } | null,
};

export type GetOpenedReviewRequestsCountQueryVariables = {
  serviceId?: string | null,
};

export type GetOpenedReviewRequestsCountQuery = {
  getOpenedReviewRequestsCount?: number | null,
};

export type GetReviewRequestsByStatusQueryVariables = {
  serviceId?: string | null,
  status?: ReviewRequestStatus | null,
  nextToken?: string | null,
};

export type GetReviewRequestsByStatusQuery = {
  getReviewRequestsByStatus?: string | null,
};

export type GetAllReviewRequestEventQueryVariables = {
  serviceId?: string | null,
  reqId?: number | null,
};

export type GetAllReviewRequestEventQuery = {
  getAllReviewRequestEvent?: string | null,
};

export type GetPlanListQueryVariables = {
  serviceId?: string | null,
};

export type GetPlanListQuery = {
  getPlanList?:  {
    __typename: "PlanList",
    suspended?: boolean | null,
    planTypes?:  Array< {
      __typename: "PlanType",
      type: PlanTypeValue,
      plans:  Array< {
        __typename: "PlanDetail",
        name: string,
        current: boolean,
        description?: string | null,
        displayName?: string | null,
        minimumMonthPrice?: number | null,
        minimumMonthTaxIncludedPrice?: number | null,
        trialLength?: string | null,
        limit:  {
          __typename: "PlanLimit",
          transfer?: string | null,
          transferOveragePrice?: number | null,
          transferOverageUnit?: string | null,
          contentsCount?: number | null,
          memberCount?: number | null,
          memberCountOveragePrice?: number | null,
          memberCountOverageUnit?: string | null,
          importUpperLimit?: number | null,
          maxMediumSizeInMb?: number | null,
          roleCount?: number | null,
          apiCount?: number | null,
          apiCountOveragePrice?: number | null,
          apiCountOverageUnit?: string | null,
          webhookCount?: number | null,
          customFieldCount?: number | null,
          fileUpload: boolean,
          history: boolean,
          ipRestriction: boolean,
          ipRestrictionCount: number,
          apiIpRestriction: boolean,
          apiIpRestrictionCount: number,
          additionalEnvironments: boolean,
          auditLog: boolean,
          requireMfa: boolean,
          iframe: boolean,
          premiumSupport: boolean,
          indivisualInfra: boolean,
          sla: boolean,
          handleEnvironments: boolean,
          enableAllParams: boolean,
          enableAddons: boolean,
          maxApiKeyCount?: number | null,
          enableSendInvoice: boolean,
          customStatusCount: number,
          enableSAML: boolean,
          mediumTags: number,
        },
      } | null >,
    } | null > | null,
  } | null,
};

export type HasExperiencedPaidPlansQueryVariables = {
  serviceId?: string | null,
};

export type HasExperiencedPaidPlansQuery = {
  hasExperiencedPaidPlans: boolean,
};

export type DescribeCreateEnvironmentQueryVariables = {
  executionId?: string | null,
};

export type DescribeCreateEnvironmentQuery = {
  describeCreateEnvironment?: string | null,
};

export type GetSAMLConfigurationQueryVariables = {
  serviceId?: string | null,
};

export type GetSAMLConfigurationQuery = {
  getSAMLConfiguration?:  {
    __typename: "SAMLConfigurationResponse",
    serviceProviderInfo?:  {
      __typename: "SAMLConfigurationServiceProviderInfo",
      applicationCallbackURL: string,
      settings:  {
        __typename: "SAMLConfigurationSettings",
        audience: string,
        nameIdentifierFormat: string,
        mappings:  {
          __typename: "SAMLConfigurationMappings",
          email: string,
          name: string,
          picture: string,
        },
      },
    } | null,
    idpMetadataURL?: string | null,
    idpMetadataXML?: string | null,
    defaultRoleId?: string | null,
    samlOnly: boolean,
    loginURL?: string | null,
  } | null,
};

export type ListCustomStatusQueryVariables = {
  apiId: string,
};

export type ListCustomStatusQuery = {
  listCustomStatus?:  Array< {
    __typename: "CustomState",
    id: string,
    apiId: string,
    key: string,
    description: string,
    name: string,
    behaviour: CustomStatusBehaviour,
    color: string,
    createdAt: string,
    updatedAt?: string | null,
  } | null > | null,
};

export type GetAccountLanguageQuery = {
  getAccountLanguage?: Language | null,
};

export type GetServiceLanguageQueryVariables = {
  serviceId: string,
};

export type GetServiceLanguageQuery = {
  getServiceLanguage?: Language | null,
};

export type HealthcheckGoQueryVariables = {
  message?: string | null,
};

export type HealthcheckGoQuery = {
  healthcheckGo?:  {
    __typename: "HaalthcheckGo",
    message?: string | null,
  } | null,
};

export type ListMediumTagsQueryVariables = {
  serviceId: string,
  text: string,
};

export type ListMediumTagsQuery = {
  listMediumTags?:  {
    __typename: "MediumTagList",
    tags:  Array< {
      __typename: "MediumTag",
      mediumTagId: string,
      serviceId: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      createdBy: string,
      updatedBy: string,
    } | null >,
  } | null,
};
