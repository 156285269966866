import { Auth, Storage } from 'aws-amplify';
import mime from 'mime-types';
import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

type ImageUploadResult = {
  image: string;
};

export const useName = () => {
  const [imageUploadResult, setResult] = useState<
    ImageUploadResult | undefined
  >();

  const uploadImage = useCallback(async (file: File) => {
    if (file === undefined) {
      setResult(undefined);
      return;
    }

    const identityId = (await Auth.currentUserInfo()).id;
    const extension = file.name.split('.').pop();
    const fileName = `${uuid()}.${extension}`;
    const level = 'protected';
    const contentType = mime.lookup(fileName) || 'application/octet-stream';
    Storage.put(fileName, file, { level, contentType })
      .then((putResult) => {
        //identityId情報を含めておくことで他の人もパスを取得できるようにしておく
        setResult({
          image: `${identityId},${putResult.key}`,
        });
      })
      .catch((err) => {
        console.log(err);
        setResult(undefined);
      });
  }, []);

  return {
    uploadImage,
    imageUploadResult,
  };
};
