import { nanoid } from 'nanoid';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { useCreateApi } from '../../../hooks/Api/useApiWriterTs';
import { useLoadApis } from '../../../hooks/ApiList/useApiListReader';
import { useCreateContent } from '../../../hooks/Content/useContentWriterTs';
import { createdContentId } from '../../../hooks/Content/useCreateContentId';
import useMediumWriter from '../../../hooks/Medium/useMediumWriter';
import i18n from '../../../i18n';

import type { Service } from '../../../types/services';
import type React from 'react';

import { FieldKind, ApiType } from '@/API';
import { useApiListInvalidateCache } from '@/views/Common/api/invalidateCache';

type CreateBlogTemplateParam = {
  service: Service;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const createFileList = async (paths: string[]) => {
  const dataTransfer = (
    await Promise.all(
      paths.map(async (path) => {
        return await fetch(path)
          .then((res) => res.blob())
          .then((blob) => new File([blob], path.split('/').pop() || 'unknown'));
      }),
    )
  ).reduce((dataTransfer, file) => {
    dataTransfer.items.add(file);
    return dataTransfer;
  }, new DataTransfer());
  return dataTransfer.files;
};

export const useCreateBlogTemplate = ({
  service,
  setIsLoading,
}: CreateBlogTemplateParam) => {
  const { t } = useTranslation('selectApiTemplate');
  const { createApiAsync } = useCreateApi(service.partitionKey);
  const { refetchApiList } = useLoadApis(service);
  const history = useHistory();
  const apiListInvalidateCache = useApiListInvalidateCache();
  const { addToast } = useToasts();
  const { upload: uploadMedia } = useMediumWriter(service);
  const { createContentAsync } = useCreateContent();

  return useCallback(async () => {
    setIsLoading(true);
    //カテゴリ作成
    await createApiAsync({
      apiName: t('Category'),
      apiEndpoint: 'categories',
      apiType: ApiType.LIST,
      apiFields: [
        {
          __typename: 'Field',
          idValue: nanoid(10),
          fieldId: 'name',
          name: t('Category Name'),
          kind: FieldKind.text,
          isUnique: false,
        },
      ],
      customFields: [],
    });

    //API一覧からカテゴリAPIを取得する
    const categoryApi = (await refetchApiList()).data?.list.find(
      (d) => d.apiEndpoint === 'categories',
    );
    const categoryApiId = categoryApi?.partitionKey;

    //ブログ作成
    await createApiAsync({
      apiName: t('Blog'),
      apiEndpoint: 'blogs',
      apiType: ApiType.LIST,
      apiFields: [
        {
          __typename: 'Field',
          idValue: nanoid(10),
          fieldId: 'title',
          name: t('Title'),
          kind: FieldKind.text,
          isUnique: false,
        },
        {
          __typename: 'Field',

          idValue: nanoid(10),
          fieldId: 'content',
          name: t('Content'),
          kind: FieldKind.richEditorV2,
        },
        {
          __typename: 'Field',
          idValue: nanoid(10),
          fieldId: 'eyecatch',
          name: t('Eyecatch'),
          kind: FieldKind.media,
        },
        {
          __typename: 'Field',
          idValue: nanoid(10),
          fieldId: 'category',
          name: t('Category'),
          kind: FieldKind.relation,
          referenceKey: categoryApiId,
        },
      ],
      customFields: [],
    });

    //API一覧からブログAPIを取得する
    const blogsApi = (await refetchApiList()).data?.list.find(
      (d) => d.apiEndpoint === 'blogs',
    );
    const blogsTitleField = blogsApi?.apiFields.find(
      (f) => f.fieldId === 'title',
    );
    const blogsContentField = blogsApi?.apiFields.find(
      (f) => f.fieldId === 'content',
    );
    const blogsEyecatchField = blogsApi?.apiFields.find(
      (f) => f.fieldId === 'eyecatch',
    );
    const blogsCategoryField = blogsApi?.apiFields.find(
      (f) => f.fieldId === 'category',
    );

    //画像のアップロード
    const files = await createFileList([
      t('/images/templates/blog-template-en.png'),
      '/images/templates-v2/blog-template-description1.png',
      '/images/templates-v2/blog-template-description2.png',
      '/images/templates-v2/blog-template-description3.png',
    ]);

    const mediumIds = await uploadMedia(files);
    const exampleMediumId = mediumIds?.[0];
    const desc1MediumId = mediumIds?.[1];
    const desc2MediumId = mediumIds?.[2];
    const desc3MediumId = mediumIds?.[3];

    //コンテンツを作る
    const categoryNameField = categoryApi?.apiFields.find(
      (f) => f.fieldId === 'name',
    );
    // --- カテゴリー
    const [categoryContent] = await Promise.all(
      [t('Update Information'), t('Technology'), t('Tutorials')].map(
        async (categoryName) => {
          return await createContentAsync({
            apiId: categoryApiId || '',
            contentId: await createdContentId(categoryApiId ?? ''),
            value: {
              [categoryNameField?.idValue || '']: { text: categoryName },
            },
            contentStatus: 'PUBLISH',
          });
        },
      ),
    );
    const categoryContentId = categoryContent.partitionKey;
    // --- ブログ
    await createContentAsync({
      apiId: blogsApi?.partitionKey || '',
      contentId: await createdContentId(categoryApiId ?? ''),
      value: {
        [blogsTitleField?.idValue || '']: {
          text: t('(Sample) First, open this article'),
        },
        [blogsContentField?.idValue || '']: {
          richEditorV2: {
            type: 'doc',
            content: [
              {
                type: 'heading',
                attrs: { textAlign: 'left', level: 2 },
                content: [
                  {
                    type: 'text',
                    text: t('Created from a blog template🎉'),
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  {
                    type: 'text',
                    text: t('An API was created with the blog template.'),
                  },
                  { type: 'hardBreak' },
                  { type: 'text', text: t('Excellent job! 🎉') },
                ],
              },
              {
                type: 'heading',
                attrs: { textAlign: 'left', level: 2 },
                content: [{ type: 'text', text: t('Try Preview API🚀') }],
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  {
                    type: 'text',
                    text: t("Let's try Preview API first."),
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  {
                    type: 'text',
                    text: t(
                      'The content you submitted will be retrieved with an API and connected to the View.',
                    ),
                  },
                  { type: 'hardBreak' },
                  {
                    type: 'text',
                    text: t(
                      'The Preview API allows you to see the actual API response and accelerate your development.',
                    ),
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [{ type: 'text', text: t('👇Click here first.') }],
              },
              {
                type: 'imageBlock',
                attrs: {
                  medium: {
                    mediumId: desc1MediumId,
                    serviceId: service.partitionKey,
                  },
                  alt: '',
                  customWidth: null,
                  customHeight: null,
                  figcaption: null,
                  link: '',
                  isTargetBlank: false,
                },
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  {
                    type: 'text',
                    text: t('When the Preview API page opens, click '),
                  },
                  {
                    type: 'text',
                    marks: [{ type: 'bold' }],
                    text: t('Get'),
                  },
                  {
                    type: 'text',
                    text: t('button to try out the request.'),
                  },
                ],
              },
              {
                type: 'imageBlock',
                attrs: {
                  medium: {
                    mediumId: desc2MediumId,
                    serviceId: service.partitionKey,
                  },
                  alt: '',
                  customWidth: null,
                  customHeight: null,
                  figcaption: null,
                  link: '',
                  isTargetBlank: false,
                },
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  {
                    type: 'text',
                    text: t(
                      'You can see that the contents of this article can be retrieved via the API.',
                    ),
                  },
                ],
              },
              {
                type: 'imageBlock',
                attrs: {
                  medium: {
                    mediumId: desc3MediumId,
                    serviceId: service.partitionKey,
                  },
                  alt: '',
                  customWidth: null,
                  customHeight: null,
                  figcaption: null,
                  link: '',
                  isTargetBlank: false,
                },
              },
              {
                type: 'heading',
                attrs: { textAlign: 'left', level: 2 },
                content: [{ type: 'text', text: t('What to do next? 🏃') }],
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  {
                    type: 'text',
                    text: t(
                      'Build your own website using the responses you see in the Preview API.',
                    ),
                  },
                  { type: 'hardBreak' },
                  {
                    type: 'text',
                    text: t(
                      'microCMS retrieves content via API, so you can build screens in your favorite language and framework.',
                    ),
                  },
                ],
              },
              {
                type: 'bulletList',
                content: [
                  {
                    type: 'listItem',
                    content: [
                      {
                        type: 'paragraph',
                        attrs: { textAlign: 'left' },
                        content: [
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'link',
                                attrs: {
                                  href: 'https://document.microcms.io/tutorial/javascript/javascript-top',
                                  target: '_blank',
                                  class: 'link--external',
                                },
                              },
                              { type: 'underline' },
                            ],
                            text: 'JavaScript SDK',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'listItem',
                    content: [
                      {
                        type: 'paragraph',
                        attrs: { textAlign: 'left' },
                        content: [
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'link',
                                attrs: {
                                  href: 'https://document.microcms.io/tutorial/nuxt/nuxt-top',
                                  target: '_blank',
                                  class: 'link--external',
                                },
                              },
                              { type: 'underline' },
                            ],
                            text: 'Nuxt SDK',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'listItem',
                    content: [
                      {
                        type: 'paragraph',
                        attrs: { textAlign: 'left' },
                        content: [
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'link',
                                attrs: {
                                  href: 'https://document.microcms.io/tutorial/gatsby/gatsby-top',
                                  target: '_blank',
                                  class: 'link--external',
                                },
                              },
                              { type: 'underline' },
                            ],
                            text: 'Gatsby SDK',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  { type: 'text', text: t('Additionally') },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'link',
                        attrs: {
                          href: 'https://microcms.io/features/sdk',
                          target: '_blank',
                          class: 'link--external',
                        },
                      },
                      { type: 'underline' },
                    ],
                    text: t(
                      'Server-side SDKs (PHP / Go / Ruby) and mobile SDKs (iOS / Android)',
                    ),
                  },
                  { type: 'text', text: t('are also available.') },
                ],
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  {
                    type: 'text',
                    text: t(
                      'Please feel free to reach out to us if you have any problems or questions.',
                    ),
                  },
                  { type: 'hardBreak' },
                  {
                    type: 'text',
                    text:
                      i18n.language === 'ja' ? 'サポート窓口：' : 'Support: ', // t関数だと上手くデータが入らないため、i18n.languageで分岐している                      },
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'link',
                        attrs: {
                          href: 'mailto:support@microcms.io',
                          target: null,
                          class: null,
                        },
                      },
                    ],
                    text: 'support@microcms.io',
                  },
                  { type: 'hardBreak' },
                  { type: 'text', text: t('Frequently Asked Questions:') },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'link',
                        attrs: {
                          href: 'https://help.microcms.io/ja/knowledge',
                          target: null,
                          class: null,
                        },
                      },
                      { type: 'underline' },
                    ],
                    text: 'https://help.microcms.io/ja/knowledge',
                  },
                ],
              },
            ],
          },
        },
        [blogsEyecatchField?.idValue || '']: {
          media: { mediumId: exampleMediumId },
        },
        [blogsCategoryField?.idValue || '']: {
          relation: categoryContentId,
        },
      },
      contentStatus: 'PUBLISH',
    });

    //画面遷移
    apiListInvalidateCache();
    addToast(t('Created an API.'), { appearance: 'success' });
    history.push('/apis/blogs');
  }, [
    setIsLoading,
    createApiAsync,
    t,
    refetchApiList,
    uploadMedia,
    createContentAsync,
    service.partitionKey,
    apiListInvalidateCache,
    addToast,
    history,
  ]);
};

export const useCreateNewsTemplate = ({
  service,
  setIsLoading,
}: CreateBlogTemplateParam) => {
  const { t } = useTranslation('selectApiTemplate');
  const { createApiAsync } = useCreateApi(service.partitionKey);
  const { refetchApiList } = useLoadApis(service);
  const history = useHistory();
  const apiListInvalidateCache = useApiListInvalidateCache();
  const { addToast } = useToasts();
  const { upload: uploadMedia } = useMediumWriter(service);
  const { createContentAsync } = useCreateContent();

  return useCallback(async () => {
    setIsLoading(true);
    //カテゴリ作成
    await createApiAsync({
      apiName: t('Category'),
      apiEndpoint: 'categories',
      apiType: ApiType.LIST,
      apiFields: [
        {
          __typename: 'Field',
          idValue: nanoid(10),
          fieldId: 'name',
          name: t('Category Name'),
          kind: FieldKind.text,
          isUnique: false,
        },
      ],
      customFields: [],
    });

    //API一覧からカテゴリAPIを取得する
    const categoryApi = (await refetchApiList()).data?.list.find(
      (d) => d.apiEndpoint === 'categories',
    );
    const categoryApiId = categoryApi?.partitionKey;

    //お知らせ作成
    await createApiAsync({
      apiName: t('Announcement'),
      apiEndpoint: 'news',
      apiType: ApiType.LIST,
      apiFields: [
        {
          __typename: 'Field',
          idValue: nanoid(10),
          fieldId: 'title',
          name: t('Title'),
          kind: FieldKind.text,
          isUnique: false,
        },
        {
          __typename: 'Field',
          idValue: nanoid(10),
          fieldId: 'content',
          name: t('Content'),
          kind: FieldKind.richEditorV2,
        },
        {
          __typename: 'Field',
          idValue: nanoid(10),
          fieldId: 'category',
          name: t('Category'),
          kind: FieldKind.relation,
          referenceKey: categoryApiId,
        },
      ],
      customFields: [],
    });

    //API一覧からお知らせAPIを取得する
    const newsApi = (await refetchApiList()).data?.list.find(
      (d) => d.apiEndpoint === 'news',
    );
    const blogsTitleField = newsApi?.apiFields.find(
      (f) => f.fieldId === 'title',
    );
    const blogsContentField = newsApi?.apiFields.find(
      (f) => f.fieldId === 'content',
    );
    const blogsCategoryField = newsApi?.apiFields.find(
      (f) => f.fieldId === 'category',
    );

    //画像のアップロード
    const files = await createFileList([
      '/images/templates-v2/announce-template-description1.png',
      '/images/templates-v2/announce-template-description2.png',
      '/images/templates-v2/announce-template-description3.png',
    ]);
    const mediumIds = await uploadMedia(files);

    const desc1MediumId = mediumIds?.[0];
    const desc2MediumId = mediumIds?.[1];
    const desc3MediumId = mediumIds?.[2];

    //コンテンツを作る
    const categoryNameField = categoryApi?.apiFields.find(
      (f) => f.fieldId === 'name',
    );
    // --- カテゴリー
    const [categoryContent] = await Promise.all(
      [t('Update Information'), t('Technology'), t('Tutorials')].map(
        async (categoryName) => {
          return await createContentAsync({
            apiId: categoryApiId || '',
            contentId: await createdContentId(categoryApiId ?? ''),
            value: {
              [categoryNameField?.idValue || '']: { text: categoryName },
            },
            contentStatus: 'PUBLISH',
          });
        },
      ),
    );
    const categoryContentId = categoryContent.partitionKey;
    // --- お知らせ
    await createContentAsync({
      apiId: newsApi?.partitionKey || '',
      contentId: await createdContentId(categoryApiId ?? ''),
      value: {
        [blogsTitleField?.idValue || '']: {
          text: t('(Sample) First, open this article'),
        },
        [blogsContentField?.idValue || '']: {
          richEditorV2: {
            type: 'doc',
            content: [
              {
                type: 'heading',
                attrs: { textAlign: 'left', level: 2 },
                content: [
                  {
                    type: 'text',
                    text: t('Created by Announcement Template 🎉'),
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  {
                    type: 'text',
                    text: t(
                      'An API has been created by announcement template.',
                    ),
                  },
                  { type: 'hardBreak' },
                  { type: 'text', text: t('Excellent job! 🎉') },
                ],
              },
              {
                type: 'heading',
                attrs: { textAlign: 'left', level: 2 },
                content: [{ type: 'text', text: t('Try Preview API🚀') }],
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  {
                    type: 'text',
                    text: t("Let's try Preview API first."),
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  {
                    type: 'text',
                    text: t(
                      'The content you submitted will be retrieved with an API and connected to the View.',
                    ),
                  },
                  { type: 'hardBreak' },
                  {
                    type: 'text',
                    text: t(
                      'The Preview API allows you to see the actual API response and accelerate your development.',
                    ),
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [{ type: 'text', text: t('👇Click here first.') }],
              },
              {
                type: 'imageBlock',
                attrs: {
                  medium: {
                    mediumId: desc1MediumId,
                    serviceId: service.partitionKey,
                  },
                  alt: '',
                  customWidth: null,
                  customHeight: null,
                  figcaption: null,
                  link: '',
                  isTargetBlank: false,
                },
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  {
                    type: 'text',
                    text: t('When the Preview API page opens, click '),
                  },
                  {
                    type: 'text',
                    marks: [{ type: 'bold' }],
                    text: t('Get'),
                  },
                  {
                    type: 'text',
                    text: t('button to try out the request.'),
                  },
                ],
              },
              {
                type: 'imageBlock',
                attrs: {
                  medium: {
                    mediumId: desc2MediumId,
                    serviceId: service.partitionKey,
                  },
                  alt: '',
                  customWidth: null,
                  customHeight: null,
                  figcaption: null,
                  link: '',
                  isTargetBlank: false,
                },
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  {
                    type: 'text',
                    text: t(
                      'You can see that the contents of this article can be retrieved via the API.',
                    ),
                  },
                ],
              },
              {
                type: 'imageBlock',
                attrs: {
                  medium: {
                    mediumId: desc3MediumId,
                    serviceId: service.partitionKey,
                  },
                  alt: '',
                  customWidth: null,
                  customHeight: null,
                  figcaption: null,
                  link: '',
                  isTargetBlank: false,
                },
              },
              {
                type: 'heading',
                attrs: { textAlign: 'left', level: 2 },
                content: [{ type: 'text', text: t('What to do next? 🏃') }],
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  {
                    type: 'text',
                    text: t(
                      'Build your own website using the responses you see in the Preview API.',
                    ),
                  },
                  { type: 'hardBreak' },
                  {
                    type: 'text',
                    text: t(
                      'microCMS retrieves content via API, so you can build screens in your favorite language and framework.',
                    ),
                  },
                ],
              },
              {
                type: 'bulletList',
                content: [
                  {
                    type: 'listItem',
                    content: [
                      {
                        type: 'paragraph',
                        attrs: { textAlign: 'left' },
                        content: [
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'link',
                                attrs: {
                                  href: 'https://document.microcms.io/tutorial/javascript/javascript-top',
                                  target: '_blank',
                                  class: 'link--external',
                                },
                              },
                              { type: 'underline' },
                            ],
                            text: 'JavaScript SDK',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'listItem',
                    content: [
                      {
                        type: 'paragraph',
                        attrs: { textAlign: 'left' },
                        content: [
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'link',
                                attrs: {
                                  href: 'https://document.microcms.io/tutorial/nuxt/nuxt-top',
                                  target: '_blank',
                                  class: 'link--external',
                                },
                              },
                              { type: 'underline' },
                            ],
                            text: 'Nuxt SDK',
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: 'listItem',
                    content: [
                      {
                        type: 'paragraph',
                        attrs: { textAlign: 'left' },
                        content: [
                          {
                            type: 'text',
                            marks: [
                              {
                                type: 'link',
                                attrs: {
                                  href: 'https://document.microcms.io/tutorial/gatsby/gatsby-top',
                                  target: '_blank',
                                  class: 'link--external',
                                },
                              },
                              { type: 'underline' },
                            ],
                            text: 'Gatsby SDK',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  { type: 'text', text: t('Additionally') },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'link',
                        attrs: {
                          href: 'https://microcms.io/features/sdk',
                          target: '_blank',
                          class: 'link--external',
                        },
                      },
                      { type: 'underline' },
                    ],
                    text: t(
                      'Server-side SDKs (PHP / Go / Ruby) and mobile SDKs (iOS / Android)',
                    ),
                  },
                  { type: 'text', text: t('are also available.') },
                ],
              },
              {
                type: 'paragraph',
                attrs: { textAlign: 'left' },
                content: [
                  {
                    type: 'text',
                    text: t(
                      'Please feel free to reach out to us if you have any problems or questions.',
                    ),
                  },
                  { type: 'hardBreak' },
                  {
                    type: 'text',
                    text:
                      i18n.language === 'ja' ? 'サポート窓口：' : 'Support: ', // t関数だと上手くデータが入らないため、i18n.languageで分岐している                      },
                  },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'link',
                        attrs: {
                          href: 'mailto:support@microcms.io',
                          target: null,
                          class: null,
                        },
                      },
                    ],
                    text: 'support@microcms.io',
                  },
                  { type: 'hardBreak' },
                  { type: 'text', text: t('Frequently Asked Questions:') },
                  {
                    type: 'text',
                    marks: [
                      {
                        type: 'link',
                        attrs: {
                          href: 'https://help.microcms.io/ja/knowledge',
                          target: null,
                          class: null,
                        },
                      },
                      { type: 'underline' },
                    ],
                    text: 'https://help.microcms.io/ja/knowledge',
                  },
                ],
              },
            ],
          },
        },
        [blogsCategoryField?.idValue || '']: {
          relation: categoryContentId,
        },
      },
      contentStatus: 'PUBLISH',
    });

    //画面遷移
    apiListInvalidateCache();
    addToast(t('Created an API.'), { appearance: 'success' });
    history.push('/apis/news');
  }, [
    setIsLoading,
    createApiAsync,
    t,
    refetchApiList,
    uploadMedia,
    createContentAsync,
    service.partitionKey,
    apiListInvalidateCache,
    addToast,
    history,
  ]);
};

export const useCreateBannerTemplate = ({
  service,
  setIsLoading,
}: CreateBlogTemplateParam) => {
  const { t } = useTranslation('selectApiTemplate');
  const { createApiAsync } = useCreateApi(service.partitionKey);
  const { refetchApiList } = useLoadApis(service);
  const history = useHistory();
  const apiListInvalidateCache = useApiListInvalidateCache();
  const { addToast } = useToasts();
  const { upload: uploadMedia } = useMediumWriter(service);
  const { createContentAsync } = useCreateContent();

  return useCallback(async () => {
    setIsLoading(true);

    //バナー作成
    await createApiAsync({
      apiName: t('Banner'),
      apiEndpoint: 'banner',
      apiType: ApiType.PAGE,
      apiFields: [
        {
          __typename: 'Field',
          idValue: nanoid(10),
          fieldId: 'image',
          name: t('Image'),
          kind: FieldKind.media,
          required: true,
        },
        {
          __typename: 'Field',
          idValue: nanoid(10),
          fieldId: 'url',
          name: t('Destination URL'),
          kind: FieldKind.text,
          isUnique: false,
          required: true,
        },
        {
          __typename: 'Field',
          idValue: nanoid(10),
          fieldId: 'description',
          name: t('Banner Description'),
          kind: FieldKind.textArea,
        },
      ],
      customFields: [],
    });

    //API一覧からブログAPIを取得する
    const bannerApi = (await refetchApiList()).data?.list.find(
      (d) => d.apiEndpoint === 'banner',
    );
    const bannerImageField = bannerApi?.apiFields.find(
      (f) => f.fieldId === 'image',
    );
    const bannerUrlField = bannerApi?.apiFields.find(
      (f) => f.fieldId === 'url',
    );
    const bannerDescriptionField = bannerApi?.apiFields.find(
      (f) => f.fieldId === 'description',
    );

    //画像のアップロード
    const files = await createFileList([
      t('/images/templates/banner-template-en.png'),
    ]);
    const mediumIds = await uploadMedia(files);
    const mediumId = mediumIds?.[0];

    //コンテンツを作る
    // --- バナー
    await createContentAsync({
      apiId: bannerApi?.partitionKey || '',
      contentId: await createdContentId(bannerApi?.partitionKey ?? ''),
      value: {
        [bannerImageField?.idValue || '']: {
          media: { mediumId },
        },
        [bannerUrlField?.idValue || '']: {
          text: 'https://microcms.io/',
        },
        [bannerDescriptionField?.idValue || '']: {
          textArea:
            // Switching with i18n.language because it is a very long text
            i18n.language === 'ja'
              ? `バナーテンプレートからAPIを作成しました🎉
WebサイトやアプリのバナーをmicroCMSで管理することで運用を効率化できます。

✔︎ APIプレビュー
microCMSでは入稿したコンテンツはAPIベースで取得します。
画面右上の「APIプレビュー」からAPI利用時の実際のレスポンスをお試しいただけます。

✔︎ 画像API
microCMSの画像APIを使うとデバイスごとに最適なサイズ・形式で画像を扱えます。
表示の高速化、データ通信量の低減に役立ちます。詳しくは以下のドキュメントを参照ください。
https://document.microcms.io/image-api/introduction

✔︎サポート・お問い合せ
お困りなことや疑問点などございましたらお気軽にご連絡ください。

サポート窓口：support@microcms.io
よくある質問：https://help.microcms.io/ja/knowledge`
              : `Created from the banner template🎉
Websites and application banners can be managed with microCMS to streamline operations.

✔︎ Preview API
microCMS retrieves submitted content on an API basis.
You can try out the actual response when using the API from the "Preview API" in the upper right corner of the screen.

✔︎ Image API
A microCMS image API allows you to handle images in the optimal size and format for various devices.
It helps speed up the display and reduce data traffic. For more information, please refer to the following documents.
https://document.microcms.io/image-api/introduction

✔︎ Support・Contact
Please feel free to contact us if you have any problems or questions.

Support:support@microcms.io
Frequently Asked Questions: https://help.microcms.io/ja/knowledge`,
        },
      },
      contentStatus: 'PUBLISH',
    });

    //画面遷移
    apiListInvalidateCache();
    addToast(t('Created an API.'), { appearance: 'success' });
    history.push('/apis/banner');
  }, [
    setIsLoading,
    createApiAsync,
    t,
    refetchApiList,
    uploadMedia,
    createContentAsync,
    apiListInvalidateCache,
    addToast,
    history,
  ]);
};
