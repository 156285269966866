import type { Role } from '@/entity/role';

/**
 * 対象のAPIのREAD権限を持っている場合はtrueを返す
 */
function hasApiReadPermission<
  T extends Pick<
    Role,
    'defaultContentPermission' | 'exceptionContentPermissions'
  >
>({ roles, apiId }: { roles: Array<T> | undefined; apiId: string }): boolean {
  if (!roles) {
    return false;
  }
  return roles?.some((role: T) => {
    const ecp = role.exceptionContentPermissions?.find(
      (ecp) => ecp.apiId === apiId
    );
    const permission = ecp ? ecp.permission : role.defaultContentPermission;
    return permission.apiRead;
  });
}

export { hasApiReadPermission };
