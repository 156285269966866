import { API, graphqlOperation } from 'aws-amplify';

import type { SAMLConfiguration } from '@/entity/saml';

import * as mutations from '@/graphql/mutations';
import * as queries from '@/graphql/queries';

export type UpdateParams = {
  serviceId: string;
  idpMetadataURL: string | undefined;
  idpMetadataXML: string | undefined;
  defaultRoleId: string;
  samlOnly: boolean;
};

export type DeleteParams = {
  serviceId: string;
};

export type ResultResponse = {
  result: boolean;
  message?: string;
};

export const samlRepository = () => {
  const findSAMLConfiguration = async (serviceId: string) => {
    try {
      const result = await API.graphql(
        graphqlOperation(queries.getSAMLConfiguration, { serviceId }),
      );
      const typedResult = result as {
        data: { getSAMLConfiguration: SAMLConfiguration };
      };
      return typedResult.data.getSAMLConfiguration;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not fetch SAML configuration');
    }
  };

  const updateSAMLConfiguration = async (
    serviceId: string,
    idpMetadataURL: string | undefined,
    idpMetadataXML: string | undefined,
    defaultRoleId: string,
    samlOnly: boolean,
  ) => {
    try {
      const response = await API.graphql(
        graphqlOperation(mutations.setSAMLConfiguration, {
          serviceId,
          idpMetadataURL,
          idpMetadataXML,
          defaultRoleId,
          samlOnly,
        }),
      );

      const updateResult = response as {
        data: { setSAMLConfiguration: { result: boolean; message?: string } };
      };
      return updateResult.data.setSAMLConfiguration;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not update SAML settings');
    }
  };

  const deleteSAMLConfiguration = async (serviceId: string) => {
    try {
      const response = await API.graphql(
        graphqlOperation(mutations.deleteSAMLConfiguration, {
          serviceId,
        }),
      );
      const deleteResult = response as {
        data: {
          deleteSAMLConfiguration: { result: boolean; message?: string };
        };
      };
      return deleteResult.data.deleteSAMLConfiguration;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not delete SAML configuration');
    }
  };

  return {
    findSAMLConfiguration,
    updateSAMLConfiguration,
    deleteSAMLConfiguration,
  };
};
