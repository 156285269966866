import { useCallback } from 'react';

import type { Api } from '@/entity/api';

// @ts-expect-error
export const useToggleItem = (api: Api, path: string, items, setItems) => {
  const toggleItem = useCallback(
    (idValue: string) => (on?: boolean) => {
      const _items = on
        ? [...items, idValue]
        : items.filter((item: any) => item !== idValue);
      setItems(_items);
      window.localStorage.setItem(`${path}`, JSON.stringify(_items));
    },
    [items, path, setItems],
  );

  return toggleItem;
};
