import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { validateMedium } from '@/components/Validations';

import type { FileError } from 'react-dropzone';

import { useUploadMedium } from '@/views/Common/medium/useMediumWriter';
import { useCurrentPlan } from '@/views/Common/service/useServiceReader';

type UseUploadByDragAndDropArgs = {
  serviceId?: string;
  domain?: string;
  multiple: boolean;
  onDropAccepted: (resultMediumIds: (string | null)[] | null) => void;
  hasMediumCreatePermission: boolean;
};

export const useUploadByDragAndDrop = ({
  serviceId,
  domain,
  multiple,
  onDropAccepted,
  hasMediumCreatePermission,
}: UseUploadByDragAndDropArgs) => {
  const { t } = useTranslation('imageOperation');

  // プランによる制限を取得するため現在のプラン情報を取得
  const { data: currentPlan } = useCurrentPlan(serviceId);

  // 画像のアップロードに関するミューテーションを定義
  const { uploadMediumAsync, uploadMediumProgress } = useUploadMedium({
    serviceId,
    domain,
  });

  const { addToast } = useToasts();

  // ドラッグ＆ドロップの動作を定義
  const { isDragActive, getInputProps, getRootProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple,
    accept: {
      'image/*': [],
    },
    maxFiles: multiple ? 10 : undefined,
    validator: (file) => {
      // プランごとのサイズ制限
      const error = validateMedium(file, currentPlan ?? undefined);
      if (error) {
        return {
          code: 'custom',
          message: error,
        };
      }
      return null;
    },
    onDropAccepted: async (acceptedFiles) => {
      if (!hasMediumCreatePermission) {
        addToast(t('No upload permission'), {
          appearance: 'error',
        });
        return;
      }
      const resultMediumIds = await uploadMediumAsync(acceptedFiles);
      onDropAccepted(resultMediumIds);
    },
    onDropRejected: (fileRejection) => {
      const errors: FileError[] = [];
      fileRejection.forEach((rejection) => {
        rejection.errors.forEach((error) => {
          // 同じエラーが複数回出力されないようにする
          errors.every((e) => e.code !== error.code) && errors.push(error);
        });
      });
      errors.forEach((error) => {
        switch (error.code) {
          case 'file-invalid-type': {
            addToast(t('Upload your file in image format'), {
              appearance: 'error',
            });
            break;
          }
          case 'too-many-files': {
            addToast(
              multiple
                ? t('Please add up to 10 images')
                : t('Please add only one image'),
              {
                appearance: 'error',
              },
            );
            break;
          }
          case 'custom': {
            addToast(error.message, {
              appearance: 'error',
            });
            break;
          }
          default:
            addToast(t('Failed to upload images'), {
              appearance: 'error',
            });
            break;
        }
      });
    },
  });

  return {
    isDragActive,
    dropZoneRootProps: getRootProps(),
    dropZoneInputProps: getInputProps(),
    uploadMediumProgress,
  };
};
