import { Td } from '@/components/ui/Table';

import { FIELD_NO_VALUE } from '../constants';
import { useI18nFormRole } from '../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../provider';

import { Checkbox } from '@/views/Common/Ui/Checkbox';

const FieldDevMenu = () => {
  const { t } = useI18nFormRole();

  const {
    isAdminRole,
    input: {
      defaultContentPermission: { showDeveloperMenu },
    },
  } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  return (
    <>
      <Td>{t('Developer menu')}</Td>
      <Td>
        <Checkbox
          checked={showDeveloperMenu}
          disabled={disabled}
          name="defaultContentPermission.showDeveloperMenu"
          onChange={(e) => {
            const payload = e.target.checked;
            dispatch({
              type: 'set-dc-permission-showdevelopermenu',
              payload,
            });
          }}
          label={t('Show developer settings')}
        />
      </Td>
      <Td>{FIELD_NO_VALUE}</Td>
      <Td>{FIELD_NO_VALUE}</Td>
      <Td>{FIELD_NO_VALUE}</Td>
    </>
  );
};

export default FieldDevMenu;
