import { useCallback, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from 'src/components/ui/Button';
import ExternalLink from 'src/components/ui/ExternalLink';
import { Table, Tbody, Td, Th, Thead, Tr } from 'src/components/ui/Table';
import Tooltip from 'src/components/ui/Tooltip';
import { useDeleteMediumTag } from 'src/hooks/MediumTags/useMediumTags';
import { formatDateToMinutes } from 'src/util/date';

import EditMediumTag from '../EditMediumTag';

import type { MediumTag } from 'src/types/MediumTag';

import styles from './mediumTags.module.css';

type Props = {
  serviceId: string;
  listMediumTags?: {
    tags: MediumTag[] | null;
  };
  isLoading?: boolean;
};

const MediumTags: React.FC<Props> = ({
  serviceId,
  listMediumTags,
  isLoading,
}) => {
  const { t } = useTranslation('mediumTags');
  const history = useHistory();
  const [MediumTagModal, openModal, closeModal] = useModal('root');
  const [deleteMediumTag] = useDeleteMediumTag();
  // メディアタグ編集時の値
  const [mediumTag, setMediumTag] = useState<MediumTag | undefined>(undefined);

  const onRemoveMediumTag = useCallback(
    (mediumTagId: string) => {
      window.confirm(
        t(
          'This cannot be recovered once deleted. Are you sure you want to delete it?',
        ),
      ) &&
        deleteMediumTag({
          serviceId,
          mediumTagId,
        });
    },
    [deleteMediumTag, serviceId, t],
  );

  if (listMediumTags === undefined || isLoading) {
    return (
      <div className={styles.empty}>
        <img src="/images/icon_loading.svg" alt="" />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {listMediumTags.tags !== null && listMediumTags.tags?.length !== 0 && (
        <div>
          <div className={styles.displayCount}>
            {t('Display {{count}} items', {
              count: listMediumTags.tags?.length,
            })}
          </div>
          <main className={styles.main}>
            <div className={styles.tableWrap}>
              <Table>
                <Thead>
                  <Tr>
                    <Th>{t('Tag Name')}</Th>
                    <Th>{t('Date Created')}</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {listMediumTags.tags?.map((tag: MediumTag) => (
                    <Tr key={tag.mediumTagId} enableHoverStyle={false}>
                      <Td>{tag.name}</Td>
                      <Td className={styles.date}>
                        {formatDateToMinutes(tag.createdAt)}
                      </Td>
                      <Td className={styles.tooltipPosition}>
                        <Tooltip
                          alignment="right"
                          trigger={
                            <i className={`material-icons ${styles.menuIcon}`}>
                              more_vert
                            </i>
                          }
                        >
                          <ul className={styles.tooltip}>
                            <li className={styles.tooltipList}>
                              <button
                                className={styles.tooltipButton}
                                onClick={() => {
                                  setMediumTag(tag);
                                  openModal();
                                }}
                              >
                                <i className="material-icons">edit</i>
                                {t('Edit Tag')}
                              </button>
                            </li>
                            <li className={styles.tooltipList}>
                              <button
                                className={styles.tooltipButton}
                                onClick={() =>
                                  onRemoveMediumTag(tag.mediumTagId)
                                }
                              >
                                <i className="material-icons">clear</i>
                                {t('Remove Tag')}
                              </button>
                            </li>
                          </ul>
                        </Tooltip>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </main>
        </div>
      )}
      {listMediumTags.tags?.length === 0 && (
        <div className={styles.empty}>
          <img
            className={styles.emptyIcon}
            src="/images/icon_empty.svg"
            alt=""
          />
          <p className={styles.emptyTitle}>{t('No tags')}</p>
          <p className={styles.emptySubText}>
            {t(
              'The tags which have been set for your media will be displayed here.',
            )}
            <br />
            {t('New tags can be created from the media details page.')}
          </p>
          <p className={styles.link}>
            {Trans({
              t,
              i18nKey:
                'For more information, please see Media Tag Settings (Documentation).',
              children: (
                <ExternalLink
                  href="https://document.microcms.io/manual/media-tag-settings"
                  hasUnderline={true}
                  eraseNoreferrer={true}
                >
                  Media Tag Settings (Documentation)
                </ExternalLink>
              ),
            })}
          </p>
          <Button
            type="secondary"
            value={t('Go to media details page')}
            size="large"
            className={styles.button}
            onClick={() => history.push('/media')}
          />
        </div>
      )}
      {listMediumTags.tags === null && (
        <div className={styles.empty}>{t('No tags found')}</div>
      )}
      <MediumTagModal>
        <EditMediumTag
          serviceId={serviceId}
          mediumTag={mediumTag}
          closeModal={closeModal}
        />
      </MediumTagModal>
    </div>
  );
};

export default MediumTags;
