import type React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  useVerifyMemberInvitation,
  useAcceptMemberInvitation,
} from '../../hooks/Auth/useAuth';
import { getHost } from '../../util';

import styles from './verifyinvitation.module.css';

const VerifyInvitation: React.FC = () => {
  const { t } = useTranslation('verifyInvitation');
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const token = params.get('token') || '';

  const { addToast } = useToasts();
  const {
    verifyMemberInvitationData,
    verifyMemberInvitation,
    verifyMemberInvitationLoading,
    verifyMemberInvitationError,
  } = useVerifyMemberInvitation();
  const { acceptMemberInvitation, acceptMemberInvitationIsSuccess } =
    useAcceptMemberInvitation();

  useEffect(() => {
    token && verifyMemberInvitation({ token });
  }, [token, verifyMemberInvitation]);

  useEffect(() => {
    if (verifyMemberInvitationError) {
      // メッセージを出してから遷移する
      setTimeout(() => {
        window.location.href = `${getHost('app')}`;
      }, 3000);
    }
  }, [verifyMemberInvitationError]);

  useEffect(() => {
    if (verifyMemberInvitationData?.result === false) {
      const errorMessage =
        verifyMemberInvitationData?.message ?? t('Authentication failed.');
      addToast(errorMessage, { appearance: 'error' });
      // メッセージを出してから遷移する
      setTimeout(() => {
        window.location.href = `${getHost('app')}`;
      }, 3000);
    }
  }, [
    addToast,
    verifyMemberInvitationData,
    verifyMemberInvitationData?.message,
    verifyMemberInvitationData?.result,
    t,
  ]);

  //成功した場合は他の招待がある可能性もあるので対応しておく
  useEffect(() => {
    if (verifyMemberInvitationData?.result === true) {
      acceptMemberInvitation();
    }
  }, [acceptMemberInvitation, verifyMemberInvitationData?.result]);

  //招待受諾と他サービスの確認が終わったら画面遷移
  useEffect(() => {
    if (acceptMemberInvitationIsSuccess === true) {
      window.location.href = `${getHost(verifyMemberInvitationData?.domain)}`;
    }
  }, [
    acceptMemberInvitationIsSuccess,
    addToast,
    verifyMemberInvitationData,
    verifyMemberInvitationData?.domain,
  ]);

  //失敗ケース
  if (!token) {
    return <Redirect to="/" />;
  }

  if (verifyMemberInvitationLoading) {
    return (
      <div className={styles.loading}>
        <img src="/images/icon_loading.svg" alt="" />
      </div>
    );
  }

  return (
    <div className={styles.loading}>
      <img src="/images/icon_loading.svg" alt="" />
    </div>
  );
};

export default VerifyInvitation;
