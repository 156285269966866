import { useQueryClient } from '@tanstack/react-query';
import cx from 'classnames';
import dayjs from 'dayjs';
import qs from 'query-string';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { useGetMyService } from '@/hooks/useService';

import { useLoading } from '../../hooks';
import { useValidApiKey } from '../../hooks/ApiKey/useApiKeyReader';
import { myRolesContext } from '../../hooks/Role/useMyRoles';
import { useStripeActions } from '../../hooks/useStripeActions';
import { getApiHost } from '../../util';
import Button from '../ui/Button';
import { Android, Curl, Go, IOS, JavaScript, Php, Ruby } from './Code';
import Method from './Method';
import Params from './Params';

import type { ApiList, MigrateApi } from '@/entity/api';
import type { Param } from './Params';

import styles from './apiRequest.module.css';

import { apiListSelectors } from '@/ducks/apiList';
import { useAppSelector } from '@/store/hooks';

type Props = {
  contentId?: string;
  draftKey?: string;
};

const ApiRequest: React.FC<Props> = ({ contentId, draftKey }) => {
  const { t } = useTranslation('apiRequest');
  const { service } = useGetMyService();

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint } = useParams<{ endpoint: string }>();
  const api = useAppSelector((state) =>
    apiListSelectors.getApi(state.apiListState as ApiList, endpoint),
  ) as MigrateApi;

  const { apiType } = api;
  const { currentPlan } = useStripeActions(service);

  const context = useContext(myRolesContext);
  const isAdmin = useMemo(() => {
    return context.roles
      ? context.roles.some((role) => role.isAdmin === true)
      : undefined;
  }, [context.roles]);

  //HTTPメソッド
  const [method, setMethod] = useState('GET');

  // 権限が付与されているAPIキーのみを取得、ない場合はundefined
  const validApiKey = useValidApiKey(
    service?.partitionKey || '',
    api.partitionKey,
    // @ts-ignore
    method.toLowerCase(),
  );

  // メソッドメニュー開閉
  const [methodOpen, setMethodOpen] = useState(false);
  const changeMethod = useCallback((v: any) => {
    setMethod(v);
    setMethodOpen(false);
  }, []);

  // Params
  const defaultParams = useMemo(
    () =>
      draftKey
        ? [
            {
              key: 'draftKey',
              value: draftKey,
              check: true,
            },
          ]
        : [],
    [draftKey],
  );

  // Write Params
  const defaultWriteParams = useMemo(
    () => [
      {
        key: 'status',
        value: 'draft',
        check: false,
      },
    ],
    [],
  );

  const [params, setParams] = useState<any>(defaultParams);
  const resetParams = useCallback(() => {
    setParams(defaultParams);
  }, [defaultParams]);

  //パス情報
  const defaultPath =
    endpoint + (apiType === 'LIST' && contentId ? `/${contentId}` : '');
  const [inputPath, setInputPath] = useState(defaultPath);
  const onChangePath = useCallback((e: any) => {
    setInputPath(e.target.value);
  }, []);
  useEffect(() => {
    if (method !== 'PUT') {
      setInputPath(defaultPath);
    }
  }, [defaultPath, method]);

  useEffect(() => {
    if (method === 'GET') {
      resetParams();
    }
    if (method === 'POST' || method === 'PUT') {
      setParams(defaultWriteParams);
    }
  }, [defaultWriteParams, resetParams, method]);

  //ボディ部
  const defaultBody = useMemo(() => {
    return api.apiFields
      .map(({ fieldId, kind }) => {
        return {
          [fieldId]:
            kind === 'text'
              ? t('text1')
              : kind === 'textArea' || kind === 'richEditor'
                ? t('Enter multiline text,\nEnter multiline text')
                : kind === 'media' || kind === 'file'
                  ? ''
                  : kind === 'mediaList'
                    ? []
                    : kind === 'richEditorV2'
                      ? t(
                          '<p>Enter multiline text<br />Enter multiline text</p>',
                        )
                      : kind === 'date'
                        ? // @ts-ignore
                          dayjs().toISOString(true)
                        : kind === 'number'
                          ? 123
                          : kind === 'boolean'
                            ? true
                            : kind === 'select'
                              ? [t('Options')]
                              : kind === 'relation'
                                ? t('Reference id')
                                : kind === 'relationList'
                                  ? [t('Reference id 1'), t('Reference id 2')]
                                  : kind === 'custom'
                                    ? {
                                        fieldId: 'YOUR_FIELD_ID',
                                        some_value: '',
                                      }
                                    : kind === 'repeater'
                                      ? [
                                          {
                                            fieldId: 'YOUR_FIELD_ID',
                                            some_value: '',
                                          },
                                        ]
                                      : kind === 'iframe'
                                        ? {
                                            id: 'YOUR_ID',
                                            description: 'YOUR_DESCRIPTION',
                                            imageUrl:
                                              'https://example.com/sample.jpg',
                                            data: { some_value: '' },
                                          }
                                        : '',
        };
      })
      .reduce(
        (obj, data) => ({
          ...obj,
          ...data,
        }),
        {},
      );
  }, [api, t]);

  const bodyEl = useRef<HTMLInputElement>(null);
  const [currentBody, setCurrentBody] = useState(JSON.stringify(defaultBody)); //curlコマンドを再描画したいので用意
  const resetBody = useCallback(() => {
    if (bodyEl.current) {
      bodyEl.current.value = JSON.stringify(defaultBody, null, 2);
    }
    setCurrentBody(JSON.stringify(defaultBody));
  }, [defaultBody]);
  useEffect(() => {
    resetBody();
  }, [resetBody]);

  // language選択
  const defaultLanguage = useMemo(() => {
    const lang = window.localStorage.getItem(`${api.id}#apiPreview#lang`);
    return lang === null ? 'JavaScript' : lang;
  }, [api.id]);
  const [language, setLanguage] = useState(defaultLanguage);
  const setLang = useCallback(
    (lang: any) => {
      setLanguage(lang);
      window.localStorage.setItem(`${api.id}#apiPreview#lang`, lang);
    },
    [api.id],
  );

  //HTTPリクエスト周り
  const [httpResult, setHttpResult] = useState<{
    body: string | null;
    status: any;
    statusText: string | null;
  } | null>(null);
  const resetHttpResult = useCallback(() => {
    setHttpResult(null);
  }, []);
  const [loading, startLoading] = useLoading(httpResult !== null);
  const cache = useQueryClient();
  const doRequest = useCallback(async () => {
    resetHttpResult();
    startLoading();
    const query = params
      .filter((p: Param) => p.check)
      .reduce(
        (prev: Param, obj: Param) => ({ ...prev, [obj.key]: obj.value }),
        {},
      );
    const queryString = qs.stringify(query);
    const url = `${getApiHost()}/api/v1/${inputPath}${
      queryString && '?' + queryString
    }`;
    const result = await fetch(url, {
      method,
      // @ts-ignore
      headers: {
        'X-MICROCMS-API-KEY': validApiKey ? validApiKey.value : '',
        'Content-Type': 'application/json',
        'X-API-Preview': true,
      },
      body: bodyEl.current && bodyEl.current.value,
    }).catch((error) => error);

    if (result instanceof Error) {
      return setHttpResult({
        body: null,
        status: t('API Request is failed'),
        statusText: null,
      });
    }

    const body = await result.json().catch(() => '');
    const status = result.status;
    const statusText = result.statusText;
    setHttpResult({ body, status, statusText });

    // GET以外は一覧のキャッシュ削除
    if (method !== 'GET') {
      cache.invalidateQueries(['contentList'], { type: 'all' });
    }
  }, [
    cache,
    inputPath,
    method,
    params,
    resetHttpResult,
    startLoading,
    validApiKey,
    t,
  ]);

  const isList = useMemo(() => {
    return apiType === 'LIST' && inputPath === endpoint;
  }, [apiType, endpoint, inputPath]);

  return (
    <div>
      <div className={styles.endpoint}>
        <p className={styles.origin}>
          <button className={styles.method} onClick={() => setMethodOpen(true)}>
            {method}
            <i className="material-icons-outlined">expand_more</i>
          </button>
          {getApiHost()}/api/v1/
        </p>
        {method === 'PUT' ? (
          <input
            type="text"
            className={styles.input}
            value={inputPath}
            onChange={onChangePath}
          />
        ) : (
          <select className={styles.input} onChange={onChangePath}>
            {apiType === 'LIST' && contentId && (
              <option value={`${endpoint}/${contentId}`}>
                {endpoint}/{contentId}
              </option>
            )}
            <option value={endpoint}>{endpoint}</option>
          </select>
        )}
        <Button
          icon="send"
          className="ga-api-preview-button"
          onClick={doRequest}
          value={method === 'GET' ? t('Get') : t('Send')}
        />
        {methodOpen && (
          <div>
            <div
              className={styles.methodMask}
              onClick={() => setMethodOpen(false)}
            />
            <ul className={styles.methodMenu}>
              {(api.apiType === 'PAGE'
                ? ['GET', 'PATCH']
                : isList
                  ? ['GET', 'POST', 'PUT']
                  : ['GET', 'PUT', 'PATCH', 'DELETE']
              ).map((v, i) => (
                <li
                  className={styles.methodMenuList}
                  key={i}
                  onClick={() => changeMethod(v)}
                >
                  {v}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {!validApiKey && (
        <>
          {isAdmin ? (
            <span className={styles.error}>
              <i className="material-icons-outlined">error</i>
              <p className={styles.alert}>
                {/* @ts-ignore */}
                <Trans
                  i18nKey="There is no corresponding API key. You can create it here."
                  t={t}
                >
                  There is no corresponding API key. You can create it
                  {'  '}
                  <Link to="/api-keys">here</Link>.
                </Trans>
              </p>
            </span>
          ) : (
            <span className={styles.error}>
              <i className="material-icons-outlined">error</i>
              <p className={styles.alert}>
                {t(
                  'There is no corresponding API key. Only administrators can create them.',
                )}
              </p>
            </span>
          )}
        </>
      )}
      {method === 'GET' && (
        <div className={styles.details}>
          <dl className={styles.headers}>
            <dt className={styles.detailTitle}>
              Headers
              <a
                href={
                  isList
                    ? 'https://document.microcms.io/content-api/get-list-contents#h91d3988597'
                    : 'https://document.microcms.io/content-api/get-content#h91d3988597'
                }
                target="docs"
                className={styles.helpLink}
              >
                <i className={`material-icons ${styles.helpIcon}`}>
                  help_outline
                </i>
              </a>
            </dt>
            <dd>
              <ul>
                <li>
                  <p className={styles.headerKey}>X-MICROCMS-API-KEY :</p>
                  {validApiKey ? (
                    validApiKey.value
                  ) : (
                    <Link to="/api-keys">{t('Create API Key')}</Link>
                  )}
                </li>
              </ul>
            </dd>
          </dl>
          <dl className={styles.body}>
            <dt className={styles.detailTitle}>
              Params
              <a
                href={
                  isList
                    ? 'https://document.microcms.io/content-api/get-list-contents#h9ce528688c'
                    : 'https://document.microcms.io/content-api/get-content#h9ce528688c'
                }
                target="docs"
                className={styles.helpLink}
              >
                <i className={`material-icons ${styles.helpIcon}`}>
                  help_outline
                </i>
              </a>
              <button className={styles.smallButton} onClick={resetParams}>
                reset
              </button>
            </dt>
            <dd>
              <Params
                params={params}
                change={setParams}
                draftKey={draftKey}
                isList={isList}
              />
            </dd>
          </dl>
        </div>
      )}
      {method === 'POST' && (
        <Method
          method="POST"
          href={`https://document.microcms.io/content-api/${method.toLowerCase()}-content#h91d3988597`}
          bodyHref={`https://document.microcms.io/content-api/${method.toLowerCase()}-content#h44fdca3bb7`}
          apiKey={validApiKey}
          resetBody={resetBody}
          defaultBody={defaultBody}
          bodyEl={bodyEl}
          setCurrentBody={setCurrentBody}
          params={params}
          setParams={setParams}
          currentPlan={currentPlan}
        />
      )}
      {method === 'PUT' && (
        <Method
          method="PUT"
          href={`https://document.microcms.io/content-api/${method.toLowerCase()}-content#h91d3988597`}
          bodyHref={`https://document.microcms.io/content-api/${method.toLowerCase()}-content#h44fdca3bb7`}
          apiKey={validApiKey}
          resetBody={resetBody}
          defaultBody={defaultBody}
          bodyEl={bodyEl}
          setCurrentBody={setCurrentBody}
          params={params}
          setParams={setParams}
          currentPlan={currentPlan}
        />
      )}
      {method === 'PATCH' && (
        <Method
          method="PATCH"
          href={`https://document.microcms.io/content-api/${method.toLowerCase()}-content#h91d3988597`}
          bodyHref={`https://document.microcms.io/content-api/${method.toLowerCase()}-content#h44fdca3bb7`}
          apiKey={validApiKey}
          resetBody={resetBody}
          defaultBody={defaultBody}
          bodyEl={bodyEl}
          setCurrentBody={setCurrentBody}
        />
      )}
      {method === 'DELETE' && (
        <Method
          method="DELETE"
          href={`https://document.microcms.io/content-api/${method.toLowerCase()}-content#h91d3988597`}
          apiKey={validApiKey}
        />
      )}
      <div>
        <ul className={styles.tabs}>
          <li
            className={cx(styles.tab, {
              [styles.active]: language === 'JavaScript',
            })}
            onClick={() => setLang('JavaScript')}
          >
            JavaScript
            <a
              href="https://document.microcms.io/tutorial/javascript/javascript-top"
              target="docs"
              className={styles.helpLink}
            >
              <i className={`material-icons ${styles.helpIcon}`}>
                help_outline
              </i>
            </a>
          </li>
          <li
            className={cx(styles.tab, {
              [styles.active]: language === 'IOS',
            })}
            onClick={() => setLang('IOS')}
          >
            iOS
            <a
              href="https://document.microcms.io/tutorial/ios/ios-top"
              target="docs"
              className={styles.helpLink}
            >
              <i className={`material-icons ${styles.helpIcon}`}>
                help_outline
              </i>
            </a>
          </li>
          <li
            className={cx(styles.tab, {
              [styles.active]: language === 'Android',
            })}
            onClick={() => setLang('Android')}
          >
            Android
            <a
              href="https://document.microcms.io/tutorial/android/android-top"
              target="docs"
              className={styles.helpLink}
            >
              <i className={`material-icons ${styles.helpIcon}`}>
                help_outline
              </i>
            </a>
          </li>
          <li
            className={cx(styles.tab, {
              [styles.active]: language === 'PHP',
            })}
            onClick={() => setLang('PHP')}
          >
            PHP
            <a
              href="https://document.microcms.io/tutorial/php/php-top"
              target="docs"
              className={styles.helpLink}
            >
              <i className={`material-icons ${styles.helpIcon}`}>
                help_outline
              </i>
            </a>
          </li>
          <li
            className={cx(styles.tab, {
              [styles.active]: language === 'Ruby',
            })}
            onClick={() => setLang('Ruby')}
          >
            Ruby
            <a
              href="https://document.microcms.io/tutorial/ruby/ruby-top"
              target="docs"
              className={styles.helpLink}
            >
              <i className={`material-icons ${styles.helpIcon}`}>
                help_outline
              </i>
            </a>
          </li>
          <li
            className={cx(styles.tab, {
              [styles.active]: language === 'GO',
            })}
            onClick={() => setLang('GO')}
          >
            Go
            <a
              href="https://document.microcms.io/tutorial/go/go-top"
              target="docs"
              className={styles.helpLink}
            >
              <i className={`material-icons ${styles.helpIcon}`}>
                help_outline
              </i>
            </a>
          </li>
          <li
            className={cx(styles.tab, {
              [styles.active]: language === 'cURL',
            })}
            onClick={() => setLang('cURL')}
          >
            cURL
          </li>
        </ul>
        <div className={styles.code}>
          {language === 'JavaScript' && (
            <JavaScript
              inputPath={inputPath}
              params={params}
              method={method}
              body={currentBody}
            />
          )}
          {language === 'IOS' && (
            <IOS inputPath={inputPath} params={params} method={method} />
          )}
          {language === 'Android' && (
            <Android inputPath={inputPath} params={params} method={method} />
          )}
          {language === 'PHP' && (
            <Php
              inputPath={inputPath}
              params={params}
              method={method}
              body={currentBody}
              apiType={apiType}
            />
          )}
          {language === 'Ruby' && (
            <Ruby
              inputPath={inputPath}
              params={params}
              method={method}
              body={currentBody}
              apiType={apiType}
            />
          )}
          {language === 'GO' && (
            <Go
              inputPath={inputPath}
              params={params}
              method={method}
              body={currentBody}
              apiType={apiType}
            />
          )}
          {language === 'cURL' && (
            <Curl
              host={getApiHost()}
              inputPath={inputPath}
              method={method}
              params={params}
              body={currentBody}
              apiKey={validApiKey}
            />
          )}
        </div>
      </div>
      {loading ? (
        <div className={styles.loading}>
          <img src="/images/icon_loading.svg" alt="" />
        </div>
      ) : (
        httpResult && (
          <div>
            <h3 className={styles.title}>{t('Response')}</h3>
            <div
              className={
                httpResult.status <= 399
                  ? styles.httpStatus
                  : `${styles.httpStatus} ${styles.hasError}`
              }
            >
              <span className={styles.statusCode}>{httpResult.status}</span>
              {httpResult.statusText}
            </div>
            <div className={styles.response}>
              <pre>
                {httpResult.body
                  ? JSON.stringify(httpResult.body, undefined, 4)
                  : ''}
              </pre>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default ApiRequest;
