import { useMemo } from 'react';

import { useMyRolesContext } from '@/hooks/Role/useMyRoles';

import { hasAdminPermission } from '@/util/permissions/admin';

export const useEnvironmentsSettingsTooltip = () => {
  const { roles } = useMyRolesContext();

  const isAdmin = useMemo(() => {
    return hasAdminPermission(roles);
  }, [roles]);

  const isDeleteEnvironmentRole = useMemo(() => {
    if (!roles) {
      return false;
    }
    if (isAdmin) {
      return true;
    }
    return roles.some((role) => role.environmentPermission.delete === true);
  }, [isAdmin, roles]);

  return {
    isAdmin,
    isDeleteEnvironmentRole,
  };
};
