import cx from 'classnames';
import { filesize } from 'filesize';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useInput } from '../../hooks';
import useMediumWriter from '../../hooks/Medium/useMediumWriter';
import { getResourceUrl } from '../../util';
import { formatDateToMinutes } from '../../util/date';
import { RelatedContentsModal } from '../Form';
import { validateFileName } from '../Validations';
import Button from '../ui/Button';
import { Tag } from '../ui/Tag';
import Textfield from '../ui/Textfield';
import { TagsSettings } from './TagsSettings';

import type { ListMediumTags } from '@/types/MediumTag';
import type { Medium } from '../../entity/medium';
import type { Service } from '../../types/services';

import styles from './mediaJson.module.css';

import { Modal, ModalContent, ModalTrigger } from '@/views/Common/Ui/Modal';
import { useToggle } from '@/views/Common/Ui/useToggle';
import { UpdateCreatedBy } from '@/views/media/Media/MediaJson/UpdateCreatedBy';

interface Props {
  medium: Medium;
  contentIds?: string[];
  service: Service;
  listMediumTags?: ListMediumTags;
  addFiltersTag: (name: string) => void;
}

const MediaJson: React.FC<Props> = ({
  medium,
  contentIds,
  service,
  listMediumTags,
  addFiltersTag,
}) => {
  const { t } = useTranslation('mediaJson');

  const [isOpenRelatedModal, toggleRelatedModal] = useToggle(false);
  const [isOpenNameModal, toggleNameModal] = useToggle(false);
  const [fileUrl, setFileUrl] = useState<string | undefined>();
  const fileExtension = medium?.fileName.match('.[^.]*$');

  const [isOpenTagsSettingsModal, toggleTagsSettingsModal] = useToggle(false);

  const { updateMediumName, updateMediumNameLoading }: any =
    useMediumWriter(service);

  useEffect(() => {
    if (medium) {
      getResourceUrl(
        medium.directory,
        medium.fileName,
        '',
        medium.kind,
        service.customDomainImageHost,
        service.customDomainFileHost,
      ).then(setFileUrl);
    }
  }, [service.customDomainImageHost, service.customDomainFileHost, medium]);

  const [newFileName, onChangeNewFileName, newFileNameError, setNewFileName] =
    // @ts-expect-error
    useInput(medium.fileName, validateFileName);

  useEffect(() => {
    if (medium) {
      setNewFileName(medium.fileName);
    }
  }, [medium, setNewFileName]);

  const changeName = useCallback(() => {
    // ファイル名と拡張を合わせる
    const updateFileName = newFileName + fileExtension;
    if (updateFileName) {
      updateMediumName({ medium, fileName: updateFileName });
    }
    toggleNameModal(false);
  }, [fileExtension, medium, newFileName, toggleNameModal, updateMediumName]);

  const tags = useMemo(() => {
    if (!listMediumTags || !medium.tags) {
      return [];
    }

    const selectedMediumTags = medium.tags.flatMap((id) => {
      const matchedMediumTag = listMediumTags.tags.find(
        ({ mediumTagId }) => id === mediumTagId,
      );
      return matchedMediumTag !== undefined ? [matchedMediumTag] : [];
    });

    return selectedMediumTags;
  }, [listMediumTags, medium?.tags]);

  if (!medium) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.filenameWrapper}>
        <a
          className={styles.link}
          href={fileUrl}
          target="_blank"
          rel="noopener noreferrer"
          data-testid="media-json-media-link"
        >
          {medium.fileName}
        </a>
        <Modal open={isOpenNameModal} onOpenChange={toggleNameModal}>
          <ModalTrigger className={styles.linkButton}>
            <i className={`material-icons ${styles.icon}`}>edit</i>
          </ModalTrigger>
          <ModalContent size="medium" title={t('Rename File')}>
            <div className={styles.modalContents}>
              <div className={styles.form}>
                <Textfield
                  defaultValue={medium?.fileName.replace(/.[^.]*$/, '')}
                  onChange={onChangeNewFileName}
                  errorText={newFileNameError}
                  className={styles.formTextfield}
                />
                <p
                  className={cx(styles.fileExtension, {
                    [styles.error]: newFileNameError,
                  })}
                >
                  {fileExtension}
                </p>
              </div>
              <div className={styles.modalButton}>
                <Button
                  value={t('Save changes')}
                  size="large"
                  type="secondary"
                  onClick={changeName}
                  disabled={
                    newFileNameError ||
                    medium.fileName === newFileName ||
                    updateMediumNameLoading
                  }
                />
              </div>
            </div>
          </ModalContent>
        </Modal>
      </div>

      <table>
        <tbody>
          <tr>
            <th className={styles.th}>{t('Date Created')}</th>
            <td className={styles.td}>
              {formatDateToMinutes(medium.createdAt)}
            </td>
          </tr>
          <tr>
            <th className={styles.th}>{t('Format')}</th>
            <td className={styles.td}>{medium.contentType.split(';')[0]}</td>
          </tr>
          <tr>
            <th className={styles.th}>{t('File Size')}</th>
            <td>{filesize(medium.contentLength)}</td>
          </tr>
          {medium.imageWidth && medium.imageHeight && (
            <tr>
              <th className={styles.th}>{t('Image Size')}</th>
              <td>
                {medium.imageWidth} × {medium.imageHeight}
              </td>
            </tr>
          )}
          <tr>
            <th className={styles.th}>{t('Created By')}</th>
            <td>
              <UpdateCreatedBy
                mediumId={medium.mediumId}
                mediumCreatedBy={medium.uploader}
                serviceId={service.partitionKey}
              />
            </td>
          </tr>
          <tr>
            <th className={styles.th}>{t('Content References')}</th>
            <td>
              {!!contentIds && contentIds.length > 0 ? (
                <Modal
                  open={isOpenRelatedModal}
                  onOpenChange={toggleRelatedModal}
                >
                  <ModalTrigger className={styles.openModalTextButton}>
                    {t('Number of references', { count: contentIds?.length })}
                  </ModalTrigger>
                  <ModalContent size="large" hasSpace={false}>
                    <RelatedContentsModal
                      service={service}
                      contentIds={contentIds}
                    />
                  </ModalContent>
                </Modal>
              ) : contentIds?.length === 0 ? (
                <span>{t('None')}</span>
              ) : (
                <span>{t('Loading...')}</span>
              )}
            </td>
          </tr>
          {
            <tr>
              <th className={styles.th}>{t('Tags')}</th>
              <td>
                <Modal
                  open={isOpenTagsSettingsModal}
                  onOpenChange={toggleTagsSettingsModal}
                >
                  {tags !== undefined && tags?.length > 0 ? (
                    <div className={styles.mediumTagsWrapper}>
                      <ul className={styles.mediumTags}>
                        {tags.map(({ mediumTagId, name }) => (
                          <li key={mediumTagId} className={styles.mediumTag}>
                            <button
                              className={styles.mediumTagButton}
                              onClick={() => addFiltersTag(mediumTagId)}
                            >
                              <Tag name={name} icon="sell" limitedWidth />
                            </button>
                          </li>
                        ))}
                      </ul>
                      <ModalTrigger
                        className={cx(
                          styles.mediumTagsLinkButton,
                          'track-click-by-gtm',
                        )}
                        data-gtm-track-event-name="click_edit_tag_icon"
                      >
                        <span className="material-icons">edit</span>
                      </ModalTrigger>
                    </div>
                  ) : (
                    <ModalTrigger
                      className={cx(
                        styles.openModalTextButton,
                        'track-click-by-gtm',
                      )}
                      data-gtm-track-event-name="click_edit_tag_button"
                    >
                      {t('Setup')}
                    </ModalTrigger>
                  )}
                  <TagsSettings
                    serviceId={service.partitionKey}
                    medium={medium}
                    listMediumTags={listMediumTags}
                    tags={tags}
                    closeModal={() => toggleTagsSettingsModal(false)}
                  />
                </Modal>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  );
};

export default MediaJson;
