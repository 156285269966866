import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useHistory } from 'react-router-dom';

import { useLoadApis } from '@/hooks/ApiList/useApiListReader';
import { useGetMyService } from '@/hooks/useService';

import CheckRoles from '../CheckRoles';
import Main from '../Main';
import Button from '../ui/Button';
import Searchfield from '../ui/Searchfield';
import { Table, Tbody, Td, Th, Thead, Tr } from '../ui/Table';

import styles from './apiList.module.css';

const ApiList: React.FC = () => {
  const { service } = useGetMyService();
  const history = useHistory();
  const { t } = useTranslation('apiList');
  const { apiList, filteredApis, searchApis } = useLoadApis(service);
  const displayApis = useMemo(() => {
    if (!apiList) {
      return [];
    }
    return filteredApis || apiList.list;
  }, [filteredApis, apiList]);

  if (apiList === undefined || service === undefined) {
    return null;
  }
  if (service === null) {
    return <Redirect to="/not-found" />;
  }
  if (apiList.list.length === 0) {
    return <Redirect to="/create-api" />;
  }
  return (
    <Main
      header={
        <header className={styles.headerTop}>
          <h2 className={styles.titleTop}>{t('API Management')}</h2>
        </header>
      }
    >
      <div className={styles.wrapper}>
        <CheckRoles permission="apiPermission" operation="create">
          <header className={styles.header}>
            <Searchfield
              onChange={(e) => searchApis(e.target.value)}
              className={styles.searchfield}
            />
            <Link to="/create-api">
              <Button icon="add" value={t('Add')} type="secondary" />
            </Link>
          </header>
        </CheckRoles>
        <main className={styles.main}>
          <div className={styles.tableWrap}>
            <Table>
              <Thead>
                <Tr>
                  <Th>{t('API Name')}</Th>
                  <Th>{t('Endpoint')}</Th>
                  <Th>{t('API Type')}</Th>
                  <Th>{t('Date Created')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {displayApis.map(
                  ({ contentId, apiName, apiType, createdAt }) => (
                    <Tr
                      key={contentId}
                      onClick={() => history.push(`/apis/${contentId}`)}
                    >
                      <Td>{apiName}</Td>
                      <Td>{contentId}</Td>
                      {apiType === 'PAGE' ? (
                        <Td>{t('Object Format')}</Td>
                      ) : (
                        <Td>{t('List Format')}</Td>
                      )}
                      <Td>{dayjs(createdAt).fromNow()}</Td>
                    </Tr>
                  ),
                )}
              </Tbody>
            </Table>
            {displayApis.length === 0 && (
              <div className={styles.empty}>{t('No matching Apis')}</div>
            )}
          </div>
        </main>
      </div>
    </Main>
  );
};

export default ApiList;
