import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon';
import { useFieldIds } from '../useFieldIds';

import styles from './fieldset.module.css';

export type Props = {
  label: string;
  description?: React.ReactNode;
  error?: string;
  required?: boolean;
  help?: string;
} & React.ComponentProps<'div'>;

export type ViewProps = Props & ReturnType<typeof useFieldIds>;

export const FieldsetView: React.FC<ViewProps> = ({
  label,
  labelId,
  description,
  descriptionId,
  error,
  errorId,
  required,
  help,
  ...props
}) => {
  const { t } = useTranslation('commonUi');

  return (
    <div
      role="group"
      className={props.className}
      aria-labelledby={labelId}
      aria-describedby={
        [descriptionId, errorId].filter(Boolean).join(' ') || undefined
      }
      {...props}
    >
      <div id={labelId} className={styles.label}>
        <span>{label}</span>
        {required && (
          <span className={styles.required}>
            <span aria-hidden>*</span>
            <span className="sr-only">{t('Required')}</span>
          </span>
        )}
        {help && (
          <a
            href={help}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.helpLink}
          >
            <Icon name="help_outline" />
          </a>
        )}
      </div>
      {description && (
        <div id={descriptionId} className={styles.description}>
          {description}
        </div>
      )}
      <div className={styles.content}>{props.children}</div>
      {error && (
        <div role="alert" id={errorId} className={styles.error}>
          {error}
        </div>
      )}
    </div>
  );
};

export const Fieldset: React.FC<Props> = (props) => {
  const hooks = useFieldIds(props);

  return <FieldsetView {...hooks} {...props} />;
};
