import type React from 'react';

import { useGetMyMember } from '@/hooks/Member/useMyMembers';

import JoinServiceNotifierModal from './JoinServiceNotifierModal';

import type { Service } from '@/types/services';

import { isInvitingMember } from '@/util/member';

type Props = {
  service: Service;
  parentService: Service | null;
};

const JoinServiceNotifier: React.FC<Props> = ({ service, parentService }) => {
  const { myMember, isLoading } = useGetMyMember(
    // 親環境があれば、親環境のパーティションキーを使う
    // もし、閲覧中の環境が子環境ではない場合は、parentServiceはnullをとる
    parentService ? parentService.partitionKey : service.partitionKey,
  );

  if (isLoading || !myMember) {
    return null;
  }

  return (
    <JoinServiceNotifierModal
      service={parentService || service}
      isOpen={isInvitingMember(myMember)}
    />
  );
};

export default JoinServiceNotifier;
