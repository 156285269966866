import { useCallback } from 'react';

import type { Props } from './Underline';

export const useUnderline = ({ editor }: Props) => {
  const toggleUnderlineHandler = useCallback(() => {
    editor.chain().focus().toggleUnderline().run();
  }, [editor]);
  const isActiveUnderline = editor.isActive('underline');

  return {
    toggleUnderlineHandler,
    isActiveUnderline,
  };
};
