import { membersRepository } from '@/repository/membersRepository';

import type { FindServiceUsersAssignableReviewRequestQueryVariables } from '@/API';
import type { ServiceUser } from '@/entity/member';

import { isInvitingMember } from '@/util/member';

export const inviteMembers = async ({
  serviceId,
  emails,
  roleIds,
}: {
  serviceId: string;
  emails: string[];
  roleIds: string[];
}) => {
  const { inviteMembers } = membersRepository(serviceId);
  return await inviteMembers({ emails, roleIds });
};

export const getAllMembers = async (
  serviceId: string,
  excludePending = true,
): Promise<ServiceUser[]> => {
  const { fetchAllMembers } = membersRepository(serviceId);
  const allMembers = await fetchAllMembers();

  if (!excludePending) return allMembers;

  const filteredMembers = allMembers.filter(
    (member) => excludePending && !isInvitingMember(member),
  );

  return filteredMembers;
};

export const cancelMemberInvitation = async ({
  serviceId,
  email,
}: {
  serviceId: string;
  email: string;
}) => {
  const { cancelMemberInvitation } = membersRepository(serviceId);
  return await cancelMemberInvitation({ email });
};

export const getMyMembers = async () => {
  const { getMyMembers } = membersRepository(
    // NOTE: getMyMembers()は、serviceIdに依存しないため、空文字を渡しておく
    '',
  );
  return getMyMembers();
};

export const getMyMember = async (serviceId: string) => {
  const { getMyMember } = membersRepository(serviceId);
  return getMyMember();
};

export const answerMemberInvitation = async (
  serviceId: string,
  // 参加承諾はtrue、拒否はfalse
  accept: boolean,
) => {
  const { answerMemberInvitation } = membersRepository(serviceId);
  return answerMemberInvitation(accept);
};

export const fetchServiceUsersAssignableReviewRequestUsecase = async ({
  serviceId,
  reqId,
}: FindServiceUsersAssignableReviewRequestQueryVariables) => {
  const { fetchServiceUsersAssignableReviewRequestRepository } =
    membersRepository(serviceId);
  return fetchServiceUsersAssignableReviewRequestRepository({ reqId });
};
