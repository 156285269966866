import type { ApiList } from '../../../../entity/api';

export type Endpoint = 'blogs' | 'categories' | 'news' | 'banner';

type Param = {
  apiList: ApiList | undefined;
  templateEndpoints: Endpoint[];
};

type CheckApiEndpoint = ({
  apiList,
  templateEndpoints,
}: Param) => string | undefined;

export const checkApiEndpoint: CheckApiEndpoint = ({
  apiList,
  templateEndpoints,
}) => {
  const currentApiList =
    apiList &&
    apiList.list.find((list) => {
      return templateEndpoints?.find(
        (templateEndpoint) => templateEndpoint === list.apiEndpoint,
      );
    });

  return currentApiList?.apiEndpoint;
};
