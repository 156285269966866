import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Empty from '@/components/ui/Empty';

type Props = {
  addCustomField: () => void;
  endpoint: string;
};

export const EmptyWrapper: React.FC<Props> = ({ addCustomField, endpoint }) => {
  const { t } = useTranslation('customFields');
  return (
    <Empty
      title={t('No Custom fields')}
      helpUrl="https://document.microcms.io/manual/custom-field"
      helpText={t('What is a Custom field?')}
      description={Trans({
        t,
        i18nKey:
          'Custom fields that have already been created can be configured from the API schema in the API Settings.',
        children: (
          <Link to={`/apis/${endpoint}/settings/model`}>API schema</Link>
        ),
      })}
      onClick={addCustomField}
      checkPermission="defaultContentPermission"
      checkOperation="apiUpdate"
    />
  );
};
