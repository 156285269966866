const mediaListLayouts = [
  'HORIZONTAL_SCROLL',
  'GRID_2',
  'GRID_3',
  'GRID_4',
] as const;
export type MediaListLayout = (typeof mediaListLayouts)[number];
export const isMediaListLayout = (
  layout: string,
): layout is MediaListLayout => {
  return mediaListLayouts.includes(layout as MediaListLayout);
};
