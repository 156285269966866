import cx from 'classnames';
import { forwardRef } from 'react';

import styles from './textarea.module.css';

export type Props = React.ComponentPropsWithoutRef<'textarea'>;

export const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  ({ className, ...props }, forwardedRef) => {
    return (
      <textarea
        ref={forwardedRef}
        className={cx(styles.textarea, className)}
        {...props}
      />
    );
  },
);
Textarea.displayName = 'Textarea';
