import type { MigrateApi } from '@/entity/api';
import type { ReviewRequest } from '@/entity/review';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Member from '../Member';

import styles from './information.module.css';

type Props = {
  api?: MigrateApi | undefined;
  contentId?: string | undefined;
  reviewRequest: ReviewRequest;
};

const Information: React.FC<Props> = ({ api, contentId, reviewRequest }) => {
  const { t } = useTranslation('editReview');

  return (
    <div className={styles.main}>
      <div className={styles.meta}>
        {api && contentId && (
          <p className={styles.contentLink}>
            <i className="material-icons-outlined">assignment</i>
            <Link to={`/apis/${api.apiEndpoint}/${contentId}`}>
              {api.apiName} &gt; {contentId}
            </Link>
          </p>
        )}
        <p className={styles.commentCount}>
          <i className="material-icons-outlined">chat_bubble_outline</i>
          <span className={styles.commentCountText}>
            <span>{t('Comment')}</span>
            <span>{reviewRequest.commentCount}</span>
            <span>{t('comments', { count: reviewRequest.commentCount })}</span>
          </span>
        </p>
      </div>
      <div className={styles.author}>
        <Member username={reviewRequest.authorId || ''} theme="black" />
        <span className={styles.createdAt}>
          {dayjs(reviewRequest.createdAt).fromNow()}
        </span>
      </div>
    </div>
  );
};

export { Information };
