import { Trans, useTranslation } from 'react-i18next';

import { CHARGE_AUTOMATICALLY } from '../../constants/collectionMethodTypes';
import { useInput } from '../../hooks';
import { useStripeActions } from '../../hooks/useStripeActions';
import PlanCard from '../PlanCard';
import Button from '../ui/Button';
import ExternalLink from '../ui/ExternalLink';
import Modal from '../ui/Modal';
import Item from './Item';

import type { Service } from '@/types/services';

import styles from './planlistmodal.module.css';

import { PlanName } from '@/constants/plan';

// TODO：useInputがTS化されたらPlanで型をつける
// type Plan = 'Hobby' | 'Team' | 'Business' | 'Advanced' | 'Enterprise';

type Props = {
  service: Service;
  closePlanListModal: () => void;
  openChangePaymentInfoModal: (options: { withSkipButton: boolean }) => void;
  setCollectionMethod?: any;
};

const PlanListModal: React.FC<Props> = ({
  service,
  closePlanListModal,
  openChangePaymentInfoModal,
  setCollectionMethod,
}) => {
  const { t } = useTranslation('planListModal');
  const {
    changePlanToBusiness,
    changePlanToBusinessLoading,
    changePlanToTeam,
    changePlanToTeamLoading,
    changePlanToAdvanced,
    changePlanToAdvancedLoading,
    changePlanToHobby,
    changePlanToHobbyLoading,
    hasExperiencedPaidPlansQuery,
    invoiceDataQuery,
    subscriptionDataQuery,
    planListData,
    currentPlan,
    isTrial,
  } = useStripeActions(service);

  const { data: invoiceData } = invoiceDataQuery;
  const { data: hasExperiencedPaidPlans } = hasExperiencedPaidPlansQuery;
  const { data: subscriptionData } = subscriptionDataQuery;

  const [selectedPlan, onChangePlan] = useInput(
    currentPlan.name || PlanName.Hobby,
  );
  const isStartTrialPlan = !hasExperiencedPaidPlans && !invoiceData?.card_last4;
  const isRegisteredPaymentMethod = Boolean(
    subscriptionData || invoiceData?.card_last4,
  );

  const changePlan = () => {
    if (selectedPlan === PlanName.Team) {
      // @ts-expect-error
      changePlanToTeam({
        closeModal: closePlanListModal,
        openPaymentInfoModal: isStartTrialPlan
          ? () => openChangePaymentInfoModal({ withSkipButton: true })
          : undefined,
      });
      setCollectionMethod && setCollectionMethod(CHARGE_AUTOMATICALLY);
    } else if (selectedPlan === PlanName.Business) {
      // @ts-expect-error
      changePlanToBusiness({
        closeModal: closePlanListModal,
        openPaymentInfoModal: isStartTrialPlan
          ? () => openChangePaymentInfoModal({ withSkipButton: true })
          : undefined,
      });
    } else if (selectedPlan === PlanName.Advanced) {
      // @ts-expect-error
      changePlanToAdvanced({
        closeModal: closePlanListModal,
        openPaymentInfoModal: isStartTrialPlan
          ? () => openChangePaymentInfoModal({ withSkipButton: true })
          : undefined,
      });
    } else if (selectedPlan === PlanName.Hobby) {
      if (isTrial) {
        // NOTE: 無料トライアル期間中にHobbyプランに変更すると無料トライアルが終了するため、確認ダイアログでその旨を伝え、OKを押したらHobbyプランに変更する
        if (
          window.confirm(
            t(
              'If you change to Hobby plan, your free trial ends. Are you sure you want to change the plan?',
            ),
          )
        ) {
          // @ts-expect-error
          changePlanToHobby({ closeModal: closePlanListModal });
        }
      } else {
        // @ts-expect-error
        changePlanToHobby({ closeModal: closePlanListModal });
      }
    }
  };

  return (
    <Modal
      title={t('Select a Plan')}
      type="medium"
      footer={
        <Button
          value={
            isStartTrialPlan
              ? t('Start your 14-day free trial')
              : t('Save changes')
          }
          type="secondary"
          size="large"
          onClick={changePlan}
          disabled={
            changePlanToBusinessLoading ||
            changePlanToTeamLoading ||
            changePlanToAdvancedLoading ||
            changePlanToHobbyLoading ||
            selectedPlan === 'Enterprise' ||
            selectedPlan === currentPlan.name
          }
          className="ga-change-plan"
          data-testid="submit-changing-plan-button"
        />
      }
    >
      <p className={styles.modalDescription}>
        {Trans({
          t,
          i18nKey:
            'For more information, refer to the pricing page on our service website.',
          children: (
            <ExternalLink href="https://microcms.io/pricing">
              the pricing page
            </ExternalLink>
          ),
        })}
        <br />
        {t(
          'You will not be able to claim free trial again if you change from free trial to Hobby plan or cancel the service.',
        )}
      </p>
      <div className={styles.planSelect}>
        <div className={styles.planDescription}>
          {selectedPlan === PlanName.Hobby && (
            <ul>
              <Item>{t('Unlimited API calls')}</Item>
              <Item>{t('Up to 3 members')}</Item>
              <Item>{t('Up to 3 APIs')}</Item>
            </ul>
          )}
          {selectedPlan === PlanName.Team && (
            <>
              <p className={styles.itemText}>
                {t('In addition to all the features of the Hobby plan')}
              </p>
              <ul>
                <Item>{t('3 members (additional members can be added)')}</Item>
                <Item>{t('10 APIs (additional APIs possible)')}</Item>
                <Item>{t('Larger volume for data-transfer')}</Item>
              </ul>
            </>
          )}
          {selectedPlan === PlanName.Business && (
            <>
              <p className={styles.itemText}>
                {t('All features of the Team plan, plus')}
              </p>
              <ul>
                <Item>{t('20 members (can be added)')}</Item>
                <Item>{t('30 APIs (can be added)')}</Item>
                <Item>{t('Extra large data-transfer volume')}</Item>
                <Item>{t('Authorization Management')}</Item>
                <Item>{t('IP restrictions')}</Item>
                <Item>{t('Media tag management')}</Item>
              </ul>
            </>
          )}
          {selectedPlan === PlanName.Advanced && (
            <>
              <p className={styles.itemText}>
                {t('In addition to all features of the Business plan')}
              </p>
              <ul>
                <Item>{t('50 members (can be added)')}</Item>
                <Item>{t('50 APIs (can be added)')}</Item>
                <Item>{t('Audit Log')}</Item>
                <Item>{t('Mandatory two-factor authentication')}</Item>
                <Item>{t('Custom domains')}</Item>
                <Item>{t('Payment by invoice')}</Item>
                <Item>{t('Single Sign On')}</Item>
              </ul>
            </>
          )}
          {selectedPlan === PlanName.Enterprise && (
            <>
              <p className={styles.itemText}>
                {t('In addition to all features of the Advanced plan')}
              </p>
              <ul>
                <Item>
                  {t(
                    'Custom settings for number of members, number of APIs, number of API keys, number of contents, amount of data transfer, etc.',
                  )}
                </Item>
                <Item>{t('SLA settings')}</Item>
                <Item>
                  {t('Premium support with dedicated representatives')}
                </Item>
              </ul>
              <div className={styles.descriptionAction}>
                <a href="https://microcms.io/contact" target="contact">
                  <Button value={t('Contact us')} type="tertiary" />
                </a>
              </div>
            </>
          )}
        </div>
        <ul>
          {planListData &&
            planListData.planTypes &&
            planListData.planTypes[0] &&
            planListData.planTypes[0].plans.map((plan) => (
              <li key={plan?.name} className={styles.planList}>
                <PlanCard
                  name={plan?.name ?? ''}
                  description={plan?.description ?? ''}
                  minimumMonthPrice={plan?.minimumMonthPrice ?? null}
                  minimumMonthTaxIncludedPrice={
                    plan?.minimumMonthTaxIncludedPrice ?? null
                  }
                  defaultPlan={selectedPlan}
                  trialLength={plan?.trialLength ?? undefined}
                  onChangePlan={onChangePlan}
                  currentPlan={currentPlan.name}
                />
              </li>
            ))}
          {planListData &&
            planListData.planTypes &&
            planListData.planTypes[1] &&
            planListData.planTypes[1].plans.map(
              (plan) =>
                plan?.name !== PlanName.Template && (
                  <li key={plan?.name} className={styles.planList}>
                    <PlanCard
                      name={plan?.name ?? ''}
                      description={plan?.description ?? ''}
                      minimumMonthPrice={plan?.minimumMonthPrice ?? null}
                      minimumMonthTaxIncludedPrice={
                        plan?.minimumMonthTaxIncludedPrice ?? null
                      }
                      defaultPlan={selectedPlan}
                      trialLength={
                        !hasExperiencedPaidPlans && plan?.trialLength
                          ? plan.trialLength
                          : undefined
                      }
                      onChangePlan={onChangePlan}
                      currentPlan={currentPlan.name}
                    />
                  </li>
                ),
            )}
        </ul>
      </div>
      <div className={styles.notes}>
        <p className={styles.notesText}>{t('All prices are tax excluded.')}</p>
      </div>
    </Modal>
  );
};

export default PlanListModal;
