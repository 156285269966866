import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import type { MigrateApi } from '@/entity/api';

export const useContentActions = (api: MigrateApi) => {
  const history = useHistory();
  const historyPushStateContentId = useCallback(
    ({ contentId }: { contentId: string }) => {
      history.push(`/apis/${api.apiEndpoint}/create`, { contentId });
    },
    [api, history],
  );

  return { historyPushStateContentId };
};
