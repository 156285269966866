import InputField from '@/components/InputField';

import type { MigrateApi } from '@/entity/api';
import type { ContentValue } from '@/entity/content';
import type { CustomField } from '@/types/contents';
import type { Field } from '@/types/field';
import type { PrimitiveAtom } from 'jotai';

import styles from './contentdetail.module.css';

export type Props = {
  api: MigrateApi;
} & Omit<ViewProps, 'fields'>;

export type ViewProps = {
  api: MigrateApi;
  fields: Field[];
  endpoint: string;
  customFields: CustomField[];
  contentAtom: PrimitiveAtom<ContentValue>;
  getContent: () => ContentValue;
};

export const ContentDetailView: React.FC<ViewProps> = ({
  api,
  fields,
  endpoint,
  customFields,
  contentAtom,
  getContent,
}) => {
  return (
    <div className={styles.main}>
      {fields.map((field) => {
        return (
          <InputField
            key={field.idValue}
            api={api}
            field={field}
            endpoint={endpoint}
            contentAtom={contentAtom}
            customFields={customFields}
            getContent={getContent}
          />
        );
      })}
    </div>
  );
};

export const ContentDetail: React.FC<Props> = ({ api, ...props }) => {
  return <ContentDetailView api={api} fields={api.apiFields} {...props} />;
};
