import { getTextCount } from '@/components/Validations';

import type { Props, ViewProps } from './TextArea';

import { validateStringField } from '@/views/apis/content/useValidateFields';

export const useTextArea = ({ field, value, onChange }: Props): ViewProps => {
  const error = validateStringField(field, value) ?? undefined;

  return {
    label: field.name,
    description: field.description,
    value,
    onChange,
    required: field.required,
    error,
    textSize: field.textSizeLimitValidation?.textSize,
    textCount: getTextCount(value),
  };
};
