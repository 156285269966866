import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from './_types';
import type { Role } from '@/entity/role';

import { produce } from '@/lib/immer';

type SetBillingPermissionReadAction = FormRoleInputValueAction<
  'set-billing-permission-read',
  Role['billingPermission']['read']
>;
type SetBillingPermissionUpdateAction = FormRoleInputValueAction<
  'set-billing-permission-update',
  Role['billingPermission']['update']
>;
type BillingPermissionAction =
  | SetBillingPermissionReadAction
  | SetBillingPermissionUpdateAction;

/**
 * BillingPermissionのreadフィールドを更新する
 */
const setBillingPermissionRead: FormRoleInputValueDispatchFn<
  SetBillingPermissionReadAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.billingPermission.read = payload;
  });
};

/**
 * BillingPermissionのupdateフィールドを更新する
 */
const setBillingPermissionUpdate: FormRoleInputValueDispatchFn<
  SetBillingPermissionUpdateAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.billingPermission.update = payload;
  });
};

export {
  type BillingPermissionAction,
  setBillingPermissionRead,
  setBillingPermissionUpdate,
};
