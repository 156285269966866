import { useLinkBlock } from './useLinkBlock';
import { EditLinkBody } from '../../EditLinkBody';

import type { Editor } from '@tiptap/react';

import style from './linkblock.module.css';

export type LinkAttributes = {
  text: string;
  href: string;
  target?: string | null;
};

export type Props = {
  editor: Editor;
  onToggleTooltip: () => void;
};

export type ViewProps = {
  inputLinkAttributes: LinkAttributes;
  onChangeText: (href: LinkAttributes['text']) => void;
  onChangeHref: (href: LinkAttributes['href']) => void;
  onChangeTargetBlank: (isTargetBlank: boolean) => void;
  onUpdateLink: () => void;
  onClearLink: () => void;
  textError: string | undefined;
  hrefError: string | undefined;
};

export const LinkBlockView: React.FC<ViewProps> = ({
  inputLinkAttributes,
  onChangeText,
  onChangeHref,
  onChangeTargetBlank,
  onUpdateLink,
  onClearLink,
  textError,
  hrefError,
}) => {
  return (
    <div className={style.container}>
      <EditLinkBody
        inputLinkAttributes={inputLinkAttributes}
        onChangeText={onChangeText}
        onChangeHref={onChangeHref}
        onChangeTargetBlank={onChangeTargetBlank}
        onUpdateLink={onUpdateLink}
        onClearLink={onClearLink}
        hrefError={hrefError}
        textError={textError}
      />
    </div>
  );
};

export const LinkBlock: React.FC<Props> = ({ editor, onToggleTooltip }) => {
  const {
    inputLinkAttributes,
    onChangeText,
    onChangeHref,
    onChangeTargetBlank,
    onUpdateLink,
    onClearLink,
    textError,
    hrefError,
  } = useLinkBlock({ editor, onToggleTooltip });

  return (
    <LinkBlockView
      inputLinkAttributes={inputLinkAttributes}
      onChangeText={onChangeText}
      onChangeHref={onChangeHref}
      onChangeTargetBlank={onChangeTargetBlank}
      onUpdateLink={onUpdateLink}
      onClearLink={onClearLink}
      textError={textError}
      hrefError={hrefError}
    />
  );
};
