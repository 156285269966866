import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import type { TopicValue } from '@/entity/momento';
import type { TopicItem, TopicSubscribe } from '@gomomento/sdk-web';

import { MetaContext } from '@/context/MetaContext';
import { clearCurrentClient, subscribeToTopic } from '@/lib/momentoClient';

const getCacheName = () => {
  const metaContextValue = MetaContext.value;

  if (metaContextValue.isProduction || metaContextValue.isEnterprise) {
    return 'microcms-production';
  } else if (metaContextValue.environment === 'stg') {
    return 'microcms-staging';
  } else {
    return 'microcms-development';
  }
};

type TopicItemStore = TopicValue[];
type TopicEvent = (topicValue: TopicValue, done: () => void) => void;

const topicItemStoreContext = createContext<TopicItemStore>([]);
const topicEventsRefContext = createContext<
  React.MutableRefObject<TopicEvent[]>
>({
  current: [],
});

type Props = {
  children: React.ReactNode;
  serviceId?: string | null;
};

const MomentoProvider: React.FC<Props> = ({ children, serviceId }) => {
  const [topicItemStore, setTopicItemStore] = useState<TopicItemStore>([]);
  const topicEventsRef = useRef<TopicEvent[]>([]);

  const cacheName = getCacheName();

  // momentoのsubscribe
  // 不必要にリセットされるのを防ぐため、topicEventsはrefで管理している（topicEventsをstateで管理した場合、useEffectの依存配列に加えなければ動作はしないがtopicEventsに変更があるたびにsubscribeのリセットが行われてしまう）
  useEffect(() => {
    if (!serviceId) {
      return;
    }

    const onItem = (item: TopicItem) => {
      const topicValue: TopicValue = JSON.parse(item.valueString());
      setTopicItemStore((prev) => [...prev, topicValue]);

      topicEventsRef.current.forEach((event) => {
        const done = () => {
          // イベントが実行されたら削除
          const index = topicEventsRef.current.indexOf(event);
          if (index !== -1) {
            topicEventsRef.current.splice(index, 1);
          }
        };
        event(topicValue, done);
      });
    };
    const onError = async (
      error: TopicSubscribe.Error,
      sub: TopicSubscribe.Subscription,
    ) => {
      console.error(
        'received error from momento, getting new token and resubscribing',
        error,
      );
      sub.unsubscribe();
      clearCurrentClient();
      await subscribeToTopic(cacheName, serviceId, onItem, onError);
    };
    subscribeToTopic(cacheName, serviceId, onItem, onError).catch((e) =>
      console.error('error subscribing to topic', e),
    );

    return () => {
      clearCurrentClient();
    };
  }, [cacheName, serviceId]);

  return (
    <topicItemStoreContext.Provider value={topicItemStore}>
      <topicEventsRefContext.Provider value={topicEventsRef}>
        {children}
      </topicEventsRefContext.Provider>
    </topicItemStoreContext.Provider>
  );
};

/**
 * イベント名に紐づくTopicValue[]を取得する
 * 古いものから新しいものの順に並ぶ
 */
const useTopicValues = (eventName: string): TopicValue[] => {
  const topicItemStore = useContext(topicItemStoreContext);

  const topicValues = useMemo(
    () =>
      topicItemStore
        // イベント名でフィルタリング
        .filter((topicItem) => topicItem.eventName === eventName)
        // 作成日時で昇順にソート
        .sort((a, b) =>
          a.createdAt > b.createdAt ||
          // 作成日時が同じ場合はSUCCEEDEDを優先
          (a.createdAt === b.createdAt &&
            a.status === 'SUCCEEDED' &&
            b.status !== 'SUCCEEDED')
            ? 1
            : -1,
        ),
    [eventName, topicItemStore],
  );

  return topicValues;
};

const useRegisterTopicEvent = () => {
  const topicEventsRef = useContext(topicEventsRefContext);

  const register = useCallback(
    (topicEvent: TopicEvent) => {
      topicEventsRef.current.push(topicEvent);
    },
    [topicEventsRef],
  );

  const remove = useCallback(
    (topicEvent: TopicEvent) => {
      const index = topicEventsRef.current.indexOf(topicEvent);
      if (index !== -1) {
        topicEventsRef.current.splice(index, 1);
      }
    },
    [topicEventsRef],
  );

  return {
    register,
    remove,
  };
};

export { MomentoProvider, useTopicValues, useRegisterTopicEvent };
