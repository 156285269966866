import { useFlags } from 'launchdarkly-react-client-sdk';
import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/ui/Button';

import styles from './maintenance.module.css';

import { REACT_APP_VERSION } from '@/constants/env';
import { Modal, ModalContent } from '@/views/Common/Ui/Modal';

type MaintenanceMode = {
  description: string | null;
  maintenance: boolean;
};

type ForceRefresh = {
  require_update: boolean;
  required_version: number;
};

type Props = {
  children: React.ReactNode;
};

type ViewProps = {
  showMaintenance: boolean;
  maintenanceDescription: string;
  showRefreshModal: boolean;
  children: React.ReactNode;
};

const MaintenanceProvider: React.FC<Props> = ({ children }) => {
  const flags = useFlags();

  const maintenanceMode: MaintenanceMode = flags?.maintenanceMode;
  const forceRefresh: ForceRefresh = flags?.forceRefresh;

  // 未ログインの場合はフラグを取得できないが、実態としてログインや新規登録画面の表示が可能になるだけのため許容する
  // なお、別案として web/src/index.tsx で MetaContext.user.username に未ログインである値（例："UNAUTHRIZED_USER"）を差し込むことも検討したが全体影響が大きいため断念した
  if (maintenanceMode === undefined || forceRefresh === undefined) {
    return <>{children}</>;
  }

  if (
    maintenanceMode.maintenance === false &&
    forceRefresh.require_update === false
  ) {
    return <>{children}</>;
  }

  const currentVersion = Number(REACT_APP_VERSION) || Number.MAX_SAFE_INTEGER; // 万が一バージョンが取得できなかった時のために十分大きい値を用意
  const showRefreshModal =
    (forceRefresh.required_version || 0) > currentVersion;

  return (
    <Maintenance
      showMaintenance={maintenanceMode.maintenance}
      maintenanceDescription={
        maintenanceMode.description || 'microCMSはただいまメンテナンス中です。'
      }
      showRefreshModal={showRefreshModal}
    >
      {children}
    </Maintenance>
  );
};

const Maintenance: React.FC<ViewProps> = ({
  showMaintenance,
  maintenanceDescription,
  showRefreshModal,
  children,
}) => {
  const { t } = useTranslation('maintenance');
  const [openModal, setOpenModal] = useState(showRefreshModal);

  if (showMaintenance) {
    return (
      <div className={styles.wrapper}>
        <a href="https://microcms.io" className={styles.logo}>
          <img
            className={styles.logoImg}
            src="/images/logo_black.svg"
            alt="microCMS"
          />
        </a>
        <div>
          <img src="/images/icon_maintenance.svg" alt="" />
          <h1 className={styles.title}>{t('Maintenance')}</h1>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: maintenanceDescription.replace(/\r\n|\r|\n/g, '<br>'),
            }}
          ></div>
        </div>
      </div>
    );
  }
  return (
    <>
      {children}
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <ModalContent hasSpace={false}>
          <div className={styles.modal}>
            <i className={`material-icons ${styles.icon}`}>error_outline</i>
            <p className={styles.modalText}>
              {t('A new version is available now.')}
              <br />
              {t('Reload the page.')}
            </p>
            <Button
              type="secondary"
              size="small"
              className={styles.reloadButton}
              value={t('Reload')}
              onClick={() => window.location.reload()}
            />
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MaintenanceProvider;
