import type React from 'react';
import { useEffect, useState } from 'react';

import { getResourceUrl } from '../../util';

import styles from './s3Image.module.css';

interface Props {
  directory: string;
  fileName: any;
  kind: string;
  queryString?: string;
  className?: string;
  style?: React.CSSProperties;
  customDomainImageHost?: string;
}

const S3Image: React.FC<Props> = ({
  directory,
  fileName,
  kind,
  queryString = '',
  className = '',
  style = {},
  customDomainImageHost,
}) => {
  const [src, setSrc] = useState();
  useEffect(() => {
    if (
      directory === undefined ||
      fileName === undefined ||
      kind === undefined
    ) {
      return;
    }

    // useEffectに直接async指定は出来なかったため、ここで関数定義
    const get = async () => {
      const _src = await getResourceUrl(
        directory,
        fileName,
        queryString,
        kind,
        customDomainImageHost,
      );
      setSrc(_src);
    };
    get();
  }, [directory, fileName, queryString, kind, customDomainImageHost]);
  return (
    <div className={styles.wrapper} style={style}>
      <img className={className} src={src} alt="" />
    </div>
  );
};

export default S3Image;
