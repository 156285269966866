import cx from 'classnames';
import { useMemo, type FC } from 'react';

import Button from '@/components/ui/Button';

import { isAllCheckedForExceptionContentPermission } from './helpers/isAllChecked';
import { useI18nFormRole } from './i18n';
import { useFormRoleDispatch, useFormRoleState } from './provider';

import type { PropsWithECP } from './fields/FieldExceptionContent/_types';
import type { ButtonHTMLAttributes } from 'react';

import styles from './AllCheckButton.module.css';

const BaseAllCheckButton: FC<
  Omit<
    ButtonHTMLAttributes<HTMLButtonElement>,
    'children' | 'type' | 'value' | 'className'
  > & {
    isAllChecked: boolean;
  }
> = ({ isAllChecked, ...buttonElementProps }) => {
  const { t } = useI18nFormRole();

  return (
    <Button
      type="tertiary"
      value={isAllChecked ? t('Revoke all') : t('Grant all')}
      className={cx(styles.allCheckButton, 'track-click-by-gtm')}
      {...buttonElementProps}
    />
  );
};

const DefaultPermissionAllCheckButton = () => {
  const { isAllCheckedDefaultPermission } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  return (
    <BaseAllCheckButton
      isAllChecked={isAllCheckedDefaultPermission}
      onClick={() => {
        // 全ての権限が付与されている場合は、最低権限に変更
        if (isAllCheckedDefaultPermission) {
          dispatch({
            type: 'set-lowest-level-permission',
          });
        }
        // そうでない場合は、最高権限に変更
        else {
          dispatch({
            type: 'set-highest-level-permission',
          });
        }
      }}
      data-gtm-track-event-name={
        isAllCheckedDefaultPermission
          ? 'click_role_all_off_button'
          : 'click_role_all_on_button'
      }
      data-testid="allCheckButton-defaultPermission"
    />
  );
};

const ExceptionContentPermissionAllCheckButton: FC<PropsWithECP> = ({
  targetECP,
}) => {
  const dispatch = useFormRoleDispatch();

  const isAllChecked = useMemo(
    () => isAllCheckedForExceptionContentPermission(targetECP),
    [targetECP],
  );

  return (
    <BaseAllCheckButton
      isAllChecked={isAllChecked}
      onClick={() => {
        // 全ての権限が付与されている場合は、最低権限に変更
        if (isAllChecked) {
          dispatch({
            type: 'set-ec-permission-alloff',
            payload: targetECP.id,
          });
        }
        // そうでない場合は、最高権限に変更
        else {
          dispatch({
            type: 'set-ec-permission-allon',
            payload: targetECP.id,
          });
        }
      }}
      data-testid="allCheckButton-defaultPermission"
    />
  );
};

export {
  DefaultPermissionAllCheckButton,
  ExceptionContentPermissionAllCheckButton,
};
