import * as Dialog from '@radix-ui/react-dialog';
import cx from 'classnames';
import { createElement } from 'react';

import Button from '@/components/ui/Button';
import type { ButtonProps } from '@/components/ui/Button';

import type { ComponentProps } from 'react';

import styles from './modal.module.css';

export type ContentProps = {
  /**
   * The size of the modal. Defaults to small.
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Whether to add space around the content. Defaults to true.
   */
  hasSpace?: boolean;
  title?: string;
  /**
   * The title element to use. Defaults to h3.
   */
  titleAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  description?: React.ReactNode;
  children: React.ReactNode;
  footer?: {
    errorMessage?: React.ReactNode;
    /**
     * The submit button. The size is automatically set according to the size of the modal.
     */
    submitButtonProps: Omit<ButtonProps, 'size'>;
  } & (
    | {
        cancelButtonProps?: Omit<ComponentProps<'button'>, 'type' | 'children'>;
        dangerButtonProps?: undefined;
      }
    | {
        cancelButtonProps?: undefined;
        dangerButtonProps: Omit<ButtonProps, 'type' | 'size'>;
      }
  );
} & Dialog.DialogContentProps;

export const ModalContent: React.FC<ContentProps> = ({
  size = 'small',
  hasSpace = true,
  title,
  titleAs = 'h3',
  description,
  children,
  footer,
  ...props
}) => {
  return (
    <Dialog.Portal>
      <Dialog.Overlay className={styles.mask}>
        <Dialog.Content
          {...props}
          className={cx(styles.container, styles[size], props.className)}
        >
          <div className={cx(styles.body, hasSpace && styles.hasSpace)}>
            {title && (
              <Dialog.Title asChild>
                {createElement(titleAs, { className: styles.title }, title)}
              </Dialog.Title>
            )}
            {description && (
              <Dialog.Description className={styles.description}>
                {description}
              </Dialog.Description>
            )}
            {children}
          </div>
          {footer && (
            <div className={styles.footer}>
              {footer.errorMessage && (
                <div role="alert" className={styles.error}>
                  {footer.errorMessage}
                </div>
              )}
              <div className={styles.buttons}>
                <Button
                  {...footer.submitButtonProps}
                  size={size === 'small' ? 'small' : 'large'}
                />
                {footer.dangerButtonProps && (
                  <Button
                    {...footer.dangerButtonProps}
                    type="danger"
                    size={size === 'small' ? 'small' : 'large'}
                  />
                )}
                {footer.cancelButtonProps && (
                  <button
                    {...footer.cancelButtonProps}
                    type="button"
                    value={undefined}
                    className={cx(
                      styles.cancelButton,
                      footer.cancelButtonProps.className,
                    )}
                  >
                    {/* <Button />コンポーネントと同様にvalueでchildrenを渡せるようにする。 */}
                    {footer.cancelButtonProps.value}
                  </button>
                )}
              </div>
            </div>
          )}
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  );
};

type Props = Dialog.DialogProps & {
  onOpen?: () => void;
  onClose?: () => void;
};
export const Modal: React.FC<Props> = ({
  onOpen,
  onClose,
  onOpenChange,
  ...props
}) => {
  return (
    <Dialog.Root
      onOpenChange={(open) => {
        if (open) {
          onOpen?.();
        } else {
          onClose?.();
        }
        onOpenChange?.(open);
      }}
      {...props}
    />
  );
};

export const ModalTrigger = Dialog.Trigger;
