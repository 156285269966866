import Linkify from '@/components/Linkify';

import { useNumberField } from './useNumberField';

import type { Field } from '@/types/field';

import { Textfield } from '@/views/Common/Ui/Textfield';

export type Props = {
  field: Field;
  value: number | string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export type ViewProps = {
  label: string;
  description?: React.ReactNode;
  value: number | string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
  error?: string;
};

export const NumberFieldView: React.FC<ViewProps> = ({
  label,
  description,
  value,
  onChange,
  required,
  error,
}) => {
  return (
    <Textfield
      type="text"
      inputMode="numeric"
      label={label}
      description={
        description !== undefined ? <Linkify>{description}</Linkify> : undefined
      }
      value={value}
      onChange={onChange}
      required={required}
      error={error}
    />
  );
};

export const NumberField: React.FC<Props> = (props) => {
  const hooks = useNumberField(props);

  return <NumberFieldView {...hooks} {...props} />;
};
