/* tslint:disable */
/* eslint-disable */
/**
 * マネジメントAPI
 * マネジメントAPI
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * カスタムステータスモデル
 * @export
 * @interface CustomStatus
 */
export interface CustomStatus {
  /**
   *
   * @type {string}
   * @memberof CustomStatus
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof CustomStatus
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof CustomStatus
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CustomStatus
   */
  behaviour: CustomStatusBehaviourEnum;
  /**
   *
   * @type {string}
   * @memberof CustomStatus
   */
  color: string;
  /**
   *
   * @type {Date}
   * @memberof CustomStatus
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof CustomStatus
   */
  updatedAt?: Date | null;
}

/**
 * @export
 * @enum {string}
 */
export enum CustomStatusBehaviourEnum {
  Publish = 'PUBLISH',
  Draft = 'DRAFT',
  Closed = 'CLOSED',
}

export function CustomStatusFromJSON(json: any): CustomStatus {
  return CustomStatusFromJSONTyped(json, false);
}

export function CustomStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CustomStatus {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    key: json['key'],
    description: json['description'],
    name: json['name'],
    behaviour: json['behaviour'],
    color: json['color'],
    createdAt: new Date(json['createdAt']),
    updatedAt: !exists(json, 'updatedAt')
      ? undefined
      : json['updatedAt'] === null
        ? null
        : new Date(json['updatedAt']),
  };
}

export function CustomStatusToJSON(value?: CustomStatus | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    key: value.key,
    description: value.description,
    name: value.name,
    behaviour: value.behaviour,
    color: value.color,
    createdAt: value.createdAt.toISOString(),
    updatedAt:
      value.updatedAt === undefined
        ? undefined
        : value.updatedAt === null
          ? null
          : value.updatedAt.toISOString(),
  };
}
