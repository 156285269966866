import type { MigrateApi, RestrictedApi } from '@/entity/api';
import { useGetMyService } from '@/hooks/useService';
import { useApi } from '@/lib/useApi';
import { findApis } from '@/usecase/apiUsecase';
import { apiQueryKeys } from '@/views/Common/api/queryKeys';

export const useLoadApis = () => {
  const { service } = useGetMyService();

  return useApi<
    readonly ['apiList'],
    { list: (MigrateApi | RestrictedApi)[] } | undefined,
    Error
  >(
    apiQueryKeys.list(),
    async () => {
      if (!service) {
        return;
      }
      return findApis({ service });
    },
    { enabled: !!service, staleTime: Number.POSITIVE_INFINITY },
  );
};
