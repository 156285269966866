import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useCodeBlock } from './useCodeBlock';
import { IconButton } from '../../../IconButton';
import styles from '../../../richeditorv2.module.css';

import type { Editor } from '@tiptap/react';

import { CodeBlockIcon } from '@/views/Common/Icons';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  toggleCodeBlockHandler: () => void;
  isActiveCodeBlock: boolean;
  editable: boolean;
};

export const CodeBlockView: React.FC<ViewProps> = ({
  toggleCodeBlockHandler,
  isActiveCodeBlock,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <IconButton
      icon={
        <CodeBlockIcon
          className={cx(styles.icon, {
            [styles.isActive]: isActiveCodeBlock,
          })}
        />
      }
      aria-label={t('CodeBlock')}
      isActive={isActiveCodeBlock}
      onClick={toggleCodeBlockHandler}
      disabled={!editable}
    />
  );
};

export const CodeBlock: React.FC<Props> = ({ editor }) => {
  const { toggleCodeBlockHandler, isActiveCodeBlock } = useCodeBlock({
    editor,
  });

  return (
    <CodeBlockView
      toggleCodeBlockHandler={toggleCodeBlockHandler}
      isActiveCodeBlock={isActiveCodeBlock}
      editable={editor.isEditable}
    />
  );
};
