import type { Field } from '@/types/contents'

type ApiType = "PAGE" | "LIST"

type ProgressStatus = "RUNNING" | "ERROR" | "COMPLETE"

type ApiImportProgress = {
  status: ProgressStatus
  date: string
  message: string
}

type ContentIdCharacter =   "lowercase" |
  "uppercase" |
  "number" |
  "symbol"

export type AllowList = 'lowercase' | 'uppercase' | 'symbol' | 'number';

export type ContentIdSetting = {
  allowList: ContentIdCharacter[]
  length: number | null
}

type InputSource = "APPSYNC" | "FILE" | "SCRIPT" | "API"

/**
 * @see https://github.com/microcmsio/microcms/blob/80ac7ca98ebe65e6bb333a7786a58d1448622931/web/amplify/backend/api/microcmsql/schema.graphql#L314
 *  type contentsからAPIの値のみを抽出した型
 *  旧DBで取得できていた値が含まれていないので、usecaseレイヤーで加工するなどしてから使用するように注意
 */
export type Api = {
  /**
   * @example API#1035f5d8-87ec-40dc-9faf-6e9cdf7b5e66
   */
  partitionKey: string
  sortKey: string
  /**
   * @example SERVICE#c7e47f9a-3f31-4349-a592-849cacced5a4#API
   */
  gsiPartitionKey1: string
  /**
   * @example SORT#2024-05-14T01:21:54.598Z
   */
  gsiSortKey1: string
    /**
   * @example SERVICE#c7e47f9a-3f31-4349-a592-849cacced5a4
   */
  gsiPartitionKey2: string
  /**
   * APIのエンドポイント名
   *
   * @example ENDPOINT#blogs
  */
  gsiSortKey2: string
  gsiSinglePartitionKey1: string | null
  gsiPartitionKey3: string | null
  gsiSortKey3: string | null

  apiViewerGroup: string
  contentViewerGroup: string
  apiName: string
  apiType: ApiType
  apiFields: Field[]
  createdAt: string
  updatedAt: string
  editorId: string
  groups: string[]
  inputSource: InputSource

  apiDescription: string | null
  apiPreviewUrl: string | null
  apiPreviewLinkIsVisible: boolean | null
  apiImportProgress: ApiImportProgress | null
  apiAllowedIpList: string | null
  contentIdSetting: ContentIdSetting | null

  _restricted: ContentsRestricted | null;
}

//  コンテンツ(サービス、API、コンテンツ)一覧で権限を制御する場合このフィールドを追加する
type ContentsRestrictedType = "API_READ_PERMISSION";

type ContentsRestricted =  {
  type: ContentsRestrictedType;
  reason?: string;
}

// 読み込み権限がない場合のAPIの型
export type RestrictedApi = {
    /**
   * @example API#1035f5d8-87ec-40dc-9faf-6e9cdf7b5e66
   */
  partitionKey: string
  sortKey: string
  _restricted: ContentsRestricted
}

export type MigrateApi = Api & {
  apiEndpoint: string
  contentId: string,
  domain: string,
  /**
   * APIを持つサービスのドメイン名 (IDではないので注意)
   * domainとidは同じ値になる
   */
  id: string,
  /**
   * @example SERVICE#c7e47f9a-3f31-4349-a592-849cacced5a4
   */
  serviceId: string//各所で必要になるため予め持っておく
}

/**
  * Reduxのグローバルステートの返り値の型
  * @see https://github.com/microcmsio/microcms/blob/3a168b33b1f75f0ae295632e3b37b234781242f2/web/src/ducks/apiList/selectors.js#L1
  * Reduxのステートに合わせてReactQueryの返り値も同様になっている
  * @see https://github.com/microcmsio/microcms/blob/7c452a0e6e18971f7ca249afdbbc540c423cf01e/web/src/hooks/ApiList/useApiListReader.ts#L54
  * TODO: Reduxが削除されたらこの型も削除し、MigrateApi[]に変更する
*/
export type ApiList = {
  list: MigrateApi[]
}

/**
  * Redux経由で取得されたAPI一覧の型。Reduxが削除されたら消してOK
  * @see https://github.com/microcmsio/microcms/blob/3a168b33b1f75f0ae295632e3b37b234781242f2/web/src/ducks/apiList/selectors.js#L1
*/
export type ApiListByRedux = {
  apiList: MigrateApi[]
}


// ApiとRestrictedAPIを受け取って、RestrictedAPIかどうかを判定する
export const isRestrictedApi = (api: Api | RestrictedApi): api is RestrictedApi => {
  return "_restricted" in api && api._restricted !== null;
};
