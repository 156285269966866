// TODO:roleUsecaseフォルダに移動する
import { rolesRepository } from '@/repository/rolesRepository';

import type { Role } from '../entity/role';

export const getAllRoles = async (serviceId: string): Promise<Role[]> => {
  const { fetchAllRoles } = rolesRepository(serviceId);
  const allRoles = await fetchAllRoles();
  return allRoles;
};

export const getMyRoles = async (serviceId: string): Promise<Role[]> => {
  const { fetchMyRoles } = rolesRepository(serviceId);
  const allMyRoles = await fetchMyRoles();
  return allMyRoles;
};
