import { useCallback } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useInput } from '../../hooks';
import { useExceptionPermissionIsHaveLeastOne } from '../../hooks/Role/useMyRoles';
import Feedback from '../Feedback';
import Linkify from '../Linkify';
import Button from '../ui/Button';
import IconWithTextNavLink from '../ui/IconWithTextNavLink';
import Modal from '../ui/Modal';
import Textarea from '../ui/Textarea';
import { validateApiDescription } from '../Validations';

import styles from './contentHeader.module.css';

import { useApiListInvalidateCache } from '@/views/Common/api/invalidateCache';

type Props = {
  api: any;
  putApiDescription: any;
  putDescriptionResult: any;
};

const ContentHeader: React.FC<Props> = ({
  api,
  putApiDescription,
  putDescriptionResult,
}) => {
  const { t } = useTranslation('contentHeader');
  const apiListInvalidateCache = useApiListInvalidateCache();

  const [hasReadDeveloperPermission] = useExceptionPermissionIsHaveLeastOne(
    api && api.partitionKey,
    'showDeveloperMenu',
  );
  const [DescriptionModal, openDescriptionModal, closeDescriptionModal] =
    useModal('root');

  const [description, onChangeDescription, descriptionError] = useInput(
    api.apiDescription,
    // @ts-expect-error
    validateApiDescription,
  );

  const [updatable] = useExceptionPermissionIsHaveLeastOne(
    api && api.partitionKey,
    'apiUpdate',
  );

  const updateDescription = useCallback(() => {
    putApiDescription({
      apiId: api && api.partitionKey,
      description,
    }).then(() => {
      apiListInvalidateCache();
    });
    closeDescriptionModal();
  }, [api, closeDescriptionModal, description, putApiDescription]);

  if (api === undefined) {
    return null;
  }

  return (
    <>
      <header className={styles.header}>
        <dl className={styles.apiInfo}>
          <dt className={styles.apiName}>
            <Link to={`/apis/${api.apiEndpoint}`}>{api.apiName}</Link>
          </dt>
          <dd className={styles.apiItems}>
            {api.apiDescription ? (
              <div className={styles.apiDescriptionArea}>
                <p
                  className={styles.apiDescription}
                  onClick={openDescriptionModal}
                >
                  <Linkify>{api.apiDescription}</Linkify>
                </p>
              </div>
            ) : (
              <p
                className={styles.apiDescriptionLink}
                onClick={openDescriptionModal}
              >
                {t('Add description')}
              </p>
            )}
          </dd>
        </dl>
        <div className={styles.tabs}>
          {hasReadDeveloperPermission && ( // 開発者のみ見せる
            <IconWithTextNavLink
              to={`/apis/${api.apiEndpoint}/custom-fields`}
              icon="playlist_add"
              text={t('Custom Fields')}
              enableActiveStyle={true}
            />
          )}
          {hasReadDeveloperPermission && ( // 開発者のみ見せる
            <IconWithTextNavLink
              to={`/apis/${api.apiEndpoint}/settings`}
              icon="settings"
              text={t('API Settings')}
              enableActiveStyle={true}
            />
          )}
        </div>
      </header>
      <DescriptionModal>
        <Modal title={t('Add description title')} type="small">
          <div className={styles.form}>
            <Textarea
              placeholder={t('Enter description')}
              defaultValue={api.apiDescription}
              onChange={onChangeDescription}
              errorText={descriptionError}
            />
          </div>
          {!updatable && (
            <p className={styles.errorText}>
              {t('You do not have permission to edit API settings.')}
            </p>
          )}
          <Button
            type="primary"
            size="small"
            className={styles.descriptionModalButton}
            value={t('Save changes')}
            onClick={updateDescription}
            disabled={!updatable}
          />
        </Modal>
      </DescriptionModal>
      <Feedback
        type="failure"
        message={
          putDescriptionResult === false
            ? t('Failed to make changes.')
            : undefined
        }
      />
      <Feedback
        type="success"
        message={
          putDescriptionResult === true ? t('Saved successfully') : undefined
        }
      />
    </>
  );
};

export default ContentHeader;
