import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Toolbar from '@radix-ui/react-toolbar';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useCustomClass } from './useCustomClass';

import type { CustomClass as CustomClassType } from '@/types/field';
import type { Editor } from '@tiptap/react';

import style from './customclass.module.css';

export type Props = {
  editor: Editor;
  customClassList: CustomClassType[];
};

export type ViewProps = {
  setCustomClassHandler: (id: string) => void;
  unsetCustomClassHandler: () => void;
  customClassName?: string | null;
  customClassList: CustomClassType[];
  editable: boolean;
};

export const CustomClassView: React.FC<ViewProps> = ({
  setCustomClassHandler,
  unsetCustomClassHandler,
  customClassName,
  customClassList,
  editable,
}) => {
  const { t } = useTranslation('richEditorV2');

  return (
    <DropdownMenu.Root modal={false}>
      <Toolbar.Button asChild>
        <DropdownMenu.Trigger
          className={cx(style.trigger, {
            [style.isActive]: customClassName !== t('Custom'),
          })}
          aria-label={t('Custom')}
          disabled={!editable}
        >
          {customClassName === t('Custom')
            ? customClassName
            : // ツールバーに最大5文字表示
              customClassName?.slice(0, 5)}
          <span className="material-icons-outlined">keyboard_arrow_down</span>
        </DropdownMenu.Trigger>
      </Toolbar.Button>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={style.content}
          sideOffset={2}
          align="start"
          onEscapeKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          {customClassList?.length === 0 ? (
            <DropdownMenu.Group className={cx(style.group)}>
              <DropdownMenu.Item className={style.item}>
                {t('No Custom class')}
              </DropdownMenu.Item>
            </DropdownMenu.Group>
          ) : (
            <>
              <DropdownMenu.Group className={cx(style.group, style.border)}>
                <DropdownMenu.Item
                  onSelect={unsetCustomClassHandler}
                  className={style.item}
                >
                  {t('Remove custom class')}
                </DropdownMenu.Item>
              </DropdownMenu.Group>
              <DropdownMenu.Group className={style.group}>
                {customClassList?.map(({ id, name }) => (
                  <DropdownMenu.Item
                    key={id}
                    onSelect={() => setCustomClassHandler(id || '')}
                    className={cx(style.item, {
                      [style.isActive]: customClassName === name,
                    })}
                  >
                    {name}
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.Group>
            </>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export const CustomClass: React.FC<Props> = ({ editor, customClassList }) => {
  const { setCustomClassHandler, unsetCustomClassHandler, customClassName } =
    useCustomClass({
      editor,
      customClassList,
    });
  return (
    <CustomClassView
      setCustomClassHandler={setCustomClassHandler}
      unsetCustomClassHandler={unsetCustomClassHandler}
      customClassName={customClassName}
      customClassList={customClassList}
      editable={editor.isEditable}
    />
  );
};
