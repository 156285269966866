export type Options =
  | 'headerOne'
  | 'headerTwo'
  | 'headerThree'
  | 'headerFour'
  | 'headerFive'
  | 'paragraph'
  | 'sizeSmall'
  | 'sizeNormal'
  | 'sizeLarge'
  | 'sizeHuge'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'strike'
  | 'code'
  | 'background'
  | 'color'
  | 'align'
  | 'blockquote'
  | 'codeBlock'
  | 'listOrdered'
  | 'listBullet'
  | 'indentRemove'
  | 'indentAdd'
  | 'scriptSub'
  | 'scriptSuper'
  | 'link'
  | 'image'
  | 'oembedly'
  | 'clean';

export const optionTypes: Options[] = [
  'headerOne',
  'headerTwo',
  'headerThree',
  'headerFour',
  'headerFive',
  'paragraph',
  'sizeSmall',
  'sizeNormal',
  'sizeLarge',
  'sizeHuge',
  'bold',
  'italic',
  'underline',
  'strike',
  'code',
  'background',
  'color',
  'align',
  'blockquote',
  'codeBlock',
  'listOrdered',
  'listBullet',
  'indentRemove',
  'indentAdd',
  'scriptSub',
  'scriptSuper',
  'link',
  'image',
  'oembedly',
  'clean',
];
