import Quill from 'quill';

import { getTranslation } from '../../../i18n';

const BlockEmbed = Quill.import('blots/embed');
const Link = Quill.import('formats/link');
const icons = Quill.import('ui/icons');
icons['oembedly'] = `
<svg
  xmlns="http://www.w3.org/2000/svg"
  height="18"
  viewBox="0 0 24 24"
  width="18"
>
  <path
    class="ql-fill"
    d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
  />
</svg>
`;

type Value = {
  url: string;
  html: string;
};

class OembedlyFormat extends BlockEmbed {
  static create(value: Value) {
    const { t } = getTranslation('richEditor');
    const node = super.create(value);
    node.setAttribute('data-value', value.url);
    node.setAttribute('data-html', value.html);

    if (value.html != null) {
      if (value.html.indexOf('<iframe') === 0) {
        node.innerHTML = value.html;
      } else {
        node.innerHTML = `<a href="${value.url}" target="_blank" rel="noopener noreferrer" >${value.url}</a>`;
        node.setAttribute('data-link', true);
        node.addEventListener('click', () => window.open(value.url));
      }
    } else {
      node.innerHTML = t('Not supported');
    }
    return node;
  }
  static value(domNode: Element) {
    return {
      url: domNode.getAttribute('data-value'),
      html: domNode.getAttribute('data-html'),
    };
  }
  static sanitize(url: string) {
    return Link.sanitize(url);
  }
}

OembedlyFormat.blotName = 'oembedly';
OembedlyFormat.className = 'ql-embed';
OembedlyFormat.tagName = 'DIV';

export default OembedlyFormat;
