import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import {
  useHandleSortButtonClick,
  getSortDirection,
} from '@/components/Form/SortButton/useSortButtonTs';

import { useQueryParam } from '@/hooks/useQueryParams';
import { useGetMyService } from '@/hooks/useService';

import CheckRoles from '../CheckRoles';
import SortButton from '../Form/SortButton/SortButtonTs';
import Head from '../Head';
import Button from '../ui/Button';
import Searchfield from '../ui/Searchfield';
import { Table, Thead, Tbody, Tr, Th, Td } from '../ui/Table';

import type { RoleOrderQuery } from '@/entity/role';

import styles from './roleList.module.css';

import { formatDateToMinutes } from '@/util/date';
import {
  useAllRoles,
  useFilteredRoles,
} from '@/views/Common/Roles/useRolesReader';

const RoleList: React.FC = () => {
  const { service } = useGetMyService();
  const history = useHistory();

  const { t } = useTranslation('roleList');
  // URLパラメータからソート順を取得
  const order = useQueryParam('order', 'createdAt') as RoleOrderQuery;
  const handleSortButtonClick = useHandleSortButtonClick('createdAt');
  const { data: roles, isLoading } = useAllRoles(service?.partitionKey);
  const { filteredRoles, sortedRoles, searchRoles } = useFilteredRoles(
    roles,
    order,
  );

  const displayRoles = useMemo(() => {
    if (!sortedRoles) {
      return [];
    }
    return filteredRoles || sortedRoles;
  }, [filteredRoles, sortedRoles]);

  if (roles === undefined) {
    return null;
  }

  const createSortableHeader = (sortName: string, element: string) => {
    return (
      <Th>
        <SortButton
          name={sortName}
          direction={getSortDirection(order, sortName)}
          onClick={handleSortButtonClick}
        >
          {element}
        </SortButton>
      </Th>
    );
  };

  return (
    <div className={styles.wrapper}>
      <Head title={t('Roles')} />
      <header className={styles.header}>
        <Searchfield
          onChange={(e) => searchRoles(e.target.value)}
          className={styles.searchfield}
        />
        <CheckRoles permission="rolePermission" operation="create">
          <Link to="/roles/create">
            <Button icon="add" value={t('Add')} type="secondary" />
          </Link>
        </CheckRoles>
      </header>
      <main className={styles.main}>
        <div className={styles.tableWrap}>
          <Table>
            <Thead>
              <Tr>
                {createSortableHeader('name', t('Role Name'))}
                {createSortableHeader('createdAt', t('Date Created'))}
                {createSortableHeader('updatedAt', t('Date Updated'))}
              </Tr>
            </Thead>
            <Tbody>
              {displayRoles.map((role) => (
                <Tr
                  key={role.roleId}
                  onClick={() =>
                    history.push(`/roles/${role.roleId.replace('ROLE#', '')}`)
                  }
                >
                  <Td>{role.name}</Td>
                  <Td>{formatDateToMinutes(role.createdAt)}</Td>
                  <Td>{dayjs(role.updatedAt).fromNow()}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {!isLoading && displayRoles.length === 0 && (
            <div className={styles.empty}>{t('No matching roles')}</div>
          )}
        </div>
      </main>
    </div>
  );
};

export default RoleList;
