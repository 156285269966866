import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToasts } from 'react-toast-notifications';

import { updateRole as updateRoleUsecase } from '@/usecase/roleUsecase';

import { useMyRolesContext } from '@/hooks/Role/useMyRoles';

import { useI18nFormRoleHelpers } from '../i18n';
import { getRoleOutput } from './convRoleInputOutput';

import type { Role } from '@/entity/role';
import type { Service } from '@/types/services';
import type { FormRoleInputValue } from '../provider/stateContext';

import { hasDuplicates } from '@/util/array';
import { apiQueryKeys } from '@/views/Common/api/queryKeys';

/**
 * Roleを更新するハンドラーとその結果を提供する
 */
function useUpdateRole(
  serviceId: Service['partitionKey'],
  roleId: Role['roleId'],
) {
  const cache = useQueryClient();
  const { t } = useI18nFormRoleHelpers();
  const { addToast } = useToasts();
  const { reloadRoles } = useMyRolesContext();

  const { mutate, data, isLoading } = useMutation({
    mutationFn: async (inputValue: FormRoleInputValue) => {
      // 個別権限でAPIが重複している場合はエラー
      if (
        hasDuplicates(
          inputValue.exceptionContentPermissions.map(({ apiId }) => apiId),
        )
      ) {
        addToast(t('Same API already exists'), { appearance: 'error' });
        return;
      }

      return updateRoleUsecase(
        getRoleOutput(inputValue, { serviceId, roleId }, true),
      );
    },
    async onSuccess(data) {
      if (data) {
        // サイドバーの値に反映させるためキャッシュを更新
        await cache.invalidateQueries(['formRole', { serviceId, roleId }]);
        await cache.invalidateQueries(['roleList'], { type: 'all' });
        await cache.invalidateQueries(['serviceUsers'], { type: 'all' });
        await cache.invalidateQueries(['myRoles', serviceId], { type: 'all' });
        await cache.invalidateQueries(['apiKey'], { type: 'all' });
        await cache.invalidateQueries(['reviewRequestsCount'], { type: 'all' });
        await cache.invalidateQueries(apiQueryKeys.list(), { type: 'all' });
        reloadRoles(serviceId);
        addToast(t('Changes have been made.'), { appearance: 'success' });
      }
    },
    onError(e) {
      console.error(e);
      addToast(t('Failed to make changes.'), { appearance: 'error' });
    },
  });

  return [mutate, data, isLoading] as const;
}

export { useUpdateRole };
