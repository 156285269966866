import { useTranslation, Trans } from 'react-i18next';

import UpsellModal from '../../ui/UpsellModal';

type Props = {
  endpoint: string;
  close: () => void;
};

export const DuplicateEndpointModalChildren: React.FC<Props> = ({
  endpoint,
  close,
}) => {
  const { t } = useTranslation('selectApiTemplate');

  return (
    <UpsellModal
      title={t('API endpoint is already in use')}
      close={close}
      description={
        <>
          <p>
            {t('An API with {{endpoint}} endpoint already exists.', {
              endpoint,
            })}
          </p>
          <p>
            {Trans({
              t,
              i18nKey:
                'To create an API from a template, delete this API or change the endpoint.',
              children: <br />,
            })}
          </p>
        </>
      }
    />
  );
};
