import { Code } from '@tiptap/extension-code';

import type { CodeOptions } from '@tiptap/extension-code';

type CustomCodeOptions = CodeOptions & {
  inputRules: boolean;
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-code/src/code.ts
export const CustomCode = Code.extend<CustomCodeOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: true,
    };
  },

  parseHTML() {
    return this.options.inputRules ? this.parent?.() : [];
  },

  addKeyboardShortcuts() {
    return this.options.inputRules ? { ...this.parent?.() } : {};
  },

  addInputRules() {
    return this.options.inputRules ? this.parent?.() || [] : [];
  },

  addPasteRules() {
    return this.options.inputRules ? this.parent?.() || [] : [];
  },
});
