import { useCallback, useState } from 'react';

/**
 * AlertDialogの開閉状態を外部から制御する際の機能を提供する
 *
 * このフックを用いず、AlertDialogのtrigger要素を配置して内部で開閉状態を制御する方法も採れます
 *
 * @param defaultOpen 初期の開閉状態 (falseなら閉じた状態)
 * @example
 * ```tsx
 * const alertDialog = useAlertDialogTrigger();
 *
 * return (
 *   <>
 *     // triggerをAlertDialogの外に配置するケース
 *     <button type="button" onClick={() => alertDialog.openAlertDialog()}>Open AlertDialog</button>
 *     <AlertDialog
 *       open={alertDialog.isOpenAlertDialog}
 *       trigger={null}
 *       onCancel={() => alertDialog.closeAlertDialog()}
 *     />
 *   </>
 * )
 * ```
 */
function useAlertDialogTrigger(defaultOpen = false) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return {
    isOpenAlertDialog: isOpen,
    openAlertDialog: open,
    closeAlertDialog: close,
  };
}

export { useAlertDialogTrigger };
