import type React from 'react';

import { useMediaField } from './useMediaField';
import { ImageOperation } from '../shared/ImageOperation';

import type { Field } from '@/types/contents';

import styles from './mediafield.module.css';

export type Value = {
  mediumId: string;
};

export type Props = {
  value?: Value | null;
  field?: Field | null;
  onChange: (value: Value) => void;
  onDelete: () => void;
  hasMediumCreatePermission: boolean;
};

export type ViewProps = {
  value?: Value | null;
  field?: Field | null;
  onChange: (value: Value) => void;
  onDelete: () => void;
  multipleUpload: boolean;
  onDropAccepted: (resultMediumIds: (string | null)[] | null) => void;
  hasMediumCreatePermission: boolean;
};

export const MediaFieldView: React.FC<ViewProps> = ({
  value,
  field,
  onChange,
  onDelete,
  multipleUpload,
  onDropAccepted,
  hasMediumCreatePermission,
}) => {
  return (
    <ImageOperation
      value={value}
      field={field}
      onChange={onChange}
      onDelete={onDelete}
      multipleUpload={multipleUpload}
      onDropAccepted={onDropAccepted}
      className={styles.main}
      hasMediumCreatePermission={hasMediumCreatePermission}
    />
  );
};

export const MediaField: React.FC<Props> = ({
  value,
  field,
  onChange,
  onDelete,
  hasMediumCreatePermission,
}) => {
  const { onDropAccepted } = useMediaField({ onChange });

  return (
    <MediaFieldView
      value={value}
      field={field}
      onChange={onChange}
      onDelete={onDelete}
      multipleUpload={false}
      onDropAccepted={onDropAccepted}
      hasMediumCreatePermission={hasMediumCreatePermission}
    />
  );
};
