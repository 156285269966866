import { Auth } from 'aws-amplify';
import QRCode from 'qrcode.react';
import React, { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useInput } from '../../../hooks';
import {
  useVerifyMfaToken,
  useRemoveMfaToken,
  useCognitoUser,
  useUserAttributes,
} from '../../../hooks/Profile/useProfile';
import { getPureHost } from '../../../util';
import { isSAMLUser as getIsSAMLUserFlag } from '../../../util';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import Switch from '../../ui/Switch';
import Textfield from '../../ui/Textfield';

import styles from './mfa.module.css';

const Mfa = () => {
  const { t } = useTranslation('profileMfa');
  const { cognitoUser } = useCognitoUser();
  const { userAttributes } = useUserAttributes();

  const [useTotp, setUseTotp] = useState<boolean>(
    cognitoUser?.preferredMFA === 'SOFTWARE_TOKEN_MFA',
  );

  const [mfaInitCode, setMfaInitCode] = useState<string | undefined>(undefined);
  const [mfaUrl, setMfaUrl] = useState<string | undefined>(undefined);

  const [mfaCode, onChangeMfaCode] = useInput('', () => {});

  //保存後は2要素認証QRコードを消す
  const closeQRcode = useCallback(() => {
    setMfaInitCode(undefined);
    setMfaUrl(undefined);
  }, []);

  const { verifyMfaToken, verifyMfaTokenLoading } =
    useVerifyMfaToken(closeQRcode);
  const { removeMfaToken, removeMfaTokenLoading } = useRemoveMfaToken();

  const changeUseTotp = useCallback(
    async (useTotp: any) => {
      setUseTotp(useTotp);

      if (useTotp) {
        const code = await Auth.setupTOTP(cognitoUser);
        const email = userAttributes.email
          ? encodeURIComponent(userAttributes.email)
          : undefined;
        const otpUrl = `otpauth://totp/${email}?secret=${code}&issuer=${getPureHost()}`;
        setMfaInitCode(code);
        setMfaUrl(otpUrl);
      } else {
        setMfaInitCode(undefined);
        setMfaUrl(undefined);
        if (cognitoUser) {
          return (
            cognitoUser.preferredMFA === 'SOFTWARE_TOKEN_MFA' &&
            removeMfaToken({ user: cognitoUser })
          );
        }
      }
    },
    [cognitoUser, userAttributes, removeMfaToken],
  );

  const doVerifyMfaToken = useCallback(async () => {
    verifyMfaToken({
      user: cognitoUser,
      code: mfaCode,
    });
  }, [verifyMfaToken, cognitoUser, mfaCode]);

  const isSAMLUser = useMemo(() => getIsSAMLUserFlag(), []);

  if (isSAMLUser) {
    return (
      <div>
        <h2 className={styles.title}>{t('Two-Factor authentication')}</h2>
        <div className={styles.wrapper}>
          <p>{t('This setting cannot be changed with your account. ')}</p>
        </div>
      </div>
    );
  }

  return Object.keys(userAttributes).length === 0 ? null : (
    <div>
      <h2 className={styles.title}>{t('Two-Factor authentication')}</h2>
      <p>{t('Two-Factor authentication is used to enhance security.')}</p>
      <Fieldset legend={t('Two-Factor authentication')}>
        <Switch
          children={t('Set')}
          size="large"
          onChange={changeUseTotp}
          on={useTotp}
          disabled={removeMfaTokenLoading}
        />
        {mfaInitCode && mfaUrl && (
          <div className={styles.mfa}>
            <div className={styles.qr}>
              <QRCode value={mfaUrl} />
              <p className={styles.qrText}>
                {t(
                  'Scan the QR code with Google Authenticator app. Then, enter the verification number displayed on the screen.',
                )}
              </p>
            </div>
            <Textfield
              type="tel"
              placeholder={t(
                'Enter the 6-digit number displayed on the authentication app.',
              )}
              onChange={onChangeMfaCode}
            />
            <div className={styles.actions}>
              <Button
                type="primary"
                value={t('Send')}
                onClick={doVerifyMfaToken}
                disabled={verifyMfaTokenLoading}
              />
            </div>
          </div>
        )}
      </Fieldset>
    </div>
  );
};

export default Mfa;
