import * as Popover from '@radix-ui/react-popover';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useMembers } from '@/hooks/Member/useMembers';
import { useGetMyService } from '@/hooks/useService';

import ProfileImage from '../ProfileImage';
import Searchfield from '../ui/Searchfield';

import styles from './selectServiceUsers.module.css';

import { filterNotInvitingMembers } from '@/util/member';

type Props = {
  selectedUsers: string[];
  trigger: React.ReactNode;
  excludeAuthorId?: string;
  remove: (username: string) => void;
  add: (username: string) => void;
};

const SelectServiceUsers: React.FC<Props> = ({
  selectedUsers = [],
  trigger,
  excludeAuthorId,
  remove,
  add,
}) => {
  const { service } = useGetMyService();
  const { t } = useTranslation('selectServiceUsers');
  const { serviceUsers, filteredUsers, setFilteredUsers, searchUsers } =
    useMembers(service);

  const displayUsers = useMemo(() => {
    if (!serviceUsers) {
      return [];
    }
    return filterNotInvitingMembers(filteredUsers || serviceUsers).filter(
      (user: any) => user.username !== excludeAuthorId,
    );
  }, [excludeAuthorId, filteredUsers, serviceUsers]);

  return (
    <Popover.Root
      onOpenChange={(isOpen) => !isOpen && setFilteredUsers(undefined)}
    >
      <Popover.Trigger className={styles.trigger} asChild>
        {trigger}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          align="start"
          className={styles.content}
          style={{ zIndex: 1 }}
        >
          <div className={styles.search} onClick={(e) => e.stopPropagation()}>
            <Searchfield onChange={(e) => searchUsers(e.target.value)} />
          </div>
          {displayUsers && displayUsers.length > 0 ? (
            <ul className={styles.userList}>
              {displayUsers.map((user: any) => {
                const isSelected = selectedUsers.some(
                  (selectedUser: any) => selectedUser === user.username,
                );
                return (
                  <li key={user.username}>
                    <button
                      className={styles.userListButton}
                      onClick={() =>
                        isSelected ? remove(user.username) : add(user.username)
                      }
                    >
                      <ProfileImage picture={user.picture} />
                      <p className={styles.userName}>
                        {user.name || user.email.split('@')[0]}
                      </p>
                      {isSelected && <i className="material-icons">check</i>}
                    </button>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className={styles.noUsers}>
              {t('No members available for display')}
            </p>
          )}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default SelectServiceUsers;
