import { configureStore } from '@reduxjs/toolkit';

import accountReducers from '../ducks/account';
import apiReducers from '../ducks/api';
import apiListReducers from '../ducks/apiList';
import authReducers from '../ducks/auth';

export const store = configureStore({
  reducer: {
    accountState: accountReducers,
    authState: authReducers,
    apiState: apiReducers,
    apiListState: apiListReducers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these field paths in all actions
        ignoredActionPaths: ['payload.cognitoUser'],
        // Ignore these paths in the state
        ignoredPaths: ['accountState.cognitoUser'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
