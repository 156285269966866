import type { MigrateApi } from '@/entity/api';
import type { WebhookSetting } from '@/entity/webhookSettings';
import { webhookSettingRepository } from '@/repository/webhookSettingRepository';
import type { Service } from '@/types/services';
import { safeJsonParse } from '@/util/safe-json';

const findWebhookSettingsUsecase = async (
  apiId: MigrateApi['partitionKey'],
) => {
  const { findWebhookSettings } = webhookSettingRepository();
  const res = await findWebhookSettings(apiId);

  return res.map((item) => {
    const { data: settingValues } = safeJsonParse(item.settingValues || '{}');

    const handleEvents = [...new Set(item.handleEvents || [])];

    return {
      ...item,
      handleEvents,
      settingValues,
    } as WebhookSetting;
  });
};

const getWebhookSettingsCountUsecase = async (
  serviceId: Service['partitionKey'],
  // 既存実装に倣い、LIMITを1,000とする
  limit = 1000,
) => {
  const { getWebhookSettingsCount } = webhookSettingRepository();
  const res = await getWebhookSettingsCount(serviceId, limit);
  return res || 0;
};

export { findWebhookSettingsUsecase, getWebhookSettingsCountUsecase };
