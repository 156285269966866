import { useTranslation } from 'react-i18next';

import IconWithTextNavLink from '../../../ui/IconWithTextNavLink';
import { Tag } from '../../../ui/Tag';

import type { MediumTag } from '../../../../types/MediumTag';

import styles from './tagsSelectGroup.module.css';

export type Props = {
  tags?: MediumTag[];
  onClickSelect: (id: string) => void;
};

const TagsSelectGroup: React.FC<Props> = ({ tags, onClickSelect }) => {
  const { t } = useTranslation('tagsSettings');

  return (
    <div>
      <h4 className={styles.heading}>{t('Select from existing tags')}</h4>
      <div className={styles.listWrapper}>
        <ul className={styles.list}>
          {tags?.map(({ mediumTagId, name }) => (
            <li key={mediumTagId} className={styles.item}>
              <button
                type="button"
                onClick={() => onClickSelect(mediumTagId)}
                className={styles.button}
              >
                <Tag name={name} icon="sell" />
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.link}>
        <IconWithTextNavLink
          to="/media/settings/tags"
          icon="settings"
          text={t('Manage Existing Tags')}
        />
      </div>
    </div>
  );
};

export default TagsSelectGroup;
