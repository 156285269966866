import { useMemo } from 'react';

import SelectServiceUsers from '@/components/SelectServiceUsers';

import {
  addMemberToAllowList,
  getMemberPermissionReadIdentifier,
  removeMemberToAllowList,
} from '../../helpers/memberPermissionValue';
import { useI18nFormRole } from '../../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../../provider';

import styles from './Read.module.css';

import { Select } from '@/views/Common/Roles/Select';

const Read = () => {
  const { t } = useI18nFormRole();

  const {
    originalRole,
    isAdminRole,
    input: {
      memberPermission: { read },
    },
  } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;
  const memberRead = useMemo(
    () => getMemberPermissionReadIdentifier(read),
    [read],
  );
  const originalAllowMemberList =
    originalRole?.memberPermission.read.allowMemberList || [];
  const inputAllowMemberList = read.allowMemberList || [];

  return (
    <>
      <Select
        name="memberPermission.read"
        value={memberRead}
        disabled={disabled}
        onValueChange={(v) => {
          if (v === 'ALL') {
            dispatch({
              type: 'set-member-permission-read',
              payload: true,
            });
          } else if (v === 'SAME_ROLE') {
            dispatch({
              type: 'set-member-permission-read',
              payload: { sameRole: true },
            });
          } else if (v === 'ALLOWLIST') {
            dispatch({
              type: 'set-member-permission-read',
              // 初期値は編集するRoleの設定値があればその値を、なければ空配列を設定する
              payload: { allowMemberList: originalAllowMemberList },
            });
          } else {
            dispatch({
              type: 'set-member-permission-read',
              payload: false,
            });
          }
        }}
        items={[
          {
            value: 'ALL',
            label: t('All members'),
            description: t(
              'Able to access the member list and the member detail page.',
            ),
          },
          {
            value: 'SAME_ROLE',
            label: t('Only members assigned to this role'),
            description: t(
              'Able to view only members assigned to this role in the member list.',
            ),
          },
          {
            value: 'ALLOWLIST',
            label: t('Only specified members'),
            description: t(
              'Only selected members are able to access the member details page.',
            ),
          },
          {
            value: 'NONE',
            label: t('None'),
            description: t('Members can not be displayed.'),
          },
        ]}
      />

      {memberRead === 'ALLOWLIST' && (
        <div className={styles.allowList}>
          <SelectServiceUsers
            data-testid="selectServiceUsers"
            selectedUsers={inputAllowMemberList}
            trigger={
              inputAllowMemberList.length === 0 ? (
                <span className={styles.allow}>
                  <i className="material-icons">group</i>
                  {t('No member is selected')}
                </span>
              ) : (
                <span className={styles.allow}>
                  <i className="material-icons">group</i>
                  {t('Number of members', {
                    count: inputAllowMemberList.length || 0,
                  })}
                </span>
              )
            }
            remove={(userId) => {
              dispatch({
                type: 'set-member-permission-read',
                payload: {
                  allowMemberList: removeMemberToAllowList(
                    inputAllowMemberList,
                    userId,
                  ),
                },
              });
            }}
            add={(userId) => {
              dispatch({
                type: 'set-member-permission-read',
                payload: {
                  allowMemberList: addMemberToAllowList(
                    inputAllowMemberList,
                    userId,
                  ),
                },
              });
            }}
          />
        </div>
      )}
    </>
  );
};

export default Read;
