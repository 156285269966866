import { getTranslation } from '@/i18n';
import { isUrl } from '@/util/is-url';

// 相対リンクのチェック
export function isRelativeLink(url: string): boolean {
  const isValidPath = /^\//;
  if (isValidPath.test(url)) {
    return true;
  }

  return false;
}

function isLink(url: string): boolean {
  if (typeof url !== 'string') {
    return false;
  }

  // 相対パス許容
  if (isRelativeLink(url)) {
    return true;
  }

  // 電話リンク許容 tel:の後に数字とハイフン、プラス記号のいずれかが続くこと
  if (url.match(/^tel:[0-9\-+]+$/)) {
    return true;
  }
  // メーラー起動リンク許容
  if (
    url.match(
      /^mailto:[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    )
  ) {
    return true;
  }

  if (isUrl(url)) {
    return true;
  }

  return false;
}

export const validateLink = (url: string) => {
  const { t } = getTranslation('richEditorV2');

  if (url === '') {
    return t('This field is required.');
  }
  if (!isLink(url)) {
    return t('Incorrect URL format');
  }
};
