import cx from 'classnames';
import { useMemo, useCallback } from 'react';

import type { Dispatch, SetStateAction } from 'react';

import styles from './pagination.module.css';

type Props = {
  offset: number;
  setOptions: Dispatch<SetStateAction<number[]>>;
  totalCount: number;
  pageLength: number;
  disabled?: boolean;
};

const Pagination: React.FC<Props> = ({
  offset,
  setOptions,
  totalCount,
  pageLength,
  disabled = false,
}) => {
  const currentPage = Math.floor(offset / pageLength);
  const setOffset = useCallback(
    (v: any) => {
      setOptions((option: number[]) => ({
        ...option,
        offset: v,
      }));
    },
    [setOptions],
  );
  const paginationKeys = useMemo(() => {
    const PAGINATION_LENGTH = 6;
    const createArray = (i = 0) => [
      ...Array.from(Array(PAGINATION_LENGTH).keys()).map((v) => v + i),
    ];
    if (totalCount <= pageLength * PAGINATION_LENGTH) {
      return [...Array(Math.ceil(totalCount / pageLength)).keys()];
    }
    if (currentPage < PAGINATION_LENGTH / 2) {
      return createArray();
    }
    if (
      Math.ceil(totalCount / pageLength) - PAGINATION_LENGTH + 1 <
      currentPage
    ) {
      return createArray(
        Math.ceil(totalCount / pageLength) - PAGINATION_LENGTH,
      );
    }
    return createArray(currentPage - Math.floor(PAGINATION_LENGTH / 2));
  }, [currentPage, pageLength, totalCount]);

  if (
    currentPage === undefined ||
    totalCount === undefined ||
    paginationKeys.length <= 1
  ) {
    return null;
  }

  return (
    <nav className={styles.pagination}>
      <button
        className={cx(styles.paginationButton, {
          [styles.disabled]: disabled,
        })}
        onClick={() => setOffset(0)}
        disabled={disabled}
      >
        <i className="material-icons-outlined">navigate_before</i>
      </button>
      {paginationKeys &&
        paginationKeys.map((i) =>
          currentPage === i ? (
            <button
              key={i}
              className={cx(styles.paginationList, styles.isSelected, {
                [styles.disabled]: disabled,
              })}
              disabled={disabled}
            >
              {i + 1}
            </button>
          ) : (
            <button
              className={cx(styles.paginationList, {
                [styles.disabled]: disabled,
              })}
              key={i}
              onClick={() => setOffset(pageLength * i)}
              disabled={disabled}
            >
              {i + 1}
            </button>
          ),
        )}
      <button
        className={cx(styles.paginationButton, {
          [styles.disabled]: disabled,
        })}
        onClick={() =>
          setOffset(pageLength * (Math.ceil(totalCount / pageLength) - 1))
        }
        disabled={disabled}
      >
        <i className="material-icons-outlined">navigate_next</i>
      </button>
    </nav>
  );
};

export default Pagination;
