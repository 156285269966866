import GraphemeSplitter from 'grapheme-splitter';

import i18n, { getTranslation } from '../../i18n';

import type { PlanDetail } from '@/API';
import type { NumberRange } from '@/types/field';

import { isUrl } from '@/util/is-url';

export const validateEmail = (email = '') => {
  const { t } = getTranslation('validations');
  if (email === '') {
    return t('Enter your email address.');
  }
  if (
    !email.match(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/,
    )
  ) {
    return t('Email address format is incorrect.');
  }
  return null;
};

export const validatePassword = (password = '') => {
  const { t } = getTranslation('validations');
  if (password === '') {
    return t('Enter your password');
  }
  if (password.length < 8) {
    return t('Enter a password of at least 8 characters');
  }
  if (256 < password.length) {
    return t('Enter a password of 256 characters or less');
  }
  if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)) {
    return t('Include uppercase and lowercase letters and numbers');
  }
  return null;
};

export const validateCode = (code = '') => {
  const { t } = getTranslation('validations');
  if (code === '') {
    return t('Enter the code');
  }
  return null;
};

export const validateMfaCode = (code = '') => {
  const { t } = getTranslation('validations');
  if (code === '') {
    return t('Enter the code');
  }
  return null;
};

export const validateAccountName = (name: string) => {
  const { t } = getTranslation('validations');
  if (!name) {
    return t('Enter a display name');
  }
  return null;
};

export const validateServiceName = (name: string) => {
  const { t } = getTranslation('validations');
  if (!name) {
    return t('Enter a service name');
  }
  return null;
};

export const validateServiceDomain = (domain: string) => {
  const { t } = getTranslation('validations');
  if (!domain) {
    return t('Enter your domain');
  }
  if (domain.length < 3) {
    return t('Domain is too short');
  }
  if (32 < domain.length) {
    return t('Domain is too long');
  }
  if (!domain.match(/^([a-z0-9][a-z0-9-]*[a-z0-9])$/)) {
    return t('Domain format is incorrect.');
  }
  return null;
};

export const validateEnvironmentName = (name: string | null) => {
  const { t } = getTranslation('validations');

  if (!name) {
    return t('Enter environment name');
  }
  const PRODUCTION_NAME = ['本番環境', 'Production'];
  if (PRODUCTION_NAME.includes(name)) {
    return t('Invalid environment name');
  }

  return null;
};

export const validateApiName = (name: string) => {
  const { t } = getTranslation('validations');
  if (!name) {
    return t('Enter an API name');
  }
  return null;
};

export const validateCustomFieldName = (name: string) => {
  const { t } = getTranslation('validations');
  if (!name) {
    return t('Enter an Custom Field name');
  }
  return null;
};

export const validateApiEndpoint = (endpoint: string) => {
  const { t } = getTranslation('validations');
  if (!endpoint) {
    return t('Enter an endpoint');
  }
  if (endpoint.length < 3) {
    return t('Endpoint is too short');
  }
  if (32 < endpoint.length) {
    return t('Endpoint is too long');
  }
  if (!endpoint.match(/^[a-z0-9-_]*$/)) {
    return t('Enter endpoints in alphanumeric characters');
  }
  return null;
};

export const validateApiDescription = (description: string) => {
  const { t } = getTranslation('validations');
  if (1000 < description.length) {
    return t('Enter up to 1000 characters');
  }
  return null;
};

export const validateApiType = (type: 'PAGE' | 'LIST') => {
  const { t } = getTranslation('validations');
  if (type !== 'PAGE' && type !== 'LIST') {
    return t('Select an option.');
  }
  return null;
};

export const validateApiFields = (
  fields: any[] = [],
  allowPublishedAt = false,
) => {
  for (const field of fields) {
    const idRes = validateApiFieldId(
      field.fieldId,
      fields
        .filter((f) => f.idValue !== field.idValue)
        .map((field) => field.fieldId),
      allowPublishedAt,
    );
    if (idRes !== null) {
      return idRes;
    }
    const nameRes = validateApiFieldName(field.name);
    if (nameRes !== null) {
      return nameRes;
    }
    const kindRes = validateApiFieldKind(field.kind);
    if (kindRes !== null) {
      return kindRes;
    }

    const referenceKeyRes = validateApiFieldReferenceKey(
      field.kind,
      field.referenceKey,
    );
    if (referenceKeyRes !== null) {
      return referenceKeyRes;
    }
  }
  return null;
};

//publishedAtを既に設定しているAPIのスキーマが一切変更できなくなってしまうため、
//更新時のみは変更を可能としている
export const validateApiFieldsAllowPublishedAt = (fields = []) => {
  validateApiFields(fields, true);
};

//publishedAtを既に設定しているAPIのスキーマが一切変更できなくなってしまうため、
//更新時のみは変更を可能としている
export const validateApiFieldId = (
  fieldId: string,
  otherFieldIds?: string[],
  allowPublishedAt = false,
) => {
  const { t } = getTranslation('validations');
  if (!fieldId) {
    return t('This field is required.');
  }
  if (fieldId.length < 2) {
    return t('Too short');
  }
  if (20 < fieldId.length) {
    return t('Too long');
  }
  if (!fieldId.match(/^[a-zA-Z0-9-_]*$/)) {
    return t('Only alphanumeric characters are allowed.');
  }
  const invalidIds = [
    'id',
    'createdat',
    'updatedat',
    allowPublishedAt ? null : 'publishedat',
    'revisedat',
    'fieldid',
  ].filter((v) => v);
  if (invalidIds.includes(fieldId.toLowerCase())) {
    return t('Unavailable value');
  }
  if (otherFieldIds?.includes(fieldId)) {
    return t('Same id already exists');
  }
  return null;
};

export const validateApiFieldName = (fieldName: string) => {
  const { t } = getTranslation('validations');
  if (!fieldName) {
    return t('This field is required.');
  }
  return null;
};

export const validateApiFieldKind = (kind: string) => {
  const { t } = getTranslation('validations');
  if (!kind) {
    return t('Select an option.');
  }
  return null;
};

export const validateApiFieldReferenceKey = (kind: string, key?: string) => {
  const { t } = getTranslation('validations');

  if (kind !== 'relation' && kind !== 'relationList') {
    return null;
  }
  if (!key) {
    return t('A referenced API must be selected.');
  }
  return null;
};

export const validateUrl = (url: string) => {
  const { t } = getTranslation('validations');

  if (url === '') {
    return t('This field is required.');
  }
  if (!url.startsWith('http') || !isUrl(url)) {
    return t('Incorrect URL format');
  }
  return null;
};

export const validateSecret = (secret: string) => {
  const { t } = getTranslation('validations');

  if ((secret?.length ?? 0) > 1000) {
    return t('Enter up to 1000 characters');
  }
  return null;
};

export const allowedFileNameLength = (fileName: string) => {
  return fileName.length <= 1000;
};

export const validateFileName = (fileName: string) => {
  const { t } = getTranslation('validations');

  if (fileName === '') {
    return t('This field is required.');
  }

  if (!allowedFileNameLength(fileName)) {
    return t('Enter up to 1000 characters');
  }

  return null;
};

export const validateContentId = (id: string) => {
  const { t } = getTranslation('validations');

  if (!id) {
    return t('This field is required.');
  }
  if (id.length < 3) {
    return t('At least 3 characters are required.');
  }
  if (50 < id.length) {
    return t('Enter up to 50 characters');
  }
  if (!id.match(/^[a-zA-Z0-9-_]*$/)) {
    return t('Only alphanumeric characters are allowed.');
  }
  if (
    ['create', 'reference', 'settings', 'custom-fields', 'auth', 'p'].includes(
      id,
    )
  ) {
    return t('Cannot be created');
  }
  return null;
};

export const validateMedium = (file: File, currentPlan?: PlanDetail) => {
  // ファイルがない場合は無視
  if (file === undefined) {
    return null;
  }

  // 40MBを超えていたら
  const maxSize = currentPlan?.limit?.maxMediumSizeInMb ?? 40;
  if (file.size > maxSize * 1024 * 1024) {
    return i18n.t('Files larger than {{maxSize}} MB cannot be uploaded', {
      ns: 'validations',
      maxSize,
    });
  }

  return null;
};

export const validateImageSize = (width: number, height: number) => {
  const { t } = getTranslation('validations');
  if (width < 1 || height < 1) {
    return t('Size is too small');
  }
  if (
    !String(width).match(/^([1-9]\d*|0)$/) ||
    !String(height).match(/^([1-9]\d*|0)$/)
  ) {
    return t('Enter an integer greater than or equal to 1');
  }
  return null;
};

export const validateImageFieldSize = (
  width: number | undefined,
  height: number | undefined,
) => {
  const { t } = getTranslation('validations');
  if (!width && !height) {
    return t('Enter an integer greater than or equal to 1');
  }

  if (width && width < 1) {
    return t('Size is too small');
  }
  if (height && height < 1) {
    return t('Size is too small');
  }
  if (width && !String(width).match(/^([1-9]\d*|0)$/)) {
    return t('Enter an integer greater than or equal to 1');
  }
  if (height && !String(height).match(/^([1-9]\d*|0)$/)) {
    return t('Enter an integer greater than or equal to 1');
  }
  return undefined;
};
export const validateCSV = (data: any[], upperLimit: number) => {
  const { t } = getTranslation('validations');

  if (data.length > upperLimit + 1) {
    return i18n.t('Cannot import more than {{upperLimit}} contents', {
      ns: 'validations',
      upperLimit,
    });
  }
  const _data = data
    .map((d) => d[0])
    .filter((id: string, i: number) => i !== 0 && id !== '');
  for (const [i, id] of _data.entries()) {
    if (id.length !== 0 && (id.length < 3 || 50 < id.length)) {
      return t(
        'Enter id of at least 3 characters and no more than 50 characters',
      );
    }
    if (!id.match(/^[a-zA-Z0-9-_]*$/)) {
      return t('Enter id in alphanumeric characters');
    }
    if (
      ['create', 'reference', 'settings', 'custom-fields', 'auth'].includes(id)
    ) {
      return t('id contains a value that cannot be set');
    }
    if (_data.indexOf(id) !== i) {
      return t('Same id already exists');
    }
  }
  return null;
};

export const validateRequiredFiled = (
  fields: any[] = [],
  content: any = {},
  customFields: any[] = [],
): any | null => {
  for (const field of fields) {
    if (
      field.required === true &&
      field.kind !== 'boolean' && // 真偽値ではundefinedでもfalse判定とする
      (content === undefined ||
        content[field.idValue] === undefined ||
        content[field.idValue][field.kind] === undefined)
    ) {
      return i18n.t('{{name}} is a requirement', {
        ns: 'validations',
        name: field.name,
      });
    }

    // 複数画像フィールドではundefinedではなく[]となるので別判定する
    if (
      field.required === true &&
      field.kind === 'mediaList' &&
      content[field.idValue]['mediaList'].length === 0
    ) {
      return i18n.t('{{name}} is a requirement', {
        ns: 'validations',
        name: field.name,
      });
    }

    // セレクトフィールドではundefinedではなく[]となるので別判定する
    if (
      field.required === true &&
      field.kind === 'select' &&
      content[field.idValue]['select'].length === 0
    ) {
      return i18n.t('{{name}} is a requirement', {
        ns: 'validations',
        name: field.name,
      });
    }

    // 複数参照ではundefinedではなく[]となるので別判定する
    if (
      field.required === true &&
      field.kind === 'relationList' &&
      content[field.idValue]['relationList'].length === 0
    ) {
      return i18n.t('{{name}} is a requirement', {
        ns: 'validations',
        name: field.name,
      });
    }

    // 繰り返しフィールドではundefinedではなく[]となるので別判定する
    if (
      field.required === true &&
      field.kind === 'repeater' &&
      content[field.idValue]['repeater'].length === 0
    ) {
      return i18n.t('{{name}} is a requirement', {
        ns: 'validations',
        name: field.name,
      });
    }

    // カスタム
    if (field.kind === 'custom') {
      const customField = customFields.find(
        (f) => f.createdAt === field.customFieldCreatedAt,
      );
      // 中のフィールドに対して再帰で見に行く
      // カスタムの入れ子はできないので現状は1階層のみ
      const res = validateRequiredFiled(
        customField && customField.fields,
        content[field.idValue]?.[field.kind] ?? {},
        customFields,
      );
      if (res !== null) {
        return res;
      }
    }

    // 繰り返しフィールド
    if (field.kind === 'repeater') {
      for (const item of content[field.idValue]
        ? content[field.idValue][field.kind]
        : []) {
        const customField = customFields.find(
          (f) => f.createdAt === item.customFieldCreatedAt,
        );

        // 中のフィールドに対して再帰で見に行く
        // カスタムの入れ子はできないので現状は1階層のみ
        const res = validateRequiredFiled(
          customField && customField.fields,
          item,
          customFields,
        );
        if (res !== null) {
          return res;
        }
      }
    }
  }
  return null;
};

export const validateEmpty = (value: any) => {
  const { t } = getTranslation('validations');
  if (!value) {
    return t('This field is required.');
  }
  return null;
};

export const validateNumber = (value: string | number | null | undefined) => {
  const { t } = getTranslation('validations');
  if (value === undefined || value === null || value === '') {
    return null;
  }
  if (isNaN(Number.parseFloat(value.toString()))) {
    return t('Enter a number');
  }
  return null;
};

export const validateNumberFinite = (
  value: string | number | null | undefined,
) => {
  const { t } = getTranslation('validations');
  if (value === undefined || value === null) {
    return null;
  }
  if (!isFinite(Number(value.toString()))) {
    return t('Number exceeds the upper limit');
  }
  return null;
};

// aws-sdk-js-v3にてJSのnumber範囲外の数値がBigIntとして扱われるため、
// 入力値がJSのnumber範囲内かどうかを検証する
// https://github.com/aws/aws-sdk-js-v3/pull/5427
export const validateNumberIsSafeNumber = (
  value: string | number | null | undefined,
) => {
  const { t } = getTranslation('validations');
  if (value === undefined || value === null) {
    return null;
  }

  const numberValue = Number(value.toString());
  if (
    numberValue < Number.MIN_SAFE_INTEGER ||
    numberValue > Number.MAX_SAFE_INTEGER
  ) {
    return t('Number exceeds the upper or lower limit');
  }
  return null;
};

export const validateRegExpPattern = (value: string) => {
  const { t } = getTranslation('validations');
  if (!value) {
    return t('Input a regular expression');
  }
  try {
    new RegExp(value);
    return null;
  } catch (error) {
    return t('Incorrect regular expression');
  }
};

export const validateRegExpFlags = (value: string) => {
  const { t } = getTranslation('validations');
  if (!value) return null;
  return /^(?!.*(.).*\1)[gimsuy]+$/.test(value) ? null : t('Incorrect flag');
};

const isPositiveInteger = (value: any) => {
  const x = Number.parseFloat(value);
  return !isNaN(value) && (x | 0) === x && x >= 0;
};

export const validateNumberRange = (
  min: number | undefined,
  max: number | undefined,
) => {
  const { t } = getTranslation('validations');
  const atLeast = typeof min === 'number' && max == null;
  if (atLeast) {
    return isPositiveInteger(min) ? null : t('Number are incorrect');
  }
  const notMoreThan = min == null && typeof max === 'number';
  if (notMoreThan) {
    return isPositiveInteger(max) && max > 0 ? null : t('Number are incorrect');
  }
  const between = typeof min === 'number' && typeof max === 'number';
  if (between) {
    if (!isPositiveInteger(min) || !isPositiveInteger(max)) {
      return t('Number are incorrect');
    }
    if (max === 0) {
      return t('Number are incorrect');
    }
    return max - min >= 0 ? null : t('Specify a number in valid range');
  }
  return !min && !max ? t('Enter a number') : t('Incorrect number');
};

type Text = string | null | undefined;
type Range = NumberRange;

export function getTextCount(text: Text): number {
  if (!text) {
    return 0;
  }

  // 絵文字等の文字数カウントのためにGraphemeSplitterを使用
  const splitter = new GraphemeSplitter();
  return text ? splitter.splitGraphemes(text).length : 0;
}

export const validateTextSizeLimit = (text: Text, { min, max }: Range) => {
  if (text === undefined || text === '' || text === null) {
    return null;
  }
  const textCount = getTextCount(text);
  const atLeast = typeof min === 'number' && max == null;
  if (atLeast) {
    return textCount >= min
      ? null
      : i18n.t('At least {{count}} characters are required.', {
          ns: 'validations',
          count: min,
        });
  }
  const notMoreThan = min == null && typeof max === 'number';
  if (notMoreThan) {
    return textCount <= max
      ? null
      : i18n.t('Cannot enter more than {{max}} characters', {
          ns: 'validations',
          max: max + 1,
        });
  }
  const between = typeof min === 'number' && typeof max === 'number';
  if (between) {
    return textCount >= min && textCount <= max
      ? null
      : i18n.t('Must be between {{min}} and {{count}} characters', {
          ns: 'validations',
          min,
          count: max,
        });
  }
  return;
};

export const validateNumberSizeLimit = (
  number: number | string | undefined | null,
  { min, max }: Range,
) => {
  if (number === undefined || number === '' || number === null) {
    return null;
  }
  const numberCount = (number && Number(number)) || 0;
  const atLeast = typeof min === 'number' && max == null;
  if (atLeast) {
    return numberCount >= min
      ? null
      : i18n.t('At least {{count}} are required.', {
          ns: 'validations',
          count: min,
        });
  }
  const notMoreThan = min == null && typeof max === 'number';
  if (notMoreThan) {
    return numberCount <= max
      ? null
      : i18n.t('Cannot enter more than {{max}}', {
          ns: 'validations',
          max: max + 1,
        });
  }
  const between = typeof min === 'number' && typeof max === 'number';
  if (between) {
    return numberCount >= min && numberCount <= max
      ? null
      : i18n.t('Must be between {{min}} and {{count}}', {
          ns: 'validations',
          min,
          count: max,
        });
  }
  return;
};

export const validateRelationListCountLimit = (
  relationList: any,
  { min, max }: Range,
) => {
  const relationListCount = relationList ? relationList : 0;
  const atLeast = typeof min === 'number' && max == null;
  const totalCount = relationListCount.length ?? 0;

  if (atLeast) {
    return totalCount >= min
      ? null
      : i18n.t('At least {{count}} characters are required.', {
          ns: 'validations',
          count: min,
        });
  }
  const notMoreThan = min == null && typeof max === 'number';
  if (notMoreThan) {
    return totalCount <= max
      ? null
      : i18n.t('Cannot enter more than {{max}} characters', {
          ns: 'validations',
          max: max + 1,
        });
  }
  const between = typeof min === 'number' && typeof max === 'number';
  if (between) {
    return totalCount >= min && totalCount <= max
      ? null
      : i18n.t('Must be between {{min}} and {{count}} characters', {
          ns: 'validations',
          min,
          count: max,
        });
  }
  return;
};

export const validateMatchPattern = (
  text: string | null | undefined,
  { pattern, flags }: { pattern: string | RegExp; flags: string | undefined },
) => {
  const { t } = getTranslation('validations');
  if (text === undefined || text === '' || text === null) {
    return null;
  }
  try {
    const regex = new RegExp(pattern, flags || undefined);
    return regex.test(text) ? null : t('Does not match the specified format');
  } catch {
    return t('Verification was not successful');
  }
};

export const validateReviewRequestTitle = (text: string) => {
  const { t } = getTranslation('validations');
  if (!text) {
    return t('This field is required.');
  }
  return null;
};

export const validateReviewRequestComment = (text: string | null) => {
  const { t } = getTranslation('validations');
  if (!text) {
    return t('This field is required.');
  }
  return null;
};

type Iframe = {
  id?: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  updatedAt?: Date;
  data?: any;
};
export const validateIframe = (message: Iframe) => {
  const { t } = getTranslation('validations');
  const { id, title, description, imageUrl, updatedAt, data } = message;
  if (id !== undefined && typeof id !== 'string') {
    return t('Enter id in string format');
  }
  if (title !== undefined && typeof title !== 'string') {
    return t('Enter title in string format');
  }
  if (description !== undefined && typeof description !== 'string') {
    return t('Enter description in string format');
  }
  if (imageUrl !== undefined && !isUrl(imageUrl)) {
    return t('Enter imageUrl in url format');
  }
  const isInvalidDate = (date: Date) =>
    date && date.getDate ? Number.isNaN(date.getDate()) : false;
  if (updatedAt !== undefined && isInvalidDate(updatedAt)) {
    return t('Enter updatedAt in date format');
  }
  if (data === undefined) {
    return t('Enter data');
  }

  return null;
};

export const validateSettingContentId = (length: number) => {
  const { t } = getTranslation('validations');
  if (length) {
    if (length < 3) {
      return t('At least 3 characters are required.');
    }
    if (50 < length) {
      return t('Enter up to 50 characters');
    }
  }
  return null;
};

export const validateAllowSwitch = (setting: any) => {
  const { t } = getTranslation('validations');
  if (setting.allowList.includes('symbol') && setting.allowList.length === 1) {
    return t('Only symbol cannot be selected');
  }
  if (setting.allowList.length === 0) {
    return t('Select an option.');
  }
  return null;
};

export const validateRichEditorSelectOption = (richEditorOptions: any[]) => {
  const { t } = getTranslation('validations');
  if (richEditorOptions.length === 0) {
    return t('Select an option.');
  }
  return null;
};

export const validateCustomerName = (text: string | null) => {
  const { t } = getTranslation('validations');
  if (!text) {
    return t('Enter the name of the person in charge of billing');
  }
  return null;
};

export const validateCustomerCompanyName = (text: string) => {
  const { t } = getTranslation('validations');
  if (!text) {
    return t('Enter billing company name.');
  }
  return null;
};

export const validateCustomerPhoneNumber = (number: number) => {
  const { t } = getTranslation('validations');
  if (!number) {
    return t('Enter billing phone number.');
  }
  return null;
};

export const validateCustomRequestHeaderKey = (key: string) => {
  const { t } = getTranslation('validations');
  if (!key) {
    return t('Enter key name');
  }
  if (typeof key !== 'string') {
    return t('Enter in string format');
  }
  if (!key.match('^[\x20-\x7E]+$')) {
    return t('Incorrect string');
  }
  if (!key.startsWith('X-')) {
    return t('Key names in custom request headers must be prefixed with X-');
  }
  return null;
};

export const validateCustomRequestHeaderValue = (value: string) => {
  const { t } = getTranslation('validations');
  if (!value) {
    return t('This field is required.');
  }
  if (typeof value !== 'string') {
    return t('Enter in string format');
  }
  if (!value.match('^[\x20-\x7E]+$')) {
    return t('Incorrect string');
  }
  return null;
};

export const validateCustomStatusKey = (key: string) => {
  const { t } = getTranslation('validations');
  if (!key) {
    return t('Enter API status name');
  }
  return null;
};

export const validateCustomStatusName = (name: string) => {
  const { t } = getTranslation('validations');
  if (!name) {
    return t('Enter display status name');
  }
  return null;
};

export const validateMediumTagName = (name: string) => {
  const { t } = getTranslation('validations');
  if (!name) {
    return t('Enter Tag Name');
  }
  if (validateTextSizeLimit(name, { min: null, max: 32 }) !== null) {
    return t('Tag name character limit exceeded');
  }
  const matches = name.match(/[()[\]]/g);
  if (matches !== null) {
    return i18n.t('The {{text}} cannot be used', {
      ns: 'validations',
      text: Array.from(new Set(matches)).join(''),
    });
  }
};
