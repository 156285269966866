import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Media from '@/components/Media';
import S3Image from '@/components/S3Image';
import Loading from '@/components/ui/Loading';

import { useMediaModal } from './useMediaModal';
import { useUploadByDragAndDrop } from './useUploadByDragAndDrop';
import { useValidation } from './useValidation';
import { NoPermission } from '../NoPermission';

import type { MediaFieldValue } from '../../MediaField';
import type { Medium } from '@/entity/medium';
import type { Field } from '@/types/contents';
import type { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';

import styles from './imageoperation.module.css';

import { useMedium } from '@/views/Common/medium/useMediumReader';
import { useCurrentService } from '@/views/Common/service/useServiceReader';
import { Icon } from '@/views/Common/Ui/Icon';
import { Modal, ModalContent, ModalTrigger } from '@/views/Common/Ui/Modal';

export type Props = {
  value?: MediaFieldValue | null;
  field?: Field | null;
  onChange: (value: MediaFieldValue) => void;
  onDelete: () => void;
  multipleUpload: boolean;
  onDropAccepted: (resultMediumIds: (string | null)[] | null) => void;
  className?: string;
  queryString?: string;
  hasMediumCreatePermission: boolean;
};

export type ViewProps = {
  medium?: Medium | null;
  isFetchingMedium: boolean;
  existMedium: boolean;
  customDomainImageHost?: string;
  errors: string[] | null;
  isOpenMediaModal: boolean;
  toggleMediaModal: (nextIsOpen: boolean) => void;
  openMediaModal: () => void;
  disabledMediaModalTrigger?: boolean;
  selectFile: (medium: Medium) => () => void;
  onDelete: () => void;
  isDragActive: boolean;
  dropZoneRootProps: DropzoneRootProps;
  dropZoneInputProps: DropzoneInputProps;
  uploadMediumProgress: {
    loaded: number;
    total: number;
  } | null;
  className?: string;
  queryString?: string;
  hasMediumCreatePermission: boolean;
};

export const ImageOperationView: React.FC<ViewProps> = ({
  medium,
  isFetchingMedium,
  existMedium,
  customDomainImageHost,
  errors,
  isOpenMediaModal,
  toggleMediaModal,
  openMediaModal,
  selectFile,
  disabledMediaModalTrigger,
  onDelete,
  isDragActive,
  dropZoneRootProps,
  dropZoneInputProps,
  uploadMediumProgress,
  className,
  queryString = '?w=320',
  hasMediumCreatePermission,
}) => {
  const { t } = useTranslation('imageOperation');

  return (
    <div>
      <div
        className={cx(
          className,
          styles.main,
          existMedium && styles.selected,
          isDragActive && styles.dragActive,
          uploadMediumProgress !== null && styles.uploadingProgress,
        )}
      >
        <Modal open={isOpenMediaModal} onOpenChange={toggleMediaModal}>
          <div {...dropZoneRootProps} className={styles.addButtonWrapper}>
            <input {...dropZoneInputProps} />
            <ModalTrigger
              title={t('Select an image')}
              className={cx(
                styles.addButton,
                existMedium && styles.selected,
                isDragActive && styles.dragActive,
              )}
              onClick={(e) => {
                e.stopPropagation();
                openMediaModal();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.stopPropagation();
                }
              }}
              disabled={disabledMediaModalTrigger}
            >
              {medium ? (
                <div className={styles.selectedImage}>
                  <S3Image
                    directory={medium.directory}
                    fileName={medium.fileName}
                    kind={medium.kind}
                    queryString={queryString}
                    customDomainImageHost={customDomainImageHost}
                  />
                </div>
              ) : isFetchingMedium ? (
                <Loading />
              ) : medium === null ? (
                <NoPermission />
              ) : (
                <>
                  <Icon
                    name="add_photo_alternate"
                    outlined
                    className={cx(styles.addButtonIcon, styles.override)}
                  />
                  {hasMediumCreatePermission && (
                    <span className={styles.addButtonText}>
                      {t('You can also add by drag and drop.')}
                    </span>
                  )}
                </>
              )}
            </ModalTrigger>
          </div>
          <ModalContent size="large" hasSpace={false}>
            <Media onClick={selectFile} onlyImage inModal />
          </ModalContent>
        </Modal>

        {!!medium && (
          <>
            <p className={styles.filename}>{medium?.fileName}</p>
            <Modal>
              <ModalTrigger
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.stopPropagation();
                  }
                }}
                aria-label={t('Zoom in on the selected image.')}
                className={cx(styles.roundButton, styles.previewButton)}
              >
                <Icon name="zoom_in" />
              </ModalTrigger>
              <ModalContent size="large" className={styles.previewModalContent}>
                {medium && (
                  <div className={styles.selectedImage}>
                    <S3Image
                      directory={medium.directory}
                      fileName={medium.fileName}
                      kind={medium.kind}
                      customDomainImageHost={customDomainImageHost}
                    />
                  </div>
                )}
              </ModalContent>
            </Modal>
          </>
        )}

        {existMedium && (
          <button
            aria-label={t('Deselect the selected image.')}
            className={cx(styles.roundButton, styles.closeButton)}
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.stopPropagation();
              }
            }}
          >
            <Icon name="close" />
          </button>
        )}

        {uploadMediumProgress && (
          <div className={styles.uploading}>
            <progress
              value={uploadMediumProgress.loaded}
              max={uploadMediumProgress.total}
              className={styles.progress}
            />
            <p>{t('Uploading...')}</p>
          </div>
        )}
      </div>

      {errors && errors.length > 0 && (
        <div role="alert">
          {errors.map((error) => (
            <p key={error} className={styles.error}>
              {error}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export const ImageOperation: React.FC<Props> = ({
  value,
  field,
  onChange,
  onDelete,
  multipleUpload,
  onDropAccepted,
  className,
  queryString,
  hasMediumCreatePermission,
}) => {
  // 現在のサービスを取得
  const { data: currentService } = useCurrentService();

  // value から medium を取得
  const { data: medium, isFetching: isFetchingMedium } = useMedium({
    serviceId: currentService?.partitionKey,
    mediumId: value?.mediumId,
  });

  // ユーザー定義のバリデーション
  const errors = useValidation(medium ?? null, {
    width: field?.imageSizeValidation?.imageSize?.width ?? undefined,
    height: field?.imageSizeValidation?.imageSize?.height ?? undefined,
  });

  // メディアモーダルが開かれる時の動作を定義
  const {
    isOpenMediaModal,
    toggleMediaModal,
    openMediaModal,
    selectFile,
    disabledMediaModalTrigger,
  } = useMediaModal(onChange);

  // ドラッグ＆ドロップでのアップロード
  const {
    isDragActive,
    dropZoneRootProps,
    dropZoneInputProps,
    uploadMediumProgress,
  } = useUploadByDragAndDrop({
    serviceId: currentService?.partitionKey,
    domain: currentService?.domain,
    multiple: multipleUpload,
    onDropAccepted,
    hasMediumCreatePermission,
  });

  return (
    <ImageOperationView
      medium={medium}
      isFetchingMedium={isFetchingMedium}
      existMedium={medium !== undefined}
      customDomainImageHost={currentService?.customDomainImageHost ?? undefined}
      errors={errors}
      isOpenMediaModal={isOpenMediaModal}
      toggleMediaModal={toggleMediaModal}
      openMediaModal={openMediaModal}
      selectFile={selectFile}
      disabledMediaModalTrigger={disabledMediaModalTrigger}
      onDelete={onDelete}
      isDragActive={isDragActive}
      dropZoneRootProps={dropZoneRootProps}
      dropZoneInputProps={dropZoneInputProps}
      uploadMediumProgress={uploadMediumProgress}
      className={className}
      queryString={queryString}
      hasMediumCreatePermission={hasMediumCreatePermission}
    />
  );
};
