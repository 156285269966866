import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { CustomClass as CustomClassType } from '@/types/field';
import type { Editor } from '@tiptap/react';

type Args = {
  editor: Editor;
  customClassList: CustomClassType[];
};

export const useCustomClass = ({ editor, customClassList }: Args) => {
  const { t } = useTranslation('richEditorV2');

  const setCustomClassHandler = useCallback(
    (id: string) => {
      editor.view.focus();
      editor.chain().focus().setCustomClass(id).run();
    },
    [editor],
  );

  const unsetCustomClassHandler = useCallback(() => {
    editor.view.focus();
    editor.chain().focus().unsetCustomClass().run();
  }, [editor]);

  const currentCustomClass = customClassList.find((v) =>
    editor.isActive('textStyle', { customClassId: v?.id }),
  );

  const customClassName = useMemo(() => {
    if (!currentCustomClass) {
      return t('Custom');
    }

    return currentCustomClass?.name;
  }, [currentCustomClass, t]);

  return {
    setCustomClassHandler,
    unsetCustomClassHandler,
    currentCustomClass,
    customClassName,
    customClassList,
  };
};
