import { useSyncExternalStore } from 'react';

const subscribe = (callback: () => void): (() => void) => {
  const contentMetaElement = document.getElementById('content-meta');

  if (!contentMetaElement) {
    return () => null;
  }

  const observer = new ResizeObserver(callback);
  observer.observe(contentMetaElement);

  return () => {
    observer.disconnect();
  };
};

const getSnapshot = (): number => {
  const contentMetaElement = document.getElementById('content-meta');

  if (!contentMetaElement) {
    return 0;
  }

  return contentMetaElement.clientHeight;
};

const useContentMetaHight = () => {
  return useSyncExternalStore(subscribe, getSnapshot);
};

export { useContentMetaHight };
