import { useState, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Button from '../../ui/Button';
import Textfield from '../../ui/Textfield';
import { validateUrl } from '../../Validations';
import styles from '../apiModel.module.css';

type InputIframeFieldProps = {
  url: string;
  setUrl: React.Dispatch<string>;
};

export const InputIframeField: React.FC<InputIframeFieldProps> = ({
  url = '',
  setUrl,
}) => {
  const { t } = useTranslation('inputIframeField');
  const [errorText, setErrorText] = useState<string | null>(null);
  const onChange = useCallback(
    (e?: React.ChangeEvent<HTMLInputElement>) => {
      if (e === undefined) {
        return;
      }
      setUrl(e.target.value);
      setErrorText(validateUrl(e.target.value));
    },
    [setUrl],
  );
  return (
    <Textfield
      onChange={onChange}
      defaultValue={url}
      // @ts-expect-error
      errorText={errorText && t(errorText)}
    />
  );
};

type InputIframeFieldInModalProps = {
  iframeUrl?: string;
  setIframe: React.Dispatch<string>;
};

export const InputIframeFieldInModal: React.FC<
  InputIframeFieldInModalProps
> = ({ iframeUrl, setIframe }) => {
  const { t } = useTranslation('inputIframeField');

  const [url, setUrl] = useState(iframeUrl ?? '');

  const set = useCallback(() => {
    setIframe(url);
  }, [setIframe, url]);

  return (
    <div>
      <p className={styles.modalDescription}>
        {Trans({
          t,
          i18nKey:
            'Enter URL to get information for Extended field. Click here for more information.',
          children: (
            <a
              href="https://document.microcms.io/manual/iframe-field"
              target="docs"
            >
              here
            </a>
          ),
        })}
      </p>
      <InputIframeField url={url} setUrl={setUrl} />
      <div className={styles.modalActionButtonWrapper}>
        <Button
          type="secondary"
          className={styles.modalActionButton}
          onClick={set}
          value={t('Confirm')}
        />
      </div>
    </div>
  );
};
