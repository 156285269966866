import cx from 'classnames';
import { forwardRef } from 'react';

import s from './InputGroupContainer.module.css';

/**
 * InputGroupのコンテナコンポーネント
 *
 * 複数のInputGroupをまとめるためのもの
 *
 * @example
 * ```tsx
 * <InputGroupContainer>
 *   <InputGroup></InputGroup>
 *   <InputGroup></InputGroup>
 * </InputGroupContainer>
 * ```
 */
const InputGroupContainer = forwardRef<
  HTMLDivElement,
  JSX.IntrinsicElements['div']
>(function InputGroupContainer({ children, className, ...otherProps }, ref) {
  return (
    <div
      {...otherProps}
      ref={ref}
      className={cx(s.inputGroupContainer, className)}
    >
      {children}
    </div>
  );
});

export { InputGroupContainer };
