import Linkify from '@/components/Linkify';

import { useTextArea } from './useTextArea';

import type { Field, NumberRange } from '@/types/field';

import { TextareaField } from '@/views/Common/Ui/TextareaField';

export type Props = {
  field: Field;
  value: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
};

export type ViewProps = {
  label: string;
  description?: React.ReactNode;
  value: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  required?: boolean;
  error?: string;
  textSize?: NumberRange;
  textCount?: number;
};

export const TextAreaView: React.FC<ViewProps> = ({
  label,
  description,
  value,
  onChange,
  required,
  error,
  textSize,
  textCount,
}) => {
  return (
    <TextareaField
      label={label}
      description={
        description !== undefined ? <Linkify>{description}</Linkify> : undefined
      }
      value={value}
      onChange={onChange}
      required={required}
      error={error}
      displayTextCount
      textSize={textSize}
      textCount={textCount}
    />
  );
};

export const TextArea: React.FC<Props> = (props) => {
  const hooks = useTextArea(props);

  return <TextAreaView {...hooks} {...props} />;
};
