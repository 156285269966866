import { useTranslation } from 'react-i18next';

import { Popover } from '../../../../Popover';

import type { LinkAttributes } from '../LinkBlock';
import type { Editor } from '@tiptap/react';

import style from './editlinkblock.module.css';

import { LinkIcon } from '@/views/Common/Icons';
import { EditLinkBody } from '@/views/InputField/RichEditorV2/EditLinkBody';
import { IconButton } from '@/views/InputField/RichEditorV2/IconButton';

export type Range = {
  from: number;
  to: number;
};

export type Props = {
  editor: Editor;
  inputLinkAttributes: LinkAttributes;
  onChangeText: (href: LinkAttributes['text']) => void;
  onChangeHref: (href: LinkAttributes['href']) => void;
  onChangeTargetBlank: (isTargetBlank: boolean) => void;
  onUpdateLink: () => void;
  onClearLink: () => void;
  textError: string | undefined;
  hrefError: string | undefined;
  isOpen: boolean;
  onToggleLinkSetting: (nextState?: boolean) => void;
};

export type ViewProps = {
  inputLinkAttributes: LinkAttributes;
  onChangeText: (text: LinkAttributes['text']) => void;
  onChangeHref: (href: LinkAttributes['href']) => void;
  onChangeTargetBlank: (isTargetBlank: boolean) => void;
  onUpdateLink: () => void;
  onClearLink: () => void;
  hrefError: string | undefined;
  textError: string | undefined;
  isOpen: boolean;
  onToggleLinkSetting: (nextState?: boolean) => void;
};

export const EditLinkBlockView: React.FC<ViewProps> = ({
  inputLinkAttributes,
  onChangeText,
  onChangeHref,
  onChangeTargetBlank,
  onUpdateLink,
  onClearLink,
  hrefError,
  textError,
  isOpen,
  onToggleLinkSetting,
}) => {
  const { t } = useTranslation('richEditorV2');
  return (
    <Popover
      trigger={
        <IconButton
          icon={<LinkIcon className={style.icon} />}
          aria-label={t('Link')}
          isActive={true}
        />
      }
      body={
        <EditLinkBody
          inputLinkAttributes={inputLinkAttributes}
          onChangeText={onChangeText}
          onChangeHref={onChangeHref}
          onChangeTargetBlank={onChangeTargetBlank}
          onUpdateLink={onUpdateLink}
          onClearLink={onClearLink}
          hrefError={hrefError}
          textError={textError}
        />
      }
      open={isOpen}
      onOpenChange={onToggleLinkSetting}
    />
  );
};

export const EditLinkBlock: React.FC<Props> = ({
  inputLinkAttributes,
  onChangeText,
  onChangeHref,
  onChangeTargetBlank,
  onUpdateLink,
  onClearLink,
  textError,
  hrefError,
  isOpen,
  onToggleLinkSetting,
}) => {
  return (
    <EditLinkBlockView
      inputLinkAttributes={inputLinkAttributes}
      onChangeText={onChangeText}
      onChangeHref={onChangeHref}
      onChangeTargetBlank={onChangeTargetBlank}
      onUpdateLink={onUpdateLink}
      onClearLink={onClearLink}
      hrefError={hrefError}
      textError={textError}
      isOpen={isOpen}
      onToggleLinkSetting={onToggleLinkSetting}
    />
  );
};
