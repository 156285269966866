type Meta = {
  value: {
    environment: string;
    fileHost: string;
    imageHost: string;
    isProduction: boolean;
    isEnterprise?: boolean;
  };
  user: {
    username?: string;
  };
};

export const MetaContext: Meta = {
  value: {
    environment: 'production',
    fileHost: 'files.microcms-assets.io',
    imageHost: 'images.microcms-assets.io',
    isProduction: true,
  }, //default value
  user: {
    username: '',
  },
};
