import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuditLog } from '@/hooks/AuditLog/useAuditLog';
import { useGetMyService } from '@/hooks/useService';

import Member from '../../Member';
import Pagination from '../../ui/Pagination';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../ui/Table';

import styles from './auditLogTables.module.css';

import { convertToEvent } from '@/util';
import { formatDateToMinutes, formatDateToSeconds } from '@/util/date';

type Props = {
  items: string[];
};

function formatOptionalDate(date?: string) {
  if (!date) {
    return '';
  }

  return formatDateToMinutes(date);
}

const AuditLogTables: React.FC<Props> = ({ items }) => {
  const { t } = useTranslation('serviceAuditLogTables');
  const { service } = useGetMyService();
  const [options, setOptions] = useState({
    limit: 25,
    offset: 0,
  });

  // 監査ログを取得
  const { auditLogList, isLoading } = useAuditLog(
    service && service.partitionKey,
    options.limit,
    options.offset,
  );

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <img src="/images/icon_loading.svg" alt="" />
      </div>
    );
  }

  // 監査ログのデータがない場合に表示
  if (auditLogList === undefined) {
    return <div className={styles.notFound}>{t('No data in audit log')}</div>;
  }

  return (
    <div className={styles.main}>
      <Pagination
        offset={options.offset}
        totalCount={auditLogList ? auditLogList.totalCount : 0}
        pageLength={options.limit}
        // @ts-expect-error
        setOptions={setOptions}
      />
      <div className={styles.tableWrap}>
        <Table>
          <Thead>
            <Tr>
              {items.includes('createdAt') && <Th>{t('Date')}</Th>}
              {items.includes('sourceIp') && <Th>{t('IP Address')}</Th>}
              {items.includes('user') && <Th>{t('User')}</Th>}
              {items.includes('event') && (
                <Th isLong={true}>{t('Activity')}</Th>
              )}
              {items.includes('apiKeyId') && <Th>{t('Target API Key ID')}</Th>}
              {items.includes('apiKeyName') && (
                <Th>{t('Target API Key Name')}</Th>
              )}
              {items.includes('apiId') && (
                <Th isLong={true}>{t('Target API ID')}</Th>
              )}
              {items.includes('apiName') && (
                <Th isLong={true}>{t('Target API Name')}</Th>
              )}
              {items.includes('contentId') && (
                <Th isLong={true}>{t('Target Content ID')}</Th>
              )}
              {items.includes('userDefinedContentId') && (
                <Th isLong={true}>{t('Target Content ID (User defined)')}</Th>
              )}
              {items.includes('mediumId') && (
                <Th isLong={true}>{t('Target Media ID')}</Th>
              )}
              {items.includes('mediumName') && (
                <Th>{t('Target Media Name')}</Th>
              )}
              {items.includes('mediumTagId') && (
                <Th isLong={true}>{t('Target Media Tag ID')}</Th>
              )}
              {items.includes('mediumTagName') && (
                <Th>{t('Target Media Tag Name')}</Th>
              )}
              {items.includes('memberId') && <Th>{t('Target Member')}</Th>}
              {items.includes('roleId') && <Th>{t('Target Role ID')}</Th>}
              {items.includes('roleName') && <Th>{t('Target Role Name')}</Th>}
              {items.includes('customFieldId') && (
                <Th>{t('Target Custom Field Name')}</Th>
              )}
              {items.includes('customStatusId') && (
                <Th>{t('Target Custom Status ID')}</Th>
              )}
              {items.includes('customStatusName') && (
                <Th>{t('Target Custom Status Name')}</Th>
              )}
              {items.includes('reviewerId') && <Th>{t('Target Reviewer')}</Th>}
              {items.includes('reviewRequestId') && (
                <Th>{t('Target Review Application ID')}</Th>
              )}
              {items.includes('reviewRequestTitle') && (
                <Th isLong={true}>{t('Target Review Application Title')}</Th>
              )}
              {items.includes('environmentServiceId') && (
                <Th>{t('Target Environment Service ID')}</Th>
              )}
              {items.includes('environmentName') && (
                <Th>{t('Target Environment Name')}</Th>
              )}
              {items.includes('environmentSubdomain') && (
                <Th>{t('Target Environment Domain')}</Th>
              )}
              {items.includes('createdBy') && <Th>{t('New Creator')}</Th>}
              {items.includes('email') && <Th>{t('Email')}</Th>}
              {items.includes('reservationStartTime') && (
                <Th>{t('Scheduled Starting Time')}</Th>
              )}
              {items.includes('reservationStopTime') && (
                <Th>{t('Scheduled Ending Time')}</Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {auditLogList !== undefined &&
              auditLogList.auditLogList
                // @ts-expect-error
                .filter((v) => v)
                // @ts-expect-error
                .map((auditLogList) => (
                  <Tr
                    key={auditLogList.logId}
                    isSlim={true}
                    enableHoverStyle={false}
                  >
                    {items.includes('createdAt') && (
                      <Td className={styles.date}>
                        {formatDateToSeconds(auditLogList.createdAt)}
                      </Td>
                    )}
                    {items.includes('sourceIp') && (
                      <Td>{auditLogList.sourceIp}</Td>
                    )}
                    {items.includes('user') && (
                      <Td>
                        <Member
                          username={auditLogList.userId}
                          theme="black"
                          nameOnly={true}
                        />
                      </Td>
                    )}
                    {items.includes('event') && (
                      <Td>{convertToEvent(auditLogList.event)}</Td>
                    )}
                    {items.includes('apiKeyId') && (
                      <Td>{auditLogList.apiKeyId}</Td>
                    )}
                    {items.includes('apiKeyName') && (
                      <Td>{auditLogList.apiKeyName}</Td>
                    )}
                    {items.includes('apiId') && <Td>{auditLogList.apiId}</Td>}
                    {items.includes('apiName') && (
                      <Td>{auditLogList.apiName}</Td>
                    )}
                    {items.includes('contentId') && (
                      <Td>
                        {auditLogList.contentIds
                          ? auditLogList.contentIds.join('\n')
                          : auditLogList.contentId}
                      </Td>
                    )}
                    {items.includes('userDefinedContentId') && (
                      <Td>
                        {auditLogList.userDefinedContentIds
                          ? auditLogList.userDefinedContentIds.join('\n')
                          : auditLogList.userDefinedContentId}
                      </Td>
                    )}
                    {items.includes('mediumId') && (
                      <Td>{auditLogList.mediumId}</Td>
                    )}
                    {items.includes('mediumName') && (
                      <Td>{auditLogList.mediumName}</Td>
                    )}
                    {items.includes('mediumTagId') && (
                      <Td>
                        {auditLogList.mediumTagIds
                          ? auditLogList.mediumTagIds.join('\n')
                          : auditLogList.mediumTagId}
                      </Td>
                    )}
                    {items.includes('mediumTagName') && (
                      <Td>{auditLogList.mediumTagName}</Td>
                    )}
                    {items.includes('memberId') && (
                      <Td>
                        <Member
                          username={auditLogList.memberId}
                          theme="black"
                          nameOnly={true}
                        />
                      </Td>
                    )}
                    {items.includes('roleId') && (
                      <Td>
                        {auditLogList.roleIds
                          ? auditLogList.roleIds.join('\n')
                          : auditLogList.roleId}
                      </Td>
                    )}
                    {items.includes('roleName') && (
                      <Td>
                        {auditLogList.roleNames
                          ? auditLogList.roleNames.join('\n')
                          : auditLogList.roleName}
                      </Td>
                    )}
                    {items.includes('customFieldId') && (
                      <Td>{auditLogList.customFieldId}</Td>
                    )}
                    {items.includes('customStatusId') && (
                      <Td>{auditLogList.customStatusId}</Td>
                    )}
                    {items.includes('customStatusName') && (
                      <Td>{auditLogList.customStatusName}</Td>
                    )}
                    {items.includes('reviewerId') && (
                      <Td>
                        <Member
                          username={auditLogList.reviewerId}
                          theme="black"
                          nameOnly={true}
                        />
                      </Td>
                    )}
                    {items.includes('reviewRequestId') && (
                      <Td>{auditLogList.reviewRequestId}</Td>
                    )}
                    {items.includes('reviewRequestTitle') && (
                      <Td>{auditLogList.reviewRequestTitle}</Td>
                    )}
                    {items.includes('environmentServiceId') && (
                      <Td>{auditLogList.environmentServiceId}</Td>
                    )}
                    {items.includes('environmentName') && (
                      <Td>{auditLogList.environmentName}</Td>
                    )}
                    {items.includes('environmentSubdomain') && (
                      <Td>{auditLogList.environmentSubdomain}</Td>
                    )}
                    {items.includes('createdBy') && (
                      <Td>
                        <Member
                          username={auditLogList.createdBy}
                          theme="black"
                          nameOnly={true}
                        />
                      </Td>
                    )}
                    {items.includes('email') && <Td>{auditLogList.email}</Td>}
                    {items.includes('reservationStartTime') && (
                      <Td>
                        {formatOptionalDate(auditLogList.reservationStartTime)}
                      </Td>
                    )}
                    {items.includes('reservationStopTime') && (
                      <Td>
                        {formatOptionalDate(auditLogList.reservationStopTime)}
                      </Td>
                    )}
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </div>
      <div className={styles.bottomPagination}>
        <Pagination
          offset={options.offset}
          totalCount={auditLogList ? auditLogList.totalCount : 0}
          pageLength={options.limit}
          // @ts-expect-error
          setOptions={setOptions}
        />
      </div>
    </div>
  );
};

export default AuditLogTables;
