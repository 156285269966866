import React from 'react';

import styles from './sortButton.module.css';

/**
 * @deprecated @/components/Form/SortButton/SortButtonTs.tsx を使用してください。
 */
function SortButton({ children, name, orders, onClick, disabled = false }) {
  const order =
    orders === name ? 'asc' : orders === `-${name}` ? 'desc' : undefined;
  return (
    <button
      className={order ? `${styles.button} ${styles[order]}` : styles.button}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={
            order === 'asc' ? `${styles.asc} ${styles.on}` : styles.asc
          }
          d="M8 5H0L4 0L8 5Z"
        />
        <path
          className={
            order === 'desc' ? `${styles.desc} ${styles.on}` : styles.desc
          }
          d="M8 9H0L4 14L8 9Z"
        />
      </svg>
    </button>
  );
}

export default SortButton;
