import cx from 'classnames';

import { Checkbox } from '../Checkbox/Checkbox';
import { Fieldset } from '../Fieldset';

import type { CheckboxProps } from '../Checkbox';
import type { FieldsetProps } from '../Fieldset';

import style from './checkboxgroup.module.css';

export type Props = ViewProps;

export type ViewProps = {
  items: CheckboxProps[];
  scrollable?: boolean;
} & FieldsetProps;

export const CheckboxGroupView: React.FC<ViewProps> = ({
  items,
  scrollable = false,
  ...fieldsetProps
}) => {
  return (
    <Fieldset {...fieldsetProps}>
      <div className={cx(style.items, scrollable && style.scrollable)}>
        {items.map((item, index) => (
          // チェックボックスは順番が入れ替わらない想定なのでindexをkeyにしている
          <Checkbox key={index} {...item} />
        ))}
      </div>
    </Fieldset>
  );
};

export const CheckboxGroup: React.FC<Props> = (props) => {
  return <CheckboxGroupView {...props} />;
};
