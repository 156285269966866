import { useMemo } from 'react';

import { useContentHistories } from '@/components/ContentHistories/useContentHistories';

import { useCustomFields } from '@/hooks/CustomField/useCustomFieldReader';

import type { CustomField } from '@/types/contents';
import type { Props } from './ContentsCompare';

import { parseHistoryVersion } from '@/util/history-version';
import { useContentsByVersions } from '@/views/Common/content/useContentReader';

const useContentsCompare = ({ api, contentId, currentStatus }: Props) => {
  // 全てのカスタムフィールドの取得
  const [customFields] = useCustomFields(api?.partitionKey);

  // 最新のバージョンを取得
  const { data: contentHistories, isFetching } = useContentHistories(contentId);
  const latestVersion = contentHistories?.pages?.[0]?.items?.[0]
    ? parseHistoryVersion(contentHistories.pages[0].items[0].sortKey) + 1
    : 1;

  /**
   * コンテンツ比較のためのデータ取得のためのクエリキーを生成
   * 現時点では、下書き中→公開中、公開中かつ下書き中→公開中のレビューしかないが、公開中→公開終了も増える予定
   */
  const queryKeys = useMemo(() => {
    if (!contentHistories || !currentStatus) {
      return [null, null];
    }

    if (currentStatus === 'DRAFT') {
      return [null, { version: latestVersion }];
    }
    if (currentStatus === 'PUBLISH_AND_DRAFT') {
      return [
        { version: latestVersion, denyDraft: true },
        { version: latestVersion },
      ];
    }
    // TODO: 公開中→公開終了のレビューが追加された場合の処理を追加する
    return [null, null];
  }, [contentHistories, currentStatus, latestVersion]);

  const [{ data: sinceContent }, { data: untilContent }] =
    useContentsByVersions(contentId, queryKeys);

  const isDataLoading = !api || !customFields || isFetching;
  const readyComparisonContent =
    (currentStatus === 'DRAFT' && untilContent) ||
    (currentStatus === 'PUBLISH_AND_DRAFT' && sinceContent && untilContent);

  const isReady = !isDataLoading && !!readyComparisonContent;

  if (!isReady) {
    return { isReady };
  }

  return {
    isReady,
    apiFields: api.apiFields,
    customFields,
    sinceContent: sinceContent?.value ?? {},
    untilContent: untilContent?.value ?? {},
    unauthorizedFieldIds: Array.from(
      new Set(
        (sinceContent?.unauthorizedFieldIds ?? []).concat(
          untilContent?.unauthorizedFieldIds ?? [],
        ),
      ),
    ),
  };
};

export { useContentsCompare };
