import * as Popover from '@radix-ui/react-popover';
import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import type { MigrateContent } from '@/usecase/contentUsecase';

import Member from '@/components/Member';

import styles from './selectVersions.module.css';

import { formatDateToMinutes } from '@/util/date';
import { parseHistoryVersion } from '@/util/history-version';
import { useAllContentHistories } from '@/views/Common/content/useContentReader';
import { Combobox } from '@/views/Common/Ui/Combobox';
import { Icon } from '@/views/Common/Ui/Icon';
import { Loading } from '@/views/Common/Ui/Loading';
import { useToggle } from '@/views/Common/Ui/useToggle';

// この関数は、pathのsinceまたはuntilのバージョンをversionに置き換える
const replaceVersion = (
  path: string,
  version: string,
  type: 'since' | 'until',
) => {
  // /apis/${apiEndpoint}/${contentId}/compare/${sinceVersion}...${untilVersion}
  // $1: /apis/${apiEndpoint}/${contentId}/compare/
  // $2: sinceVersion
  // $3: untilVersion
  const pattern = /(.+\/compare\/)(.+)\.\.\.(.+)/;
  const replacedPath = path.replace(
    pattern,
    `$1${type === 'since' ? version : '$2'}...${
      type === 'until' ? version : '$3'
    }`,
  );
  return replacedPath;
};

type Props = {
  content: MigrateContent;
  sinceVersion: number;
  untilVersion: number;
  notFound: () => null;
};

const SelectVersions: React.FC<Props> = ({
  content,
  sinceVersion,
  untilVersion,
  notFound,
}) => {
  const { t } = useTranslation('apisContentCompare');

  const { allContentHistories, isLoading } = useAllContentHistories(
    content.partitionKey,
  );

  const selectList: RowProps[] | null = useMemo(() => {
    if (isLoading || !allContentHistories) {
      return null;
    }

    const latestVersion = allContentHistories[0]
      ? parseHistoryVersion(allContentHistories[0].sortKey) + 1
      : 1;

    const list: RowProps[] = [
      // コンテンツ履歴にはまだ存在しない、最新のデータを入れておく
      {
        key: latestVersion.toString(),
        version: latestVersion,
        editorId: content.editorId,
        updatedAt: formatDateToMinutes(content.updatedAt),
      },
    ];

    for (const content of allContentHistories) {
      const version = parseHistoryVersion(content.sortKey);
      list.push({
        key: version.toString(),
        version,
        editorId: content.editorId,
        updatedAt: formatDateToMinutes(content.updatedAt),
      });
    }

    return list;
  }, [allContentHistories, content.editorId, content.updatedAt, isLoading]);

  const filterRule = useCallback((item: RowProps, inputValue: string) => {
    if (inputValue.startsWith('v')) {
      return item.version.toString().startsWith(inputValue.slice(1));
    }
    return item.version.toString().includes(inputValue);
  }, []);

  const equalRule = useCallback((item: RowProps, inputValue: string) => {
    if (inputValue.startsWith('v')) {
      return item.version.toString() === inputValue.slice(1);
    }
    return item.version.toString() === inputValue;
  }, []);

  const history = useHistory();
  const handleVersionChange = useCallback(
    (value: string, type: 'since' | 'until') => {
      const replacedPath = replaceVersion(
        history.location.pathname,
        value,
        type,
      );
      history.push(replacedPath);
    },
    [history],
  );

  if (selectList === null) {
    return <Loading className={styles.loading} />;
  }

  const sinceVersionItem = selectList.find(
    (item) => item.version === sinceVersion,
  );
  const untilVersionItem = selectList.find(
    (item) => item.version === untilVersion,
  );

  if (!sinceVersionItem || !untilVersionItem) {
    return notFound();
  }

  return (
    <div className={styles.main}>
      {/* Transコンポーネントは型の再起制限に引っかかるのでts-ignoreをしている */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore-next-line */}
      <Trans
        t={t}
        i18nKey="Display the difference between <since></since> and <until></until>."
        components={{
          since: (
            <SelectVersion
              type="since"
              selectList={selectList}
              filterRule={filterRule}
              equalRule={equalRule}
              versionItem={sinceVersionItem}
              handleVersionChange={handleVersionChange}
            />
          ),
          until: (
            <SelectVersion
              type="until"
              selectList={selectList}
              filterRule={filterRule}
              equalRule={equalRule}
              versionItem={untilVersionItem}
              handleVersionChange={handleVersionChange}
            />
          ),
        }}
      />
    </div>
  );
};

type RowProps = {
  key: string;
  version: number;
  editorId: string;
  updatedAt: string;
};

type SelectVersionProps = {
  type: 'since' | 'until';
  selectList: RowProps[] | null;
  filterRule: (item: RowProps, inputValue: string) => boolean;
  equalRule: (item: RowProps, key: string) => boolean;
  versionItem: RowProps;
  handleVersionChange: (value: string, type: 'since' | 'until') => void;
};

const SelectVersion: React.FC<SelectVersionProps> = ({
  type,
  selectList,
  filterRule,
  equalRule,
  versionItem,
  handleVersionChange,
}) => {
  const { t } = useTranslation('apisContentCompare');

  const [isOpen, toggle] = useToggle(false);

  const onConfirm = useCallback(
    (item: RowProps) => {
      handleVersionChange(item.version.toString(), type);
      toggle(false);
    },
    [handleVersionChange, toggle, type],
  );

  return (
    <Popover.Root open={isOpen} onOpenChange={toggle}>
      <Popover.Trigger
        title={
          type === 'since'
            ? t('Change the starting version of the difference')
            : t('Change the ending version of the difference')
        }
        className={styles.trigger}
      >
        <VersionRow item={versionItem} />
        <Icon name="expand_more" />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side="bottom"
          sideOffset={8}
          avoidCollisions={false}
          style={{ zIndex: 100, pointerEvents: 'auto' }}
          hideWhenDetached
          className={styles.popover}
        >
          <Combobox
            list={selectList}
            currentValueKeys={[versionItem.key]}
            filterRule={filterRule}
            equalRule={equalRule}
            ItemComponent={VersionRow}
            onConfirm={onConfirm}
            onEscapeKeyDown={() => toggle(false)}
            inputAriaLabel={t('Find content history')}
            listAriaLabel={t('Content history')}
            className={styles.combobox}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

type VersionRowProps = {
  item: RowProps;
};

const VersionRow: React.FC<VersionRowProps> = ({ item }) => {
  const { t } = useTranslation('apisContentCompare');

  return (
    <div className={styles.item}>
      <span className={styles.version}>v{item.version}</span>
      <div className={styles.member}>
        <Member username={item.editorId} theme="black" />
      </div>
      <span className={styles.updatedAt}>
        {item.updatedAt} {t('Updated')}
      </span>
    </div>
  );
};

export { SelectVersions };
