import { useEffect } from 'react';

const ReloadPrompt = ({ showPrompt = () => false }) => {
  useEffect(() => {
    const onBeforeunload = (event: BeforeUnloadEvent) => {
      if (showPrompt()) {
        event.preventDefault();
        event.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onBeforeunload, false);
    return () => {
      window.removeEventListener('beforeunload', onBeforeunload, false);
    };
  }, [showPrompt]);
  return <></>;
};

export default ReloadPrompt;
