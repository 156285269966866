import { apiListSelectors } from '@/ducks/apiList';
import type { ApiList, MigrateApi } from '@/entity/api';
import { useInput } from '@/hooks';
import useCustomFieldWriter from '@/hooks/CustomField/useCustomFieldWriter';
import { useGetMyService } from '@/hooks/useService';
import { useAppSelector } from '@/store/hooks';
import { nanoid } from 'nanoid';
import type React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import Feedback from '../Feedback';
import Head from '../Head';
import {
  validateApiFieldId,
  validateApiFieldName,
  validateApiFields,
} from '../Validations';
import InputBasicInfo from './InputBasicInfo';
import InputLayout from './InputLayout';
import InputModel from './InputModel';

const CreateField: React.FC = () => {
  const { t } = useTranslation('createField');
  const { service } = useGetMyService();

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint } = useParams<{ endpoint: string }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );

  const [page, setPage] = useState(1);
  // @ts-expect-error
  const nameInput = useInput(undefined, validateApiFieldName);
  // @ts-expect-error
  const idInput = useInput(undefined, validateApiFieldId);
  const fieldsInput = useInput(
    [{ idValue: nanoid(10), isAdditionalField: true }],
    validateApiFields,
  );
  const [layout, setLayout] = useState<any[]>();

  const goNext = useCallback(() => setPage(page + 1), [page, setPage]);
  const goPrevious = useCallback(() => setPage(page - 1), [page, setPage]);

  const { result, error, loading, create } = useCustomFieldWriter(api);
  const createField = useCallback(() => {
    create(idInput[0], nameInput[0], fieldsInput[0], layout);
  }, [idInput, nameInput, fieldsInput, layout, create]);

  if (result && loading === false) {
    return <Redirect to={`/apis/${endpoint}/custom-fields`} />;
  }

  if (!service) return null;

  return (
    <>
      <Head title={t('Custom Field / Create')} />
      {page === 1 && (
        <InputBasicInfo
          service={service}
          api={api}
          goNext={goNext}
          // @ts-expect-error
          nameInput={nameInput}
          // @ts-expect-error
          idInput={idInput}
        />
      )}
      {page === 2 && (
        <InputModel
          api={api}
          goNext={goNext}
          goPrevious={goPrevious}
          // @ts-expect-error
          fieldsInput={fieldsInput}
        />
      )}
      {page === 3 && (
        <InputLayout
          goPrevious={goPrevious}
          setLayout={setLayout}
          fields={fieldsInput[0]}
          createField={createField}
          loading={loading}
        />
      )}
      <Feedback
        type="failure"
        message={error ? t('Could not be created.') : undefined}
      />
    </>
  );
};

export default CreateField;
