import { useMemo } from 'react';

import { getRolePermissionReadIsomorphic } from '../../helpers/rolePermissionIsomorphic';
import { useI18nFormRole } from '../../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../../provider';

import { Select } from '@/views/Common/Roles/Select';

const Read = () => {
  const { t } = useI18nFormRole();

  const {
    isAdminRole,
    input: {
      rolePermission: { read, readLevel },
    },
  } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;
  const roleRead = useMemo(
    () => getRolePermissionReadIsomorphic({ readLevel, read }),
    [read, readLevel],
  );

  return (
    <Select
      name="rolePermission.readLevel"
      value={roleRead}
      onValueChange={(value) =>
        dispatch({
          type: 'set-role-permission-read',
          payload: value,
        })
      }
      disabled={disabled}
      items={[
        {
          value: 'ALL',
          label: t('All roles'),
          description: t('Access to Manage roles and its detail pages.'),
        },
        {
          value: 'SAME_ROLE',
          label: t('Only roles to which you belong'),
          description: t(
            'In the Roles List, only roles to which you belong are displayed.',
          ),
        },
        {
          value: 'NONE',
          label: t('None'),
          description: t('Roles cannot be displayed.'),
        },
      ]}
    />
  );
};

export default Read;
