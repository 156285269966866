import type { MigrateApi } from '@/entity/api';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import Button from '../ui/Button';
import styles from './importStatus.module.css';
import useImportStatus from './useImportStatus';

type Props = {
  api: MigrateApi;
  importResult?: any;
  resetImportProgress: any;
};

const ImportStatus: React.FC<Props> = ({
  api,
  importResult,
  resetImportProgress,
}) => {
  const { t } = useTranslation('importStatus');
  const [progress, isOpen, close] = useImportStatus(
    api,
    importResult,
    resetImportProgress,
  );

  return (
    <div>
      {progress.status === 'RUNNING' && (
        <span className={`${styles.status} ${styles.RUNNING}`}>
          <i className="material-icons">cached</i>
          {t('Importing')}
        </span>
      )}
      <Modal isOpen={isOpen} close={close}>
        <div className={`${styles.modal} ${styles[progress.status]}`}>
          <div className={styles.icon}>
            {progress.status === 'COMPLETE' && (
              <img src="/images/icon_success.svg" alt="" />
            )}
            {progress.status === 'ERROR' && (
              <img src="/images/icon_error.svg" alt="" />
            )}
          </div>
          <h3 className={styles.title}>
            {progress.status === 'COMPLETE' && t('Import completed')}
            {progress.status === 'ERROR' && t('Import failed')}
          </h3>
          {progress.message && (
            <p className={styles.description}>{progress.message}</p>
          )}
          <Button
            type="secondary"
            size="small"
            className={styles.button}
            value={t('Close')}
            onClick={close}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ImportStatus;
