import Loading from '@/components/ui/Loading';
import { apiListSelectors } from '@/ducks/apiList';
import type { ApiList, MigrateApi } from '@/entity/api';
import { useLimitCustomFields } from '@/hooks/FieldLimit/useLimitApiField';
import { useGetMyService } from '@/hooks/useService';
import { useAppSelector } from '@/store/hooks';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useParams } from 'react-router-dom';
import { useInput } from '../../../hooks';
import { useCustomFields } from '../../../hooks/CustomField/useCustomFieldReader';
import useCustomFieldWriter from '../../../hooks/CustomField/useCustomFieldWriter';
import { useExceptionPermissionIsHaveLeastOne } from '../../../hooks/Role/useMyRoles';
import { useHasDiff } from '../../../hooks/useHasDiff';
import type { Field } from '../../../types/field';
import ApiModel from '../../ApiModel';
import Feedback from '../../Feedback';
import Head from '../../Head';
import ReloadPrompt from '../../ReloadPrompt';
import { validateApiFields } from '../../Validations';
import Button from '../../ui/Button';
import Notification from '../../ui/Notification';
import styles from './modelSettings.module.css';

const ModelSettings: React.FC = () => {
  const { t } = useTranslation('customFieldsModelSettings');
  const { service, parentService } = useGetMyService();

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint, fieldId } = useParams<{
    endpoint: string;
    fieldId: string;
  }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );

  //権限を取得
  const [hasPermission] = useExceptionPermissionIsHaveLeastOne(
    api.partitionKey,
    'apiUpdate',
  );

  const [customFields = []] = useCustomFields(api.partitionKey);
  const [field, setField] = useState();
  const { result, error, loading, update } = useCustomFieldWriter(api);

  useEffect(() => {
    // @ts-expect-error
    setField(customFields.find((field) => field.fieldId === fieldId));
  }, [customFields, fieldId]);

  useEffect(() => {
    result && setField(result);
  }, [result]);

  const [fields, , fieldsError, setFields] = useInput(
    null,
    validateApiFields, // 既存カスタムフィールドの中で publishedAt は全く設定されていなかったので入力を許可しない
  );

  // @ts-expect-error
  const { hasDiff } = useHasDiff(field?.fields, fields);

  useEffect(() => {
    if (field !== undefined) {
      // @ts-expect-error
      setFields(field.fields);
    }
  }, [field, setFields]);

  const submit = useCallback(() => {
    // @ts-expect-error
    const flatten = field.position.flat();
    // @ts-expect-error
    const newPosition = [...field.position];
    for (const f of fields) {
      if (!flatten.includes(f.idValue)) {
        // 追加した分だけレイアウト側にも追加する
        newPosition[0].push(f.idValue);
      }
    }
    const confirm = window.confirm(
      t(
        'If you are already using the API, this may cause problems. Are you sure you want to change it?',
      ),
    );
    if (confirm) {
      // @ts-expect-error
      update(field, field.fieldId, field.name, fields, field.position);
    }
  }, [field, fields, t, update]);

  const swap = useCallback(
    (x: any, y: any) => {
      if (x === y) {
        return;
      }
      const copy = [...fields];
      copy.splice(y, 0, fields[x]);
      if (x < y) {
        copy.splice(x, 1);
      } else {
        copy.splice(x + 1, 1);
      }
      setFields(copy);
    },
    [fields, setFields],
  );

  const setFieldAt = useCallback(
    (f: any, index: any) => {
      // @ts-expect-error
      setFields((prev) => {
        const next = [...prev];
        next[index] = f(prev[index]);
        return next;
      });
    },
    [setFields],
  );

  // ANKEN-1273 カスタムフィールド数上限値の設定
  const errorAddCustomField = useLimitCustomFields(
    fields,
    service,
    parentService,
  );

  if (field === undefined) {
    return <Loading />;
  }

  return (
    <div className={styles.wrapper}>
      <Head title={t('Custom Fields/Schema')} />
      <h2 className={styles.title}>{t('Schema')}</h2>
      <Prompt
        message={() => {
          return hasDiff
            ? t(
                'Changes in the API schema settings will be lost. Are you sure you want to leave from this page?',
              )
            : true;
        }}
      />
      <ReloadPrompt
        showPrompt={() => {
          return hasDiff;
        }}
      />
      {hasDiff && (
        <Notification
          text={t('Some changes were not saved.')}
          status="warning"
        />
      )}
      <div className={styles.lists}>
        {fields &&
          fields.map((targetField: Field, i: number) => {
            return (
              <div key={i} className={styles.list}>
                <ApiModel
                  api={api}
                  useInCustom={true}
                  type={api.apiType}
                  field={targetField}
                  allFields={fields}
                  setFieldAt={setFieldAt}
                  index={i}
                  swap={swap}
                  deleteField={(index) =>
                    // @ts-expect-error
                    setFields(fields.filter((field, j) => j !== index))
                  }
                  readOnly={!hasPermission}
                  isEditApi={true}
                />
              </div>
            );
          })}
        <div className={styles.addBtn}>
          <Button
            type="tertiary"
            icon="add"
            value={t('Add field')}
            size="full"
            onClick={() =>
              setFields([
                ...fields,
                { idValue: nanoid(10), isAdditionalField: true },
              ])
            }
            disabled={!hasPermission || errorAddCustomField}
          />
          {errorAddCustomField && (
            <p className={styles.errorText}>
              {t('The maximum number of fields has been reached.')}
            </p>
          )}
        </div>
      </div>
      {hasPermission && (
        <div className={styles.actions}>
          <Feedback
            type="success"
            message={result ? t('Changed') : undefined}
          />
          <Feedback
            type="failure"
            message={error ? t('Failed to make changes.') : undefined}
          />
          <Button
            type="primary"
            value={t('Save changes')}
            onClick={submit}
            disabled={!hasDiff || loading || fieldsError}
          />
        </div>
      )}
    </div>
  );
};

export default ModelSettings;
