import * as Toolbar from '@radix-ui/react-toolbar';
import { EditorContent } from '@tiptap/react';
import cx from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FloatingMenu } from './FloatingMenu';
import { Block } from './toolbars/Block';
import { CustomClass } from './toolbars/CustomClass';
import { Decoration } from './toolbars/Decoration';
import { Embed } from './toolbars/Embed';
import { FullScreen } from './toolbars/FullScreen';
import { Heading } from './toolbars/Heading';
import { List } from './toolbars/List';
import { Operation } from './toolbars/Operation';
import { useRichEditorV2 } from './useRichEditorV2';

import type { RichEditorV2Options } from '@/constants/richEditorOptions';
import type {
  CustomClass as CustomClassType,
  RichEditorV2Color,
} from '@/types/field';
import type { JSONContent } from '@tiptap/react';
import type { Editor } from '@tiptap/react';

import styles from './richeditorv2.module.css';

import { useContentMetaHight } from '@/views/apis/content/useContentMetaHight';

export type Props = {
  onChange: (value?: JSONContent) => void;
  defaultValue: JSONContent;
  forceUpdatedValue?: JSONContent;
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
  customClassList: CustomClassType[];
  colorList: RichEditorV2Color[];
  editable?: boolean;
};

export type ViewProps = {
  progress: {
    loaded: number;
    total: number;
  } | null;
  editor: Editor;
  richEditorV2Options: RichEditorV2Options[] | Readonly<RichEditorV2Options[]>;
  customClassList: CustomClassType[];
  colorList: RichEditorV2Color[];
  hasHeadingOption: boolean;
  hasDecorationOption: boolean;
  hasListOption: boolean;
  hasBlockOption: boolean;
  hasEmbedOption: boolean;
  editable?: boolean;
  isFullScreen: boolean;
  handleFullScreen: () => void;
  handleCloseFullScreen: () => void;
};

export const RichEditorV2View: React.FC<ViewProps> = ({
  progress,
  editor,
  richEditorV2Options,
  customClassList,
  colorList,
  hasHeadingOption,
  hasDecorationOption,
  hasListOption,
  hasBlockOption,
  hasEmbedOption,
  isFullScreen,
  handleFullScreen,
  handleCloseFullScreen,
}) => {
  const { t } = useTranslation('richEditorV2');

  const stickyTop = useContentMetaHight();

  return (
    <>
      <div
        className={cx(styles.wrapper, {
          [styles.isFullScreen]: isFullScreen,
        })}
        id="wrapper"
      >
        <div
          className={styles.sticky}
          id="sticky"
          style={{ top: isFullScreen ? 0 : stickyTop }}
        >
          <Toolbar.Root className={styles.toolbar}>
            {hasHeadingOption && (
              <>
                <Heading
                  editor={editor}
                  richEditorV2Options={richEditorV2Options}
                />
                <Toolbar.Separator className={styles.separator} />
              </>
            )}
            {hasDecorationOption && (
              <>
                <Decoration
                  editor={editor}
                  richEditorV2Options={richEditorV2Options}
                  colorList={colorList}
                />
                <Toolbar.Separator className={styles.separator} />
              </>
            )}
            {hasBlockOption && (
              <>
                <Block
                  editor={editor}
                  richEditorV2Options={richEditorV2Options}
                />
                <Toolbar.Separator className={styles.separator} />
              </>
            )}
            {hasListOption && (
              <>
                <List
                  editor={editor}
                  richEditorV2Options={richEditorV2Options}
                />
                <Toolbar.Separator className={styles.separator} />
              </>
            )}
            {hasEmbedOption && (
              <>
                <Embed
                  editor={editor}
                  richEditorV2Options={richEditorV2Options}
                />
                <Toolbar.Separator className={styles.separator} />
              </>
            )}
            {richEditorV2Options.includes('customClass') && (
              <>
                <CustomClass
                  editor={editor}
                  customClassList={customClassList}
                />
                <Toolbar.Separator className={styles.separator} />
              </>
            )}
            <Operation editor={editor} />
            <div className={styles.rightMenu}>
              <FullScreen
                editor={editor}
                isFullScreen={isFullScreen}
                handleFullScreen={handleFullScreen}
                handleCloseFullScreen={handleCloseFullScreen}
              />
            </div>
          </Toolbar.Root>
        </div>
        <FloatingMenu
          editor={editor}
          key={editor.view.state.selection.$from.pos}
        />
        <div
          id="richEditor-v2-editorWrapper"
          className={cx(styles.editorWrapper, {
            [styles.isFullScreen]: isFullScreen,
          })}
        >
          {progress && (
            <div className={styles.uploading}>
              <progress
                value={progress.loaded}
                max={progress.total}
                className={styles.progress}
              />
              <p>{t('Uploading now...')}</p>
            </div>
          )}
          <EditorContent editor={editor} />
        </div>
      </div>
      <p className={styles.description}>
        {t(
          'Pressing Enter on the editor will bring up the next paragraph, and pressing Shift + Enter will insert a line break.',
        )}
      </p>
    </>
  );
};

export const RichEditorV2: React.FC<Props> = ({
  onChange,
  defaultValue,
  forceUpdatedValue,
  richEditorV2Options,
  customClassList,
  colorList,
  editable = true,
}) => {
  const {
    progress,
    editor,
    hasHeadingOption,
    hasDecorationOption,
    hasListOption,
    hasBlockOption,
    hasEmbedOption,
    isFullScreen,
    handleFullScreen,
    handleCloseFullScreen,
  } = useRichEditorV2({
    onChange,
    defaultValue,
    richEditorV2Options,
    customClassList,
    editable,
  });

  useEffect(() => {
    if (editor && forceUpdatedValue) {
      // Nodeにフォーカスが当たらないようにするため、setTextSelectionを実行している
      editor.chain().setContent(forceUpdatedValue).setTextSelection(0).run();
    }
  }, [editor, forceUpdatedValue]);

  if (!editor) {
    return null;
  }

  return (
    <RichEditorV2View
      progress={progress}
      editor={editor}
      richEditorV2Options={richEditorV2Options}
      customClassList={customClassList}
      colorList={colorList}
      hasHeadingOption={hasHeadingOption}
      hasDecorationOption={hasDecorationOption}
      hasListOption={hasListOption}
      hasBlockOption={hasBlockOption}
      hasEmbedOption={hasEmbedOption}
      isFullScreen={isFullScreen}
      handleFullScreen={handleFullScreen}
      handleCloseFullScreen={handleCloseFullScreen}
    />
  );
};
