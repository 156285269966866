import styles from './menu.module.css';

type Props = {
  title: string;
  children: React.ReactNode;
};

const Menu: React.FC<Props> = ({ title, children }) => {
  return (
    <div className={styles.wrapper}>
      <h3 className={styles.title}>{title}</h3>
      {children}
    </div>
  );
};

export default Menu;
