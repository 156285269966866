import { useI18nFormRole } from '../../../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../../../provider';

import type { FormRoleInputValue } from '../../../provider/stateContext';
import type { PropsWithECP } from '../../FieldExceptionContent/_types';
import type { FC } from 'react';

import { Select } from '@/views/Common/Roles/Select';

type Props = Partial<PropsWithECP>;

/**
 * コンテンツ権限項目 - READ権限の入力フィールド
 */
const FieldContentRead: FC<Props> = ({ targetECP }) => {
  const { t } = useI18nFormRole('$contentPermission.$read');

  const { isAdminRole, input } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  const value = getValueIsomorphic(input, targetECP);

  return (
    <Select
      name={'permission.contentReadLevel'}
      onValueChange={(value) => {
        if (targetECP) {
          dispatch({
            type: 'set-ec-permission-contentread',
            payload: {
              id: targetECP.id,
              nextValue: value,
            },
          });
        } else {
          dispatch({
            type: 'set-dc-permission-contentread',
            payload: value,
          });
        }
      }}
      value={value}
      disabled={disabled}
      items={[
        {
          value: 'ALL',
          label: t('All contents'),
          description: t('Able to view all content.'),
        },
        {
          value: 'SAME_ROLE',
          label: t('Only contents created by members of this role'),
          description: t(
            'Able to view only content created by members in the same role.',
          ),
        },
        {
          value: 'CREATED',
          label: t('Only contents created by yourself'),
          description: t('Able to view only content created by you.'),
        },
        {
          value: 'NONE',
          label: t('None'),
          description: t('Contents cannot be viewed.'),
        },
      ]}
    />
  );
};

function getValueIsomorphic(
  input: FormRoleInputValue,
  targetEcp: Props['targetECP'],
) {
  if (targetEcp) {
    return targetEcp.permission.contentReadLevel;
  }
  return input.defaultContentPermission.contentReadLevel;
}

export default FieldContentRead;
