import { useCallback } from 'react';

import type { Props } from './Italic';

export const useItalic = ({ editor }: Props) => {
  const toggleItalicHandler = useCallback(() => {
    editor.chain().focus().toggleItalic().run();
  }, [editor]);
  const isActiveItalic = editor.isActive('italic');

  return {
    toggleItalicHandler,
    isActiveItalic,
  };
};
