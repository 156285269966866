import { API, graphqlOperation, Auth } from 'aws-amplify';

import { updateSortOrderValue as updateSortOrderValueUsecase } from '@/usecase/apiUsecase';

import actions from './actions';
import * as queries from '../../graphql/queries';

export const loadApis = (service) => async (dispatch) => {
  if (service) {
    //API作成直後など、権限グループが変わっている可能性があるのでトークンを再取得
    const [user, session] = await Promise.all([
      Auth.currentAuthenticatedUser(),
      Auth.currentSession(),
    ]);
    user &&
      session &&
      session.refreshToken &&
      user.refreshSession(session.refreshToken, async () => {
        //API一覧を取得
        const apis = (
          await API.graphql(
            graphqlOperation(queries.findApis, {
              serviceId: service.partitionKey,
            }),
          )
        ).data.findApis;
        dispatch(actions.loadApiList({ service, apis }));
      });
  }
};

export const loadApi = (apiId) => async (dispatch) => {
  const result = await API.graphql(
    graphqlOperation(queries.findApi, { apiId }),
  );
  const api = result.data.findApi;
  return dispatch(actions.loadApi(api));
};

export const updateSortOrderValue =
  (service, api, sortOrderValue) => async (dispatch) => {
    try {
      await updateSortOrderValueUsecase({
        apiId: api.partitionKey,
        sortOrderValue: `SORT#${sortOrderValue}`,
      });
      dispatch(loadApis(service)); // 画面に反映させるためにもう一度GETしなおす
    } catch {
      // TODO: エラー処理
    }
  };
