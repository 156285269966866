import isEmpty from 'is-empty';
import { useState } from 'react';

import { useI18nContentsComparison } from '../../useI18nContentsComparison';
import { Image } from '../comparator/Image/Image';
import { PlainText } from '../comparator/PlainText';
import { beautifyJson } from '../parts/beautifyJson';
import { ModeChangeButtons } from '../parts/ModeChangeButtons';

import type { ImageItem } from '../comparator/Image';

type Props = {
  sinceValue: ImageItem[] | ImageItem;
  untilValue: ImageItem[] | ImageItem;
};

const Media: React.FC<Props> = ({ sinceValue, untilValue }) => {
  const { t } = useI18nContentsComparison();

  const [mode, setMode] = useState<'image' | 'json'>('image');

  return (
    <div>
      <ModeChangeButtons
        currentMode={mode}
        modeList={[
          { key: 'image', label: t('Image') },
          { key: 'json', label: t('JSON') },
        ]}
        setMode={setMode}
      />
      {mode === 'image' ? (
        <Image
          sinceValue={Array.isArray(sinceValue) ? sinceValue : [sinceValue]}
          untilValue={Array.isArray(untilValue) ? untilValue : [untilValue]}
        />
      ) : mode === 'json' ? (
        <PlainText
          sinceValue={isEmpty(sinceValue) ? '' : beautifyJson(sinceValue)}
          untilValue={isEmpty(untilValue) ? '' : beautifyJson(untilValue)}
          json
          diffType="char"
        />
      ) : null}
    </div>
  );
};

export { Media };
