import { useCallback, useMemo } from 'react';

import { useMyRolesContext } from '@/hooks/Role/useMyRoles';

import type { Role } from '@/entity/role';

import { hasAdminPermission } from '@/util/permissions/admin';
import { uniformRoleId } from '@/util/role';

/**
 * 閲覧者がRoleに対して権限を持っているかを判定する機能を提供する
 */
function useHasRolePermission() {
  const { roles } = useMyRolesContext();

  /**
   * 閲覧者がRoleのCREATE権限を持っている場合はtrue
   */
  const hasCreateRolePermission = useMemo(() => {
    if (!roles) {
      return false;
    }
    return (
      hasAdminPermission(roles) || roles.some((r) => r.rolePermission.create)
    );
  }, [roles]);

  /**
   * 閲覧者がRoleのUPDATE権限を持っている場合はtrue
   *
   * @see {hasUpdateRolePermissionTargetRole} RoleIDを指定して、閲覧者が対象のRoleについてUPDATE権限を持っているかを判定する場合はこちらを利用してください
   */
  const hasUpdateRolePermission = useMemo(() => {
    if (!roles) {
      return false;
    }
    return (
      hasAdminPermission(roles) || roles.some((r) => r.rolePermission.update)
    );
  }, [roles]);

  /**
   * 対象のRoleについて、閲覧者がUPDATE権限を持っている場合はtrue
   */
  const hasUpdateRolePermissionTargetRole = useCallback(
    (roleId: Role['roleId']) => {
      if (!roles) {
        return false;
      }
      const uniformedId = uniformRoleId(roleId);
      return (
        roles.some((r) => r.rolePermission.update) &&
        roles.some((r) => r.isAdmin || r.roleId === uniformedId)
      );
    },
    [roles],
  );

  /**
   * 閲覧者がRoleのDELETE権限を持っている場合はtrue
   */
  const hasDeleteRolePermission = useMemo(() => {
    if (!roles) {
      return false;
    }
    return (
      hasAdminPermission(roles) || roles.some((r) => r.rolePermission.delete)
    );
  }, [roles]);

  return {
    hasCreateRolePermission,
    hasUpdateRolePermission,
    hasUpdateRolePermissionTargetRole,
    hasDeleteRolePermission,
  };
}

export { useHasRolePermission };
