import OrderedList from '@tiptap/extension-ordered-list';

import type { OrderedListOptions } from '@tiptap/extension-ordered-list';

type CustomOrderedListOptions = OrderedListOptions & {
  inputRules: boolean;
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-ordered-list/src/ordered-list.ts
export const CustomOrderedList = OrderedList.extend<CustomOrderedListOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: true,
    };
  },
  parseHTML() {
    return this.options.inputRules ? this.parent?.() : [];
  },

  addKeyboardShortcuts() {
    return this.options.inputRules ? { ...this.parent?.() } : {};
  },

  addInputRules() {
    return this.options.inputRules ? this.parent?.() || [] : [];
  },
});
