import cx from 'classnames';
import type React from 'react';
import { useState, useCallback, useRef, useEffect } from 'react';

import styles from './tooltip.module.css';

type Props = {
  children?: React.ReactNode;
  trigger?: React.ReactChild;
  className?: string;
  onClose?: () => React.MouseEventHandler;
};

const Tooltip: React.FC<Props> = ({
  children,
  trigger,
  className,
  onClose = () => {},
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const open = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setIsOpen(true);
    },
    [setIsOpen],
  );
  const close = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setIsOpen(false);
      onClose();
    },
    [onClose],
  );

  // calculate the display position on the screen
  const [isInHighPosition, setIsInHighPosition] = useState<boolean | null>(
    null,
  );
  const tooltipRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isOpen) {
      const rect = tooltipRef.current?.getBoundingClientRect();
      const windowHalfHeight = window.innerHeight / 2;
      const isInHighPosition = windowHalfHeight > (rect?.top || 0);
      setIsInHighPosition(isInHighPosition);
    } else {
      setIsInHighPosition(null);
    }
  }, [isOpen]);

  return (
    <div className={className} ref={tooltipRef}>
      <div className={styles.trigger} onClick={open}>
        {trigger}
        {isOpen && isInHighPosition !== null && (
          <div>
            <div className={styles.mask} onClick={close} />
            <div
              className={cx(
                styles.container,
                isInHighPosition ? styles.top : styles.bottom,
              )}
              onClick={close}
            >
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Tooltip;
