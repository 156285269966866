import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from '../Button';
import ExternalLink from '../ExternalLink';

import styles from './upsell.module.css';

type Props = {
  isChildEnvironment?: boolean;
  helpUrl?: string;
  children?: React.ReactNode;
};

/**
 * @deprecated
 */
const Upsell: React.FC<Props> = ({
  isChildEnvironment,
  helpUrl = 'https://document.microcms.io/',
  children,
}) => {
  const { t } = useTranslation('uiUpsell');
  return (
    <div className={styles.wrapper}>
      <img className={styles.img} src="/images/icon_lock_fill.svg" alt="" />
      <p className={styles.title}>{t('Not available on current plan')}</p>
      <div className={styles.description}>
        {children ? (
          children
        ) : (
          <p>{t('Please check payment settings and documentation.')}</p>
        )}
      </div>
      <p className={styles.link}>
        {Trans({
          t,
          i18nKey: 'For more information, click here',
          children: (
            <ExternalLink
              href={helpUrl}
              hasUnderline={true}
              eraseNoreferrer={true}
            >
              here
            </ExternalLink>
          ),
        })}
      </p>
      {!isChildEnvironment && (
        <Link to="/settings/billing">
          <Button
            value={t('Upgrade this service')}
            type="secondary"
            size="large"
          />
        </Link>
      )}
    </div>
  );
};

export default Upsell;
