import { nanoid } from 'nanoid';
import type React from 'react';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { useGetMyService } from '@/hooks/useService';

import InputBasicInfo from './InputBasicInfo';
import InputModel from './InputModel';
import InputType from './InputType';
import SelectApiTemplate from './SelectApiTemplate';
import CheckRoles from '../CheckRoles';
import Head from '../Head';
import Loading from '../ui/Loading';
import {
  validateApiName,
  validateApiEndpoint,
  validateApiType,
  validateApiFields,
} from '../Validations';

import { useInput } from '@/hooks';

const CreateApi: React.FC = () => {
  const { t } = useTranslation('createApi');
  const { service } = useGetMyService();
  const [page, setPage] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  // @ts-expect-error
  const nameInput = useInput(undefined, validateApiName);
  // @ts-expect-error
  const endpointInput = useInput(undefined, validateApiEndpoint);
  // @ts-expect-error
  const typeInput = useInput('LIST', validateApiType);
  const fieldsInput = useInput(
    [{ idValue: nanoid(10), isAdditionalField: true }],
    validateApiFields,
  );
  // @ts-expect-error
  const customFieldsInput = useInput([], (customField) =>
    validateApiFields(customField.fields),
  );

  const goNext = useCallback(() => setPage(page + 1), [page, setPage]);
  const goPrevious = useCallback(() => setPage(page - 1), [page, setPage]);

  if (service === undefined) {
    return null;
  }

  if (service === null) {
    return <Redirect to="/not-found" />;
  }

  if (isLoading) {
    return (
      <Loading>
        {t('Creating API...')}
        <br />
        {t('(This process will take some time. Please wait a moment)')}
      </Loading>
    );
  }

  return (
    <CheckRoles permission="apiPermission" operation="create" display={true}>
      <Head title={t('API/Create')} />
      {page === 0 && (
        <SelectApiTemplate
          // @ts-expect-error
          goNext={goNext}
          setIsLoading={setIsLoading}
          service={service}
        />
      )}
      {page === 1 && (
        <InputBasicInfo
          // @ts-expect-error
          service={service}
          goNext={goNext}
          nameInput={nameInput}
          endpointInput={endpointInput}
        />
      )}
      {page === 2 && (
        <InputType
          goNext={goNext}
          goPrevious={goPrevious}
          typeInput={typeInput}
        />
      )}
      {page === 3 && (
        <InputModel
          goPrevious={goPrevious}
          fieldsInput={fieldsInput}
          customFieldsInput={customFieldsInput}
          objects={{
            name: nameInput[0],
            endpoint: endpointInput[0],
            type: typeInput[0],
            fields: fieldsInput[0],
            customFields: customFieldsInput[0],
          }}
        />
      )}
    </CheckRoles>
  );
};

export default CreateApi;
