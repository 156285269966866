import { Clear } from './Clear';
import { Redo } from './Redo';
import { Undo } from './Undo';

import type { Editor } from '@tiptap/react';

import style from './operation.module.css';

export type Props = {
  editor: Editor;
};

export type ViewProps = {
  editor: Editor;
};

export const OperationView: React.FC<ViewProps> = ({ editor }) => {
  return (
    <div className={style.main}>
      <Undo editor={editor} />
      <Redo editor={editor} />
      <Clear editor={editor} />
    </div>
  );
};

export const Operation: React.FC<Props> = ({ editor }) => {
  return <OperationView editor={editor} />;
};
