import {
  isRelativeLink,
  validateLink,
} from '@/views/Common/validations/validateLink';

export const useEditLinkBody = ({ href }: { href: string }) => {
  const isDisplayLink = !isRelativeLink(href) && !validateLink(href);

  return { isDisplayLink };
};
