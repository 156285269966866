import { Trans, useTranslation } from 'react-i18next';

import Button from '../Button';
import ExternalLink from '../ExternalLink';

import styles from './error.module.css';

const Error: React.FC = () => {
  const { t } = useTranslation('error');
  return (
    <div className={styles.main}>
      <img
        src="/images/icon_error_screen.svg"
        alt=""
        className={styles.errorImage}
      />
      <h3 className={styles.title}>{t('Error occurred')}</h3>
      <p className={styles.note}>
        {t('Please reload, or')}
        <br />
        {Trans({
          t,
          i18nKey: 'Check the status page.',
          children: (
            <ExternalLink
              href="https://status.microcms.io/#"
              children="the staus page"
              hasUnderline={true}
            />
          ),
        })}
      </p>
      <Button
        value={t('Reload')}
        type="secondary"
        size="large"
        onClick={() => window.location.reload()}
      />
    </div>
  );
};

export default Error;
