import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import cx from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { Trans, useTranslation } from 'react-i18next';

import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';

import CheckRoles from '../../CheckRoles';
import Head from '../../Head';
import PlanSelectModal from '../../PlanListModal';
import {
  validateCustomerCompanyName,
  validateCustomerName,
  validateCustomerPhoneNumber,
  validateEmail,
} from '../../Validations';
import Button from '../../ui/Button';
import ExternalLink from '../../ui/ExternalLink';
import Fieldset from '../../ui/Fieldset';
import IconButton from '../../ui/IconButton';
import Modal from '../../ui/Modal';
import { Table, Tbody, Td, Tr } from '../../ui/Table';
import Textarea from '../../ui/Textarea';
import Textfield from '../../ui/Textfield';

import type { CollectionMethodType } from '@/types/stripe';

import styles from './billingSettings.module.css';

import {
  CANCEL_REASON_MATTER,
  CANCEL_REASON_OTHERS,
  CANCEL_REASON_SERVICE,
  CHARGE_AUTOMATICALLY,
  SEND_INVOICE,
} from '@/constants/collectionMethodTypes';
import { PlanName } from '@/constants/plan';
import { StripeCardBrand } from '@/constants/stripe';
import { useInput } from '@/hooks';
import { formatSeparatedNumber, getNetPrice } from '@/util/billing';
import { formatUnix, formatUnixToMinutes } from '@/util/date';

const aboutPageUrl = 'https://document.microcms.io/manual/templates';

const BillingSettings: React.FC = () => {
  const { t } = useTranslation('serviceBillingSettings');
  const { service } = useGetMyService();

  const [collectionMethod, setCollectionMethod] =
    useState<CollectionMethodType>(CHARGE_AUTOMATICALLY);
  const [email, onChangeEmail, emailError] = useInput(undefined, validateEmail);
  const [company, onChangeCompany, companyError] = useInput(
    undefined,
    // @ts-expect-error
    validateCustomerCompanyName,
  );
  const [name, onChangeName, nameError] = useInput(
    undefined,
    // @ts-expect-error
    validateCustomerName,
  );
  const [phone, onChangePhone, phoneError] = useInput(
    undefined,
    // @ts-expect-error
    validateCustomerPhoneNumber,
  );

  // サービス解約理由の処理
  const [cancelSelect, setCancelSelect] = useState(null);
  const [cancelReason, onChangeCancelReason] = useInput();
  const changeCanselReasonRadio = useCallback(
    (type: any) => {
      setCancelSelect(type);
    },
    [setCancelSelect],
  );

  const {
    changePaymentInfo,
    changePaymentInfoLoading,
    deleteCard,
    changeCard,
    changeCardLoading,
    changePlanToBusinessLoading,
    changePlanToTeamLoading,
    changePlanToAdvancedLoading,
    changePlanToHobbyLoading,
    changeBillingEmail,
    changeBillingEmailLoading,
    changeBillingName,
    changeBillingNameLoading,
    changeBillingPhone,
    changeBillingPhoneLoading,
    changeBillingCompany,
    changeBillingCompanyLoading,
    changeBillingInvoice,
    changeBillingInvoiceLoading,
    cancelSubscription,
    cancelSubscriptionLoading,
    handleCardPayment,
    handleCardPaymentLoading,
    invoiceDataQuery,
    subscriptionDataQuery,
    planListData,
    currentPlan,
    isTrial,
    actionRequired,
  } = useStripeActions(service);
  const { data: subscriptionData } = subscriptionDataQuery;
  const { data: invoiceData } = invoiceDataQuery;

  const stripe = useStripe();
  const elements = useElements();

  const [PlanListModal, openPlanListModal, closePlanListModal] =
    useModal('root');

  const [ChangeEmailModal, openChangeEmailModal, closeChangeEmailModal] =
    useModal('root');

  const [
    ChangePaymentInfoModal,
    openChangePaymentInfoModal,
    closeChangePaymentInfoModal,
  ] = useModal('root');

  //スキップボタンの表示を切り替える
  const [onlyRegisterButton, setOnlyRegisterButton] = useState(false);
  const openChangePaymentInfoModalWithSkipButton = useCallback(() => {
    openChangePaymentInfoModal();
    setOnlyRegisterButton(false);
  }, [openChangePaymentInfoModal, setOnlyRegisterButton]);
  const openChangePaymentInfoModalWithoutSkipButton = useCallback(() => {
    openChangePaymentInfoModal();
    setOnlyRegisterButton(true);
  }, [openChangePaymentInfoModal, setOnlyRegisterButton]);

  const [ChangeCardModal, openChangeCardModal, closeChangeCardModal] =
    useModal('root');

  const [
    CancelSubscriptionModal,
    openCancelSubscriptionModal,
    closeCancelSubscriptionModal,
  ] = useModal('root');

  const [ChangeNameModal, openChangeNameModal, closeChangeNameModal] =
    useModal('root');

  const [ChangePhoneModal, openChangePhoneModal, closeChangePhoneModal] =
    useModal('root');

  const [ChangeCompanyModal, openChangeCompanyModal, closeChangeCompanyModal] =
    useModal('root');

  const changeEmail = useCallback(() => {
    const _email = email || subscriptionData?.customer_email;
    // @ts-expect-error
    changeBillingEmail({ email: _email, closeModal: closeChangeEmailModal });
  }, [changeBillingEmail, closeChangeEmailModal, email, subscriptionData]);

  const changeName = useCallback(() => {
    // @ts-expect-error
    changeBillingName({ name: name, closeModal: closeChangeNameModal });
  }, [changeBillingName, closeChangeNameModal, name]);

  const changePhone = useCallback(() => {
    // @ts-expect-error
    changeBillingPhone({ phone: phone, closeModal: closeChangePhoneModal });
  }, [changeBillingPhone, closeChangePhoneModal, phone]);

  const changeCompany = useCallback(() => {
    // @ts-expect-error
    changeBillingCompany({
      company: company,
      closeModal: closeChangeCompanyModal,
    });
  }, [changeBillingCompany, closeChangeCompanyModal, company]);

  const handle3dSecure = useCallback(() => {
    // @ts-expect-error
    handleCardPayment({ stripe });
  }, [handleCardPayment, stripe]);

  const doChangeCard = useCallback(
    async (e: any) => {
      e.preventDefault();
      // @ts-expect-error
      const cardElement = elements.getElement(CardElement);
      // @ts-expect-error
      changeCard({
        stripe,
        cardElement,
        closeModal: closeChangeCardModal,
      });
    },
    [elements, changeCard, stripe, closeChangeCardModal],
  );

  const doChangeInvoice = useCallback(
    async (e: any) => {
      e.preventDefault();
      // @ts-expect-error
      changeBillingInvoice({ closeModal: closeChangeCardModal });
    },
    [changeBillingInvoice, closeChangeCardModal],
  );

  const doDeleteCard = useCallback(
    async (e: any) => {
      e.preventDefault();
      // @ts-expect-error
      deleteCard({ closeModal: closeChangeCardModal });
    },
    [deleteCard, closeChangeCardModal],
  );

  const doChangePaymentInfo = useCallback(
    async (e: any) => {
      e.preventDefault();
      // @ts-expect-error
      const cardElement = elements.getElement(CardElement);
      const _email =
        email || (subscriptionData && subscriptionData.customer_email);

      // @ts-expect-error
      changePaymentInfo({
        stripe,
        cardElement,
        email: _email,
        name,
        phone,
        company,
        closeModal: closeChangePaymentInfoModal,
      });
    },
    [
      elements,
      email,
      subscriptionData,
      changePaymentInfo,
      stripe,
      name,
      phone,
      company,
      closeChangePaymentInfoModal,
    ],
  );

  const doCancelSubscription = useCallback(
    async (e: any) => {
      e.preventDefault();
      // @ts-expect-error
      cancelSubscription({
        closeModal: closeCancelSubscriptionModal,
        cancelSelect: cancelSelect,
        cancelReason: cancelReason,
      });
    },
    [
      cancelReason,
      cancelSelect,
      cancelSubscription,
      closeCancelSubscriptionModal,
    ],
  );

  const doChangeRadio = useCallback((type: CollectionMethodType) => {
    setCollectionMethod(type);
  }, []);

  /**
   * 割引後の合計(税抜き)
   */
  const netPrice = useMemo(() => {
    if (
      subscriptionData &&
      subscriptionData.nextDiscounts &&
      subscriptionData.nextDiscounts.length > 0
    ) {
      return getNetPrice(subscriptionData);
    }

    return undefined;
  }, [subscriptionData]);

  if (service && Object.keys(service).length === 0) {
    return null;
  }

  if (!planListData) {
    return null;
  }

  return (
    <div>
      <Head title={t('Payment')} />
      <h2 className={styles.title}>{t('Payment')}</h2>
      {service && (
        <div className={styles.container}>
          {currentPlan &&
            currentPlan.name !== PlanName.Enterprise &&
            currentPlan.name !== PlanName.Template && (
              <div className={styles.action}>
                <CheckRoles permission="billingPermission" operation="update">
                  <Button
                    type="primary"
                    value={t('Change')}
                    onClick={openPlanListModal}
                    disabled={
                      changePlanToBusinessLoading ||
                      changePlanToTeamLoading ||
                      changePlanToAdvancedLoading ||
                      changePlanToHobbyLoading
                    }
                    className="ga-open-plan-modal"
                  />
                </CheckRoles>
              </div>
            )}
          <dl>
            <dd className={styles.currentPlanArea}>
              <dl>
                <dt className={styles.currentPlanTitle}>{t('Current Plan')}</dt>
                <dd className={styles.currentPlanName}>
                  {currentPlan && (
                    <span data-testid="current-plan-name">
                      {currentPlan.name}
                    </span>
                  )}
                  {isTrial && subscriptionData && (
                    <p className={styles.trial}>
                      {t('Free Trial (Until {{trialEnd}})', {
                        trialEnd: formatUnixToMinutes(
                          subscriptionData.trial_end,
                        ),
                      })}
                    </p>
                  )}
                </dd>
              </dl>
              <dl>
                {currentPlan && (
                  <dd>
                    {currentPlan && currentPlan.name === 'Enterprise' ? (
                      <p className={styles.value}>
                        {t(
                          'For more information about our plans, contact our sales representatives.',
                        )}
                      </p>
                    ) : (
                      <>
                        <dl className={styles.line}>
                          <dt className={styles.indicator}>
                            {t('Amount of data transfer')}
                          </dt>
                          <dd className={styles.value}>
                            {currentPlan.limit.transfer === null
                              ? t('Unlimited')
                              : currentPlan.limit.transfer}
                            {currentPlan.limit.transferOveragePrice &&
                              currentPlan.limit.transferOverageUnit &&
                              t(
                                '+{{OveragePrice}} yen (tax excluded) /{{OverageUnit}}',
                                {
                                  OveragePrice:
                                    currentPlan.limit.transferOveragePrice.toLocaleString(),
                                  OverageUnit:
                                    currentPlan.limit.transferOverageUnit,
                                },
                              )}
                          </dd>
                          <dt className={styles.indicator}>{t('Members')}</dt>
                          <dd className={styles.value}>
                            {currentPlan.limit.memberCount === null
                              ? t('Unlimited')
                              : t('{{count}} people', {
                                  count: currentPlan.limit.memberCount,
                                })}
                            {currentPlan.limit.memberCountOveragePrice &&
                              currentPlan.limit.memberCountOverageUnit &&
                              t(
                                '+{{OveragePrice}} yen (tax excluded) /{{OverageUnit}}',
                                {
                                  OveragePrice:
                                    currentPlan.limit.memberCountOveragePrice.toLocaleString(),
                                  OverageUnit:
                                    currentPlan.limit.memberCountOverageUnit,
                                },
                              )}
                          </dd>
                          <dt className={styles.indicator}>API</dt>
                          <dd className={styles.value}>
                            {currentPlan.limit.apiCount === null
                              ? t('Unlimited')
                              : t('{{count}} pieces', {
                                  count: currentPlan.limit.apiCount,
                                })}
                            {currentPlan.limit.apiCountOveragePrice &&
                              currentPlan.limit.apiCountOverageUnit &&
                              t(
                                '+{{OveragePrice}} yen (tax excluded) /{{OverageUnit}}',
                                {
                                  OveragePrice:
                                    currentPlan.limit.apiCountOveragePrice.toLocaleString(),
                                  OverageUnit:
                                    currentPlan.limit.apiCountOverageUnit,
                                },
                              )}
                          </dd>
                          <dt className={styles.indicator}>{t('API Key')}</dt>
                          <dd className={styles.value}>
                            {currentPlan.limit.maxApiKeyCount === null
                              ? t('Unlimited')
                              : t('{{count}} pieces', {
                                  count: currentPlan.limit.maxApiKeyCount,
                                })}
                          </dd>
                          <dt className={styles.indicator}>{t('Contents')}</dt>
                          <dd className={styles.value}>
                            {currentPlan.limit.contentsCount === null
                              ? t('Unlimited')
                              : t('{{count}} items', {
                                  count:
                                    currentPlan.limit.contentsCount.toLocaleString(),
                                })}
                          </dd>
                        </dl>
                        <dl className={styles.line}>
                          <dt className={styles.indicator}>
                            {t('CSV import')}
                          </dt>
                          <dd className={styles.value}>
                            {currentPlan.limit.importUpperLimit === null
                              ? t('Unlimited')
                              : t('{{count}} items', {
                                  count:
                                    currentPlan.limit.importUpperLimit.toLocaleString(),
                                })}
                          </dd>
                          <dt className={styles.indicator}>
                            {t('Permission')}
                          </dt>
                          <dd className={styles.value}>
                            {currentPlan.limit.roleCount === null
                              ? t('Unlimited')
                              : t('{{count}} pieces', {
                                  count: currentPlan.limit.roleCount,
                                })}
                          </dd>
                          <dt className={styles.indicator}>
                            {t('Maximum media file size')}
                          </dt>
                          <dd className={styles.value}>
                            {currentPlan.limit.maxMediumSizeInMb === null
                              ? t('Unlimited')
                              : `${currentPlan.limit.maxMediumSizeInMb}MB`}
                          </dd>
                          <dt className={styles.indicator}>
                            {t('WRITE API queries')}
                          </dt>
                          <dd className={styles.value}>
                            {currentPlan.limit.enableAllParams
                              ? t('Available')
                              : t('Unavailable')}
                          </dd>
                        </dl>
                      </>
                    )}
                  </dd>
                )}
              </dl>
            </dd>
            {currentPlan.name === PlanName.Template ? (
              <dd className={styles.detailLink}>
                <ExternalLink href={aboutPageUrl}>
                  {t('For more information about microCMS Templates plan.')}
                </ExternalLink>
              </dd>
            ) : (
              <dd className={styles.detailLink}>
                <ExternalLink href="https://microcms.io/pricing">
                  {t('For more information on plans and rates, click here.')}
                </ExternalLink>
              </dd>
            )}
            {subscriptionData && (
              <dd>
                <Table>
                  <Tbody>
                    {subscriptionData.nextLines.map((line, i) => {
                      return (
                        <Tr key={i} isSlim={true} enableHoverStyle={false}>
                          <Td>
                            <p className={styles.planListData}>
                              {line.description}
                            </p>
                          </Td>
                          <Td>
                            <p className={styles.planListData}>
                              {formatUnix(line.period.start)} 〜{' '}
                              {formatUnix(line.period.end)}
                            </p>
                          </Td>
                          <Td isRight={true}>
                            <p className={styles.planListData}>
                              {t('{{price}} yen', {
                                price: formatSeparatedNumber(line.amount),
                              })}
                            </p>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                  <tfoot className={styles.tfoot}>
                    <tr>
                      <th>{t('subtotal')}</th>
                      <td />
                      <td className={styles.number}>
                        {t('{{price}} yen', {
                          price: formatSeparatedNumber(
                            subscriptionData.nextSubtotal,
                          ),
                        })}
                      </td>
                    </tr>

                    {subscriptionData.nextDiscounts.length > 0 && (
                      <>
                        <Tr isSlim={true} enableHoverStyle={false}>
                          <Td>
                            <p
                              className={cx(
                                styles.planListData,
                                styles.headingLabelCell,
                              )}
                            >
                              {t('Discounts')}
                            </p>
                          </Td>
                          <Td />
                          <Td />
                        </Tr>
                        {subscriptionData.nextDiscounts.map((discount, i) => (
                          <Tr
                            key={discount.couponName + i}
                            isSlim={true}
                            enableHoverStyle={false}
                          >
                            <Td>
                              <p className={styles.planListData}>
                                {discount.couponName}
                              </p>
                            </Td>
                            <Td />
                            <Td className={styles.number}>
                              <p className={styles.planListData}>
                                {t('{{price}} yen', {
                                  price: `-${formatSeparatedNumber(
                                    discount.amount,
                                  )}`,
                                })}
                              </p>
                            </Td>
                          </Tr>
                        ))}
                        {netPrice !== undefined && (
                          <Tr isSlim={true} enableHoverStyle={false}>
                            <Td>
                              <p
                                className={cx(
                                  styles.planListData,
                                  styles.headingLabelCell,
                                )}
                              >
                                {t('Net price')}
                              </p>
                            </Td>
                            <Td />
                            <Td className={styles.number}>
                              <p className={styles.planListData}>
                                {t('{{price}} yen', {
                                  price: formatSeparatedNumber(netPrice),
                                })}
                              </p>
                            </Td>
                          </Tr>
                        )}
                      </>
                    )}

                    <tr>
                      <th>
                        {t('Consumption tax {{percentage}}%', {
                          percentage: subscriptionData.taxPercent,
                        })}
                      </th>
                      <td />
                      <td className={styles.number}>
                        {t('{{price}} yen', {
                          price: formatSeparatedNumber(
                            subscriptionData.nextTax,
                          ),
                        })}
                      </td>
                    </tr>
                    <tr>
                      <th>{t('Estimated next payment(tax included)')}</th>
                      <td />
                      <td className={styles.nextTotal}>
                        {t('{{price}} yen', {
                          price: formatSeparatedNumber(
                            subscriptionData.nextTotal,
                          ),
                        })}
                        <p className={styles.nextDate}>
                          {t(
                            'Scheduled to be deducted on {{nextPaymentDate}}',
                            {
                              nextPaymentDate: formatUnix(
                                subscriptionData.nextPaymentDate,
                              ),
                            },
                          )}
                        </p>
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </dd>
            )}
          </dl>
        </div>
      )}

      {currentPlan.name !== PlanName.Enterprise &&
        currentPlan.name !== PlanName.Template && (
          <div>
            {actionRequired && (
              <button
                className={styles.alert}
                onClick={handle3dSecure}
                disabled={handleCardPaymentLoading}
              >
                {t('Complete 3D Secure authentication and complete payment')}
              </button>
            )}
            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>{t('Payment Details')}</h3>
              {invoiceData?.card_last4 ||
              subscriptionData?.collectionMethod === SEND_INVOICE ? (
                <Table>
                  <Tbody>
                    <Tr isSlim={true} enableHoverStyle={false}>
                      <Td>
                        <p className={styles.listTitle}>
                          {t('Payment Method')}
                        </p>
                      </Td>
                      <Td>
                        <div className={styles.cardDetails}>
                          {invoiceData?.card_brand && (
                            <div className={styles.cardNumber}>
                              {invoiceData.card_brand ===
                                StripeCardBrand.Visa && (
                                <img
                                  src="/images/logo_visa.gif"
                                  className={styles.cardBrandLogoLarge}
                                  alt="VISA"
                                />
                              )}
                              {invoiceData.card_brand ===
                                StripeCardBrand.MasterCard && (
                                <img
                                  src="/images/logo_mastercard.gif"
                                  className={styles.cardBrandLogoLarge}
                                  alt="MasterCard"
                                />
                              )}
                              {invoiceData.card_brand ===
                                StripeCardBrand.Jcb && (
                                <img
                                  src="/images/logo_jcb.gif"
                                  className={styles.cardBrandLogoLarge}
                                  alt="JCB"
                                />
                              )}
                              {invoiceData.card_brand ===
                                StripeCardBrand.AmericanExpress && (
                                <img
                                  src="/images/logo_american_express.png"
                                  className={styles.cardBrandLogoLarge}
                                  alt="AmericanExpress"
                                />
                              )}
                              {invoiceData.card_brand ===
                                StripeCardBrand.DinersClub && (
                                <img
                                  src="/images/logo_diners.gif"
                                  className={styles.cardBrandLogoLarge}
                                  alt="Diners"
                                />
                              )}
                              {invoiceData.card_brand ===
                                StripeCardBrand.Discover && (
                                <img
                                  src="/images/logo_discover.jpg"
                                  className={styles.cardBrandLogoLarge}
                                  alt="Discover"
                                />
                              )}
                              <p>
                                <span className={styles.hide}>
                                  ●●●● ●●●● ●●●●
                                </span>
                                {invoiceData?.card_last4}
                              </p>
                            </div>
                          )}
                          {invoiceData?.card_exp_year &&
                            invoiceData?.card_exp_month && (
                              <p>
                                <span className={styles.limit}>
                                  {t('Expiration Date')}
                                </span>
                                {invoiceData?.card_exp_year}/
                                {invoiceData?.card_exp_month}
                              </p>
                            )}
                        </div>
                        {subscriptionData?.collectionMethod ===
                          SEND_INVOICE && (
                          <p className={styles.data}>
                            {t('Payment by invoice')}
                          </p>
                        )}
                      </Td>
                      <Td>
                        <CheckRoles
                          permission="billingPermission"
                          operation="update"
                        >
                          <IconButton
                            className={styles.buttonRight}
                            icon="edit"
                            onClick={openChangeCardModal}
                          />
                        </CheckRoles>
                      </Td>
                    </Tr>
                    <Tr isSlim={true} enableHoverStyle={false}>
                      <Td>
                        <p className={styles.listTitle}>
                          {t('Billing Email Address')}
                        </p>
                      </Td>
                      <Td>
                        <p className={styles.data}>
                          {invoiceData?.customer_email}
                        </p>
                      </Td>
                      <Td>
                        <CheckRoles
                          permission="billingPermission"
                          operation="update"
                        >
                          <IconButton
                            className={styles.buttonRight}
                            icon="edit"
                            onClick={openChangeEmailModal}
                          />
                        </CheckRoles>
                      </Td>
                    </Tr>
                    <Tr isSlim={true} enableHoverStyle={false}>
                      <Td>
                        <p className={styles.listTitle}>
                          {t('Billing Contact Name')}
                        </p>
                      </Td>
                      <Td>
                        <p className={styles.data}>
                          {invoiceData?.customer_name
                            ? invoiceData.customer_name
                            : t('Unset')}
                        </p>
                      </Td>
                      <Td>
                        <CheckRoles
                          permission="billingPermission"
                          operation="update"
                        >
                          <IconButton
                            className={styles.buttonRight}
                            icon="edit"
                            onClick={openChangeNameModal}
                          />
                        </CheckRoles>
                      </Td>
                    </Tr>
                    <Tr isSlim={true} enableHoverStyle={false}>
                      <Td>
                        <p className={styles.listTitle}>
                          {t('Billing Company Name')}
                        </p>
                      </Td>
                      <Td>
                        <p className={styles.data}>
                          {invoiceData?.customer_company
                            ? invoiceData.customer_company
                            : t('Unset')}
                        </p>
                      </Td>
                      <Td>
                        <CheckRoles
                          permission="billingPermission"
                          operation="update"
                        >
                          <IconButton
                            className={styles.buttonRight}
                            icon="edit"
                            onClick={openChangeCompanyModal}
                          />
                        </CheckRoles>
                      </Td>
                    </Tr>
                    <Tr isSlim={true} enableHoverStyle={false}>
                      <Td>
                        <p className={styles.listTitle}>
                          {t('Billing Phone Number')}
                        </p>
                      </Td>
                      <Td>
                        <p className={styles.data}>
                          {invoiceData?.customer_phone
                            ? invoiceData.customer_phone
                            : t('Unset')}
                        </p>
                      </Td>
                      <Td>
                        <CheckRoles
                          permission="billingPermission"
                          operation="update"
                        >
                          <IconButton
                            className={styles.buttonRight}
                            icon="edit"
                            onClick={openChangePhoneModal}
                          />
                        </CheckRoles>
                      </Td>
                    </Tr>
                    {subscriptionData && (
                      <Tr isSlim={true} enableHoverStyle={false}>
                        <Td>
                          <p className={styles.listTitle}>
                            {t('Next scheduled payment date')}
                          </p>
                        </Td>
                        <Td>
                          <p className={styles.data}>
                            {formatUnix(subscriptionData.nextPaymentDate)}
                          </p>
                        </Td>
                        <Td></Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              ) : (
                <div className={styles.registerMethod}>
                  <Button
                    type="primary"
                    value={t('Register Payment Details')}
                    onClick={openChangePaymentInfoModalWithoutSkipButton}
                  />
                  {subscriptionData && (
                    <p className={styles.errorDescription}>
                      {Trans({
                        t,
                        i18nKey:
                          'At the end of the free trial period, your plan will be reverted to Hobby plan.',
                        children: (
                          <strong className={styles.errorText}>
                            the Hobby plan
                          </strong>
                        ),
                      })}
                      <br />
                      {t(
                        'If you have registered your payment details, you can continue to use your current plan.',
                      )}
                    </p>
                  )}
                </div>
              )}
            </div>

            <div className={styles.section}>
              <h3 className={styles.sectionTitle}>
                {t('Payment History (Up to 10)')}
              </h3>
              {invoiceData && invoiceData.paidInvoices.length > 0 && (
                <p className={styles.note}>
                  {t(
                    '*Detailed links are available to download invoices/receipts.',
                  )}
                </p>
              )}
              {invoiceData?.paidInvoices?.map((invoice, i) => {
                return (
                  <dl key={i} className={styles.historyLists}>
                    <dt className={styles.historyListsDate}>
                      {formatUnixToMinutes(invoice.date)}
                    </dt>
                    <dd>
                      {t('{{price}} yen_HISTORY', {
                        price: formatSeparatedNumber(invoice.paid),
                      })}{' '}
                      <a
                        href={invoice.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('(Details)')}
                      </a>
                    </dd>
                  </dl>
                );
              })}
              {(invoiceData?.paidInvoices.length === 0 || !invoiceData) && (
                <p className={styles.data}>{t('No payment yet.')}</p>
              )}
            </div>
          </div>
        )}

      {subscriptionData && (
        <CheckRoles permission="billingPermission" operation="update">
          <div className={styles.cancelArea}>
            <Button
              type="danger"
              className={styles.cancelButton}
              value={
                isTrial
                  ? t('Discontinue trial and cancel service')
                  : t('Cancel recurring payment')
              }
              onClick={openCancelSubscriptionModal}
            />
          </div>
        </CheckRoles>
      )}

      <PlanListModal>
        <PlanSelectModal
          // @ts-expect-error
          service={service}
          closePlanListModal={closePlanListModal}
          openChangePaymentInfoModal={({ withSkipButton }) => {
            if (withSkipButton) {
              openChangePaymentInfoModalWithSkipButton();
            } else {
              openChangePaymentInfoModalWithoutSkipButton();
            }
          }}
          setCollectionMethod={setCollectionMethod}
        />
      </PlanListModal>
      <ChangeEmailModal>
        <Modal title={t('Change Billing Email Address')}>
          {invoiceData && (
            <Fieldset legend={t('Billing Email Address')} option={false}>
              <Textfield
                onChange={onChangeEmail}
                errorText={emailError}
                defaultValue={invoiceData.customer_email}
              />
            </Fieldset>
          )}
          <Button
            type="primary"
            value={t('Change')}
            size="full"
            className={styles.modalActionButton}
            onClick={changeEmail}
            disabled={changeBillingEmailLoading || emailError}
          />
        </Modal>
      </ChangeEmailModal>
      <ChangeCompanyModal>
        <Modal title={t('Change Billing Company Name')}>
          {invoiceData && (
            <Fieldset legend={t('Billing Company Name')} option={false}>
              <Textfield
                onChange={onChangeCompany}
                errorText={companyError}
                defaultValue={invoiceData.customer_company ?? undefined}
              />
            </Fieldset>
          )}
          <Button
            type="primary"
            size="full"
            value={t('Change')}
            className={styles.modalActionButton}
            onClick={changeCompany}
            disabled={changeBillingCompanyLoading || companyError}
          />
        </Modal>
      </ChangeCompanyModal>
      <ChangeNameModal>
        <Modal title={t('Change Billing Contact Name')}>
          {invoiceData && (
            <Fieldset legend={t('Billing Contact Name')} option={false}>
              <Textfield
                onChange={onChangeName}
                errorText={nameError}
                defaultValue={invoiceData.customer_name}
              />
            </Fieldset>
          )}
          <Button
            type="primary"
            size="full"
            value={t('Change')}
            className={styles.modalActionButton}
            onClick={changeName}
            disabled={changeBillingNameLoading || nameError}
          />
        </Modal>
      </ChangeNameModal>
      <ChangePhoneModal>
        <Modal title={t('Change Billing Phone Number')}>
          {invoiceData && (
            <Fieldset legend={t('Billing Phone Number')} option={false}>
              <Textfield
                type="number"
                onChange={onChangePhone}
                errorText={phoneError}
                defaultValue={invoiceData.customer_phone ?? undefined}
              />
            </Fieldset>
          )}
          <Button
            type="primary"
            size="full"
            value={t('Change')}
            className={styles.modalActionButton}
            onClick={changePhone}
            disabled={changeBillingPhoneLoading || phoneError}
          />
        </Modal>
      </ChangePhoneModal>
      <CancelSubscriptionModal>
        <Modal
          title={
            isTrial
              ? t('Discontinue trial and cancel service')
              : t('Cancel recurring payment')
          }
        >
          <ul className={styles.cancelDescription}>
            <li>{t('・The service will return to Hobby plan.')}</li>
            <li className={styles.errorText}>
              {t(
                '・The APIs will stop functioning if the limit of Hobby plan is exceeded.',
              )}
            </li>
          </ul>
          <p className={styles.cancelDescription}>
            {t(
              'Would you please tell us the reasons for cancellation so that we can improve our services in the future?',
            )}
          </p>
          <Fieldset legend={t('Reasons for Cancellation')} option={false}>
            <div className={styles.radioGroup}>
              <div>
                <label>
                  <input
                    type="radio"
                    className={styles.radioBox}
                    checked={cancelSelect === CANCEL_REASON_SERVICE}
                    onChange={() =>
                      changeCanselReasonRadio(CANCEL_REASON_SERVICE)
                    }
                  />
                  <span>{t('Unsatisfied with microCMS')}</span>
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    className={styles.radioBox}
                    checked={cancelSelect === CANCEL_REASON_MATTER}
                    onChange={() =>
                      changeCanselReasonRadio(CANCEL_REASON_MATTER)
                    }
                  />
                  <span>{t('Project has ended')}</span>
                </label>
              </div>
              <div>
                <label>
                  <input
                    type="radio"
                    className={styles.radioBox}
                    checked={cancelSelect === CANCEL_REASON_OTHERS}
                    onChange={() =>
                      changeCanselReasonRadio(CANCEL_REASON_OTHERS)
                    }
                  />
                  <span>{t('Other')}</span>
                </label>
              </div>
            </div>
          </Fieldset>
          <Textarea
            placeholder={t('Enter reasons for cancellation')}
            style={{ minHeight: 100 }}
            onChange={onChangeCancelReason}
          />
          <div className={styles.cancelAction}>
            <Button
              type="danger"
              value={t('Cancel payment')}
              className={styles.modalCancelButton}
              size="full"
              onClick={doCancelSubscription}
              disabled={cancelSubscriptionLoading || cancelSelect === null}
            />
          </div>
        </Modal>
      </CancelSubscriptionModal>
      <ChangePaymentInfoModal>
        <Modal
          title={t('Register Payment Details')}
          footer={
            <CheckRoles permission="billingPermission" operation="update">
              <Button
                type="primary"
                value={t('Register')}
                size="full"
                className={cx(styles.registerButton, {
                  [styles.onlyRegister]: onlyRegisterButton,
                })}
                onClick={doChangePaymentInfo}
                disabled={
                  changePaymentInfoLoading ||
                  emailError ||
                  companyError ||
                  company === undefined ||
                  nameError ||
                  name === undefined ||
                  phoneError ||
                  phone === undefined
                }
              />
              {!onlyRegisterButton && (
                <button
                  className={styles.skipButton}
                  onClick={closeChangePaymentInfoModal}
                >
                  {t('Skip')}
                </button>
              )}
            </CheckRoles>
          }
        >
          {currentPlan.limit.enableSendInvoice && (
            <Fieldset legend={t('Payment Method')} option={false}>
              <div className={styles.radioGroup}>
                <div>
                  <label>
                    <input
                      type="radio"
                      className={styles.radioBox}
                      checked={collectionMethod === CHARGE_AUTOMATICALLY}
                      onChange={() => doChangeRadio(CHARGE_AUTOMATICALLY)}
                    />
                    <span>{t('Credit Card')}</span>
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="radio"
                      className={styles.radioBox}
                      checked={collectionMethod === SEND_INVOICE}
                      onChange={() => doChangeRadio(SEND_INVOICE)}
                    />
                    <span>{t('Invoice Payment')}</span>
                  </label>
                </div>
              </div>
            </Fieldset>
          )}
          {collectionMethod === CHARGE_AUTOMATICALLY && (
            <Fieldset legend={t('Credit Card Details')} option={false}>
              <CardElement />
              <ul className={styles.cardBrands}>
                <li>
                  <img
                    src="/images/logo_visa.gif"
                    className={styles.cardBrandLogo}
                    alt="VISA"
                  />
                </li>
                <li>
                  <img
                    src="/images/logo_mastercard.gif"
                    className={styles.cardBrandLogo}
                    alt="MasterCard"
                  />
                </li>
                <li>
                  <img
                    src="/images/logo_jcb.gif"
                    className={styles.cardBrandLogo}
                    alt="JCB"
                  />
                </li>
                <li>
                  <img
                    src="/images/logo_american_express.png"
                    className={styles.cardBrandLogo}
                    alt="AmericanExpress"
                  />
                </li>
                <li>
                  <img
                    src="/images/logo_diners.gif"
                    className={styles.cardBrandLogo}
                    alt="Diners Club INTERNATIONAL"
                  />
                </li>
                <li>
                  <img
                    src="/images/logo_discover.jpg"
                    className={styles.cardBrandLogo}
                    alt="Discover"
                  />
                </li>
              </ul>
            </Fieldset>
          )}
          <Fieldset legend={t('Billing Email Address')} option={false}>
            <Textfield onChange={onChangeEmail} errorText={emailError} />
          </Fieldset>
          <Fieldset legend={t('Billing Company Name')} option={false}>
            <Textfield onChange={onChangeCompany} errorText={companyError} />
          </Fieldset>
          <Fieldset legend={t('Billing Contact Name')} option={false}>
            <Textfield onChange={onChangeName} errorText={nameError} />
          </Fieldset>
          <Fieldset
            legend={t('Billing Phone Number(without hyphen)')}
            option={false}
          >
            <Textfield
              type="number"
              onChange={onChangePhone}
              errorText={phoneError}
            />
          </Fieldset>
        </Modal>
      </ChangePaymentInfoModal>
      <ChangeCardModal>
        <Modal title={t('Register Payment Details')}>
          {currentPlan.limit.enableSendInvoice && (
            <Fieldset legend={t('Payment Method')} option={false}>
              <div className={styles.radioGroup}>
                <div>
                  <label className={styles.radioLabel}>
                    <input
                      type="radio"
                      className={styles.radioBox}
                      checked={collectionMethod === CHARGE_AUTOMATICALLY}
                      onChange={() => doChangeRadio(CHARGE_AUTOMATICALLY)}
                    />
                    <span>{t('Credit Card')}</span>
                  </label>
                </div>
                <div>
                  <label className={styles.radioLabel}>
                    <input
                      type="radio"
                      className={styles.radioBox}
                      checked={collectionMethod === SEND_INVOICE}
                      onChange={() => doChangeRadio(SEND_INVOICE)}
                    />
                    <span>{t('Invoice Payment')}</span>
                  </label>
                </div>
              </div>
            </Fieldset>
          )}
          {collectionMethod === CHARGE_AUTOMATICALLY && (
            <Fieldset legend={t('Credit Card Details')} option={false}>
              <CardElement />
              <ul className={styles.cardBrands}>
                <li>
                  <img
                    src="/images/logo_visa.gif"
                    className={styles.cardBrandLogo}
                    alt="VISA"
                  />
                </li>
                <li>
                  <img
                    src="/images/logo_mastercard.gif"
                    className={styles.cardBrandLogo}
                    alt="MasterCard"
                  />
                </li>
                <li>
                  <img
                    src="/images/logo_jcb.gif"
                    className={styles.cardBrandLogo}
                    alt="JCB"
                  />
                </li>
                <li>
                  <img
                    src="/images/logo_american_express.png"
                    className={styles.cardBrandLogo}
                    alt="AmericanExpress"
                  />
                </li>
                <li>
                  <img
                    src="/images/logo_diners.gif"
                    className={styles.cardBrandLogo}
                    alt="Diners Club INTERNATIONAL"
                  />
                </li>
                <li>
                  <img
                    src="/images/logo_discover.jpg"
                    className={styles.cardBrandLogo}
                    alt="Discover"
                  />
                </li>
              </ul>
            </Fieldset>
          )}

          <Button
            type="primary"
            size="full"
            value={t('Register')}
            className={styles.modalActionButton}
            onClick={
              collectionMethod === CHARGE_AUTOMATICALLY
                ? doChangeCard
                : doChangeInvoice
            }
            disabled={changeCardLoading || changeBillingInvoiceLoading}
          />
          {currentPlan.name === 'Hobby' && (
            <Button
              type="danger"
              size="full"
              value={t('Delete credit card details')}
              className={styles.modalActionButton}
              onClick={doDeleteCard}
            />
          )}
        </Modal>
      </ChangeCardModal>
    </div>
  );
};

export default BillingSettings;
