import { API, graphqlOperation } from 'aws-amplify';

import type { AddonType, CancelSelect } from '@/entity/plan';
import type { ApiResponse } from '@/views/Common/handleApiResult';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

import * as mutations from '@/graphql/mutations';
import { isGraphQLResultErrorsObject } from '@/util/type-guard';
import {
  handleApiResult,
  handleApiError,
} from '@/views/Common/handleApiResult';

export const stripeRepository = () => {
  const changeCard = async ({
    serviceId,
    source,
  }: {
    serviceId: string;
    source: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.changeCard, {
          serviceId,
          source,
        }),
      )) as GraphQLResult<{
        changeCard: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.changeCard === 'object' &&
        typeof result.data.changeCard.result === 'boolean'
      ) {
        return handleApiResult(result.data.changeCard);
      } else if (typeof result.data.changeCard === 'string') {
        return JSON.parse(result.data.changeCard);
      }
      throw new Error('Unexpected Error');
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const deleteCard = async ({ serviceId }: { serviceId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.deleteCard, {
          serviceId,
        }),
      )) as GraphQLResult<{
        deleteCard: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.deleteCard === 'object' &&
        typeof result.data.deleteCard.result === 'boolean'
      ) {
        return handleApiResult(result.data.deleteCard);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const changePlanToBusiness = async ({ serviceId }: { serviceId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.changePlanToBusiness, {
          serviceId,
        }),
      )) as GraphQLResult<{
        // TODO:Go移植後にレスポンスの型をつける
        changePlanToBusiness: ApiResponse<unknown> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.changePlanToBusiness === 'object' &&
        typeof result.data.changePlanToBusiness.result === 'boolean'
      ) {
        return handleApiResult(result.data.changePlanToBusiness);
      } else if (typeof result.data.changePlanToBusiness === 'string') {
        return JSON.parse(result.data.changePlanToBusiness);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (isGraphQLResultErrorsObject(e)) {
        throw new Error(e.errors[0].message);
      }
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const changePlanToTeam = async ({ serviceId }: { serviceId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.changePlanToTeam, {
          serviceId,
        }),
      )) as GraphQLResult<{
        // TODO:Go移植後にレスポンスの型をつける
        changePlanToTeam: ApiResponse<unknown> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.changePlanToTeam === 'object' &&
        typeof result.data.changePlanToTeam.result === 'boolean'
      ) {
        return handleApiResult(result.data.changePlanToTeam);
      } else if (typeof result.data.changePlanToTeam === 'string') {
        return JSON.parse(result.data.changePlanToTeam);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (isGraphQLResultErrorsObject(e)) {
        throw new Error(e.errors[0].message);
      }
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const changePlanToAdvanced = async ({ serviceId }: { serviceId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.changePlanToAdvanced, {
          serviceId,
        }),
      )) as GraphQLResult<{
        // TODO:Go移植後にレスポンスの型をつける
        changePlanToAdvanced: ApiResponse<unknown> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.changePlanToAdvanced === 'object' &&
        typeof result.data.changePlanToAdvanced.result === 'boolean'
      ) {
        return handleApiResult(result.data.changePlanToAdvanced);
      } else if (typeof result.data.changePlanToAdvanced === 'string') {
        return JSON.parse(result.data.changePlanToAdvanced);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (isGraphQLResultErrorsObject(e)) {
        throw new Error(e.errors[0].message);
      }
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const changePlanToHobby = async ({ serviceId }: { serviceId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.changePlanToHobby, {
          serviceId,
        }),
      )) as GraphQLResult<{
        // TODO:Go移植後にレスポンスの型をつける
        changePlanToHobby: ApiResponse<unknown> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.changePlanToHobby === 'object' &&
        typeof result.data.changePlanToHobby.result === 'boolean'
      ) {
        return handleApiResult(result.data.changePlanToHobby);
      } else if (typeof result.data.changePlanToHobby === 'string') {
        return JSON.parse(result.data.changePlanToHobby);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (isGraphQLResultErrorsObject(e)) {
        throw new Error(e.errors[0].message);
      }
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };
  const changePlanToTemplate = async ({ serviceId }: { serviceId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.changePlanToTemplate, {
          serviceId,
        }),
      )) as GraphQLResult<{
        // TODO:Go移植後にレスポンスの型をつける
        changePlanToTemplate: ApiResponse<unknown> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.changePlanToTemplate === 'object' &&
        typeof result.data.changePlanToTemplate.result === 'boolean'
      ) {
        return handleApiResult(result.data.changePlanToTemplate);
      } else if (typeof result.data.changePlanToTemplate === 'string') {
        return JSON.parse(result.data.changePlanToTemplate);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const changeBillingEmail = async ({
    serviceId,
    email,
  }: {
    serviceId: string;
    email: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.changeBillingEmail, {
          serviceId,
          email,
        }),
      )) as GraphQLResult<{
        changeBillingEmail: ApiResponse | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.changeBillingEmail === 'object' &&
        typeof result.data.changeBillingEmail.result === 'boolean'
      ) {
        return handleApiResult(result.data.changeBillingEmail);
      } else if (typeof result.data.changeBillingEmail === 'string') {
        return JSON.parse(result.data.changeBillingEmail);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const changeBillingName = async ({
    serviceId,
    name,
  }: {
    serviceId: string;
    name: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.changeBillingName, {
          serviceId,
          name,
        }),
      )) as GraphQLResult<{
        changeBillingName: ApiResponse | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.changeBillingName === 'object' &&
        typeof result.data.changeBillingName.result === 'boolean'
      ) {
        return handleApiResult(result.data.changeBillingName);
      } else if (typeof result.data.changeBillingName === 'string') {
        return JSON.parse(result.data.changeBillingName);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const changeBillingPhone = async ({
    serviceId,
    phone,
  }: {
    serviceId: string;
    phone: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.changeBillingPhone, {
          serviceId,
          phone,
        }),
      )) as GraphQLResult<{
        changeBillingPhone: ApiResponse | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.changeBillingPhone === 'object' &&
        typeof result.data.changeBillingPhone.result === 'boolean'
      ) {
        return handleApiResult(result.data.changeBillingPhone);
      } else if (typeof result.data.changeBillingPhone === 'string') {
        return JSON.parse(result.data.changeBillingPhone);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const changeBillingCompany = async ({
    serviceId,
    company,
  }: {
    serviceId: string;
    company: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.changeBillingCompany, {
          serviceId,
          company,
        }),
      )) as GraphQLResult<{
        changeBillingCompany: ApiResponse | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.changeBillingCompany === 'object' &&
        typeof result.data.changeBillingCompany.result === 'boolean'
      ) {
        return handleApiResult(result.data.changeBillingCompany);
      } else if (typeof result.data.changeBillingCompany === 'string') {
        return JSON.parse(result.data.changeBillingCompany);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const purchaseAddons = async ({
    serviceId,
    type,
  }: {
    serviceId: string;
    type: AddonType;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.purchaseAddons, {
          serviceId,
          type,
        }),
      )) as GraphQLResult<{
        purchaseAddons: ApiResponse | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.purchaseAddons === 'object' &&
        typeof result.data.purchaseAddons.result === 'boolean'
      ) {
        return handleApiResult(result.data.purchaseAddons);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const cancelSubscription = async ({
    serviceId,
    cancelSelect,
    cancelReason,
  }: {
    serviceId: string;
    cancelSelect: CancelSelect;
    cancelReason: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.cancelSubscription, {
          serviceId,
          cancelSelect,
          cancelReason,
        }),
      )) as GraphQLResult<{
        cancelSubscription: ApiResponse | boolean;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.cancelSubscription === 'object' &&
        typeof result.data.cancelSubscription.result === 'boolean'
      ) {
        return handleApiResult(result.data.cancelSubscription);
      } else if (typeof result.data.cancelSubscription === 'boolean') {
        return result.data.cancelSubscription;
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      handleApiError(e);
    }
  };

  const restartSubscription = async ({
    serviceId,
    sourceId,
    email,
    name,
    phone,
    company,
  }: {
    serviceId: string;
    sourceId: string | null;
    email: string;
    name: string;
    phone: string;
    company: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.restartSubscription, {
          serviceId,
          source: sourceId,
          email,
          name,
          phone,
          company,
        }),
      )) as GraphQLResult<{
        restartSubscription: ApiResponse | boolean;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.restartSubscription === 'object' &&
        typeof result.data.restartSubscription.result === 'boolean'
      ) {
        return handleApiResult(result.data.restartSubscription);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      handleApiError(e);
    }
  };

  const changeBillingInvoice = async ({ serviceId }: { serviceId: string }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.changeBillingInvoice, {
          serviceId,
        }),
      )) as GraphQLResult<{
        changeBillingInvoice: ApiResponse | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.changeBillingInvoice === 'object' &&
        typeof result.data.changeBillingInvoice.result === 'boolean'
      ) {
        return handleApiResult(result.data.changeBillingInvoice);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  return {
    changeCard,
    deleteCard,
    changePlanToBusiness,
    changePlanToTeam,
    changePlanToAdvanced,
    changePlanToHobby,
    changePlanToTemplate,
    changeBillingEmail,
    changeBillingName,
    changeBillingPhone,
    changeBillingCompany,
    purchaseAddons,
    cancelSubscription,
    restartSubscription,
    changeBillingInvoice,
  };
};
