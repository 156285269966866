import { useCallback } from 'react';

import { useContentCapacityWrite } from './ContentCapacityProvider';

import type { ContentValue } from '@/entity/content';

import throttle from '@/views/apis/content/ContentCapacity/throttle';

const calcContentValueSize = (value: unknown) => {
  // 再帰的にオブジェクトを走査して、キーと値を文字列に連結していく
  const recursive = (value: unknown, characters: string): string => {
    if (Array.isArray(value)) {
      return value.reduce((acc, v) => {
        return recursive(v, acc);
      }, characters);
    }

    if (typeof value === 'object' && value !== null) {
      return Object.keys(value).reduce((acc, key) => {
        return recursive((value as Record<string, unknown>)[key], acc + key);
      }, characters);
    }

    if (
      typeof value === 'string' ||
      typeof value === 'bigint' ||
      typeof value === 'number' ||
      typeof value === 'boolean'
    ) {
      return characters + value;
    }

    return characters;
  };

  const characters = recursive(value, '');

  return new TextEncoder().encode(characters).length;
};

/**
 * 196KB
 * 200KB - 4KB(4KBはコンテンツデータ以外のpartitionKeyやsortKeyなどのデータを考慮した値)
 */
const CONTENT_LIMIT_BYTE = 200 * 1024 - 4 * 1024;

const getCapacityPercentage = (contentSize: number) => {
  // パーセント表記にして整数に切り捨てる
  const capacityPercentageOriginal =
    100 - Math.floor((contentSize / CONTENT_LIMIT_BYTE) * 100);
  // コンテンツ空き容量が0%未満にならないようにする
  return Math.max(capacityPercentageOriginal, 0);
};

const useSetContentCapacity = (getContent: () => ContentValue) => {
  const contentCapacityWrite = useContentCapacityWrite();

  const setContentCapacity = useCallback(() => {
    const contentSize = calcContentValueSize(getContent());
    const capacityPercentage = getCapacityPercentage(contentSize);

    contentCapacityWrite(capacityPercentage);
  }, [contentCapacityWrite, getContent]);

  return throttle(1000, setContentCapacity);
};

export { calcContentValueSize, getCapacityPercentage, useSetContentCapacity };
