import { useState, useCallback } from 'react';

import { useLanguageSettingsQuery } from './useLanguageSettingsQuery';

import type { LanguageSetting } from '@/entity/serviceLanguage';
import type { Service } from '@/types/services';

import { isServiceLanguage } from '@/entity/serviceLanguage';

export const useLanguageSettings = (partitionKey: Service['partitionKey']) => {
  const { useGetServiceLanguage, useUpdateServiceLanguage } =
    useLanguageSettingsQuery();

  const [language, setLanguage] = useState<LanguageSetting>(null);

  const {
    mutate: updateServiceLanguage,
    isLoading: updateServiceLanguageLoading,
  } = useUpdateServiceLanguage();

  const { data: serviceLanguage, isLoading: serviceLanguageLoading } =
    useGetServiceLanguage(partitionKey);

  const onChangeLanguage = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (isServiceLanguage(e.target?.value)) {
        return setLanguage(e.target.value);
      }
      setLanguage(null);
    },
    [],
  );

  const onUpdateServiceLanguage = useCallback(() => {
    updateServiceLanguage({
      serviceId: partitionKey,
      serviceLanguage: language === null ? serviceLanguage || 'ja' : language,
    });
  }, [serviceLanguage, updateServiceLanguage, language, partitionKey]);

  return {
    language,
    serviceLanguage,
    serviceLanguageLoading,
    updateServiceLanguageLoading,
    onChangeLanguage,
    onUpdateServiceLanguage,
  };
};
