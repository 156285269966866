import type { MigrateApi } from '@/entity/api';
import type { ReviewRequest } from '@/entity/review';
import { useGetContent } from '@/hooks/Content/useContentWriter';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Button from '../ui/Button';
import ReviewStatus from '../ui/ReviewStatus';
import styles from './header.module.css';

type Props = {
  api?: MigrateApi | undefined;
  contentId?: string | undefined;
  reviewRequest: ReviewRequest;
  // getContentsByIdで取得したデータのため、コンテンツ一覧で取得できるようなデータになっている
  data: any;
  toggleReviewRequestStatus: () => void;
  toggleReviewRequestStatusLoading: boolean;
  closeReviewRequestAndPublishContentLoading: boolean;
  closeAndPublish: () => void;
  hasEditReviewUpdatePermission: boolean;
  hasAllReviewUpdatePermission: boolean;
};

const Header: React.FC<Props> = ({
  api,
  contentId,
  reviewRequest,
  data,
  toggleReviewRequestStatus,
  toggleReviewRequestStatusLoading,
  closeReviewRequestAndPublishContentLoading,
  closeAndPublish,
  hasEditReviewUpdatePermission,
  hasAllReviewUpdatePermission,
}) => {
  const { t } = useTranslation('editReview');

  const [contentData] = useGetContent(api, contentId);

  const history = useHistory();
  // コンテンツプレビュー
  const preview = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (!api || !contentId) return;

      if (api?.apiPreviewUrl) {
        // Web遷移
        const url = api.apiPreviewUrl
          .replace('{CONTENT_ID}', contentId)
          .replace('{DRAFT_KEY}', data.contentDraftKey || '');
        window.open(url);
        e.preventDefault();

        // Analytics
        window.ga && window.ga('send', 'event', 'preview', 'show', url);
        return;
      }
      window.alert(
        t(
          'Page preview has not been configured. Contact developer or enter preview page URL in the settings page.',
        ),
      );
      e.preventDefault();
    },
    [api, contentId, data, t],
  );

  const historyBack = useCallback(() => {
    if (history.action === 'POP') {
      history.push(`/reviews`);
    } else {
      history.goBack();
    }
  }, [history]);

  return (
    <header className={styles.header}>
      <div className={styles.left}>
        <button
          type="button"
          className={styles.backButton}
          onClick={historyBack}
        >
          <i className="material-icons-outlined">arrow_back_ios</i>
        </button>
        <ReviewStatus status={reviewRequest.status} />
      </div>
      <div className={styles.buttons}>
        {api &&
          contentId &&
          reviewRequest.status === 'OPEN' &&
          (api.apiPreviewLinkIsVisible ||
            api.apiPreviewLinkIsVisible === null) && (
            <Link
              className={styles.linkButton}
              onClick={preview}
              to={`/apis/${api.apiEndpoint}/settings/preview`}
            >
              <i className="material-icons-outlined">open_in_browser</i>
              <span className={styles.pcOnly}>{t('Page Preview')}</span>
            </Link>
          )}
        {hasEditReviewUpdatePermission && reviewRequest.status === 'OPEN' && (
          <button
            type="button"
            className={styles.linkButton}
            onClick={toggleReviewRequestStatus}
            disabled={toggleReviewRequestStatusLoading}
          >
            <i className="material-icons-outlined">assignment_turned_in</i>
            <span className={styles.pcOnly}>{t('Unpublish')}</span>
          </button>
        )}
        {hasEditReviewUpdatePermission &&
          reviewRequest.status === 'CLOSE' &&
          data &&
          // TODO：dataがReact Queryにリプレイスされたら data.reservationTimeで参照する
          !contentData?.reservationTime &&
          data.contentStatus !== 'PUBLISH' && (
            <button
              type="button"
              className={styles.linkButton}
              onClick={toggleReviewRequestStatus}
              disabled={toggleReviewRequestStatusLoading}
            >
              <i className="material-icons-outlined">assignment</i>
              <span className={styles.pcOnly}>{t('Re-request')}</span>
            </button>
          )}
        {hasAllReviewUpdatePermission && reviewRequest.status === 'OPEN' && (
          <Button
            type="primary"
            icon={reviewRequest?.reservationTime ? 'schedule' : 'publish'}
            onClick={closeAndPublish}
            disabled={closeReviewRequestAndPublishContentLoading}
            value={
              reviewRequest?.reservationTime
                ? t('Approve and Schedule Publishing')
                : t('Approve and Publish')
            }
          />
        )}
      </div>
    </header>
  );
};

export { Header };
