import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

export const useQueryParam = (name: string, defaultValue = '') => {
  const location = useLocation();
  const rawValue = queryString.parse(location.search)[name];
  return (Array.isArray(rawValue) ? rawValue[0] : rawValue) || defaultValue;
};

export const useUpdateQueryParam = (name: string) => {
  const location = useLocation();
  const history = useHistory();

  return (value: string) => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.set(name, value);

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };
};

export const useDeleteQueryParam = (name: string) => {
  const location = useLocation();
  const history = useHistory();

  return () => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.delete(name);

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };
};
