import * as RadixUISelect from '@radix-ui/react-select';

import styles from './select.module.css';

export type SelectItem<V extends string> = {
  value: V;
  label: string;
  description?: string;
};
export type SelectItems<V extends string> = Array<SelectItem<V>>;

type Props<V extends string> = {
  value: V;
  onValueChange(value: V): void;
  name: string;
  disabled?: boolean;
  items: SelectItems<V>;
};

export function SelectView<V extends string>({
  value,
  onValueChange,
  name,
  disabled,
  items,
}: Props<V>) {
  return (
    <RadixUISelect.Root
      value={value}
      onValueChange={onValueChange}
      name={name}
      disabled={disabled}
    >
      <RadixUISelect.Trigger className={styles.trigger}>
        <RadixUISelect.Value />
        <RadixUISelect.Icon className={styles.icon}>
          <span className="material-icons-outlined">keyboard_arrow_down</span>
        </RadixUISelect.Icon>
      </RadixUISelect.Trigger>

      <RadixUISelect.Portal>
        <RadixUISelect.Content className={styles.content}>
          <RadixUISelect.ScrollUpButton className={styles.scrollButton}>
            <span className="material-icons-outlined">keyboard_arrow_up</span>
          </RadixUISelect.ScrollUpButton>
          <RadixUISelect.Viewport className={styles.viewport}>
            {items.map(({ value, label, description }) => (
              <RadixUISelect.Item
                key={value}
                value={value}
                className={styles.item}
              >
                <RadixUISelect.ItemText>{label}</RadixUISelect.ItemText>
                {description && (
                  <p className={styles.description}>{description}</p>
                )}
              </RadixUISelect.Item>
            ))}
          </RadixUISelect.Viewport>
          <RadixUISelect.ScrollDownButton className={styles.scrollButton}>
            <span className="material-icons-outlined">keyboard_arrow_down</span>
          </RadixUISelect.ScrollDownButton>
        </RadixUISelect.Content>
      </RadixUISelect.Portal>
    </RadixUISelect.Root>
  );
}

export function Select<V extends string>(props: Props<V>) {
  return <SelectView {...props} />;
}
