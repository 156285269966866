/* tslint:disable */
/* eslint-disable */
/**
 * マネジメントAPI
 * マネジメントAPI
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * ヘルスチェック
 * @export
 * @interface HealthcheckResponse
 */
export interface HealthcheckResponse {
  /**
   *
   * @type {string}
   * @memberof HealthcheckResponse
   */
  message: string;
}

export function HealthcheckResponseFromJSON(json: any): HealthcheckResponse {
  return HealthcheckResponseFromJSONTyped(json, false);
}

export function HealthcheckResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): HealthcheckResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: json['message'],
  };
}

export function HealthcheckResponseToJSON(
  value?: HealthcheckResponse | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
  };
}
