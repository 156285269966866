import { getTranslation } from '@/i18n';
import { isUrl } from '@/util/is-url';

export const validateImageSize = (width: number, height: number) => {
  const { t } = getTranslation('richEditorV2');
  if (width < 1 || height < 1) {
    return t('Size is too small');
  }
  if (
    !String(width).match(/^([1-9]\d*|0)$/) ||
    !String(height).match(/^([1-9]\d*|0)$/)
  ) {
    return t('Enter an integer greater than or equal to 1');
  }
};
