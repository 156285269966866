import cx from 'classnames';
import { customAlphabet } from 'nanoid';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectTemplate } from './SelectTemplate';
import { useInput } from '../../hooks';
import { usePostService } from '../../hooks/Service/useServiceWriter';
import { getPureHost } from '../../util';
import Head from '../Head';
import Button from '../ui/Button';
import Fieldset from '../ui/Fieldset';
import Textfield from '../ui/Textfield';
import { validateServiceName, validateServiceDomain } from '../Validations';

import styles from './createService.module.css';

const CreateService = () => {
  const { t } = useTranslation('createService');
  const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 10);
  const [domain, setDomain, domainError] = useInput(
    nanoid(),
    // @ts-expect-error
    validateServiceDomain,
  );
  // @ts-expect-error
  const [name, setName, nameError] = useInput('Untitled', validateServiceName);

  const { postService, postServiceLoading } = usePostService();

  const createService = useCallback(() => {
    // @ts-expect-error
    postService({ serviceName: name, serviceDomain: domain });
  }, [domain, name, postService]);

  const [step, setStep] = useState(0);

  if (step === 0) {
    return <SelectTemplate setStep={setStep} />;
  }

  return (
    <div className={styles.wrapper}>
      <Head title={t('Service/Create')} />
      <h2 className={styles.title}>{t('Create new service')}</h2>
      <div className={styles.container}>
        <Fieldset
          legend={t('Service Name')}
          description={t(
            'Enter the name of your organization or product. You can change it later.',
          )}
        >
          <Textfield
            type="text"
            defaultValue={name}
            onChange={setName}
            errorText={nameError}
            dataTestid="create-service-textfield-service-name"
          />
        </Fieldset>
        <Fieldset
          legend={t('Service ID')}
          description={t('Enter a new Service ID. You can change it later.')}
        >
          <div className={styles.flexField}>
            <Textfield
              className={styles.serviceId}
              type="text"
              defaultValue={domain}
              onChange={setDomain}
              errorText={domainError}
              dataTestid="create-service-textfield-service-id"
            />
            <span>.{getPureHost()}</span>
          </div>
        </Fieldset>
      </div>
      <Button
        type="primary"
        size="large"
        className={cx('ga-create-service-basic-info', styles.actionButton)}
        value={t('Create service')}
        disabled={domainError || nameError || postServiceLoading}
        onClick={createService}
      />
    </div>
  );
};

export default CreateService;
