import React, { useContext, useMemo } from 'react';

import { myRolesContext } from '../../hooks/Role/useMyRoles';
import Forbidden from '../Forbidden';

const CheckRoles = ({
  children,
  permission,
  operation = 'read',
  display = false,
}) => {
  const context = useContext(myRolesContext);
  const visible = useMemo(() => {
    return context.roles
      ? context.roles.some(
          (role) =>
            role[permission][operation] === true ||
            role[permission][operation] === 'ALL' ||
            role[permission][operation] === 'CREATED' ||
            role[permission][operation] === 'SAME_ROLE',
        )
      : undefined;
  }, [context.roles, operation, permission]);
  if (visible === undefined) {
    return null;
  }
  if (visible === false && display === false) {
    return null;
  }
  // display === trueの場合はロック画面を表示
  if (visible === false && display === true) {
    return <Forbidden />;
  }
  return children;
};

export default CheckRoles;
