import { mediumTagRepository } from '@/repository/mediumTagRepository';

import type { NewMediumTag } from '@/types/MediumTag';

export const createMediumTags = async ({
  serviceId,
  newMediumTags,
}: {
  serviceId: string;
  newMediumTags: NewMediumTag[];
}) => {
  const { createMediumTags } = mediumTagRepository();

  return await createMediumTags({
    serviceId,
    newMediumTags,
  });
};

export const updateMediumTags = async ({
  serviceId,
  mediumId,
  tags,
}: {
  serviceId: string;
  mediumId: string;
  tags: string[];
}) => {
  const { updateMediumTags } = mediumTagRepository();

  return await updateMediumTags({
    serviceId,
    mediumId,
    tags,
  });
};
