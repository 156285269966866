/* tslint:disable */
/* eslint-disable */
/**
 * マネジメントAPI
 * マネジメントAPI
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  type ContentReservationTime,
  ContentReservationTimeFromJSON,
  ContentReservationTimeFromJSONTyped,
  ContentReservationTimeToJSON,
} from './ContentReservationTime';
import {
  type CustomStatus,
  CustomStatusFromJSON,
  CustomStatusFromJSONTyped,
  CustomStatusToJSON,
} from './CustomStatus';

/**
 * コンテンツモデル
 * @export
 * @interface Content
 */
export interface Content {
  /**
   *
   * @type {string}
   * @memberof Content
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof Content
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof Content
   */
  updatedAt: Date;
  /**
   *
   * @type {Date}
   * @memberof Content
   */
  publishedAt: Date | null;
  /**
   *
   * @type {Date}
   * @memberof Content
   */
  closedAt: Date | null;
  /**
   *
   * @type {Date}
   * @memberof Content
   */
  revisedAt: Date | null;
  /**
   *
   * @type {Array<CustomStatus>}
   * @memberof Content
   */
  customStatus?: Array<CustomStatus> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof Content
   */
  status: Array<ContentStatusEnum>;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  draftKey: string | null;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  createdBy: string;
  /**
   *
   * @type {string}
   * @memberof Content
   */
  updatedBy: string;
  /**
   *
   * @type {ContentReservationTime}
   * @memberof Content
   */
  reservationTime: ContentReservationTime | null;
}

/**
 * @export
 * @enum {string}
 */
export enum ContentStatusEnum {
  Publish = 'PUBLISH',
  Draft = 'DRAFT',
  PublishAndDraft = 'PUBLISH_AND_DRAFT',
  Closed = 'CLOSED',
}

export function ContentFromJSON(json: any): Content {
  return ContentFromJSONTyped(json, false);
}

export function ContentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): Content {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    createdAt: new Date(json['createdAt']),
    updatedAt: new Date(json['updatedAt']),
    publishedAt:
      json['publishedAt'] === null ? null : new Date(json['publishedAt']),
    closedAt: json['closedAt'] === null ? null : new Date(json['closedAt']),
    revisedAt: json['revisedAt'] === null ? null : new Date(json['revisedAt']),
    customStatus: !exists(json, 'customStatus')
      ? undefined
      : json['customStatus'] === null
        ? null
        : (json['customStatus'] as Array<any>).map(CustomStatusFromJSON),
    status: json['status'],
    draftKey: json['draftKey'],
    createdBy: json['createdBy'],
    updatedBy: json['updatedBy'],
    reservationTime: ContentReservationTimeFromJSON(json['reservationTime']),
  };
}

export function ContentToJSON(value?: Content | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    createdAt: value.createdAt.toISOString(),
    updatedAt: value.updatedAt.toISOString(),
    publishedAt:
      value.publishedAt === null ? null : value.publishedAt.toISOString(),
    closedAt: value.closedAt === null ? null : value.closedAt.toISOString(),
    revisedAt: value.revisedAt === null ? null : value.revisedAt.toISOString(),
    customStatus:
      value.customStatus === undefined
        ? undefined
        : value.customStatus === null
          ? null
          : (value.customStatus as Array<any>).map(CustomStatusToJSON),
    status: value.status,
    draftKey: value.draftKey,
    createdBy: value.createdBy,
    updatedBy: value.updatedBy,
    reservationTime: ContentReservationTimeToJSON(value.reservationTime),
  };
}
