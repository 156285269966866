import { handleActions } from 'redux-actions';

import actions from './actions.js';

const initialState = {};

const reducer = handleActions(
  {
    [actions.onSignin]: (state, { payload: { cognitoUser } }) => ({
      ...state,
      cognitoUser,
    }),
    [actions.onSignout]: (state) => ({
      ...state,
      cognitoUser: null,
    }),
    [actions.getAttributes]: (state, { payload: { attrs } }) => ({
      ...state,
      attrs,
    }),
    [actions.getCognitoUser]: (state, { payload: { cognitoUser } }) => ({
      ...state,
      cognitoUser,
    }),
  },
  initialState,
);

export default reducer;
