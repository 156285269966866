import { Td } from '@/components/ui/Table';

import { FIELD_NO_VALUE } from '../../../constants';
import { useI18nFormRole } from '../../../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../../../provider';

import type { PropsWithECP } from '../_types';
import type { FC } from 'react';

import { Checkbox } from '@/views/Common/Ui/Checkbox';

const FieldApi: FC<PropsWithECP> = ({ targetECP: { id, permission } }) => {
  const { t } = useI18nFormRole();

  const { isAdminRole } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  return (
    <>
      <Td>{t('API')}</Td>
      <Td>
        <Checkbox
          name="apiRead"
          onChange={(e) => {
            const nextValue = e.target.checked;
            dispatch({
              type: 'set-ec-permission-apiread',
              payload: { id, nextValue },
            });
          }}
          checked={permission.apiRead}
          disabled={disabled}
          label={t('Reading the API\n(Sidebar, Content Reference)')}
        />
      </Td>
      <Td>{FIELD_NO_VALUE}</Td>
      <Td>
        <Checkbox
          name="apiUpdate"
          onChange={(e) => {
            const nextValue = e.target.checked;
            dispatch({
              type: 'set-ec-permission-apiupdate',
              payload: { id, nextValue },
            });
          }}
          checked={permission.apiUpdate}
          disabled={disabled}
          label={t('API Settings and\ncreate / edit / delete Custom fields')}
        />
      </Td>
      <Td>
        <Checkbox
          name="apiDelete"
          onChange={(e) => {
            const nextValue = e.target.checked;
            dispatch({
              type: 'set-ec-permission-apidelete',
              payload: { id, nextValue },
            });
          }}
          checked={permission.apiDelete}
          disabled={disabled}
          label={t('Delete API')}
        />
      </Td>
    </>
  );
};

export default FieldApi;
