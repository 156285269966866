import Quill from 'quill';
const Link = Quill.import('formats/link');

export default class LinkFormat extends Link {
  static create(value) {
    const href = typeof value === 'string' ? value : value.href;
    const node = super.create(href);
    value = this.sanitize(href);
    node.setAttribute('href', href);
    if (
      value &&
      (value.startsWith('https://') || value.startsWith('http://'))
    ) {
      node.className = 'link--external';
    } else {
      node.removeAttribute('target');
      node.removeAttribute('rel');
    }
    return node;
  }
  static formats(domNode) {
    return {
      href: domNode.getAttribute('href'),
      target: domNode.getAttribute('target'),
      rel: domNode.getAttribute('rel'),
    };
  }
}
