import { Auth } from 'aws-amplify';

import { mediumRepository } from '@/repository/mediumRepository';
import { serviceRepository } from '@/repository/serviceRepository';
import { storageRepository } from '@/repository/storageRepository';

import type { contents as Contents } from '@/API';
import type { Medium } from '@/entity/medium';

import { MetaContext } from '@/context/MetaContext';
import { getServiceDomain } from '@/util';

type FindMediumArgs = {
  serviceId?: string | null;
  mediumId?: string | null;
};
export const findMedium = async ({ serviceId, mediumId }: FindMediumArgs) => {
  const { findMedium } = mediumRepository();

  if (!serviceId || !mediumId) {
    return null;
  }

  return findMedium(serviceId, mediumId);
};

const getImageHost = (
  customDomainImageHost: Contents['customDomainImageHost'],
) => {
  if (customDomainImageHost) {
    return customDomainImageHost;
  }

  return MetaContext.value.imageHost;
};

type GetMediumAttributesArgs = {
  serviceId: string;
  mediumId: Medium['mediumId'];
};

export const getMediumAttributes = async ({
  serviceId,
  mediumId,
}: GetMediumAttributesArgs) => {
  const { findMedium } = mediumRepository();
  const { findServiceByDomain } = serviceRepository();
  const domain = getServiceDomain();

  const medium = await findMedium(serviceId, mediumId);
  const service = await findServiceByDomain(domain);

  if (medium === null) {
    return null;
  }

  const imageHost = getImageHost(service?.customDomainImageHost);

  return {
    src: `https://${imageHost}/${medium.directory}/${encodeURIComponent(
      medium.fileName,
    )}`,
    width: medium?.imageWidth,
    height: medium?.imageHeight,
  };
};

type UploadMediumArgs = {
  serviceId?: string | null;
  domain?: string | null;
  files: FileList | File[];
  /**
   * それぞれのファイルのアップロードが成功したときに呼ばれる
   * @param mediumId
   * @returns
   */
  onSuccessEach?: (mediumId: string | null) => void;
};

export const uploadMedium = async ({
  serviceId,
  domain,
  files,
  onSuccessEach,
}: UploadMediumArgs) => {
  if (!serviceId || !domain) {
    return null;
  }

  const identityId = (await Auth.currentUserInfo()).id;

  const { addMedium } = mediumRepository();
  const { upload } = storageRepository();

  const resultMediumIds = await Promise.all(
    Array.from(files).map(async (file) => {
      const fileName = `service/${domain}/media/${file.name}`;

      //S3にアップロード
      await upload(file, fileName);

      //データ更新
      const mediumId = await addMedium({
        serviceId,
        fileName: file.name,
        identityId,
      });

      onSuccessEach?.(mediumId);

      return mediumId;
    }),
  );

  return resultMediumIds;
};

export const updateMedium = async ({
  serviceId,
  mediumId,
  filePath,
}: {
  serviceId: string;
  mediumId: string;
  filePath: string;
}) => {
  const { updateMedium } = mediumRepository();

  return await updateMedium({
    serviceId,
    mediumId,
    filePath,
  });
};

export const updateMediumCreatedBy = async ({
  serviceId,
  mediumId,
  createdBy,
}: {
  serviceId: string;
  mediumId: string;
  createdBy: string;
}) => {
  const { updateMediumCreatedBy } = mediumRepository();

  return await updateMediumCreatedBy({
    serviceId,
    mediumId,
    createdBy,
  });
};

export const deleteAllMedia = async ({ serviceId }: { serviceId: string }) => {
  const { deleteAllMedia } = mediumRepository();
  return await deleteAllMedia({ serviceId });
};
