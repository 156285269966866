import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLoadApis } from '../../../hooks/ApiList/useApiListReader';
import { useGetContentsByIdandApiId } from '../../../hooks/Content/useGetContentsByIdandApiId';
import { useAllCustomStatuses } from '../../../hooks/CustomStatus/useCustomStatus';
import { formatDateToMinutes } from '../../../util/date';
import ContentStatus from '../../ui/ContentStatus';

import type { Service } from '../../../types/services';

import styles from './relatedContentsModal.module.css';

type Props = {
  service: Service;
  contentIds: string[];
  close?: () => void;
};

const RelatedContentsModal: React.FC<Props> = ({
  service,
  contentIds,
  close,
}) => {
  const { t } = useTranslation('relatedContentsModal');
  const { apiList } = useLoadApis(service);
  const { data: contents, loading } = useGetContentsByIdandApiId(contentIds);
  const { data: allCustomStatues } = useAllCustomStatuses(
    apiList && apiList.list,
  );

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead className={styles.thead}>
          <tr>
            <th className={styles.th}>{t('Status')}</th>
            <th className={styles.th}>{t('Content(API)')}</th>
            <th className={styles.th}>{t('Content ID')}</th>
            <th className={styles.th}>{t('Date Created')}</th>
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {contents.map((content) => {
            const contentsId = content.gsiPartitionKey1.replace('#CONTENT', '');
            return (
              <React.Fragment key={content.contentId}>
                {apiList &&
                  apiList.list
                    .filter((api) => contentsId === api.partitionKey)
                    .map((api) => {
                      return (
                        <React.Fragment key={content.contentId}>
                          <tr className={styles.tr}>
                            <td className={styles.td}>
                              <Link
                                to={`/apis/${api.apiEndpoint}/${content.contentId}`}
                                href={t('Move to content', {
                                  contentId: content.contentId,
                                })}
                                className={styles.linkStatus}
                                onClick={() => close && close()}
                              >
                                <ContentStatus
                                  status={content.contentStatus}
                                  reservationTime={content.reservationTime}
                                  reservationStopTime={
                                    content.reservationStopTime
                                  }
                                  reviewRequestId={content.reviewRequestId}
                                  customStatus={allCustomStatues as any}
                                  contentCustomStatusIds={
                                    content.contentCustomStatusIds
                                  }
                                />
                              </Link>
                            </td>
                            <td className={styles.td}>
                              <Link
                                to={`/apis/${api.apiEndpoint}/${content.contentId}`}
                                href={t('Move to content', {
                                  contentId: content.contentId,
                                })}
                                className={styles.link}
                                onClick={() => close && close()}
                              >
                                {api.apiName}
                              </Link>
                            </td>
                            <td className={styles.td}>
                              <Link
                                to={`/apis/${api.apiEndpoint}/${content.contentId}`}
                                href={t('Move to content', {
                                  contentId: content.contentId,
                                })}
                                className={styles.link}
                                onClick={() => close && close()}
                              >
                                {api.apiType === 'LIST'
                                  ? content.contentId
                                  : '-'}
                              </Link>
                            </td>
                            <td className={styles.td}>
                              <Link
                                to={`/apis/${api.apiEndpoint}/${content.contentId}`}
                                href={t('Move to content', {
                                  contentId: content.contentId,
                                })}
                                className={styles.link}
                                onClick={() => close && close()}
                              >
                                {formatDateToMinutes(content.createdAt)}
                              </Link>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {contents.length === 0 && loading === false && (
        <div className={styles.notContents}>
          <p className={styles.notContentsText}>
            {t('No browsable contents ')}
          </p>
        </div>
      )}
      <p className={styles.text}>
        {t('Only contents with read permission are displayed')}
      </p>
    </div>
  );
};

export default RelatedContentsModal;
