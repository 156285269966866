import { Auth, Hub } from 'aws-amplify';

import actions from './actions';

export const subscribeAuth = () => (dispatch) => {
  Hub.listen('auth', ({ payload: { event, data } }) => {
    switch (event) {
      case 'signIn':
        dispatch(actions.onSignin(data));
        break;
      case 'signOut':
        dispatch(actions.onSignout());
        break;
      default:
        break;
    }
  });

  Auth.currentAuthenticatedUser()
    .then((user) => dispatch(actions.onSignin(user)))
    .catch((err) => dispatch(actions.onSignout()));
};

export const unsubscribeAuth = () => (dispatch) => {
  Hub.remove('auth');
};

export const getAttributes = () => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();

  Auth.userAttributes(user).then((attrs) => {
    dispatch(actions.getAttributes(attrs));
  });
};

export const getCognitoUser = () => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  dispatch(actions.getCognitoUser(user));
};
