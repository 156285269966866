import { useMemo, useContext } from 'react';

import { myRolesContext } from '@/hooks/Role/useMyRoles';

import type { Role } from '@/entity/role';

type ReviewRequestPermissionArgs = {
  apiIds: string[];
  roles: Role[] | undefined;
  reviewAuthorId?: string;
  username?: string;
};

export const checkReviewRequestReadPermission = ({
  apiIds,
  roles,
  reviewAuthorId,
  username,
}: ReviewRequestPermissionArgs): boolean => {
  if (!roles) {
    return false;
  }

  const readLevels = roles.map((role) => {
    // 個別権限にALL,CREATED,SAME_ROLE権限が存在する場合は、個別権限を優先する
    const canReadReviewRequestPermission =
      role.exceptionContentPermissions?.find((ecp) => {
        const isExistApiPermission = apiIds?.some((apiId) => {
          return (
            ecp.apiId === apiId &&
            ecp.reviewRequestPermission.readLevel !== 'NONE'
          );
        });
        return isExistApiPermission;
      });

    // 存在する全てのAPIの読み込み権限がNONEの場合は、NONEをもつ権限を返す
    const allNoneReadReviewRequestPermission =
      role.exceptionContentPermissions?.find((ecp) => {
        const isExistApiPermission = apiIds?.some((apiId) => {
          return (
            ecp.apiId === apiId &&
            apiIds.length === role.exceptionContentPermissions?.length &&
            ecp.reviewRequestPermission.readLevel === 'NONE'
          );
        });
        return isExistApiPermission;
      });

    return (
      canReadReviewRequestPermission?.reviewRequestPermission?.readLevel ||
      allNoneReadReviewRequestPermission?.reviewRequestPermission?.readLevel ||
      // 上記のケース以外はデフォルト権限で判定をする
      role.reviewRequestPermission.readLevel
    );
  });

  // SAME_ROLE判定について:
  // 厳密にはreviewAuthorIdのメンバーがrolesの中に同じロールを持っているかどうか判断する必要がありますが、
  // 画面表示においてそこまでシビアなチェックが必要なタイミングがないため簡易的な判定のみを行っています。

  const hasReviewRequestPermission =
    readLevels.includes('ALL') ||
    readLevels.includes('SAME_ROLE') ||
    (readLevels.includes('CREATED') && reviewAuthorId === username)
      ? true
      : false;
  return hasReviewRequestPermission;
};

/**
 * レビューの読み取り権限をチェックするHooks
 * usernameとreviewRequestを両方渡すことで、レビュー申請が自身が作成したレビューかどうかを厳密に判定します
 * 渡さない場合はALL(or)CREATED権限のロールを持っているかのみで判定します
 * @returns boolean
 */
export const useReviewRequestReadPermission = ({
  apiIds,
  username,
  reviewAuthorId,
}: Pick<ReviewRequestPermissionArgs, 'apiIds' | 'username'> & {
  reviewAuthorId?: string;
}): boolean => {
  const { roles } = useContext(myRolesContext);

  const hasPermission = useMemo(() => {
    return checkReviewRequestReadPermission({
      apiIds,
      roles,
      reviewAuthorId,
      username,
    });
  }, [apiIds, reviewAuthorId, roles, username]);

  return hasPermission;
};
