import qs from 'query-string';
import Quill from 'quill';
import ReactDOM from 'react-dom';

import ImageEditor from './ImageEditor';
import { getResourceUrl, getResourceUrlByMediumId } from '../../../util';

import { getTranslation } from '@/i18n';

const Image = Quill.import('formats/image');

export default class ImageFormat extends Image {
  static create(mediumStr) {
    const { t } = getTranslation('richEditor');

    const medium = JSON.parse(mediumStr);
    /**
     * @type {HTMLImageElement}
     */
    const node = super.create(medium);
    if (
      (medium.path && medium.identityId) || //旧形式
      (medium.mediumId && medium.serviceId) //新形式
    ) {
      const getUrl =
        medium.path && medium.identityId //旧形式
          ? getResourceUrl(
              `protected/${medium.identityId}/${medium.path}`.replace(
                `/${medium.path.replace(/.*\//, '')}`,
                '',
              ),
              medium.path.replace(/.*\//, ''),
              '',
              'IMAGE',
            )
          : medium.mediumId && medium.serviceId //新形式
            ? getResourceUrlByMediumId(medium.serviceId, medium.mediumId)
            : null;

      getUrl.then((res) => {
        // 権限がない場合
        if (res === null) {
          node.setAttribute('src', t('RICH_EDITOR_IMAGE_PATH'));
          node.style.width = '344px';
          node.style.height = '194px';
          node.style.backgroundColor = '#f8f9fd';
          node.style.objectFit = 'contain';

          return;
        }

        node.setAttribute('src', res);
        node.style.maxWidth = '500px';
        if (medium.queryString) {
          const obj = qs.parse(medium.queryString);
          node.setAttribute('data-w', obj.w);
          node.setAttribute('data-h', obj.h);
        }
        node.addEventListener('click', () => {
          const dataPath = node.getAttribute('data-path');
          const dataW = node.getAttribute('data-w');
          const dataH = node.getAttribute('data-h');
          ReactDOM.render(
            <ImageEditor
              edit={({ width, height, alt, link, isTargetBlank }) => {
                const params = {
                  ...medium,
                  queryString: `?w=${Number(width)}&h=${Number(height)}`,
                  alt,
                  link,
                  isTargetBlank,
                };
                node.setAttribute('data-path', JSON.stringify(params));
                node.setAttribute('data-w', width);
                node.setAttribute('data-h', height);
              }}
              src={node.src}
              naturalWidth={node.naturalWidth}
              naturalHeight={node.naturalHeight}
              width={dataW ? dataW : undefined}
              height={dataH ? dataH : undefined}
              alt={dataPath === null ? '' : JSON.parse(dataPath).alt}
              link={dataPath === null ? '' : JSON.parse(dataPath).link}
              isTargetBlank={
                dataPath === null ? '' : JSON.parse(dataPath).isTargetBlank
              }
              close={() =>
                ReactDOM.unmountComponentAtNode(
                  document.getElementById('outside'),
                )
              }
            />,
            document.getElementById('outside'),
          );
        });
      });
      node.setAttribute('data-path', JSON.stringify(medium));
    }
    return node;
  }
  static value(domNode) {
    return domNode.getAttribute('data-path');
  }
}
