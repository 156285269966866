import { useQuery } from '@tanstack/react-query';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { useCallback, useState } from 'react';

import * as queries from '../../graphql/queries';

import type { Api, ApiList, MigrateApi } from '@/entity/api';
import type { Service } from '../../types/services';

import { apiQueryKeys } from '@/views/Common/api/queryKeys';

export const useLoadApis = (service: Service | null | undefined) => {
  const getApiList = async (): Promise<ApiList | undefined> => {
    if (service) {
      //API作成直後など、権限グループが変わっている可能性があるのでトークンを再取得
      const user = await Auth.currentAuthenticatedUser();
      const session: any = await Auth.currentSession();
      user &&
        session &&
        session.refreshToken &&
        user.refreshSession(
          session.refreshToken,
          async (err: Error, session: any) => {
            if (err) return;
            return session.refreshToken;
          },
        );
      //API一覧を取得
      const apis: Api[] = (
        (await API.graphql(
          graphqlOperation(queries.findApis, {
            serviceId: service.partitionKey,
          }),
        )) as any
      ).data.findApis;
      const list = apis
        .map((api) => {
          // ANKEN-1357: 既存のAPIレスポンスに影響を与えないため、権限のないレスポンスはundefinedを返す
          if (api?._restricted) {
            return;
          }

          return {
            ...api,
            //新DB移行にあたって、旧DBで取得できていた値を調整して入れておく
            apiEndpoint: api.gsiSortKey2.replace('ENDPOINT#', ''),
            contentId: api.gsiSortKey2.replace('ENDPOINT#', ''),
            domain: service.gsiSinglePartitionKey1.replace('DOMAIN#', ''),
            id: service.gsiSinglePartitionKey1.replace('DOMAIN#', ''),
            serviceId: service.partitionKey, //各所で必要になるため予め持っておく
          };
        })
        .filter((api) => !!api);
      return { list };
    }
  };
  const apiListQuery = useQuery({
    queryKey: apiQueryKeys.list(),
    queryFn: getApiList,
    staleTime: Number.POSITIVE_INFINITY,
    enabled: !!service,
  });
  const {
    data: apiList,
    isLoading: isApiListLoading,
    refetch: refetchApiList,
  } = apiListQuery;

  const [filteredApis, setFilteredApi] = useState<MigrateApi[] | undefined>();

  const searchApis = useCallback(
    (value: any) => {
      if (!value) {
        setFilteredApi(undefined);
        return;
      }
      return setFilteredApi(
        apiList?.list.filter(
          (item) =>
            (item.contentId && item.contentId.indexOf(value) > -1) ||
            (item.apiName && item.apiName.indexOf(value) > -1),
        ),
      );
    },
    [apiList],
  );

  return {
    apiList,
    isApiListLoading,
    refetchApiList,
    searchApis,
    filteredApis,
  };
};
