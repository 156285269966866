import { apiListSelectors } from '@/ducks/apiList';
import type { ContentValue } from '@/entity/content';
import { useCustomFields } from '@/hooks/CustomField/useCustomFieldReader';
import { useAppSelector } from '@/store/hooks';
import type { CustomField } from '@/types/contents';
import { useInput } from '@/views/Common/useInput';
import { ContentCapacity } from '@/views/apis/content/ContentCapacity';
import { ContentCapacityProvider } from '@/views/apis/content/ContentCapacity/ContentCapacityProvider';
import { ContentDetail } from '@/views/apis/content/ContentDetail';
import { useContent } from '@/views/apis/content/useContent';
import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import type { ApiList, MigrateApi } from '../../entity/api';
import ContentActions from '../ContentActions';
import Head from '../Head';
import { validateContentId } from '../Validations';
import Loading from '../ui/Loading';
import Textfield from '../ui/Textfield';
import styles from './createContent.module.css';
import { useCreateContent } from './useCreateContent';

type Props = {
  api: MigrateApi;
  endpoint: string;
  customFields: CustomField[];
  initialContentValue: ContentValue;
  initialContentId: string;
};

const CreateContent: React.FC<Props> = ({
  api,
  endpoint,
  customFields,
  initialContentValue,
  initialContentId,
}) => {
  const { t } = useTranslation('createContent');
  const routerHistory = useHistory();

  const { contentAtom, getContent, validateAllFields } = useContent(
    initialContentValue,
    api.apiFields,
    customFields,
  );

  const [contentId, onChangeContentId, contentIdError] = useInput(
    initialContentId,
    validateContentId,
  );

  const [contentIdEditable, setContentIdEditable] = useState(false);

  return (
    <div className={styles.wrapper}>
      <Head title={t('New Content')} />
      <ContentActions
        api={api}
        contentId={contentId}
        getContent={getContent}
        endpoint={endpoint}
        customFields={customFields}
        validateOnBeforePublish={validateAllFields}
        isEditing={false}
        openedReviewRequestAccessInfo={null}
        history={routerHistory}
      />
      <div id="main" className={styles.main}>
        <div id="content-meta" className={styles.meta}>
          {api.apiType === 'LIST' && (
            <dl className={styles.keyValue}>
              <dt className={styles.key}>{t('Content ID')}</dt>
              <dd>
                {contentIdEditable ? (
                  <Textfield
                    defaultValue={contentId}
                    onChange={onChangeContentId}
                    errorText={contentIdError}
                  />
                ) : (
                  <button
                    className={styles.linkButton}
                    onClick={() => setContentIdEditable(true)}
                  >
                    <span data-testid="contentId">{contentId}</span>
                    <i className={`material-icons ${styles.icon}`}>edit</i>
                  </button>
                )}
              </dd>
            </dl>
          )}
          <ContentCapacity />
        </div>
        <div className={styles.content}>
          <ContentDetail
            api={api}
            endpoint={endpoint}
            customFields={customFields}
            contentAtom={contentAtom}
            getContent={getContent}
          />
        </div>
      </div>
    </div>
  );
};

// フィールドの初期値生成時にapiとcustomFieldsの両方が必要なため、
// 両者を取得するまで本体(CreateContent)のレンダリングを遅らせる
const CreateContentWrapper: React.FC = () => {
  // TODO: ReduxをReactQueryに置き換える
  const { endpoint } = useParams<{ endpoint: string }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );

  const [customFields] = useCustomFields(api.partitionKey) as [
    CustomField[] | undefined,
    () => void,
  ];

  // 「コンテンツをコピーして新規作成」から遷移した場合stateにcontentIdが入る
  const history = useHistory<{ contentId?: string } | undefined>();
  const copyContentId = history.location.state?.contentId;

  const {
    copySourceContentData,
    isCopySourceContentDataLoading,
    initialContentValue,
    initialContentId,
  } = useCreateContent(api, customFields, copyContentId);

  const readyServerData = api !== undefined && customFields !== undefined;
  const readyInitialContentValue = initialContentValue !== null;
  const readyInitialContentId = !!initialContentId;

  if (
    !readyServerData ||
    !readyInitialContentValue ||
    !readyInitialContentId ||
    isCopySourceContentDataLoading
  ) {
    return <Loading />;
  }

  const keyObject = {
    endpoint,
    copyContentId: history.location.state?.contentId ?? null,
  };

  const defaultContentValue =
    copySourceContentData?.data ?? initialContentValue;

  return (
    <ContentCapacityProvider defaultValue={defaultContentValue}>
      <CreateContent
        key={JSON.stringify(keyObject)}
        api={api}
        endpoint={endpoint}
        customFields={customFields}
        initialContentValue={defaultContentValue}
        initialContentId={initialContentId}
      />
    </ContentCapacityProvider>
  );
};

export default CreateContentWrapper;
