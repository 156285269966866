import styles from './loading.module.css';

type Props = {
  children?: React.ReactNode;
};

const Loading: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.loading}>
      <img src="/images/icon_loading.svg" alt="loading..." />
      {children && <p className={styles.text}>{children}</p>}
    </div>
  );
};

export default Loading;
