import { useTranslation } from 'react-i18next';

import Head from '@/components/Head';
import Button from '@/components/ui/Button';
import Loading from '@/components/ui/Loading';
import Selectfield from '@/components/ui/Selectfield';

import { useGetMyService } from '@/hooks/useService';

import { useLanguageSettings } from './useLanguageSettings';

import type {
  LanguageSetting,
  ServiceLanguage,
} from '@/entity/serviceLanguage';

import styles from './languageSettings.module.css';

export type ViewProps = {
  language: LanguageSetting;
  serviceLanguage: ServiceLanguage;
  serviceLanguageLoading: boolean;
  updateServiceLanguageLoading: boolean;
  onChangeLanguage: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onUpdateServiceLanguage: () => void;
};

export const LanguageSettingsView: React.FC<ViewProps> = ({
  language,
  serviceLanguage,
  serviceLanguageLoading,
  updateServiceLanguageLoading,
  onChangeLanguage,
  onUpdateServiceLanguage,
}) => {
  const { t } = useTranslation('serviceLanguageSettings');

  if (serviceLanguageLoading) return <Loading />;

  return (
    <div>
      <Head title={t('Notification Language')} />
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{t('Notification Language')}</h2>
        <p className={styles.description}>
          {t(
            'Select a language for notifications such as webhooks and user invitations.',
          )}
        </p>
        <div className={styles.select}>
          <Selectfield
            value={language === null ? serviceLanguage : language}
            onChange={onChangeLanguage}
            label={t('Notification Language')}
          >
            <option value="ja">日本語</option>
            <option value="en">English</option>
          </Selectfield>
        </div>
        <div className={styles.actions}>
          <Button
            type="primary"
            value={t('Save changes')}
            onClick={onUpdateServiceLanguage}
            disabled={updateServiceLanguageLoading}
          />
        </div>
      </div>
    </div>
  );
};

const LanguageSettings: React.FC = () => {
  const { service } = useGetMyService();

  const {
    language,
    serviceLanguage = 'ja',
    serviceLanguageLoading,
    updateServiceLanguageLoading,
    onChangeLanguage,
    onUpdateServiceLanguage,
  } = useLanguageSettings(service?.partitionKey || '');

  return (
    <LanguageSettingsView
      language={language}
      serviceLanguageLoading={serviceLanguageLoading}
      serviceLanguage={serviceLanguage}
      updateServiceLanguageLoading={updateServiceLanguageLoading}
      onChangeLanguage={onChangeLanguage}
      onUpdateServiceLanguage={onUpdateServiceLanguage}
    />
  );
};

export default LanguageSettings;
