import type React from 'react';

import styles from './caution.module.css';

type Props = {
  children: React.ReactNode;
  icon?: string;
};

const Caution: React.FC<Props> = ({ children, icon = 'error_outline' }) => {
  return (
    <div className={styles.wrapper}>
      <i className="material-icons">{icon}</i>
      <p className={styles.description}>{children}</p>
    </div>
  );
};

export default Caution;
