import { NodeViewWrapper } from '@tiptap/react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useEmbedBlock } from './useEmbedBlock';
import { EmbedModal } from '../../EmbedModal';
import { EmbedPreview } from '../../EmbedPreview';

import type {
  IframelyResponse,
  EmbedResult,
  OptionsField,
} from '@/entity/iframely';
import type { NodeViewProps } from '@tiptap/react';

import styles from './embedblock.module.css';

export type ViewProps = {
  isEmbedModal: boolean;
  onToggleEmbedModal: (open: boolean) => void;
  embedUrl: string;
  onChangeEmbedUrl: (url: string) => void;
  embedUrlError?: string;
  inputOptions?: Record<string, OptionsField['value']>;
  onChangeInputOptions: (value: OptionsField['value'], key: string) => void;
  embedResult: EmbedResult;
  onClickPreviewEmbed: () => Promise<void>;
  onUpdate: () => void;
  onDelete: () => void;
  url: IframelyResponse['url'];
  html: IframelyResponse['html'];
  options: IframelyResponse['options'];
  blockRef: React.RefObject<HTMLButtonElement>;
  isCurrentActiveBlock: boolean;
};

export const EmbedBlockView: React.FC<ViewProps> = ({
  isEmbedModal,
  onToggleEmbedModal,
  embedUrl,
  onChangeEmbedUrl,
  embedUrlError,
  inputOptions,
  onChangeInputOptions,
  embedResult,
  onClickPreviewEmbed,
  onUpdate,
  onDelete,
  url,
  html,
  options,
  blockRef,
  isCurrentActiveBlock,
}) => {
  const { t } = useTranslation('richEditorV2');

  return (
    <NodeViewWrapper
      className={cx(styles.main, {
        [styles.isActive]: isCurrentActiveBlock,
      })}
    >
      <div className={styles.action}>
        <p className={styles.text}>{t('Embed')}</p>
        <EmbedModal
          title={t('Edit Oembedly')}
          trigger={
            <button
              ref={blockRef}
              className={styles.linkButton}
              aria-label={t('Edit')}
              tabIndex={-1}
            >
              <span className={`material-icons`} aria-hidden>
                edit
              </span>
            </button>
          }
          footer={{
            submitButtonProps: {
              value: t('Update_OEMBEDLY'),
              type: 'primary',
              onClick: onUpdate,
              disabled: !!embedResult.data?.error,
            },
            dangerButtonProps: {
              value: t('Delete'),
              onClick: onDelete,
            },
          }}
          isEmbedModal={isEmbedModal}
          onToggleEmbedModal={onToggleEmbedModal}
          embedUrl={embedUrl}
          onChangeEmbedUrl={onChangeEmbedUrl}
          embedUrlError={embedUrlError}
          inputOptions={inputOptions}
          onChangeInputOptions={onChangeInputOptions}
          embedResult={embedResult}
          onClickPreviewEmbed={onClickPreviewEmbed}
        />
      </div>
      <div className={styles.embed} tabIndex={-1}>
        <EmbedPreview url={url} html={html} options={options} />
      </div>
    </NodeViewWrapper>
  );
};

export const EmbedBlock: React.FC<NodeViewProps> = (props) => {
  const { html, url, options } = props.node.attrs as IframelyResponse;
  const {
    isEmbedModal,
    onToggleEmbedModal,
    embedUrl,
    onChangeEmbedUrl,
    embedUrlError,
    inputOptions,
    onChangeInputOptions,
    embedResult,
    onClickPreviewEmbed,
    onUpdate,
    onDelete,
    blockRef,
    isCurrentActiveBlock,
  } = useEmbedBlock({
    editor: props.editor,
    url,
    html,
    options,
    currentPosition: props.getPos(),
  });

  return (
    <EmbedBlockView
      html={html}
      url={url}
      options={options}
      isEmbedModal={isEmbedModal}
      onToggleEmbedModal={onToggleEmbedModal}
      embedUrl={embedUrl}
      onChangeEmbedUrl={onChangeEmbedUrl}
      embedUrlError={embedUrlError}
      inputOptions={inputOptions}
      onChangeInputOptions={onChangeInputOptions}
      embedResult={embedResult}
      onClickPreviewEmbed={onClickPreviewEmbed}
      onUpdate={onUpdate}
      onDelete={onDelete}
      blockRef={blockRef}
      isCurrentActiveBlock={isCurrentActiveBlock}
    />
  );
};
