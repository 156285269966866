import cx from 'classnames';

import styles from './icon.module.css';

export type Props = ViewProps;

export type ViewProps = {
  name: string;
  size?: 'small' | 'large';
  outlined?: boolean;
} & Omit<React.ComponentProps<'span'>, 'children'>;

export const IconView: React.FC<ViewProps> = ({
  name,
  size = 'small',
  outlined = false,
  className,
  ...props
}) => {
  return (
    <span
      {...props}
      aria-hidden
      className={cx(
        `material-icons${outlined ? '-outlined' : ''}`,
        styles[size],
        className,
      )}
    >
      {name}
    </span>
  );
};

export const Icon: React.FC<Props> = (props) => {
  return <IconView {...props} />;
};
