export const get = ({ list }) => {
  if (list === undefined) {
    return {};
  }
  return {
    apiList: list,
  };
};

export const getAllApiLength = ({ list }) => {
  return list ? list.length : 0;
};

export const getApi = ({ list }, endpoint) => {
  return list && list.find((api) => api.apiEndpoint === endpoint);
};

export const getApiFromReferenceKey = ({ list }, referenceKey) => {
  return list && list.find((api) => api.partitionKey === referenceKey);
};
