import dayjs from 'dayjs';
import type React from 'react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Area,
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import Loading from '@/components/ui/Loading';

import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';

import Caution from '../../Caution';
import Head from '../../Head';
import { useAmountSettings } from './useAmountSettings';

import styles from './amountSettings.module.css';

import { formatDateChart } from '@/util/date';

const AmountSettings: React.FC = () => {
  const { t } = useTranslation('serviceAmountSettings');
  const { service } = useGetMyService();
  const { currentPlan } = useStripeActions(service);
  const { useGetBandwidthLog } = useAmountSettings();
  const { data: bandwidthLog } = useGetBandwidthLog(service?.partitionKey);

  const [selectedMonth, setMonth] = useState(dayjs().format('YYYY-MM'));

  // 選択用に3ヶ月分の配列を用意
  const monthRange = [0, 1, 2].map((i) =>
    dayjs().subtract(i, 'months').format('YYYY-MM'),
  );

  const data = useMemo(() => {
    if (!bandwidthLog) {
      return [];
    }

    // 選択した月の現在の日付までの日付（ex:["YYYY-MM-DD","YYYY-MM-DD","YYYY-MM-DD" ]）
    const dateList = [...Array(31)]
      .map((_, i) => dayjs(selectedMonth).add(i, 'day').format('YYYY-MM-DD'))
      .filter(
        (date) =>
          date.startsWith(selectedMonth) &&
          date <= dayjs().format('YYYY-MM-DD'),
      );

    // 転送量が発生した日のデータ
    const targetData = bandwidthLog.filter(({ date }: { date: string }) =>
      dateList.includes(date),
    );

    let sum = 0;
    // rechartsに渡す形式にして返す
    return dateList.map((date) => {
      const data = targetData.find(({ date: dataDate }) => date === dataDate);
      const value = data
        ? data.values
            .map((v) => v.bytes / (1024 * 1024 * 1024))
            .reduce((x, y) => x + y)
        : 0;
      const apiRequestCount = data
        ? data.values.map((v) => v.apiRequestCount || 0).reduce((x, y) => x + y)
        : 0;
      const mediumRequestCount = data
        ? data.values
            .map((v) => v.mediumRequestCount || 0)
            .reduce((x, y) => x + y)
        : 0;

      return {
        name: formatDateChart(date),
        value,
        sum: (sum += value),
        apiRequestCount,
        mediumRequestCount,
      };
    });
  }, [bandwidthLog, selectedMonth]);

  if (!service) return <Loading />;

  return (
    <div>
      <Head title={t('Amount of Data Transferred')} />
      <div className={styles.head}>
        <h2 className={styles.title}>{t('Amount of Data Transferred(GB)')}</h2>
        <div className={styles.date}>
          <i className="material-icons-outlined">date_range</i>
          <select
            className={styles.select}
            onChange={(e) => setMonth(e.target.value)}
          >
            {monthRange.map((v, i) => (
              <option value={v} key={i}>
                {t('{{month}}/{{year}}', {
                  year: v.split('-')[0],
                  month: v.split('-')[1],
                })}
              </option>
            ))}
          </select>
        </div>
      </div>
      {data.length === 0 ? (
        <p>{t('There is no Data.')}</p>
      ) : (
        <div>
          <p className={styles.sum}>
            <span
              className={styles.number}
              data-testid="monthly-data-transfer-volume"
            >
              {data.length > 0 && data[data.length - 1].sum.toFixed(3)}
            </span>
            <span className={styles.unit}>{t('GB / month')}</span>
            {currentPlan && (
              <span className={styles.planInfo}>
                {currentPlan.limit.transfer === null
                  ? t(
                      'Unlimited amount of data is available with the current plan.',
                    )
                  : t(
                      'Up to {{transfer}} / month of data is available with the current plan.',
                      { transfer: currentPlan.limit.transfer },
                    )}{' '}
                <Link to="/settings/billing" className={styles.link}>
                  {t('Check the current plan.')}
                </Link>
              </span>
            )}
          </p>
          <h3 className={styles.subTitle}>{t('Amount of Data Transferred')}</h3>
          <div className={styles.graph}>
            <ResponsiveContainer>
              <ComposedChart
                data={data}
                margin={{
                  top: 24,
                  right: 0,
                  left: 8,
                  bottom: 0,
                }}
              >
                <defs>
                  <linearGradient
                    id="colorBandwidth"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="10%" stopColor="#563bff" stopOpacity={0.5} />
                    <stop offset="95%" stopColor="#563bff" stopOpacity={0.5} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="sum"
                  name={t('Total amount(GB)')}
                  stroke="#563bff"
                  fill="url(#colorBandwidth)"
                />
                <Bar
                  dataKey="value"
                  name={t('Value(GB)')}
                  barSize={10}
                  fill="#563bff"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
          {service.createdAt < '2021-02-25' && (
            <div className={styles.alert}>
              {/* @ts-expect-error TODO: titleはpropsに存在しないので削除するか修正が必要 */}
              <Caution title="重要">
                <>
                  {t(
                    'Due to a bug in calculation, only about 10% of the actual amount of data transfer was counted for total prior to February 23, 2021.',
                  )}
                  <br />
                  {t(
                    'The bug has been fixed. However, considering the impact on future billing, only 10% of the actual amount of data transfer will continue to be shown on this screen for services created prior to February 24, 2021.',
                  )}
                  <br />
                  <br />
                  {t('This measure will end on August 31, 2022.')}
                </>
              </Caution>
            </div>
          )}
          <h3 className={styles.subTitle}>
            {t('Number of Content Retrieval')}
          </h3>
          <div className={styles.graph}>
            <ResponsiveContainer>
              <BarChart
                data={data}
                margin={{
                  top: 24,
                  right: 0,
                  left: 8,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip cursor={false} />
                <Bar
                  dataKey="apiRequestCount"
                  name="GET API"
                  fill="#563bff"
                  stackId="x"
                />
                <Bar
                  dataKey="mediumRequestCount"
                  name={t('Media')}
                  fill="#563bff"
                  stackId="x"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default AmountSettings;
