import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useParams } from 'react-router-dom';

import { useLimitApiFields } from '@/hooks/FieldLimit/useLimitApiField';
import { useGetMyService } from '@/hooks/useService';

import { useInput, useLoading } from '../../../hooks';
import { useCustomFields } from '../../../hooks/CustomField/useCustomFieldReader';
import { useExceptionPermissionIsHaveLeastOne } from '../../../hooks/Role/useMyRoles';
import { useHasDiff } from '../../../hooks/useHasDiff';
import ApiModel from '../../ApiModel';
import Feedback from '../../Feedback';
import Head from '../../Head';
import ReloadPrompt from '../../ReloadPrompt';
import { validateApiFieldsAllowPublishedAt } from '../../Validations';
import Button from '../../ui/Button';
import Notification from '../../ui/Notification';

import type { GetPutResultArgs } from '@/ducks/api/selector';
import type { ApiList, MigrateApi } from '@/entity/api';
import type { Field } from '../../../types/field';

import styles from './modelSettings.module.css';

import { apiSelectors } from '@/ducks/api';
import { putApi } from '@/ducks/api/operations';
import { apiListSelectors } from '@/ducks/apiList';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AlertDialog } from '@/views/Common/Ui/AlertDialog';
import { useApiListInvalidateCache } from '@/views/Common/api/invalidateCache';

const ModelSettings: React.FC = () => {
  const { t } = useTranslation('modelSettings');
  const { service, parentService } = useGetMyService();
  const apiListInvalidateCache = useApiListInvalidateCache();

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint } = useParams<{
    endpoint: string;
  }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );

  const dispatch = useAppDispatch();
  const putApiResult = useAppSelector((state) =>
    apiSelectors.getPutResult(state.apiState as GetPutResultArgs),
  );

  const [updatable] = useExceptionPermissionIsHaveLeastOne(
    api.partitionKey,
    'apiUpdate',
  );

  const { apiFields, apiEndpoint, partitionKey } = api;
  const [customFields = []] = useCustomFields(api && api.partitionKey);
  const [fields, , fieldsError, setFields] = useInput(
    apiFields,
    validateApiFieldsAllowPublishedAt,
  );
  useEffect(() => {
    setFields(api.apiFields);
  }, [api, setFields]);

  // フィールド数の上限値チェック
  const errorAddField = useLimitApiFields(fields, service, parentService);

  const { hasDiff } = useHasDiff(apiFields, fields);

  const [loading, startLoading] = useLoading(
    putApiResult.putResult !== undefined,
  );

  const submit = useCallback(() => {
    startLoading();
    // @ts-expect-error
    dispatch(putApi({ apiId: partitionKey, fields })).then(() => {
      // apiListのキャッシュを無効化
      apiListInvalidateCache();
    });
  }, [startLoading, dispatch, partitionKey, fields, apiListInvalidateCache]);

  const exportApi = useCallback(() => {
    const simplify = (field: any, removeKey = true) => {
      const undefObject = Object.keys(field)
        .filter((key) => field[key] === null)
        .map((key) => ({ [key]: undefined }))
        .reduce((total, current) => ({ ...total, ...current }), {});
      return {
        ...field,
        idValue: removeKey ? undefined : field.idValue,
        referenceKey: undefined, //コンテンツ参照には未対応
        ...undefObject,
      };
    };
    const content = JSON.stringify({
      // @ts-expect-error
      apiFields: apiFields.map(simplify),
      customFields: customFields.map((field) => {
        // @ts-expect-error: stringだが、ここの場合はundefinedになる
        field.apiId = undefined;
        field.fields = field.fields.map((f: Field) => simplify(f, false));
        return field;
      }),
    });
    const blob = new Blob([content], { type: 'text/plain' });
    const fileName = `api-${apiEndpoint}-${dayjs().format(
      'YYYYMMDDHHmmss',
    )}.json`;

    // @ts-expect-error
    if (window.navigator.msSaveBlob) {
      // @ts-expect-error
      window.navigator.msSaveBlob(blob, fileName);
      // @ts-expect-error
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const downLoadLink = document.createElement('a');
      downLoadLink.download = fileName;
      downLoadLink.href = URL.createObjectURL(blob);
      downLoadLink.dataset.downloadurl = [
        'text/plain',
        downLoadLink.download,
        downLoadLink.href,
      ].join(':');
      downLoadLink.click();
    }
  }, [apiFields, customFields, apiEndpoint]);

  const swap = useCallback(
    (x: any, y: any) => {
      if (x === y) {
        return;
      }
      const copy = [...fields];
      copy.splice(y, 0, fields[x]);
      if (x < y) {
        copy.splice(x, 1);
      } else {
        copy.splice(x + 1, 1);
      }
      setFields(copy);
    },
    [fields, setFields],
  );

  const setFieldAt = useCallback(
    (f: any, index: any) => {
      setFields((prev: Field[]) => {
        const next = [...prev];
        next[index] = f(prev[index]);
        return next;
      });
    },
    [setFields],
  );

  return (
    <>
      <Head title={t('API/Schema')} />
      <h2 className={styles.title}>{t('API Schema')}</h2>
      {hasDiff && (
        <Notification
          text={t('Some changes were not saved.')}
          status="warning"
        />
      )}
      <Prompt
        message={() => {
          return hasDiff
            ? t(
                'Changes in the API schema settings will be lost. Are you sure you want to leave from this page?',
              )
            : true;
        }}
      />
      <ReloadPrompt
        showPrompt={() => {
          return hasDiff;
        }}
      />
      <div>
        {fields.map((field: Field, i: number) => {
          return (
            <div key={i} className={styles.list}>
              <ApiModel
                type={api.apiType}
                api={api}
                field={field}
                allFields={fields}
                setFieldAt={setFieldAt}
                index={i}
                swap={swap}
                deleteField={(index: number) =>
                  setFields(
                    fields.filter((_: unknown, j: number) => j !== index),
                  )
                }
                allowPublishedAt={true}
                readOnly={!updatable}
                isEditApi={true}
              />
            </div>
          );
        })}
        <div className={styles.addBtn}>
          <Button
            type="tertiary"
            icon="add"
            value={t('Add field')}
            size="full"
            onClick={() =>
              setFields([
                ...fields,
                { idValue: nanoid(10), isAdditionalField: true },
              ])
            }
            disabled={!updatable || errorAddField}
          />
          {errorAddField && (
            <p className={styles.errorText}>
              {t('The maximum number of fields has been reached.')}
            </p>
          )}
        </div>
      </div>
      <div className={styles.actions}>
        <Feedback
          type={putApiResult.putResult === false ? 'failure' : 'success'}
          message={
            putApiResult.putResult === false
              ? putApiResult.errorMessage
              : putApiResult.putResult === true
                ? t('Changes have been made.')
                : undefined
          }
        />
        <div className={styles.buttons}>
          {updatable && (
            <AlertDialog
              trigger={
                <Button
                  type="primary"
                  className="ga-api-settings-schema"
                  value={t('Save changes')}
                  disabled={!hasDiff || loading || fieldsError}
                />
              }
              title={t('API Schema changes')}
              description={t(
                'Are you sure you want to change the API schema? If you are already using the API, the change may cause problems. To perform the change, enter ({{endpoint}}).',
                { endpoint: apiEndpoint },
              )}
              buttonText={t('Save changes')}
              onSubmit={submit}
              confirmText={apiEndpoint}
            />
          )}
          <button
            onClick={exportApi}
            className={styles.textButton}
            style={{
              display: !hasDiff ? 'inline' : 'none',
            }}
          >
            {t('Export this configuration')}
          </button>
        </div>
      </div>
    </>
  );
};

export default ModelSettings;
