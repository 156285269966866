import { isRestrictedApi } from '@/entity/api';
import type { Field } from '@/types/field';
import { Icon } from '@/views/Common/Ui/Icon';
import { useLoadApis } from '@/views/Common/api/useApiListReader';
import type React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Errorfield } from '..';
import RelationListSelect from './RelationListSelect';

import styles from './relationListSelect.module.css';

type Props = {
  endpoint?: string;
  onChange?: (value: unknown) => void;
  value?: unknown[];
  readOnly?: boolean;
  parentField?: Field;
  referenceKey: string;
};

const RelationListSelectContainer: React.FC<Props> = ({
  endpoint,
  onChange = () => {},
  value = [],
  readOnly,
  parentField,
  referenceKey,
}) => {
  const { t } = useTranslation('relationListSelect');

  const apiResponse = useLoadApis();

  if (apiResponse.isLoading) {
    return null;
  }

  const api = apiResponse.data?.list?.find(
    (api) => api.partitionKey === referenceKey,
  );

  if (api === undefined || api === null) {
    return (
      <Errorfield>
        {Trans({
          t,
          i18nKey: 'The referenced API does not exist',
          children: (
            <Link to={`/apis/${endpoint}/settings/model`}>
              API Schema Settings
            </Link>
          ),
        })}
      </Errorfield>
    );
  }

  if (isRestrictedApi(api)) {
    return (
      <span className={styles.noPermission}>
        <Icon name="visibility_off" size="large" className={styles.icon} />
        <span className={styles.text}>
          {t(
            'Cannot be displayed due to lack of read permissions for the target API.',
          )}
        </span>
      </span>
    );
  }

  return (
    <RelationListSelect
      api={api}
      endpoint={endpoint}
      onChange={onChange}
      value={value}
      readOnly={readOnly}
      parentField={parentField}
    />
  );
};

export default RelationListSelectContainer;
