/**
 * @file 支払い情報などのユーティリティ関数を定義する
 */

import type { GetStripeSubscriptionData } from '@/types/stripe';

/**
 * 桁区切り記号をつけた数値の文字列を返す
 *
 * @see {@link https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString}
 * @example
 * separatedNumber(1000) // => "1,000"
 */
function formatSeparatedNumber(value: number) {
  return value.toLocaleString();
}

/**
 * 割引後の合計(税抜き)を算出して返す
 */
function getNetPrice<
  P extends Pick<
    GetStripeSubscriptionData,
    'nextNetPrice' | 'nextSubtotal' | 'nextDiscounts'
  >,
>({ nextNetPrice, nextSubtotal, nextDiscounts }: P) {
  /**
   * NOTE: StripeAPIで配信されるtotal_excluding_taxがnullableであるため、nullableな場合は計算する
   * @see {@link https://docs.stripe.com/api/invoices/object#invoice_object-total_excluding_tax}
   */
  if (typeof nextNetPrice === 'number') {
    return nextNetPrice;
  }

  const result = nextDiscounts.reduce(
    (acc, { amount }) => acc - amount,
    nextSubtotal,
  );
  // NOTE: 基本的に整数の値のみを扱うので小数点を含むことはないが、小数点以下を切り捨てる処理を入れておく
  return result >= 0 ? Math.floor(result) : 0;
}

export { formatSeparatedNumber, getNetPrice };
