import { useCallback } from 'react';

import { usePermissionIsNot } from '@/hooks/Role/useMyRoles';

import type { Value } from '../../MediaField/MediaField';
import type { Medium } from '@/entity/medium';

import { useToggle } from '@/views/Common/Ui/useToggle';

export const useMediaModal = (onChange: (value: Value) => void) => {
  const [isOpenMediaModal, toggleMediaModal, openMediaModal] = useToggle(false);
  const selectFile = useCallback(
    (medium: Medium) => () => {
      toggleMediaModal(false);
      onChange(medium);
    },
    [onChange, toggleMediaModal],
  );

  const [hasnotReadPermission] = usePermissionIsNot(
    'mediumPermission',
    'readLevel',
    'NONE',
  );

  return {
    isOpenMediaModal,
    toggleMediaModal,
    openMediaModal,
    selectFile,
    disabledMediaModalTrigger: hasnotReadPermission,
  };
};
