import type React from 'react';
import { useTranslation } from 'react-i18next';

import Selectfield from '../../ui/Selectfield';

import type { ApiListByRedux } from '@/entity/api';
import type { FieldKind } from '../../../types/field';

import styles from './ReferenceContent.module.css';

import { Popover } from '@/views/ApiModel/Popover';
import { Icon } from '@/views/Common/Ui/Icon';

type Props = {
  apiId: string;
  apiList: ApiListByRedux['apiList'];
  kind: FieldKind;
  onChangeReference: (e?: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled: boolean;
};

const ReferenceContent: React.FC<Props> = ({
  apiId,
  apiList,
  kind,
  onChangeReference,
  disabled,
}) => {
  const { t } = useTranslation('referenceContent');
  return (
    <Popover
      triggerSize="full"
      enabled={disabled}
      contentPosition={{
        side: 'top',
        align: 'center',
      }}
      content={t(
        'Referenced content cannot be changed. If you wish to change it, delete the field and recreate it.',
      )}
    >
      <span tabIndex={-1}>
        {/* 閲覧権限がなくレスポンスに該当API IDが含まれない場合非表示 */}
        {apiList.some((api) => api.partitionKey === apiId) ? (
          <Selectfield
            size="full"
            onChange={onChangeReference}
            value={apiId}
            disabled={disabled}
            style={{ pointerEvents: disabled ? 'none' : 'all' }}
          >
            {apiList &&
              apiList.map((api, i) => {
                if (kind === 'relationList' && api.apiType === 'PAGE') {
                  // 複数参照の際はオブジェクト形式のコンテンツは参照できないようにする
                  return null;
                }
                return (
                  <option key={i} value={api.partitionKey}>
                    {api.apiName} ({api.apiEndpoint})
                  </option>
                );
              })}
          </Selectfield>
        ) : (
          <span className={styles.noPermission}>
            <Icon name="visibility_off" size="large" className={styles.icon} />
            <span className={styles.text}>
              {t('Cannot display due to lack of permission.')}
            </span>
          </span>
        )}
      </span>
    </Popover>
  );
};

export default ReferenceContent;
