import { API, graphqlOperation } from 'aws-amplify';

import type { Service } from './../../types/services';
import type { Role } from '@/entity/role';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

import * as queries from '@/graphql/queries';
import { uniformRoleId } from '@/util/role';
import { uniformServiceId } from '@/util/service';
import {
  handleApiResult,
  type ApiResponse,
  handleApiError,
} from '@/views/Common/handleApiResult';

export const roleRepository = () => {
  const createRole = async ({
    serviceId,
    name,
    settings,
  }: {
    serviceId: string;
    name: Role['name'];
    settings: string;
  }): Promise<ApiResponse<Role['roleId']> | Role['roleId']> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.createRole, {
          serviceId,
          name,
          settings,
        }),
      )) as GraphQLResult<{
        createRole: ApiResponse<Role['roleId']> | Role['roleId'];
      }>;

      if (!result.data?.createRole) {
        throw new Error('Unexpected error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.createRole === 'object' &&
        typeof result.data.createRole.result === 'boolean'
      ) {
        return handleApiResult(result.data.createRole);
      } else if (typeof result.data.createRole === 'string') {
        return result.data.createRole;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      return handleApiError(e);
    }
  };

  const updateRole = async ({
    serviceId,
    roleId,
    name,
    settings,
  }: {
    serviceId: string;
    roleId: Role['roleId'];
    name: Role['name'];
    settings: string;
  }): Promise<ApiResponse | boolean> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.updateRole, {
          serviceId,
          roleId,
          name,
          settings,
        }),
      )) as GraphQLResult<{
        updateRole: ApiResponse | boolean;
      }>;

      if (!result.data?.updateRole) {
        throw new Error('Unexpected error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.updateRole === 'object' &&
        typeof result.data.updateRole.result === 'boolean'
      ) {
        return handleApiResult(result.data.updateRole);
      } else if (typeof result.data.updateRole === 'boolean') {
        return result.data.updateRole;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      return handleApiError(e);
    }
  };

  const deleteRole = async ({
    serviceId,
    roleId,
  }: {
    serviceId: string;
    roleId: Role['roleId'];
  }): Promise<ApiResponse | boolean> => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.deleteRole, {
          serviceId,
          roleId,
        }),
      )) as GraphQLResult<{
        deleteRole: ApiResponse | boolean;
      }>;

      if (!result.data?.deleteRole) {
        throw new Error('Unexpected error');
      }

      // TODO:フロントエンドエラー改善
      if (
        typeof result.data.deleteRole === 'object' &&
        typeof result.data.deleteRole.result === 'boolean'
      ) {
        return handleApiResult(result.data.deleteRole);
      } else if (typeof result.data.deleteRole === 'boolean') {
        return result.data.deleteRole;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      return handleApiError(e);
    }
  };

  /**
   * ロール編集画面用のRoleを取得する
   */
  const fetchServiceRoleForForm = async ({
    serviceId,
    roleId,
  }: {
    serviceId: Service['partitionKey'];
    roleId: Role['roleId'];
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.getServiceRole, {
          serviceId: uniformServiceId(serviceId),
          roleId: uniformRoleId(roleId),
        }),
      )) as GraphQLResult<{
        getServiceRole: Role;
      }>;
      if (!result.data?.getServiceRole) {
        throw new Error('Unexpected error');
      }
      return result.data.getServiceRole;
    } catch (e) {
      return handleApiError(e);
    }
  };

  return {
    createRole,
    updateRole,
    deleteRole,
    fetchServiceRoleForForm,
  };
};
