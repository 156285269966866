import { API, graphqlOperation } from 'aws-amplify';

import * as queries from '../../graphql/queries';

import type { WebhookSetting as RawResponseWebhookSetting } from '@/API';
import type { MigrateApi } from '@/entity/api';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

const findWebhookSettings = async (apiId: MigrateApi['partitionKey']) => {
  try {
    const res = (await API.graphql(
      graphqlOperation(queries.findWebhookSettings, { apiId }),
    )) as GraphQLResult<{
      findWebhookSettings: Array<RawResponseWebhookSetting>;
    }>;

    if (!res.data) {
      throw new Error('Failed to fetch webhook settings');
    }

    return res.data.findWebhookSettings;
  } catch (e) {
    if (e instanceof Error) {
      throw e;
    }

    throw new Error('Unexpected error');
  }
};

export { findWebhookSettings };
