import { useTranslation } from 'react-i18next';

import Head from '@/components/Head';
import Button from '@/components/ui/Button';
import Loading from '@/components/ui/Loading';

import { useApiIpAddressRestrictionSettings } from './useApiIpAddressRestrictionSettings';
import { useApiIpAddressRestrictionSettingsLoader } from './useApiIpAddressRestrictionSettingsLoader';

import type { Service } from '@/types/services';

import styles from './apiipaddressrestrictionsettings.module.css';

import { TextareaField } from '@/views/Common/Ui/TextareaField';
import { Upsell } from '@/views/Common/plan/Upsell';

export type ViewProps = {
  ipRestrictionCount: number;
  currentIp?: string;
  ipList: string;
  handleChangeIpList: React.ChangeEventHandler<HTMLTextAreaElement>;
  handleSaveButtonClick: () => void;
  disabledSaveButton: boolean;
};

export type Props = {
  service: Service;
  initialIpList: string;
};

const ApiIpAddressRestrictionSettingsView: React.FC<ViewProps> = ({
  ipRestrictionCount,
  currentIp,
  ipList,
  handleChangeIpList,
  handleSaveButtonClick,
  disabledSaveButton,
}) => {
  const { t } = useTranslation('serviceApiIpAddressRestrictionSettings');

  return (
    <Upsell restrictionKey="apiIpRestriction">
      <Head title={t('API IP Restriction')} />
      <div className={styles.main}>
        <h2 className={styles.title}>{t('API IP Restriction')}</h2>
        <TextareaField
          label={t('Allowed IP Addresses')}
          description={t(
            'Enter the IP addresses allowed to access all APIs (Contents), one per line in IPv4 / IPv6 CIDR notation. A maximum of {{validateCount}} lines can be set. If this is blank, any IP addresses will be allowed to access the API (Contents).',
            {
              validateCount: ipRestrictionCount,
            },
          )}
          placeholder={t('E.g., \n198.51.100.60\n198.51.100.150')}
          value={ipList}
          onChange={handleChangeIpList}
        />
        <p className={styles.caution}>
          {t(
            'Accessing from IP addresses other than the above is restricted. Do not set a dynamically assigned IP address.',
          )}
        </p>
        <p className={styles.currentIp}>
          <span>{t('Current source IP address :')}</span>
          <span>{currentIp}</span>
        </p>
      </div>
      <div className={styles.actions}>
        <Button
          type="primary"
          value={t('Save changes')}
          onClick={handleSaveButtonClick}
          disabled={disabledSaveButton}
        />
      </div>
    </Upsell>
  );
};

const ApiIpAddressRestrictionSettings: React.FC<Props> = (props) => {
  const hooks = useApiIpAddressRestrictionSettings(props);

  return <ApiIpAddressRestrictionSettingsView {...hooks} />;
};

export const ApiIpAddressRestrictionSettingsLoader: React.FC = () => {
  const { service, initialIpList, isInitialIpListLoading } =
    useApiIpAddressRestrictionSettingsLoader();

  if (!service || isInitialIpListLoading || initialIpList === undefined) {
    return <Loading />;
  }

  return (
    <ApiIpAddressRestrictionSettings
      service={service}
      initialIpList={initialIpList}
    />
  );
};
