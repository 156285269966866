import { ContentActionsView } from './ContentActions';

import type { ReviewRequestAccessInfo } from '@/API';
import type { MigrateApi } from '@/entity/api';
import type { ContentStatus, ContentValue } from '@/entity/content';
import type { CognitoUser } from '@/entity/user';
import type { useHistory } from 'react-router-dom';

import { useReviewRequestCreatePermission } from '@/views/Common/ReviewRequestPermission/useReviewRequestCreatePermission';

export type Props = {
  api: MigrateApi;
  endpoint: string;
  contentId: string;
  getContent: () => ContentValue;
  contentStatus?: ContentStatus;
  data?: any;
  contentDraftKey?: string;
  reservationTime?: any;
  reservationStopTime?: any;
  history: ReturnType<typeof useHistory>;
  customFields: any;
  partitionKey?: string;
  validateOnBeforePublish: any;
  setHistory?: any;
  isEditing: boolean;
  customStatus?: any;
  contentCustomStatusIds?: any;
  currentPlan?: any;
  cognitoUser?: CognitoUser;
  cognitoUserIsLoading?: boolean;
  openedReviewRequestAccessInfo?: ReviewRequestAccessInfo | null;
  openedReviewRequestAccessInfoIsLoading?: boolean;
};

const ContentActions: React.FC<Props> = ({
  api,
  endpoint,
  contentId,
  getContent,
  contentStatus,
  data,
  contentDraftKey,
  reservationTime,
  reservationStopTime,
  history,
  customFields,
  partitionKey,
  validateOnBeforePublish = () => {},
  setHistory = () => {},
  isEditing = true,
  customStatus,
  contentCustomStatusIds,
  currentPlan,
  openedReviewRequestAccessInfo,
  openedReviewRequestAccessInfoIsLoading,
}) => {
  //権限を取得

  const hasCreateReview = useReviewRequestCreatePermission({
    apiId: api.partitionKey,
  });

  return (
    <ContentActionsView
      api={api}
      endpoint={endpoint}
      contentId={contentId}
      getContent={getContent}
      contentStatus={contentStatus}
      data={data}
      contentDraftKey={contentDraftKey}
      reservationTime={reservationTime}
      reservationStopTime={reservationStopTime}
      history={history}
      customFields={customFields}
      partitionKey={partitionKey}
      validateOnBeforePublish={validateOnBeforePublish}
      setHistory={setHistory}
      hasCreateReview={hasCreateReview || false}
      isEditing={isEditing}
      customStatus={customStatus}
      contentCustomStatusIds={contentCustomStatusIds}
      currentPlan={currentPlan}
      openedReviewRequestAccessInfo={openedReviewRequestAccessInfo}
      openedReviewRequestAccessInfoIsLoading={
        openedReviewRequestAccessInfoIsLoading
      }
    />
  );
};

export default ContentActions;
