import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '@/components/ui/Button';
import Switch from '@/components/ui/Switch';

import { useEditLinkBody } from './useEditLinkBody';

import type { LinkAttributes } from '../toolbars/Embed/LinkBlock/LinkBlock';

import style from './editlinkbody.module.css';

import { Textfield } from '@/views/Common/Ui/Textfield';

export type Props = {
  inputLinkAttributes: LinkAttributes;
  onChangeText: (text: LinkAttributes['text']) => void;
  onChangeHref: (href: LinkAttributes['href']) => void;
  onChangeTargetBlank: (isTargetBlank: boolean) => void;
  onUpdateLink: () => void;
  onClearLink: () => void;
  hrefError: string | undefined;
  textError: string | undefined;
};

export type ViewProps = Props & {
  isDisplayLink: boolean;
};

export const EditLinkBodyView: React.FC<ViewProps> = ({
  inputLinkAttributes,
  onChangeText,
  onChangeHref,
  onChangeTargetBlank,
  onUpdateLink,
  onClearLink,
  hrefError,
  textError,
  isDisplayLink,
}) => {
  const { t } = useTranslation('richEditorV2');

  return (
    <div className={style.wrapper}>
      <Textfield
        label={t('Text')}
        value={inputLinkAttributes.text}
        onChange={(e) => onChangeText(e.target.value)}
        error={textError}
      />
      <div className={style.linkWrapper}>
        <Textfield
          className={style.linkInput}
          label={t('Link')}
          placeholder={t('Enter URL')}
          value={inputLinkAttributes.href}
          onChange={(e) => onChangeHref(e.target.value)}
          error={hrefError}
        />
        {isDisplayLink && (
          <a
            href={inputLinkAttributes.href}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <span
              className={cx(`material-icons ${style.linkIcon}`, {
                [style.hasError]: !!hrefError,
              })}
            >
              launch
            </span>
          </a>
        )}
      </div>
      <Switch
        on={inputLinkAttributes.target ? true : false}
        onChange={onChangeTargetBlank}
      >
        {t('Open in a new tab')}
      </Switch>
      <div className={style.button}>
        <Button
          value={t('Update')}
          type="secondary"
          onClick={onUpdateLink}
          disabled={!!hrefError || !!textError}
        />
        <Button
          value={t('Unlink')}
          type="danger"
          onClick={onClearLink}
          disabled={!!hrefError || !!textError}
        />
      </div>
    </div>
  );
};

export const EditLinkBody: React.FC<Props> = ({
  inputLinkAttributes,
  onChangeText,
  onChangeHref,
  onChangeTargetBlank,
  onUpdateLink,
  onClearLink,
  hrefError,
  textError,
}) => {
  const { isDisplayLink } = useEditLinkBody({ href: inputLinkAttributes.href });

  return (
    <EditLinkBodyView
      inputLinkAttributes={inputLinkAttributes}
      onChangeText={onChangeText}
      onChangeHref={onChangeHref}
      onChangeTargetBlank={onChangeTargetBlank}
      onUpdateLink={onUpdateLink}
      onClearLink={onClearLink}
      hrefError={hrefError}
      textError={textError}
      isDisplayLink={isDisplayLink}
    />
  );
};
