import type { ApiKeyPriorityPolicy, ManagementPolicy } from '../types/apiKey';

export const defaultPriorityPolicy: ApiKeyPriorityPolicy = {
  policy: {
    get: true,
    getAllDraft: false,
    getAllClosed: false,
    post: false,
    put: false,
    patch: false,
    delete: false,
  },
};

export const defaultManagementPolicy: ManagementPolicy = {
  getContent: false,
  patchContentCreatedBy: false,
  patchContentStatus: false,
  getMedia: false,
  postMedia: false,
  deleteMedia: false,
};
