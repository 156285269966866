import { useMemo } from 'react';

import { useI18nContentsComparison } from '../../useI18nContentsComparison';
import { DiffNodesDisplay } from '../parts/DiffNodesDisplay';

import type { DiffNode } from '../parts/DiffNodesDisplay';

type Props = {
  sinceValue: boolean | undefined;
  untilValue: boolean | undefined;
};

const Switch: React.FC<Props> = ({ sinceValue, untilValue }) => {
  const { t } = useI18nContentsComparison();

  const diffNodes = useMemo(() => {
    const nodes: DiffNode[] = [];

    const nodeSinceValue =
      sinceValue !== undefined ? (sinceValue ? t('On') : t('Off')) : '';
    const nodeUntilValue =
      untilValue !== undefined ? (untilValue ? t('On') : t('Off')) : '';

    if (sinceValue === untilValue) {
      nodes.push({
        since: {
          value: nodeSinceValue,
          type: 'noChange',
        },
        until: { value: nodeUntilValue, type: 'noChange' },
      });
    } else {
      nodes.push({
        since:
          nodeSinceValue === ''
            ? null
            : {
                value: nodeSinceValue,
                type: 'removed',
              },
        until:
          nodeUntilValue === ''
            ? null
            : { value: nodeUntilValue, type: 'added' },
      });
    }

    return nodes;
  }, [sinceValue, t, untilValue]);

  return <DiffNodesDisplay nodes={diffNodes} position="center" />;
};

export { Switch };
