import { stripeRepository } from '@/repository/stripeRepository';

import type { AddonType, CancelSelect } from '@/entity/plan';

export const changeCard = async ({
  serviceId,
  source,
}: {
  serviceId: string;
  source: string;
}) => {
  const { changeCard } = stripeRepository();
  return await changeCard({
    serviceId,
    source,
  });
};

export const deleteCard = async ({ serviceId }: { serviceId: string }) => {
  const { deleteCard } = stripeRepository();
  return await deleteCard({
    serviceId,
  });
};

export const changePlanToBusiness = async ({
  serviceId,
}: {
  serviceId: string;
}) => {
  const { changePlanToBusiness } = stripeRepository();
  return await changePlanToBusiness({
    serviceId,
  });
};

export const changePlanToTeam = async ({
  serviceId,
}: {
  serviceId: string;
}) => {
  const { changePlanToTeam } = stripeRepository();
  return await changePlanToTeam({
    serviceId,
  });
};

export const changePlanToAdvanced = async ({
  serviceId,
}: {
  serviceId: string;
}) => {
  const { changePlanToAdvanced } = stripeRepository();
  return await changePlanToAdvanced({
    serviceId,
  });
};

export const changePlanToHobby = async ({
  serviceId,
}: {
  serviceId: string;
}) => {
  const { changePlanToHobby } = stripeRepository();
  return await changePlanToHobby({
    serviceId,
  });
};

export const changePlanToTemplate = async ({
  serviceId,
}: {
  serviceId: string;
}) => {
  const { changePlanToTemplate } = stripeRepository();
  return await changePlanToTemplate({
    serviceId,
  });
};

export const changeBillingEmail = async ({
  serviceId,
  email,
}: {
  serviceId: string;
  email: string;
}) => {
  const { changeBillingEmail } = stripeRepository();
  return await changeBillingEmail({
    serviceId,
    email,
  });
};

export const changeBillingName = async ({
  serviceId,
  name,
}: {
  serviceId: string;
  name: string;
}) => {
  const { changeBillingName } = stripeRepository();
  return await changeBillingName({
    serviceId,
    name,
  });
};

export const changeBillingPhone = async ({
  serviceId,
  phone,
}: {
  serviceId: string;
  phone: string;
}) => {
  const { changeBillingPhone } = stripeRepository();
  return await changeBillingPhone({
    serviceId,
    phone,
  });
};

export const changeBillingCompany = async ({
  serviceId,
  company,
}: {
  serviceId: string;
  company: string;
}) => {
  const { changeBillingCompany } = stripeRepository();
  return await changeBillingCompany({
    serviceId,
    company,
  });
};

export const purchaseAddons = async ({
  serviceId,
  type,
}: {
  serviceId: string;
  type: AddonType;
}) => {
  const { purchaseAddons } = stripeRepository();
  return await purchaseAddons({
    serviceId,
    type,
  });
};

export const cancelSubscription = async ({
  serviceId,
  cancelSelect,
  cancelReason,
}: {
  serviceId: string;
  cancelSelect: CancelSelect;
  cancelReason: string;
}) => {
  const { cancelSubscription } = stripeRepository();
  return await cancelSubscription({
    serviceId,
    cancelSelect,
    cancelReason,
  });
};

export const restartSubscription = async ({
  serviceId,
  sourceId,
  email,
  name,
  phone,
  company,
}: {
  serviceId: string;
  sourceId: string | null;
  email: string;
  name: string;
  phone: string;
  company: string;
}) => {
  const { restartSubscription } = stripeRepository();
  return await restartSubscription({
    serviceId,
    sourceId,
    email,
    name,
    phone,
    company,
  });
};

export const changeBillingInvoice = async ({
  serviceId,
}: {
  serviceId: string;
}) => {
  const { changeBillingInvoice } = stripeRepository();
  return await changeBillingInvoice({
    serviceId,
  });
};
