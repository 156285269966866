import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { getAllRoles } from '@/usecase/rolesUseCase';
import {
  findSAMLConfiguration,
  updateSAMLConfiguration,
  deleteSAMLConfiguration,
} from '@/usecase/samlUsecase';

import { useApi } from '@/lib/useApi';
import { isReactQueryError } from '@/util/type-guard';

export const useSAMLQuery = (serviceId: string) => {
  const { t } = useTranslation('serviceSAMLSettings');
  const { addToast } = useToasts();
  const cache = useQueryClient();

  const useFindSAMLConfiguration = () => {
    return useApi(
      ['samlConfiguration', serviceId],

      async (serviceId) => await findSAMLConfiguration(serviceId),
      {
        staleTime: Number.POSITIVE_INFINITY,
        enabled: serviceId !== '',
        onError: (error) => {
          if (isReactQueryError(error)) {
            return {
              message: `${error.status}:${error.message}`,
            };
          }
          if (error instanceof Error) {
            return {
              message: error.message,
            };
          }
          return {
            message: 'Something went wrong...',
          };
        },
      },
    );
  };

  const useUpdateSAMLConfiguration = () => {
    return useMutation({
      mutationFn: updateSAMLConfiguration,
      onSuccess: (response) => {
        if (response.result) {
          cache.invalidateQueries(['samlConfiguration', serviceId], {
            type: 'all',
          });
          addToast(t('SAML configuration have been updated'), {
            appearance: 'success',
          });
        } else {
          addToast(response.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      },
      onError: (error: Error) => {
        addToast(
          error.message
            ? error.message
            : t('Could not update SAML configuration'),
          {
            appearance: 'error',
          },
        );
      },
    });
  };

  const useDeleteSAMLConfiguration = () => {
    return useMutation({
      mutationFn: deleteSAMLConfiguration,
      onSuccess: () => {
        cache.invalidateQueries(['samlConfiguration', serviceId], {
          type: 'all',
        });
        addToast(t('SAML configuration have been removed'), {
          appearance: 'success',
        });
      },
      onError: (error: Error) => {
        addToast(
          error.message
            ? error.message
            : t('Could not delete SAML configuration'),
          {
            appearance: 'error',
          },
        );
      },
    });
  };

  /**
   *
   * @deprecated
   * 代わりに views/Common/roles/useRolesReader.ts の useAllRoles を使ってください
   */
  const useAllRoles = () => {
    return useApi(
      ['serviceRoles', serviceId],
      async (serviceId) => await getAllRoles(serviceId),
      {
        staleTime: Number.POSITIVE_INFINITY,
      },
    );
  };

  return {
    useFindSAMLConfiguration,
    useUpdateSAMLConfiguration,
    useDeleteSAMLConfiguration,
    useAllRoles,
  };
};
