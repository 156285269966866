import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import styles from './guestOnlyRoute.module.css';

const GuestOnlyRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isAuthorized === undefined ? (
          <div className={styles.loading}>
            <img src="/images/icon_loading.svg" alt="" />
          </div>
        ) : rest.isAuthorized === false ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', search: window.location.search }} />
        )
      }
    />
  );
};

export default GuestOnlyRoute;
