import * as Sentry from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useMemo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { myRolesContext, useMyRoles } from '@/hooks/Role/useMyRoles';
import { useGetMyService } from '@/hooks/useService';

import Head from '../Head';
import { JoinServiceNotifier } from '../JoinServiceNotifier';
import Nav from '../Nav';
import Routes from '../Routes';
import ServiceSideBar from '../ServiceSideBar';
import Error from '../ui/Error';

import styles from './app.module.css';

import { HasDiffContextProvider } from '@/context/HasDiffContext';
import { MetaContext } from '@/context/MetaContext';
import { getServiceDomain } from '@/util';
import { MomentoProvider } from '@/views/Common/providers/momento';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscribeAuth: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unsubscribeAuth: any;
  isAuthorized: boolean;
};

const App: React.FC<Props> = ({
  subscribeAuth,
  unsubscribeAuth,
  isAuthorized,
}) => {
  const { t } = useTranslation('app');
  const { service, parentService } = useGetMyService();

  useEffect(() => {
    subscribeAuth();
    return () => {
      unsubscribeAuth();
    };
  }, [subscribeAuth, unsubscribeAuth]);

  const isProduction =
    MetaContext.value.isProduction && window.location.protocol === 'https:';

  const myRoles = useMyRoles();
  const isLoadedRoles = myRoles.roles !== undefined;

  const stripePromise = useMemo(() => {
    if (isProduction) {
      return loadStripe('pk_live_ggQNNfnLz0XswhcWXPfinPED002mHuUzu3'); // gitleaks:allow
    }
    return loadStripe('pk_test_3jlO76heyj3VHaaB9CO9yomV006A7RFIRY'); // gitleaks:allow
  }, [isProduction]);

  return (
    <div className={styles.wrapper}>
      <HelmetProvider>
        <myRolesContext.Provider value={myRoles}>
          <HasDiffContextProvider>
            <MomentoProvider serviceId={service?.partitionKey}>
              <BrowserRouter>
                {isAuthorized && <Nav />}
                {isAuthorized &&
                  getServiceDomain() !== '' &&
                  service &&
                  isLoadedRoles && <ServiceSideBar />}
                <div className={styles.main}>
                  <Elements stripe={stripePromise}>
                    <Head title={t('Admin')} />
                    <Sentry.ErrorBoundary fallback={<Error />}>
                      <Routes />
                      {isAuthorized && service && (
                        <JoinServiceNotifier
                          service={service}
                          parentService={parentService}
                        />
                      )}
                    </Sentry.ErrorBoundary>
                  </Elements>
                </div>
              </BrowserRouter>
            </MomentoProvider>
          </HasDiffContextProvider>
        </myRolesContext.Provider>
      </HelmetProvider>
    </div>
  );
};

export default App;
