import { API, graphqlOperation } from 'aws-amplify';

import * as queries from '@/graphql/queries';

export type BandwidthLog = {
  date: string;
  values: [
    {
      mediumRequestCount: number;
      apiRequestCount: number;
      bytes: number;
      time: string;
    },
  ];
};

export const bandwidthLogRepository = (serviceId: string) => {
  /**
   * データ転送量を取得する
   */
  const fetchBandwidthLog = async (): Promise<BandwidthLog[]> => {
    try {
      const result = await API.graphql(
        graphqlOperation(queries.getBandwidthLog, {
          serviceId,
        }),
      );
      const typedResult = result as {
        data: {
          getBandwidthLog: string; // AWSJSON型のためstring
        };
      };

      return JSON.parse(typedResult.data.getBandwidthLog);
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not fetch bandwidth log');
    }
  };
  return { fetchBandwidthLog };
};
