import { useTranslation } from 'react-i18next';

import Forbidden from '@/components/Forbidden';
import { LinkButton } from '@/components/Form';
import Member from '@/components/Member';
import IconWithTextButton from '@/components/ui/IconWithTextButton';
import { Table, Thead, Tbody, Tr, Th, Td } from '@/components/ui/Table';

import { useReviewList } from './useReviewList';

import type { ReviewRequest } from '@/entity/review';

import styles from './reviewlist.module.css';

import { useReviewRequestReadPermission } from '@/views/Common/ReviewRequestPermission/useReviewRequestReadPermission';

export type Props = {
  apiIds: string[];
  fetchNextPage: () => void;
  reviewList?: ReviewRequest[];
  hasNextPage?: boolean;
  selectedStatus: 'OPEN' | 'CLOSE';
  setStatus: React.Dispatch<React.SetStateAction<'OPEN' | 'CLOSE'>>;
};

export type ViewProps = {
  selectedStatus: 'OPEN' | 'CLOSE';
  setStatus: React.Dispatch<React.SetStateAction<'OPEN' | 'CLOSE'>>;
  onHistoryPush: (reqId: number) => void;
  fromNowHandler: (date: string) => string;
  formatDateToMinutesHandler: (date: string) => string;
  fetchNextPage: () => void;
  reviewList?: ReviewRequest[];
  hasNextPage?: boolean;
};

export const ReviewListView: React.FC<ViewProps> = ({
  reviewList,
  onHistoryPush,
  fromNowHandler,
  formatDateToMinutesHandler,
  fetchNextPage,
  hasNextPage,
  selectedStatus,
  setStatus,
}) => {
  const { t } = useTranslation('reviewList');
  return (
    <div>
      <div className={styles.header}>
        <div className={styles.tabs}>
          <IconWithTextButton
            icon="assignment"
            text={t('In Review')}
            outlined={true}
            onClick={() => setStatus('OPEN')}
            selected={selectedStatus === 'OPEN'}
          />
          <IconWithTextButton
            icon="assignment_turned_in"
            text={t('Unpublished')}
            outlined={true}
            onClick={() => setStatus('CLOSE')}
            selected={selectedStatus === 'CLOSE'}
          />
        </div>
      </div>
      <main className={styles.main}>
        {reviewList === undefined ? (
          <div className={styles.loading}>
            <img src="/images/icon_loading.svg" alt="" />
          </div>
        ) : reviewList.length === 0 ? (
          <div className={styles.empty}>
            <img
              className={styles.emptyIcon}
              src="/images/icon_review_chat.svg"
              alt=""
            />
            <p className={styles.emptyTitle}>{t('No Reviews')}</p>
            <p className={styles.emptySubText}>
              {t('A review request can be made from the content editing page.')}
            </p>
          </div>
        ) : (
          <div className={styles.tableWrap}>
            <Table>
              <Thead>
                <Tr>
                  <Th>{t('Title')}</Th>
                  <Th>{t('Assignees')}</Th>
                  <Th>{t('Date Created')}</Th>
                  <Th>{t('Date Updated')}</Th>
                  <Th>{t('Number of Comments')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {reviewList
                  .filter((v) => v)
                  .map((item) => (
                    <Tr
                      key={item.reqId}
                      onClick={() => onHistoryPush(item.reqId)}
                    >
                      <Td>{item.title}</Td>
                      <Td>
                        <Member username={item.authorId} theme="black" />
                      </Td>
                      <Td>{formatDateToMinutesHandler(item.createdAt)}</Td>
                      <Td>{fromNowHandler(item.updatedAt)}</Td>
                      <Td>{item.commentCount}</Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            {hasNextPage && (
              <div className={styles.footer}>
                <LinkButton
                  style={{ width: '100%', height: '100%' }}
                  value={t('See more')}
                  onClick={() => fetchNextPage()}
                />
              </div>
            )}
            <p className={styles.note}>
              {t('A review request can be made from the content editing page.')}
            </p>
          </div>
        )}
      </main>
    </div>
  );
};

export const ReviewList: React.FC<Props> = ({
  apiIds,
  reviewList,
  fetchNextPage,
  hasNextPage,
  selectedStatus,
  setStatus,
}) => {
  const hasReviewReadPermission = useReviewRequestReadPermission({ apiIds });

  const { onHistoryPush, fromNowHandler, formatDateToMinutesHandler } =
    useReviewList();

  if (!hasReviewReadPermission) {
    return <Forbidden />;
  }

  return (
    <ReviewListView
      reviewList={reviewList}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      onHistoryPush={onHistoryPush}
      fromNowHandler={fromNowHandler}
      formatDateToMinutesHandler={formatDateToMinutesHandler}
      selectedStatus={selectedStatus}
      setStatus={setStatus}
    />
  );
};
