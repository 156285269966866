import TableRow from '@tiptap/extension-table-row';

import type { TableRowOptions } from '@tiptap/extension-table-row';

type CustomTableRowOptions = TableRowOptions & {
  inputRules: boolean;
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-table-row/src/table-row.ts
export const CustomTableRow = TableRow.extend<CustomTableRowOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: true,
    };
  },

  parseHTML() {
    return this.options.inputRules ? this.parent?.() : [];
  },
});
