import type { ServiceUser } from '@/entity/member';

/**
 * キーワードでユーザーを検索し、ユーザー名とメールアドレスにキーワードを含むユーザーを返却する
 *
 * @param users 検索するユーザー群
 * @param keyword キーワード
 * @param options.caseSensitive 大文字小文字を区別するかどうか
 * @param options.trimKeyword 前後の空白を削除するかどうか
 */
function searchUsersByKeyword(
  users: Array<ServiceUser>,
  keyword: string,
  options: { caseSensitive?: boolean; trimKeyword?: boolean } = {
    caseSensitive: false,
    trimKeyword: true,
  },
): Array<ServiceUser> {
  let _keyword = keyword;
  if (options.trimKeyword) {
    _keyword = _keyword.trim();
  }
  if (!options.caseSensitive) {
    _keyword = _keyword.toLowerCase();
  }

  if (!_keyword) {
    return users;
  }

  return users.filter(({ name, email }) => {
    return (
      (name &&
        (options.caseSensitive ? name : name.toLowerCase()).includes(
          _keyword,
        )) ||
      (email &&
        (options.caseSensitive ? email : email.toLowerCase()).includes(
          _keyword,
        ))
    );
  });
}

/**
 * ユーザーの名前を返却する
 *
 * nameフィールドが空・存在しない場合は、emailフィールドのlocal-partを返却する
 *
 * @param user
 * @param options.trim 名前を返却する際に前後の空白を削除するかどうか
 */
function getUserNameWithFallback<T extends Pick<ServiceUser, 'name' | 'email'>>(
  user: T,
  options: { trim?: boolean } = { trim: true },
): string {
  const name = user.name || user.email.split('@')[0];
  if (options.trim) {
    return name.trim();
  }
  return name;
}

/**
 * ユーザーのID(登録済みはUUID, サービスに登録されていない場合はPENDING#{メールアドレス}の値)を返却する
 *
 * ※ この関数は、ユーザーのusernameフィールドを返却する（usernameフィールドがIDをとることが直感的ではないため、ヘルパー関数を用意した）
 *
 * @param user
 */
function getUserId<T extends Pick<ServiceUser, 'username'>>(user: T): string {
  return user.username;
}

export { searchUsersByKeyword, getUserNameWithFallback, getUserId };
