import { createContext, useContext } from 'react';

import type { Api } from '@/entity/api';
import type {
  InputExceptionContentPermission,
  Role,
  SettingsDefaultPermission,
} from '@/entity/role';

import { highestLevelPermission } from '@/constants/role';

type ContextValue = {
  /**
   * APIスキーマ群
   */
  apiSchemas: Array<Api>;
  /**
   * 編集するRole
   *
   * ※ 新規作成時はundefined
   */
  originalRole?: Role;
  /**
   * 編集するRoleがADMINであるかのフラグ
   *
   * ※ 新規作成時はfalseが設定される
   *
   * @returns ADMINの場合はtrue
   */
  isAdminRole: boolean;
  /**
   * デフォルト権限が全ての権限を持っているかのフラグ
   *
   * @returns 全ての権限を持っている場合はtrue
   */
  isAllCheckedDefaultPermission: boolean;
  /**
   * 指定した個別権限が全ての権限を持っているかを判定する関数
   *
   * @returns 全ての権限を持っている場合はtrue
   */
  isAllCheckedExceptionContentPermission: (
    id: InputExceptionContentPermission['id'],
  ) => boolean;
  /**
   * 入力内容のバリデーションを実行する関数
   *
   * @returns バリデーションエラーがない場合はtrue
   */
  validateFields: () => boolean;
  /**
   * 権限項目の入力中の内容
   */
  input: FormRoleInputValue;
};

type FormRoleInputValue = {
  /**
   * ロール名
   */
  name: string;
  /**
   * 個別権限
   */
  exceptionContentPermissions: Array<InputExceptionContentPermission>;
} & SettingsDefaultPermission;

/**
 * formRoleStateContextの初期値の内容
 *
 * inputフィールドの内容は初期値はReducerの初期値と一致させる
 *
 * @see web/src/views/Role/FormRole/provider/reducer.ts の `useFormRoleInputValueReducer`
 */
const initialContextValue: ContextValue = {
  apiSchemas: [],
  isAdminRole: false,
  isAllCheckedDefaultPermission: false,
  isAllCheckedExceptionContentPermission: () => false,
  validateFields: () => false,
  input: {
    name: '',
    exceptionContentPermissions: [],
    ...highestLevelPermission,
  },
};

const formRoleStateContext = createContext<ContextValue>(initialContextValue);

function useFormRoleState() {
  return useContext(formRoleStateContext);
}

export { formRoleStateContext, useFormRoleState, type FormRoleInputValue };
