import { useTranslation } from 'react-i18next';

import { useUpdateDraftKey } from './useUpdateDraftKey';

import style from './updatedraftkey.module.css';

export type Props = {
  contentId: string;
  contentDraftKey: string;
  hasUpdateDraftKeyPermission: boolean;
};

export type ViewProps = {
  contentDraftKey: string;
  hasUpdateDraftKeyPermission: boolean;
  doUpdateDraftKey: () => void;
  updateDraftKeyIsLoading: boolean;
};

export const UpdateDraftKeyView: React.FC<ViewProps> = ({
  contentDraftKey,
  hasUpdateDraftKeyPermission,
  doUpdateDraftKey,
  updateDraftKeyIsLoading,
}) => {
  const { t } = useTranslation('updateDraftKey');
  return (
    <dl className={style.keyValue}>
      <dt className={style.key}>draftKey</dt>
      <dd className={style.flex}>
        {contentDraftKey}
        {hasUpdateDraftKeyPermission && (
          <button
            className={style.linkButton}
            onClick={doUpdateDraftKey}
            disabled={updateDraftKeyIsLoading}
            aria-label={t('Update draftKey')}
          >
            <span className={`material-icons ${style.icon}`} aria-hidden>
              refresh
            </span>
          </button>
        )}
      </dd>
    </dl>
  );
};

export const UpdateDraftKey: React.FC<Props> = ({
  contentId,
  contentDraftKey,
  hasUpdateDraftKeyPermission,
}) => {
  const { doUpdateDraftKey, updateDraftKeyIsLoading } =
    useUpdateDraftKey(contentId);

  return (
    <UpdateDraftKeyView
      contentDraftKey={contentDraftKey}
      hasUpdateDraftKeyPermission={hasUpdateDraftKeyPermission}
      doUpdateDraftKey={doUpdateDraftKey}
      updateDraftKeyIsLoading={updateDraftKeyIsLoading}
    />
  );
};
