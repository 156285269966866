import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { defaultManagementPolicy } from '../../constants/apiKeyPolices';
import { myRolesContext } from '../../hooks/Role/useMyRoles';
import { Table, Tbody, Tr, Td } from '../ui/Table';

import type { ManagementPolicy } from '../../types/apiKey';
import type { SetStateAction, Dispatch } from 'react';

import styles from './managementPolicySetting.module.css';

type Props = {
  managementPolicy: ManagementPolicy | null;
  setPolicy: Dispatch<SetStateAction<any>>;
};

const ManagementPolicySetting: React.FC<Props> = ({
  managementPolicy,
  setPolicy,
}) => {
  const { t } = useTranslation('managementPolicySetting');
  const context = useContext(myRolesContext);
  const isAdmin = useMemo(() => {
    return context.roles
      ? context.roles.some((role) => role.isAdmin === true)
      : undefined;
  }, [context.roles]);

  const onChangeSettings = useCallback(
    (e: any) => {
      const { checked, name } = e.target;

      // 既存のAPIキーを考慮
      if (managementPolicy === null) {
        setPolicy(defaultManagementPolicy);
      }

      setPolicy((managementPolicy: ManagementPolicy) => ({
        ...managementPolicy,
        [name]: checked,
      }));
    },
    [setPolicy, managementPolicy],
  );

  if (managementPolicy === undefined) {
    return null;
  }
  return (
    <Table>
      <Tbody>
        <Tr enableHoverStyle={false} isSlim={true}>
          <Td>
            <input
              type="checkbox"
              defaultChecked={managementPolicy?.getContent}
              disabled={!isAdmin}
              name="getContent"
              onChange={onChangeSettings}
            />
            {t('Retrieving content (list and details)')}
            <a
              href={`https://document.microcms.io/management-api/get-list-contents-management`}
              target="docs"
              className={styles.helpLink}
            >
              <i className={`material-icons ${styles.helpIcon}`}>
                help_outline
              </i>
            </a>
          </Td>
        </Tr>
        <Tr enableHoverStyle={false} isSlim={true}>
          <Td>
            <input
              type="checkbox"
              defaultChecked={managementPolicy?.patchContentCreatedBy}
              disabled={!isAdmin}
              name="patchContentCreatedBy"
              onChange={onChangeSettings}
              className={styles.checkbox}
            />
            {t('Change the content creator')}
            <a
              href={`https://document.microcms.io/management-api/patch-contents-created-by`}
              target="docs"
              className={styles.helpLink}
            >
              <i className={`material-icons ${styles.helpIcon}`}>
                help_outline
              </i>
            </a>
          </Td>
        </Tr>
        <Tr enableHoverStyle={false} isSlim={true}>
          <Td>
            <input
              type="checkbox"
              defaultChecked={managementPolicy?.patchContentStatus}
              disabled={!isAdmin}
              name="patchContentStatus"
              onChange={onChangeSettings}
              className={styles.checkbox}
            />
            {t('Change the publication status of content')}
            <a
              href={`https://document.microcms.io/management-api/patch-contents-status`}
              target="docs"
              className={styles.helpLink}
            >
              <i className={`material-icons ${styles.helpIcon}`}>
                help_outline
              </i>
            </a>
          </Td>
        </Tr>
        <Tr enableHoverStyle={false} isSlim={true}>
          <Td>
            <input
              type="checkbox"
              defaultChecked={managementPolicy?.getMedia}
              disabled={!isAdmin}
              name="getMedia"
              onChange={onChangeSettings}
              className={styles.checkbox}
            />
            {t('Retrieve media')}
            <a
              href={`https://document.microcms.io/management-api/get-media-v2`}
              target="docs"
              className={styles.helpLink}
            >
              <i className={`material-icons ${styles.helpIcon}`}>
                help_outline
              </i>
            </a>
          </Td>
        </Tr>
        <Tr enableHoverStyle={false} isSlim={true}>
          <Td>
            <input
              type="checkbox"
              defaultChecked={managementPolicy?.postMedia}
              disabled={!isAdmin}
              name="postMedia"
              onChange={onChangeSettings}
              className={styles.checkbox}
            />
            {t('Upload media')}
            <a
              href={`https://document.microcms.io/management-api/post-media`}
              target="docs"
              className={styles.helpLink}
            >
              <i className={`material-icons ${styles.helpIcon}`}>
                help_outline
              </i>
            </a>
          </Td>
        </Tr>
        <Tr enableHoverStyle={false} isSlim={true}>
          <Td>
            <input
              type="checkbox"
              defaultChecked={managementPolicy?.deleteMedia}
              disabled={!isAdmin}
              name="deleteMedia"
              onChange={onChangeSettings}
              className={styles.checkbox}
            />
            {t('Delete media')}
            <a
              href={`https://document.microcms.io/management-api/delete-media-v2`}
              target="docs"
              className={styles.helpLink}
            >
              <i className={`material-icons ${styles.helpIcon}`}>
                help_outline
              </i>
            </a>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
};

export default ManagementPolicySetting;
