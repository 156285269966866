import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import cx from 'classnames';

import { useTable } from './useTable';

import type { NodeViewProps } from '@tiptap/core';

import styles from './table.module.css';

export type Props = {};

export type ViewProps = {
  width: number;
};

export const TableView: React.FC<ViewProps> = ({ width }) => {
  return (
    <NodeViewWrapper className={cx(styles.wrapper, 'richeditorv2-table')}>
      <NodeViewContent className={styles.table} as="table" style={{ width }} />
    </NodeViewWrapper>
  );
};

export const Table: React.FC<NodeViewProps> = (props) => {
  const { width } = useTable({
    rowCellCount: props.node.firstChild?.childCount || 0,
  });

  return <TableView width={width} />;
};
