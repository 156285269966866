import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';

import TagsSettings from './TagsSettings';
import WebhookSettings from './WebhookSettings';
import CheckRoles from '../CheckRoles';
import PrivateRoute from '../PrivateRoute';
import ExternalLink from '../ui/ExternalLink';
import Menu from '../ui/Menu';
import MenuItem from '../ui/MenuItem';

import styles from './mediaSettings.module.css';

import { DeleteSettings } from '@/views/MediumSettings/DeleteSettings';

type Props = {
  apiId: string;
  apiType: 'LIST' | 'PAGE';
  endpoint: string;
};

const MediaSettings: React.FC<Props> = () => {
  const { t } = useTranslation('mediaSettings');

  return (
    <div className={styles.wrapper}>
      <div className={styles.menu}>
        <Menu title={t('Media Settings')}>
          <MenuItem to="/media/settings" exact={true}>
            {t('Webhooks')}
          </MenuItem>
          <MenuItem to="/media/settings/tags" exact={true}>
            {t('Manage Tags')}
          </MenuItem>
          <CheckRoles permission="mediumPermission" operation="delete">
            <MenuItem to="/media/settings/delete" exact={true}>
              {t('Delete all media')}
            </MenuItem>
          </CheckRoles>
        </Menu>
        <Menu title={t('Document')}>
          <ExternalLink
            href="https://document.microcms.io/manual/media-management"
            className={styles.link}
          >
            {t('Document')}
          </ExternalLink>
        </Menu>
      </div>

      <div className={styles.flex}>
        <Switch>
          <PrivateRoute
            exact
            path="/media/settings"
            component={WebhookSettings}
          />
          <PrivateRoute
            exact
            path="/media/settings/tags"
            component={TagsSettings}
          />
          <CheckRoles
            permission="mediumPermission"
            operation="delete"
            display={true}
          >
            <PrivateRoute
              exact
              path="/media/settings/delete"
              component={DeleteSettings}
            />
          </CheckRoles>
        </Switch>
      </div>
    </div>
  );
};

export default MediaSettings;
