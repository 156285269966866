import type { ContentListRelationProps } from '@/components/ContentListRelation';
import ContentListRelation from '@/components/ContentListRelation';

import { NoPermission } from '../shared/NoPermission';

export type Props = ViewProps;

export type ViewProps = {
  content: ContentListRelationProps['content'] | null;
  displayItem?: ContentListRelationProps['displayItem'];
};

export const RelationView: React.FC<ViewProps> = ({ content, displayItem }) => {
  if (content === null) return <NoPermission />;

  return <ContentListRelation content={content} displayItem={displayItem} />;
};

export const Relation: React.FC<Props> = (props) => {
  return <RelationView {...props} />;
};
