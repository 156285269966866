import cx from 'classnames';
import { forwardRef } from 'react';

import styles from './selectbutton.module.css';

type SizeType = 'small' | 'medium' | 'large' | 'full';

type Props = {
  value: string;
  size?: SizeType;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  errorText?: string | null;
  hasError?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onFocus?: React.FocusEventHandler<HTMLButtonElement>;
};

const SelectButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      value,
      size = 'full',
      disabled = false,
      className = '',
      style,
      errorText = null,
      hasError = false,
      onClick,
      onFocus,
      ...props
    },
    ref,
  ) => {
    return (
      <>
        <button
          type="button"
          style={style}
          className={cx(
            styles.button,
            styles[size],
            {
              [styles.hasError]: errorText !== null || hasError,
              [styles.disabled]: disabled,
            },
            className,
          )}
          onClick={onClick}
          onFocus={onFocus}
          disabled={disabled}
          ref={ref}
          {...props}
        >
          <span>{value}</span>
          <i className={cx('material-icons', styles.icon)}>expand_more</i>
        </button>
        {errorText && <p className={styles.errorText}>{errorText}</p>}
      </>
    );
  },
);
SelectButton.displayName = 'SelectButton';

export default SelectButton;
