import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { createMediumTags } from '@/usecase/mediumTagUsecase';

import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';

import type { MediumTag, NewMediumTag } from '../../types/MediumTag';

import { useApi } from '@/lib/useApi';

type ListMediumTags = {
  tags: MediumTag[];
};

type UseListMediumTags = {
  listMediumTags?: ListMediumTags;
  listhMediumTagsLoading?: boolean;
};

export const useListMediumTags = (
  serviceId: string,
  text = '',
): UseListMediumTags => {
  const getListMediumTagsQuery = useApi(
    ['mediumTags', { text }],
    async (): Promise<ListMediumTags> => {
      const response = (await API.graphql(
        graphqlOperation(queries.listMediumTags, {
          serviceId,
          text,
        }),
      )) as { data: { listMediumTags: ListMediumTags } };

      return response.data.listMediumTags;
    },
  );

  const { data: listMediumTags, isLoading: listhMediumTagsLoading } =
    getListMediumTagsQuery;

  return {
    listMediumTags,
    listhMediumTagsLoading,
  };
};

export const useUpdateMediumTagName = () => {
  const { t } = useTranslation('hooksMediumTags');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  const { mutate: updateMediumTagName, isLoading: updateMediumTagNameLoading } =
    useMutation({
      mutationFn: async ({ serviceId, mediumTagId, name }: any) => {
        const response: any = await API.graphql(
          graphqlOperation(mutations.updateMediumTagName, {
            serviceId,
            mediumTagId,
            name,
          }),
        );
        const data = JSON.parse(await response.data.updateMediumTagName);

        return data;
      },
      onSuccess(data, { closeModal }) {
        if (data.result === false) {
          addToast(data.message, { appearance: 'error' });
        } else {
          cache.invalidateQueries(['mediumTags'], { type: 'all' });
          closeModal();
          addToast(t('A tag has been updated.'), {
            appearance: 'success',
          });
        }
      },
      onError({ message }) {
        addToast(message ? message : t('Failed to make changes.'), {
          appearance: 'error',
        });
      },
    });
  return { updateMediumTagName, updateMediumTagNameLoading };
};

export const useDeleteMediumTag = () => {
  const { t } = useTranslation('hooksMediumTags');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  const { mutate: deleteMediumTag } = useMutation({
    mutationFn: async ({ serviceId, mediumTagId }: any) => {
      const response: any = await API.graphql(
        graphqlOperation(mutations.deleteMediumTag, {
          serviceId,
          mediumTagId,
        }),
      );
      const data = JSON.parse(await response.data.deleteMediumTag);

      return data;
    },
    onSuccess(data) {
      if (data.result === false) {
        const errorMessage = data.message;
        addToast(errorMessage ? errorMessage : t('Failed to make changes.'), {
          appearance: 'error',
        });
        return;
      }
      cache.invalidateQueries(['mediumTags'], { type: 'all' });
      addToast(t('A tag has been deleted.'), {
        appearance: 'success',
      });
    },
    onError({ errors }) {
      addToast(errors ? errors[0].message : t('Failed to make changes.'), {
        appearance: 'error',
      });
    },
  });
  return [deleteMediumTag];
};

type CreateMediumTagsParams = {
  serviceId: string;
  newMediumTags: NewMediumTag[];
};

export const useCreateMediumTags = () => {
  const { t } = useTranslation('hooksMediumTags');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  const {
    mutateAsync: createMediumTagsAsync,
    isLoading: createMediumTagsLoading,
  } = useMutation({
    mutationFn: async ({
      serviceId,
      newMediumTags,
    }: CreateMediumTagsParams) => {
      return createMediumTags({ serviceId, newMediumTags });
    },
    onSuccess() {
      cache.invalidateQueries(['mediumTags'], { type: 'all' });
    },
    onError({ message }) {
      addToast(message ? message : t('Failed to make changes.'), {
        appearance: 'error',
      });
    },
  });
  return { createMediumTagsAsync, createMediumTagsLoading };
};
