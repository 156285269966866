import * as Dialog from '@radix-ui/react-dialog';
import cx from 'classnames';
import { createElement } from 'react';

import styles from './sidemodal.module.css';

export type ContentProps = {
  /**
   * The size of the side menu. Defaults to medium.
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Whether to add space around the content. Defaults to true.
   */
  hasSpace?: boolean;
  /**
   * The position of the side menu. Defaults to right.
   */
  position?: 'left' | 'right';
  title?: string;
  /**
   * The title element to use. Defaults to h4.
   */
  titleAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children: React.ReactNode;
} & Dialog.DialogContentProps;

export const SideModalContent: React.FC<ContentProps> = ({
  size = 'medium',
  hasSpace = true,
  position = 'right',
  title,
  titleAs = 'h4',
  children,
  ...props
}) => {
  return (
    <Dialog.Portal>
      <Dialog.Overlay className={styles.mask} />
      <Dialog.Content
        className={cx(
          styles.container,
          styles[size],
          hasSpace && styles.hasSpace,
          styles[position],
        )}
        {...props}
      >
        {title && (
          <Dialog.Title asChild>
            {createElement(titleAs, { className: styles.title }, title)}
          </Dialog.Title>
        )}
        {children}
      </Dialog.Content>
    </Dialog.Portal>
  );
};

type Props = Dialog.DialogProps & {
  onOpen?: () => void;
  onClose?: () => void;
};
export const SideModal: React.FC<Props> = ({
  onOpen,
  onClose,
  onOpenChange,
  ...props
}) => {
  return (
    <Dialog.Root
      onOpenChange={(open) => {
        if (open) {
          onOpen?.();
        } else {
          onClose?.();
        }
        onOpenChange?.(open);
      }}
      {...props}
    />
  );
};

export const SideModalTrigger = Dialog.Trigger;
