import { contentRepository } from '@/repository/contentRepository';

import type { SearchOptions } from '@/API';
import type { MigrateApi } from '@/entity/api';
import type {
  BulkContentStatus,
  Content,
  ContentByVersion,
  ContentStatus,
  ContentValue,
} from '@/entity/content';
import type { CustomField, Field } from '@/types/contents';
import { filterUniqueValues } from '@/util/array';

import { getContentId } from '@/entity/content';

export type MigrateContent = Content & {
  contentId: string;
  domain: string;
  id: string;
  data: ContentValue;
  reservationTime?: string;
  reservationStopTime?: string;
  contentDraftKey?: string;
};

export const findContent = async (
  api: Pick<MigrateApi, 'partitionKey' | 'domain' | 'apiEndpoint'>,
  contentId: string,
): Promise<MigrateContent | null> => {
  const { findContent } = contentRepository();
  const content = await findContent(api.partitionKey, contentId);

  if (content === null) {
    return null;
  }

  const contentValue =
    content.contentStatus === 'PUBLISH'
      ? content.contentValue
      : content.contentStatus === 'CLOSED'
        ? content.closedValue
        : content.draftValue;

  const migrateContent = {
    ...content,
    //旧DBとの整合性を取る
    contentId: content.gsiSortKey2.replace(/.*#CONTENT_ID#/, ''),
    domain: api.domain,
    id: `${api.domain}#${api.apiEndpoint}`,
    data: contentValue && JSON.parse(contentValue),
    reservationTime: content.gsiSinglePartitionKey1 && content.reservationTime,
    reservationStopTime:
      content.gsiSinglePartitionKey1 && content.reservationStopTime,
    contentDraftKey:
      content.contentStatus.includes('DRAFT') ||
      content.contentStatus.includes('RESERVATION')
        ? content.contentDraftKey
        : undefined,
  };
  return migrateContent;
};

export const searchContents = async (
  apiId: string,
  options: SearchOptions = {},
) => {
  const { searchContents } = contentRepository();

  return searchContents(apiId, options);
};

export const findContentByVersion = async ({
  contentId,
  version,
  denyDraft,
}: {
  contentId: string;
  version: number;
  denyDraft?: boolean;
}): Promise<ContentByVersion> => {
  const { findContentByVersion } = contentRepository();

  const result = await findContentByVersion({
    contentId,
    version,
    denyDraft,
  });

  return {
    value: JSON.parse(result.value),
    unauthorizedFieldIds: result.unauthorizedFieldIds,
  };
};

export const ensureContentObjectExists = (apiId: string) => {
  const { ensureContentObjectExists } = contentRepository();

  return ensureContentObjectExists(apiId);
};

export const updateContent = async ({
  contentId,
  value,
  contentStatus,
}: {
  contentId: string;
  value: string;
  contentStatus: ContentStatus;
}): Promise<{ contentId: string }> => {
  const { updateContent } = contentRepository();

  return {
    contentId: (
      await updateContent({ contentId, value, contentStatus })
    ).gsiSortKey2.replace(/.*#CONTENT_ID#/, ''),
  };
};

export const getContentInitialValues = (
  api: MigrateApi,
  customFields: CustomField[],
) => {
  const initialValues: ContentValue = {};
  for (const field of api.apiFields) {
    switch (field.kind) {
      case 'boolean':
        if (field.booleanInitialValue != null) {
          initialValues[field.idValue] = {
            [field.kind]: field.booleanInitialValue,
          };
        }
        break;
      case 'select':
        if (field.selectInitialValue != null) {
          const validInitialValue = field.selectInitialValue.filter(
            (value) =>
              field.selectItems &&
              field.selectItems.some((item) => item?.id === value),
          );
          initialValues[field.idValue] = {
            [field.kind]: validInitialValue,
          };
        }
        break;
      case 'custom':
        const customField = customFields.find(
          (v) => v.createdAt === field.customFieldCreatedAt,
        );
        if (customField == null) {
          continue;
        }

        initialValues[field.idValue] = {
          [field.kind]: {
            ...getCustomFieldInitialValues(customField),
          },
        };
        break;
      case 'repeater':
        initialValues[field.idValue] = {
          [field.kind]: [],
        };
        break;
    }
  }

  return initialValues;
};

export const getCustomFieldInitialValues = (customField: CustomField) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customFieldInitialValues: ContentValue = {};

  if (customField.fields == null) {
    return customFieldInitialValues;
  }

  for (const fieldOfCustomField of customField.fields) {
    if (fieldOfCustomField == null || fieldOfCustomField.idValue == null) {
      continue;
    }
    switch (fieldOfCustomField.kind) {
      case 'boolean':
        if (fieldOfCustomField.booleanInitialValue != null) {
          customFieldInitialValues[fieldOfCustomField.idValue] = {
            [fieldOfCustomField.kind]: fieldOfCustomField.booleanInitialValue,
          };
        }
        break;
      case 'select':
        if (fieldOfCustomField.selectInitialValue != null) {
          const validInitialValue =
            fieldOfCustomField.selectInitialValue.filter(
              (value) =>
                fieldOfCustomField.selectItems &&
                fieldOfCustomField.selectItems.some(
                  (item) => item?.id === value,
                ),
            );
          customFieldInitialValues[fieldOfCustomField.idValue] = {
            [fieldOfCustomField.kind]: validInitialValue,
          };
        }
        break;
      case 'repeater':
        customFieldInitialValues[fieldOfCustomField.idValue] = {
          [fieldOfCustomField.kind]: [],
        };
        break;
    }
  }

  return customFieldInitialValues;
};

export const createCustomField = async ({
  apiId,
  fieldId,
  name,
  fields,
  position,
}: {
  apiId: string;
  fieldId: string;
  name: string;
  fields: Field[];
  position: string[][];
}) => {
  const { createCustomField } = contentRepository();
  return await createCustomField({
    apiId,
    fieldId,
    name,
    fields: JSON.stringify(
      fields?.map((v) => ({
        ...v,
        isAdditionalField: undefined,
        // richEditorV2ColorListが存在する場合は重複を削除する
        richEditorV2ColorList: v.richEditorV2ColorList
          ? filterUniqueValues(v.richEditorV2ColorList)
          : undefined,
      })),
    ),
    position: JSON.stringify(position),
  });
};

export const updateCustomField = async ({
  apiId,
  fieldId,
  createdAt,
  name,
  fields,
  position,
}: {
  apiId: string;
  fieldId: string;
  createdAt: string;
  name: string;
  fields: Field[];
  position: string[][];
}) => {
  const { updateCustomField } = contentRepository();
  return await updateCustomField({
    apiId,
    fieldId,
    createdAt,
    name,
    fields: JSON.stringify(
      fields?.map((v) => ({
        ...v,
        isAdditionalField: undefined,
        // richEditorV2ColorListが存在する場合は重複を削除する
        richEditorV2ColorList: v.richEditorV2ColorList
          ? filterUniqueValues(v.richEditorV2ColorList)
          : undefined,
      })),
    ),
    position: JSON.stringify(position),
  });
};

export const deleteCustomField = async ({
  apiId,
  createdAt,
}: {
  apiId: string;
  createdAt: string;
}) => {
  const { deleteCustomField } = contentRepository();
  return await deleteCustomField({
    apiId,
    createdAt,
  });
};

export const updateDraftKey = async (contentId: string) => {
  const { updateDraftKey } = contentRepository();
  return await updateDraftKey(contentId);
};

export const updatePublishedAt = async (
  contentId: string,
  publishedAt: Date,
) => {
  const { updatePublishedAt } = contentRepository();
  return await updatePublishedAt(contentId, publishedAt);
};

export const deleteDraft = async ({
  contentId,
}: {
  contentId: string;
}): Promise<{ contentId: string }> => {
  const { deleteDraft } = contentRepository();

  return {
    contentId: (await deleteDraft({ contentId })).gsiSortKey2.replace(
      /.*#CONTENT_ID#/,
      '',
    ),
  };
};

export const moveContentToFirst = async ({
  contentId,
}: {
  contentId: string;
}) => {
  const { moveContentToFirst } = contentRepository();
  return await moveContentToFirst({ contentId });
};

export const moveContentToUp = async ({ contentId }: { contentId: string }) => {
  const { moveContentToUp } = contentRepository();
  return await moveContentToUp({ contentId });
};

export const moveContentToDown = async ({
  contentId,
}: {
  contentId: string;
}) => {
  const { moveContentToDown } = contentRepository();
  return await moveContentToDown({ contentId });
};

export const moveContentToLast = async ({
  contentId,
}: {
  contentId: string;
}) => {
  const { moveContentToLast } = contentRepository();
  return await moveContentToLast({ contentId });
};

export const moveContentToAnyPlace = async ({
  contentId,
  targetContentId,
  isLast,
}: {
  contentId: string;
  targetContentId: string;
  isLast: boolean;
}) => {
  const { moveContentToAnyPlace } = contentRepository();
  return await moveContentToAnyPlace({ contentId, targetContentId, isLast });
};

export const importContentList = async ({
  bucket,
  identityId,
  fileName,
}: {
  bucket: string;
  identityId: string;
  fileName: string;
}) => {
  const { importContentList } = contentRepository();
  return await importContentList({ bucket, identityId, fileName });
};

export const createContent = async ({
  apiId,
  contentId,
  value,
  contentStatus,
}: {
  apiId: string;
  contentId: string;
  value: string;
  contentStatus: ContentStatus;
}) => {
  const { createContent } = contentRepository();
  return await createContent({ apiId, contentId, value, contentStatus });
};

export const copyContent = async ({ contentId }: { contentId: string }) => {
  const { copyContent } = contentRepository();
  return await copyContent({ contentId });
};

export const setReservationTime = async ({
  contentId,
  startDate,
  stopDate,
}: {
  contentId: string;
  startDate: string;
  stopDate: string;
}) => {
  const { setReservationTime } = contentRepository();

  const content = await setReservationTime({ contentId, startDate, stopDate });

  return { contentId: getContentId(content) };
};

export const updateContentStatus = async ({
  contentId,
  customStatusId,
  behaviour,
}: {
  contentId: string;
  customStatusId: string;
  behaviour: ContentStatus;
}) => {
  const { updateContentStatus } = contentRepository();

  const content = await updateContentStatus({
    contentId,
    customStatusId,
    behaviour,
  });

  return { contentId: getContentId(content) };
};

export const createdContentId = async ({ apiId }: { apiId: string }) => {
  const { generateUnusedContentId } = contentRepository();
  return await generateUnusedContentId({ apiId });
};

export const updateContentId = async ({
  contentId,
  userDefinedId,
}: {
  contentId: string;
  userDefinedId: string;
}) => {
  const { updateContentId } = contentRepository();

  const content = await updateContentId({ contentId, userDefinedId });

  return { contentId: getContentId(content) };
};

export const deleteContent = async ({ contentId }: { contentId: string }) => {
  const { deleteContent } = contentRepository();
  return await deleteContent({ contentId });
};

export const deleteAllContents = async ({ apiId }: { apiId: string }) => {
  const { deleteAllContents } = contentRepository();
  return await deleteAllContents({ apiId });
};

export const bulkDeleteContents = async ({
  apiId,
  contentIds,
}: {
  apiId: string;
  contentIds: string[];
}) => {
  const { bulkDeleteContents } = contentRepository();
  return await bulkDeleteContents({ apiId, contentIds });
};

export const bulkUpdateContentsStatus = async ({
  apiId,
  contentIds,
  contentStatus,
}: {
  apiId: string;
  contentIds: string[];
  contentStatus: BulkContentStatus;
}) => {
  const { bulkUpdateContentsStatus } = contentRepository();
  return await bulkUpdateContentsStatus({ apiId, contentIds, contentStatus });
};
