import { useMemo } from 'react';

import S3Image from '../S3Image';

import styles from './profileImage.module.css';

type Props = {
  picture?: string | null;
  title?: string;
  isApi?: boolean;
};

const ProfileImage: React.FC<Props> = ({ picture, title, isApi = false }) => {
  const [identityId, fileName] = useMemo(
    () => (picture ? picture.split(',') : []),
    [picture],
  );

  return (
    <div className={styles.wrapper} title={title}>
      {identityId && fileName ? (
        <span className={styles.selectedImage}>
          <S3Image
            directory={`protected/${identityId}`}
            fileName={fileName}
            kind={'IMAGE'}
            queryString="?fit=crop&w=32&h=32"
            className={styles.selectedImage}
            style={{ background: 'none', border: 'none' }}
          />
        </span>
      ) : picture ? (
        <img
          className={styles.defaultImage}
          width={32}
          height={32}
          src={picture}
          alt=""
        />
      ) : (
        <img
          className={styles.defaultImage}
          width={32}
          height={32}
          src={isApi ? '/images/icon_api.svg' : '/images/icon_person.svg'}
          alt=""
        />
      )}
    </div>
  );
};

export default ProfileImage;
