import { Link, Redirect } from 'react-router-dom';

import Button from '@/components/ui/Button';
import IconWithTextButton from '@/components/ui/IconWithTextButton';

import { useCreateRole } from './helpers/useCreateRole';
import { useDeleteRole } from './helpers/useDeleteRole';
import { useUpdateRole } from './helpers/useUpdateRole';
import { useI18nFormRole } from './i18n';
import { useFormRoleState } from './provider';

import type { Role } from '@/entity/role';
import type { Service } from '@/types/services';
import type { FC } from 'react';

import styles from './Actions.module.css';

import { useHasRolePermission } from '@/views/Common/Roles/MyRolesPermission';
import { Icon } from '@/views/Common/Ui/Icon';

type Props = {
  currentServiceId: Service['partitionKey'];
};

const Actions: FC<Props> = (props) => {
  const { originalRole } = useFormRoleState();

  return (
    <div role="toolbar" className={styles.wrapper}>
      <Link to="/roles" className={styles.backButton}>
        <Icon size="large" name="arrow_back_ios" />
      </Link>

      {/* NOTE: originalRoleがある場合は編集 */}
      {originalRole ? (
        <ActionForEdit {...props} currentRoleId={originalRole.roleId} />
      ) : (
        <ActionForCreate {...props} />
      )}
    </div>
  );
};

const ActionForCreate: FC<Props> = ({ currentServiceId }) => {
  const { t } = useI18nFormRole();
  const { input, validateFields } = useFormRoleState();

  const { hasCreateRolePermission } = useHasRolePermission();

  const [createRole, resultOfCreateRole, isCreating] =
    useCreateRole(currentServiceId);

  if (!hasCreateRolePermission) {
    return null;
  }

  /**
   * 新規作成が完了したらロール一覧へ遷移させる
   */
  if (resultOfCreateRole !== undefined) {
    return <Redirect to="/roles" />;
  }

  return (
    <Button
      value={t('Create new')}
      disabled={isCreating || !validateFields()}
      onClick={() => {
        if (validateFields()) {
          createRole(input);
        }
      }}
      data-testid="createRoleButton"
    />
  );
};

const ActionForEdit: FC<
  Props & {
    currentRoleId: Role['roleId'];
  }
> = ({ currentServiceId, currentRoleId }) => {
  const { t } = useI18nFormRole();
  const { input, validateFields } = useFormRoleState();

  const { hasDeleteRolePermission, hasUpdateRolePermissionTargetRole } =
    useHasRolePermission();

  const [deleteRole, resultOfDeleteRole, isDeleting] = useDeleteRole(
    currentServiceId,
    currentRoleId,
  );
  const [updateRole, , isUpdating] = useUpdateRole(
    currentServiceId,
    currentRoleId,
  );

  /**
   * 削除が完了したらロール一覧へ遷移させる
   */
  if (resultOfDeleteRole !== undefined) {
    return <Redirect to="/roles" />;
  }

  const isProcessing = isDeleting || isUpdating;

  return (
    <div className={styles.buttons}>
      {hasDeleteRolePermission && (
        <IconWithTextButton
          text={t('Delete')}
          disabled={isProcessing}
          icon="delete"
          onClick={() => {
            // 削除前に確認ダイアログを表示
            const allowToDelete = window.confirm(
              t(
                'This cannot be recovered once deleted. Are you sure you want to delete it?',
              ),
            );
            if (allowToDelete) {
              deleteRole();
            }
          }}
          className={styles.deleteButton}
          data-testid="deleteRoleButton"
        />
      )}
      {hasUpdateRolePermissionTargetRole(currentRoleId) && (
        <Button
          value={t('Save changes')}
          disabled={isProcessing || !validateFields()}
          onClick={() => {
            if (validateFields()) {
              updateRole(input);
            }
          }}
          data-testid="updateRoleButton"
        />
      )}
    </div>
  );
};

export default Actions;
