import type React from 'react';

import { useStripeActions } from '../../hooks/useStripeActions';
import Suspended from '../Suspended';

import type { Service } from '../../types/services';

type Props = {
  children: React.ReactChildren;
  service: Service;
  parentService: Service;
};
const CheckPlans = ({ children, service, parentService }: Props) => {
  const { planListQuery } = useStripeActions(service);
  const { data: planList } = planListQuery;
  if (!planList) {
    return null;
  }
  if (planList.suspended === true || parentService?.suspended === true) {
    return <Suspended service={service} parentService={parentService} />;
  }
  return children;
};

export default CheckPlans;
