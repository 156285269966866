import { isMacOS } from '@tiptap/core';
import { Strike } from '@tiptap/extension-strike';

import type { StrikeOptions } from '@tiptap/extension-strike';

type CustomUnderlineOptions = StrikeOptions & {
  inputRules: boolean;
};

// NOTE:https://github.com/ueberdosis/tiptap/blob/main/packages/extension-strike/src/strike.ts
export const CustomStrike = Strike.extend<CustomUnderlineOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      inputRules: true,
    };
  },

  parseHTML() {
    return this.options.inputRules ? this.parent?.() : [];
  },

  addKeyboardShortcuts() {
    if (this.options.inputRules) {
      const shortcuts: Record<string, () => boolean> = {};
      if (isMacOS()) {
        shortcuts['Mod-Shift-x'] = () => this.editor.commands.toggleStrike();
      } else {
        shortcuts['Ctrl-Shift-x'] = () => this.editor.commands.toggleStrike();
      }

      return shortcuts;
    }

    return {};
  },

  addInputRules() {
    return this.options.inputRules ? this.parent?.() || [] : [];
  },

  addPasteRules() {
    return this.options.inputRules ? this.parent?.() || [] : [];
  },
});
