import { API, graphqlOperation } from 'aws-amplify';

import type { GraphQLResult } from '@aws-amplify/api-graphql';

import * as queries from '@/graphql/queries';
import {
  handleApiResult,
  type ApiResponse,
} from '@/views/Common/handleApiResult';

export const serviceWriterRepository = () => {
  const putService = async ({
    partitionKey,
    serviceName,
    serviceImage,
  }: {
    partitionKey: string;
    serviceName: string;
    serviceImage: object;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.updateService, {
          serviceId: partitionKey,
          name: serviceName,
          image: JSON.stringify(serviceImage),
        }),
      )) as GraphQLResult<{
        updateService: {
          message: string;
          result: boolean;
        };
      }>;

      if (!result.data) {
        throw new Error('Unexpected error');
      }

      // TODO: フロントエンドエラー改善
      if (
        typeof result.data.updateService === 'object' &&
        typeof result.data.updateService.result === 'boolean'
      ) {
        const success = result.data.updateService.result;

        if (success) {
          return result.data.updateService;
        } else {
          throw new Error(
            result.data.updateService.message ?? 'Unexpected error',
          );
        }
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected error');
    }
  };

  const putServiceId = async ({
    partitionKey,
    domain,
  }: {
    partitionKey: string;
    domain: string;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(queries.updateServiceId, {
          serviceId: partitionKey,
          domain: domain,
        }),
      )) as GraphQLResult<{
        updateServiceId: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected error');
      }

      // TODO: フロントエンドエラー改善
      if (
        typeof result.data.updateServiceId === 'object' &&
        typeof result.data.updateServiceId?.result === 'boolean'
      ) {
        return handleApiResult(result.data.updateServiceId);
      } else if (typeof result.data.updateServiceId === 'string') {
        return result.data.updateServiceId;
      } else {
        throw new Error('Unexpected error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected error');
    }
  };

  return {
    putService,
    putServiceId,
  };
};
