import { PlainText } from './comparator/PlainText';
import { Switch } from './comparator/Switch';
import { Custom } from './fields/Custom';
import { Media } from './fields/Media';
import { Repeater } from './fields/Repeater';
import { RichEditor } from './fields/RichEditor';
import { beautifyJson } from './parts/beautifyJson';

import type { MigrateApi } from '@/entity/api';
import type { CustomField } from '@/types/contents';
import type { FieldKind } from '@/types/field';

import { formatDateToMinutes } from '@/util/date';

type Props = {
  apiField: MigrateApi['apiFields'][number];
  customFields: CustomField[];
  kind: FieldKind;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sinceValue: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  untilValue: any;
};

const ComparisonField: React.FC<Props> = ({
  apiField,
  customFields,
  kind,
  sinceValue,
  untilValue,
}) => {
  switch (kind) {
    case 'text': {
      return (
        <PlainText
          sinceValue={sinceValue ?? ''}
          untilValue={untilValue ?? ''}
          diffType="char"
        />
      );
    }
    case 'textArea': {
      return (
        <PlainText
          sinceValue={sinceValue ?? ''}
          untilValue={untilValue ?? ''}
          diffType="char"
        />
      );
    }
    case 'richEditor': {
      return (
        <RichEditor
          sinceValue={sinceValue ?? ''}
          untilValue={untilValue ?? ''}
        />
      );
    }
    case 'richEditorV2': {
      return (
        <RichEditor
          sinceValue={sinceValue ?? ''}
          untilValue={untilValue ?? ''}
        />
      );
    }
    case 'media': {
      return (
        <Media sinceValue={sinceValue ?? {}} untilValue={untilValue ?? {}} />
      );
    }
    case 'mediaList': {
      return (
        <Media
          sinceValue={sinceValue?.filter(Boolean) ?? []}
          untilValue={untilValue?.filter(Boolean) ?? []}
        />
      );
    }
    case 'date': {
      return (
        <PlainText
          sinceValue={sinceValue ? formatDateToMinutes(sinceValue) : ''}
          untilValue={untilValue ? formatDateToMinutes(untilValue) : ''}
          diffType="char"
        />
      );
    }
    case 'boolean': {
      return (
        <Switch
          sinceValue={sinceValue ?? undefined}
          untilValue={untilValue ?? undefined}
        />
      );
    }
    case 'select': {
      return (
        <PlainText
          sinceValue={sinceValue?.join('\n') ?? ''}
          untilValue={untilValue?.join('\n') ?? ''}
          diffType="char"
        />
      );
    }
    case 'relation': {
      return (
        <PlainText
          sinceValue={sinceValue?.id ?? ''}
          untilValue={untilValue?.id ?? ''}
          diffType="char"
        />
      );
    }
    case 'relationList': {
      return (
        <PlainText
          sinceValue={
            sinceValue?.map((value: { id: string }) => value.id)?.join('\n') ??
            ''
          }
          untilValue={
            untilValue?.map((value: { id: string }) => value.id)?.join('\n') ??
            ''
          }
          diffType="char"
        />
      );
    }
    case 'number': {
      return (
        <PlainText
          sinceValue={sinceValue ? sinceValue.toString() : ''}
          untilValue={untilValue ? untilValue.toString() : ''}
          diffType="char"
        />
      );
    }
    case 'file': {
      return (
        <PlainText
          sinceValue={sinceValue ? sinceValue.url : ''}
          untilValue={untilValue ? untilValue.url : ''}
          url
        />
      );
    }
    case 'custom': {
      return (
        <Custom
          apiField={apiField}
          customFields={customFields}
          sinceValue={sinceValue ?? {}}
          untilValue={untilValue ?? {}}
        />
      );
    }
    case 'repeater': {
      return (
        <Repeater
          customFields={customFields}
          // すでにカスタムフィールドが削除されているケースではnullが入るため、filter(Boolean)で除外する
          sinceValue={sinceValue?.filter(Boolean) ?? []}
          untilValue={untilValue?.filter(Boolean) ?? []}
        />
      );
    }
    case 'iframe': {
      return (
        <PlainText
          sinceValue={sinceValue ? beautifyJson(sinceValue) : ''}
          untilValue={untilValue ? beautifyJson(untilValue) : ''}
          json
          diffType="char"
        />
      );
    }
  }
};

export { ComparisonField };
