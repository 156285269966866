import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

import styles from './reviewscount.module.css';

import { useReviewRequestReadPermission } from '@/views/Common/ReviewRequestPermission/useReviewRequestReadPermission';

export type Props = {
  apiIds: string[];
  reviewRequestsCount: number;
  closeSpMenu: () => void;
};

export type ViewProps = {
  reviewRequestsCount: number;
  closeSpMenu: () => void;
};

export const ReviewsCountView: React.FC<ViewProps> = ({
  reviewRequestsCount,
  closeSpMenu,
}) => {
  const { t } = useTranslation('reviewsCount');
  return (
    <div className={styles.main}>
      <dl>
        <dt className={styles.title}>
          <Link to="/reviews" onClick={closeSpMenu}>
            {t('Reviews')}
          </Link>
        </dt>
        <dd className={styles.length}>
          <NavLink
            to="/reviews"
            activeClassName={styles.isActive}
            onClick={closeSpMenu}
          >
            <i className={`material-icons-outlined`}>question_answer</i>
            {t('Number of Reviews', { count: reviewRequestsCount })}
          </NavLink>
        </dd>
      </dl>
    </div>
  );
};

export const ReviewsCount: React.FC<Props> = ({
  apiIds,
  reviewRequestsCount,
  closeSpMenu,
}) => {
  const hasReviewRequestReadPermission = useReviewRequestReadPermission({
    apiIds,
  });

  if (!hasReviewRequestReadPermission) {
    return null;
  }
  return (
    <ReviewsCountView
      reviewRequestsCount={reviewRequestsCount}
      closeSpMenu={closeSpMenu}
    />
  );
};
