import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import type { Props } from './MediaField';

export const useMediaField = ({ onChange }: Pick<Props, 'onChange'>) => {
  const { t } = useTranslation('mediaField');
  const { addToast } = useToasts();

  const onDropAccepted = useCallback(
    (resultMediumIds: (string | null)[] | null) => {
      const mediumId = resultMediumIds?.[0];

      if (!mediumId) {
        addToast(t('Failed to upload images'), {
          appearance: 'error',
        });
        return;
      }

      onChange({ mediumId });
    },
    [addToast, onChange, t],
  );

  return {
    onDropAccepted,
  };
};
