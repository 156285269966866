import deepEqual from 'deep-equal';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';

import type { ContentValue } from '@/entity/content';
import type { Location } from 'history';

import { AlertDialog } from '@/views/Common/Ui/AlertDialog';

type Props = {
  getContent: () => ContentValue;
  data?: ContentValue;
  tryingToSave: boolean;
};

/**
 * react-router-dom v5のPromptではカスタムコンポーネントを渡せないため、ラッパーコンポーネントを作成
 * v6にバージョンアップしたら、useBlockerが使えるのでこのコンポーネントは不要になる
 */
export const CustomPrompt: React.FC<Props> = ({
  getContent,
  data,
  tryingToSave,
}) => {
  const { t } = useTranslation('contentActions');
  const history = useHistory();

  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const [confirm, setConfirm] = useState(false);

  const onConfirmNavigation = useCallback(() => {
    setConfirm(true);
  }, [setConfirm]);

  const onCloseDialog = useCallback(() => {
    setNextLocation(null);
  }, [setNextLocation]);

  useEffect(() => {
    if (confirm && nextLocation) {
      history.push(nextLocation?.pathname);
    }
  }, [confirm, history, nextLocation]);

  return (
    <>
      <AlertDialog
        open={!!nextLocation}
        trigger={<></>}
        description={t(
          'If you move, the content you are editing will be discarded. Are you sure you want to move?',
        )}
        buttonText={t('Moving')}
        onSubmit={onConfirmNavigation}
        onCancel={onCloseDialog}
        onEscapeKeyDown={onCloseDialog}
      />
      <Prompt
        message={(location) => {
          const hasUnsavedContent = !deepEqual(getContent(), data);
          if (hasUnsavedContent && !tryingToSave) {
            setNextLocation(location);
            return false;
          }

          return true;
        }}
        when={!confirm}
      />
    </>
  );
};
