export const getAccountState = ({ cognitoUser, attrs = [] }) => ({
  cognitoUser,

  // 配列形式となっているのでオブジェクト形式に変換
  attrs: attrs.reduce((o, c) => ({ ...o, [c.Name]: c.Value }), {}),
  isAuthorized:
    cognitoUser === undefined ? undefined : cognitoUser === null ? false : true,
});

export const getAttributesState = ({ cognitoUser, attrs = [] }) => {
  return {
    cognitoUser,

    // 配列形式となっているのでオブジェクト形式に変換
    attrs: attrs.reduce((o, c) => ({ ...o, [c.Name]: c.Value }), {}),
  };
};

export const getCognitoUser = ({ cognitoUser }) => {
  return cognitoUser;
};
