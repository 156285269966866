import * as Tooltip from '@radix-ui/react-tooltip';
import cx from 'classnames';

import styles from './iconButton.module.css';

export type Props = {
  icon: React.ReactNode;
  isSelected: boolean;
  label: string;
  onChangeOption: () => void;
};

export const IconButton: React.FC<Props> = ({
  icon,
  isSelected,
  label,
  onChangeOption,
}) => {
  return (
    <Tooltip.Provider delayDuration={0}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <button
            className={cx(styles.button, {
              [styles.isSelected]: isSelected,
            })}
            onClick={onChangeOption}
            aria-label={label}
          >
            <span aria-hidden className={styles.icon}>
              {icon}
            </span>
          </button>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className={styles.tooltipContent} sideOffset={1}>
            {label}
            <Tooltip.Arrow
              className={styles.tooltipArrow}
              width={5}
              height={3}
            />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
