import type React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { MigrateApi } from '../../entity/api';

import styles from './breadcrumb.module.css';

type Props = {
  apiList: MigrateApi[];
};

const Breadcrumb: React.FC<Props> = ({ apiList }) => {
  const { t } = useTranslation('breadcrumb');
  const [, path1, path2] = window.location.pathname.split('/');
  const apiName = useMemo(() => {
    return apiList && apiList.find((api) => api.apiEndpoint === path2)?.apiName;
  }, [apiList, path2]);

  if (apiList === undefined) {
    return null;
  }
  if (path1 === 'apis') {
    return (
      <ul className={styles.wrapper}>
        <li className={styles.list}>
          <Link to="/apis">{t('Top')}</Link>
        </li>
        {path2 && (
          <li className={styles.list}>
            <Link to={`/apis/${path2}`}>{apiName}</Link>
          </li>
        )}
      </ul>
    );
  }
  if (path1 === 'media') {
    return (
      <ul className={styles.wrapper}>
        <li className={styles.list}>
          <Link to="/apis">{t('Top')}</Link>
        </li>
        <li className={styles.list}>{t('Media')}</li>
      </ul>
    );
  }
  if (path1 === 'reviews') {
    return (
      <ul className={styles.wrapper}>
        <li className={styles.list}>
          <Link to="/apis">{t('Top')}</Link>
        </li>
        <li className={styles.list}>{t('Manage Reviews')}</li>
      </ul>
    );
  }
  return (
    <ul className={styles.wrapper}>
      <li className={styles.list}>{t('Top')}</li>
      <li className={styles.list}>{path1}</li>
    </ul>
  );
};

export default Breadcrumb;
