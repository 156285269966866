import { useDebounce } from '@/hooks/useDebounce';

import { convertOptionsToQuery } from './convertEmbedOptions';

import type { OptionsField } from '@/entity/iframely';

type Args = {
  inputOptions?: Record<string, OptionsField['value']>;
};

export const useEmbedOptionsDebounce = ({ inputOptions }: Args) => {
  const [delayInputOptions] = useDebounce(inputOptions, 2000);

  const { optionQuery } = convertOptionsToQuery(inputOptions);
  const { optionQuery: newOptionQuery } =
    convertOptionsToQuery(delayInputOptions);

  const isDebounceDone = optionQuery === newOptionQuery;

  return { isDebounceDone };
};
