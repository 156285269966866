import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ProfileImage from '../ProfileImage';

import type { userAttributes } from '../../hooks/Profile/useProfile';

import styles from './nav.module.css';

type Props = {
  hasServiceDomain: boolean;
  host: string;
  userAttributes: userAttributes;
  openAnnouncement: () => void;
  isSAMLUser: boolean;
};

export const ProfileMenu: React.FC<Props> = ({
  hasServiceDomain,
  host,
  userAttributes,
  openAnnouncement,
  isSAMLUser,
}) => {
  const { t } = useTranslation('nav');
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className={styles.profile}>
        <ProfileImage picture={userAttributes.picture} />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content side="right" align="end" className={styles.menu}>
          <DropdownMenu.Group className={styles.group}>
            <DropdownMenu.Item asChild className={styles.item}>
              {hasServiceDomain && !isSAMLUser ? (
                <a href={`${host}/profile`}>
                  <i className="material-icons">person_outline</i>
                  {t('My Page')}
                </a>
              ) : (
                <Link to="/profile">
                  <i className="material-icons">person_outline</i>
                  {t('My Page')}
                </Link>
              )}
            </DropdownMenu.Item>
            {!isSAMLUser && (
              <DropdownMenu.Item asChild className={styles.item}>
                {hasServiceDomain ? (
                  <a href={host}>
                    <i className="material-icons">format_list_bulleted</i>
                    {t('Service List')}
                  </a>
                ) : (
                  <Link to="/">
                    <i className="material-icons">format_list_bulleted</i>
                    {t('Service List')}
                  </Link>
                )}
              </DropdownMenu.Item>
            )}
          </DropdownMenu.Group>
          <DropdownMenu.Group className={styles.group}>
            <DropdownMenu.Item asChild className={styles.item}>
              <div role="button" onClick={openAnnouncement}>
                <i className="material-icons-outlined">notifications</i>
                {t('Announcement')}
              </div>
            </DropdownMenu.Item>
            <DropdownMenu.Item asChild className={styles.item}>
              <a
                href="https://status.microcms.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="material-icons-outlined">cell_tower</i>
                {t('Fault information')}
              </a>
            </DropdownMenu.Item>
          </DropdownMenu.Group>
          <DropdownMenu.Group className={styles.group}>
            <DropdownMenu.Item asChild className={styles.item}>
              <a
                href="https://document.microcms.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="material-icons-outlined">description</i>
                {t('Document')}
              </a>
            </DropdownMenu.Item>
            <DropdownMenu.Item asChild className={styles.item}>
              <a
                href="https://discord.gg/K3DPqw4EJ2"
                title={t('Community')}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="material-icons-outlined">groups</i>
                {t('Community')}
              </a>
            </DropdownMenu.Item>
            <DropdownMenu.Item asChild className={styles.item}>
              <a
                href="https://microcms.notion.site/72fba106b7ac4178a590b17875f09d96?v=9c050961701f4b44a6f74e576e7d1c9a"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="material-icons-outlined">map</i>
                {t('Roadmap')}
              </a>
            </DropdownMenu.Item>
          </DropdownMenu.Group>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
