import { handleActions } from 'redux-actions';

import actions from './actions.js';

const initialState = {};

const reducer = handleActions(
  {
    [actions.networking.findSameEndpoint]: () => {
      return { object: { hasSameEndpoint: true } };
    },
    [actions.networking.findNoSameEndpoint]: () => {
      return { object: { hasSameEndpoint: false } };
    },
    [actions.networking.successPostApi]: (state) => {
      return {
        ...state,
        postResult: true,
      };
    },
    [actions.networking.failurePostApi]: (state) => {
      return { ...state, postResult: false };
    },
    [actions.networking.successPutApi]: (state) => {
      return {
        ...state,
        putResult: true,
      };
    },
    [actions.networking.failurePutApi]: (state, { payload }) => {
      return {
        ...state,
        putResult: false,
        // APIの更新処理だけ例外としてエラーメッセージを表示する
        errorMessage: payload.message ?? 'Unexpected Error',
      };
    },
    [actions.networking.successPutApiDescription]: (state) => {
      return {
        ...state,
        putDescriptionResult: true,
      };
    },
    [actions.networking.failurePutApiDescription]: (state) => {
      return { ...state, putDescriptionResult: false };
    },
    [actions.resetApi]: () => ({}),
  },
  initialState,
);

export default reducer;
