import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import Head from '@/components/Head';
import Button from '@/components/ui/Button';
import Loading from '@/components/ui/Loading';
import Switch from '@/components/ui/Switch';

import { useIpAddressRestrictionSettings } from './useIpAddressRestrictionSettings';

import type { MigrateApi } from '@/entity/api';
import type { Service } from '@/types/services';

import styles from './ipaddressrestrictionsettings.module.css';

import { useLoadApis } from '@/hooks/ApiList/useApiListReader';
import { Textarea } from '@/views/Common/Ui/Textarea';
import { TextareaField } from '@/views/Common/Ui/TextareaField';
import { useGlobalApiIpAddressRestriction } from '@/views/Common/apiIpAddressRestriction/useApiIpAddressRestrictionReader';
import { Upsell } from '@/views/Common/plan/Upsell';
import { useCurrentService } from '@/views/Common/service/useServiceReader';

export type Props = {
  service: Service;
  api: MigrateApi;
  globalIpList: string;
};

export type ViewProps = {
  apiName: string;
  globalIpList: string;
  ipRestrictionCount: number;
  currentIp: string;
  ipList: string;
  handleChangeIpList: React.ChangeEventHandler<HTMLTextAreaElement>;
  enabledSetIndividually: boolean;
  handleSetIndividuallyChange: (on: boolean) => void;
  handleSaveButtonClick: () => void;
  disabledSaveButton: boolean;
};

const IpAddressRestrictionSettingsView: React.FC<ViewProps> = ({
  apiName,
  globalIpList,
  ipRestrictionCount,
  currentIp,
  ipList,
  handleChangeIpList,
  enabledSetIndividually,
  handleSetIndividuallyChange,
  handleSaveButtonClick,
  disabledSaveButton,
}) => {
  const { t } = useTranslation('apisSettingsIpAddressRestrictionSettings');

  return (
    <Upsell restrictionKey="apiIpRestriction">
      <Head title={t('API IP Restriction')} />
      <div className={styles.main}>
        <h2 className={styles.title}>{t('API IP Restriction')}</h2>
        <TextareaField
          label={t('Allowed IP addresses for all APIs')}
          description={
            <Trans
              t={t}
              i18nKey={`List of IP addresses set in the \'API IP restrictions\' section of the service settings. \n If "Set individually" is switched on, you can change (overwrite) the IP address to be specific to the {{apiName}}API.`}
              values={{ apiName }}
              components={{
                l: <Link to="/settings/api-ip-restrictions"></Link>,
              }}
            />
          }
          readOnly={true}
          value={globalIpList}
          className={cx(
            styles.globalIpListTextarea,
            enabledSetIndividually && styles.disabled,
          )}
        />
        <p className={styles.currentIp}>
          <span>{t('Current source IP address :')}</span>
          <span>{currentIp}</span>
        </p>
        <Switch
          size="large"
          on={enabledSetIndividually}
          onChange={handleSetIndividuallyChange}
          className={styles.setIndividuallySwitch}
        >
          {t('Set individually')}
        </Switch>
        {enabledSetIndividually && (
          <>
            <p
              className={styles.description}
              id="individually-ip-list-description"
            >
              {t(
                'Enter the IP addresses allowed to access the {{apiName}}API, one per line in IPv4 / IPv6 CIDR notation. A maximum of {{validateCount}} lines can be set. If this is blank, any IP addresses will be allowed to access the {{apiName}}API.',
                {
                  apiName,
                  validateCount: ipRestrictionCount,
                },
              )}
            </p>
            <Textarea
              value={ipList}
              onChange={handleChangeIpList}
              placeholder={t('E.g., \n198.51.100.60\n198.51.100.150')}
              aria-label={t('Allowed IP addresses for the {{apiName}}API', {
                apiName,
              })}
              aria-describedby="individually-ip-list-description individually-ip-list-caution"
            />
            <p className={styles.caution} id="individually-ip-list-caution">
              {t(
                'Overwriting will restrict access from IP addresses other than those set individually. Be careful not to set it if you are not in a fixed IP environment.',
              )}
            </p>
          </>
        )}
      </div>
      <div className={styles.actions}>
        <Button
          type="primary"
          value={t('Save changes')}
          onClick={handleSaveButtonClick}
          disabled={disabledSaveButton}
        />
      </div>
    </Upsell>
  );
};

const IpAddressRestrictionSettings: React.FC<Props> = (props) => {
  const hooks = useIpAddressRestrictionSettings(props);

  return <IpAddressRestrictionSettingsView {...hooks} />;
};

export const IpAddressRestrictionSettingsLoader: React.FC = () => {
  const { data: service } = useCurrentService();
  const { data: globalIpList, isLoading: isGlobalIpListLoading } =
    useGlobalApiIpAddressRestriction(service?.partitionKey);

  const { endpoint } = useParams<{
    endpoint: string;
  }>();
  const { apiList } = useLoadApis(service);
  const api = apiList?.list.find((api) => api.apiEndpoint === endpoint);

  if (!api || !service || isGlobalIpListLoading || globalIpList === undefined) {
    return <Loading />;
  }

  return (
    <IpAddressRestrictionSettings
      service={service}
      api={api}
      globalIpList={globalIpList}
    />
  );
};
