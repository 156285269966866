import { useCallback, useMemo, useState } from 'react';

import { validateImageSize } from './imageSettingsValidation';
import { validateLink } from '@/views/Common/validations/validateLink';

import type { ImageAttributes } from '..';

export type Props = {
  initialImgAttributes: ImageAttributes;
  width: number;
  height: number;
};

export const useImageSettings = ({
  initialImgAttributes,
  width,
  height,
}: Props) => {
  const [inputImgAttributes, setInputImgAttributes] = useState<ImageAttributes>(
    {
      ...initialImgAttributes,
      customWidth: initialImgAttributes.customWidth,
      customHeight: initialImgAttributes.customHeight,
    },
  );
  const onChangeAlt = useCallback((alt: ImageAttributes['alt']) => {
    setInputImgAttributes((prev) => ({ ...prev, alt }));
  }, []);

  const onChangeFigcaption = useCallback(
    (figcaption: ImageAttributes['figcaption']) => {
      setInputImgAttributes((prev) => ({ ...prev, figcaption }));
    },
    [],
  );

  // 画像の縦横比率を保つため
  const ratio = useMemo(() => width / height, [height, width]);

  const [sizeError, setSizeError] = useState<string | undefined>(undefined);

  const onChangeWidth = useCallback(
    (customWidth: number) => {
      setInputImgAttributes((prev) => ({
        ...prev,
        customWidth,
        customHeight: Math.floor(customWidth / ratio),
      }));
      setSizeError(
        validateImageSize(customWidth, Math.floor(customWidth / ratio)),
      );
    },
    [ratio],
  );

  const onChangeHeight = useCallback(
    (customHeight: number) => {
      setInputImgAttributes((prev) => ({
        ...prev,
        customWidth: Math.floor(customHeight * ratio),
        customHeight,
      }));
      setSizeError(
        validateImageSize(customHeight, Math.floor(customHeight / ratio)),
      );
    },
    [ratio],
  );

  const [linkError, setLinkError] = useState<string | undefined>(undefined);
  const onChangeLink = useCallback((link: ImageAttributes['link']) => {
    setInputImgAttributes((prev) => ({ ...prev, link }));
    setLinkError(link === '' ? undefined : validateLink(link));
  }, []);

  const onChangeTargetBlank = useCallback(
    (isTargetBlank: ImageAttributes['isTargetBlank']) => {
      setInputImgAttributes((prev) => ({ ...prev, isTargetBlank }));
    },
    [],
  );

  return {
    inputImgAttributes,
    onChangeAlt,
    onChangeFigcaption,
    sizeError,
    onChangeWidth,
    onChangeHeight,
    linkError,
    onChangeLink,
    onChangeTargetBlank,
  };
};
