import { useCallback, useState } from 'react';

export const useToggle = (
  initialValue: boolean,
): [
  state: boolean,
  toggle: (nextValue?: boolean) => void,
  open: () => void,
  close: () => void,
] => {
  const [state, setState] = useState(initialValue);
  const toggle = useCallback((nextValue?: boolean) => {
    setState((prevState) => nextValue ?? !prevState);
  }, []);
  const open = useCallback(() => {
    setState(true);
  }, []);
  const close = useCallback(() => {
    setState(false);
  }, []);

  return [state, toggle, open, close];
};
