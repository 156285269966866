import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import * as queries from '../../graphql/queries';

export const useWebhookMediumSetting = (apiId) => {
  // メディアのWebhookを取得
  const webhookMediumQuery = useQuery({
    queryKey: ['mediumWebhook'],
    queryFn: () => {
      return API.graphql(
        graphqlOperation(queries.findWebhookMediumSettings, {
          apiId,
        }),
      ).then((result) => {
        const formattedItems = result.data.findWebhookMediumSettings.map(
          (item) => {
            return {
              ...item,
              settingValues: item.settingValues
                ? JSON.parse(item.settingValues)
                : {},
              handleEvents: [
                ...new Set(
                  item.handleEvents.map((event) => {
                    return event;
                  }),
                ),
              ],
            };
          },
        );
        return formattedItems;
      });
    },
    staleTime: Number.POSITIVE_INFINITY,
  });
  const { data: webhookMedium, isLoading } = webhookMediumQuery;

  return [webhookMedium, isLoading];
};

export const useWebhookMediumSettingCrud = (apiId) => {
  const { t } = useTranslation('hooksWebhook');
  const cache = useQueryClient();
  const { addToast } = useToasts();

  // Webhookの作成
  const { mutate: createWebhookMedium, isLoading: createWebhookMediumLoading } =
    useMutation(
      ({ values, events }) => {
        if (apiId) {
          return API.graphql(
            graphqlOperation(queries.createWebhookMediumSetting, {
              apiId,
              settingValues: JSON.stringify(values),
              handleEvents: events,
            }),
          ).then((result) => result.data.createWebhookMediumSetting);
        }
        return;
      },
      {
        onSuccess(data) {
          if (data.result === false) {
            addToast(data.message, { appearance: 'error' });
            return;
          }

          cache.invalidateQueries(['mediumWebhook'], { type: 'all' });
          addToast(t('Changes have been completed.'), {
            appearance: 'success',
          });
        },
        onError({ errors }) {
          addToast(errors ? errors[0].message : t('Failed to make changes.'), {
            appearance: 'error',
          });
        },
      },
    );

  // Webhookの更新
  const { mutate: updateWebhookMedium, isLoading: updateWebhookMediumLoading } =
    useMutation(
      ({ current, values, events }) => {
        if (apiId) {
          return API.graphql(
            graphqlOperation(queries.updateWebhookMediumSetting, {
              apiId: current.apiId,
              createdAt: current.createdAt,
              settingValues: JSON.stringify(values),
              handleEvents: events,
            }),
          ).then((result) => result.data.updateWebhookMediumSetting);
        }
        return;
      },
      {
        onSuccess(data) {
          if (data.result === false) {
            addToast(data.message, { appearance: 'error' });
            return;
          }
          cache.invalidateQueries(['mediumWebhook'], { type: 'all' });
          addToast(t('Changes have been completed.'), {
            appearance: 'success',
          });
        },
        onError({ errors }) {
          addToast(errors ? errors[0].message : t('Failed to make changes.'), {
            appearance: 'error',
          });
        },
      },
    );

  // Webhookの削除
  const { mutate: deleteWebhookMedium, isLoading: deleteWebhookMediumLoading } =
    useMutation(
      ({ current }) => {
        if (apiId) {
          return API.graphql(
            graphqlOperation(queries.deleteWebhookMediumSetting, {
              apiId: current.apiId,
              createdAt: current.createdAt,
            }),
          ).then((result) => result.data.deleteWebhookMediumSetting);
        }
        return;
      },
      {
        onSuccess(data) {
          if (data.result === false) {
            addToast(data.message, { appearance: 'error' });
            return;
          }
          cache.invalidateQueries(['mediumWebhook'], { type: 'all' });
          addToast(t('Changes have been completed.'), {
            appearance: 'success',
          });
        },
        onError({ errors }) {
          addToast(errors ? errors[0].message : t('Failed to make changes.'), {
            appearance: 'error',
          });
        },
      },
    );
  return {
    createWebhookMedium,
    createWebhookMediumLoading,
    updateWebhookMedium,
    updateWebhookMediumLoading,
    deleteWebhookMedium,
    deleteWebhookMediumLoading,
  };
};
