import type React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { optionTypes } from '../../../constants/optionTypes';
import { validateRichEditorSelectOption } from '../../Validations';

import type { Options } from '../../../constants/optionTypes';

import styles from './inputRichEditorField.module.css';

type Props = {
  options: Options[];
  setOption: React.Dispatch<Options[]>;
};

export const InputRichEditorField: React.FC<Props> = ({
  options = optionTypes,
  setOption,
}) => {
  const { t } = useTranslation('inputRichEditorField');
  const [errorText, setErrorText] = useState<string | null>(null);

  // optionsがnullの場合はすべてのオプションを付ける
  const richEditorOptions = options === null ? optionTypes : options;

  useEffect(() => {
    const error = validateRichEditorSelectOption(richEditorOptions);
    setErrorText(error);

    if (!error) {
      setErrorText(null);
    }
  }, [errorText, richEditorOptions]);

  const set = useCallback(
    (value: any) => {
      setOption(value);
    },
    [setOption],
  );

  const onChange = useCallback(
    (value: any) => () => {
      // richEditorOptionsに重複した値がないか検索
      const isSameValue = richEditorOptions.some((f) => {
        return f === value;
      });

      // isSameValueの結果でrichEditorOptionsへの追加と削除の処理を分ける
      const newValue = isSameValue
        ? richEditorOptions.filter((f) => {
            return f !== value;
          })
        : [...(richEditorOptions || []), value];

      set(newValue);
    },
    [richEditorOptions, set],
  );

  return (
    <div>
      <div className={styles.section}>
        <button
          className={`${styles.button} ${
            richEditorOptions.includes('headerOne')
              ? `${styles.isSelected}`
              : ''
          }`}
          onClick={onChange('headerOne')}
        >
          {t('Header1')}
        </button>
        <button
          className={`${styles.button} ${
            richEditorOptions.includes('headerTwo')
              ? `${styles.isSelected}`
              : ''
          }`}
          onClick={onChange('headerTwo')}
        >
          {t('Header2')}
        </button>
        <button
          className={`${styles.button} ${
            richEditorOptions.includes('headerThree')
              ? `${styles.isSelected}`
              : ''
          }`}
          onClick={onChange('headerThree')}
        >
          {t('Header3')}
        </button>
        <button
          className={`${styles.button} ${
            richEditorOptions.includes('headerFour')
              ? `${styles.isSelected}`
              : ''
          }`}
          onClick={onChange('headerFour')}
        >
          {t('Header4')}
        </button>
        <button
          className={`${styles.button} ${
            richEditorOptions.includes('headerFive')
              ? `${styles.isSelected}`
              : ''
          }`}
          onClick={onChange('headerFive')}
        >
          {t('Header5')}
        </button>
        <button
          className={`${styles.button} ${
            richEditorOptions.includes('paragraph')
              ? `${styles.isSelected}`
              : ''
          }`}
          onClick={onChange('paragraph')}
        >
          {t('Paragraph')}
        </button>
      </div>
      <div className={styles.section}>
        <button
          className={`${styles.button} ${
            richEditorOptions.includes('sizeSmall')
              ? `${styles.isSelected}`
              : ''
          }`}
          onClick={onChange('sizeSmall')}
        >
          {t('Small')}
        </button>
        <button
          className={`${styles.button} ${
            richEditorOptions.includes('sizeNormal')
              ? `${styles.isSelected}`
              : ''
          }`}
          onClick={onChange('sizeNormal')}
        >
          {t('Normal')}
        </button>
        <button
          className={`${styles.button} ${
            richEditorOptions.includes('sizeLarge')
              ? `${styles.isSelected}`
              : ''
          }`}
          onClick={onChange('sizeLarge')}
        >
          {t('Large')}
        </button>
        <button
          className={`${styles.button} ${
            richEditorOptions.includes('sizeHuge') ? `${styles.isSelected}` : ''
          }`}
          onClick={onChange('sizeHuge')}
        >
          {t('Huge')}
        </button>
      </div>
      <div className={styles.section}>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('bold') ? `${styles.isSelected}` : ''
          }`}
          data-balloon={t('Bold')}
          onClick={onChange('bold')}
        >
          <svg viewBox="0 0 18 18">
            <path
              className={styles.qlStroke}
              d="M5,4H9.5A2.5,2.5,0,0,1,12,6.5v0A2.5,2.5,0,0,1,9.5,9H5A0,0,0,0,1,5,9V4A0,0,0,0,1,5,4Z"
            />
            <path
              className={styles.qlStroke}
              d="M5,9h5.5A2.5,2.5,0,0,1,13,11.5v0A2.5,2.5,0,0,1,10.5,14H5a0,0,0,0,1,0,0V9A0,0,0,0,1,5,9Z"
            />
          </svg>
        </button>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('italic') ? `${styles.isSelected}` : ''
          }`}
          data-balloon={t('Italic')}
          onClick={onChange('italic')}
        >
          <svg viewBox="0 0 18 18">
            <line className={styles.qlStroke} x1="7" x2="13" y1="4" y2="4" />
            <line className={styles.qlStroke} x1="5" x2="11" y1="14" y2="14" />
            <line className={styles.qlStroke} x1="8" x2="10" y1="14" y2="4" />
          </svg>
        </button>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('underline')
              ? `${styles.isSelected}`
              : ''
          }`}
          data-balloon={t('Underline')}
          onClick={onChange('underline')}
        >
          <svg viewBox="0 0 18 18">
            <path
              className={styles.qlStroke}
              d="M5,3V9a4.012,4.012,0,0,0,4,4H9a4.012,4.012,0,0,0,4-4V3"
            />
            <rect
              className={styles.qlFill}
              height="1"
              rx="0.5"
              ry="0.5"
              width="12"
              x="3"
              y="15"
            />
          </svg>
        </button>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('strike') ? `${styles.isSelected}` : ''
          }`}
          data-balloon={t('Strike')}
          onClick={onChange('strike')}
        >
          <svg viewBox="0 0 18 18">
            <line
              className={`${styles.qlStroke} ${styles.qlThin}`}
              x1="15.5"
              x2="2.5"
              y1="8.5"
              y2="9.5"
            />
            <path
              className={styles.qlFill}
              d="M9.007,8C6.542,7.791,6,7.519,6,6.5,6,5.792,7.283,5,9,5c1.571,0,2.765.679,2.969,1.309a1,1,0,0,0,1.9-.617C13.356,4.106,11.354,3,9,3,6.2,3,4,4.538,4,6.5a3.2,3.2,0,0,0,.5,1.843Z"
            />
            <path
              className={styles.qlFill}
              d="M8.984,10C11.457,10.208,12,10.479,12,11.5c0,0.708-1.283,1.5-3,1.5-1.571,0-2.765-.679-2.969-1.309a1,1,0,1,0-1.9.617C4.644,13.894,6.646,15,9,15c2.8,0,5-1.538,5-3.5a3.2,3.2,0,0,0-.5-1.843Z"
            />
          </svg>
        </button>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('code') ? `${styles.isSelected}` : ''
          }`}
          data-balloon={t('Code')}
          onClick={onChange('code')}
        >
          <svg viewBox="0 0 18 18">
            <g
              id="OGP-Copy-2"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <path
                className={styles.qlFill}
                d="M6,5 L6,7 L4,7 L4,11 L6,11 L6,13 L3,13 C2.44771525,13 2,12.5522847 2,12 L2,6 C2,5.44771525 2.44771525,5 3,5 L6,5 Z M15,5 C15.5522847,5 16,5.44771525 16,6 L16,12 C16,12.5522847 15.5522847,13 15,13 L12,13 L12,11 L14,11 L14,7 L12,7 L12,5 L15,5 Z"
                id="Combined-Shape"
              ></path>
              <rect
                className={styles.qlFill}
                id="Rectangle"
                transform="translate(9.000000, 9.000000) rotate(15.000000) translate(-9.000000, -9.000000) "
                x="8"
                y="3"
                width="2"
                height="12"
                rx="1"
              ></rect>
            </g>
          </svg>
        </button>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('background')
              ? `${styles.isSelected}`
              : ''
          }`}
          data-balloon={t('Background')}
          onClick={onChange('background')}
        >
          <svg viewBox="0 0 18 18">
            <g className={`${styles.qlFill}`}>
              <polygon points="6 6.868 6 6 5 6 5 7 5.942 7 6 6.868" />
              <rect height="1" width="1" x="4" y="4" />
              <polygon points="6.817 5 6 5 6 6 6.38 6 6.817 5" />
              <rect height="1" width="1" x="2" y="6" />
              <rect height="1" width="1" x="3" y="5" />
              <rect height="1" width="1" x="4" y="7" />
              <polygon points="4 11.439 4 11 3 11 3 12 3.755 12 4 11.439" />
              <rect height="1" width="1" x="2" y="12" />
              <rect height="1" width="1" x="2" y="9" />
              <rect height="1" width="1" x="2" y="15" />
              <polygon points="4.63 10 4 10 4 11 4.192 11 4.63 10" />
              <rect height="1" width="1" x="3" y="8" />
              <path d="M10.832,4.2L11,4.582V4H10.708A1.948,1.948,0,0,1,10.832,4.2Z" />
              <path d="M7,4.582L7.168,4.2A1.929,1.929,0,0,1,7.292,4H7V4.582Z" />
              <path d="M8,13H7.683l-0.351.8a1.933,1.933,0,0,1-.124.2H8V13Z" />
              <rect height="1" width="1" x="12" y="2" />
              <rect height="1" width="1" x="11" y="3" />
              <path d="M9,3H8V3.282A1.985,1.985,0,0,1,9,3Z" />
              <rect height="1" width="1" x="2" y="3" />
              <rect height="1" width="1" x="6" y="2" />
              <rect height="1" width="1" x="3" y="2" />
              <rect height="1" width="1" x="5" y="3" />
              <rect height="1" width="1" x="9" y="2" />
              <rect height="1" width="1" x="15" y="14" />
              <polygon points="13.447 10.174 13.469 10.225 13.472 10.232 13.808 11 14 11 14 10 13.37 10 13.447 10.174" />
              <rect height="1" width="1" x="13" y="7" />
              <rect height="1" width="1" x="15" y="5" />
              <rect height="1" width="1" x="14" y="6" />
              <rect height="1" width="1" x="15" y="8" />
              <rect height="1" width="1" x="14" y="9" />
              <path d="M3.775,14H3v1H4V14.314A1.97,1.97,0,0,1,3.775,14Z" />
              <rect height="1" width="1" x="14" y="3" />
              <polygon points="12 6.868 12 6 11.62 6 12 6.868" />
              <rect height="1" width="1" x="15" y="2" />
              <rect height="1" width="1" x="12" y="5" />
              <rect height="1" width="1" x="13" y="4" />
              <polygon points="12.933 9 13 9 13 8 12.495 8 12.933 9" />
              <rect height="1" width="1" x="9" y="14" />
              <rect height="1" width="1" x="8" y="15" />
              <path d="M6,14.926V15H7V14.316A1.993,1.993,0,0,1,6,14.926Z" />
              <rect height="1" width="1" x="5" y="15" />
              <path d="M10.668,13.8L10.317,13H10v1h0.792A1.947,1.947,0,0,1,10.668,13.8Z" />
              <rect height="1" width="1" x="11" y="15" />
              <path d="M14.332,12.2a1.99,1.99,0,0,1,.166.8H15V12H14.245Z" />
              <rect height="1" width="1" x="14" y="15" />
              <rect height="1" width="1" x="15" y="11" />
            </g>
            <polyline className={styles.qlStroke} points="5.5 13 9 5 12.5 13" />
            <line
              className={styles.qlStroke}
              x1="11.63"
              x2="6.38"
              y1="11"
              y2="11"
            />
          </svg>
        </button>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('color') ? `${styles.isSelected}` : ''
          }`}
          data-balloon={t('Color')}
          onClick={onChange('color')}
        >
          <svg viewBox="0 0 18 18">
            <line
              className={`${styles.qlColorLabel} ${styles.qlStroke} ${styles.qlTransparent}`}
              x1="3"
              x2="15"
              y1="15"
              y2="15"
            />
            <polyline className={styles.qlStroke} points="5.5 11 9 3 12.5 11" />
            <line
              className={styles.qlStroke}
              x1="11.63"
              x2="6.38"
              y1="9"
              y2="9"
            />
          </svg>
        </button>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('align') ? `${styles.isSelected}` : ''
          }`}
          data-balloon={t('Align')}
          onClick={onChange('align')}
        >
          <svg viewBox="0 0 18 18">
            <line className={styles.qlStroke} x1="3" x2="15" y1="9" y2="9" />
            <line className={styles.qlStroke} x1="3" x2="13" y1="14" y2="14" />
            <line className={styles.qlStroke} x1="3" x2="9" y1="4" y2="4" />
          </svg>
        </button>
      </div>
      <div className={styles.section}>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('blockquote')
              ? `${styles.isSelected}`
              : ''
          }`}
          data-balloon={t('Blockquote')}
          onClick={onChange('blockquote')}
        >
          <svg viewBox="0 0 18 18">
            <rect
              className={`${styles.qlFill} ${styles.qlStroke}`}
              height="3"
              width="3"
              x="4"
              y="5"
            />
            <rect
              className={`${styles.qlFill} ${styles.qlStroke}`}
              height="3"
              width="3"
              x="11"
              y="5"
            />
            <path
              className={` ${styles.qlEven} ${styles.qlFill} ${styles.qlStroke}`}
              d="M7,8c0,4.031-3,5-3,5"
            />
            <path
              className={` ${styles.qlEven} ${styles.qlFill} ${styles.qlStroke}`}
              d="M14,8c0,4.031-3,5-3,5"
            />
          </svg>
        </button>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('codeBlock')
              ? `${styles.isSelected}`
              : ''
          }`}
          data-balloon={t('CodeBlock')}
          onClick={onChange('codeBlock')}
        >
          <svg viewBox="0 0 18 18">
            <polyline
              className={`${styles.qlEven} ${styles.qlStroke}`}
              points="5 7 3 9 5 11"
            />
            <polyline
              className={`${styles.qlEven} ${styles.qlStroke}`}
              points="13 7 15 9 13 11"
            />
            <line className={styles.qlStroke} x1="10" x2="8" y1="5" y2="13" />
          </svg>
        </button>
      </div>
      <div className={styles.section}>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('listOrdered')
              ? `${styles.isSelected}`
              : ''
          }`}
          data-balloon={t('ListBullet')}
          onClick={onChange('listOrdered')}
        >
          <svg viewBox="0 0 18 18">
            <line className={styles.qlStroke} x1="7" x2="15" y1="4" y2="4" />
            <line className={styles.qlStroke} x1="7" x2="15" y1="9" y2="9" />
            <line className={styles.qlStroke} x1="7" x2="15" y1="14" y2="14" />
            <line
              className={`${styles.qlStroke} ${styles.qlThin}`}
              x1="2.5"
              x2="4.5"
              y1="5.5"
              y2="5.5"
            />
            <path
              className={styles.qlFill}
              d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"
            />
            <path
              className={`${styles.qlStroke} ${styles.qlThin}`}
              d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"
            />
            <path
              className={`${styles.qlStroke} ${styles.qlThin}`}
              d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"
            />
          </svg>
        </button>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('listBullet')
              ? `${styles.isSelected}`
              : ''
          }`}
          data-balloon={t('ListBullet')}
          onClick={onChange('listBullet')}
        >
          <svg viewBox="0 0 18 18">
            <line className={styles.qlStroke} x1="6" x2="15" y1="4" y2="4" />
            <line className={styles.qlStroke} x1="6" x2="15" y1="9" y2="9" />
            <line className={styles.qlStroke} x1="6" x2="15" y1="14" y2="14" />
            <line className={styles.qlStroke} x1="3" x2="3" y1="4" y2="4" />
            <line className={styles.qlStroke} x1="3" x2="3" y1="9" y2="9" />
            <line className={styles.qlStroke} x1="3" x2="3" y1="14" y2="14" />
          </svg>
        </button>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('indentRemove')
              ? `${styles.isSelected}`
              : ''
          }`}
          data-balloon={t('IndentRemove')}
          onClick={onChange('indentRemove')}
        >
          <svg viewBox="0 0 18 18">
            <line className={styles.qlStroke} x1="3" x2="15" y1="14" y2="14" />
            <line className={styles.qlStroke} x1="3" x2="15" y1="4" y2="4" />
            <line className={styles.qlStroke} x1="9" x2="15" y1="9" y2="9" />
            <polyline className={styles.qlStroke} points="5 7 5 11 3 9 5 7" />
          </svg>
        </button>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('indentAdd')
              ? `${styles.isSelected}`
              : ''
          }`}
          data-balloon={t('IndentAdd')}
          onClick={onChange('indentAdd')}
        >
          <svg viewBox="0 0 18 18">
            <line className={styles.qlStroke} x1="3" x2="15" y1="14" y2="14" />
            <line className={styles.qlStroke} x1="3" x2="15" y1="4" y2="4" />
            <line className={styles.qlStroke} x1="9" x2="15" y1="9" y2="9" />
            <polyline
              className={`${styles.qlFill} ${styles.qlStroke}`}
              points="3 7 3 11 5 9 3 7"
            />
          </svg>
        </button>
      </div>
      <div className={styles.section}>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('scriptSub')
              ? `${styles.isSelected}`
              : ''
          }`}
          data-balloon={t('ScriptSub')}
          onClick={onChange('scriptSub')}
        >
          <svg viewBox="0 0 18 18">
            <path
              className={styles.qlFill}
              d="M15.5,15H13.861a3.858,3.858,0,0,0,1.914-2.975,1.8,1.8,0,0,0-1.6-1.751A1.921,1.921,0,0,0,12.021,11.7a0.50013,0.50013,0,1,0,.957.291h0a0.914,0.914,0,0,1,1.053-.725,0.81,0.81,0,0,1,.744.762c0,1.076-1.16971,1.86982-1.93971,2.43082A1.45639,1.45639,0,0,0,12,15.5a0.5,0.5,0,0,0,.5.5h3A0.5,0.5,0,0,0,15.5,15Z"
            />
            <path
              className={styles.qlFill}
              d="M9.65,5.241a1,1,0,0,0-1.409.108L6,7.964,3.759,5.349A1,1,0,0,0,2.192,6.59178Q2.21541,6.6213,2.241,6.649L4.684,9.5,2.241,12.35A1,1,0,0,0,3.71,13.70722q0.02557-.02768.049-0.05722L6,11.036,8.241,13.65a1,1,0,1,0,1.567-1.24277Q9.78459,12.3777,9.759,12.35L7.316,9.5,9.759,6.651A1,1,0,0,0,9.65,5.241Z"
            />
          </svg>
        </button>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('scriptSuper')
              ? `${styles.isSelected}`
              : ''
          }`}
          data-balloon={t('ScriptSuper')}
          onClick={onChange('scriptSuper')}
        >
          <svg viewBox="0 0 18 18">
            <path
              className={styles.qlFill}
              d="M15.5,7H13.861a4.015,4.015,0,0,0,1.914-2.975,1.8,1.8,0,0,0-1.6-1.751A1.922,1.922,0,0,0,12.021,3.7a0.5,0.5,0,1,0,.957.291,0.917,0.917,0,0,1,1.053-.725,0.81,0.81,0,0,1,.744.762c0,1.077-1.164,1.925-1.934,2.486A1.423,1.423,0,0,0,12,7.5a0.5,0.5,0,0,0,.5.5h3A0.5,0.5,0,0,0,15.5,7Z"
            />
            <path
              className={styles.qlFill}
              d="M9.651,5.241a1,1,0,0,0-1.41.108L6,7.964,3.759,5.349a1,1,0,1,0-1.519,1.3L4.683,9.5,2.241,12.35a1,1,0,1,0,1.519,1.3L6,11.036,8.241,13.65a1,1,0,0,0,1.519-1.3L7.317,9.5,9.759,6.651A1,1,0,0,0,9.651,5.241Z"
            />
          </svg>
        </button>
      </div>
      <div className={styles.section}>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('link') ? `${styles.isSelected}` : ''
          }`}
          data-balloon={t('Link')}
          onClick={onChange('link')}
        >
          <svg viewBox="0 0 18 18">
            <line className={styles.qlStroke} x1="7" x2="11" y1="7" y2="11" />
            <path
              className={`${styles.qlEven} ${styles.qlStroke}`}
              d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"
            />
            <path
              className={`${styles.qlEven} ${styles.qlStroke}`}
              d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"
            />
          </svg>
        </button>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('image') ? `${styles.isSelected}` : ''
          }`}
          data-balloon={t('Image')}
          onClick={onChange('image')}
        >
          <svg viewBox="0 0 18 18">
            <rect
              className={styles.qlStroke}
              height="10"
              width="12"
              x="3"
              y="4"
            />
            <circle className={styles.qlFill} cx="6" cy="7" r="1" />
            <polyline
              className={`${styles.qlEven} ${styles.qlFill}`}
              points="5 12 5 11 7 9 8 10 11 7 13 9 13 12 5 12"
            />
          </svg>
        </button>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('oembedly') ? `${styles.isSelected}` : ''
          }`}
          data-balloon={t('Oembedly')}
          onClick={onChange('oembedly')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18"
            viewBox="0 0 24 24"
            width="18"
          >
            <path
              className={styles.qlFill}
              d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92s2.92-1.31 2.92-2.92c0-1.61-1.31-2.92-2.92-2.92zM18 4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM6 13c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm12 7.02c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"
            />
          </svg>
        </button>
      </div>
      <div className={styles.section}>
        <button
          className={`${styles.iconButton} ${styles.balloon} ${
            richEditorOptions.includes('clean') ? `${styles.isSelected}` : ''
          }`}
          data-balloon={t('Clean')}
          onClick={onChange('clean')}
        >
          <svg viewBox="0 0 18 18">
            <line className={styles.qlStroke} x1="5" x2="13" y1="3" y2="3" />
            <line className={styles.qlStroke} x1="6" x2="9.35" y1="12" y2="3" />
            <line className={styles.qlStroke} x1="11" x2="15" y1="11" y2="15" />
            <line className={styles.qlStroke} x1="15" x2="11" y1="11" y2="15" />
            <rect
              className={styles.qlFill}
              height="1"
              rx="0.5"
              ry="0.5"
              width="7"
              x="2"
              y="14"
            />
          </svg>
        </button>
      </div>
      {errorText && <p className={styles.errorText}>{errorText}</p>}
    </div>
  );
};
