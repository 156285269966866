import { useReducer } from 'react';

import * as apiKeyDispatch from './dispatches/apiKeyPermission';
import * as apiDispatch from './dispatches/apiPermission';
import * as billingDispatch from './dispatches/billingPermission';
import * as defaultContentDispatch from './dispatches/defaultContentPermission';
import * as environmentDispatch from './dispatches/environmentPermission';
import * as exceptionContentDispatch from './dispatches/exceptionContentPermission';
import * as mediumDispatch from './dispatches/mediumPermission';
import * as memberDispatch from './dispatches/memberPermission';
import * as nameDispatch from './dispatches/name';
import * as replacementDispatch from './dispatches/replacement';
import * as reviewRequestDispatch from './dispatches/reviewRequestPermission';
import * as roleDispatch from './dispatches/rolePermission';
import * as usageDispatch from './dispatches/usagePermission';
import { getRoleInput } from '../helpers/convRoleInputOutput';

import type { FormRoleInputValue } from './stateContext';
import type { Role } from '@/entity/role';
import type { Reducer } from 'react';

import { highestLevelPermission } from '@/constants/role';

type Action =
  | replacementDispatch.ReplacementAction
  | nameDispatch.NameAction
  | roleDispatch.RolePermissionAction
  | memberDispatch.MemberPermissionAction
  | billingDispatch.BillingPermissionAction
  | usageDispatch.UsagePermissionAction
  | apiKeyDispatch.ApiKeyPermissionAction
  | reviewRequestDispatch.ReviewRequestPermissionAction
  | mediumDispatch.MediumPermissionAction
  | apiDispatch.ApiPermissionAction
  | defaultContentDispatch.DefaultContentPermissionAction
  | environmentDispatch.EnvironmentPermissionAction
  | exceptionContentDispatch.ExceptionContentPermissionAction;

const reducer: Reducer<FormRoleInputValue, Action> = (state, action) => {
  /**
   * Actionに応じてstateを変更する
   *
   * NOTE: Actionが増えた場合はここに追加すること
   */
  switch (action.type) {
    case 'replacement-with-role':
      return replacementDispatch.replacementWithRole(state, action.payload);
    case 'set-highest-level-permission':
      return replacementDispatch.setHighestLevelPermission(
        state,
        action.payload,
      );
    case 'set-lowest-level-permission':
      return replacementDispatch.setLowestLevelPermission(
        state,
        action.payload,
      );
    /**
     * 名前
     */
    case 'set-name':
      return nameDispatch.setName(state, action.payload);
    /**
     * ロール
     */
    case 'set-role-permission-create':
      return roleDispatch.setRolePermissionCreate(state, action.payload);
    case 'set-role-permission-read':
      return roleDispatch.setRolePermissionRead(state, action.payload);
    case 'set-role-permission-update':
      return roleDispatch.setRolePermissionUpdate(state, action.payload);
    case 'set-role-permission-delete':
      return roleDispatch.setRolePermissionDelete(state, action.payload);
    /**
     * メンバー
     */
    case 'set-member-permission-create':
      return memberDispatch.setMemberPermissionCreate(state, action.payload);
    case 'set-member-permission-read':
      return memberDispatch.setMemberPermissionRead(state, action.payload);
    case 'set-member-permission-update':
      return memberDispatch.setMemberPermissionUpdate(state, action.payload);
    case 'set-member-permission-delete':
      return memberDispatch.setMemberPermissionDelete(state, action.payload);
    /**
     * お支払い
     */
    case 'set-billing-permission-read':
      return billingDispatch.setBillingPermissionRead(state, action.payload);
    case 'set-billing-permission-update':
      return billingDispatch.setBillingPermissionUpdate(state, action.payload);
    /**
     * 利用
     */
    case 'set-usage-permission-read':
      return usageDispatch.setUsagePermissionRead(state, action.payload);
    /**
     * APIキー
     */
    case 'set-apikey-permission-read':
      return apiKeyDispatch.setApiKeyPermissionRead(state, action.payload);
    /**
     * レビュー
     */
    case 'set-reviewrequest-permission-create':
      return reviewRequestDispatch.setReviewRequestPermissionCreate(
        state,
        action.payload,
      );
    case 'set-reviewrequest-permission-read':
      return reviewRequestDispatch.setReviewRequestPermissionRead(
        state,
        action.payload,
      );
    case 'set-reviewrequest-permission-update':
      return reviewRequestDispatch.setReviewRequestPermissionUpdate(
        state,
        action.payload,
      );
    /**
     * メディア
     */
    case 'set-medium-permission-create':
      return mediumDispatch.setMediumPermissionCreate(state, action.payload);
    case 'set-medium-permission-read':
      return mediumDispatch.setMediumPermissionRead(state, action.payload);
    case 'set-medium-permission-update':
      return mediumDispatch.setMediumPermissionUpdate(state, action.payload);
    case 'set-medium-permission-delete':
      return mediumDispatch.setMediumPermissionDelete(state, action.payload);
    /**
     * API
     */
    case 'set-api-permission-create':
      return apiDispatch.setApiPermissionCreate(state, action.payload);
    /**
     * defaultContent関連
     */
    case 'set-dc-permission-apiread':
      return defaultContentDispatch.setDefaultContentPermissionApiRead(
        state,
        action.payload,
      );
    case 'set-dc-permission-apiupdate':
      return defaultContentDispatch.setDefaultContentPermissionApiUpdate(
        state,
        action.payload,
      );
    case 'set-dc-permission-apidelete':
      return defaultContentDispatch.setDefaultContentPermissionApiDelete(
        state,
        action.payload,
      );
    case 'set-dc-permission-contentcreate':
      return defaultContentDispatch.setDefaultContentPermissionContentCreate(
        state,
        action.payload,
      );
    case 'set-dc-permission-contentread':
      return defaultContentDispatch.setDefaultContentPermissionContentRead(
        state,
        action.payload,
      );
    case 'set-dc-permission-contentupdate':
      return defaultContentDispatch.setDefaultContentPermissionContentUpdate(
        state,
        action.payload,
      );
    case 'set-dc-permission-contentdelete':
      return defaultContentDispatch.setDefaultContentPermissionContentDelete(
        state,
        action.payload,
      );
    case 'set-dc-permission-showdevelopermenu':
      return defaultContentDispatch.setDefaultContentPermissionShowDeveloperMenu(
        state,
        action.payload,
      );
    /**
     * 複数環境
     */
    case 'set-environment-permission-create':
      return environmentDispatch.setEnvironmentPermissionCreate(
        state,
        action.payload,
      );
    case 'set-environment-permission-read':
      return environmentDispatch.setEnvironmentPermissionRead(
        state,
        action.payload,
      );
    case 'set-environment-permission-update':
      return environmentDispatch.setEnvironmentPermissionUpdate(
        state,
        action.payload,
      );
    case 'set-environment-permission-delete':
      return environmentDispatch.setEnvironmentPermissionDelete(
        state,
        action.payload,
      );
    /**
     * 個別権限
     */
    case 'add-ec-permission':
      return exceptionContentDispatch.addExceptionContentPermission(
        state,
        action.payload,
      );
    case 'remove-ec-permission':
      return exceptionContentDispatch.removeExceptionContentPermission(
        state,
        action.payload,
      );
    case 'set-ec-permission-apiid':
      return exceptionContentDispatch.setExceptionContentPermissionApiId(
        state,
        action.payload,
      );
    case 'set-ec-permission-allon':
      return exceptionContentDispatch.setExceptionContentPermissionAllOn(
        state,
        action.payload,
      );
    case 'set-ec-permission-alloff':
      return exceptionContentDispatch.setExceptionContentPermissionAllOff(
        state,
        action.payload,
      );
    case 'set-ec-permission-apiread':
      return exceptionContentDispatch.setECPermissionApiRead(
        state,
        action.payload,
      );
    case 'set-ec-permission-apiupdate':
      return exceptionContentDispatch.setECPermissionApiUpdate(
        state,
        action.payload,
      );
    case 'set-ec-permission-apidelete':
      return exceptionContentDispatch.setECPermissionApiDelete(
        state,
        action.payload,
      );
    case 'set-ec-permission-contentcreate':
      return exceptionContentDispatch.setECPermissionContentCreate(
        state,
        action.payload,
      );
    case 'set-ec-permission-contentread':
      return exceptionContentDispatch.setECPermissionContentRead(
        state,
        action.payload,
      );
    case 'set-ec-permission-contentupdate':
      return exceptionContentDispatch.setECPermissionContentUpdate(
        state,
        action.payload,
      );
    case 'set-ec-permission-contentdelete':
      return exceptionContentDispatch.setECPermissionContentDelete(
        state,
        action.payload,
      );
    case 'set-ec-permission-showdevelopermenu':
      return exceptionContentDispatch.setECPermissionShowDeveloperMenu(
        state,
        action.payload,
      );
    case 'set-ec-permission-reviewrequestcreate':
      return exceptionContentDispatch.setECPermissionReviewRequestCreate(
        state,
        action.payload,
      );
    case 'set-ec-permission-reviewrequestread':
      return exceptionContentDispatch.setECPermissionReviewRequestRead(
        state,
        action.payload,
      );
    case 'set-ec-permission-reviewrequestupdate':
      return exceptionContentDispatch.setECPermissionReviewRequestUpdate(
        state,
        action.payload,
      );
    /**
     * 上記に該当しない場合はstateをそのまま返す
     */
    default:
      return state;
  }
};

/**
 * Roleの入力値を管理するReducer
 */
function useFormRoleInputValueReducer(originalRole?: Role) {
  const [state, dispatch] = useReducer(
    reducer,
    originalRole
      ? getRoleInput(originalRole)
      : {
          name: '',
          exceptionContentPermissions: [],
          ...highestLevelPermission,
        },
  );

  return [state, dispatch] as const;
}

export { useFormRoleInputValueReducer };
