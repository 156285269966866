import { connect } from 'react-redux';

import { accountSelectors } from '../../ducks/account';
import Routes from './Routes';

import type { ComponentType } from 'react';

const mapStateToProps = (state: any) => {
  return {
    isAuthorized: accountSelectors.getAccountState(state.accountState)
      .isAuthorized,
  };
};

export default connect(mapStateToProps)(Routes as ComponentType<{}>);
