import { API, graphqlOperation } from 'aws-amplify';

import * as mutations from '../../graphql/mutations';

import type {
  RemoveReviewerMutationVariables,
  RemoveReviewerMutation,
} from '@/API';
import type { NonNullishFields } from '@/util/utility-type';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

async function removeReviewerRepository(
  variables: NonNullishFields<RemoveReviewerMutationVariables>,
) {
  const result = (await API.graphql<RemoveReviewerMutation>(
    graphqlOperation(mutations.removeReviewer, variables),
  )) as GraphQLResult<RemoveReviewerMutation>; // 基本的に、ObservableではなくGraphQLResultの型になるのでアサーションしている

  return result;
}

export { removeReviewerRepository };
