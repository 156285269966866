import { useState, useEffect, useRef } from 'react';

import {
  convertInputOptions,
  convertOptionsToQuery,
} from '../shared/convertEmbedOptions';

import type { OptionsField } from '@/entity/iframely';

type Props = {
  html: string;
  url: string;
  options?: Record<string, OptionsField>;
};

export const useEmbedPreview = ({ html, url, options }: Props) => {
  const [isScript, setIsScript] = useState(false);

  useEffect(() => {
    const scriptSrc = getScriptSrc(html);
    if (scriptSrc) {
      setIsScript(true);
    }
  }, [html, isScript]);

  const { optionQuery } = convertOptionsToQuery(convertInputOptions(options));

  const isSmallCard = optionQuery.includes('card=small');

  const isTwitter = url.includes('https://twitter.com');

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isTwitter) {
      const scriptSrc = getScriptSrc(html);
      const isTwitterScriptSrc =
        scriptSrc === 'https://platform.twitter.com/widgets.js';

      if (isTwitterScriptSrc) {
        window.twttr.widgets.load(containerRef.current);
      }
    }
  }, [html, isTwitter]);

  return {
    containerRef,
    isScript,
    isTwitter,
    isSmallCard,
    optionQuery,
  };
};

// htmlの情報からscriptタグを抜き出す
const getScriptSrc = (html: string) => {
  if (!html) {
    return null;
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const script = doc.querySelector('script');
  return script ? script.getAttribute('src') : null;
};
