import { Storage } from 'aws-amplify';
import mime from 'mime-types';

export const storageRepository = () => {
  const upload = async (file: File, fileName: string) => {
    try {
      const contentType = mime.lookup(file.name);

      if (!contentType) {
        throw new Error('Could not get content type');
      }

      await Storage.put(fileName, file, {
        level: 'protected',
        contentType,
      });
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not upload file');
    }
  };

  return { upload };
};
