import { useCallback, useEffect, useState } from 'react';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useInput, useLoading } from '../../../hooks';
import { useExceptionPermissionIsHaveLeastOne } from '../../../hooks/Role/useMyRoles';
import Feedback from '../../Feedback';
import Head from '../../Head';
import {
  validateAllowSwitch,
  validateSettingContentId,
} from '../../Validations';
import Button from '../../ui/Button';
import Fieldset from '../../ui/Fieldset';
import Switch from '../../ui/Switch';
import Textfield from '../../ui/Textfield';

import type { GetPutResultArgs } from '@/ducks/api/selector';
import type {
  ApiList,
  ContentIdSetting,
  MigrateApi,
} from '../../../entity/api';

import styles from './contentIdSettings.module.css';

import { apiSelectors } from '@/ducks/api';
import { putApi } from '@/ducks/api/operations';
import { apiListSelectors } from '@/ducks/apiList';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useApiListInvalidateCache } from '@/views/Common/api/invalidateCache';

const ContentIdSettings: React.FC = () => {
  const { t } = useTranslation('contentIdSettings');

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint } = useParams<{
    endpoint: string;
  }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );
  const dispatch = useAppDispatch();
  const putResult = useAppSelector(
    (state) =>
      apiSelectors.getPutResult(state.apiState as GetPutResultArgs).putResult,
  );

  const [error, setError] = useState<boolean | null>();
  const [errorText, setErrorText] = useState<string | null>();
  const [updatable] = useExceptionPermissionIsHaveLeastOne(
    api.partitionKey,
    'apiUpdate',
  );

  const [setting, setSetting] = useState<ContentIdSetting>(
    (api && api.contentIdSetting) || {
      allowList: ['lowercase' /*'uppercase',*/, 'symbol', 'number'],
      length: null,
    },
  );

  const onToggleSetting = useCallback(
    (method: any) => (bool: boolean) => {
      setSetting({
        ...setting,
        allowList:
          bool === true
            ? [...setting.allowList, method]
            : setting.allowList.filter((v) => v !== method),
      });
    },
    [setting],
  );

  const [length, onChangeLength, lengthError] = useInput(
    setting.length,
    // @ts-ignore
    validateSettingContentId,
  );
  useEffect(() => {
    const intLength = length ? Number.parseInt(length) : null;
    setSetting((setting: ContentIdSetting) => ({
      ...setting,
      length: intLength,
    }));
  }, [length]);

  useEffect(() => {
    const error = validateAllowSwitch(setting);
    setError(true);
    setErrorText(error);

    if (!error) {
      setError(undefined);
      setErrorText(undefined);
    }
  }, [errorText, setting, setting.allowList]);

  const apiListInvalidateCache = useApiListInvalidateCache();

  const [loading, startLoading] = useLoading(putResult !== undefined);
  const submit = useCallback(() => {
    startLoading();
    dispatch(
      //@ts-expect-error
      putApi({ apiId: api.partitionKey, contentIdSetting: setting }),
    ).then(() => {
      apiListInvalidateCache();
    });
  }, [
    api.partitionKey,
    apiListInvalidateCache,
    dispatch,
    setting,
    startLoading,
  ]);
  return (
    <div>
      <Head title={t('API/Content ID Settings')} />
      <h2 className={styles.title}>{t('Content ID')}</h2>
      <Fieldset
        legend={t('Character Types')}
        description={t('Specify character types allowed in a content ID.')}
      >
        <ul>
          <li className={styles.list}>
            <Switch
              onChange={onToggleSetting('lowercase')}
              on={setting.allowList.includes('lowercase')}
              size="large"
              disabled={!updatable}
            >
              {t('Lowercase')}
            </Switch>
          </li>
          <li className={styles.list}>
            <Switch
              onChange={onToggleSetting('uppercase')}
              on={setting.allowList.includes('uppercase')}
              size="large"
              disabled={!updatable}
            >
              {t('Uppercase')}
            </Switch>
          </li>
          <li className={styles.list}>
            <Switch
              onChange={onToggleSetting('number')}
              on={setting.allowList.includes('number')}
              size="large"
              disabled={!updatable}
            >
              {t('Number')}
            </Switch>
          </li>
          <li className={styles.list}>
            <Switch
              onChange={onToggleSetting('symbol')}
              on={setting.allowList.includes('symbol')}
              size="large"
              disabled={!updatable}
            >
              {t('Symbol (-, _)')}
            </Switch>
          </li>
        </ul>
        <p className={styles.errorText}>{errorText}</p>
      </Fieldset>
      <Fieldset
        legend={t('Number of characters')}
        description={t(
          'The number of characters in a content ID can be fixed in the range of 3 to 50 characters.',
        )}
      >
        <div className={styles.validationContent}>
          <Textfield
            defaultValue={String(setting.length)}
            className={styles.validationContentField}
            type="number"
            placeholder={t('E.g., 5')}
            onChange={onChangeLength}
            errorText={lengthError}
            style={{ width: '100px' }}
          />
        </div>
      </Fieldset>
      {updatable && (
        <div className={styles.actions}>
          <Feedback
            type={putResult === false ? 'failure' : 'success'}
            message={
              putResult === false
                ? t('Failed to make changes.')
                : putResult === true
                  ? t('Changes have been made.')
                  : undefined
            }
          />
          <Button
            type="primary"
            disabled={loading || lengthError || error}
            value={t('Save changes')}
            onClick={submit}
          />
        </div>
      )}
    </div>
  );
};

export default ContentIdSettings;
