import { connect } from 'react-redux';

import { apiOperations, apiSelectors } from '../../../ducks/api';
import InputModel from './InputModel';

const mapStateToProps = (state: any) => {
  return {
    ...apiSelectors.getPostResult(state.apiState),
  };
};

const mapDispatchToProps = {
  postApi: apiOperations.postApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(InputModel);
