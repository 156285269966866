import dayjs from 'dayjs';
import { useCallback, useMemo } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { useRestartApi } from '@/hooks/Api/useApiWriter';
import { useInfo } from '@/hooks/useInfo';
import { useGetMyService } from '@/hooks/useService';
import { useStripeActions } from '@/hooks/useStripeActions';

import Button from '../ui/Button';
import Loading from '../ui/Loading';
import Modal from '../ui/Modal';
import Notification from '../ui/Notification';

import styles from './main.module.css';

import { getDefaultHost } from '@/util';
import { formatDateToMinutes } from '@/util/date';

type Props = {
  header: any;
  children: React.ReactNode;
  attrs: any;
};

const Main: React.FC<Props> = ({ header, children, attrs }) => {
  const { t } = useTranslation('main');
  const { service, isChildEnvironment } = useGetMyService();
  const today = dayjs().toISOString();
  const [restartApi, restartApiLoading] = useRestartApi(service?.partitionKey);
  const [ApiStopModal, openApiStopModal, closeApiStopModal] = useModal('root');
  const doRestartApi = useCallback(() => {
    // @ts-expect-error
    restartApi({ close: closeApiStopModal });
  }, [restartApi, closeApiStopModal]);
  const host = useMemo(() => getDefaultHost(), []);
  const isIE = useMemo(() => {
    const userAgent = window.navigator.userAgent.toUpperCase();
    return userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('TRIDENT') > -1;
  }, []);
  const { isTrial, subscriptionDataQuery, currentPlan } =
    useStripeActions(service);
  const { data: subscriptionData } = subscriptionDataQuery;
  const { info, isInfoOpen, closeInfo, isInfoLoading } = useInfo();
  const history = useHistory();

  if (!service) return <Loading />;

  return (
    <main className={styles.wrapper}>
      <header className={styles.header}>
        {isChildEnvironment && (
          <div className={styles.env}>{service?.environment?.name}</div>
        )}
        <div className={styles.notification}>
          {isIE && (
            <Notification
              status="error"
              text={t(
                'Your browser is not supported. The application may not work or pages may not be displayed properly.',
              )}
              onClick={() =>
                window.open(
                  'https://document.microcms.io/manual/recommended-environment',
                )
              }
            />
          )}
          {(service?.apiStopUntil > today ||
            service?.apiStopUntil === 'FOREVER') && (
            <Notification
              status="error"
              onClick={openApiStopModal}
              text={`${t('API is suspended.')} ${
                service?.apiStopUntil !== 'FOREVER'
                  ? t('(Scheduled to reopen in {{time}})', {
                      time: formatDateToMinutes(service?.apiStopUntil),
                    })
                  : ''
              } ${t('Click here for more information.')}`}
            />
          )}
          {attrs.email_verified === 'false' && (
            <Notification
              status="warning"
              text={t(
                'Check your e-mail as you will receive a confirmation e-mail.',
              )}
              onClick={() => (window.location.href = `${host}/profile`)}
            />
          )}
          {isTrial && subscriptionData && (
            <Link to="/settings/billing">
              <Notification
                status="info"
                text={t(
                  'You are on the {{name}} plan free trial. (Expiring {{trial}}.)',
                  {
                    name: currentPlan?.name,
                    trial: dayjs.unix(subscriptionData.trial_end).fromNow(),
                  },
                )}
              />
            </Link>
          )}
          {info && isInfoOpen && isInfoLoading === false && (
            <a href={info.link} target="_blank" rel="noreferrer">
              <Notification status="info" text={info.title} close={closeInfo} />
            </a>
          )}
        </div>
        {header}
      </header>
      <div className={styles.main}>{children}</div>
      <ApiStopModal>
        <Modal
          type="medium"
          title={t('API is suspended')}
          description={t(
            'The API may be suspended for the following reasons The API can be restarted by upgrading the plan or removing the relevant items and keeping them within the plan.',
          )}
          footer={
            <div className={styles.apiStopActions}>
              <Button
                size="large"
                value={t('Upgrade Plan')}
                onClick={() => history.push('/settings/billing')}
                // @ts-expect-error
                disabled={restartApiLoading}
              />
              <button
                onClick={doRestartApi}
                className={styles.apiRestartedButton}
                // @ts-expect-error
                disabled={restartApiLoading}
              >
                {t('API is restarted')}
              </button>
            </div>
          }
        >
          <div className={styles.apiStopDescription}>
            <ul>
              <li className={styles.list}>
                {t('Exceeded maximum amount of data transfer')}
              </li>
              <li className={styles.list}>
                {t('Exceeding the number of APIs')}
              </li>
              <li className={styles.list}>{t('Exceeds number of members')}</li>
              <li className={styles.list}>
                {t('Number of API keys exceeded')}
              </li>
              <li className={styles.list}>
                {t('Exceeds the number of contents')}
              </li>
            </ul>
          </div>
        </Modal>
      </ApiStopModal>
    </main>
  );
};
export default Main;
