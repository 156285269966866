import type React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Fieldset from '../../ui/Fieldset';
import Switch from '../../ui/Switch';
import Textfield from '../../ui/Textfield';
import {
  validateRegExpPattern,
  validateRegExpFlags,
} from '../../Validations/index';
import styles from '../apiModel.module.css';

import type { PatternMatchValidation } from '../../../types/field';

type Props = {
  patternMatchValidation: PatternMatchValidation;
  idValue: string;
  onChange: (validation?: PatternMatchValidation) => void;
  onError: (error: string | null) => void;
};

const PatternMatchValidationField: React.FC<Props> = ({
  patternMatchValidation,
  idValue,
  onChange = () => {},
  onError = () => {},
}) => {
  const { t } = useTranslation('patternMatchValidationField');
  const [enabled, setEnabled] = useState(() => !!patternMatchValidation);
  const [pattern, setPattern] = useState(
    () => patternMatchValidation?.regexp.pattern,
  );
  const [flags, setFlags] = useState(
    () => patternMatchValidation?.regexp.flags,
  );
  const [regExpPatternErrorText, setRegExpPatternErrorText] = useState<
    string | null
  >(null);
  const [regExpFlagsErrorText, setRegExpFlagsErrorText] = useState<
    string | null
  >(null);
  const [validation, setValidation] = useState<
    PatternMatchValidation | undefined
  >(patternMatchValidation);

  const onChangeRegExpPattern = useCallback(({ target }: any) => {
    setPattern(target.value || null);
  }, []);

  const onChangeRegExpFlags = useCallback(({ target }: any) => {
    setFlags(target.value || null);
  }, []);

  useEffect(() => {
    onError(regExpPatternErrorText || regExpFlagsErrorText);
  }, [onError, regExpFlagsErrorText, regExpPatternErrorText]);

  useEffect(() => {
    onChange(validation);
  }, [onChange, validation]);

  useEffect(() => {
    if (!enabled) {
      setValidation(undefined);
      setRegExpPatternErrorText(null);
      setRegExpFlagsErrorText(null);
      return;
    }
    const regExpPatternError = validateRegExpPattern(pattern);
    const regExpFlagsError = validateRegExpFlags(flags);
    setRegExpPatternErrorText(regExpPatternError);
    setRegExpFlagsErrorText(regExpFlagsError);
    if (!regExpPatternError && !regExpFlagsError) {
      setValidation({ regexp: { pattern, flags } });
    }
  }, [flags, pattern, enabled]);

  return (
    <Fieldset
      legend={t('Allow Text Matches with Specific Pattern')}
      description={t('Allow input when text matches with a regular expression')}
    >
      <Switch
        on={enabled}
        onChange={(state) => {
          setEnabled(state);
        }}
        size="large"
      />
      {enabled && (
        <div className={styles.validationContent}>
          <Textfield
            id={`${idValue}_validation-regexp-pattern`}
            className={styles.validationContentFieldPrimary}
            labelName={t('Patterns')}
            placeholder={t('E.g., [0-9a-z]+')}
            defaultValue={pattern}
            onChange={onChangeRegExpPattern}
            errorText={regExpPatternErrorText}
          />
          <Textfield
            id={`${idValue}_validation-regxp-flags`}
            className={styles.validationContentField}
            labelName={t('Flags')}
            // @ts-expect-error
            pattern="[gimsuy]+"
            placeholder={t('E.g., gi')}
            defaultValue={flags}
            onChange={onChangeRegExpFlags}
            size="small"
            errorText={regExpFlagsErrorText}
          />
        </div>
      )}
    </Fieldset>
  );
};

export default PatternMatchValidationField;
