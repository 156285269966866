import Forbidden from '@/components/Forbidden';

import { useContentsCompare } from './useContentsCompare';

import type { MigrateApi } from '@/entity/api';
import type { ContentStatus } from '@/entity/content';
import type { CustomField } from '@/types/contents';

import styles from './contentsCompare.module.css';

import { Loading } from '@/views/Common/Ui/Loading';
import { ContentsComparisonComponent } from '@/views/Common/content/ContentsComparison/ContentsComparison';

type Props = {
  api?: MigrateApi | null | undefined;
  contentId?: string | null | undefined;
  currentStatus?: ContentStatus | null | undefined;
  forbidden: boolean;
};

type ViewProps = {
  apiFields: MigrateApi['apiFields'];
  customFields: CustomField[];
  sinceContent: Record<string, unknown>;
  untilContent: Record<string, unknown>;
  unauthorizedFieldIds: string[];
};

const ContentsCompareView: React.FC<ViewProps> = ({
  apiFields,
  customFields,
  sinceContent,
  untilContent,
  unauthorizedFieldIds,
}) => {
  return (
    <div>
      <ContentsComparisonComponent
        apiFields={apiFields}
        customFields={customFields}
        sinceContent={sinceContent}
        untilContent={untilContent}
        noDiffSkip
        unauthorizedFieldIds={unauthorizedFieldIds}
      />
    </div>
  );
};

const ContentsCompare: React.FC<Props> = (props) => {
  const hooks = useContentsCompare(props);

  if (props.forbidden) {
    return (
      <div className={styles.forbidden}>
        <Forbidden />
      </div>
    );
  }

  if (!hooks.isReady) {
    return <Loading className={styles.loading} />;
  }

  return <ContentsCompareView {...hooks} />;
};

export { ContentsCompare };
export type { Props };
