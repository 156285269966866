import { connect } from 'react-redux';

import { accountSelectors } from '../../ducks/account';
import Root from './Root';

import type { ComponentType } from 'react';

const mapStateToProps = (state: any) => {
  return {
    isAuthorized: accountSelectors.getAccountState(state.accountState)
      .isAuthorized,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Root as ComponentType<{}>);
