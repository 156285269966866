import { API, graphqlOperation } from 'aws-amplify';

import type { IframelyResponse } from '@/entity/iframely';

import * as queries from '@/graphql/queries';

export const iframelyRepository = () => {
  const getIframelyResult = async ({
    url,
    optionQuery,
  }: {
    url: string;
    optionQuery: string;
  }): Promise<IframelyResponse | null> => {
    try {
      const result = await API.graphql(
        graphqlOperation(queries.getIframelyResult, {
          url,
          optionQuery,
        }),
      );
      const typedResult = result as {
        data: { getIframelyResult: string };
      };
      if (typedResult.data.getIframelyResult === null) {
        return null;
      }
      return JSON.parse(typedResult.data.getIframelyResult)
        .result as IframelyResponse;
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
      throw new Error('Could not get iframely result');
    }
  };

  return { getIframelyResult };
};
