import { findIndexECPs } from '../../../helpers/findIndexExceptionContentPermissions';

import type { Api } from '@/entity/api';
import type { InputExceptionContentPermission } from '@/entity/role';
import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from '../_types';

import {
  highestLevelExceptionContentPermission,
  highestLevelExceptionReviewRequestPermission,
  lowestLevelExceptionContentPermission,
  lowestLevelExceptionReviewRequestPermission,
} from '@/constants/role';
import { produce } from '@/lib/immer';
import { genUID } from '@/lib/nanoid';

type AddExceptionContentPermissionAction = FormRoleInputValueAction<
  'add-ec-permission',
  Api['partitionKey']
>;
type RemoveExceptionContentPermissionAction = FormRoleInputValueAction<
  'remove-ec-permission',
  InputExceptionContentPermission['id']
>;
type SetExceptionContentPermissionApiIdAction = FormRoleInputValueAction<
  'set-ec-permission-apiid',
  { id: string; apiId: InputExceptionContentPermission['apiId'] }
>;
type SetExceptionContentPermissionAllOnAction = FormRoleInputValueAction<
  'set-ec-permission-allon',
  InputExceptionContentPermission['id']
>;
type SetExceptionContentPermissionAllOffAction = FormRoleInputValueAction<
  'set-ec-permission-alloff',
  InputExceptionContentPermission['id']
>;

/**
 * 個別権限の要素を1つ追加する
 *
 * payloadにはAPIIDを指定すること
 */
const addExceptionContentPermission: FormRoleInputValueDispatchFn<
  AddExceptionContentPermissionAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.exceptionContentPermissions.push({
      apiId: payload,
      id: genUID(),
      permission: highestLevelExceptionContentPermission,
      reviewRequestPermission: highestLevelExceptionReviewRequestPermission,
    });
  });
};

/**
 * 個別権限の要素を1つ削除する
 *
 * payloadにはID値を指定すること (APIIDではないので注意)
 */
const removeExceptionContentPermission: FormRoleInputValueDispatchFn<
  RemoveExceptionContentPermissionAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.exceptionContentPermissions =
      draft.exceptionContentPermissions.filter(
        (permission) => permission.id !== payload,
      );
  });
};

/**
 * 個別権限の指定したIDの要素にあるapiIdを更新する
 */
const setExceptionContentPermissionApiId: FormRoleInputValueDispatchFn<
  SetExceptionContentPermissionApiIdAction
> = (currentState, { id, apiId }) => {
  return produce(currentState, (draft) => {
    const index = findIndexECPs(draft.exceptionContentPermissions, id);
    if (index >= 0) {
      draft.exceptionContentPermissions[index].apiId = apiId;
    }
  });
};

/**
 * 個別権限の指定したIDの要素にあるpermissionを全てONにする
 */
const setExceptionContentPermissionAllOn: FormRoleInputValueDispatchFn<
  SetExceptionContentPermissionAllOnAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    const index = findIndexECPs(draft.exceptionContentPermissions, payload);
    if (index >= 0) {
      draft.exceptionContentPermissions[index].permission =
        highestLevelExceptionContentPermission;
      draft.exceptionContentPermissions[index].reviewRequestPermission =
        highestLevelExceptionReviewRequestPermission;
    }
  });
};

/**
 * 個別権限の指定したIDの要素にあるpermissionを全てOFFにする
 */
const setExceptionContentPermissionAllOff: FormRoleInputValueDispatchFn<
  SetExceptionContentPermissionAllOffAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    const index = findIndexECPs(draft.exceptionContentPermissions, payload);
    if (index >= 0) {
      draft.exceptionContentPermissions[index].permission =
        lowestLevelExceptionContentPermission;
      draft.exceptionContentPermissions[index].reviewRequestPermission =
        lowestLevelExceptionReviewRequestPermission;
    }
  });
};

export {
  type AddExceptionContentPermissionAction,
  type RemoveExceptionContentPermissionAction,
  type SetExceptionContentPermissionApiIdAction,
  type SetExceptionContentPermissionAllOnAction,
  type SetExceptionContentPermissionAllOffAction,
  addExceptionContentPermission,
  removeExceptionContentPermission,
  setExceptionContentPermissionApiId,
  setExceptionContentPermissionAllOn,
  setExceptionContentPermissionAllOff,
};
