import { useQuery } from '@tanstack/react-query';
import { API, graphqlOperation } from 'aws-amplify';

import * as queries from '../../graphql/queries';

import type { Role } from '@/entity/role';
import type { GraphQLResultErrors } from '@/types/graphql';

/**
 *
 * @deprecated
 * 代わりに views/Common/roles/useRolesReader.ts の useAllRoles を使ってください
 */
export const useRoleList = (serviceId: string | null) => {
  // const [list, setList] = useState(undefined);
  // useEffect(() => {
  //   if (serviceId) {
  //     API.graphql(graphqlOperation(queries.listServiceRoles, { serviceId }))
  //       .then((result) => result.data.listServiceRoles)
  //       .then((result) => {
  //         setList(result);
  //       });
  //   }
  // }, [serviceId]);
  // return [list];
  const roleListQuery = useQuery({
    queryKey: ['roleList'],
    queryFn: () => {
      if (serviceId) {
        return (
          API.graphql(
            graphqlOperation(queries.listServiceRoles, { serviceId }),
          ) as Promise<GraphQLResultErrors<{ listServiceRoles: Array<Role> }>>
        ).then((result) => result.data?.listServiceRoles);
      }
    },
    staleTime: Number.POSITIVE_INFINITY,
  });

  const { data: list } = roleListQuery;

  return [list];
};
