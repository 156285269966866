import { useI18nFormRole } from '../../../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../../../provider';

import type { FormRoleInputValue } from '../../../provider/stateContext';
import type { PropsWithECP } from '../../FieldExceptionContent/_types';
import type { FC } from 'react';

import { Checkbox } from '@/views/Common/Ui/Checkbox';
import { InputGroup, InputGroupContainer } from '@/views/Role/InputGroup';

type Props = Partial<PropsWithECP>;

/**
 * コンテンツ権限項目 - UPDATE権限の入力フィールド
 */
const FieldContentUpdate: FC<Props> = ({ targetECP }) => {
  const { t } = useI18nFormRole('$contentPermission.$update');

  const { isAdminRole, input } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  const value = getValueIsomorphic(input, targetECP);

  return (
    <InputGroupContainer>
      <InputGroup
        groupLabel={t('Operations that do not affect published contents')}
      >
        <Checkbox
          name={'permission.contentUpdate.saveDraft'}
          label={t('Draft')}
          checked={value.saveDraft}
          disabled={disabled}
          onChange={(e) => {
            const nextValue = e.target.checked;
            if (targetECP) {
              dispatch({
                type: 'set-ec-permission-contentupdate',
                payload: { id: targetECP.id, nextValue, key: 'saveDraft' },
              });
            } else {
              dispatch({
                type: 'set-dc-permission-contentupdate',
                payload: { key: 'saveDraft', nextValue },
              });
            }
          }}
        />
        <Checkbox
          name={'permission.contentUpdate.removeDraft'}
          label={t('Discard draft')}
          checked={value.removeDraft}
          disabled={disabled}
          onChange={(e) => {
            const nextValue = e.target.checked;
            if (targetECP) {
              dispatch({
                type: 'set-ec-permission-contentupdate',
                payload: { id: targetECP.id, nextValue, key: 'removeDraft' },
              });
            } else {
              dispatch({
                type: 'set-dc-permission-contentupdate',
                payload: { key: 'removeDraft', nextValue },
              });
            }
          }}
        />
        <Checkbox
          name={'permission.contentUpdate.revertToDraftUnpublished'}
          label={t('Revert unpublished contents to draft')}
          checked={value?.revertToDraftUnpublished} // FIXME: マイグレーション後にオプショナルチェイニングは外せる
          disabled={disabled}
          onChange={(e) => {
            const nextValue = e.target.checked;
            if (targetECP) {
              dispatch({
                type: 'set-ec-permission-contentupdate',
                payload: {
                  id: targetECP.id,
                  nextValue,
                  key: 'revertToDraftUnpublished',
                },
              });
            } else {
              dispatch({
                type: 'set-dc-permission-contentupdate',
                payload: { key: 'revertToDraftUnpublished', nextValue },
              });
            }
          }}
        />
        <Checkbox
          name={'permission.contentUpdate.changeContentIdUnpublished'}
          label={t('Change content ID of unpublished contents')}
          checked={value.changeContentIdUnpublished}
          disabled={disabled}
          onChange={(e) => {
            const nextValue = e.target.checked;
            if (targetECP) {
              dispatch({
                type: 'set-ec-permission-contentupdate',
                payload: {
                  id: targetECP.id,
                  nextValue,
                  key: 'changeContentIdUnpublished',
                },
              });
            } else {
              dispatch({
                type: 'set-dc-permission-contentupdate',
                payload: { key: 'changeContentIdUnpublished', nextValue },
              });
            }
          }}
        />
        <Checkbox
          name={'permission.contentUpdate.reorderUnpublished'}
          label={t('Reorder unpublished contents')}
          checked={value.reorderUnpublished}
          disabled={disabled}
          onChange={(e) => {
            const nextValue = e.target.checked;
            if (targetECP) {
              dispatch({
                type: 'set-ec-permission-contentupdate',
                payload: {
                  id: targetECP.id,
                  nextValue,
                  key: 'reorderUnpublished',
                },
              });
            } else {
              dispatch({
                type: 'set-dc-permission-contentupdate',
                payload: { key: 'reorderUnpublished', nextValue },
              });
            }
          }}
        />
        <Checkbox
          name={'permission.contentUpdate.changePublicationDateUnpublished'}
          label={t('Change publication date of unpublished contents')}
          checked={value.changePublicationDateUnpublished}
          disabled={disabled}
          onChange={(e) => {
            const nextValue = e.target.checked;
            if (targetECP) {
              dispatch({
                type: 'set-ec-permission-contentupdate',
                payload: {
                  id: targetECP.id,
                  nextValue,
                  key: 'changePublicationDateUnpublished',
                },
              });
            } else {
              dispatch({
                type: 'set-dc-permission-contentupdate',
                payload: {
                  key: 'changePublicationDateUnpublished',
                  nextValue,
                },
              });
            }
          }}
        />
        <Checkbox
          name={'permission.contentUpdate.changeAuthor'}
          label={t('Change content author')}
          checked={value.changeAuthor}
          disabled={disabled}
          onChange={(e) => {
            const nextValue = e.target.checked;
            if (targetECP) {
              dispatch({
                type: 'set-ec-permission-contentupdate',
                payload: { id: targetECP.id, nextValue, key: 'changeAuthor' },
              });
            } else {
              dispatch({
                type: 'set-dc-permission-contentupdate',
                payload: { key: 'changeAuthor', nextValue },
              });
            }
          }}
        />
        <Checkbox
          name={'permission.contentUpdate.changeDraftKey'}
          label={t('Change draftKey')}
          checked={value.changeDraftKey}
          disabled={disabled}
          onChange={(e) => {
            const nextValue = e.target.checked;
            if (targetECP) {
              dispatch({
                type: 'set-ec-permission-contentupdate',
                payload: { id: targetECP.id, nextValue, key: 'changeDraftKey' },
              });
            } else {
              dispatch({
                type: 'set-dc-permission-contentupdate',
                payload: { key: 'changeDraftKey', nextValue },
              });
            }
          }}
        />
      </InputGroup>

      <InputGroup groupLabel={t('Operations that affect published contents')}>
        <Checkbox
          name={'permission.contentUpdate.publish'}
          label={t('Publish / Schedule publication')}
          checked={value.publish}
          disabled={disabled}
          onChange={(e) => {
            const nextValue = e.target.checked;
            if (targetECP) {
              dispatch({
                type: 'set-ec-permission-contentupdate',
                payload: { id: targetECP.id, nextValue, key: 'publish' },
              });
            } else {
              dispatch({
                type: 'set-dc-permission-contentupdate',
                payload: { key: 'publish', nextValue },
              });
            }
          }}
        />
        <Checkbox
          name={'permission.contentUpdate.unpublish'}
          label={t('Unpublish / Schedule unpublishing')}
          checked={value.unpublish}
          disabled={disabled}
          onChange={(e) => {
            const nextValue = e.target.checked;
            if (targetECP) {
              dispatch({
                type: 'set-ec-permission-contentupdate',
                payload: { id: targetECP.id, nextValue, key: 'unpublish' },
              });
            } else {
              dispatch({
                type: 'set-dc-permission-contentupdate',
                payload: { key: 'unpublish', nextValue },
              });
            }
          }}
        />
        <Checkbox
          name={'permission.contentUpdate.revertToDraft'}
          label={t('Revert published contents to draft')}
          checked={value.revertToDraft}
          disabled={disabled}
          onChange={(e) => {
            const nextValue = e.target.checked;
            if (targetECP) {
              dispatch({
                type: 'set-ec-permission-contentupdate',
                payload: { id: targetECP.id, nextValue, key: 'revertToDraft' },
              });
            } else {
              dispatch({
                type: 'set-dc-permission-contentupdate',
                payload: { key: 'revertToDraft', nextValue },
              });
            }
          }}
        />
        <Checkbox
          name={'permission.contentUpdate.changeContentIdPublished'}
          label={t('Change content ID of published contents')}
          checked={value.changeContentIdPublished}
          disabled={disabled}
          onChange={(e) => {
            const nextValue = e.target.checked;
            if (targetECP) {
              dispatch({
                type: 'set-ec-permission-contentupdate',
                payload: {
                  id: targetECP.id,
                  nextValue,
                  key: 'changeContentIdPublished',
                },
              });
            } else {
              dispatch({
                type: 'set-dc-permission-contentupdate',
                payload: { key: 'changeContentIdPublished', nextValue },
              });
            }
          }}
        />
        <Checkbox
          name={'permission.contentUpdate.reorderPublished'}
          label={t('Reorder published contents')}
          checked={value.reorderPublished}
          disabled={disabled}
          onChange={(e) => {
            const nextValue = e.target.checked;
            if (targetECP) {
              dispatch({
                type: 'set-ec-permission-contentupdate',
                payload: {
                  id: targetECP.id,
                  nextValue,
                  key: 'reorderPublished',
                },
              });
            } else {
              dispatch({
                type: 'set-dc-permission-contentupdate',
                payload: { key: 'reorderPublished', nextValue },
              });
            }
          }}
        />
        <Checkbox
          name={'permission.contentUpdate.changePublicationDatePublished'}
          label={t('Change publication date of published contents')}
          checked={value.changePublicationDatePublished}
          disabled={disabled}
          onChange={(e) => {
            const nextValue = e.target.checked;
            if (targetECP) {
              dispatch({
                type: 'set-ec-permission-contentupdate',
                payload: {
                  id: targetECP.id,
                  nextValue,
                  key: 'changePublicationDatePublished',
                },
              });
            } else {
              dispatch({
                type: 'set-dc-permission-contentupdate',
                payload: {
                  key: 'changePublicationDatePublished',
                  nextValue,
                },
              });
            }
          }}
        />
      </InputGroup>
    </InputGroupContainer>
  );
};

function getValueIsomorphic(
  input: FormRoleInputValue,
  targetEcp: Props['targetECP'],
) {
  if (targetEcp) {
    return targetEcp.permission.contentUpdate;
  }
  return input.defaultContentPermission.contentUpdate;
}

export default FieldContentUpdate;
