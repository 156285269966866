import { connect } from 'react-redux';

import { authOperations } from '../../ducks/auth';
import Nav from './Nav';

const mapDispatchToProps = {
  signOut: authOperations.signOut,
};

export default connect(null, mapDispatchToProps)(Nav);
