/**
 * Webhook設定の通知対象のサービスの識別子
 */
const WebhookSettingTarget = {
  SLACK: 'SLACK',
  CHATWORK: 'CHATWORK',
  NETLIFY: 'NETLIFY',
  CLOUDFLARE_PAGES: 'CLOUDFLARE_PAGES',
  VERCEL: 'VERCEL',
  AMPLIFY: 'AMPLIFY',
  GITHUB_ACTIONS: 'GITHUB_ACTIONS',
  EMAIL: 'EMAIL',
  CUSTOM: 'CUSTOM',
} as const;

/**
 * 通知タイミング設定項目の識別子
 *
 * @see {WebhookMediumEvent} メディアのWebhook用の通知タイミング設定項目の識別子はそちらを参照
 */
const WebhookEvent = {
  /**
   * [コンテンツの公開時・更新時] コンテンツの公開（管理画面による操作）
   */
  PUBLISH_CONTENT_BY_SCREEN: 'PUBLISH_CONTENT_BY_SCREEN',
  /**
   * [コンテンツの公開時・更新時] コンテンツの公開（APIによる操作）
   */
  PUBLISH_CONTENT_BY_WRITE_API: 'PUBLISH_CONTENT_BY_WRITE_API',
  /**
   * [コンテンツの公開時・更新時] コンテンツの公開（レビューによる公開）
   */
  PUBLISH_CONTENT_BY_REVIEW: 'PUBLISH_CONTENT_BY_REVIEW',
  /**
   * [コンテンツの公開時・更新時] コンテンツの公開（予約設定による操作）
   */
  PUBLISH_CONTENT_BY_RESERVATION: 'PUBLISH_CONTENT_BY_RESERVATION',
  /**
   * [コンテンツの公開時・更新時] コンテンツの並び替え
   */
  PUBLISH_CONTENT_BY_CHANGE_ORDER: 'PUBLISH_CONTENT_BY_CHANGE_ORDER',
  /**
   * [コンテンツの公開時・更新時] コンテンツIDの変更
   */
  PUBLISH_CONTENT_BY_CHANGE_CONTENT_ID: 'PUBLISH_CONTENT_BY_CHANGE_CONTENT_ID',
  /**
   * [コンテンツの公開時・更新時] 公開日時の変更
   */
  PUBLISH_CONTENT_BY_CHANGE_PUBLISHED_AT:
    'PUBLISH_CONTENT_BY_CHANGE_PUBLISHED_AT',
  /**
   * [コンテンツの公開時・更新時] カスタムステータスの変更
   */
  PUBLISH_CONTENT_BY_CHANGE_CONTENT_STATUS:
    'PUBLISH_CONTENT_BY_CHANGE_CONTENT_STATUS',
  /**
   * [コンテンツの公開終了時] コンテンツの公開終了、または公開中コンテンツを下書きに戻す（管理画面による操作）
   */
  UNPUBLISH_CONTENT_BY_SCREEN: 'UNPUBLISH_CONTENT_BY_SCREEN',
  /**
   * [コンテンツの公開終了時] コンテンツの公開終了（予約設定による操作）
   */
  UNPUBLISH_CONTENT_BY_RESERVATION: 'UNPUBLISH_CONTENT_BY_RESERVATION',
  /**
   * [コンテンツの公開終了時] コンテンツの並び替え
   */
  UNPUBLISH_CONTENT_BY_CHANGE_ORDER: 'UNPUBLISH_CONTENT_BY_CHANGE_ORDER',
  /**
   * [コンテンツの公開終了時] コンテンツIDの変更
   */
  UNPUBLISH_CONTENT_BY_CHANGE_CONTENT_ID:
    'UNPUBLISH_CONTENT_BY_CHANGE_CONTENT_ID',
  /**
   * [コンテンツの公開終了時] 公開日時の変更
   */
  UNPUBLISH_CONTENT_BY_CHANGE_PUBLISHED_AT:
    'UNPUBLISH_CONTENT_BY_CHANGE_PUBLISHED_AT',
  /**
   * [コンテンツの公開終了時] カスタムステータスの変更
   */
  UNPUBLISH_CONTENT_BY_CHANGE_CONTENT_STATUS:
    'UNPUBLISH_CONTENT_BY_CHANGE_CONTENT_STATUS',
  /**
   * [コンテンツの下書き保存時] コンテンツの下書き保存（管理画面による操作）
   */
  SAVE_DRAFT_CONTENT_BY_SCREEN: 'SAVE_DRAFT_CONTENT_BY_SCREEN',
  /**
   * [コンテンツの下書き保存時] コンテンツの下書き保存（APIによる操作）
   */
  SAVE_DRAFT_CONTENT_BY_WRITE_API: 'SAVE_DRAFT_CONTENT_BY_WRITE_API',
  /**
   * [コンテンツの下書き保存時] コンテンツの並び替え
   */
  SAVE_DRAFT_CONTENT_BY_CHANGE_ORDER: 'SAVE_DRAFT_CONTENT_BY_CHANGE_ORDER',
  /**
   * [コンテンツの下書き保存時] コンテンツIDの変更
   */
  SAVE_DRAFT_CONTENT_BY_CHANGE_CONTENT_ID:
    'SAVE_DRAFT_CONTENT_BY_CHANGE_CONTENT_ID',
  /**
   * [コンテンツの下書き保存時] 公開日時の変更
   */
  SAVE_DRAFT_CONTENT_BY_CHANGE_PUBLISHED_AT:
    'SAVE_DRAFT_CONTENT_BY_CHANGE_PUBLISHED_AT',
  /**
   * [コンテンツの下書き保存時] カスタムステータスの変更
   */
  SAVE_DRAFT_CONTENT_BY_CHANGE_CONTENT_STATUS:
    'SAVE_DRAFT_CONTENT_BY_CHANGE_CONTENT_STATUS',
  /**
   * [公開中コンテンツの削除時] コンテンツの削除（管理画面による操作）
   */
  DELETE_PUBLISH_CONTENT_BY_SCREEN: 'DELETE_PUBLISH_CONTENT_BY_SCREEN',
  /**
   * [公開中コンテンツの削除時] コンテンツの削除（APIによる操作）
   */
  DELETE_PUBLISH_CONTENT_BY_WRITE_API: 'DELETE_PUBLISH_CONTENT_BY_WRITE_API',
  /**
   * [公開終了コンテンツの削除時] コンテンツの削除（管理画面による操作）
   */
  DELETE_UNPUBLISH_CONTENT_BY_SCREEN: 'DELETE_UNPUBLISH_CONTENT_BY_SCREEN',
  /**
   * [公開終了コンテンツの削除時] コンテンツの削除（APIによる操作）
   */
  DELETE_UNPUBLISH_CONTENT_BY_WRITE_API:
    'DELETE_UNPUBLISH_CONTENT_BY_WRITE_API',
  /**
   * [下書きコンテンツの削除時] コンテンツの削除（管理画面による操作）
   */
  DELETE_CONTENT_BY_SCREEN: 'DELETE_CONTENT_BY_SCREEN',
  /**
   * [下書きコンテンツの削除時] コンテンツの削除（APIによる操作）
   */
  DELETE_CONTENT_BY_WRITE_API: 'DELETE_CONTENT_BY_WRITE_API',
  /**
   * [公開中かつ下書き中コンテンツの下書き破棄時] 下書きの破棄（管理画面による操作）
   */
  DELETE_DRAFT_BY_SCREEN: 'DELETE_DRAFT_BY_SCREEN',
  /**
   * APIの設定変更時
   */
  UPDATE_API: 'UPDATE_API',
  /**
   * APIの削除時
   */
  DELETE_API: 'DELETE_API',
} as const;

/**
 * メディアのWebhook用の通知タイミング設定項目の識別子
 */
const WebhookMediumEvent = {
  /**
   * メディアの作成
   */
  ADD_MEDIUM: 'ADD_MEDIUM',
  /**
   * メディアの更新
   */
  UPDATE_MEDIUM: 'UPDATE_MEDIUM',
  /**
   * メディアの削除
   */
  DELETE_MEDIUM: 'DELETE_MEDIUM',
} as const;

export { WebhookSettingTarget, WebhookEvent, WebhookMediumEvent };
