import { connect } from 'react-redux';

import { apiOperations, apiSelectors } from '../../../ducks/api';
import CreateApiInputBasicInfo from './CreateApiInputBasicInfo';

const mapStateToProps = (state: any) => ({
  ...apiSelectors.getHasSameEndpoint(state.apiState),
});

const mapDispatchToProps = {
  checkSameEndpoint: apiOperations.checkSameEndpoint,
  reset: apiOperations.resetApi,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateApiInputBasicInfo);
