import { API, graphqlOperation } from 'aws-amplify';

import type {
  ApiKeyPolicy,
  ApiKeyPriorityPolicy,
  ManagementPolicy,
} from '@/types/apiKey';
import type { GraphQLResult } from '@aws-amplify/api-graphql';

import * as mutations from '@/graphql/mutations';
import {
  handleApiResult,
  type ApiResponse,
} from '@/views/Common/handleApiResult';

export const apiKeyRepository = () => {
  const createApiKey = async ({
    serviceId,
    label,
    defaultPolicy,
    priorityPolicies,
    managementPolicy,
  }: {
    serviceId: string;
    label?: string;
    defaultPolicy: ApiKeyPolicy;
    priorityPolicies?: ApiKeyPriorityPolicy[];
    managementPolicy?: ManagementPolicy | null;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.createApiKey, {
          serviceId,
          label: label ? label : undefined,
          defaultPolicy,
          priorityPolicies: priorityPolicies ? priorityPolicies : [],
          managementPolicy: managementPolicy ? managementPolicy : null,
        }),
      )) as GraphQLResult<{
        createApiKey: ApiResponse<string> | string;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.createApiKey === 'object' &&
        typeof result.data.createApiKey.result === 'boolean'
      ) {
        return handleApiResult(result.data.createApiKey);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  const updateApiKey = async ({
    apiKeyId,
    label,
    defaultPolicy,
    priorityPolicies,
    managementPolicy,
  }: {
    apiKeyId: string;
    label?: string;
    defaultPolicy: ApiKeyPolicy;
    priorityPolicies?: ApiKeyPriorityPolicy[];
    managementPolicy?: ManagementPolicy | null;
  }) => {
    try {
      const result = (await API.graphql(
        graphqlOperation(mutations.updateApiKey, {
          apiKeyId,
          label: label ? label : undefined,
          defaultPolicy,
          priorityPolicies: priorityPolicies ? priorityPolicies : [],
          managementPolicy: managementPolicy ? managementPolicy : null,
        }),
      )) as GraphQLResult<{
        // NOTE:Node.jsではdataがundefinedなので、それに合わせて定義している
        // Go移植の際レスポンスにdataを追加する場合は、それに合わせて型定義を変更する
        updateApiKey: ApiResponse<undefined>;
      }>;

      if (!result.data) {
        throw new Error('Unexpected Error');
      }

      // TODO：フロントエンドエラー改善
      if (
        typeof result.data.updateApiKey === 'object' &&
        typeof result.data.updateApiKey.result === 'boolean'
      ) {
        return handleApiResult(result.data.updateApiKey);
      } else {
        throw new Error('Unexpected Error');
      }
    } catch (e) {
      if (e instanceof Error) {
        throw e;
      }

      throw new Error('Unexpected Error');
    }
  };

  return { createApiKey, updateApiKey };
};
