import dayjs from 'dayjs';

import i18n from '../i18n';

/**
 * Format a dateString as  year, month, day
 * @returns en: D MMMM YYYY, ja: YYYY年M月D日
 */
export const formatDate = (date: string): string => {
  return dayjs(date).format('LL');
};

/**
 * Format a dateString as year, month, day, hour, minute
 * @returns  en: D MMMM YYYY HH:mm, ja: YYYY年M月D日 HH:mm
 */
export const formatDateToMinutes = (date: string): string => {
  return dayjs(date).format('LLL');
};

/**
 * Format a dateString as year, month, day, hour, minute, second
 * @returns en: D MMMM YYYY HH:mm:ss, ja: YYYY年M月D日 HH:mm:ss
 */
export const formatDateToSeconds = (date: string): string => {
  return dayjs(date).format('LL') + ' ' + dayjs(date).format('LTS');
};

/**
 * Format a unix as  year, month, day
 * @returns en: D MMMM YYYY, ja: YYYY年M月D日
 */
export const formatUnix = (unixTime: number): string => {
  return dayjs.unix(unixTime).format('LL');
};

/**
 * Format a unix as year, month, day, hour, minute
 * @returns  en: D MMMM YYYY HH:mm, ja: YYYY年M月D日 HH:mm
 */
export const formatUnixToMinutes = (unixTime: number): string => {
  return dayjs.unix(unixTime).format('LLL');
};

/**
 * Format a datePicker by dateFormat and i18n.language
 * @returns (ja) dateFormat === true ? yyyy年MM月dd日 : yyyy年MM月dd日 HH:mm
 * @returns (en) dateFormat === true ? MMMM d, yyyy : MMMM d, yyyy HH:mm
 */
export const formatDatePicker = ({ dateFormat }: { dateFormat: boolean }) => {
  if (i18n.language === 'ja') {
    return dateFormat ? 'yyyy年MM月dd日' : 'yyyy年MM月dd日 HH:mm';
  }
  return dateFormat ? 'MMMM d, yyyy' : 'MMMM d, yyyy HH:mm';
};

/**
 * Format a recharts date
 * @returns (ja) MM月DD日, (en) MMM D
 */
export const formatDateChart = (date: string) => {
  if (i18n.language === 'ja') {
    return dayjs(date).format('MM月DD日');
  }
  return dayjs(date).format('MMM D');
};
