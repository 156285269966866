import { useMemo } from 'react';

import type { Props } from './ReservationModal';

type Args = Pick<Props, 'startDate' | 'stopDate' | 'contentStatus'>;

export const useReservationModal = ({
  startDate,
  stopDate,
  contentStatus,
}: Args) => {
  const canReservationTime =
    // @ts-expect-error
    startDate < stopDate && contentStatus === 'PUBLISH';

  const canReservationStopTime =
    // @ts-expect-error
    startDate > stopDate &&
    (contentStatus === 'DRAFT' || contentStatus === 'CLOSED');

  const disabledReservationTime = useMemo(() => {
    if (canReservationTime) {
      return true;
    }

    if (stopDate && contentStatus === 'PUBLISH') {
      return false;
    }

    return contentStatus === 'PUBLISH';
  }, [canReservationTime, contentStatus, stopDate]);

  const disabledReservationStopTime = useMemo(() => {
    if (canReservationStopTime) {
      return true;
    }

    if (
      startDate &&
      (contentStatus === 'DRAFT' || contentStatus === 'CLOSED')
    ) {
      return false;
    }

    return contentStatus === 'DRAFT' || contentStatus === 'CLOSED';
  }, [canReservationStopTime, contentStatus, startDate]);

  return {
    canReservationTime,
    canReservationStopTime,
    disabledReservationTime,
    disabledReservationStopTime,
  };
};
