import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { formatDatePicker } from '../../../util/date';

import styles from './reservationdatepicker.module.css';

import { DatePicker } from '@/views/Common/Ui/DatePicker';

type Props = {
  date: string | Date;
  inputDate?: Date;
  setInputDate: (date?: Date) => void;
  currentHours: number;
  currentMinutes: number;
  disabled?: boolean;
  readonlyMode?: boolean;
  errorText?: string;

  'aria-labelledby'?: string;
};

const ReservationDatePicker: React.FC<Props> = ({
  date,
  inputDate,
  setInputDate,
  currentHours,
  currentMinutes,
  disabled = false,
  readonlyMode = false,
  errorText,
  'aria-labelledby': ariaLabelledBy,
}) => {
  const { t } = useTranslation('reservationDatePicker');
  const onChangeInputDate = useCallback(
    (date: any) => {
      setInputDate(date);
    },
    [setInputDate],
  );

  const excludeDate = useCallback(
    (date: any, inputDate: any) => {
      if (inputDate === undefined) {
        return date.setHours(currentHours, currentMinutes, 0, 0);
      }

      // 現在の日付と選択した日付が同じ日付の場合（年月日が同一）は、現在の時分を設定する
      const inputDateObj = new Date(inputDate);
      return date.getFullYear() === inputDateObj.getFullYear() &&
        date.getMonth() === inputDateObj.getMonth() &&
        date.getDate() === inputDateObj.getDate()
        ? date.setHours(currentHours, currentMinutes, 0, 0)
        : date.setHours(0, 0, 0, 0);
    },
    [currentHours, currentMinutes],
  );

  return (
    <div className={styles.form}>
      <DatePicker
        reactDatePickerProps={{
          locale: 'ja',
          selected: inputDate,
          onChange: onChangeInputDate,
          showTimeSelect: true,
          minDate: new Date(),
          minTime: excludeDate(date, inputDate),
          maxTime: new Date().setHours(23, 59, 0, 0) as any,
          timeFormat: 'HH:mm',
          timeIntervals: 15,
          dateFormat: formatDatePicker({ dateFormat: false }),
          timeCaption: t('Time'),
          wrapperClassName: styles.inputWrapper,
          disabled: readonlyMode || disabled,
          readOnly: readonlyMode,
          ariaLabelledBy,
        }}
      />
      {(disabled || readonlyMode) && errorText && (
        <p className={styles.errorText}>{errorText}</p>
      )}

      {!readonlyMode && (
        <button
          type="button"
          className={styles.removeButton}
          onClick={() => setInputDate()}
        >
          {t('Reset')}
        </button>
      )}
    </div>
  );
};

export default ReservationDatePicker;
