import * as Popover from '@radix-ui/react-popover';
import cx from 'classnames';
import ReactDatePicker, { CalendarContainer } from 'react-datepicker';

import { useDatePicker } from './useDatePicker';

import type {
  ReactDatePickerProps,
  CalendarContainerProps,
} from 'react-datepicker';

import styles from './datepicker.module.css';

export type Props = {
  reactDatePickerProps: ReactDatePickerProps;
} & React.ComponentPropsWithoutRef<'button'>;

export type ViewProps = Props & {
  inputId: string;
  isOpen: boolean;
  handleOpenChange: NonNullable<Popover.PopoverProps['onOpenChange']>;
  handleTriggerClick: NonNullable<Popover.PopoverTriggerProps['onClick']>;
  handleEscapeKeyDown: NonNullable<
    Popover.PopoverContentProps['onEscapeKeyDown']
  >;
  handleInteractOutside: NonNullable<
    Popover.PopoverContentProps['onInteractOutside']
  >;
};

const CustomCalendarContainer: React.FC<CalendarContainerProps> = ({
  children,
  className,
}) => {
  return (
    <CalendarContainer className={className}>
      {children}
      <div className={styles.arrow}></div>
    </CalendarContainer>
  );
};

export const DatePickerView: React.FC<ViewProps> = ({
  reactDatePickerProps,
  inputId,
  isOpen,
  handleOpenChange,
  handleTriggerClick,
  handleEscapeKeyDown,
  handleInteractOutside,
  ...props
}) => {
  return (
    <Popover.Root open={isOpen} onOpenChange={handleOpenChange}>
      <Popover.Trigger
        {...props}
        asChild
        onClick={(e) => {
          handleTriggerClick(e);
          props.onClick?.(e);
        }}
        disabled={reactDatePickerProps.disabled}
      >
        <div role="button">
          <ReactDatePicker
            {...reactDatePickerProps}
            id={inputId}
            className={cx(styles.input, reactDatePickerProps.className)}
            popperClassName={styles.hidden}
          />
        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className={styles.popoverContent}
          align="start"
          sideOffset={5}
          style={{ zIndex: 1000, pointerEvents: 'auto' }}
          onEscapeKeyDown={handleEscapeKeyDown}
          onInteractOutside={handleInteractOutside}
        >
          <ReactDatePicker
            {...reactDatePickerProps}
            inline
            calendarContainer={CustomCalendarContainer}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export const DatePicker: React.FC<Props> = (props) => {
  const hooks = useDatePicker(props);

  return <DatePickerView {...props} {...hooks} />;
};
