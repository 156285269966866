import cx from 'classnames';
import deepEqual from 'deep-equal';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { PromptGroup } from './PromptGroup';
import { useContentActions } from './useContentActions';
import { useContentOperationOnContentActions } from './useContentOperationOnContentActions';
import {
  useCreateContent,
  useEditContent,
  useDeleteDraft,
  useDeleteContent,
  useSetReservationTime,
  useUpdateContentStatus,
} from '../../hooks/Content/useContentWriter';
import Tooltip from '../Tooltip';
import Button from '../ui/Button';
import ContentStatus from '../ui/ContentStatus';
import IconWithTextButton from '../ui/IconWithTextButton';
import { validateRequiredFiled } from '../Validations';

import type { Props } from './';
import type { ContentStatus as Status } from '@/entity/content';
import type { Behaviour } from '@/types/customState';

import styles from './contentActions.module.css';

import { Icon } from '@/views/Common/Ui/Icon';
import { ReservationModal } from '@/views/ContentActions/ReservationModal';
import { ReservationTrigger } from '@/views/ContentActions/ReservationTrigger';

type ViewProps = Props & {
  hasCreateReview: boolean;
};

export const ContentActionsView: React.FC<ViewProps> = ({
  api,
  endpoint,
  contentId,
  getContent,
  contentStatus,
  data,
  contentDraftKey,
  reservationTime,
  reservationStopTime,
  history,
  customFields,
  partitionKey,
  validateOnBeforePublish,
  setHistory,
  hasCreateReview,
  isEditing,
  customStatus,
  contentCustomStatusIds,
  currentPlan,
  openedReviewRequestAccessInfo,
  openedReviewRequestAccessInfoIsLoading,
}) => {
  const { t } = useTranslation('contentActions');
  const { addToast } = useToasts();

  // TODO:このHooksの処理は下層のコンポーネントを作成して移動する
  const { historyPushStateContentId } = useContentActions(api);

  const underReviewing = useMemo(() => {
    // ReviewRequestAccessInfoが不明な場合(取得中)
    if (openedReviewRequestAccessInfo === undefined) {
      return undefined;
    }

    // ReviewRequestAccessInfoが存在しない場合(コンテンツ新規作成時)
    if (openedReviewRequestAccessInfo === null) {
      return false;
    }

    // ReviewRequestAccessInfoが存在する場合(コンテンツ編集時)
    return openedReviewRequestAccessInfo.reqId != null;
  }, [openedReviewRequestAccessInfo]);

  const {
    contentCopyable,
    contentDeletable,
    canRevertToDraft,
    canBeReturnedToClosed,
    draftDeletable,
    canScheduleContent,
    canSavePublish,
    canSaveDraft,
  } = useContentOperationOnContentActions({
    apiId: api.partitionKey,
    contentStatus,
    underReviewing,
    isEditing,
  });

  const [tryingToSave, setTryingToSave] = useState(false);
  const { setReservationTime, loading } = useSetReservationTime(partitionKey);
  const [startDate, setStartDate] = useState(
    reservationTime ? new Date(reservationTime) : undefined,
  );
  const [stopDate, setStopDate] = useState(
    reservationStopTime ? new Date(reservationStopTime) : undefined,
  );
  const [ReservationModalWrapper, openReservationModal, closeReservationModal] =
    useModal('root');

  const [create, createLoading] = useCreateContent(api);

  const [update, updateResult, updateLoading] = useEditContent({
    setHistory,
    reviewRequestId: openedReviewRequestAccessInfo?.reqId,
  });

  const [deleteDraft, deleteDraftLoading] = useDeleteDraft();

  const [deleteContent, deleteContentResult, deleteContentLoading] =
    useDeleteContent();

  const [updateContentStatus] = useUpdateContentStatus();

  useEffect(() => {
    if (updateResult) {
      setTryingToSave(false);
    }
  }, [updateResult]);

  useEffect(() => {
    if (deleteContentResult) {
      setTryingToSave(false);
    }
  }, [deleteContentResult]);

  const doUpdateContentStatus = useCallback(
    (customStatusId: string, behaviour: Behaviour) => () => {
      // @ts-expect-error
      updateContentStatus({ partitionKey, customStatusId, behaviour });
    },
    [updateContentStatus, partitionKey],
  );

  const doPostOrPutValues = useCallback(
    (status: Status) => () => {
      const hasValidationError = validateOnBeforePublish();
      if (hasValidationError) {
        addToast(t('Some input fields need to be corrected'), {
          appearance: 'error',
        });
        return;
      }

      const requiredFieldNotFilledMessage = validateRequiredFiled(
        api.apiFields,
        {
          ...data,
          ...getContent(),
        },
        customFields,
      );
      if (requiredFieldNotFilledMessage) {
        addToast(requiredFieldNotFilledMessage, {
          appearance: 'error',
        });
        return;
      }

      setTryingToSave(true);
      if (isEditing) {
        // @ts-expect-error
        update(partitionKey, { ...data, ...getContent() }, status);
      } else {
        create(contentId, { ...data, ...getContent() }, status).then(
          (succeeded) => {
            if (succeeded) {
              // NOTE: 新規作成後にコンテンツ詳細画面へ遷移するため、離脱防止フラグを解除
              setTryingToSave(false);

              // NOTE: 新規作成時に作成したコンテンツ詳細画面に遷移
              history.replace(`/apis/${endpoint}/${contentId}`);
              // NOTE: ページ遷移を挟んでトースト表示させると、瞬時に閉じてしまうため、setTimeoutで遅延させてからaddToastを実行
              setTimeout(() => {
                addToast(t('The content has been created'), {
                  appearance: 'success',
                });
              }, 100);
            }
          },
        );
      }
    },
    [
      validateOnBeforePublish,
      api.apiFields,
      data,
      getContent,
      customFields,
      isEditing,
      t,
      update,
      partitionKey,
      create,
      contentId,
      addToast,
      endpoint,
      history,
    ],
  );

  const historyBack = useCallback(() => {
    if (history.action === 'POP') {
      history.push(`/apis/${endpoint}`);
    } else {
      history.goBack();
    }
  }, [endpoint, history]);

  const doDeleteContent = useCallback(() => {
    const allowToDelete = window.confirm(
      t(
        'This cannot be recovered once deleted. Are you sure you want to delete it?',
      ),
    );
    if (allowToDelete) {
      setTryingToSave(true);
      // @ts-expect-error
      deleteContent(partitionKey);
    }
  }, [deleteContent, partitionKey, t]);

  const doDeleteDraft = useCallback(() => {
    const confirmResult = window.confirm(
      t(
        'This cannot be recovered once discarded. Are you sure you want to discard the changes?',
      ),
    );
    if (!confirmResult) {
      return;
    }
    // @ts-expect-error
    deleteDraft(partitionKey);
  }, [deleteDraft, partitionKey, t]);

  const doPreview = useCallback(
    async (e: any) => {
      if (api.apiPreviewUrl) {
        const url = api.apiPreviewUrl
          .replace('{CONTENT_ID}', contentId)
          .replace('{DRAFT_KEY}', contentDraftKey || '');

        // 画面プレビューの際に編集中のコンテンツがある場合は保存を促す
        const needSave =
          !deepEqual(getContent(), data) &&
          window.confirm(
            t(
              'Your changes have not been saved. Do you want to save the changes and then preview?',
            ),
          );

        if (needSave) {
          const newStatus = contentStatus?.includes('PUBLISH')
            ? 'PUBLISH_AND_DRAFT'
            : 'DRAFT';
          doPostOrPutValues(newStatus)();
        }
        const delayTime = needSave ? 1000 : 0;
        setTimeout(() => {
          window.open(url);
        }, delayTime);
        e.preventDefault();

        //Analytics
        window.ga && window.ga('send', 'event', 'preview', 'show', url);

        return;
      }
      window.alert(t('Page preview has not been configured.'));
      e.preventDefault();
    },
    [
      api.apiPreviewUrl,
      t,
      contentId,
      contentDraftKey,
      getContent,
      data,
      contentStatus,
      doPostOrPutValues,
    ],
  );

  const setReservation = useCallback(() => {
    setReservationTime(startDate, stopDate);
    closeReservationModal();
  }, [setReservationTime, startDate, stopDate, closeReservationModal]);

  // 予約日時の設定によっては公開ボタンを押せない
  const isDisablePublish =
    (reservationTime && !reservationStopTime) ||
    (reservationTime &&
      reservationStopTime &&
      Date.parse(reservationStopTime) > Date.parse(reservationTime));

  const stopPropagation = useCallback((e: any) => {
    e.stopPropagation();
  }, []);

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();

    const handleKeyUp = (e: any) => {
      // cmd+shift+Sで公開
      if (
        ((userAgent.indexOf('windows nt') > -1 && e.ctrlKey) ||
          (userAgent.indexOf('mac os x') > -1 && e.metaKey)) &&
        e.shiftKey &&
        e.keyCode === 83
      ) {
        if (canSavePublish) {
          doPostOrPutValues('PUBLISH')();
        }
        e.preventDefault();
      } else if (
        // cmd+Sで下書き保存
        ((userAgent.indexOf('windows nt') > -1 && e.ctrlKey) ||
          (userAgent.indexOf('mac os x') > -1 && e.metaKey)) &&
        e.keyCode === 83
      ) {
        if (canSaveDraft) {
          doPostOrPutValues(
            contentStatus && contentStatus.includes('PUBLISH')
              ? 'PUBLISH_AND_DRAFT'
              : 'DRAFT',
          )();
        }
        e.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyUp);
    };
  }, [contentStatus, doPostOrPutValues, canSavePublish, canSaveDraft]);

  if (underReviewing === undefined) {
    return <div className={styles.actions} />;
  }

  if (openedReviewRequestAccessInfoIsLoading) {
    return <div className={styles.actions} />;
  }

  if (deleteContentResult) {
    return <Redirect to={`/apis/${endpoint}`} />;
  }

  return (
    <div className={styles.actions}>
      <PromptGroup
        getContent={getContent}
        data={data}
        tryingToSave={tryingToSave}
      />
      <div className={styles.statuses}>
        {isEditing && api.apiType === 'LIST' && (
          <button
            className={styles.backButton}
            onClick={historyBack}
            aria-label={t('Back')}
          >
            <Icon name="arrow_back_ios" size="large" outlined />
          </button>
        )}

        {/* NOTE: コンテンツのステータス表示は新規作成時では表示されない仕様なので、コンテンツのステータスが存在する場合にのみ表示 */}
        {contentStatus && (
          <ContentStatus
            status={contentStatus}
            reservationTime={reservationTime}
            reservationStopTime={reservationStopTime}
            reviewRequestId={openedReviewRequestAccessInfo?.reqId}
            reviewLink={true}
            customStatus={customStatus}
            contentCustomStatusIds={contentCustomStatusIds}
            // @ts-expect-error
            doUpdateContentStatus={doUpdateContentStatus}
            isEditing={currentPlan?.limit?.customStatusCount > 0}
            apiId={api.partitionKey}
            hasReadReviewRequestPermission={
              openedReviewRequestAccessInfo?.readable
            }
          />
        )}

        {draftDeletable && !isDisablePublish && (
          <IconWithTextButton
            className={styles.statusActionButton}
            text={t('Discard Draft')}
            icon="delete"
            outlined={true}
            onClick={doDeleteDraft}
            // @ts-expect-error
            disabled={deleteDraftLoading}
          />
        )}
        {/* NOTE: コンテンツステータス表示部分での「下書きに戻す」は公開終了の時のみ表示する */}
        {contentStatus === 'CLOSED' &&
          canRevertToDraft &&
          !reservationStopTime && (
            <IconWithTextButton
              className={styles.statusActionButton}
              text={t('Change to Draft')}
              icon="undo"
              outlined={true}
              onClick={doPostOrPutValues('DRAFT')}
            />
          )}
        {canBeReturnedToClosed && !reservationStopTime && (
          <IconWithTextButton
            className={styles.statusActionButton}
            text={t('Change to Unpublished')}
            icon="stop_circle"
            outlined={true}
            onClick={doPostOrPutValues('CLOSED')}
          />
        )}
      </div>
      <div className={styles.buttons}>
        {hasCreateReview &&
          !reservationTime &&
          isEditing &&
          !underReviewing &&
          contentStatus !== 'PUBLISH' &&
          contentStatus !== 'CLOSED' &&
          partitionKey && (
            <Link
              className={styles.linkButton}
              to={`/reviews/create/${partitionKey.replace('CONTENT#', '')}`}
            >
              <i className="material-icons-outlined">question_answer</i>
              <span className={styles.pcOnly}>{t('Request Review')}</span>
            </Link>
          )}
        {isEditing &&
          (api.apiPreviewLinkIsVisible ||
            api.apiPreviewLinkIsVisible === null) && (
            <Link
              className={styles.linkButton}
              onClick={doPreview}
              to={`/apis/${endpoint}/settings/preview`}
            >
              <i className="material-icons-outlined">open_in_browser</i>
              <span className={styles.pcOnly}>{t('Page Preview')}</span>
            </Link>
          )}
        {isEditing && (
          <Tooltip
            trigger={
              <button
                className={styles.linkButton}
                aria-label={t('Additional actions')}
              >
                <Icon name="more_horiz" />
              </button>
            }
          >
            <>
              <ul className={styles.tooltip}>
                {api.apiType === 'LIST' && (
                  <li className={styles.tooltipList}>
                    <button
                      className={cx(
                        styles.tooltipButton,
                        !contentCopyable && styles.disabled,
                        'track-click-by-gtm',
                      )}
                      data-gtm-track-event-name="click_duplicate_content"
                      onClick={() => historyPushStateContentId({ contentId })}
                      disabled={!contentCopyable}
                    >
                      <i className="material-icons">content_copy</i>
                      <dl>
                        <dt className={styles.tooltipListTitle}>
                          {t('Copy content and create new content')}
                        </dt>
                        <dd className={styles.tooltipListDescription}>
                          {t('Create a new content with the same content.')}
                        </dd>
                      </dl>
                    </button>
                  </li>
                )}
                <li className={styles.tooltipList}>
                  <ReservationTrigger
                    openReservationModal={openReservationModal}
                    contentReservable={canScheduleContent}
                  />
                </li>
                {api.apiType === 'LIST' && (
                  <li className={styles.tooltipList}>
                    <button
                      className={cx(
                        styles.tooltipButton,
                        !contentDeletable && styles.disabled,
                      )}
                      onClick={
                        contentDeletable ? doDeleteContent : stopPropagation
                      }
                      // @ts-expect-error
                      disabled={!contentDeletable || deleteContentLoading}
                    >
                      <i className="material-icons-outlined">delete</i>
                      <dl>
                        <dt className={styles.tooltipListTitle}>
                          {t('Delete')}
                        </dt>
                        <dd className={styles.tooltipListDescription}>
                          {t('Delete this content')}
                        </dd>
                      </dl>
                    </button>
                  </li>
                )}
              </ul>
              <ul className={styles.tooltip}>
                <li className={styles.tooltipList}>
                  <button
                    className={cx(
                      styles.tooltipButton,
                      !canBeReturnedToClosed && styles.disabled,
                    )}
                    disabled={!canBeReturnedToClosed}
                    onClick={
                      canBeReturnedToClosed
                        ? doPostOrPutValues('CLOSED')
                        : stopPropagation
                    }
                  >
                    <i className="material-icons-outlined">stop_circle</i>
                    <dl>
                      <dt className={styles.tooltipListTitle}>
                        {t('Unpublish')}
                      </dt>
                      <dd className={styles.tooltipListDescription}>
                        {t('Unpublish this content.')}
                      </dd>
                    </dl>
                  </button>
                </li>
                <li className={styles.tooltipList}>
                  <button
                    className={cx(
                      styles.tooltipButton,
                      !canRevertToDraft && styles.disabled,
                    )}
                    disabled={!canRevertToDraft}
                    onClick={
                      canRevertToDraft
                        ? doPostOrPutValues('DRAFT')
                        : stopPropagation
                    }
                  >
                    <i className="material-icons-outlined">undo</i>
                    <dl>
                      <dt className={styles.tooltipListTitle}>
                        {t('Change to Draft')}
                      </dt>
                      <dd className={styles.tooltipListDescription}>
                        {t('Change this content to Draft')}
                      </dd>
                    </dl>
                  </button>
                </li>
                <li className={styles.tooltipList}>
                  <button
                    className={cx(
                      styles.tooltipButton,
                      !draftDeletable && styles.disabled,
                    )}
                    onClick={draftDeletable ? doDeleteDraft : stopPropagation}
                    disabled={!draftDeletable}
                  >
                    <i className="material-icons-outlined">delete</i>
                    <dl>
                      <dt className={styles.tooltipListTitle}>
                        {t('Discard Draft')}
                      </dt>
                      <dd className={styles.tooltipListDescription}>
                        {t('Discard Draft and Publish')}
                      </dd>
                    </dl>
                  </button>
                </li>
              </ul>
            </>
          </Tooltip>
        )}
        {canSaveDraft && (
          <Button
            className="ga-content-save"
            onClick={doPostOrPutValues(
              contentStatus &&
                (contentStatus.includes('PUBLISH') ||
                  contentStatus === 'CLOSED')
                ? 'PUBLISH_AND_DRAFT'
                : 'DRAFT',
            )}
            disabled={
              createLoading || updateLoading || contentStatus === 'CLOSED'
            }
            type="tertiary"
            value={
              contentStatus &&
              (contentStatus === 'PUBLISH' || contentStatus === 'CLOSED')
                ? t('Add Draft')
                : t('Save as Draft')
            }
          ></Button>
        )}
        {canSavePublish && (
          <Button
            className="ga-content-publish"
            style={{ marginLeft: 10 }}
            onClick={doPostOrPutValues('PUBLISH')}
            disabled={createLoading || updateLoading || isDisablePublish}
            value={
              isDisablePublish
                ? t('Scheduled to Publish')
                : contentStatus === 'CLOSED'
                  ? t('Republish')
                  : t('Publish')
            }
          ></Button>
        )}
      </div>

      {/* NOTE: 予約は新規作成時はできないので、ステータスが存在する場合のみ実行できる操作である */}
      {contentStatus && (
        <ReservationModalWrapper>
          <ReservationModal
            contentStatus={contentStatus}
            apiId={api.partitionKey}
            startDate={startDate}
            stopDate={stopDate}
            setStartDate={setStartDate}
            setStopDate={setStopDate}
            setReservation={setReservation}
            setReservationLoading={loading}
          />
        </ReservationModalWrapper>
      )}
    </div>
  );
};
