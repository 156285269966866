import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useMemo, useCallback, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { MICROCMS_API_KEY, getPureHost } from '../../util';

export const useReleaseAnnouncement = (
  openReleaseAnnounceModal: () => void,
  contentsCount = 0,
) => {
  const { t } = useTranslation('hooksAnnouncement');
  const [cookies, setCookie] = useCookies([
    'usage#latestOpenReleaseAnnouncement',
  ]);
  const getAnnouncementQuery = useQuery({
    queryKey: ['releaseAnnouncement'],
    queryFn: () => {
      const endpoint =
        'https://microcms.microcms.io/api/v1/news?limit=1&filters=pickup[equals]true&id,title,publishedAt,content,blog.id,blog.title,blog.ogimage';
      return fetch(endpoint, {
        headers: { 'X-MICROCMS-API-KEY': MICROCMS_API_KEY },
      }).then((res) => {
        if (!res.ok) {
          throw new Error(t('Could not retrieve notification'));
        }
        return res.json();
      });
    },
    staleTime: Number.POSITIVE_INFINITY,
  });

  const {
    data: announcement,
    error: announcementError,
    isLoading: announcementLoading,
  } = getAnnouncementQuery;

  const openAnnouncement = useCallback(() => {
    setCookie('usage#latestOpenReleaseAnnouncement', new Date().toISOString(), {
      path: '/',
      domain: getPureHost(),
      secure: window.location.protocol === 'https',
      expires: dayjs().add(20, 'year').toDate(),
    });
    openReleaseAnnounceModal();
  }, [openReleaseAnnounceModal, setCookie]);

  // 最新のニュースがあるかどうか判定
  // - 7日前までのピックアップNewsを表示する
  // - 全サービス横断で一度でも表示されていたら表示しない
  const hasLatestAnnouncement = useMemo(() => {
    const oneWeekAgoDate = dayjs().subtract(7, 'day').toISOString();
    const latestOpenDate =
      cookies['usage#latestOpenReleaseAnnouncement'] ||
      new Date(0).toISOString();
    const pickupNewsDate = announcement?.contents?.[0]?.publishedAt;
    if (pickupNewsDate > latestOpenDate && pickupNewsDate > oneWeekAgoDate) {
      return true;
    }
    return false;
  }, [announcement, cookies]);

  useEffect(() => {
    // ある程度利用してくれているユーザーに情報を届けるために、コンテンツ数が10件以上ある場合にのみ表示する
    if (hasLatestAnnouncement && contentsCount >= 10) {
      openAnnouncement();
    }
  }, [hasLatestAnnouncement, openAnnouncement, contentsCount]);

  return {
    announcement: announcement?.contents?.[0],
    announcementError,
    announcementLoading,
  };
};
