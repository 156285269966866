import { connect } from 'react-redux';

import { accountSelectors } from '../../ducks/account';
import Main from './Main';

type Props = {
  accountState: any;
};

const mapStateToProps = ({ accountState }: Props) => {
  return {
    ...accountSelectors.getAccountState(accountState),
  };
};

export default connect(mapStateToProps)(Main);
