import { useEffect, useRef, useState } from 'react';

import type { NodeViewProps } from '@tiptap/core';

type Args = {
  editor: NodeViewProps['editor'];
  isActive: boolean;
  currentPosition: number;
  isOpen: boolean;
};

export const useKeyEventBlock = ({
  editor,
  isActive,
  currentPosition,
  isOpen,
}: Args) => {
  const blockRef = useRef<HTMLButtonElement>(null);

  const [isBlockFocus, setIsBlockFocus] = useState<boolean>(false);
  const [isButtonFocus, setIsButtonFocus] = useState<boolean>(false);

  // NOTE:extensionsにイベントハンドラーを渡すことができないため、DOMイベントを直接生成している
  useEffect(() => {
    const richEditorWrapper = document.getElementById(
      'richEditor-v2-editorWrapper',
    );
    richEditorWrapper?.addEventListener('click', () => {
      setIsBlockFocus(false);
    });

    return () => {
      richEditorWrapper?.removeEventListener('click', () => {
        setIsBlockFocus(false);
      });
    };
  }, []);

  useEffect(() => {
    setIsBlockFocus(isActive);
    return () => {
      setIsBlockFocus(false);
    };
  }, [isActive]);

  useEffect(() => {
    if (isButtonFocus) {
      blockRef.current?.focus();
    }
  }, [isButtonFocus]);

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (isOpen) {
        return;
      }

      if (!editor.isFocused && blockRef.current !== document.activeElement) {
        return;
      }

      if (e.code === 'ArrowRight' && isBlockFocus) {
        setIsBlockFocus(true);
        blockRef.current?.focus();
        return;
      }

      if (e.code === 'ArrowLeft' && isBlockFocus) {
        e.preventDefault();
        blockRef.current?.blur();
        editor.view.focus();
        setIsBlockFocus(false);
        setIsButtonFocus(false);
        return editor.chain().focus().setNodeSelection(currentPosition).run();
      }

      if (e.code === 'Backspace' && isBlockFocus) {
        e.preventDefault();
        editor.chain().focus().setNodeSelection(currentPosition).run();
        return editor.chain().focus().deleteSelection().run();
      }
    };

    window.addEventListener('keydown', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyUp);
    };
  }, [currentPosition, editor, isActive, isBlockFocus, isOpen]);

  const isCurrentActiveBlock = editor.isFocused && isBlockFocus;

  return { blockRef, isCurrentActiveBlock, setIsButtonFocus };
};
