import type React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetMyService } from '@/hooks/useService';

import { useFieldTypes } from '../../constants/useFieldTypes';
import { useCustomFields } from '../../hooks/CustomField/useCustomFieldReader';
import {
  validateApiFieldId,
  validateApiFieldKind,
  validateApiFieldName,
  validateApiFieldReferenceKey,
} from '../Validations';
import Fieldset from '../ui/Fieldset';
import IconWithTextButton from '../ui/IconWithTextButton';
import SelectButton from '../ui/SelectButton';
import Switch from '../ui/Switch';
import Textfield from '../ui/Textfield';
import DetailFieldSettings from './DetailFieldSettings';
import { InputIframeFieldInModal } from './InputIframeField';
import { InputSelectFieldInModal } from './InputSelectField';
import { SelectCustomFieldInModal } from './SelectCustomField';
import SelectKind from './SelectKind';
import SelectRelationInModal from './SelectRelationInModal';
import { SelectRepeaterFieldInModal } from './SelectRepeaterField';
import { useChangeKind } from './useChangeKind';

import type { Api, ApiList, ApiListByRedux, MigrateApi } from '@/entity/api';
import type { Field } from '@/types/field';

import styles from './apiModel.module.css';

import { apiListSelectors } from '@/ducks/apiList';
import { useAppSelector } from '@/store/hooks';
import { ObjectValues } from '@/util/assertions';
import { Popover } from '@/views/ApiModel/Popover';
import { Modal, ModalContent } from '@/views/Common/Ui/Modal';
import {
  SideModal,
  SideModalContent,
  SideModalTrigger,
} from '@/views/Common/Ui/SideModal';

type Props = {
  api: MigrateApi;
  useInCustom?: boolean;
  allowCustomField?: boolean;
  allowPublishedAt?: boolean;
  field: Field;
  allFields: any[];
  setFieldAt: (field: (prev: any) => void, index: number) => void;
  index: number;
  swap: (x: number, y: number) => void;
  deleteField: (index: number) => void;
  apiList?: Api[];
  readOnly?: boolean;
  type: 'LIST' | 'PAGE';
  isEditApi?: boolean;
};

const ApiModel: React.FC<Props> = ({
  api,
  useInCustom = false,
  allowCustomField = true,
  allowPublishedAt = false,
  field,
  allFields,
  setFieldAt,
  index,
  swap,
  deleteField,
  readOnly = false,
  type,
  isEditApi = false,
}) => {
  const { t } = useTranslation('apiModel');
  const { service } = useGetMyService();

  // TODO: ReduxをReactQueryに置き換える
  const { apiList } = useAppSelector((state) =>
    apiListSelectors.get(state.apiListState as ApiList),
  ) as ApiListByRedux;

  const {
    idValue,
    fieldId,
    name,
    kind,
    required = false,
    selectItems,
    selectInitialValue,
    multipleSelect = false,
    referenceKey,
    iframeUrl,
    customFieldCreatedAt,
    customFieldCreatedAtList,
    isAdditionalField,
  } = field;

  const [customFields = []] = useCustomFields(api && api.partitionKey);
  const [fieldIdError, setFieldIdError] = useState<string | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);
  const [kindError, setKindError] = useState<string | null>(null);
  const [isDetailFieldSettingsModalOpen, setIsDetailFieldSettingsModalOpen] =
    useState(false); // 詳細のopenフラグ
  const [modalOpen, setModalOpen] = useState(false);
  const [relationKind, setRelationKind] = useState<
    'relation' | 'relationList' | undefined
  >();
  const [isDragOver, setIsDragOver] = useState(false);
  const relationContentRef = useRef<HTMLSelectElement | null>(null);

  const {
    additionalSettings,
    additionalSettingsReturnFocusRef,
    closeAdditionalSettingsModal,
    handleChangeKind,
  } = useChangeKind();

  const referenceKeyError = validateApiFieldReferenceKey(kind, referenceKey);

  const placeholder = useMemo(() => {
    const list = [
      {
        fieldId: 'title',
        name: t('Title'),
        placeholder: t('Enter up to 30 characters'),
      },
      {
        fieldId: 'link',
        name: t('Destination URL'),
        placeholder: t('Enter up to 100 characters'),
      },
      {
        fieldId: 'body',
        name: t('Body'),
        placeholder: t('Enter the text of your blog post'),
      },
      {
        fieldId: 'content',
        name: t('Contents'),
        placeholder: t('Enter your campaign details'),
      },
      {
        fieldId: 'profileImage',
        name: t('Profile Image'),
        placeholder: t('Select a 240x240 sized image'),
      },
      {
        fieldId: 'mainVisual',
        name: t('Main Visual'),
        placeholder: t('Select a 1160x240 sized image'),
      },
      {
        fieldId: 'eventDate',
        name: t('Date of the event'),
        placeholder: t('Enter the date of your event'),
      },
    ];
    return index === 0
      ? list[0]
      : list[Math.floor(Math.random() * list.length)];
  }, [index, t]);

  const fieldTypes = useFieldTypes();
  // fieldIdの重複判定のため、自身以外のfieldIdの配列を作成
  const otherFieldIds = useMemo(
    () =>
      allFields
        ?.filter((f) => f.idValue !== idValue)
        .map((otherField) => otherField.fieldId),
    [allFields, idValue],
  );

  const types = useMemo(() => {
    return useInCustom === true
      ? ObjectValues(fieldTypes).filter(
          (obj) => obj.can_use_in_custom !== false,
        )
      : ObjectValues(fieldTypes); // カスタムフィールド、繰り返しフィールドの入れ子はできないようにする
  }, [fieldTypes, useInCustom]);

  const openDetailFieldSettingsModal = useCallback(() => {
    setIsDetailFieldSettingsModalOpen(true);
  }, []);

  const closeDetailFieldSettingsModal = useCallback((enable = true) => {
    if (enable) {
      setIsDetailFieldSettingsModalOpen(false);
    }
  }, []);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const onTypeClick = useCallback(
    (e: any) => {
      e.preventDefault();
      openModal();
    },
    [openModal],
  );

  useEffect(() => {
    setFieldIdError(
      validateApiFieldId(fieldId, otherFieldIds, allowPublishedAt),
    );
  }, [allowPublishedAt, otherFieldIds, fieldId]);
  useEffect(() => {
    setNameError(validateApiFieldName(name));
  }, [name]);
  useEffect(() => {
    setKindError(validateApiFieldKind(kind));
  }, [kind]);
  useEffect(() => {
    setIsDetailFieldSettingsModalOpen(false); // 並び替え時に設定は閉じる
  }, [idValue]);

  const onChangeFieldId = useCallback(
    (e: any) => {
      const fieldId = e.target.value;
      setFieldAt(
        (prev) => ({
          ...prev,
          fieldId,
        }),
        index,
      );
    },
    [index, setFieldAt],
  );

  const onChangeName = useCallback(
    (e: any) => {
      const name = e.target.value;
      setFieldAt(
        (prev) => ({
          ...prev,
          name,
        }),
        index,
      );
    },
    [setFieldAt, index],
  );

  const onChangeKind = useCallback(
    (
      e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
    ) => {
      const kind = e.currentTarget.getAttribute('data-id');
      closeModal();
      if (kind === 'relation' || kind === 'relationList') {
        handleChangeKind(kind);
        setRelationKind(kind);
      } else if (
        kind === 'select' ||
        kind === 'iframe' ||
        kind === 'custom' ||
        kind === 'repeater'
      ) {
        handleChangeKind(kind);
      } else {
        setRelationKind(undefined);
        setFieldAt(
          (prev) => ({
            ...prev,
            kind,
          }),
          index,
        );
      }
    },
    [closeModal, handleChangeKind, setFieldAt, index],
  );

  const onChangeRequired = useCallback(
    (required: any) => {
      setFieldAt(
        (prev) => ({
          ...prev,
          required,
        }),
        index,
      );
    },
    [setFieldAt, index],
  );

  const onChangeReference = useCallback(
    (e: any) => {
      const referenceKey = e.target.value;
      setFieldAt(
        (prev) => ({
          ...prev,
          referenceKey,
          referenceDisplayItem: '',
        }),
        index,
      );
    },
    [setFieldAt, index],
  );

  const onDragStart = useCallback((e: any) => {
    e.dataTransfer.setData('index', e.target.getAttribute('data-index'));
  }, []);

  const onDragOver = useCallback((e: any) => {
    e.preventDefault();
    setIsDragOver(true);
  }, []);

  const onDragLeave = useCallback(() => {
    setIsDragOver(false);
  }, []);

  const onDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      const index = e.dataTransfer.getData('index');
      const targetIndex = e.currentTarget.getAttribute('data-index');

      if (!index || !targetIndex) return;

      setIsDragOver(false);
      swap(Number.parseInt(index), Number.parseInt(targetIndex));
    },
    [swap],
  );

  const setRelation = useCallback(() => {
    closeAdditionalSettingsModal();
    setFieldAt(
      (prev) => ({
        ...prev,
        kind: relationKind,

        referenceKey:
          relationContentRef &&
          relationContentRef.current &&
          relationContentRef.current.value,
      }),
      index,
    );
    setRelationKind(undefined);
  }, [closeAdditionalSettingsModal, setFieldAt, index, relationKind]);

  const setSelectField = useCallback(
    (items: any, multiple = false, initialValue: string[]) => {
      closeAdditionalSettingsModal();
      setFieldAt(
        (prev) => ({
          ...prev,
          kind: 'select',
          selectItems: items,
          multipleSelect: multiple,
          selectInitialValue: initialValue,
        }),
        index,
      );
    },
    [closeAdditionalSettingsModal, setFieldAt, index],
  );

  const setIframe = useCallback(
    (value: any) => {
      closeAdditionalSettingsModal();
      setFieldAt(
        (prev) => ({
          ...prev,
          kind: 'iframe',
          iframeUrl: value,
        }),
        index,
      );
    },
    [closeAdditionalSettingsModal, setFieldAt, index],
  );

  const setCustom = useCallback(
    (value: any) => {
      closeAdditionalSettingsModal();
      setFieldAt(
        (prev) => ({
          ...prev,
          kind: 'custom',
          customFieldCreatedAt: value,
        }),
        index,
      );
    },
    [closeAdditionalSettingsModal, setFieldAt, index],
  );

  const setRepeater = useCallback(
    (value: any) => {
      closeAdditionalSettingsModal();
      setFieldAt(
        (prev) => ({
          ...prev,
          kind: 'repeater',
          customFieldCreatedAtList: value,
        }),
        index,
      );
    },
    [closeAdditionalSettingsModal, setFieldAt, index],
  );

  const referenceName = useMemo(
    () =>
      apiList &&
      referenceKey &&
      apiList.find((api) => api.partitionKey === referenceKey)?.apiName,
    [apiList, referenceKey],
  );

  const customName = useMemo(
    () =>
      customFields &&
      customFieldCreatedAt &&
      customFields.find((f) => f.createdAt === customFieldCreatedAt)?.name,
    [customFields, customFieldCreatedAt],
  );

  const isSelectButtonEnabled = !isAdditionalField;

  if (!service) return null;

  return (
    <>
      <div
        className={
          isDragOver ? `${styles.wrapper} ${styles.isDragOver}` : styles.wrapper
        }
        draggable
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        data-index={index}
        data-testid="api-model"
      >
        <p className={styles.idValue} data-testid="api-model-id-value">
          {idValue}
        </p>
        <i className={`material-icons ${styles.handle}`}>drag_handle</i>
        <div className={styles.row}>
          <Fieldset legend={t('Field ID')} className={styles.fieldItem}>
            <Textfield
              type="text"
              value={fieldId || ''}
              placeholder={t('E.g., {{fieldId}}', {
                fieldId: placeholder.fieldId,
              })}
              onChange={onChangeFieldId}
              errorText={fieldIdError}
              readOnly={readOnly}
              dataTestid={`api-model-fieldId`}
            />
          </Fieldset>
          <Fieldset legend={t('Display Name')} className={styles.fieldItem}>
            <Textfield
              type="text"
              value={name || ''}
              placeholder={t('E.g., {{name}}', {
                name: placeholder.name,
              })}
              onChange={onChangeName}
              errorText={nameError}
              readOnly={readOnly}
              dataTestid={`api-model-displayName`}
            />
          </Fieldset>
          <Fieldset legend={t('Type')} className={styles.fieldItem}>
            <SideModal open={modalOpen} onClose={closeModal}>
              <Popover
                triggerSize="full"
                enabled={isSelectButtonEnabled}
                contentPosition={{
                  side: 'top',
                  align: 'center',
                }}
                content={
                  <p>
                    {t(
                      'Cannot change field type. If you want to change it, delete the field and recreate it.',
                    )}
                  </p>
                }
              >
                <span tabIndex={-1}>
                  <SideModalTrigger asChild>
                    <SelectButton
                      ref={additionalSettingsReturnFocusRef}
                      style={{
                        pointerEvents: isSelectButtonEnabled ? 'none' : 'all',
                      }}
                      value={
                        kind
                          ? `${types.find((type) => type.id === kind)?.name}${
                              referenceName &&
                              (kind === 'relation' || kind === 'relationList')
                                ? ` - ${referenceName}`
                                : kind === 'custom'
                                  ? ` - ${customName || ''}`
                                  : kind === 'repeater'
                                    ? t('- {{count}} fields', {
                                        count: customFieldCreatedAtList?.length,
                                      })
                                    : ''
                            }`
                          : t('Unselected')
                      }
                      disabled={isSelectButtonEnabled}
                      onClick={!readOnly ? onTypeClick : undefined}
                      errorText={kindError ?? referenceKeyError}
                    />
                  </SideModalTrigger>
                </span>
              </Popover>
              <SideModalContent hasSpace={false}>
                <SelectKind
                  service={service}
                  isOpen={modalOpen}
                  allowCustomField={allowCustomField}
                  types={types}
                  kind={kind}
                  onChangeKind={onChangeKind}
                />
              </SideModalContent>
            </SideModal>
          </Fieldset>
        </div>
        <div className={styles.actionBtns}>
          <SideModal
            open={isDetailFieldSettingsModalOpen}
            onClose={closeDetailFieldSettingsModal}
          >
            <SideModalTrigger asChild>
              <IconWithTextButton
                className={styles.detailBtn}
                text={t('Detailed Settings')}
                icon="settings"
                onClick={openDetailFieldSettingsModal}
                disabled={readOnly}
                outlined={false}
              />
            </SideModalTrigger>
            <SideModalContent hasSpace={false}>
              <DetailFieldSettings
                useInCustom={useInCustom}
                field={field}
                setFieldAt={setFieldAt}
                index={index}
                deleteField={deleteField}
                apiList={apiList}
                type={type}
                close={closeDetailFieldSettingsModal}
                placeholder={placeholder}
                customFields={customFields}
                setSelectField={setSelectField}
                setIframe={setIframe}
                setCustom={setCustom}
                setRepeater={setRepeater}
                onChangeRequired={onChangeRequired}
                onChangeReference={onChangeReference}
                isEditApi={isEditApi}
              />
            </SideModalContent>
          </SideModal>
          {!readOnly && (
            <IconWithTextButton
              text={t('Delete')}
              icon="clear"
              onClick={() => deleteField(index)}
              disabled={readOnly}
              outlined={false}
            />
          )}
        </div>
        <ul className={styles.bottom}>
          <li className={styles.bottomContainer}>
            <div className={styles.horizontalLabel}>
              <p className={styles.horizontalLabelText}>
                {t('Required field')}
              </p>
              <div className={styles.horizontalLabelChildren}>
                <Switch on={required} onChange={onChangeRequired} />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <Modal
        open={additionalSettings.isOpenModal}
        onClose={closeAdditionalSettingsModal}
      >
        <ModalContent
          onCloseAutoFocus={(event) => {
            event.preventDefault();
            additionalSettingsReturnFocusRef.current?.focus();
          }}
        >
          {additionalSettings.kind === 'select' && (
            <InputSelectFieldInModal
              items={selectItems ?? []}
              multiple={multipleSelect ?? false}
              initialValue={selectInitialValue ?? []}
              setField={setSelectField}
              isAdditionalField={isAdditionalField}
            />
          )}
          {(additionalSettings.kind === 'relation' ||
            additionalSettings.kind === 'relationList') && (
            <SelectRelationInModal
              apiList={apiList}
              relationKind={relationKind}
              relationContentRef={relationContentRef}
              setRelation={setRelation}
              closeRelationModal={closeAdditionalSettingsModal}
            />
          )}
          {additionalSettings.kind === 'custom' && (
            <SelectCustomFieldInModal
              api={api}
              customFieldCreatedAt={customFieldCreatedAt}
              setCustom={setCustom}
              closeCustomModal={closeAdditionalSettingsModal}
              customFields={customFields}
            />
          )}
          {additionalSettings.kind === 'repeater' && (
            <SelectRepeaterFieldInModal
              api={api}
              createdAtList={customFieldCreatedAtList}
              setRepeater={setRepeater}
              closeRepeaterModal={closeAdditionalSettingsModal}
              customFields={customFields}
            />
          )}
          {additionalSettings.kind === 'iframe' && (
            <InputIframeFieldInModal
              iframeUrl={iframeUrl}
              setIframe={setIframe}
            />
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ApiModel;
