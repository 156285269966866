import type React from 'react';
import { useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { useCopy } from '../../../hooks/Copy/useCopy';
import IconButton from '../../ui/IconButton';

import type { Param } from '../Params';

import styles from './code.module.css';

interface Props {
  inputPath: string;
  params: Param[];
  method: string;
}

const IOS: React.FC<Props> = ({ inputPath, params, method }) => {
  const { t } = useTranslation('code');
  const [endpoint, contentId] = inputPath.split('/');
  const _params = useMemo(() => {
    return params.filter((p) => p.check && p.key !== 'draftKey');
  }, [params]);
  const paramsString = useMemo(() => {
    const getValue = (key: string, value: string) => {
      // 数値
      if (['limit', 'offset', 'depth'].includes(key)) {
        return value;
      }
      // 配列
      if (['fields', 'ids', 'orders'].includes(key)) {
        return (
          '[' +
          value
            .split(',')
            .map((v) => `"${v}"`)
            .join(`, `) +
          ']'
        );
      }
      // 文字列
      return `"${value}"`;
    };
    return _params
      .map((p) => `.${p.key}(${getValue(p.key, p.value)})`)
      .join(`,
  `);
  }, [_params]);
  const code =
    (_params.length === 0
      ? ''
      : `let params: [MicrocmsParameter] = [
  ${paramsString}
]
`) +
    `client
  .get(
    endpoint: "${endpoint}"` +
    (contentId
      ? `,
    contentId: "${contentId}"`
      : '') +
    (_params.length > 0
      ? `,
    params: params`
      : '') +
    `) { result in
    switch result {
      case .success(let object):
        print("[SUCCESS] ", object)
      case .failure(let error):
        print("[ERROR] ", error)
    }
  }`;

  const [copied, copyAction, setCopied] = useCopy(code);
  const copyApiKey = () => {
    copyAction();

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  if (method !== 'GET') {
    return <p className={styles.unsupported}>{t('Not supported')}</p>;
  }

  return (
    <>
      <SyntaxHighlighter
        language="swift"
        style={docco}
        customStyle={{ fontSize: 14, padding: '16px', borderRadius: '4px' }}
      >
        {code}
      </SyntaxHighlighter>
      <p className={styles.note}>
        {Trans({
          t,
          i18nKey: 'Above is an example using microcms-ios-sdk.',
          children: (
            <a
              href="https://github.com/microcmsio/microcms-ios-sdk"
              target="sdk"
            >
              microcms-ios-sdk
            </a>
          ),
        })}
      </p>
      <div className={styles.copyButton}>
        <IconButton
          icon={copied ? 'check' : 'content_copy'}
          hasFrame={true}
          onClick={copyApiKey}
        />
      </div>
    </>
  );
};

export default IOS;
