import { createContext, useState } from 'react';

import type { Dispatch, SetStateAction, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const HasDiffContext = createContext<{
  hasDiff: boolean;
}>({ hasDiff: false });

export const HasDiffUpdateContext = createContext<{
  setHasDiff: Dispatch<SetStateAction<boolean>>;
}>({ setHasDiff: () => {} });

export const HasDiffContextProvider: React.FC<Props> = ({ children }) => {
  const [hasDiff, setHasDiff] = useState<boolean>(false);

  return (
    <HasDiffContext.Provider value={{ hasDiff }}>
      <HasDiffUpdateContext.Provider value={{ setHasDiff }}>
        {children}
      </HasDiffUpdateContext.Provider>
    </HasDiffContext.Provider>
  );
};
