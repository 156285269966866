import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import {
  updateApiIpAddressRestriction as updateApiIpAddressRestrictionUsecase,
  updateGlobalApiIpAddressRestriction as updateGlobalApiIpAddressRestrictionUsecase,
} from '@/usecase/apiIpAddressRestrictionUsecase';

import { apiIpAddressRestrictionQueryKeys } from './queryKeys';

import { useApiListInvalidateCache } from '../api/invalidateCache';

export const useUpdateGlobalApiIpAddressRestriction = (
  serviceId?: string | null | undefined,
) => {
  const { t } = useTranslation('commonApiIpAddressRestriction');
  const queryClient = useQueryClient();
  const { addToast } = useToasts();

  return useMutation({
    mutationFn: async (ipList: string) => {
      if (!serviceId) {
        return;
      }

      return updateGlobalApiIpAddressRestrictionUsecase({
        serviceId,
        ipList,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(apiIpAddressRestrictionQueryKeys.global, {
        type: 'all',
      });
      addToast(t('Changes have been made.'), {
        appearance: 'success',
      });
    },
    onError: (error) => {
      addToast(
        error instanceof Error ? error.message : t('Failed to make changes.'),
        {
          appearance: 'error',
        },
      );
    },
  });
};

export const useUpdateApiIpAddressRestriction = (
  apiId?: string | null | undefined,
) => {
  const { t } = useTranslation('commonApiIpAddressRestriction');
  const { addToast } = useToasts();
  const apiListInvalidateCache = useApiListInvalidateCache();

  return useMutation({
    mutationFn: async ({
      ipList,
      enabled,
    }: {
      ipList: string;
      enabled: boolean;
    }) => {
      if (!apiId) {
        return;
      }

      return updateApiIpAddressRestrictionUsecase({
        apiId,
        ipList,
        enabled,
      });
    },
    onSuccess: () => {
      apiListInvalidateCache();
      addToast(t('Changes have been made.'), {
        appearance: 'success',
      });
    },
    onError: (error) => {
      addToast(
        error instanceof Error ? error.message : t('Failed to make changes.'),
        {
          appearance: 'error',
        },
      );
    },
  });
};
