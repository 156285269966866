import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from './_types';
import type { Role } from '@/entity/role';

import { produce } from '@/lib/immer';

type SetEnvironmentPermissionCreateAction = FormRoleInputValueAction<
  'set-environment-permission-create',
  Role['environmentPermission']['create']
>;
type SetEnvironmentPermissionReadAction = FormRoleInputValueAction<
  'set-environment-permission-read',
  Role['environmentPermission']['read']
>;
type SetEnvironmentPermissionUpdateAction = FormRoleInputValueAction<
  'set-environment-permission-update',
  Role['environmentPermission']['update']
>;
type SetEnvironmentPermissionDeleteAction = FormRoleInputValueAction<
  'set-environment-permission-delete',
  Role['environmentPermission']['delete']
>;
type EnvironmentPermissionAction =
  | SetEnvironmentPermissionCreateAction
  | SetEnvironmentPermissionReadAction
  | SetEnvironmentPermissionUpdateAction
  | SetEnvironmentPermissionDeleteAction;

/**
 * EnvironmentPermissionのcreateフィールドを更新する
 */
const setEnvironmentPermissionCreate: FormRoleInputValueDispatchFn<
  SetEnvironmentPermissionCreateAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.environmentPermission.create = payload;
  });
};

/**
 * EnvironmentPermissionのreadフィールドを更新する
 */
const setEnvironmentPermissionRead: FormRoleInputValueDispatchFn<
  SetEnvironmentPermissionReadAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.environmentPermission.read = payload;
  });
};

/**
 * EnvironmentPermissionのupdateフィールドを更新する
 */
const setEnvironmentPermissionUpdate: FormRoleInputValueDispatchFn<
  SetEnvironmentPermissionUpdateAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.environmentPermission.update = payload;
  });
};

/**
 * EnvironmentPermissionのdeleteフィールドを更新する
 */
const setEnvironmentPermissionDelete: FormRoleInputValueDispatchFn<
  SetEnvironmentPermissionDeleteAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.environmentPermission.delete = payload;
  });
};

export {
  type EnvironmentPermissionAction,
  setEnvironmentPermissionCreate,
  setEnvironmentPermissionRead,
  setEnvironmentPermissionUpdate,
  setEnvironmentPermissionDelete,
};
