import CopyButton from '@/components/ui/CopyButton';
import Textfield from '@/components/ui/Textfield/Textfield';

import styles from './readOnlyTextFieldWithCopyButton.module.css';

type ViewProps = {
  value: string;
};

type ContainerProps = {
  value: string;
};

const ReadOnlyTextFieldWithCopyButtonView: React.FC<ViewProps> = ({
  value,
}) => {
  return (
    <div className={styles.main}>
      <Textfield type="text" value={value} readOnly={true} />
      <CopyButton value={value} className={styles.copyButton} />
    </div>
  );
};

export const ReadOnlyTextFieldWithCopyButton: React.FC<ContainerProps> = ({
  value,
}) => {
  return <ReadOnlyTextFieldWithCopyButtonView value={value} />;
};
