import Quill from 'quill';
import Delta from 'quill-delta';
const Clipboard = Quill.import('modules/clipboard');

class CustomClipboard extends Clipboard {
  onPaste(e) {
    if (e.defaultPrevented || !this.quill.isEnabled()) return;
    const $mainEl = document.getElementById('main');
    const range = this.quill.getSelection();
    let delta = new Delta().retain(range.index);
    const scrollTop = this.quill.scrollingContainer.scrollTop;
    const mainTop = $mainEl.scrollTop;
    this.container.focus();
    this.quill.selection.update(Quill.sources.SILENT);
    setTimeout(() => {
      delta = delta.concat(this.convert()).delete(range.length);
      this.quill.updateContents(delta, Quill.sources.USER);
      this.quill.setSelection(
        delta.length() - range.length,
        Quill.sources.SILENT,
      );
      this.quill.scrollingContainer.scrollTop = scrollTop; // エディタ内のスクロールを補正
      $mainEl.scrollTop = mainTop; // 外側領域のスクロールを補正
      this.quill.focus();
    }, 1);
  }
}

export default CustomClipboard;
