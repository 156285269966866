import { useCallback, useMemo, useState } from 'react';
import type React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useInput, useLoading } from '../../../hooks';
import { useExceptionPermissionIsHaveLeastOne } from '../../../hooks/Role/useMyRoles';
import Feedback from '../../Feedback';
import Head from '../../Head';
import { validateUrl } from '../../Validations';
import Button from '../../ui/Button';
import ExternalLink from '../../ui/ExternalLink';
import Fieldset from '../../ui/Fieldset';
import Switch from '../../ui/Switch';
import Textfield from '../../ui/Textfield';

import type { GetPutResultArgs } from '@/ducks/api/selector';
import type { ApiList, MigrateApi } from '@/entity/api';

import styles from './previewSettings.module.css';

import { apiSelectors } from '@/ducks/api';
import { putApi } from '@/ducks/api/operations';
import { apiListSelectors } from '@/ducks/apiList';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useApiListInvalidateCache } from '@/views/Common/api/invalidateCache';

const PreviewSettings: React.FC = () => {
  const { t } = useTranslation('previewSettings');
  const apiListInvalidateCache = useApiListInvalidateCache();

  // TODO: ReduxをReactQueryに置き換える
  const { endpoint } = useParams<{
    endpoint: string;
  }>();
  const api = useAppSelector(
    (state) =>
      apiListSelectors.getApi(
        state.apiListState as ApiList,
        endpoint,
      ) as MigrateApi,
  );
  const dispatch = useAppDispatch();
  const putResult = useAppSelector(
    (state) =>
      apiSelectors.getPutResult(state.apiState as GetPutResultArgs).putResult,
  );

  const [updatable] = useExceptionPermissionIsHaveLeastOne(
    api.partitionKey,
    'apiUpdate',
  );
  const { apiPreviewUrl, apiPreviewLinkIsVisible, partitionKey } = api;
  const [previewUrl, onChangePreviewUrl, previewUrlError] = useInput(
    undefined,
    // @ts-ignore
    validateUrl,
    true,
  );
  const [previewLinkIsVisible, setPreviewLinkIsVisible] = useState(
    apiPreviewLinkIsVisible === null ? true : apiPreviewLinkIsVisible,
  );
  const TogglePreviewSwitch = useCallback((apiPreviewLinkIsVisible: any) => {
    setPreviewLinkIsVisible(apiPreviewLinkIsVisible);
  }, []);

  const [loading, startLoading] = useLoading(putResult !== undefined);
  const submit = useCallback(() => {
    startLoading();
    dispatch(
      // @ts-expect-error
      putApi({
        apiId: partitionKey,
        previewUrl,
        previewLinkIsVisible,
      }),
    ).then(() => {
      apiListInvalidateCache();
    });
  }, [
    apiListInvalidateCache,
    dispatch,
    partitionKey,
    previewLinkIsVisible,
    previewUrl,
    startLoading,
  ]);
  const isList = useMemo(() => api.apiType === 'LIST', [api]);
  return (
    <div>
      <Head title={t('API/Page Preview')} />
      <h2 className={styles.title}>{t('Page Preview')}</h2>
      <Fieldset legend={t('Preview URL')}>
        <p className={styles.subText}>
          {t(
            'Specify URL of the page displayed when Page preview button is clicked.',
          )}
          <br />
          <br />
          {isList ? (
            <>
              {Trans({
                t,
                i18nKey: 'URL must include {CONTENT_ID} and {DRAFT_KEY}.',
                children: (
                  <span className={styles.param}>{'{CONTENT_ID}'}</span>
                ),
              })}
            </>
          ) : (
            <>
              {Trans({
                t,
                i18nKey: 'URL must include {DRAFT_KEY}.',
                children: <span className={styles.param}>{'{DRAFT_KEY}'}</span>,
              })}
            </>
          )}
          <br />
          {isList ? (
            <>
              {Trans({
                t,
                i18nKey:
                  '{CONTENT_ID} and {DRAFT_KEY} are replaced with actual content ID and draftKey when Page preview button is clicked. {CONTENT_ID} is an ID specific to the article.',
                children: (
                  <span className={styles.param}>{'{CONTENT_ID}'}</span>
                ),
              })}
            </>
          ) : (
            <>
              {Trans({
                t,
                i18nKey:
                  '{DRAFT_KEY} is replaced with the draftKey when Page preview button is clicked.',
                children: <span className={styles.param}>{'{DRAFT_KEY}'}</span>,
              })}
            </>
          )}
        </p>
        <Textfield
          type="text"
          placeholder="https://yourdomain.com/{CONTENT_ID}?draftKey={DRAFT_KEY}"
          defaultValue={apiPreviewUrl || undefined}
          onChange={onChangePreviewUrl}
          errorText={previewUrlError}
          readOnly={!updatable}
        />
        <p className={styles.note}>
          {Trans({
            t,
            i18nKey: 'For more information on how to use the API, click here.',
            children: (
              <ExternalLink href="https://document.microcms.io/content-api/get-list-contents">
                click here
              </ExternalLink>
            ),
          })}
        </p>
      </Fieldset>
      <Fieldset
        legend={t('Show Page preview button')}
        description={t('Displays Page preview button in content editing page.')}
      >
        <Switch
          on={previewLinkIsVisible}
          onChange={TogglePreviewSwitch}
          disabled={!updatable}
          size="large"
        />
      </Fieldset>
      {updatable && (
        <div className={styles.actions}>
          <Feedback
            type={putResult === false ? 'failure' : 'success'}
            message={
              putResult === false
                ? t('Failed to make changes.')
                : putResult === true
                  ? t('Changes have been made.')
                  : undefined
            }
          />
          <Button
            type="primary"
            className="ga-api-settings-preview"
            disabled={loading || previewUrlError}
            value={t('Save changes')}
            onClick={submit}
          />
        </div>
      )}
    </div>
  );
};

export default PreviewSettings;
