import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';

import type { CustomField } from '@/types/contents';
import type { Field } from '../../API';
import type { Service } from '../../types/services';

/**
 * フィールド数の上限チェックを行うカスタムフック
 *
 * @param fields - フィールドの配列
 * @param service - 現在のサービス
 * @param parentService - 親サービス（オプション）
 * @returns フィールド数が上限を超えているかどうか
 */
const useLimitApiFields = (
  fields: Field[],
  service: Service | null,
  parentService?: Service | null,
) => {
  // ANKEN-1163 フィールド数上限値の設定
  // 上限値のチェックに用いる値
  // サービス作成日が指定日以降の場合上限値チェックを行う
  // LaunchDarklyにて設定された値を使用する
  const flags = useFlags();
  const maxFieldDate = flags.maxFieldDate;
  const maxFieldNumber = flags.maxFieldNumber;

  const isLimitNumApiFields = useMemo(() => {
    return isLimitApiFields(
      { fields, service, parentService },
      { maxFieldDate, maxFieldNumber },
    );
  }, [fields, maxFieldNumber, maxFieldDate, service, parentService]);

  return isLimitNumApiFields;
};

/**
 * カスタムフィールドのネスト数の上限チェックを行うカスタムフック
 *
 * @param customFields - 全カスタムフィールドの配列
 * @param service - 現在のサービス
 * @param parentService - 親サービス（オプション）
 * @returns ネスト数が上限を超えているかどうか
 */
const useLimitCustomFields = (
  customFields: CustomField[] | undefined,
  service: Service | null,
  parentService?: Service | null,
) => {
  const flags = useFlags();
  const maxFieldDate = flags.maxFieldDate;
  const maxCustomFieldNumber = flags.maxCustomFieldNumber;

  const isLimitNumCustomFields = useMemo(() => {
    return isLimitCustomFields(
      { customFields, service, parentService },
      { maxFieldDate, maxFieldNumber: maxCustomFieldNumber },
    );
  }, [
    customFields,
    maxCustomFieldNumber,
    maxFieldDate,
    service,
    parentService,
  ]);

  return isLimitNumCustomFields;
};

/**
 * フィールド数の上限チェックを行う関数
 *
 * @param input - 入力オブジェクト（フィールド、サービス、親サービス）
 * @param max - 上限値オブジェクト（最大作成日、最大フィールド数）
 * @returns フィールド数が上限を超えているかどうか
 */
const isLimitApiFields = (
  input: {
    fields: Field[];
    service: Service | null;
    parentService?: Service | null;
  },
  max: { maxFieldDate: string; maxFieldNumber: number },
) => {
  if (
    input.service &&
    max.maxFieldDate &&
    // 複数環境の場合、親サービスの作成日で判定する
    ((input.parentService &&
      new Date(input.parentService.createdAt) > new Date(max.maxFieldDate)) ||
      (!input.parentService &&
        new Date(input.service.createdAt) > new Date(max.maxFieldDate)))
  ) {
    if (
      max.maxFieldNumber > 0 &&
      input.fields &&
      input.fields.length >= max.maxFieldNumber
    ) {
      return true;
    }
    return false;
  }
  return false;
};

/**
 * カスタムフィールド数の上限チェックを行う関数
 *
 * @param input - 入力オブジェクト（カスタムフィールド、サービス、親サービス）
 * @param max - 上限値オブジェクト（最大作成日、最大フィールド数）
 * @returns カスタムフィールド数が上限を超えているかどうか
 */
const isLimitCustomFields = (
  input: {
    customFields: CustomField[] | undefined;
    service: Service | null;
    parentService?: Service | null;
  },
  max: { maxFieldDate: string; maxFieldNumber: number },
) => {
  if (
    input.service &&
    max.maxFieldDate &&
    // 複数環境の場合、親サービスの作成日で判定する
    ((input.parentService &&
      new Date(input.parentService.createdAt) > new Date(max.maxFieldDate)) ||
      (!input.parentService &&
        new Date(input.service.createdAt) > new Date(max.maxFieldDate)))
  ) {
    if (
      max.maxFieldNumber > 0 &&
      input.customFields &&
      input.customFields.length >= max.maxFieldNumber
    ) {
      return true;
    }
    return false;
  }
  return false;
};

export {
  useLimitApiFields,
  isLimitApiFields,
  useLimitCustomFields,
  isLimitCustomFields,
};
