const dict = {
  ja: {
    UsernameExistsException: 'メールアドレスがすでに登録済みです。',
    InvalidParameterException: '入力項目に誤りがあります。',
    InvalidPasswordException: 'パスワードの入力に誤りがあります。',
    NotAuthorizedException:
      'メールアドレス、またはパスワードの入力に誤りがあります。',
    UserNotFoundException:
      'メールアドレス、またはパスワードの入力に誤りがあります。', // 登録済みかどうかも知られないようにするため
    CodeMismatchException: '確認コードの入力に誤りがあります。',
    LimitExceededException:
      'リクエスト回数の限度を超えています。時間をおいてから再度お試しください。',
    EnableSoftwareTokenMFAException: '入力に誤りがあります',
  },
};

export default dict;
