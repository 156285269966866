import { Auth, Storage } from 'aws-amplify';
import mime from 'mime-types';
import { useCallback, useState } from 'react';

type ImageUploadResult = {
  key: string;
  level: string;
  identityId: string;
};

export const useNameSettings = () => {
  const [imageUploadResult, setResult] = useState<
    ImageUploadResult | undefined
  >();

  const uploadImage = useCallback(async (domain: string, file: File) => {
    if (file === undefined) {
      return;
    }

    const fileName = `service/${domain}/icon/${file.name}`;
    const level = 'protected';
    const identityId = (await Auth.currentUserInfo()).id;
    const contentType = mime.lookup(fileName) || 'application/octet-stream';
    Storage.put(fileName, file, { level, contentType })
      .then((putResult) => {
        setResult({
          key: putResult.key,
          level,
          identityId,
        });
      })
      .catch((err) => {
        console.log(err);
        setResult(undefined);
      });
  }, []);
  return {
    uploadImage,
    imageUploadResult,
  };
};
