import { Td } from '@/components/ui/Table';

import Read from './Read';
import { useI18nFormRole } from '../../i18n';
import { useFormRoleDispatch, useFormRoleState } from '../../provider';

import { Checkbox } from '@/views/Common/Ui/Checkbox';

const FieldRole = () => {
  const { t } = useI18nFormRole();

  const {
    isAdminRole,
    input: { rolePermission },
  } = useFormRoleState();
  const dispatch = useFormRoleDispatch();

  const disabled = isAdminRole;

  return (
    <>
      <Td>{t('Roles')}</Td>
      <Td>
        <Read />
      </Td>
      <Td>
        <Checkbox
          checked={rolePermission.create}
          disabled={disabled}
          name="rolePermission.create"
          onChange={(e) =>
            dispatch({
              type: 'set-role-permission-create',
              payload: e.target.checked,
            })
          }
          label={t('Create roles')}
        />
      </Td>
      <Td>
        <Checkbox
          checked={rolePermission.update}
          disabled={disabled}
          name="rolePermission.update"
          onChange={(e) =>
            dispatch({
              type: 'set-role-permission-update',
              payload: e.target.checked,
            })
          }
          label={t('Update permissions')}
        />
      </Td>
      <Td>
        <Checkbox
          checked={rolePermission.delete}
          disabled={disabled}
          name="rolePermission.delete"
          onChange={(e) =>
            dispatch({
              type: 'set-role-permission-delete',
              payload: e.target.checked,
            })
          }
          label={t('Delete roles')}
        />
      </Td>
    </>
  );
};

export default FieldRole;
