import type {
  FormRoleInputValueAction,
  FormRoleInputValueDispatchFn,
} from './_types';
import type { Role } from '@/entity/role';

import { produce } from '@/lib/immer';
import { isBoolean } from '@/util/type-guard';

type SetMemberPermissionCreateAction = FormRoleInputValueAction<
  'set-member-permission-create',
  Role['memberPermission']['create']
>;
type SetMemberPermissionReadAction = FormRoleInputValueAction<
  'set-member-permission-read',
  | boolean
  | Required<Pick<Role['memberPermission']['read'], 'allowMemberList'>>
  | Required<Pick<Role['memberPermission']['read'], 'sameRole'>>
>;
type SetMemberPermissionUpdateAction = FormRoleInputValueAction<
  'set-member-permission-update',
  Role['memberPermission']['update']
>;
type SetMemberPermissionDeleteAction = FormRoleInputValueAction<
  'set-member-permission-delete',
  Role['memberPermission']['delete']
>;
type MemberPermissionAction =
  | SetMemberPermissionCreateAction
  | SetMemberPermissionReadAction
  | SetMemberPermissionUpdateAction
  | SetMemberPermissionDeleteAction;

/**
 * MemberPermissionのcreateフィールドを更新する
 */
const setMemberPermissionCreate: FormRoleInputValueDispatchFn<
  SetMemberPermissionCreateAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.memberPermission.create = payload;
  });
};

/**
 * MemberPermissionのreadフィールドを更新する
 *
 * payloadの値によって、更新方法を切り替える
 * - payloadがtrueの場合は、defaultReadの値をtrueにする, その他(allowMemberList, sameRole)はなし.
 * - payloadがfalseの場合は、defaultReadの値をfalse, その他(allowMemberList, sameRole)はなし.
 */
const setMemberPermissionRead: FormRoleInputValueDispatchFn<
  SetMemberPermissionReadAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    if (isBoolean(payload)) {
      draft.memberPermission.read = {
        defaultRead: payload,
      };
    } else if ('allowMemberList' in payload) {
      draft.memberPermission.read = {
        defaultRead: false,
        allowMemberList: payload.allowMemberList,
      };
    } else if (
      'sameRole' in payload &&
      // NOTE: sameRoleはtrueか未定義しか取り得ないが、値チェックしている
      payload.sameRole
    ) {
      draft.memberPermission.read = {
        defaultRead: false,
        sameRole: payload.sameRole,
      };
    }
  });
};

/**
 * MemberPermissionのupdateフィールドを更新する
 */
const setMemberPermissionUpdate: FormRoleInputValueDispatchFn<
  SetMemberPermissionUpdateAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.memberPermission.update = payload;
  });
};

/**
 * MemberPermissionのdeleteフィールドを更新する
 */
const setMemberPermissionDelete: FormRoleInputValueDispatchFn<
  SetMemberPermissionDeleteAction
> = (currentState, payload) => {
  return produce(currentState, (draft) => {
    draft.memberPermission.delete = payload;
  });
};

export {
  type MemberPermissionAction,
  setMemberPermissionCreate,
  setMemberPermissionRead,
  setMemberPermissionUpdate,
  setMemberPermissionDelete,
};
