import { iframelyRepository } from '@/repository/iframelyRepository';

export const getIframelyResult = async ({
  url,
  optionQuery,
}: {
  url: string;
  optionQuery: string;
}) => {
  const { getIframelyResult } = iframelyRepository();

  const result = await getIframelyResult({ url, optionQuery });

  return result;
};
