/**
 * ReactQueryのクエリキーにIDを指定する際に、値を文字列に統一する ※1
 *
 * ※1 キャッシュのInvalidateのマーキングなどで、クエリキー中の数値など文字列は別のキーとして扱われてしまう
 *
 * @param someId
 * @returns
 */
function uniformQueryKeyParam(someId: string | number | boolean): string {
  return someId.toString();
}

export { uniformQueryKeyParam };
