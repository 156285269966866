import { useCallback } from 'react';

import type { Props } from './Undo';

export const useUndo = ({ editor }: Props) => {
  const onUndoHandler = useCallback(() => {
    editor.commands.undo();
  }, [editor]);

  return {
    onUndoHandler,
  };
};
