/* eslint-disable @typescript-eslint/no-this-alias */

// https://github.com/niksy/throttle-debounce/blob/master/throttle.js

/*
 * After wrapper has stopped being called, this timeout ensures that
 * `callback` is executed at the proper times in `throttle` and `end`
 * debounce modes.
 */
let timeoutID;
let cancelled = false;

// Keep track of the last time `callback` was executed.
let lastExec = 0;

/**
 * 関数の実行をスロットルする。特にレート制限に便利です。
 * 特に、リサイズやスクロールのようなイベントのハンドラの実行速度を制限するのに便利です。
 *
 * @param {number} delay -                   ミリ秒単位の0以上の遅延。イベントコールバックでは、100または250（あるいはそれ以上）の値が最も有用です。
 * @param {Function} callback -              遅延ミリ秒後に実行される関数。this`コンテキストとすべての引数は、throttled-function が実行されるときに `callback` にそのまま渡される。
 * @param {object} [options] -               オプションを設定するオブジェクト。
 * @param {boolean} [options.noTrailing] -   オプション。デフォルトはfalse。noTrailing が true の場合、throttled-function が呼び出されている間、コールバックは `delay` ミリ秒ごとにのみ実行されます。noTrailing が false または未指定の場合、callback は最後の throttled-function 呼び出しの後に最後に1回実行されます。(`delay` ミリ秒の間、throttled-function が呼び出されなかった後、内部カウンタはリセットされる)。
 * @param {boolean} [options.noLeading] -    オプション。デフォルトはfalse。noLeadingがfalseの場合、最初のthrottled-function呼び出しは直ちにコールバックを実行します。noLeadingがtrueの場合、最初のコールバックの実行はスキップされます。noLeading=trueとnoTrailing=trueの両方の場合、コールバックは実行されないことに注意する必要がある。
 * @param {boolean} [options.debounceMode] - debounceMode`がtrueの場合（開始時）、`delay`ミリ秒後に`clear`を実行するようにスケジュールする。debounceMode`がfalseの場合（終了時）、`delay`ミリ秒後に`callback`を実行する。
 *
 * @returns {Function} 新しい、スロットル関数。
 */
export default function (delay, callback, options) {
  const {
    noTrailing = false,
    noLeading = false,
    debounceMode = undefined,
  } = options || {};

  // Function to clear existing timeout
  function clearExistingTimeout() {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
  }

  // Function to cancel next exec
  function cancel(options) {
    const { upcomingOnly = false } = options || {};
    clearExistingTimeout();
    cancelled = !upcomingOnly;
  }

  /*
   * The `wrapper` function encapsulates all of the throttling / debouncing
   * functionality and when executed will limit the rate at which `callback`
   * is executed.
   */
  function wrapper(...arguments_) {
    const self = this;
    const elapsed = Date.now() - lastExec;

    if (cancelled) {
      return;
    }

    // Execute `callback` and update the `lastExec` timestamp.
    function exec() {
      lastExec = Date.now();
      callback.apply(self, arguments_);
    }

    /*
     * If `debounceMode` is true (at begin) this is used to clear the flag
     * to allow future `callback` executions.
     */
    function clear() {
      timeoutID = undefined;
    }

    if (!noLeading && debounceMode && !timeoutID) {
      /*
       * Since `wrapper` is being called for the first time and
       * `debounceMode` is true (at begin), execute `callback`
       * and noLeading != true.
       */
      exec();
    }

    clearExistingTimeout();

    if (debounceMode === undefined && elapsed > delay) {
      if (noLeading) {
        /*
         * In throttle mode with noLeading, if `delay` time has
         * been exceeded, update `lastExec` and schedule `callback`
         * to execute after `delay` ms.
         */
        lastExec = Date.now();
        if (!noTrailing) {
          timeoutID = setTimeout(debounceMode ? clear : exec, delay);
        }
      } else {
        /*
         * In throttle mode without noLeading, if `delay` time has been exceeded, execute
         * `callback`.
         */
        exec();
      }
    } else if (noTrailing !== true) {
      /*
       * In trailing throttle mode, since `delay` time has not been
       * exceeded, schedule `callback` to execute `delay` ms after most
       * recent execution.
       *
       * If `debounceMode` is true (at begin), schedule `clear` to execute
       * after `delay` ms.
       *
       * If `debounceMode` is false (at end), schedule `callback` to
       * execute after `delay` ms.
       */
      timeoutID = setTimeout(
        debounceMode ? clear : exec,
        debounceMode === undefined ? delay - elapsed : delay,
      );
    }
  }

  wrapper.cancel = cancel;

  // Return the wrapper function.
  return wrapper;
}
