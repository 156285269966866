import { memberRolesRepository } from '@/repository/memberRolesRepository';

export const updateMemberRoles = async ({
  serviceId,
  targetUsername,
  roleIds,
}: {
  serviceId: string;
  targetUsername: string;
  roleIds: string[];
}) => {
  const { updateMemberRoles } = memberRolesRepository();
  return await updateMemberRoles({ serviceId, targetUsername, roleIds });
};
