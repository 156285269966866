import { Auth } from 'aws-amplify';
import type React from 'react';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {
  useAcceptMemberInvitation,
  useMemberEmailVerified,
} from '@/hooks/Auth/useAuth';
import {
  useUpdateUserAttributes,
  useVerifyCurrentUserAttributeSubmit,
  useUserAttributes,
} from '@/hooks/Profile/useProfile';

import { useName } from './useName';
import Feedback from '../../Feedback';
import { LinkButton } from '../../Form';
import S3Image from '../../S3Image';
import Button from '../../ui/Button';
import SendEmailModal from '../../ui/Modal';
import {
  validateAccountName,
  validateEmail,
  validateMedium,
} from '../../Validations';

import styles from './name.module.css';

import { useInput } from '@/hooks';
import { isSAMLUser as getIsSAMLUserFlag } from '@/util';
import { getDefaultHost } from '@/util';
import { Fieldset } from '@/views/Common/Ui/Fieldset';
import { Icon } from '@/views/Common/Ui/Icon';
import { Textfield } from '@/views/Common/Ui/Textfield';

const useSearch = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

const Name: React.FC = () => {
  const { t } = useTranslation('profileName');
  const query = useSearch();
  const history = useHistory();
  const { uploadImage, imageUploadResult } = useName();
  const { addToast } = useToasts();
  const verificationCode = query.get('verificationCode');
  const { userAttributes } = useUserAttributes();
  const [identityId, fileName] = useMemo(
    () =>
      userAttributes && userAttributes.picture
        ? userAttributes.picture.split(',')
        : [],
    [userAttributes],
  );

  const { hasAcceptMember, acceptMemberInvitation } =
    useAcceptMemberInvitation();
  const { onDoneVerified } = useMemberEmailVerified(
    userAttributes.email_verified,
  );

  const isSAMLUser = useMemo(() => getIsSAMLUserFlag(), []);
  const [Modal, openModal, closeModal] = useModal('root');

  const [file, setFile] = useState(undefined);

  const [name, onChangeName, nameError] = useInput(
    userAttributes && userAttributes.name,
    // @ts-expect-error
    validateAccountName,
  );
  const [email, onChangeEmail, emailError] = useInput(
    userAttributes && userAttributes.email,
    validateEmail,
  );
  const [validationResult, setValidationResult] = useState<string | null>(null);
  const { updateUserAttributes, updateUserAttributesLoading } =
    useUpdateUserAttributes();
  const {
    verifyCurrentUserAttributeSubmit,
    verifyCurrentUserAttributeSubmitLoading,
  } = useVerifyCurrentUserAttributeSubmit();

  const selectFile = useCallback(
    (e: any) => {
      const selectedFile = e.target.files[0];
      if (selectedFile === undefined) {
        return;
      }
      const res = validateMedium(selectedFile);
      setValidationResult(res);
      if (res !== null) {
        setTimeout(() => setValidationResult(null), 3000);
        return;
      }
      setFile(selectedFile);
      uploadImage(selectedFile);
    },
    [uploadImage],
  );

  // 変更の保存
  const submit = useCallback(() => {
    const params: any = {};
    if (name !== undefined) {
      params.name = name;
    }
    if (email !== undefined) {
      params.email = email;
    }
    if (imageUploadResult?.image) {
      params.picture = imageUploadResult.image;
    }
    updateUserAttributes({
      params,
    });
  }, [name, email, imageUploadResult?.image, updateUserAttributes]);

  // メールアドレス変更時の確認コード送信
  const verify = useCallback(
    (code: any) => {
      closeModal();
      verifyCurrentUserAttributeSubmit({ code });
    },
    [closeModal, verifyCurrentUserAttributeSubmit],
  );

  useEffect(() => {
    if (verificationCode !== null) {
      verify(verificationCode);
    }
  }, [query, verificationCode, verify]);

  useEffect(() => {
    if (userAttributes.email_verified === 'true') {
      query.delete('verificationCode');
      history.replace({
        search: query.toString(),
      });
    }
  }, [history, query, userAttributes.email_verified, verificationCode, verify]);

  //メールアドレス認証が終わったタイミングで招待を確認・受諾する
  useEffect(() => {
    if (onDoneVerified) {
      acceptMemberInvitation();
    }
  }, [onDoneVerified, acceptMemberInvitation]);

  // サービスに招待された直後はサービス一覧に遷移する
  useEffect(() => {
    if (hasAcceptMember) {
      window.location.href = getDefaultHost();
    }
  }, [hasAcceptMember]);

  const resendEmail = useCallback(() => {
    closeModal();
    Auth.verifyCurrentUserAttribute('email').then(() => {
      addToast(t('Email has been resent.'), {
        appearance: 'success',
      });
    });
  }, [addToast, closeModal, t]);

  return Object.keys(userAttributes).length === 0 ? null : (
    <div className={styles.container}>
      <h2 className={styles.title}>{t('Profile')}</h2>
      <div className={styles.formGroup}>
        <Fieldset label={t('Account Image')}>
          <label className={styles.selectImage}>
            {file ? (
              <img
                className={styles.selectedImage}
                src={window.URL.createObjectURL(file)}
                alt=""
              />
            ) : identityId && fileName ? (
              <S3Image
                directory={`protected/${identityId}`}
                fileName={fileName}
                kind={'IMAGE'}
                queryString="?fit=crop&w=150&h=150"
                className={styles.selectedImage}
                style={{ background: 'none', border: 'none' }}
              />
            ) : userAttributes.picture ? (
              <img
                className={styles.defaultImage}
                src={userAttributes.picture}
                alt=""
              />
            ) : (
              <img
                className={styles.defaultImage}
                src="/images/icon_person.svg"
                alt=""
              />
            )}
            <Feedback
              message={validationResult !== null ? validationResult : undefined}
            />
            <span className={styles.editBtn}>
              <i className={`material-icons ${styles.editIcon}`}>edit</i>
            </span>
            <input
              type="file"
              accept="image/*"
              onChange={selectFile}
              disabled={isSAMLUser}
              className="sr-only"
            />
          </label>
        </Fieldset>
        <Textfield
          label={t('Display Name')}
          defaultValue={userAttributes && userAttributes.name}
          onChange={onChangeName}
          error={nameError}
          readOnly={isSAMLUser}
        />
        {userAttributes.email_verified === 'false' && (
          <div>
            <Textfield
              label={t('Email Address')}
              defaultValue={userAttributes && userAttributes.email}
              onChange={onChangeEmail}
              error={emailError}
              className={styles.textField}
              readOnly={isSAMLUser}
            />
            <p className={styles.unverified}>
              <Icon name="warning" className={styles.iconWarning} />
              {t('Email address has not been verified. ')}
              <LinkButton
                value={t('Resend Verification E-mail')}
                onClick={openModal}
              />
            </p>
          </div>
        )}
        {userAttributes.email_verified === 'true' && !isSAMLUser && (
          <div>
            <Textfield
              label={t('Email Address')}
              defaultValue={userAttributes && userAttributes.email}
              onChange={onChangeEmail}
              error={emailError}
            />
            <p className={styles.verified}>
              <Icon name="verified_user" className={styles.iconVerified} />
              {t('Verified')}
            </p>
          </div>
        )}
      </div>
      <div className={styles.actions}>
        <Button
          value={t('Save changes')}
          type="primary"
          disabled={updateUserAttributesLoading || isSAMLUser}
          onClick={submit}
        />
      </div>
      <Modal>
        <SendEmailModal
          type="small"
          title={t('Resend Verification E-mail')}
          description={t(
            'A verification e-mail will be resent to your e-mail address. Check the URL in the e-mail.',
          )}
        >
          <div className={styles.modalAction}>
            <Button
              value={t('Resend e-mail')}
              type="secondary"
              size="large"
              disabled={verifyCurrentUserAttributeSubmitLoading}
              onClick={resendEmail}
              className={styles.modalButton}
            />
          </div>
        </SendEmailModal>
      </Modal>
    </div>
  );
};

export default Name;
