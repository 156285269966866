import { useCallback, useMemo, useState } from 'react';

import type { CodeBlockAttributes } from '.';
import type React from 'react';

export type Props = {
  codeBlockAttributes: CodeBlockAttributes;
  listLanguage: string[];
  updateCodeBlockAttributes: (attributes: CodeBlockAttributes) => void;
};

export type FocusManagement = {
  isInputFocus: boolean;
  isAutoCompleteFocus: boolean;
};

export const useCodeBlock = ({
  codeBlockAttributes,
  listLanguage,
  updateCodeBlockAttributes,
}: Props) => {
  const [open, setOpen] = useState(false);

  const [focusManagement, setFocusManagement] = useState<FocusManagement>({
    isInputFocus: false,
    isAutoCompleteFocus: false,
  });
  const toggleFocusManagement = useCallback((state: FocusManagement) => {
    setFocusManagement(state);
  }, []);

  const onOpenChange = useCallback(() => {
    if (!open) {
      toggleFocusManagement({ isInputFocus: false, isAutoCompleteFocus: true });
    }
    setOpen((prev) => !prev);
  }, [open, toggleFocusManagement]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const onChangeFileName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      updateCodeBlockAttributes({ ...codeBlockAttributes, fileName: value });
    },
    [codeBlockAttributes, updateCodeBlockAttributes],
  );

  const onChangeLanguage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      updateCodeBlockAttributes({ ...codeBlockAttributes, language: value });
      setOpen(true);
      setActiveIndex(0);
    },
    [codeBlockAttributes, updateCodeBlockAttributes],
  );

  const filteredListLanguage = useMemo(() => {
    const language = codeBlockAttributes.language;
    if (!language) {
      return listLanguage;
    }
    return listLanguage.filter((item) => {
      return item.toLowerCase().startsWith(language.toLowerCase());
    });
  }, [codeBlockAttributes, listLanguage]);

  const onClickLanguage = useCallback(
    (item: string) => {
      updateCodeBlockAttributes({ ...codeBlockAttributes, language: item });
      setActiveIndex(null);
      setOpen(false);
    },
    [codeBlockAttributes, updateCodeBlockAttributes],
  );

  const onKeyDownLanguage = useCallback(
    (event: React.KeyboardEvent) => {
      if (
        event.key === 'Enter' &&
        activeIndex != null &&
        filteredListLanguage[activeIndex]
      ) {
        updateCodeBlockAttributes({
          ...codeBlockAttributes,
          language: filteredListLanguage[activeIndex],
        });
        setActiveIndex(null);
        setOpen(false);
      }
    },
    [
      activeIndex,
      codeBlockAttributes,
      filteredListLanguage,
      updateCodeBlockAttributes,
    ],
  );

  return {
    filteredListLanguage,
    onChangeFileName,
    onChangeLanguage,
    onClickLanguage,
    onKeyDownLanguage,
    open,
    onOpenChange,
    activeIndex,
    setActiveIndex,
    focusManagement,
    toggleFocusManagement,
  };
};
