import type React from 'react';
import { createPortal } from 'react-dom';
import { Transition } from 'react-transition-group';

import Toast from '../ui/Toast';

type typeProps = 'success' | 'failure';

interface Props {
  // fix:string型が望ましい
  message: string | null | undefined;
  type?: typeProps;
}

const duration = 0;

const Feedback: React.FC<Props> = ({ message, type = 'failure' }) => {
  return createPortal(
    <Transition in={message !== undefined} timeout={duration}>
      {(state) => (
        <Toast appearance={type} transitionState={state} fixed={true}>
          {message}
        </Toast>
      )}
    </Transition>,
    document.getElementById('root') as HTMLElement,
  );
};

export default Feedback;
